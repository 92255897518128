import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MisDecumentService {
  private baseUrl: string = environment.apiUrl;
  constructor(private http:HttpClient) { }

  getGridData(clientId:string,type:String,month:string,year:string,userid:number): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/ClientPortal/GetMISPortalDetails?ClientId=${clientId}&Type=${type}&Month=${month}&Year=${year}&Userid=${userid}`;
    return this.http.get(url, { observe: 'response' });
  }
  fetchFile(filepath:string): Observable<Blob> {
    const url = `${this.baseUrl}/api/ClientPortal/MISFilePreview?filepath=${filepath}`;
    return this.http.post(url, null, { responseType: 'blob' });
  }
}
