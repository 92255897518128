<hr class="hr-fluid" *ngIf="showOtherTabs">
<div class="col-md-12 colpadding0" style="margin-top: -6px;" *ngIf="showOtherTabs">
    <header class="panel-heading tab-bg-primary colpadding0" style="background: none !important;">
        <ul class="nav nav-tabs">
            <li class="listItem pemt" [ngClass]="{'selected-tab':  EmployeeOption==='Notice'}"
                (click)="EmployeeOption='Notice'" style="margin-left: -8px !important">
                <a>Notice Details</a>
            </li>
            <li class="listItem pemt" [ngClass]="{'selected-tab':  EmployeeOption==='SME'}"
                (click)="EmployeeOption='SME'" style="margin-left: -8px !important">
                <a>SME Response</a>
            </li>
            <li class="listItem pemt" [ngClass]="{'selected-tab':  EmployeeOption==='SD'}" (click)="EmployeeOption='SD'"
                style="margin-left: -8px !important">
                <a>SD Executer</a>
            </li>
            <li class="listItem pemt" [ngClass]="{'selected-tab':  EmployeeOption==='Document'}"
                (click)="EmployeeOption='Document'" style="margin-left: -8px !important">
                <a>Document Section</a>
            </li>
        </ul>
    </header>
</div>

<div class="row col-md-12 colpadding0 " *ngIf="!showOtherTabs">
    <div class="row col-md-12 " style="margin-left: 2px;">
        <h4 class="f-label">{{DashboardRole}} Dashboard</h4>
    </div>

    <div class="dashboard-container colpadding0" *ngIf="role==='CORDN'">
        <div class="DashboardContainer f-label" (click)="applyFilterDashboard('pending assignment')">
            <img src="../assets/vectors/PendingAssignment.svg" alt="" class="me-2">
            <div class="text-container">
                <span class="title-text">Pending Assignment</span>
                <p class="mb-0 count-text">{{PendingAssignmentCount}}</p>
            </div>
        </div>

        <div class="DashboardContainer f-label" (click)="applyFilterDashboard('pending action')">
            <img src="../assets/vectors/PendingAction.svg" alt="" class="me-2">
            <div class="text-container">
                <span class="title-text">Pending Action</span>
                <p class="mb-0 count-text">{{PendingActionCount}}</p>
            </div>
        </div>

        <div class="DashboardContainer f-label" (click)="applyFilterDashboard('overdue')">
            <img src="../assets/vectors/Overdue.svg" alt="" class="me-2">
            <div class="text-container">
                <span class="title-text">Overdue</span>
                <p class="mb-0 count-text">{{OverdueCount}}</p>
            </div>
        </div>

        <div class="DashboardContainer f-label" (click)="applyFilterDashboard('closed')">
            <img src="../assets/vectors/NoticeClose.svg" alt="" class="me-2">
            <div class="text-container">
                <span class="title-text">Closed</span>
                <p class="mb-0 count-text">{{ClosedCount}}</p>
            </div>
        </div>
    </div>

    <div class="dashboard-container colpadding0" *ngIf="role==='SME'">
        <div class="DashboardContainer f-label" (click)="applyFilterDashboard('extension')">
            <img src="../assets/vectors/PendingAssignment.svg" alt="" class="me-2">
            <div class="text-container">
                <span class="title-text">Extension</span>
                <p class="mb-0 count-text">{{PendingExtensionCount}}</p>
            </div>
        </div>

        <div class="DashboardContainer f-label" (click)="applyFilterDashboard('response pending')">
            <img src="../assets/vectors/PendingAction.svg" alt="" class="me-2">
            <div class="text-container">
                <span class="title-text">Response Pending</span>
                <p class="mb-0 count-text">{{ResponsePendingCount}}</p>
            </div>
        </div>

        <div class="DashboardContainer f-label" (click)="applyFilterDashboard('overdue')">
            <img src="../assets/vectors/Overdue.svg" alt="" class="me-2">
            <div class="text-container">
                <span class="title-text">Overdue</span>
                <p class="mb-0 count-text">{{OverdueCount}}</p>
            </div>
        </div>

        <div class="DashboardContainer f-label" (click)="applyFilterDashboard('noticeresponsesubmitted')">
            <img src="../assets/vectors/NoticeClose.svg" alt="" class="me-2">
            <div class="text-container">
                <span class="title-text">Response Submitted</span>
                <p class="mb-0 count-text">{{NoticeResponseSubmittedCount}}</p>
            </div>
        </div>
    </div>

    <div class="dashboard-container colpadding0" *ngIf="role==='SDEXE'">
        <div class="DashboardContainer f-label" (click)="applyFilterDashboard('translationpending')">
            <img src="../assets/vectors/PendingAssignment.svg" alt="" class="me-2">
            <div class="text-container">
                <span class="title-text">Translation Pending</span>
                <p class="mb-0 count-text">{{TranslationPendingCount}}</p>
            </div>
        </div>

        <div class="DashboardContainer f-label" (click)="applyFilterDashboard('extensionsubmission')">
            <img src="../assets/vectors/PendingAction.svg" alt="" class="me-2">
            <div class="text-container">
                <span class="title-text">Ext.Submission Pending</span>
                <p class="mb-0 count-text">{{ResponsePendingCount}}</p>
            </div>
        </div>

        <div class="DashboardContainer f-label" (click)="applyFilterDashboard('submission pending')">
            <img src="../assets/vectors/Overdue.svg" alt="" class="me-2">
            <div class="text-container">
                <span class="title-text">Submission Pending</span>
                <p class="mb-0 count-text">{{OverdueCount}}</p>
            </div>
        </div>

        <div class="DashboardContainer f-label" (click)="applyFilterDashboard('response submitted')">
            <img src="../assets/vectors/NoticeClose.svg" alt="" class="me-2">
            <div class="text-container">
                <span class="title-text">Response Submitted</span>
                <p class="mb-0 count-text">{{NoticeResponseSubmittedCount}}</p>
            </div>
        </div>
    </div>

    <div class="row colpadding0" style="margin-bottom: 10px; margin-top: 10px;">
        <div class="col-md-4 colpadding0" >
            <input kendoTextBox placeholder="Type to Search" class="f-textbox" (keyup)="applyFilter($event)">
        </div>
        <div class="col-md-8 colpadding0">
            <button (click)="clearFilter()" class="figma-btn-white" style="float: right; margin-right: -40px;">
                <img src="../assets/vectors/ClearFilter.svg" alt="">
            </button>
            <button class="figma-btn-blue" style="float: right; " (click)="openAddNewPopup()"
                *ngIf="role==='CORDN' || role=='HR-Manager'">
                <img src="../assets/vectors/Plus.svg" alt="">Add Notice
            </button>
        </div>
    </div>

    <kendo-grid class="custom-grid" style="margin-left: 10px;" [data]="gridData" [columnMenu]="true" [resizable]="true" [pageSize]="pageSize" [pageable]="true" 
        (pageChange)="pageChange($event)" [skip]="skip" [reorderable]="true" >
        <kendo-grid-column field="clientId" title="EntitId">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div [title]="dataItem.clientId" class="cell-content">
                    {{dataItem.clientId}}
                </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="clientName" title="Entity Name">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div [title]="dataItem.clientName" class="cell-content">
                    {{dataItem.clientName}}
                </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="branchName" title="Branch">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div [title]="dataItem.branchName" class="cell-content">
                    {{dataItem.branchName}}
                </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="noticeType" title="Notice Type">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div [title]="dataItem.noticeType" class="cell-content">
                    {{dataItem.noticeType}}
                </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="noticeReferenceNo" title="Notice No">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div [title]="dataItem.noticeReferenceNo" class="cell-content">
                    {{dataItem.noticeReferenceNo}}
                </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="dueDate" title="Due Date">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div [title]="dataItem.dueDate" class="cell-content"
                    [ngClass]="{ 'blink': isDueDateAfterToday(dataItem.dueDate) }">
                    {{ dataItem.dueDate ? (dataItem.dueDate) : 'N/A' }}
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="noticeReceivedDate" title="Revised Due Date">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div [title]="dataItem.noticeReceivedDate" class="cell-content">
                    {{dataItem.noticeReceivedDate}}
                </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="risk" title="Risk">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div [title]="dataItem.risk" class="cell-content">
                    {{dataItem.risk}}
                </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="status" title="Status">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div [title]="dataItem.status" class="cell-content">
                    {{dataItem.status}}
                </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="action" title="Action" [width]="100">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div class="cell-content alignment">
                        <img src="../assets/vectors/edit.svg" (click)="gridAction(dataItem,'edit')" alt="Save Icon" class="svg-icon " />
                        <img src="../assets/vectors/DownloadBlue.svg"  (click)="gridAction(dataItem,'download')" alt="Delete Icon" class="svg-icon" />
                </div>
            </ng-template>
        </kendo-grid-column>

        <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
            <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
            <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
            <kendo-pager-next-buttons></kendo-pager-next-buttons>
            <kendo-pager-input></kendo-pager-input>
            <kendo-pager-info></kendo-pager-info>
            <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
        </ng-template>
    </kendo-grid>

    <div *ngIf="loading" class="loader-overlay">
        <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
    </div>

    <app-alert-popup *ngIf="alertData.alertVisible" [Width]="270"  [Data]="alertData"
        (close)="closeAlert()"></app-alert-popup>

</div>

<div class="colpadding0" *ngIf="EmployeeOption=='Notice'">
    <app-edit-notice [Data]="Data" (close)="backToNotice()"></app-edit-notice>
</div>
<div class="colpadding0" *ngIf="EmployeeOption=='SME'">
    <app-smeresponse [Data]="Data" (close)="backToNotice()"></app-smeresponse>
</div>
<div class="colpadding0" *ngIf="EmployeeOption=='SD'">
    <app-sdexecuter [Data]="Data" (close)="backToNotice()"></app-sdexecuter>
</div>
<div class="colpadding0" *ngIf="EmployeeOption=='Document'">
    <app-document-section [Data]="Data" (close)="backToNotice()"></app-document-section>
</div>

<app-notice-add-new-popup (close)="closePopup()" *ngIf="showAddNewPopup"></app-notice-add-new-popup>