import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class WorkspaceService {
  private baseUrl  = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getcompliance(type:string):Observable<HttpResponse<any>>{
    const url = `${this.baseUrl}/api/Common/Get_LC_CodeDescription?Type=${type}`;
    return this.http.get(url, { observe: 'response' });
  }

  getActs(type:string,frequency:string):Observable<HttpResponse<any>>{
    const url = `${this.baseUrl}/api/StatutoryDocumentsContoller/Get_Labour_StatutoryActs?Type=${type}&frequency=${frequency}`;
    return this.http.get(url, { observe: 'response' });
  }

  getAllFiles(flag:string,complianceType:string,entityId:string):Observable<HttpResponse<any>>{
    const url = `${this.baseUrl}/api/ComplianceInput/Get_LC_AllFilesType?flag=${flag}&ComplianceType=${complianceType}&EntityId=${entityId}`;
    return this.http.get(url, { observe: 'response' });
  }

  getJsonId(IOJson: any[] = [], parameterId: any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/ComplianceInput/LC_JSONInsert?IOJson=${JSON.stringify(IOJson)}&ParameterID=${parameterId}`;
    return this.http.get(url, { observe: 'response' });
  }

  getRegisterGrid(CustomerID:number,ComplianceType:string,ComplianceID:string,MonthId:string,Year:number,UserID:number,ClientID:string,JSONID:number,ActGroup:string): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/ComplianceInput/Get_LC_AllFileInputOutput_Register?CustomerID=${CustomerID}&ComplianceType=${ComplianceType}&ComplianceID=${ComplianceID}&MonthId=${MonthId}&Year=${Year}&UserID=${UserID}&ClientID=${ClientID}&JSONID=${JSONID}&ActGroup=${ActGroup}`;
    
    return this.http.get(url, { observe: 'response' });
  }
  getReturnGrid(CustomerID:number,ComplianceType:string,ComplianceID:string,MonthId:string,Year:number,UserID:number,ClientID:string,JSONID:number,ActGroup:string,Frequency:string): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/ComplianceInput/Get_LC_AllFileInputOutput_Return?CustomerID=${CustomerID}&ComplianceType=${ComplianceType}&ComplianceID=${ComplianceID}&MonthId=${MonthId}&Year=${Year}&UserID=${UserID}&ClientID=${ClientID}&JSONID=${JSONID}&ActGroup=${ActGroup}&Frequency=${Frequency}`;

    return this.http.get(url, { observe: 'response' });
  }
  getChallanGrid(CustomerID:number,ComplianceType:string,ComplianceID:string,MonthId:string,Year:number,UserID:number,ClientID:string,JSONID:number,ActGroup:string): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/ComplianceInput/Get_LC_AllFileInputOutput_Challan?CustomerID=${CustomerID}&ComplianceType=${ComplianceType}&ComplianceID=${ComplianceID}&MonthId=${MonthId}&Year=${Year}&UserID=${UserID}&ClientID=${ClientID}&JSONID=${JSONID}&ActGroup=${ActGroup}`;

    return this.http.get(url, { observe: 'response' });
  }

  getChallanGridStatutory(CustomerID:number,ComplianceType:string,ComplianceID:string,MonthId:string,Year:number,UserID:number,ClientID:string,JSONID:number,ActGroup:string): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/StatutoryDocumentsContoller/LC_RLCS_ComplianceDocuments_StateWise_LoadOnDemand?CustomerID=${CustomerID}&ComplianceType=${ComplianceType}&ComplianceID=${ComplianceID}&MonthId=${MonthId}&Year=${Year}&UserID=${UserID}&ClientID=${ClientID}&JSONID=${JSONID}&ActGroup=${ActGroup}`;

    return this.http.get(url, { observe: 'response' });
  }

  GetInputfileisProcessed(Entitiy:any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/ComplianceInput/GetInputfileisProcessed?EntityId=${Entitiy}`;

    return this.http.get(url, { observe: 'response' });
  }

  GetGenerateDocumentStatus(payload: any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/ComplianceInput/GetGenerateDocumentStatus`;
    return this.http.post(url, payload, { observe: 'response' });
  }

  ComplianceInput(payload: any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/ComplianceInput/GetInputList_Revamped`;
    return this.http.post(url, payload, { observe: 'response' });
  }

  GetUpoadedFilesStatus(payload: any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/ComplianceInput/LC_GetUpoadedFilesStatus`;
    return this.http.post(url, payload, { observe: 'response' });
  }

  GetSampleBasedOnInputID(payload: any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/ComplianceInput/GetSampleBasedOnInputID`;
    return this.http.post(url, payload, { observe: 'response' });
  }

  OutputJSON(payload: any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/ComplianceInput/OutputJSONInsert?IOJson=${payload.JsonID}&ParameterID=${payload.ParameterID}&ComplianceID=${payload.ComplianceID}&BranchID=${payload.BranchID}`;
    return this.http.get(url, { observe: 'response' });
  }

  uploadFile(payload:any,formData: any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/ComplianceInput/UploadInputFile?EntityId=${payload.EntityId}&Month=${payload.Month}&Year=${payload.Year}&JsonId=${payload.JsonId}&InputId=${payload.InputId}&UserId=${payload.UserId}&UniqueID =''&CustomerID=${payload.CustomerID}&FileName=${payload.FileName}&FilepathWithFileName=''`;
    return this.http.post(url, formData,{observe:'response'});
  }
  GenerateDocumentRegister(payload:any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/DocumentGeneration/GenerateRegisterComplianceDocuments`;
    return this.http.post(url, payload,{observe:'response'});
  }
  
  GenerateDocumentReturn(payload:any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/DocumentGeneration/GenerateReturnComplianceDocuments`;
    return this.http.post(url, payload,{observe:'response'});
  }
  GenerateDocumentChallan(payload:any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/DocumentGeneration/GenerateChallanComplianceDocuments`;
    return this.http.post(url, payload,{observe:'response'});
  }
  GetErrorFile(payload: any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/ComplianceInput/GetInputUploadErrorFile`;
    return this.http.post(url, payload, { observe: 'response' });
  }

  uploadReturns(payload:any): Observable<any> {
    const url=`${this.baseUrl}/api/ComplianceInput/Returns_ManulUpload?ScheduleONID=${payload.ScheduleONID}&customerID=${payload.customerID}`
    const formData: FormData = new FormData();
    formData.append('file', payload.file);
    return this.http.post<any>(url, formData);
  }

  getInputFile(payload:any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/ComplianceInput/Get_InputFilesBasedONComplianceID?JSONID=${payload.JSONID}&CmpID=${payload.CmpID}&EntityID=${payload.EntityID}&ActType=${payload.ActType}&CType=${payload.CType}`;
    return this.http.get(url, { observe: 'response' });
  }
}
