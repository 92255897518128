import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { KendoDatepickerComponent } from '../../../components/kendo-datepicker/kendo-datepicker.component';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import {  MinimumWageNotification, MwNotificationService } from '../../../service/mw-notification.service';
import { LocationMasterServiceService } from '../../../service/location-master-service.service';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { FormsModule } from '@angular/forms';
import { NotificationsComponent } from '../../minimum-wages/notifications/notifications.component';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { NgClass, NgIf } from '@angular/common';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';

@Component({
  selector: 'app-notification-add-popup',
  standalone: true,
  imports: [TextBoxModule,KendoDatepickerComponent,ComboBoxComponent,FormsModule,DialogModule,NgIf,AlertPopupComponent,NgClass],
  templateUrl: './notification-add-popup.component.html',
  styleUrl: './notification-add-popup.component.css'
})
export class NotificationAddPopupComponent {
  @ViewChild(ComboBoxComponent) comboBoxComponent!: ComboBoxComponent;
  public gridData: GridDataResult = { data: [], total: 0 };
  public selectionType: string | null = null;
  public defaultState = "Select State";
  public defaultIndustry = { text: 'Select Industry Type', value: null };
  public defaultId = { text: 'Select Notification Id', value: null };
  public industryTypes: any[] = [this.defaultIndustry];
  public notificationId: any[] = [this.defaultId];
  public selectedIndustryType = this.defaultIndustry;
  public selectedNotificationId = this.defaultId;
  public allStatesData: any[] = [];
  public selectedState:{id:any,name:any}={id:'',name:''}
  public selectedIndustry: string | null = null;
  public notifiedOn!:Date | null;
  public effectiveFrom!: Date | null;
  public effectiveTo!: Date | null;
  notificationNo: string = '';
  public placeholder: string = 'DD-MM-YYYY';
  public showValidationErrors = false;
  notificationError: string = '';
  todayDate: Date = new Date();
  @Output() close = new EventEmitter<void>();
  showDateError: boolean | null = false;
  showEffectiveFromError: boolean | null = false;
  showEffectiveToError: boolean | null = false;
  @ViewChild('dialogTitle') dialogTitle!: ElementRef;

  alertData = { alertMessage: '', alertVisible: '' }

  constructor(private notificationService: MwNotificationService, private locationMaster: LocationMasterServiceService, private cdr: ChangeDetectorRef,private notification:NotificationsComponent) { }

  ngAfterViewInit() {
    const titleElement = this.dialogTitle.nativeElement;
    titleElement.style.textAlign = 'center';
    titleElement.style.width = '100%';
  }

  ngOnInit(): void {

    this.fetchStateMasters();

  }

    validateForm(): boolean {
    const isValid = !!(
      this.notifiedOn &&
      this.effectiveFrom &&
      this.effectiveTo &&
      this.notificationNo &&
      this.selectedState &&
      this.effectiveTo > this.effectiveFrom 
    );

    this.showValidationErrors = !isValid;
    return isValid;
  }
  onInputChange(event: Event) {
    const input = (event.target as HTMLInputElement).value;
    const validPattern = /^[a-zA-Z0-9()\/\-]*$/;
  
    if (!validPattern.test(input)) {
     
      this.notificationNo = input.replace(/[^a-zA-Z0-9()\/\-]/g, '');
    }
  }
  onDateBlur(): void {
    this.validateDates();
  }
  
  validateDates(): void {
    this.showEffectiveFromError = this.effectiveFrom && 
                                this.notifiedOn &&
                                this.effectiveFrom < this.notifiedOn;

    this.showEffectiveToError = this.effectiveTo && 
                                this.notifiedOn &&
                                this.effectiveTo < this.notifiedOn;

    this.showDateError = this.effectiveTo && 
                         this.effectiveFrom && 
                         this.effectiveTo <= this.effectiveFrom;
  }
  
  isNotificationNumberDuplicate(notificationNo: string): boolean {
    console.log('Checking for duplicate notification number:', notificationNo);
    
    if (!Array.isArray(this.gridData.data)) {
        console.error('Grid data is not an array:', this.gridData.data);
        return false;
    }
    
    console.log('Grid data length:', this.gridData.data.length);

    const isDuplicate = this.gridData.data.some(item => {
        console.log('Comparing with:', item.notification_Number); 
        return item.notification_Number === notificationNo;
    });

    return isDuplicate;
}
  
  validateNotificationNumber(notificationNo: string): void {
    const isDuplicate = this.isNotificationNumberDuplicate(notificationNo);
    if (isDuplicate) {
        this.notificationError = 'Notification number already exists.';
    } else {
        this.notificationError = '';
    }
    console.log('Notification error:', this.notificationError);
  }

  public closePopup(): void {
    this.close.emit();
  }
  closeAlert() {
    this.alertData = { alertMessage: '', alertVisible: '' };
  }
  
  fetchStateMasters(): void {
    this.notificationService.getStateMaster().subscribe(
      {     next: (response) => {

          const data = response.body;
          if (data && Array.isArray(data)) {
              this.allStatesData = data.map((item: any) => ({
              id: item.sM_Code,
              name: item.sM_Name
            }));
            this.defaultState = 'Select State';
            console.log('Updated states data:', this.allStatesData);
          }
          else {
            console.error('Unexpected API response format:', data);
          }
        }, error: (error) => {
          console.error('Error fetching states data', error);
        } });
  }


  DropdownValueChange(value: any) {
    console.log('selected state',value.id);
    this.selectedState = {id:value.id,name:value.name};
  }

  fetchStatesData(): Promise<void> {
    return new Promise((resolve, reject) => {
        this.notificationService.getMasterNotification().subscribe(
          {
            next: (response) => {
                const data = response.body;
                const transformedData = data.map((item: any) => ({
                          ...item,
                          notificationInfo: `[${item.id}]  ${item.notification_Number}`
                      }));
                      this.gridData = {
                          data: transformedData,
                          total: transformedData.length
                      };
                      this.cdr.detectChanges();
                      resolve();   
            },error(error) {
              console.error("Error fetching dropdown data", error);
            },
          }
        );
    });
}

  addOrUpdateNotification() {
    if (!this.validateForm()) {
        return;
    }

    this.fetchStatesData().then(() => {
        this.validateNotificationNumber(this.notificationNo);
        if (this.notificationError) {
            console.log('Stopping form submission due to notification error:', this.notificationError);
            return;
        }

        const notification: MinimumWageNotification = {
            id: 0,
            state: this.selectedState.id || '',
            effective_From: this.effectiveFrom ? this.effectiveFrom.toISOString() : '',
            effective_To: this.effectiveTo ? this.effectiveTo.toISOString() : '',
            notification_Number: this.notificationNo,
            notified_On: this.notifiedOn ? this.notifiedOn.toISOString() : '',
            created_On: new Date().toISOString()
        };

        this.notificationService.addOrUpdateNotification(notification).subscribe(
          {
            next: (response) => {
                const data = response.body;
                this.alertData = { alertMessage: 'Notification saved successfully...', alertVisible: 'success' };
                this.fetchStatesData(); 
                this.cdr.detectChanges();
            },error: (error) => {
              this.alertData = { alertMessage: error.error.message || 'Error saving notification', alertVisible: 'error' };
              console.error('Error saving notification:', error);
            }
          });
    }).catch(error => {
        console.error('Error fetching states data:', error);
    });
}

 
  onDateChange(value: Date, type: string) {
    const date = new Date(value.toISOString().split('T')[0]);

    if (type === 'notifiedOn') {
      this.notifiedOn = date;
    } else if (type === 'effectiveFrom') {
      this.effectiveFrom = date;
      if (this.notifiedOn && this.effectiveFrom && this.effectiveFrom < this.notifiedOn) {
        this.alertData = { alertMessage: 'Effective From should be greater than or equal to Notified On date.', alertVisible: 'error' };
        this.effectiveFrom = null;
      } else {
        this.effectiveFrom = null;
      }
    } else if (type === 'effectiveTo') {
      this.effectiveTo = date;
    }
    //this.validateDates();
  }

  public parentColumns: any[] = [
    {
      field: 'notificationInfo',
      title: 'Notification Id'
    },
    { field: 'state', title: 'State' },
    { field: 'effective_From', title: 'Effective From' },
    { field: 'effective_To', title: 'Effective To' },
    { field: 'notification_Number', title: 'Notification Number' },
    { field: 'notified_On', title: 'Notification On' },
    { field: 'created_On', title: 'Created On' },

    {
      field: 'Action',
      isIconColumn: true,
      title: 'Action',
      filteredIcons: [

        {
          url: '../assets/vectors/delete.svg',
          alt: 'Delete Icon',
          action: 'delete'
        }
      ]
    }
  ]; 
}
