<div class="container-fluid colpadding0">
    <form #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm)" class="login-form">
        <div class="col-md-12 login-form-head">
            <p class="login-img">
                <a href="https://www.avantis.co.in">
                    <img src="https://login.teamleaseregtech.com/Images/TeamLease1.png" alt="AVANTIS Logo" class="teamlease-img"/>
                </a>
            </p>
        </div>

        <div class="login-wrap">
            <div class="row" style="padding-left:20px">
                <h1>Sign in</h1>
                <div class="input-group">
                    <span class="input-group-addon">
                          <img src="../assets/vectors/profile_icon.svg" class="profile-icon" alt="">
                        </span>
                        <input type="text" class="form-control" placeholder="Username" [(ngModel)]="loginusername"
                        name="loginusername" required maxlength="100" />
                    </div>
                    
                    <div class="input-group">
                        <span class="input-group-addon">
                            <img src="../assets/vectors/lock_icon.svg" class="profile-icon" alt="">
                        </span>
                    <input type="password" class="form-control" placeholder="Password" [(ngModel)]="loginpassword"
                        name="loginpassword" required maxlength="50" />
                </div>

                <br />
                <div class="clearfix"></div>
            </div>

                <div style="margin-top: 15px; margin-bottom:50px; display:flex; justify-content:center;">
                    <button type="submit" class="figma-btn-blue">Sign in</button>
                    <!-- <button type="submit" class="btn btn-primary btn-lg btn-block">Sign in</button> -->
                </div>

                <div *ngIf="loginError" class="login-error-message">
                    Please enter a valid username or password.
                </div>
        </div>
    </form>
</div>