import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientRegistrationRepositoryService {
  private baseUrl: string = environment.apiUrl;

  constructor(private http:HttpClient) { }

  getGridData(clientId:string): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/RegistrationRepository/LC_GetClientRegistrationRepository?ClientId=${clientId}`;
    return this.http.get(url, { observe: 'response' });
  }

  getExcelFile(entityId:string,flag:String): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/RegistrationRepository/ExportRepository?EntityID=${entityId}&flag=${flag}`;
    return this.http.get<any>(url, { observe: 'response' });
  }

  getAllActivities():Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/RegistrationRepository/LC_GetActivitiesForRegistrationRepository`;
    return this.http.get<any>(url, { observe: 'response' });
  }

  getAllStates(entityId:string):Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/RegistrationRepository/LC_GetStatesForRegistrationRepository?EntityID=${entityId}`;
    return this.http.get<any>(url, { observe: 'response' });
  }

  getAllLocations(entityId:string):Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/RegistrationRepository/LC_GetLocationsForRegistrationRepository?EntityID=${entityId}`;
    return this.http.get<any>(url, { observe: 'response' });
  }

  getAllBranches(entityId:string):Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/RegistrationRepository/LC_GetBranchesForRegistrationRepository?EntityID=${entityId}`;
    return this.http.get<any>(url, { observe: 'response' });
  }

  getAllTypes():Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/RegistrationRepository/LC_GetRegTypesForRegistrationRepository`;
    return this.http.get<any>(url, { observe: 'response' });
  }
  
  saveUpdateData(value:any): Observable<HttpResponse<any>> {
    debugger
    const data = new FormData();

    data.append('StateId', value.StateId);
    data.append('Activity', value.Activity);
    data.append('EntityId', value.EntityId);
    data.append('userID', value.userID);
    data.append('LocationId', value.LocationId);
    data.append('Branch', value.Branch);
    data.append('RegType', value.RegType);
    data.append('RCNumber', value.RCNumber);
    data.append('ExpiryDate', value.ExpiryDate);
    const file = value.file;
    if (file) {
      data.append('file', file, file.name);
    }
    const url = `${this.baseUrl}/api/RegistrationRepository/SaveNewRegRepository`;
    return this.http.post(url,data, { observe: 'response' });
  }
}
