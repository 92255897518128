<kendo-dialog #dialogRef title="Map Activity-Frequency" (close)="closePopup()" 
[width]="700" class="custom-dialog">
 
    <div class="row col-12 colpadding0" style="padding: 10px;">
      <kendo-grid 
        [data]="gridView" 
        [pageSize]="pageSize"
        [skip]="skip"
        [pageable]="true" class="custom-grid"
        (pageChange)="pageChange($event)">
        
        <kendo-grid-column field="name" title="Activity">
          <ng-template kendoGridCellTemplate let-rowIndex="rowIndex" let-dataItem="dataItem">
          <span class="cell-content" *ngIf="popupType==='Edit Popup'">{{selectedActivities[rowIndex].name}}</span>
          <span class="cell-content" *ngIf="popupType==='Add Popup'">{{selectedActivities[rowIndex].id}}</span>
        </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Frequency">
          <ng-template kendoGridCellTemplate let-rowIndex="rowIndex" let-dataItem="dataItem">
            <kendo-multiselecttree #multiselectTree placeholder="Select Nature of Acts"
            kendoMultiSelectTreeExpandable [kendoMultiSelectTreeHierarchyBinding]="frequencyOptions"
            textField="text" valueField="value"
            [value]="dataItem.Frequencies"
            (valueChange)="onFrequencyChange(skip + rowIndex, $event)" [expandedKeys]="['0']"
            [popupSettings]="popupSettings" class="f-textbox multi-box">
        </kendo-multiselecttree>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
</div>
</kendo-dialog>
