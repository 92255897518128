import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import {DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GridDataResult, GridModule, PageChangeEvent } from '@progress/kendo-angular-grid';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { PtSlabServiceService } from '../../../service/pt-slab-service.service';
import { UserService } from '../../../service/user.service';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { NgIf } from '@angular/common';
import { ChallanBulkuploadPopupComponent } from '../../popups/challan-bulkupload-popup/challan-bulkupload-popup.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';

@Component({
  selector: 'app-challan-pt-slab',
  standalone: true,
  imports: [GridActionItemComponent, FormsModule ,GridModule,DropdownListComponent,ButtonsModule,AlertPopupComponent,NgIf,ChallanBulkuploadPopupComponent,IndicatorsModule,DropDownListModule,ReactiveFormsModule],
  templateUrl: './challan-pt-slab.component.html',
  styleUrl: './challan-pt-slab.component.css'
})
export class ChallanPtSlabComponent {
  public buttonCount = 2;
  public sizes = [{text:'All',value:1},5, 10, 20];
  public gridData: GridDataResult = { data: [], total: 0 };
  public pageSize: number = 10;
  public skip: number = 0;    
  uploadOpen=false;
  loading=false;
  public municipalities: any[] = [];
  municipalityControls: FormControl[] = [];
  selectedmunicipality:{text:any,value:any}={text:'',value:''}
  @Output() close = new EventEmitter<void>();
  alertData = { alertMessage: '', alertVisible: '' };
  public states = [
    {key: 'Select State', value: 0},
    { key: 'Kerala',value: 'KRL' },
    { key: 'Tamil nadu', value: 'TMN' }
  ];
  public defaultState: { key: string, value: any } | null = { key: 'Select State', value: 0 };
  

  
  constructor(private ptSlabService: PtSlabServiceService,private user:UserService,private cdr: ChangeDetectorRef){}



  public pageChange(event: PageChangeEvent): void {
    console.log('Page Change Event Received:', event);
    this.loading=true;
    this.skip = event.skip;
    if(event.take==1){
      this.pageSize = this.gridData.total;
    }else{
      this.pageSize = event.take;
    }
    this.getEmployeeSlabMaster();
  }

 
  getEmployeeSlabMaster(): void {
    const client = this.user.getClientId();
    this.loading=true;
    this.ptSlabService.getEmployeeSlabMaster(client,this.defaultState?.value || '' ,this.skip / this.pageSize + 1, this.pageSize, this.skip).subscribe({
      next:(response)=>{
        if(response.status===200){
          const data=response.body;
          console.log('response body',data);
          
          this.gridData = {
          data: data.data,
          total: data.total
        }
        this.loading=false;
        }
      }
    })
    this.fetchMunicipalities()  ;
    
  }
  onValueChange(event: any): void {
    this.defaultState = event; 
    console.log(this.defaultState); 
  }

  applyFilter(): void {
    console.log('Apply Filter button clicked'); 
    console.log(this.defaultState); 
    if (this.defaultState) {
      this.getEmployeeSlabMaster(); 
    }
  }

  
  clearFilter(): void {
    this.defaultState = { key: 'Select State',value: 0 }; 
    this.gridData = { data: [], total: 0 };
  }
  
  onSaveClick(dataItem: any): void {
    console.log('Clicked Data Item:', dataItem);
    if (dataItem) {
      this.saveMunicipalityMapping(dataItem);
    } else {
      console.error('Data item is undefined');
    }
  }
  
  saveMunicipalityMapping(rowData?: any): void {
    let selectedData;
    if (rowData) {
      selectedData = [{
        empid: rowData.eM_EmpID ?? '',
        empname: rowData.eM_EmpName ?? '',
        branch: rowData.eM_Branch ?? '',
        addr: rowData.municipalityName.value ?? '',
        client: this.user.getClientId()
      }];
    } else {
      selectedData = this.gridData.data.map((item: any) => ({
        empid: item.eM_EmpID ?? '',
        empname: item.eM_EmpName ?? '',
        branch: item.eM_Branch ?? '',
        addr: item.municipalityName.value ?? '',
        client: this.user.getClientId()
      }));
    }
    console.log('Data being sent:', selectedData);
  
    this.ptSlabService.saveMunicipalityMapping(selectedData).subscribe({
      next:(response)=>{
        if(response.status===200){
            this.alertData = { alertMessage: 'Data Saved Successfully...', alertVisible: 'success' };
        console.log('Save successful', response);
      
        }
        else{
          this.alertData = { alertMessage:  response.body.error.status, alertVisible: 'error' };
        }
      }
    })
    
  }  
  fetchMunicipalities(): void {
    this.ptSlabService.getSlab('TMN1').subscribe({
      next: (response) => {
        console.log(response);
        
        this.municipalities = response.body.map((item: any) => ({
          text: item.municipalityName,
          value: item.municipalityName
        }));
      },
      error: (err) => {
        console.error('Error fetching municipalities', err);
      }
    });
  }

  onMunicipalityChange(newValue: string, dataItem: any): void {
    dataItem.municipalityName = newValue; 
  }
  bulkUpload(){
    this.uploadOpen=true
  
  }

  closeUploadPopup(){
    this.uploadOpen=false
  }

  public closePopup(): void {    
  this.alertData = { alertMessage: '', alertVisible: '' };
    this.close.emit();
  }
}