import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { GridModule } from '@progress/kendo-angular-grid';
import { EditPaycodeMapping_Dto, PaycodeMasterService } from '../../../service/paycode-master.service';
import { UserService } from '../../../service/user.service';
import { FormsModule } from '@angular/forms';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'app-paycode-edit-popup',
  standalone: true,
  imports: [DialogModule, GridModule, FormsModule, DropDownListModule, AlertPopupComponent, NgIf],
  templateUrl: './paycode-edit-popup.component.html',
  styleUrl: './paycode-edit-popup.component.css'
})
export class PaycodeEditPopupComponent {
  @Input() rowData: any;
  @Output() close = new EventEmitter<void>();
  @Output() update = new EventEmitter<EditPaycodeMapping_Dto>();

  public headername: string = '';
  public sequenceOrder: number | null = null;
  public paycodeType: { text: string, value: string }[] = [
    { text: 'Earning', value: 'E' },
    { text: 'Deduction', value: 'D' },
    { text: 'Standard', value: 'S' }
  ];
  public selectedPaycodeType!: { text: string, value: string }
  public paycode: { text: string, value: string }[] = [];
  public selectedPaycode!: { text: string, value: string };
  public esic_App: boolean = false;
  public pF_App: boolean = false;
  public pT_App: boolean = false;
  public lwF_App: boolean = false;
  alertData = { alertMessage: '', alertVisible: '' }

  constructor(private paycodeService: PaycodeMasterService, private user: UserService, private cdr: ChangeDetectorRef) { }

  @ViewChild('dialogTitle') dialogTitle!: ElementRef;
  ngAfterViewInit() {
    const titleElement = this.dialogTitle.nativeElement;
    titleElement.style.textAlign = 'center';
    titleElement.style.width = '100%';
  }

  ngOnInit(): void {
    this.loadPaycodes();
  }

  ngOnChanges(changes: SimpleChanges): void {
    debugger;
    if (changes['rowData'] && changes['rowData'].currentValue) {
      console.log('Received rowData:', changes['rowData'].currentValue);
      this.initializeFields();
      console.log('After initializing fields:', {
        headername: this.headername,
        sequenceOrder: this.sequenceOrder,
        selectedPaycodeType: this.selectedPaycodeType,
        selectedPaycode: this.selectedPaycode,
        esic_App: this.esic_App,
        pF_App: this.pF_App,
        pT_App: this.pT_App,
        lwF_App: this.lwF_App,
      });
      this.cdr.detectChanges();
    }
  }


  public headerNameEditable: boolean = true;
  public sequenceOrderEditable: boolean = true;


  toPascalCase = (input: string): string => {
    return input
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join('');
  };

  public loadPaycodes(): void {
    if (this.selectedPaycodeType.value === 'S') {
      this.paycodeService.getStandardPaycodeDropdown().subscribe({
        next:(response)=>{
          if(response.status===200){
            const data=response.body
            if (Array.isArray(data.Result)) {
                    this.paycode = data.map((item: any) => ({
                      text: item.key,
                      value: item.value
                    }));
                    console.log('Loaded standard paycodes:', this.paycode); 
                    this.updateSelectedPaycode();
                  }
                  else {
                          console.error('Invalid data structure received:', data);
                        }
          }
        }
      })
    
      
    }else {
      this.paycodeService.getPaycodeDropdown(this.selectedPaycodeType.value).subscribe({
        next:(response)=>{
         
            const data=response.body
                 if (Array.isArray(data)) {
            this.paycode = data.map((item: any) => ({
              text: item.key,
              value: item.value
            }));
            this.updateSelectedPaycode();
           } 
           else {
            console.error('Invalid data structure received:', data);
          }
          
        }
      })

    }
  }
  
  public updateSelectedPaycode(): void {
    if (this.rowData) {
      if (this.selectedPaycodeType.value === 'S') {
        const paycodeMatch = this.paycode.find(code => code.value === this.rowData.cpmD_Standard_Column);
        this.selectedPaycode = paycodeMatch ? paycodeMatch : { text: this.rowData.cpmD_Standard_Column, value: this.rowData.cpmD_Standard_Column };
      } else {
        const paycodeMatch = this.paycode.find(code => code.value === this.rowData.cpmD_PayCode);
        this.selectedPaycode = paycodeMatch ? paycodeMatch : { text: '', value: '' };
      }
    }
  }

  private initializeFields() {
    if (!this.rowData) {
      console.error('No rowData available for initialization');
      return;
    }

    console.log('Initializing fields with rowData:', this.rowData);

    this.headername = this.rowData.cpmD_Header ?? '';
    this.sequenceOrder = this.rowData.cpmD_Sequence_Order ?? null;

    const paycodeTypeMatch = this.paycodeType.find(type => type.text === this.toPascalCase(this.rowData.cpmD_Deduction_Type));
    this.selectedPaycodeType = paycodeTypeMatch ? paycodeTypeMatch : { text: '', value: '' };
    console.log('Available Paycode Types:', this.paycodeType);

    if (this.rowData.cpmD_PayGroup === 'standard') {
      const paycodeMatch = this.paycode.find(code => code.value === this.rowData.cpmD_Standard_Column);
      this.selectedPaycode = paycodeMatch ? paycodeMatch : { text: this.rowData.cpmD_Standard_Column, value: this.rowData.cpmD_Standard_Column };
    } else if (this.rowData.cpmD_PayGroup === 'paycodes') {
      const paycodeMatch = this.paycode.find(code => code.value === this.rowData.cpmD_PayCode);
      this.selectedPaycode = paycodeMatch ? paycodeMatch : { text: this.rowData.cpmD_PayCode ?? '', value: this.rowData.cpmD_PayCode ?? '' };
    } else {
      this.selectedPaycode = { text: '', value: '' };
    }


    this.esic_App = this.rowData.cpmD_appl_ESI === 'YES';
    this.pF_App = this.rowData.cpmD_appl_PF === 'YES';
    this.pT_App = this.rowData.cpmD_Appl_PT === 'YES';
    this.lwF_App = this.rowData.cpmD_Appl_LWF === 'YES';

  }

  public onCheckboxChange(event: any, field: 'pF_App' | 'esic_App' | 'pT_App' | 'lwF_App'): void {
    console.log('Checkbox change event triggered:', event);

    const value = event.target.checked ? 'Y' : 'N';
    if (field === 'pF_App') {
      this.pF_App = value === 'Y';
    } else if (field === 'esic_App') {
      this.esic_App = value === 'Y';
    } else if (field === 'pT_App') {
      this.pT_App = value === 'Y';
    } else if (field === 'lwF_App') {
      this.lwF_App = value === 'Y';
    }

    console.log(
      this.pF_App,
      this.esic_App,
      this.pT_App,
      this.lwF_App
    );

  }

  public isStandardPaycodeType(): boolean {
    return this.selectedPaycodeType?.value === 'S';
  }



  public closePopup(): void {
    this.close.emit();
  }

  public editFiles(): void {
    const updateData: EditPaycodeMapping_Dto = {
      entityId: this.user.getClientId(),
      paycode: this.selectedPaycode.value,
      paycodeType: this.selectedPaycodeType.value,
      sequenceOrder: this.sequenceOrder!,
      headername: this.headername,
      esic_App: this.esic_App ? 'Y' : 'N',
      pF_App: this.pF_App ? 'Y' : 'N',
      pT_App: this.pT_App ? 'Y' : 'N',
      lwF_App: this.lwF_App ? 'Y' : 'N'
    };
    console.log('Update Data:', updateData);

    this.paycodeService.updatePaycodeMapping(updateData).subscribe({
      next:(response)=>{
        console.log('Response',response);
        
        if(response.body.result==='success'){

          console.log('Update successful:', response);
    this.alertData = { alertMessage: 'Data Edited Successfully', alertVisible: 'success' };
    this.update.emit(updateData);
    console.log('Update:', response);
        }
        else{
            this.alertData = { alertMessage: response.body.error.status, alertVisible: 'error' };
       
        }
      },
   
    });
  }


}


