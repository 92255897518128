<kendo-dialog 
#dialogRef 
  title="Upload Department Address"
  (close)="closePopup()"
  [minWidth]="300"
  [height]="400"
  [width]="700"
  class="custom-dialog">
  
  <div class="row col-12 colpadding0">
    <div class="row sampleDocument">
      <div class="col-md-4" style="float: left;" >
        <label class="f-label" style="font-size:12px">Upload</label>
      </div>
      <div class="col-md-8 clopadding0" style="float: right;">
        <!-- </div>
        <div class="col-md-4 clopadding0 " style="float: right;"> -->
          <a href="../assets/files/AuthorityAddress.xlsx" download="AuthorityAddress.xlsx"
          style="color: #125AAB; text-decoration: underline; margin-top:5px;font-size: 12px; float: right;" id="btnSampleRLCS">
          Sample Template
        </a>
        <img src="../assets/vectors/Excel.svg" alt="" style="padding: 5px; float: right; ">
        <a href="../assets/files/AvailableAddress_type.xlsx" download="AvailableAddress_type.xlsx"
        style="color: #125AAB; text-decoration: underline; margin-top:5px; font-size: 12px; float: right;" id="btnSampleRLCS">
        Available AddressType
      </a>
      <img src="../assets/vectors/Excel.svg" alt="" style="padding: 5px; float: right;">
  </div>
      
    </div>
    <div class="col-md-12 form-group" >
      <div class="drop-zone" 
      (dragover)="onDragOver($event)" 
      (dragleave)="onDragLeave($event)" 
      (drop)="onDrop($event)">
      <div *ngIf="!selectedFile" class="f-label" style="font-size: 18px;">
        <div class="col-md-12">
          <img src="../assets/vectors/cloud.svg" alt="">
          </div>
          <div class="col-md-8">
            <label style="margin-left: 162px;">{{ dropzoneMessage }}</label>
          </div>
          <div class="col-md-4">
            <label style="color: #125AAB; text-decoration: underline; cursor: pointer;  margin-left: -75px; font-weight: 500;">
              Browse
              <input type="file" style="display: none;" (change)="onFileSelect($event)" 
              accept=".xls,.xlsx,.csv" />
            </label>
          </div>
        </div>
        <p *ngIf="selectedFile" style="margin-top: 45px;">{{ selectedFile.name }}</p>
      </div>
    </div>
  </div>

  <kendo-dialog-actions style="border: none;">
    <button kendoButton type="submit" (click)="uploadFiles()" class="figma-btn-blue f-popup">
      Upload
    </button>
  </kendo-dialog-actions>
  <div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>
</kendo-dialog>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270" [Data]="alertData" (close)="closeAlert()"></app-alert-popup>
