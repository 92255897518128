<kendo-dialog #dialogRef title="User Details" (close)="closePopup()" [width]="1000" [height]="365" class="custom-dialog">
    <div class="row col-12 dialog-content custom-dialog">
        <form [formGroup]="form">
            <div class="row UserContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">First Name<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="First Name"
                            [(ngModel)]="userObject.firstName" formControlName="FirstName"
                            [ngClass]="{'is-invalid': form.get('FirstName')?.invalid && (form.get('FirstName')?.touched) && form.get('FirstName')?.hasError('whitespace')}">
                        <div *ngIf="form.get('FirstName')?.invalid && (form.get('FirstName')?.touched)"
                            class="text-danger">
                            <div *ngIf="form.get('FirstName')?.errors?.['pattern']">
                                First Name must contain only letters and spaces.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Last Name<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="Last Name"
                            formControlName="LastName" [(ngModel)]="userObject.lastName"
                            [ngClass]="{'is-invalid': form.get('LastName')?.invalid && (form.get('LastName')?.touched) && form.get('LastName')?.hasError('whitespace')}">
                        <div *ngIf="form.get('LastName')?.invalid && (form.get('LastName')?.touched)"
                            class="text-danger">
                            <div *ngIf="form.get('LastName')?.errors?.['pattern']">
                                Last Name must contain only letters and spaces.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row UserContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Designation<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="Designation"
                            formControlName="Designation" [(ngModel)]="userObject.designation" (keydown)="onKeyDownDesignation($event)"
                            [ngClass]="{'is-invalid': form.get('Designation')?.invalid && (form.get('Designation')?.touched) && form.get('Designation')?.hasError('whitespace')}">
                        <div *ngIf="form.get('Designation')?.invalid && (form.get('Designation')?.touched)"
                            class="text-danger">
                            <div *ngIf="form.get('Designation')?.errors?.['pattern']">
                                Designation must contain only letters and spaces.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Email<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="email" class="form-control f-textbox" placeholder="Email" formControlName="Email"
                            [(ngModel)]="userObject.email" (keypress)="blockInvalidEmailCharacters($event)"
                            [ngClass]="{'is-invalid': form.get('Email')?.invalid && (form.get('Email')?.touched)}">
                        <div *ngIf="form.get('Email')?.invalid && (form.get('Email')?.touched) && form.get('Email')?.errors?.['pattern']"
                            class="text-danger">
                            Please enter a valid Email address.
                        </div>
                    </div>
                </div>
            </div>
            <div class="row UserContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Contact No<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="Contact No"
                            formControlName="ContactNo" minlength="10" maxlength="10" (input)="validateMobileNo($event)"
                            [(ngModel)]="userObject.contactNumber"
                            [ngClass]="{'is-invalid': form.get('ContactNo')?.invalid && (form.get('ContactNo')?.touched)}">
                        <div *ngIf="form.get('ContactNo')?.invalid && (form.get('ContactNo')?.touched)"
                            class="text-danger">
                            <div *ngIf="form.get('ContactNo')?.errors?.['pattern']">
                                Contact No must be exactly 10 digits.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Status<sub class="text-danger">*</sub></label>
                    <app-dropdown-list [data]="dropdownData" [selectedValue]="defaultStatusSelectedValue"
                        [width]="'250px'" textField="name" valueField="id"
                        (valueChange)="onDropdownValueChange($event, 'Status')"
                        [hasError]="!defaultStatusSelectedValue.id && showValidationErrors">
                    </app-dropdown-list>
                </div>
            </div>
            <div class="row UserContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Role<sub class="text-danger">*</sub></label>
                    <app-dropdown-list [data]="roleDropdown" [selectedValue]="defaultRoleSelectedValue"
                        [width]="'250px'" textField="name" valueField="id"
                        (valueChange)="onDropdownValueChange($event, 'RoleDropdown')"
                        [hasError]="defaultRoleSelectedValue.id ==-1 && showValidationErrors">
                    </app-dropdown-list>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Reporting to<sub class="text-danger">*</sub></label>
                    <app-dropdown-list [data]="reportingToDropdown" [selectedValue]="defaultReportingSelectedValue"
                        [width]="'250px'" textField="name" valueField="id"
                        (valueChange)="onDropdownValueChange($event, 'ReportingTo')"
                        [hasError]="defaultReportingSelectedValue.id ==-1 && showValidationErrors">
                    </app-dropdown-list>
                </div>
            </div>
            <div class="row UserContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Address<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <textarea type="text" class="form-control f-textbox" placeholder="Address"
                            formControlName="Address"
                            [ngClass]="{'is-invalid': form.get('Address')?.invalid && (form.get('Address')?.touched) && form.get('Address')?.hasError('whitespace')}">
                        </textarea>
                        <div *ngIf="form.get('Address')?.invalid && (form.get('Address')?.touched)" class="text-danger">
                            <div *ngIf="form.get('Address')?.errors?.['pattern']">
                                Address Name must contain only letters and spaces.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center" *ngIf="showCheckboxList">
                    <label class="f-label me-3">Compliance Type<sub class="text-danger">*</sub></label>
                    <br><br><br>
                    <div class="form-check-group ">
                        <input type="checkbox" class="form-check-input" formControlName='Register' />
                        <label class="form-check-label ">Register</label>
                        <input type="checkbox" class="form-check-input" formControlName='Returns' />
                        <label class="form-check-label ">Returns</label>
                        <input type="checkbox" class="form-check-input" formControlName='Challan' />
                        <label class="form-check-label">Challan</label>
                        <div *ngIf="checkboxReq" class="text-danger">
                            Compliance Type Required
                        </div>

                    </div>
                </div>
            </div>
            <div class="row UserContainer button-div" style=" margin-top:20px;">
                <button type="button" (click)="onSave()" class="figma-btn-blue">
                    Save
                </button>
            </div>
        </form>
    </div>
    <div *ngIf="loading" class="loader-overlay">
        <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
    </div>
</kendo-dialog>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270" [Data]="alertData"
    (close)="closeAlert()"></app-alert-popup>