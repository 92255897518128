<kendo-dialog #dialogRef  title="Create Employee Branch Transfer" (close)="closePopup()" [width]="1000" 
    class="custom-dialog">

    <div class="col-md-12 colpadding0" style=" height: 35px; margin-bottom: 10px;">
        <div class="col-md-6  align-items-center">
            <h4 class="f-label" style="margin-top: 0px; font-weight: 500;">Current Branch Details
            </h4>

        </div>
        <div class="col-md-6 align-items-center">
            <h4 class="f-label" style="margin-top: 0px; font-weight: 500;">Transfer Branch Details</h4>
        </div>
    </div>

    <div class="row col-12 dialog-content">
        <div class="row EmpContainer">
            <div class="col-md-6 d-flex align-items-center">
                <div class="d-flex align-items-center">
                    <label class="f-label me-3">State<sub class="text-danger">*</sub>:</label>
                    <div class="flex-grow-1">
                        <input type="text" disabled class="form-control f-textbox"
                            placeholder="State" [(ngModel)]="CurrentState">
                    </div>
                </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
                <div class="d-flex align-items-center">
                    <label class="f-label me-3" style="margin-left: 8px;">State<sub class="text-danger">*</sub>:</label>
                    <div class="require-container">
                        <app-combo-box [width]="'250px'" [Placeholder]="defaultSelectedValueEB"
                            (valueChange)="onDropdownValueChange($event, 'TransferState')"
                            [data]="TransferStateDropdownData" 
                            [hasError]="!TransferState.value && showValidationErrors">
                        </app-combo-box>
                    </div>
                </div>
            </div>
        </div>

   
        <div class="row EmpContainer">
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label me-3">Location<sub class="text-danger">*</sub>:</label>
                <div class="require-container">
                    <input type="text" disabled class="form-control f-textbox"
                        placeholder="Location" [(ngModel)]="CurrentLocation">
                </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label me-3" style="margin-left: 8px;">Location<sub class="text-danger">*</sub>:</label>
                <div class="require-container">
                    <app-combo-box [width]="'250px'" [Placeholder]="defaultSelectedValueLo"
                        (valueChange)="onDropdownValueChange($event, 'TransferLocation')"
                        [data]="TransferLocationDropdownData"
                        [hasError]="!TransferLocation.value && showValidationErrors">
                    </app-combo-box>
                </div>
            </div>
        </div>
   
        <div class="row EmpContainer">
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label me-3" >Branch<sub class="text-danger">*</sub>:</label>
                <div class="require-container">
                    <input type="text" disabled class="form-control f-textbox"
                        placeholder="Branch" [(ngModel)]="CurrentBranch">
                </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label me-3" style="margin-left: 8px;">Branch<sub class="text-danger">*</sub>:</label>
                <div class="require-container">
                    <app-combo-box [width]="'250px'" [Placeholder]="defaultSelectedValueBr"
                        (valueChange)="onDropdownValueChange($event, 'TransferBranch')"
                        [data]="TransferBranchDropdownData" 
                        [hasError]="!TransferBranch.value && showValidationErrors">
                    </app-combo-box>
                 
                </div>
            </div>
        </div>
 
        <div class="row EmpContainer">
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label me-3">Last Working Date<sub class="text-danger">*</sub>:</label>
                <div class="require-container">
                    <input type="text" disabled="CurrentLastDate" placeholder="DD-MM-YYYY" class="form-control f-textbox"
                        [(ngModel)]="CurrentLastDate">
                </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label me-3" style="margin-left: 8px;">Date Of Joining <sub class="text-danger">*</sub>:</label>
                <div class="require-container">
                    <app-kendo-datepicker class="uniform-size " [(value)]="TransferDateJoining"
                        (valueChange)="onDateChange($event, 'TransferDateJoining')" [format]="'dd-MM-yyyy'"
                        [placeholder]="placeholder" [width]="'250px'" [max]="todayDate"
                        [class.input-invalid]="!TransferDateJoining && showValidationErrors"
                        [hasError]="!TransferDateJoining && showValidationErrors">
                    </app-kendo-datepicker>
                </div>
            </div>
        </div>
    </div>


    <kendo-dialog-actions class="ksave">
        <button kendoButton type="button" (click)="onSubmit()" class="figma-btn-blue">
            Save
        </button>
        <button kendoButton type="button" (click)="closePopup()" class="figma-btn-white">
            Close
        </button>
    </kendo-dialog-actions>
    <div *ngIf="loading" class="loader-overlay">
        <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
    </div>
</kendo-dialog>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270" [Height]="280" [Data]="alertData"
    (close)="closeAlert()"></app-alert-popup>