import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { KendoDatepickerComponent } from '../../../components/kendo-datepicker/kendo-datepicker.component';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { MinimumWageIndustryNotification, MwNotificationService } from '../../../service/mw-notification.service';
import { LocationMasterServiceService } from '../../../service/location-master-service.service';
import { FormsModule } from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';

@Component({
  selector: 'app-notification-industry-add-popup',
  standalone: true,
  imports: [TextBoxModule,ComboBoxComponent,DropdownListComponent,KendoDatepickerComponent,FormsModule,NgIf,DialogModule,AlertPopupComponent,NgClass],
  templateUrl: './notification-industry-add-popup.component.html',
  styleUrl: './notification-industry-add-popup.component.css'
})
export class NotificationIndustryAddPopupComponent {
  public gridData1: GridDataResult = { data: [], total: 0 };
  public selectionType: string | null = null;
  public defaultState = "Select State";
  public defaultIndustry = { text: 'Select Industry Type', value: null };
  public defaultId = { text: 'Select Notification Id', value: null };
  public industryTypes: any[] = [this.defaultIndustry];
  public notificationId: any[] = [this.defaultId];
  public selectedIndustryType = this.defaultIndustry;
  public selectedNotificationId = this.defaultId;
  public allStatesData: any[] = [];
  public selectedState:{id:any,name:any}={id:'',name:''}
  public selectedIndustry: string | null = null;
  public notifiedOn!:Date | null;
  public effectiveFrom!:Date | null;
  public effectiveTo!:Date | null;
  notificationNo: string = '';
  public placeholder: string = 'DD-MM-YYYY';
  public showValidationErrors = false;
  notificationError: string = '';
  todayDate: Date = new Date();
  @Output() close = new EventEmitter<void>();
  @ViewChild('dialogTitle') dialogTitle!: ElementRef;
  alertData = { alertMessage: '', alertVisible: '' }
  showDateError: boolean | null = false;
  showEffectiveFromError: boolean | null = false;
  showEffectiveToError: boolean | null = false;

  constructor(private notificationService: MwNotificationService, private locationMaster: LocationMasterServiceService, private cdr: ChangeDetectorRef) { }

  ngAfterViewInit() {
    const titleElement = this.dialogTitle.nativeElement;
    titleElement.style.textAlign = 'center';
    titleElement.style.width = '100%';
  }

  ngOnInit(): void {

    this.fetchStateMasters();

  }

  validateForm(): boolean {
    const isValid = !!(
      this.notifiedOn &&
      this.effectiveFrom &&
      this.effectiveTo &&
      this.notificationNo &&
      this.selectedState &&
      (this.selectionType === 'I'
        ? this.selectedIndustryType !== this.defaultIndustry && this.selectedNotificationId !== this.defaultId
        : true) &&
      this.effectiveTo > this.effectiveFrom 
    );
  
    this.showValidationErrors = !isValid;
    return isValid;
  }
  onInputChange(event: Event) {
    const input = (event.target as HTMLInputElement).value;
    const validPattern = /^[a-zA-Z0-9()\/\-]*$/;
  
    if (!validPattern.test(input)) {
     
      this.notificationNo = input.replace(/[^a-zA-Z0-9()\/\-]/g, '');
    }
  }
  onDateBlur(): void {
    this.validateDates();
  }
  
  validateDates(): void {
    this.showEffectiveFromError = this.effectiveFrom && 
                                this.notifiedOn &&
                                this.effectiveFrom < this.notifiedOn;

    this.showEffectiveToError = this.effectiveTo && 
                                this.notifiedOn &&
                                this.effectiveTo < this.notifiedOn;

    this.showDateError = this.effectiveTo && 
                         this.effectiveFrom && 
                         this.effectiveTo <= this.effectiveFrom;
  }
  

  isIndustryNotificationNumberDuplicate(notificationNo: string): boolean {
    console.log('Checking for duplicate notification number:', notificationNo);
    
    if (!Array.isArray(this.gridData1.data)) {
        console.error('Grid data is not an array:', this.gridData1.data);
        return false;
    }
    
    console.log('Grid data length:', this.gridData1.data.length);

    const isDuplicate = this.gridData1.data.some(item => {
        console.log('Comparing with:', item.notification_Number); 
        return item.notification_Number === notificationNo;
    });

    return isDuplicate;
}

  validateNotificationNumber(notificationNo: string): void {

    if (this.isIndustryNotificationNumberDuplicate(notificationNo)) {
      this.notificationError = 'Notification number already exists.';
    }
    else {
      this.notificationError = '';
    }
  }
  
  fetchStateMasters(): void {
    console.log('Fetching state masters...');
    this.locationMaster.getStateMaster().subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && Array.isArray(data)) {
            this.allStatesData = data.map((item: any) => ({
              id: item.sM_Code,
              name: item.sM_Name
            }));
            this.defaultState = 'Select State';
            console.log('Updated states data:', this.allStatesData);
          }
          else {
            console.error('Unexpected API response format:', data);
          }
        }, error: (error) => {
          console.error('Error fetching states data', error);
        }
      });
  }


  public closePopup(): void {
    this.alertData = { alertMessage: '', alertVisible: '' };
    this.close.emit();
    this.fetchIndustryData();
  }
  closeAlert() {
    this.alertData = { alertMessage: '', alertVisible: '' };
  }

  DropdownValueChange(value: any) {
    this.selectedState = {id:value.id,name:value.name};
    if (this.selectionType === 'I') {
      this.selectedIndustry = this.selectedIndustryType.value || '';
      console.log(this.selectedIndustry);

      this.fetchIndustryData();
    }
    console.log(value.id);

    this.fetchIndustryTypes(value.id, 'FACT');
    this.fetchNotificationId(value.id);
  }

  fetchIndustryData():Promise<void> {
    debugger;
    return new Promise((resolve, reject) => {
    const industryParam = this.selectedIndustry ?? '';
    console.log(`Selected Industry: ${industryParam}`);

    this.notificationService.getIndustryMasterNotification(industryParam).subscribe(
      {
        next: (response) => {
          const data = response.body;
          const transformedData = data.map((item: any) => ({
            ...item,
            notificationInfo: `[${item.id}]  ${item.notification_Number}`
          }));
          this.gridData1 = {
            data: transformedData,
            total: transformedData.length
          };
          this.cdr.detectChanges();
          resolve();
        }, error: (error) => {
          console.error('Error fetching states data', error);
          reject(error);
        }
      }
    );
  });
  }

  fetchIndustryTypes(state: string, type: string): void {
    this.notificationService.getIndustryType(state, 'FACT').subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && Array.isArray(data)) {
            this.industryTypes = data.map((item: any) => ({
              text: item.industry,
              value: item.industry
            }));
            if (this.industryTypes.length > 0) {
              this.selectedIndustryType = this.industryTypes[0];
            } else {
              this.selectedIndustryType = this.defaultIndustry;
            }
          }
        }, error: (error) => {
  
          console.error('Error fetching industry types:', error);
        },
      }
    );
  }

  fetchNotificationId(state: string): void {
    this.notificationService.getNotificationId(state).subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && Array.isArray(data)) {
                this.notificationId = data.map((item: any) => {
                  const combinedValue = `[${item.id}] ${item.notification_Number}`;
                  return {
                    text: combinedValue,
                    value: item.id
                  };
                });
                if (this.notificationId.length > 0) {
                  this.selectedNotificationId = this.notificationId[0];
                } else {
                  this.selectedNotificationId = this.defaultId;
                }
              }
        }, error: (error) => {
  
          console.error('Error fetching industry types:', error);
        },
      }
    );
  }


  addOrUpdateIndustryNotification() {
    if (!this.validateForm()) {
      return;
    }

    this.fetchIndustryData().then(() => {
      this.validateNotificationNumber(this.notificationNo);
      if (this.notificationError) {
          console.log('Stopping form submission due to notification error:', this.notificationError);
          return;
      }
      const notification: MinimumWageIndustryNotification = {
        id: 0,
        notification_Id: this.selectedNotificationId.value || 0,
        state: this.selectedState.id || '',
        industry: this.selectedIndustryType?.value || '',
        effective_From: this.effectiveFrom ? this.effectiveFrom.toISOString() : '',
        effective_To: this.effectiveTo ? this.effectiveTo.toISOString() : '',
        notification_Number: this.notificationNo,
        notified_On: this.notifiedOn ? this.notifiedOn.toISOString() : '',
        created_On: new Date().toISOString()
      };

      this.notificationService.addOrUpdateIndustryNotification(notification).subscribe(
        {
          next: (response) => {
            this.alertData = { alertMessage: 'Notification saved successfully...', alertVisible: 'success' };
            this.fetchIndustryData();
            this.cdr.detectChanges();
          }, error: (error) => {
            this.alertData = { alertMessage: error.error.message || 'Error saving notification', alertVisible: 'error' };
            console.error('Error saving notification:', error);
            console.error('Error saving notification:', error);
          }
        }
    );
}).catch(error => {
    console.error('Error fetching states data:', error);
});
}

onDateChange(value: Date, type: string) {
  const date = new Date(value);
  if (type === 'notifiedOn') {
    this.notifiedOn = date;
    if (this.effectiveFrom && this.notifiedOn  && this.notifiedOn > this.effectiveFrom) {
      this.alertData = { 
        alertMessage: "'Effective From' should be greater than or equal to 'Notified On' date.", 
        alertVisible: 'error' 
      };
      this.effectiveFrom = null;
    }
  } else if (type === 'effectiveFrom') {
    this.effectiveFrom = date;
    if (this.effectiveFrom && this.notifiedOn && this.effectiveFrom < this.notifiedOn) {
      this.alertData = { 
        alertMessage: "'Effective From' should be greater than or equal to 'Notified On' date.", 
        alertVisible: 'error' 
      };
      this.effectiveFrom = null;
    }
  } else if (type === 'effectiveTo') {
    this.effectiveTo = date;
    if (this.effectiveTo && this.notifiedOn && this.effectiveTo < this.notifiedOn) {
      this.alertData = { 
        alertMessage:  "'Effective To' should be greater than or equal to 'Notified On' date.", 
        alertVisible: 'error' 
      };
      this.effectiveTo = null;
    }
  }
}

  onIdChange(value: any) {
    this.selectedNotificationId = value;
    console.log('Selected Notification ID:', this.selectedNotificationId);
  }

  onIndustryChange(value: any) {
    this.selectedIndustryType = value;
    console.log('Selected Industry:', this.selectedIndustryType);
  }

  public parentColumns1: any[] = [
    { field: 'sM_Name', title: 'State' },
    { field: 'notificationInfo', title: 'Notification Id' },
    { field: 'industry', title: 'Industry Type' },
    { field: 'effective_From', title: 'Effective From' },
    { field: 'effective_To', title: 'Effective To' },
    { field: 'notification_Number', title: 'Notification Number' },
    { field: 'notified_On', title: 'Notification On' },

    {
      field: 'Action',
      isIconColumn: true,
      title: 'Action',
      filteredIcons: [

        {
          url: '../assets/vectors/delete.svg',
          alt: 'Delete Icon',
          action: 'delete'
        }
      ]
    }
  ];
}
