import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { NgIf } from '@angular/common';
import { UserService } from '../../../service/user.service';
import { PremiseMasterService } from '../../../service/premise-master.service';
import { IndicatorsModule, LoaderComponent } from '@progress/kendo-angular-indicators';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-premise-address-mapping-popup',
  standalone: true,
  imports: [DialogModule, AlertPopupComponent, NgIf, IndicatorsModule],
  templateUrl: './premise-address-mapping-popup.component.html',
  styleUrl: './premise-address-mapping-popup.component.css'
})
export class PremiseAddressMappingPopupComponent {
  @Output() close = new EventEmitter<void>();
  selectedFile?: File;
  dropzoneMessage: string = "Drag your files here or ";
  alertData = { alertMessage: '', alertVisible: '' }
  @ViewChild('dialogTitle') dialogTitle!: ElementRef;
  loading = false;

  constructor(private user: UserService, private premiseMasterService: PremiseMasterService) { }

  ngAfterViewInit() {
    const titleElement = this.dialogTitle.nativeElement;
    titleElement.style.textAlign = 'center';
    titleElement.style.width = '100%';
  }


  // public onFileSelect(event: Event): void {
  //   const input = event.target as HTMLInputElement;
  //   if (input.files?.length) {
  //     this.selectedFile = input.files[0];
  //     console.log('Selected file:', this.selectedFile);
  //   }
  // }
  public onFileSelect(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input.files?.length) {
      const selectedFile = input.files[0];
      const fileName = selectedFile.name;
      const fileExtension = fileName.split('.').pop()?.toLowerCase();
      if (fileExtension !== 'xlsx') {
        this.alertData = {
          alertMessage: 'Please upload XLSX file only.',
          alertVisible: 'error'
        };
        this.selectedFile = undefined;
        console.warn('Invalid file type selected');
        return;
      }
      if (selectedFile.size === 0) {
        this.alertData = {
          alertMessage: 'Empty file uploaded!',
          alertVisible: 'error'
        };
        this.selectedFile = undefined;
        console.warn('Empty file selected');
        return;
      }
      this.selectedFile = selectedFile;
      console.log('Selected file:', this.selectedFile);
    }
  }

  public onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drop the file here";
  }

  public onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drag your files here or ";
  }

  public onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      this.selectedFile = event.dataTransfer.files[0];
      event.dataTransfer.clearData();
    }
  }

  uploadFiles() {
    if (this.selectedFile) {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target?.result as ArrayBuffer;
            const workbook = XLSX.read(new Uint8Array(data), { type: 'array' });
            const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 }) as unknown[];
            if (jsonData.length === 0 || (Array.isArray(jsonData[0]) && jsonData[0].length === 0)) {
                this.selectedFile = undefined;
                console.error('Empty file uploaded!');
                this.alertData = {
                    alertMessage: 'Empty file uploaded!',
                    alertVisible: 'error'
                };
                return;
            }
            const entityId = this.user.getClientId();
            const userId = this.user.getUserId();
            console.log(this.selectedFile,entityId,userId);
            
            this.premiseMasterService.uploadAuthorityAddress(this.selectedFile as File, entityId, userId).subscribe({
                next: (response) => {
                    const data = response.body;
                    if (data.status === 'Invalid headers') {
                      this.selectedFile = undefined;
                      console.error('Invalid headers in the response:', response);
                      this.alertData = { alertMessage: 'Invalid Template.', alertVisible: 'error' };
                    } else if (response.status === 200 && data.status === 'error') {
                      this.selectedFile = undefined;
                      this.downloadErrorFile(response.body.filePath, response?.body.sasToken);
                      this.alertData = {
                        alertMessage: 'Invalid Template.',
                        alertVisible: 'error'
                      };
                    } else if (response.status === 200 && data.status === 'SheetNameError') {
                      this.selectedFile = undefined;
                     
                      this.alertData = {
                        alertMessage: data.status,
                        alertVisible: 'error'
                      };
                    } else if (response.status === 200 && data.status === 'Please Upload File with some data') {
                      this.selectedFile = undefined;
                     
                      this.alertData = {
                        alertMessage: data.status,
                        alertVisible: 'error'
                      };
                    } else {
                      console.log('Upload response:', response);
                      this.alertData = { alertMessage: 'File uploaded successfully', alertVisible: 'success' };
                    }
                },
                error: (error) => {
                    console.log('error', error);
                    this.alertData = {
                        alertMessage: 'Error while uploading the file. Please check error file.',
                        alertVisible: 'error'
                    };
                }
            });
        };
        reader.readAsArrayBuffer(this.selectedFile);
    } else {
        this.alertData = {
            alertMessage: 'You have not chosen any file.',
            alertVisible: 'error'
        };
        console.warn('You have not chosen any file.');
    }
}

  downloadErrorFile(FilePath: string, sasToken: string) {
    const blobUrl = `${FilePath}?${sasToken}`;
    const a = document.createElement('a');
    a.href = blobUrl;
    const filename = FilePath.split('/').pop() || 'downloaded_file';
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  public closePopup(): void {
    this.close.emit();
  }

  closeAlertPopup(){
    this.alertData = {alertMessage: '',alertVisible: ''};
  }

}
