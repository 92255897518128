import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { AuthorityAddressServiceService } from '../../../service/authority-address-service.service';
import { UserService } from '../../../service/user.service';
import { CommonModule, NgIf } from '@angular/common';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { FormsModule } from '@angular/forms';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';

@Component({
  selector: 'app-authority-upload-popup',
  standalone: true,
  imports: [NgIf, GridActionItemComponent, CommonModule, FormsModule, DialogModule, AlertPopupComponent],
  templateUrl: './authority-upload-popup.component.html',
  styleUrls: ['./authority-upload-popup.component.css']
})
export class AuthorityUploadPopupComponent {
  @Output() close = new EventEmitter<void>();
  selectedFile?: File|'';
  dropzoneMessage: string = "Drag your files here or ";
  
  UserID: any = 0;
  CustID: any = 0;
  alertData = { alertMessage: '', alertVisible: '' };

  constructor(private authorityService: AuthorityAddressServiceService, private userService: UserService) {}

  @ViewChild('dialogTitle') dialogTitle!: ElementRef;
  ngAfterViewInit() {
    const titleElement = this.dialogTitle.nativeElement;
    titleElement.style.textAlign = 'center';
    titleElement.style.width = '100%';
  }

  ngOnInit(): void {
    this.UserID = this.userService.getUserId();
    this.CustID = this.userService.getCustomerId();
  }

  handleButtonClick(event: { dataItem: any, button: any }): void {
    console.log(event);
    this.userService.setCustomerId(event.dataItem.customerId);
  }

  public closePopup(): void {
    this.close.emit();
  }
  public closeAlertPopup(): void {
    this.alertData.alertVisible = ''; 
  }

  public onFileSelect(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
      const file = input.files[0];
      if (this.validateFile(file)) {
        this.selectedFile = file;
        console.log('Selected file:', this.selectedFile);
      } else {
        this.alertData = { alertMessage: 'Invalid file type. Only Excel files are allowed.', alertVisible: 'error' };
      }
    }
  }

  public onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drop your file here or ";
  }

  public onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drag your files here or ";
  }

  public onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      if (this.validateFile(file)) {
        this.selectedFile = file;
        console.log('Selected file:', this.selectedFile);
      } else {
        this.alertData = { alertMessage: 'Invalid file type. Only Excel files are allowed.', alertVisible: 'error' };
      }
      event.dataTransfer.clearData();
    }
  }

  public uploadFiles(): void {
    if (this.selectedFile) {      
    const fileName = this.selectedFile.name;   
    const extensions = fileName.split('.');    
    if (extensions.length > 2) {
      this.selectedFile=undefined;
      this.alertData = { alertMessage: 'Please upload files with a single extension', alertVisible: 'error' };
      return; 
    }
      this.authorityService.uploadFile(this.selectedFile, this.CustID, this.UserID).subscribe({
        next: (response) => {
        console.log('response',response);
          if(response.status==='success'){
            this.alertData = { alertMessage: 'File Uploaded Successfully', alertVisible: 'success' };
          }
          else if(response.status==='error'){
            this.selectedFile=undefined;
            this.downloadErrorFile(response.filePath, response?.sasToken);
            this.selectedFile=''
          }
          else{
            this.selectedFile=undefined;
            this.alertData = { alertMessage: response.status, alertVisible: 'error' };
          }
        },
        error: (error) => {
          this.selectedFile=undefined;
          this.alertData = { alertMessage: error.error.Result, alertVisible: 'error' };
          console.log('error',error);
          
        }
      });
    } else {
      this.alertData = { alertMessage: 'No file selected', alertVisible: 'error' };
    }
  }

  downloadErrorFile(FilePath: string, sasToken: string) {
    debugger;
    const blobUrl = `${FilePath}?${sasToken}`;
    const a = document.createElement('a');
    a.href = blobUrl;
    const filename = FilePath.split('/').pop() || 'downloaded_file';
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  private validateFile(file: File): boolean {
    const allowedExtensions = ['xls', 'xlsx', 'csv'];
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    return fileExtension ? allowedExtensions.includes(fileExtension) : false;
  }
}
