import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DesignationMasterService } from '../../../service/designation-master.service';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { FormsModule } from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { BlobService } from '../../../service/blob.service';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-designation-bulk-upload',
  standalone: true,
  imports: [DialogModule, FormsModule, NgIf, NgClass, AlertPopupComponent,IndicatorsModule],
  templateUrl: './designation-bulk-upload.component.html',
  styleUrls: ['./designation-bulk-upload.component.css']
})
export class DesignationBulkUploadComponent {
  file: File | null = null;
  dropzoneMessage: string = "Drag your files here or ";
  @Output() close = new EventEmitter<void>();
  selectedFile?: File;
  designationOption: string = 'add';
  alertData = { alertMessage: '', alertVisible: '' };
  loading=false;
  // @Input() dialogTitles: string = 'Designation Bulk Upload'; 
  constructor(private designationMasterService: DesignationMasterService,private blobService: BlobService) {}

  @ViewChild('dialogTitle') dialogTitle!: ElementRef;
  ngAfterViewInit() {
    const titleElement = this.dialogTitle.nativeElement;
    titleElement.style.textAlign = 'center';
    titleElement.style.width = '100%';
  }

  public onFileSelect(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
      const file = input.files[0];
      if (this.validateFile(file)) {
        this.selectedFile = file;
      } else {
        this.alertData = { alertMessage: 'Please select .xlsx file only.', alertVisible: 'error' };
      }
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drop your file here or";
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drag your files here or ";
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      if (this.validateFile(file)) {
        this.file = file;
        this.selectedFile = this.file;
      } else {
        this.alertData = { alertMessage: 'Please select .xlsx file only.', alertVisible: 'error' };
      }
      event.dataTransfer.clearData();
    }
  }

  public uploadFiles(): void {
    if (this.selectedFile) {
      this.loading = true; 
      this.designationMasterService.uploadFile(this.selectedFile, this.designationOption).subscribe({
        next: (response) => {
          if(response.body.result=='error'){
            this.selectedFile=undefined;
            const data = response.body;
            const blobLink = data.filePath;
            const blobName = this.blobService.extractBlobName(blobLink);
            setTimeout(() => {
              this.blobService.getSasToken(blobName).subscribe(
                (tokenResponse: any) => {
                  try {
                    this.selectedFile=undefined;
                    const sasToken = tokenResponse.result;
                    this.blobService.downloadFile(blobName, sasToken);
                    this.loading = false;
                  } catch (error) {
                    this.alertData={alertMessage:'File Not Found',alertVisible:'error'};
                    this.loading = false;
                    this.selectedFile=undefined;
                  }
                },
                (error) => {
                  this.selectedFile=undefined;
                  this.alertData={alertMessage:'File Not Found',alertVisible:'error'};
                  this.loading = false; 
                }
              );
            }, 5000);
          }else if (response.body.result=='success'){
            this.loading = false; 
            this.alertData = { alertMessage: 'File Uploaded Successfully', alertVisible: 'success' };
          }
        },
        error: (error) => {
          this.selectedFile=undefined;
          this.loading = false; 
          this.alertData = { alertMessage: 'Invalid Template File!', alertVisible: 'error' };
        }
      });
    } else {
      this.alertData = { alertMessage: 'No File Selected', alertVisible: 'error' }; 
    }
  }

  private validateFile(file: File): boolean {
    const allowedExtensions = ['xls', 'xlsx', 'csv'];
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    return fileExtension ? allowedExtensions.includes(fileExtension) : false;
  }

  closeAlert(){
    if(this.alertData.alertVisible=='success'){
      this.closePopup();
    }
    this.alertData = { alertMessage: '', alertVisible: '' };
  }

  public closePopup(): void {
    this.close.emit();
  }
}
