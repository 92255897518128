import {
  Component,
  ViewChild,
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectorRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { NgClass, NgIf, CommonModule, NgFor } from '@angular/common';
import {
  DropDownsModule,
  MultiSelectComponent,
  MultiSelectModule,
} from '@progress/kendo-angular-dropdowns';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { LabelModule } from '@progress/kendo-angular-label';
import { GridModule, PageChangeEvent } from '@progress/kendo-angular-grid';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { WorkspaceService } from '../../../service/workspace.service';
import { UserService } from '../../../service/user.service';
import { CommonService } from '../../../service/common.service';
import { UserBranchMappingService } from '../../../service/user-branch-mapping.service';
import { InputsModule } from '@progress/kendo-angular-inputs';

import { TreeGridComponent } from '../../../components/tree-grid/tree-grid.component';
import moment from 'moment';
import { StatutoryService } from '../../../service/statutory.service';
import { LazyTreeListComponent } from '../../../components/lazy-tree-list/lazy-tree-list.component';
// import { EmployeeTreeListComponent } from '../../../components/employee-tree-list/employee-tree-list.component';
// import { Employee } from '../../../employees';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ClraAssignmentReportService } from '../../../service/clra-assignment-report.service';
import { Router } from '@angular/router';
type Branch = {
  id: number;
  name: string;
  parentID: number | null;
  branchType: string;
  branchStatus: string;
  fullName: string | null;
  children?: Branch[];
};

type clraBranch = {
  parentID: number | null;
  id: number;
  name: string | null;
  level: number;
  type: string | null;
  children: clraBranch[];
};

export interface Item {
  id: number;
  name: string;
  hasChildren: boolean;
  children?: Item[];
}

interface ComplianceItem {
  id: number;
  parentID?: number; // Optional for the parent-child relationship
  state?: string; // Optional
  location?: string; // Optional
  compid?: number; // Optional
  description?: string; // Optional
  duedate?: string; // Optional
  period?: string; // Optional
  status?: string; // Optional
  children?: ComplianceItem[]; // Recursive type for children
}

@Component({
  selector: 'app-my-compliance-document',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    NgFor,
    InputsModule,
    TreeGridComponent,
    DropdownListComponent,
    NgClass,
    MultiSelectModule,
    FormsModule,
    TreeViewModule,
    LabelModule,
    DropDownsModule,
    GridModule,
    NgIf,
    IndicatorsModule,
    LazyTreeListComponent
  ],
  templateUrl: './my-compliance-document.component.html',
  styleUrl: './my-compliance-document.component.css',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MyComplianceDocumentComponent {
  form!: FormGroup;
  public rootData: Observable<any[]> | undefined;
  private serviceUrl =
    "https://demos.telerik.com/kendo-ui/service/EmployeeDirectory";
  challanGridData: any[] = [];
  public treeData: Item[] = [];
  registerGridData: any[] = [];
  returnGridData: any[] = [];
  take = 10;
  skip = 0;
  page = 1;
  pageSize = 10;
  allSelected: boolean = false;
  complianceType: { text: any; value: any }[] = [];
  complianceStatus: { text: any; value: any }[] = [];
  frequency: { text: any; value: any }[] = [];
  public treelistData: Item[] = [];
  Act: { text: any; value: any }[] = [];
  years: any[] = [];
  period: any[] = [];
  files: any[] = [];
  entity: any[] = [];
  selectedComplianceType: { text: any; value: any } = {
    text: 'Compliance',
    value: 'Compliance',
  };
  selectedStatus: { text: any; value: any } = {
    text: 'Compliance Status',
    value: 'Compliance Status',
  };
  selectedFrequency: { text: any; value: any } = {
    text: 'Frequency',
    value: 'Frequency',
  };
  selectedAct: { text: any; value: any } = {
    text: 'Selected Act',
    value: 'Selected Act',
  };
  selectedYear: { text: any; value: any } = { text: 'Year', value: 'Year' };
  selectedPeriod: { text: any; value: any } = {
    text: 'Period',
    value: 'Period',
  };
  selectedFiles: any;
  selectAll: boolean = false;
  type: any;
  frequencyType!: any;
  public clraBranchDropdown: clraBranch[] = [];
  public BranchDropdown: Branch[] = [];
  public selectedBranch: any;
  IOJsonId: any;
  selectedItems: any[] = [];
  loading = false;
  popupSettings: any = { width: '280px' };
  radioButtonValue = [
    { text: 'State', value: 'State' },
    { text: 'Branch', value: 'Branch' },
    { text: 'Compliance', value: 'Compliance' },
  ];
  public selectedValue = 'State';
  isFieldDisabled: boolean = false;
  public firstRow: boolean = false;
  isactive: boolean = false;
  payload:any
  public gridData: any[] = [];
  public filteredGridData: any[] = [];
  entityPlaceholder = 'Entity/Sub-Entity/Location';
  filePlaceholder = 'All Files';;
  @ViewChild('multiSelect', { static: false })
  multiSelect!: MultiSelectComponent;
  parentColumns:any;
  isChallan : boolean = false
  constructor(
    private workspace: WorkspaceService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private user: UserService,
    private common: CommonService,
    private userBranch: UserBranchMappingService,
    private statutory: StatutoryService,
    private http: HttpClient,
    private router: Router,
  ) { }

  ngOnInit(): void {
    debugger;
    this.getComplianceType();
    this.getYears();
    //this.rootData = this.query();
    // this.treeData = [
    //   { id: 1, name: 'Parent 1', hasChildren: true, children: [] },
    //   { id: 2, name: 'Parent 2', hasChildren: true, children: [] },
    //   { id: 3, name: 'Parent 3', hasChildren: false },
    // ];
    this.type = 'Months';
    this.getPeriods();
    this.getComplianceStatus()
    this.form = this.fb.group({
      compliance: ['', Validators.required],
      frequency: [''],
      act: ['', Validators.required],
      year: ['', Validators.required],
      period: ['', Validators.required],
      files: [''],
      entity: [''],
      status:['']
    });
    this.checkDisableField();
    sessionStorage.setItem('selected', this.selectedValue);
    this.onValueChange(this.selectedValue)
  }

  public pageChange(event: PageChangeEvent): void {
    console.log('Page Change Event Received:', event);
    this.skip = event.skip;
    this.pageSize = event.take;
  }
  // toggleSelectAll(event: Event, type: string) {
  //   const checked = (event.target as HTMLInputElement).checked;
  //   if (type === 'Register') {
  //     this.registerGridData.forEach((item) => (item.selected = checked));
  //     this.updateSelectedItems('Register');
  //   } else if (type === 'Return') {
  //     this.returnGridData.forEach((item) => (item.selected = checked));
  //     this.updateSelectedItems('Return');
  //   } else if (type === 'Challan') {
  //     this.challanGridData.forEach((item) => (item.selected = checked));
  //     this.updateSelectedItems('Challan');
  //   }
  // }
  // onItemSelectionChange(dataItem: any, type: string) {
  //   dataItem.selected = !dataItem.selected;
  //   if (type === 'Register') this.updateSelectedItems('Register');
  //   else if (type === 'Return') this.updateSelectedItems('Return');
  //   else if (type === 'Challan') this.updateSelectedItems('Challan');
  // }

  // updateSelectedItems(type: string) {
  //   if (type === 'Register') {
  //     this.selectedItems = this.registerGridData.filter(
  //       (item) => item.selected
  //     );
  //   } else if (type === 'Return') {
  //     this.selectedItems = this.returnGridData.filter((item) => item.selected);
  //   } else if (type === 'Challan') {
  //     this.selectedItems = this.challanGridData.filter((item) => item.selected);
  //   }
  // }

  calculateMaxTextLength(data: any[]): number {
    let maxLength = 0;

    const findMaxLength = (items: any[]) => {
      items.forEach((item) => {
        maxLength = Math.max(maxLength, item.name.length);
      });
    };

    findMaxLength(data);
    return maxLength;
  }

  getComplianceType() {
    const type = 'ComplianceName';
    this.workspace.getcompliance(type).subscribe({
      next: (response) => {
        const data = response.body;
        console.log('Compliance Type', data);
        this.complianceType = data.map((item: any) => ({
          text: item.text,
          value: item.value,
        }));
      },
    });
  }

  public onComplianceTypeChange(value: any) {
    this.selectedComplianceType = { text: value.text, value: value.value };
    console.log('selected compliance', this.selectedComplianceType.value);
    this.selectedComplianceType = { text: value.text, value: value.value };
    this.selectedFrequency = { text: 'Frequency', value: 'Frequency' };
    this.selectedAct = { text: 'Select Act', value: 'Select Act' };
    this.selectedYear = { text: 'Year', value: 'Year' };
    this.selectedPeriod = { text: 'Period', value: 'Period' };
    this.selectedStatus= {text: 'Compliance Status',value: 'Compliance Status'};
    this.selectedItems = [];
    this.filePlaceholder = 'All Files';
    this.selectedFiles = '';
    this.entityPlaceholder = 'Entity/Sub-Entity/Location';
    this.selectedBranch = '';
    this.gridData = []
    if (this.selectedComplianceType.value === 'Return') {
      this.getFrequency();
    } else {
      this.frequency = [];
    }
    if (
      this.selectedComplianceType.value === 'Register' ||
      this.selectedComplianceType.value === 'Challan'
    ) {
      this.type = 'Months';
      this.getPeriods();
    } else if (this.selectedComplianceType.value === 'Return') {
      this.period = [];
    }
    this.getActDropdown();
    this.getFiles();
    this.getComplianceStatus()  
    if (
      this.selectedComplianceType.value === 'Return' ||
      this.selectedComplianceType.value === 'Challan'
    ) {
      this.getEntityDropdown();
    }
  }
  getFrequency() {
    const type = 'Frequency';
    this.workspace.getcompliance(type).subscribe({
      next: (response) => {
        const data = response.body;
        console.log('frequency Type', data);
        this.frequency = data.map((item: any) => ({
          text: item.text,
          value: item.value,
        }));
      },
    });
  }

  onFrequencyTypeChange(value: any) {
    this.selectedFrequency = { text: value.text, value: value.value };

    if (this.selectedFrequency.value === 'Annual') {
      this.type = 'SubFrequencyA';
      this.getPeriods();
    } else if (this.selectedFrequency.value === 'HalfYearly') {
      this.type = 'SubFrequencyH';
      this.getPeriods();
    } else if (this.selectedFrequency.value === 'Monthly') {
      this.type = 'Months';
      this.getPeriods();
    } else if (this.selectedFrequency.value === 'Quarterly') {
      this.type = 'SubFrequencyQ';
      this.getPeriods();
    }
    this.getActDropdown();
    this.getComplianceStatus();
  }

  getActDropdown() {
    const type = this.selectedComplianceType.value;
    if (type === 'Register' || type === 'Challan') {
      this.frequencyType = 'Monthly';
    } else {
      this.frequencyType = this.selectedFrequency.text;
    }

    this.workspace.getActs(type, this.frequencyType).subscribe({
      next: (response) => {
        const data = response.body;
        console.log('Act dropdown', data);
        this.Act = data.map((item: any) => ({
          text: item.text,
          value: item.value,
        }));
      },
    });
  }

  onActTypeChange(value: any) {
    this.selectedAct = { text: value.text, value: value.value };
    console.log(this.selectedAct.value);

    if (this.selectedComplianceType.value === 'Register') {
      this.getEntityDropdown();
    }
  }

  getYears(): void {
    const currentYear = new Date().getFullYear();
    this.years = [];
    for (let year = currentYear; year >= 2017; year--) {
      this.years.push({ text: year.toString(), value: year });
    }
  }

  onYearChange(value: any): void {
    this.selectedYear = { text: value.text, value: value.value };
  }

  getPeriods(): void {
    this.workspace.getcompliance(this.type).subscribe({
      next: (response) => {
        const data = response.body;
        console.log('Period', data);
        this.period = data.map((item: any) => ({
          text: item.text,
          value: item.value,
        }));
      },
    });
  }

  onPeriodChange(value: any): void {
    this.selectedPeriod = { text: value.text, value: value.value };
  }

  public tagMapper(tags: any[]): any[] {
    return tags.length < 3 ? tags : [tags];
  }

  buildHierarchy(data: Branch[]): Branch[] {
    const grouped: { [key: number]: Branch[] } = data.reduce((acc, item) => {
      (acc[item.parentID || -1] = acc[item.parentID || -1] || []).push(item);
      return acc;
    }, {} as { [key: number]: Branch[] });

    function buildTree(parentID: number | null): Branch[] {
      return (grouped[parentID || -1] || []).map((item) => ({
        ...item,
        children: buildTree(item.id),
      }));
    }

    return buildTree(null);
  }

  getFiles() {
    const entityId = this.user.getClientId();
    const complianceType = this.selectedComplianceType.value;

    this.workspace.getAllFiles('I', complianceType, entityId).subscribe({
      next: (response) => {
        const data = response.body;
        console.log('file response', data);

        this.files = this.buildHierarchy(
          data.map((item: any) => ({
            id: item.id,
            name: item.name,
          }))
        );
        const maxLength = this.calculateMaxTextLength(this.files);

        const calculatedWidth = Math.max(280, maxLength * 10);
        this.popupSettings = { width: `${calculatedWidth}px` };
      },
      error: (err) => {
        console.error('Error fetching Files', err);
      },
    });
  }
  onFileChange(value: any) {
    this.selectedFiles = value.map((file: any) => file.id).join(',');
    console.log('Selected files:', this.selectedFiles);
    //this.getJsonId()
  }

  getEntityDropdown() {
    const entityId = this.user.getClientId();

    if (
      this.selectedComplianceType.value === 'Return' ||
      this.selectedComplianceType.value === 'Challan'
    ) {
      this.userBranch.getBranchDropdown(entityId).subscribe({
        next: (response: any) => {
          console.log('branch dropdown data', response);
          const data = response.body;
          if (data && Array.isArray(data)) {
            this.BranchDropdown = this.buildHierarchy(data);
            const maxLength = this.calculateMaxTextLength(this.BranchDropdown);

            const calculatedWidth = Math.max(280, maxLength * 10);
            this.popupSettings = { width: `${calculatedWidth}px` };
          } else {
            console.error('Unexpected data format', data);
          }
        },
      });
    }

    if (this.selectedComplianceType.value === 'Register') {
      if (
        this.selectedAct.value === 'SEA' ||
        this.selectedAct.value === 'FACT'
      ) {
        this.userBranch.getBranchDropdown(entityId).subscribe({
          next: (response: any) => {
            console.log('branch dropdown data', response);
            const data = response.body;
            if (data && Array.isArray(data)) {
              this.BranchDropdown = this.buildHierarchy(data);
              const maxLength = this.calculateMaxTextLength(
                this.BranchDropdown
              );

              const calculatedWidth = Math.max(280, maxLength * 10);
              this.popupSettings = { width: `${calculatedWidth}px` };
            } else {
              console.error('Unexpected data format', data);
            }
          },
        });
      } else if (
        this.selectedAct.value === 'FCLRA' ||
        this.selectedAct.value === 'SCLRA'
      ) {
        this.common.getClraType(entityId).subscribe({
          next: (response: any) => {
            if (response.body.result === 'CAC') {
              this.userBranch.getCLRABranchDropdown(entityId).subscribe({
                next: (response: any) => {
                  console.log('SCLRA & FCLRA ', response);
                  const data = response.body;
                  if (data && Array.isArray(data)) {
                    this.clraBranchDropdown = this.mapHierarchy(data);
                    const maxLength = this.calculateMaxTextLengthclra(
                      this.clraBranchDropdown
                    );

                    const calculatedWidth = Math.max(280, maxLength * 10);
                    this.popupSettings = { width: `${calculatedWidth}px` };
                  } else {
                    console.error('Unexpected data format', data);
                  }
                },
              });
            } else if (response.body.result === 'CAP') {
              this.userBranch.getCLRA_PEBranchDropdown(entityId).subscribe({
                next: (response: any) => {
                  console.log('SCLRA & FCLRA ', response);
                  const data = response.body;
                  if (data && Array.isArray(data)) {
                    this.clraBranchDropdown = this.mapHierarchy(data);
                    const maxLength = this.calculateMaxTextLengthclra(
                      this.clraBranchDropdown
                    );

                    const calculatedWidth = Math.max(280, maxLength * 10);
                    this.popupSettings = { width: `${calculatedWidth}px` };
                  } else {
                    console.error('Unexpected data format', data);
                  }
                },
              });
            }
          },
        });
      }
    }
  }

  onEntityChange(value: any) {
    this.selectedBranch = value.map((branch: any) => branch.id).join(',');
    console.log('Selected branch:', this.selectedBranch);
    this.getJsonId();
  }
  calculateMaxTextLengthclra(data: clraBranch[]): number {
    let maxLength = 0;

    const findMaxLength = (items: clraBranch[]) => {
      items.forEach((item) => {
        const nameLength = item.name ? item.name.length : 0;
        maxLength = Math.max(maxLength, nameLength);
        if (item.children && item.children.length) {
          findMaxLength(item.children);
        }
      });
    };
    findMaxLength(data);
    return maxLength;
  }
  private mapHierarchy(items: any[]): clraBranch[] {
    return items.map((item: any) => {
      const hasChildren = item.children && item.children.length > 0;
      return {
        parentID: item.parentID,
        id: item.id,
        name: item.name || 'Unknown',
        level: item.level,
        type: item.type,
        children: hasChildren ? this.mapHierarchy(item.children) : [],
      };
    });
  }

  clraTagMapper(tags: any[]): any[] {
    return tags.map((tag) => ({
      text: tag.name,
      id: tag.id,
    }));
  }
  getJsonId() {
    const commaSeparatedFiles = this.selectedFiles;
    const commaSeparatedBranch = this.selectedBranch || '';
    const ParameterId = `[{"InputID":"${commaSeparatedFiles}","BranchID":"${commaSeparatedBranch}"}]`;

    console.log('ParameterId:', ParameterId);

    this.workspace.getJsonId([], ParameterId).subscribe({
      next: (response) => {
        this.IOJsonId = response.body.result;
        console.log('Json Id', this.IOJsonId);
      },
    });
  }

  getStateGrid() {
    this.payload = {
      userID: this.user.getUserId(),
      customerID: this.user.getCustomerId(),
      monthId: this.selectedPeriod.value,
      staOrInt: 'SAT',
      staIntFlag: 5,
      fy: '',
      profileID: '',
      docType: this.selectedComplianceType.value,
      challanType: this.selectedAct.value,
      month: this.selectedPeriod.value,
      year: this.selectedYear.value,
      entityID: this.user.getClientId(),
      actType: this.selectedAct.value,
      frequency: this.selectedFrequency.value,
      branchId: null,
      state: '',
    };
    console.log('register', this.payload);
    this.loading = true;
    this.statutory.getStateGrid(this.payload).subscribe({
      next: (response: any) => {
        let data = response.body;
        console.log('Register Grid', data);
        this.gridData = data;
      console.log('checkpay',this.payload);
        this.cdr.detectChanges();
        this.loading = false;
        this.isactive = true;
      },
    });
  }

  getBranchGrid() {
    this.payload = {
      userID: this.user.getUserId(),
      customerID: this.user.getCustomerId(),
      monthId: this.selectedPeriod.value,
      staOrInt: 'SAT',
      staIntFlag: 5,
      fy: '',
      profileID: '',
      docType: this.selectedComplianceType.value,
      challanType: this.selectedAct.value,
      month: this.selectedPeriod.value,
      year: this.selectedYear.value,
      entityID: this.user.getClientId(),
      actType: this.selectedAct.value,
      frequency: this.selectedFrequency.value,
      branchId: this.gridData[0]?.branchSrID ? this.gridData[0]?.branchSrID :null,
      state: this.gridData[0]?.cM_State ? this.gridData[0]?.cM_State :'',
    };
    this.loading = true;
    this.statutory.getBranchGrid(this.payload).subscribe({
      next: (response: any) => {
        const data = response.body;
        console.log('Return Grid', data);
        this.gridData = data;
        this.loading = false;
      },
    });
  }

  getComplianceGrid() {
    this.payload = {
      userID: this.user.getUserId(),
      customerID: this.user.getCustomerId(),
      monthId: this.selectedPeriod.value,
      staOrInt: 'SAT',
      staIntFlag: 5,
      fy: '',
      profileID: '',
      docType: this.selectedComplianceType.value,
      challanType: this.selectedAct.value,
      month: this.selectedPeriod.value,
      year: this.selectedYear.value,
      entityID: this.user.getClientId(),
      actType: this.selectedAct.value,
      frequency: this.selectedFrequency.value,
      branchId: null,
      state: '',
    };
    this.loading = true;
    this.statutory.getComplianceStatutory(this.payload).subscribe({
      next: (response: any) => {
        const data = response.body;
        console.log('data', data);
        this.gridData = data;
        this.loading = false;
      },
    });
  }

  getComplianceChallan() {
    this.payload = {
      userID: this.user.getUserId(),
      customerID: this.user.getCustomerId(),
      monthId: this.selectedPeriod.value,
      staOrInt: 'SAT',
      staIntFlag: 5,
      fy: '',
      profileID: '',
      docType: this.selectedComplianceType.value,
      challanType: this.selectedAct.value,
      month: this.selectedPeriod.value,
      year: this.selectedYear.value,
      entityID: this.user.getClientId(),
      actType: this.selectedAct.value,
    };
    this.loading = true;
    this.statutory.getComplianceChallan(this.payload).subscribe({
      next: (response: any) => {
        const data = response.body;
        console.log('Challan Grid', data);
        this.gridData = data;
        this.loading = false;
      },
    });
  }
  onActionClick(event: { dataItem: any; action: string }) {
    console.log('Action clicked for item:', event);
  }
  applyFilter() {
    // if (this.form.invalid) {
    //   this.form.markAllAsTouched();
    //   return;
    // }
    this.payload = {}
    console.log('selected valueeee', this.selectedValue);
    if (this.selectedValue === 'State' ){
      if(this.selectedComplianceType.value === 'Challan'){
    this.Challan()
      }
      else{
        this.parentColumns = []
      this.parentColumns= [

        { field: 'cM_State', title: 'State', width: '80px', expandable: this.isChallan }, // Reduce width
        { field: 'branch', title: 'Location', width: '50px' }, // Reduce width
        { field: 'complianceID', title: 'Comp.Id', width: '30px' }, // Reduce width
        { field: 'shortDescription', title: 'Description', width: '120px' }, // Reduce width
        {
          field: 'scheduledOn',
          title: 'Due Date',
          width: '50px', // Adjust the width
          cellTemplate: (dataItem: any) => {
            return this.formatDate(dataItem.scheduledOn); // Format Date
          },
        },
        { field: 'forMonth', title: 'Period', width: '50px' }, // Reduce width
        { field: 'status', title: 'Status', width: '50px' }, // Adjust the width
        {
          field: 'action',
          title: 'Action',
          width: '50px',
          cellTemplate: (dataItem: any) => {
            return this.getActionTemplate(dataItem);;
          },class:'svg-icon'
        }
      ];
        this.getStateGrid();
      }
      
    }
      
    else if (this.selectedValue === 'Branch' ){
     
      if(this.selectedComplianceType.value === 'Challan'){
        this.Challan()
        }
          else{
            this.parentColumns = []
     
            this.parentColumns= [
    
              { field: 'branch', title: 'Location', width: '90px', expandable: this.isChallan  }, // Reduce width
              { field: 'complianceID', title: 'Comp.Id', width: '30px' }, // Reduce width
              { field: 'shortDescription', title: 'Description', width: '100px' }, // Reduce width
              {
                field: 'scheduledOn',
                title: 'Due Date',
                width: '50px', // Adjust the width
                cellTemplate: (dataItem: any) => {
                  return this.formatDate(dataItem.scheduledOn); // Format Date
                },
              },
              
              { field: 'forMonth', title: 'Period', width: '40px' }, // Reduce width
              { field: 'status', title: 'Status', width: '50px' }, // Adjust the width
              {
                field: 'action',
                title: 'Action',
                width: '70px',
                cellTemplate: (dataItem: any) => {
                  return this.getActionBranch(dataItem);;
                },class:'svg-icon'
              }
            ];
            this.getBranchGrid();
          }
    }
      
    else if (this.selectedValue === 'Compliance' ){
      console.log('log1',this.selectedComplianceType.value)
      if(this.selectedComplianceType.value === 'Challan'){
        console.log('log',this.selectedComplianceType.value)
        this.Challan()
          }
          else{
            this.parentColumns = []
            this.parentColumns= [
      
              { field: 'branch', title: 'Location', width: '50px', expandable: this.isChallan  }, // Reduce width
              { field: 'complianceID', title: 'Comp.Id', width: '30px' }, // Reduce width
              { field: 'shortDescription', title: 'Description', width: '150px' }, // Reduce width
              { field: 'cM_EstablishmentType', title: 'Establishment Type', width: '50px' },
              {
                field: 'scheduledOn',
                title: 'Due Date',
                width: '50px', // Adjust the width
                cellTemplate: (dataItem: any) => {
                  return this.formatDate(dataItem.scheduledOn); // Format Date
                },
              },
              
              { field: 'forMonth', title: 'Period', width: '50px' }, // Reduce width
              { field: 'status', title: 'Status', width: '50px' }, // Adjust the width
              {
                field: 'action',
                title: 'Action',
                width: '70px',
                cellTemplate: (dataItem: any) => {
                  return this.getActionChallan(dataItem);;
                },class:'svg-icon'
              }
            ];
            this.getComplianceGrid();
          }
    }
     
    else if (this.selectedValue === 'Challan'){
      console.log('chalan', this.selectedValue);
      this.parentColumns= [];
      this.parentColumns= [
  
        { field: 'clientName', title: 'Client Name', width: '80px', expandable: this.isChallan }, // Reduce width
        { field: 'branch', title: 'Location/Code', width: '50px' }, // Reduce width
        { field: 'shortDescription', title: 'Description', width: '120px' }, // Reduce width
        { field: 'forMonth', title: 'Period', width: '50px' },
        { field: 'status', title: 'Status', width: '50px' }, 
        {
          field: 'eye',
          title: 'Branches',
          width: '30px',
          cellTemplate: (dataItem: any) => {
            return this.getActionEye(dataItem);;
          },class:'svg-icon'
        },
        {
          field: 'action',
          title: 'Action',
          width: '70px',
          cellTemplate: (dataItem: any) => {
            return this.getActionChallan(dataItem);;
          },class:'svg-icon'
        }
      ];
        this.getComplianceChallan();
    }
    
  }

Challan(){
  console.log('chalan', this.selectedValue);
      this.parentColumns= [];
      this.parentColumns= [
  
        { field: 'clientName', title: 'Client Name', width: '80px', expandable: this.isChallan }, // Reduce width
        { field: 'branch', title: 'Location/Code', width: '50px' }, // Reduce width
        { field: 'shortDescription', title: 'Description', width: '120px' }, // Reduce width
        { field: 'forMonth', title: 'Period', width: '50px' },
        { field: 'status', title: 'Status', width: '50px' }, 
        {
          field: 'eye',
          title: 'Branches',
          width: '30px',
          cellTemplate: (dataItem: any) => {
            return this.getActionEye(dataItem);;
          },class:'svg-icon'
        },
        {
          field: 'action',
          title: 'Action',
          width: '70px',
          cellTemplate: (dataItem: any) => {
            return this.getActionChallan(dataItem);;
          },class:'svg-icon'
        }
      ];
        this.getComplianceChallan();
}

  onValueChange(event: any): void {
    this.selectedValue = event;
    let selected = sessionStorage.setItem('selected', this.selectedValue);
   
    this.clearFilter();
    console.log('sel',this.selectedComplianceType.value)
    this.isChallan = this.selectedComplianceType.value === 'Challan' ? false :true;
console.log('chal',this.isChallan)
console.log('c',this.selectedValue)
    if(this.selectedValue === 'State' ) {
      this.parentColumns = []
      this.parentColumns= [

        { field: 'cM_State', title: 'State', width: '80px', expandable: this.isChallan }, // Reduce width
        { field: 'branch', title: 'Location', width: '50px' }, // Reduce width
        { field: 'complianceID', title: 'Comp.Id', width: '30px' }, // Reduce width
        { field: 'shortDescription', title: 'Description', width: '120px' }, // Reduce width
        {
          field: 'scheduledOn',
          title: 'Due Date',
          width: '50px', // Adjust the width
          cellTemplate: (dataItem: any) => {
            return this.formatDate(dataItem.scheduledOn); // Format Date
          },
        },
        { field: 'forMonth', title: 'Period', width: '50px' }, // Reduce width
        { field: 'status', title: 'Status', width: '50px' }, // Adjust the width
        {
          field: 'action',
          title: 'Action',
          width: '50px',
          cellTemplate: (dataItem: any) => {
            return this.getActionTemplate(dataItem);;
          },class:'svg-icon'
        }
      ];
    }
    else if (this.selectedValue === 'Branch' ){
      this.parentColumns = []
     
        this.parentColumns= [

          { field: 'branch', title: 'Location', width: '100px', expandable: this.isChallan  }, // Reduce width
          { field: 'complianceID', title: 'Comp.Id', width: '30px' }, // Reduce width
          { field: 'shortDescription', title: 'Description', width: '100px' }, // Reduce width
          {
            field: 'scheduledOn',
            title: 'Due Date',
            width: '50px', // Adjust the width
            cellTemplate: (dataItem: any) => {
              return this.formatDate(dataItem.scheduledOn); // Format Date
            },
          },
          
          { field: 'forMonth', title: 'Period', width: '50px' }, // Reduce width
          { field: 'status', title: 'Status', width: '50px' }, // Adjust the width
          {
            field: 'action',
            title: 'Action',
            width: '50px',
            cellTemplate: (dataItem: any) => {
              return this.getActionTemplate(dataItem);;
            },class:'svg-icon'
          }
        ];
      
    }
    else if (this.selectedValue === 'Compliance' ){
      this.parentColumns = []
      this.parentColumns= [

        { field: 'branch', title: 'Location', width: '50px', expandable: this.isChallan  }, // Reduce width
        { field: 'complianceID', title: 'Comp.Id', width: '30px' }, // Reduce width
        { field: 'shortDescription', title: 'Description', width: '150px' }, // Reduce width
        { field: 'cM_EstablishmentType', title: 'Establishment Type', width: '50px' },
        {
          field: 'scheduledOn',
          title: 'Due Date',
          width: '50px', // Adjust the width
          cellTemplate: (dataItem: any) => {
            return this.formatDate(dataItem.scheduledOn); // Format Date
          },
        },
        
        { field: 'forMonth', title: 'Period', width: '50px' }, // Reduce width
        { field: 'status', title: 'Status', width: '50px' }, // Adjust the width
       
      ];
    }
    console.log('Selected value:', this.selectedValue);
  }

  clearFilter(): void {
    
    this.form.reset();
    this.selectedComplianceType = { text: 'Compliance', value: 'Compliance' };
    this.selectedFrequency = { text: 'Frequency', value: 'Frequency' };
    this.selectedAct = { text: 'Act', value: 'Act' };
    this.selectedYear = { text: 'Year', value: 'Year' };
    this.selectedPeriod = { text: 'Period', value: 'Period' };
    this.selectedFiles = '';
    this.selectedBranch = [];

    this.frequency = [];
    this.period = [];
    this.files = [];
    this.BranchDropdown = [];
    this.clraBranchDropdown = [];
    this.selectedItems = [];
    this.gridData = []
    this.getActDropdown();
    this.getFiles();
  }

  checkDisableField() {

    this.isFieldDisabled =
      this.selectedValue === 'register' || this.selectedValue === 'challan';
  }

  // public parentColumns: any[] = [

  //   { field: 'cM_State', title: 'State', width: '30px', expandable: true }, // Reduce width
  //   { field: 'branch', title: 'Location', width: '50px' }, // Reduce width
  //   { field: 'complianceID', title: 'Comp.Id', width: '30px' }, // Reduce width
  //   { field: 'shortDescription', title: 'Description', width: '150px' }, // Reduce width
  //   {
  //     field: 'scheduledOn',
  //     title: 'Due Date',
  //     width: '50px', // Adjust the width
  //     cellTemplate: (dataItem: any) => {
  //       return this.formatDate(dataItem.scheduledOn); // Format Date
  //     },
  //   },
  //   { field: 'forMonth', title: 'Period', width: '50px' }, // Reduce width
  //   { field: 'status', title: 'Status', width: '50px' }, // Adjust the width
   
  // ];

  private formatDate(dateString: string): string {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return ''; // Handle invalid dates
    }

    const day = ('0' + date.getDate()).slice(-2); // Get two-digit day
    const month = date.toLocaleString('default', { month: 'short' }); // Get abbreviated month
    const year = date.getFullYear(); // Get full year

    return `${day}-${month}-${year}`; // Return formatted date
  }


  handleNodeExpand(event: any) {
    const { dataItem, isExpanded } = event;
    event.dataItem.children = [
      { field: 'forMonth', title: 'Period', width: '50px' },
      { field: 'status', title: 'Status', width: '50px' },
    ];
    console.log('dataItem:', dataItem, 'Is Expanded:', isExpanded);
  }

  // onFetchChildrens(parentId: number): void {
  //   const parent = this.findItemById(this.treeData, parentId);
  //   if (parent) {
  //     // Simulate a delayed network request to fetch children
  //     setTimeout(() => {
  //       parent.children = [
  //         { id: 4, name: 'Child 1 of ' + parent.name, hasChildren: false },
  //         { id: 5, name: 'Child 2 of ' + parent.name, hasChildren: true }, // Has children
  //       ];
  //     }, 1000); // Simulate network latency
  //   }
  // }

  // Utility function to find an item by ID
  findItemById(items: Item[], id: number): Item | undefined {
    debugger;
    for (const item of items) {
      if (item.id === id) {
        return item;
      }
      if (item.children) {
        const child = this.findItemById(item.children, id);
        if (child) {
          return child;
        }
      }
    }
    return undefined;
  }

  oncomplianceStatus(value: any) {
    this.selectedStatus = { text: value.text, value: value.value };
  }

  getComplianceStatus() {

    this.statutory.getComplianceStatus('ComStatus').subscribe({
      next: (response) => {
        const data = response.body;
        if (data && Array.isArray(data)) {
          this.complianceStatus = data.map((item: any) => ({
            value: item.value,
            text: item.text
          }));
        }
      },
      error: (err) => {
        console.error("Error fetching compliance status:", err);
      }
    });
  }

  ngOnDestroy() {
    if (this.router.url !== '/LCMyComplianceDocumentComponent') {
      sessionStorage.removeItem('selected');
    }
  }

  handleImageClick(event: [string, any]) {
    const [action, data] = event;
    console.log('Action:', event);
    console.log('Data:', data);
    // Handle the values based on action and data
  }
  
  // getActionTemplate(dataItem: any): string {
  //   if (dataItem.isFilePresent === 1) {
  //     return `
  //       <div style ="text-align:center">
  //         <img
  //           src="../assets/vectors/DownloadBlue.svg"
  //           alt="Download"
  //           style="cursor: pointer; max-width: 100%; height: auto;"
  //           onclick="angularComponentReference.downloadFile(${dataItem.id})"
  //         />
  //       </div>
  //     `;
  //   } else {
  //     return `
  //       <div style ="text-align:center">
  //         <img
  //           src="../assets/vectors/NoAccessDownload.svg"
  //           alt="No Access"
  //           style="max-width: 100%; height: auto;"
  //         />
         
  //       </div>
  //     `;
  //   }
  // }
  // hasChildren = (dataItem: any): boolean => {
  //   return dataItem.hasChildren ;
  // };

  getActionTemplate(dataItem: any): string {
    
      // Show download icon for parent nodes only
      if (dataItem.isFilePresent === 1) {
        return `
          <div style="text-align:center">
            <img
              src="../assets/vectors/DownloadBlue.svg"
              alt="Download"
              class="svg-icon"
              onclick="angularComponentReference.downloadFile(${dataItem.id})"
            />
          </div>
        `;
      } else {
        return `
          <div style="text-align:center">
            <img
              src="../assets/vectors/NoAccessDownload.svg"
              alt="No Access" class="svg-icon"
            />
          </div>
        `;
      }
    
  }
  getActionChallan(dataItem: any){
        return `
          <div style="text-align:center">
            <img
              src="../assets/vectors/OverviewIcon.svg"
              alt="Download"
              class="svg-icon"
              onclick="angularComponentReference.downloadFile(${dataItem.id})"
            />
            <img
              src="../assets/vectors/DownloadBlue.svg"
              alt="Download"
              class="svg-icon"
              onclick="angularComponentReference.downloadFile(${dataItem.id})"
            />
            
            <img
              src="../assets/vectors/EyeIcon.svg"
              alt="Download"
              class="svg-icon"
              onclick="angularComponentReference.downloadFile(${dataItem.id})"
            />
          </div>
        `;
      
  }
  getActionEye(dataItem: any){
    
        return `
          <div style="text-align:center">
            <img
              src="../assets/vectors/EyeIcon.svg"
              alt="Download"
              class="svg-icon"
              onclick="angularComponentReference.downloadFile(${dataItem.id})"
            />
          </div>
        `;
      
  }
  getActionBranch(dataItem: any): string {
    
    if(dataItem.hasChildren){
      return `
        <div style="text-align:center">
          <img
            src="../assets/vectors/DownloadBlue.svg"
            alt="Download" class="svg-icon"
            onclick="angularComponentReference.downloadFile(${dataItem.id})"
          />
        </div>
      `;
    }else if (dataItem.isFilePresent === 1) {
      return `
        <div style="text-align:center">
            <img
              src="../assets/vectors/OverviewIcon.svg"
              alt="Download" class="svg-icon"
              onclick="angularComponentReference.downloadFile(${dataItem.id})"
            />
            <img
              src="../assets/vectors/DownloadBlue.svg"
              alt="Download" class="svg-icon"
              onclick="angularComponentReference.downloadFile(${dataItem.id})"
            />
            
            <img
              src="../assets/vectors/EyeIcon.svg"
              alt="Download" class="svg-icon"
              onclick="angularComponentReference.downloadFile(${dataItem.id})"
            />
          </div>
      `;
    }
    else{
      return `<div> </div>`
    }
  
}
}
