<kendo-grid class="custom-grid" *ngIf="showGrid" [data]="gridData" [columnMenu]="{filter:true}" [pageSize]="pageSize"
    [pageable]="true" (pageChange)="pageChange($event)" [resizable]="true" style="margin-top: 20px;" [skip]="skip">

    <kendo-grid-column field="state" title="State">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="cell-content">
                <span>{{ dataItem.state }}</span>
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="frequency" title="Frequency">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div *ngIf="isCellBeingEdited(rowIndex, 'frequency'); else displayFrequency" class="cell-content">
                <kendo-dropdownlist class="f-textbox" [data]="frequencyOptions" [value]="dataItem.frequency"
                    (valueChange)="onFrequencyChange($event, dataItem)">
                </kendo-dropdownlist>
            </div>
            <ng-template #displayFrequency>
                <div class="cell-content">
                    <span (click)="editRow(rowIndex, 'frequency')">{{ dataItem.frequency }}</span>
                </div>
            </ng-template>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="period" title="Period">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div *ngIf="isCellBeingEdited(rowIndex, 'period'); else displayPeriod" class="cell-content">
                <input class="f-textbox" type="text" [value]="dataItem.period"
                    (input)="onPeriodChange($event, dataItem)">
            </div>
            <ng-template #displayPeriod>
                <div class="cell-content">
                    <span (click)="editRow(rowIndex, 'period')">{{ dataItem.period }}</span>
                </div>
            </ng-template>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="Action" title="Action" [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="cell-content">
                <img src="../assets/vectors/save1.svg" alt="Save Icon" class="svg-icon" (click)="onSave(dataItem)"
                    style="margin-left: 25px;" title="Save" />
            </div>
        </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-input></kendo-pager-input>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>
</kendo-grid>
<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>
<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270"  [Data]="alertData"
    (close)="closePopup()"></app-alert-popup>