<kendo-dialog #dialogRef title="Add Notice" (close)="closePopup()" [width]="1150" [height]="'auto'" class="custom-dialog">
    <div class="dialog-content">
        <form [formGroup]="form">
            <div class="row row-setting">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-5 label-setting">Entity<sub class="text-danger">*</sub></label>
                    <div class="require-container ms-5">
                        <app-dropdown-list [data]="entityData" [selectedValue]="selectedEntity" textField="text" (valueChange)="onEntityChange($event)"
                            valueField="value" [isDisabled]="Role!=='CORDN'" [width]="'250px'">
                        </app-dropdown-list>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3 label-setting">State<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <app-dropdown-list [data]="stateData" [selectedValue]="selectedState" textField="text"
                            valueField="value" (valueChange)="onStateChange($event)" [width]="'250px'"
                            [ngClass]="{'is-invalid': form.get('state')?.invalid && (form.get('state')?.touched)}">
                        </app-dropdown-list>
                    </div>
                </div>
            </div>
            <div class="row row-setting">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-5 label-setting">Location<sub class="text-danger">*</sub></label>
                    <div class="require-container ms-5">
                        <app-dropdown-list [data]="locationData" [selectedValue]="selectedLocation" textField="text"
                            valueField="value" (valueChange)="onLocationChange($event)" [width]="'250px'"
                            [ngClass]="{'is-invalid': form.get('location')?.invalid && (form.get('location')?.touched)}">
                        </app-dropdown-list>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3 label-setting">Branch<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <app-dropdown-list [data]="branchData" [selectedValue]="selectedBranch" textField="text"
                            valueField="value" (valueChange)="onBranchChange($event)" [width]="'250px'"
                            [ngClass]="{'is-invalid': form.get('branch')?.invalid && (form.get('branch')?.touched)}">
                        </app-dropdown-list>
                    </div>
                </div>
            </div>
            <div class="row row-setting">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-5 label-setting">Mode of Receipt<sub class="text-danger">*</sub></label>
                    <div class="require-container ms-5">
                        <app-dropdown-list [data]="receiptData" [selectedValue]="selectedReceipt" textField="text"
                            valueField="value" (valueChange)="onReceiptChange($event)" [width]="'250px'"
                            [ngClass]="{'is-invalid': form.get('receiptMode')?.invalid && (form.get('receiptMode')?.touched)}">
                        </app-dropdown-list>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3 label-setting">Notice Number<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="text" [(ngModel)]="noticeNo" class="f-textbox" placeholder="Notice Number"
                            formControlName="noticeNo"
                            [ngClass]="{'is-invalid': form.get('noticeNo')?.invalid && (form.get('noticeNo')?.touched)}">
                    </div>
                </div>
            </div>
            <div class="row row-setting">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-5 label-setting">Notice Received Date<sub
                            class="text-danger">*</sub></label>
                    <div class="require-container ms-5">
                        <app-kendo-datepicker class="uniform-size" [(value)]="noticeReceived"
                            (valueChange)="onDateChange($event, 'noticeReceived')" [format]="'dd-MM-yyyy'"
                            [placeholder]="placeholder" [width]="'250px'"
                            [ngClass]="{'is-invalid': form.get('noticeReceived')?.invalid && (form.get('noticeReceived')?.touched)}">
                        </app-kendo-datepicker>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3 label-setting">Response Due Date<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <app-kendo-datepicker class="uniform-size" [(value)]="responseDate"
                            (valueChange)="onDateChange($event, 'responseDate')" [format]="'dd-MM-yyyy'"
                            [placeholder]="placeholder" [width]="'250px'"
                            [ngClass]="{'is-invalid': form.get('responseDate')?.invalid && (form.get('responseDate')?.touched)}">
                        </app-kendo-datepicker>
                    </div>
                </div>
            </div>
            <div class="row row-setting">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-5 label-setting">Notice Type<sub class="text-danger">*</sub></label>
                    <div class="require-container ms-5">
                        <app-dropdown-list [data]="noticeTypeData" [selectedValue]="selectedNotice" textField="text"
                            valueField="value" (valueChange)="onNoticeChange($event)" [width]="'250px'"
                            [ngClass]="{'is-invalid': form.get('noticeType')?.invalid && (form.get('noticeType')?.touched)}">
                        </app-dropdown-list>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3 label-setting">Risk<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <app-dropdown-list [data]="riskData" [selectedValue]="selectedRisk" textField="text"
                            valueField="value" (valueChange)="onRiskChange($event)" [width]="'250px'"
                            [ngClass]="{'is-invalid': form.get('risk')?.invalid && (form.get('risk')?.touched)}">
                        </app-dropdown-list>
                    </div>
                </div>
            </div>
            <div class="row row-setting">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-5 label-setting">Receipt Acknowledgement<sub
                            class="text-danger">*</sub></label>
                    <div class="require-container ms-5"
                        [ngClass]="{'disabled-opacity': !isReceiptAcknowledgementEnabled()}">
                        <span *ngIf="!fileNames['receipt']" title="Uploaded File"
                            style="color: #A0A0A0;vertical-align:sub;">No file
                            chosen</span>
                        <span *ngIf="fileNames['receipt']">
                            {{fileNames['receipt']}}
                        </span>
                        <input type="file" #receipt (change)="onFileChange($event, 'receipt')" id="fileInput"
                            style="display: none;" accept=".doc,.docx,.pdf"
                            [disabled]="!isReceiptAcknowledgementEnabled()" />
                        <button class="figma-btn-gray  disabled-button" (click)="receipt.click()"
                            [disabled]="!isReceiptAcknowledgementEnabled()">Browse</button>
                        <div *ngIf="!fileNames['receipt'] && showValidationErrors && isReceiptAcknowledgementEnabled()"
                            class="text-danger">
                            File is required.
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3 label-setting">Notice Document<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <span *ngIf="!fileNames['notice']" title="Uploaded File"
                            style="color: #A0A0A0;vertical-align:sub;">No file
                            chosen</span>
                        <span *ngIf="fileNames['notice']">
                            {{fileNames['notice']}}
                        </span>
                        <input type="file" #notice (change)="onFileChange($event, 'notice')" id="fileInput"
                            style="display: none;" accept=".doc,.docx,.pdf" formControlName="" />
                        <button class="figma-btn-gray" (click)="notice.click()">Browse</button>
                        <div *ngIf="!fileNames['notice']&&showValidationErrors" class="text-danger ">
                            File is required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-setting">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-5 label-setting">Remarks<sub class="text-danger">*</sub></label>
                    <div class="require-container ms-5">
                        <textarea class="f-textbox text-area" [(ngModel)]="remarks" formControlName="remarks" placeholder="Remarks"
                            [ngClass]="{'is-invalid': form.get('remarks')?.invalid && (form.get('remarks')?.touched)}" rows="5" ></textarea>
                    </div>
                </div>
            </div>
            <div class="btn-setting">
                <button class="figma-btn-blue " (click)="saveNotice()">Save</button>
                <button class="figma-btn-blue " (click)="back()">Back</button>
            </div>
        </form>
    </div>
</kendo-dialog>

<app-alert-popup *ngIf="alertData" [Width]="270" [Data]="alertData"
    (close)="closePopup()"></app-alert-popup>

    <div *ngIf="loading" class="loader-overlay">
        <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
    </div>