import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EcrConfirmationServiceService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }
  getMonth(Months: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Common/Get_LC_CodeDescription?Type=${Months}`;
    return this.http.get(url, { observe: 'response' });
  }
  getReportGridData(payload: any): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/ECR/GetECRFileConfirmationDetails?ClientID=${payload.ClientID}&month=${payload.month}&year=${payload.year}`;
    return this.http.get(url, { observe: 'response' });

    // 'https://localhost:7111/api/ECR/GetECRFileConfirmationDetails?ClientID=AVACORED&month=01&year=2024'
  }
  // FinalizeComplianceAssignment(value: any): Observable<HttpResponse<any>> {
  //   const params = {
  //     UserId: value.UserId,
  //     _RecordIDList: value._RecordIDList.join(',') // Convert array to a comma-separated string
  //   };

  //   const url = `${this.apiUrl}/api/ECR/Update_PFECRFinalizeRecord`;

  //   // Perform the GET request with query params
  //   return this.http.post<any>(url, { params, observe: 'response' });
  // }


  // FinalizeComplianceAssignment(body: any): Observable<HttpResponse<any>> {
  //   const userId = body.UserId; // extract UserId from the body
  //   const recordIdList = body._RecordIDList; // extract _RecordIDList from the body

  //   // Build the GET URL with query parameters
  //   const url = `${this.apiUrl}/api/ECR/Update_PFECRFinalizeRecord?UserId=${userId}`;

  //   // Prepare the options (including _RecordIDList in the body if necessary)
  //   const options = {
  //     observe: 'response' as const,
  //     body: { _RecordIDList: recordIdList },
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   };

  //   // Make the GET request (if your API allows GET with a body, otherwise consider switching to POST)
  //   return this.http.request<any>('POST', url, options);
  // }
  FinalizeComplianceAssignment(body: any): Observable<HttpResponse<any>> {
    const userId = body.UserId; // extract UserId from the body
    const recordIdList = body._RecordIDList; // extract _RecordIDList from the body

    // Build the POST URL
    const url = `${this.apiUrl}/api/ECR/Update_PFECRFinalizeRecord?UserId=${userId}`;

    // Prepare the options for the POST request
    const options = {
      observe: 'response' as const, // ensures you get the full response object
      headers: {
        'Content-Type': 'application/json'
      }
    };

    // Make the POST request with body containing _RecordIDList
    return this.http.post<any>(url, { _RecordIDList: recordIdList }, options);
  }

  ecrBulkDownload(payload: any): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/ECR/ECRBulkDownload`;
    return this.http.post(url, payload, {
      observe: 'response',
      responseType: 'arraybuffer' // This tells Angular to expect binary data (FileStream)
    });
  }
  fetchFile(FilePath: string): Observable<Blob> {
    const url = `${this.apiUrl}/api/ECR/ECRPreview?filepath=${FilePath}`;
    return this.http.post(url, null, { responseType: 'blob' });
  }
}


