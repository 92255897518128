<div class="row colpadding0" style="margin-bottom: 10px; margin-top: 10px;"> 
    <div class="col-md-8 colpadding0">
        <kendo-multiselecttree #multiselectTree placeholder="Entity/State/Location/Branch" kendoMultiSelectTreeExpandable [popupSettings]="popupSettings"
                [kendoMultiSelectTreeHierarchyBinding]="BranchDropdown" childrenField="children" textField="name"
                valueField="id" [tagMapper]="tagMapper" (valueChange)="OnMultiSelectChange($event)"
                [expandedKeys]="['0']" class="f-textbox multi-box">
            </kendo-multiselecttree>
        <app-dropdown-list 
            [data]="ActDropdownData" 
            [selectedValue]="selectedAct" 
            [width]="'300px'"
            textField="text" 
            valueField="value" 
            (valueChange)="onDropdownValueChange($event, 'activity')"
            style="margin-left: 7px;">
        </app-dropdown-list>


    </div>
    <div class="col-md-4 colpadding0" >
        <button (click)="exportToExcel()" class="exportbtn" style="float: right;">
            <img src="../assets/vectors/ExportButton.svg" alt="">
        </button>
        <button class="figma-btn-white apply-btn" style="float: right;" type="submit" (click)="applyFilter()">
            <img src="../assets/vectors/ApplyFilter.svg" alt="">
        </button>
        <button class="figma-btn-white clear-btn" style="float: right;" (click)="clearFilter()">
            <img src="../assets/vectors/ClearFilter.svg" alt="">
        </button>
    </div>
</div>

<app-grid-action-item [gridData]="gridData" [columns]="parentColumns" [pageSize]="10" [pageable]="true"
[filterable]="true" (buttonClick)="handleButtonClick($event)"></app-grid-action-item>

<app-master-abstract-upload-popup [StateId]="selectedState.value" [ActId]="selectedAct.value" *ngIf="showUploadPopup" (close)="closePopup()"></app-master-abstract-upload-popup>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="250" [Height]="260" [Data]="alertData"
(close)="closeAlert()"></app-alert-popup>
<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>
<!-- <ng-template #actionCell let-row="row">
    <ng-container *ngIf="row.docStatus === 'Document Available'; else noDocument">
      <img src="../assets/vectors/DownloadBlue.svg" alt="Download Icon" (click)="downloadDocument(row)" />
      <img src="../assets/vectors/EyeIcon.svg" alt="View Icon" (click)="viewDocument(row)" />
    </ng-container>
    <ng-template #noDocument>
      <span>No Document</span>
    </ng-template>
</ng-template> -->

<app-adobe-viewer [fileBlob]="fileBlob" *ngIf="fileBlob" (close)="closeViewer()"></app-adobe-viewer>