import { CommonModule, NgIf } from '@angular/common';
import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { FormsModule } from '@angular/forms';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { PaycodeMasterService } from '../../../service/paycode-master.service';
import { UserService } from '../../../service/user.service';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';

@Component({
  selector: 'app-paycode-upload-popup',
  standalone: true,
  imports: [NgIf, GridActionItemComponent, CommonModule, FormsModule, DialogModule,AlertPopupComponent],
  templateUrl: './paycode-upload-popup.component.html',
  styleUrl: './paycode-upload-popup.component.css'
})
export class PaycodeUploadPopupComponent {
  @Output() close = new EventEmitter<void>();
  selectedFile?: File;
  dropzoneMessage: string = "Drag your files here or ";
  EntityID: any = 0;
  alertData={alertMessage:'',alertVisible:''}
  
  
  constructor(private paycodeService:PaycodeMasterService , private userService: UserService){}
  
  @ViewChild('dialogTitle') dialogTitle!: ElementRef;
  ngAfterViewInit() {
    const titleElement = this.dialogTitle.nativeElement;
    titleElement.style.textAlign = 'center';
    titleElement.style.width = '100%';
  }

  ngOnInit(): void {
    this.EntityID = this.userService.getClientId();
   
  }

  public closePopup(): void {
    this.close.emit();
  }

  public onFileSelect(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
        this.selectedFile = input.files[0];
        console.log('Selected file:', this.selectedFile);
        const fileExtension = this.selectedFile.name.split('.').pop()?.toLowerCase();
        if (fileExtension !== 'xlsx') {
            this.selectedFile = undefined;
            this.alertData = {
                alertMessage: 'Only .xlsx files are allowed.',
                alertVisible: 'error'
            };           
        }
    }
}

  public onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drop the file here";
  }

  public onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drag your files here or ";
  }

  public onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      this.selectedFile = event.dataTransfer.files[0];
      event.dataTransfer.clearData();
    }
  }

  
  public uploadFiles(): void {
    if (this.selectedFile) {
        this.paycodeService.uploadClientPaycodeMappings(this.selectedFile, this.EntityID).subscribe({
            next: (response) => {
              console.log('response',response);
              
                if (response?.status === 200) {
                  this.alertData = {
                    alertMessage: 'File Uploaded Successfully...',
                    alertVisible: 'success'
                };
                console.log('File uploaded successfully', response);
               
                } 
                else {
                  this.downloadErrorFile(response?.body.filePath, response?.body.sasToken);
                  this.alertData = {
                      alertMessage: 'Error uploading file.',
                      alertVisible: 'error'
                    };
                    this.selectedFile=undefined;
                    console.log('File not uploaded ', response);
                }
            },
            error: (error) => {
                console.log('error', error);
                this.selectedFile=undefined;
                this.alertData = {
                    alertMessage:'Invalid template file!',
                    alertVisible: 'error'
                };
            }
        });
    } else {
      this.selectedFile=undefined;
        this.alertData = {
            alertMessage: 'No file selected.',
            alertVisible: 'error'
        };
        console.warn('No file selected.');
    }
}

downloadErrorFile(FilePath: string, sasToken: string) {
  const blobUrl = `${FilePath}?${sasToken}`;
  const a = document.createElement('a');
  a.href = blobUrl;
  const filename = FilePath.split('/').pop() || 'downloaded_file';
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

  }

 
  


