import { Injectable } from '@angular/core';


import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InvoiceFaceSheetService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {

  }

  getClientsGstMaster(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Invoice/GetClientsMaster`;
    // https://localhost:7111/api/Invoice/GetClientsMaster
    return this.http.get(url, {observe:'response'});
  }
  // GetInvoiceGridDetail(payload:any): Observable<HttpResponse<any>> {
  //   const url = `${this.apiUrl}/api/Invoice/GetInvoiceGridDetails?ClientId=${payload.ClientId}`;
  //   https://localhost:7111/api/Invoice/GetInvoiceGridDetails
  //   return this.http.get(url, {observe:'response'});
  // }
  GetInvoiceGridDetail(payload:any): Observable<HttpResponse<any>> {
   
    const url = `${this.apiUrl}/api/Invoice/GetInvoiceGridDetails?InvoiceType=${payload.InvoiceType}&DraftType=${payload.DraftType}&Monthid=${payload.Monthid}&yearid=${payload.yearid}&Clientid=${payload.Clientid}&InvoiceNumber=${payload.InvoiceNumber}`;
    return this.http.get(url, { observe: 'response' });
  // 'https://localhost:7111/api/Invoice/GetInvoiceGridDetails?InvoiceType=INV&DraftType=D&Monthid=09&yearid=2024&Clientid=WWKRG'
  }
}
