import { Component, EventEmitter, Output } from '@angular/core';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { GridDataResult, GridModule } from '@progress/kendo-angular-grid';
import { FormsModule } from '@angular/forms';
import { ZoneLocationMappingService } from '../../../service/zone-location-mapping.service';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { NgIf } from '@angular/common';
import { ZoneLocationMappingBulkUploadComponent } from '../../popups/zone-location-mapping-bulk-upload/zone-location-mapping-bulk-upload.component';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { PageChangeEvent } from '@progress/kendo-angular-treelist';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { ComboBoxFreeTextComponent } from '../../../components/combo-box-free-text/combo-box-free-text.component';
@Component({
  selector: 'app-zone-location-mapping',
  standalone: true,
  imports: [
    DropdownListComponent,
    GridActionItemComponent,
    GridModule,
    FormsModule,
    AlertPopupComponent,
    NgIf,
    ZoneLocationMappingBulkUploadComponent,
    InputsModule,IndicatorsModule,
    ComboBoxComponent,ComboBoxFreeTextComponent
  ],
  templateUrl: './zone-location-mapping.component.html',
  styleUrl: './zone-location-mapping.component.css'
})
export class ZoneLocationMappingComponent {
  @Output() valueChange = new EventEmitter<any>();
  public gridData: GridDataResult = { data: [], total: 0 };
  statesList:  any[] = [];
  zoneList: Array<{ name: string, id: string }> = [];
  pincodesAndLocationsList: Array<{ name: string, id: string }> = [];
  rowData!: any;
  isAddNewVisible: boolean = false;
  alertData = { alertMessage: '', alertVisible: '' };
  isFormValid: boolean = false;
  deleteId: any;
  uploadPopup = false;
  loading=false;
  StateDefaultValue = { name: 'Select State', id: '' };
  PincodeDefaultValue = { name: 'Select PinCode', id: '' };
  ZoneDefaultValue = { name: 'Select Zone', id: '' };
  public pageSize = 10;
  public skip = 0;
  public showGrid = true;
  public sizes = [{text:'All',value:1},5, 10, 20];
  public buttonCount = 5;
  public editedRowIndex: number | null = null;
  public editedColumn: string | null = null;

  constructor(private zoneLocationMappingService: ZoneLocationMappingService) {
    this.getAllStateList();
    this.loadGridData();
  }

  confirmAlert(value: any) {
    console.log(value);
    this.deleteId = value.id;
    this.alertData = { alertMessage: 'Are you sure you want to delete this record?', alertVisible: 'confirm' };
  }

  pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    if (event.take === 1) {
      this.pageSize = this.gridData.total; 
  } else {
      this.pageSize = event.take;
  }
    this.loadGridData();
  }

  public editRow(index: number, column: string): void {
    this.editedRowIndex = index;
    this.editedColumn = column;
  }

  public isCellBeingEdited(index: number, column: string): boolean {
    return this.editedRowIndex === index && this.editedColumn === column;
  }

  onPeriodChange(event: Event, dataItem: any): void {
    const inputElement = event.target as HTMLInputElement;
    dataItem.period = inputElement.value;
  }

  save(dataItem: any) {
    console.log(dataItem.state, dataItem.zone, dataItem.location, dataItem.pincode);
    this.zoneLocationMappingService.saveZoneMappingData(dataItem.state, dataItem.zone, dataItem.location, dataItem.pincode).subscribe({
      next: (response) => {
        this.alertData = { alertMessage: 'Data added Successfully', alertVisible: 'success' };
        this.loadGridData();
      },
      error: (error) => {
        this.alertData = { alertMessage: 'Error while adding data', alertVisible: 'error' };
      }
    });
  }

  public selectedState: string = 'State';
  selectedZone: any;
  selectedLocation: any;
  selectedPincode: any;
  public parentColumns: any[] = [
    { field: 'state', title: 'State' },
    { field: 'zone', title: 'Zone' },
    { field: 'location', title: 'Location' },
    { field: 'pincode', title: 'Pincode' },
    {
      field: 'Action',
      title: 'Action',
      filteredIcons: [
        {
          url: '../assets/vectors/edit.svg',
          alt: 'Icon 1',
          action: 'edit'
        },
        {
          url: '../assets/vectors/delete.svg',
          alt: 'Delete Icon',
          action: 'delete'
        }
      ]
    }
  ];

  getAllStateList() {
    this.zoneLocationMappingService.getAllStateList().subscribe(
      {
        next: (response) => {
          const data = response.body;
          this.statesList =data.map((item: any) => ({
              id: item.sM_Code,
            name: item.sM_Name
            }));
        }, error(error) {
          console.error("Error fetching dropdown data", error);
        },
      }
    );
  }

  loadGridData() {
    this.loading=true;
    this.zoneLocationMappingService.getZoneList().subscribe({
      next: (response) => {
        if (response.status === 200) {
          const data = response.body;
          this.gridData = {
            data: data.slice(this.skip, this.skip + this.pageSize),
            total: data.length
          };
          this.loading=false;
        }
        else {
          console.error('Error fetching entity data');
        }
      },
      error: (error) => {
        console.error('Error fetching entity data', error);
      }
    });
  }

  onStateChange(state: any) {
    this.selectedState = state.id;
    this.zoneLocationMappingService.getPincodeAndLocationByState(state.id).subscribe(
      {
        next: (response) => {
          console.log(response);
          const data = response.body;
          this.pincodesAndLocationsList = [{ name: 'Select PinCode', id: '' },
            ...data.map((item: any) => ({
              id: item.value,
              name: item.key
            }))];
        }, error(error) {
          console.error("Error fetching dropdown data", error);
        },
      }
    );
    this.zoneLocationMappingService.getZoneByState(state.id).subscribe(
      {
        next: (response) => {
          console.log(response);
          const data = response.body;
          this.zoneList = [{ name: 'Select State', id: '' },
            ...data.map((item: any) => ({
              id: item.zone,
              name: item.zone
            }))];
        }, error(error) {
          console.error("Error fetching dropdown data", error);
        },
      }
    );
  }

  onPincodeAndLocationChange(pincodeAndLocation: any) {
    this.selectedLocation = pincodeAndLocation.name.split(']')[1];
    this.selectedPincode = pincodeAndLocation.id;
  }


  onZoneChange(zoneObj: any): void {
    const isExistingValue = this.zoneList.some(
      item => item.id === zoneObj || item.name === zoneObj
    );

    if (isExistingValue) {
      this.selectedZone = zoneObj;
      this.valueChange.emit(zoneObj);
    } else {
      const newValue = { id: zoneObj, name: zoneObj };
      this.selectedZone = zoneObj;
      if (zoneObj) {
        this.zoneList.push(newValue);
      }
      this.valueChange.emit(zoneObj);
    }

  }

  onSave() {
    if (this.selectedState && this.selectedLocation && this.selectedZone) {
      this.zoneLocationMappingService.saveZoneMappingData(this.selectedState, this.selectedZone, this.selectedLocation, this.selectedPincode).subscribe({
        next: (response) => {
          this.alertData = { alertMessage: 'Data added Successfully', alertVisible: 'success' };
          this.loadGridData();
        },
        error: (error) => {
          this.alertData = { alertMessage: 'Error while saving data', alertVisible: 'error' };
          console.log('er', error);
        }
      });
    } else {
      this.alertData = { alertMessage: 'Please Select the State, Zone, pincode and location', alertVisible: 'error' };
    }
  }

  openUploadPopup() {
    this.uploadPopup = true;
  }

  public closePopup(): void {
    this.alertData = { alertMessage: '', alertVisible: '' };
    this.uploadPopup = false;
    this.loadGridData();
  }

  deleteZoneMaping(value: any) {
    this.alertData = { alertMessage: '', alertVisible: '' };
    this.zoneLocationMappingService.deleteZoneMapping(value).subscribe({
      next: (response) => {
        this.alertData = { alertMessage: 'Data Deleted Successfully', alertVisible: 'success' };
        this.loadGridData();
      },
      error: (error) => {
        this.alertData = { alertMessage: error.error.Result, alertVisible: 'error' };
      }
    });
  }
}
