import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MinimumWagesMasterService {
  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getMinimumWage(): Observable<HttpResponse<any>>{
    const url = `${this.apiUrl}/api/MinimumWage/Get_LC_MW_Master`;
    return this.http.get(url, {observe:'response'});
  }

  getStateDropdown(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/StateMaster/Get_StateMaster`;
    return this.http.get(url, {observe:'response'});
  }
  getEstablishTypeDropdown(filter: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MinimumWage/Get_LC_MW_IndustryType_ByState/${filter}`;
    return this.http.get(url, {observe:'response'});
  }
  getNotificationIdDropdown(filter: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MinimumWage/Get_LC_MW_MasterNotification_Statewise/${filter}`;
    return this.http.get(url, {observe:'response'});
  }
  getIndustryTypeDropdown(State:string,Industrytype: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MinimumWage/Get_LC_MW_Industry_ByState/${State}/${Industrytype}`;
    return this.http.get(url, {observe:'response'});
  }
  getNotificationIDDropdown(Industry: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MinimumWage/Get_LC_MW_MasterNotification_Industrywise/${Industry}`;
    return this.http.get(url, {observe:'response'});
  }
  getSkillCategoryDropdown(state: string,IndustryType:string,Industry:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MinimumWage/Get_LC_MW_SkillCategory_ByState/${state}/${IndustryType}/${Industry}`;
    return this.http.get(url, {observe:'response'});
  }
  getDesignationDropdown(state: string,IndustryType:string,Industry:string,Skill:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MinimumWage/Get_LC_MW_Designation_ByState/${state}/${IndustryType}/${Industry}/${Skill}`;
    return this.http.get(url, {observe:'response'});
  }
  getZoneDropdown(state: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MinimumWage/Get_LC_MW_ZoneLocation_Mapping_ByState/${state}`;
    return this.http.get(url, {observe:'response'});
  }
  deleteMWMaster(id: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MinimumWage/Delete_LC_MW_Master/${id}`;
    return this.http.get(url, {observe:'response'});
  }
    saveMWMaster(body:any): Observable<HttpResponse<any>>{
    const value={
      id: body.id,
      notification_Id: body.notification_Id,
      notification_Number: body.notification_Number,
      industryNotification_Id: body.industryNotification_Id,
      industryNotification_Number: body.industryNotification_Number,
      state: body.state,
      sM_Name: body.sM_Name,
      industry_Type: body.industry_Type,
      industry: body.industry,
      skill_Category: body.skill_Category,
      designation: body.designation,
      zone: body.zone,
      status: body.status,
      mW_MasterId: body.mW_MasterId,
      mW_Value:parseInt(body.mW_Value, 10),
      basic: body.basic,
      da: body.da,
      hra: body.hra,
      vda: body.vda,
      spl_Allowance: body.spl_Allowance
    }
    console.log('service',value)
    const url = `${this.apiUrl}/api/MinimumWage/Add_Update_LC_MW_Master`;
    return this.http.post(url,value,{observe:'response'})
  }
  uploadFile(state: string,industrytype:string,notification:string,zone:string,file: File): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MinimumWage/Upload_LC_MW_Master?state=${state}&industry_type=${industrytype}&notification_id=${notification}&zone=${zone}`;
    const formData: FormData = new FormData();
    formData.append('file', file, file.name); 
    return this.http.post<any>(url, formData,{observe:'response'});
  }

  getMWTemplate(state: any,industry_Type:string): Observable<HttpResponse<any>> {
    const body={
     state: state,
     industry_type: industry_Type
    }
    const url = `${this.apiUrl}/api/MinimumWage/Get_MW_Template`;
    return this.http.post<any>(url, body,{observe:'response'});
  }

}
