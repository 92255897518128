import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { MultiSelectDropdownComponent } from '../../../components/multi-select-dropdown/multi-select-dropdown.component';
import { NgClass, NgIf } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { IndustrySkillDesignationMappingService } from '../../../service/industry-skill-designation-mapping.service';
import { ComboBoxFreeTextComponent } from '../../../components/combo-box-free-text/combo-box-free-text.component';


@Component({
  selector: 'app-industry-skill-add-new-popup',
  standalone: true,
  imports: [DialogModule, AlertPopupComponent, ReactiveFormsModule, NgIf, MultiSelectDropdownComponent, DropdownListComponent, ComboBoxComponent, ComboBoxFreeTextComponent, NgClass],
  templateUrl: './industry-skill-add-new-popup.component.html',
  styleUrl: './industry-skill-add-new-popup.component.css'
})
export class IndustrySkillAddNewPopupComponent implements OnInit {
  @Output() valueChange = new EventEmitter<any>();
  public stateDropdownData: any[] = [];
  public EstablishDropdownData: any[] = [];
  public SkillCategoryDropdownData: any[] = [];
  public DesignationDropdownData: any[] = [];
  public IndustryTypeDropdownData: any[] = [];
  industryType = false;
  public selectedIndustryType: string = '';
  public selectedState: string = '';
  searchTerm: string = '';
  public selectedEstablishment: string = '';
  public selectedDesignation: string = '';
  public selectedSkills: string = '';
  public isFormValid: boolean = false;
  public showValidation: boolean = false;
  public showEstValidation: boolean = false;
  public showDesignationValidation: boolean = false;
  alertData = { alertMessage: '', alertVisible: '' };
  SkillCategory = { text: 'Select Skill Category', value: '' };
  filteredStateDropdownData: { id: string; name: string }[] = [];
  @Output() close = new EventEmitter<void>();
  public defaultFilterValue!: any;
  @ViewChild('dialogTitle') dialogTitle!: ElementRef;
  SkillCategorydropdownData = [
    { text: 'Select Skill Category', value: '' },
    { text: 'Highly Skilled', value: 'HIGHLY SKILLED' },
    { text: 'Skilled', value: 'SKILLED' },
    { text: 'Semiskilled', value: 'SEMISKILLED' },
    { text: 'Unskilled', value: 'UNSKILLED' }
  ];
  constructor(private IndustrySkillService: IndustrySkillDesignationMappingService) { }
  ngOnInit(): void {
    this.SkillCategoryDropdown('');
    this.DesignationDropdown('');
    this.EstablishTypeDropdown('');
    this.StateDropdown();
    this.filteredStateDropdownData = [...this.stateDropdownData];
  }
  ngAfterViewInit() {
    const titleElement = this.dialogTitle.nativeElement;
    titleElement.style.textAlign = 'center';
    titleElement.style.width = '100%';
  }

  OnSave() {
    debugger;
    this.showValidation = true;
    this.showEstValidation = true;
    this.showDesignationValidation=true;
    if (this.selectedState && this.selectedEstablishment && this.SkillCategory.value && this.selectedDesignation) {
      if (this.industryType && this.selectedIndustryType) {
        this.IndustrySkillService.SaveIndustry(this.selectedState, this.selectedEstablishment, this.selectedIndustryType, this.selectedSkills, this.selectedDesignation).subscribe({
          next: (response) => {
            if (response.status == 200) {
              this.alertData = { alertMessage: 'Data added Successfully', alertVisible: 'success' };
              
            } else {
              this.alertData = { alertMessage: 'Failed to add Data', alertVisible: 'error' };
            }
          }, error(error) {
            console.error('Error:', error);
          }
        });
      } else if (!this.industryType) {
        this.selectedIndustryType='';
        this.IndustrySkillService.SaveIndustry(this.selectedState, this.selectedEstablishment, this.selectedIndustryType, this.selectedSkills, this.selectedDesignation).subscribe({
          next: (response) => {
            if (response.status == 200) {
              this.alertData = { alertMessage: 'Data added Successfully', alertVisible: 'success' };
            } else {
              this.alertData = { alertMessage: 'Failed to add Data', alertVisible: 'error' };
            }
          }, error(error) {
            console.error('Error:', error);
          }
        });
      } else {
        this.alertData = { alertMessage: 'Please fill all the fields', alertVisible: 'error' };
      }
    } else {
      //this.alertData = { alertMessage: 'Please fill all the fields', alertVisible: 'error' };
    }
  }
  EstablishValueChange(value: any): void {
    const isExistingValue = this.EstablishDropdownData.some(
      item => item.id === value || item.name === value
    );  
  
    if (isExistingValue) {
      const selectedItem = this.EstablishDropdownData.find(
        item => item.id === value || item.name === value
      );      
      this.selectedEstablishment = selectedItem;
      this.industryType = selectedItem?.name === 'FACT';
      this.industryType=true;
      this.valueChange.emit(selectedItem);
    } else {
      const newValue = { id: value, name: value };
      this.selectedEstablishment = newValue.name;
      this.industryType = newValue.name === 'FACT';
      if (value) {
        this.EstablishDropdownData.push(newValue);
      }
      this.valueChange.emit(newValue);
    }  
    this.showEstValidation = !this.selectedEstablishment;
  }
  EstablishTypeDropdown(value: any) {
    this.IndustrySkillService.getEstablishTypeDropdown(value).subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && Array.isArray(data)) {
            this.EstablishDropdownData = data.map((item: any) => ({
              id: item.key,
              name: item.value
            }));
          } else {
            console.error("Unexpected data format", data);
          }
        }, error(error) {
          console.error("Error fetching dropdown data", error);
        },
      }
    );
  }

  IndustryTypeDropdown(value: any) {
    this.IndustrySkillService.getIndustryTypeDropdown(value).subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && Array.isArray(data)) {
            this.IndustryTypeDropdownData = data.map((item: any) => ({
              id: item.key,
              name: item.value
            }));
            console.log('industry dropdown',this.IndustryTypeDropdownData)
          } else {
            console.error("Unexpected data format", data);
          }
        }, error(error) {
          console.error("Error fetching dropdown data", error);
        },
      }
    );
  }

  IndustryChange(value: any): void {
    const isExistingValue = this.IndustryTypeDropdownData.some(
      item => item.id === value || item.name === value
    );
    if (isExistingValue) {
      this.selectedIndustryType = value;
      this.valueChange.emit(value);
    } else {
      const newValue = { id: value, name: value };
      this.selectedIndustryType = value;
      if (value) {
        this.IndustryTypeDropdownData.push(newValue);
      }
      this.valueChange.emit(value);
    }
    this.showValidation = !this.selectedIndustryType;
  }

  SkillCategoryDropdown(value: any) {
    this.IndustrySkillService.getSkillCategoryDropdown(value).subscribe(
      {
        next: (response) => {
          const data = response.body;

          this.SkillCategoryDropdownData = data.map((item: any) => ({
            id: item.id,
            name: item.skills
          }));
          console.error("Unexpected data format", data);
        }, error(error) {
          console.error("Error fetching dropdown data", error);
        },
      }
    );
  }

  SkillCategoryValueChange(value: any) {
    this.SkillCategory = { text: value.text, value: value.value };
    // if (this.SkillCategory) {
    //   this.showValidation = false;
    // }
    // this.showValidation = true;
  }

  DesignationDropdown(value: any) {
    this.IndustrySkillService.getDesignationDropdown(value).subscribe(
      {
        next: (response) => {
          const data = response.body;
          this.DesignationDropdownData = data.map((item: any) => ({
            id: item.key,
            name: item.value
          }));
        }, error(error) {
          console.error("Error fetching dropdown data", error);
        },
      }
    );
  }

  DesignationValueChange(value: any): void {
    const isExistingValue = this.DesignationDropdownData.some(
      item => item.id === value || item.name === value
    );
    if (isExistingValue) {
      this.selectedDesignation = value;
      this.valueChange.emit(value);
    } else {
      const newValue = { id: value, name: value };
      this.selectedDesignation = value;
      if (value) {
        this.DesignationDropdownData.push(newValue);
      }
      this.valueChange.emit(value);
    }
    this.showDesignationValidation = !this.selectedDesignation;
  }

  StateDropdown() {
    this.IndustrySkillService.getStateDropdown().subscribe(
      {
        next: (response) => {
          console.log(response);
          const data = response.body;
          this.stateDropdownData = data.map((item: any) => ({
            id: item.sM_Code,
            name: item.sM_Name
          }));
          this.filteredStateDropdownData = this.stateDropdownData;
        }, error(error) {
          console.error("Error fetching dropdown data", error);
        },
      }
    );
  }
  filterStates(event: any) {
    const searchTermLower = event.target.value.toLowerCase();

    if (searchTermLower && searchTermLower.length > 0) {
      this.filteredStateDropdownData = this.stateDropdownData.filter(item =>
        item.name.toLowerCase().startsWith(searchTermLower)
      );
    } else {
      this.filteredStateDropdownData = [...this.stateDropdownData];
    }
  }
  StateValueChange(value: any) {
    this.selectedState = value.id;
  }
  closeAlert() {
    this.alertData = { alertMessage: '', alertVisible: '' };
  }

  closePopup() {
    this.close.emit();
  }
}
