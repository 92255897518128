<kendo-grid class="custom-grid " [kendoGridBinding]="gridData.data" [columnMenu]="true" [pageSize]="pageSize" [pageable]="true" [height]="height" 
    [filter]="filter" (pageChange)="pageChange($event)" filterable="menu" (filterChange)="filterChange($event)" [sortable]="true" [resizable]="true"[loading]="loading">
    <kendo-grid-column *ngFor="let column of columns" [field]="column.field" [title]="column.title"
    [filter]="column.filter" [headerStyle]="{'font-size' : '3rem'}" [hidden]="column.hidden"
    [headerClass]="column.isIconColumn ? 'center-header' : (column.title === 'Action' ? 'center-action-header' : '')"
    [class]="column.isIconColumn ? 'center-column' : ''"
    [width]="column.title === 'Action' ? actionColumnWidth : column.width"
    [columnMenu]="column.title !== 'Action'">
    <ng-template kendoGridHeaderTemplate let-column>
        <div [title]="column.title" class="custom-tooltip">
            {{ column.title }}
        </div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container *ngIf="column.buttons; else checkIcons">
            <button *ngFor="let button of column.buttons"
            [ngStyle]="{ 'width.px': button.width, 'height.px': button.height, 'background': 'transparent', 'border': 'none', 'padding': '0', 'margin-left.px': button.marginleft }"
            (click)="onButtonClick(dataItem, button)">
            <img [src]="button.icon" [style.width.px]="button.width" [style.height.px]="button.height"
            alt="button-icon">
        </button>
    </ng-container>
    <ng-template #checkIcons>
        <ng-container *ngIf="column.filteredIcons; else defaultTemplate">
            <div style="display: flex; justify-content: center; align-items: center;">
                <button *ngFor="let icon of column.filteredIcons" class="icon-item">
                            <!-- <span class="k-icon k-font-icon {{ icon.className }}"
                            ></span> -->
                            <img [src]="icon.url" [alt]="icon.alt || 'icon'" class="svg-icon" [title]="icon.action"
                            (click)="onButtonClick(dataItem, icon)" />
                        </button>
                    </div>
                </ng-container>
            </ng-template>
            <ng-template #defaultTemplate>
                <div class="cell-content" [ngStyle]="{
                    'padding': column.filteredIcons ? '' : '6.5px'
                  }" [title]="dataItem[column.field]">
                    {{ dataItem[column.field] }}
                </div>
            </ng-template>
        </ng-template>
        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
            <ng-container *ngIf="column.title !== 'Action'">
                <kendo-grid-string-filter-menu *ngIf="column.filter === 'text'" [column]="column" [filter]="filter">
                </kendo-grid-string-filter-menu>
                <kendo-grid-numeric-filter-menu *ngIf="column.filter === 'numeric'" [column]="column" [filter]="filter">
                </kendo-grid-numeric-filter-menu>
                <kendo-grid-boolean-filter-menu *ngIf="column.filter === 'boolean'" [column]="column" [filter]="filter">
                </kendo-grid-boolean-filter-menu>
                <kendo-grid-date-filter-menu *ngIf="column.filter === 'date'" [column]="column" [filter]="filter">
                </kendo-grid-date-filter-menu>
                <multicheck-filter *ngIf="column.filter === 'multiselect'" [isPrimitive]="true" [field]="column.field"
                [filterService]="filterService" [currentFilter]="filter"
                [data]="getUniqueColumnValues(column.field)">
            </multicheck-filter>
        </ng-container>
    </ng-template>
</kendo-grid-column>
<ng-template kendoPagerTemplate let-totalPages="total" let-currentPage="currentPage">
    <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
    <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
    <kendo-pager-next-buttons>
    </kendo-pager-next-buttons>
    <kendo-pager-input></kendo-pager-input>
    <kendo-pager-info></kendo-pager-info>
    <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
</ng-template>

<ng-template *ngIf="loading" kendoGridLoadingTemplate >
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</ng-template>
</kendo-grid>