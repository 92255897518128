import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { PopupModule } from '@progress/kendo-angular-popup';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-multi-select-tree',
  standalone: true,
  imports: [CommonModule, TreeViewModule,FormsModule, PopupModule,NgIf,DropDownsModule],
  templateUrl: './multi-select-tree.component.html',
  styleUrls: ['./multi-select-tree.component.css']
})
export class MultiSelectTreeComponent {
  @Input() data: any[] = [];
  @Input() textField: string = 'text';
  @Input() valueField: string = 'value';
  @Input() selectedValues: any[] = [];
  @Output() selectedValuesChange = new EventEmitter<any[]>();

  onFilterChange(filter: string): void {
    // Implement filtering logic if needed
  }

  onOpen(): void {
    // Logic when dropdown opens
  }

  onClose(): void {
    // Logic when dropdown closes
  }

  isItemSelected(item: any): boolean {
    return this.selectedValues.includes(item[this.valueField]);
  }

  onCheckboxChange(event: any, item: any): void {
    if (event.target.checked) {
      this.selectedValues.push(item[this.valueField]);
    } else {
      const index = this.selectedValues.indexOf(item[this.valueField]);
      if (index >= 0) {
        this.selectedValues.splice(index, 1);
      }
    }
    this.selectedValuesChange.emit(this.selectedValues);
  }
  clearSelectedValues(): void {
    this.selectedValues = [];
    this.selectedValuesChange.emit(this.selectedValues);
  }
}
