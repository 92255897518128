import { Component } from '@angular/core';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { StatutoryMasterService } from '../../../service/statutory-master.service';
import { NgFor, NgIf } from '@angular/common';
import { GridModule, PageChangeEvent } from '@progress/kendo-angular-grid';
import { BlobService } from '../../../service/blob.service';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-statutory-master',
  standalone: true,
  imports: [DropdownListComponent, DropDownListModule, NgIf, GridModule, NgFor, ComboBoxComponent, IndicatorsModule],
  templateUrl: './statutory-master.component.html',
  styleUrl: './statutory-master.component.css'
})
export class StatutoryMasterComponent {
  public buttonCount = 2;
  loadingGrid = false;
  public sizes = [{ text: 'All', value: Infinity }, 5, 10, 20];
  public gridData: any = { data: [], total: 0 };
  public showGrid: boolean = false;
  public dropdownData = [
    { text: 'Compliance Type', value: 'Compliance Type' },
    { text: 'Challan', value: 'Challan' },
    { text: 'Register', value: 'Register' },
    { text: 'Returns', value: 'Returns' }
  ];
  public selectedDropdown: any = { id: null, name: null };
  public allStatesData: any[] = [];
  public defaultState = 'Search ComplianceID / Form'
  public defaultSelectedValue = { text: 'Compliance Type', value: 'Compliance Type' };
  public defaultSelectedid = { text: 'Search ComplianceID / Form', value: 'Search ComplianceID / Form' };
  public applyFilterEvent!: string;
  public parentColumns: any[] = [];
  take = 10;
  skip = 0;
  page = 1;
  public pageSize: number = 10;
  constructor(private statutoryMasterService: StatutoryMasterService, private blobService: BlobService) { }
  loading: boolean = false;

  onDropdownValueChange(event: any) {
    console.log('statutary', event);
    this.applyFilterEvent = event.value;
    this.showGrid = false;
    if (event.value == 'Challan') {
      this.getDropDownData('CHA');
    } else if (event.value == 'Register') {
      this.getDropDownData('REG');
    } else if (event.value == 'Returns') {
      this.getDropDownData('Ret');
    }
  }

  public pageChange(event: PageChangeEvent): void {
    console.log('Page Change Event Received:', event);
    this.skip = event.skip;
    this.loadingGrid = true;
    if (event.take == Infinity) {
      this.pageSize = this.gridData.total;
    }
    else {
      this.pageSize = event.take;
    }
    this.applyFilter();
  }
  DropdownGridChange(event: any) {
    console.log(event)
    this.selectedDropdown = { id: event.id, name: event.name };
  }
  clearFilter() {
    this.showGrid = false;
    this.applyFilterEvent = ''
    this.selectedDropdown = null;
    this.defaultSelectedValue = { text: 'Compliance Type', value: 'Compliance Type' };
  }

  applyFilter() {
    console.log('applyFilter', this.applyFilterEvent);
    if (this.applyFilterEvent == 'Challan') {
      this.getChallan(this.selectedDropdown && this.selectedDropdown.id ? this.selectedDropdown.id : '');
    } else if (this.applyFilterEvent == 'Returns') {
      this.getReturns(this.selectedDropdown && this.selectedDropdown.id ? this.selectedDropdown.id : '');
    } else if (this.applyFilterEvent == 'Register') {
      this.getRegister(this.selectedDropdown && this.selectedDropdown.id ? this.selectedDropdown.id : '');
    }
  }
  getDropDownData(filter: string): void {
    console.log('Fetching state masters with filter:', filter);
    this.statutoryMasterService.getDropdownData(filter).subscribe({
      next: (response) => {
        if (response.status === 200) {
          const data = response.body;
          this.allStatesData = data.data.map((item: any) => ({
            id: item.key,
            name: item.value
          }));
          this.defaultState = 'Select State/Location Code';
        }
        else {
          console.error('Error fetching state masters:',);
        }
      }
    })

  }

  getChallan(value: string) {
    this.parentColumns = [
      { field: 'avacoM_ComplianceID', title: 'CID', width: '10px' },
      { field: 'challanType', title: 'Challan Type', width: '10px' },
      { field: 'state_Code', title: 'StateID', width: '10px' },
      { field: 'isActive', title: 'IsActive', width: '10px' },
      { field: 'shortDescription', title: 'Short Description' },
      { field: 'description', title: 'Description' },
      { field: 'name', title: 'Act Name' },
      { field: 'inputs', title: 'Input' },
      { field: 'frequency', title: 'Frequency', width: '10px' },
      { field: 'rlcS_Frequency', title: 'RLCSFrequency', width: '10px' }
    ]
    this.statutoryMasterService.getAllChallanMasters(value, this.take, this.skip, this.page, this.pageSize).subscribe({
      next: (response) => {
        debugger;
        console.log('Response=>', response);

        if (response.status === 200) {
          const data = response.body;
          console.log('body', data);
          this.gridData = {
            data: data.data,
            total: data.total

          };
          this.loadingGrid = false;
          this.showGrid = true
        }

      },error:(error)=>{
        this.loadingGrid=false;
      }
    }
    )

  }

  getReturns(value: string) {
    this.parentColumns = [
      { field: 'avacoM_ComplianceID', title: 'CID' },
      { field: 'returnID', title: 'RID' },
      { field: 'rM_StateID', title: 'StateID' },
      { field: 'rM_Act', title: 'ACT' },
      { field: 'rM_Frequency', title: 'Frequency' },
      { field: 'rlcS_Frequency', title: 'RLCSFrequency' },
      { field: 'rM_Name', title: 'Form Name' },
      { field: 'inputs', title: 'Status' },
      { field: 'isActive', title: 'IsActive' },
      { field: 'rlcS_Frequency', title: 'Short Discription' },
      { field: 'description', title: 'Discription' },
      { field: 'name', title: 'Act Name' },
      { field: 'inputs', title: 'Inputs' },
      { field: 'coveringID', title: 'Covering ID' },
      { field: 'coveringName', title: 'Covering Name' }
    ]
    this.statutoryMasterService.getAllReturnsMasters(value, this.take, this.skip, this.page, this.pageSize).subscribe({
      next: (response) => {
        console.log('Response=>', response);

        if (response.status === 200) {
          const data = response.body;
          console.log('body', data);
          this.gridData = {
            data: data.data,
            total: data.total

          };
          this.loadingGrid = false;
          this.showGrid = true
        }

      },error:(error)=>{
        this.loadingGrid=false;
      }
    }
    )

  }

  getRegister(value: string) {
    this.parentColumns = [
      { field: 'avacoM_ComplianceID', title: 'CID' },
      { field: 'registerID', title: 'RID' },
      { field: 'register_Name', title: 'Form Name' },
      { field: 'stateID', title: 'StateID' },
      { field: 'actGroup', title: 'ACT' },
      { field: 'register_Status', title: 'Status' },
      { field: 'stateName', title: 'State Name' },
      { field: 'isActive', title: 'IsActive' },
      { field: 'shortDescription', title: 'Short Discription' },
      { field: 'description', title: 'Discription' },
      { field: 'name', title: 'Act Name' },
      { field: 'inputs', title: 'Inputs' },
      { field: 'frequency', title: 'Frequency' },
      { field: 'rlcS_Frequency', title: 'RLCSFrequency' }
    ]
    this.statutoryMasterService.getAllRegisterMasters(value, this.take, this.skip, this.page, this.pageSize).subscribe({
      next: (response) => {
        debugger;
        console.log('Response=>', response);

        if (response.status === 200) {
          const data = response.body;
          console.log('body', data);
          this.gridData = {
            data: data.data,
            total: data.total

          };
          this.loadingGrid = false;
          this.showGrid = true
        }

      },error:(error)=>{
        this.loadingGrid=false;
      }
    }
    )

  }

  exportToExcel(): void {
    this.loading = true;
    if (this.applyFilterEvent == 'Challan') {
      this.statutoryMasterService.getChallanExcel().subscribe({
        next: (response: any) => {
          console.log('response', response);

          if (response.status === 200) {
            const data = response.body
            const blobLink = data.filePath;
            const blobName = this.blobService.extractBlobName(blobLink);
            setTimeout(() => {
              this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
                console.log('tokenResponse', tokenResponse);

                debugger;
                const sasToken = tokenResponse.result;
                this.blobService.downloadFile(blobName, sasToken);
                this.loading = false;
              });
            }, 5000);
          }
          else {
            console.error('Error fetching blob link:', response.body.error);

            this.loading = false;
          }
        }
      })


    } else if (this.applyFilterEvent == 'Returns') {
      this.statutoryMasterService.getReturnExcel().subscribe({
        next: (response: any) => {
          console.log('response', response);

          if (response.status === 200) {
            const data = response.body
            const blobLink = data.filePath;
            const blobName = this.blobService.extractBlobName(blobLink);
            setTimeout(() => {
              this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
                console.log('tokenResponse', tokenResponse);

                debugger;
                const sasToken = tokenResponse.result;
                this.blobService.downloadFile(blobName, sasToken);
                this.loading = false;
              });
            }, 5000);
          }
          else {
            console.error('Error fetching blob link:', response.body.error);

            this.loading = false;
          }
        }
      })

    } else if (this.applyFilterEvent == 'Register') {
      this.statutoryMasterService.getRegisterExcel().subscribe({
        next: (response: any) => {
          console.log('response', response);

          if (response.status === 200) {
            const data = response.body
            const blobLink = data.filePath;
            const blobName = this.blobService.extractBlobName(blobLink);
            setTimeout(() => {
              this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
                console.log('tokenResponse', tokenResponse);

                debugger;
                const sasToken = tokenResponse.result;
                this.blobService.downloadFile(blobName, sasToken);
                this.loading = false;
              });
            }, 5000);
          }
          else {
            console.error('Error fetching blob link:', response.body.error);

            this.loading = false;
          }
        }
      })


    }
  }

}
