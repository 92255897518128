<kendo-dialog #dialogRef title="Add New Scope" (close)="closePopup()"  [maxHeight]="585" [width]="1100"
    class="custom-dialog">
    <div class="dialog-content-wrapper colpadding0" >
        <div class="row col-12 dialog-content colpadding0">

            <form [formGroup]="form">

                <div class="row  MasterContainer">
                    <div class="col-md-6 d-flex align-items-center">
                        <label class="f-label me-3">Entities</label>
                        <div class="require-container" [ngClass]="{'invalid-border': form.get('entities')?.invalid && form.get('entities')?.touched}">
                         <kendo-multiselecttree #multiselectTree placeholder="Select Entities"
                            kendoMultiSelectTreeExpandable [kendoMultiSelectTreeHierarchyBinding]="EntityDropdown"  formControlName="entities"
                            textField="name" valueField="id" [tagMapper]="tagMapper"
                            (valueChange)="OnEntitySelectChange($event)" [expandedKeys]="['0']"
                            [popupSettings]="popupSettings" class="f-textbox multi-box">
                        </kendo-multiselecttree>                              
                            
                        </div>
                    </div>
                    <div class="col-md-6 d-flex align-items-center">
                        <div class="entity-container d-flex flex-column">
                            <div class="selected-values">
                                <img *ngIf="!showEntities"  src="../assets/vectors/Open.svg" alt=""
                                    (click)="toggle('Entity')">
                                <img *ngIf="showEntities"  src="../assets/vectors/Closeminus.svg" alt=""
                                    (click)="toggle('Entity')">
                                <span>Selected Entities</span>
                            </div>
                            <div *ngIf="showEntities" class="selected-container">
                                <div *ngFor="let entity of selectedEntities" class="selected-entity">
                                    {{ entity.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row  MasterContainer">
                    <div class="col-md-6 d-flex align-items-center">
                        <label class="f-label me-3">States</label>
                        <div class="require-container" [ngClass]="{'invalid-border': form.get('states')?.invalid && form.get('states')?.touched}">
                          
                            <kendo-multiselecttree #multiselectTree placeholder="Select States"
                                kendoMultiSelectTreeExpandable [kendoMultiSelectTreeHierarchyBinding]="StateDropdown" formControlName="states"
                                textField="name" valueField="id" [tagMapper]="tagMapper"
                                (valueChange)="OnMultiSelectStateChange($event)" [expandedKeys]="['0']"
                                [popupSettings]="popupSettings" class="f-textbox multi-box">
                            </kendo-multiselecttree>
                        </div>
                    </div>
                    <div class="col-md-6 d-flex align-items-center">
                        <div class="entity-container d-flex flex-column">
                            <div class="selected-values">
                                <img *ngIf="!showStates"  src="../assets/vectors/Open.svg" alt=""
                                    (click)="toggle('State')">
                                <img *ngIf="showStates"  src="../assets/vectors/Closeminus.svg" alt=""
                                    (click)="toggle('State')">
                                <span>Selected States</span>
                            </div>
                            <div *ngIf="showStates" class="selected-container">
                                <div *ngFor="let state of selectedStates" class="selected-entity">
                                    {{ state.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row  MasterContainer">
                    <div class="col-md-6 d-flex align-items-center">
                        <label class="f-label me-3">Service Type</label>
                        <div class="require-container" [ngClass]="{'invalid-border': form.get('service')?.invalid && form.get('service')?.touched}">
                            <app-dropdown-list [data]="services" [selectedValue]="selectedService" textField="text" formControlName="service"
                                valueField="value" [width]="'250px'" (valueChange)="onServiceChange($event)">
                            </app-dropdown-list>

                        </div>
                    </div>
                </div>

                <div class="row  MasterContainer">
                    <div class="col-md-6 d-flex align-items-center">
                        <label class="f-label me-3">Mandate</label>
                        <div class="require-container" [ngClass]="{'invalid-border': form.get('mandate')?.invalid && form.get('mandate')?.touched}">
                            <app-dropdown-list [data]="mandate" [selectedValue]="selectedMandate" textField="text" formControlName="mandate"
                                valueField="value" [width]="'250px'" (valueChange)="onMandateChange($event)">
                            </app-dropdown-list>
                        </div>
                    </div>
                    <div class="col-md-6 d-flex align-items-center">
                    </div>
                </div>

                <div class="row  MasterContainer">
                    <div class="col-md-6 d-flex align-items-center">
                        <label class="f-label me-3">Activity</label>
                        <div class="require-container" [ngClass]="{'invalid-border': form.get('activity')?.invalid && form.get('activity')?.touched}">
                            <kendo-multiselecttree #multiselectTree placeholder="Select Activity"
                                kendoMultiSelectTreeExpandable [kendoMultiSelectTreeHierarchyBinding]="ActivityDropdown" formControlName="activity"
                                textField="name" valueField="id" (valueChange)="OnActivitySelectChange($event)"
                                [expandedKeys]="['0']" [popupSettings]="popupSettings" class="f-textbox multi-box">
                            </kendo-multiselecttree>
                        </div>
                    </div>
                    <div class="col-md-6 d-flex align-items-center">
                        <!-- <div class="col-md-6 d-flex align-items-center">
                            <div class="entity-container d-flex flex-column">
                                <div class="selected-values">
                                    <img *ngIf="!showStates"  src="../assets/vectors/Open.svg" alt=""
                                        (click)="toggle('State')">
                                    <img *ngIf="showStates"  src="../assets/vectors/Closeminus.svg" alt=""
                                        (click)="toggle('State')">
                                    <span>Selected States</span>
                                </div>
                                <div *ngIf="showStates" class="selected-container">
                                    <div *ngFor="let state of selectedStates" class="selected-entity">
                                        {{ state.name }}
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div class="entity-container d-flex flex-column">
                            <div class="selected-values">
                                <img *ngIf="!showActivities" src="../assets/vectors/Open.svg" alt=""
                                    (click)="toggle('Activity')">
                                <img *ngIf="showActivities"  src="../assets/vectors/Closeminus.svg" alt=""
                                    (click)="toggle('Activity')">
                                <span>Selected Activities</span>
                            </div>
                            <div *ngIf="showActivities" class="selected-container">
                                <div *ngFor="let activity of selectedActivities" class="selected-entity">
                                    {{ activity.name }}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row  MasterContainer">
                    <div class="col-md-6 d-flex align-items-center">
                        <label class="f-label me-3">Frequency</label>
                        <div class="require-container">

                            <button class="figma-btn-blue map-btn" (click)="mapFrequency('Add Popup')" formControlName="frequency">Map
                                Frequency
                            </button>
                            <div *ngIf="form.get('frequency')?.invalid && form.get('frequency')?.touched" class="error-message text-danger">
                                Required
                            </div>
                        </div>
                        </div>
                    <div class="col-md-6 d-flex align-items-center">
                    </div>
                </div>

                <div class="row  MasterContainer">
                    <div class="col-md-6 d-flex align-items-center">
                        <label class="f-label me-3">Nature of Act</label>
                        <div class="require-container" [ngClass]="{'invalid-border': form.get('nature')?.invalid && form.get('nature')?.touched}">
                            <kendo-multiselecttree #multiselectTree placeholder="Select Nature of Acts"
                                kendoMultiSelectTreeExpandable [kendoMultiSelectTreeHierarchyBinding]="NatureDropdown" formControlName="nature"
                                textField="name" valueField="id" (valueChange)="OnNatureSelectChange($event)"
                                [expandedKeys]="['0']" [popupSettings]="popupSettings" class="f-textbox multi-box">
                            </kendo-multiselecttree>
                        </div>
                    </div>
                    <div class="col-md-6 d-flex align-items-center">
                        <div class="entity-container d-flex flex-column">
                            <div class="selected-values">
                                <img *ngIf="!showNature"  src="../assets/vectors/Open.svg" alt=""
                                    (click)="toggle('Nature')">
                                <img *ngIf="showNature"  src="../assets/vectors/Closeminus.svg" alt=""
                                    (click)="toggle('Nature')">
                                <span> Selected Nature of Acts</span>
                            </div>
                            <div *ngIf="showNature" class="selected-container">
                                <div *ngFor="let nature of selectedNature" class="selected-entity">
                                    {{ nature.name }}
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <div class="row  MasterContainer">
                    <div class="col-md-6 d-flex align-items-center">
                        <label class="f-label me-3">Act Type</label>
                        <div class="require-container" [ngClass]="{'invalid-border': form.get('acts')?.invalid && form.get('acts')?.touched}">
                            <kendo-multiselecttree #multiselectTree class="custom-multiselect-tree" placeholder="Select Acts"
                                kendoMultiSelectTreeExpandable [kendoMultiSelectTreeHierarchyBinding]="ActDropdown" formControlName="acts"
                                textField="name" valueField="id" (valueChange)="OnActSelectChange($event)"
                                [expandedKeys]="['0']" [popupSettings]="popupSettings" class="f-textbox multi-box">
                            </kendo-multiselecttree>
                        </div>
                    </div>
                    <div class="col-md-6 d-flex align-items-center">
                        <div class="entity-container d-flex flex-column">
                            <div class="selected-values">
                                <img *ngIf="!showAct"  src="../assets/vectors/Open.svg" alt="" (click)="toggle('Act')">
                                <img *ngIf="showAct"  src="../assets/vectors/Closeminus.svg" alt=""
                                    (click)="toggle('Act')">
                                <span> Selected Acts</span>
                            </div>
                            <div *ngIf="showAct" class="selected-container">
                                <div *ngFor="let act of selectedActs" class="selected-entity">
                                    {{ act.name }}
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

                <div>
                    <button class="figma-btn-blue save-btn" (click)="saveScope()">Save</button>
                </div>

            </form>
        </div>
    </div>
</kendo-dialog>
<div kendoDialogContainer *ngIf="openFrequency">
    <app-master-scoping-map-frequency (close)="closeFrequencyDialog()" [selectedActivities]="selectedActivities"
        [popupType]="popupType">

    </app-master-scoping-map-frequency>
</div>

<app-alert-popup *ngIf="alertData" [Width]="270" [Data]="alertData"
    (close)="closePopup()"></app-alert-popup>