<kendo-dialog #dialogRef title="PO Number Generation" (close)="closePopup()" [minWidth]="400" [width]="1200"
  class="custom-dialog">
  <div class="row dialog-content">




    <app-dropdown-list [data]="complianceType" [selectedValue]="selectedComplianceType" [width]="'200px'"
      class="space-setting" textField="text" valueField="value" (valueChange)="oncomplianceTypeChange($event)"
      [class.input-invalid]="!selectedComplianceType.value && showValidationErrors">
      <div *ngIf="!selectedComplianceType.value && showValidationErrors" class="validation-error">
      </div>
    </app-dropdown-list>




    <kendo-multiselecttree #multiselectTree placeholder="Entity/Sub-Entity/Location" kendoMultiSelectTreeExpandable
      [kendoMultiSelectTreeHierarchyBinding]="BranchDropdown" childrenField="children" textField="name" valueField="id"
      [tagMapper]="tagMapper" (valueChange)="onMultiSelectChange($event)" [expandedKeys]="['0']" class="f-textbox "
      style="width:220px">
    </kendo-multiselecttree>

    <app-multi-select-dropdown [data]="dropdownDataMonth" [selectedValues]="defaultSelectedValueMonth" [width]="'120px'"
      style="margin-left:10px;" [height]="'34px'" textField="displayvalue" valueField="value" [placeholder]="'Period'"
      (valueChange)="onPeriod($event)">
    </app-multi-select-dropdown>


    <app-multi-select-dropdown [data]="years" [selectedValues]="selectedYear" [width]="'120px'" textField="text"
      style="margin-left:10px" valueField="value" (valueChange)="onYearChange($event)" formControlName="year"
      [placeholder]="'Year'">
    </app-multi-select-dropdown>



    <button class="figma-btn-white apply-btn" type="submit">
      <img src="../assets/vectors/ApplyFilter.svg" alt="">
    </button>

    <button class="exportbtn">
      Branch-code

    </button>

    <button class="exportbtn" >
      <img src="../assets/vectors/ExportButton.svg" alt="">

    </button>

  </div>










  <kendo-grid [data]="gridData" style="margin-top: 10px;" [pageSize]="pageSize" [pageable]="true" class="custom-grid"
    (pageChange)="pageChange($event)" [skip]="skip">
    <kendo-grid-column field="eM_EmpID" title="EM_EmpID">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div [title]="dataItem.eM_EmpID" class="cell-content">
          {{dataItem.eM_EmpID}}
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="eM_EmpName" title="EM_EmpName">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div [title]="dataItem.eM_EmpName" class="cell-content">
          {{dataItem.eM_EmpName}}
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="eM_Branch" title="EM_Branch">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div [title]="dataItem.eM_Branch" class="cell-content">
          {{dataItem.eM_Branch}}
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="eM_Location" title="EM_Location">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div [title]="dataItem.eM_Location" class="cell-content">
          {{dataItem.eM_Location}}
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="municipalityName" title="PT Location">
      <!-- <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-dropdownlist
              [data]="municipalities"  
              textField="text"
              valueField="value"
              (valueChange)="onMunicipalityChange($event, dataItem)"
              class="custom-dropdown">
      
            </kendo-dropdownlist>
            </ng-template> -->
    </kendo-grid-column>
    <kendo-grid-column field="action" title="Action" [width]="80">
      <!-- <ng-template kendoGridCellTemplate let-dataItem>     
            <img src="../assets/vectors/save1.svg" class="save-icon" alt="" (click)="onSaveClick(dataItem)">    
        </ng-template> -->
    </kendo-grid-column>
    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-input></kendo-pager-input>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>
  </kendo-grid>
</kendo-dialog>
<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270" [Data]="alertData"
  (close)="closeAlert()"></app-alert-popup>