import { Component, OnInit } from '@angular/core';
import {  GridDataResult, GridModule, PageChangeEvent } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { SealSignatureUploadPopupComponent } from '../../popups/seal-signature-upload-popup/seal-signature-upload-popup.component';
import { AuthorityUploadPopupComponent } from '../../popups/authority-upload-popup/authority-upload-popup.component';
import { LocationMasterServiceService } from '../../../service/location-master-service.service';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { UserService } from '../../../service/user.service';
import { LocationMasterAddNewPopupComponent } from '../../popups/location-master-add-new-popup/location-master-add-new-popup.component';
import { BlobService } from '../../../service/blob.service';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { ViewAddressPopupComponent } from '../../popups/view-address-popup/view-address-popup.component';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';

@Component({
  selector: 'app-location-master',
  standalone: true,
  imports: [GridActionItemComponent, FormsModule, NgIf, GridModule, SealSignatureUploadPopupComponent, AuthorityUploadPopupComponent, DropdownListComponent, DropDownsModule, ComboBoxComponent, LocationMasterAddNewPopupComponent,IndicatorsModule,ViewAddressPopupComponent,AlertPopupComponent],
  templateUrl: './location-master.component.html',
  styleUrls: ['./location-master.component.css']
})
export class LocationMasterComponent implements OnInit {
  public buttonCount = 2;
  public sizes = [{text:'All',value:1},5, 10, 20];
  public gridData: GridDataResult = { data: [], total: 0 };
  public pageSize: number = 10;
  public allStatesData: any[] = [];
  public selectedState: any = {id:null,name:null};
  public skip: number = 0;
  public uploadOpen: boolean = false
  public signatureOpen: boolean = false;
  Role!: string;
  public alertData = { alertMessage: '', alertVisible: '' };
  showAddNewPopup = false;
  public locationid?: number;
  public defaultState = "Select State/Location Code";
  loading=false;
  showAddressMappingPopup=false;
  Data!:string;
  constructor(private locationMaster: LocationMasterServiceService, private router: Router, private user: UserService,private blobService: BlobService) { }

  ngOnInit(): void {
    this.Role = this.user.getRole()
    this.fetchAllStatesData();
    this.fetchStateMasters('0');
  }
  AddressMappingPopup(value:any){
    this.Data=value;
    this.showAddressMappingPopup=true;
  }
  OpenAddNewPopup() {
    this.showAddNewPopup = true;
  }
  exportToExcel() {
    this.loading=true;
    const stateId = this.selectedState || '';
    this.locationMaster.getExportFile(stateId).subscribe({
      next: (response) => {
        const data = response.body;
        const blobLink = data.filePath;
        const blobName = this.blobService.extractBlobName(blobLink);
        setTimeout(() => {
          this.blobService.getSasToken(blobName).subscribe(
            (tokenResponse: any) => {
              try {
                const sasToken = tokenResponse.result;
                this.blobService.downloadFile(blobName, sasToken);
                this.loading = false;
              } catch (error) {
                this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
                this.loading = false;
              }
            },
            (error) => {
              this.alertData={alertMessage:'File Not Found',alertVisible:'error'};
              this.loading = false; 
            }
          );
        }, 5000);
      },error:(error)=>{
        console.log(error);
        this.loading = false;
      }
    }
    )
  }
  exportMpping() {
    this.loading=true;
    this.locationMaster.getExportMappingFile().subscribe({
      next: (response) => {
        const data = response.body;
        const blobLink = data.filePath;
        const blobName = this.blobService.extractBlobName(blobLink);
        setTimeout(() => {
          this.blobService.getSasToken(blobName).subscribe(
            (tokenResponse: any) => {
              try {
                const sasToken = tokenResponse.result;
                this.blobService.downloadFile(blobName, sasToken);
                this.loading = false;
              } catch (error) {
                this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
                this.loading = false;
              }
            },
            (error) => {
              this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
              this.loading = false;
            }
          );
        }, 5000);
      }, error: (error) => {
        this.loading = true;
        console.log(error);
      }
    }
    )
  }
  private fetchAllStatesData(): void {
    const stateId = this.selectedState && this.selectedState.id ? this.selectedState.id : ''; 
    this.locationMaster.getLocationList(stateId, Math.floor(this.skip / this.pageSize) + 1, this.pageSize, this.skip).subscribe({
      next: (response) => {
        if (response.status === 200) {
          const data = response.body;
          this.gridData = {
            data: data.data,
            total: data.total
          };
          if (this.gridData.data.length > 0) {
            this.locationid = this.gridData.data[0].avacoM_StateID;
          }
          
          this.loading=false;
        }
      }
    }
    )
  }


  fetchStateMasters(filter: string): void {
    this.locationMaster.getAllStateMasters(filter).subscribe({
      next: (response) => {
        if (response.status === 200) {
          const data = response.body;
          this.allStatesData = data.data.map((item: any) => ({
            id: item.value,
            name: item.key
          }));
          this.defaultState = 'Select State/Location Code';
        }
      }
    });
  }

  DropdownGridChange(value: any) {
    if (value == undefined) {
      this.fetchAllStatesData();
    }
    this.selectedState = {id:value.id,name:value.name};
  }


  public onFilterChange(event: any): void {
    if (event.length >= 3) {
      this.fetchStateMasters(event);
    } else {
      this.allStatesData = [];
    }
  }

  public pageChange(event: PageChangeEvent): void {
    this.loading=true;
    this.skip = event.skip;
    if(event.take==1){
      this.pageSize = this.gridData.total;
    }else{
      this.pageSize = event.take;
    }
    this.fetchAllStatesData();
  }

  public uploadDialog(): void {
    this.uploadOpen = true;
  }


  public applyFilter(): void {
    this.skip = 0;
    this.fetchAllStatesData();
  }

  public clearFilter(): void {
    this.selectedState = null; 
    this.defaultState = 'Select State/Location Code';
    this.skip = 0;
    this.fetchAllStatesData(); 
  }

  public closeUploadDialog(): void {
    this.uploadOpen = false;
    this.fetchAllStatesData();
  }

  signaturePopup(): void {
    this.signatureOpen = true;
  }

  closeSignaturePopup(): void {
    this.signatureOpen = false;
  }
  closePopup() {
    this.showAddNewPopup = false;
    this.showAddressMappingPopup = false;
  }
  closeAddNewPopup(){
    this.loading=true;
    this.showAddNewPopup = false;
    this.fetchAllStatesData();    
  }
  handleButtonClick(event: any) {
    if (event.button.action === "save") {
      this.signatureOpen = true;
    }
  }
  closeAlert() {
    this.alertData = { alertMessage: '', alertVisible: '' };
  }
}
