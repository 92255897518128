import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { KendoDatepickerComponent } from '../../../components/kendo-datepicker/kendo-datepicker.component';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { DatePipe, NgClass, NgIf } from '@angular/common';
import { DigitalSignatureService } from '../../../service/digital-signature.service';
import { UserService } from '../../../service/user.service';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';

@Component({
  selector: 'app-digital-signature-add-edit-popup',
  standalone: true,
  imports: [DialogsModule, ReactiveFormsModule, KendoDatepickerComponent, DropdownListComponent, NgIf, FormsModule, NgClass, AlertPopupComponent],
  templateUrl: './digital-signature-add-edit-popup.component.html',
  styleUrl: './digital-signature-add-edit-popup.component.css',
  providers: [DatePipe]
})
export class DigitalSignatureAddEditPopupComponent implements OnInit {
  form!: FormGroup;
  @Input() data: any;
  authorisedPerson: string = ''
  authorisedPersonId: string = ''
  designation: string = ''
  mobile!: number
  remark: string = ''
  selectionType: string = ''
  employees: { text: any, value: any }[] = []
  selectedEmployee = { text: 'Select Employee', value: '' }
  public placeholder: string = 'DD-MM-YYYY';
  public effectiveFrom!: Date | null;
  public effectiveTo!: Date | null;
  public statusType: { text: string, value: string }[] = [
    { text: 'Select', value: '' },
    { text: 'Active', value: 'Active' },
    { text: 'Inactive', value: 'Inactive' }
  ]
  selectedStatusType = { text: 'Select Status', value: '' };
  @Output() close = new EventEmitter<void>();
  alertData = { alertMessage: '', alertVisible: '' }
  constructor(private fb: FormBuilder, private digitalService: DigitalSignatureService, private user: UserService, private datePipe: DatePipe) {
    this.form = this.fb.group({
      authorisedPersonId: ['', [Validators.required]],
      authorisedPerson: ['', [Validators.required]],
      designation: ['', [Validators.required]],
      mobile: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      effectiveFrom: ['', [Validators.required]],
      effectiveTo: ['', [Validators.required]],
      remark: ['', [Validators.required]],
      statusType: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.selectionType = 'N'
    console.log('Row data', this.data);
    if (this.data && this.data.length !== 0) {
      this.predefinedData();
    } else {

      this.selectedStatusType = { text: 'Select', value: '' };
    }
    this.getEmployees()
  }

  onKeyPress(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  }
  onRadioChange(value: string) {
    this.selectionType = value;
    console.log('Selection Type', this.selectionType);

    if (this.selectionType === 'N') {

    } else if (this.selectionType === 'E') {

    }
  }
  getEmployees() {
    const clientId = this.user.getClientId();

    this.digitalService.getEmployees(clientId).subscribe({
      next: (response) => {
        const data = response.body;
        console.log('data', data);
        this.employees = data.map((item: any) => ({
          text: item.key,
          value: item.value
        }))
      }
    })
  }
  onSelectedEmployee(value: any) {
    this.selectedEmployee = { text: value.text, value: value.value }
    this.authorisedPerson = value.text
    this.authorisedPersonId = value.value
    this.form.get('authorisedPersonId')?.setValue(value.value);
    this.form.get('authorisedPersonId')?.updateValueAndValidity();
  }
  onDateChange(value: Date, type: string) {
    const date = new Date(value);

    if (type === 'effectiveFrom') {
      this.effectiveFrom = date;
      this.form.get('effectiveFrom')?.setValue(date);
      this.form.get('effectiveFrom')?.updateValueAndValidity();
    }
    else if (type === 'effectiveTo') {
      this.effectiveTo = date;
      this.form.get('effectiveTo')?.setValue(date);
      this.form.get('effectiveTo')?.updateValueAndValidity();
    }

  }
  onSelectedStatusType(value: any): void {
    this.selectedStatusType = value;
    this.form.get('statusType')?.setValue(value.value);
    this.form.get('statusType')?.updateValueAndValidity();
  }

  predefinedData() {
    this.selectionType = 'E'
    this.form.patchValue({
      authorisedPersonId: this.data.dsC_EmpId,
      authorisedPerson: this.data.dsC_EmpName,
      designation: this.data.dsC_EmpDesignation,
      effectiveFrom: this.data.dsC_EffectiveFrom,
      effectiveTo: this.data.dsC_EffectiveTo,
      mobile: this.data.dsC_Mobile,
      remark: this.data.dsC_Remarks,
      statusType: this.data.dsC_Status
    });

    this.authorisedPersonId = this.data.dsC_EmpId;
    this.authorisedPerson = this.data.dsC_EmpName;
    this.designation = this.data.dsC_EmpDesignation;
    this.mobile = this.data.dsC_Mobile;
    this.remark = this.data.dsC_Remarks;
    this.selectedStatusType = { text: this.data.dsC_Status, value: this.data.dsC_Status };
    this.effectiveFrom = this.data.dsC_EffectiveFrom ? new Date(this.data.dsC_EffectiveFrom) : null;
    this.effectiveTo = this.data.dsC_EffectiveTo ? new Date(this.data.dsC_EffectiveTo) : null;
  }


  saveData() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    const empid = this.authorisedPersonId
    const person = this.authorisedPerson
    const desig = this.designation
    const mob = this.mobile.toString()
    const efrom = this.effectiveFrom ? this.datePipe.transform(this.effectiveFrom, 'yyyy-MM-dd') : null;
    const eto = this.effectiveTo ? this.datePipe.transform(this.effectiveTo, 'yyyy-MM-dd') : null;
    const remark = this.remark
    const status = this.selectedStatusType.value
    const entityId = this.user.getClientId()
    const userId = this.user.getUserId().toString()

    console.log(empid, person, desig, mob, efrom, eto, remark, status, entityId, userId);


    this.digitalService.saveUpdateData(empid, person, desig, mob, efrom, eto, remark, status, entityId, userId).subscribe({
      next: (response) => {
        console.log('save response', response);
        this.alertData = { alertMessage: 'Recored Saved Successfully', alertVisible: 'success' };
      }
    })

  }
  closePopup(): void {
    this.close.emit();
  }
  public closeAlertPopup(): void {
    this.alertData.alertVisible = '';
  }
}
