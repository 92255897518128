<h4 class="Abstract-head">Abstract Summary</h4>
<div style="display: flex;
    justify-content: center;
    align-items: center;">
  <div>
    <kendo-chart style="height: 280px; width: 250px;">
      <ng-template kendoChartDonutCenterTemplate>
          <b style="font-size: 14px;">Totals</b>
          <h3 style="margin: 0; font-size: 14px;">{{totalAbstract}}</h3>
      </ng-template>
      <kendo-chart-tooltip>
          <ng-template
            kendoChartSeriesTooltipTemplate
            let-value="value"
            let-category="category"
            let-series="series"
          >
            {{ category }} : {{ value }}%
          </ng-template>
        </kendo-chart-tooltip>
      <kendo-chart-series>
        <kendo-chart-series-item
          type="donut"
          [data]="data"
          categoryField="kind"
          field="share"
          [holeSize]="45"
          [gap]="10"
          [startAngle]="50"
        >
          <kendo-chart-series-item-labels
            [content]="labelContent"
            color="#fff"
            background="none"
          >
          </kendo-chart-series-item-labels>
        </kendo-chart-series-item>
      </kendo-chart-series>
      <kendo-chart-legend [visible]="false">
          <kendo-chart-legend-item  cursor="pointer"></kendo-chart-legend-item>
      </kendo-chart-legend>
  
    </kendo-chart>
  </div>

  <div>
    <div class="status-card complied">
      <div class="status-color"></div>
      <div class="status-text">
          <span>Displayed</span>
          <h2>{{totalAbstract}} %</h2>
      </div>
  </div>
  
  <div class="status-card not-complied">
      <div class="status-color"></div>
      <div class="status-text">
          <span>Not Displayed</span>
          <h2>{{100 - totalAbstract}} %</h2>
      </div>
  </div>

  </div>
</div>