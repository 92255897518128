import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { UserService } from '../../../service/user.service';

import { CommonModule, NgIf } from '@angular/common';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { EcrReportService } from '../../../service/ecr-report.service';





@Component({
  selector: 'app-ecr-master-add-editor-popup',
  standalone: true,
  imports: [NgIf,CommonModule, ReactiveFormsModule, DialogModule, AlertPopupComponent],
  templateUrl: './ecr-master-add-editor-popup.component.html',
  styleUrl: './ecr-master-add-editor-popup.component.css'
})
export class EcrMasterAddEditorPopupComponent {

  @Output() close = new EventEmitter<void>();
  @Input() columns: any[] = [];
  userForm!: FormGroup;
  @Input() data: any;
  @Input() IsUpdate!: boolean;
  showPassword = false;
  passValue: string = '';
  alertData = { alertMessage: '', alertVisible: '' }
  usernameExists: boolean = false;
  clientId:string ='';
  @Input() dialogTitles: string = 'Add/Edit ESIC Subcode'; 

  
  constructor(private fb: FormBuilder,private user:UserService,public EcrReportService:EcrReportService) {
    this.userForm = this.fb.group({
      clientid:['', Validators.required],
      username: ['', Validators.required],
      password: ['', [Validators.required, Validators.maxLength(15)]],
      
    });
  }

  @ViewChild('dialogTitle') dialogTitle!: ElementRef;
  ngAfterViewInit() {
    const titleElement = this.dialogTitle.nativeElement;
    titleElement.style.textAlign = 'center';
    titleElement.style.width = '100%';
  }

  ngOnInit(): void {
    if (this.columns.length > 3) {
      console.log('Grid', this.columns[3].filteredIcons[0].action);
    }
    if (this.data && this.IsUpdate==true) {
      
      this.initUser();
    }
    else{
      this.userForm.patchValue({
        clientid:'',
        username: '',
        password: ''
      });
    }
  }
  initUser() {
    debugger
    this.userForm.patchValue(this.data);
    this.passValue = this.data?.originalPassword || '';
    this.clientId =this.data?.clientID || '';
    this.userForm.get('originalPassword')?.setValue(this.passValue);
    this.userForm.get('clientid')?.setValue(this.clientId);
  }
  checkUserId(event: FocusEvent) {
    debugger;
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    if (this.data.username != inputValue) {

      this.EcrReportService.isUserIdExists(inputValue).subscribe((res:any) => {
        debugger;
        this.usernameExists = res.body.result;
        if (this.usernameExists) {
          this.userForm.get('username')?.setErrors({ usernameExists: true });
        } else {
          this.userForm.get('username')?.setErrors(null);
        }
      });
    }
  }
  onSubmit() {
    if (this.userForm.valid) {
      // const userData = {
      //   ...this.userForm.value,
      //   password: this.passValue
      // };
      const userData={
        key:this.userForm.get('username')?.value,
        value:this.userForm.get('password')?.value,
        clientId:this.userForm.get('clientid')?.value
      }
      debugger
      this.EcrReportService.saveEcrCode(userData).subscribe({
        next: (response: any) => {
          debugger;
          console.log('Response', response);

          if (response.body.result == "success") {
            if (this.data) {
              this.alertData = { alertMessage: 'User Updated successfully', alertVisible: 'success' }

            } else {
              this.alertData = { alertMessage: 'User Added successfully', alertVisible: 'success' }
            }
          }
          else {
            this.alertData = { alertMessage: 'Error Occurred', alertVisible: 'error' }
          }
        },
        error: (error: any) => {
          console.error('Error submitting user data:', error);
        }
      });
    }
  }
 
  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
    const input = document.querySelector('input[formControlName="password"]') as HTMLInputElement;
    if (input) {
      input.type = this.showPassword ? 'text' : 'password';
      input.value = this.passValue;
    

      setTimeout(() => {
        input.type ='password';
        this.showPassword = false
      }, 5000);
    }
  }

  formatPassword(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input) return;
    this.passValue = input.value;
    this.userForm.get('password')?.setValue(this.passValue);

    input.value = this.showPassword
      ? this.passValue
      : this.maskPassword(this.passValue);
  }

  maskPassword(password: string): string {
    
    return password;
  }


  closePopup() {
    this.data = undefined;
    this.close.emit();
  }

}
