
// import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { GridDataResult, GridModule, PageChangeEvent } from '@progress/kendo-angular-grid';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { UserService } from '../../../service/user.service';
import { BlobService } from '../../../service/blob.service';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { HttpResponse } from '@angular/common/http';
import { InvoiceUpdationService } from '../../../service/invoice-updation.service';

import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { Component } from '@angular/core';


@Component({
  selector: 'app-invoice-updation',
  standalone: true,
  imports: [ComboBoxComponent, IndicatorsModule, AlertPopupComponent, GridModule, DropdownListComponent, ReactiveFormsModule, FormsModule, CommonModule],

  templateUrl: './invoice-updation.component.html',
  styleUrl: './invoice-updation.component.css'
})
export class InvoiceUpdationComponent {
  loading: boolean = false;
  invoiceForm!: FormGroup;
  invoiceNumber: string = '';
  description: string = '';
  public alertData = { alertMessage: '', alertVisible: '' };

  constructor(private fb: FormBuilder, private user: UserService, private InvoiceUpdationService: InvoiceUpdationService,) {

  }

  ngOnInit(): void {
    this.invoiceForm = this.fb.group({
      invoiceNumber: ['', [Validators.required, Validators.min(1)]], 
      description: ['', [Validators.required]]
    });
  }



  updateInvoiceDescription(): void {

    if (this.invoiceForm.invalid) {
      this.invoiceForm.markAllAsTouched(); 
      return; 
    }

  
    const payload = {
      InvoiceNumber: this.invoiceForm.get('invoiceNumber')?.value,
      Description: this.invoiceForm.get('description')?.value
    };


    this.InvoiceUpdationService.UpdateInvoiceDescriptionDetail(payload).subscribe(
      (response) => {

        if (response.body === 1) {
      
          this.alertData = { alertMessage: 'Added/Updated Successfully', alertVisible: 'success' };
        } else {
          
          this.alertData = { alertMessage: 'Submission Failed', alertVisible: 'error' };
        }
      },
      (error) => {
       
      }
    );
  }


  public closeAlert(): void {
    this.alertData = { alertMessage: '', alertVisible: '' };
  }
}
