import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { WorkspaceService } from '../../../service/workspace.service';
import { UserService } from '../../../service/user.service';

@Component({
  selector: 'app-workspace-input-file-popup',
  standalone: true,
  imports: [DialogModule,GridActionItemComponent],
  templateUrl: './workspace-input-file-popup.component.html',
  styleUrl: './workspace-input-file-popup.component.css'
})
export class WorkspaceInputFilePopupComponent {
  @Output() close = new EventEmitter<void>();
  public gridData: GridDataResult = { data: [], total: 0 };
  @Input() actId:any
  @Input() complianceId:any
  @Input() compliance:any
  constructor(private workspace:WorkspaceService,private user:UserService){}
  ngOnInit(): void {
    console.log('Id',this.actId)
    console.log('complianceId',this.complianceId)
    console.log('compliance',this.compliance)
    this.getInputFileGrid()
  }

  getInputFileGrid(){
    const payload={
    JSONID:0,
    CmpID:this.complianceId,
    EntityID:this.user.getClientId(),
    ActType:this.actId,
    CType:this.compliance
    }
    

    this.workspace.getInputFile(payload).subscribe({
      next:(response)=>{
        console.log(response);
        
        const data=response.body
        this.gridData={
          data:data,
          total:data.length
        }
      }
    })
  }

  public parentColumns: any[] = [
    {field: 'inputID',title: 'Input Id'},
    { field: 'page_name', title: 'Input File' },
  ];

  public closePopup(): void {
    this.close.emit();
  }   
}
