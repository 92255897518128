import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { GridComponent, GridDataResult, GridModule, LoadingComponent } from '@progress/kendo-angular-grid';
import { LeaveTypeServiceService } from '../../../service/leave-type-master.service';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { ComboBoxModule, DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { FormsModule } from '@angular/forms';
import { PageChangeEvent } from '@progress/kendo-angular-treelist';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { BlobService } from '../../../service/blob.service';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-leave-type-master',
  standalone: true,
  imports: [GridActionItemComponent, DropDownListModule, FormsModule, GridModule, ComboBoxModule, ComboBoxComponent, IndicatorsModule, NgIf],
  templateUrl: './leave-type-master.component.html',
  styleUrl: './leave-type-master.component.css'
})
export class LeaveTypeMasterComponent implements OnInit {
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  public buttonCount = 2;
  public sizes = [{text:'All',value:1},5, 10, 20];
  public gridData: GridDataResult = { data: [], total: 0 };
  public pageSize: number = 10;
  public skip: number = 0;
  public leaveTypeDropdownData: any[] = [];
  public selectedLeaveType: any = { name: null, id: null,displayText:null }
  //public defaultLeaveType: { key: any, value: any } = { key: "Search Leave Type/Description", value: null };
  loading: boolean = false;
  public defaultSelectedValue='Search Leave Type/Description';
  constructor(private leaveType: LeaveTypeServiceService, private blobService: BlobService) { }

  ngOnInit(): void {

    this.fetchAllLeaveTypeData();
    this.fetchLeaveTypeDropdown();

    
  }
  onFilterChange(filter: string): void {
    console.log('Filtering ComboBox with:', filter);
    this.fetchLeaveTypeDropdown(filter);  // Pass the filter term
}
  fetchAllLeaveTypeData(): void {
    const leave = this.selectedLeaveType && this.selectedLeaveType.id ? this.selectedLeaveType.id : '';;
    const pageIndex = Math.floor(this.skip / this.pageSize) + 1;
    console.log('Selected LeaveType:', leave);

    this.leaveType.getAllLeaveType(leave, pageIndex, this.pageSize, this.skip).subscribe({
      next: (response) => {
        console.log('response', response);

        if (response.status === 200) {
          const data = response.body;
          console.log('body', data);
          this.gridData = {
            data: data.data,
            total: data.total
          };
          this.loading=false;
        }}
          
    });
  }

  fetchLeaveTypeDropdown(filter: string = ''): void {
    console.log('Fetching leave type dropdown with filter:', filter);
    this.leaveType.getLeaveTypeDropdown(filter).subscribe({
      next: (response) => {
        
        if (response.status === 200) {
          const data = response.body;
          this.leaveTypeDropdownData = data.data.map((item: any) => ({
            id: item.value,
            name: item.key,
            displayText: `${item.key} ${item.value}`
          }));}

          console.log('Dropdown data', this.leaveTypeDropdownData);
        }
    });
  }

  applyFilter(): void {
    this.skip = 0;
    console.log('Applying filter with leave type:', this.selectedLeaveType);
    this.fetchAllLeaveTypeData();
  }

  clearFilter(): void {
    debugger;
    this.defaultSelectedValue = 'Search Leave Type/Description';
    this.selectedLeaveType = null
    this.valueChange.emit(this.selectedLeaveType);
    this.skip = 0;
  
    this.fetchLeaveTypeDropdown();
    this.fetchAllLeaveTypeData();
  }

  public pageChange(event: PageChangeEvent): void {
    this.loading=true;
    this.skip = event.skip;
    if(event.take==1){
      this.pageSize = this.gridData.total;
    }else{
      this.pageSize = event.take;
    }
    this.fetchAllLeaveTypeData();
  }

  DropdownValueChange(value: any) {
    console.log('value', value);

    this.selectedLeaveType = { name: value.name, id: value.id,displayText:value.displayText};
    if (value == undefined) {
        this.fetchAllLeaveTypeData();
    } 
}

  exportToExcel(): void {
    this.loading = true;
    this.leaveType.exportToExcel().subscribe((response: any) => {
      console.log('Export response', response);

      const blobLink = response.body.filePath;
      const blobName = this.blobService.extractBlobName(blobLink);
      setTimeout(() => {
        this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
          console.log('token response', tokenResponse);

          const sasToken = tokenResponse.result;
          this.blobService.downloadFile(blobName, sasToken);
          this.loading = false;
        });
      }, 5000);
    });
  }

}
