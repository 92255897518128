import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommercialMappingService {
  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }
  
  getPOGrid(EntityId:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MasterScoping/GetPODetails?EntityId=${EntityId}`;
    return this.http.get(url, {observe:'response'});
  }
  getSplitBranchWiseGrid(EntityId:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MasterScoping/GetSavedCommercial_Branch_Details?EntityId=${EntityId}`;
    return this.http.get(url, {observe:'response'});
  }
  getCommercialDetails(EntityId:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MasterScoping/GetCommercialDetailsRegular?EntityId=${EntityId}`;
    return this.http.get(url, {observe:'response'});
  }
  getOneTimeGrid(EntityId:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MasterScoping/GetCommercialOnetimedetails?EntityId=${EntityId}`;
    return this.http.get(url, {observe:'response'});
  }
  getSplitActivityWiseGrid(EntityId:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MasterScoping/GetSavedCommercialActivityDetails?EntityId=${EntityId}`;
    return this.http.get(url, {observe:'response'});
  }
  getBillingDetails(EntityId:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MasterScoping/GetBillingDetails_ForEntity?EntityId=${EntityId}`;
    return this.http.get(url, {observe:'response'});
  }
  getBillingContacts(EntityId:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MasterScoping/GetContactDetails?EntityId=${EntityId}`;
    return this.http.get(url, {observe:'response'});
  }
  getMandate(EntityId:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MasterScoping/GetCommercialDetails_ForEntity?EntityId=${EntityId}`;
    return this.http.get(url, {observe:'response'});
  }
  getEntityDropdown(CustomerID:number,Flag:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MasterScoping/GetEntitiesByCustomer?CustomerID=${CustomerID}&Flag=${Flag}`;
    return this.http.get(url, {observe:'response'});
  }
  getActivityTypeDropdown(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MasterScoping/Get_CommercialActivityType`;
    return this.http.get(url, {observe:'response'});
  }
  getCommercialTypeDropdown(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MasterScoping/Get_CommercialTypes`;
    return this.http.get(url, {observe:'response'});
  }
  getCommercialElementTypeDropdown(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MasterScoping/Get_CommercialElementTypes`;
    return this.http.get(url, {observe:'response'});
  }
  getVariableBillingTypeDropdown(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MasterScoping/Get_Variable_Billing_Types`;
    return this.http.get(url, {observe:'response'});
  }
  getBillingZoneDropdown(EntityId:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MasterScoping/GetBillingDetails_ForEntity?EntityId=${EntityId}`;
    return this.http.get(url, {observe:'response'});
  }
  getActivityDropdown(EntityId:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MasterScoping/Get_ListOfActivities_ForCommercial?EntityId=${EntityId}`;
    return this.http.get(url, {observe:'response'});
  }
  saveCommercialDetails(value:any): Observable<HttpResponse<any>> {
    const body = {
      entityId: value.entityId || '',
      billing_type: value.billing_type || '',
      split_type: value.split_type || '',
      activity: value.activity || '',
      po_number: value.po_number.toString() || '',
      fixed_amount: value.fixed_amount.toString() || '',
      state: value.state || '',
      location: value.location || '',
      branch: value.branch || '',
      invoice_spoc_name: value.invoice_spoc_name || '',
      invoice_spoc_emailid: value.invoice_spoc_emailid || '',
      invoice_contact_number: value.invoice_contact_number || '',
      invoice_address: value.invoice_address || '',
      dispatch_address: value.dispatch_address || '',
      commercial_activity_type: value.commercial_activity_type || '',
      commercial_type: value.commercial_type || '',
      commercial_element_type: value.commercial_element_type || '',
      price_structure: value.price_structure || '',
      charges: value.charges || '',
      tl_charges: value.tl_charges || '',
      variable_billing_types: value.variable_billing_types || '',
      element_count: value.element_count.toString() || '',
      per_element_amount: value.per_element_amount.toString() || '',
      consolidated_value: value.consolidated_value.toString() || '',
      mandate: value.mandate || ''
    };   
    console.log('service body',body);
    const url = `${this.apiUrl}/api/MasterScoping/SaveCommercial_Details_Regular`;
    return this.http.post(url,body, {observe:'response'});
  }
  saveBillingContacts(EntityId:string,value:any): Observable<HttpResponse<any>> {
    const body={
      entityId: EntityId,
      spoc_name: value.spoc_name,
      spoc_email: value.spoc_email,
      spoc_contact: value.spoc_contact,
      invoice_spoc_address: value.invoice_spoc_address ,
      dispatch_spoc_address: value.dispatch_spoc_address 
    }
    const url = `${this.apiUrl}/api/MasterScoping/SaveContactDetails`;
    return this.http.post(url,body, {observe:'response'});
  }
  saveBillingDetailsGridData(gridData:any): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MasterScoping/SavePODetails`;
    console.log('service grid',gridData);
    
    return this.http.post(url,gridData, {observe:'response'});
  }
  saveBillingDetails(data: any ,file:File|null): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MasterScoping/SaveBillingDetails_EntityWise`;
    const formData = new FormData();
    formData.append('id', String(data.id ?? 0));
    formData.append('entityId', data.entityId ?? '');
    formData.append('billing_zone', data.billing_zone ?? '');
    formData.append('billing_cycle', data.billing_cycle ?? '');
    formData.append('billing_subm_date', data.billing_subm_date ?? '');
    formData.append('payment_credit_period', data.payment_credit_period ?? '');
    formData.append('revenue_gen_tab', data.revenue_gen_tab ?? '');
    formData.append('mandate_type', data.mandate_type ?? '');
    formData.append('po_wo_appl', data.po_wo_appl ?? '');
    formData.append('work_order', data.work_order ?? '');
    formData.append('po_type', data.po_type ?? '');
    formData.append('billing_type', data.billing_type ?? '');
    formData.append('split_type', data.split_type ?? '');
    if(file){
      formData.append('file', file);
      console.log('file',file);
    }
    

    formData.forEach((value, key) => {
      console.log(`${key}: ${value}`);
    });
    return this.http.post(url, formData, { observe: 'response' });
  }
  saveCommercialSplitBranch(file: File, entityId: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/EmployeeMaster/SaveBranchwise_Commercials`;
    
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('EntityId', entityId);
  
    formData.forEach((value, key) => {
      console.log(`${key}:`, value);
    });
  
    return this.http.post<HttpResponse<any>>(url, formData, {
      observe: 'response',
    });
  }
  
}
