import { Component, OnInit } from '@angular/core';
import { ManageEmployeeService } from '../../../service/manage-employee.service';
import { GridModule } from '@progress/kendo-angular-grid';
import { PageChangeEvent } from '@progress/kendo-angular-treelist';
import { UserService } from '../../../service/user.service';
import { DatePipe, NgIf } from '@angular/common';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { AddEditEmployeeMasterPopupComponent } from '../../popups/add-edit-employee-master-popup/add-edit-employee-master-popup.component';
import { EmployeeSelectBulkUploadComponent } from '../../popups/employee-select-bulk-upload/employee-select-bulk-upload.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { filterBy } from '@progress/kendo-data-query';

@Component({
  selector: 'app-manage-employee',
  standalone: true,
  imports: [GridModule, DatePipe, ComboBoxComponent, DropdownListComponent, AlertPopupComponent, NgIf, AddEditEmployeeMasterPopupComponent, EmployeeSelectBulkUploadComponent, IndicatorsModule],
  templateUrl: './manage-employee.component.html',
  styleUrl: './manage-employee.component.css'
})
export class ManageEmployeeComponent implements OnInit {
  public defaultSelectedValue = 'Search/Select Employee';
  public buttonCount = 2;
  public sizes = [{text:'All',value:Infinity},5, 10, 20];
  public gridData: any ={data:[],total:0};
  public pageSize: number = 10;
  take = 10;
  skip = 0;
  page = 1;
  deleteId = 0;
  loading = false;
  selectBulkUploadPopup = false;
  public applyFilterEvent!: any;
  public Role!: any;
  public alertData = { alertMessage: '', alertVisible: '' };
  public AddNewPopup: boolean = false;
  public dropdown: { id: number; name: string }[] = [];
  public defaultFilterValue = { text: 'Select', value: 'select' };
  dropdownData = [
    { text: 'Select', value: 'select' },
    { text: 'Active', value: 'A' },
    { text: 'InActive', value: 'I' }
  ];
  clientId = '';
  selectedEmployeeID :any={id:null,name:null};
  EmpID = '';
  constructor(private manageEmployeeService: ManageEmployeeService, private user: UserService) { }
  ngOnInit(): void {
    this.clientId = this.user.getClientId();
    this.Role = this.user.getRole();
    this.loadGridData('', '');
  }
  onDropdownValueChange(value: any) {
    this.applyFilterEvent = value.value;
  }

  clearFilter() {
    this.loadGridData('', '')
    this.selectedEmployeeID = null; 
    this.defaultFilterValue = { text: 'Select', value: 'select' }
    this.defaultSelectedValue = 'Search/Select Employee';
    this.dropdown = [];
  }
  applyFilter() {
    this.loadGridData( this.selectedEmployeeID.id|| '' , this.applyFilterEvent||'')
  }
  openUploadPopup() {
    this.AddNewPopup = true;
  }
  OpenAddNewPopup() {
    this.AddNewPopup = true;
    this.EmpID = '';
  }
  OpenEditPopup(value: any) {
    this.EmpID = value.eM_EmpID;
    this.AddNewPopup = true;
  }
  uploadDialog() {
    this.selectBulkUploadPopup = true;
  }
  FilterDropdown(value: string) {
    this.manageEmployeeService.getEmployeeDropdown(this.clientId, value).subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && data && data.data) {
            this.dropdown = data.data.map((item: any) => ({
              id: item.value,
              name: item.key
            }));
          } else {
            console.error("Unexpected data format", data);
          }
        }, error: (error) => {
          console.error("Error fetching dropdown data", error);
        }
      });
  }

  DropdownGridChange(value: any) {
    if (value == undefined) {
      this.loadGridData('', this.applyFilterEvent|| '');
      this.selectedEmployeeID='';
      return;
    }
    this.selectedEmployeeID = {id:value.id,name:value.name};
  }
  public pageChange(event: PageChangeEvent): void {
    debugger
    this.skip = event.skip;
    if(event.take==Infinity){
      this.pageSize = this.gridData.total;
    }else{
      this.pageSize = event.take;
    }
    this.page = (this.skip / this.pageSize) + 1;
    this.loadGridData('', '');
  }
  loadGridData(value: string, filter: string) {
    this.loading = true;
    this.manageEmployeeService.getAllEmployeeMasters(this.clientId, value, filter, this.take, this.skip, this.page, this.pageSize).subscribe(
      {
        next: (response) => {
          const data = response.body;
          console.log(response);
          this.gridData = {
            data: data.data,       
            total: data.total    
          };
          this.loading = false;
        }, error: (error) => {
          this.loading = false;
          console.error('Error fetching grid data:', error);
        }
      }
    );
  }
  confirmAlert(value: any) {
    this.deleteId = value.eM_EmpID;
    this.alertData = { alertMessage: 'Are you sure you want to delete this Employee?', alertVisible: 'confirm' };
  }
  DeleteMWMaster(value: any) {
    const clientId = this.user.getClientId();
    this.manageEmployeeService.DeleteEmployeeMasters(clientId, value).subscribe(
      {
        next: (response) => {
          if (response.status == 200) {
            this.alertData = { alertMessage: 'Deleted Successfully', alertVisible: 'success' };
            console.log(response);
            this.loadGridData('', '');
          } else {
            this.alertData = { alertMessage: 'Error Detected', alertVisible: 'error' };
          }
        }, error: (error) => {
          console.error(error);
          this.alertData = { alertMessage: 'Error Detected', alertVisible: 'error' };
        }
      })
  }
  closeAlert() {
    this.alertData = { alertMessage: '', alertVisible: '' };
  }
  closePopup() {
    this.AddNewPopup = false;
    this.selectBulkUploadPopup = false;
    this.loadGridData('', '');
  }
}
