<div class="row colpadding0" style="margin-bottom: 10px; margin-top: 10px;">
    <div class="col-md-8 colpadding0">
        <input class="f-textbox" type="text" (keyup)="applyFilter($event)" placeholder="Type to Search">
    </div>
    <div class="col-md-4 colpadding0">
        <div class="DivButtons">
            <button class="figma-btn-blue " (click)="openUploadPopup()">
                <img src="../assets/vectors/uploadWhite.svg" alt="" >Upload
            </button>
            <button class="figma-btn-blue" (click)="openAddNewPopup()" type="submit" >
                <img src="../assets/vectors/Plus.svg" alt="">Add New
            </button>
            <button (click)="exportToExcel()" class="exportbtn">
                <img src="../assets/vectors/ExportButton.svg" alt="">
            </button>
        </div>
    </div>
</div>

<app-grid-action-item [gridData]="gridData" [columns]="parentColumns" [pageSize]="pageSize" [pageable]="true"
    [filterable]="true" (buttonClick)="handleButtonClick($event)"></app-grid-action-item>

<app-labour-department-add-edit-popup [Data]="selectedGridData" *ngIf="showAddEditPopup" (close)="closePopup()" [(header)]="parentHeader"></app-labour-department-add-edit-popup>
<app-labour-department-upload-popup *ngIf="showUploadPopup" (close)="closePopup()"></app-labour-department-upload-popup>

<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>


<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270"  [Data]="alertData"
    (close)="closeAlert()"></app-alert-popup>