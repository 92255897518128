import { Component } from '@angular/core';
import { MasterAbstractsService } from '../../../service/master-abstracts.service';
import { GridDataResult, GridModule } from '@progress/kendo-angular-grid';
import { UserService } from '../../../service/user.service';
import { BlobService } from '../../../service/blob.service';
import { environment } from '../../../environments/environment';
import { DropdownListComponent } from "../../../components/dropdown-list/dropdown-list.component";
import { MasterAbstractUploadPopupComponent } from "../../popups/master-abstract-upload-popup/master-abstract-upload-popup.component";
import { GridActionItemComponent } from "../../../components/grid-action-item/grid-action-item.component";
import { AlertPopupComponent } from "../../popups/alert-popup/alert-popup.component";
import { DropDownsModule, KENDO_MULTISELECT, MultiSelectModule, MultiSelectTreeComponent } from '@progress/kendo-angular-dropdowns';
import { StatusReportService } from '../../../service/status-report.service';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { TemplateRef, ViewChild } from '@angular/core';
// import { saveAs } from 'file-saver';
import { NgFor, NgIf } from '@angular/common';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { AdobeViewerComponent } from '../../../components/adobe-viewer/adobe-viewer.component';

type Branch = {
  id: number;
  name: string;
  parentID: number | null;
  branchType: string;
  branchStatus: string;
  fullName: string | null;
  children?: Branch[];
};
@Component({
  selector: 'app-registration-repository',
  standalone: true,
  imports: [DropdownListComponent, MasterAbstractUploadPopupComponent, GridActionItemComponent, AlertPopupComponent, MultiSelectTreeComponent, TreeViewModule, 
    DropDownsModule, MultiSelectModule, DropdownListComponent, GridModule, NgFor, NgIf,IndicatorsModule,AdobeViewerComponent],
  templateUrl: './registration-repository.component.html',
  styleUrl: './registration-repository.component.css'
})
export class RegistrationRepositoryComponent {
  multiSelectEntities: any[] = [];
  StateDropdownData: any[] = [];
  ActDropdownData: any[] = [];
  showUploadPopup = false;
  public BranchDropdown: Branch[] = [];
  loginusername: string = '';
  fileBlob!:any
  commaSeparatedIds: string = '';
  selectedState = { text: 'Select State', value: '' };
  selectedAct = { text: 'All activities', value: '' };
  userID!: number;
  customerID !:number;
  @ViewChild('actionCell', { static: true }) actionCell!: TemplateRef<any>;
  @ViewChild('multiselectTree') multiselectTree: any;
  loading = false;
  EntityID!: string;
  alertData = { alertMessage: '', alertVisible: '' }
  public gridData: GridDataResult = { data: [], total: 0 };
  public OriginalData: GridDataResult = { data: [], total: 0 };
  popupSettings: any = { width: '280px' }; 

  constructor(private MasterAbstractService: MasterAbstractsService, private user: UserService, private blobService: BlobService, private StatusReportService: StatusReportService) { }
  ngOnInit(): void {
    this.userID = this.user.getUserId();
    this.customerID = this.user.getCustomerId();
    this.EntityID = this.user.getClientId();
    this.getActDropdownData()
    this.loginusername=this.user.getFirstName();
    this.loadGridData("1");
    this.getBranchDropdownData();
  }

  applyFilter() {
    let filteredData = this.OriginalData.data;

    // Filter by selected activity if available
    if (this.selectedAct && this.selectedAct.value) {
        filteredData = filteredData.filter(item => item.cM_ActivityName === this.selectedAct.text);
    }

    // Filter by selected branches if available
    if (this.commaSeparatedIds) {
        const branchIdsArray = this.commaSeparatedIds.split(',').map(id => Number(id));
        filteredData = filteredData.filter(item => branchIdsArray.includes(item.avacoM_BranchID));
    }
    // Update grid data
    this.gridData = {
        data: filteredData,
        total: filteredData.length
    };

    console.log('Filtered Grid Data:', this.gridData);
}

  
  public tagMapper(tags: any[]): any[] {
    return tags.length < 3 ? tags : [tags];
  }
  downloadFile(filePath: string, fileName: string) {
    debugger
    if(filePath){

      const apiUrl = environment.blobUrl;
      const blobLink = apiUrl + filePath;
      const blobName = this.blobService.extractBlobName(blobLink);
      setTimeout(() => {
        this.blobService.getSasToken(blobName).subscribe(
          (tokenResponse: any) => {
            try {
              const sasToken = tokenResponse.result;
              this.blobService.downloadFile(blobName, sasToken);
              this.loading = false;
            } catch (error) {
              this.alertData={alertMessage:'File Not Found',alertVisible:'error'};
              this.loading = false;
            }
          },
          (error) => {
            this.alertData={alertMessage:'File Not Found',alertVisible:'error'};
            this.loading = false; 
          }
        );
      }, 5000);
  }else{
    this.alertData={alertMessage:'File Not Found',alertVisible:'error'};
    this.loading=false;
  }
  }
  OnMultiSelectChange(value: any) {
    if (Array.isArray(value)) {
        this.commaSeparatedIds = value.map(item => item.id).join(',');
    } else {
        console.error('Expected an array of selected items.');
    }
}

  getBranchDropdownData() {
    this.StatusReportService.getBranchDropdown(this.EntityID).subscribe({
        next: (response) => {
            const data = response.body;
            if (response && Array.isArray(data)) {
                this.BranchDropdown = this.buildHierarchy(data);

                const maxLength = this.calculateMaxTextLength(this.BranchDropdown);
                
                const calculatedWidth = Math.max(280, maxLength * 10); 
                this.popupSettings = { width: `${calculatedWidth}px` };
            } else {
                console.error('Unexpected data format', response);
            }
        },
        error: (error) => {
            console.error('Error fetching Branch Dropdown data', error);
        }
    });
}
downloadDocument(recordID: string) {
  debugger;
  this.StatusReportService.downloadDocument(15469).subscribe({
    next: (response) => {
      if (response.body) { // Ensure body is not null
        const blob = new Blob([response.body], { type: 'application/pdf' }); // Adjust MIME type if needed
        const fileName = `document_${recordID}.pdf`; // Customize file name
        // saveAs(blob, fileName); // Trigger the download using file-saver
      } else {
        console.log('File download failed, no data in response body.');
      }
    },
    error: (error) => {
      console.error('Error downloading the document:', error);
    }
  });
}

calculateMaxTextLength(data: Branch[]): number {
  let maxLength = 0;

  const findMaxLength = (items: Branch[]) => {
      items.forEach(item => {
          maxLength = Math.max(maxLength, item.name.length); 
          if (item.children && item.children.length) {
              findMaxLength(item.children); 
          }
      });
  };

  findMaxLength(data);
  return maxLength;
}
  buildHierarchy(data: Branch[]): Branch[] {
    const grouped: { [key: number]: Branch[] } = data.reduce((acc, item) => {
      (acc[item.parentID || -1] = acc[item.parentID || -1] || []).push(item);
      return acc;
    }, {} as { [key: number]: Branch[] });

    function buildTree(parentID: number | null): Branch[] {
      return (grouped[parentID || -1] || []).map(item => ({
        ...item,
        children: buildTree(item.id)
      }));
    }

    return buildTree(null);
  }
  
  loadGridData(value: string): void {
    //this.loading = true;
    var profileID = null;
    this.MasterAbstractService.getRegistrationGrid(this.customerID, this.userID, profileID,this.EntityID, true).subscribe({
      next: (response) => {
        console.log(response);
        
        const data = response.body.map((item:any, index:any) => ({
          ...item,
          srNo: index + 1  // Adding Sr.no dynamically
        }));
        this.OriginalData = {
          data: data,
          total: data.length
        };
        this.gridData = {
          data: data,
          total: data.length
        };
        //this.loading = false;
      },
      error: (err) => {
        console.error('Error fetching entity data', err);
        //this.loading = false;
      }
    });
  }

  getActDropdownData() {
    this.MasterAbstractService.getOnetimeActivityDropdown().subscribe({
      next: (response) => {
        const data = response.body;
        this.ActDropdownData = [
          { text: 'All activities', value: '' },
          ...data.map((item: any) => ({
            text: item.cM_ActivityName,
            value: item.cM_ActivityName
          }))
        ];
      },
      error: (error) => {
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }
  onDropdownValueChange(value: any, type: string) {
    if (type == 'activity') {
      // Update selectedAct with selected dropdown value and ensure both text and value are set
      this.selectedAct = { text: value.text, value: value.value };
    }
  }
  
  
  
  openUploadPopup() {
    this.showUploadPopup = true;
  }
  clearFilter() {
    // Reset selected activity and branch
    this.selectedAct = { text: '', value: '' };
    this.commaSeparatedIds = '';  // Clear selected branch IDs

    // Clear MultiSelectTree selection
    if (this.multiselectTree) {
        this.multiselectTree.value = [];  // Clear selected values
        this.multiselectTree.writeValue([]);  // Ensure the displayed tags are cleared
    }

    // Restore original grid data
    this.gridData = {
      data: this.OriginalData.data,
      total: this.OriginalData.data.length
    };

    console.log('Filter cleared, showing original data:', this.gridData);
}
  

  public parentColumns: any[] = [
    { field: 'srNo', title: 'Sr.no', width:80},
    { field: 'entityClientName', title: 'Entity' },
    { field: 'cM_ActivityName', title: 'Document Type', width:300 },
    { field: 'sM_Name', title: 'State' },
    { field: 'lM_Name', title: 'Location' },
    { field: 'rR_Branch', title: 'Branch' },
    { field: 'rR_ExpiryDate', title: 'Expiry Date' },
    {
      field: 'action',
      isIconColumn: true,
      title: 'Action',
      width:260,
      filteredIcons: [
        {
          url: '../assets/vectors/DownloadBlue.svg',
          alt: 'Icon 1',
          action: 'Download'
        },
        {
          url: '../assets/vectors/EyeIcon.svg',
          alt: 'Icon e',
          action: 'View'
        },
      ]
    }
  ];

  exportToExcel(): void {
    const entityId = this.user.getClientId();
    this.loading = true
    this.MasterAbstractService.getRegisterRepoExport(this.customerID, this.userID, 1,this.EntityID).subscribe(
      (response: any) => {
        console.log('response', response);

        const blobLink = response.body.filePath;
        const blobName = this.blobService.extractBlobName(blobLink);
        setTimeout(() => {
          this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
            debugger;
            const sasToken = tokenResponse.result;
            this.blobService.downloadFile(blobName, sasToken);
            this.loading = false;
          });
        }, 5000);
      },
      (error) => {
        console.error('Error fetching blob link:', error);
        this.loading = false;
      });
  }

  closeAlert(){
    this.alertData = { alertMessage: '', alertVisible: '' }
  }
  closePopup() {
    this.showUploadPopup = false;
  }
  handleButtonClick(dataItem:any){
    console.log(dataItem);
    if(dataItem.button.action=='Download'){
      this.downloadDocument(dataItem.dataItem.recordID);
    }else{
      this.MasterAbstractService.RegRepoFile(dataItem.dataItem.recordID, 'REG').subscribe({
        next: (blob: Blob) => {
          debugger;
          this.fileBlob = blob;
          this.loading = false;
        },
        error: (error) => {
          this.loading = false;
          console.error('Error fetching Branch Dropdown data', error);
        }
      });
    }    
  }
  closeViewer(){
    this.fileBlob=null;
  }
}
