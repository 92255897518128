import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ListBoxModule } from '@progress/kendo-angular-listbox';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { CustomerMasterService } from '../../../service/customer-master.service';
import { UserService } from '../../../service/user.service';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { CustomerMasterComponent } from '../../onboard-entity/customer-master/customer-master.component';

@Component({
  selector: 'app-customer-master-add-edit-popup',
  standalone: true,
  imports: [NgIf, DialogModule, NgClass, DropdownListComponent, ReactiveFormsModule, AlertPopupComponent, ListBoxModule, IndicatorsModule],
  templateUrl: './customer-master-add-edit-popup.component.html',
  styleUrl: './customer-master-add-edit-popup.component.css'
})
export class CustomerMasterAddEditPopupComponent implements OnInit {
  form!: FormGroup;
  @Input() data: any;
  @Output() close = new EventEmitter<void>();
  public showPan: boolean = false;
  public isSameAsName:boolean = false;
  public disableCID:boolean = false;
  private originalPan: string = '';
  public showcO_TAN: boolean = false;
  public showValidationErrorsPAN = false;
  private originalcO_TAN: string = '';
  cO_TAN = '';
  selectedPincode = '';
  cO_PAN = '';
  mergedObj!: any;
  avacoM_CustomerID!: number;
  CorporateIdExist: any=true;
  hasPredefinedData: boolean = false;
  ButtonClick=false;
  startswith!:boolean;
  cO_CorporateID = ''
  cO_Status='';
  alertData = { alertMessage: '', alertVisible: '' };
  Status = { text: 'Select', value: ''};
  State = { text: 'Select', value: '' };
  Country = { text: 'Select', value: '' };
  AssignProduct = { text: 'Select', value: '' };
  Compliance = { text: 'Select Compliance', value: 0 };
  City = { text: 'Select', value: '' };
  StatusdropdownData: any[] = [];
  CountrydropdownData: any[] = [];
  StatedropdownData: any[] = [];
  CitydropdownData: any[] = [{ text: 'Select', value: '' }];
  AssignProductdropdownData: any[] = [];
  CompliancedropdownData = [
    { text: 'Select Compliance', value: 0 },
    // { text: 'HRProduct Only', value: 1 } ||
    { text: 'HRProduct Only', value: 1 | 2 },
    { text: 'Avacom + TL', value: 3 },
    { text: 'Avacom + HRProduct', value: 4 }
  ];
  fileNames: { [key: string]: string | null } = {
    Logo: null
  };
  selectedFile?: File;
  userId!: number;
  loading = false;
  Role!:string;
  ServiceProviderID!: number;
  constructor(private fb: FormBuilder, private user: UserService, private CustomerMasterService: CustomerMasterService,private customermaster: CustomerMasterComponent) {
    this.form = this.fb.group({
      cO_Name: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9 ()/-]*$')]],
      cO_CorporateID: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]*$')]],
      buyerName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]],
      buyerContactNumber: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      buyerEmail: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]],
      cO_PAN: ['', [Validators.pattern(/^[A-Z0-9]{10}$/)]],
      cO_TAN: ['', [Validators.pattern(/^[A-Z0-9]{10}$/)]],
      address: [''],
    });
  }
  // @ViewChild('dialogTitle') dialogTitle!: ElementRef;
  // ngAfterViewInit() {
  //   const titleElement = this.dialogTitle.nativeElement;
  //   titleElement.style.textAlign = 'center';
  //   titleElement.style.width = '100%';
  // }
  ngOnInit(): void {
    this.userId = this.user.getUserId();
    this.Role = this.user.getRole();
    console.log(this.Role);
    if (this.Role=='PERF' || this.Role=='RVW1') {
      this.form.disable(); 
    } else {
      this.form.enable();  
    }
    this.getServiceProviderID(596);
    this.getAssignList(596);
    this.CountryDropdown();
    this.StateDropdown();
    this.StatusDropdown();
    if (this.data) {
      this.loadData(this.data.id);
    }else{
      this.initData('');
    }
  }
  onKeyDownCustomerName(event: KeyboardEvent) {
    const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Tab", " "];
    const isLetter = /^[a-zA-Z]$/.test(event.key);
    if (!allowedKeys.includes(event.key) && !isLetter) {
        event.preventDefault();
    }
  }
  onKeyDownBuyerName(event: KeyboardEvent) {
    const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Tab", " "];
    const inputValue = (event.target as HTMLInputElement).value;
    const isFirstCharacter = inputValue.length === 0;
    const isLetter = /^[a-zA-Z]$/.test(event.key);
    if ((isFirstCharacter && event.key === ' ') || (!allowedKeys.includes(event.key) && !isLetter)) {
      event.preventDefault();
    }
  }
  initData(value: any): void {
    console.log('predefine', value);
    this.form.patchValue(value);
    this.initializePan(value.cO_PAN);
    this.cO_PAN = value.cO_PAN;
    if (value?.cO_PAN) {
      const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

      if (!panRegex.test(value.cO_PAN)) {
        this.form.get('cO_PAN')?.setErrors({ pattern: true });
        this.showValidationErrorsPAN = true;
      } else {
        this.form.get('cO_PAN')?.setErrors(null);
        this.showValidationErrorsPAN = false;
      }
    }
    this.initializecO_TAN(value.cO_TAN)
    this.showcO_TAN = false;
    this.updatecO_TANDisplay();
    this.showPan = false;
    this.updatePanDisplay();
    this.CityDropdown(value.cO_State);
    this.cO_CorporateID = value.cO_CorporateID;
    this.cO_CorporateID ? this.ButtonClick=true :this.ButtonClick=false;
    this.cO_CorporateID ? this.hasPredefinedData=true :this.hasPredefinedData=false;
    this.cO_CorporateID ? this.form.get('cO_CorporateID')?.disable(): this.form.get('cO_CorporateID')?.enable();;
    this.CorporateIdExist=null;
      this.Status = { text: '', value: value?.status.toString() };
    // if (value.cO_Status == 'A') {
    //   this.Status = { text: 'Active', value: "1" };
    // } else if (value.status == 'I') {
    //   this.Status = { text: 'Inactive', value: "0" };
    // } else if (value.cO_Status == 'S') {
    //   this.Status = { text: 'Suspended', value: "-1" };
    // }
    this.State = { text: '', value: value.cO_State || '' };
    this.Country = { text: '', value: value.cO_Country || '' };
    this.City = { text: '', value: value.cO_City || '' };
    this.Compliance = { text: '', value: value.complianceProductType || 0 };

    if (this.data) {
      setTimeout(() => {
        this.showPan = false;
        this.updatePanDisplay();
        this.showcO_TAN = false;
        this.updatecO_TANDisplay();
      }, 500);
    } else {
      if (this.showValidationErrorsPAN) {
        this.showPan = true;
        this.updatePanDisplay();
        this.form.markAllAsTouched();
      }
    }
    value? this.form.markAllAsTouched():'';  
    // if (!this.cO_CorporateID.startsWith('AVA')) {
    //   this.startswith=true;
    //   return;
    // }else{
    //   this.startswith=false;
    // }
    this.startswith=false;
  }
  getServiceProviderID(value: number) {
    this.CustomerMasterService.GetServiceProviderID(value).subscribe({
      next: (response) => {
        const data = response.body;
        this.ServiceProviderID = data.result;
      }, error: (error) => {
        console.error('Error:', error);
      }
    })
  }
  loadData(value: number) {
    this.CustomerMasterService.getCustomerDataMapping(value).subscribe({
      next: (response) => {
        const data = response.body;
        this.mergedObj = { ...this.data, ...data };
        this.initData(this.mergedObj);
      }, error: (error) => {
        console.error('Error:', error);
      }
    })
  }
  getAssignList(value: number) {
    this.CustomerMasterService.getAssignProducts(value).subscribe({
      next: (response) => {
        const data = response.body;
        if (data && data && Array.isArray(data)) {
          this.AssignProductdropdownData = [
            ...data.map((item: any) => ({
              value: item.productID,
              text: item.productName
            }))]
        } else {
          console.error("Unexpected data format", data);
        }
      }, error: (error) => {
        console.error('Error:', error);
      }
    })
  }
  onFileChange(event: any, type: string) {
    const file = event.target.files[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        this.fileNames[type] = file.name;
        this.selectedFile = file;
      } else {
        this.fileNames[type] = null;
        this.alertData = { alertMessage: 'Please select an image file', alertVisible: 'error' };
      }
    } else {
      this.fileNames[type] = null;
    }
  }

  validateMobileNo(event: any): void {
    const input = event.target.value;
    event.target.value = input.replace(/[^0-9]/g, '');
    this.form.get('buyerContactNumber')?.setValue(event.target.value);
  }
  onDropdownValueChange(value: any, type: string) {
    if (type == 'Status') {
      this.Status = { text: value.text, value: value.value };
    } else if (type == 'Country') {
      this.Country = { text: '', value: value.value };
    } else if (type == 'State') {
      this.State = { text: '', value: value.value };
      this.CityDropdown(value.value);
    } else if (type == 'AssignProduct') {
      this.AssignProduct = { text: '', value: value.value };
    } else if (type == 'City') {
      this.City = { text: '', value: value.value };
      this.onPincodeAndLocationChange(value.text)
    } else if (type == 'Compliance') {
      this.Compliance = { text: '', value: value.value };
    }
  }
  CountryDropdown() {
    this.CustomerMasterService.getCountryDropdown().subscribe({
      next: (response) => {
        console.log(response);
        
        const data = response.body;
        if (data && Array.isArray(data)) {
          this.CountrydropdownData = [
            { text: 'Select', value: '' },
            ...data.map((item: any) => ({
              value: item.key,
              text: item.value
            }))
          ];
          const indiaItem = this.CountrydropdownData.find(item => item.text === 'India');
          if (indiaItem) {
            this.Country = indiaItem;
          }
        } else {
          console.error("Unexpected data format", data);
        }
      },
      error: (error) => {
        console.error('Error fetching Country dropdown data', error);
      }
    });
  }
  StateDropdown() {
    this.CustomerMasterService.getStateDropdown().subscribe(
      {
        next: (response) => {
          console.log(response);

          const data = response.body;
          if (data && data && Array.isArray(data)) {
            this.StatedropdownData = [{ text: 'Select ', value: '' },
            ...data.map((item: any) => ({
              value: item.key,
              text: item.value
            }))]
          } else {
            console.error("Unexpected data format", data);
          }
        }, error: (error) => {
          console.error('Error fetching State Dropdown data', error);
        }
      }
    );
  }
  CityDropdown(value: number) {
    this.CustomerMasterService.getCityByStateDropdown(value).subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && data && Array.isArray(data)) {
            this.CitydropdownData = [{ text: 'Select ', value: '' },
            ...data.map((item: any) => ({
              value: item.key,
              text: item.value
            }))]
          } else {
            console.error("Unexpected data format", data);
          }
        }, error: (error) => {
          console.error('Error fetching State Dropdown data', error);
        }
      }
    );
  }
  StatusDropdown() {
    this.CustomerMasterService.getStatusDropdown().subscribe({
      next: (response) => {
        const data = response.body;
        if (data && Array.isArray(data)) {
          this.StatusdropdownData = [
            { text: 'Select', value: '' },
            ...data.map((item: any) => ({
              value: item.key,
              text: item.value
            }))
          ];
          if (this.Status.value === '-1') {
            const activeItem = this.StatusdropdownData.find(item => item.text === 'Suspended');
            if (activeItem) {
              this.Status = activeItem;
            }
          }
        } else {
          console.error("Unexpected data format", data);
        }
      },
      error: (error) => {
        console.error('Error fetching Status dropdown data', error);
      }
    });
  }
 


  allowAlphanumeric(event: KeyboardEvent) {
    const pattern = /^[a-zA-Z0-9]*$/;
    const key = event.key;
    if (!pattern.test(key) && !['Backspace', 'Delete', 'Tab', 'Escape', 'Enter', 'ArrowLeft', 'ArrowRight'].includes(key)) {
        event.preventDefault();
    }
  }

  allowAlphanumericId(event: KeyboardEvent) {
    const pattern = /^[a-zA-Z0-9]*$/;
    const key = event.key;
    if (!pattern.test(key) && !['Backspace', 'Delete', 'Tab', 'Escape', 'Enter', 'ArrowLeft', 'ArrowRight'].includes(key)) {
        event.preventDefault(); 
    }
}
  FormatCorporate(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
    if (!input.value.startsWith('AVA')) {
      this.startswith=true;
      return;
    }else{
      this.startswith=false;
      this.form.get('cO_CorporateID')?.setValue(input.value, { emitEvent: false });
      this.cO_CorporateID = input.value.toUpperCase();
    }
    this.ButtonClick=false;
  }
  generateCorporateId(): void {
    if(!this.hasPredefinedData){
      let name = this.form.get('cO_Name')?.value || '';
      name = name.toUpperCase();
      if (name.length < 8) {
        name = name.padEnd(8, ''); 
        const cO_CorporateID = `AVA${name}`;
        this.cO_CorporateID = cO_CorporateID;
        this.form.get('cO_CorporateID')?.setValue(cO_CorporateID, { emitEvent: false });
        this.ButtonClick=false;
        this.CorporateIdExist=null;
      }   
      if (!this.cO_CorporateID.startsWith('AVA')) {
        this.startswith=true;
      }else{
        this.startswith=false;
      }
    }
  }

  CheckCorporateIdExists() {
    if(this.cO_CorporateID==this.mergedObj?.cO_CorporateID){
      this.CorporateIdExist==false;
      return;
    }
    if (!this.cO_CorporateID.startsWith('AVA')) {
      this.startswith=true;
      return;
    }else{
      this.startswith=false;
    }
    if (this.cO_CorporateID.toLowerCase() === this.form.get('cO_Name')?.value.toLowerCase()) {
      console.log('Corporate ID should not be the same as Customer Name');
      this.isSameAsName = true;
      return;
    } else {
      this.isSameAsName = false;
    }
    if (this.cO_CorporateID != this.mergedObj?.cO_CorporateID) {
      this.CustomerMasterService.CheckCorporateIdExists(this.cO_CorporateID).subscribe(
        {
          next: (response) => {
            const data = response.body;
            this.CorporateIdExist = data.result;
            this.startswith=false;
            this.ButtonClick=true;
          }, error: (error) => {
            this.ButtonClick=false;
            console.error('Error fetching State Dropdown data', error);
          }
        }
      );
    }
  }

  formatPan(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input) {
      return;
    }

    let value = input.value.toUpperCase();
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

    if (!panRegex.test(value)) {
      this.form.get('cO_PAN')?.setErrors({ pattern: true });
      this.showValidationErrorsPAN = true;
    } else {
      this.form.get('cO_PAN')?.setErrors(null);
      this.showValidationErrorsPAN = false;
    }

    this.originalPan = value;

    if (value.length === 10) {
      this.showPan = false;
      this.updatePanDisplay();
    } else {
      this.showPan = true;
      this.updatePanDisplay();
    }
  }
  formatcO_TAN(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input) {
      return;
    }
    
    let value = input.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
    this.originalcO_TAN = value;
    
    if (value.length === 10) {
      this.showcO_TAN = false;
      this.updatecO_TANDisplay();
    } else {
      this.showcO_TAN = true;
      this.updatecO_TANDisplay();
    }
  }

  
  togglePanVisibility(): void {
    this.showPan = !this.showPan;
    this.updatePanDisplay();
  }
  
  togglecO_TANVisibility(): void {
    this.showcO_TAN = !this.showcO_TAN;
    this.updatecO_TANDisplay();
  }
  
  
  maskPan(cO_PAN: string): string {
    if (cO_PAN.length === 10) {
      setTimeout(() => {
        if (this.cO_PAN.length === 10) {
          this.showPan = false;
          this.updatePanDisplay();
        }
      }, 5000);
      this.form.get('cO_PAN')?.disable();
      return cO_PAN.replace(/^(.{6})(.{4})$/, 'XXXXXX$2');
    }
    return cO_PAN;
  }
  
  maskcO_TAN(cO_TAN: string): string {
    if (cO_TAN.length === 10) {
      setTimeout(() => {
        if (this.cO_TAN.length === 10) {
          this.showcO_TAN = false;
          this.updatePanDisplay();
        }
      }, 5000);
      this.form.get('cO_TAN')?.disable();
      return cO_TAN.replace(/^(.{6})(.{4})$/, 'XXXXXX$2');
    }
    return cO_TAN;
  }

  updatePanDisplay(): void {
    const panControl = this.form.get('cO_PAN');
    if (this.showPan) {
      panControl?.setValue(this.originalPan);
      panControl?.enable();
    } else {
      panControl?.setValue(this.maskPan(this.originalPan));
    }
  }

  updatecO_TANDisplay(): void {
    const TanControl = this.form.get('cO_TAN');
    if (this.showcO_TAN) {
      TanControl?.setValue(this.originalcO_TAN);
      TanControl?.enable(); 
    } else {
      TanControl?.setValue(this.maskcO_TAN(this.originalcO_TAN));
    }
  }
  initializePan(cO_PAN: string | null | undefined): string {
    if (!cO_PAN) {
      this.showPan = false;
      this.originalPan = '';
      return '';
    }
    
    this.originalPan = cO_PAN.toUpperCase();
    this.showPan = !cO_PAN.includes('X');
    
    return this.showPan ? this.originalPan : this.maskPan(this.originalPan);
  }
  initializecO_TAN(cO_TAN: string | null | undefined): string {
    if (!cO_TAN) {
      this.showcO_TAN = false;
      this.originalcO_TAN = '';
      return '';
    }

    this.originalcO_TAN = cO_TAN.toUpperCase();
    this.showcO_TAN = !cO_TAN.includes('X');

    return this.showcO_TAN ? this.originalcO_TAN : this.maskcO_TAN(this.originalcO_TAN);
  }

  onPincodeAndLocationChange(pincodeAndLocation: any) {
    this.selectedPincode = pincodeAndLocation.split('-')[1];
  }

  onSave(): void {
    debugger;
    this.loading = true;
    this.form.get('cO_PAN')?.setValue(this.originalPan)
    this.form.get('cO_TAN')?.setValue(this.originalcO_TAN)

    const CreateCustomer =
    {
      id: this.mergedObj?.id || 0,
      name: this.form.value.cO_Name || '',
      address: this.form.value.address || "",
      industry: '',
      buyerName: this.form.value.buyerName || "",
      buyerContactNumber: this.form.value.buyerContactNumber || "",
      buyerEmail: this.form.value.buyerEmail || "",
      createdOn: "0001-01-01T00:00:00",
      isDeleted: false,
      startDate: '',
      endDate: '',
      diskSpace: "",
      status: parseInt(this.Status.value),
      iComplianceApplicable: 0,
      locationType: -1,
      verticalApplicable: 0,
      taskApplicable: 0,
      isServiceProvider: false,
      serviceProviderID: this.ServiceProviderID || 0,
      gstNum: "",
      cRegNum: "",
      complianceProductType: this.Compliance.value || 0,
      logoPath: "",
      canCreateSubDist: false,
      isDistributor: false,
      parentID: 0,
      isPayment: false,
      isLabelApplicable: 0,
      isLock: false,
      lockingDays: 0,
      createdBy: this.userId || 0,
      createdFrom: 9
    }
  this.CorporateIdExist==false?this.CorporateIdExist=null:'';
    console.log('createUpdateCustomer',CreateCustomer)
    if (this.form.valid && this.CorporateIdExist == null && this.ButtonClick && !this.startswith) {
      //Onboarding/CreateUpdateCustomer
      this.CustomerMasterService.createUpdateCustomer(CreateCustomer).subscribe({
        next: (response) => {
          console.log('createUpdateCustomer',response);
          const data = response.body.result;
          if (response.status == 200 && data > 0) {
            this.saveCustomerMaster(data);
            this.avacoM_CustomerID = data;
          } else {
            this.alertData = { alertMessage: 'Error while saving Data', alertVisible: 'error' };
            this.loading = false;
          }
        }, error: (error) => {
          this.loading = false;
          console.log(error);
          this.alertData = { alertMessage: error.error.result, alertVisible: 'error' };
        }
      }
      );
    } else {
      this.loading = false;
      console.log('validation error');
      this.form.markAllAsTouched();
    }
  }
  saveCustomerMaster(value: any) {
    debugger
    let formattedDate = new Date();
    const body = {
      avacoM_CustomerID: value || 0,
      cO_CorporateID: this.form.value.cO_CorporateID ||this.cO_CorporateID || '',
      cO_Name: this.form.value.cO_Name || '',
      cO_City: this.City.value,
      cO_State: this.State.value,
      cO_Country: this.Country.value,
      cO_BDLocation: '',
      cO_BDAnchor: '',
      cO_Pincode: this.selectedPincode,
      cO_PAN: this.originalPan,
      cO_TAN: this.originalcO_TAN,
      cO_URL: '',
      cO_ContactDesignation: '',
      cO_FAXNo: '',
      cO_Status: this.Status.text[0]=='S'?null:this.Status.text[0],
      cO_Version: 0,
      createdOn: this.mergedObj?.createdOn || this.formatDateForSave(formattedDate.toISOString()),
      updatedOn: null,
      isProcessed: true,
      cO_IsAventisCorporate: 0
    }
    console.log('saveCustomerMaster', body);
    //Onboarding/CreateUpdate_Customer_CorporateClient_Mapping
    this.CustomerMasterService.saveCustomerMaster(body).subscribe({
      next: (response) => {
        console.log('saveCustomerMasterResponse', response);
        if (response.status == 200 && response.body.result == true) {
          this.UpdateProcessesStatus();
        } else {
          this.loading = false;
          this.alertData = { alertMessage: 'Error while saving Data', alertVisible: 'error' };
        }
      }, error: (error) => {
        this.loading = false;
        console.log(error);
        this.alertData = { alertMessage: error.error.result, alertVisible: 'error' };
      }
    })
  }

  UpdateProcessesStatus() {
    debugger
    const corpID = this.form.value.cO_CorporateID || this.cO_CorporateID;
    //Onboarding/Update_ProcessedStatus_Corporate
    this.CustomerMasterService.UpdateProcessedStatus(this.avacoM_CustomerID, corpID, true).subscribe({
      next: (response) => {
        console.log('UpdateProcessedStatus', response);
        if (response.status == 200 && response.body.result == true) {
          this.CreateProductMapping();
        } else {
          this.loading = false;
          this.alertData = { alertMessage: 'Error while saving Data', alertVisible: 'error' };
        }
      }, error: (error) => {
        this.loading = false;
        console.log('UpdateProcessedStatus', error);
        this.alertData = { alertMessage: 'Error while saving Data', alertVisible: 'error' };
      }
    })
  }
  CreateProductMapping() {
    let formattedDate = new Date();
    const body =
    {
      id: 0,
      customerID: this.avacoM_CustomerID,
      productID: 1,
      isActive: false,
      createdOn: this.formatDateForSave(formattedDate.toISOString()),
      createdBy: 1
    }
    console.log('CreateProductMapping', body);
    this.CustomerMasterService.CreateProductMapping(body).subscribe({
      //Onboarding/CreateProductMapping
      next: (response) => {
        console.log('CreateProductMapping', response);
        if (response.status == 200) {
          if (this.selectedFile) {
            this.UploadClientLogo();
          } else {
            this.loading = false;
            this.alertData = { alertMessage: 'Details Save Successfully', alertVisible: 'success' };
          }
        } else {
          this.loading = false;
          this.alertData = { alertMessage: 'Error while saving Data', alertVisible: 'error' };
        }
      }, error: (error) => {
        console.log('CreateProductMapping', error);
        this.loading = false;
        this.alertData = { alertMessage: 'Error while saving Data', alertVisible: 'error' };
      }
    })
  }
  UploadClientLogo() {
    if (this.selectedFile) {
      this.CustomerMasterService.UploadClientLogo(this.selectedFile, this.avacoM_CustomerID).subscribe({

        next: (response) => {
          console.log('UploadClientLogo', response);
          if (response.status == 200 && response.body.result == true) {
            this.loading = false;
            this.alertData = { alertMessage: 'Details Save Successfully', alertVisible: 'success' };
          } else {
            this.loading = false;
            this.alertData = { alertMessage: 'Error while saving Data', alertVisible: 'error' };
          }
        }, error: (error) => {
          console.log('UploadClientLogo', error);
          this.loading = false;
          this.alertData = { alertMessage: 'Error while saving Data', alertVisible: 'error' };
        }
      })
    }
  }
  formatDateForSave(dateString: string | null): string | null {
    if (!dateString || dateString.trim() === '') {
      return null;
    }

    const dateParts = dateString.split('/');

    if (dateParts.length === 3 && dateParts.every(part => !isNaN(Number(part)))) {
      const day = dateParts[0];
      const month = dateParts[1];
      const year = dateParts[2];

      const date = new Date(`${year}-${month}-${day}T00:00:00`);

      if (!isNaN(date.getTime())) {
        return this.convertToISOFormat(date);
      } else {
        return null;
      }
    } else {
      const date = new Date(dateString);

      if (!isNaN(date.getTime())) {
        return this.convertToISOFormat(date);
      } else {
        return null;
      }
    }
  }

  convertToISOFormat(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }
  closePopup(): void {
    this.data = undefined;
    this.close.emit();
  }
  closeAlert() {
    if (this.alertData.alertVisible == 'success') {
      this.closePopup();
    }
    this.alertData = { alertMessage: '', alertVisible: '' };
  }
}
