<kendo-dialog #dialogRef title="PO Number Generation" (close)="closePopup()" [minWidth]="400" [width]="1200"
    class="custom-dialog">
    <div class="row dialog-content">

        <div class="col-md-12 colpadding0 " style="display:flex;justify-content: space-between;">
            <div class="col-md-3" style="    margin-left: -19px;margin-top:22px;">
                <!-- <label for="clientDropdown" style="margin-left: 7px;color:black;font-size:13px;">Select Client<sup class="text-danger">*</sup></label> -->
                <app-dropdown-list id="dropdownvalue" [data]="ClientDropdownData" [selectedValue]="selectedClient"
                    [width]="'250px'" style="margin-left: 20px;" textField="text" valueField="value"
                    (valueChange)="onDropdownValueChange($event)">
                </app-dropdown-list>
                <!-- <sup class="text-danger">*</sup> -->
            </div>
            <div class="row colpadding0 col-md-8">


                <div class="col-md-6 colpadding0" style="display: flex; align-items: center;margin-top:10px;">
                    <h4 style="color: black; font-size: 12px; margin-right: 5px;">
                        Po Type<sup class="text-danger">*</sup>
                    </h4>

                    <span style="margin-right: 5px;"></span> <!-- Colon added here -->


                    <header class="panel-heading tab-bg-primary" style="background: none !important;">
                        <ul style="display: flex; align-items: center; margin-top: 10px;">
                            <li style="display: flex; align-items: center; margin-right: 20px;">
                                <input id="rdDow" value="C" type="radio" class="custom-radio" name="measurementSystem"
                                    (change)="onMeasurementSystemChange($event)" [disabled]="!isRowDataEmpty()" />
                                <h4 style="margin: 0 0 0 5px;">Consolidated</h4>
                            </li>
                            <!-- fsf -->
                            <li style="display: flex; align-items: center;">
                                <input id="rdFai" value="S" type="radio" class="custom-radio" name="measurementSystem"
                                    (change)="onMeasurementSystemChange($event)" [disabled]="!isRowDataEmpty()" />
                                <h4 style="margin: 0 0 0 5px;">Split</h4>
                            </li>
                        </ul>
                    </header>

                </div>

            </div>

        </div>
        <div class="col-md-12" style="margin-top:1%;">
            <ng-container *ngIf="rowData?.length > 0 && rowData[0]?.poType == 'C'">
                <kendo-grid [data]="rowData" [scrollable]="'none'">
                    <kendo-grid-column field="poType" title="Type">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span style="font-size: 14px;font-weight: 400;"> {{ dataItem.poType === 'C' ? 'Consolidate'
                                : dataItem.poType }}</span>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column field="poDate" title="PO Date">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <app-kendo-datepicker class="uniform-size" [(value)]="dataItem.pO_ConsolidatedDateFormatted"
                                (valueChange)="onDateChange($event, 'poDate', dataItem)" [format]="'dd-MM-yyyy'"
                                [placeholder]="placeholder" [max]="todayDate" [width]="'200px'"
                                [class.input-invalid]="!dataItem.poDate && showValidationErrors"
                                [hasError]="!dataItem.poDate && showValidationErrors">
                            </app-kendo-datepicker>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column field="pO_ConsolidatedPONumber" title="PO Number">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <input type="text" class="f-textbox" [(ngModel)]="dataItem.pO_ConsolidatedPONumber" name="poNumber"
                                style="width: 100%; border: 1px solid #ddd; background-color: #f5f5f5;">
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="pO_ConsolidatedAmount" title="po Amount">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <input type="number" class="f-textbox" [(ngModel)]="dataItem.pO_ConsolidatedAmount" name="po Amount"
                                style="width: 100%; border: 1px solid #ddd; background-color: #f5f5f5;">
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column field="poFrom" title="From">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <app-kendo-datepicker class="uniform-size" [(value)]="dataItem.pO_ConsolidatedFromFormated"
                                (valueChange)="onDateChange($event, 'poFrom', dataItem)" [format]="'dd-MM-yyyy'"
                                [placeholder]="placeholder" [max]="todayDate" [width]="'200px'"
                                [class.input-invalid]="!dataItem.poFrom && showValidationErrors"
                                [hasError]="!dataItem.poFrom && showValidationErrors">
                            </app-kendo-datepicker>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column field="poTo" title="To">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <app-kendo-datepicker class="uniform-size" [(value)]="dataItem.pO_ConsolidatedToFormated"
                                (valueChange)="onDateChange($event, 'poTo', dataItem)" [format]="'dd-MM-yyyy'"
                                [placeholder]="placeholder" [max]="todayDate" [width]="'200px'"
                                [class.input-invalid]="!dataItem.poTo && showValidationErrors"
                                [hasError]="!dataItem.poTo && showValidationErrors">
                            </app-kendo-datepicker>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </ng-container>

            <ng-container
                *ngIf="rowData?.length == 0 && rowData[0]?.poType != 'C' && rowData[0]?.poType != 'Recurring'">
                <kendo-grid [data]="rowData" [scrollable]="'none'">
                    <kendo-grid-column field="poType" title="Type">
                        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                            <span>{{ dataItem.poType === 'S' ? 'Recurring' : 'Liasoning' }}</span>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column field="pO_RecurringDate" title="PO Date">
                        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                            <app-kendo-datepicker class="uniform-size" [(value)]="dataItem.pO_RecurringDate"
                                (valueChange)="onDateChange($event, 'recurringPODate','')" [format]="'dd-MM-yyyy'"
                                [placeholder]="'DD-MM-YYYY'" [max]="todayDate" [width]="'200px'">
                            </app-kendo-datepicker>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column field="pO_RecurringPONumber" title="PO Number">
                        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                            <input type="text" class="f-textbox" [(ngModel)]="dataItem.pO_RecurringPONumber"
                                style="width: 100%; border: 1px solid #ddd; background-color: #f5f5f5;">
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column field="pO_RecurringAmount" title="PO Amount">
                        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                            <input type="number" class="f-textbox" [(ngModel)]="dataItem.pO_RecurringAmount"
                                style="width: 100%; border: 1px solid #ddd; background-color: #f5f5f5;">
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column field="pO_RecurringFrom" title="From">
                        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                            <app-kendo-datepicker class="uniform-size" [(value)]="dataItem.pO_RecurringFrom"
                                (valueChange)="onDateChange($event, 'recurringFromDate','')" [format]="'dd-MM-yyyy'"
                                [placeholder]="'DD-MM-YYYY'" [max]="todayDate" [width]="'200px'">
                            </app-kendo-datepicker>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column field="pO_RecurringTo" title="To">
                        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                            <app-kendo-datepicker class="uniform-size" [(value)]="dataItem.pO_RecurringTo"
                                (valueChange)="onDateChange($event, 'recurringToDate','')" [format]="'dd-MM-yyyy'"
                                [placeholder]="'DD-MM-YYYY'" [max]="todayDate" [width]="'200px'">
                            </app-kendo-datepicker>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>



            </ng-container>

        </div>
        
     
            <!-- <kendo-grid [data]="[rowData]" [scrollable]="'none'" >
                <kendo-grid-column field="pO_Type" title="Type">
                    <ng-template kendoGridCellTemplate>
                    </ng-template>
                </kendo-grid-column>

           

    
                <kendo-grid-column field="esic_App" title="	PO Date">
                    <ng-template kendoGridCellTemplate>
                        <app-kendo-datepicker class="uniform-size" [(value)]="dateOfBirth"
                        (valueChange)="onDateChange($event, 'dateOfBirth','')" [format]="'dd-MM-yyyy'"
                        [placeholder]="placeholder" [max]="todayDate" [width]="'200px'"
                        [class.input-invalid]="!dateOfBirth && showValidationErrors"
                        [hasError]="!dateOfBirth && showValidationErrors">
                    </app-kendo-datepicker>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="pF_App" title="PO Number">
                    <ng-template kendoGridCellTemplate>
                        <input type="number" [(ngModel)]="sequenceOrder" name="sequenceOrder"
                        style="width: 100%; border: 1px solid #ddd; background-color: #f5f5f5;">                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="pT_App" title="PO Amount">
                    <ng-template kendoGridCellTemplate>
                        <input type="number" [(ngModel)]="sequenceOrder" name="sequenceOrder"
                        style="width: 100%; border: 1px solid #ddd; background-color: #f5f5f5;">                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="lwF_App" title="From">
                    <ng-template kendoGridCellTemplate>
                        <app-kendo-datepicker class="uniform-size" [(value)]="dateOfBirth"
                        (valueChange)="onDateChange($event, 'dateOfBirth','')" [format]="'dd-MM-yyyy'"
                        [placeholder]="placeholder" [max]="todayDate" [width]="'200px'"
                        [class.input-invalid]="!dateOfBirth && showValidationErrors"
                        [hasError]="!dateOfBirth && showValidationErrors">
                    </app-kendo-datepicker>                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="lwF_App" title="To">
                    <ng-template kendoGridCellTemplate>
                        <app-kendo-datepicker class="uniform-size" [(value)]="dateOfBirth"
                        (valueChange)="onDateChange($event, 'dateOfBirth','')" [format]="'dd-MM-yyyy'"
                        [placeholder]="placeholder" [max]="todayDate" [width]="'200px'"
                        [class.input-invalid]="!dateOfBirth && showValidationErrors"
                        [hasError]="!dateOfBirth && showValidationErrors">
                    </app-kendo-datepicker>
                </ng-template>
                </kendo-grid-column>
            </kendo-grid> -->
        </div> 

    <div class="col-md-12" style="margin-top:0%;" *ngIf="selectedRadioValue == 'C' && isDataInvalid()">
        <kendo-grid [data]="[rowData]" [scrollable]="'none'">
            <kendo-grid-column field="pO_Type" title="Type">
                <ng-template kendoGridCellTemplate>
                    <span style="font-size: 14px;font-weight: 400;">Consolidated</span>
                </ng-template>
            </kendo-grid-column>




            <kendo-grid-column field="esic_App" title="	PO Date">
                <ng-template kendoGridCellTemplate>
                    <app-kendo-datepicker class="uniform-size" [(value)]="consolidateDOB"
                        (valueChange)="onDateChange($event, 'consolidateDOB','')" [format]="'dd-MM-yyyy'"
                        [placeholder]="placeholder" [max]="todayDate" [width]="'200px'"
                        [class.input-invalid]="!consolidateDOB && showValidationErrors"
                        [hasError]="!consolidateDOB && showValidationErrors">
                    </app-kendo-datepicker>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="pF_App" title="PO Number">
                <ng-template kendoGridCellTemplate>
                    <input type="number" [(ngModel)]="consolidatepoNumber" name="consolidatepoNumber"
                        style="width: 100%; border: 1px solid #ddd; background-color: #f5f5f5;"> </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="pT_App" title="PO Amount">
                <ng-template kendoGridCellTemplate>
                    <input type="number" [(ngModel)]="consolidatepoAmount" name="consolidatepoAmount"
                        style="width: 100%; border: 1px solid #ddd; background-color: #f5f5f5;"> </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="lwF_App" title="From">
                <ng-template kendoGridCellTemplate>
                    <app-kendo-datepicker class="uniform-size" [(value)]="consolidatefrom"
                        (valueChange)="onDateChange($event, 'consolidatefrom','')" [format]="'dd-MM-yyyy'"
                        [placeholder]="placeholder" [max]="todayDate" [width]="'200px'"
                        [class.input-invalid]="!consolidatefrom && showValidationErrors"
                        [hasError]="!consolidatefrom && showValidationErrors">
                    </app-kendo-datepicker> </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="lwF_App" title="To">
                <ng-template kendoGridCellTemplate>
                    <app-kendo-datepicker class="uniform-size" [(value)]="consolidateto"
                        (valueChange)="onDateChange($event, 'consolidateto','')" [format]="'dd-MM-yyyy'"
                        [placeholder]="placeholder" [max]="todayDate" [width]="'200px'"
                        [class.input-invalid]="!consolidateto && showValidationErrors"
                        [hasError]="!consolidateto && showValidationErrors">
                    </app-kendo-datepicker>
                </ng-template>
                </kendo-grid-column>
            </kendo-grid>
 </div>


<div class="col-md-12" style="margin-top:0%;" *ngIf="selectedRadioValue == 'S' &&  isDataInvalid()">
    <kendo-grid [data]="[{}, {}]" [scrollable]="'none'">
        <kendo-grid-column field="pO_Type" title="Type">
            <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
                <span *ngIf="rowIndex === 0" class="splits">Recurring</span>
                <span *ngIf="rowIndex === 1" class="splits">Liasoning</span>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="esic_App" title="PO Date">
            <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
                <app-kendo-datepicker 
                    class="uniform-size" 
                    [(value)]="splitPODate[rowIndex]"
                    (valueChange)="onDateChange($event, rowIndex === 0 ? 'recurringPODate' : 'liasoningPODate', rowIndex)"
                    [format]="'dd-MM-yyyy'"
                    [placeholder]="'DD-MM-YYYY'"
                    [max]="todayDate"
                    [width]="'200px'">
                </app-kendo-datepicker>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="pF_App" title="PO Number">
            <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
                <input 
                    type="number" 
                    [(ngModel)]="spiltpoNumbers[rowIndex]"
                    style="width: 100%; border: 1px solid #ddd; background-color: #f5f5f5;">
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="pT_App" title="PO Amount">
            <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
                <input 
                    type="number" 
                    [(ngModel)]="spiltpoAmount[rowIndex]"
                    style="width: 100%; border: 1px solid #ddd; background-color: #f5f5f5;">
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="lwF_App" title="From">
            <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
                <app-kendo-datepicker 
                    class="uniform-size" 
                    [(value)]="splitfrom[rowIndex]"
                    (valueChange)="onDateChange($event, rowIndex === 0 ? 'recurringFromDate' : 'liasoningFromDate', rowIndex)"
                    [format]="'dd-MM-yyyy'"
                    [placeholder]="'DD-MM-YYYY'"
                    [max]="todayDate"
                    [width]="'200px'">
                </app-kendo-datepicker>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="lwF_App" title="To">
            <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
                <app-kendo-datepicker 
                    class="uniform-size" 
                    [(value)]="splitTo[rowIndex]"
                    (valueChange)="onDateChange($event, rowIndex === 0 ? 'recurringToDate' : 'liasoningToDate', rowIndex)"
                    [format]="'dd-MM-yyyy'"
                    [placeholder]="'DD-MM-YYYY'"
                    [max]="todayDate"
                    [width]="'200px'">
                </app-kendo-datepicker>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid>
</div>

   
    <button class="figma-btn-blue " style="margin-top:35px;font-size:16px;" (click)="OnSubmitPoDetails()">
        Submit
    </button>
    <kendo-dialog-actions style="border: none; padding: 15px;">
        <!-- <button kendoButton class="figma-btn-blue figma-popup" (click)="savePaycodeMapping()">Save</button> -->
    </kendo-dialog-actions>
</kendo-dialog>
<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270" [Data]="alertData"
    (close)="closeAlert()"></app-alert-popup>