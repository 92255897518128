import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { SubcodeService } from '../../../service/subcode.service';
import { BlobService } from '../../../service/blob.service';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { PopupComponent } from '../../../components/popup/popup.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { SubCodeMasterAddEditPopupComponent } from '../../popups/sub-code-master-add-edit-popup/sub-code-master-add-edit-popup.component';

@Component({
  selector: 'app-esic-subcode',
  standalone: true,
  imports: [GridActionItemComponent, CommonModule, FormsModule, PopupComponent, SubCodeMasterAddEditPopupComponent, IndicatorsModule, ComboBoxComponent],
  templateUrl: './esic-subcode.component.html',
  styleUrls: ['./esic-subcode.component.css']
})
export class EsicSubcodeComponent implements OnInit {
  public rowData!: string[];
  public gridData: GridDataResult = { data: [], total: 0 };
  public originalData: any[] = [];
  public dropdown: { id: number; name: string }[] = [];
  tokenCookie?: string;
  public defaultSelectedValue = 'Search User ID';
  public isAddNewVisible: boolean = false;
  loading: boolean = false;
  IsUpdate: boolean = false;
  public password: string = '';

  constructor(private subcodeService: SubcodeService, private blobService: BlobService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.loadEntityData();
    this.FilterDropdown('0');
  }

  applyFilter(event: any) {
    const filterValue = event;
    this.gridData = {
      data: this.originalData.filter(item => item.username.toLowerCase().includes(filterValue)),
      total: this.originalData.filter(item => item.username.toLowerCase().includes(filterValue)).length
    };
  }
  DropdownGridChange(value: any) {
    debugger
    if (value == undefined) {
      this.loadEntityData()
    }
    this.applyFilter(value.name)
  }

  FilterDropdown(value: any) {
    this.subcodeService.getUserIDDropdown(value).subscribe({
      next: (response) => {
        if (response.status === 200) {
          const data = response.body;
          if (data) {
            this.dropdown = data.map((item: any) => ({
              id: item.username,
              name: item.subCode
            }));
          }
        }
      }
    })
  }

  openAddNewPopup() {
    this.isAddNewVisible = true;
    this.rowData = [];
    this.password = '';
    this.IsUpdate=false;
  }

  exportToExcel() {
    debugger;
    this.loading = true;
    this.subcodeService.getSubCodeMasterBlobLink().subscribe({
      next: (res: any) => {
        debugger;
        const blobLink = res.filePath;
        const blobName = this.blobService.extractBlobName(blobLink);
        setTimeout(() => {
          this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
            debugger;
            const sasToken = tokenResponse.result;
            this.blobService.downloadFile(blobName, sasToken);
            this.loading = false;
          });
        }, 5000);
      },
      error: (error) => {
        console.error('Error fetching blob link:', error);
        this.loading = false;
      }
    });
  }

  handleButtonClick(event: any) {
    if (event.button.action === "Edit") {
      this.rowData = event.dataItem;
      this.password = event.dataItem.originalPassword;
      this.isAddNewVisible = true;
      this.IsUpdate=true;
    }
  }

  handleClosePopup() {
    this.isAddNewVisible = false;
    this.loadEntityData();
  }

  loadEntityData(): void {
    this.subcodeService.getSubCodeData().subscribe({
      next: (response) => {
        const data = response.body;
        this.originalData = data.map((item: any) => ({
          ...item,
          originalPassword: item.password
        }));


        this.gridData = {
          data: this.originalData,
          total: this.originalData.length
        };

        setTimeout(() => {
          this.originalData.forEach(item => {
            item.password = '**********';
          });
          this.gridData = {
            data: this.originalData,
            total: this.originalData.length
          };
        }, 5000);
      },
      error: (err) => {
        console.error('Error fetching entity data', err);
      }
    });
  }

  applyMasking(): void {
    setTimeout(() => {
      this.gridData.data.forEach(item => {
        item.password = '**********';
      });
    }, 5000);
  }

  public parentColumns: any[] = [
    { field: 'username', title: 'User ID' },
    { field: 'password', title: 'Password' },
    {
      field: 'Action',
      isIconColumn: true,
      title: 'Action',
      filteredIcons: [
        {
          url: '../assets/vectors/edit.svg',
          alt: 'Icon 1',
          action: 'Edit',
        },
      ]
    }
  ];
}
