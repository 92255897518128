import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthorityAddressServiceService {
  private baseUrl =  environment.apiUrl;
  constructor(private http: HttpClient) { }

  uploadFile(file: File, CustID: string, UserID: string): Observable<any> {
    const url=`${this.baseUrl}/api/AuthorityAddress/AuthorityAddressUpload`
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('CustID', Number(CustID).toString());
    formData.append('UserID', Number(UserID).toString());
    
    return this.http.post<any>(url, formData);
  }


  uploadSignature(file: File, clientid: any, locationid: any, userid: any): 
  Observable<Blob> {
     const url=`${this.baseUrl}/api/AuthorityAddress/UploadSignature`
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('clientid', clientid.toString());
    formData.append('locationid', locationid.toString());
    formData.append('userid', userid.toString());

    return this.http.post<Blob>(url, formData);
  }

}



