import { Component,CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';

import { ActivityCalenderServiceService } from '../../../service/activity-calender.service';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { NgIf } from '@angular/common';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { MasterTabsComponent } from '../../../components/master-tabs/master-tabs.component';
import { UserService } from '../../../service/user.service';


@Component({
  selector: 'app-activity-calender',
  standalone:true,
  imports:[GridActionItemComponent,AlertPopupComponent,NgIf,DropdownListComponent,MasterTabsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './activity-calender.component.html',
  styleUrls: ['./activity-calender.component.css']
})

export class ActivityCalenderComponent implements OnInit {
  TabOption: string = 'Activity';
  showOtherTabs = true;
  route='/ActivityCalender';
  options: string[] = [];
  public originalData: any[] = [];
  public dropdownData: any[] = [];
  Status = { text: 'Select Entity', value: '' };
  public dropData = { id: 0, name: 'Select State' };
  public selectedOption: any;
  
  public gridData: GridDataResult = { data: [], total: 0 };
  rowData: any; 
  parentColumns: any[] = [
    { field: 'service', title: 'Service' },
    { field: 'inputCutoffDate', title: 'Input Cut-off Date' },
    { field: 'activityStartDate', title: 'Activity Start Date' },
    { field: 'activityEndDate', title: 'Activity End Date' },
    { field: 'alertTriggerDate', title: 'Alert Trigger Date' },
    { field: 'eP1', title: 'EP 1' },
    { field: 'eP2', title: 'EP 2' },
    { field: 'eP3', title: 'EP 3' }
  ];
  loading = false;
  isAddNewVisible = false;
  custID!:any;
constructor(private activityCalenderService: ActivityCalenderServiceService, private userService: UserService) {}
ngOnInit(): void {
    this.custID =this.userService.getCustomerId();
    // this.custID = localStorage.getItem('CustomerId');
    this.getDropdownList()
  }
getDropdownList(){
debugger;
    const customerId = Number(this.custID);
    if (!isNaN(customerId)) {

    this.activityCalenderService.getDropdownOptions(customerId).subscribe({
      next: (response) => {
        debugger;
        const data=response.body;
        console.log(data);
        
        if (data && data && Array.isArray(data)) {
          this.dropdownData =[ 
            ...data.map((item: any) => ({
              value: item.value,
              text: item.key
            }))]}
      },
      error: (err) => {
        console.error('Error fetching Dispencery data List', err);
        this.loading = false;
      }
    }
    );

  }
else {
      console.error('Invalid customer ID');
    }

  }

 
  onDropdownValueChange(value: any) {
    debugger;
   console.log(value);
   this.gridData={data:[],total:0}
   this.loadGridData(value.value);  
  }
  loadGridData(value:string): void {
    this.loading = true;
    this.activityCalenderService.getGridData(value).subscribe(
     {
      next:(response)=> {
        const data=response.body;
        console.log(data);
        
        this.gridData=
         {
          data: data,
          total: data.length
        };     
         this.loading=false;
      },error:(error)=>{
        console.error(error);
        
      }
     }
    );
  }

  handleButtonClick(event: any): void {
    this.rowData = event.dataItem;  
    this.isAddNewVisible = true; 
}
}
 
  

  


