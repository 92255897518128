<div class="row colpadding0" >
  <div class="col-md-12 colpadding0 toolbar" style="margin-top: 10px;">
      <div class="col-md-8 colpadding0" style=" justify-content: space-between;">
      <app-dropdown-list [data]="states" [selectedValue]="defaultState" 
            (valueChange)="onValueChange($event)"
             [width]="'200px'"
             textField="key" 
            valueField="value" 
            style="margin-right: 7px;">
        </app-dropdown-list>
          <div style="display: inline">
              <button class="figma-btn-white" (click)="applyFilter()" type="submit">
                  <img src="../assets/vectors/ApplyFilter.svg" alt="">
              </button>
              <button (click)="clearFilter()" class="figma-btn-white">
                  <img src="../assets/vectors/ClearFilter.svg" alt="">
                </button>
              </div>
            </div>
            <div class="col-md-4 colpadding0">
              <div style="display: inline" style="float: right; ">
                <button  class="figma-btn-blue" (click)="saveMunicipalityMapping()" >
              <img src="../assets/vectors/Plus.svg" alt="">Save Mapping
             
            </button>
            <button class="figma-btn-blue"   (click)="bulkUpload()">
              <img src="../assets/vectors/uploadWhite.svg" alt="">Upload Mapping
            </button>
          </div>
      </div>
  </div>
</div>

<kendo-grid [data]="gridData" style="margin-top: 10px;" [pageSize]="pageSize" [pageable]="true" class="custom-grid" (pageChange)="pageChange($event)" [skip]="skip">
  <kendo-grid-column field="eM_EmpID" title="EM_EmpID">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [title]="dataItem.eM_EmpID" class="cell-content">
        {{dataItem.eM_EmpID}}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="eM_EmpName" title="EM_EmpName">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [title]="dataItem.eM_EmpName" class="cell-content">
        {{dataItem.eM_EmpName}}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="eM_Branch" title="EM_Branch">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [title]="dataItem.eM_Branch" class="cell-content">
        {{dataItem.eM_Branch}}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="eM_Location" title="EM_Location">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [title]="dataItem.eM_Location" class="cell-content">
        {{dataItem.eM_Location}}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="municipalityName" title="PT Location">
    <ng-template kendoGridCellTemplate let-dataItem>
      <kendo-dropdownlist
        [data]="municipalities"  
        textField="text"
        valueField="value"
        (valueChange)="onMunicipalityChange($event, dataItem)"
        class="custom-dropdown">

      </kendo-dropdownlist>
      </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="action" title="Action" [width]="80">
    <ng-template kendoGridCellTemplate let-dataItem>     
      <img src="../assets/vectors/save1.svg" class="save-icon" alt="" (click)="onSaveClick(dataItem)">    
  </ng-template>
  </kendo-grid-column>
  <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
    <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
    <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
    <kendo-pager-next-buttons></kendo-pager-next-buttons>
    <kendo-pager-input></kendo-pager-input>
    <kendo-pager-info></kendo-pager-info>
    <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
</ng-template>
</kendo-grid>

<app-alert-popup *ngIf="alertData" [Width]="270"  [Data]="alertData" (close)="closePopup()"></app-alert-popup>

<div *ngIf="loading" class="loader-overlay">
  <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>
<app-challan-bulkupload-popup *ngIf="uploadOpen" (close)="closeUploadPopup()" >
</app-challan-bulkupload-popup>


