import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DispenceryMasterService } from '../../../service/dispencery-master.service';
import { BlobService } from '../../../service/blob.service';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { DispencerymasterPopupComponent } from '../../popups/dispencerymaster-popup/dispencerymaster-popup.component';

interface State {
  id?: number;
  stateName?: string;
  name?: string;
}

@Component({
  selector: 'app-dispencery-master',
  standalone: true,
  imports: [DropdownListComponent, GridActionItemComponent, FormsModule, CommonModule, DispencerymasterPopupComponent, IndicatorsModule, ComboBoxComponent],
  templateUrl: './dispencery-master.component.html',
  styleUrls: ['./dispencery-master.component.css']
})
export class DispenceryMasterComponent implements OnInit {

  public gridData: GridDataResult = { data: [], total: 0 };
  public dropdownData: any[] = [];
  public originalData: any[] = []; // Store original data
  hdntokenCookie?: string;
  public isAddNewVisible: boolean = false;
  public dropData = { id: null, name: 'Select State' };
  public defaultSelectedValue = { id: 0, name: 'Select State' };
  public rowData!: any;
  loading: boolean = false; 
  public OriginalData: any[] = [];
  constructor(private dispenceryMasterService: DispenceryMasterService, private blobService: BlobService) { }

  ngOnInit(): void {
    this.loadStateDropdown();
  }

  onDropdownValueChange(value: any) {
    debugger;
    if (value == undefined) {
      this.gridData = { data: [], total: 0 };
      this.dropData={ id: null, name: 'Select State' }      
    }
    this.dropData = { id: value.id, name: value.name }
    this.dispenceryMasterService.getDispenceryDataList(value.name).subscribe({
      next: (response) => {
        const data = response.body;
        if (data) {
          this.OriginalData = data;
          this.gridData = {
            data: data,
            total: data.length
          };
        }
      },
      error: (err) => {
        console.error('Error fetching Dispencery data List', err);
        this.loading = false;
      }
    }
    );
  }
loadGrid(value:string){
  this.dispenceryMasterService.getDispenceryDataList(value).subscribe({
    next: (response) => {
      const data = response.body;
      if (data) {
        this.OriginalData = data;
        this.gridData = {
          data: data,
          total: data.length
        };
      }
    },
    error: (err) => {
      console.error('Error fetching Dispencery data List', err);
      this.loading = false;
    }
  }
  );
}
  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    const filteredData = this.OriginalData.filter(item =>
      (item.stateName && item.stateName.toLowerCase().includes(filterValue)) ||
      (item.districtName && item.districtName.toString().toLowerCase().includes(filterValue))||
      (item.dispenceryName && item.dispenceryName.toString().toLowerCase().includes(filterValue))
    );

    this.gridData = {
      data: filteredData,
      total: filteredData.length
    };
  }

  handleButtonClick(event: any) {
    if (event.button.action === "edit") {
      this.rowData = event.dataItem;
      this.isAddNewVisible = true;
    }
  }

  handleClosePopup() {
    this.isAddNewVisible = false;
    this.loadGrid(this.dropData.name)
  }
  openAddNewPopup() {
    this.isAddNewVisible = true;
    this.rowData = {
      stateName: 'Select State', districtName: 'Select District',
      dispenceryName: 'Select Dispencery'
    }
  }

  exportToExcel() {
    debugger;
    this.loading = true;
    this.dispenceryMasterService.getDispenceryExcel().subscribe((response: any) => {
      debugger;
      const blobLink = response.filePath;
      const blobName = this.blobService.extractBlobName(blobLink);
      setTimeout(() => {
        this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
          debugger;
          const sasToken = tokenResponse.result;
          this.blobService.downloadFile(blobName, sasToken);
          this.loading = false;
        });
      }, 5000);
    });
  }

  loadStateDropdown(): void {
    this.dispenceryMasterService.getAllStatesForDispencery().subscribe({
      next: (response) => {
        const data = response.body;
        if (data && Array.isArray(data)) {
          this.dropdownData = data.map((item: State, index: number) => {
            const newItem: State = { ...item };
            if (!newItem.id) {
              newItem.id = index;
            }
            if (newItem.stateName) {
              newItem.name = newItem.stateName;
              delete newItem.stateName;
            }
            return newItem;
          });
        }
      },
      error: (err) => {
        console.error('Error fetching State Dropdown data', err);
      }
    });
  }

  public parentColumns: any[] = [
    { field: 'stateName', title: 'State' },
    { field: 'districtName', title: 'District' },
    { field: 'dispenceryName', title: 'Dispencery' },
    {
      field: 'Action',
      title: 'Action',
      filteredIcons: [
        {
          url: '../assets/vectors/edit.svg',
          alt: 'Icon 1',
          action: 'edit'
        },
      ]
    }
  ];
}
