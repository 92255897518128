

<div class="row colpadding0">
  <div class="col-md-12 colpadding0 toolbar" style="margin-top: 10px;">
      <div class="col-md-8 colpadding0" style=" justify-content: space-between; ">
        <app-combo-box [width]="'280px'" [Placeholder]="defaultSearch" (filterChange)="onFilterChange($event)" textField="name" valueField="id" [selectedValue]="selectedValue"
        (valueChange)="onDropdownChange($event)" [data]="allPaycodeMappingData">
      </app-combo-box>

        
          <div style="display: inline">
              <button class="figma-btn-white" (click)="applyFilter()"type="submit">
                  <img src="../assets/vectors/ApplyFilter.svg" alt="">
              </button>
              <button (click)="clearFilter()" class="figma-btn-white">
                  <img src="../assets/vectors/ClearFilter.svg" alt="">
              </button>
          </div>
      </div>
      <div class="col-md-4 colpadding0">
          <div style="display: inline" style="float: right; ">
    
              <button (click)="uploadDialog()" class="figma-btn-blue  ">
                  <img src="../assets/vectors/uploadWhite.svg" alt="">Upload
              </button>
              <button class="figma-btn-blue" (click)="saveDialog()" type="submit">
                  <img src="../assets/vectors/Plus.svg" alt="">Add New
              </button>
          </div>
      </div>
  </div>
</div>

<div class="grid" style="margin-top: 10px;">
  <app-grid-action-item [gridData]="gridData" [columns]="parentColumns" [pageSize]="10" [pageable]="true"
    [actionColumnWidth]="'100px'" [filterable]="true" (buttonClick)="onActionClick($event)">
    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
      <ng-container *ngIf="column.field === 'cpmD_PayCode'">
        <ng-container *ngIf="dataItem.cpmD_PayGroup === 'standard'; else paycodesTemplate">
          {{ dataItem.cpmD_Standard_Column }}
        </ng-container>
        <ng-template #paycodesTemplate>
          {{ dataItem.cpmD_PayCode }}
        </ng-template>
      </ng-container>
    </ng-template>

  </app-grid-action-item>
</div>


<div *ngIf="showLoader" class="loader-overlay" class="loader-overlay">
  <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>
<div kendoDialogContainer *ngIf="uploadOpen">
  <app-paycode-upload-popup (close)="closeUploadDialog()"></app-paycode-upload-popup>
</div>

<div kendoDialogContainer *ngIf="editOpen">
  <app-paycode-edit-popup [rowData]="selectedRowData" (update)="handleUpdate()"
    (close)="handleEditPopupClose()"></app-paycode-edit-popup>
</div>

<div kendoDialogContainer *ngIf="saveOpen">
  <app-paycode-add-popup (dataSaved)="onDataSaved()" (close)="closeSaveDialog()"></app-paycode-add-popup>
</div>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270"  [id]="deleteId" [Data]="alertData"
  (close)="closePopup()" (confirm)="DeleteIndustrySkill($event)"></app-alert-popup>