<kendo-dialog #dialogRef title="Shift Master Details" (close)="closeDialog()" [minWidth]="500" [width]="1000"
    [height]="'auto'" class="custom-dialog" >
    <div>
        <button class="figma-btn-blue "  style="float: right; " (click)="addNewShiftMaster()" >
            <img src="../assets/vectors/Plus.svg" alt="">
             Add Shift Slab
          </button>
    </div>
    <div class="dialog-content">
        <kendo-grid [data]="data" [style.height]="'auto'">
            <kendo-grid-column field="shiftName" title="Shift Name" class="cell-content">
            </kendo-grid-column>
            <kendo-grid-column field="shiftCode" title="Shift Code" class="cell-content">
            </kendo-grid-column>
            <kendo-grid-column field="nightShift" title="Night Shift" class="cell-content">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <kendo-dropdownlist 
                    [(ngModel)]="dataItem.nightShift"       
                    name="nightShift"
                    [data]="nightShiftOptions"
                    textField="text"
                    valueField="value"
                    [defaultItem]="{text:'Select',value:0}"
                    style="width: 100%;">
                    </kendo-dropdownlist>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="shiftFrom" title="Shift From" class="cell-content">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <kendo-dropdownlist 
                        [(ngModel)]="dataItem.shiftFrom"
                        [data]="timeIntervals"
                        [defaultItem]="'Select'"
                        [valuePrimitive]="true"
                        style="width: 100%;">
                    </kendo-dropdownlist>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="shiftTo" title="Shift To" class="cell-content">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <kendo-dropdownlist 
                        [(ngModel)]="dataItem.shiftTo"
                        [data]="timeIntervals"
                        [defaultItem]="'Select'"
                        [valuePrimitive]="true"
                        style="width: 100%;">
                    </kendo-dropdownlist>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="shiftIntervalFrom" title="Interval From" class="cell-content">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <kendo-dropdownlist 
                        [(ngModel)]="dataItem.shiftIntervalFrom"
                        [data]="timeIntervals"
                        [defaultItem]="'Select'"
                        [valuePrimitive]="true"
                        style="width: 100%;">
                    </kendo-dropdownlist>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="shiftIntervalTo" title="Shift Interval To" class="cell-content">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <kendo-dropdownlist 
                        [(ngModel)]="dataItem.shiftIntervalTo"
                        [data]="timeIntervals"
                        [defaultItem]="'Select'"
                        [valuePrimitive]="true"
                        style="width: 100%;">
                    </kendo-dropdownlist>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Action" >
                <ng-template kendoGridCellTemplate let-dataItem>
                  <button kendoButton (click)="deleteShift(dataItem)" style="border: 0; background: none; margin-left: 30px;">
                    <img src='../assets/vectors/delete.svg' alt="Icon 1" class="svg-icon" />
                  </button>
                </ng-template>
              </kendo-grid-column>
        </kendo-grid>
    </div>
  
        <button kendoButton (click)="saveShift()"  class="figma-btn-blue figma-popup custom-button">
           Save
        </button>
   
</kendo-dialog>

<app-alert-popup *ngIf="alertData" [Width]="300" [Height]="250" [Data]="alertData" (close)="closeAlertPopup()"></app-alert-popup>

