<div class="col-md-12 colpadding0 " style="margin-top: 10px;">
    <div class="col-md-12 colpadding0">
        <button (click)="CTCReport()" class="exportbtn">
            <img src="../assets/vectors/ExportButton.svg" alt="">
        </button>
        <button (click)="Refresh()" class="figma-btn-blue report-btn">
            Refresh
        </button>
    </div>
    <form [formGroup]="myGroup">
        <table class="table Upload-table table-border">
            <thead>
                <tr>
                    <th>Input</th>
                    <th>Template</th>
                    <th>Upload File</th>
                    <th>Uploaded</th>
                    <th>Validation</th>
                    <th>Processed</th>
                    <th>History</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <div class="col-12 collpadding0">
                            <p style="padding-top: 4px;">{{InputName}}<sup class="text-danger"
                                    *ngIf="IsFileRequired == 1">*</sup></p>
                        </div>
                    </td>
                    <td>
                        <div class="col-12 collpadding0">
                            <img src="../assets/vectors/ExcelDownload.svg" class="svg-icon-btn" (click)="CTCTemplate()" alt="">
                        </div>
                    </td>
                    <td>
                        <div class="col-12 collpadding0" style="justify-content: space-around;">
                            <span *ngIf="!fileNames['CTCTemplate']" style="color: #A0A0A0;">No file chosen</span>
                            <span  *ngIf="fileNames['CTCTemplate']"
                                style="color: #A0A0A0;">{{fileNames['CTCTemplate']}}</span>
                            <input type="file" formControlName="CTCTemplate" #CTCTemplateFile
                                (change)="onFileChange($event, 'CTCTemplate')" id="fileInput" style="display: none;" />
                            <button class="figma-btn-gray" (click)="CTCTemplateFile.click()">Browse</button>

                            <button class="figma-btn-blue" style="color: white;"
                                (click)="uploadFiles()">Upload</button>
                        </div>
                    </td>
                    <td>
                        <!-- <img src="../assets/vectors/sandclock.svg" *ngIf="DisplayUploadFlag == 1 && IsUploaded == -1" alt=""> -->
                        <img src="../assets/vectors/GreenTick.svg" class="greentick" *ngIf="DisplayUploadFlag == 1 && IsUploaded == 1" alt="">
                        <img src="../assets/vectors/DownloadOrange.svg" class="svg-icon-btn" (click)="downlaodError()" *ngIf="DisplayUploadFlag == 1 && IsUploaded == -2" alt="">
                    </td>
                    <td>
                        <img src="../assets/vectors/sandclock.svg"  class="svg-icon-btn" *ngIf="DisplayUploadFlag == 1 && IsValidated == -1" alt="">
                        <img src="../assets/vectors/GreenTick.svg" class="greentick"  *ngIf="DisplayUploadFlag == 1 && IsValidated == 1" alt="">
                        <img src="../assets/vectors/DownloadOrange.svg" class="svg-icon-btn" (click)="downlaodError()" *ngIf="DisplayUploadFlag == 1 && IsValidated == -2" alt="">
                    </td>
                    <td>
                        <div class="col-12 collpadding0">
                            <img src="../assets/vectors/sandclock.svg"  class="svg-icon-btn" *ngIf="DisplayUploadFlag == 1 && isProcessed == -1" alt="">
                            <img src="../assets/vectors/GreenTick.svg" class="greentick"  *ngIf="DisplayUploadFlag == 1 && isProcessed == 1" alt="">
                            <img src="../assets/vectors/DownloadOrange.svg" class="svg-icon-btn" (click)="downlaodError()" *ngIf="DisplayUploadFlag == 1 && isProcessed == -2" alt="">
                        </div>
                    </td>
                    <td>
                        <img (click)="openHistoryPopup()" src="../assets/vectors/History.svg" class="svg-icon-btn" alt="">
                    </td>
                </tr>
            </tbody>
        </table>
    </form>
</div>


<app-employee-ctchistory *ngIf="EmployeeHistoryPopup" (close)="closeHistoryPopup()"></app-employee-ctchistory>

<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270"  [Data]="alertData"
    (close)="closeAlert()"></app-alert-popup>