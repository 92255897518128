import { CommonModule, NgClass, NgIf, NgStyle } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NoticesServiceService } from '../../../service/notices-service.service';
import { UserService } from '../../../service/user.service';
import { GridModule } from '@progress/kendo-angular-grid';
import { KendoDatepickerComponent } from '../../../components/kendo-datepicker/kendo-datepicker.component';
import { PageChangeEvent } from '@progress/kendo-angular-treelist';
import { environment } from '../../../environments/environment';
import { BlobService } from '../../../service/blob.service';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { FormsModule } from '@angular/forms';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';

@Component({
  selector: 'app-document-section',
  standalone: true,
  imports: [NgIf, GridModule, KendoDatepickerComponent, GridModule, IndicatorsModule, AlertPopupComponent, NgClass, FormsModule, DropDownListModule, DropdownListComponent,NgStyle,CommonModule],
  templateUrl: './document-section.component.html',
  styleUrl: './document-section.component.css'
})
export class DocumentSectionComponent implements OnInit {
  @Input() Data!: any;
  @Output() close = new EventEmitter<void>();
  ModeData!: any;
  Role!: string;
  showValidationErrors = false;
  public frequencyOptions: string[] = ['Yes', 'No'];
  showRepository: boolean = false;
  showRequirements: boolean = false;
  public searchQuery: string = '';
  public editedRowIndex: number | null = null;
  public editedColumn: string | null = null;
  public gridData: any = { data: [], total: 0 };
  fileNames: { [key: string]: string } = {};
  poTo: Date | null = null;
  poFrom: Date | null = null;
  poDate: Date | null = null;
  public pageSize = 10;
  public skip = 0;
  public formattedDate!: string;
  public updatedInstances: { instanceId: string; activationDate: string }[] = [];
  loading = false;
  public alertData = { alertMessage: '', alertVisible: '' };
  POType = { text: '', value: '' };
  constructor(private DocumentSectionService: NoticesServiceService, private user: UserService, private blobService: BlobService,private cdr: ChangeDetectorRef) { }
  ngOnInit(): void {
    this.Role = this.user.getRole()
    // this.GetNoticeData('ABCD');
    // this.loadGrid(10, 'ABCD');
    console.log('perdata', this.Data);

    if (this.Data) {
      this.GetNoticeData(this.Data.noticeReferenceNo);
    }
  }
  logDataItem(dataItem: any): void {
    console.log('dataItem:', dataItem);
  }

  GetfilePath(Act: string, DocumentName: string, NoticeTypeId: number) {
    debugger;
    this.DocumentSectionService.GetfilePath(Act, DocumentName, NoticeTypeId).subscribe({
      next: (response) => {
        const data = response.body;
        console.log('file path', data);


      }
    });
  }
  loadGrid(noticeTypeId: number, noticeReferenceNo: string) {
    debugger;
    this.DocumentSectionService.NoticeDocumentsDetails(noticeTypeId, noticeReferenceNo).subscribe({
      next: (response) => {
        console.log('grid res', response);
        const data = response.body;

        const filteredData = this.applyActivationSearchFilter(data);
        console.log('filtered data',filteredData);
        
        filteredData.forEach(item => {
          this.DocumentSectionService.GetfilePath(item.act, item.documentList, noticeTypeId).subscribe(
            {
              next: (response) => {
                const data = response.body;
                item.downloadPath = data.result;
              }
            });
        })
        filteredData.forEach(item => {
          item.periodFrom = this.formatDatePredefine(item.periodFrom);
          item.periodTo = this.formatDatePredefine(item.periodTo);
          item.expectedDate = this.formatDatePredefine(item.expectedDate);
        });

        this.gridData = {
          data: filteredData,
          total: filteredData.length
          
        };
        this.cdr.detectChanges();
        console.log(filteredData,filteredData.length);
        this.GetfilePath(data[0]?.act, data[0].documentList, this.Data.noticeTypeId)
        console.log('grid', this.gridData);
      }
    });
  }

  formatDatePredefine(dateString: string | null): Date | null {
    if (!dateString) return null;
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? null : date;
  }

  isAvailableChange(value: string, dataItem: any): void {
    dataItem.isAvailable = value === 'Yes' ? true : false;
    if (value == 'Yes') {
      dataItem.isAvailable = 'true';
    } else {
      dataItem.isAvailable = 'false';
    }
  }
  downloadGridFile(dataItem: any) {
    this.loading = true;
    const filePath = dataItem.downloadPath;
    if (filePath) {
      const apiUrl = environment.blobUrl;
      const blobLink = apiUrl + filePath;
      const blobName = this.blobService.extractBlobName(blobLink);
      setTimeout(() => {
        this.blobService.getSasToken(blobName).subscribe(
          (tokenResponse: any) => {
            try {
              const sasToken = tokenResponse.result;
              this.blobService.downloadFile(blobName, sasToken);
              this.loading = false;
            } catch (error) {
              this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
              this.loading = false;
            }
          },
          (error) => {
            this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
            this.loading = false;
          }
        );
      }, 5000);
    } else {
      this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
      this.loading = false;
    }
  }

  onFileChange(event: any, dataItem: any, rowIndex: number): void {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      const fileName = file.name;

      const isZipFile =
        fileType === 'application/zip' ||
        fileType === 'application/x-zip-compressed' ||
        fileType === 'application/x-zip' ||
        fileType === 'application/octet-stream' ||
        fileName.endsWith('.zip') ||
        fileType === 'application/x-rar-compressed' ||
        fileType === 'application/x-rar' ||
        fileName.endsWith('.rar');

      if (isZipFile) {
        dataItem.FileName = fileName;
        dataItem.upload = file;
      } else {
        this.alertData = { alertMessage: 'Please upload a valid ZIP or RAR file.', alertVisible: 'error' };
        event.target.value = '';
      }
    }
  }

  onDateChanges(event: any, dataItem: any, rowIndex: number, type: string): void {
    console.log('dataItem',dataItem);
    
    const formattedDate = this.formatDatePredefine(event);
    if (type === 'periodFrom') {
      dataItem.periodFrom = formattedDate;
    } else if (type === 'periodTo') {
      dataItem.periodTo = formattedDate;
    } else if (type === 'expectedDate') {
      dataItem.expectedDate = formattedDate;
    }
    this.gridData[rowIndex] = dataItem;
  }

  onGridTextChange(event: Event, dataItem: any, type: string, rowIndex: number): void {
    const inputElement = event.target as HTMLInputElement;
    dataItem[type] = inputElement.value;
    this.gridData[rowIndex] = dataItem;
  }

  private applyActivationSearchFilter(data: any[]): any[] {
    if (!this.searchQuery) {
      return data;
    }

    const search = this.searchQuery.toLowerCase();

    return data.filter(item => {
      const complianceMatch = item.shortForm.toLowerCase().includes(search);
      const branchMatch = item.avacoM_BranchName.toLowerCase().includes(search);
      const stateMatch = item.sM_Name.toLowerCase().includes(search);
      return complianceMatch || branchMatch || stateMatch;
    });
  }

  downloadFile(type: string) {
    debugger;
    this.loading = true;
    let filePath = ''
    if (type == 'acknowledgement') {
      filePath = this.ModeData?.acknowledgement;
    }
    else if (type == 'draftExtensionApplication') {
      filePath = this.ModeData?.draftExtensionApplication;
    }
    else if (type == 'noticeDocument') {
      filePath = this.ModeData?.noticeDocument;
    }
    else if (type == 'noticeDraftResponse') {
      filePath = this.ModeData?.noticeDraftResponse;
    }
    else if (type == 'translationDocument') {
      filePath = this.ModeData?.translationDocument;
    }
    else if (type == 'noticeResponseAcknowledgement') {
      filePath = this.ModeData?.noticeResponseAcknowledgement;
    }
    else if (type == 'extensionAcknowledgementDocument') {
      filePath = this.ModeData?.extensionAcknowledgementDocument;
    }
    console.log(filePath);
    
    if (filePath) {
      const apiUrl = environment.blobUrl;
      const blobLink = apiUrl + filePath;
      const blobName = this.blobService.extractBlobName(blobLink);
      setTimeout(() => {
        this.blobService.getSasToken(blobName).subscribe(
          (tokenResponse: any) => {
            debugger;
            try {
              const sasToken = tokenResponse.result;
              this.blobService.downloadFile(blobName, sasToken);
              this.loading = false;
            } catch (error) {
              this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
              this.loading = false;
            }
          },
          (error) => {
            this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
            this.loading = false;
          }
        );
      }, 5000);
    } else {
      this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
      this.loading = false;
    }
  }

  GetNoticeData(Noticenumber: string) {
    this.DocumentSectionService.GetNoticeData(Noticenumber).subscribe(
      {
        next: (response) => {
          const data = response.body;
          console.log(data)
          this.ModeData = data[0];
          this.InitData(data[0]);
          this.loadGrid(data[0]?.noticeTypeId, this.Data?.noticeReferenceNo);
        }, error: (error) => {
          console.error('Error fetching State Dropdown data', error);
        }
      });
  }
  InitData(data: any) {
    console.log('initdata', data);

  }

  validateField(dataItem: any, field: string) {
    this.showValidationErrors = true;
  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
  }
  public parseDate(dateString: string): Date {
    if (!dateString || typeof dateString !== 'string') {
      return new Date();
    }
    const [day, month, year] = dateString.split('-').map(part => parseInt(part, 10));
    return new Date(year, month - 1, day);
  }

  public isCellBeingEdited(rowIndex: number, column: string): boolean {
    return this.editedRowIndex === rowIndex && this.editedColumn === column;
  }
  public editRow(index: number, column: string, dataItem: any): void {
    this.editedRowIndex = index;
    this.editedColumn = column;
  }
  toggle(dropdownType: any) {
    if (dropdownType === 'Repository') {
      this.showRepository = !this.showRepository;
      this.showRequirements = false;
    }
    else if (dropdownType === 'Requirements') {
      this.showRequirements = !this.showRequirements;
      this.showRepository = false;
    }
  }
  public formatDate(date: Date): string {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
  onSave() {
    this.loading = true;
    const noticeDocumentList = Object.values(this.gridData.data).map((item: any) => {
      const clonedItem = { ...item };
      if (clonedItem.periodFrom && clonedItem.periodFrom instanceof Date) {
        clonedItem.periodFrom = clonedItem.periodFrom.toISOString();
      }

      if (clonedItem.periodTo && clonedItem.periodTo instanceof Date) {
        clonedItem.periodTo = clonedItem.periodTo.toISOString();
      }

      if (clonedItem.expectedDate && clonedItem.expectedDate instanceof Date) {
        clonedItem.expectedDate = clonedItem.expectedDate.toISOString();
      }

      clonedItem.noticeType = this.Data.noticeTypeId;
      clonedItem.upload = clonedItem.upload || null;
      return clonedItem;
    });
    this.DocumentSectionService.SaveMasterDocument(noticeDocumentList).subscribe({
      next: (response) => {
        if (response.status == 200) {
          const data = response.body;
          if (data == 1) {
            this.alertData = { alertMessage: 'Notice saved successfully', alertVisible: 'success' };
            this.loading = false;
          } else {
            this.loading = false;
            this.alertData = { alertMessage: 'Notice not saved successfully', alertVisible: 'error' };
          }
        } else {
          this.loading = false;
          this.alertData = { alertMessage: 'Notice not saved successfully', alertVisible: 'error' };
        }
      },
      error: (error) => {
        this.loading = false;
        this.alertData = { alertMessage: 'Notice not saved successfully', alertVisible: 'error' };
        console.log(error);
      }
    });
  }

  closeAlert() {
    this.alertData = { alertMessage: '', alertVisible: '' };
  }

  goBack() {
    // this.router.navigate(['/Notices']);
    this.Data = undefined;
    this.close.emit();
  }
}
