import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { NgFor, NgIf } from '@angular/common';
import { EsicreportService } from '../../../service/esicreport.service';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { EmployeeReportService } from '../../../service/employee-report.service';
import { BlobService } from '../../../service/blob.service';
import { UserService } from '../../../service/user.service';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { MultiSelectTreeComponent } from '../../../components/multi-select-tree/multi-select-tree.component';
import { MultiSelectModule } from "@progress/kendo-angular-dropdowns";
import { TreeViewModule } from "@progress/kendo-angular-treeview";
import { LabelModule } from "@progress/kendo-angular-label";
import { FormsModule } from "@angular/forms";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { load } from '@progress/kendo-angular-intl';

type Branch = {
  id: number;
  name: string;
  parentID: number | null;
  branchType: string;
  branchStatus: string;
  fullName: string | null;
  children?: Branch[];
};

@Component({
  selector: 'app-employee-report',
  standalone: true,
  imports: [
    DropdownListComponent,
    NgFor,
    NgIf,
    InputsModule,
    DropDownsModule,
    IndicatorsModule,
    AlertPopupComponent,
    LabelModule,
    MultiSelectModule,
    MultiSelectTreeComponent,
    TreeViewModule,
    FormsModule
  ],
  templateUrl: './employee-report.component.html',
  styleUrls: ['./employee-report.component.css']
})
export class EmployeeReportComponent implements OnInit {
  @ViewChild('multiselectTree') multiselectTree!: ElementRef;
  commaSeparatedIds: string = '';
  public alertData = { alertMessage: '', alertVisible: '' };
  public defaultFilterValue = { text: 'Select Report', value: '' };
  public dropdownDataMonth: any[] = [];
  public dropdownDataYear: any[] = [];
  public defaultSelectedValueMonth = { value: '', displayvalue: 'Select Month' };
  public defaultSelectedValueYear = { value: '', displayvalue: 'Select Year' };
  selectedMonth: string = '';
  selectedYear: string ='';
  selectedDropdown = '';
  loading = false;
  clientID!: string;
  dropdownData = [
    { text: 'Select Report', value: '' },
    { text: 'Employee Master Report', value: 'Employee Master Report' },
    { text: 'Employee Limited Column Report', value: 'Employee Limited Column Report' },
    { text: 'Employee Monthwise Report', value: 'Employee Monthwise Report' }
  ];
  PreviousInput = false;
  showState = false;
  popupSettings: any = { width: '280px' }; 
  public BranchDropdown: Branch[] = [];

  constructor(
    private esicreportService: EsicreportService,
    private user: UserService,
    private EmployeeReportService: EmployeeReportService,
    private blobService: BlobService
  ) { }

  ngOnInit(): void {
    this.clientID = this.user.getClientId();
    this.getBranchDropdownData();
  }

 
  public tagMapper(tags: any[]): any[] {
    return tags.length < 3 ? tags : [tags];
  }

  OnMultiSelectChange(value: any) {
    if (Array.isArray(value)) {
      this.commaSeparatedIds = value.map(item => item.id).join(',');
    } else {
      console.error('Expected an array of selected items.');
    }
  }

  getBranchDropdownData() {
    this.EmployeeReportService.getBranchDropdown(this.clientID).subscribe({
        next: (response) => {
            const data = response.body;
            if (response && Array.isArray(data)) {
                this.BranchDropdown = this.buildHierarchy(data);

                const maxLength = this.calculateMaxTextLength(this.BranchDropdown);
                
                const calculatedWidth = Math.max(280, maxLength * 10); 
                this.popupSettings = { width: `${calculatedWidth}px` };
            } else {
                console.error('Unexpected data format', response);
            }
        },
        error: (error) => {
            console.error('Error fetching Branch Dropdown data', error);
        }
    });
}
calculateMaxTextLength(data: Branch[]): number {
  let maxLength = 0;

  const findMaxLength = (items: Branch[]) => {
      items.forEach(item => {
          maxLength = Math.max(maxLength, item.name.length); 
          if (item.children && item.children.length) {
              findMaxLength(item.children); 
          }
      });
  };

  findMaxLength(data);
  return maxLength;
}

  buildHierarchy(data: Branch[]): Branch[] {
    const grouped: { [key: number]: Branch[] } = data.reduce((acc, item) => {
      (acc[item.parentID || -1] = acc[item.parentID || -1] || []).push(item);
      return acc;
    }, {} as { [key: number]: Branch[] });

    function buildTree(parentID: number | null): Branch[] {
      return (grouped[parentID || -1] || []).map(item => ({
        ...item,
        children: buildTree(item.id)
      }));
    }

    return buildTree(null);
  }

  onDropdownValueChange(value: any) {
    this.PreviousInput = false;
    if (value.text === "Employee Master Report") {
      this.selectedDropdown = 'MasterReport';
    } else if (value.text === "Employee Limited Column Report") {
      this.selectedDropdown = 'ColumnReport';
    } else if (value.text === "Employee Monthwise Report") {
      this.selectedDropdown = 'MothwiseReport';
      this.loadMonthYearData();
      this.PreviousInput = true;
    }
  }

  onDropdownValueChangeMonth(value: any) {
    this.selectedMonth = value.value;
  }

  onDropdownValueChangeYear(value: any) {
    this.selectedYear = value.value;
  }

  loadMonthYearData() {
    this.esicreportService.getMonth().subscribe({
      next: (res: any) => {
        const data = res.body;
        if (data) {
          this.dropdownDataMonth = [this.defaultSelectedValueMonth, ...data];
        }
      },
      error: (error) => {
        console.error('Error fetching Month Dropdown data', error);
      }
    });
    
    this.esicreportService.getYear().subscribe({
      next: (res: any) => {
        const data = res.body;
        if (data) {
          this.dropdownDataYear = [this.defaultSelectedValueYear, ...data];
        }
      },
      error: (error) => {
        console.error('Error fetching Year Dropdown data', error);
      }
    });
  }

  exportToExcel(): void {
    const clientId = this.clientID;
    this.loading = true;
    if (this.selectedDropdown === 'MasterReport') {
      this.EmployeeReportService.getMasterReportExcel(clientId).subscribe(
        {
          next: (response) => {
            const data = response.body;
            const blobLink = data.filePath;
            const blobName = this.blobService.extractBlobName(blobLink);
            setTimeout(() => {
              this.blobService.getSasToken(blobName).subscribe(
                (tokenResponse: any) => {
                  try {
                    const sasToken = tokenResponse.result;
                    this.blobService.downloadFile(blobName, sasToken);
                    this.loading = false;
                  } catch (error) {
                    this.alertData={alertMessage:'File Not Found',alertVisible:'error'};
                    this.loading = false;
                  }
                },
                (error) => {
                  this.alertData={alertMessage:'File Not Found',alertVisible:'error'};
                  this.loading = false; 
                }
              );
            }, 5000);
          }, error: (error) => {
            this.alertData = { alertMessage: error.error, alertVisible: 'error' }
            this.loading = false;
          }
        });
    } else if (this.selectedDropdown === 'ColumnReport') {
      this.EmployeeReportService.getLimitedColumnExcel(clientId).subscribe(
        {
          next: (response) => {
            const data = response.body;
            const blobLink = data.filePath;
            const blobName = this.blobService.extractBlobName(blobLink);
            setTimeout(() => {
              this.blobService.getSasToken(blobName).subscribe(
                (tokenResponse: any) => {
                  try {
                    const sasToken = tokenResponse.result;
                    this.blobService.downloadFile(blobName, sasToken);
                    this.loading = false;
                  } catch (error) {
                    this.alertData={alertMessage:'File Not Found',alertVisible:'error'};
                    this.loading = false;
                  }
                },
                (error) => {
                  this.alertData={alertMessage:'File Not Found',alertVisible:'error'};
                  this.loading = false; 
                }
              );
            }, 5000);
          }, error: (error) => {
            this.alertData = { alertMessage: error.error, alertVisible: 'error' }
            this.loading = false;
          }
        });
    } else if (this.selectedDropdown === 'MothwiseReport') {
      
      if (this.selectedMonth && this.selectedYear && this.commaSeparatedIds) {
        const month=  parseInt(this.selectedMonth, 10);
        const year=  parseInt(this.selectedYear, 10);
        this.EmployeeReportService.getMonthWiseExcel(clientId, month, year, this.commaSeparatedIds).subscribe(
          {
            next: (response) => {
              const data = response.body;
              const blobLink = data.filePath;
              const blobName = this.blobService.extractBlobName(blobLink);
              setTimeout(() => {
                this.blobService.getSasToken(blobName).subscribe(
                  (tokenResponse: any) => {
                    try {
                      const sasToken = tokenResponse.result;
                      this.blobService.downloadFile(blobName, sasToken);
                      this.loading = false;
                    } catch (error) {
                      this.alertData={alertMessage:'File Not Found',alertVisible:'error'};
                      this.loading = false;
                    }
                  },
                  (error) => {
                    this.alertData={alertMessage:'File Not Found',alertVisible:'error'};
                    this.loading = false; 
                  }
                );
              }, 5000);
            }, error: (error) => {
              this.alertData = { alertMessage: error.error, alertVisible: 'error' }
              this.loading = false;
            }
          });
      } else {
        this.alertData = { alertMessage: 'Please Select all Fields', alertVisible: 'error' }
      }
    }
    else {
      this.alertData = { alertMessage: 'Please Select Employee Report type', alertVisible: 'error' };
      this.loading = false;
    }
  }

  public closeAlert(): void {
    this.loading = false;
    this.alertData = { alertMessage: '', alertVisible: '' };
  }
}
