import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnInit, Output, Renderer2 } from '@angular/core';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { MasterScopingSetupService, ScopingObjModel } from '../../../service/master-scoping-setup.service';
import { DropDownListModule, DropDownsModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { LabelModule } from '@progress/kendo-angular-label';
import { GridModule } from '@progress/kendo-angular-grid';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { FormsModule } from '@angular/forms';
import { MasterScopingMapFrequencyComponent } from '../master-scoping-map-frequency/master-scoping-map-frequency.component';
import { NgIf } from '@angular/common';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { UserService } from '../../../service/user.service';

@Component({
  selector: 'app-master-scope-edit-popup',
  standalone: true,
  imports: [DialogModule, DropDownListModule, MultiSelectModule, TreeViewModule, LabelModule, DropDownsModule, GridModule, DropdownListComponent, FormsModule, MasterScopingMapFrequencyComponent, NgIf, AlertPopupComponent],
  templateUrl: './master-scope-edit-popup.component.html',
  styleUrls: ['./master-scope-edit-popup.component.css']
})

export class MasterScopeEditPopupComponent implements OnInit {
  @Output() close = new EventEmitter<void>();
  @Input() selectedEntity!: { key: any, value: any };
  selectedStates: any[] = [];
  StateDropdown: any[] = [];
  editDataArray: any[] = [];
  gridData: any[] = []
  selectedEntities: any[] = [];
  selectedActivities: any
  selectedNature: any[] = [];
  selectedActs: any[] = [];
  services: any[] = []
  mandate: any[] = []
  openFrequency = false
  selectedService: { text: any, value: any } = { text: 'Select Service Type', value: null }
  selectedMandate: { text: any, value: any } = { text: 'Select Mandate', value: null }
  ActivityDropdown: any[] = []
  NatureDropdown: any[] = []
  ActDropdown: any[] = []
  commaSeparatedEntities: any = ''
  commaSeparatedStates: any = ''
  commaSeparatedActivity: string = ''
  commaSeparatedNature: string = ''
  commaSeparatedActs: string = ''
  popupType: string = ''
  pageSize = 10;
  newRow: any
  selectedRowIndex: any
  skip = 0;
  public alertData = { alertMessage: '', alertVisible: '' };
  public activityFrequencyMapping: any[] = [];
  popupSettings: any = { width: '160px' };
  NaturepopupSettings: any = { width: '160px' };
  StatepopupSettings: any = { width: '160px' };
  isEditingNewRow: boolean = false;
  currentRowIndex: number | null = null;
  constructor(private masterScopingService: MasterScopingSetupService, private cdr: ChangeDetectorRef, private renderer: Renderer2, private zone: NgZone,private user: UserService) { }

  ngOnInit(): void {
    debugger;
    this.getEditData();
  }

  pageChange(event: any): void {
    console.log('event', event);
    this.skip = event.skip;
  }

  generateRandomString(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomString = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters.charAt(randomIndex);
    }
    return randomString;
  }

  addNewRow() {
    const newId = this.generateRandomString(10);

    let newRow = {
      id: newId,
      entityid: this.editDataArray.length > 0 ? this.editDataArray[0].selectedEntity : null,
      service: 'temp_' + newId,
      mandate: 'One Time',
      selectedActivities: [],
      commaSeparatedActivity: '',
      isNew: true
    };
    console.log(newRow);
    
    // this.services = this.services.filter(service => 
    //   !this.editDataArray.some(editData => editData.service === service.value)
    // );
    this.services = this.services.filter(activity => 
      !this.editDataArray.some((editData: any) => editData.service === activity.value)
    );
  
    // Set selectedService if there are available services left
    if (this.services.length > 0) {
      this.selectedService = { text: this.services[0].text, value: this.services[0].value };
      // newRow.service=this.services[0].value 
    } else {
      // Handle case when no services are available
      // this.selectedService = null;
    }
    console.log('service dropdown', this.services);
    console.log('edit dropdown', this.editDataArray);
    
    this.editDataArray = [...this.editDataArray, newRow];
    this.isEditingNewRow = true;
    this.currentRowIndex = this.editDataArray.length - 1;
    this.cdr.detectChanges();
    this.focusOnNewRow(this.currentRowIndex);
  }

  focusOnNewRow(rowIndex: number) {
    setTimeout(() => {
      const rowElement = document.querySelectorAll('.k-grid tr')[rowIndex + 1];
      const inputElement = rowElement.querySelector('input');

      if (inputElement) {
        this.renderer.selectRootElement(inputElement).focus();
      }
    }, 100);
  }

  getEditData() {
    debugger;
    console.log('Entity Id', this.selectedEntity);
    this.masterScopingService.getEditDetails(this.selectedEntity.value).subscribe({
      next: (response) => {
        debugger;
        console.log('Edit Response', response);
        const data = response.body;

        if (Array.isArray(data) && data.length > 0) {
          this.editDataArray = data;
        console.log('drop',this.ActivityDropdown);
        
          this.editDataArray = this.editDataArray.map(record => ({
            ...record,
            disable: true,
            commaSeparatedActivity: record.lstActivity || '',
            commaSeparatedActs: record.lstActivity || '',
            selectedActivities: record.lstActivity?.split(',').map((item:any) => ({
              id: item.trim(), 
              name: item.trim() 
            }))
          }));
          console.log('Mapped Edit Data Array:', this.editDataArray);
          data.forEach((record, index) => {
            console.log(`Processing record ${index}:`, record);
            this.predefinedData(record, index);
          });
          this.getStates();
        }
      },
      error: (err) => {
        console.error('Error fetching edit data', err);
      }});
  }

  predefinedData(value: any, index: number) {
    debugger
    console.log(`Processing data for record ${index}:`, value);

    this.commaSeparatedActivity = value.lstActivity || '';
    this.commaSeparatedActs = value.lstActs || '';
    this.commaSeparatedStates = value.state || '';
    this.activityFrequencyMapping  = value.activityAndFrequencies || '';
    this.selectedService = { text: value.service || '', value: value.service || '' };
    this.selectedMandate = { text: value.mandate || '', value: value.mandate || '' };
    // this.editDataArray[index] = this.editDataArray.map(item => {
    //   return {
    //     ...item,
    //       disable: true,
         
    //     };
    //   });
    console.log('Selected Service', this.editDataArray);
    this.getService();
    this.initializeMandate();
    this.getStates();
    this.getActivity();
    this.getActs();
    this.getNatureOfAct();
    console.log('Selected Act', this.commaSeparatedActs);
    console.log('Selected States', this.commaSeparatedStates);
  }
  calculateMaxTextLength(data: any[]): number {
    let maxLength = 0;

    const findMaxLength = (items: any[]) => {
      items.forEach(item => {
        maxLength = Math.max(maxLength, item.name.length);
        if (item.children && item.children.length) {
          findMaxLength(item.children);
        }});
    };

    findMaxLength(data);
    return maxLength;
  }

  setPreselectedStates(): void {
    if (this.commaSeparatedStates && this.StateDropdown.length) {
      const stateIds = this.commaSeparatedStates.split(',');
      this.selectedStates = this.StateDropdown.filter(state =>
        stateIds.includes(state.id.toString())
      ).map(state => ({
        id: state.id,
        name: state.name
      }));
      console.log('Preselected states:', this.selectedStates);
    } else {
      console.log('No preselected states available or dropdown not populated.');
    }
  }

  setPreselectedActivity(): void {
    if (!this.isEditingNewRow && this.commaSeparatedActivity && this.ActivityDropdown.length) {
      const activityIds = this.commaSeparatedActivity.split(',');
      this.selectedActivities = this.ActivityDropdown.filter(activity =>
        activityIds.includes(activity.id.toString())
      ).map(activity => ({
        id: activity.id,
        name: activity.name
      }));
      console.log('Preselected activities:', this.selectedActivities);
    } else {
      console.log('No preselected activities available or dropdown not populated, or editing a new row.');
    }
  }
  setPreselectedActs(): void {
    if (!this.isEditingNewRow && this.commaSeparatedActs && this.ActDropdown.length) {
      const actIds = this.commaSeparatedActs.split(',');
      console.log('Acts',actIds);
      
      this.selectedActs = this.ActDropdown.filter(act =>
        actIds.includes(act.id.toString())
      ).map(act => ({
        id: act.id,
        name: act.name
      }));      
      console.log('Preselected acts:', this.selectedActs);
    } else {
      console.log('No preselected activities available or dropdown not populated.');
    }
  }

  getStates() {
    this.masterScopingService.getStatesDropdown().subscribe({
      next: (response) => {
        console.log('States response', response);
        const data = response.body;
        this.StateDropdown = this.buildHierarchy(
          data.map((item: any) => ({
            id: item.key,
            name: item.value,
            children: item.children
          }))
        );

        const maxLength = this.calculateMaxTextLength(this.StateDropdown);
        const calculatedWidth = Math.max(280, maxLength * 10);
        this.StatepopupSettings = { width: `${calculatedWidth}px` };

        this.setPreselectedStates();
      },
      error: (err) => {
        console.error('Error fetching states', err);
      }});
      }

  buildHierarchy(data: any[]): any[] {
    return data.map(item => ({
      id: item.id,
      name: item.name,
      items: item.children || []
    }));
  }

  OnMultiSelectStateChange(event: any[]) {
    console.log('Selected states:', this.selectedStates);
    this.selectedStates = event.map(item => ({
      id: item.id,
      name: item.name
    }));
    this.commaSeparatedStates = this.selectedStates.map(item => item.id).join(',');

    console.log(this.commaSeparatedStates);
  }

  getService() {
    this.masterScopingService.getServiceDropdown().subscribe({
      next: (response) => {
        console.log('Service Response', response);
        const data = response.body;
        this.services = data.map((item: any) => ({
          text: item.key,
          value: item.value
        }))}
    })
  }

  onServiceChange(value: any, rowIndex: number) {
    if (rowIndex >= 0 && rowIndex < this.editDataArray.length) {

      this.editDataArray[rowIndex] = {
        ...this.editDataArray[rowIndex],
        service: value.value
      };
    }
    console.log('Updated Row:', this.editDataArray[rowIndex]);
  }

  initializeMandate() {
    this.mandate = [
      { text: 'Select Mandate', value: null },
      { text: "One Time", value: "One Time" },
      { text: "Recurring", value: "Recurring" },
      { text: "Both", value: "Both" }
    ];
  }

  onMandateChange(value: any, rowIndex: number) {
    if (rowIndex >= 0 && rowIndex < this.editDataArray.length) {

      this.editDataArray[rowIndex] = {
        ...this.editDataArray[rowIndex],
        mandate: value.value
      };
    }
    console.log('Updated Row:', this.editDataArray[rowIndex]);
  }

  getActivity() {
    const service = this.selectedService.value;
    this.masterScopingService.getActivityDropdown(service).subscribe({
      next: (response) => {
        console.log('Activity response', response);
        const data = response.body;

        this.ActivityDropdown = this.buildHierarchy(
          data.map((item: any) => ({
            id: item.key,
            name: item.value,
            children: item.children
          })));
        this.setPreselectedActivity();
      }
    });
  }

  OnActivitySelectChange(event: any, rowIndex: number) {
    debugger
    if (rowIndex >= 0 && rowIndex < this.editDataArray.length) {
      const selectedActivities = event.map((item: any) => ({
        id: item.id,
        name: item.name
      }));

      const isNewRow = this.editDataArray[rowIndex].isNew || false;

      if (isNewRow || selectedActivities.length > 0) {
        this.editDataArray[rowIndex] = {
          ...this.editDataArray[rowIndex],
          selectedActivities: [...selectedActivities],
          commaSeparatedActivity: selectedActivities.map((item: any) => item.name).join(',')
        };
        console.log(`Updated row ${rowIndex}:`, this.editDataArray[rowIndex]);
      }}
  }

  getNatureOfAct() {
    console.log('activities', this.commaSeparatedActivity);
    debugger;
    const activities = this.commaSeparatedActivity
    this.masterScopingService.getNatoreOfActDropdown(activities).subscribe({
      next: (response) => {
        console.log('Nature  response', response);
        const data = response.body
        this.NatureDropdown = this.buildHierarchy(
          data.map((item: any) => ({
            id: item.key,
            name: item.value
          }))
        );
        let maxLength = 0;
        this.NatureDropdown.forEach((item: any) => {
          maxLength = Math?.max(maxLength, item.name.length || 0);
        });
        const calculatedWidth = Math?.max(280, maxLength * 7.5);
        this.NaturepopupSettings = { width: `${calculatedWidth}px` };
        console.log("Nature Dropdownlist", this.NatureDropdown)
      }
    })
    this.cdr.detectChanges();
  }

  OnNatureSelectChange(event: any, rowIndex: number) {
    if (rowIndex >= 0 && rowIndex < this.editDataArray.length) {
      const selectedNature = event.map((item: any) => ({
        id: item.id,
        name: item.name
      }));

      const isNewRow = this.editDataArray[rowIndex].isNew || false;

      if (isNewRow || selectedNature.length > 0) {
        this.editDataArray[rowIndex] = {
          ...this.editDataArray[rowIndex],
          selectedNature: [...selectedNature],
          commaSeparatedNature: selectedNature.map((item: any) => item.name).join(',')
        };
        this.getActs()
        console.log(`Updated row ${rowIndex}:`, this.editDataArray[rowIndex]);
      }}
  }

  getActs() {
    const activity = this.commaSeparatedActivity;
    const natureOfAct = this.commaSeparatedNature;
    const states = this.commaSeparatedStates;
    this.masterScopingService.getActDropdown(activity, natureOfAct||'', states, 'yes').subscribe({
      next: (response) => {
        console.log('Act response', response);
        const data = response.body;
        this.ActDropdown = this.buildHierarchy(
          data.map((item: any) => ({
            id: item.actId,
            name: item.actName,
            children: item.children
          }))
        );
        this.setPreselectedActs()
        const maxLength = this.calculateMaxTextLength(this.ActDropdown);
    
        const calculatedWidth = Math.max(160, maxLength * 6.5);
        this.popupSettings = { width: `${calculatedWidth}px` };
      }
    })
  
  }

  OnActSelectChange(event: any,rowIndex: number) {
    if (rowIndex >= 0 && rowIndex < this.editDataArray.length) {
      const selectedActs = event.map((item: any) => ({
        id: item.id,
        name: item.name
      }));


      const isNewRow = this.editDataArray[rowIndex].isNew || false;

      if (isNewRow || selectedActs.length > 0) {
        this.editDataArray[rowIndex] = {
          ...this.editDataArray[rowIndex],
          selectedActs: [...selectedActs],
          commaSeparatedActs: selectedActs.map((item: any) => item.name).join(',')
        };
        // this.getActs()
        console.log(`Updated row ${rowIndex}:`, this.editDataArray[rowIndex]);
      }}
    console.log(this.commaSeparatedActs);
  }
  public tagMapper(tags: any[]): any[] {
    return tags.length < 2 ? tags : [tags];
  }
  public closePopup(): void {
    if(this.alertData.alertVisible=='error'){
      this.alertData = { alertMessage: '', alertVisible: '' };
      return;
    }
    this.close.emit();
  }



  updateScope(dataItem:any) {
    console.log('data item',dataItem);
    debugger
    const custId = this.user.getCustomerId()
    // const custId = localStorage.getItem('CustomerId')
    const isNewRow =  dataItem.isNew
    const scopingObj: ScopingObjModel = {
      CustomerId: custId.toString(),
      EntityId: this.selectedEntity.key,
      State: this.commaSeparatedStates,
      // Service:isNewRow ?dataItem.service: this.selectedService.value,
      // Mandate: isNewRow ?dataItem.mandate:this.selectedMandate.value,
      // LstActivity: isNewRow ?dataItem.commaSeparatedActivity:this.commaSeparatedActivity,
      // LstActs:isNewRow ?dataItem.commaSeparatedActs: this.commaSeparatedActs,
      Service:dataItem.service,
      Mandate:dataItem.mandate,
      LstActivity: dataItem.commaSeparatedActivity,
      LstActs:dataItem.commaSeparatedActs,
      activityAndFrequencies: this.activityFrequencyMapping
    };
    console.log('Save Response', scopingObj);
    if(scopingObj.activityAndFrequencies){

      this.masterScopingService.updateMasterScoping(scopingObj).subscribe({
        next: (response) => {
          console.log('Result', response);
          const data = response.body;
        if (response.status === 200) {
          this.alertData = { alertMessage: 'Scope Updated Successfully', alertVisible: 'success' };

        }
        else {
          this.alertData = { alertMessage: 'Error while updating scope ', alertVisible: 'error' };
        }}})
        
      }else{
        this.alertData = { alertMessage: 'Map Frequency Require ', alertVisible: 'error' };

      }
  }

  mapFrequency(type: string, rowIndex: number) {
    const isNewRow = this.editDataArray[rowIndex]?.isNew;
    console.log('new row', isNewRow);
    this.selectedRowIndex = rowIndex;
    // this.editDataArray[rowIndex].selectedActivities ==this.selectedActivities? this.editDataArray[rowIndex].selectedActivities=this.selectedActivities:this.editDataArray[rowIndex].selectedActivities;
    if (isNewRow) {
      this.popupType = 'Add Popup';
    }
    else {
      this.popupType = type;
    }
    console.log('type', this.popupType);
    this.openFrequency = true;
  }

  closeFrequencyDialog() {
    this.openFrequency = false
    this.activityFrequencyMapping = this.masterScopingService.getActivityFrequencyMapping();
    console.log('Activity Frequency Mapping on init:', this.activityFrequencyMapping);
  }
}
