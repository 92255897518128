import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { DropDownsModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { MultiSelectTreeComponent } from '../../../components/multi-select-tree/multi-select-tree.component';
import { LabelModule } from '@progress/kendo-angular-label';
import { UserBranchMappingService } from '../../../service/user-branch-mapping.service';
import { UserService } from '../../../service/user.service';
import { NgClass, NgIf } from '@angular/common';
import { ManageComplianceUploadPopupComponent } from '../../popups/manage-compliance-upload-popup/manage-compliance-upload-popup.component';
import { DTOReAssignment, InstanceActivationMap, InstanceGridPayload_Dto, ManageComplianceService, MissingFormsDTO, ReassignGrid_Dto, ReassignUserList_Dto, RemoveScheduleDto, UniStringObject } from '../../../service/manage-compliance.service';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { FilterMenuModule, GridModule, PageSizeItem } from '@progress/kendo-angular-grid';
import { PageChangeEvent } from '@progress/kendo-angular-treelist';
import { KendoDatepickerComponent } from '../../../components/kendo-datepicker/kendo-datepicker.component';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { FormsModule } from '@angular/forms';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { MultiCheckFilterComponent } from '../../../components/multicheck-filter/multicheck-filter.component';
import { Observable, of } from 'rxjs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
type Branch = {
  id: number;
  name: string;
  parentID: number | null;
  branchType: string;
  branchStatus: string;
  fullName: string | null;
  children?: Branch[];
};
interface User {
  key: string;
  value: string;
}
interface GridDataItem {
  id: number;
  activationDate?: Date;
}
@Component({
  selector: 'app-manage-compliances',
  standalone: true,
  imports: [DropdownListComponent, MultiSelectModule, NgClass, TreeViewModule, MultiSelectTreeComponent, LabelModule, DropDownsModule, NgIf, ManageComplianceUploadPopupComponent, GridActionItemComponent, GridModule, DatePickerModule, KendoDatepickerComponent, FormsModule, AlertPopupComponent, IndicatorsModule, InputsModule, GridModule, FilterMenuModule, MultiCheckFilterComponent, DialogsModule],
  templateUrl: './manage-compliances.component.html',
  styleUrl: './manage-compliances.component.css'
})
export class ManageCompliancesComponent implements OnInit {
  public gridData: any = { data: [], total: 0 };
  public gridData1: any = { data: [], total: 0 };
  public gridData2: any = { data: [], total: 0 };
  public filter: CompositeFilterDescriptor | null = null;
  selectedFilterItems: any[] = [];
  @Output() filterChanges: EventEmitter<any> = new EventEmitter<any>();
  selectedComplianceReassignment: { text: string, value: string } = { text: 'Activity', value: 'Activity' }
  selectedComplianceType: { text: string, value: string } = { text: 'Compliance Type', value: 'Compliance Type' }
  selectedschedulesComplianceType: { text: string, value: string } = { text: 'Compliance Type', value: 'Compliance Type' }
  selectedExistingUser: { key: any, value: any } = { key: 'User', value: 'User' }
  selectedNewUser: { key: any, value: any } = { key: 'New User', value: 'New User' }
  public BranchDropdown: Branch[] = [];
  commaSeparatedIds: string = '';
  branchId: number = 0
  formSubmitted = false;
  selectedRole: { text: string, value: string } = { text: 'Role', value: 'Role' }
  selectedPeriod: { text: string, value: string } = { text: 'Period', value: 'Period' }
  selectedPeriodCreation: { text: string, value: string } = { text: 'Period', value: 'Period' }
  public editedRowIndex: number | null = null;
  public editedColumn: string | null = null;
  role: any[] = []
  loading = false;
  existingUser: { key: any, value: any }[] = [];
  newUser: { key: any, value: any }[] = [];
  @Output() close = new EventEmitter<void>();
  instanceIds: string = ''
  public roleId: number = 0
  public formattedDate!: string;
  branch: any;
  branch2: any;
  branch3: any;
  branch4: any;
  public buttonCount = 2;
  selectedBranch: any;
  public showValidationErrors = false;
  openUpload: boolean = false
  datePickerValue: Date | null = new Date();
  public sizes: Array<number | any> = [10, 20, 50, 'All'];
  alertData = { alertMessage: '', alertVisible: '' }
  checkedRows: boolean[] = [];
  selectedInstanceIds: string = '';
  scheduleList: string = ''
  complianceIdList: string = ''
  anyChecked: boolean = false;
  scheduleChecked: boolean = false
  public gridVisible = false;
  public updatedInstances: { instanceId: string; activationDate: string }[] = [];
  public searchQuery: string = '';
  reassignmentInstanceIds = ''
  selectedReassignmentIds: string[] = [];
  isSaveButtonVisible = false;
  isAnyCheckboxChecked: boolean = false;
  allSelected: boolean = false;
  selectedItems: any[] = [];
  public originalData: any[] = [];
  showSave = false;
  wholeData: any[] = [];
  popupSettings: any = { width: '280px' };
  selectedKeys: number[] = [];
  selectedNodeName: string | null = 'Entity/Sub-Entity/Location';
  isDropdownOpen = false;

  @ViewChild('searchInput') searchInput!: ElementRef;
  complianceReassignment: { text: string, value: string }[] = [
    { text: 'Activity', value: 'Activity' },

  ]

  complianceType: { text: string, value: string }[] = [
    { text: 'Compliance Type', value: 'Compliance Type' },


  ]


  schedulesComplianceType: { text: string, value: string }[] = [
    { text: 'Compliance Type', value: 'Compliance Type' },
    { text: 'Register', value: 'Register' }


  ]

  periods: { text: string, value: string }[] = [
    { text: 'Period', value: 'Period' },
    { text: 'January', value: '1' },
    { text: 'February', value: '2' },
    { text: 'March', value: '3' },
    { text: 'April', value: '4' },
    { text: 'May', value: '5' },
    { text: 'June', value: '6' },
    { text: 'July', value: '7' },
    { text: 'August', value: '8' },
    { text: 'September', value: '9' },
    { text: 'October', value: '10' },
    { text: 'November', value: '11' },
    { text: 'December', value: '12' },


  ]

  years: Array<{ text: any, value: any }> = [{ text: 'Year', value: 'Year' }];
  selectedYear: { text: any, value: any } = { text: 'Year', value: 'Year' };
  selectedYearCreation: { text: any, value: any } = { text: 'Year', value: 'Year' };

  public gridView: any = { data: [], total: 0 };
  public pageSize = 10;

  public skip = 0;
  public size: any = [{ text: 'All', value: 1 }, 5, 10, 20];
  public size1: any = [{ text: 'All', value: 1 }, 5, 10, 20];
  public size2: any = [{ text: 'All', value: 1 }, 5, 10, 20];

  constructor(private userBranchMappingService: UserBranchMappingService, private user: UserService
    , private manageComplianceService: ManageComplianceService, private cdr: ChangeDetectorRef, private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    this.selectedComplianceReassignment.value = 'ComplianceReassignment';
    this.selectedComplianceType.value = 'Compliance Type';
    this.getBranchDropdownData();
    this.generateYearList();
    this.getRoles();
    this.getCompliance();
    this.getComplianceType()
    console.log('userid', this.user.getUserId());
    console.log('roleid', this.user.getRole());

  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;

    if (typeof event.take === 'number' && event.take === 1) {
      if (this.gridData.total !== 0) {
        this.pageSize = this.gridData.total;
      } else if (this.gridData1.total !== 0) {
        this.size = this.gridData1.total;
        this.skip = 0
      } else if (this.gridData2.total !== 0) {
        this.pageSize = this.gridData2.total;
      }
    } else {
      this.pageSize = event.take;
    }

    this.getActivationGridData();
    this.getScheduleRemovalGrid();
    this.getGridData();
  }

  public updateFormattedDate(): void {
    if (this.datePickerValue) {
      this.formattedDate = this.formatDate(this.datePickerValue);
    }
  }

  public onDateChange(event: Date, dataItem: any, rowIndex: number): void {
    const formattedDate = this.formatDate(event);
    dataItem.activationDate = formattedDate;
    const selectedId = dataItem.complianceInstanceID;
    const existingInstanceIndex = this.updatedInstances.findIndex(
      instance => instance.instanceId === selectedId.toString()
    );
    if (existingInstanceIndex !== -1) {
      this.updatedInstances[existingInstanceIndex].activationDate = formattedDate;
    } else {
      this.updatedInstances.push({
        instanceId: selectedId.toString(),
        activationDate: formattedDate,
      });
    }
    this.updateSaveButtonVisibility();
  }



  getCompliance() {
    this.manageComplianceService.getCompliance('ComplianceActivity').subscribe({
      next: (response) => {
        const data = response.body
        this.complianceReassignment = data.map((item: any) => ({
          value: item.value,
          text: item.text
        }));
      }
    })
  }

  getComplianceType() {
    this.manageComplianceService.getCompliance('ComplianceName').subscribe({
      next: (response) => {
        const data = response.body
        // this.complianceType = data.map((item: any) => ({
        //   value: item.value,
        //   text: item.text
        // }));
        this.complianceType = [
          { value: null, text: 'Select Compliance Type' }, // Default option
          ...data.map((item: any) => ({
            value: item.value,
            text: item.text
          }))
        ];
      }
    })
  }

  onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;

    // Check if the clicked target is inside the dropdown container
    const dropdownContainer = this.elementRef.nativeElement.querySelector('.dropdown-container');
    if (dropdownContainer && !dropdownContainer.contains(targetElement)) {
      this.isDropdownOpen = false; // Close dropdown
    }
  }

  public tagMapper(tags: any[]): any[] {
    return tags.length < 3 ? tags : [tags];
  }

  getBranchDropdownData() {
    const entityId = this.user.getClientId();
    this.userBranchMappingService.getBranchDropdown(entityId).subscribe({
      next: (response) => {
        console.log(response);
        const data = response.body;
        if (data && Array.isArray(data)) {
          this.BranchDropdown = this.buildHierarchy(data);
        } else {
          console.error('Unexpected data format', data);
        }

      },
      error: (err) => {
        console.error('Error fetching branch dropdown data', err);
      }
    });
  }
  buildHierarchy(data: Branch[]): Branch[] {
    const grouped: { [key: number]: Branch[] } = data.reduce((acc, item) => {
      (acc[item.parentID || -1] = acc[item.parentID || -1] || []).push(item);
      return acc;
    }, {} as { [key: number]: Branch[] });
    function buildTree(parentID: number | null): Branch[] {
      return (grouped[parentID || -1] || []).map((item) => ({
        ...item,
        children: buildTree(item.id),
      }));
    }
    return buildTree(null);
  }
  onBranchChange(event: any): void {
    console.log('Selection event:', event);

    if (event && event.dataItem) {
      const selectedNode = event.dataItem as Branch;
      console.log('Selected node details:', selectedNode);

      if (selectedNode && this.isLeafNode(selectedNode)) {
        this.selectedBranch = selectedNode;
        this.branchId = selectedNode.id;
        this.selectedNodeName = selectedNode.name;
        this.isDropdownOpen = false;
        console.log('Selected Leaf Node:', this.selectedBranch);
      } else {
        console.warn('Only leaf nodes can be selected.');
      }
    } else {
      console.error('Invalid selection event or no selected item.');
    }
  }




  findBranchById(branches: Branch[], id: number): Branch | undefined {
    for (const branch of branches) {
      if (branch.id === id) {
        return branch;
      }
      if (branch.children) {
        const found = this.findBranchById(branch.children, id);
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  }

  isLeafNode(branch: Branch | undefined): boolean {
    if (!branch) {
      console.warn('Invalid branch passed to isLeafNode');
      return false; // Or true, based on your application's needs
    }
    return !branch.children || branch.children.length === 0;
  }
  hasChildren(item: object): boolean {
    const branchItem = item as Branch;
    return Array.isArray(branchItem.children) && branchItem.children.length > 0;

  }

  fetchChildren(item: object): Observable<Branch[]> {
    const branchItem = item as Branch;
    return of(branchItem.children || []);
  }
  calculateMaxTextLength(data: Branch[]): number {
    let maxLength = 0;
    const findMaxLength = (items: Branch[]) => {
      items.forEach(item => {
        maxLength = Math.max(maxLength, item.name.length);
        if (item.children && item.children.length) {
          findMaxLength(item.children);
        }
      });
    };

    findMaxLength(data);
    return maxLength;
  }

  OnMultiSelectChange(value: any) {
    if (Array.isArray(value)) {
      this.commaSeparatedIds = value.map(item => item.id).join(',');

      console.log(this.commaSeparatedIds);
    } else {
      console.error('Expected an array of selected items.');
    }

  }

  oncomplianceReassignmentChange(value: any) {
    this.gridVisible = false
    this.selectedComplianceReassignment = value;
    console.log('Selected Compliance ', this.selectedComplianceReassignment);
    this.gridData = { data: [], total: 0 };
    this.gridData1 = { data: [], total: 0 };
    this.gridData2 = { data: [], total: 0 };
    this.size = [5, 10, 25, { text: 'All', value: -1 }];
    this.pageSize = 10;
  }

  oncomplianceTypeChange(value: any) {
    this.selectedComplianceType = value;
    console.log('Selected Compliance Type', this.selectedComplianceType);
  }
  onSchedulescomplianceTypeChange(value: any) {
    this.selectedschedulesComplianceType = value;
    console.log('Selected Compliance Type', this.selectedschedulesComplianceType);
  }

  onRoleChange(value: any) {
    this.selectedRole = { text: value.text, value: value.value };
    this.roleId = value.value;
    console.log('Selected role id', this.roleId);

    console.log('Selected Role', this.selectedRole);
    console.log(this.branchId);

    if (this.branchId) {
      this.getExistingUserList();
      this.getNewUserList()

    }
    this.resetUserDropdown();
  }

  onPeriodChange(value: any) {
    if (this.selectedComplianceReassignment.value === 'FormsMappping') {
      this.selectedPeriodCreation = value
      console.log('selectedperiod', this.selectedPeriodCreation);
    }
    else if (this.selectedComplianceReassignment.value === 'ScheduleRemoval') {
      this.selectedPeriod = value
      console.log('selectedperiod', this.selectedPeriod);
    }
  }

  shouldShowDropdowns(): boolean {
    return this.selectedComplianceReassignment.value === 'ComplianceReassignment' || this.selectedComplianceReassignment.value === 'Activity';
  }
  generateYearList() {
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year >= 2017; year--) {
      this.years.push({ text: year.toString(), value: year });
    }
  }

  getRoles() {
    const type = 'UserType'
    this.manageComplianceService.getroles(type).subscribe({
      next: (response) => {
        console.log('roles data', response);
        const data = response.body
        this.role = [
          { value: null, text: 'Select Role' }, // Default option
          ...data.map((item: any) => ({
            value: item.value,
            text: item.text
          }))
        ];
        console.log('Role API response', this.role);

      }
    })
  }

  getExistingUserList() {
    const requestData: ReassignUserList_Dto = {
      CustID: this.user.getCustomerId(),
      EntityId: this.user.getClientId(),
      RoleID: this.roleId,
      Branch: this.branchId,
    };

    this.manageComplianceService.getExistingUserList(requestData).subscribe({
      next: (response) => {
        console.log('Existing User List:', response);
        const data = response.body
        if (data && Array.isArray(data)) {


          this.existingUser = [
            { key: 'Select User', value: null }, // Default option
            ...data.map((user: User) => ({
              key: user.key,
              value: user.value
            }))
          ];

          console.log('Mapped existing users:', this.existingUser);
        } else {
          console.error('Unexpected response format:', response);
          this.existingUser = [];
        }
      },
      error: (err) => {
        console.error('Error fetching user list:', err);
      }
    });
  }

  getNewUserList() {
    const requestData: ReassignUserList_Dto = {
      CustID: this.user.getCustomerId(),
      EntityId: this.user.getClientId(),
      RoleID: this.roleId,
      Branch: this.branchId,
    };

    this.manageComplianceService.getExistingUserList(requestData).subscribe({
      next: (response) => {
        console.log('Existing User List:', response);
        const data = response.body
        if (data && Array.isArray(data)) {


          this.newUser = [
            { key: 'Select New User', value: null }, // Default option
            ...data.map((user: User) => ({
              key: user.key,
              value: user.value
            }))
          ];

          console.log('Mapped existing users:', this.existingUser);
        } else {
          console.error('Unexpected response format:', response);
          this.newUser = [];
        }
      },
      error: (err) => {
        console.error('Error fetching user list:', err);
      }
    });
  }

  getGridData() {
    this.selectedItems = [];
    const reassignGridDto: ReassignGrid_Dto = {
      CustID: this.user.getCustomerId(),
      EntityId: this.user.getClientId(),
      RoleID: this.roleId,
      userID: this.selectedExistingUser.value,
      selectedBranches: this.branchId.toString(),
      DocType: this.selectedComplianceType.value,
      isCLRA: false
    };
    this.loading = true;
    this.manageComplianceService.getReAssignmentGridData(reassignGridDto).subscribe({
      next: (response) => {
        console.log('grid data', response);
        const data = response.body;

        const filteredData = this.applySearchFilter(data);
        console.log('filetered data', filteredData);

        filteredData.forEach((item: any) => {
          item.selected = false;
        });

        this.gridData = {
          data: filteredData.slice(this.skip, this.skip + this.pageSize),
          total: filteredData.length
        };
        this.loading = false;
      }, error: (errror) => {
        this.loading = false;
      }
    });
  }

  onReassignmentCheckboxChange(instanceId: string, event: any): void {
    const isChecked = event.target.checked;
    if (isChecked) {
      this.selectedReassignmentIds.push(instanceId);
    } else {
      this.selectedReassignmentIds = this.selectedReassignmentIds.filter(id => id !== instanceId);
    }
    this.reassignmentInstanceIds = this.selectedReassignmentIds.join(',');
    this.isAnyCheckboxChecked = this.selectedReassignmentIds.length > 0;
    console.log('Reassignment Instance IDs:', this.reassignmentInstanceIds);
  }


  reassignCompliance(): void {
    const reAssignList: DTOReAssignment = {
      roleID: this.roleId,
      userID: this.selectedNewUser.value,
      instanceIDlst: this.reassignmentInstanceIds
    };

    this.manageComplianceService.reassignCompliances(reAssignList).subscribe({
      next: (response) => {
        console.log('Reassign Response', response);

        this.alertData = { alertMessage: 'Compliance Reassigned Successfully', alertVisible: 'success' };

        this.selectedReassignmentIds = [];
        this.reassignmentInstanceIds = '';
        this.isAnyCheckboxChecked = false;
        this.gridData.data.forEach((item: any) => {
          item.selected = false;
        });

        this.cdr.detectChanges();
        this.getGridData();
      },
      error: (err) => {
        console.error('Error during reassignment', err);
      }
    });
  }

  getActivationGridData(): void {
    this.selectedItems = [];
    const instanceGridPayload: InstanceGridPayload_Dto = {
      entityId: this.user.getClientId(),
      flag: 'Instance',
      year: 0,
      branches: this.commaSeparatedIds,
      month: ''
    };
    this.loading = true;
    console.log('Payload', instanceGridPayload);
    this.manageComplianceService.getComplianceManagementData(instanceGridPayload).subscribe({
      next: (response) => {
        console.log('Activation Date Change Grid data', response);
        const data = response.body;
        const filteredData = this.applyActivationSearchFilter(data);
        this.originalData = filteredData;
        filteredData.forEach((item: any) => {
          item.selected = false;
        });
        this.wholeData = filteredData
        console.log('Filtered Data:', filteredData);
        if (this.size === 'All') {
          this.gridData1 = { data: filteredData, total: filteredData.length };
        } else {
          this.gridData1 = {
            data: filteredData.slice(this.skip, this.skip + Number(this.pageSize)),
            total: filteredData.length
          };
        }
        this.loading = false;
      },
      error: (error) => {
        console.error('Error fetching compliance management data:', error);
        this.loading = false;
      }
    });
  }

  onActivationDateCheckboxChange(rowIndex: number): void {
    this.updateSaveButtonVisibility();
  }
  updateSaveButtonVisibility(): void {
    this.isSaveButtonVisible = this.checkedRows.includes(true);
  }

  saveUpdatedDate(): void {
    const instanceActivation: InstanceActivationMap[] = this.updatedInstances.map(item => ({
      InstanceID: item.instanceId,
      ActivationDate: item.activationDate
    }));
    console.log('Instance Activation', instanceActivation);
    this.manageComplianceService.updateActivationDate(instanceActivation).subscribe({
      next: (response) => {

        if (response.status === 200) {
          console.log('Update Activation Record', response);
          this.alertData = { alertMessage: 'Activation Date Updated', alertVisible: 'success' };
          this.checkedRows = this.gridData1.data.map(() => false);
          this.isSaveButtonVisible = false;
          this.getActivationGridData();
        }
        else {
          this.alertData = { alertMessage: 'Error Updating Date', alertVisible: 'error' };
        }
      },
      error: (error) => {
        this.alertData = { alertMessage: 'Error Updating Date', alertVisible: 'error' };
      }
    })
    this.getActivationGridData();
  }

  onCheckboxChange(dataItem: any, rowIndex: number): void {
    console.log('dataItem', dataItem);
    dataItem.isChecked = !dataItem.isChecked;

    const selectedId = dataItem.complianceInstanceID;
    let instanceIdArray: string[] = this.selectedInstanceIds ? this.selectedInstanceIds.split(',') : [];

    if (this.checkedRows[rowIndex]) {
      instanceIdArray = instanceIdArray.filter(id => id !== selectedId.toString());
      this.checkedRows[rowIndex] = false;
    } else {
      instanceIdArray.push(selectedId.toString());
      this.checkedRows[rowIndex] = true;
    }

    this.selectedInstanceIds = instanceIdArray.join(',');
    this.anyChecked = this.checkedRows.some(isChecked => isChecked);
    console.log(`Instance IDs: ${this.selectedInstanceIds}`);
  }

  removeAssignment() {
    const stringList: UniStringObject = {
      StringLIst: this.selectedInstanceIds
    }

    this.manageComplianceService.removeAssignment(stringList).subscribe({
      next: (response) => {
        if (response.status === 200) {
          console.log('Assignment Removal', response);
          this.gridData1.data.forEach((item: any, index: number) => {
            item.selected = false;
            this.checkedRows[index] = false;
          });

          this.selectedInstanceIds = '';
          this.anyChecked = false;
          this.alertData = { alertMessage: 'Assignment Removed Successfully', alertVisible: 'success' };
          this.getActivationGridData();
        }
        else {
          this.alertData = { alertMessage: 'Error Removing Assignment', alertVisible: 'error' };
        }
      },
      error: (error) => {
        this.alertData = { alertMessage: 'Error Removing Assignment', alertVisible: 'error' };
      }
    })
  }

  private applyActivationSearchFilter(data: any[]): any[] {
    if (!this.searchQuery) {
      return data;
    }
    const search = this.searchQuery.toLowerCase();
    const complianceIdFilteredData = data.filter(item =>
      item.complianceId && item.complianceId.toString().toLowerCase().startsWith(search)
    );

    if (complianceIdFilteredData.length > 0) {
      return complianceIdFilteredData.sort((a, b) => {
        const aComplianceId = Number(a.complianceId) || 0;
        const bComplianceId = Number(b.complianceId) || 0;
        return aComplianceId - bComplianceId;

      });
    }
    const otherFilteredData = data.filter(item => {
      const shortFormMatch = item.shortForm && item.shortForm.toLowerCase().includes(search);
      const branchNameMatch = item.avacoM_BranchName && item.avacoM_BranchName.toLowerCase().includes(search);
      const stateMatch = item.sM_Name && item.sM_Name.toLowerCase().includes(search);
      const locationMatch = item.cM_City && item.cM_City.toLowerCase().includes(search);
      return shortFormMatch || branchNameMatch || stateMatch || locationMatch;
    });
    const sortedOtherData = otherFilteredData.sort((a, b) => {
      const aComplianceId = Number(a.complianceId) || 0;
      const bComplianceId = Number(b.complianceId) || 0;
      return aComplianceId - bComplianceId;
    });
    return sortedOtherData;
  }


  private applySearchFilter(data: any[]): any[] {
    if (!this.searchQuery) {
      return data;
    }

    const search = this.searchQuery.toLowerCase();

    return data.filter(item => {
      const complianceMatch = item.shortForm.toLowerCase().includes(search);
      return complianceMatch;
    });
  }
  private applyScheduleSearchFilter(data: any[]): any[] {
    if (!this.searchQuery) {
      return data;
    }

    const search = this.searchQuery.toLowerCase();

    return data.filter(item => {
      const complianceIdMatch = item.complianceId && item.complianceId.toString().toLowerCase().startsWith(search);
      const scheduleIdMatch = item.scheduleid && item.scheduleid.toString().toLowerCase().startsWith(search);
      const complianceMatch = item.shortForm.toLowerCase().includes(search);
      const stateMatch = item.sM_Name.toLowerCase().includes(search);
      const locationMatch = item.cM_City && item.cM_City.toString().toLowerCase().startsWith(search);
      const branchMatch = item.avacoM_BranchName.toLowerCase().includes(search);
      const statusMatch = item.complianceStatus.toLowerCase().includes(search);
      return complianceIdMatch || scheduleIdMatch || complianceMatch || branchMatch || stateMatch || locationMatch || statusMatch;
    });
  }
  resetUserDropdown() {
    this.selectedExistingUser = { key: 'User', value: 'User' };
    this.getExistingUserList();
  }

  resetNewUserDropdown() {
    this.selectedNewUser = { key: 'New User', value: 'New User' };
    this.getNewUserList();
  }

  onYearChange(value: any) {

    if (this.selectedComplianceReassignment.value === 'FormsMappping') {
      this.selectedYearCreation = value;
    }
    else if (this.selectedComplianceReassignment.value === 'ScheduleRemoval') {
      this.selectedYear = value;
    }

  }
  validateForm(): boolean {
    const isValid = !!(
      this.selectedComplianceType
    );

    this.showValidationErrors = !isValid;
    return isValid;
  }
  onUserChange(value: User) {
    this.selectedExistingUser = { key: value.key, value: value.value }
  }

  onNewUserChange(value: User) {
    this.selectedNewUser = { key: value.key, value: value.value }
  }

  filterChange(event: any) {
    this.searchQuery = event.target.value;
    console.log('Search Query:', this.searchQuery);
    this.getGridData();
  }

  activationDatefilterChange(event: any) {
    this.searchQuery = event.target.value;
    console.log('Search Query:', this.searchQuery);
    this.getActivationGridData()
  }

  scheduleFilterChange(event: any) {
    this.searchQuery = event.target.value;
    console.log('Search Query:', this.searchQuery);
    this.getScheduleRemovalGrid()
  }



  applyFilter() {
    this.gridVisible = false;
    this.gridData1 = []
    this.cdr.detectChanges();
    if (this.selectedComplianceReassignment.value === 'ComplianceReassignment') {
      this.getGridData();
    }
    else if (this.selectedComplianceReassignment.value === 'ActivationDateChange') {
      this.getActivationGridData();
      this.updateFormattedDate();
    }
    else if (this.selectedComplianceReassignment.value === 'AssignmentRemoval') {
      this.getActivationGridData();
    }
    else if (this.selectedComplianceReassignment.value === 'ScheduleRemoval') {
      this.getScheduleRemovalGrid();
    }
    this.gridVisible = true;
  }

  resetCheckboxes(): void {
    this.checkedRows = this.checkedRows.map(() => false);
    this.selectedInstanceIds = '';
    this.anyChecked = false;
  }


  scheduleCreation() {
    this.formSubmitted = true;
    if (!this.selectedschedulesComplianceType ||
      !this.selectedPeriodCreation ||
      !this.selectedYearCreation) {
      this.alertData = {
        alertMessage: 'Please select Compliance Type, Period, and Year before submitting.',
        alertVisible: 'error'
      };
      return;
    }

    const missingForms: MissingFormsDTO = {
      ComplianceType: this.selectedschedulesComplianceType.value,
      Month: this.selectedPeriod.value.toString(),
      Year: this.selectedYear.value.toString(),
      EntityID: this.user.getClientId()
    };

    this.loading = true;
    console.log('Missing Forms', missingForms);

    this.manageComplianceService.CreateMissingForms(missingForms).subscribe({
      next: (response) => {
        console.log('Schedule Creation response', response);
        if (response.status === 200) {
          this.alertData = { alertMessage: 'Schedule created successfully.', alertVisible: 'success' };
        } else {
          this.alertData = { alertMessage: 'Error', alertVisible: 'error' };
        }
        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this.alertData = { alertMessage: 'Error', alertVisible: 'error' };
      }
    });
  }

  upload() {
    this.openUpload = true
  }
  clearFilter() {
    if (this.selectedComplianceReassignment.value === 'ComplianceReassignment') {
      this.selectedComplianceType = { text: 'Compliance Type', value: 'Compliance Type' }
      this.selectedRole = { text: 'Role', value: 'Role' }
      this.selectedExistingUser = { key: 'User', value: 'User' }
      this.selectedNewUser = { key: 'New User', value: 'New User' }
      this.selectedNodeName = 'Entity/Sub-Entity/Location';
      this.branch = [];
      this.gridData = []
    }
    else if (this.selectedComplianceReassignment.value === 'ActivationDateChange') {
      this.branch2 = [];
      this.gridData1 = []
    }
    else if (this.selectedComplianceReassignment.value === 'AssignmentRemoval') {
      this.branch3 = [];
      this.selectedBranch = [];
      this.gridData1 = []
    }
    else if (this.selectedComplianceReassignment.value === 'FormsMappping') {
      this.selectedschedulesComplianceType = { text: 'Compliance Type', value: 'Compliance Type' }
      this.selectedPeriodCreation = { text: 'Period', value: 'Period' }
      this.selectedYearCreation = { text: 'Year', value: 'Year' };
    }
    else if (this.selectedComplianceReassignment.value === 'ScheduleRemoval') {
      this.selectedPeriod = { text: 'Period', value: 'Period' }
      this.selectedYear = { text: 'Year', value: 'Year' };
      this.branch4 = [];
      this.gridData2 = []
    }

    this.searchInput.nativeElement.value = '';

  }


  closePopup() {
    this.openUpload = false
  }

  getScheduleRemovalGrid() {
    const instanceGridPayload: InstanceGridPayload_Dto = {
      entityId: this.user.getClientId(),
      flag: 'Schedules',
      year: this.selectedYear.value,
      branches: this.commaSeparatedIds,
      month: this.selectedPeriod.value.toString()
    };
    this.loading = true;
    console.log('Payload', instanceGridPayload);

    this.manageComplianceService.getComplianceManagementData(instanceGridPayload).subscribe({
      next: (response) => {
        console.log('Schedule Removal Grid data', response);
        const data = response.body;

        const filteredData = this.applyScheduleSearchFilter(data);
        console.log('filetered data', filteredData);

        this.gridData2 = {
          data: filteredData.slice(this.skip, this.skip + this.pageSize),
          total: filteredData.length
        };
        this.loading = false;
      }, error: (error) => {
        this.loading = false;
      }
    });
  }


  onScheduleCheckboxChange(complianceId: string, scheduleId: string, event: any): void {
    const isChecked = event.target.checked;

    if (isChecked) {

      this.scheduleList = this.scheduleList
        ? `${this.scheduleList},${scheduleId}`
        : scheduleId;
      this.complianceIdList = this.complianceIdList
        ? `${this.complianceIdList},${complianceId}`
        : complianceId;
    } else {

      const ids = this.scheduleList.split(',');
      this.scheduleList = ids.filter(id => id !== scheduleId).join(',');
      const Complianceids = this.complianceIdList.split(',');
      this.complianceIdList = Complianceids.filter(id => id !== complianceId).join(',');
    }

    if (this.scheduleList.length === 0) {
      this.scheduleList = '';
    }
    this.scheduleChecked = this.gridData2.data.some((item: any) => item.selected);
    console.log('Schedule List', this.scheduleList);
    console.log('Compliance List', this.complianceIdList);

  }
  removeSchedules() {

    const removeScheduleList: RemoveScheduleDto = {
      ScheduleList: this.scheduleList.toString(),
      UserID: this.selectedExistingUser.value.toString(),
      ComplianceList: this.complianceIdList.toString(),
      EntityId: this.user.getClientId()
    }
    console.log('Schedule List', removeScheduleList);

    this.manageComplianceService.removeSchedules(removeScheduleList).subscribe({
      next: (response) => {
        console.log('Schedule removal Response', response);
        this.alertData = { alertMessage: 'Schedule Removed Successfully', alertVisible: 'success' };
        this.getScheduleRemovalGrid()
      }
    })
  }

  public closeAlertPopup(): void {
    this.alertData = { alertMessage: '', alertVisible: '' };
    this.close.emit();
  }


  public isCellBeingEdited(rowIndex: number, column: string): boolean {
    return this.editedRowIndex === rowIndex && this.editedColumn === column;
  }
  public editRow(index: number, column: string, dataItem: any): void {
    this.editedRowIndex = index;
    this.editedColumn = column;
  }


  public parseDate(dateString: string): Date {
    if (!dateString) {
      return new Date();
    }
    const [day, month, year] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day);
  }

  public formatDate(date: Date): string {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
  toggleSelectAll(event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    if (this.shouldShowDropdowns()) {
      this.gridData.data.forEach((item: any) => {
        item.selected = checked;
        if (checked) {
          this.selectedReassignmentIds.push(item.complianceInstanceID);
        } else {
          this.selectedReassignmentIds = [];
        }
      });

      this.reassignmentInstanceIds = this.selectedReassignmentIds.join(',');
      this.isAnyCheckboxChecked = this.selectedReassignmentIds.length > 0;
    }

    else if (this.selectedComplianceReassignment.value === 'ActivationDateChange') {
      const isChecked = checked;
      this.checkedRows = this.gridData1.data.map(() => isChecked);
      this.updateSaveButtonVisibility();
    }

    else if (this.selectedComplianceReassignment.value === 'AssignmentRemoval') {
      this.gridData1.data.forEach((item: any, index: number) => {
        item.selected = checked;
        this.checkedRows[index] = checked;

        const selectedId = item.complianceInstanceID;
        let instanceIdArray: string[] = this.selectedInstanceIds ? this.selectedInstanceIds.split(',') : [];

        if (checked) {
          if (!instanceIdArray.includes(selectedId.toString())) {
            instanceIdArray.push(selectedId.toString());
          }
        } else {
          instanceIdArray = instanceIdArray.filter(id => id !== selectedId.toString());
        }

        this.selectedInstanceIds = instanceIdArray.join(',');
      });

      this.anyChecked = checked;
    }
    else if (this.selectedComplianceReassignment.value === 'ScheduleRemoval') {
      this.gridData2.data.forEach((item: any) => {
        item.selected = checked;
        if (checked) {
          this.scheduleList = this.scheduleList
            ? `${this.scheduleList},${item.scheduleid}`
            : item.scheduleid;
          this.complianceIdList = this.complianceIdList
            ? `${this.complianceIdList},${item.complianceId}`
            : item.complianceId;
        } else {
          this.scheduleList = this.scheduleList.split(',').filter(id => id !== item.scheduleid).join(',');
          this.complianceIdList = this.complianceIdList.split(',').filter(id => id !== item.complianceId).join(',');
        }
      });
      this.scheduleChecked = checked;
    }

    this.cdr.detectChanges();

    console.log('Schedule List after select all', this.scheduleList);
    console.log('Compliance List after select all', this.complianceIdList);
  }
  getUniqueColumnValues(field: string): any[] {
    const dataArray = this.wholeData || []; 
    
    const uniqueValues = new Set(
      dataArray.map((item: any) => item[field]).filter((value: any) => value != null)
    );
  
   
    return Array.from(new Set(uniqueValues));
  }
 
}
