<!-- <kendo-dialog #dialogRef [title]="dialogTitles" (close)="closePopup()" [minWidth]="400" [width]="1200"
    class="custom-dialog">
   
    <div id="divDownloads" class="align" >
        <div class="row colpadding0 new-gen-round">
            <div class="col-12 d-flex justify-content-between flex-wrap align-items-center p-3 ">
                <div class="col-md-5 col-12 mb-2 mb-md-0 mobile-responsive">
                    <div class="col-md-4 d-flex left">
                        <span style="font-weight: 500;">Upload</span>
                    </div>
                    <div class="col-md-8 d-flex align-items-center">
                        <img src="../assets/vectors/Excel.svg" alt="" class="excelSample mr-2">
                        <a href="../assets/BulkUploadDocuments/ESIC_Downlods.xlsx" download="ESIC_Downlods.xlsx">Sample Document</a>
                    </div>
                </div>
                <div class="col-md-6 col-12 mobile-responsive1 mt-3 mt-md-0 d-flex align-items-center">
                    <div class="d-flex flex-grow-1 p-left">
                        <div *ngIf="!fileNames['DownloadsInput']" class="file-display d-flex align-items-center">
                            <span style="color: #A0A0A0;">No file chosen</span>
                        </div>
                        <div *ngIf="fileNames['DownloadsInput']" class="file-display d-flex align-items-center">
                            <span style="color: #A0A0A0;">{{fileNames['DownloadsInput']}}</span>
                        </div>
                        <input type="file" formControlName="DownloadsInput" #DownloadsInputFile
                            (change)="onFileChange($event, 'DownloadsInput')" id="fileInput" style="display: none;" />
                        <button class="figma-btn-gray mr-2" style="height: 27px;" (click)="DownloadsInputFile.click()">Browse</button>
                        <button class="figma-btn-blue mr-2" (click)="uploadFile()">Upload</button>
                    </div>
                    <button class="figma-icon-white mr-2" (click)="removeFile('DownloadsInput')" aria-label="Delete">
                        <img src="../assets/vectors/Trash.svg" alt="" title="Delete">
                    </button>
                </div>
            </div>
        </div>
    </div>
    

</kendo-dialog> -->

<kendo-dialog title="File Upload" (close)="closePopup()" [minWidth]="400" [width]="600" [height]="400"
  class="custom-dialog">
  <div class="row dialog-content">
    <div class="col-md-12" style="display: flex; align-items: center;">
      <div class="col-md-6" style="flex: 1;">
        <label for="upload" class="f-label">Upload</label>
      </div>
      <div class="col-md-6 sampleDocument" style="flex: 1; display: flex; align-items: center;">
        <img src="../assets/vectors/Excel.svg" alt="" style="padding: 5px;margin-left:-40%;">
        <!-- <a href="../assets/files/AvailableActivityDetails.xlsx" download="AvailableActivityDetails.xlsx"
          style="color: #125AAB; text-decoration: underline;font-size:15px;" id="btnSampleRLCS">
          AvailableActivityDetails
        </a> -->
        <a (click)="ActivityDetailsExporttoExcel()" style="color: #125AAB; text-decoration: underline; font-size: 15px;" id="btnSampleRLCS">
          AvailableActivityDetails
        </a>

       

        <img src="../assets/vectors/Excel.svg" alt="" style="padding: 5px;">
        <a href="../assets/files/GstUploads.xlsx" download="GstUploads.xlsx"
          style="color: #125AAB; text-decoration: underline;font-size:15px" id="btnSampleRLCS">
          EmptyTemplate
        </a>


        <!-- <img src="../assets/vectors/GstUploads.csv" alt="" style="padding: 5px;"> -->
      </div>
    </div>
    <div class="col-md-12 form-group" style="margin-top: 20px;">
      <div class="drop-zone" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
        <div *ngIf="!selectedFile" class="f-label" style="font-size: 18px;">
          <div class="col-md-12" style="margin-top: 25px;">
            <img src="../assets/vectors/cloud.svg" alt="">
          </div>
          <div class="col-md-8">
            <label style="margin-left: 130px;">{{ dropzoneMessage }}</label>
          </div>
          <div class="col-md-4">
            <label
              style="color: #125AAB; text-decoration: underline; cursor: pointer; margin-left: -40px; font-weight: 500;">
              Browse
              <input type="file" style="display: none;" (change)="onFileSelect($event)" accept=".xls,.xlsx,.csv" />
            </label>
          </div>
        </div>
        <p *ngIf="selectedFile" style="margin-top: 60px;">{{ selectedFile.name }}</p>
      </div>
    </div>
  </div>
  <kendo-dialog-actions style="border: none; padding: 15px;">
    <button kendoButton class="figma-btn-blue figma-popup" (click)="uploadFile()">Upload</button>
  </kendo-dialog-actions>
</kendo-dialog>
<app-alert-popup *ngIf="alertData.alertVisible" [Width]="350" [Height]="280" [Data]="alertData"
  (close)="closeAlertPopup()"></app-alert-popup>