import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private readonly localStorageKey = 'selectedMenu';

  private selectedMenuSubject = new BehaviorSubject<string>(localStorage.getItem(this.localStorageKey) || 'ESIC Cards');
  selectedMenu$ = this.selectedMenuSubject.asObservable();

  setSelectedMenu(menu: string) {
    this.selectedMenuSubject.next(menu);
    localStorage.setItem(this.localStorageKey, menu);
  }
}
