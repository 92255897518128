<kendo-dialog #dialogRef title="File Upload" (close)="closePopup()" [minWidth]="300" [height]="450" [width]="750"
  class="custom-dialog">

  <div class="col-md-12 colpadding0" style="margin-top: 0px;">
    <header class="panel-heading tab-bg-primary"
      style="background: none !important; margin-left: 33px; padding: 0px 0px; zoom: 0.9;">
      <ul class="nav nav-tabs" style="margin-left: -23px;">
        <li class="listItem" [ngClass]="{'selected-tab': TabOption === 'R'}" (click)="TabOption = 'R'"
          style="margin-left: -8px !important">
          <a>Upload Regional File</a>
        </li>
        <li class="listItem" [ngClass]="{'selected-tab': TabOption === 'E'}" (click)="TabOption = 'E'"
          style="margin-left: -8px !important">
          <a>Upload English File</a>
        </li>
      </ul>
    </header>
  </div>
  <div class="row MasterContainer">
    <div class="col-md-6 d-flex align-items-center">
      <label class="f-label me-3">State</label>
      <div class="require-container">
        <app-dropdown-list [data]="StateDropdownData" [selectedValue]="selectedState"
          (valueChange)="onDropdownValueChange($event,'State')" [hasError]="!selectedState.value && showErrorValidation"
          textField="text" valueField="value" [width]="'250px'">
        </app-dropdown-list>
      </div>
    </div>
    <div class="col-md-6 d-flex align-items-center">
      <label class="f-label me-3">Act</label>
      <div class="require-container">
        <app-dropdown-list [data]="ActDropdownData" [selectedValue]="selectedAct" [width]="'250px'" textField="text"
          valueField="value" (valueChange)="onDropdownValueChange($event,'Act')">
        </app-dropdown-list>
      </div>
    </div>
  </div>
  <div class="row col-12 colpadding0 sampleDocument">
    <div class="row ">
      <div class="col-md-12" style="float: left;">
        <label class="f-label">Upload</label>
      </div>
    </div>
    <div class="col-md-12 form-group">
      <div class="drop-zone" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
        <div *ngIf="!selectedFile" class="f-label" style="font-size: 18px;">
          <div class="col-md-12">
            <img src="../assets/vectors/cloud.svg" alt="">
          </div>
          <div class="col-md-8">
            <label style="margin-left: 185px;">{{ dropzoneMessage }}</label>
          </div>
          <div class="col-md-4">
            <label
              style="color: #125AAB; text-decoration: underline; cursor: pointer;  margin-left: -75px; font-weight: 500;">
              Browse
              <input type="file" style="display: none;" (change)="onFileSelect($event)"
                accept=".pdf,.docx,.doc,.zip,.rar,.txt,.jpg,.png" />
            </label>
          </div>
        </div>
        <p *ngIf="selectedFile" style="margin-top: 30px;">{{ selectedFile.name }}</p>
      </div>
    </div>
  </div>

  <kendo-dialog-actions style="border: none;">
    <button kendoButton type="submit" (click)="uploadFiles()" class="figma-btn-blue f-popup">
      Upload
    </button>
  </kendo-dialog-actions>

  <div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
  </div>
</kendo-dialog>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270"  [Data]="alertData"
  (close)="cloasAlert()"></app-alert-popup>