<kendo-dialog #dialogRef title="Share With Others" (close)="closePopup()" [width]="700" class="custom-dialog">
    <form [formGroup]="form">

        <!-- <div class="row EmpContainer">
            <div class="col-md-12 d-flex align-items-center">
                <label class="f-label me-3">Permission<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <app-dropdown-list [data]="PermissiondropdownData" [selectedValue]="Permission" [width]="'330px'"
                    textField="text" valueField="value" name="gender"
                    [hasError]="!Permission.value && showValidationErrors"
                    (valueChange)="onDropdownValueChange($event, 'Permission')">
                </app-dropdown-list>
            </div>
        </div>
    </div> -->
        <div class="row EmpContainer">
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label me-3">Permission<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <app-dropdown-list [data]="PermissiondropdownData" [selectedValue]="Permission" [width]="'250px'"
                        textField="text" valueField="value" name="gender"
                        [hasError]="!Permission.value && showValidationErrors"
                        (valueChange)="onDropdownValueChange($event, 'Permission')">
                    </app-dropdown-list>
                </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label me-3">People<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <!-- <kendo-multiselecttree [ngClass]="{'is-invalid':  isInvalid()}" #multiselectTree
                    placeholder="Select Branch" kendoMultiSelectTreeExpandable kendoMultiSelectTreeFilterable
                    [popupSettings]="popupSettings" [kendoMultiSelectTreeHierarchyBinding]="BranchDropdown"
                    childrenField="children" textField="key" valueField="value" [tagMapper]="tagMapper"
                    [(ngModel)]="selectedItems" (valueChange)="onValueChange($event)" [expandedKeys]="['0']"
                    class="f-textbox multi-box">
                </kendo-multiselecttree> -->
                    <kendo-multiselecttree style="padding-right: 8px; width: 250px" class="f-textbox multi-box"
                        #multiselectTree [placeholder]="filePlaceholder" kendoMultiSelectTreeExpandable
                        [kendoMultiSelectTreeHierarchyBinding]="BranchDropdown" formControlName="SelectedUser"
                        textField="name" valueField="id" childrenField="children" [tagMapper]="tagMapper"
                        (valueChange)="onFileChange($event)" [expandedKeys]="['0']" [popupSettings]="popupSettings"
                        [ngClass]="{'is-invalid': form.get('SelectedUser')?.invalid && (form.get('SelectedUser')?.touched)}">
                    </kendo-multiselecttree>

                </div>
            </div>
        </div>
    </form>

    <kendo-dialog-actions class="ksave">
        <button type="button" (click)="onCreate()" class="figma-btn-blue">
            Done
        </button>
    </kendo-dialog-actions>
    <app-grid-action-item [gridData]="gridData" [columns]="parentColumns" [pageSize]="10" [pageable]="true"
        [filterable]="true" (buttonClick)="handleButtonClick($event)">
    </app-grid-action-item>
    <div *ngIf="loading" class="loader-overlay">
        <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
    </div>
</kendo-dialog>

<app-alert-popup *ngIf="alertData.alertVisible" [id]="deleteId" [Width]="290" [Data]="alertData" (close)="closeAlert()"
    (confirm)="DeleteMWMaster($event)"></app-alert-popup>