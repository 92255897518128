<!-- <kendo-dialog #dialogRef title="Agreement Details" (close)="closePopup()" [width]="1250" height="1000"
    class="custom-dialog"> -->
<div class="row col-12 dialog-content" style="margin-left:10px;">
    <form [formGroup]="agreementForm" >
        <div class="row EmpContainer">
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label " title="Agreement Type">Agreement Type<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <app-dropdown-list title="Agreement Type" [data]="dropdownData" [selectedValue]="selectedAgreement"
                        textField="text" valueField="value" [width]="'250px'"
                        [hasError]="!selectedAgreement.value && showValidationErrors"
                        (valueChange)="onAgreementChange($event)">
                    </app-dropdown-list>
                </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label " title="Service Zone">Service Zone<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <div class="masking d-flex align-items-center" style="bottom: 5px;">
                        <app-dropdown-list title="Service Zone" [data]="zones" [selectedValue]="selectedZone"
                            textField="text" valueField="value" [width]="'250px'"
                            [hasError]="!selectedZone.value && showValidationErrors"
                            (valueChange)="onZoneChange($event)">
                        </app-dropdown-list>
                    </div>
                </div>
            </div>
        </div>
        <div class="row EmpContainer">
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label " for="datepicker" title="Agreement Start Date">Agreement Start Date <sub
                        class="text-danger">*</sub></label>
                <div class="require-container">
                    <app-kendo-datepicker title="Agreement Start Date" aria-hidden="true" [class.is-invalid]="hasError"
                        [(value)]="selectedStartDate" (valueChange)="onStartDateChange($event)" [format]="'dd-MM-yyyy'"
                        [placeholder]="datePlaceholder" [width]="'250px'"
                        [hasError]="!selectedStartDate && showValidationErrors">
                    </app-kendo-datepicker>
                </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label " title="No of Entities">No of Entities<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <input type="number" title="No of Entities" placeholder="Enter only numbers"
                        class="form-control f-textbox" formControlName="NoOfEntities"
                        [ngClass]="{'is-invalid': agreementForm.get('NoOfEntities')?.invalid && (agreementForm.get('NoOfEntities')?.touched) && showValidationErrors}">

                </div>
            </div>
        </div>
        <div class="row EmpContainer">
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label " title="Agreement End Date">Agreement End Date <sub
                        class="text-danger">*</sub></label>
                <div class="require-container">
                    <app-kendo-datepicker title="Agreement End Date" [class.is-invalid]="hasError" aria-hidden="true"
                        [(value)]="selectedEndDate" (valueChange)="onEndDateChange($event)" [format]="'dd-MM-yyyy'"
                        [placeholder]="datePlaceholder" [width]="'250px'"
                        [hasError]="!selectedEndDate && showValidationErrors">
                    </app-kendo-datepicker>
                </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label " title="No of States">No of States<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <input type="number" title="No of States" placeholder="Enter only numbers"
                        class="form-control f-textbox" formControlName="NoOfStates"
                        [ngClass]="{'is-invalid': agreementForm.get('NoOfStates')?.invalid && (agreementForm.get('NoOfStates')?.touched) && showValidationErrors}">

                </div>
            </div>
        </div>
        <div class="row EmpContainer">
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label " title="Agreement Execution Date">Agreement Execution Date <sub
                        class="text-danger">*</sub></label>
                <div class="require-container">
                    <app-kendo-datepicker title="Agreement Execution Date" [class.is-invalid]="hasError"
                        aria-hidden="true" [(value)]="selectedExeDate" (valueChange)="onExeDateChange($event)"
                        [format]="'dd-MM-yyyy'" [placeholder]="datePlaceholder" [width]="'250px'"
                        [hasError]="!selectedExeDate && showValidationErrors">
                    </app-kendo-datepicker>
                </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label " title="No of Branches">No of Branches<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <input type="number" title="No of Branches" placeholder="Enter only numbers"
                        class="form-control f-textbox" formControlName="NoOfBranches"
                        [ngClass]="{'is-invalid': agreementForm.get('NoOfBranches')?.invalid && (agreementForm.get('NoOfBranches')?.touched) && showValidationErrors}">

                </div>
            </div>
        </div>
        <div class="row EmpContainer">
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label " title="Office Address(TDS Exemption)">Office Address(TDS Exemption)<sub
                        class="text-danger">*</sub></label>
                <div class="require-container ">
                    <textarea class="form-control f-textbox" title="Office Address(TDS Exemption)"
                        formControlName="address" placeholder="Address" [ngClass]="{'is-invalid': agreementForm.get('address')?.invalid && 
                        (agreementForm.get('address')?.touched || showValidationErrors) }"></textarea>

                </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label " title="Indemnity Clause if any specified">Indemnity Clause if any
                    specified</label>
                <div class="require-container">
                    <input type="text" title="Indemnity Clause if any specified" class="form-control f-textbox"
                        [(ngModel)]="Clause" placeholder="Indemnity Clause if any specified" formControlName="Clause">
                </div>
            </div>
        </div>
        <div class="row EmpContainer">
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label " title="Agreement Termination Date">Agreement Termination Date<sub
                        class="text-danger">*</sub></label>
                <div class="require-container">
                    <app-kendo-datepicker title="Agreement Termination Date" aria-hidden="true"
                        [(value)]="selectedTermDate" (valueChange)="onTermDateChange($event)" [format]="'dd-MM-yyyy'"
                        [placeholder]="datePlaceholder" [width]="'250px'"
                        [hasError]="!selectedTermDate && showValidationErrors">
                    </app-kendo-datepicker>
                </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label " title="SLA">SLA<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <app-dropdown-list title="SLA" [data]="sla" [selectedValue]="selectedSla" textField="text"
                        valueField="value" [width]="'250px'" [hasError]="!selectedSla.value && showValidationErrors"
                        (valueChange)="onSlaChange($event)">
                    </app-dropdown-list>
                </div>
            </div>
        </div>
        <div class="row EmpContainer">
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label " title="Funding">Funding<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <app-dropdown-list title="Funding" [data]="funding" [selectedValue]="selectedFunding"
                        textField="text" valueField="value" [width]="'250px'"
                        [hasError]="!selectedFunding.value && showValidationErrors"
                        (valueChange)="onFundingChange($event)">
                    </app-dropdown-list>
                </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">

                <label class="f-label " title="SLA Upload">SLA Upload<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <div>
                        <img src="../assets/vectors/ExcelDownload.svg" title="SLA Download" (click)="downloadSlaFile()"
                            alt="" style="margin-right: 5px;">

                        <span *ngIf="!fileNames['SlaUpload']" title="Uploaded File"
                            style="color: #A0A0A0;vertical-align:sub;">No file
                            chosen</span>
                        <span *ngIf="fileNames['SlaUpload']">
                            {{fileNames['SlaUpload']}}
                        </span>
                        <input type="file" #SlaUpload (change)="onFileChange($event, 'SlaUpload')" id="fileInput"
                            style="display: none;" accept=".xlsx" />
                        <button class="figma-btn-gray" (click)="SlaUpload.click()">Browse</button>
                    </div>
                    <div *ngIf="agreementForm.get('SlaUpload')?.invalid && (agreementForm.get('SlaUpload')?.touched) " class="text-danger">
                        File is required.
                    </div>
                </div>
            </div>
        </div>
        <div class="row EmpContainer">
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label " title="Advance/Security Deposit">Advance/Security Deposit<sub
                        class="text-danger">*</sub></label>
                <div class="require-container">
                    <app-dropdown-list title="Advance/Security Deposit" [data]="SecurityDeposit"
                        [selectedValue]="selectedSecurityDeposit" textField="text" valueField="value" [width]="'250px'"
                        [hasError]="!selectedSecurityDeposit.value && showValidationErrors"
                        (valueChange)="onSecurityDepositChange($event)">
                    </app-dropdown-list>
                </div>
            </div>

            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label " title="Agreement Copy">Agreement Copy<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <div>
                        <img src="../assets/vectors/ExcelDownload.svg" (click)="downloadAgreementFile()" alt=""
                            style="margin-right: 5px;">
                        <span *ngIf="!fileNames['Agreement']" style="color: #A0A0A0;vertical-align:sub;">No file
                            chosen</span>
                        <span *ngIf="fileNames['Agreement']">
                            {{fileNames['Agreement']}}
                        </span>
                        <input type="file" #Agreement (change)="onFileChange($event, 'Agreement')" id="fileInput"
                            style="display: none;" accept=".xlsx" />
                        <button class="figma-btn-gray" (click)="Agreement.click()">Browse</button>
                    </div>
                    <div *ngIf="agreementForm.get('Agreement')?.invalid && (agreementForm.get('Agreement')?.touched)" class="text-danger">
                        File is required.
                    </div>
                </div>
            </div>
        </div>
        <div class="row EmpContainer">
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label " title="Advance/Security Deposit%">Advance/Security Deposit%<sub
                        class="text-danger">*</sub></label>
                <div class="require-container">
                    <input type="number" title="Deposit" class="form-control f-textbox" formControlName="DepositPer"
                        placeholder="Enter only numbers"
                        [ngClass]="{'is-invalid': agreementForm.get('DepositPer')?.invalid && (agreementForm.get('DepositPer')?.touched) && showValidationErrors}">
                </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label " title="Process Document">Process Document<sub
                        class="text-danger">*</sub></label>
                <div class="require-container">
                    <div>
                        <img src="../assets/vectors/ExcelDownload.svg" (click)="downloadDocFile()" alt=""
                            style="margin-right: 5px;">
                        <span *ngIf="!fileNames['ProcessDoc']" style="color: #A0A0A0;vertical-align:sub;">No file
                            chosen</span>
                        <span *ngIf="fileNames['ProcessDoc']">
                            {{fileNames['ProcessDoc']}}
                        </span>
                        <input type="file" #ProcessDoc (change)="onFileChange($event, 'ProcessDoc')" id="fileInput"
                            style="display: none;" accept=".xlsx" />
                        <button class="figma-btn-gray" (click)="ProcessDoc.click()">Browse</button>
                    </div>
                    <div *ngIf="agreementForm.get('ProcessDoc')?.invalid && (agreementForm.get('ProcessDoc')?.touched)" class="text-danger">
                        File is required.
                    </div>
                </div>
            </div>
        </div>
        <div class="row EmpContainer">
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label "> SPOC Owner Name<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <input type="text" class="form-control f-textbox" placeholder="Name" formControlName="SpocName"
                        [ngClass]="{'is-invalid': agreementForm.get('SpocName')?.invalid && (agreementForm.get('SpocName')?.touched)}">
                    <div *ngIf="agreementForm.get('SpocName')?.invalid && (agreementForm.get('SpocName')?.touched)"
                        class="text-danger">
                        <div *ngIf="agreementForm.get('SpocName')?.errors?.['pattern']">
                            Name must contain only letters and spaces.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label " title="Agreement Status">Agreement Status<sub
                        class="text-danger">*</sub></label>
                <div class="require-container">
                    <app-dropdown-list title="Agreement Status" [data]="astatus" [selectedValue]="selectedAStatus"
                        textField="text" valueField="value" [width]="'250px'"
                        [hasError]="!selectedAStatus.value && showValidationErrors"
                        (valueChange)="onAStatusChange($event)">
                    </app-dropdown-list>
                </div>
            </div>
        </div>
        <div class="row EmpContainer">
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label " title="Contact Number">Contact Number<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <input type="text" title="Contact Number" class="form-control f-textbox"
                        placeholder="Enter only numbers" formControlName="ContactNumber" [(ngModel)]="ContactNumber"
                        (input)="validateMobileNo($event)" minlength="10" maxlength="10"
                        [ngClass]="{'is-invalid': agreementForm.get('ContactNumber')?.invalid && (agreementForm.get('ContactNumber')?.touched || showValidationErrors)}">
                    <div *ngIf="agreementForm.get('ContactNumber')?.invalid && (agreementForm.get('ContactNumber')?.touched || showValidationErrors)"
                        class="text-danger">
                        <div *ngIf="agreementForm.get('ContactNumber')?.errors?.['pattern']">
                            Contact No must be exactly 10 digits.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label " title="Renewal Trigger">Renewal Trigger<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <div class="d-flex align-items-center" style="bottom: 5px;">
                        <input type="number" title="Renewal Trigger" formControlName="RenewalTrigger" class="f-textbox"
                            value="0">
                    </div>
                </div>
            </div>
        </div>
        <div class="row EmpContainer">
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label " title="Agreement-Client Email ID">Agreement-Client Email ID<sub
                        class="text-danger">*</sub></label>
                <div class="require-container">
                    <input type="email" title="Agreement-Client Email ID" class="form-control f-textbox"
                        placeholder="Email" formControlName="Email"
                        [ngClass]="{'is-invalid': agreementForm.get('Email')?.invalid && (agreementForm.get('Email')?.touched || showValidationErrors)}">
                    <div *ngIf="agreementForm.get('Email')?.invalid && (agreementForm.get('Email')?.touched || showValidationErrors)"
                        class="text-danger">
                        <div *ngIf="agreementForm.get('Email')?.errors?.['pattern']">
                            Please enter a valid Email address.
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row EmpContainer" id="savebtn">
            <button type="button" (click)="OnSave()" class="figma-btn-blue ">
                Save
            </button>
        </div>
    </form>
</div>

<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>
<!-- </kendo-dialog> -->
<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270"  [Data]="alertData"
    (close)="closeAlert()"></app-alert-popup>