<form [formGroup]="form">
  <div class="row colpadding0 row-setting">
    <div class="col-12 d-flex align-items-center" style="flex-wrap:nowrap;">
      <app-dropdown-list [data]="complianceType" [width]="'250px'" [selectedValue]="selectedComplianceType"
        textField="text" valueField="value" class="dropdown-setting" (valueChange)="onComplianceTypeChange($event,1)"
        formControlName="compliance"
        [ngClass]="{'is-invalid': form.get('compliance')?.invalid && (form.get('compliance')?.touched)}">
      </app-dropdown-list>

      <app-dropdown-list [width]="'250px'" [data]="frequency" [selectedValue]="selectedFrequency" textField="text"
        valueField="value" (valueChange)="onFrequencyTypeChange($event)" formControlName="frequency"
        class="dropdown-setting"
        [ngClass]="{'is-invalid': form.get('frequency')?.invalid && (form.get('frequency')?.touched)}"
        [isDisabled]="selectedComplianceType.value==='Register' || selectedComplianceType.value==='Challan' || selectedComplianceType.value==='Compliance'">
      </app-dropdown-list>

      <app-dropdown-list [data]="Act" [width]="'250px'" [selectedValue]="selectedAct" textField="text" valueField="value"
        class=" dropdown-setting" (valueChange)="onActTypeChange($event)" formControlName="act"
        [ngClass]="{'is-invalid': form.get('act')?.invalid && (form.get('act')?.touched)}">
      </app-dropdown-list>

      <app-dropdown-list [data]="years" [selectedValue]="selectedYear" [width]="'120px'" textField="text"
        valueField="value" class="dropdown-setting" (valueChange)="onYearChange($event)" formControlName="year"
        [ngClass]="{'is-invalid': form.get('year')?.invalid && (form.get('year')?.touched)}">
      </app-dropdown-list>

      <app-dropdown-list [data]="period" [selectedValue]="selectedPeriod" [width]="'120px'" textField="text"
        valueField="value" (valueChange)="onPeriodChange($event)" formControlName="period"
        [ngClass]="{'is-invalid': form.get('period')?.invalid && (form.get('period')?.touched)}">
      </app-dropdown-list>
    </div>
  </div>
  <!-- <div class="col-12 d-flex align-items-center" style="flex-wrap:nowrap;">
      <div class="col-md-2 colpadding0" style="padding-right: 10px;">
        <app-dropdown-list [data]="complianceType" [width]="'100%'" [selectedValue]="selectedComplianceType"
          textField="text" valueField="value" class="dropdown-setting" (valueChange)="onComplianceTypeChange($event,1)"
          formControlName="compliance"
          [ngClass]="{'is-invalid': form.get('compliance')?.invalid && (form.get('compliance')?.touched)}">
        </app-dropdown-list>
      </div>
      <div class="col-md-2 colpadding0" style="padding-right: 10px;">
        <app-dropdown-list [width]="'100%'" style="width: 100%;" [data]="frequency" [selectedValue]="selectedFrequency"
          textField="text" valueField="value" (valueChange)="onFrequencyTypeChange($event)" formControlName="frequency"
          class="dropdown-setting"
          [ngClass]="{'is-invalid': form.get('frequency')?.invalid && (form.get('frequency')?.touched)}"
          [isDisabled]="selectedComplianceType.value==='Register' || selectedComplianceType.value==='Challan' || selectedComplianceType.value==='Compliance'">
        </app-dropdown-list>
      </div>
      <div class="col-md-2 colpadding0" style="padding-right: 10px;">
        <app-dropdown-list [data]="Act" style="width: 100%;" [width]="'100%'" [selectedValue]="selectedAct"
          textField="text" valueField="value" class=" dropdown-setting" (valueChange)="onActTypeChange($event)"
          formControlName="act" [ngClass]="{'is-invalid': form.get('act')?.invalid && (form.get('act')?.touched)}">
        </app-dropdown-list>
      </div>
      <div class="col-md-2 colpadding0" style="padding-right: 10px;">
        <app-dropdown-list style="width: 100%;" [data]="years" [selectedValue]="selectedYear" [width]="'100%'"
          textField="text" valueField="value" class="dropdown-setting" (valueChange)="onYearChange($event)"
          formControlName="year" [ngClass]="{'is-invalid': form.get('year')?.invalid && (form.get('year')?.touched)}">
        </app-dropdown-list>
      </div>
      <div class="col-md-2 colpadding0" style="padding-right: 10px;">
        <app-dropdown-list style="width: 100%;" [data]="period" [selectedValue]="selectedPeriod" [width]="'100%'"
          textField="text" valueField="value" (valueChange)="onPeriodChange($event)" formControlName="period"
          [ngClass]="{'is-invalid': form.get('period')?.invalid && (form.get('period')?.touched)}">
        </app-dropdown-list>
      </div>
    </div> -->
  <!-- </div> -->
  <div class="singlerow" style="margin-top: 10px;">

    <div class="left-panel searches">
      <div class="col-md-5 colpadding0" style="padding-right: 10px;">
        <kendo-multiselecttree style="padding-right: 8px; width: 100%" class="f-textbox multi-box" #multiselectTree
          [placeholder]="filePlaceholder" kendoMultiSelectTreeExpandable [kendoMultiSelectTreeHierarchyBinding]="files"
          formControlName="files" textField="name" valueField="id" childrenField="children" [tagMapper]="tagMapper"   
          (valueChange)="onFileChange($event)" [expandedKeys]="['0']" [popupSettings]="popupSettings"
          [ngClass]="{'is-invalid': form.get('files')?.invalid && (form.get('files')?.touched)}">
        </kendo-multiselecttree>
      </div>

      <div class="col-md-5 colpadding0" style="padding-right: 10px;">
        <kendo-multiselecttree style=" width: 250px" class="f-textbox multi-box" #multiselectTreeBranches
          [placeholder]="entityPlaceholder" kendoMultiSelectTreeExpandable
          [kendoMultiSelectTreeHierarchyBinding]="selectedAct.value === 'SEA'|| selectedAct.value==='FACT' || selectedComplianceType.value==='Return' || selectedComplianceType.value==='Challan' ? BranchDropdown : clraBranchDropdown"
          [value]="selectedBranches" formControlName="branches" childrenField="children" textField="name"
          valueField="id"
          [tagMapper]="selectedAct.value === 'SEA'||selectedAct.value==='FACT' ? tagMapper : clraTagMapper"
          (valueChange)="onEntityChange($event)" [expandedKeys]="['0']" [popupSettings]="popupSettings"
          [ngClass]="{'is-invalid': form.get('branches')?.invalid && (form.get('branches')?.touched)}">
        </kendo-multiselecttree>
      </div>


    </div>

    <div class="right-panel">
      <div>
        <button class="figma-btn-white" type="submit" (click)="getJsonId()">
          <img src="../assets/vectors/ApplyFilter.svg" alt="">
        </button>
      </div>
      <div >
        <button class="figma-btn-white" (click)="clearFilter()">
          <img src="../assets/vectors/ClearFilter.svg" alt="">
        </button>
      </div>
      <div *ngIf="selectedItems.length > 0" >
        <button class="figma-btn-blue" (click)="getOutputJson()">Proceed</button>
        <!-- <button  class="figma-btn-blue" (click)="GetInputfileisProcess()" >Proceed</button> -->
      </div>
    </div>

  </div>

</form>

<div *ngIf="selectedComplianceType.value==='Register'" class="grid">
  <!-- <kendo-grid [data]="registerGridData" style="height: 366px;" class="custom-grid"  [columnMenu]="true" [pageSize]="pageSize" [pageable]="true"
        (pageChange)="pageChange($event)" [sortable]="true" [skip]="skip" [resizable]="true">
        <kendo-grid-column [width]="60">
            <ng-template kendoGridHeaderTemplate>
              <div class="header-checkbox">
                <input type="checkbox" [(ngModel)]="allSelected" (change)="toggleSelectAll($event, 'Register')" />
              </div>
            </ng-template>
      
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="checkbox-container">
                <input type="checkbox" [checked]="dataItem.selected" [disabled]="dataItem.disabled"
                       (change)="onItemSelectionChange(dataItem, 'Register')" />
              </div>
            </ng-template>
          </kendo-grid-column>
            <kendo-grid-column field="complianceID" title="Compliance ID" [width]="100"></kendo-grid-column>
            <kendo-grid-column field="complianceName" title="Compliance Description" [width]="300"></kendo-grid-column>
            <kendo-grid-column field="form" title="Format" [width]="150"></kendo-grid-column>
            <kendo-grid-column field="frequency" title="Frequency" [width]="120"></kendo-grid-column>
            <kendo-grid-column field="Action" title="Input File" [width]="120">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="action-icon" (click)="onActionClick(dataItem,'Input')">
                      <img src="../assets/vectors/eye_112_x2.svg" alt="Action Icon" style="cursor: pointer; width: 24px; height: 24px;" />
                    </div>
                  </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="branchCount" title="Branch Count" [width]="120">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div class="cell-content  branch-count-cell">
                    {{ dataItem.branchCount }}
                  </div>
                </ng-template>
              </kendo-grid-column>
        </kendo-grid> -->
  <kendo-grid [data]="registerGridData" style="height: 366px;" class="custom-grid" [columnMenu]="true"
    [pageSize]="pageSize" [pageable]="true" (pageChange)="pageChange($event)" [sortable]="true" [skip]="skip"
    [resizable]="true">
    <kendo-grid-column [width]="75">
      <ng-template kendoGridHeaderTemplate>
        <div class="header-checkbox">
          <input type="checkbox" [(ngModel)]="allSelected" (change)="toggleSelectAll($event, 'Register')" />
        </div>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="checkbox-container">
          <input type="checkbox" [checked]="dataItem.selected" [disabled]="dataItem.disabled"
            (change)="onItemSelectionChange(dataItem, 'Register')" />
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="complianceID" title="Compliance ID" [width]="100" class="cell-content"></kendo-grid-column>
    <kendo-grid-column field="complianceName" title="Compliance Description" [width]="300" class="cell-content"></kendo-grid-column>
    <kendo-grid-column field="form" title="Format" [width]="150" class="cell-content"></kendo-grid-column>
    <kendo-grid-column field="frequency" title="Frequency" [width]="120" class="cell-content"></kendo-grid-column>

    <kendo-grid-column field="Action" title="Input File" [width]="120">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="cell-content action-icon" (click)="onActionClick(dataItem,'Input')">
          <img src="../assets/vectors/EyeIcon.svg" alt="Action Icon" class="svg-icon"/>
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="branchCount" title="Branch Count" [width]="120">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="cell-content  branch-count-cell">
          {{ dataItem.branchCount }}
        </div>
      </ng-template>
    </kendo-grid-column>

    <!-- Custom pagination template -->
    <ng-template kendoPagerTemplate let-totalPages="total" let-currentPage="currentPage">
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-input></kendo-pager-input>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>

  </kendo-grid>

</div>

<div *ngIf="selectedComplianceType.value==='Return'" class="grid">
  <!-- <kendo-grid [data]="returnGridData" class="custom-grid" [columnMenu]="true" [pageSize]="pageSize" [pageable]="true"
        (pageChange)="pageChange($event)" [sortable]="true" [skip]="skip" [resizable]="true">
        <kendo-grid-column [width]="60">
            <ng-template kendoGridHeaderTemplate>
                <div class="cell-content  branch-count-cell">
                    <input type="checkbox" style="margin-left: 10px;" [(ngModel)]="allSelected" (change)="toggleSelectAll($event,'Return')" />
                </div>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="checkbox-container">
                    <input type="checkbox" style="margin-left: 10px;" [checked]="dataItem.selected" [disabled]="dataItem.disabled"
                        (change)="onItemSelectionChange(dataItem,'Return')" />
                </div>
            </ng-template>
        </kendo-grid-column>
            <kendo-grid-column field="complianceID" title="Compliance ID" [width]="140"></kendo-grid-column>
            <kendo-grid-column field="complianceName" title="Compliance Description" [width]="350"></kendo-grid-column>
            <kendo-grid-column field="form" title="Format" [width]="120"></kendo-grid-column>
            <kendo-grid-column field="frequency" title="Frequency" [width]="120"></kendo-grid-column>
            <kendo-grid-column field="Action" title="Input File" [width]="120">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="action-icon" (click)="onActionClick(dataItem,'Input')">
                      <img src="../assets/vectors/eye_112_x2.svg" alt="Action Icon" style="cursor: pointer; width: 24px; height: 24px;" />
                    </div>
                  </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="Action" title="Upload" [width]="120">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="action-icon" (click)="onActionClick(dataItem,'Upload')">
                      <img src="../assets/vectors/DownloadBlue.svg" alt="Action Icon" style="cursor: pointer; width: 24px; height: 24px;" />
                    </div>
                
                  </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="branchCount" title="Branch Count" [width]="120">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div class="cell-content  branch-count-cell">
                    {{ dataItem.branchCount }}
                  </div>
                </ng-template>
              </kendo-grid-column>
        </kendo-grid> -->
  <kendo-grid [data]="returnGridData" class="custom-grid" [columnMenu]="true" [pageSize]="pageSize" [pageable]="true"
    (pageChange)="pageChange($event)" [sortable]="true" [skip]="skip" [resizable]="true">
    <kendo-grid-column [width]="75">
      <ng-template kendoGridHeaderTemplate>
        <div class="cell-content branch-count-cell">
          <input type="checkbox" style="margin-left: 10px;" [(ngModel)]="allSelected"
            (change)="toggleSelectAll($event,'Return')" />
        </div>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="checkbox-container">
          <input type="checkbox" style="margin-left: 10px;" [checked]="dataItem.selected" [disabled]="dataItem.disabled"
            (change)="onItemSelectionChange(dataItem,'Return')" />
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="complianceID" title="Compliance ID" [width]="140" class="cell-content"></kendo-grid-column>
    <kendo-grid-column field="complianceName" title="Compliance Description" [width]="350" class="cell-content"></kendo-grid-column>
    <kendo-grid-column field="form" title="Format" [width]="120" class="cell-content"></kendo-grid-column>
    <kendo-grid-column field="frequency" title="Frequency" [width]="120" class="cell-content"></kendo-grid-column>

    <kendo-grid-column field="Action" title="Input File" [width]="120" >
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="cell-content action-icon" (click)="onActionClick(dataItem,'Input')">
          <img src="../assets/vectors/EyeIcon.svg" alt="Action Icon" class="svg-icon"/>
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="Action" title="Upload" [width]="120">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="cell-content action-icon" (click)="onActionClick(dataItem,'Upload')">
          <img src="../assets/vectors/DownloadBlue.svg" alt="Action Icon" class="svg-icon"/>
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="branchCount" title="Branch Count" [width]="120">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="cell-content branch-count-cell">
          {{ dataItem.branchCount }}
        </div>
      </ng-template>
    </kendo-grid-column>

    <!-- Custom pagination template -->
    <ng-template kendoPagerTemplate let-totalPages="total" let-currentPage="currentPage">
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-input></kendo-pager-input>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>

  </kendo-grid>

</div>

<div *ngIf="selectedComplianceType.value==='Challan'" class="grid">
  <!-- <kendo-grid [data]="challanGridData" class="custom-grid" [columnMenu]="true" [pageSize]="pageSize" [pageable]="true"
        (pageChange)="pageChange($event)" [sortable]="true" [skip]="skip" [resizable]="true">
        <kendo-grid-column [width]="60">
            <ng-template kendoGridHeaderTemplate>
                <div class="cell-content  branch-count-cell">
                    <input type="checkbox"  [(ngModel)]="allSelected" (change)="toggleSelectAll($event,'Challan')" />
                </div>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="checkbox-container">
                    <input type="checkbox" class="checkbox" [checked]="dataItem.selected" [disabled]="dataItem.disabled" 
                        (change)="onItemSelectionChange(dataItem,'Challan')" />
                </div>
            </ng-template>
        </kendo-grid-column>
            <kendo-grid-column field="complianceID" title="Compliance ID" [width]="140"></kendo-grid-column>
            <kendo-grid-column field="complianceName" title="Compliance Description" [width]="350"></kendo-grid-column>
            <kendo-grid-column field="form" title="Format" [width]="120"></kendo-grid-column>
            <kendo-grid-column field="frequency" title="Frequency" [width]="120"></kendo-grid-column>
            <kendo-grid-column field="Action" title="Input File" [width]="120" [style]="{'text-align': 'center'}">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div class="action-icon" (click)="onActionClick(dataItem,'Input')">
                    <img src="../assets/vectors/eye_112_x2.svg" alt="Action Icon" style="cursor: pointer; width: 24px; height: 24px;" />
                  </div>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="branchCount" title="Branch Count" [width]="120">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div class="cell-content  branch-count-cell">
                    {{ dataItem.branchCount }}
                  </div>
                </ng-template>
              </kendo-grid-column>
        </kendo-grid> -->
  <kendo-grid [data]="challanGridData" class="custom-grid" [columnMenu]="true" [pageSize]="pageSize" [pageable]="true"
    (pageChange)="pageChange($event)" [sortable]="true" [skip]="skip" [resizable]="true">

    <!-- Column with checkboxes -->
    <kendo-grid-column [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <div class="cell-content branch-count-cell">
          <input type="checkbox" [(ngModel)]="allSelected" (change)="toggleSelectAll($event,'Challan')" />
        </div>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="checkbox-container">
          <input type="checkbox" class="checkbox" [checked]="dataItem.selected" [disabled]="dataItem.disabled"
            (change)="onItemSelectionChange(dataItem,'Challan')" />
        </div>
      </ng-template>
    </kendo-grid-column>

    <!-- Other columns -->
    <kendo-grid-column field="complianceID" title="Compliance ID" [width]="140"class="cell-content"></kendo-grid-column>
    <kendo-grid-column field="complianceName" title="Compliance Description" [width]="350" class="cell-content"></kendo-grid-column>
    <kendo-grid-column field="form" title="Format" [width]="120" class="cell-content"></kendo-grid-column>
    <kendo-grid-column field="frequency" title="Frequency" [width]="120" class="cell-content" ></kendo-grid-column>

    <!-- Action column with an icon -->
    <kendo-grid-column field="Action" title="Input File" [width]="120" [style]="{'text-align': 'center'}">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="action-icon" (click)="onActionClick(dataItem,'Input')">
          <img src="../assets/vectors/EyeIcon.svg" alt="Action Icon" class="svg-icon"/>
        </div>
      </ng-template>
    </kendo-grid-column>

    <!-- Branch count column -->
    <kendo-grid-column field="branchCount" title="Branch Count" [width]="120">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="cell-content branch-count-cell">
          {{ dataItem.branchCount }}
        </div>
      </ng-template>
    </kendo-grid-column>

    <!-- Custom pagination template -->
    <ng-template kendoPagerTemplate let-totalPages="total" let-currentPage="currentPage">
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-input></kendo-pager-input>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>

  </kendo-grid>

</div>
<div *ngIf="loading" class="loader-overlay">
  <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>



<div kendoDialogContainer *ngIf="openUpload">
  <app-workspace-remanual-upload-popup (close)="closeUploadPopup()">

  </app-workspace-remanual-upload-popup>
</div>
<app-alert-popup *ngIf="alertData.alertVisible" [Width]="250" [Height]="260" [Data]="alertData"
  (close)="closeAlert()"></app-alert-popup>

<div kendoDialogContainer *ngIf="openInput">
<app-workspace-input-file-popup [actId]="actId" [complianceId]="complianceId" [compliance]="compliance" (close)="closeInput()">

</app-workspace-input-file-popup>
</div>