import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../service/user.service';
import { InputFilesService } from '../../../service/input-files.service';
import { BlobService } from '../../../service/blob.service';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { ChallanHistoryPerformerComponent } from '../../popups/challan-history-performer/challan-history-performer.component';
import { GridModule } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-output-challan-performer',
  standalone: true,
  imports: [NgIf,IndicatorsModule,NgFor,DropdownListComponent,NgClass,ChallanHistoryPerformerComponent,AlertPopupComponent,GridModule],
  templateUrl: './output-challan-performer.component.html',
  styleUrl: './output-challan-performer.component.css'
})
export class OutputChallanPerformerComponent {
  activeTable: string = 'output';
  challanWorkingList : any[] = [];
  finalChallanConfirmation : any[] = [];
  month: any;
  year: any;
  complianceId: any;
  selectedMonth:any
  selectedYear:any
  selectedComplianceId:any
  challanIds:any
  loading=false
  showState = false;
  openHistory=false
  public status: { text: string, value: string }[] = [
    { text: 'Select', value: 'S' },
    { text: 'Final', value: 'F' },
    { text: 'Revision', value: 'R' }
  ];
  public selectedStatus: { text: string, value: string } = { text: 'Select', value: 'S' };
  fileNames: string[] = [];
  finalFileNames: string[] = [];
  reUploadFileNames: string[] = [];
  public alertData = { alertMessage: '', alertVisible: '' };
  constructor(private router:Router,private user:UserService,private route: ActivatedRoute,private inputService:InputFilesService,private blobService:BlobService){}

  ngOnInit(): void {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state as {
        month: any;
        year: any;
        complianceId: any;
    };

    if (state) {
        this.month = state.month;
        this.year = state.year;
        this.complianceId = state.complianceId;
    }

    this.getChallan()
   console.log(this.selectedStatus.value);
   
    this.fileNames = new Array(this.challanWorkingList.length).fill('');
    this.reUploadFileNames = new Array(this.challanWorkingList.length).fill('');
    this.finalFileNames = new Array(this.finalChallanConfirmation.length).fill('');
}

  showTable(table: string) {
      this.activeTable = table; 
    }

    getChallan(){
      this.loading=true
      const payload={
        userId:this.user.getUserId(),
        customerId:this.user.getCustomerId(),
        entityId:this.user.getClientId(),
        month:this.month,
        year:this.year,
        complianceIds:'',
        jsonId:0,
        complianceType:this.complianceId,
        inputID:0
      }
      this.inputService.getChallanPerformerList(payload).subscribe({
        next:(response)=>{
          console.log(response);
          this.loading=false
          this.challanWorkingList  = response.body.challanWorking; 
          this.challanWorkingList.map(item => item.inputId).join(',');
          this.finalChallanConfirmation  = response.body.finalChallanConfirmation; 
          this.finalChallanConfirmation.map(item => item.inputId).join(',');
          if (this.challanWorkingList.length > 0) {
            const statusValue = this.challanWorkingList[0].status;
            this.selectedStatus = this.status.find(s => s.value === statusValue) || { text: 'Select', value: 'S' };
          }
        },
        error: (error) => {
          this.loading = false;
          console.error(error);
        }
      });

    }  

    onStatusChange(value:any){
      this.selectedStatus={text:value.text,value:value.value}
      
    }
    onFileChange(event: any, index: number) {
      const file = event.target.files[0];
  
      if (file) {
          this.fileNames[index] = file.name;
      } else {
          this.fileNames[index] = '';  
      }
  }
  onReuploadFileChange(event: any, index: number) {
      const file = event.target.files[0];
  
      if (file) {
          this.reUploadFileNames[index] = file.name;
      } else {
          this.reUploadFileNames[index] = '';  
      }
  }
  onFinalFileChange(event: any, index: number) {
    const file = event.target.files[0];

    if (file) {
        this.finalFileNames[index] = file.name;
    } else {
        this.finalFileNames[index] = '';  
    }
}
  downloadChallan(path:string,type:string){
    console.log(path,type);
    
    this.inputService.downloadChallan(path,type).subscribe({
      next:(response)=>{
        this.loading = false;
        const blobLink = response.body.filePath;
        let sasToken = response.body.sasToken
        const blobName = this.blobService.downloadBlobFile(blobLink,sasToken);
      }, error: (error) => {
        console.error('Error fetching blob link:', error);
        this.alertData = { alertMessage: 'Error downloading file', alertVisible: 'error' };
        this.showState = false;
        this.loading = false;
      }
    })
  }
  uploadFiles(index:number,status:string){
    console.log('status',status );
    
    this.loading = true;
    if (this.fileNames[index]) {
      const fileInput = <HTMLInputElement>document.getElementById('fileInput'+index);
      const file = fileInput?.files?.[0];
      let payload={
        EntityId : this.user.getClientId(),
        Month:this.month,
        Year:this.year,
        UserId:this.user.getUserId(),
        CustomerID:this.user.getCustomerId(),
        FileName:this.fileNames[index],
        ComplianceTypes:this.complianceId,
        Status:status,
        UserType:'P',
        FileType:'DC',
        FilePath:'',
        Remarks:'test',
      }
      console.log(payload);
      
      if (file) {
        const formData = new FormData();
        formData.append('file', file);

        this.inputService.uploadChallan(payload,formData).subscribe(
          {
            next: (response) => {
              console.log(response);
              
              this.loading = false;
             let result =  response.body.status
             if(result === 1 ){
              console.log(result);
              
              this.alertData = { alertMessage: 'File uploaded successfully', alertVisible: 'success' };
              this.fileNames[index] = '';
              
            }
            else {
                 console.log(result);
                this.alertData = { alertMessage: 'Error uploading file', alertVisible: 'error' };
              }
            }, error: (error) => {
              console.error('Error uploading file:', error);
              this.alertData = { alertMessage: 'Error uploading file', alertVisible: 'error' };
            }
          }
        );
      } else {
        this.alertData = { alertMessage: 'No file selected', alertVisible: 'error' };
        this.loading = false;
      }
    } else {
      this.alertData = { alertMessage: 'No file chosen', alertVisible: 'error' };
      this.loading = false;
    }
  }
  reUploadFiles(index:number,status:string){
    
    this.loading = true;
    if (this.reUploadFileNames[index]) {
      const fileInput = <HTMLInputElement>document.getElementById('fileInput'+index);
      const file = fileInput?.files?.[0];
      let payload={
        EntityId : this.user.getClientId(),
        Month:this.month,
        Year:this.year,
        UserId:this.user.getUserId(),
        CustomerID:this.user.getCustomerId(),
        FileName:this.reUploadFileNames[index],
        ComplianceTypes:this.complianceId,
        Status:status,
        UserType:'P',
        FileType:'RU',
        FilePath:'',
        Remarks:'test',
      }
      console.log(payload);
      
      if (file) {
        const formData = new FormData();
        formData.append('file', file);

        this.inputService.uploadChallan(payload,formData).subscribe(
          {
            next: (response) => {
              console.log(response);
              
              this.loading = false;
             let result =  response.body.status
             if(result === 1 ){
               this.alertData = { alertMessage: 'File uploaded successfully', alertVisible: 'success' };
               this.reUploadFileNames[index] = '';
                
              }
               else {
                this.alertData = { alertMessage: 'Error uploading file', alertVisible: 'error' };
              }
            }, error: (error) => {
              console.error('Error uploading file:', error);
              this.alertData = { alertMessage: 'Error uploading file', alertVisible: 'error' };
            }
          }
        );
      } else {
        this.alertData = { alertMessage: 'No file selected', alertVisible: 'error' };
        this.loading = false;
      }
    } else {
      this.alertData = { alertMessage: 'No file chosen', alertVisible: 'error' };
      this.loading = false;
    }
  
  }

  uploadFinalFiles(index:number){
    this.loading = true;
    if (this.finalFileNames[index]) {
      const fileInput = <HTMLInputElement>document.getElementById('fileInput'+index);
      const file = fileInput?.files?.[0];
      let payload={
        EntityId : this.user.getClientId(),
        Month:this.month,
        Year:this.year,
        UserId:this.user.getUserId(),
        CustomerID:this.user.getCustomerId(),
        FileName:this.finalFileNames[index],
        ComplianceType:this.complianceId,
        Status:this.selectedStatus.value,
        UserType:'P',
        FileType:'EC',
        FilePath:'',
        Remarks:'test',
      }
      console.log('final file payload',payload);
      
      if (file) {
        const formData = new FormData();
        formData.append('file', file);

        this.inputService.uploadFinalChallanFile(payload,formData).subscribe(
          {
            next: (response) => {
              console.log(response);
              
              this.loading = false;
             let result =  response.body.status
              if (result === 1) {
                this.finalFileNames[index] = ''; 
                this.alertData = { alertMessage: 'File uploaded successfully', alertVisible: 'success' };
                this.loading=false
              }
            
               else {
                this.alertData = { alertMessage: 'Error uploading file', alertVisible: 'error' };
                this.loading=false
              }
            }, error: (error) => {
              console.error('Error uploading file:', error);
              this.alertData = { alertMessage: 'Error uploading file', alertVisible: 'error' };
              this.loading=false
            }
          }
        );
      } else {
        this.alertData = { alertMessage: 'No file selected', alertVisible: 'error' };
        this.loading = false;
      }
    } else {
      this.alertData = { alertMessage: 'No file chosen', alertVisible: 'error' };
      this.loading = false;
    }
  }
  openPerformerHistoryPopup(){
    this.selectedMonth=this.month
    this.selectedYear=this.year
    this.selectedComplianceId=this.complianceId
    this.openHistory=true;
  }
  closePerformerHistoryPopup(){
    this.openHistory=false;
  }
  navigateToInput(){
    this.router.navigate(['InputFilesPerformer'])
  }

  closeAlert(){
    this.alertData = { alertMessage: '', alertVisible: '' }
  }

}
