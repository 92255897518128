import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
export interface TranslationDetailsDto {
  translationDocumentUploadDate?: Date;
  translationRequestDate?: Date;
  isTranslationRequired?: boolean;
  status: string;
  clientId: string;
  stateId: string;
  locationId: string;
  branch: string;
  noticeTypeid: number;
  noticeNumber: string;
  receiptMode: string;
  risk: string;
}

export interface ExtensionDetailsDto {
  clientId: string;
  extensionDraftExtensionDate: Date | null;
  extensionAppliedOn: Date | null; 
  isExtensionRequired?: boolean;
  status: string;
  stateId: string;
  locationId: string;
  branch: string;
  noticeNumber: string;
  draftExtensionApplicationfile: File | null; 
}
@Injectable({
  providedIn: 'root'
})
export class NoticesServiceService {
  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getPendingAssignmentCount(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Notices/PendingAssignmentCount`;
    return this.http.get(url,{observe:'response'});
  }
  getPendingActionCount(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Notices/PendingActionCount`;
    return this.http.get(url,{observe:'response'});
  }
  getOverdueCount(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Notices/OverdueCount`;
    return this.http.get(url,{observe:'response'});
  }
  getClosedCount(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Notices/ClosedCount`;
    return this.http.get(url,{observe:'response'});
  }
  getPendingExtensionCount(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Notices/PendingExtensionCount`;
    return this.http.get(url,{observe:'response'});
  }
  getResponsePendingCount(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Notices/ResponsePendingCount`;
    return this.http.get(url,{observe:'response'});
  }
  getNoticeResponseSubmittedCount(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Notices/NoticeResponseSubmittedCount`;
    return this.http.get(url,{observe:'response'});
  }
  getTranslationPendingCount(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Notices/TranslationPendingCount`;
    return this.http.get(url,{observe:'response'});
  }
  getExtensionSubmitionCount(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Notices/ExtensionSubmitionCount`;
    return this.http.get(url,{observe:'response'});
  }
  getSubmissionPendingCount(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Notices/SubmissionPendingCount`;
    return this.http.get(url,{observe:'response'});
  }
  GetRole(UserId:number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Notices/GetRole?UserId=${UserId}`;
    return this.http.get(url,{observe:'response'});
  }
  getNoticeDetails(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Notices/NoticeDetails`;
    return this.http.get(url,{observe:'response'});
  }
  GetNoticeData(Noticenumber:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Notices/GetNoticeData?Noticenumber=${Noticenumber}`;
    return this.http.get(url,{observe:'response'});
  }
  GetNoticeState(clientId:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Notices/GetNoticeState?clientId=${clientId}`;
    return this.http.get(url,{observe:'response'});
  }
  GetNoticeClientList(UserId:number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Notices/GetNoticeClientList?UserId=${UserId}`;
    return this.http.get(url,{observe:'response'});
  }
  getNoticeTypeList(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Notices/NoticeTypeList`;
    return this.http.get(url,{observe:'response'});
  }
  getSMETeamList(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Notices/SMETeamList`;
    return this.http.get(url,{observe:'response'});
  }
  getSDExecuterTeamList(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Notices/SDExecuterTeamList`;
    return this.http.get(url,{observe:'response'});
  }
  getGetNoticeLocation(State_code:string,Clientid:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Notices/GetNoticeLocation?Status=A&State_code=${State_code}&Clientid=${Clientid}`;
    return this.http.get(url,{observe:'response'});
  }
  GetNoticeBranchForClient(ClientID:string,State_code:string,Location_Code:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Notices/GetNoticeBranchForClient?ClientID=${ClientID}&State_code=${State_code}&Location_Code=${Location_Code}`;
    return this.http.get(url,{observe:'response'});
  }
  getGetNoticeRisk(NoticeTypeid:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Notices/GetNoticeRisk?NoticeTypeid=${NoticeTypeid}`;
    return this.http.get(url,{observe:'response'});
  }
  SaveNoticeDetails(value:any): Observable<HttpResponse<any>> {
    const body={    
      translationRequestDate: value.translationRequestDate,
      isTranslationRequired: value.isTranslationRequired,
      sme: value.sme,
      clientId:value.clientId,
      sdExecuter: value.sdExecuter,
      status: value.status,
      stateId: value.stateId,
      locationId: value.locationId,
      branch: value.branch,
      noticeNumber: value.noticeNumber,
    }
    const url = `${this.apiUrl}/api/Notices/SaveNoticeDetails`;
    return this.http.post(url,body,{observe:'response'});
  }
  UpdateNotice(value:any): Observable<HttpResponse<any>> {
    const body={  
      clientId: value.clientId,
      status: value.status,
      stateId: value.stateId,
      locationId: value.locationId,
      branch: value.branch,
      noticeNumber: value.noticeNumber,
      receiptMode: value.receiptMode,
      risk: value.risk,
      responseDueDate: value.responseDueDate,
      noticeReceivedDate: value.noticeReceivedDate,
      noticeTypeid: value.noticeTypeid,
      remarks: value.remarks 
    }
    const url = `${this.apiUrl}/api/Notices/UpdateNotice`;
    return this.http.post(url,body,{observe:'response'});
  }
 
  submitTranslationDetails(translationDetails: TranslationDetailsDto): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Notices/SubmitTranslationDetails`;
    return this.http.post(url,translationDetails,{observe:'response'});
  }
  submitExtensionDetails(extensionDetails: ExtensionDetailsDto): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Notices/SubmitExtensionDetails`;
    const formData: FormData = new FormData();
    formData.append('ClientId', extensionDetails.clientId);    
  if (extensionDetails.extensionDraftExtensionDate) {
    formData.append('ExtensionDraftExtensionDate', extensionDetails.extensionDraftExtensionDate.toISOString());
  }
  if (extensionDetails.extensionAppliedOn) {
    formData.append('ExtensionAppliedOn', extensionDetails.extensionAppliedOn.toISOString());
  }
    formData.append('IsExtensionRequired', extensionDetails.isExtensionRequired ? 'true' : 'false');
    formData.append('Status', extensionDetails.status);
    formData.append('StateId', extensionDetails.stateId);
    formData.append('LocationId', extensionDetails.locationId);
    formData.append('Branch', extensionDetails.branch);
    formData.append('NoticeNumber', extensionDetails.noticeNumber);    

    if (extensionDetails.draftExtensionApplicationfile) {
      formData.append('DraftExtensionApplicationfile', extensionDetails.draftExtensionApplicationfile, extensionDetails.draftExtensionApplicationfile.name);
    }
    return this.http.post(url,formData,{observe:'response'});
  }
  NoticeDocumentsDetails(NoticeTypeId:number,NoticeNumber:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Notices/NoticeDocumentsDetails?NoticeTypeId=${NoticeTypeId}&NoticeNumber=${NoticeNumber}`;
    return this.http.get(url,{observe:'response'});
  }

  saveNoticeResponseBySME(data: any, file: File): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Notices/SaveNoticeResponseBySME`;
    const formData: FormData = new FormData();  
    formData.append('Status', data.Status);
    formData.append('NoticeDraftResponseDate', data.NoticeDraftResponseDate);
    formData.append('ClientId', data.ClientId);
    formData.append('StateId', data.StateId);
    formData.append('Branch', data.Branch);
    formData.append('LocationId', data.LocationId);
    formData.append('NoticeNumber', data.NoticeNumber);
    if (file) {
      formData.append('NoticeDraftResponseFile', file, file.name);
    }
    return this.http.post<any>(url, formData,{observe:'response'});
  }
  saveSDTranslationDocument(data: any, file: File): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Notices/SaveTranslationDocument`; 
    const formData: FormData = new FormData();
    formData.append('TranslationDocumentUploadDate', data.TranslationDocumentUploadDate);
    formData.append('TranslationRequestDate', data.TranslationRequestDate);
    formData.append('IsTranslationRequired', data.IsTranslationRequired);
    formData.append('Status', data.Status);
    formData.append('ClientId', data.ClientId);
    formData.append('StateId', data.StateId);
    formData.append('LocationId', data.LocationId);
    formData.append('Branch', data.Branch);
    formData.append('NoticeNumber', data.NoticeNumber);
    if (file) {
      formData.append('TranslationDocumentFile', file, file.name);
    }
    return this.http.post<any>(url, formData, { observe: 'response' });
  }

  saveSDExtensionDocument(data: any, file: File): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Notices/SaveExtensionDocument`;
    const formData: FormData = new FormData();
    formData.append('IsExtensionRequired', data.IsExtensionRequired);
    formData.append('ExtensionGrantedDate', data.ExtensionGrantedDate.toString());
    formData.append('ExtensionApplicationDate', data.ExtensionApplicationDate.toString());
    formData.append('Status', data.Status);
    formData.append('ClientId', data.ClientId);
    formData.append('StateId', data.StateId);
    formData.append('LocationId', data.LocationId);
    formData.append('Branch', data.Branch);
    formData.append('NoticeNumber', data.NoticeNumber);
    if (file) {
      formData.append('ExtensionAcknowledgementDocumentFile', file, file.name);
    }
    return this.http.post<any>(url, formData, { observe: 'response' });
  }
 saveSDNoticeResponse(data: any, file: File): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Notices/SaveNoticeResponse`;
    const formData: FormData = new FormData();
    formData.append('NoticeResponseSubmittedToDept', data.NoticeResponseSubmittedToDept);
    formData.append('Status', data.Status);
    formData.append('ClientId', data.ClientId);
    formData.append('StateId', data.StateId);
    formData.append('LocationId', data.LocationId);
    formData.append('Branch', data.Branch);
    formData.append('NoticeNumber', data.NoticeNumber);
    if (file) {
      formData.append('NoticeResponseAcknowledgementFile', file, file.name);
    }
    return this.http.post<any>(url, formData, { observe: 'response' });
  }
  GetfilePath(Act:string,DocumentName:string,NoticeTypeId:number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Notices/GetfilePath?Act=${Act}&DocumentName=${DocumentName}&NoticeTypeId=${NoticeTypeId}`;
    return this.http.get(url,{observe:'response'});
  }
  MailSentAfterNoticeAssignment(NoticeNumber:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Notices/MailSentAfterNoticeAssignment?NoticeNumber=${NoticeNumber}`;
    return this.http.get(url,{observe:'response'});
  }
  SaveMasterDocument(noticeDocumentList: any[]): Observable<HttpResponse<any>> {
    if (!Array.isArray(noticeDocumentList)) {
        console.error('Expected noticeDocumentList to be an array, but received:', noticeDocumentList);
        throw new Error('noticeDocumentList must be an array');
    }

    const formData = new FormData();
  
    noticeDocumentList.forEach((item, index) => {
        if (item.upload) {
            formData.append('masterFiles', item.upload, item.upload.name);
        }else{
          formData.append(`masterFiles[${index}]`, '');
        }
  
        const clonedItem = { ...item };
        delete clonedItem.upload; 
 
        formData.append(`NoticeDocumentList[${index}]`, JSON.stringify(clonedItem));
      });
      console.log(formData);
  
    const url = `${this.apiUrl}/api/Notices/SaveMasterDocument`;
    return this.http.post(url, formData, { observe: 'response' });
}

}





