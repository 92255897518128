import * as XLSX from 'xlsx';
import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { GridModule } from '@progress/kendo-angular-grid';
import { PremiseMasterService } from '../../../service/premise-master.service';
import { UserService } from '../../../service/user.service';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-premise-bulk-upload',
  standalone: true,
  imports: [DialogModule, GridModule, FormsModule, AlertPopupComponent, NgIf],
  templateUrl: './premise-bulk-upload.component.html',
  styleUrl: './premise-bulk-upload.component.css'
})
export class PremiseBulkUploadComponent {
  @Output() close = new EventEmitter<void>();
  selectedFile?: File;
  dropzoneMessage: string = "Drag your files here or ";
  public gridData: any[] = [];
  allSelected: boolean = false;
  selectedItems: any[] = [];
  public headerTrackerId!: any ;
  alertData = { alertMessage: '', alertVisible: '' }
  constructor(private premiseService: PremiseMasterService, private user: UserService) { }

  @ViewChild('dialogTitle') dialogTitle!: ElementRef;
  ngAfterViewInit() {
    const titleElement = this.dialogTitle.nativeElement;
    titleElement.style.textAlign = 'center';
    titleElement.style.width = '100%';
  }

  ngOnInit(): void {
    this.loadGridData();
    this.headerTrackerId=sessionStorage.getItem('headerTrackerPremiseMaster')
  }

  public onFileSelect(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
      this.selectedFile = input.files[0];
      console.log('Selected file:', this.selectedFile);
      const fileExtension = this.selectedFile.name.split('.').pop()?.toLowerCase();
      if (fileExtension !== 'xlsx') {
        this.selectedFile = undefined;
        this.alertData = {
          alertMessage: 'Please upload XLSX file only.',
          alertVisible: 'error'
        };
      }
    }
  }

  public onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drop the file here";
  }

  public onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drag your files here or ";
  }

  public onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      this.selectedFile = event.dataTransfer.files[0];
      event.dataTransfer.clearData();
    }
  }


  loadGridData() {
    this.selectedItems = [];
    const custId = this.user.getCustomerId();
    console.log('custid',custId);
    this.premiseService.getSelectUploadGrid(custId).subscribe({
      next: (response) => {
        console.log('response', response);

        const data = response.body
        console.log('griddata',data);
        if (data && Array.isArray(data)) {
          this.gridData = data.map((item: any) => {
            const isDefaultSelected = item.colName === 'CM_ClientID' || item.colName === 'AVACOM_BranchName';

            if (isDefaultSelected) {
              item.selected = true;
              item.disabled = true;
              this.selectedItems.push(item);
            } else if (item.selected) {
              this.selectedItems.push(item);
            }
            return {
              ...item,
              selected: item.selected || isDefaultSelected,
              disabled: item.disabled || isDefaultSelected
            };
          });
        }
      }
    })
  }

  onItemSelectionChange(dataItem: any) {
    if (dataItem.selected) {

      if (!this.selectedItems.includes(dataItem)) {
        this.selectedItems.push(dataItem);
      }
    } else {

      this.selectedItems = this.selectedItems.filter(item => item.colName !== dataItem.colName);
    }

    console.log('Selected Items:', this.selectedItems);
  }

  toggleSelectAll(event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    // this.gridData.forEach(item => item.selected = checked);
    this.gridData.forEach(item => {
      if (checked) {
        item.selected = true;
      } else {
        if (item.colName !== 'CM_ClientID' && item.colName !== 'AVACOM_BranchName') {
          item.selected = false;
        }
      }
    });
  }

  downloadTemplate() {
    const defaultColumns = ['CM_ClientID', 'AVACOM_BranchName'];

    const onlyDefaultColumnsSelected = this.selectedItems.length > 0 &&
      this.selectedItems.every(item => defaultColumns.includes(item.colName));

    const selectedItems = onlyDefaultColumnsSelected
      ? this.gridData.map(item => item)
      : this.selectedItems;

    const columnsToInclude: string[] = [];
    const headersToInclude: string[] = [];


    for (let i = 0; i < selectedItems.length; i++) {
      const item = selectedItems[i];
      columnsToInclude.push(item.colName.toLowerCase());
      headersToInclude.push(item.clientHeaderName.toLowerCase());
    }

    const columnList = columnsToInclude.join(',');
    const headerList = headersToInclude.join(',');
    debugger;
    this.premiseService.insertDynamicUploadHeaders(columnList, headerList).subscribe({
      next: (response) => {
        const data = response.body
        this.headerTrackerId = data.result;
        sessionStorage.setItem('headerTrackerPremiseMaster', data.result);
        console.log('Header Tracker ID:', this.headerTrackerId);

        const workbook = XLSX.utils.book_new();
        const worksheetData = [headersToInclude];
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);


        worksheet['!cols'] = headersToInclude.map((col) => {
          return { wch: col.length };
        });

        XLSX.utils.book_append_sheet(workbook, worksheet, 'LocationSampleUpdate');

        const timestamp = new Date().toISOString().replace(/[-:.]/g, '');
        const clientID = this.user.getClientId();
        const filename = `LocationSampleUpdate_${clientID}_${timestamp}.xlsx`;

        XLSX.writeFile(workbook, filename);

      }
    })
  }

  closeAlert() {
    if (this.alertData.alertVisible == 'success') {
      this.closePopup();
    }
    this.alertData = { alertMessage: '', alertVisible: '' };
  }

  public closePopup(): void {
    this.close.emit();
  }

  resetData(): void {

    this.gridData.forEach(item => {
      if (!item.disabled) {
        item.selected = false;
      }
    });


    if (this.allSelected) {
      this.allSelected = false;
    }
  }

  public uploadFiles(): void {
    if (!this.selectedFile) {
      console.error('You have not chosen any file.');
      this.alertData = {
        alertMessage: 'You have not chosen any file.',
        alertVisible: 'error'
      };
      return;
    }
  
    if (!this.headerTrackerId ) {
      this.selectedFile = undefined;
      console.error('Header Tracker ID is missing.');
      this.alertData = {
        alertMessage: 'Please download the template first.',
        alertVisible: 'error'
      };
      return;
    }
  
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target?.result as ArrayBuffer;
  
      const workbook = XLSX.read(data, { type: 'array' });
      const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
  
      const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 }) as unknown[];
  
      if (jsonData.length === 0 || (Array.isArray(jsonData[0]) && jsonData[0].length === 0)) {
        this.selectedFile = undefined;
        console.error('Empty file uploaded!');
        this.alertData = {
          alertMessage: 'Empty file uploaded!',
          alertVisible: 'error'
        };
        return; 
      }
  
      if (!this.selectedFile) {
        console.error('Selected file has been cleared or is undefined before upload.');
        return;
      }
  
      const entityID = this.user.getClientId();
      const custId = this.user.getCustomerId();
      const userId = this.user.getUserId();
  
      // Proceed with the upload
      this.premiseService.premiseBulkUpload(entityID, this.selectedFile, this.headerTrackerId, custId, userId)
        .subscribe({
          next: (response) => {
            console.log('upload response', response);
            const data = response.body;
            if (data.status === 'Invalid headers') {
              this.selectedFile = undefined;
              console.error('Invalid headers in the response:', response);
              this.alertData = { alertMessage: 'Invalid Template.', alertVisible: 'error' };
            } else if (response.status === 200 && data.status === 'error') {
              this.selectedFile = undefined;
              this.downloadErrorFile(response.body.filePath, response?.body.sasToken);
              this.alertData = {
                alertMessage: 'Invalid Template.',
                alertVisible: 'error'
              };
            } else {
              console.log('Upload response:', response);
              this.alertData = { alertMessage: 'File uploaded successfully', alertVisible: 'success' };
            }
          },
          error: (error) => {
            this.selectedFile = undefined;
            console.error('Error uploading file:', error);
            this.alertData = { alertMessage: 'File upload failed.', alertVisible: 'error' };
          }
        });
    };
  
    reader.readAsArrayBuffer(this.selectedFile); // Read the file as an ArrayBuffer
  }

  downloadErrorFile(FilePath: string, sasToken: string) {
    const blobUrl = `${FilePath}?${sasToken}`;
    const a = document.createElement('a');
    a.href = blobUrl;
    const filename = FilePath.split('/').pop() || 'downloaded_file';
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
