import { Component, OnInit } from '@angular/core';
import { EmployeeCTCHistoryComponent } from '../../popups/employee-ctchistory/employee-ctchistory.component';
import { NgIf } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { EmployeeCTCService } from '../../../service/employee-ctc.service';
import { UserService } from '../../../service/user.service';
import { BlobService } from '../../../service/blob.service';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';

@Component({
  selector: 'app-employee-ctc',
  standalone: true,
  imports: [EmployeeCTCHistoryComponent, NgIf, ReactiveFormsModule, IndicatorsModule, AlertPopupComponent],
  templateUrl: './employee-ctc.component.html',
  styleUrl: './employee-ctc.component.css'
})
export class EmployeeCtcComponent implements OnInit {
  public alertData = { alertMessage: '', alertVisible: '' };
  EmployeeHistoryPopup = false;
  fileNames: { [key: string]: string | null } = {
    CTCTemplate: null
  };
  clientID!: string;
  selectedFile!: File|null;
  ErrorFile!: string;
  InputName!: string;
  customerID!: number;
  inputID!: number;
  userID!: number
  IsFileRequired!: number
  DisplayUploadFlag!: number
  IsUploaded!: number
  IsValidated!: number
  isProcessed!: number
  showState = false;
  loading: boolean = false;
  myGroup: FormGroup;
  constructor(private EmployeeCTCService: EmployeeCTCService, private userService: UserService, private blobService: BlobService) {
    this.myGroup = new FormGroup({
      CTCTemplate: new FormControl('')
    });
  }
  ngOnInit(): void {
    this.clientID = this.userService.getClientId();
    this.customerID = this.userService.getCustomerId();
    this.userID = this.userService.getUserId();
    this.getCTCUploadStatus();
  }
  onFileChange(event: any, type: string) {
    debugger;
    console.log(event.target.files);
    const file = event.target.files[0];
    console.log(file);
    const fileInput = event.target as HTMLInputElement;
    if (file) {
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      if (fileExtension === 'xlsx') {
        this.fileNames[type] = file.name;
        this.selectedFile = file;
      } else {
        this.fileNames[type] = '';
        this.selectedFile = null;
        this.alertData = { alertMessage: 'Please upload XLSX file only', alertVisible: 'error' };
      }
    } else {
      this.fileNames[type] = '';
      this.selectedFile = null;
      this.alertData = { alertMessage: 'Please upload XLSX file only', alertVisible: 'error' };
    }
    fileInput.value = '';
  }
  downlaodError(){
    this.loading=true;
    if(this.ErrorFile){
      const blobLink = this.ErrorFile;
      const blobName = this.blobService.extractBlobName(blobLink);
      setTimeout(() => {
        debugger
        this.blobService.getSasToken(blobName).subscribe(
          (tokenResponse: any) => {
            try {
              const sasToken = tokenResponse.result;
              this.blobService.downloadFile(blobName, sasToken);
              this.loading = false;
            } catch (error) {
              this.alertData={alertMessage:'File Not Found',alertVisible:'error'};
              this.loading = false;
            }
          },
          (error) => {
            console.log(error);
            this.alertData={alertMessage:'File Not Found',alertVisible:'error'};
            this.loading = false; 
          }
        );
      }, 5000);
    }else{
      this.alertData={alertMessage:'File Not Found',alertVisible:'error'};
      this.loading=false;
    }
  }
  Refresh(){
    this.getCTCUploadStatus();
  }
  getCTCUploadStatus() {
    this.EmployeeCTCService.getCTCFileUploadStatus(this.clientID).subscribe(
      {
        next: (response) => {
          console.log('stats',response);
          const data = response.body;
          this.InputName = data.inputList[0].inputName;
          this.IsFileRequired = data.inputList[0].isFileRequired;
          this.DisplayUploadFlag = data.inputList[0].displayUploadFlag;
          this.IsUploaded = data.inputList[0].isUploaded;
          this.IsValidated = data.inputList[0].isValidated;
          this.isProcessed = data.inputList[0].isProcessed;
          this.ErrorFile = data.inputList[0].errorPath;
        }, error: (error) => {
          console.error(error);
        }
      });
  }

  CTCReport() {
    this.loading = true;
    this.EmployeeCTCService.getCTCReportBlobLink(this.clientID).subscribe(
      {
        next: (response) => {
          const blobLink = response.body.filePath;
          const blobName = this.blobService.extractBlobName(blobLink);
          setTimeout(() => {
            this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
              debugger;
              const sasToken = tokenResponse.Result;
              this.blobService.downloadFile(blobName, sasToken);
              this.loading = false;
            });
          }, 5000);
        }, error: (error) => {
          console.error('Error fetching blob link:', error);
          this.alertData = { alertMessage: error.error.result, alertVisible: 'error' };
          this.showState = false;
          this.loading = false;
        }
      });
  }
  CTCTemplate() {
    this.loading = true;
    this.EmployeeCTCService.getCTCTemplateBlobLink(this.customerID, this.clientID).subscribe(
      {
        next: (response) => {
          console.log(response);
          const blobLink = response.body.filePath;
          const blobName = this.blobService.extractBlobName(blobLink);
          setTimeout(() => {
            this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
              debugger;
              const sasToken = tokenResponse.result;
              this.blobService.downloadFile(blobName, sasToken);
              this.loading = false;
            });
          }, 5000);
        }, error: (error) => {
          console.error('Error fetching blob link:', error);
          this.alertData = { alertMessage: error.error, alertVisible: 'error' };
          this.showState = false;
          this.loading = false;
        }
      });
  }

  openHistoryPopup() {
    this.EmployeeHistoryPopup = true;
  }

  uploadFiles() {
    this.loading=true;
    if (this.fileNames['CTCTemplate']) {
      const file = this.selectedFile;
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('clientID', this.clientID);
        formData.append('InputId', '55');
        formData.append('CustId', this.customerID.toString());
        formData.append('UserID', this.userID.toString());

        this.EmployeeCTCService.uploadFile(formData).subscribe(
          {
            next: (response) => {
              console.log('upload',response);
              if (response.status == 200 && response.body.status=='success')
              {
                this.fileNames['CTCTemplate']='';
                this.alertData = { alertMessage: 'File uploaded successfully', alertVisible: 'success' };
                this.loading=false;
              } else {
                this.fileNames['CTCTemplate']='';
                this.selectedFile=null;
                this.alertData = { alertMessage: response.body.status, alertVisible: 'error' };
                this.loading=false;
              }
            }, error: (error) => {
              console.error('Error uploading file:', error);
              this.alertData = { alertMessage: 'Error uploading file', alertVisible: 'error' };
              this.loading=false;
              this.selectedFile=null;
              this.fileNames['CTCTemplate']='';
            }
          }
        );
        setTimeout(() => {
          this.getCTCUploadStatus();
        }, 5000);
      } else {
        this.selectedFile=null;
        this.fileNames['CTCTemplate']='';
        this.alertData = { alertMessage: 'Please select file to upload', alertVisible: 'error' };
        this.loading=false;
      }
    } else {
      this.alertData = { alertMessage: 'Please select file to upload', alertVisible: 'error' };
      this.loading=false;
    }
    this.fileNames['CTCTemplate'] = '';
    this.selectedFile=null;
  }

  closeHistoryPopup() {
    this.EmployeeHistoryPopup = false;
  }
  closeAlert() {
    this.getCTCUploadStatus();
    this.alertData = { alertMessage: '', alertVisible: '' };
  }
}
