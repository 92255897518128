import { Component, EventEmitter, Input, Output } from '@angular/core';

import { NgFor, NgIf } from '@angular/common';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { PoDetailsService } from '../../../service/po-details.service';


import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { UserService } from '../../../service/user.service';
import { AddEditPODetailsPopupComponent } from '../../popups/add-edit-podetails-popup/add-edit-podetails-popup.component';

@Component({
  selector: 'app-po-details',
  standalone: true,
  imports: [IndicatorsModule,DropdownListComponent,NgIf,NgFor,AddEditPODetailsPopupComponent,AlertPopupComponent],
  templateUrl: './po-details.component.html',
  styleUrl: './po-details.component.css'
})
export class PoDetailsComponent {
  saveOpen=false;
  clientID!: string;
myGroup: any;
inputListData: any;
public isAddNewVisible: boolean = false;
IsUpdate: boolean = false;
public rowData!: string[]; alertData = { alertMessage: '', alertVisible: '' }
@Output() close = new EventEmitter<void>();
deleteId = 0;
@Input() customStyle: any;
// GetClientpODetail:any;
GetClientpODetail: any;
  alertVisible: boolean=false;


  constructor(private PoDetailsService:PoDetailsService,private user:UserService,){}

  ngOnInit(): void {
   this.getClientsMaster();
  

   
  }


  
  ClientDropdownData: any[] = [];
selectedClient = { text: 'Select Client', value: '' };

onDropdownValueChange(data: any) {
    
  // Update selectedAct with selected dropdown value and ensure both text and value are set
 console.log('data',data)
  this.clientID = data?.value;
  console.log('client', this.clientID)
  this.GetPOListDetail(this.clientID);
}


getClientsMaster() {
    this.PoDetailsService.getClientsMaster().subscribe({
      next: (response) => {
        const data = response.body;
        this.ClientDropdownData = [
          { text: 'Select Client', value: '' },
          ...data.map((item: any) => ({
            text: item.text,
            value: item.value
          }))
        ];
      },
      error: (error) => {
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }






  handleClosePopup() {
    this.isAddNewVisible = false;
  //  this.GetEcrMasterGrid();
  }
  openAddNewPopup() {//subcode
    this.isAddNewVisible = true;
    this.rowData=this.GetClientpODetail
    // this.password = '';
    this.IsUpdate=false;
  
  
  }
  update(){
    this.rowData=this.GetClientpODetail
    this.isAddNewVisible = true;
    console.log('this.rowdata',this.rowData);
  }

  public closePopup(): void {
    this.alertData = { alertMessage: '', alertVisible: '' };
    this.close.emit();
  }

  markActive(){
    console.log("check event");
    this.alertData = { alertMessage: 'Are you Sure you want to mark Purchase Order as Inactive?', alertVisible: 'confirm' };
  }
  fun(){
    console.log('cgfhgfhjh')
  }

  onActionClick() {
   
    console.log("check event");
    
      // this.deleteId = event.dataItem.id;
      this.alertData = { alertMessage: 'Are you Sure you want to mark Purchase Order as Inactive?', alertVisible: 'confirm' };
      // this.showAlert();
  }
  showAlert() {

    this.alertVisible = true; 
}
  
  GetMarkAsInActives(value:any) {

    console.log('con',value)
    console.log('fdfdf',this.clientID)
    this.alertData = { alertMessage: '', alertVisible: '' };
    let payload={
      PO_Id: this.GetClientpODetail.pO_Id ? this.GetClientpODetail.pO_Id : '0',
      UserId:this.user.getUserId()
    }
  
    this.PoDetailsService.GetMarkAsInActive(payload).subscribe(
      (response) => {
        this.getClientsMaster();
        this.alertData = { alertMessage: 'Marked inActive Successfully', alertVisible: 'success' };
     
        this.GetPOListDetail(value);
      },
      (error) => {
        this.alertData = { alertMessage: error.error.Result, alertVisible: 'error' };
      });  
  }

 
  GetPOListDetail(clientID:any) {
    
   let payload={
     ClientId:clientID,
   }
       this.PoDetailsService.GetPOListDetails(payload).subscribe({
         next: (response) => {

           const data = response.body;
           this.GetClientpODetail= data[0];
          
           console.log("GetClientpODetail",this.GetClientpODetail);
           
         },
         error: (error) => {
           console.error('Error fetching Branch Dropdown data', error);
         }
       });
     }
  
}
