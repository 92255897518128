<kendo-dialog #dialogRef title="Add New MWMaster" (close)="closePopup()" [minWidth]="1000" [width]="700" 
    class="custom-dialog  dialog-content">
    <div class="row" style="margin-top: 15px;">
        <div class="col-md-6 inline-flex-container">
            <label class="f-label">
                State<sub style="color: red; font-size: 15px;">*</sub>
            </label>
            <div class="require-container">
                <app-combo-box [width]="'250px'" [Placeholder]="'Search State/Select State'"
                    (valueChange)="StateValueChange($event)" [data]="stateDropdownData"
                    [class.input-invalid]="!selectedState && showValidationErrors">
                </app-combo-box>
            </div>
        </div>
        <div class="col-md-6 inline-flex-container">
            <label class="f-label">
                State Notification Id
            </label>
            <div class="require-container">
                <app-dropdown-list [data]="StateNotificationDropdownData"
                    [selectedValue]="StateNotificationDefaultValue" (valueChange)="StateNotificationValueChange($event)"
                    [width]="'250px'" textField="id" valueField="name"
                    [hasError]="!selectedStateNotificationID && showValidationErrors">
                </app-dropdown-list>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 inline-flex-container ">
            <label class="f-label">
                Establishment Type<sub style="color: red; font-size: 15px;">*</sub>
            </label>
            <div class="require-container">
                <app-dropdown-list [data]="EstablishDropdownData" [selectedValue]="EstablishmentDefaultValue"
                    (valueChange)="EstablishValueChange($event)" [width]="'250px'" textField="id" valueField="name"
                    [hasError]="!selectedEstablishment && showValidationErrors">
                </app-dropdown-list>
            </div>
        </div>
        <div class="col-md-6 inline-flex-container" *ngIf="industryType">
            <label class="f-label">
                Industry Type<sub style="color: red; font-size: 15px;">*</sub>
            </label>
            <div class="require-container">
                <app-dropdown-list [data]="IndustryTypeDropdownData" [selectedValue]="IndustryTypeDefaultValue"
                    (valueChange)="IndustryChange($event)" [width]="'250px'" textField="id" valueField="name"
                    [hasError]="!selectedIndustryType && showValidationErrors">
                </app-dropdown-list>
            </div>
        </div>
        <div class="col-md-6 inline-flex-container" *ngIf="industryType">
            <label class="f-label">
                Notification ID
            </label>
            <app-dropdown-list [data]="NotificationIDDropdownData" [selectedValue]="NotificationIDDefaultValue"
                (valueChange)="NotificationIDChange($event)" [width]="'250px'" textField="id" valueField="name">
            </app-dropdown-list>
        </div>
        <div class="col-md-6 inline-flex-container">
            <label class="f-label">
                Skill Category<sub style="color: red; font-size: 15px;">*</sub>
            </label>
            <div class="require-container">
                <app-dropdown-list [data]="SkillCategoryDropdownData" [selectedValue]="SkillDefaultValue"
                    (valueChange)="SkillCategoryValueChange($event)" [width]="'250px'" textField="id" valueField="name"
                    [hasError]="!selectedSkills && showValidationErrors">
                </app-dropdown-list>
            </div>

        </div>
    </div>
    <div class="row colpadding0">
        <div class="col-md-6 inline-flex-container">
            <label class="f-label">
                Designation<sub style="color: red; font-size: 15px;">*</sub>
            </label>
            <div class="require-container">
                <app-dropdown-list [data]="DesignationDropdownData" [selectedValue]="DesignationDefaultValue"
                    (valueChange)="DesignationValueChange($event)" [width]="'250px'" textField="id" valueField="name"
                    [hasError]="!selectedDesignation && showValidationErrors">
                </app-dropdown-list>
            </div>
        </div>
        <div class="col-md-6 inline-flex-container">
            <label class="f-label">
                Zone
            </label>
            <div class="require-container">
                <app-dropdown-list [data]="ZoneDropdownData" [selectedValue]="ZoneDefaultValue"
                    (valueChange)="ZoneValueChange($event)" [width]="'250px'" textField="id" valueField="name">
                </app-dropdown-list>
            </div>
        </div>
    </div>
    <div class="row" [formGroup]="form">
        <div class="col-md-6 inline-flex-container">
            <label class="f-label">
                Components<sub style="color: red; font-size: 15px;">*</sub>
            </label>
            <div class="require-container">
                <app-multi-select-dropdown [data]="ComponentDropdownData" [width]="'250px'"
                    (valueChange)="onZoneSelectionChange($event)" [textField]="'name'" [valueField]="'id'"
                    [ngClass]="{'input-invalid': showValidationErrors && (!selectedComponents || selectedComponents.length === 0)}">
                </app-multi-select-dropdown>
            </div>
        </div>
        <div class="col-md-6 inline-flex-container" *ngIf="isComponentSelected('Basic')">
            <label class="f-label">
                Basic
            </label>
            <div class="require-container">
                <input type="number" placeholder="Basic" class="f-textbox" style="width: 250px; "
                    formControlName="basic" [ngClass]="{
                'is-invalid': form.get('basic')?.invalid && 
                (form.get('basic')?.touched || showValidationErrors) }">
                <div *ngIf="form.get('basic')?.invalid && form.get('basic')?.touched" class="text-danger">Please enter a
                    valid number</div>
            </div>
        </div>
        <div class="col-md-6 inline-flex-container" *ngIf="isComponentSelected('DA')">
            <label class="f-label">
                DA
            </label>
            <div class="require-container">
                <input type="number" placeholder="DA" class="f-textbox" style="width: 250px;" formControlName="da">
                <div *ngIf="form.get('da')?.invalid && form.get('da')?.touched" class="text-danger">Please enter a valid
                    number</div>
            </div>
        </div>
        <div class="col-md-6 inline-flex-container" *ngIf="isComponentSelected('HRA')">
            <label class="f-label">
                HRA
            </label>
            <div class="require-container">
                <input type="number" placeholder="HRA" class="f-textbox" style="width: 250px;" formControlName="hra">
                <div *ngIf="form.get('hra')?.invalid && form.get('hra')?.touched" class="text-danger">Please enter a
                    valid
                    number</div>
            </div>
        </div>
        <div class="col-md-6 inline-flex-container" *ngIf="isComponentSelected('VDA')">
            <label class="f-label">
                VDA
            </label>
            <div class="require-container">
                <input type="number" placeholder="VDA" class="f-textbox" style="width: 250px; " formControlName="vda">
                <div *ngIf="form.get('vda')?.invalid && form.get('vda')?.touched" class="text-danger">Please enter a
                    valid
                    number</div>
            </div>
        </div>
        <div class="col-md-6 inline-flex-container" *ngIf="isComponentSelected('Special Allowance')">
            <label class="f-label">
                Special Allowance
            </label>
            <div class="require-container">

                <input type="number" placeholder="Special Allowance" class="f-textbox" style="width: 250px; "
                    formControlName="specialAllowance">
                <div *ngIf="form.get('specialAllowance')?.invalid && form.get('specialAllowance')?.touched"
                    class="text-danger">Please enter a valid number</div>
            </div>
        </div>
    </div>

    <kendo-dialog-actions style="border: none;">
        <button kendoButton class="figma-btn-blue figma-popup" (click)="OnSave()">Save</button>
    </kendo-dialog-actions>
</kendo-dialog>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270" [Height]="270" [Data]="alertData"
    (close)="closeAlert()"></app-alert-popup>