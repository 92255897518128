import { NgIf } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';

@Component({
  selector: 'app-workspace-remanual-upload-popup',
  standalone: true,
  imports: [DialogsModule,NgIf,AlertPopupComponent],
  templateUrl: './workspace-remanual-upload-popup.component.html',
  styleUrl: './workspace-remanual-upload-popup.component.css'
})
export class WorkspaceRemanualUploadPopupComponent {
  @Output() close = new EventEmitter<void>();
  @Output() fileUploaded: EventEmitter<File> = new EventEmitter<File>();
  selectedFile?: File;
  dropzoneMessage: string = "Drag your files here or ";
  alertData = { alertMessage: '', alertVisible: '' };

  public onFileSelect(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
      this.selectedFile = input.files[0];
      console.log('Selected file:', this.selectedFile);
    }
  }

  public onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drop your file here or";
  }

  public onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drag your files here or ";
  }

  public onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      this.selectedFile = event.dataTransfer.files[0];
      event.dataTransfer.clearData();
    }
  }
  uploadFile(){
    if (this.selectedFile) {
      // Emit the file to the parent component
      this.fileUploaded.emit(this.selectedFile);
      this.closePopup();  // Optionally close the popup after upload
    }
  }


  public closePopup(): void {
    this.close.emit();
  }
}
