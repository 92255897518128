import { Component ,Output,EventEmitter,Input,OnInit} from '@angular/core';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { InputFilesService } from '../../../service/input-files.service';
import { UserService } from '../../../service/user.service';
import { environment } from '../../../environments/environment';
import { BlobService } from '../../../service/blob.service';
@Component({
  selector: 'app-input-client-history-popup',
  standalone: true,
  imports: [DialogModule,GridActionItemComponent],
  templateUrl: './input-client-history-popup.component.html',
  styleUrl: './input-client-history-popup.component.css'
})
export class InputClientHistoryPopupComponent implements OnInit {
  @Output() close = new EventEmitter<void>();
  public gridData: GridDataResult = { data: [], total: 0 };
  @Input() id:any
  @Input() month:any
  @Input() year:any
  private blobUrl: string = environment.blobUrl;

  constructor(private inputService:InputFilesService,private user:UserService, private blobService: BlobService){}
  ngOnInit(): void {
    console.log('Id',this.id)
    console.log('month',this.month)
    console.log('year',this.year)
    this.getGridData()
  }
  getGridData(){
    const payload={
      UserId:this.user.getUserId(),
      EntityId:this.user.getClientId(),
      month:this.month,
      year:this.year,
      InputId:this.id
    }
    this.inputService.GetHistoryData(payload).subscribe({
      next:(response)=>{
        console.log('response',response)
        const data = response.body || [];
        this.gridData = {
          data: data,
          total: data.length
        };
      }
    })
  }

  public parentColumns: any[] = [
    {field: 'fileName',title: 'File Name'},
    { field: 'createdDate', title: 'Uploaded On' },
    { field: 'status', title: 'Status' },
    {
      field: 'Action',
      isIconColumn: true,
      title: 'Action',
      filteredIcons: [

        {
          url: '../assets/vectors/ExcelDownload.svg',
          alt: 'Download Icon',
          action: 'Download'
        }
      ]
    }
  ];

  onActionClick(value:any){
    console.log(value)
    debugger;
    const uploadedExcelFile = value.dataItem.uploadedExcelFile;    
      const blobLink = this.blobUrl + uploadedExcelFile;
      const blobName = this.blobService.extractBlobName(blobLink);
      this.blobService.getSasToken(blobName).subscribe(
        (tokenResponse: any) => {
          try {
            debugger;
            const sasToken = tokenResponse.result;
            this.blobService.downloadFile(blobName, sasToken);
          } catch (error) {
          }
        },
        (error) => { 
        }
      );
    }
  

  public closePopup(): void {
    this.close.emit();
  }   
}
