import { CommonModule, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UserService } from '../../../service/user.service';
import { NoticesServiceService, TranslationDetailsDto } from '../../../service/notices-service.service';
import { BlobService } from '../../../service/blob.service';
import { environment } from '../../../environments/environment';
import { KendoDatepickerComponent } from '../../../components/kendo-datepicker/kendo-datepicker.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';

@Component({
  selector: 'app-sdexecuter',
  standalone: true,
  imports: [NgIf, FormsModule, KendoDatepickerComponent, IndicatorsModule, AlertPopupComponent, CommonModule],
  templateUrl: './sdexecuter.component.html',
  styleUrl: './sdexecuter.component.css'
})
export class SDExecuterComponent {
  @Input() Data!: any;
  @Output() close = new EventEmitter<void>();
  showTranslation: boolean = false;
  showExtension: boolean = false;
  showNotice: boolean = false;
  TranslationRequired!: string
  TranslationRequestDate!: Date;
  formattedTranslationRequestDate!:string
  TranslatedDocumentUploadDate!: Date;
  formattedTranslatedDocumentUploadDate!:string
  extensionRequired!: string
  extensionDocumentDate!: string;
  applicationAcknowledgement!: string
  extendedDateGranted!: string
  extendedSubmittedDate: Date | null = null;
  noticeResponseDate: Date | null = null;
  extendedGrantedDate: Date | null = null;
  showValidationErrors: boolean = false;
  public placeholder: string = 'DD-MM-YYYY';
  noticeDocumentReceived!: string
  draftResponseFromSME!: string
  SMETeamdropdownData: any[] = [];
  status!: string
  clientId!: string
  stateId!: string
  locationId!: string
  branch!: string
  noticeTypeid!: number
  noticeNumber!: string
  receiptMode!: string
  risk!: string
  loading = false;
  role: string = ''
  fileNames: { [key: string]: string } = {
    translatedDocument: '',
    acknowledgmentDocument: '',
    noticeAcknowledgment: ''
  };
  selectedFiles: { [key: string]: File | null } = {
    translatedDocument: null,
    acknowledgmentDocument: null,
    noticeAcknowledgment: null
  };
  alertData = { alertMessage: '', alertVisible: '' }
  constructor(private user: UserService, private editNoticeService: NoticesServiceService, private blobService: BlobService) { }

  ngOnInit(): void {
    this.role = this.user.getRole()
    console.log(this.role);

    if (this.Data) {

      // this.getSMETeamList();
      this.GetNoticeData(this.Data.noticeReferenceNo);

    }
  }

  InitData(data: any) {
    console.log('initdata', data);

    if (data.translationDocument) {
      const filePath = data?.translationDocument;

    }
    if (data.extensionDraftExtensionDate) {
      const uploadDate = new Date(data.extensionDraftExtensionDate);

      this.extensionDocumentDate = uploadDate.toLocaleDateString('en-GB');
    } else {
      this.extensionDocumentDate = '';
    }
    this.status = data.status;
    this.clientId = data.clientId;
    this.stateId = data.state
    this.locationId = data.location
    this.branch = data.branch;
    this.noticeTypeid = data.noticeTypeId
    this.noticeNumber = this.Data.noticeReferenceNo
    this.receiptMode = data.modeOfReceipt
    this.risk = data.risk
    if (data.noticeReceivedDate) {
      const noticeDate = new Date(data.noticeReceivedDate);
      if (!isNaN(noticeDate.getTime())) {
        this.noticeDocumentReceived = noticeDate.toLocaleDateString('en-GB');
      } else {
        console.error("Invalid date format for noticeReceivedDate");
        this.noticeDocumentReceived = '';
      }
    } else {
      console.error("noticeReceivedDate is null or undefined");
      this.noticeDocumentReceived = '';
    }
    if (data.noticeDraftResponseDate) {
      const noticeDate = new Date(data.noticeDraftResponseDate);
      if (!isNaN(noticeDate.getTime())) {
        this.draftResponseFromSME = noticeDate.toLocaleDateString('en-GB');
      } else {
        console.error("Invalid date format for noticeReceivedDate");
        this.draftResponseFromSME = '';
      }
    } else {
      console.error("noticeReceivedDate is null or undefined");
      this.draftResponseFromSME = '';
    }

  }

  GetNoticeData(Noticenumber: string) {
    this.editNoticeService.GetNoticeData(Noticenumber).subscribe(
      {
        next: (response) => {
          const data = response.body;
          this.InitData(data[0])
        }, error: (error) => {
          console.error('Error fetching State Dropdown data', error);
        }
      });
  }

  toggle(dropdownType: any) {
    if (dropdownType === 'translation') {
      this.showTranslation = !this.showTranslation;
    }
    else if (dropdownType === 'extension') {
      this.showExtension = !this.showExtension;
    }
    else if (dropdownType === 'notice') {
      this.showNotice = !this.showNotice;
    }
  }

  onRadioChange(value: string, type: string) {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    if (value == 'Y' && type === 'translation') {
      this.TranslationRequired = 'true';
      this.formattedTranslationRequestDate = `${day}/${month}/${year}`;
      this.TranslationRequestDate=new Date()
    } else if (value == 'N' && type === 'translation') {
      this.TranslationRequired = 'false';
      this.formattedTranslationRequestDate = '';
    }
    if (value == 'Y' && type === 'extension') {
      this.extensionRequired = 'true';
    } else if (value == 'N' && type === 'extension') {
      this.extensionRequired = 'false';
    }
  }

  onDateChange(value: Date, type: string) {
    const date = new Date(value.toISOString().split('T')[0]);
    if (type === 'extendedSubmittedDate') {
      this.extendedSubmittedDate = date;
    }
    if (type === 'noticeResponseDate') {
      this.noticeResponseDate = date;
    }
  }

  downloadDocument() {
    this.loading = true;
    const filePath = this.Data.noticeDocument;
    console.log(filePath);
    if (filePath) {
      const apiUrl = environment.blobUrl;
      const blobLink = apiUrl + filePath;
      const blobName = this.blobService.extractBlobName(blobLink);
      setTimeout(() => {
        this.blobService.getSasToken(blobName).subscribe(
          (tokenResponse: any) => {
            try {
              const sasToken = tokenResponse.result;
              this.blobService.downloadFile(blobName, sasToken);
              this.loading = false

            } catch (error) {
              this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
              this.loading = false
            }
          },
          (error) => {
            this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
            this.loading = false
          }
        );
      }, 5000);
    } else {
      this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
      this.loading = false

    }
  }
  downloadExtensionApplication() {
    this.loading = true;
    const filePath = this.Data.draftExtensionApplication;
    console.log(filePath);
    if (filePath) {
      const apiUrl = environment.blobUrl;
      const blobLink = apiUrl + filePath;
      const blobName = this.blobService.extractBlobName(blobLink);
      setTimeout(() => {
        this.blobService.getSasToken(blobName).subscribe(
          (tokenResponse: any) => {
            try {
              const sasToken = tokenResponse.result;
              this.blobService.downloadFile(blobName, sasToken);
              this.loading = false

            } catch (error) {
              this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
              this.loading = false
            }
          },
          (error) => {
            this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
            this.loading = false
          }
        );
      }, 5000);
    } else {
      this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
      this.loading = false

    }
  }
  downloadDraftResponseFronSME() {
    this.loading = true
    const filePath = this.Data.noticeDraftResponse;
    console.log(filePath);
    if (filePath) {
      const apiUrl = environment.blobUrl;
      const blobLink = apiUrl + filePath;
      const blobName = this.blobService.extractBlobName(blobLink);
      setTimeout(() => {
        this.blobService.getSasToken(blobName).subscribe(
          (tokenResponse: any) => {
            try {
              const sasToken = tokenResponse.result;
              this.blobService.downloadFile(blobName, sasToken);
              this.loading = false

            } catch (error) {
              this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
              this.loading = false
            }
          },
          (error) => {
            this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
            this.loading = false
          }
        );
      }, 5000);
    } else {
      this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
      this.loading = false

    }
  }
  onFileChange(event: any, type: string) {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = [
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf'
      ];

      if (allowedTypes.includes(file.type)) {
        this.fileNames[type] = file.name;
        this.selectedFiles[type] = file;

        if (type === 'translatedDocument') {
          this.TranslatedDocumentUploadDate = new Date();
          this.formattedTranslatedDocumentUploadDate = this.TranslatedDocumentUploadDate.toLocaleDateString('en-GB');
        }
      } else {
        this.fileNames[type] = '';
        this.selectedFiles[type] = null;
        this.alertData = {
          alertMessage: 'Please select a .doc,.docx or .pdf file',
          alertVisible: 'error'
        };
      }
    } else {
      this.fileNames[type] = '';
      this.selectedFiles[type] = null;
    }

    console.log(this.fileNames);
  }

  saveTranslation() {
    this.loading = true
    const data = {
      TranslationDocumentUploadDate: this.formattedTranslatedDocumentUploadDate,
      TranslationRequestDate: this.formattedTranslationRequestDate,
      IsTranslationRequired: this.TranslationRequired,
      Status: this.status,
      ClientId: this.clientId,
      StateId: this.stateId,
      LocationId: this.locationId,
      Branch: this.branch,
      NoticeNumber: this.noticeNumber
    };
    console.log('save response', data);

    if (this.selectedFiles['translatedDocument']) {
      this.editNoticeService.saveSDTranslationDocument(data, this.selectedFiles['translatedDocument']).subscribe({
        next: (response) => {
          console.log(response);
          if (response.body.result === 1) {
            this.loading = false
            this.alertData = { alertMessage: 'Translation Submitted Successfully', alertVisible: 'success' };
          }
          else {
            this.loading = false
            this.alertData = { alertMessage: 'Submission Failed', alertVisible: 'error' };
          }
        }
      })
    }
  }

  saveExtension() {
    this.loading = true
    const formatDate = (date: Date | null): string | null => {
      if (!date) {
        return null;
      }
      const day = ('0' + date.getDate()).slice(-2);
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };

    const extendedGrantedDate = this.extendedGrantedDate ? formatDate(new Date()) : null;
    const extendedSubmittedDate = this.extendedSubmittedDate ? formatDate(new Date()) : null;

    const data = {
      IsExtensionRequired: this.extensionRequired,
      ExtensionGrantedDate: extendedGrantedDate,
      ExtensionApplicationDate: extendedSubmittedDate,
      Status: this.status,
      ClientId: this.clientId,
      StateId: this.stateId,
      LocationId: this.locationId,
      Branch: this.branch,
      NoticeNumber: this.noticeNumber
    };
    console.log('save response', data);
    console.log(this.selectedFiles['acknowledgmentDocument']);

    if (this.selectedFiles['acknowledgmentDocument']) {
      this.editNoticeService.saveSDExtensionDocument(data, this.selectedFiles['acknowledgmentDocument']).subscribe({
        next: (response) => {
          console.log(response);
          if (response.body.result === 1) {
            this.loading = false
            this.alertData = { alertMessage: 'Extension Submitted Successfully', alertVisible: 'success' };
          }
          else {
            this.loading = false
            this.alertData = { alertMessage: 'Submission Failed', alertVisible: 'error' };
          }
        }
      })
    } else {
      this.loading = false
      this.alertData = { alertMessage: 'No File Selected', alertVisible: 'error' };
    }
  }

  saveNotice() {
    this.loading = true
    const formatDate = (date: Date | null): string | null => {
      if (!date) {
        return null;
      }
      const day = ('0' + date.getDate()).slice(-2);
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };

    const noticeDate = this.noticeResponseDate ? formatDate(new Date(this.noticeResponseDate)) : null;
    debugger;
    const data = {
      NoticeResponseSubmittedToDept: noticeDate,
      Status: this.status,
      ClientId: this.clientId,
      StateId: this.stateId,
      LocationId: this.locationId,
      Branch: this.branch,
      NoticeNumber: this.noticeNumber,
    };

    console.log('save response', data);
    console.log(this.selectedFiles['noticeAcknowledgment']);
    if (this.selectedFiles['noticeAcknowledgment']) {
      this.editNoticeService.saveSDNoticeResponse(data, this.selectedFiles['noticeAcknowledgment']).subscribe({
        next: (response) => {
          console.log(response);
          if (response.body.result === 1) {
            this.loading = false
            this.alertData = { alertMessage: 'Notice Submitted Successfully', alertVisible: 'success' };
          }
          else {
            this.loading = false
            this.alertData = { alertMessage: 'Submission Failed', alertVisible: 'error' };
          }
        }
      })
    } else {
      this.loading = false
      this.alertData = { alertMessage: 'No File Selected', alertVisible: 'error' };
    }
  }

  closeAlert() {
    this.alertData = { alertMessage: '', alertVisible: '' };
  }
  goBack() {
    // this.router.navigate(['/Notices']);
    this.Data = undefined;
    this.close.emit();
  }
}
