import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { UserService } from '../../../service/user.service';
import { NoticePortalService, NoticesModel } from '../../../service/notice-portal.service';
import { NgClass, NgIf } from '@angular/common';
import { KendoDatepickerComponent } from '../../../components/kendo-datepicker/kendo-datepicker.component';
import { Router, RouterModule } from '@angular/router';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-notice-add-new-popup',
  standalone: true,
  imports: [DialogsModule,ReactiveFormsModule,DropdownListComponent,NgIf,FormsModule,KendoDatepickerComponent,NgClass,RouterModule,AlertPopupComponent,IndicatorsModule],
  templateUrl: './notice-add-new-popup.component.html',
  styleUrl: './notice-add-new-popup.component.css'
})
export class NoticeAddNewPopupComponent implements OnInit {
  @Output() close = new EventEmitter<void>();
  alertData = { alertMessage: '', alertVisible: '' }
  entityData:{text:any,value:any}[]=[{text:'',value:''}]
  selectedEntity:any={text:'Select Client',value:'Select Client'}
  Role:string=''
  clientId:string=''
  stateData:{text:any,value:any}[]=[{text:'',value:''}]
  selectedState={text:'Select State',value:''}
  locationData:{text:any,value:any}[]=[{text:'',value:''}]
  selectedLocation={text:'Select Loaction',value:''}
  branchData:{text:any,value:any}[]=[{text:'',value:''}]
  selectedBranch={text:'Select Branch',value:''}
  receiptData: { value: any, text: any }[] = [
    {text:'Select Receipt Mode',value:'Select Receipt Mode'},
    {  text: 'Email',value: 'Email' },
    { text: 'Registered Post', value: 'RegisteredPost' }
  ];
  selectedReceipt={text:'Select Receipt Mode',value:'Select Receipt Mode'}
  noticeNo:any
  noticeReceived:Date|null=null
  responseDate:Date|null=null
  noticeTypeData:{text:any,value:any}[]=[{text:'',value:''}]
  selectedNotice={text:'Select Notice Type',value:''}
  riskData: { value: any, text: any }[] = [
    {text:'Select Risk',value:'Select Risk'},
    {  text: 'Highly Critical',value: 'HighlyCritical' },
    { text: 'Critical', value: 'Critical' },
    { text: 'Medium', value: 'Medium' }
  ];
  selectedRisk={text:'Select Risk',value:''}
  public placeholder: string = 'DD-MM-YYYY';
  fileNames: { [key: string]: string } = {
    receipt: '',
    notice: '',
  };
  selectedFiles: { [key: string]: File | null } = {
    receipt: null,
    notice: null,
  };  
  remarks:string=''
  form!: FormGroup;
  loading=false
  showValidationErrors = false;
  constructor(private user:UserService,private noticeService:NoticePortalService,private router: Router,private fb: FormBuilder){}
  ngOnInit(): void {
 
    this.Role=this.user.getRole()
    this.getEntity()
    this.getStateDropdown()
    this.getNoticeDropdown()
    this.receiptData
    this.riskData
    this.selectedLocation={text:'Select Loaction',value:'Select Location'}
    this.selectedBranch={text:'Select Branch',value:'Select Branch'}
    this.form = this.fb.group({
       state: ['', Validators.required],
      location: ['', Validators.required],
      branch: ['', Validators.required],
      receiptMode: ['', Validators.required],
      noticeNo: ['', Validators.required],
      noticeReceived: ['', Validators.required],
      responseDate: ['', Validators.required],
      noticeType: ['', Validators.required],
      risk: ['', Validators.required],
      remarks: ['', Validators.required],
      notice: [null, Validators.required], 
      receipt: [{ value: this.selectedFiles['receipt'] || '', disabled: !this.isReceiptAcknowledgementEnabled() }, !this.isReceiptAcknowledgementEnabled() ? null : Validators.required],
    });
  }
  getEntity(){
    const userId=this.user.getUserId()  
    const client = this.user.getClientId();
    this.noticeService.getEntityDropdown(userId).subscribe({
      next:(response)=>{
        console.log(response);
        
        const data=response.body;
        console.log(response);
        this.entityData=data.map((item: any) => ({
          text: item.text,
          value: item.value
        }));
        if(this.Role!=='CORDN'){
          this.selectedEntity = this.entityData.find((entity: any) => entity.value === client);
        }


      }
    })
  }

  onEntityChange(value:any){
    this.selectedEntity={text:value.text,value:value.value}
    this.getStateDropdown()
    console.log(this.selectedEntity);
    
  }

  getStateDropdown(){
    if(this.Role==='CORDN'){
      this.clientId=this.selectedEntity.value
    }
    else{
      this.clientId=this.user.getClientId();
      
    }
    this.noticeService.getStateDropdown(this.clientId).subscribe({
      next:(response)=>{
        console.log('state response',response);
        
        const data=response.body;
        console.log('state response',data);
        const fetchedState = data.map((item: any) => ({
          text: item.text,
          value: item.value
        }))
        this.stateData = [
          { text: 'Select State', value: 'Select State' }, 
          ...fetchedState
        ];
      }
    })
  }
  onStateChange(value:any){
    this.selectedState={text:value.text,value:value.value}
    this.getLocationDropdown();
    this.form.get('state')?.setValue(this.selectedState);
    if (this.selectedState.value === 'Select State') {
      this.form.get('state')?.setErrors({ invalidLocation: true });
  } else {
      this.form.get('state')?.setErrors(null); 
  }
    if (this.form.get('state')?.valid) {
        this.form.get('state')?.markAsTouched();
    }
  }
  getLocationDropdown(){
    if(this.Role==='CORDN'){
      this.clientId=this.selectedEntity.value
    }
    else{
      this.clientId=this.user.getClientId();
      
    }
    const state=this.selectedState.value
    this.noticeService.getLocationDropdown('A','null',state,this.clientId).subscribe({
      next:(response)=>{
        const data=response.body;
        const fetchedLocation = data.map((item: any) => ({
          text: item.text,
          value: item.value
        }))
        this.locationData = [
          { text: 'Select Location', value: 'Select Location' }, 
          ...fetchedLocation
        ];
      }
    })
  }
  onLocationChange(value:any){
    this.selectedLocation={text:value.text,value:value.value}
    this.getBranchDropdown()
    if (this.selectedLocation.value === 'Select Location') {
      this.form.get('location')?.setErrors({ invalidLocation: true });
  } else {
      this.form.get('location')?.setErrors(null); 
  }
    if (this.form.get('location')?.valid) {
        this.form.get('location')?.markAsTouched();
    }
  }
  getBranchDropdown(){
    if(this.Role==='CORDN'){
      this.clientId=this.selectedEntity.value
    }
    else{
      this.clientId=this.user.getClientId();
      
    }
    const state=this.selectedState.value;
    const location=this.selectedLocation.value

    this.noticeService.getBranchDropdown('null',this.clientId,state,location).subscribe({
      next:(response)=>{
        console.log(response);
        
        const data=response.body;
        const fetchedBranch = data.map((item: any) => ({
          text: item.text,
          value: item.value
        }))
        this.branchData = [
          { text: 'Select Branch', value: 'Select Branch' }, 
          ...fetchedBranch
        ];
      }
    })
  }
  onBranchChange(value:any){
    this.selectedBranch={text:value.text,value:value.value}
    if (this.selectedBranch.value === 'Select Branch') {
      this.form.get('branch')?.setErrors({ invalidBranch: true });
  } else {
      this.form.get('branch')?.setErrors(null); 
  }
    if (this.form.get('branch')?.valid) {
        this.form.get('branch')?.markAsTouched();
    }
  }
 
  onReceiptChange(value:any){
    this.selectedReceipt={text:value.text,value:value.value}
    if (this.selectedReceipt.value === 'Select Receipt Mode') {
      this.form.get('receiptMode')?.setErrors({ invalidReceipt: true });
  } else {
      this.form.get('receiptMode')?.setErrors(null); 
  }
    if (this.form.get('receiptMode')?.valid) {
        this.form.get('receiptMode')?.markAsTouched();
    }
  }
  getNoticeDropdown(){
    this.noticeService.getNoticeDropdown().subscribe({
      next:(response)=>{
        const data=response.body;
        const fetchedNotice = data.map((item: any) => ({
          text: item.text,
          value: item.value
        }))
        this.noticeTypeData = [
          { text: 'Select Notice Type', value: 'Select Notice Type' }, 
          ...fetchedNotice
        ];
      }
    })
  }
  onNoticeChange(value:any)
  {
    this.selectedNotice={text:value.text,value:value.value}
    if (this.selectedNotice.value === 'Select Notice Type') {
      this.form.get('noticeType')?.setErrors({ invalidNotice: true });
  } else {
      this.form.get('noticeType')?.setErrors(null); 
  }
    if (this.form.get('noticeType')?.valid) {
        this.form.get('noticeType')?.markAsTouched();
    }
  }

  onRiskChange(value:any){
    this.selectedRisk={text:value.text,value:value.value}
    if (this.selectedRisk.value === 'Select Risk') {
      this.form.get('risk')?.setErrors({ invalidRisk: true });
  } else {
      this.form.get('risk')?.setErrors(null); 
  }
    if (this.form.get('risk')?.valid) {
        this.form.get('risk')?.markAsTouched();
    }
  }
  onDateChange(value: Date, type: string) {
    const date = new Date(value);

    if (type === 'noticeReceived') {
      this.noticeReceived = date;
      this.form.get('noticeReceived')?.setValue(date);
      this.form.get('noticeReceived')?.markAsTouched();
      
    }
    else if (type === 'responseDate') {
      this.responseDate = date;
      this.form.get('responseDate')?.setValue(date);
      this.form.get('responseDate')?.markAsTouched();
    }
    
  }
  isReceiptAcknowledgementEnabled(): boolean {
    return this.noticeReceived && this.responseDate 
      ? this.noticeReceived > this.responseDate 
      : false; 
  }
  onFileChange(event: any, type: string) {
    const file = event.target.files[0];
    const control = this.form.get(type); 
  
    if (file) {
      const allowedTypes = [
        'application/msword', 
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 
        'application/pdf' 
      ];
  
      if (allowedTypes.includes(file.type)) {
        this.fileNames[type] = file.name;
        this.selectedFiles[type] = file;
  
        if (control) {
          control.setValue(file);
        }
      } else {
        this.fileNames[type] = '';
        this.selectedFiles[type] = null;
        this.alertData = { alertMessage: 'Please select a .doc, .docx, or .pdf file', alertVisible: 'error' };
        
        if (control) {
          control.setValue(null);
        }
      }
    } else {
      this.fileNames[type] = '';
      this.selectedFiles[type] = null;
  
      if (control) {
        control.setValue(null);
      }
    }
  
    console.log(this.fileNames);
  }
  
  saveNotice(){
    this.showValidationErrors=true
    if (this.form.invalid) {
      console.log('Form is invalid', this.form);
      const invalidControls = Object.keys(this.form.controls).filter(key => this.form.controls[key].invalid);
      console.log('Invalid controls:', invalidControls); 
      
      this.form.markAllAsTouched(); 
      return; 
    }
    const formData = new FormData();
    
    formData.append('ClientId', this.selectedEntity.value);
    formData.append('Branch', this.selectedBranch.value);
    formData.append('StateId', this.selectedState.value.toString());
    formData.append('LocationId', this.selectedLocation.value.toString());
    formData.append('ReceiptMode', this.selectedReceipt.value);
    formData.append('NoticeReceivedDate', this.noticeReceived? this.noticeReceived.toISOString() : '');
    formData.append('NoticeTypeid', this.selectedNotice.value.toString());
    formData.append('NoticeNumber', this.noticeNo);
    formData.append('Risk', this.selectedRisk.value);
    formData.append('ResponseDueDate', this.responseDate ? this.responseDate.toISOString() : '');
    formData.append('Remarks', this.remarks);
    formData.append('Status', this.responseDate ? (this.responseDate < new Date() ? 'Overdue' : 'PendingAssignment') : 'PendingAssignment');
    formData.append('CreatedBy', this.user.getUserId().toString());
    formData.append('CreatedDate', new Date().toISOString());
   
    if (this.selectedFiles['receipt']) {
      formData.append('AcknowledgementFile', this.selectedFiles['receipt']);
    }
    if (this.selectedFiles['notice']) {
      formData.append('NoticeDocumentfile', this.selectedFiles['notice']);
    }
    console.log('Save response', formData);
    this.loading=true
    this.noticeService.saveNotice(formData).subscribe({
      next: (response) => {
        console.log('save', response);
        if(response.body.result===1)
        {
          this.alertData = { alertMessage: 'Notice Saved Successfully', alertVisible: 'success' };
          this.loading=false
        }
      },
      error: (err) => {
        console.error('Error during save:', err);
        this.loading=false
      }
    });
  }

  back() {
    this.close.emit();
  }
  public closePopup(): void {
    this.close.emit();
  }

}
