<div class="col-md-12 colpadding0">
    <div class="entity-container d-flex flex-column">
        <div class="selected-values">
            <img *ngIf="!showTranslation" src="../assets/vectors/Open.svg" class="svg-icon-btn" alt="" (click)="toggle('translation')">
            <img *ngIf="showTranslation" src="../assets/vectors/Closeminus.svg" class="svg-icon-btn" alt="" (click)="toggle('translation')">
            <span class="f-label">Translation Required</span>
        </div>
        <div *ngIf="showTranslation" class="selected-container">
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Translation Required</label>
                    <h4 class="f-label" style="width: 50px;"><input id="translation" value="true" type="radio" [(ngModel)]="TranslationRequired"
                            [ngModelOptions]="{standalone: true}" class="custom-radio" name="translation"
                            (change)="onRadioChange('Y','translation')" />Yes</h4>
                    <h4 class="f-label" style="width: 50px;"><input id="translation" [(ngModel)]="TranslationRequired"
                            [ngModelOptions]="{standalone: true}" value="false" type="radio" class="custom-radio"
                            name="translation" (change)="onRadioChange('N','translation')" />No</h4>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Translation Request Date</label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" [ngModel]="TranslationRequestDate" disabled>
                    </div>
                </div>

            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Translated Document Upload date</label>
                    <input type="text" class="form-control f-textbox" [(ngModel)]="TranslatedDocumentUploadDate" disabled>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Translation Document</label>
                    <div class="require-container">
                        <img src="../assets/vectors/DownloadMedium.svg" class="svg-icon-btn" alt="Action Icon" (click)="downloadDocument()" />

                    </div>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-12 save-con">
                    <button class="figma-btn-blue" (click)="saveTranslationDetails()" [disabled]="role!== 'SME'" [ngClass]="{'disabled-button': role !== 'SME'}">Submit</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-md-12 colpadding0">
    <div class="entity-container d-flex flex-column">
        <div class="selected-values">
            <img *ngIf="!showExtension" src="../assets/vectors/Open.svg" class="svg-icon-btn" alt="" (click)="toggle('extension')">
            <img *ngIf="showExtension" src="../assets/vectors/Closeminus.svg" class="svg-icon-btn" alt="" (click)="toggle('extension')">
            <span class="f-label">Extension Application</span>
        </div>
        <div *ngIf="showExtension" class="selected-container">
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Extension Required</label>
                    <h4 class="f-label" style="width: 50px;"><input id="extension" value="true" type="radio" [(ngModel)]="extensionRequired"
                            [ngModelOptions]="{standalone: true}" class="custom-radio" name="extension"
                            (change)="onRadioChange('Y','extension')" />Yes</h4>
                    <h4 class="f-label" style="width: 50px;"><input id="extension" [(ngModel)]="extensionRequired"
                            [ngModelOptions]="{standalone: true}" value="false" type="radio" class="custom-radio"
                            name="extension" (change)="onRadioChange('N','extension')" />No</h4>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Date of Draft Extension Document Submitted to SD</label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" [ngModel]="formattedDate" disabled>
                    </div>
                </div>

            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Extended Date Applied For</label>
                    <app-kendo-datepicker class="uniform-size " [(value)]="extendedDate"
                        (valueChange)="onDateChange($event, 'extendedDate')" [format]="'dd-MM-yyyy'"
                        [placeholder]="placeholder" [width]="'250px'" [isDisabled]="extensionRequired === 'false'" [class.input-invalid]="!extendedDate && showValidationErrors"
                        [hasError]="!extendedDate && showValidationErrors">
                    </app-kendo-datepicker>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Extension Application Acknowledgment</label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" [ngModel]="applicationAcknowledgement"
                            disabled>

                    </div>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Extended Date Granted</label>
                    <input type="text" class="form-control f-textbox" [ngModel]="extendedDateGranted" disabled>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Draft Extension Application</label>
                    <div class="require-container">
                        <span *ngIf="!fileNames['draft']" title="Uploaded File"
                            style="color: #A0A0A0;vertical-align:sub;">No file
                            chosen</span>
                        <span *ngIf="fileNames['draft']" [style.color]="'#A0A0A0'">
                            {{fileNames['draft']}}
                        </span>
                        <input type="file" #draft (change)="onFileChange($event, 'draft')" id="fileInput"
                            style="display: none;" accept=".doc,.docx,.pdf" />
                        <button class="figma-btn-gray" (click)="draft.click()">Browse</button>

                    </div>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-12 save-con">
                    <button class="figma-btn-blue" (click)="saveExtensionDetails()" [disabled]="role!== 'SME'" [ngClass]="{'disabled-button': role !== 'SME'}">Submit</button>
                </div>
            </div>
        </div>

    </div>
</div>
<div class="col-md-12 colpadding0">
    <div class="entity-container d-flex flex-column">
        <div class="selected-values">
            <img *ngIf="!showNotice" src="../assets/vectors/Open.svg" class="svg-icon-btn" alt="" (click)="toggle('notice')">
            <img *ngIf="showNotice" src="../assets/vectors/Closeminus.svg" class="svg-icon-btn"  alt="" (click)="toggle('notice')">
            <span class="f-label">Notice Response</span>
        </div>
        <div *ngIf="showNotice" class="selected-container">
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Notice Document Received On</label>
                    <input type="text" class="form-control f-textbox" [ngModel]="noticeDocumentReceived" disabled>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Draft Response Submitted To SD</label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" [ngModel]="formatteddraftResponseToSD" disabled>
                    </div>
                </div>

            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Notice Response Acknowledgement</label>
                    <div class="require-container">
                        <img src="../assets/vectors/DownloadMedium.svg" class="svg-icon-btn" alt="Action Icon" (click)="downloadNoticeAcknowledgement()" />

                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Draft Response</label>
                    <div class="require-container">
                        <span *ngIf="!fileNames['draftResponse']" title="Uploaded File"
                            style="color: #A0A0A0;vertical-align:sub;">No file
                            chosen</span>
                        <span *ngIf="fileNames['draftResponse']" [style.color]="'#A0A0A0'">
                            {{fileNames['draftResponse']}}
                        </span>
                        <input type="file" #draftResponse (change)="onFileChange($event, 'draftResponse')" id="fileInput"
                            style="display: none;" accept=".doc,.docx,.pdf" />
                        <button class="figma-btn-gray" (click)="draftResponse.click()">Browse</button>
                    </div>
                </div>

            </div>
            <div class="row EmpContainer">
                <div class="col-md-12 save-con">
                    <button class="figma-btn-blue" (click)="submitNoticeResponse()" [disabled]="role!== 'SME'" [ngClass]="{'disabled-button': role !== 'SME'}">Submit</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row EmpContainer">
    <div class="col-md-12 save-con">
        <button class="figma-btn-blue" (click)="goBack()">Back</button>
    </div>
</div>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270"  [Data]="alertData"
(close)="closeAlert()"></app-alert-popup>

<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>