import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ComplianceAssignmentReportService {
  private baseUrl=environment.apiUrl;
  constructor(private http:HttpClient) { }

  getDesignationExcel(UserId:number,ProfileId:string,CustId:number,ClientId:string,DistId:number,SpId:number,RoleCode:string,Ctype:string,Branch:string): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/LC_Reports/GetComplianceAssignment_Excelexport?userID=${UserId}&profileID=${ProfileId}&custID=${CustId}&clientID=${ClientId}&distID=${DistId}&spID=${SpId}&roleCode=${RoleCode}&ctype=${Ctype}&Branch=${Branch}`;
    return this.http.get<any>(url, { observe: 'response' });
  }
  getComplianceTypeOptions(type:string): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/Common/Get_LC_CodeDescription?Type=${type}`;
    return this.http.get<any>(url,{ observe: 'response' });
  }
  getGridData(UserId:number,ProfileId:string,CustId:number,ClientId:string,DistId:number,SpId:number,RoleCode:string,Ctype:string,Branch:string,Take:number,Skip:number,Page:number,PageSize:number): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/LC_Reports/GetComplianceAssignmentNew?userID=${UserId}&profileID=${ProfileId}&custID=${CustId}&clientID=${ClientId}&distID=${DistId}&spID=${SpId}&roleCode=${RoleCode}&ctype=${Ctype}&Branch=${Branch}&take=${Take}&skip=${Skip}&page=${Page}&pageSize=${PageSize}`;
    return this.http.get<any>(url, { observe: 'response' });
  }
  
}
