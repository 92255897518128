<div class="row colpadding0" style="margin-bottom: 15px;">
  <div class="col-md-12 colpadding0 toolbar" style="margin-top: 10px;">
    <div class="col-md-8 colpadding0" style=" justify-content: space-between; ">
      <app-combo-box [width]="'350px'" [Placeholder]="defaultSelectedValue" (filterChange)="FilterDropdown($event)"
        [selectedValue]="selectedEmployeeID" (valueChange)="DropdownGridChange($event)" [data]="dropdown"
        textField="name" valueField="id">
      </app-combo-box>
      <app-dropdown-list [data]="dropdownData" [selectedValue]="defaultFilterValue" [width]="'130px'" textField="text"
        valueField="value" (valueChange)="onDropdownValueChange($event)" style="margin-right: 0px; margin-left: 9px;">
      </app-dropdown-list>
      <div style="display: inline">
        <button class="figma-btn-white" (click)="applyFilter()" type="submit">
          <img src="../assets/vectors/ApplyFilter.svg" alt="">
        </button>
        <button (click)="clearFilter()" class="figma-btn-white">
          <img src="../assets/vectors/ClearFilter.svg" alt="">
        </button>
      </div>
    </div>
    <div class="col-md-4 colpadding0">
      <div style="display: inline; float: right; ">
        <button class="figma-btn-blue upload-btn" (click)="uploadDialog()">
          <img src="../assets/vectors/uploadWhite.svg">Bulk Upload
        </button>
        <button class="figma-btn-blue addNew" (click)="OpenAddNewPopup()" type="submit">
          <img src="../assets/vectors/Plus.svg" alt="">Add New
        </button>
      </div>
    </div>
  </div>
</div>

<kendo-grid [data]="gridData" [columnMenu]="true" [pageSize]="pageSize" [pageable]="true" class="custom-grid"
  (pageChange)="pageChange($event)" [loading]="loading" [sortable]="true" [skip]="skip" [resizable]="true">
  <kendo-grid-column field="eM_EmpID" title="EmpID">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [title]="dataItem.eM_EmpID" class="cell-content">
        {{dataItem.eM_EmpID}}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="eM_EmpName" title="Name">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [title]="dataItem.eM_EmpName" class="cell-content">
        {{dataItem.eM_EmpName}}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="eM_Branch" title="Branch">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [title]="dataItem.eM_Branch" class="cell-content">
        {{dataItem.eM_Branch}}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="eM_Location" title="Location">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [title]="dataItem.eM_Location" class="cell-content">
        {{dataItem.eM_Location}}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="eM_DOJ" title="DOJ">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [title]="dataItem.eM_DOJ" class="cell-content">
        {{ dataItem.eM_DOJ | date: 'dd-MMM-yyyy' }}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="EM_DOL" title="DOL">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [title]="dataItem.eM_DOL" class="cell-content">
        {{ dataItem.eM_DOL | date: 'dd-MMM-yyyy' }}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="eM_State" title="PT State">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [title]="dataItem.eM_State" class="cell-content">
        {{dataItem.eM_State}}
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="eM_Status" title="Status">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [title]="dataItem.eM_Status" *ngIf="dataItem.eM_Status == 'I'" class="cell-content">
        InActive
      </div>
      <div [title]="dataItem.eM_Status" *ngIf="dataItem.eM_Status == 'A'" class="cell-content">
        Active
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="action" title="Action" [width]="100">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div class="cell-content" style="display: flex; justify-content:center" >

        <img (click)="OpenEditPopup(dataItem)" src="../assets/vectors/edit.svg" class="svg-icon" alt="Save Icon" />
        <img src="../assets/vectors/delete.svg" alt="Delete Icon" class="svg-icon"  *ngIf="Role != 'PERF'" (click)="confirmAlert(dataItem)" />
      </div>
    </ng-template>
  </kendo-grid-column>
  <ng-template kendoPagerTemplate let-total="total" let-currentPage="currentPage">
    <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
    <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
    <kendo-pager-next-buttons></kendo-pager-next-buttons>
    <kendo-pager-input></kendo-pager-input>
    <kendo-pager-info></kendo-pager-info>
    <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
  </ng-template>
  <ng-template *ngIf="loading" kendoGridLoadingTemplate>
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
  </ng-template>
</kendo-grid>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270" [id]="deleteId" [Data]="alertData"
  (close)="closeAlert()" (confirm)="DeleteMWMaster($event)"></app-alert-popup>

<app-add-edit-employee-master-popup [EmpID]="EmpID" *ngIf="AddNewPopup"
  (close)="closePopup()"></app-add-edit-employee-master-popup>

<app-employee-select-bulk-upload *ngIf="selectBulkUploadPopup" (close)="closePopup()"></app-employee-select-bulk-upload>