<h4 class="Updates-head">Daily Updates</h4>

<div class="du-container">
    <!-- Tabs for filtering updates -->
    <div class="tabs">
      <button class="tab-button active">State</button>
      <button class="tab-button">Industry Specific</button>
    </div>
  
    <!-- Carousel for updates -->
    <div class="carousel-container">
      <button class="carousel-btn left-btn" (click)="prev()">&#8249;</button>
  
      <div class="carousel-wrapper">
        <div class="updates">
          <div class="card" *ngFor="let update of updates">
            <h3>{{ update.title }}</h3>
            <p class="date">{{ update.date }}</p>
            <p class="description">{{ update.description }}</p>
            <a href="#" class="read-more">Read More</a>
          </div>
        </div>
      </div>
  
      <button class="carousel-btn right-btn" (click)="next()">&#8250;</button>
    </div>
  </div>
  