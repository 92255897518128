<kendo-dialog #dialogRef title="Upload" (close)="closePopup()" [minWidth]="300" [height]="250"
    [width]="1050" class="custom-dialog">
    <ul class="nav nav-tabs">
        <li style="display: flex; align-items: center;">
            <h4 class="f-label"> <input id="rdCA" value="S" type="radio" class="custom-radio" name="measurementSystem"
                (change)="onRadioChange('CA')" [checked]="selectionType === 'CA'" />
            Compliance Assignment</h4>
        </li>
        <li style="display: flex; align-items: center;">
            <h4 class="f-label"><input id="rdCR" value="I" type="radio" class="custom-radio" name="measurementSystem"
                (change)="onRadioChange('CR')" [checked]="selectionType === 'CR'" />
            Compliance Re-Assignment</h4>
        </li>
        <li style="display: flex; align-items: center;">
            <h4 class="f-label"><input id="rdRA" value="I" type="radio" class="custom-radio" name="measurementSystem"
                (change)="onRadioChange('RA')" [checked]="selectionType === 'RA'" />
           Remove Assignment</h4>
        </li>
        <li style="display: flex; align-items: center;">
            <h4 class="f-label"><input id="rdRS" value="I" type="radio" class="custom-radio" name="measurementSystem"
                (change)="onRadioChange('RS')" [checked]="selectionType === 'RS'" />
            Remove Schedules</h4>
        </li>
        <li style="display: flex; align-items: center;">
            <h4 class="f-label"><input id="rdCAD" value="I" type="radio" class="custom-radio" name="measurementSystem"
                (change)="onRadioChange('CAD')" [checked]="selectionType === 'CAD'" />
           Change Activation Date</h4>
        </li>
        </ul>
  
        <div class="col-md-7 colpadding0">
            
                <table class="table Upload-table table-border">
                    <thead>
                        <tr class="f-label">
                            <th>Name</th>
                            <th>Template</th>
                            <th>Upload File</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr >
                            <td>
                                <div class="col-12 collpadding0">
                                    <p style="padding-top: 6px;" class="f-label">{{Name}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="col-12 collpadding0">
                                    <img src="../assets/vectors/ExcelDownload.svg" class="svg-icon-btn" (click)="downloadTemplate()" alt="">
                                </div>
                            </td>
                            <td>
                                <div class="col-12 collpadding0" style="justify-content: space-around;">
                                   
                                    <input type="file" formControlName="CTCTemplate" #CTCTemplateFile
                                        id="fileInput" style="display: none;" />
                                    <button class="figma-btn-gray" (click)="CTCTemplateFile.click()">Browse</button>
        
                                    <button class="figma-btn-blue" style="color: white; "
                                        (click)="uploadFiles()">Upload</button>
                                </div>
                            </td>
                           
                           
                            <td>
                                <div class="col-12 collpadding0">
                                    <img src="../assets/vectors/GreenTick.svg" alt="" class="greentick">
                                </div>
                            </td>
                            
                        </tr>
                    </tbody>
                </table>
            
        
    </div>
</kendo-dialog>