<div class="singlerow">
    <div class="left-panel">
        <button class="figma-btn-white" style="margin-left:0;" type="submit" (click)="goBack()">
            Back
        </button>
        </div>
   
    <div class="right-panel">
      <div >
        <button class="figma-btn-white" type="submit" (click)="GetUpoadedFilesStatus()" >
          Refresh
      </button>
      </div>
      <div  *ngIf="generateDocument">
        <button class="figma-btn-white marginleft" style="width: 160px;" (click)="Generate()">
          Generate Documents
      </button> 
      </div>
      <div >
        <button class="figma-btn-white marginleft" (click)="DocumentStatus()">
          Check
      </button> 
      </div>
    </div>
   </div>
   <!-- <kendo-grid [data]="gridData" [height]="300">
    <kendo-grid-column field="input" title="Input" width="250">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.input }} <span class="required-star">*</span>
      </ng-template>
    </kendo-grid-column>
    
    <kendo-grid-column title="Template" width="120">
      <ng-template kendoGridCellTemplate>
        <button kendoButton icon="download" class="btn-icon"></button>
      </ng-template>
    </kendo-grid-column>
  
    <kendo-grid-column title="Upload File" [width]="150">
        <ng-template kendoGridCellTemplate>
    
          <div >
            <div class="input-group ">
            <div class="form-control file-caption" style="display:none;">
            <div class="file-caption-name" style="width:180px;" id="fileCaption_0"></div>
            </div>
            <div class="input-group-btn">
            <div class="btn btn-default btn-sm btn-file  fileUpload"><span class="hidden-xs" style="display:none;"><i class="fa fa-paperclip" aria-hidden="true"></i>&nbsp;Browse</span><input name="file" id="MonthlyInputFile_0" style="width: 165px;" type="file" class="upload"></div>
            <button type="button" class="btn btn-primary btn-sm fileinput-upload fileinput-upload-button"><i class="glyphicon glyphicon-upload"></i>&nbsp;<span class="hidden-xs" onclick="UploadFile()">Upload</span></button>
            </div>
            </div>
            </div>
        </ng-template>
      </kendo-grid-column>
      
      
  
    <kendo-grid-column title="Uploaded" width="100">
      <ng-template kendoGridCellTemplate>
        <span class="k-icon k-i-check-circle"></span>
      </ng-template>
    </kendo-grid-column>
  
    <kendo-grid-column title="Validated" width="100">
      <ng-template kendoGridCellTemplate>
        <span class="k-icon k-i-check-circle"></span>
      </ng-template>
    </kendo-grid-column>
  
    <kendo-grid-column title="Processed" width="100">
      <ng-template kendoGridCellTemplate>
        <span class="k-icon k-i-check-circle"></span>
      </ng-template>
    </kendo-grid-column>
  
    <kendo-grid-column title="History" width="100">
      <ng-template kendoGridCellTemplate>
        <button kendoButton icon="eye"></button>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid> -->
  

  <!-- <kendo-grid class="mt-3" [data]="gridData" [height]="300">
    <kendo-grid-column field="input" title="Input" [width]="200">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.input }} <span class="required-star">*</span>
      </ng-template>
    </kendo-grid-column>
  
    <kendo-grid-column title="Template" [width]="70">
      <ng-template kendoGridCellTemplate>
        <div class="align">
            <button kendoButton icon="download" class="btn-icon"></button>
        </div>
        
      </ng-template>
    </kendo-grid-column>
  
    <kendo-grid-column title="Upload File" [width]="280">
      <ng-template kendoGridCellTemplate>
        <div>
          <div class="input-group">
            <div class="form-control file-caption" style="display:none;">
              <div class="file-caption-name" style="width:180px;" id="fileCaption_0"></div>
            </div>
            <div class="input-group-btn">
              <div class="btn btn-default btn-sm btn-file fileUpload">
                <input name="file" id="MonthlyInputFile_0" style="width: 165px;" type="file" class="upload">
              </div>
              <button type="button" class="btn btn-primary btn-sm fileinput-upload fileinput-upload-button">
                <span class="hidden-xs">Upload</span>
              </button>
            </div>
          </div>
        </div>
      </ng-template>
    </kendo-grid-column>
  
    <kendo-grid-column title="Uploaded" [width]="70" class="align">
      <ng-template kendoGridCellTemplate >
        <div class="align">
            <span class="k-icon k-i-check-circle" style="color: green;"></span>
        </div>
       
      </ng-template>
    </kendo-grid-column>
  
    <kendo-grid-column title="Validated" [width]="70" class="align">
      <ng-template kendoGridCellTemplate>
        <div class="align">
            <span class="k-icon k-i-check-circle" style="color: green;"></span>
        </div>
        
      </ng-template>
    </kendo-grid-column>
  
    <kendo-grid-column title="Processed" [width]="70" class="align">
      <ng-template kendoGridCellTemplate>
        <div class="align">
            <span class="k-icon k-i-check-circle"></span>
        </div>
       
      </ng-template>
    </kendo-grid-column>
  
    <kendo-grid-column title="History" [width]="70" class="align">
      <ng-template kendoGridCellTemplate>
        <div class="align">
            <button kendoButton icon="eye"></button>
        </div>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid> -->
  

  <form [formGroup]="myGroup" style="margin-top: 10px;">
    <table class="table Upload-table table-border">
        <thead>
            <tr>
                <th>Input</th>
                <th>Template</th>
                <th>Upload File</th>
                <th>Uploaded</th>
                <th>Validation</th>
                <th>Processed</th>
                <th>History</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let input of inputListData; let i = index">
                <td >
                    <div class="col-12">
                        <p style="padding-top: 6px;">{{ input.inputName }}
                            <sup class="text-danger" *ngIf="input.isFileRequired == 1">*</sup>
                        </p>
                    </div>
                </td>
                <td class="align">
                    <div class="col-12 collpadding0 ">
                        <img src="../assets/vectors/ExcelDownload.svg" class="svg-icon-btn" (click)="Template(input.inputId )" alt="">
                    </div>
                </td>
                <td class="align fixed-column">
                    <div class="col-12 collpadding0" >
                      <span style="color: #A0A0A0;">
                        {{ fileNames[i] ? (fileNames[i].length > 10 ? fileNames[i].substring(0, 12) + '..' : fileNames[i]) : 'No file chosen' }}
                    </span>
                     
                       
                        <input type="file" formControlName="Template" #TemplateFile
                        (change)="onFileChange($event, i)" [id]="'fileInput' + i" style="display: none;" />
                    <button class="figma-btn-gray" (click)="TemplateFile.click()">Browse</button>
                    <button class="figma-btn-blue"  (click)="uploadFiles(i,input.inputId)">Upload</button>
                    </div>
                </td>
                <td class="align">
                    
                    <div *ngIf="input?.displayUploadFlag === 1">
                      <div *ngIf="input?.isUploaded === 0 && input?.isError === 0">
                        <img src="../assets/vectors/sandclock.svg" class="svg-icon-btn"  alt="">
                      </div>
                      <div *ngIf="input?.isUploaded === 1">
                        <img src="../assets/vectors/GreenTick.svg" class="greentick" alt="">
                      </div>
                      <div *ngIf="input?.isError === 1 && input?.isUploaded === 0">
                        <a class="download-icon" (click)="downloadErrorFile(input.inputId)">
                          <img src="../assets/vectors/DownloadOrange.svg" class="svg-icon-btn" alt="" style="cursor:pointer;">
                        </a>
                      </div>
                    </div>
                </td>
                <td class="align">
                    
                    <div *ngIf="input?.displayValidationFlag === 1">
                      <div *ngIf="input?.isError === 0 && input?.isValidated === 0">
                        <img src="../assets/vectors/sandclock.svg" class="svg-icon-btn"  alt="">
                      </div>
                      <div *ngIf="input?.isValidated === 1">
                        <img src="../assets/vectors/GreenTick.svg" class="greentick" alt="">
                      </div>
                      <div *ngIf="input?.isError === 1 && input?.isValidated === 0">
                        <a class="download-icon" (click)="downloadErrorFile(input.inputId)">
                          <img src="../assets/vectors/DownloadOrange.svg" class="svg-icon-btn"  alt="" style="cursor:pointer;">
                        </a>
                      </div>
                    </div>
                  </td>
                <td class="align">
                  
                    <div *ngIf="input?.displayProcessFlag === 1">
                      <div *ngIf="input?.isError === 0 && input?.isProcessed === 0">
                        <img src="../assets/vectors/sandclock.svg" class="svg-icon-btn"  alt="">
                      </div>
                      <div *ngIf="input?.isProcessed === 1">
                        <img src="../assets/vectors/GreenTick.svg" class="greentick" alt="">
                      </div>
                      <div *ngIf="input?.isError === 1 && input?.isProcessed === 0">
                        <a class="download-icon" (click)="downloadErrorFile(input.inputId)">
                          <img src="../assets/vectors/DownloadOrange.svg" class="svg-icon-btn"  alt="" style="cursor:pointer;">
                        </a>
                      </div>
                    </div>
                </td>
                <td class="align">
                    <img (click)="openHistoryPopup(input.inputId)" src="../assets/vectors/History.svg"  class="svg-icon-btn"  alt="">
                </td>
            </tr>
            
          
          
        </tbody>
    </table>
</form>


<div *ngIf="loading" class="loader-overlay">
  <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>
<app-workspace-history-popup *ngIf="openHistory" [Id]="Id" [month]="month" [year]="year" (close)="closeHistoryPopup()"></app-workspace-history-popup>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="250" [Height]="260" [Data]="alertData"
    (close)="closeAlert()"></app-alert-popup>