import { Component, EventEmitter, Input, input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { CriticalDocumnetsService } from '../../../service/critical-documnets.service';
import { UserService } from '../../../service/user.service';
import { NgClass, NgIf } from '@angular/common';
import { MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { KendoDatepickerComponent } from '../../../components/kendo-datepicker/kendo-datepicker.component';

@Component({
  selector: 'app-critical-documents-edit-details',
  standalone: true,
  imports: [DialogModule, FormsModule, ReactiveFormsModule, NgIf, NgClass, MultiSelectModule, KendoDatepickerComponent],
  templateUrl: './critical-documents-edit-details.component.html',
  styleUrl: './critical-documents-edit-details.component.css'
})
export class CriticalDocumentsEditDetailsComponent implements OnInit {
  @Output() close = new EventEmitter<void>();
  @Input() FileId!: any;
  form!: FormGroup;
  loading = false;
  CustomerId!: number;
  userid!: number;
  EntityId!: string;
  public alertData = { alertMessage: '', alertVisible: '' };
  tags: Array<{ id: number; name: string }> = []
  allTags: any[] = [];
  inputFocus: boolean = false;
  selectedTags!: any
  public placeholder: string = 'DD-MM-YYYY';
  todayDate: Date = new Date();
  fileCreatedDate: Date | null = null;
  showValidationErrors = false;
  nextId: number = this.tags.length + 1;

  constructor(private CriticalDocumentsService: CriticalDocumnetsService, private user: UserService, private fb: FormBuilder) {
    this.form = this.fb.group({
      name: [{ value: '', disabled: true }],
      type: [{ value: '', disabled: true }],
      createdBy: [{ value: '', disabled: true }],
      createdOn: [{ value: '', disabled: true }],
      updatedOn: [{ value: '', disabled: true }],
      doc_Header: ['', [Validators.required]],
      doc_Description: ['', [Validators.required]],
      others: ['', [Validators.required]],
      process: ['', [Validators.required]],
      subProcess: ['', [Validators.required]],
      vertical: ['', [Validators.required]],
      location: ['', [Validators.required]],
      tags: [[]]

    })
  }
  ngOnInit(): void {
    this.CustomerId = this.user.getCustomerId();
    this.userid = this.user.getUserId();
    this.EntityId = this.user.getClientId();
    console.log(this.userid);
    this.getFileinfo(this.FileId);
  }
  InitData(data: any) {
    debugger
    this.form.patchValue(data)
    const name = data.name || '';
    const extension = name.substring(name.lastIndexOf('.') + 1);
    this.form.get('type')?.setValue(extension);
    const createdBy = data.createdBy === this.userid ? 'Me' : data.createdBy;
    this.form.get('createdBy')?.setValue(createdBy);
    this.fileCreatedDate = data.fileCreatedDate ? new Date(data.fileCreatedDate) : null;
  }
  getFileinfo(id: number) {
    this.CriticalDocumentsService.GetFileInfo(id).subscribe({
      next: (response) => {
        const data = response.body;
        console.log('getfile', data);
        this.InitData(data)
      },
      error: (error) => {
        console.log(error);
      }
    });
  }
  formatDateForSave(dateString: string | null): string | null {
    if (!dateString || dateString.trim() === '') {
      return null;
    }

    const dateParts = dateString.split('/');

    if (dateParts.length === 3 && dateParts.every(part => !isNaN(Number(part)))) {
      const day = dateParts[0];
      const month = dateParts[1];
      const year = dateParts[2];

      const date = new Date(`${year}-${month}-${day}T00:00:00`);

      if (!isNaN(date.getTime())) {
        return this.convertToISOFormat(date);
      } else {
        return null;
      }
    } else {
      const date = new Date(dateString);

      if (!isNaN(date.getTime())) {
        return this.convertToISOFormat(date);
      } else {
        return null;
      }
    }
  }

  convertToISOFormat(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }
  onUpdate() {
    const body={
      "fileid": this.FileId,
      "doc_Header": this.form.value.doc_Header,
      "doc_Description": this.form.value.doc_Description,
      "others": this.form.value.others,
      "process": this.form.value.process,
      "subProcess": this.form.value.subProcess,
      "vertical": this.form.value.vertical,
      "fileCreatedDate":  this.fileCreatedDate ? this.formatDateForSave(this.fileCreatedDate.toISOString()) : '',
      "location": this.form.value.location
    }
    console.log(body);
    this.showValidationErrors=true;
    
    if (this.form.valid && this.fileCreatedDate) {
      this.CriticalDocumentsService.SaveFileInfo(body)
      .subscribe({
        next: (response) => {
          console.log(response);
          if (response.status === 200 && response.body.result == 1) {
            this.alertData = { alertMessage: 'File info saved Successfully!', alertVisible: 'success' };
            this.loading = false;
          } else {
            this.alertData = { alertMessage: 'Error sharing the File', alertVisible: 'error' };
            this.loading = false;
          }
        },
        error: (error) => {
          this.alertData = { alertMessage: 'Something went wrong, Please try again', alertVisible: 'error' };
          console.error('Error fetching Grid data', error);
          this.loading = false;
        }
      });
    } else {
      this.form.markAllAsTouched();
    }
  }
  onTagAdd(event: any): void {
    if (!event || !Array.isArray(event)) {
      console.error('Invalid event data:', event);
      return;
    }

    this.selectedTags = event.map((item: any) => {
      if (typeof item === 'string') {
        return { id: this.nextId++, name: item };
      } else if (item && typeof item === 'object') {
        return item;
      }
      return null;
    }).filter(tag => tag !== null);
    console.log('Selected tags:', this.selectedTags);
  }

  onDateChange(value: Date, type: string) {
    const date = new Date(value);
    if (type === 'fileCreatedDate') {
      this.fileCreatedDate = date;
    }
  }

  mapCustomTag(tagName: string): string {
    if (tagName && typeof tagName === 'string') {
      return tagName;
    }
    return '';
  }

  onTagRemove(tag: { id: number; name: string }): void {
    this.selectedTags = this.selectedTags.filter((t: any) => t.id !== tag.id);
    console.log('Tags after removal:', this.selectedTags);
  }
  preventDropdown(event: MouseEvent) {
    event.stopPropagation();
  }
  closePopup() {
    this.close.emit();
  }
}
