import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ComboBoxModule } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-combo-box',
  standalone: true,
  imports: [ComboBoxModule,CommonModule],
  templateUrl: './combo-box.component.html',
  styleUrls: ['./combo-box.component.css']
})
export class ComboBoxComponent implements OnInit, OnChanges {
  @Input() width: string = '100%';
  @Input() disabled: boolean = false;
  @Input() height: string = '29px';
  @Input() data: { id: any; name: string }[] = [];
  @Input() Placeholder: string = '';
  @Input() hasError!: any ;
  @Input() textField: string = 'name';
  @Input() valueField: string = 'id';
  @Input() selectedValue: any;  // Added input for selected value
  // public filter: string = '';
  @Input() isDisabled: boolean = false;
  public filteredData: { id: any; name: string }[] = [];
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterChange: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit() {
    this.filteredData = this.data;
    console.log("Filtered Data from Resuable Component", this.filteredData);
  }

  ngOnChanges(changes: SimpleChanges) {
   
    if (changes['selectedValue']) {
      if (this.selectedValue === null) {
        this.filteredData = this.data;  
      }
    }
  }
  
  public handleFilter(value: string): void {
    if (!value) {
      this.filteredData = this.data; 
    } else {
      const lowerValue = value.trim().toLowerCase();
  
      this.filteredData = this.data.filter(item => 
        item.name.toLowerCase().includes(lowerValue) || 
        item.id.toString().toLowerCase().includes(lowerValue)
      );
    }
  
    this.filterChange.emit(value);
  }
  

  public resetFilter() {
    debugger;
    this.filteredData = this.data;
  }

  onValueChange(value: any) {
    debugger;
    console.log('com',value)
    this.valueChange.emit(value);
  }

  clearSelection() {
    this.selectedValue = null;  // Clears the selection
    this.filteredData = this.data;  // Resets the filtered data
  }
}
