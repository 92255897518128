<div class="display:flex;margin-top:12px;">
    <div style="display:flex;justify-content: space-between;
    margin-top: 10px;">
        <!-- <input placeholder="Type to Search" (keyup)="applyFilter($event)" class="f-textbox" style="width: 290px;"> -->
        <app-combo-box [width]="'350px'" [Placeholder]="defaultSelectedValue" (filterChange)="FilterDropdown($event)"
            (valueChange)="DropdownGridChange($event)" [data]="dropdown">
        </app-combo-box>
      
            <div style="display: inline" style="float: right; ">
                <!-- <button kendoButton class="figma-btn-blue"
                    style="background-color: white; width: 30px; height: 33px; border-radius: 3px; "
                    (click)="openUploadPopup()">
                    <img src="../assets/vectors/upload.svg" alt="" style="margin-left: 3px;">
                </button> -->
                <button class="figma-btn-blue upload-btn" (click)="openUploadPopup()">
                    <img src="../assets/vectors/uploadWhite.svg" >Bulk Upload
                  </button>
                <button (click)="exportToExcel()" class="exportbtn">
                    <img src="../assets/vectors/ExportButton.svg" alt="">
                </button>
                <button class="figma-btn-blue" (click)="openAddNewPopup()" type="submit">
                    <img src="../assets/vectors/Plus.svg" alt="">Add New
                </button>
            </div>
       
    </div>
    
</div>
<div style="margin-top:10px;">
<app-grid-action-item [gridData]="gridData" [columns]="parentColumns" [pageSize]="10" [pageable]="true"
    [filterable]="true" (buttonClick)="handleButtonClick($event)" style="margin-top:10px;">
</app-grid-action-item>
</div>

<app-ecr-master-add-editor-popup [dialogTitles]="'Add New PF/ECR Login'" *ngIf="isAddNewVisible" [data]="rowData"
    [IsUpdate]="IsUpdate" (close)="handleClosePopup()">
</app-ecr-master-add-editor-popup>

<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>

<app-ecr-master-bulk-upload *ngIf="uploadPopupOpen" (close)="handleclosePopup()"></app-ecr-master-bulk-upload>