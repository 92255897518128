import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GridModule } from '@progress/kendo-angular-grid';
import { EmployeeHistoryPopupComponent } from '../../popups/employee-history-popup/employee-history-popup.component';

@Component({
  selector: 'app-employee-master-upload',
  standalone: true,
  imports: [GridModule,ReactiveFormsModule,FormsModule,NgIf,EmployeeHistoryPopupComponent],
  templateUrl: './employee-master-upload.component.html',
  styleUrl: './employee-master-upload.component.css'
})
export class EmployeeMasterUploadComponent implements OnInit{
  take = 10;
  public pageSize = 10;
  public skip = 0;
  public buttonCount = 5;
  sizes=10
  EmployeeHistoryPopup=false;
  public gridData: any[] = [
    { Header: 'Item 1', selected: null },
    { Header: 'Item 2', selected: null },
    { Header: 'Item 3', selected: null },
    { Header: 'Item 4', selected: null },
    { Header: 'Item 5', selected: null },
    { Header: 'Item 1', selected: null },
    { Header: 'Item 2', selected: null },
    { Header: 'Item 3', selected: null },
    { Header: 'Item 4', selected: null },
    { Header: 'Item 5', selected: null },
    { Header: 'Item 3', selected: null },
    { Header: 'Item 4', selected: null },
    { Header: 'Item 5', selected: null }
  ];
  fileNames: { [key: string]: string | null } = {
    DownloadsInput: null,
    EmployeeInput: null,
    FamilyWelfareInput: null,
    UpdateInputEmp: null,
    UpdateInputFamily: null,
    FailedInput: null
  };
  allSelected: boolean = false;

  ngOnInit(): void {
    // this.EmployeeHistoryPopup=true;
  }
  toggleSelectAll(event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    this.gridData.forEach(item => item.selected = checked);
  }
  uploadFile(){

  }
  onFileChange(value:any,type:string){

  }
}
