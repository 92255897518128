import { Component, OnInit } from '@angular/core';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { MasterAbstractsService } from '../../../service/master-abstracts.service';
import { UserService } from '../../../service/user.service';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { MasterAbstractUploadPopupComponent } from '../../popups/master-abstract-upload-popup/master-abstract-upload-popup.component';
import { NgIf } from '@angular/common';
import { BlobService } from '../../../service/blob.service';
import { environment } from '../../../environments/environment';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { AdobeViewerComponent } from '../../../components/adobe-viewer/adobe-viewer.component';

@Component({
  selector: 'app-master-abstracts',
  standalone: true,
  imports: [DropdownListComponent, GridActionItemComponent, MasterAbstractUploadPopupComponent, NgIf, IndicatorsModule, AlertPopupComponent, AdobeViewerComponent],
  templateUrl: './master-abstracts.component.html',
  styleUrl: './master-abstracts.component.css'
})
export class MasterAbstractsComponent implements OnInit {
  StateDropdownData: any[] = [];
  ActDropdownData: any[] = [];
  showUploadPopup = false;
  public showViewer: boolean = false;
  fileBlob!: Blob;
  loginusername: string = '';
  selectedState = { text: 'Select State', value: '' };
  selectedAct = { text: 'Select Act', value: '' };
  userID!: number;
  loading = false;
  EntityID!: string;
  alertData = { alertMessage: '', alertVisible: '' }
  public gridData: GridDataResult = { data: [], total: 0 };
  public OriginalData: GridDataResult = { data: [], total: 0 };

  constructor(private MasterAbstractService: MasterAbstractsService, private user: UserService, private blobService: BlobService) { }
  ngOnInit(): void {
    this.userID = this.user.getUserId();
    this.EntityID = this.user.getClientId();
    this.getStateDropdownData();
    // this.getActDropdownData()
    this.loginusername = this.user.getFirstName();
  }

  handleButtonClick(event: any) {
    debugger;
    if (event.button.action === 'Download Regional') {
      this.loading = true;
      this.downloadFile(event.dataItem.regionalFilePath, event.dataItem.regionalFileName);
    } else if (event.button.action === 'Download English') {
      this.loading = true;
      this.downloadFile(event.dataItem.englishFilePath, event.dataItem.englishFileName);
    }
    else if (event.button.action === 'View Regional') {
      this.loading = true;
      this.getFileForViewer(event.dataItem.regionalFilePath, event.dataItem.regionalFileName, event.dataItem.FileKey);
    }
    else if (event.button.action === 'View English') {
      this.loading = true;
      this.getFileForViewer(event.dataItem.englishFilePath, event.dataItem.englishFileName, event.dataItem.FileKey);
    }
  }

  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    const originalDataArray = this.OriginalData.data;
    const filteredData = originalDataArray.filter(item =>
      (item.stateName && item.stateName.toLowerCase().includes(filterValue)) ||
      (item.actName && item.actName.toString().toLowerCase().includes(filterValue))
    );
    this.gridData = {
      data: filteredData,
      total: filteredData.length
    };
  }
  downloadFile(filePath: string, fileName: string) {
    debugger
    if (filePath) {

      const apiUrl = environment.blobUrl;
      const blobLink = apiUrl + filePath;
      const blobName = this.blobService.extractBlobName(blobLink);
      setTimeout(() => {
        this.blobService.getSasToken(blobName).subscribe(
          (tokenResponse: any) => {
            try {
              const sasToken = tokenResponse.result;
              this.blobService.downloadFile(blobName, sasToken);
              this.loading = false;
            } catch (error) {
              this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
              this.loading = false;
            }
          },
          (error) => {
            this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
            this.loading = false;
          }
        );
      }, 5000);
    } else {
      this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
      this.loading = false;
    }
  }
  loadGridData(value: string): void {
    this.loading = true;
    this.MasterAbstractService.getGridDate(this.EntityID, this.userID, parseInt(value)).subscribe({
      next: (response) => {
        console.log(response);

        const data = response.body;
        this.OriginalData = {
          data: data,
          total: data.length
        };
        this.gridData = {
          data: data,
          total: data.length
        };
        this.loading = false;
      },
      error: (err) => {
        console.error('Error fetching entity data', err);
        this.loading = false;
      }
    });
  }
  getStateDropdownData() {
    this.MasterAbstractService.getStateDropdown(this.EntityID, this.userID, 1).subscribe({
      next: (response) => {
        const data = response.body;
        this.StateDropdownData = [
          { text: 'Select State', value: '' },
          ...data.map((item: any) => ({
            text: item.key,
            value: item.value
          }))
        ];
      },
      error: (error) => {
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }
  getActDropdownData() {
    this.MasterAbstractService.getActListDropdown().subscribe({
      next: (response) => {
        const data = response.body;
        this.ActDropdownData = [
          { text: 'Select Act', value: '' },
          ...data.map((item: any) => ({
            text: item.value,
            value: item.key
          }))
        ];
      },
      error: (error) => {
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }
  onDropdownValueChange(value: any, type: string) {
    if (type === 'State') {
      this.loadGridData(value.value);
      this.selectedState = { text: value.text, value: value.value };
      this.selectedAct = { text: '', value: '' };
      this.getActDropdownData();
    } else if (type == 'Act') {
      this.selectedAct = { text: value.text, value: value.value };
      if (value.value === '') {
        this.gridData = {
          data: this.OriginalData.data,
          total: this.OriginalData.data.length
        };
      } else {
        const filteredData = this.OriginalData.data.filter(item => item.actName === value.text);
        this.gridData = {
          data: filteredData,
          total: filteredData.length
        };
      }
    }
  }
  openUploadPopup() {
    this.showUploadPopup = true;
  }
  clearFilter() {
    this.selectedAct = { text: '', value: '' };
    this.selectedState = { text: '', value: '' };
    this.gridData = {
      data: [],
      total: 0
    }
  }

  getFileForViewer(FilePath: string, FileName: string, FileKey: string) {
    debugger;
    this.MasterAbstractService.fetchFile(FilePath, FileName, FileKey).subscribe({
      next: (blob: Blob) => {
        debugger;
        this.fileBlob = blob;
        this.loading = false;
        this.showViewer = true;
      },
      error: (error) => {
        this.loading = false;
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }


  public parentColumns: any[] = [
    { field: 'stateName', title: 'State Name', width: 300 },
    { field: 'actName', title: 'Act Name' },
    {
      field: 'regional',
      isIconColumn: true,
      title: 'Regional',
      width: 160,
      filteredIcons: [
        {
          url: '../assets/vectors/DownloadBlue.svg',
          alt: 'Icon 1',
          action: 'Download Regional'
        },
        {
          url: '../assets/vectors/EyeIcon.svg',
          alt: 'Icon e',
          action: 'View Regional'
        },
      ]
    },
    {
      field: 'english',
      isIconColumn: true,
      title: 'English',
      width: 160,
      filteredIcons: [
        {
          url: '../assets/vectors/DownloadBlue.svg',
          alt: 'Icon 1',
          action: 'Download English'
        },
        {
          url: '../assets/vectors/EyeIcon.svg',
          alt: 'Icon e',
          action: 'View English'
        },
      ]
    }
  ];

  closeAlert() {
    this.alertData = { alertMessage: '', alertVisible: '' }
  }
  closePopup() {
    debugger;
    this.showUploadPopup = false;
  }
  closePopupViewer() {
    debugger;
    this.showViewer = false;
  }

}
