<kendo-dialog #dialogRef title="Export Registration" (close)="closePopup()" [width]="400" class="custom-dialog">
    <div class="col-md-6 d-flex align-items-center">
        <label class="f-label me-3">Entity<sub class="text-danger">*</sub></label>
        <app-dropdown-list [data]="dropdownData" [selectedValue]="selectedentity" [width]="'250px'" textField="text"
            valueField="value" name="gender" [hasError]="!selectedentity.value && showValidationErrors"
            (valueChange)="onDropdownValueChange($event, 'entity')">
        </app-dropdown-list>
    </div>
    <kendo-dialog-actions class="buttons">
        <div class="d-flex justify-content-start">
            <button kendoButton class="figma-btn-blue  custom-btn " type="submit" (click)="exportToExcel('C')">
                Consolidated
            </button>
            <button kendoButton class="figma-btn-blue  custom-btn "  type="submit" (click)="exportToExcel('E')">
                Expired
            </button>
            <button kendoButton class="figma-btn-blue  custom-btn"  type="submit" (click)="exportToExcel('EC')">
                Close To Expire
            </button>
        </div>
    </kendo-dialog-actions>
</kendo-dialog>
<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>