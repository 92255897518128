import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InputFilesService {
  private baseUrl  = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getcompliance(type:string):Observable<HttpResponse<any>>{
    const url = `${this.baseUrl}/api/Common/Get_LC_CodeDescription?Type=${type}`;
    return this.http.get(url, { observe: 'response' });
  }

  ComplianceInput(payload: any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/ClientsInputUpload/GetInputWebUploadClientList_Revamped`;
    return this.http.post(url, payload, { observe: 'response' });
  }

  GetSampleBasedOnInputID(payload: any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/ComplianceInput/GetSampleBasedOnInputID`;
    return this.http.post(url, payload, { observe: 'response' });
  }

  uploadFile(payload:any,formData: any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/ClientsInputUpload/TemplateCheckUpload?EntityId=${payload.EntityId}&Month=${payload.Month}&Year=${payload.Year}&JsonId=${payload.JsonId}&InputId=${payload.InputId}&UserId=${payload.UserId}&UniqueID =''&CustomerID=${payload.CustomerID}&FileName=${payload.FileName}&FilepathWithFileName=''`;
    return this.http.post(url, formData,{observe:'response'});
  }

  GetErrorFile(payload: any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/ComplianceInput/GetInputUploadErrorFile`;
    return this.http.post(url, payload, { observe: 'response' });
  }
  downloadUploadedCSVFile(filePath: string): Observable<any> {
    const url = `${this.baseUrl}/api/ClientsInputUpload/DownloadUploadedCSVFile?filePath=${filePath}`;   
    return this.http.get(url,  { observe: 'response' });
  }
  GetHistoryData(payload: any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/ClientsInputUpload/GetUploadedFileDataCustomer?UserId=${payload.UserId}&EntityId=${payload.EntityId}&month=${payload.month}&year=${payload.year}&InputId=${payload.InputId}`;
    return this.http.post(url,payload, { observe: 'response' });
  }

  getChallanCustomerList(payload:any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/ClientsInputUpload/GetChallanWorkingCustomerList`;
    return this.http.post(url,payload, { observe: 'response' });
  }

  downloadChallan(path:string,type:string): Observable<HttpResponse<any>>{
    const url = `${this.baseUrl}/api/ClientsInputUpload/DownloadChallan?Path=${path}&UploadType=${type}`;
    return this.http.get(url, { observe: 'response' });
  }

  getChallanHistoryData(payload: any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/ClientsInputUpload/GetChallanUploadedFileData?EntityId=${payload.EntityId}&month=${payload.month}&year=${payload.year}&ComplianceType=${payload.ComplianceType}&UserType=${payload.UserType}`;
    return this.http.post(url,payload, { observe: 'response' });
  }
  uploadChallan(payload:any,formData: any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/ClientsInputUpload/ChallanUpload?EntityId=${payload.EntityId}&Month=${payload.Month}&Year=${payload.Year}&UserId=${payload.UserId}&CustomerID=${payload.CustomerID}&FileName=${payload.FileName}&ComplianceType=${payload.ComplianceTypes}&Status=${payload.Status}&UserType=${payload.UserType}&FileType=${payload.FileType}&FilePath=${payload.FilePath}&Remarks=${payload.Remarks}`;
    return this.http.post(url, formData,{observe:'response'});
  }

  uploadFinalChallanFile(payload:any,formData: any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/ClientsInputUpload/SaveFinalChallanFile?EntityId=${payload.EntityId}&Month=${payload.Month}&Year=${payload.Year}&UserId=${payload.UserId}&CustomerID=${payload.CustomerID}&FileName=${payload.FileName}&ComplianceType=${payload.ComplianceType}&Status=${payload.Status}&UserType=${payload.UserType}&FileType=${payload.FileType}&FilePath=${payload.FilePath}&Remarks=${payload.Remarks}`;
    return this.http.post(url, formData,{observe:'response'});
  }

  getPerformerList(payload: any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/ClientsInputUpload/GetInputWebUploadPerformerList_Revamped`;
    return this.http.post(url, payload, { observe: 'response' });
  }

  downloadConvertedFile(filePath: string): Observable<any> {
    const url = `${this.baseUrl}/api/ClientsInputUpload/DownloadConvertedFile?filePath=${filePath}`;   
    return this.http.get(url,  { observe: 'response' });
  }

  uploadperformer(payload:any,formData: any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/ComplianceInput/UploadInputFile?EntityId=${payload.EntityId}&Month=${payload.Month}&Year=${payload.Year}&JsonId=${payload.JsonId}&InputId=${payload.InputId}&UserId=${payload.UserId}&UniqueID =''&CustomerID=${payload.CustomerID}`;
    return this.http.post(url, formData,{observe:'response'});
  }
  getChallanPerformerList(payload:any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/ClientsInputUpload/OutputChallanPerformerSection`;
    return this.http.post(url,payload, { observe: 'response' });
  }
  GetPerformerHistoryData(payload: any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/ComplianceInput/GetUploadedFileData?UserId=${payload.UserId}&EntityId=${payload.EntityId}&month=${payload.month}&year=${payload.year}&InputId=${payload.InputId}`;
    return this.http.get(url, { observe: 'response' });
  }



}
