import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { GridDataResult } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-doc-viewer-grid-popup',
  standalone: true,
  imports: [DialogModule,GridActionItemComponent],
  templateUrl: './doc-viewer-grid-popup.component.html',
  styleUrl: './doc-viewer-grid-popup.component.css'
})
export class DocViewerGridPopupComponent implements OnInit {
  @Output() close = new EventEmitter<void>();
  @Output() selectedFile = new EventEmitter<void>();
  @Input() gridData: GridDataResult = { data: [], total: 0 };

  ngOnInit(): void {
    if (this.gridData && Array.isArray(this.gridData.data)) {
      this.gridData.data = this.gridData.data.filter(item => item.filePath);
    }
  }
  public parentColumns:any[]=[
    { field: 'fileName', title: 'File Name' },
    {
      field: 'action',
      isIconColumn: true,
      title: 'View Document',
      width:'120px',
      filteredIcons: [
        {
          url: '../assets/vectors/EyeIcon.svg',
          alt: 'View Document',
          action: 'view',
          class: 'svg-icon'
          
        }
      ]
    }
  ];
  handleButtonClick(value:any){
    this.selectedFile.emit(value);
  }
  closePopup() {
    this.close.emit();
  }
}
