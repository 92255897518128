<div>
    <h4 class="f-label" style="font-weight: 500;">Entities</h4>
    
    <div class="row colpadding0" style="margin-bottom: 10px;margin-top: 10px;">
        <div class="col-md-12 colpadding0 toolbar" >
            <div class="col-md-4 colpadding0" style=" justify-content: space-between;">
                <input kendoTextBox placeholder="Type to Search" class="f-textbox"  (keyup)="applyFilter($event)" style="width: 300px;">
            </div>
            <div class="col-md-8 colpadding0">
                <div style="display: inline" style="float: right; ">
                    <button *ngIf="userRole=='DADMN'" (click)="gotoUser()" class="figma-btn-blue" >
                        <img src="../assets/vectors/Plus.svg" alt="">
                        User
                    </button>
                    <button class="figma-btn-blue" (click)="gotoCorporate()" >
                        <img src="../assets/vectors/Plus.svg" alt="">
                        Corporate
                    </button>
                    <button *ngIf="userRole=='PERF'" (click)="downloadAnchor()" class="figma-btn-blue" >
                        <img src="../assets/vectors/ExportLogo.svg" alt="">
                        AnchorDashboardSummary
                    </button>
                    <button (click)="exportToExcel1()" class="figma-btn-blue">
                        <img src="../assets/vectors/ExportLogo.svg" alt="">
                        EntityLocation
                    </button> 
                    <button (click)="exportToExcel2()"  class="figma-btn-blue" >
                        <img src="../assets/vectors/ExportLogo.svg" alt="">
                        Entity
                    </button>

                </div>
            </div>
        </div>
    </div>

    <app-grid-action-item [gridData]="gridData" [columns]="parentColumns" [pageSize]="10" [pageable]="true" [height]="400"
        [filterable]="true" (buttonClick)="handleButtonClick($event)">
    </app-grid-action-item>

    <div *ngIf="loading" class="loader-overlay">
        <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
    </div>

    <app-alert-popup *ngIf="alertData.alertVisible" [Width]="250" [Height]="260" [Data]="alertData"
    (close)="closeAlert()"></app-alert-popup>

    <app-client-portal-login *ngIf="openClientLogin" (close)="closePopup()">

    </app-client-portal-login>