import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AgreementDetailsService {
  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient, private userService: UserService) { }

  getDropdownOptions(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MasterScoping/GetAggrementTypes`;
    return this.http.get(url, { observe: 'response' });
  }
  getPreviousSavedData(CustomerId: number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MasterScoping/GetAggrementDetails?CustomerId=${CustomerId}`;
    return this.http.get(url, { observe: 'response' });
  }
  saveAgreementDetails(agreementDetails: any): Observable<any> {
    const url = `${this.apiUrl}/api/MasterScoping/SaveAgreementDetails`;
    const formData: FormData = new FormData();
    formData.append('CustomerId', agreementDetails.CustomerId);
    formData.append('AgreementType', agreementDetails.AgreementType);
    formData.append('AgreementExecutionDate', agreementDetails.AgreementExecutionDate);
    formData.append('AgreementEffectiveDate', agreementDetails.AgreementEffectiveDate);
    formData.append('AgreementEndDate', agreementDetails.AgreementEndDate);
    formData.append('AgreementTerminationDate', agreementDetails.AgreementTerminationDate);
    formData.append('RenewalTrigger', agreementDetails.RenewalTrigger);
    formData.append('Sla', agreementDetails.Sla);
    formData.append('ClientRegisteredOfficeForTDSExemptionAddress', agreementDetails.ClientRegisteredOfficeForTDSExemptionAddress);
    formData.append('Funding', agreementDetails.Funding.toString());
    formData.append('AdvanceSecurityDeposite', agreementDetails.AdvanceSecurityDeposite.toString());
    formData.append('AdvanceSecurityDepositePercentage', agreementDetails.AdvanceSecurityDepositePercentage.toString());
    formData.append('AggreementClientSPOCName', agreementDetails.AggreementClientSPOCName);
    formData.append('AggreementClientEmailId', agreementDetails.AggreementClientEmailId);
    formData.append('AggreementClientMobileNo', agreementDetails.AggreementClientMobileNo);
    formData.append('ServiceZone', agreementDetails.ServiceZone);
    formData.append('NoOfEntities', agreementDetails.NoOfEntities.toString());
    formData.append('NoOfStates', agreementDetails.NoOfStates.toString());
    formData.append('NoOfBranches', agreementDetails.NoOfBranches.toString());
    formData.append('IndemnityClause', agreementDetails.IndemnityClause);
    formData.append('AggreementStatus', agreementDetails.AggreementStatus);

    if (agreementDetails.slauploadfile) {
      formData.append('slauploadfile', agreementDetails.slauploadfile);
    }
    if (agreementDetails.agreementcopyuploadfile) {
      formData.append('agreementcopyuploadfile', agreementDetails.agreementcopyuploadfile);
    }
    if (agreementDetails.files_Processfile) {
      formData.append('files_Processfile', agreementDetails.files_Processfile);
    }
    return this.http.post(url, formData, { observe: 'response' });
  }

}
