<h4 class="Calendar-head">My Compliance Calendar</h4>

<div class="row">
  <div class="col-md-6">
    <ejs-schedule class="custom-grid" *ngIf="!isLoading" style="border-radius: 10px;" height='300px' [selectedDate]="selectedDate"
      [eventSettings]="eventSettings" showTimeIndicator="false" (cellClick)="onDateChange($event)">
      <ng-template #cellTemplate let-data>
        <div class="templatewrap" [innerHTML]="getCellContent(data.date)"></div>
      </ng-template>
      <e-views>
        <e-view option="MonthAgenda" [showWeekend]="showWeekend"></e-view>
      </e-views>
    </ejs-schedule>

    <div class="status">
      <div class="status-item">
        <div class="circle si-complete"></div>
        <span>Completed</span>
      </div>
      <div class="status-item">
        <div class="circle si-overdue"></div>
        <span>Overdue</span>
      </div>
      <div class="status-item">
        <div class="circle si-upcoming"></div>
        <span>Upcoming</span>
      </div>
    </div>
  </div>

  <div class="col-md-6">
    <kendo-grid class="custom-grid" [data]="gridData" [columnMenu]="true" [pageSize]="pageSize" [pageable]="true"
    (pageChange)="pageChange($event)" [sortable]="true" [skip]="skip" [resizable]="true">

    <kendo-grid-column field="shortForm" title="Name">
      <ng-template kendoGridHeaderTemplate>
        <span title="Name">Name</span>
      </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.shortForm">
                {{dataItem.shortForm}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="risk" title="Status">
      <ng-template kendoGridHeaderTemplate>
        <span title="Status">Status</span>
      </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.risk">
              <div *ngIf="eventColor == 'upcoming'" class="circle si-upcoming"></div>
              <div *ngIf="eventColor == 'overdue'" class="circle si-overdue"></div>
              <div *ngIf="eventColor == 'complete'" class="circle si-complete"></div>
            </div>
        </ng-template>
    </kendo-grid-column>

    <!-- <kendo-grid-column field="Action" title="Action" [width]="120">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="cell-content" style="justify-content: space-around;">
                <img src="../assets/vectors/edit.svg" (click)="OpenAddNewPopup(dataItem)" alt="Edit Icon"
                    class="save-btn" />
                <img src="../assets/vectors/delete.svg" alt="Delete Icon" class="save-btn" *ngIf="role == 'DADMN'"
                    (click)="confirmDelete(dataItem)" />
                <img src="../assets/vectors/RightArrowButton.svg" (click)="goToEntity(dataItem)" alt="goto Icon"
                    class="save-btn" />
            </div>
        </ng-template>
    </kendo-grid-column> -->

    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-input></kendo-pager-input>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>
</kendo-grid>
  </div>
</div>