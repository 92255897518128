import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridModule, GridDataResult, PageChangeEvent, FilterService } from '@progress/kendo-angular-grid';
import { MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { MultiCheckFilterComponent } from '../multicheck-filter/multicheck-filter.component';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-grid-action-item',
  standalone: true,
  imports: [CommonModule, GridModule, MultiSelectModule, MultiCheckFilterComponent, ButtonsModule,IndicatorsModule],
  templateUrl: './grid-action-item.component.html',
  styleUrls: ['./grid-action-item.component.css'],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class GridActionItemComponent implements OnChanges {
  @Input() gridData: GridDataResult = { data: [], total: 0 };
  @Input() columns: any[] = [];
  @Input() pageSize: number = 10;
  @Input() pageable: boolean = true;
  @Input() filterable: boolean = true;
  @Output() buttonClick = new EventEmitter<{ dataItem: any, button: any }>();
  loading=false;
  public buttonCount = 5;
  public sizes = [{text:'All',value:Infinity},5, 10, 20];
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  public skip = 0;
  private items: any[] = [];
  @Input() actionColumnWidth: string = '100px';
  // @Input() height: any = '520';
  @Input() height: any = '';
  public filters: { [key: string]: any[] } = {};
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['gridData']) {
      this.loading=true;
      this.items = this.gridData.data;
      this.setColumnFilters();
    }
    setTimeout(() => {
      this.loading = false;
    }, 2000);
  }

  public pageChange(event: PageChangeEvent): void {
    this.loading = true;
    this.skip = event.skip; 
    if (event.take === Infinity) {
      this.pageSize = this.gridData.total;
    } else {
      this.pageSize = event.take;
    }
  
    setTimeout(() => {  
      this.loading = false; 
    }, 1000);
  }

  private applyFilters(items: any[]): any[] {
    
    return Object.keys(this.filters).reduce((filtered, field) => {
      const values = this.filters[field];
      return values.length ? filtered.filter(item => values.includes(item[field])) : filtered;
    }, items);
  }

  private setColumnFilters(): void {
    
    this.columns.forEach(column => {
      const firstItem = this.gridData.data[0];
      if (firstItem) {
        const fieldValue = firstItem[column.field];
        column.filter = this.determineFilterType(fieldValue, column);
      }
    });
  }

  private determineFilterType(value: any, column: any): string | null {
    
    if (typeof value === 'number') return 'numeric';
    if (typeof value === 'boolean') return 'boolean';
    if (value instanceof Date) return 'date';
    if (column.buttons) return null;
    return 'multiselect';
  }

  public getUniqueColumnValues(field: string): any[] {
    
    return Array.from(new Set(this.gridData.data.map(item => item[field])));
  }

  public onMultiSelectChange(selectedValues: any[], field: string): void {
    debugger;
    this.filters = { ...this.filters, [field]: selectedValues };
  }

  public onButtonClick(dataItem: any, button: any): void {
    
    this.buttonClick.emit({ dataItem, button });
  }

  public categoryChange(values: any[], filterService: FilterService, field: string): void {
    filterService.filter({
      filters: values.map(value => ({
        field,
        operator: "eq",
        value,
      })),
      logic: "or",
    });
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    if (filter.filters.length === 0) {
      const filteredItems = this.applyFilters(this.items);
      this.gridData = {
        data: this.items,
        total: this.items.length,
      };
    } else {
      const filteredData = filterBy(this.items, filter);
      this.gridData = {
        data: filteredData.slice(this.skip, this.skip + this.pageSize),
        total: filteredData.length,
      };
    }
  }
}
