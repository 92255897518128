<kendo-grid class="custom-grid" [kendoGridBinding]="gridData" [columnMenu]="true" [pageSize]="pageSize" [sortable]="true" [skip]="skip" 
    [filter]="filter"  [pageable]="true" filterable="menu" (filterChange)="filterChange($event)" >
    <kendo-grid-column *ngFor="let column of columns" [field]="column.field" [title]="column.title"
        [filter]="column.filter" [width]="column.width">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" title="{{ dataItem[column.field] }}">{{ dataItem[column.field] }}</div>
        </ng-template>
        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
            <kendo-grid-string-filter-menu *ngIf="column.filter === 'text'"   [column]="column" [filter]="filter">
            </kendo-grid-string-filter-menu>
            <kendo-grid-numeric-filter-menu *ngIf="column.filter === 'numeric'"  [column]="column" [filter]="filter">
            </kendo-grid-numeric-filter-menu>
            <kendo-grid-boolean-filter-menu *ngIf="column.filter === 'boolean'"  [column]="column" [filter]="filter">
            </kendo-grid-boolean-filter-menu>
            <kendo-grid-date-filter-menu *ngIf="column.filter === 'date'"  [column]="column" [filter]="filter">
            </kendo-grid-date-filter-menu>
            <multicheck-filter *ngIf="column.filter === 'multiselect'" [isPrimitive]="true" [field]="column.field"
                [filterService]="filterService" [currentFilter]="filter" [data]="getUniqueColumnValues(column.field)">
            </multicheck-filter>
        </ng-template>
       
        
    </kendo-grid-column>
    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-input></kendo-pager-input>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>
    <ng-template *ngIf="loading" kendoGridLoadingTemplate>
        <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
    </ng-template>
</kendo-grid>