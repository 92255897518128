<div class="row">
    <div class="col-md-3" style="margin-bottom: 10px;">
        <app-dropdown-list [data]="yearDropdown" [selectedValue]="selectedOption" [width]="'100%'"
                textField="text" valueField="value" (valueChange)="onDropdownValueChange($event)">
            </app-dropdown-list>
    </div>
</div>


<div class="row">
    <div class="col-md-3">
        <div class="min-card" (click)="onComplianceClick('Compliance')">
            <div style="display: flex">
                <div><img src="../../../assets/vectors/complainceSVG.svg" alt="compliance icon"></div>
                <div>
                    <div class="min-cd-title">Compliances</div>
                    <div class="min-card-count">{{countData?.compliancesCount}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-3">
        <div class="min-card" (click)="onComplianceClick('Location')">
            <div style="display: flex">
                <div><img src="../../../assets/vectors/mapPinSVG.svg" alt="map icon"></div>
                <div>
                    <div class="min-cd-title">Locations</div>
                    <div class="min-card-count">{{countData?.locationCount}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-3">
        <div class="min-card">
            <div style="display: flex">
                <div><img src="../../../assets/vectors/calendarSVG.svg" alt="calendar icon"></div>
                <div>
                    <div class="min-cd-title">Upcoming</div>
                    <div class="min-card-count">{{countData?.upcomingCount}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-3">
        <div class="min-card">
            <div style="display: flex">
                <div><img src="../../../assets/vectors/overdueSVG.svg" alt="overdue icon"></div>
                <div>
                    <div class="min-cd-title">Overdue</div>
                    <div class="min-card-count">{{countData?.overdueCount}}</div>
                </div>
            </div>
        </div>
    </div>
    
</div>

<div class="row">
    <div class="col-md-6">
        <div class="card">
            <app-cd-performance-summary [chartData]="countData"></app-cd-performance-summary>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card">
            <app-cd-risk-summary [chartData]="countData"></app-cd-risk-summary>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-6">
        <div class="card">
            <app-cd-abstract-summary></app-cd-abstract-summary>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card">
            <app-cd-applicable-status></app-cd-applicable-status>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="card">
            <app-cd-calendar [year]="selectedYear"></app-cd-calendar>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="card">
            <app-cd-daily-updates></app-cd-daily-updates>
        </div>
    </div>
</div>

<!-- Pop ups -->
 <app-dashboard-compliance-popup *ngIf="isOpenComPopup" [year]="selectedYear" [acts]="acts" [Type]="Type" (close)="onClosePopup()"></app-dashboard-compliance-popup>