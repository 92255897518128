import { Component, OnInit } from '@angular/core';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { LabourDepartmentAddressService } from '../../../service/labour-department-address.service';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { NgIf } from '@angular/common';
import { BlobService } from '../../../service/blob.service';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { LabourDepartmentAddEditPopupComponent } from "../../popups/labour-department-add-edit-popup/labour-department-add-edit-popup.component";
import { LabourDepartmentUploadPopupComponent } from '../../popups/labour-department-upload-popup/labour-department-upload-popup.component';

@Component({
  selector: 'app-labour-department-address',
  standalone: true,
  imports: [GridActionItemComponent, IndicatorsModule, NgIf, AlertPopupComponent, LabourDepartmentAddEditPopupComponent, LabourDepartmentUploadPopupComponent],
  templateUrl: './labour-department-address.component.html',
  styleUrl: './labour-department-address.component.css'
})
export class LabourDepartmentAddressComponent implements OnInit {
  loading = false;
  showUploadPopup = false;
  showAddEditPopup = false;
  pageSize: number = 10;
  public gridData: GridDataResult = { data: [], total: 0 };
  public OriginalData: any[] = [];
  parentHeader: string = 'Add Department Address';
  alertData = { alertMessage: '', alertVisible: '' }
  selectedGridData!:any;

  constructor(private LabourDepartmentService: LabourDepartmentAddressService, private blobService: BlobService) { }
  
  ngOnInit(): void {
    this.loadGridData();
  }
  onHeaderChange(newHeader: string) {

    this.parentHeader = newHeader;

  }
  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    const filteredData = this.OriginalData.filter(item =>
      (item.stateID && item.stateID.toLowerCase().includes(filterValue)) ||
      (item.adressType && item.adressType.toString().toLowerCase().includes(filterValue)) ||
      (item.uniqueID && item.uniqueID.toString().toLowerCase().includes(filterValue)) ||
      (item.addressName && item.addressName.toString().toLowerCase().includes(filterValue)) 
    );

    this.gridData = {
      data: filteredData,
      total: filteredData.length
    };
  }
  loadGridData(): void {
    
    this.LabourDepartmentService.getGridDate().subscribe({
      next: (response) => {
        debugger;
        const data = response.body;
        data.sort((a: any, b: any) => {

          const stateA = a.stateID.toLowerCase();

          const stateB = b.stateID.toLowerCase();

          return stateA < stateB ? -1 : stateA > stateB ? 1 : 0;

        });
        this.OriginalData = data;
        this.gridData = {
          data: data,
          total: data.length
        };
        
      },
      error: (err) => {
        console.error('Error fetching entity data', err);
        
      }
    });
  }
  handleButtonClick(event: any) {
    this.LabourDepartmentService.setHeader('Edit Department Address');
    if (event.button.action === 'Edit') {
      this.showAddEditPopup=true;
      this.selectedGridData = event.dataItem;
    } 
  }
  openUploadPopup() {
    this.showUploadPopup = true;
  }
  openAddNewPopup() {
    this.showAddEditPopup = true;
  }
  exportToExcel() {
    this.loading = true;
    this.LabourDepartmentService.getDepartmentAddressExcel().subscribe(
      {
        next: (response) => {
          const data = response.body;
          const blobLink = data.filePath;
          const blobName = this.blobService.extractBlobName(blobLink);
          setTimeout(() => {
            this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
              debugger;
              const sasToken = tokenResponse.result;
              this.blobService.downloadFile(blobName, sasToken);
              this.loading = false;
            });
          }, 5000);
        }, error: (error) => {
          this.alertData = { alertMessage: error.error, alertVisible: 'error' }
          this.loading = false;
        }
      });
  }

  closeAlert() {
    this.alertData = { alertMessage: '', alertVisible: '' };
  }
  closePopup() {
    this.selectedGridData='';
    this.showUploadPopup = false;
    this.showAddEditPopup = false;
    this.loadGridData();
  }
  public parentColumns: any[] = [
    { field: 'stateID', title: 'State Name' },
    { field: 'adressType', title: 'Address Type' },
    { field: 'uniqueID', title: 'Unique Number' },
    { field: 'addressName', title: 'Address' },
    {
      field: 'action',
      isIconColumn: true,
      title: 'Action',
      columnMenu: false,
      filteredIcons: [
        {
          url: '../assets/vectors/edit.svg',
          alt: 'Icon 1',
          action: 'Edit'
        }
      ]
    }
  ];
}
