<div *ngIf="data && data.length > 0; else noDataTemplate">
    <kendo-treelist [kendoTreeListFlatBinding]="data" class="custom-grid" [parentIdField]="parentIdField" [idField]="idField" [style.height]="height"
        kendoTreeListExpandable [columnMenu]="true" filterable="menu" [sortable]="true" [resizable]="true">
        
        <ng-container *ngFor="let column of columns">
            <kendo-treelist-column 
                [expandable]="column.expandable ?? false" 
                [field]="column.field" 
                [title]="column.title" 
                [width]="column.width">
                
                <ng-template kendoTreeListCellTemplate let-dataItem>
                    <div class="cell-wrapper" [ngClass]="{'center-content': column.field === 'action' || column.field === 'addressmapping'}">
                        <div *ngIf="column.iconColumn && isLeafNode(dataItem)" class="icon-container">
                            <ng-container *ngFor="let icon of column.filteredIcons">
                                <button (click)="onButtonClick(dataItem, icon.action)" class="button-with-icon"
                                    style="background: transparent; border: none; padding: 0;">
                                    <img [src]="icon.url" [alt]="icon.alt"  [title]="icon.action" class="icon-image" />
                                </button>
                            </ng-container>
                        </div>
                
                        <div class="tree-cell-content" [title]="dataItem[column.field]">
                            {{ dataItem[column.field] }}
                        </div>
                    </div>
                </ng-template>
                
                
            </kendo-treelist-column>
        </ng-container>
    </kendo-treelist>
</div>

<ng-template #noDataTemplate>
    <div class="no-data">No records available.</div>
</ng-template>
