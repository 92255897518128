<div class="row colpadding0" >
    <div class="col-md-12 colpadding0 toolbar" style="margin-top: 10px;">
        <div class="col-md-8 colpadding0" style=" justify-content: space-between;">
            <app-dropdown-list [data]="dropdownData" [selectedValue]="defaultSelectedValue"
                (valueChange)="onDropdownValueChange($event)" [width]="'200px'" [height]="'27px'" textField="text"
                valueField="value" style="margin-right: 7px;">
            </app-dropdown-list>
            <app-combo-box [width]="'350px'" [Placeholder]="defaultState" 
                     (valueChange)="DropdownGridChange($event)" [selectedValue]="selectedDropdown"
                      [data]="allStatesData">
                     </app-combo-box>
        </div>
        <div class="col-md-4 colpadding0">
            <button (click)="exportToExcel()" class="exportbtn">
                <img src="../assets/vectors/ExportButton.svg" alt="">
            </button>
            <button (click)="clearFilter()" class="figma-btn-white">
                <img src="../assets/vectors/ClearFilter.svg" alt="">
            </button>
            <button class="figma-btn-white" (click)="applyFilter()" type="submit">
                <img src="../assets/vectors/ApplyFilter.svg" alt="">
            </button>               
        </div>
    </div>
</div>

<!-- <kendo-grid *ngIf="showGrid" [data]="gridData" [columnMenu]="true" [pageSize]="pageSize" [pageable]="true"
    (pageChange)="pageChange($event)" [skip]="skip" [scrollable]="'none'" [height]="1000">
    <kendo-grid-column *ngFor="let column of parentColumns" [field]="column.field"
     [title]="column.title">
        <ng-template *ngIf="column.field==='isActive'" kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem[column.field]">
                {{ dataItem.isActive ? 'Yes' : 'No' }}
            </div>
        </ng-template>
        <ng-template *ngIf="column.field !=='isActive'" kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem[column.field]"  >
                {{ dataItem[column.field] }}
            </div>
        </ng-template>
</kendo-grid-column>
    

    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-input></kendo-pager-input>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>
</kendo-grid> -->

<kendo-grid class="custom-grid" *ngIf="showGrid" [data]="gridData" [columnMenu]="true" [pageSize]="pageSize" [pageable]="true"
    (pageChange)="pageChange($event)" [loading]="loadingGrid" [skip]="skip"   [resizable]="true"  [reorderable]="true" style="table-layout: auto;margin-top: 10px;">
    
    <kendo-grid-column *ngFor="let column of parentColumns" [field]="column.field" [title]="column.title">
      
        <ng-template *ngIf="column.field === 'isActive'" kendoGridCellTemplate let-dataItem>
            <div class="cell-content " [title]="dataItem[column.field]">
                {{ dataItem.isActive ? 'Yes' : 'No' }}
            </div>
        </ng-template>

        <ng-template *ngIf="column.field !== 'isActive'" kendoGridCellTemplate let-dataItem>
            <div class="cell-content " [title]="dataItem[column.field]">
                {{ dataItem[column.field] }}
            </div>
        </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-input></kendo-pager-input>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>

    <ng-template *ngIf="loadingGrid" kendoGridLoadingTemplate >
        <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
    </ng-template>
</kendo-grid>


<div *ngIf="loading" class="loader-overlay">
  <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>
