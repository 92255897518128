import { ChangeDetectorRef, Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';

import { WorkspaceService } from '../../../service/workspace.service';
import { NgClass, NgIf } from '@angular/common';
import { DropDownsModule, MultiSelectComponent, MultiSelectModule, MultiSelectTreeComponent } from '@progress/kendo-angular-dropdowns';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { LabelModule } from '@progress/kendo-angular-label';
import { GridModule } from '@progress/kendo-angular-grid';
import { PageChangeEvent } from '@progress/kendo-angular-treelist';

import { UserService } from '../../../service/user.service';

import { CommonService } from '../../../service/common.service';

import { UserBranchMappingService } from '../../../service/user-branch-mapping.service';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
// import { WorkspaceRemanualUploadPopupComponent } from '../popups/workspace-remanual-upload-popup/workspace-remanual-upload-popup.component';
import { Router } from '@angular/router';

import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { StatutoryService } from '../../../service/statutory.service';
import { BlobService } from '../../../service/blob.service';
import { environment } from '../../../environments/environment';


type Branch = {
  id: number;
  name: string;
  parentID: number | null;
  branchType: string;
  branchStatus: string;
  fullName: string | null;
  children?: Branch[];
};

type clraBranch = {
  parentID: number | null;
  id: number;
  name: string | null;
  level: number;
  type: string | null;
  children: clraBranch[];
};

@Component({
  selector: 'app-client-statutory-document',
  standalone: true,
  imports: [ReactiveFormsModule,AlertPopupComponent, DropdownListComponent, NgClass, MultiSelectModule, FormsModule, TreeViewModule, LabelModule, DropDownsModule, GridModule,NgIf,IndicatorsModule],
  templateUrl: './client-statutory-document.component.html',
  styleUrl: './client-statutory-document.component.css'
})
export class ClientStatutoryDocumentComponent {


  @Output() close = new EventEmitter<void>();
  form!: FormGroup
  challanGridData: any[] = []
  registerGridData: any[] = []
  returnGridData: any[] = []
  take = 10;
  skip = 0;
  page = 1;
  pageSize = 10
  allSelected: boolean = false;
  complianceType: Array<{ text: any, value: any }> = [
    { text: 'Compliance', value: 'Compliance' } 
  ];
  frequency: Array<{ text: any, value: any }> = [
    { text: 'Frequency', value: 'Frequency' } 
  ];
  Act: Array<{ text: any, value: any }> = [
    { text: 'Frequency', value: 'Frequency' } 
  ];
  years: Array<{ text: any, value:any }> = [
    { text: 'Year', value: 'Year' } // Default value
  ];
  period: Array<{ text: any, value: any }> = [
    { text: 'Period', value: 'Period' } 
  ];
  files: any[] = []
  entity: any[] = []
  selectedComplianceType: { text: any, value: any } = { text: 'Compliance', value: 'Compliance' }
  selectedFrequency: { text: any, value: any } = { text: 'Frequency', value: 'Frequency' }
  selectedAct: { text: any, value: any } = { text: 'Select Act', value: 'Select Act' }
  selectedYear: { text: any, value: any } = { text: 'Year', value: 'Year' };
  selectedPeriod: { text: any, value: any } = { text: 'Period', value: 'Period' }
  filePlaceholder:string='All Files'
  entityPlaceholder:string='Entity/Sub-Entity/Location'
  selectedFiles: any
  selectAll: boolean = false;
  type: any
  frequencyType!: any
  public clraBranchDropdown: clraBranch[] = [];
  public BranchDropdown: Branch[] = [];
  public selectedBranch: any
  IOJsonId: any
  selectedItems: any[] = []; 
  public selectedBranches: any[] = [];
  loading = false;
  openUpload=false
  inputs:any
  public alertData = { alertMessage: '', alertVisible: '' };
  parameterID:any;
  complianceID:any;
  isError:boolean = false;
  isLocalData:boolean = false;  
  file: any[] = [];
  storedBranch:any;
  isSessionData:boolean = false;
  complianceTypeLocal:any;
  private blobUrl = environment.blobUrl;

  public Download: boolean = false;
  @ViewChild('multiselectTree', { static: false }) multiselectTree!: MultiSelectTreeComponent;
  popupSettings: any = { width: '280px' };
  @ViewChild('multiselectTreeBranches', { static: false }) multiselectTreeBranches!: MultiSelectComponent;
  uploadPopupOpen: boolean | undefined;
  constructor( private blobService: BlobService,private StatutoryService: StatutoryService,private workspace: WorkspaceService,private router: Router, private fb: FormBuilder, private user: UserService, private common: CommonService, private userBranch: UserBranchMappingService,private cdr: ChangeDetectorRef) { }

  async ngOnInit(): Promise<void> {
    // this.getRegisterGrid();
    this.form = this.fb.group({
      compliance: ['', [Validators.required]],
      frequency: ['', []],
      act: ['', [Validators.required]],
      year: ['', [Validators.required]],
      period: ['', [Validators.required]],
      files: ['', [Validators.required]],
      branches: [''],
    })
 
    this.getComplianceType()
    this.getYears()
    
    this.type = 'Months'
    this.getPeriods()
    this.complianceTypeLocal = sessionStorage.getItem('complianceType')
  //   window.onload = function() {
  //     if (window.location.href == sessionStorage.getItem("complianceType")) {
  //       sessionStorage.removeItem('month');
  //       sessionStorage.removeItem('year');
  //       sessionStorage.removeItem('Input');
  //       sessionStorage.removeItem('complianceType');
  //       sessionStorage.removeItem('JsonId');
  //       sessionStorage.removeItem('formValues');
  //     }
  // }
     const storedValues = sessionStorage.getItem('formValues');
     if (storedValues) {
      this.isLocalData = true
      this.isSessionData = true
      const parsedValue = JSON.parse(storedValues);
      if (parsedValue.compliance) {
        this.selectedComplianceType = parsedValue.compliance;
        
        await this.onComplianceTypeChange(parsedValue.compliance);
       this.selectedAct = parsedValue.act;
        this.form.patchValue({
          compliance: parsedValue.compliance,
        });
      }
      if (parsedValue.frequency) {
        this.selectedAct = parsedValue.frequency
        this.onFrequencyTypeChange(parsedValue.frequency);
        this.form.patchValue({
          frequency: parsedValue.frequency,
        });
      }
      if (parsedValue.act) {
        this.selectedAct = parsedValue.act
        this.onActTypeChange(parsedValue.act);
        this.form.patchValue({
          act: parsedValue.act,
        });
      }
      if (parsedValue.year) {
        this.selectedYear = parsedValue.year
        this.onYearChange(parsedValue.year);
        this.form.patchValue({
          year: parsedValue.year,
        });
      }
      if (parsedValue.period) {
        this.selectedPeriod = parsedValue.period
        this.onPeriodChange(parsedValue.period);
        this.form.patchValue({
          period: parsedValue.period,
        });
      }
      if (parsedValue.files) {
        this.selectedFiles = parsedValue.files
        this.form.patchValue({
          files: parsedValue.files
        });
        if (this.multiselectTree) {
          this.multiselectTree.writeValue(parsedValue.files.map((file:any) => file.id));
        }
      }
      if (parsedValue.branches) {
        debugger
        this.selectedBranches = parsedValue.branches
        this.storedBranch = parsedValue.branches
        this.form.patchValue({
          branches: parsedValue.branches
        });
        if (this.multiselectTreeBranches) {
          this.multiselectTreeBranches.writeValue(parsedValue.branches.map((file:any) => file.id));
        }
      }
     
    }
   
  }


  public pageChange(event: PageChangeEvent): void {
    console.log('Page Change Event Received:', event);
    this.skip = event.skip;
    this.pageSize = event.take;

  }
  toggleSelectAll(event: Event,type:string) {
    const checked = (event.target as HTMLInputElement).checked;
    if (type === 'Register') {
      this.registerGridData.forEach(item => item.selected = checked);

      const allChecked = this.registerGridData.every(item => item.selected);
    
      // Update the download button visibility based on allChecked
      this.Download = allChecked;

      this.updateSelectedItems('Register');
    }
    else if(type==='Return'){
      this.returnGridData.forEach(item => item.selected = checked);

      const allChecked = this.returnGridData.every(item => item.selected);
    
      // Update the download button visibility based on allChecked
      this.Download = allChecked;

      this.updateSelectedItems('Return');
    }
    // else if(type==='Challan'){
    //   this.challanGridData.forEach(item => item.selected = checked);
    //   this.updateSelectedItems('Challan');
    //   this.Download=true;
    // }
    else if (type === 'Challan') {
      // Update the selected state for each item in the challanGridData
      this.challanGridData.forEach(item => item.selected = checked);
    
      // Check if all items are selected
      const allChecked = this.challanGridData.every(item => item.selected);
    
      // Update the download button visibility based on allChecked
      this.Download = allChecked;
      
      // Call your method to update selected items if needed
      this.updateSelectedItems('Challan');
    }
  
  }
  // onItemSelectionChange(dataItem: any,type:string) {
  //   dataItem.selected = !dataItem.selected;
  //   if(type==='Register')
  //   this.updateSelectedItems('Register');
  //   else if(type==='Return')
  //   this.updateSelectedItems('Return');
  //   else if(type==='Challan')
  //   this.updateSelectedItems('Challan');
    
  // }
  onItemSelectionChange(dataItem: any, type: string) {
    dataItem.selected = !dataItem.selected;

    if (dataItem.selected) {
        this.selectedItems.push(dataItem);
    } else {
        const index = this.selectedItems.indexOf(dataItem);
        if (index > -1) {
            this.selectedItems.splice(index, 1);
        }
    }

    // Update the selected items based on the type
    if (type === 'Register') {
        this.updateSelectedItems('Register');
    } else if (type === 'Return') {
        this.updateSelectedItems('Return');
    } else if (type === 'Challan') {
        this.updateSelectedItems('Challan');
    }

    // Show/hide the download button
    this.Download  = this.selectedItems.length > 0;
}

 
  getComplianceIDs(data: any) {
    // Extract complianceID, scheduleList, and returnRegisterChallanID from each object
    const complianceDetails = data.map((item: any) => {
        return {
            complianceID: item.complianceID,
            scheduleList: item.scheduleList,
            returnRegisterChallanID: item.returnRegisterChallanID
        };
    });

    // Join them as comma-separated values for each field
    const complianceIDs = complianceDetails.map((item:any) => item.complianceID).join(',');
    const scheduleLists = complianceDetails.map((item:any)=> item.scheduleList).join(',');
    const returnRegisterChallanIDs = complianceDetails.map((item:any) => item.returnRegisterChallanID).join(',');

    return {
        complianceIDs,
        scheduleLists,
        returnRegisterChallanIDs
    };
}

  updateSelectedItems(type: string) {
    if (type === 'Register') {
      this.selectedItems = this.registerGridData.filter(item => item.selected);
      this.complianceID  = this.getComplianceIDs( this.selectedItems)
      console.log(" this.complianceID ",this.complianceID)
    }
    else if (type === 'Return') {
      this.selectedItems = this.returnGridData.filter(item => item.selected);
      this.complianceID  = this.getComplianceIDs( this.selectedItems)
    }
    else if (type === 'Challan') {
      this.selectedItems = this.challanGridData.filter(item => item.selected);
      this.complianceID  = this.getComplianceIDs( this.selectedItems)
    }
   
  }

  calculateMaxTextLength(data: any[]): number {
    let maxLength = 0;

    const findMaxLength = (items: any[]) => {
      items.forEach(item => {
        maxLength = Math.max(maxLength, item.name.length);
      });
    };

    findMaxLength(data);
    return maxLength;
  }


  getComplianceType() {
    const type = 'ComplianceName';
    this.workspace.getcompliance(type).subscribe({
      next: (response) => {
        const data = response.body;
        console.log('Compliance Type', data);
        const fetchedCompliance = data.map((item: any) => ({
          text: item.text,
          value: item.value
        }));
        this.complianceType = [
          { text: 'Compliance', value: 'Compliance' }, 
          ...fetchedCompliance
        ];
      }
    });
  }
  public onComplianceTypeChange(value: any) {
    console.log("valuessss", value);
    this. isSessionData  = this.complianceTypeLocal == value.value ? true : false;
    console.log("isSessionData", this.isSessionData)
    this.selectedComplianceType = { text: value.text, value: value.value }
    console.log('slected',this.selectedComplianceType)
    this.selectedFrequency=  { text: 'Frequency', value: 'Frequency' }
    this.selectedAct= { text: 'Select Act', value: 'Select Act' }
    this.selectedYear = { text: 'Year', value: 'Year' }
    // this.selectedPeriod= { text: 'Period', value: 'Period' }
    this.selectedItems=[]
    this.filePlaceholder = 'All Files';
    this.selectedFiles = '';
    this.entityPlaceholder='Entity/Sub-Entity/Location'
    this.selectedBranch=''
    this.selectedBranches = [];  

    if(this.isLocalData ){
      this.loading = true
    }
    if (this.multiselectTree) {
      this.multiselectTree.writeValue([]);  
    }
    // this.cdr.detectChanges();
    this.getActDropdown()
    this.getFiles()
    if (this.selectedComplianceType.value === 'Return') {
      this.getFrequency()
    }
    else {
      this.frequency = []
    }
    if (this.selectedComplianceType.value === 'Register' || this.selectedComplianceType.value === 'Challan') {
      this.type = 'Months'
      console.log("month",this.type)
      this.getPeriods()
    }
    else if (this.selectedComplianceType.value === 'Return') {
      this.period = []
    }
    this.getActDropdown()
    this.getFiles()
    if (this.selectedComplianceType.value === 'Return' || this.selectedComplianceType.value === 'Challan') {
      this.getEntityDropdown();

    }
    this.form.get('compliance')?.setValue(this.selectedComplianceType);
    if (this.selectedComplianceType.value === 'Compliance') {
      this.form.get('compliance')?.setErrors({ invalidCompliance: true });
  } else {
      this.form.get('compliance')?.setErrors(null); 
  }
    if (this.form.get('compliance')?.valid) {
        this.form.get('compliance')?.markAsTouched();
    }
  
    if(this.isLocalData ){
      this.loading = false
      this.isLocalData  = false;
    }
  }
  getFrequency() {
    const type = 'Frequency'
    this.workspace.getcompliance(type).subscribe({
      next: (response) => {
        const data = response.body
        console.log('frequency Type', data);
        const fetchedFrequency= data.map((item: any) => ({
          text: item.text,
          value: item.value
        }))

        this.frequency = [
          { text: 'Frequency', value: 'Frequency' }, 
          ...fetchedFrequency
        ];
      }
    })
  }

  onFrequencyTypeChange(value: any) {
    this.selectedFrequency = { text: value.text, value: value.value }

    if (this.selectedFrequency.value === 'Annual') {
      this.type = 'SubFrequencyA'
      this.getPeriods();

    }
    else if (this.selectedFrequency.value === 'HalfYearly') {
      this.type = 'SubFrequencyH'
      this.getPeriods();

    }
    else if (this.selectedFrequency.value === 'Monthly') {
      this.type = 'Months'
      this.getPeriods();
    }
    else if (this.selectedFrequency.value === 'Quarterly') {
      this.type = 'SubFrequencyQ'
      this.getPeriods();
    }
    this.getActDropdown()
    this.form.get('frequency')?.setValue(this.selectedFrequency);
    if (this.form.get('frequency')?.valid) {
        this.form.get('frequency')?.markAsTouched();
    }
  
  }

  getActDropdown() {
    const type = this.selectedComplianceType.value;
    if (type === 'Register' || type === 'Challan') {
      this.frequencyType = 'Monthly'
    }
    else {
      this.frequencyType = this.selectedFrequency.text
    }

    this.workspace.getActs(type, this.frequencyType).subscribe({
      next: (response) => {
        const data = response.body;
        console.log('Act dropdown', data);
       const fetchedAct = data.map((item: any) => ({
          text: item.text,
          value: item.value
        }))
        this.Act = [
          { text: 'Select Act', value: 'Select Act' }, 
          ...fetchedAct
        ];
      }
    })
  }

  onActTypeChange(value: any) {
    this.selectedAct = { text: value.text, value: value.value }
    console.log(this.selectedAct.value);

    if (this.selectedComplianceType.value === 'Register') {

      this.getEntityDropdown()
    }
    this.form.get('act')?.setValue(this.selectedAct);
    if (this.selectedAct.value === 'Select Act') {
      this.form.get('act')?.setErrors({ invalidAct: true });
  } else {
      this.form.get('act')?.setErrors(null); // Clear errors if valid
  }
    if (this.form.get('act')?.valid) {
        this.form.get('act')?.markAsTouched();
    }

  }

  getYears(): void {
    const currentYear = new Date().getFullYear();
    this.years = [
      { text: 'Year', value: 'Year' } 
    ];
    for (let year = currentYear; year >= 2017; year--) {
      this.years.push({ text: year.toString(), value: year });
    }
  }
  
  onYearChange(value: any): void {
    this.selectedYear = { text: value.text, value: value.value }
    this.form.get('year')?.setValue(this.selectedYear);
    if (this.selectedYear.value === 'Year') {
      this.form.get('year')?.setErrors({ invalidYear: true });
  } else {
      this.form.get('year')?.setErrors(null); 
  }
    if (this.form.get('year')?.valid) {
        this.form.get('year')?.markAsTouched();
    }

  }

  getPeriods(): void {

    this.workspace.getcompliance(this.type).subscribe({
      next: (response) => {
        const data = response.body
        console.log('Period', data);
        const fetchedPeriod = data.map((item: any) => ({
          text: item.text,
          value: item.value
        }))
        this.period = [
          { text: 'Period', value: 'Period' }, 
          ...fetchedPeriod
        ];
        console.log("qq",this.selectedPeriod)
        if(this.selectedPeriod != null ){
         this.selectedPeriod = this.period.filter(p => p.value == this.selectedPeriod.value)[0];
          
        }
      }
    })
  }

  onPeriodChange(value: any): void {
    this.selectedPeriod = { text: value.text, value: value.value }
    this.form.get('period')?.setValue(this.selectedPeriod);
    if (this.selectedPeriod.value === 'Period') {
      this.form.get('period')?.setErrors({ invalidPeriod: true });
  } else {
      this.form.get('period')?.setErrors(null); 
  }
    if (this.form.get('period')?.valid) {
        this.form.get('period')?.markAsTouched();
    }

  }

  public tagMapper(tags: any[]): any[] {
    console.log('tags',tags);
    
    return tags.length < 3 ? tags : [tags];
  }

  buildHierarchy(data: Branch[]): Branch[] {
    const grouped: { [key: number]: Branch[] } = data.reduce((acc, item) => {
      (acc[item.parentID || -1] = acc[item.parentID || -1] || []).push(item);
      return acc;
    }, {} as { [key: number]: Branch[] });

    function buildTree(parentID: number | null): Branch[] {
      return (grouped[parentID || -1] || []).map(item => ({
        ...item,
        children: buildTree(item.id)
      }));
    }

    return buildTree(null);
  }

  getFiles() {
    const entityId = this.user.getClientId();
    const complianceType = this.selectedComplianceType.value;
  
    this.workspace.getAllFiles('I', complianceType, entityId).subscribe({
      next: (response) => {
        const data = response.body;
  
        const selectAllNode = {
          id: 'select-all',
          name: 'Select All',
          items: data.map((item: any) => ({
            id: item.id,
            name: item.name
          }))
        };
  
        this.files = [
          selectAllNode, 
          ...data.map((item: any) => ({
            id: item.id,
            name: item.name
          }))
        ];
  
        const maxLength = this.calculateMaxTextLength(this.files);
        const calculatedWidth = Math.max(280, maxLength * 10);
        this.popupSettings = { width: `${calculatedWidth}px` };
      },
      error: (err) => {
        console.error('Error fetching Files', err);
      }
    });
  }
  
  onFileChange(value: any) {
    const selectedIds = value.map((file: any) => file.id);

    if (selectedIds.includes('select-all')) {
       
        const allFileIds = this.getAllFileIds(this.files);

        this.form.controls['files'].setValue(allFileIds);
        this.selectedFiles = allFileIds.join(',');
       
        value = this.files.map((file: any) => {
            file.checked = true;
            if (file.items) {
                file.items.forEach((child: any) => child.checked = true); 
            }
            return file;
        });
    } else {
       
        this.selectedFiles = selectedIds.join(',');
        this.form.controls['files'].setValue(selectedIds);

        value.forEach((file: any) => {
            file.checked = selectedIds.includes(file.id); 
            if (file.items) {
                file.items.forEach((child: any) => {
                    child.checked = selectedIds.includes(child.id);
                });
            }
        });
    }

    this.form.get('files')?.patchValue(value);
    console.log('Selected files:', this.selectedFiles);
 
}

private getAllFileIds(files: any): string[] {
    let ids: string[] = [];

    files.forEach((file: any) => {
        if (file.id !== 'select-all') {
            ids.push(file.id); 
            if (file.items) {
               
                ids = ids.concat(this.getAllFileIds(file.items));
            }
        }
    });

    return ids;
}

  getEntityDropdown() {
    const entityId = this.user.getClientId();

    if (this.selectedComplianceType.value === 'Return' || this.selectedComplianceType.value === 'Challan') {
      this.userBranch.getBranchDropdown(entityId).subscribe({
        next: (response) => {
          console.log('branch dropdown data', response);
          const data = response.body;
          if (data && Array.isArray(data)) {
            this.BranchDropdown = this.buildHierarchy(data);
            const maxLength = this.calculateMaxTextLength(this.BranchDropdown);

            const calculatedWidth = Math.max(280, maxLength * 10);
            this.popupSettings = { width: `${calculatedWidth}px` };
          } else {
            console.error('Unexpected data format', data);
          }
        }
      })
    }

    if (this.selectedComplianceType.value === 'Register') {
      if (this.selectedAct.value === 'SEA' || this.selectedAct.value === 'FACT') {
        this.userBranch.getBranchDropdown(entityId).subscribe({
          next: (response) => {
            console.log('branch dropdown data', response);
            const data = response.body;
            if (data && Array.isArray(data)) {
              this.BranchDropdown = this.buildHierarchy(data);
              const maxLength = this.calculateMaxTextLength(this.BranchDropdown);

              const calculatedWidth = Math.max(280, maxLength * 10);
              this.popupSettings = { width: `${calculatedWidth}px` };
            } else {
              console.error('Unexpected data format', data);
            }
          }
        })
      }
      else if (this.selectedAct.value === 'FCLRA' || this.selectedAct.value === 'SCLRA') {

        this.common.getClraType(entityId).subscribe({
          next: (response) => {

            if (response.body.result === 'CAC') {
              this.userBranch.getCLRABranchDropdown(entityId).subscribe({
                next: (response) => {
                  console.log('SCLRA & FCLRA ', response);
                  const data = response.body;
                  if (data && Array.isArray(data)) {
                    this.clraBranchDropdown = this.mapHierarchy(data);
                    const maxLength = this.calculateMaxTextLengthclra(this.clraBranchDropdown);

                    const calculatedWidth = Math.max(280, maxLength * 10);
                    this.popupSettings = { width: `${calculatedWidth}px` };
                  } else {
                    console.error("Unexpected data format", data);
                  }
                }
              })
            }
            else if (response.body.result === 'CAP') {
              this.userBranch.getCLRA_PEBranchDropdown(entityId).subscribe({
                next: (response) => {
                  console.log('SCLRA & FCLRA ', response);
                  const data = response.body;
                  if (data && Array.isArray(data)) {
                    this.clraBranchDropdown = this.mapHierarchy(data);
                    const maxLength = this.calculateMaxTextLengthclra(this.clraBranchDropdown);

                    const calculatedWidth = Math.max(280, maxLength * 10);
                    this.popupSettings = { width: `${calculatedWidth}px` };
                  } else {
                    console.error("Unexpected data format", data);
                  }}})}}})}}
  }

  onEntityChange(value: any) {
    console.log('value', value);
    this.selectedBranch = value.map((branch: any) => branch.id).join(',');
    this.form.get('branches')?.patchValue(value);
    console.log('Selected branch:', this.selectedBranch);
   

  }
  calculateMaxTextLengthclra(data: clraBranch[]): number {
    let maxLength = 0;

    const findMaxLength = (items: clraBranch[]) => {
      items.forEach(item => {
        const nameLength = item.name ? item.name.length : 0;
        maxLength = Math.max(maxLength, nameLength);
        if (item.children && item.children.length) {
          findMaxLength(item.children);
        }
      });
    };
    findMaxLength(data);
    return maxLength;
  }
  private mapHierarchy(items: any[]): clraBranch[] {
    return items.map((item: any) => {
      const hasChildren = item.children && item.children.length > 0;
      return {
        parentID: item.parentID,
        id: item.id,
        name: item.name || "Unknown",
        level: item.level,
        type: item.type,
        children: hasChildren ? this.mapHierarchy(item.children) : []
      };
    });
  }

  clraTagMapper(tags: any[]): any[] {
    return tags.length < 3 ? tags : [tags];
  }
  getJsonId() {
    console.log('getJsonId')
  let commaSeparatedFiles = this.selectedFiles;
  let commaSeparatedBranch = this.selectedBranch || '';

  if(this.isSessionData ){
    commaSeparatedFiles=  commaSeparatedFiles?.flatMap((commaSeparatedFiles:any) => commaSeparatedFiles.items ? commaSeparatedFiles.items.map((item:any) => item.id) : []).join(', ');
    commaSeparatedBranch= this.storedBranch?.flatMap((commaSeparatedbranch:any) => commaSeparatedbranch.items ? commaSeparatedbranch.items.map((item:any) => item.id) : []).join(', ');
    console.log('id',commaSeparatedFiles)
    console.log('brranch',commaSeparatedBranch)
  }
  
  const ParameterId = `[{"InputID":"${commaSeparatedFiles}","BranchID":"${commaSeparatedBranch ? commaSeparatedBranch: ''}"}]`;
  this.parameterID = ParameterId
this.inputs = commaSeparatedBranch
console.log('input',this.inputs)
  console.log('ParameterId:', ParameterId);
   this.loading =true;
    this.workspace.getJsonId([], ParameterId).subscribe({
      next: (response) => {
        this.IOJsonId = response.body.result;
        this.applyFilter();
        
      }, error: (error) => {
        this.loading =false;
      }
    });
  }

  // getOutputJson(){
  //   let payload = {
  //     JsonID: `[{"ScheduleOnID":${this.complianceID.scheduleLists},"ReturnRegisterChallanID":${this.complianceID.returnRegisterChallanIDs}}]`,
  //     ParameterID:this.parameterID,
  //     ComplianceID : this.complianceID.complianceIDs,
  //     BranchID: `[{${this.inputs}}]`
  //   }
  //   console.log("getOutputJson",payload);
  //   this.workspace.OutputJSON(payload).subscribe({
  //     next: (response) => {
  //       console.log('response',response)
  //       let outputJson = response.body.result
  //       sessionStorage.setItem('JsonId', outputJson);
  //       if(outputJson){
  //         this. GetInputfileisProcess();
  //       }else{
  //         this.alertData = { alertMessage: 'There is an issue in generating JSON', alertVisible: 'error' };
  //       }
  //       // this. GetInputfileisProcess();
  //     }
  //   });
  // }

  getRegisterGrid(){
    const payload={
      customerID:this.user.getCustomerId(),
      MonthId:this.selectedPeriod.value,
      YearID:this.selectedYear.value,
      userID:this.user.getUserId(),
      EntityID:this.user.getClientId(),
      profileID:this.user.getUserId(),
      Type:this.selectedAct.value

    }
   
    // const customerID=this.user.getCustomerId();
    // // const complianceType=this.selectedComplianceType.value;
    // const MonthId=this.selectedPeriod.value;
    // const YearID=this.selectedYear.value;
    // const userID = this.user.getUserId().toString();
    // // const EntityID=this.user.getClientId();
    // const EntityID: number = parseInt(this.user.getClientId(), 10); 
    // const profileID=this.user.getUserId();
    // const Type=this.selectedAct.value
    // // const jsonId=this.IOJsonId;
    // // const ActGroup=this.selectedAct.value
    // // console.log(custID,complianceType,monthId,YearId,userId,clientId,jsonId,ActGroup);
    this.loading = true;
    this.StatutoryService.getRegisterStatutoryGrid(payload).subscribe({
      next:(response)=>{
        const data=response.body;
        console.log('Register Grid',data);
        this.registerGridData=data
        this.loading = false;
      }
    })
  }
  getReturnGrid(){     
       
    // const custID=this.user.getCustomerId();
    // const complianceType=this.selectedComplianceType.value;
    // const monthId=this.selectedPeriod.value;
    // const YearId=this.selectedYear.value;
    // const userId= this.user.getUserId();
    // const clientId=this.user.getClientId();
    // const jsonId=this.IOJsonId;
    // const ActGroup=this.selectedAct.value
    // const frequency=this.selectedFrequency.value
    // console.log(custID,complianceType,monthId,YearId,userId,clientId,jsonId,ActGroup);
    const payload={
      customerID:this.user.getCustomerId(),
      MonthId:this.selectedPeriod.value,
      YearID:this.selectedYear.value,
      userID:this.user.getUserId(),
      EntityID:this.user.getClientId(),
      profileID:this.user.getUserId(),
      frequency:this.selectedFrequency.value,
      Type:this.selectedAct.value

    }
    this.loading = true;
    this.StatutoryService.getReturnsStatutoryGrid(payload).subscribe({
      next:(response)=>{
        const data=response.body;
        console.log('Return Grid',data);
        this.returnGridData=data
        this.loading = false;
      }
    })
  }
  getChallanGrid(){     
    
    // const custID=this.user.getCustomerId();
    // const complianceType=this.selectedComplianceType.value;
    // const monthId=this.selectedPeriod.value;
    // const YearId=this.selectedYear.value;
    // const userId= this.user.getUserId();
    // const clientId=this.user.getClientId();
    // const jsonId=this.IOJsonId;
    // const ActGroup=this.selectedAct.value
   
    // console.log(custID,complianceType,monthId,YearId,userId,clientId,jsonId,ActGroup);

    const payload={
      customerID:this.user.getCustomerId(),
      MonthId:this.selectedPeriod.value,
      YearID:this.selectedYear.value,
      userID:this.user.getUserId(),
      EntityID:this.user.getClientId(),
      profileID:this.user.getUserId(),
      challanType:this.selectedAct.value
      // challanType:this.selectedComplianceType.value

    }
    this.loading = true;
    this.StatutoryService.getChallansStatutoryGrid(payload).subscribe({
      next:(response)=>{
        const data=response.body;
        console.log('Challan Grid',data);
        this.challanGridData=data
        this.loading = false;
      }
    })
  }
  onActionClick(dataItem: any,type:string) {
    console.log('Action clicked for item:', dataItem);
    console.log('Action Type',type); 
    if(type==='Upload')
      {
        this.openUpload=true
      }  
   
  }
  applyFilter() {  
    // if (this.form.invalid) {
    //   // this.form.markAllAsTouched(); 
    //   return; 
    // }
    console.log('Filter')
    if(this.selectedComplianceType.value==='Register')
        this.getRegisterGrid()

    else if(this.selectedComplianceType.value==='Return')
        this.getReturnGrid()
   
    else if(this.selectedComplianceType.value==='Challan')
        this.getChallanGrid()
   }
  clearFilter() { 
    this.selectedComplianceType = { text: 'Compliance', value: 'Compliance' }
    this.selectedFrequency=  { text: 'Frequency', value: 'Frequency' }
    this.selectedAct= { text: 'Select Act', value: 'Select Act' }
    this.selectedYear = { text: 'Year', value: 'Year' }
    this.selectedPeriod= { text: 'Period', value: 'Period' }
   
    this.selectedFiles = ''; 
    this.entityPlaceholder='Entity/Sub-Entity/Location'
    this.selectedBranch=''
    this.selectedBranches = [];  
    if (this.multiselectTree) {
      this.multiselectTree.writeValue([]);  
    }
    this.cdr.detectChanges();
    this.selectedFiles=[]
    this.selectedBranch=[]
    this.challanGridData=[]
    this.registerGridData=[]
    this.returnGridData=[]
    this.isSessionData =false;
   
    sessionStorage.removeItem('formData');
    sessionStorage.removeItem('formState');
    sessionStorage.removeItem('gridData');
  }
 
  public closeUploadPopup(): void {
    this.openUpload=false
  }
  onIconClick(action: string, dataItem: any) {
    switch (action) {
      case 'regional':
        this.handleRegionalAction(dataItem);
        break;

      // case 'english':
      //   this.handleEnglishAction(dataItem);
      //   break;

      // case 'acknowledgement':
      //   this.handleAcknowledgementDetails(dataItem);
      //   break;

      default:
        console.warn('Unknown action:', action);
        break;
    }
  }
  handleRegionalAction(dataItem: any) {
    console.log('Regional icon clicked for:', dataItem);
    this.loading = true;
    this.downloadFile(dataItem.regionalFilePath, dataItem.regionalFileName);
  }
  downloadFile(filePath: string, fileName: string) {
    debugger;
    const blobLink = this.blobUrl + filePath;
    const blobName = this.blobService.extractBlobName(blobLink);
    setTimeout(() => {
      this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
        debugger;
        const sasToken = tokenResponse.result;
        this.blobService.downloadFile(blobName, sasToken);
        this.loading = false;
      });
    }, 5000);
  }
//   GetInputfileisProcess(){
//     console.log('triggered')
//     this.cdr.detectChanges();
//   let entity = this.user.getClientId()
//   this.workspace.GetInputfileisProcessed(entity).subscribe({
//     next:(response)=>{
//       let result = response.body.result;
//       let month = this.form.get('period')?.value;
//       let year = this.form.get('year')?.value;
//       let type = this.form.get('compliance')?.value
//       if(result === true) {
//         sessionStorage.setItem('month', month.value);
//         sessionStorage.setItem('year', year.value);
//         sessionStorage.setItem('Input', this.inputs);
//         sessionStorage.setItem('complianceType', type.value);
//         sessionStorage.setItem('act', this.selectedAct.value);
//         const formValues = this.form.value;
//         sessionStorage.setItem('formValues', JSON.stringify(formValues));
//         this.router.navigate(['/DocumentGeneration'])
//       }else{
//         this.isError = true;
//         this.alertData = { alertMessage: 'Employee data is in process, please try again', alertVisible: 'error' };
//       }
     
//     }
//   })
// }


closeAlert() {
  this.alertData = { alertMessage: '', alertVisible: '' };
}



ngOnDestroy() {
  if (this.router.url !== '/DocumentGeneration' ) {
    sessionStorage.removeItem('month');
    sessionStorage.removeItem('year');
    sessionStorage.removeItem('Input');
    sessionStorage.removeItem('complianceType');
    sessionStorage.removeItem('JsonId');
    sessionStorage.removeItem('formValues');
  }
}
openUploadPopup() {
  this.uploadPopupOpen = true;
}




bulkDownloadBranchWiseRegisters() {
  // const payload={
  //   customerID:this.user.getCustomerId(),
  //   MonthId:this.selectedPeriod.value,
  //   YearID:this.selectedYear.value,
  //   userID:this.user.getUserId(),
  //   EntityID:this.user.getClientId(),
  //   profileID:this.user.getUserId(),
  //   Type:this.selectedAct.value

  // }
 
  const payload = {
    entityId: 'entity001',
    state: 'StateX',
    branch: 'BranchY',
    customerBranchID: 101,
    establishmentType: 'Private',
    documentType: 'DocumentType1',
    challanType: 'ChallanTypeA',
    month: 'October',
    year: '2024',
    actValue: 'ActValueX',
    frequency: 'Monthly',
    isStateWise: true
  };
  // {
  //   "entityId": "entity001",
  //   "state": "StateX",
  //   "branch": "BranchY",
  //   "customerBranchID": 101,
  //   "establishmentType": "Private",
  //   "documentType": "DocumentType1",
  //   "challanType": "ChallanTypeA",
  //   "month": "October",
  //   "year": "2024",
  //   "actValue": "ActValueX",
  //   "frequency": "Monthly",
  //   "isStateWise": false
  // }
  this.loading = true;
  this.StatutoryService.bulkDownloadBranchWiseRegister(payload).subscribe(
    (response: any) => {
      console.log('Full response:', response); // Check if response has data

      if (response && response.body && response.body.filePath) {
        const blobLink = response.body.filePath;
        const blobName = this.blobService.extractBlobName(blobLink);
        console.log('Blob link:', blobLink);
        console.log('Blob name:', blobName);

        setTimeout(() => {
          this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
            const sasToken = tokenResponse.result;
            console.log('SAS Token:', sasToken);

            if (sasToken) {
              this.blobService.downloadFile(blobName, sasToken);
            } else {
              console.error('Error: SAS token is null or undefined.');
            }

            this.loading = false;
          }, (tokenError) => {
            console.error('Error fetching SAS token:', tokenError);
            this.loading = false;
          });
        }, 5000);
      } else {
        console.error('Error: File path not found in response.');
        this.loading = false;
      }
    },
    (error) => {
      console.error('Error fetching blob link:', error);
      this.loading = false;
    }
  );
}


bulkDownloadBranchWiseChallan() {
  // const payload={
  //   customerID:this.user.getCustomerId(),
  //   MonthId:this.selectedPeriod.value,
  //   YearID:this.selectedYear.value,
  //   userID:this.user.getUserId(),
  //   EntityID:this.user.getClientId(),
  //   profileID:this.user.getUserId(),
  //   Type:this.selectedAct.value

  // }
 
  const payload = {
    entityId: 'entity001',
    state: 'StateX',
    branch: 'BranchY',
    customerBranchID: 101,
    establishmentType: 'Private',
    documentType: 'DocumentType1',
    challanType: 'ChallanTypeA',
    month: 'October',
    year: '2024',
    actValue: 'ActValueX',
    frequency: 'Monthly',
    isStateWise: true
  };
  // {
  //   "entityId": "entity001",
  //   "state": "StateX",
  //   "branch": "BranchY",
  //   "customerBranchID": 101,
  //   "establishmentType": "Private",
  //   "documentType": "DocumentType1",
  //   "challanType": "ChallanTypeA",
  //   "month": "October",
  //   "year": "2024",
  //   "actValue": "ActValueX",
  //   "frequency": "Monthly",
  //   "isStateWise": false
  // }
  this.loading = true;
  this.StatutoryService.bulkDownloadBranchWiseChallan(payload).subscribe(
    (response: any) => {
      console.log('Full response:', response); // Check if response has data

      if (response && response.body && response.body.filePath) {
        const blobLink = response.body.filePath;
        const blobName = this.blobService.extractBlobName(blobLink);
        console.log('Blob link:', blobLink);
        console.log('Blob name:', blobName);

        setTimeout(() => {
          this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
            const sasToken = tokenResponse.result;
            console.log('SAS Token:', sasToken);

            if (sasToken) {
              this.blobService.downloadFile(blobName, sasToken);
            } else {
              console.error('Error: SAS token is null or undefined.');
            }

            this.loading = false;
          }, (tokenError) => {
            console.error('Error fetching SAS token:', tokenError);
            this.loading = false;
          });
        }, 5000);
      } else {
        console.error('Error: File path not found in response.');
        this.loading = false;
      }
    },
    (error) => {
      console.error('Error fetching blob link:', error);
      this.loading = false;
    }
  );
}


bulkDownloadBranchWiseReturns() {
  // const payload={
  //   customerID:this.user.getCustomerId(),
  //   MonthId:this.selectedPeriod.value,
  //   YearID:this.selectedYear.value,
  //   userID:this.user.getUserId(),
  //   EntityID:this.user.getClientId(),
  //   profileID:this.user.getUserId(),
  //   Type:this.selectedAct.value

  // }
 
  const payload = {
    entityId: 'entity001',
    state: 'StateX',
    branch: 'BranchY',
    customerBranchID: 101,
    establishmentType: 'Private',
    documentType: 'DocumentType1',
    challanType: 'ChallanTypeA',
    month: 'October',
    year: '2024',
    actValue: 'ActValueX',
    frequency: 'Monthly',
    isStateWise: true
  };
  // {
  //   "entityId": "entity001",
  //   "state": "StateX",
  //   "branch": "BranchY",
  //   "customerBranchID": 101,
  //   "establishmentType": "Private",
  //   "documentType": "DocumentType1",
  //   "challanType": "ChallanTypeA",
  //   "month": "October",
  //   "year": "2024",
  //   "actValue": "ActValueX",
  //   "frequency": "Monthly",
  //   "isStateWise": false
  // }
  this.loading = true;
  this.StatutoryService.bulkDownloadBranchWiseReturn(payload).subscribe(
    (response: any) => {
      console.log('Full response:', response); // Check if response has data

      if (response && response.body && response.body.filePath) {
        const blobLink = response.body.filePath;
        const blobName = this.blobService.extractBlobName(blobLink);
        console.log('Blob link:', blobLink);
        console.log('Blob name:', blobName);

        setTimeout(() => {
          this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
            const sasToken = tokenResponse.result;
            console.log('SAS Token:', sasToken);

            if (sasToken) {
              this.blobService.downloadFile(blobName, sasToken);
            } else {
              console.error('Error: SAS token is null or undefined.');
            }

            this.loading = false;
          }, (tokenError) => {
            console.error('Error fetching SAS token:', tokenError);
            this.loading = false;
          });
        }, 5000);
      } else {
        console.error('Error: File path not found in response.');
        this.loading = false;
      }
    },
    (error) => {
      console.error('Error fetching blob link:', error);
      this.loading = false;
    }
  );
}
downloadFiles(){

  if(this.selectedComplianceType.value==='Register')
    this.bulkDownloadBranchWiseRegisters();

else if(this.selectedComplianceType.value==='Return')
    this.bulkDownloadBranchWiseReturns();

else if(this.selectedComplianceType.value==='Challan')
    this.bulkDownloadBranchWiseChallan();

}
}
