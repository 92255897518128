<div class="row colpadding0 " style="margin-top:10px;">
    <div class="col-md-12 colpadding0">

        <app-dropdown-list [data]="complianceperiods" [selectedValue]="defaultSelectedValueMonth" [width]="'200px'"
             textField="text" valueField="value" (valueChange)="onPeriod($event)">
        </app-dropdown-list>
        <!-- <app-dropdown-list [data]="years" [selectedValue]="selectedYear" [width]="'170px'" textField="text"
            style="margin-left:10px;" valueField="value" (valueChange)="onYearChange($event)" formControlName="year">
        </app-dropdown-list> -->

    <app-dropdown-list [data]="years" [selectedValue]="selectedYear" [width]="'170px'" textField="text"
        style="margin-left:10px;" valueField="value" (valueChange)="onYearChange($event)" formControlName="year">
    </app-dropdown-list>

    <button class="figma-btn-white apply-btn" type="submit" (click)="applyFilters()">
        <img src="../assets/vectors/ApplyFilter.svg" alt="">
    </button>
    <button *ngIf="showSave" class="figma-btn-blue upload-btn" (click)="OnFinalize()">
        Finalize
    </button>
    <button *ngIf="bulkdownload" class="figma-btn-blue upload-btn " (click)="bulkDownload()">
        Bulk Download
    </button>
    <!-- <img src="../assets/vectors/uploadWhite.svg"> -->
</div>
<div class="row  colpadding0" style="margin-left:0px;">
    <kendo-grid class="custom-grid" [data]="gridData" *ngIf="showGrid" [columnMenu]="{filter:true}" [pageSize]="pageSize"
    [pageable]="true" (pageChange)="pageChange($event)" [sortable]="true" [skip]="skip" [resizable]="true">
    <kendo-grid-column [width]="75" [columnMenu]="false">
        <ng-template kendoGridHeaderTemplate>
            <div class="header-column" >
                <input type="checkbox" [(ngModel)]="allSelected"
                    (change)="toggleSelectAll($event)" />
                </div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <div style="margin-left: 25%;">
                <input type="checkbox" [(ngModel)]="dataItem.selected"
                    [disabled]="dataItem.disabled" (change)="onItemSelectionChange(dataItem)" />
                </div>
                <!-- [(ngModel)]="dataItem.selected" -->
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="id" title="Document Type">
        <ng-template kendoGridHeaderTemplate>
            <span [title]="'Document Type'">Document Type</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.pfecrA_DocType">
                {{dataItem.pfecrA_DocType}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="shortForm" title="Code" >
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.pfecrA_Code">
                {{dataItem.pfecrA_Code}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="branchName" title="File Name" >
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.pfecrA_FileName">
                {{dataItem.pfecrA_FileName}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="sM_Name" title="Finalized">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.pfecrA_IsFinalized">
                {{dataItem.pfecrA_IsFinalized}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="actName" title="Processed" >
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.pfecrA_IsProcessed">
                {{dataItem.pfecrA_IsProcessed}}
            </div>
        </ng-template>
    </kendo-grid-column>
    
    <kendo-grid-column field="requiredForms" title="Form Generated" >
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.pfecrA_FinalizedDate">
                {{dataItem.pfecrA_FinalizedDate}}
            </div>
        </ng-template>
    </kendo-grid-column>
    <!-- extra head -->
    <kendo-grid-column field="requiredForms" title="Upload Status" >
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.pfecrA_AutomationStatus">
                {{dataItem.pfecrA_AutomationStatus}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="requiredForms" title="View Document" >
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" style="display:flex; justify-content:center;" [title]="dataItem.requiredForms">
                <img src="../assets/vectors/DownloadBlue.svg" class="svg-icon" title="Download"   (click)="onIconClick( dataItem)">
                <img src="../assets/vectors/EyeIcon.svg" alt="Action Icon" title="View" class="svg-icon"  (click)="onIconClickView( dataItem)" />
            </div>
        </ng-template>
    </kendo-grid-column>
    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-input></kendo-pager-input>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>
</kendo-grid>

</div>
<app-alert-popup *ngIf="alertData.alertVisible" [Width]="250" [Height]="280" [Data]="alertData"
(close)="closeAlert()"></app-alert-popup>
<app-doc-viewer-grid-popup [gridData]="viewFile" (selectedFile)="ViewFile($event)" *ngIf="viewFile" (close)="closeDocGrid()"></app-doc-viewer-grid-popup>
<app-adobe-viewer [fileBlob]="fileBlob" *ngIf="fileBlob" (close)="closeViewer()"></app-adobe-viewer>