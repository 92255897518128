<div class="col" style="width: 100%; margin-top: 10px;">
    <div class="col-md-12 colpadding0">
        <!-- <app-combo-box [width]="'280px'" [Placeholder]="'Search State/Select State'"
            (valueChange)="onStateChange($event)" [data]="statesList" [width]="'270px'">
        </app-combo-box>
        <app-dropdown-list [data]="pincodesAndLocationsList" textField="name" valueField="id"
            [selectedValue]="PincodeDefaultValue" (valueChange)="onPincodeAndLocationChange($event)" [width]="'270px'"
            [className]="'state-dropdown'" class="dropdown">></app-dropdown-list> -->
        <!-- <app-dropdown-list [data]="zoneList" textField="name" [selectedValue]="ZoneDefaultValue" valueField="id"
            (valueChange)="onZoneChange($event)" [width]="'270px'" [className]="'state-dropdown'" class="dropdown">
            ></app-dropdown-list> -->
        <app-combo-box [width]="'280px'" [Placeholder]="'Search State/Select State'"
            (valueChange)="onStateChange($event)" [data]="statesList" [width]="'250px'">
        </app-combo-box>
        <app-dropdown-list [data]="pincodesAndLocationsList" textField="name" valueField="id"
            [selectedValue]="PincodeDefaultValue" (valueChange)="onPincodeAndLocationChange($event)" [width]="'250px'"
             [className]="'state-dropdown'" class="dropdown">
        </app-dropdown-list>
        <app-combo-box-free-text [width]="'250px'" [textField]="'name'" [valueField]="'id'" class="dropdown"
            [Placeholder]="'Select Zone'" (valueChange)="onZoneChange($event)" [data]="zoneList" [allowCustom]="true">
        </app-combo-box-free-text>
        <button class="figma-btn-blue" (click)="openUploadPopup()">
            <img src="../assets/vectors/uploadWhite.svg" alt="">Upload
        </button>
        <button class="figma-btn-blue" title="Save" type="submit" (click)="onSave()">
            Save
        </button>
    </div>
</div>

<kendo-grid class="custom-grid" *ngIf="showGrid" [data]="gridData" [columnMenu]="{filter:true}" [pageSize]="pageSize"
    [pageable]="true" (pageChange)="pageChange($event)" style="margin-top: 10px;" [skip]="skip">

    <kendo-grid-column field="sM_Name" title="State" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span>{{ dataItem.sM_Name }}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="zone" title="Zone" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div *ngIf="isCellBeingEdited(rowIndex, 'zone'); else displayZone">
                <input class="f-textbox" type="text" [value]="dataItem.zone" (input)="onPeriodChange($event, dataItem)">
            </div>
            <ng-template #displayZone>
                <span (click)="editRow(rowIndex, 'zone')">{{ dataItem.zone }}</span>
            </ng-template>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="location" title="Location" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span>{{ dataItem.location }}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="pincode" title="Pincode" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span>{{ dataItem.pincode }}</span>
        </ng-template>
    </kendo-grid-column>


    <kendo-grid-column field="Action" title="Action" [width]="60">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <img src="../assets/vectors/save1.svg" alt="Save Icon" title="Save" class="svg-icon"
                style="margin-left: 5px;" (click)="save(dataItem)" />
            <img src="../assets/vectors/delete.svg" alt="Delete Icon" title="Delete" class="svg-icon"
                style="margin-left: 5px;" (click)="confirmAlert(dataItem)" />
        </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-input></kendo-pager-input>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>
</kendo-grid>


<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270"  [id]="deleteId" [Data]="alertData"
    (close)="closePopup()" (confirm)="deleteZoneMaping($event)"></app-alert-popup>
<div kendoDialogContainer *ngIf="uploadPopup">
    <app-zone-location-mapping-bulk-upload (close)="closePopup()"></app-zone-location-mapping-bulk-upload>
</div>
<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>