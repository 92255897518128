import { Component, OnInit } from '@angular/core';
import { NoticesServiceService } from '../../../service/notices-service.service';
import { GridDataResult, GridModule } from '@progress/kendo-angular-grid';
import { DatePipe, NgClass, NgIf } from '@angular/common';
import { PageChangeEvent } from '@progress/kendo-angular-treelist';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { environment } from '../../../environments/environment';
import { BlobService } from '../../../service/blob.service';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { EditNoticeComponent } from '../edit-notice/edit-notice.component';
import { DocumentSectionComponent } from '../document-section/document-section.component';
import { SDExecuterComponent } from '../sdexecuter/sdexecuter.component';
import { SMEResponseComponent } from '../smeresponse/smeresponse.component';
import { UserService } from '../../../service/user.service';
import { NoticeAddNewPopupComponent } from '../../popups/notice-add-new-popup/notice-add-new-popup.component';

@Component({
  selector: 'app-notices',
  standalone: true,
  imports: [GridModule, NgIf, DatePipe, NgClass, IndicatorsModule, AlertPopupComponent, EditNoticeComponent, DocumentSectionComponent, SDExecuterComponent, SMEResponseComponent, NoticeAddNewPopupComponent],
  templateUrl: './notices.component.html',
  styleUrl: './notices.component.css'
})
export class NoticesComponent implements OnInit {
  EmployeeOption = '';
  showOtherTabs = false;
  public buttonCount = 2;
  showAddNewPopup = false;
  public sizes = [5, 10, 20, 50];
  public originalData: any[] = [];
  public gridData: GridDataResult = { data: [], total: 0 };
  Data!: any;
  skip = 0;
  loading = false;
  today: Date = new Date();
  public pageSize: number = 10;
  PendingAssignmentCount: any = 0;
  PendingActionCount: any = 0;
  PendingExtensionCount: any = 0;
  ResponsePendingCount: any = 0;
  NoticeResponseSubmittedCount: any = 0;
  OverdueCount: any = 0;
  ClosedCount: any = 0;
  TranslationPendingCount: any = 0;
  ExtensionSubmitionCount: any = 0;
  SubmissionPendingCount: any = 0;
  role: string = ''
  DashboardRole: string = '';
  UserId!:number;
  public alertData = { alertMessage: '', alertVisible: '' };
  constructor(private NoticesService: NoticesServiceService, private blobService: BlobService, private user: UserService) { }
  ngOnInit(): void {
    this.role = this.user.getRole();
    this.UserId = this.user.getUserId();
    this.loadGridData();
    this.LoadDashboard();
    this.getDashboardRole();
  }
  getDashboardRole(){
    this.NoticesService.GetRole(this.UserId).subscribe(
      {
        next: (response) => {
          const data = response.body.result;
          this.DashboardRole = data
  
        }, error: (error) => {
          console.error('Error fetching grid data:', error);
        }
      });

  }
  isDueDateAfterToday(dueDate: string | null): boolean {
    if (!dueDate) return false;
    const dueDateObj = new Date(dueDate);
    return dueDateObj > this.today;
  }

  gridAction(value: any, type: string) {
    if (type == 'edit') {
      this.EmployeeOption = 'Notice';
      this.showOtherTabs = true;
      this.Data = value;
    } else if (type == 'download') {
      this.loading = true;
      const filePath = value?.noticeDocument;
      if (filePath) {
        const apiUrl = environment.blobUrl;
        const blobLink = apiUrl + filePath;
        const blobName = this.blobService.extractBlobName(blobLink);
        setTimeout(() => {
          this.blobService.getSasToken(blobName).subscribe(
            (tokenResponse: any) => {
              try {
                const sasToken = tokenResponse.result;
                this.blobService.downloadFile(blobName, sasToken);
                this.loading = false;
              } catch (error) {
                this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
                this.loading = false;
              }
            },
            (error) => {
              this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
              this.loading = false;
            }
          );
        }, 5000);
      } else {
        this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
        this.loading = false;
      }
    }
  }

  openAddNewPopup() {
    this.showAddNewPopup = true;
  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.loadGridData();
  }

  loadGridData(): void {
    this.loading = true;
    this.NoticesService.getNoticeDetails().subscribe({
      next: (response) => {
        console.log('grid response', response);

        if (response.status === 200) {
          const data = response.body;
          this.originalData = data
          this.gridData = {
            data: data.slice(this.skip, this.skip + this.pageSize),
            total: data.length
          };
          this.loading = false;
        }
        else {
          console.error('Error fetching entity data');
          this.loading = false;
        }
      },
      error: (error) => {
        this.loading = false;
        console.error('Error fetching entity data', error);
      }
    });
  }
  applyFilterDashboard(value: string) {
   
    const filteredData = this.originalData.filter(item =>
      (item.status && item.status.toString().toLowerCase().includes( value.toLowerCase()))
    );
    this.gridData = {
      data: filteredData.slice(this.skip, this.skip + this.pageSize),
      total: filteredData.length
    };
  }
  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    const filteredData = this.originalData.filter(item =>
      (item.clientId && item.clientId.toLowerCase().includes(filterValue)) ||
      (item.clientName && item.clientName.toString().toLowerCase().includes(filterValue)) ||
      (item.branchName && item.branchName.toString().toLowerCase().includes(filterValue)) ||
      (item.noticeType && item.noticeType.toString().toLowerCase().includes(filterValue)) ||
      (item.status && item.status.toString().toLowerCase().includes(filterValue))
    );
    this.gridData = {
      data: filteredData.slice(this.skip, this.skip + this.pageSize),
      total: filteredData.length
    };
  }

  clearFilter() {
    this.loadGridData();
  }

  LoadDashboard() {
    this.NoticesService.getPendingAssignmentCount().subscribe(
      {
        next: (response) => {
          const data = response.body.result;
          this.PendingAssignmentCount = data

        }, error: (error) => {
          console.error('Error fetching grid data:', error);
        }
      });
    this.NoticesService.getClosedCount().subscribe(
      {
        next: (response) => {
          const data = response.body.result;
          this.ClosedCount = data

        }, error: (error) => {
          console.error('Error fetching grid data:', error);
        }
      });
    this.NoticesService.getOverdueCount().subscribe(
      {
        next: (response) => {
          const data = response.body.result;
          this.OverdueCount = data
        }, error: (error) => {
          console.error('Error fetching grid data:', error);
        }
      });
    this.NoticesService.getPendingActionCount().subscribe(
      {
        next: (response) => {
          const data = response.body.result;
          this.PendingActionCount = data

        }, error: (error) => {
          console.error('Error fetching grid data:', error);
        }
      });
    this.NoticesService.getPendingExtensionCount().subscribe(
      {
        next: (response) => {
          const data = response.body.result;
          this.PendingExtensionCount = data

        }, error: (error) => {
          console.error('Error fetching grid data:', error);
        }
      });
    this.NoticesService.getResponsePendingCount().subscribe(
      {
        next: (response) => {
          const data = response.body.result;
          this.ResponsePendingCount = data

        }, error: (error) => {
          console.error('Error fetching grid data:', error);
        }
      });
    this.NoticesService.getNoticeResponseSubmittedCount().subscribe(
      {
        next: (response) => {
          const data = response.body.result;
          this.NoticeResponseSubmittedCount = data

        }, error: (error) => {
          console.error('Error fetching grid data:', error);
        }
      });
    this.NoticesService.getTranslationPendingCount().subscribe(
      {
        next: (response) => {
          const data = response.body.result;
          this.TranslationPendingCount = data

        }, error: (error) => {
          console.error('Error fetching grid data:', error);
        }
      });
    this.NoticesService.getExtensionSubmitionCount().subscribe(
      {
        next: (response) => {
          const data = response.body.result;
          this.ExtensionSubmitionCount = data

        }, error: (error) => {
          console.error('Error fetching grid data:', error);
        }
      });
    this.NoticesService.getSubmissionPendingCount().subscribe(
      {
        next: (response) => {
          const data = response.body.result;
          this.SubmissionPendingCount = data

        }, error: (error) => {
          console.error('Error fetching grid data:', error);
        }
      });
  }
  closeAlert() {
    this.alertData = { alertMessage: '', alertVisible: '' };
  }
  backToNotice() {
    this.showOtherTabs = false;
    this.EmployeeOption = '';
    this.loadGridData();
  }
  closePopup() {
    this.showAddNewPopup = false;
    this.loadGridData()
  }
}
