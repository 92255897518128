import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { environment } from '../environments/environment';

export interface EditPaycodeMapping_Dto {
  entityId: string;
  paycode: string;
  paycodeType: string;
  sequenceOrder: number;
  headername: string;
  esic_App: string;
  pF_App: string;
  pT_App: string;
  lwF_App: string;
}

export interface ClientPaycodeMappingDetails {
  cpmd_Id: number;
  cpmd_Cpm_Id: number;
  avacom_CustomerID?: number;
  cpmd_ClientID: string;
  cpmd_PayCode: string;
  cpmd_PayGroup: string;
  cpmd_Standard_Column: string;
  cpmd_Header: string;
  column_DataType: string;
  cpmd_Sequence_Order: number;
  cpmd_Deduction_Type: string;
  cpmd_Status: string;
  cpmd_appl_ESI: string;
  cpmd_Appl_PT: string;
  cpmd_Appl_LWF: string;
  cpmd_appl_PF: string;
  createdOn?: Date;
  updatedOn?: Date;
}

@Injectable({
  providedIn: 'root'
})

export class PaycodeMasterService {
  private baseUrl = environment.apiUrl;
  constructor(private http:HttpClient) { }

  getClientPaycodeMapping(clientId: string, paycode?: string): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/PaycodeMaster/GetClientPaycodeMapping?ClientID=${clientId}&Paycode=${paycode}`;
   


    return this.http.get(url,{ observe: 'response' } );

  }

  deletePaycode(recordId:number,clientId:string):Observable<HttpResponse<any>>
  {
      let url=`${this.baseUrl}/api/PaycodeMaster/DeletePaycode?RecordId=${recordId}&ClientId=${clientId}`;
    
      return this.http.get(url,{ observe: 'response' } );

  }


  uploadClientPaycodeMappings(file: File, entityId: string): Observable<HttpResponse<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('EntityId', entityId);
  
    console.log('FormData content:', formData);
  
    let url = `${this.baseUrl}/api/PaycodeMaster/UploadClientPaycodeMappings`;
  
    return this.http.post<any>(url, formData,{ observe: 'response' })
  }
  

 

  updatePaycodeMapping(updateData: EditPaycodeMapping_Dto): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/PaycodeMaster/UpdatePaycodeMapping`;
    return this.http.post(url, updateData,{ observe: 'response' })
   
  }

  saveClientPaycodeMappings(data: ClientPaycodeMappingDetails): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/PaycodeMaster/SaveClientPaycodeMappings`;
    return this.http.post(url, data,{ observe: 'response' })
  }

  getAllPaycodeMappingDropDown(clientId: string, filter: string= ''): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/PaycodeMaster/Get_AllPaycodeMappingDropDown?clientid=${clientId}&Filter=${filter}`;
    return this.http.get(url,{ observe: 'response' });
  }

  getPaycodeDropdown(type: string): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/PaycodeMaster/GetPaycodeMaster_Forddl?type=${type}`;
    return this.http.get(url, { observe: 'response' });
  }

  getStandardPaycodeDropdown():Observable<HttpResponse<any>>{
    let url = `${this.baseUrl}/api/PaycodeMaster/GetStandardPaycode_Forddl`;
    return this.http.get(url,{ observe: 'response' });
  }

}


