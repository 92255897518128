import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef, Type } from '@angular/core';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { KendoDatepickerComponent } from '../../../components/kendo-datepicker/kendo-datepicker.component';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { ManageEmployeeService } from '../../../service/manage-employee.service';
import { EsicreportService } from '../../../service/esicreport.service';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { BranchTransferPopupComponent } from '../branch-transfer-popup/branch-transfer-popup.component';
import { UserService } from '../../../service/user.service';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { EntityService } from '../../../service/entity.service';

interface Item {
  key: string;
  value: string;
}

@Component({
  selector: 'app-add-edit-entity-master',
  standalone: true,
  imports: [NgClass, DialogModule, AlertPopupComponent, NgIf, ReactiveFormsModule, FormsModule, KendoDatepickerComponent, DropdownListComponent, ComboBoxComponent, BranchTransferPopupComponent, IndicatorsModule],
  templateUrl: './add-edit-entity-master.component.html',
  styleUrl: './add-edit-entity-master.component.css'
})
export class AddEditEntityMasterComponent implements OnInit {
  EmployeeOption: string = 'Basic';
  alertData = { alertMessage: '', alertVisible: '' };
  @Output() close = new EventEmitter<void>();
  @Input() Data!: any;
  isUpdate = 0;
  ServiceProviderID!: number;
  mergedObj!: any;
  BranchData: any[] = [];
  BranchTeansferPopup = false;
  clientId = '';
  customerId !: number;
  complianceProductType !: number;
  clickButton = false;
  showClientIdAvailable: boolean = false;
  pfCodeDisabled: boolean = false;
  IsBasicValid: boolean = false;
  IsSPOCValid: boolean = false;
  CreatedDate = ''
  userID!: number;
  custId!: any;
  formBasic!: FormGroup;
  formSPOCEPD!: FormGroup;
  formInvoiceSettings!: FormGroup;
  EmpIDExist !: boolean;
  ClientIdExist  !: boolean;
  loading = false;
  ClientIdAvailable!: boolean;
  Name: string = '';
  custname:String='';
  ClientId: string = '';
  PFCode: string = '';
  ContactPerson: string = '';
  ContactNumber: string = '';
  Address: String = '';
  Email: String = '';
  status = { text: 'Select Status', value: '' };
  commencementDate: Date | null = null;
  VEmail: string | null = null;
  PFType = { text: 'Select PF Type', value: '' };
  EstablishmentType = { text: 'Select Establishment Type', value: '' };
  WagePeriodFrom = { text: 'Select Wage Period From', value: '' };
  WagePeriodTo = { text: 'Select Wage Period To', value: '' };
  PaymentDay = { text: 'Select Payment Date', value: '' };
  BonusPercentage: number | null = null;
  ActApplicability = { text: 'Select Act Applicability', value: '' };
  BonusExempted = { text: 'Select Bonus Exempted', value: '' };
  IndustryApplicability = { text: 'Select Industry Applicability', value: '0' };
  Salutation = { text: 'Select Salutation', value: '0' };
  EP1Salutation = { text: 'Select Salutation', value: '0' };
  EP2Salutation = { text: 'Select Salutation', value: '0' };
  CompanyType = { text: 'Select', value: '' };
  EDLIExcemptionType = { text: 'Select', value: '' };
  CLRAApplicable = { text: 'Select', value: '' };
  ServiceTaxExempted = { text: 'Select', value: '' };
  CLRAType = { text: 'Select', value: '' };
  SPOCFirstName: string = '';
  EP2FirstName: string = '';
  EP2LastName: string = '';
  EP2ContactNumber: string = '';
  IVSPOCName: string = '';
  IVSPOCEmail: string = '';
  IVSPOCMobileNumber: string = '';
  SPOCLastName: string = '';
  // SPOCContactNumber: number | undefined;
  SPOCContactNumber: string = '';
  SPOCEmail: string = '';
  SPOCDesignation: string = '';
  EP1LastName: string = '';
  EP2Email: string = '';
  EP2Designation: string = '';
  EP1ContactNumber: string = '';
  EP1Email: string = '';
  EP1Designation: string = '';
  EP1FirstName: string = '';
  ContractorEmployerName: string = '';
  selectedPincode: string = '';
  AgreementID: string = '';
  public RLCSFields: boolean = false;
  public showPan: boolean = false;
  public showPassport: boolean = false;
  public showESICNumber: boolean = false;
  public showUAN: boolean = false;
  public showPFNumber: boolean = false;
  public placeholder: string = 'DD-MM-YYYY';
  todayDate: Date = new Date();
  corporateId!: any
  stateCode!: string
  Role!: string
  CityCode!: string
  public showValidationErrors = false;
  public showValidationErrorsEmp = false;
  public showValidationErrorsOther = false;
  pfType = { text: 'Select', value: '' };
  state = { text: 'Select', value: '' };
  city = { text: 'Select', value: '' };
  POApplicable = { text: 'Select', value: '' };
  Mandate = { text: 'Select', value: '' };
  StateDropdownData = [
    { text: 'Select', value: '' },
  ];
  cityDropdownData = [
    { text: 'Select', value: '' },
  ];
  TypedropdownData = [
    { text: 'Select', value: '' },
    { text: 'Client', value: 'C' },
    { text: 'Branch', value: 'B' }
  ];
  EstablishmentTypedropdownData = [
    { text: 'Select', value: '' },
    { text: 'Shop & Establishment', value: 'SEA' },
    { text: 'Factory', value: 'FACT' },
    { text: 'Both', value: 'SF' }
  ];
  ActApplicabilitydropdownData = [
    { text: 'Select', value: '' },
    { text: 'Central', value: 'CEN' },
    { text: 'State', value: 'STATE' },
    { text: 'Both', value: 'Both' }
  ];
  SalutationdropdownData = [
    { text: 'Select', value: '0' },
    { text: 'Mr', value: 'Mr' },
    { text: 'Ms', value: 'Ms' }
  ];
  IndustryTypedropdownData: any[] = [
    // { text: 'Select Industry Applicability', value: '0' },
    // { text: 'Oil and Natural Gas', value: 'Oil and Natural Gas' },
    // { text: 'Banking Sector', value: 'Banking Sector' },
    // { text: 'Telecommunication', value: 'Telecommunication' },
    // { text: 'Air Transportation', value: 'Air Transportation' },
    // { text: 'Insurance', value: 'Insurance' },
    // { text: 'Mines', value: 'Mines' }
  ];
  YesNoDropdownData = [
    { text: 'Select', value: '' },
    { text: 'Yes', value: 'Y' },
    { text: 'No', value: 'N' }
  ];
  StatusDropdownData = [
    { text: 'Select', value: '' },
    { text: 'Active', value: 'A' },
    { text: 'Inactive', value: 'I' },
  ];
  EDLIExcemptionTypeDropdownData = [
    { text: 'Select', value: '' },
    { text: 'B', value: 'B' },
    { text: 'C', value: 'C' }
  ];
  CLRATypeDropdownData = [
    { text: 'Select', value: '' },
    { text: 'Client as Contractor', value: 'CAC' },
    { text: 'Client as Principal Employer', value: 'CAP' }
  ];
  dayDropdownData = [
    { text: 'Select', value: '' }
  ];
  PaymentDayDropdownData = [
    { text: 'Select', value: '' }
  ];

  MandateDropdownData = [
    { text: 'Select', value: '' },
    { text: 'One time', value: 'O' },
    { text: 'Recurring', value: 'R' },
    { text: 'Both(OneTime & Recurring)', value: 'B' },
    { text: 'PayRoll', value: 'P' },

  ];

  public CompanyTypeDropdownData: any[] = [];
  public countryDropdownData: any[] = [];
  public Entity_BranchDropdownData: any[] = [];
  public PT_StateDropdownData: any[] = [];
  public DesignationDropdownData: any[] = [];
  public Yeardropdown: any[] = [];
  public Monthdropdown: any[] = [];
  public Sectordropdown: any[] = [];
  public JobCategorydropdown: any[] = [];
  public IndustryTypedropdown: any[] = [];
  constructor(private fb: FormBuilder, private entityService: EntityService, private addEditEmployeeService: ManageEmployeeService, private cdref: ChangeDetectorRef, private esicreportService: EsicreportService, private user: UserService) {
    this.formBasic = this.fb.group({
      Name: ['', [Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]],
      ClientIds: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]*$')]],
      PFCodeNo: ['', [Validators.required, Validators.pattern(/^[a-zA-Z1-9]{22}$/)]],
      Address: ['', [Validators.required]],
      Email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]],
      ContactNumber: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      ContactPerson: ['', [Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]],
      BonusPercentage: ['', [Validators.required, Validators.pattern(/^\d{1,2}\.\d{1,2}$|^\d{1,3}$/)]]
    });
    this.formSPOCEPD = this.fb.group({
      FirstName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]],
      LastName: ['', [Validators.pattern(/^[a-zA-Z\s]*$/)]],
      ContactNumber: ['', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]],
      SPOCEmail: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]],
      SPOCDesignation: ['', [Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]],
      EP1FirstName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]],
      EP1LastName: ['', [Validators.pattern(/^[a-zA-Z\s]*$/)]],
      EP1ContactNumber: ['', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]],
      EP1Email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]],
      EP1Designation: ['', [Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]],
      EP2FirstName: ['', [Validators.pattern(/^[a-zA-Z\s]*$/)]],
      EP2LastName: ['', [Validators.pattern(/^[a-zA-Z\s]*$/)]],
      EP2ContactNumber: ['', [Validators.pattern(/^[0-9]{10}$/)]],
      EP2Email: ['', [Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]],
      EP2Designation: ['', [Validators.pattern(/^[a-zA-Z\s]*$/)]]
    });
    this.formInvoiceSettings = this.fb.group({
      IVSPOCName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]],
      IVSPOCEmail: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]],
      IVSPOCMobileNumber: ['', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]],
      AgreementID: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9@.]*$/)]],
      ContractorEmployerName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]]
    });
  }
  ngOnInit(): void {
    this.Role = this.user.getRole();
    console.log(this.Role);
    if (this.Role=='PERF' || this.Role=='RVW1') {
      this.formBasic.disable(); 
      this.formSPOCEPD.disable(); 
      this.formInvoiceSettings.disable(); 
      this.IsBasicValid = true;
      this.IsSPOCValid = true;
    } 
    this.ClientId = this.user.getFirstName();
    this.userID = this.user.getUserId();
    this.custId = localStorage.getItem('CustomerId');
    this.corporateId = localStorage.getItem('CorporateId');

    for (let i = 1; i <= 31; i++) {
      this.dayDropdownData.push({ text: i.toString(), value: i.toString() });
    }
    for (let i = 1; i <= 31; i++) {
      this.PaymentDayDropdownData.push({ text: i.toString(), value: i.toString() });
    }
    this.PaymentDayDropdownData.push({ text: 'Last day of month', value: '31' });
    this.LoadState();
    this.LoadIndustryType();
    this.GetAllComanyTypeCustomerBranch();
    this.loading = true;
    this.customerId = this.user.getCustomerId();
    this.userID = this.user.getUserId();
    console.log(this.Data);


    if (this.Data) {
      this.isUpdate = 1;
      this.GetComplianceProductType(this.Data.customerID);
      this.getServiceProviderID(this.Data.customerID);
      this.GetClientInfoByID(this.Data.id, this.Data.branchType)

    } else {
      this.loading = false;
      this.isUpdate = 0;
      this.PredefinedData('');
    }
  }

  validateAgreementID() {
    const validCharacters = /^[a-zA-Z0-9@.]*$/;
    this.AgreementID = this.AgreementID.replace(/[^a-zA-Z0-9@.]/g, '');
    this.formInvoiceSettings.get('AgreementID')?.setValue(this.AgreementID, { emitEvent: false });
  }

  restrictDateInput(event: KeyboardEvent): void {
    const invalidChars = /[0-9!@#$%^&*(),.?":{}|<>]/;

    if (invalidChars.test(event.key)) {
        event.preventDefault(); // Prevent numbers and special characters from being typed
    }
}

  validateMobileNumber() {
    this.IVSPOCMobileNumber = this.IVSPOCMobileNumber.replace(/[^0-9]/g, '');
    this.formInvoiceSettings.get('IVSPOCMobileNumber')?.setValue(this.IVSPOCMobileNumber, { emitEvent: false });
  }

  validateContactNumber() {
    this.ContactNumber = this.ContactNumber.replace(/[^0-9]/g, '');
    this.formInvoiceSettings.get('ContactNumber')?.setValue(this.ContactNumber, { emitEvent: false });
  }

  onKeyDown(event: KeyboardEvent) {
    const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Tab"];
    if (!allowedKeys.includes(event.key) && !/^[0-9]$/.test(event.key)) {
        event.preventDefault();
    }
  }

  validateContactPerson() {
    this.ContactPerson = this.ContactPerson.replace(/[^a-zA-Z\s]/g, '');
    this.formBasic.get('ContactPerson')?.setValue(this.ContactPerson, { emitEvent: false });
  }

  onKeyDownContactPerson(event: KeyboardEvent) {
    const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Tab"];
    const isLetter = /^[a-zA-Z]$/.test(event.key);
    if (!allowedKeys.includes(event.key) && !isLetter) {
        event.preventDefault();
    }
  }

  validateBasicEmail() {
    this.Email = this.Email.replace(/[^a-zA-Z0-9@.]/g, '');
    this.formBasic.get('Email')?.setValue(this.Email, { emitEvent: false });
}

validatePFCode(event: any) {
  const input = event.target as HTMLInputElement;
  this.PFCode = input.value.replace(/[^a-zA-Z0-9]/g, '');
  this.formBasic.get('PFCodeNo')?.setValue(this.PFCode, { emitEvent: false });
  if (this.PFCode.length > 22) {
      this.PFCode = this.PFCode.slice(0, 22); 
      this.formBasic.get('PFCodeNo')?.setValue(this.PFCode, { emitEvent: false });
  }
}

onKeyDownEmail(event: KeyboardEvent) {
    const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Tab", "@", "."];
    const isLetterOrNumber = /^[a-zA-Z0-9]$/.test(event.key);
    if (!allowedKeys.includes(event.key) && !isLetterOrNumber) {
        event.preventDefault();
    }
}

  validateEmail() {
    this.IVSPOCEmail = this.IVSPOCEmail.replace(/[^a-zA-Z0-9@.]/g, '');
    this.formInvoiceSettings.get('IVSPOCEmail')?.setValue(this.IVSPOCEmail, { emitEvent: false });
  }

  validateEP2Email() {
    const regex = /^[a-zA-Z0-9.@]*$/; 
    const input = this.EP2Email.split('').filter(char => regex.test(char)).join('');
    this.EP2Email = input;
    this.formSPOCEPD.get('EP2Email')?.setValue(this.EP2Email, { emitEvent: false });
  }

  validateSPOCName() {
    this.IVSPOCName = this.IVSPOCName.replace(/[^a-zA-Z\s]/g, '');
    this.formInvoiceSettings.get('IVSPOCName')?.setValue(this.IVSPOCName, { emitEvent: false });
  }
  validateClientId(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    const validKeyPattern = /^[0-9a-zA-Z]*$/; 
    if (!validKeyPattern.test(event.key) && event.key.length === 1) {
        event.preventDefault();
    }
}

  validateClientName(event: KeyboardEvent) {
    console.log('custname',this.custname)
    const input = event.target as HTMLInputElement;
    const value = input.value;
    const validKeyPattern = /^[a-zA-Z]$/;
    if (!validKeyPattern.test(event.key) && event.key.length === 1) {
        event.preventDefault();
    } else {
        this.Name = value.replace(/[^a-zA-Z]/g, ''); 
        this.formInvoiceSettings.get('Name')?.setValue(this.Name, { emitEvent: false });
    }
}
onKeyDownName(event: KeyboardEvent) {
  const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Tab", " "]; // Allow control keys and space
  const inputValue = (event.target as HTMLInputElement).value;
  const isFirstCharacter = inputValue.length === 0;
  const isLetter = /^[a-zA-Z]$/.test(event.key);

  // Prevent typing if the first character is a space or if the input is invalid
  if ((isFirstCharacter && event.key === ' ') || (!allowedKeys.includes(event.key) && !isLetter)) {
    event.preventDefault();
  }
}

  validateContractorEmployerName() {
    this.ContractorEmployerName = this.ContractorEmployerName.replace(/[^a-zA-Z\s]/g, '');
    this.formInvoiceSettings.get('ContractorEmployerName')?.setValue(this.ContractorEmployerName, { emitEvent: false });
  }

  validateEP2Designation() {
    this.EP2Designation = this.EP2Designation.replace(/[^a-zA-Z\s]/g, '');
    this.formSPOCEPD.get('EP2Designation')?.setValue(this.EP2Designation, { emitEvent: false });
  }

  validateEP2ContactNumber() {
    const regex = /^[0-9]*$/;
    const input = this.EP2ContactNumber.split('').filter(char => regex.test(char)).join('');
    this.EP2ContactNumber = input;
    this.formSPOCEPD.get('EP2ContactNumber')?.setValue(this.EP2ContactNumber, { emitEvent: false });
  }

  validateEP2FirstName() {
    const regex = /^[a-zA-Z ]*$/;
    const input = this.EP2FirstName.split('').filter(char => regex.test(char)).join('');
    this.EP2FirstName = input;
    this.formSPOCEPD.get('EP2FirstName')?.setValue(this.EP2FirstName, { emitEvent: false });
  }

  validateEP1Designation(event: KeyboardEvent): void {
    const allowedPattern = /^[a-zA-Z]+$/;
    const inputChar = String.fromCharCode(event.keyCode);    
    if (!allowedPattern.test(inputChar)) {
        event.preventDefault();    }
  }

  validateEmailInput() {
    const regex = /^[a-zA-Z0-9@.]*$/;
    const input = this.EP1Email.split('').filter(char => regex.test(char)).join('');
    this.EP1Email = input;
    this.formSPOCEPD.get('EP1Email')?.setValue(this.EP1Email, { emitEvent: false });
}

validateSPOCEmail() {
  const regex = /^[a-zA-Z0-9@.]*$/;
  const input = this.SPOCEmail.split('').filter(char => regex.test(char)).join('');
  this.SPOCEmail = input;
  this.formSPOCEPD.get('SPOCEmail')?.setValue(this.SPOCEmail, { emitEvent: false });
}

validateEP1ContactNumber() {
  const regex = /^[0-9]*$/;
  const input = this.EP1ContactNumber.split('').filter(char => regex.test(char)).join('');
  this.EP1ContactNumber = input;
  this.formSPOCEPD.get('EP1ContactNumber')?.setValue(this.EP1ContactNumber, { emitEvent: false });
}

validateEP1FirstNameInput() {
  const regex = /^[a-zA-Z]*$/; 
  const input = this.EP1FirstName.split('').filter(char => regex.test(char)).join('');
  this.EP1FirstName = input;
  this.formSPOCEPD.get('EP1FirstName')?.setValue(this.EP1FirstName, { emitEvent: false });
}
validateEP1LastNameInput() {
  const regex = /^[a-zA-Z]*$/; 
  const input = this.EP1LastName.split('').filter(char => regex.test(char)).join('');
  this.EP1LastName = input;
  this.formSPOCEPD.get('EP1LastName')?.setValue(this.EP1LastName, { emitEvent: false });
}

validateDesignationInput() {
  const regex = /^[a-zA-Z]*$/; 
  const input = this.SPOCDesignation.split('').filter(char => regex.test(char)).join('');
  this.SPOCDesignation = input;
  this.formSPOCEPD.get('SPOCDesignation')?.setValue(this.SPOCDesignation, { emitEvent: false });
}

validateContactNumberInput() {
  const regex = /^[0-9]*$/;
  const input = this.SPOCContactNumber.split('').filter(char => regex.test(char)).join('');
  this.SPOCContactNumber = input;
  this.formSPOCEPD.get('ContactNumber')?.setValue(this.SPOCContactNumber, { emitEvent: false });
}

validateFirstNameInput() {
  const regex = /^[a-zA-Z]*$/;
  const input = this.SPOCFirstName.split('').filter(char => regex.test(char)).join('');
  this.SPOCFirstName = input;
  this.formSPOCEPD.get('FirstName')?.setValue(this.SPOCFirstName, { emitEvent: false });
}

validateLastNameInput() {
  const regex = /^[a-zA-Z]*$/;
  const input = this.SPOCLastName.split('').filter(char => regex.test(char)).join('');
  this.SPOCLastName = input;
  this.formSPOCEPD.get('LastName')?.setValue(this.SPOCLastName, { emitEvent: false });
}

restrictBonusPercentageInput(event: KeyboardEvent) {
        const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete', 'Enter'];
        const key = event.key;
        const regex = /^[0-9]$|^\.$/;
        if (!allowedKeys.includes(key) && !regex.test(key)) {
            event.preventDefault();
        }
    }

    updateBonusPercentage(event: any) {
        const inputValue = event.target.value;
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (regex.test(inputValue)) {
            this.BonusPercentage = inputValue ? parseFloat(inputValue) : null;
        } else {
            event.target.value = this.BonusPercentage !== null ? this.BonusPercentage.toString() : '';
        }
        this.formBasic.get('BonusPercentage')?.setValue(this.BonusPercentage, { emitEvent: false });
    }
  PredefinedData(value: any) {
    console.log('mergedObj ', value);
    if (value.cM_ClientID) {
      this.ClientIdAvailable = true;
      this.clickButton = false;
    }

    this.GetStateCodeByStateID(value.stateID)
    this.GetCityCodeByCityID(value.cityID)
    //this.ContactNumber=value.ContactNumber;
    this.EP1FirstName = value.cB_EP1_NAME;
    this.EP1LastName = value.cB_EP1LASTNAME;
    this.EP1ContactNumber = value.cB_EP1_CONTACT;
    this.EP1Email = value.cB_EP1_EMAIL;
    this.EP1Designation = value.cB_EP1_DESIGNATION;
    this.EP2FirstName = value.cB_EP2_NAME;
    this.EP2LastName = value.cB_EP2LASTNAME;
    this.EP2ContactNumber = value.cB_EP2_CONTACT;
    this.EP2Email = value.cB_EP2_EMAIL;
    this.EP2Designation = value.cB_EP2_DESIGNATION;
    this.IVSPOCName = value.userName;
    this.IVSPOCEmail = value.email;
    this.IVSPOCMobileNumber = value.mobileNo;
    this.AgreementID = value.cM_AgreementID;
    this.ContractorEmployerName = value.cB_Contractor_EmployerName;

    this.Name = value.name;
    this.ClientId = value.cM_ClientID || '';
    this.PFCode = value.cM_PFCode || '';
    this.Address = value.addressLine1 || '';
    this.Email = value.emailID || '';
    this.ContactPerson = value.contactPerson || '';
    this.ContactNumber = value.landline || '';
    this.SPOCFirstName = value.cB_SPOC_NAME;
    this.SPOCLastName = value.cB_SPOC_LASTNAME;
    this.SPOCContactNumber = value.cB_SPOC_CONTACT;
    this.SPOCEmail = value.cB_SPOC_EMAIL;
    this.SPOCDesignation = value.cB_SPOC_DESIGNATION;
    this.BonusPercentage = value.cM_BonusPercentage || '';
    this.PFType = { text: '', value: value.cB_PF_CodeType || '' }
    this.state = { text: '', value: value.stateID.toString() || '' }
    this.LoadCity(value.stateID);
    this.city = { text: '', value: value.cityID.toString() || '' }
    this.status = { text: '', value: value.cM_Status || '' }

    this.commencementDate = value.cB_DateOfCommencement ? new Date(value.cB_DateOfCommencement) : null;
    this.WagePeriodFrom = { text: '', value: value.cB_WagePeriodFrom || '' }
    this.WagePeriodTo = { text: '', value: value.cB_WagePeriodTo || '' }
    this.PaymentDay = { text: '', value: value.cB_PaymentDate || '' }
    this.EstablishmentType = { text: '', value: value.cM_EstablishmentType || '' }
    this.ActApplicability = { text: '', value: value.cB_ActType || '' }
    this.IndustryApplicability = { text: '', value: value.industry?.toString() || '0' }
    if (value.cM_Excemption == true) {
      this.BonusExempted = { text: '', value: 'Y' }
    } else if (value.cM_Excemption == false) {
      this.BonusExempted = { text: '', value: 'N' };
    } else {
      this.BonusExempted = { text: '', value: '' };
    }
    this.Salutation = { text: '', value: value.cB_SPOCSANITATION || '0' }
    this.EP1Salutation = { text: '', value: value.cB_EP1_SANITATION || '0' }
    if (value.cM_IsPOApplicable == true) {
      this.POApplicable = { text: '', value: 'Y' }
    } else if (value.cM_IsPOApplicable == false) {
      this.POApplicable = { text: '', value: 'N' };
    } else {
      this.POApplicable = { text: '', value: '' };
    }
    if (value.isCLRAApplicable == true) {
      this.CLRAApplicable = { text: '', value: 'Y' }
    } else if (value.isCLRAApplicable == false) {
      this.CLRAApplicable = { text: '', value: 'N' };
    } else {
      this.CLRAApplicable = { text: '', value: '' };
    }
    // this.CompanyType = { text: '', value: value.comType.toString() || '' }
    this.CompanyType = this.CompanyTypeDropdownData.find(type => type.value === value.comType.toString()) || { text: '', value: '' };
    this.EDLIExcemptionType = { text: '', value: value.cB_EDLI_ExcemptionType || '' }
    this.Mandate = { text: '', value: value.cM_ContractType || '' };
    this.ServiceTaxExempted = { text: '', value: value.cB_ServiceTaxExmpted || '' }
    this.CLRAType = { text: '', value: value.clrA_Type || '' }
    this.EP2Salutation = { text: '', value: value.cB_EP2_SANITATION || '0' }

    this.loading = false;
  }
  TabValidation(value: string) {
    // this.EmployeeOption = value;
    if (value == "Basic" && this.IsBasicValid) {
      this.EmployeeOption = value;
    } else if (value == "SPOC" && this.IsBasicValid) {
      this.EmployeeOption = value;
    } else if (value == "Setting" && this.IsSPOCValid) {
      this.EmployeeOption = value;
    }
  }
  CheckClientIdExists() {
    if (this.ClientId.length > 0 && this.ClientId != this.mergedObj?.cM_ClientID) {
      this.entityService.CheckClientIdExists(this.ClientId).subscribe(
        {
          next: (response) => {
            const data = response.body;
            if (data) {
              this.ClientIdExist = true;
              this.ClientIdAvailable = false;
              this.showClientIdAvailable = false;
            } else {
              this.ClientIdExist = false;
              this.ClientIdAvailable = true;
              this.showClientIdAvailable = true;
              setTimeout(() => {
                this.showClientIdAvailable = false;
              }, 5000);
            }
          },
          error: (error) => {
            this.ClientIdAvailable = false;
            this.ClientIdExist = false;
            console.error('Error fetching Client ID', error);
          }
        }
      );
    }
  }
  LoadIndustryType() {
    this.entityService.GetAllIndustry().subscribe(
      {
        next: (response) => {
          const data: Item[] = response.body;
          this.IndustryTypedropdownData = [{ text: 'Select Industry Applicability', value: '0' },
          ...data.map((item: any) => ({
            text: item.value,
            value: item.key
          }))];
        }, error: (error) => {
          console.error('Error fetching City Dropdown data', error);
        }
      })
  }
  LoadState() {
    this.entityService.FillStates().subscribe(
      {
        next: (response) => {
          const data: Item[] = response.body;
          this.StateDropdownData = [{ text: 'Select State', value: '' },
          ...data.map((item: any) => ({
            text: item.value,
            value: item.key
          }))];
          console.log(this.StateDropdownData, "State Dropdownlist")
        }, error: (error) => {
          console.error('Error fetching City Dropdown data', error);
        }
      })
  }
  LoadCity(stateId: string) {
    this.entityService.FillLocationCityByStateID(stateId).subscribe(
      {
        next: (response) => {
          const data: Item[] = response.body;
          this.cityDropdownData = [{ text: 'Select City', value: '' },
          ...data.map((item: any) => ({
            text: item.value,
            value: item.key
          }))];
          console.log('cities',this.cityDropdownData);
        }, error: (error) => {
          console.error('Error fetching City Dropdown data', error);
          this.loading = false;
        }
      })
  }
  GetAllComanyTypeCustomerBranch() {
    this.entityService.GetAllComanyTypeCustomerBranch().subscribe(
      {
        next: (response) => {
          const data: Item[] = response.body;

          this.CompanyTypeDropdownData = [{ text: 'Select ', value: '' },
          ...data.map((item: any) => ({
            text: item.value,
            value: item.key
          }))]
        }, error: (error) => {
          console.error('Error fetching City Dropdown data', error);
          this.loading = false;
        }
      })
  }
  applyFilterEmpID(event: any) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.addEditEmployeeService.ISEmpIDExistCheckExist(filterValue, this.clientId).subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data.result == true) {
            this.EmpIDExist = true;
          } else {
            this.EmpIDExist = false;
          }
        }, error: (error) => {
          console.error('Error fetching State Dropdown data', error);
        }
      })
  }
  getServiceProviderID(value: number) {
    this.entityService.GetServiceProviderID(value).subscribe({
      next: (response) => {
        const data = response.body;
        this.ServiceProviderID = data.result;
        console.log('ServiceProviderID ', this.ServiceProviderID);
        if (this.ServiceProviderID == 94) {
          this.RLCSFields = true;
        }
        else {
          this.RLCSFields = false;
        }

      }, error: (error) => {
        console.error('Error:', error);
      }
    })
  }
  GetClientInfoByID(id: number, BranchType: string) {
    this.entityService.GetClientInfoByID(id, BranchType).subscribe({
      next: (response) => {
        
        
        const data = response.body;
        this.GetClientBasicByID(data.avacoM_BranchID, data.cM_ClientID, data);

      }, error: (error) => {
        console.error('Error:', error);
      }
    })
  }
  GetClientBasicByID(id: number, clientId: string, data1: any) {
    this.entityService.GetClientBasicByID(id, clientId).subscribe({
      next: (response) => {
        const data = response.body;
        this.GetByIDBranch(data.avacoM_BranchID, data1, data)
      }, error: (error) => {
        console.error('Error:', error);
      }
    })
  }
  GetByIDBranch(customerBranchID: number, data1: any, data2: any) {
    this.entityService.GetByIDBranch(customerBranchID).subscribe({
      next: (response) => {
        const data = response.body;
        this.mergedObj = { ...data1, ...data2, ...data };
        this.GetInvoiceCommunicationMaster(this.Data.cM_ClientID, this.mergedObj)
      }, error: (error) => {
        console.error('Error:', error);
      }
    })
  }
  GetInvoiceCommunicationMaster(clientId: string, mergedata: any) {
    this.entityService.GetInvoiceCommunicationMaster(clientId).subscribe({
      next: (response) => {
        const data = response.body;
        this.mergedObj = { ...mergedata, ...data }
        this.PredefinedData(this.mergedObj);
        console.log('invoice', data);


      }, error: (error) => {
        console.error('Error:', error);
      }
    })
  }
  GetComplianceProductType(value: number) {
    this.entityService.GetComplianceProductType(value).subscribe(
      {
        next: (response) => {
          const data = response.body;
          this.complianceProductType = data.result;
        }, error: (error) => {
          console.error('Error fetching data', error);
          this.loading = false;
        }
      });
  }
  GetStateCodeByStateID(value: any) {
    const stateId=parseInt(value)
    console.log('stateId',stateId)
    this.entityService.GetStateCodeByStateID(stateId).subscribe(
      {
        next: (response) => {
          const data = response.body;
          this.stateCode = data.result;
        }, error: (error) => {
          console.error('Error fetching data', error);
          this.loading = false;
        }
      });
  }
  GetCityCodeByCityID(value: any) {
    const cityID=parseInt(value);
    console.log('cityID',cityID)
    this.entityService.GetCityCodeByCityID(cityID).subscribe(
      {
        next: (response) => {
          const data = response.body;
          this.CityCode = data.result;
        }, error: (error) => {
          console.error('Error fetching data', error);
          this.loading = false;
        }
      });
  }
  InitData(client: string, value: string) {
    this.addEditEmployeeService.getAllEmployeeMastersEditData(client, value).subscribe(
      {
        next: (response) => {
          const data = response.body;
          this.Data = data;
          this.PredefinedData(data)
          console.log('init', data);
        }, error: (error) => {
          console.error('Error fetching data', error);
          this.loading = false;
        }
      });
  }

  onDropdownValueChange(value: any, type: string) {
    if (type === 'PFType') {
      if (value.value === 'B') {
        this.PFCode = '';
        this.formBasic.get('PFCodeNo')?.disable();
        this.pfCodeDisabled = true;
      }
      else {
        this.formBasic.get('PFCodeNo')?.enable();
        this.pfCodeDisabled = false;
      }
      this.PFType = { text: value.text, value: value.value };
    }
    else if (type === "State") {

      if (value.value !== '') {
        this.LoadCity(value.value);
        this.GetStateCodeByStateID(value.value);
      }
      this.state = { text: value.text, value: value.value };
    }
    else if (type === "City") {
      this.city = { text: value.text, value: value.value };
      this.onPincodeAndLocationChange(value.text)
      this.GetCityCodeByCityID(value.value);
    }
    else if (type === "Status") {
      this.status = { text: value.text, value: value.value };
    }
    else if (type === "WagePeriodFrom") {
      this.WagePeriodFrom = { text: value.text, value: value.value };
    }
    else if (type === "WagePeriodTo") {
      this.WagePeriodTo = { text: value.text, value: value.value };
    }
    else if (type === "PaymentDay") {
      this.PaymentDay = { text: value.text, value: value.value };
    }
    else if (type === "EstablishmentType") {
      this.EstablishmentType = { text: value.text, value: value.value };
    }
    else if (type === "IndustryApplicability") {
      this.IndustryApplicability = { text: value.text, value: value.value };
    }
    else if (type === "ActApplicability") {
      this.ActApplicability = { text: value.text, value: value.value };
    }
    else if (type === "BonusExempted") {
      this.BonusExempted = { text: value.text, value: value.value };
    }
    else if (type === "Salutation") {
      this.Salutation = { text: value.text, value: value.value };
    }
    else if (type === "EP1Salutation") {
      this.EP1Salutation = { text: value.text, value: value.value };
    }
    else if (type === "EP2Salutation") {
      this.EP2Salutation = { text: value.text, value: value.value };
    }
    else if (type === "CompanyType") {
      this.CompanyType = { text: value.text, value: value.value };
    }
    else if (type === "EDLIExcemptionType") {
      this.EDLIExcemptionType = { text: value.text, value: value.value };
    }
    else if (type === "CLRAApplicable") {
      this.CLRAApplicable = { text: value.text, value: value.value };
    }
    else if (type === "ServiceTaxExempted") {
      this.ServiceTaxExempted = { text: value.text, value: value.value };
    }
    else if (type === "POApplicable") {
      this.POApplicable = { text: value.text, value: value.value };
    }
    else if (type === "CLRAType") {
      this.CLRAType = { text: value.text, value: value.value };
    }
    else if (type === "Mandate") {
      this.Mandate = { text: value.text, value: value.value };
    }
  }
  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear().toString();

    return `${day}/${month}/${year}`;
  }

  onPincodeAndLocationChange(pincodeAndLocation: any) {
    this.selectedPincode = pincodeAndLocation.split('-')[1];
  }

  OnSave() {
    this.formInvoiceSettings.get('IVSPOCMobileNumber')?.markAsTouched();
    this.loading = true;
    let statuscode = ''
    if (this.status.value == 'A') {
      statuscode = 'Active';
    } else {
      statuscode = 'InActive';
    }
    const bodymain = {
      customerID: parseInt(this.custId),
      cO_CorporateID: this.corporateId.toString(),
      cM_ClientID: this.ClientId,
      cM_ActType: this.ActApplicability.text || '',
      cM_EstablishmentType: this.EstablishmentType.value || '',
      cM_State: this.stateCode,
      cM_City: this.CityCode,
      cM_Address: this.Address,
      cM_BonusPercentage: this.BonusPercentage?.toString() || '',
      cM_Excemption: this.BonusExempted.value || '',
      cB_WagePeriodFrom: this.WagePeriodFrom.value || '',
      cB_WagePeriodTo: this.WagePeriodTo.value || '',
      cB_PaymentDate: this.PaymentDay.value || '',
      cB_ServiceTaxExmpted: this.ServiceTaxExempted.value || '',
      cB_DateOfCommencement: this.commencementDate ? this.formatDate(this.commencementDate.toISOString()) : '',
      pfCodeType: this.PFType.value || '',
      cM_PFCode: this.PFCode || '',
      cM_Status: statuscode || '',
      contactPerson: this.ContactPerson || '',
      landline: this.ContactNumber || '',
      emailID: this.Email || '',
      type: this.CompanyType.text || '',
      cB_EDLI_ExcemptionType: this.EDLIExcemptionType.value || '',
      cM_IsPOApplicable: this.POApplicable.value,
      cM_AgreementID: this.AgreementID,
      cM_ContractType: this.Mandate.value || '',
      cM_BDAnchor: "",
      cM_RAMAnchor: "",
      cM_ProcessAnchor: "",
      cM_ChallanAnchor: "",
      cM_ReturnsAnchor: "",
      cM_LocationAnchor: "",
      cM_RLCSAnchor: "",
      cM_AuditAnchor: "",
      cB_SPOCSANITATION: this.Salutation.value || '0',
      cB_SPOC_NAME: this.SPOCFirstName || '',
      cB_SPOC_LASTNAME: this.SPOCLastName || '',
      cB_SPOC_CONTACT: this.SPOCContactNumber || '',
      cB_SPOC_EMAIL: this.SPOCEmail || '',
      cB_SPOC_DESIGNATION: this.SPOCDesignation || '',
      cB_EP1_SANITATION: this.EP1Salutation.value || '0',
      cB_EP1_NAME: this.EP1FirstName || '',
      cB_EP1LASTNAME: this.EP1LastName || '',
      cB_EP1_CONTACT: this.EP1ContactNumber || '',
      cB_EP1_EMAIL: this.EP1Email || '',
      cB_EP1_DESIGNATION: this.EP1Designation || '',
      cB_EP2_SANITATION: this.EP2Salutation.value || '0',
      cB_EP2_NAME: this.EP2FirstName || '',
      cB_EP2LASTNAME: this.EP2LastName || '',
      cB_EP2_CONTACT: this.EP2ContactNumber || '',
      cB_EP2_EMAIL: this.EP2Email || '',
      cB_EP2_DESIGNATION: this.EP2Designation || '',
      fileID: 0,
      rowNo: 0,
      avacoM_BranchID: this.mergedObj?.avacoM_BranchID || 0,
      isCLRAApplicable: this.CLRAApplicable.value || '',
      clrA_Type: this.CLRAType.text || '',
      cB_Contractor_EmployerName: this.ContractorEmployerName,
      cM_Industry_Type: this.IndustryApplicability.value,
      cM_ClientName: this.Name || ''
    }
    console.log('ts body', bodymain);

    this.showValidationErrorsOther = true;
    if (this.formSPOCEPD.valid &&  this.formBasic.valid &&  this.formInvoiceSettings.valid && this.IsBasicValid ) {
      this.entityService.AddEditEntityMaster(bodymain, this.userID).subscribe({
        next: (response) => {
          const data = response.body.result;
          console.log(response);
          if (response.status == 200) {
            if (response.body.result == 'success') {
              this.SaveInvoice();
            } else {
              this.loading = false;
              this.alertData = { alertMessage: 'Error while saving Data', alertVisible: 'error' };
            }
          } else {
            this.alertData = { alertMessage: 'Error while saving Data', alertVisible: 'error' };
            this.loading = false;
          }
        }, error: (error) => {
          this.loading = false;
          console.log(error);
          this.alertData = { alertMessage: 'Error while saving Data', alertVisible: 'error' };
        }
      }
    );
  }
  else {
      this.loading = false;
      this.formInvoiceSettings.markAllAsTouched();
      this.formSPOCEPD.markAllAsTouched();
      this.formBasic.markAllAsTouched();
    }
  }
  SaveInvoice(){
    let formattedDate = new Date();
    const InvoiceCommunication = {
      userName: this.IVSPOCName,
      mobileNo: this.IVSPOCMobileNumber,
      clientid: this.ClientId,
      email: this.IVSPOCEmail,
      created_Date: this.formatDateForSave(formattedDate.toISOString())
    }
    this.entityService.InsertInvoiceCommunicationMaster(InvoiceCommunication).subscribe({
      next: (response) => {
        const data = response.body.result;
        console.log(response);
        if (response.status == 200) {
          if (response.body.result == true) {
            if (this.Data) {
              this.loading = false;
              this.alertData = { alertMessage: 'Entity Updated Successfully', alertVisible: 'success' };
            } else {
              this.loading = false;
              this.alertData = { alertMessage: 'Entity Added Successfully', alertVisible: 'success' };
            }
          } else {
            this.loading = false;
            this.alertData = { alertMessage: 'Error while saving Data', alertVisible: 'error' };
          }
        } else {
          this.alertData = { alertMessage: 'Error while saving Data', alertVisible: 'error' };
          this.loading = false;
        }
      }, error: (error) => {
        this.loading = false;
        console.log(error);
        this.alertData = { alertMessage: error.error.result, alertVisible: 'error' };
      }
    });
   
  }
  formatDateForSave(dateString: string | null): string | null {
    if (!dateString || dateString.trim() === '') {
      return null;
    }

    const dateParts = dateString.split('/');
    if (dateParts.length === 3 && dateParts.every(part => !isNaN(Number(part)))) {
      const day = dateParts[0];
      const month = dateParts[1];
      const year = dateParts[2];

      const date = new Date(`${year}-${month}-${day}T00:00:00`);

      if (!isNaN(date.getTime())) {
        return this.convertToISOFormat(date);
      } else {
        return null;
      }
    } else {
      const date = new Date(dateString);

      if (!isNaN(date.getTime())) {
        return this.convertToISOFormat(date);
      } else {
        return null;
      }
    }
  }
  convertToISOFormat(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }
  BasicNext() {
    if(this.formBasic.disabled){
      this.EmployeeOption = 'SPOC';
      this.IsBasicValid = true;
    }
    this.showValidationErrors = true;
    if (this.formBasic.valid && this.PFType.value && this.state.value && this.city.value && this.status.value && this.WagePeriodFrom.value
      && this.WagePeriodTo.value && this.PaymentDay.value && this.EstablishmentType.value && this.ActApplicability.value && this.BonusExempted.value) {
      if (!this.ClientIdAvailable) {
        this.clickButton = true;
        return;
      }
      if (!this.ClientIdExist && !this.clickButton && this.ClientIdAvailable) {
        this.EmployeeOption = 'SPOC';
        this.IsBasicValid = true;
      } else {
        this.clickButton = false;
      }
    } else {
      this.formBasic.markAllAsTouched();
      this.clickButton = false;
      if (!this.ClientIdAvailable) {
        this.clickButton = true;
      }
    }
  }
  SPOCNext() {
    if(this.formBasic.disabled){
      this.EmployeeOption = 'Setting';
      this.IsSPOCValid = true;
    }
    this.showValidationErrorsEmp = true;
    if (this.formSPOCEPD.valid && (this.Salutation.value != '0') && (this.EP1Salutation.value != '0') && this.IsBasicValid) {
      this.EmployeeOption = 'Setting';
      this.IsSPOCValid = true;
    }
    else {
      this.formSPOCEPD.markAllAsTouched();
      this.IsSPOCValid = false;
    }
  }

  public closeAlert(): void {
    if (this.alertData.alertVisible == 'success') {
      this.closePopup();
    }
    this.alertData = { alertMessage: '', alertVisible: '' };
  }

  public closePopup(): void {
    this.Data = [];
    this.close.emit();
  }
  onDateChange(value: Date, type: string) {
    const date = new Date(value.toISOString().split('T')[0]);
    if (type === 'CommencementDate') {
      this.commencementDate = date;
    }
  }
  onBlur(event: FocusEvent): void {

    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    let extractedValue = inputValue.replace(/[^a-zA-Z0-9]/g, '');
    if (extractedValue.length > 5) {
      extractedValue = extractedValue.substring(0, 5);
    }
    this.ClientId = 'AVA' + extractedValue.toUpperCase();
  }

  bonusPercentageValidator(control: AbstractControl): { [key: string]: any } | null {
    const checkForPercentage = /^\d{1,2}\.\d{1,2}$|^\d{1,3}$/;
    const valid = checkForPercentage.test(control.value);
    return valid ? null : { invalidPercentage: true };
  }
}
