import { Component, OnInit } from '@angular/core';
import { KENDO_CHARTS, Legend, SeriesLabelsContentArgs } from '@progress/kendo-angular-charts';
import { DashboardService } from '../../../service/dashboard.service';
import { UserService } from '../../../service/user.service';

@Component({
  selector: 'app-cd-abstract-summary',
  standalone: true,
  imports: [KENDO_CHARTS],
  templateUrl: './cd-abstract-summary.component.html',
  styleUrl: './cd-abstract-summary.component.css'
})
export class CdAbstractSummaryComponent implements OnInit {

  public data: any[] = [];
  totalAbstract: number = 0;

  constructor(private dashboardService: DashboardService, private userService: UserService) { }

  ngOnInit(): void {
    this.getAbstractSummary()
  }

  legend: Legend = {
    position: "bottom",
  };

  public labelContent(e: SeriesLabelsContentArgs): string {
    // return `${e.category}: \n ${e.value}%`;
    return '';
  }

  getAbstractSummary() {
    this.dashboardService.getAbstractSummaryDetails(this.userService.getClientId()).subscribe({
      next: (response) => {
        const data = response.body;

        const count = data?.result;
        this.totalAbstract = count;

        this.data = [
          {
            kind: "Displayed",
            share: count,
            color: "#65B677"
          },
          {
            kind: "Not Displayed",
            share: 100 - count,
            color: "#FA6462"
          },
        ];
      },
      error: (err) => {
        console.log('Error', err);
      }
    });
  }
}
