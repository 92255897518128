<kendo-dialog title="Upload PT Location" (close)="closePopup()" [minWidth]="400" [width]="500" [height]="365" class="custom-dialog">
    <div class="row dialog-content">
      <div class="col-md-12">
        <div class="sampleDocument">
          <img src="../assets/vectors/Excel.svg" alt="" style="padding: 5px;">
          <a href="../assets/files/SamplePTLocationMapping.xlsx" download="SamplePTLocationMapping.xlsx"
          style="color: #125AAB; text-decoration: underline;font-size:12px; font-weight:400 !important" id="btnSampleRLCS">
          Sample Document
          </a>
          <img src="../assets/vectors/Excel.svg" alt="" style="padding: 5px;">
          <a href="../assets/files/PTLocationMaster.xlsx" download="PTLocationMaster.xlsx"
          style="color: #125AAB; text-decoration: underline;font-size:12px; font-weight:400 !important" id="btnSampleRLCS">
          PTLocation Master
          </a>
        </div>
      </div>
      <div class="col-md-12 form-group" style="margin-top: 10px;">
        <div class="drop-zone" 
        (dragover)="onDragOver($event)" 
        (dragleave)="onDragLeave($event)" 
        (drop)="onDrop($event)">
        <div *ngIf="!selectedFile" class="f-label" style="font-size: 18px;">
          <div class="col-md-12" style="margin-top: 25px;">
            <img src="../assets/vectors/cloud.svg" alt="">
            </div>
            <div class="col-md-8">
              <label style="margin-left: 105px;">{{ dropzoneMessage }}</label>
            </div>
            <div class="col-md-4">
              <label style="color: #125AAB; text-decoration: underline; cursor: pointer; margin-left: -55px; font-weight: 500;">
                Browse
                <input type="file" style="display: none;" (change)="onFileSelect($event)" accept=".xls,.xlsx,.csv" />
              </label>
            </div>
          </div>
          <p *ngIf="selectedFile" style="margin-top: 60px;">{{ selectedFile.name }}</p>
        </div>
      </div>
    </div>
    <kendo-dialog-actions style="border: none;">
      <button kendoButton class="figma-btn-blue figma-popup" (click)="uploadFiles()">Upload</button>
    </kendo-dialog-actions>
  </kendo-dialog>
  <app-alert-popup  *ngIf="alertData.alertVisible" [Width]="270" [Height]="280" [Data]="alertData" (close)="closeAlertPopup()"></app-alert-popup>
  