import { Component, Input, Output, EventEmitter, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { distinct, filterBy, FilterDescriptor } from '@progress/kendo-data-query';
import { FilterService } from '@progress/kendo-angular-grid';
import { CommonModule } from '@angular/common';
import { GridModule } from "@progress/kendo-angular-grid";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { LabelModule } from "@progress/kendo-angular-label";


interface CompositeFilterDescriptor {
  logic: 'or' | 'and';
  filters: Array<any>;
}

@Component({
  selector: 'multicheck-filter',
  standalone: true,
  imports: [CommonModule, GridModule, InputsModule, LabelModule],
  template: `
    <ul>
      <li *ngIf="showFilter">
        <input class="k-textbox k-input k-rounded-md" (input)="onInput($event)" />
      </li>
      <li #itemElement
        *ngFor="let item of currentData; let i = index;"
        (click)="onSelectionChange(valueAccessor(item), itemElement)"
        [ngClass]="{'k-selected': isItemSelected(item)}">
        <input type="checkbox" #notification kendoCheckBox [checked]="isItemSelected(item)"/>
        <kendo-label
          class="k-checkbox-label"
          [for]="notification"
          [text]="textAccessor(item)"
        ></kendo-label>
      </li>
    </ul>
  `,
  styles: [`
    ul {
      list-style-type: none;
      height: 200px;
      overflow-y: scroll;
      padding-left: 0;
      padding-right: 12px;
    }
    
    li{
    display: flex;
    align-items: center; 
}

    ul>li {
      padding: 8px 12px;
      border: 1px solid rgba(0,0,0,.08);
      border-bottom: none;
    }

    ul>li:last-of-type {
      border-bottom: 1px solid rgba(0,0,0,.08);
    }

    .k-checkbox-label {
      pointer-events: none;
    }
  `]
})
export class MultiCheckFilterComponent implements AfterViewInit {
  @Input() public isPrimitive!: boolean;
  @Input() public currentFilter!: CompositeFilterDescriptor;
  @Input() public data: any;
  @Input() public textField!: string;
  @Input() public valueField!: string;
  @Input() public filterService!: FilterService;
  @Input() public field!: string;
  @Output() public valueChange = new EventEmitter<number[]>();
  @Output() filterChange = new EventEmitter<CompositeFilterDescriptor>();

  public currentData: unknown[] = [];
  public showFilter = true;
  private value: unknown[] = [];

  public textAccessor = (dataItem: unknown): string => this.isPrimitive ? dataItem as string : (dataItem as any)[this.textField];
  public valueAccessor = (dataItem: unknown): unknown => this.isPrimitive ? dataItem : (dataItem as any)[this.valueField];

  public ngAfterViewInit(): void {
    debugger;
    this.currentData = this.data;
    this.value = this.currentFilter.filters.map((f: FilterDescriptor) => f.value);

    this.showFilter = typeof this.textAccessor(this.currentData[0]) === 'string';
  }

  public isItemSelected(item: unknown): boolean {
    debugger;
    return this.value.some(x => x === this.valueAccessor(item));
  }

  onFilterChange(filter: CompositeFilterDescriptor): void {
    debugger;
    this.filterChange.emit(filter);
    this.filterService.filter(filter);
  }

  public onSelectionChange(item: unknown, li: HTMLLIElement): void {
    debugger;
    if (this.value.some(x => x === item)) {
      this.value = this.value.filter(x => x !== item);
    } else {
      this.value.push(item);
    }

    this.filterService.filter({
      filters: this.value.map(value => ({
        field: this.field,
        operator: 'eq',
        value
      })),
      logic: 'or'
    });

    this.onFocus(li);
  }

  public onInput(e: Event): void {
    debugger;
    this.currentData = distinct([
      ...this.currentData.filter(dataItem => this.value.some(val => val === this.valueAccessor(dataItem))),
      ...filterBy(this.data, {
        operator: 'contains',
        field: this.textField,
        value: (e.target as HTMLInputElement).value
      })],
      this.textField
    );
  }

  public onFocus(li: HTMLLIElement): void {
    debugger;
    const ul = li.parentNode as HTMLUListElement;
    const below = ul.scrollTop + ul.offsetHeight < li.offsetTop + li.offsetHeight;
    const above = li.offsetTop < ul.scrollTop;

    if (above) {
      ul.scrollTop = li.offsetTop;
    }

    if (below) {
      ul.scrollTop += li.offsetHeight;
    }
  }
}
