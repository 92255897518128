import { Component, OnInit } from '@angular/core';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { InvoiceDetailsService } from '../../../service/invoice-details.service';
import { UserService } from '../../../service/user.service';
import { BlobService } from '../../../service/blob.service';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { environment } from '../../../environments/environment';
import { NgIf } from '@angular/common';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';

@Component({
  selector: 'app-invoice-details',
  standalone: true,
  imports: [GridActionItemComponent,IndicatorsModule,NgIf,AlertPopupComponent],
  templateUrl: './invoice-details.component.html',
  styleUrl: './invoice-details.component.css'
})
export class InvoiceDetailsComponent implements OnInit {
  public gridData: GridDataResult = { data: [], total: 0 };
  public clientId!:string;
  loading=false;
  alertData = { alertMessage: '', alertVisible: '' };

  constructor(private InvoiceDetailasService: InvoiceDetailsService,private user:UserService,private blobService: BlobService) { }
  ngOnInit(): void {
    this.clientId=this.user.getClientId();
    this.loadGridData();
  }

  exportExcel(){
    this.loading=true;
    this.InvoiceDetailasService.getInvoiceDetails_ExporttoExcel(this.clientId).subscribe({
      next: (res: any) => {
        if(res.body?.filePath){
          const data = res.body;
          const blobLink = data.filePath;
          const blobName = this.blobService.extractBlobName(blobLink);
          setTimeout(() => {
            this.blobService.getSasToken(blobName).subscribe(
              (tokenResponse: any) => {
                try {
                  const sasToken = tokenResponse.result;
                  this.blobService.downloadFile(blobName, sasToken);
                  this.loading = false;
                } catch (error) {
                  this.alertData={alertMessage:'File Not Found',alertVisible:'error'};
                  this.loading = false;
                }
              },
              (error) => {
                this.alertData={alertMessage:'File Not Found',alertVisible:'error'};
                this.loading = false; 
              }
            );
          }, 5000);
        }else{
          this.alertData = { alertMessage: res.body.status, alertVisible: 'error' };
          this.loading = false; 
        }
      },
      error: (error: any) => {
        console.error('Error fetching blob link:', error);
        this.loading = false;
      }
    });
  }
  handleButtonClick(event:any){
    debugger
    this.loading=true;
    if(event.button.action =='download'){
      const apiUrl = environment.blobUrl;
      const filePath =event.dataItem?.fileName
      if(filePath){
      const blobLink = apiUrl + filePath;
      console.log(blobLink);
      
        const blobName = this.blobService.extractBlobName(blobLink);
        setTimeout(() => {
          this.blobService.getSasToken(blobName).subscribe(
            (tokenResponse: any) => {
            try {
              const sasToken = tokenResponse.result;
              this.blobService.downloadFile(blobName, sasToken);
              this.loading = false;
            } catch (error) {
              this.alertData={alertMessage:'File Not Found',alertVisible:'error'};
              this.loading = false;
            }
          },
          (error) => {
            this.alertData={alertMessage:'File Not Found',alertVisible:'error'};
            this.loading = false; 
          }
        );
      }, 5000);
    }
  }else{
    this.alertData={alertMessage:'File Not Found',alertVisible:'error'};
    this.loading = false; 
  }
    
  }
  loadGridData(): void {
    this.InvoiceDetailasService.getInvoiceDetails(this.clientId).subscribe({
      next: (response) => {
        console.log(response);
        
        if (response.status === 200) {
          const data = response.body;
          this.gridData = {
            data: data,
            total: data.length
          };
        }
        else {
          console.error('Error fetching entity data');
        }
      },
      error: (error) => {
        console.error('Error fetching entity data', error);
      }
    });
  }
  closeAlert(){
    this.alertData={alertMessage:'',alertVisible:''};
  }

  public parentColumns: any[] = [
    { field: 'invoice', title: 'Invoice' },
    { field: 'invoiceType', title: 'Invoice Type' },
    { field: 'monthYear', title: 'Month & Year' },
    { field: 'invoiceDate', title: 'Invoice Date' },
    { field: 'invoiceValue', title: 'Invoice Value' },
    { field: 'poNumber', title: 'PO Number' },
    {
      field: 'fileName',
      title: 'Dowoload-Popup',
      width: '150px',
      filteredIcons: [
        {
          url: '../assets/vectors/DownloadBlue.svg',
          alt: 'Icon 1',
          action: 'Download'
        }
      ]
    }
  ];

}
