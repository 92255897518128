<div class="col-md-12 colpadding0" style="margin-top: -9px; margin-left: -27px; ">
    <header class="panel-heading tab-bg-primary"
      style="background: none !important; margin-left: 33px; padding: 0px 0px; zoom: 0.9;">
      <ul class="nav nav-tabs" style="margin-left: -23px;">
        <li *ngFor="let tab of sortedTabs" class="listItem pemt" 
            [ngClass]="{'selected-tab': isTabSelected(tab)}" 
            (click)="navigateTo(tab)" style="margin-left: -8px !important">
          <a class="tabs">{{ tab.tabName }}</a>
        </li>
      </ul>
    </header>
  </div>
  