import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class LeaveTypeServiceService {
  private baseUrl  = environment.apiUrl;
  constructor(private http:HttpClient) { }

  getAllLeaveType(filter?:string, page: number = 1, pageSize: number = 10, skip: number = 0, take: number = 10):Observable<HttpResponse<any>>
  {
    const url=`${this.baseUrl}/api/LeaveType/GetAll_LeaveTypeMaster?LeaveTypeFilter=${filter}&page=${page}&pageSize=${pageSize}&skip=${skip}&take=${take}`      
    return this.http.get(url, { observe: 'response' });
  }

  exportToExcel(): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/LeaveType/ExportToExcelLeaveType`;


    return this.http.get(url,{ observe: 'response' })
  }

  getLeaveTypeDropdown(filter: string=''): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/LeaveType/Get_AllLeaveTypeDropDown?Filter=${filter}`;
    
    return this.http.get(url,{ observe: 'response' } );
  }

}
