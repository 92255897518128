import { Component, OnInit } from '@angular/core';
import { CdPerformanceSummaryComponent } from "./cd-performance-summary/cd-performance-summary.component";
import { CdAbstractSummaryComponent } from './cd-abstract-summary/cd-abstract-summary.component';
import { CdRiskSummaryComponent } from './cd-risk-summary/cd-risk-summary.component';
import { CdApplicableStatusComponent } from './cd-applicable-status/cd-applicable-status.component';
import { CdCalendarComponent } from "./cd-calendar/cd-calendar.component";
import { CdDailyUpdatesComponent } from "./cd-daily-updates/cd-daily-updates.component";
import { DashboardService } from '../../service/dashboard.service';
import { UserService } from '../../service/user.service';
import { DropdownListComponent } from '../../components/dropdown-list/dropdown-list.component';
import { DashboardCompliancePopupComponent } from '../popups/dashboard-compliance-popup/dashboard-compliance-popup.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-compliance-dashboard',
  standalone: true,
  imports: [DropdownListComponent, CdPerformanceSummaryComponent, CdAbstractSummaryComponent, CdRiskSummaryComponent, CdApplicableStatusComponent, CdCalendarComponent, CdCalendarComponent, CdCalendarComponent, CdDailyUpdatesComponent, DashboardCompliancePopupComponent, NgIf],
  templateUrl: './compliance-dashboard.component.html',
  styleUrl: './compliance-dashboard.component.css'
})
export class ComplianceDashboardComponent implements OnInit {

  countData: any = {};
  yearDropdown: any[] = [
    {
      text: '2024',
      value: '2024'
    },
    {
      text: '2023',
      value: '2023'
    },
    {
      text: 'Current Month',
      value: 'Current Month'
    },
    {
      text: 'Previous Month',
      value: 'Previous Month'
    },
  ]

  selectedOption = {
    text: '2024',
    value: '2024'
  }

  selectedYear = '2024';

  isOpenComPopup: boolean = false;
  acts: any[] = [];
  Type:any;
  constructor(private dashboardService: DashboardService, private userService: UserService) {

  }

  ngOnInit(): void {
    this.getDashboardCount()
  }

  getDashboardCount() {
    this.dashboardService.getDashboardCount(this.userService.getUserId(), this.userService.getCustomerId(), this.userService.getClientId(), this.userService.getRole(), this.selectedYear).subscribe({
      next: (response) => {
        const data = response.body;
        console.log('Dashboard count', data);

        this.acts = data?.acts;
        this.countData = data?.countobj;
      },
      error: (err) => {
        console.log('Error', err);
      }
    });
  }

  onDropdownValueChange(event: any) {
    this.selectedYear = event.value;

    this.getDashboardCount();
  }

  onComplianceClick(type: string) {
    this.isOpenComPopup = true;
    this.Type = type;
  }

  onClosePopup(){
    console.log("Popup closed");
    this.isOpenComPopup = false;
    
  }
}
