<kendo-dialog 
#dialogRef 
title="ECR Master Bulk Upload" 
  (close)="closePopup()"
  [minWidth]="300"

  [width]="550"
  class="custom-dialog">
 
  
  
  <div class="dialog-content">
    <div class="row sampleDocument colpadding0">
      <!-- <div class="col-md-12"> -->
        <!-- <label class="f-label">Upload</label> -->
      <!-- </div> -->
      <div class="col-md-12 colpadding0">
        <a href="../assets/files/UploadPFECRMaster.xlsx" download="UploadPFECRMaster.xlsx"
           style="color: #125AAB; text-decoration: underline;font-size: 12px; float:right; margin-top:5px; font-weight:400;margin-right: 18px;" id="btnSampleRLCS">
          Sample Document
        </a>
        <img src="../assets/vectors/Excel.svg" alt="" style="padding: 5px; float:right;">
      </div>
    </div>
    <div class="col-md-12 form-group" >
      <div class="drop-zone" 
      (dragover)="onDragOver($event)" 
      (dragleave)="onDragLeave($event)" 
      (drop)="onDrop($event)">
      <div *ngIf="!selectedFile" class="f-label" style="font-size: 18px;">
        <div class="col-md-12">
          <img src="../assets/vectors/cloud.svg" alt="">
          </div>
          <div class="col-md-8">
            <label style="margin-left: 90px;">{{ dropzoneMessage }}</label>
          </div>
          <div class="col-md-4">
            <label style="color: #125AAB; text-decoration: underline; cursor: pointer;  margin-left: -40px; font-weight: 500;">
              Browse
              <input type="file" style="display: none;" (change)="onFileSelect($event)" accept=".xls,.xlsx,.csv" />
            </label>
          </div>
        </div>
        <p *ngIf="selectedFile" style="margin-top: 30px;">{{ selectedFile.name }}</p>
      </div>
    </div>
  </div>

  <kendo-dialog-actions style="border: none;">
    <button kendoButton type="submit" (click)="uploadFiles()" class="figma-btn-blue figma-popup">
      Upload
    </button>
  </kendo-dialog-actions>

  <div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>
</kendo-dialog>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="250" [Height]="265" [Data]="alertData" (close)="closeAlert()"></app-alert-popup>

