import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class GstApprovalService {

  
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {

  }
  getClientsGstMaster(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Invoice/GetClientsMaster`;
    // https://localhost:7111/api/Invoice/GetClientsMaster
    return this.http.get(url, {observe:'response'});
  }
  GetPendingGstDetailsBasedOnClientIds(payload:any): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Invoice/GetPendingGstDetailsBasedOnClientId?ClientId=${payload.ClientId}`;
    // https://localhost:7111/api/Invoice/GetPendingGstDetailsBasedOnClientId?ClientId=EZKRG
    return this.http.get(url, {observe:'response'});
  }
  UpdateGSTDetail(payload:any): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Invoice/UpdateGSTDetails`;
    // https://localhost:7111/api/Invoice/UpdateGSTDetails
    return this.http.post<any>(url, payload, { observe: 'response' });
  }

  SubmitInvoiceGstUploadApprovals(payload:any): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Invoice/SubmitInvoiceGstUploadApproval`;
    // https://localhost:7111/api/Invoice/SubmitInvoiceGstUploadApproval
    return this.http.post<any>(url, payload, { observe: 'response' });
  }
}
