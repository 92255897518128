import { ChangeDetectorRef, Component } from '@angular/core';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputFilesService } from '../../../service/input-files.service';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { UserService } from '../../../service/user.service';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { load } from '@progress/kendo-angular-intl';
import { BlobService } from '../../../service/blob.service';
import { environment } from '../../../environments/environment';
import { InputClientHistoryPopupComponent } from '../../popups/input-client-history-popup/input-client-history-popup.component';
import { Router } from '@angular/router';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

@Component({
  selector: 'app-input-files-client',
  standalone: true,
  imports: [DropdownListComponent,ReactiveFormsModule,NgClass,FormsModule,DropDownsModule,NgIf,NgFor,IndicatorsModule,InputClientHistoryPopupComponent,AlertPopupComponent,GridModule,TooltipModule],
  templateUrl: './input-files-client.component.html',
  styleUrl: './input-files-client.component.css'
})
export class InputFilesClientComponent {
  form!: FormGroup
  complianceType: Array<{ text: any, value: any }> = [
    { text: 'Compliance', value: 'Compliance' } 
  ];
  period: Array<{ text: any, value: any }> = [
    { text: 'Period', value: 'Period' } 
  ];
  years: Array<{ text: any, value:any }> = [
    { text: 'Year', value: 'Year' }
  ];
  selectedComplianceType: { text: any, value: any } = { text: 'Compliance', value: 'Compliance' }
  selectedPeriod: { text: any, value: any } = { text: 'Period', value: 'Period' }
  selectedYear: { text: any, value: any } = { text: 'Year', value: 'Year' };
  fileNames: string[] = [];
  inputListData : any[] = [];
  loading=true
  inputIds:any;
  showState = false;
  json:any
  openHistory=false
  inputId:any
  month:any
  year:any
  wrongFile:any=0
  isApplyClicked: boolean = false;
  private blobUrl: string = environment.blobUrl;
  public alertData = { alertMessage: '', alertVisible: '' };
  constructor(private inputService:InputFilesService,private fb: FormBuilder,private user:UserService,private blobService:BlobService, private router:Router,private cdr:ChangeDetectorRef){}

  ngOnInit(): void {
    this.getComplianceType()
    this.getYears()    
    this.getPeriods()
    this.selectedComplianceType.value='Register'
    this.selectedPeriod.value='10'
    this.selectedYear.value=2024
    this.form = this.fb.group({
      compliance: ['', [Validators.required]],
      year: ['', [Validators.required]],
      period: ['', [Validators.required]],
      Template:[null]
    })
    this.getUploadStatus();
    this.fileNames = new Array(this.inputListData.length).fill('');
    this.json = JSON.parse(sessionStorage.getItem('JsonId') || 'null');
  }
  onFileChange(event: any, index: number) {
    const file = event.target.files[0];

    if (file) {
        this.fileNames[index] = file.name;
    } else {
        this.fileNames[index] = '';  
    }
}


  getComplianceType() {
    const type = 'ComplianceName';
    this.inputService.getcompliance(type).subscribe({
      next: (response) => {
        const data = response.body;
        console.log('Compliance Type', data);
        const fetchedCompliance = data.map((item: any) => ({
          text: item.text,
          value: item.value
        }));
        this.complianceType = [
          { text: 'Compliance', value: 'Compliance' }, 
          ...fetchedCompliance
        ];
      }
    });
  }
  public onComplianceTypeChange(value: any) {
    console.log('compliance type',value);
    this.isApplyClicked = false;
    this.selectedComplianceType = { text: value.text, value: value.value }
    this.inputListData.length=0
    console.log(this.selectedComplianceType.value);
   
    this.form.get('compliance')?.setValue(this.selectedComplianceType);
    console.log(this.selectedComplianceType);
    
    if (this.selectedComplianceType.value === 'Compliance') {
      this.form.get('compliance')?.setErrors({ invalidCompliance: true });
  } else {
      this.form.get('compliance')?.setErrors(null); 
  }
    if (this.form.get('compliance')?.valid) {
        this.form.get('compliance')?.markAsTouched();
    }
    
  }
  getPeriods(): void {

    this.inputService.getcompliance('Months').subscribe({
      next: (response) => {
        const data = response.body
        console.log('Period', data);
        const fetchedPeriod = data.map((item: any) => ({
          text: item.text,
          value: item.value
        }))
        this.period = [
          { text: 'Period', value: 'Period' }, 
          ...fetchedPeriod
        ];
      }
    })
  }

  onPeriodChange(value: any): void {
    this.isApplyClicked = false;
    this.selectedPeriod = { text: value.text, value: value.value }
    this.inputListData.length = 0;
    
  }

  getYears(): void {
    const currentYear = new Date().getFullYear();
    this.years = [
      { text: 'Year', value: 'Year' } 
    ];
    for (let year = currentYear; year >= 2018; year--) {
      this.years.push({ text: year.toString(), value: year });
    }
    
  }
  
  onYearChange(value: any): void {
    this.isApplyClicked = false;
    this.selectedYear = { text: value.text, value: value.value }
    this.inputListData.length = 0;
  }



  getUploadStatus() {
    this.loading=true
    const payload ={      
      userId: this.user.getUserId(),
      customerId: this.user.getCustomerId(),
      entityId: this.user.getClientId(),
      month: this.selectedPeriod.value,
      year: this.selectedYear.value.toString(),
      complianceType: this.selectedComplianceType.value,
      inputID: 0 ,
      frequencies:'Monthly'
  }
  console.log('payload',payload);
  

    this.inputService.ComplianceInput(payload).subscribe({
      next: (response) => {
        console.log(response);
        this.loading=false
        this.inputListData  = response.body.inputList; 

        this.inputIds = this.inputListData.length === 1
        ? this.inputListData[0].inputId
        : this.inputListData.map(item => item.inputId).join(',');
      
      },
      error: (error) => {
        this.loading = false;
        console.error(error);
      }
    });
  }

  Template(inputID:any) {
    this.loading = true;
    let payload ={
      
      userId: this.user.getUserId(),
      customerId: this.user.getCustomerId(),
      entityId: this.user.getClientId(),
      month: this.selectedPeriod.value,
      year: this.selectedYear.value.toString(),
      jsonId: 0,
      inputID: inputID
  }
  console.log('TEMPLATE PAYLOAD',payload);
  
    this.inputService.GetSampleBasedOnInputID(payload).subscribe(
      {
        next: (response) => {
          this.loading = false;
          const blobLink = response.body.filePath;
          let sasToken = response.body.sasToken
          const blobName = this.blobService.downloadBlobFile(blobLink,sasToken);
        }, error: (error) => {
          console.error('Error fetching blob link:', error);
          this.alertData = { alertMessage: error.error, alertVisible: 'error' };
          this.showState = false;
          this.loading = false;
        }
      });
  }
  uploadFiles(index: number,inputID:any) {
    console.log('input id',inputID);
    
    this.loading = true;
    if (this.fileNames[index]) {
      const fileInput = <HTMLInputElement>document.getElementById('fileInput'+index);
      const file = fileInput?.files?.[0];
      let payload={
        EntityId : this.user.getClientId(),
        Month:this.selectedPeriod.value,
        Year:this.selectedYear.value.toString(),
        InputId:inputID,
        JsonId : 0,
        UserId:this.user.getUserId(),
        FileName:this.fileNames[index],
        CustomerID:this.user.getCustomerId() 
      }
      if (file) {
        const formData = new FormData();
        formData.append('file', file);

        this.inputService.uploadFile(payload,formData).subscribe(
          {
            next: (response) => {
              console.log(response);
              
              this.loading = false;
             let result =  response.body.result
              if (result === 3) {
                this.fileNames[index] = ''; 
                this.alertData = { alertMessage: 'File uploaded successfully', alertVisible: 'success' };
                this.getUploadStatus()
                this.cdr.detectChanges()
              }
              else if(result === 1 ){
                this.fileNames[index] = '';
                this.alertData = { alertMessage: 'File uploaded successfully', alertVisible: 'success' };
                this.getUploadStatus()
                this.cdr.detectChanges()
              }
               else {
                this.alertData = { alertMessage: 'Error uploading file', alertVisible: 'error' };
              }
            }, error: (error) => {
              console.error('Error uploading file:', error);
              this.alertData = { alertMessage: 'Error uploading file', alertVisible: 'error' };
            }
          }
        );
      } else {
        this.alertData = { alertMessage: 'No file selected', alertVisible: 'error' };
        this.loading = false;
      }
    } else {
      this.alertData = { alertMessage: 'No file chosen', alertVisible: 'error' };
      this.loading = false;
    }
  }
  downloadErrorFile(inputID:any){
    let payload = {
      userId: this.user.getUserId(),
      customerId: this.user.getCustomerId(),
      entityId: this.user.getClientId(),
      month: this.selectedPeriod.value,
      year: this.selectedYear.value.toString(),
      complianceIds:'',
      jsonId:  0,
      complianceType: this.selectedComplianceType.value,
      inputID: inputID,
    }
    this.loading = true;

    this.inputService.GetErrorFile(payload).subscribe(
      {
        next: (response) => {
          this.loading = false;
          const blobLink = response.body.filePath;
          let sasToken = response.body.sasToken
          const blobName = this.blobService.downloadBlobFile(blobLink,sasToken);
          if(response.body.result){

          }
        }, error: (error) => {
          console.error('Error fetching blob link:', error.error.result);
          this.alertData = { alertMessage: error.error.result, alertVisible: 'error' };
          this.loading = false;
        }
      });
  }

  downloadFile(filePath: any): void {
    this.inputService.downloadUploadedCSVFile(filePath).subscribe({
      next:(response)=>{
        console.log('file download response',response);
        
      }
    });
  }

  openHistoryPopup(Id:any){
    this.inputId=Id
    this.month=this.selectedPeriod.value
    this.year=this.selectedYear.value.toString()
    this.openHistory=true;
  }
  closeHistoryPopup(){
    this.openHistory=false;
  }
  applyFilter(){
    this.isApplyClicked = true;
    this.getUploadStatus()
    this.cdr.detectChanges()
  }

  navigateToChalllan(){
    const month=this.selectedPeriod.value
    const year=this.selectedYear.value.toString()
    const compliance=this.selectedComplianceType.value
    this.router.navigate(['OutputChallanCustomer'],{
      state: {
          month: month,
          year: year,
          complianceId: compliance
      }
  })
  }

  closeAlert(){
    this.alertData = { alertMessage: '', alertVisible: '' }
  }
}
