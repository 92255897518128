<div class="row col-md-12  colpadding0" style="margin-top:10px;">
    <div class="col-md-12 colpadding0" style="margin-bottom: 10px;">
        <app-dropdown-list class="dropdowns" [Placeholder]="'Search'" [selectedValue]="Status" [data]="dropdownData"
            [width]="'280px'" textField="text" valueField="value" (valueChange)="onDropdownValueChange($event)">
        </app-dropdown-list>
    </div>
</div>
<div class="col-12 colpadding0">
    <app-grid-action-item [gridData]="gridData" [columns]="parentColumns" [pageSize]="10" [pageable]="true"
        [filterable]="true" (buttonClick)="handleButtonClick($event)">
    </app-grid-action-item>
</div>

<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>