import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EntityService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  GetCustomerEntityDetails(customerId: number, branchid: any, page: number = 1, pageSize: number = 10, skip: number = 0, take: number = 10,): Observable<HttpResponse<any>> {
    if(branchid==0){
      branchid='';
    }
    const url = `${this.apiUrl}/api/Onboarding/GetCustomerEntityDetails?customerID=${customerId}&Avacom_Branchid=${branchid}&take=${take}&skip=${skip}&page=${page}&pageSize=${pageSize}`;
    return this.http.get<any>(url, { observe: 'response' });
  }
  GetCustomerEntityDetails_Forddl(customerId: number, Filter: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Onboarding/GetCustomerEntityDetails_Forddl?customerID=${customerId}&Filter=${Filter}`;
    return this.http.get<any>(url, { observe: 'response' });
  }
  DeleteCustomerEntityConfirmation(CustomerBranchID: number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Onboarding/DeleteCustomerEntityConfirmation?CustomerBranchID=${CustomerBranchID}`;
    return this.http.post<any>(url, {},{ observe: 'response' });
  }
  DeleteCustomerBranch(CustomerBranchID: number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Onboarding/DeleteCustomerBranch?CustomerBranchID=${CustomerBranchID}`;
    return this.http.get<any>(url, { observe: 'response' });
  }

  EntityBulkUpload(file: File, headerTrackerId: number, custId: number, userId: number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Onboarding/EntityBulkUpload`;
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('CustID', custId.toString());
    formData.append('UserID', userId.toString());
    formData.append('HeaderTrackerId', headerTrackerId.toString());
    console.log(formData);
    
    return this.http.post<any>(url, formData, { observe: 'response' })
  }

  CheckClientIdExists(ClientId: string): Observable<HttpResponse<any>> {
    const url = `https://rlcsapi.teamleaseregtech.com/api/AventisIntegration/CheckClientIdExists?ClientId=${ClientId}`;
    return this.http.get<any>(url, { observe: 'response' });
  }
  FillStates(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Onboarding/FillStates`;
    return this.http.get<any>(url, { observe: 'response' });
  }
  GetAllIndustry(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Onboarding/GetAllIndustry`;
    return this.http.get<any>(url, { observe: 'response' });
  }
  FillLocationCityByStateID(stateId: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Onboarding/FillLocationCityByStateID?stateID=${stateId}`;
    return this.http.get<any>(url, { observe: 'response' });
  }
  GetAllComanyTypeCustomerBranch(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Onboarding/GetAllComanyTypeCustomerBranch`;
    return this.http.get<any>(url, { observe: 'response' });
  }
  GetComplianceProductType(customerID: number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Onboarding/GetComplianceProductType?customerID=${customerID}`;
    return this.http.get<any>(url, { observe: 'response' });
  }
  GetServiceProviderID(avacom_CustomerID: number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/User/GetServiceProviderID?avacom_CustomerID=${avacom_CustomerID}`;
    return this.http.get(url, { observe: 'response' });
  }
  GetClientInfoByID(BranchID: number, BranchType: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/ClientDetailsManagement/GetClientInfoByID?BranchID=${BranchID}&BranchType=${BranchType}`;
    return this.http.get(url, { observe: 'response' });
  }
  GetClientBasicByID(ID: number, clientID: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/ClientDetailsManagement/GetClientBasicByID?ID=${ID}&clientID=${clientID}`;
    return this.http.get(url, { observe: 'response' });
  }
  GetByIDBranch(customerBranchID: number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/ClientDetailsManagement/GetByIDBranch?customerBranchID=${customerBranchID}`;
    return this.http.get(url, { observe: 'response' });
  }
  GetInvoiceCommunicationMaster(ClientId: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/ClientDetailsManagement/GetInvoiceCommunicationMaster?ClientId=${ClientId}`;
    return this.http.get(url, { observe: 'response' });
  }
  GetStateCodeByStateID(stateID: number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/StateMaster/GetStateCodeByStateID?stateID=${stateID}`;
    return this.http.get(url, { observe: 'response' });
  }
  GetCityCodeByCityID(cityID: number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/ClientDetailsManagement/GetCityCodeByCityID?cityID=${cityID}`;
    return this.http.get(url, { observe: 'response' });
  }
  CreateCustomerBranchIndustryMapping(value: any): Observable<HttpResponse<any>> {
    const body = {
      id: value.id,
      customerBranchID: value.customerBranchID,
      industryID: value.industryID,
      isActive: value.isActive,
      editedBy: value.editedBy,
      editedDate: value.editedDate
    }
    const url = `${this.apiUrl}/api/Onboarding/CreateCustomerBranchIndustryMapping`;
    return this.http.post(url, body, { observe: 'response' });
  }
  InsertInvoiceCommunicationMaster(value: any): Observable<HttpResponse<any>> {
    const body = {
      userName: value.userName,
      mobileNo: value.mobileNo,
      clientid: value.clientid,
      email: value.email,
      created_Date: value.created_Date
    }
    const url = `${this.apiUrl}/api/Onboarding/InsertInvoiceCommunicationMaster`;
    return this.http.post(url, body, { observe: 'response' });
  }
  AddEditEntityMaster(value: any,userId:number): Observable<HttpResponse<any>> {
    const body = {
      customerID: value.customerID,
      cO_CorporateID: value.cO_CorporateID,
      cM_ClientID: value.cM_ClientID,
      cM_ActType: value.cM_ActType,
      cM_EstablishmentType: value.cM_EstablishmentType,
      cM_State: value.cM_State,
      cM_City: value.cM_City,
      cM_Address: value.cM_Address,
      cM_BonusPercentage: value.cM_BonusPercentage,
      cM_Excemption: value.cM_Excemption,
      cB_WagePeriodFrom: value.cB_WagePeriodFrom,
      cB_WagePeriodTo: value.cB_WagePeriodTo,
      cB_PaymentDate: value.cB_PaymentDate,
      cB_ServiceTaxExmpted: value.cB_ServiceTaxExmpted,
      cB_DateOfCommencement: value.cB_DateOfCommencement,
      pfCodeType: value.pfCodeType,
      cM_PFCode: value.cM_PFCode,
      cM_Status: value.cM_Status,
      contactPerson: value.contactPerson,
      landline: value.landline,
      emailID: value.emailID,
      type: value.type,
      cB_EDLI_ExcemptionType: value.cB_EDLI_ExcemptionType,
      cM_IsPOApplicable: value.cM_IsPOApplicable,
      cM_AgreementID: value.cM_AgreementID,
      cM_ContractType: value.cM_ContractType,
      cM_BDAnchor: value.cM_BDAnchor,
      cM_RAMAnchor: value.cM_RAMAnchor,
      cM_ProcessAnchor: value.cM_ProcessAnchor,
      cM_ChallanAnchor: value.cM_ChallanAnchor,
      cM_ReturnsAnchor: value.cM_ReturnsAnchor,
      cM_LocationAnchor: value.cM_LocationAnchor,
      cM_RLCSAnchor: value.cM_RLCSAnchor,
      cM_AuditAnchor: value.cM_AuditAnchor,
      cB_SPOCSANITATION: value.cB_SPOCSANITATION,
      cB_SPOC_NAME: value.cB_SPOC_NAME,
      cB_SPOC_LASTNAME: value.cB_SPOC_LASTNAME,
      cB_SPOC_CONTACT: value.cB_SPOC_CONTACT,
      cB_SPOC_EMAIL: value.cB_SPOC_EMAIL,
      cB_SPOC_DESIGNATION: value.cB_SPOC_DESIGNATION,
      cB_EP1_SANITATION: value.cB_EP1_SANITATION,
      cB_EP1_NAME: value.cB_EP1_NAME,
      cB_EP1LASTNAME: value.cB_EP1LASTNAME,
      cB_EP1_CONTACT: value.cB_EP1_CONTACT,
      cB_EP1_EMAIL: value.cB_EP1_EMAIL,
      cB_EP1_DESIGNATION: value.cB_EP1_DESIGNATION,
      cB_EP2_SANITATION: value.cB_EP2_SANITATION,
      cB_EP2_NAME: value.cB_EP2_NAME,
      cB_EP2LASTNAME: value.cB_EP2LASTNAME,
      cB_EP2_CONTACT: value.cB_EP2_CONTACT,
      cB_EP2_EMAIL: value.cB_EP2_EMAIL,
      cB_EP2_DESIGNATION: value.cB_EP2_DESIGNATION,
      fileID: value.fileID,
      rowNo: value.rowNo,
      avacoM_BranchID: value.avacoM_BranchID,
      isCLRAApplicable: value.isCLRAApplicable,
      clrA_Type: value.clrA_Type,
      cB_Contractor_EmployerName: value.cB_Contractor_EmployerName,
      cM_Industry_Type: value.cM_Industry_Type,
      cM_ClientName: value.cM_ClientName
    }
    console.log('service',body);
    
    const url = `${this.apiUrl}/api/Onboarding/AddEditEntityMaster?userId=${userId}`;
    return this.http.post(url, body, { observe: 'response' });
  }
  CreateCustomerBranch(value: any): Observable<HttpResponse<any>> {
    const body = {
      name: value.name,
      customerID: value.customerID,
      type: value.type,
      legalRelationShipOrStatus: value.legalRelationShipOrStatus,
      addressLine1: value.addressLine1,
      addressLine2: value.addressLine2,
      stateID: value.stateID,
      cityID: value.cityID,
      others: value.others,
      industry: value.industry,
      contactPerson: value.contactPerson,
      landline: value.landline,
      mobile: value.mobile,
      emailID: value.emailID,
      isDeleted: value.isDeleted,
      createdOn: value.createdOn,
      id: value.id,
      parentID: value.parentID,
      pinCode: value.pinCode,
      legalRelationShipID: value.legalRelationShipID,
      status: value.status,
      legalEntityTypeID: value.legalEntityTypeID,
      comType: value.comType,
      auditPR: value.auditPR,
      gstNumber: value.gstNumber,
      createdBy: value.createdBy,
      createdFrom: value.createdFrom
    }
    const url = `${this.apiUrl}/api/Onboarding/CreateCustomerBranch`;
    return this.http.post(url, body, { observe: 'response' });
  }
  Update_ProcessedStatus_ClientBasicDetail(BranchID: number, ClientID: string, status: boolean): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Onboarding/Update_ProcessedStatus_ClientBasicDetail?BranchID=${BranchID}&ClientID=${ClientID}&status=${status}`;
    return this.http.get(url, { observe: 'response' });
  }
  UpdateCustomerBranchIndustryMappingMappedID(CustomerBranchID: number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Onboarding/UpdateCustomerBranchIndustryMappingMappedID?CustomerBranchID=328743`;
    return this.http.get(url, { observe: 'response' });
  }
  UpdateClientBasicDetailsInfo(value: any): Observable<HttpResponse<any>> {
    const body = {
      cB_ID: value.cB_ID,
      avacoM_BranchID: value.avacoM_BranchID,
      cB_ClientID: value.cB_ClientID,
      cB_PaymentDate: value.cB_PaymentDate,
      cB_WagePeriodFrom: value.cB_WagePeriodFrom,
      cB_WagePeriodTo: value.cB_WagePeriodTo,
      cB_DateOfCommencement: value.cB_DateOfCommencement,
      cB_ServiceTaxExmpted: value.cB_ServiceTaxExmpted,
      cB_EDLIExemption: value.cB_EDLIExemption,
      cB_Inv_Address: value.cB_Inv_Address,
      cB_DispatchAddress: value.cB_DispatchAddress,
      cB_Implant: value.cB_Implant,
      cB_NoOfImplants: value.cB_NoOfImplants,
      cB_ImplantLocations: value.cB_ImplantLocations,
      cB_ImplantCost: value.cB_ImplantCost,
      cM_PFCode: value.cM_PFCode,
      cM_ESICCode: value.cM_ESICCode,
      cM_ESIC_SubCode: value.cM_ESIC_SubCode,
      cM_PTCode: value.cM_PTCode,
      cM_LWFCode: value.cM_LWFCode,
      cB_MigrationType: value.cB_MigrationType,
      cB_CreatedBy: value.cB_CreatedBy,
      cB_CreatedDate: value.cB_CreatedDate,
      cB_ModifiedBy: value.cB_ModifiedBy,
      cB_ModifiedDate: value.cB_ModifiedDate,
      cB_Version: value.cB_Version,
      cB_ProjectCreation: value.cB_ProjectCreation,
      cB_ActType: value.cB_ActType,
      isProcessed: value.isProcessed,
      cB_PF_Code: value.cB_PF_Code,
      cB_YearType: value.cB_YearType,
      cB_CL_lapseCapping: value.cB_CL_lapseCapping,
      cB_PL_lapseCapping: value.cB_PL_lapseCapping,
      cB_SL_lapseCapping: value.cB_SL_lapseCapping,
      cB_PF_CodeType: value.cB_PF_CodeType,
      isCLRAApplicable: value.isCLRAApplicable,
      cB_CompanyHash: value.cB_CompanyHash,
      cB_EDLI_ExcemptionType: value.cB_EDLI_ExcemptionType,
      cM_Industry_Type: value.cM_Industry_Type,
      clrA_Type: value.clrA_Type,
      cB_Contractor_EmployerName: value.cB_Contractor_EmployerName
    }
    const url = `${this.apiUrl}/api/Onboarding/UpdateClientBasicDetailsInfo`;
    return this.http.post(url, body, { observe: 'response' });
  }
}
