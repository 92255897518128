import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-cd-daily-updates',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cd-daily-updates.component.html',
  styleUrl: './cd-daily-updates.component.css'
})
export class CdDailyUpdatesComponent {
  currentIndex: number = 0;

  // Simulating the updates array (this can come from a service or API)
  updates = [
    {
      title: 'Karnataka Govt. issued the Draft Ka...',
      date: '09 Aug 2024',
      description: 'The Government of Karnataka on Aug 23, 2024, issued the draft Karnataka Excise (Excise Duties and Fees)...'
    },
    {
      title: 'Karnataka Govt. issued the Draft Ka...',
      date: '09 Aug 2024',
      description: 'The Government of Karnataka on Aug 23, 2024, issued the draft Karnataka Excise (Excise Duties and Fees)...'
    },
    {
      title: 'Karnataka Govt. issued the Draft Ka...',
      date: '09 Aug 2024',
      description: 'The Government of Karnataka on Aug 23, 2024, issued the draft Karnataka Excise (Excise Duties and Fees)...'
    },
    {
      title: 'Karnataka Govt. issued the Draft Ka...',
      date: '09 Aug 2024',
      description: 'The Government of Karnataka on Aug 23, 2024, issued the draft Karnataka Excise (Excise Duties and Fees)...'
    },
    {
      title: 'Karnataka Govt. issued the Draft Ka...',
      date: '09 Aug 2024',
      description: 'The Government of Karnataka on Aug 23, 2024, issued the draft Karnataka Excise (Excise Duties and Fees)...'
    },
    {
      title: 'Karnataka Govt. issued the Draft Ka...',
      date: '09 Aug 2024',
      description: 'The Government of Karnataka on Aug 23, 2024, issued the draft Karnataka Excise (Excise Duties and Fees)...'
    },
    {
      title: 'Karnataka Govt. issued the Draft Ka...',
      date: '09 Aug 2024',
      description: 'The Government of Karnataka on Aug 23, 2024, issued the draft Karnataka Excise (Excise Duties and Fees)...'
    },
    // Add more updates as needed
  ];

  // Function to handle right navigation
  next() {
    const cardWidth = document.querySelector('.card')?.clientWidth || 0;
    const visibleCards = Math.floor((document.querySelector('.carousel-wrapper')?.clientWidth || 0) / cardWidth);
    if (this.currentIndex < this.updates.length - visibleCards) {
      this.currentIndex++;
      this.updateTranslateX();
    }
  }

  // Function to handle left navigation
  prev() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.updateTranslateX();
    }
  }

  // Function to update the translateX for the carousel
  private updateTranslateX() {
    const cardWidth = document.querySelector('.card')?.clientWidth || 0;
    const updatesContainer = document.querySelector('.updates') as HTMLElement;
    if (updatesContainer) {
      updatesContainer.style.transform = `translateX(-${this.currentIndex * (cardWidth + 20)}px)`; // 20px gap
    }
  }
}
