<kendo-dialog #dialogRef title="Branch Details" (close)="closePopup()" [minWidth]="900" [height]="580"
    [width]="1000" class="custom-dialog">
    <div class="row col-12 dialog-content">

        <form [formGroup]="form">

            <div class="row  PremiseContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Entity/Client<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="text" [(ngModel)]="Entity" [ngModelOptions]="{standalone: true}" [disabled]="true"
                            class="form-control f-textbox">
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Catering Applicability<sub class="text-danger">*</sub></label>
                    <div class="form-check">
                        <input type="checkbox" [(ngModel)]="cateringApplicability" [disabled]="role === 'PERF' || role === 'RVW1'"
                        [ngClass]="{'is-invalid-check':showValidation && !cateringApplicability &&  !(selectedState && (selectedState.id === 'TMN' || selectedState.id === 'PND')) || showValidationErrors}"
                            [ngModelOptions]="{standalone: true}" (change)="validateCateringApplicability($event)">
                        <div *ngIf="showValidation && !cateringApplicability && !(selectedState?.id === 'TMN' || selectedState?.id === 'PND')"
                            class="validation-error text-invalid">
                            {{ errorMessage }}
                        </div>
                    </div>
                </div>
            </div>



            <div class="row PremiseContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Branch Name<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="text"  formControlName="branchName"
                        placeholder="Branch Name" [readOnly]="true"
                       class="f-textbox">
                       
                </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label me-3">State<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <!-- <app-combo-box class="uniform-size" [Placeholder]="'Search/Select State'" [width]="'250px'"
                    [selectedValue]="selectedState" [textField]="'name'" [valueField]="'id'"
                        (valueChange)="DropdownValueChange($event)" [data]="allStatesData" formControlName="state"
                        [hasError]="!(selectedState && selectedState.id) && showValidationErrors" 
                        [class.input-invalid]="!(selectedState && selectedState.id) && showValidationErrors"
                        (keypress)="allowOnlyLettersAndSpaces($event)">
                    </app-combo-box> -->
                    <kendo-combobox [data]="allStatesData" [value]="selectedState"
                     textField="name" valueField="id" 
                    (valueChange)="DropdownValueChange($event)"  (keypress)="allowOnlyLettersAndSpaces($event)" [disabled]="role === 'PERF' || role === 'RVW1'"
                    [class.input-invalid]="!selectedState?.id && showValidationErrors"
                    >
                    </kendo-combobox>
                </div>
            </div>
            </div>

            <div class="row PremiseContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Branch Address <span class="text-danger">*</span></label>
                    <div class="require-container">
                        <textarea type="text" formControlName="branchAddress" placeholder="Branch Address"
                            [ngClass]="{'is-invalid': form.get('branchAddress')?.invalid && (form.get('branchAddress')?.touched)}" [readOnly]="role === 'PERF' || role === 'RVW1'"
                            class="form-control f-textbox" [(ngModel)]="branchAddress"></textarea>
                        <div *ngIf="form.get('branchAddress')?.invalid && (form.get('branchAddress')?.touched)"
                            class="text-danger">
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Location/City<span class="text-danger">*</span></label>
                    <div class="require-container">
                        <kendo-combobox [data]="allLocationData" [value]="selectedLocation"
                         textField="name" valueField="id" (keypress)="allowOnlyLettersAndSpaces($event)"
                        (valueChange)="DropdownLocationChange($event)" [disabled]="role === 'PERF' || role === 'RVW1'"
                        [class.input-invalid]="!selectedLocation?.id && showValidationErrors"
                        >
                    </kendo-combobox>
                    </div>
                </div>

            </div>

            <div class="row PremiseContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Location Code</label>
                    <div class="require-container">
                        <input type="text" placeholder="Location Code" [disabled]="true" class="form-control f-textbox"
                            [(ngModel)]="locationCode" formControlName="locationCode">
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">PT State</label>
                    <div class="require-container">
                        <app-combo-box class="uniform-size" [Placeholder]="defaultState"
                            (filterChange)="fetchStateMasters()" [data]="allStatesData"
                            (valueChange)="DropDownPTValueChange($event)" [selectedValue]="selectedPtState"
                            [width]="'250px'" (keypress)="allowOnlyLettersAndSpaces($event)" [disabled]="true" [isDisabled]="role === 'PERF' || role === 'RVW1'">
                        </app-combo-box>
                    </div>
                </div>

            </div>

            <div class="row PremiseContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">LWF State</label>
                    <div class="require-container">
                        <app-combo-box class="uniform-size" [Placeholder]="defaultState"
                            (filterChange)="fetchStateMasters()" [selectedValue]="selectedLwfState"
                            (valueChange)="DropdownLWFStateChange($event)" [data]="allStatesData" [width]="'250px'" [isDisabled]="role === 'PERF' || role === 'RVW1'"
                            (keypress)="allowOnlyLettersAndSpaces($event)" >
                        </app-combo-box>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">ESI Code</label>
                    <div class="require-container">
                        <input type="text" formControlName="ESICCode" class="form-control f-textbox masking"
                            placeholder="ESIC Number" maxlength="17" style="width: 210px;" [(ngModel)]="ESICCode" [readOnly]="role === 'PERF' || role === 'RVW1'"
                            (input)="formatESICCode($event)"
                            [ngClass]="{'is-invalid': form.get('ESICCode')?.invalid && form.get('ESICCode')?.touched}" />
                        <button type="button" class="figma-btn-white"  (click)="toggleESICCodeVisibility()">
                            <img *ngIf="showESICCode" src="../assets/vectors/eye_112_x2.svg"
                                alt="Toggle ESIC Code visibility" />
                            <img *ngIf="!showESICCode" src="../assets/vectors/EyeClosed.svg" alt="" />
                        </button>
                        <div *ngIf="form.get('ESICCode')?.invalid && form.get('ESICCode')?.touched" class="text-danger">
                            <div *ngIf="form.get('ESICCode')?.errors?.['pattern']">
                                ESIC Code must be exactly 17 digits.
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row PremiseContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">PF Code</label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox masking" placeholder="PF Number"
                            formControlName="pfCode" (input)="formatPFCode($event)" [(ngModel)]="pfCode"
                            [ngClass]="{'is-invalid': form.get('pfCode')?.invalid && form.get('pfCode')?.touched}" [readOnly]="role === 'PERF' || role === 'RVW1'"
                            maxlength="22" style="width:210px;" />
                        <button type="button" class="figma-btn-white" (click)="togglePFCodeVisibility()">
                            <img *ngIf="showpfCode" src="../assets/vectors/eye_112_x2.svg"
                                alt="Toggle PF Number visibility" />
                            <img *ngIf="!showpfCode" src="../assets/vectors/EyeClosed.svg" alt="">
                        </button>
                        <div *ngIf="form.get('pfCode')?.invalid && form.get('pfCode')?.touched" class="text-danger">
                            <div
                                *ngIf="form.get('pfCode')?.errors?.['pattern'] || form.get('pfCode')?.errors?.['minlength'] || form.get('pfCode')?.errors?.['maxlength']">
                                PF Number must be exactly 22 alphanumeric characters.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Establishment Type<span class="text-danger">*</span></label>
                    <div class="require-container">
                        <app-dropdown-list [data]="establishmentTypes" [selectedValue]="selectedEstablishmentType" [isDisabled]="role === 'PERF' || role === 'RVW1'"
                            [width]="'250px'" textField="text" valueField="value"
                            (valueChange)="onEstablishmentTypeChange($event)"
                            [class.input-invalid]="!selectedEstablishmentType.value && showValidationErrors"
                            [hasError]="!selectedEstablishmentType.value && showValidationErrors">
                        </app-dropdown-list>
                    </div>
                </div>

            </div>

            <div class="row PremiseContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Office Type<span class="text-danger">*</span></label>
                    <div class="require-container">
                        <app-dropdown-list [data]="officeTypes" [selectedValue]="selectedOfficeType" textField="text" [isDisabled]="role === 'PERF' || role === 'RVW1'"
                            valueField="value" [width]="'250px'" (valueChange)="onOfficeTypeChange($event)"
                            [hasError]="!selectedOfficeType.value && showValidationErrors"
                            [class.input-invalid]="!selectedOfficeType.value && showValidationErrors">
                        </app-dropdown-list>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Employer Name<span class="text-danger">*</span></label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="Employee Name"
                            formControlName="employerName" [(ngModel)]="employerName" [readOnly]="role === 'PERF' || role === 'RVW1'"
                            (keydown)="blockInvalidCharacters($event)"
                            [ngClass]="{'is-invalid': form.get('employerName')?.invalid && (form.get('employerName')?.touched)}">
                        <div *ngIf="form.get('employerName')?.invalid && (form.get('employerName')?.touched)"
                            class="text-danger">
                            <div *ngIf="form.get('employerName')?.errors?.['pattern']">
                                Name must contain only letters and spaces.
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row PremiseContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Employer Address<span class="text-danger">*</span></label>
                    <div class="require-container">
                        <textarea type="text" formControlName="employerAddress" placeholder="Employee Address" [readOnly]="role === 'PERF' || role === 'RVW1'"
                            [ngClass]="{'is-invalid': form.get('employerAddress')?.invalid && (form.get('employerAddress')?.touched)}"
                            class="form-control f-textbox" [(ngModel)]="employerAddress"></textarea>
                        <div *ngIf="form.get('employerAddress')?.invalid && (form.get('employerAddress')?.touched)"
                            class="text-danger">
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Manager Name<span class="text-danger">*</span></label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="Manager Name"
                            formControlName="managerName" [(ngModel)]="managerName"
                            (keydown)="blockInvalidCharacters($event)" [readOnly]="role === 'PERF' || role === 'RVW1'"
                            [ngClass]="{'is-invalid': form.get('managerName')?.invalid && (form.get('managerName')?.touched)}">
                        <div *ngIf="form.get('managerName')?.invalid && (form.get('managerName')?.touched)"
                            class="text-danger">
                            <div *ngIf="form.get('managerName')?.errors?.['pattern']">
                                Name must contain only letters and spaces.
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row PremiseContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Manager Address<span class="text-danger">*</span></label>
                    <div class="require-container">
                        <textarea type="text" formControlName="managerAddress" placeholder="Manager Address"
                            [ngClass]="{'is-invalid': form.get('managerAddress')?.invalid && (form.get('managerAddress')?.touched)}" [readOnly]="role === 'PERF' || role === 'RVW1'"
                            class="form-control f-textbox" [(ngModel)]="managerAddress"></textarea>
                        <div *ngIf="form.get('managerAddress')?.invalid && (form.get('managerAddress')?.touched)"
                            class="text-danger">
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">HR Contact Person</label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="HR Contact Person"
                            formControlName="hrContactPerson" [(ngModel)]="hrContactPerson" [readOnly]="role === 'PERF' || role === 'RVW1'"
                            [ngClass]="{'is-invalid': form.get('hrContactPerson')?.invalid && (form.get('hrContactPerson')?.touched)}" (keypress)="allowOnlyLettersAndSpaces($event)">
                        <div *ngIf="form.get('hrContactPerson')?.invalid && (form.get('hrContactPerson')?.touched)"
                            class="text-danger">
                            <div *ngIf="form.get('hrContactPerson')?.errors?.['pattern']">
                                Name must contain only letters and spaces.
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row PremiseContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Company Ph. No<span class="text-danger">*</span></label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="Mobile No"
                            [(ngModel)]="companyPhnNo" formControlName="companyPhnNo" minlength="10" maxlength="10" [readOnly]="role === 'PERF' || role === 'RVW1'"
                            (input)="validateMobileNo($event)"
                            [ngClass]="{'is-invalid': form.get('companyPhnNo')?.invalid && (form.get('companyPhnNo')?.touched)}">
                        <div *ngIf="form.get('companyPhnNo')?.invalid && (form.get('companyPhnNo')?.touched)"
                            class="text-danger">
                            <div *ngIf="form.get('companyPhnNo')?.errors?.['pattern']">
                                Mobile No must be exactly 10 digits.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">HR Mail ID</label>
                    <div class="require-container">
                        <input type="email" class="form-control f-textbox" placeholder="Email"
                            formControlName="hrMailId"
                            [ngClass]="{'is-invalid': form.get('hrMailId')?.invalid && (form.get('hrMailId')?.touched)}" [readOnly]="role === 'PERF' || role === 'RVW1'"
                            [(ngModel)]="hrMailId">
                        <div *ngIf="form.get('hrMailId')?.invalid && (form.get('hrMailId')?.touched)"
                            class="text-danger">
                            <div *ngIf="form.get('hrMailId')?.errors?.['pattern']">
                                Please enter a valid email address.
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row PremiseContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">HR Ph. No<span class="text-danger">*</span></label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="Mobile No" [(ngModel)]="hrPhnNo" [readOnly]="role === 'PERF' || role === 'RVW1'"
                            formControlName="hrPhnNo" minlength="10" maxlength="10" (input)="validateMobileNo($event)"
                            [ngClass]="{'is-invalid': form.get('hrPhnNo')?.invalid && (form.get('hrPhnNo')?.touched)}">
                        <div *ngIf="form.get('hrPhnNo')?.invalid && (form.get('hrPhnNo')?.touched)" class="text-danger">
                            <div *ngIf="form.get('hrPhnNo')?.errors?.['pattern']">
                                Mobile No must be exactly 10 digits.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">HR 1st Level Phn No</label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="Mobile No"
                            [(ngModel)]="hrFirstPhnNo" formControlName="hrFirstPhnNo" minlength="10" maxlength="10" [readOnly]="role === 'PERF' || role === 'RVW1'"
                            (input)="validateMobileNo($event)"
                            [ngClass]="{'is-invalid': form.get('hrFirstPhnNo')?.invalid && (form.get('hrFirstPhnNo')?.touched)}">
                        <div *ngIf="form.get('hrFirstPhnNo')?.invalid && (form.get('hrFirstPhnNo')?.touched)"
                            class="text-danger">
                            <div *ngIf="form.get('hrFirstPhnNo')?.errors?.['pattern']">
                                Mobile No must be exactly 10 digits.
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row PremiseContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">HR 1st Level Mail</label>
                    <div class="require-container">
                        <input type="email" class="form-control f-textbox" placeholder="Email"
                            formControlName="hrFirstMail"
                            [ngClass]="{'is-invalid': form.get('hrFirstMail')?.invalid && (form.get('hrFirstMail')?.touched)}" [readOnly]="role === 'PERF' || role === 'RVW1'"
                            [(ngModel)]="hrFirstMail">
                        <div *ngIf="form.get('hrFirstMail')?.invalid && (form.get('hrFirstMail')?.touched)"
                            class="text-danger">
                            <div *ngIf="form.get('hrFirstMail')?.errors?.['pattern']">
                                Please enter a valid email address.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">RC No:</label>
                    <div class="require-container">
                        <input type="number" placeholder="RC No" class="form-control f-textbox" [(ngModel)]="rcNo"
                            formControlName="rcNumber">
                    </div>
                </div>

            </div>

            <div class="row PremiseContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Nature of Business<span class="text-danger">*</span></label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="Nature of Business"
                            [(ngModel)]="natureOfBusiness" formControlName="natureOfBusiness" [readOnly]="role === 'PERF' || role === 'RVW1'"
                            [class.input-invalid]="!natureOfBusiness && showValidationErrors"
                            [ngClass]="{'is-invalid': form.get('natureOfBusiness')?.invalid && (form.get('natureOfBusiness')?.touched)}" (keypress)="allowOnlyLettersAndSpaces($event)">
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">RC Valid (From)</label>
                    <div class="require-container">
                        <app-kendo-datepicker class="uniform-size" [(value)]="rcValidFrom"
                            (valueChange)="onDateChange($event, 'rcValidFrom')" [format]="'dd-MM-yyyy'"
                            [placeholder]="placeholder" [width]="'250px'" [isDisabled]="role === 'PERF' || role === 'RVW1'">
                        </app-kendo-datepicker>
                    </div>
                </div>

            </div>

            <div class="row PremiseContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">RC Valid (To)</label>
                    <div class="require-container">
                        <app-kendo-datepicker class="uniform-size" [(value)]="rcValidTo"
                            (valueChange)="onDateChange($event, 'rcValidTo')" [format]="'dd-MM-yyyy'"
                            [placeholder]="placeholder" [width]="'250px'" [isDisabled]="role === 'PERF' || role === 'RVW1'">
                        </app-kendo-datepicker>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Work Timings<span class="text-danger">*</span></label>
                    <div class="require-container">
                        <app-dropdown-list [data]="workTimings" [selectedValue]="selectedWorkTiming" textField="text" [isDisabled]="role === 'PERF' || role === 'RVW1'"
                            valueField="value" [width]="'250px'" formControlName="worktiming"
                            (valueChange)="onWorkTimingChange($event)"
                            [hasError]="!selectedWorkTiming.value && showValidationErrors">
                        </app-dropdown-list>
                    </div>
                </div>
            </div>

            <div class="row PremiseContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Week Off Days<span class="text-danger">*</span></label>
                    <div class="require-container">
                        <kendo-multiselect [data]="filteredWeekDays" [textField]="'text'" [valueField]="'value'" [disabled]="role === 'PERF' || role === 'RVW1'"
                            (valueChange)="onSelectedDays($event)" [filterable]="true" [placeholder]="'Select'"
                            class="f-textbox" formControlName="weekoffday" [(ngModel)]="selectedDays"
                            [class.input-invalid]="!selectedDays.length && showValidationErrors"
                            [ngClass]="{'is-invalid': !selectedDays.length && showValidationErrors}">
                        </kendo-multiselect>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Municipality<span class="text-danger">*</span></label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" [(ngModel)]="municipality"
                            formControlName="municipality" placeholder="Muncipality"
                            [class.input-invalid]="!municipality && showValidationErrors" [readOnly]="role === 'PERF' || role === 'RVW1'"
                            [ngClass]="{'is-invalid': form.get('municipality')?.invalid && (form.get('municipality')?.touched)}">
                        <div *ngIf="form.get('municipality')?.invalid && (form.get('municipality')?.touched)"
                            class="text-danger">
                            <div *ngIf="form.get('municipality')?.errors?.['required']">

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row PremiseContainer" *ngIf="selectedWorkTiming.value === 'STANDARD'">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Work Hours (From)<span class="text-danger">*</span></label>
                    <div class="require-container">
                        <kendo-dropdownlist [data]="timeIntervals" [defaultItem]="'Select'" [valuePrimitive]="true" [disabled]="role === 'PERF' || role === 'RVW1'"
                            [(ngModel)]="workHoursFrom" [ngModelOptions]="{standalone: true}" class="f-textbox"
                            [class.input-invalid]="!workHoursFrom && showValidationErrors"
                            [ngClass]="{'is-invalid': !workHoursFrom && showValidationErrors}">
                        </kendo-dropdownlist>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Work Hours (To)<span class="text-danger">*</span></label>
                    <div class="require-container">
                        <kendo-dropdownlist [data]="timeIntervals" [defaultItem]="'Select'" [valuePrimitive]="true" [disabled]="role === 'PERF' || role === 'RVW1'"
                            [(ngModel)]="workHoursTo" [ngModelOptions]="{standalone: true}" class="f-textbox"
                            [class.input-invalid]="!workHoursTo && showValidationErrors"
                            [ngClass]="{'is-invalid': !workHoursTo && showValidationErrors}">
                        </kendo-dropdownlist>
                    </div>
                </div>
            </div>

            <div class="row PremiseContainer" *ngIf="selectedWorkTiming.value === 'STANDARD'">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Intervals (From)<span class="text-danger">*</span></label>
                    <div class="require-container">
                        <kendo-dropdownlist [data]="timeIntervals" [defaultItem]="'Select'" [valuePrimitive]="true" [disabled]="role === 'PERF' || role === 'RVW1'"
                            [(ngModel)]="intervalsFrom" [ngModelOptions]="{standalone: true}" class="f-textbox"
                            [class.input-invalid]="!intervalsFrom && showValidationErrors"
                            [ngClass]="{'is-invalid': !intervalsFrom && showValidationErrors}">
                        </kendo-dropdownlist>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Intervals (To)<span class="text-danger">*</span></label>
                    <div class="require-container">
                        <kendo-dropdownlist [data]="timeIntervals" [defaultItem]="'Select'" [valuePrimitive]="true" [disabled]="role === 'PERF' || role === 'RVW1'"
                            [(ngModel)]="intervalsTo" [ngModelOptions]="{standalone: true}" class="f-textbox"
                            [class.input-invalid]="!intervalsTo && showValidationErrors"
                            [ngClass]="{'is-invalid': !intervalsTo && showValidationErrors}">
                        </kendo-dropdownlist>
                    </div>
                </div>
            </div>

            <div class="row PremiseContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Business Type<span class="text-danger">*</span></label>
                    <div class="require-container">
                        <app-dropdown-list [data]="businessTypes" [selectedValue]="selectedBusinessType"
                            textField="text" valueField="value" (valueChange)="onSelectedBusinessType($event)" [isDisabled]="role === 'PERF' || role === 'RVW1'"
                            [class.input-invalid]="!selectedBusinessType.value && showValidationErrors"
                            [width]="'250px'" [hasError]="!selectedBusinessType.value && showValidationErrors">
                        </app-dropdown-list>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Power to impose fines<span class="text-danger">*</span></label>
                    <div class="require-container">
                        <app-dropdown-list [data]="Types" [selectedValue]="SelectedPowerToImpose" textField="text"
                            valueField="value" (valueChange)="onSelectedPowerToImpose($event)" [width]="'250px'" [isDisabled]="role === 'PERF' || role === 'RVW1'"
                            [class.input-invalid]="!SelectedPowerToImpose.value && showValidationErrors"
                            [hasError]="!SelectedPowerToImpose.value && showValidationErrors">
                        </app-dropdown-list>
                    </div>
                </div>
            </div>

            <div class="row PremiseContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Maintain Forms<span class="text-danger">*</span></label>
                    <div class="require-container">
                        <app-dropdown-list [data]="Types" [selectedValue]="selectedMaintainType" textField="text"
                            valueField="value" (valueChange)="onSelectedMaintainType($event)" [width]="'250px'" [isDisabled]="role === 'PERF' || role === 'RVW1'"
                            [class.input-invalid]="!selectedMaintainType.value && showValidationErrors"
                            [hasError]="!selectedMaintainType.value && showValidationErrors">
                        </app-dropdown-list>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Commencement Date</label>
                    <div class="require-container">
                        <app-kendo-datepicker class="uniform-size" [(value)]="commencementDate"
                            (valueChange)="onDateChange($event, 'commencementDate')" [format]="'dd-MM-yyyy'"
                            [width]="'250px'" [placeholder]="placeholder" [isDisabled]="role === 'PERF' || role === 'RVW1'">
                        </app-kendo-datepicker>
                    </div>
                </div>
            </div>

            <div class="row PremiseContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">LIN</label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="LIN" [(ngModel)]="lin"
                            formControlName="lin">
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Licence No</label>
                    <div class="require-container">
                        <input type="text" placeholder="Licence No" class=" f-textbox" [(ngModel)]="licenceNo"
                            formControlName="licenceNo">
                    </div>
                </div>
            </div>

            <div class="row PremiseContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Classification of Est</label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="Classiification of EST"
                            formControlName="classificationOfEst" [(ngModel)]="classificationOfEst" [readOnly]="role === 'PERF' || role === 'RVW1'"
                            [ngClass]="{'is-invalid': form.get('classificationOfEst')?.invalid && (form.get('classificationOfEst')?.touched)}">
                        <div *ngIf="form.get('classificationOfEst')?.invalid && (form.get('classificationOfEst')?.touched)"
                            class="text-danger">
                            <div *ngIf="form.get('classificationOfEst')?.errors?.['pattern']">
                                IT must contain only letters and spaces.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Section of Act</label>
                    <div class="require-container">
                        <app-dropdown-list [data]="sectionOfActOptions" [selectedValue]="selectedSectionOfAct" [isDisabled]="role === 'PERF' || role === 'RVW1'"
                            [width]="'250px'" textField="text" valueField="value"
                            (valueChange)="onSelectedSectionOfAct($event)">
                        </app-dropdown-list>
                    </div>
                </div>
            </div>

            <div class="row PremiseContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">NIC Code</label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="NIC Code"
                            formControlName="nicCode" [(ngModel)]="nicCode" [readOnly]="role === 'PERF' || role === 'RVW1'"
                            [ngClass]="{'is-invalid': form.get('nicCode')?.invalid && (form.get('nicCode')?.touched)}">
                        <div *ngIf="form.get('nicCode')?.invalid && (form.get('nicCode')?.touched)" class="text-danger">
                            <div *ngIf="form.get('nicCode')?.errors?.['pattern']">
                                IT must contain only letters and spaces.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Jurisdiction</label>
                    <div class="require-container">
                        <input type="text" placeholder="Jurisdiction" class="form-control f-textbox"
                            [(ngModel)]="jurisdiction" formControlName="jurisdiction">
                    </div>
                </div>
            </div>

            <div class="row PremiseContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">District</label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="District"
                            formControlName="district" [(ngModel)]="district" [readOnly]="role === 'PERF' || role === 'RVW1'"
                            [ngClass]="{'is-invalid': form.get('district')?.invalid && (form.get('district')?.touched)}">
                        <div *ngIf="form.get('district')?.invalid && (form.get('district')?.touched)"
                            class="text-danger">
                            <div *ngIf="form.get('district')?.errors?.['pattern']">
                                IT must contain only letters and spaces.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Trade Licence<span class="text-danger">*</span></label>
                    <div class="require-container">
                        <app-dropdown-list [data]="Types" [selectedValue]="selectedTradeLicence"
                            (valueChange)="onSelectedTradeLicence($event)" textField="text" valueField="value"
                            [width]="'250px'" [isDisabled]="role === 'PERF' || role === 'RVW1'"
                            [class.input-invalid]="!selectedTradeLicence.value && showValidationErrors"
                            [hasError]="!selectedTradeLicence.value && showValidationErrors">
                        </app-dropdown-list>
                    </div>
                </div>
            </div>

            <div class="row PremiseContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">No Of Employee</label>
                    <div class="require-container">
                        <input type="number" placeholder="No of Employee" class=" f-textbox" format="n0" [min]="0"
                            [max]="100" [(ngModel)]="noOfEmployee" formControlName="noOfEmp">
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Branch Start Date</label>
                    <div class="require-container">
                        <app-kendo-datepicker class="uniform-size" [(value)]="commencementDate"
                            (valueChange)="onDateChange($event, 'commencementDate')" [format]="'dd-MM-yyyy'"
                            [placeholder]="placeholder" [width]="'250px'" [isDisabled]="role === 'PERF' || role === 'RVW1'">
                        </app-kendo-datepicker>
                    </div>
                </div>
            </div>

            <div class="row PremiseContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Branch End Date</label>
                    <div class="require-container">
                        <app-kendo-datepicker class="uniform-size" [(value)]="branchEndDate"
                            (valueChange)="onDateChange($event, 'branchEndDate')" [format]="'dd-MM-yyyy'"
                            [placeholder]="placeholder" [width]="'250px'" [isDisabled]="role === 'PERF' || role === 'RVW1'" >
                        </app-kendo-datepicker>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Status<span class="text-danger">*</span></label>
                    <div class="require-container">
                        <app-dropdown-list [data]="statusType" [selectedValue]="selectedStatusType" textField="text" [isDisabled]="role === 'PERF' || role === 'RVW1'"
                             valueField="value" [width]="'250px'"
                            (valueChange)="onSelectedStatusType($event)"
                            [hasError]="!selectedStatusType.value && showValidationErrors">
                        </app-dropdown-list>
                    </div>
                </div>
            </div>

            <div class="row PremiseContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Employer Designation</label>
                    <div class="require-container">
                        <input type="text" class=" f-textbox" Placeholder="Enter Designation" formControlName="designation" 
                            [(ngModel)]="employerDesignation">
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Location Anchor<span class="text-danger">*</span></label>
                    <div class="require-container">
                        <kendo-combobox class="uniform-size" Placeholder="Select Anchor" 
                            [data]="allAnchorData" [value]="selectedAnchor" 
                            [valueField]="'id'" [textField]="'name'"
                            (valueChange)="DropdownAnchorChange($event)" (keypress)="allowOnlyLettersAndSpaces($event)" [disabled]="role === 'PERF' || role === 'RVW1'"
                            [class.input-invalid]="!selectedAnchor?.id && showValidationErrors"
                           >
                        </kendo-combobox>

                    </div>   
                </div>
            </div>

            <div class="row PremiseContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">EDLI Excemption</label>
                    <div class="require-container">
                        <app-dropdown-list [data]="Types" [selectedValue]="selectedEDLI" [width]="'250px'"
                           [isDisabled]="role==='PERF' || role==='RVW1'" (valueChange)="onSelectedEDLI($event)" formControlName="edli" textField="text" valueField="value">
                        </app-dropdown-list>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center" *ngIf="selectedWorkTiming.value === 'SHIFT'">
                    <label class="f-label me-3">Add Shift Master<span class="text-danger">*</span></label>
                    <div class="require-container">
                        <button kendoButton (click)="openShiftMasterDetails()" [disabled]="role==='PERF' || role==='RVW1'"
                            style="border: 0; background: none; margin-left: 60px;">
                            <img src="../assets/vectors/RightArrowButton.svg" alt="">
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <button kendoButton class="figma-btn-blue figma-popup custom-button" [disabled]="role==='PERF' || role==='RVW1'" (click)="savePremiseData()"
        style="color: white;">
        Save
    </button>

</kendo-dialog>
<div kendoDialogContainer *ngIf="openShiftDetails">
    <app-add-shift-master-details [rowData]="rowData" (close)="handleShiftMasterClose()">

    </app-add-shift-master-details>
</div>


<app-alert-popup *ngIf="alertData" [Width]="270"   [Data]="alertData"
    (close)="closeAlertPopup()"></app-alert-popup>