import { CommonModule, NgIf } from '@angular/common';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { FormsModule } from '@angular/forms';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { LocationMasterComponent } from '../../manage-masters/location-master/location-master.component';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { PremiseMasterService } from '../../../service/premise-master.service';
import { UserService } from '../../../service/user.service';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-premise-upload-holiday-master-popup',
  standalone: true,
  imports: [NgIf, GridActionItemComponent, CommonModule, FormsModule, DialogModule, LocationMasterComponent, AlertPopupComponent, IndicatorsModule],
  templateUrl: './premise-upload-holiday-master-popup.component.html',
  styleUrl: './premise-upload-holiday-master-popup.component.css'
})
export class PremiseUploadHolidayMasterPopupComponent implements OnInit{
  @Output() close = new EventEmitter<void>();
  dropzoneMessage: string = "Drag your files here or ";
  selectedFile?: File;
  alertData = { alertMessage: '', alertVisible: '' };
  loading=false;
  holidayType!:string;
  constructor(private premiseService: PremiseMasterService, private user: UserService) { }

  ngOnInit(): void {
    this.Get_Holiday_Applicability();
  }
  public onFileSelect(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
      this.selectedFile = input.files[0];
      console.log('Selected file:', this.selectedFile);
      const fileExtension = this.selectedFile.name.split('.').pop()?.toLowerCase();
      if (fileExtension !== 'xlsx') {
        this.selectedFile = undefined;
        this.alertData = {
          alertMessage: 'Please upload XLSX file only.',
          alertVisible: 'error'
        };
      }
    }
  }
  Get_Holiday_Applicability(){
    const entityId=this.user.getClientId();
    this.premiseService.Get_Holiday_Applicability(entityId).subscribe({
      next: (response) => {
        const data = response.body;
       this.holidayType=data.result;
      },
      error: (error: any) => {
        console.error('Error fetching data', error);
      }
    })
  }
  public onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drop your files here or ";
  }

  public onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drag your files here or ";
  }

  public onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      this.selectedFile = event.dataTransfer.files[0];
      event.dataTransfer.clearData();
    }
  }

  uploadHolidayMaster() {
    if (this.selectedFile) {

            const entityId = this.user.getClientId();
            const userId = this.user.getUserId();
            console.log(this.selectedFile,entityId,userId);
            
            this.premiseService.HolidayMasterUpload(this.selectedFile as File, entityId, userId,this.holidayType).subscribe({
                next: (response) => {
                    const data = response.body;
                    console.log(response);
                    
                    if (data.status === 'Invalid headers') {
                      this.selectedFile = undefined;
                      console.error('Invalid headers in the response:', response);
                      this.alertData = { alertMessage: 'Invalid Template.', alertVisible: 'error' };
                    } else if (response.status === 200 && data.status === 'error') {
                      this.selectedFile = undefined;
                      this.downloadErrorFile(response.body.filePath, response?.body.sasToken);
                      this.alertData = {
                        alertMessage: 'Invalid Template.',
                        alertVisible: 'error'
                      };
                    } else if (response.status === 200 && data.status === 'SheetNameError') {
                      this.selectedFile = undefined;
                      this.alertData = {
                        alertMessage: data.status,
                        alertVisible: 'error'
                      };
                    } else if (response.status === 200 && data.status === 'Please Upload File with some data') {
                      this.selectedFile = undefined;
                     
                      this.alertData = {
                        alertMessage: data.status,
                        alertVisible: 'error'
                      };
                    } else {
                      console.log('Upload response:', response);
                      this.alertData = { alertMessage: 'File uploaded successfully', alertVisible: 'success' };
                    }
                },
                error: (error) => {
                    console.log('error', error);
                    this.alertData = {
                        alertMessage: 'Error while uploading the file. Please check error file.',
                        alertVisible: 'error'
                    };
                }
            });
    } else {
        this.alertData = {
            alertMessage: 'You have not chosen any file.',
            alertVisible: 'error'
        };
        console.warn('You have not chosen any file.');
    }
}
downloadErrorFile(FilePath: string, sasToken: string) {
  const blobUrl = `${FilePath}?${sasToken}`;
  const a = document.createElement('a');
  a.href = blobUrl;
  const filename = FilePath.split('/').pop() || 'downloaded_file';
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
  public closeAlert(): void {
    if (this.alertData.alertVisible == 'success') {
      this.closePopup();
    }
    this.alertData = { alertMessage: '', alertVisible: '' };
  }
  public closePopup(): void {
    this.close.emit();
  }
}
