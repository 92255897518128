import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { NoticePortalService } from '../../../service/notice-portal.service';
import { UserService } from '../../../service/user.service';
import { NoticeAddNewPopupComponent } from '../../popups/notice-add-new-popup/notice-add-new-popup.component';
import { NgIf } from '@angular/common';
import { BlobService } from '../../../service/blob.service';
import { environment } from '../../../environments/environment';
import { AdobeViewerComponent } from '../../../components/adobe-viewer/adobe-viewer.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-notice-client-portal',
  standalone: true,
  imports: [GridActionItemComponent,NoticeAddNewPopupComponent,NgIf,AdobeViewerComponent,IndicatorsModule],
  templateUrl: './notice-client-portal.component.html',
  styleUrl: './notice-client-portal.component.css'
})
export class NoticeClientPortalComponent {
  public gridData: GridDataResult = { data: [], total: 0 };
  public filteredData: any[] = [];
  private originalData: any[] = [];
  fileBlob!:any;
  private blobUrl: string = environment.blobUrl;
  @Output() close = new EventEmitter<void>();
  openAddNotice=false
  loading=false
  constructor(private noticeService:NoticePortalService,private user:UserService, private blobService: BlobService){}
  ngOnInit(): void {
    this.getGrid()
  }

  getGrid() {
    const clientId = this.user.getClientId();

    this.noticeService.getGridData(clientId).subscribe({
      next: (response) => {
        console.log('Response', response);
        
        const data = response.body;
        this.gridData = {
          data: data,
          total: data.total
        };

        this.originalData = [...data]; 
        this.filteredData = data;
      }
    });
  }


  applyFilter(event: KeyboardEvent) {
    const inputValue = (event.target as HTMLInputElement).value.toLowerCase();

    if (!inputValue) {
      this.gridData = {
        data: this.originalData,
        total: this.originalData.length 
      };
      this.filteredData = this.originalData; 
    } else {
      this.filteredData = this.gridData.data.filter(item =>
        (item.branchName && item.branchName.toLowerCase().includes(inputValue)) ||
        (item.noticeType && item.noticeType.toLowerCase().includes(inputValue)) ||
        (item.status && item.status.toLowerCase().includes(inputValue))
      );

      this.gridData = {
        data: this.filteredData,
        total: this.filteredData.length
      };
    }
  }
  
  handleButtonClick(value: any) {
    debugger;
    console.log(value);
    
    const noticeDocument = value?.dataItem?.noticeDocument;
  
    if (!noticeDocument) {
      console.log('Notice document is not available.');
      return;
    }
  
    if (value?.button?.action === 'overview') {
      this.loading=true;
      this.noticeService.fetchFile(noticeDocument).subscribe({
        next: (blob: Blob) => {
          debugger;
          this.fileBlob = blob;
          this.loading=false;
        },
        error: (error) => {
          this.loading=false;
          console.error('Error fetching Branch Dropdown data', error);
        }
      });

    } else if (value?.button?.action === 'download') {
      debugger;
      const blobLink = this.blobUrl + noticeDocument;
      const blobName = this.blobService.extractBlobName(noticeDocument);
      this.blobService.getSasToken(blobName).subscribe(
        (tokenResponse: any) => {
          try {
            debugger;
            const sasToken = tokenResponse.result;
            this.blobService.downloadFile(blobName, sasToken);
          } catch (error) {
          }
        },
        (error) => { 
        }
      );
    } else {
      console.log('Button action is not "overview" or "download".');
    }

  }
  
  closeViewer(){
    this.fileBlob=null;
  }
  openAddNewPopup(){
    this.openAddNotice=true
  }

  public closeAddPopup(): void {
    this.openAddNotice=false;
    this.getGrid();
  }
  public parentColumns: any[] = [
    { field: 'clientId', title: 'Entity Id' },
    { field: 'clientName', title: 'Entity Name' },
    { field: 'branchName', title: 'Branch' },
    { field: 'noticeType', title: 'Notice Type' },
    { field: 'noticeReferenceNo', title: 'Notice No' },
    { field: 'dueDate', title: 'Due Date' },
    { field: 'extensionGrantedDate', title: 'Revised Due Date' },
    { field: 'risk', title: 'Risk' },
    { field: 'status', title: 'Status' },
    { hidden: true,title: "Acknowledgement",field: "acknowledgement"},
    { hidden: true,title: "noticeDocument",field: "noticeDocument"},
    { hidden: true,title: "state",field: "state"},
    { hidden: true,title: "location",field: "location"},
    { hidden: true,title: "noticeReceivedDate",field: "noticeReceivedDate"},
    { hidden: true,title: "translationRequestDate",field: "translationRequestDate"},
    { hidden: true,title: "translationDocumentUploadDate",field: "translationDocumentUploadDate"},
    { hidden: true,title: "modeOfReceipt",field: "modeOfReceipt"},
    { hidden: true,title: "remarks",field: "remarks"},
    { hidden: true,title: "noticeTypeId",field: "noticeTypeId"},
    { hidden: true,title: "sme",field: "sme"},
    { hidden: true,title: "sdExecuter",field: "sdExecuter"},
    { hidden: true,title: "isTranslationRequired",field: "isTranslationRequired"},
    { hidden: true,title: "isExtensionRequired",field: "isExtensionRequired"},
    { hidden: true,title: "extensionGrantedDate",field: "extensionGrantedDate"},
    { hidden: true,title: "extensionApplicationDate",field: "extensionApplicationDate"},
    { hidden: true,title: "extensionDraftExtensionDate",field: "extensionDraftExtensionDate"},
    { hidden: true,title: "draftExtensionApplication",field: "draftExtensionApplication"},
    { hidden: true,title: "noticeDraftResponse",field: "noticeDraftResponse"},
    {
      field: 'Action',
      isIconColumn: true,
      title: 'Action',
      filteredIcons: [
        {
          url: '../assets/vectors/EyeIcon.svg',
          alt: 'Icon 1',
          action: 'overview'
        },
        {
          url: '../assets/vectors/DownloadBlue.svg',
          alt: 'Icon 1',
          action: 'download'
        }
      ]
    } ] 
}

