import { Component, OnInit } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { DigitalSignatureService } from '../../../service/digital-signature.service';
import { UserService } from '../../../service/user.service';
import { NgIf } from '@angular/common';
import { DigitalSignatureAddEditPopupComponent } from '../../popups/digital-signature-add-edit-popup/digital-signature-add-edit-popup.component';

@Component({
  selector: 'app-digital-signature',
  standalone: true,
  imports: [GridActionItemComponent,NgIf,DigitalSignatureAddEditPopupComponent],
  templateUrl: './digital-signature.component.html',
  styleUrl: './digital-signature.component.css'
})
export class DigitalSignatureComponent implements OnInit {
  public gridData: GridDataResult = { data: [], total: 0 };
  public rowData!: string[];
  openAddEdit=false
  constructor(private digitalService:DigitalSignatureService,private user:UserService){}

  ngOnInit(): void {
    this.loadGridData();
  }

  loadGridData(){
    const clientId=this.user.getClientId();
    
    this.digitalService.getGridData(clientId).subscribe({
      next:(response)=>{
        console.log('Response',response);
        
        const data=response.body;
        this.gridData={
          data: data.items || data,
          total:data.total || data.length
        }}})
  }

  openAddNewPopup(){
    this.openAddEdit=true
    this.rowData=[]
  }
  handleButtonClick(event:any){
  console.log('value',event);
  this.rowData=event.dataItem;
  this.openAddEdit=true;
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    const clientId = this.user.getClientId();
    
    this.digitalService.getGridData(clientId).subscribe({
        next: (response) => {
            const data = response.body;
            const filteredData = data.filter((item:any) => 
                item.dsC_EmpId.toString().toLowerCase().includes(filterValue) ||
                item.dsC_EmpName.toLowerCase().includes(filterValue) ||
                item.dsC_Status.toLowerCase().includes(filterValue)
            );

            this.gridData = {
                data: filteredData,
                total: filteredData.length
            };
        }
    });
}

handleClosePopup(){
  this.openAddEdit=false
  this.loadGridData();
}

  public parentColumns: any[] = [
    { field: 'dsC_ClientId', title: 'Client Id' },
    { field: 'dsC_EmpId', title: 'Authorisation Person Id' },
    { field: 'dsC_EmpName', title: 'Authorisation Person Name' },
    { field: 'dsC_EffectiveFrom', title: 'Effective From' },
    { field: 'dsC_EffectiveTo', title: 'Effective To' },
    { field: 'dsC_Status', title: 'Status' },
    {
      field: 'Action',
      isIconColumn: true,
      title: 'Action',
      filteredIcons: [
        {
          url: '../assets/vectors/edit.svg',
          alt: 'Icon 1',
          action: 'Edit'
        },
      ]
    } ] 
}
