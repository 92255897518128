import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterModule, LayoutComponent, LoginComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  showContent = true;

  constructor(private router: Router) { }

  ngOnInit(): void {
    // document.body.style.setProperty('zoom', '80%');
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showContent = !(event.url === '/Login' || event.url === '/');
      }
    });
  }

}
