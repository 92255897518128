import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../environments/environment';

export interface Temp_LocationMaster {
  CustomerID: number;
  CM_ClientID: string;
  AVACOM_BranchName: string;
  CM_State: string;
  CM_City: string;
  CM_Address: string;
  CL_LWF_State: string;
  CL_PT_State: string;
  CL_PF_Code: string;
  CL_ESIC_Code: string;
  CL_OfficeType: string;
  CM_EstablishmentType: string;
  CL_EmployerName: string;
  CL_EmployerAddress: string;
  CL_ManagerName: string;
  CL_ManagerAddress: string;
  CL_CompPhoneNo: string;
  CL_HRContactPerson: string;
  CL_HRPhNo: string;
  CL_HRMailID: string;
  CL_HR1stLevelMail: string;
  CL_HR1stLevelPhNo: string;
  CL_RCNO: string;
  CL_RC_ValidFrom: string;
  CL_RC_ValidTo: string;
  CL_NatureofBusiness: string;
  CL_weekoffDay: string;
  CL_WorkHoursFrom: string;
  CL_WorkHoursTo: string;
  CL_IntervalsFrom: string;
  CL_IntervalsTo: string;
  CL_WorkTimings: string;
  CM_Status: string;
  CL_LIN: string;
  CL_Municipality: string;
  CL_PermissionMaintainingForms: string;
  CL_RequirePowerforFines: string;
  CL_BusinessType: string;
  CL_CommencementDate: string;
  CL_ClassificationofEstablishment: string;
  CL_LicenceNo: string;
  CL_NICCode: string;
  CL_SectionofAct: string;
  CL_District: string;
  CL_Juridiction: string;
  CL_BranchEndDate: string;
  CL_TradeLicenceApplicability: string;
  CL_EmployerDesignation: string;
  CL_EDLI_Excemption: string;
  CL_LocationAnchor: string;
  FileID: number;
  RowNo: number;
  AVACOM_BranchID: number;
}

export interface ShiftMasterList {
  Id: number;
  ClientId: string;
  StateId: string;
  LocationID: string;
  Branch: string;
  BranchName: string;
  ShiftName: string;
  ShiftCode: string;
  NightShift: string;
  ShiftFrom: string;
  ShiftTo: string;
  ShiftIntervalFrom: string;
  ShiftIntervalTo: string;
  CreatedBy: string;
}

@Injectable({
  providedIn: 'root'
})
export class PremiseMasterService {

  private baseUrl = environment.apiUrl;

  private shiftsSubject = new BehaviorSubject<any[]>([]);
  constructor(private http: HttpClient) { }

  getPremiseMasterData(clientId: string): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/PremiseMaster/GetPremisesDetails?EntityId=${clientId}`;
    return this.http.get(url, { observe: 'response' });
  }

  getDataForEdit(CustomerBranchID: number): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/PremiseMaster/GetDataForEdit?CustomerBranchID=${CustomerBranchID}`;
    return this.http.get(url, { observe: 'response' });

  }
  addEditPremiseMaster(branch: Temp_LocationMaster): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/PremiseMaster/AddEditPremiseMaster`;
    console.log('branch:', branch);

    return this.http.post<any>(url, branch, { observe: 'response' });


  }

  deleteCustomerBranchConfirmation(customerBranchID: number): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/PremiseMaster/DeleteCustomerBranchConfirmation?CustomerBranchID=${customerBranchID}`;
    return this.http.get<any>(url, { observe: 'response' });
  }

  deleteCustomerBranch(customerBranchID: number): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/PremiseMaster/DeleteCustomerBranch?CustomerBranchID=${customerBranchID}`;

    return this.http.get<boolean>(url, { observe: 'response' });
  }

  shiftMasterBulkUpload(file: File, custID: number, userID: number, entityID: string): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/PremiseMaster/ShiftMasterBulkUpload`;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('CustID', custID.toString());
    formData.append('UserID', userID.toString());
    formData.append('EntityID', entityID);

    return this.http.post<any>(url, formData, { observe: 'response' });
  }

  getAllLocAnchor(distributorId: number, customerId: number): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/ClientDetailsManagement/GetAllLocAnchor?DistributorID=${distributorId}&CustomerID=${customerId}`;
    return this.http.get<any>(url, { observe: 'response' });
  }

  exportToExcel(entityId: string) {
    const url = `${this.baseUrl}/api/PremiseMaster/PremiseMaster_ExportToExcel?EntityId=${entityId}`;
    return this.http.get(url, { observe: 'response' });
  }
  getSelectUploadGrid(custId: number): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/Common/GetMappedClientHeadersColumn?TableName=RLCS_CustomerBranch_ClientsLocation_Mapping&CustID=${custId}`;
    return this.http.get<any>(url, { observe: 'response' });
  }

  insertDynamicUploadHeaders(columnList: string, headerList: string): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/Common/InsertDynamicUploadHeadersNew`
   
    const body = {
      "columnList": columnList,
      "headerList": headerList
    }
    return this.http.post<any>(url, body, { observe: 'response' })
  }

  premiseBulkUpload(entityID: string, file: File, headerTrackerId: number, custId: number, userId: number): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/PremiseMaster/PremiseBulkUpload`;
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('CustID', custId.toString());
    formData.append('UserID', userId.toString());
    formData.append('EntityID', entityID);
    formData.append('HeaderTrackerId', headerTrackerId.toString());
    return this.http.post<any>(url, formData, { observe: 'response' })
  }


  addShiftMaster(shiftMasterList: ShiftMasterList[]): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/PremiseMaster/SaveUpdateShiftMaster`;
    return this.http.post<any>(url, shiftMasterList, { observe: 'response' });
  }



  getAddressList(statecode: string, locationcode: string): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/AuthorityAddress/Get_AddressTypeList?stateid=${statecode}&lcode=${locationcode}`
    return this.http.get<any>(url, { observe: 'response' })
  }


  SaveAddressMapping(body: any): Observable<HttpResponse<any>> {
    const formData: FormData = new FormData();
    formData.append('addrtype', body.addrtype);
    formData.append('addr', body.addr);
    formData.append('lcode', body.lcode);
    formData.append('stateid', body.stateid);
    formData.append('ClientID', body.ClientID);
    formData.append('userid', body.userid);
    formData.append('branchname', body.branchname);
    formData.append('branchid', body.branchid);

    const url = `${this.baseUrl}/api/RegulatoryAbstracts/SaveAddressList?addrtype=${body.addrtype}&addr=${body.addr}&lcode=${body.lcode}&stateid=${body.stateid}&ClientID=${body.ClientID}&userid=${body.userid}&branchname=${body.branchname}&branchid=${body.branchid}`;
    return this.http.post<any>(url, {}, { observe: 'response' });
  }



  GetAddressList(statecode: string, locationcode: string, AddressType: string, clientid: string): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/RegulatoryAbstracts/GetAddressList?stateid=${statecode}&adrtype=${AddressType}&lcode=${locationcode}&clientid=${clientid}`
    return this.http.get<any>(url, { observe: 'response' })
  }

  getShifts(): Observable<any> {
    return this.shiftsSubject.asObservable();
  }

  setShifts(shifts: any[]) {
    this.shiftsSubject.next(shifts);
  }
  uploadAuthorityAddress(file: File, entityId: string, userId: number): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/AuthorityAddress/AuthorityAddressUpload_BranchLevel`;
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('EntityId', entityId);
    formData.append('UserID', userId.toString());
    return this.http.post<any>(url, formData, { observe: 'response' })
  }
  
  
  getShiftData(ClientId:any,StateId:any,LocationID:any,Branch:any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/PremiseMaster/GetShiftMasterData?ClientId=${ClientId}&StateId=${StateId}&LocationID=${LocationID}&Branch=${Branch}`;
    return this.http.get<any>(url, { observe: 'response' })
  }
  Get_Holiday_Applicability(EntityID:string): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/PremiseMaster/Get_Holiday_Applicability?EntityID=${EntityID}`;
    return this.http.get<any>(url, { observe: 'response' })
  }
  
  HolidayMasterUpload(file: File, entityId: string, userId: number,HType:string): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/PremiseMaster/HolidayMasterUpload`;
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('EntityId', entityId);
    formData.append('UserID', userId.toString());
    formData.append('HType', HType);
    return this.http.post<any>(url, formData, { observe: 'response' })
  }
}