import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class NavLinksService {

    private apiUrl = environment.apiUrl;

    constructor(private http: HttpClient) { }

    getMenuBasedOnRole(Role: string, CustomerId: number, ServiceProvider: number): Observable<HttpResponse<any>> {
        const url = `${this.apiUrl}/api/MenuHandler/GetMenuBasedOnRole?Role=${Role}&CustomerId=${CustomerId}&ServiceProvider=${ServiceProvider}`;
        return this.http.get(url, { observe: 'response' });
    }
}
