import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmployeeCTCService {
  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getCTCFileUploadStatus(clientID: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/EmployeeMaster/LC_CTCFileUploadStatus?clientid=${clientID}`;
    return this.http.get(url, {observe:'response'});
  }
  getCTCReportBlobLink(clientID: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/EmployeeMaster/GetCTCReport?clientid=${clientID}`;
    return this.http.get(url, {observe:'response'});
  }
  getCTCTemplateBlobLink(id:number,clientID: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/EmployeeMaster/GetCTCTemplateByClient?id=${id}&clientid=${clientID}`;
    return this.http.get(url, {observe:'response'});
  }
  uploadFile(formData: any): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/EmployeeMaster/LC_CTCMasterUpload`;
    return this.http.post(url, formData,{observe:'response'});
  }
}
