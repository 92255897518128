import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LabourDepartmentAddressService {
  private apiUrl = environment.apiUrl;
  public header = 'Add Department Address';

  constructor(private http: HttpClient) { }
  setHeader(newHeader: string) {
    this.header = newHeader;
  }
  getGridDate(): Observable<HttpResponse<any>>{
    const url = `${this.apiUrl}/api/RegulatoryAbstracts/GetAll_DeptAddress`;
    return this.http.get(url, { observe: 'response' });
  }
  getStateDropdown(): Observable<HttpResponse<any>>{
    const url = `${this.apiUrl}/api/StateMaster/Get_StateMaster`;
    return this.http.get(url, { observe: 'response' });
  }
  getAddressTypeDropdown(): Observable<HttpResponse<any>>{
    const url = `${this.apiUrl}/api/RegulatoryAbstracts/GetAll_AddressType`;
    return this.http.get(url, { observe: 'response' });
  }
  getUniqueID(state:string,addrtype:string): Observable<HttpResponse<any>>{
    const url = `${this.apiUrl}/api/RegulatoryAbstracts/Getuniquid?state=${state}&addrtype=${addrtype}`;
    return this.http.get(url, { observe: 'response' });
  }
  getDepartmentAddressExcel(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/RegulatoryAbstracts/ExportToExcelDepartmentAddress`;
    return this.http.get<Blob>(url,{observe:'response'});
  }
  saveDepartmentAddress(state:string,addrtype:string,uniqueno:string,address:string,UserID:string): Observable<any> {
    const url = `${this.apiUrl}/api/RegulatoryAbstracts/AddeditdeptAddress?state=${state}&addrtype=${addrtype}&uniqueno=${uniqueno}&address=${address}&UserID=${UserID}`;
    console.log(url);
    
    return this.http.post<any>(url,{});
  }
  uploadFile(file: File, CustID: number,UserID:number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/RegulatoryAbstracts/DeptBulkUpload`;
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('CustID', CustID.toString());
    formData.append('UserID', UserID.toString());
  
    return this.http.post<any>(url, formData,{ observe: 'response' });
  }
}
