import { Component } from '@angular/core';
import { KendoDatepickerComponent } from '../../../components/kendo-datepicker/kendo-datepicker.component';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { NgClass, NgIf,NgFor } from '@angular/common';
import { GridDataResult, GridModule, PageChangeEvent } from '@progress/kendo-angular-grid';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { BlobService } from '../../../service/blob.service';
import { environment } from '../../../environments/environment';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { MisDecumentService } from '../../../service/mis-decument.service';
import { UserService } from '../../../service/user.service';
import { AdobeViewerComponent } from '../../../components/adobe-viewer/adobe-viewer.component';

@Component({
  selector: 'app-mis-document',
  standalone: true,
  imports: [KendoDatepickerComponent,DateInputsModule,NgIf,NgFor,NgClass,GridModule,GridActionItemComponent,AlertPopupComponent,IndicatorsModule,AdobeViewerComponent],
  templateUrl: './mis-document.component.html',
  styleUrl: './mis-document.component.css'
})
export class MisDocumentComponent {
  private blobUrl = environment.blobUrl;
  fileBlob!:any;
  selectedStartDate: Date | null = null;
  showValidationErrors = false;
  allSelected: boolean = false;
  uploadType: string = '';
  type:string='';
  month!: string;
  year!: string;
  loading=false;
  take = 10;
  skip = 0;
  page = 1;
  public buttonCount = 5;
  public PageSize: number = 10;
  dateErrorMessage: string = '';
  public datePlaceholder: string = 'MIS Month';
  public sizes = [{text:'All',value:1},5, 10, 20];
  alertData = { alertMessage: '', alertVisible: '' }
  public gridData: GridDataResult = { data: [], total: 0 };

  constructor(private blobService:BlobService,private misdocumentservice:MisDecumentService,private user:UserService){}

  onStartDateChange(value: Date) {
    const date = new Date(value.toISOString().split('T')[0]);
    this.selectedStartDate = date;
    this.showValidationErrors = true;
    const monthNames = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    this.month = monthNames[date.getMonth()];
  
    this.year = date.getFullYear().toString();
    console.log('Selected Month:', this.month);
    console.log('Selected Year:', this.year);
  }

  onRadioChange(value: string) {
    this.uploadType = value;
  }

  public pageChange(event: PageChangeEvent): void {
    console.log('Page Change Event Received:', event);
    this.loading=true;
    this.skip = event.skip;
    if(event.take==1){
      this.PageSize = this.gridData.total;
    }else{
      this.PageSize = event.take;
    }
    this.loadGridData();
  }

  applyFilters() {
    this.showValidationErrors = true;
    if (!this.selectedStartDate) {
      this.showValidationErrors = true; 
    } else {
      this.loading = true;
      this.loadGridData();
    }
  }
  ViewFile(dataItem:any){
    console.log(dataItem);
    
    this.misdocumentservice.fetchFile(dataItem.documentPath).subscribe({
      next: (blob: Blob) => {
        this.fileBlob = blob;
        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }
  closeViewer(){
    this.fileBlob=null;
  }
  public closePopup(): void {
    this.alertData = { alertMessage: '', alertVisible: '' };
  }

  clearFilter(){
    this.selectedStartDate=null;
    this.uploadType='E';
    this.showValidationErrors = false;
  }

  onIconClick(action: string, dataItem: any) {
    switch (action) {
      case 'Action':
        this.handleAction(dataItem);
        break;

      default:
        console.warn('Unknown action:', action);
        break;
    }
  }
  handleAction(dataItem: any) {
    console.log('icon clicked for:', dataItem);
    this.loading = true;
    this.downloadFile(dataItem.regionalFilePath, dataItem.regionalFileName);
  }

  downloadFile(filePath: string, fileName: string) {
    debugger;
    const blobLink = this.blobUrl + filePath;
    const blobName = this.blobService.extractBlobName(blobLink);
    setTimeout(() => {
      this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
        debugger;
        const sasToken = tokenResponse.result;
        this.blobService.downloadFile(blobName, sasToken);
        this.loading = false;
      });
    }, 5000);
  }

  loadGridData() {
    debugger;
    this.loading = true;
    const userid = this.user.getUserId();
    const clientId = this.user.getClientId();
    const type = this.uploadType;
    const month = this.month;
    const year = this.year;
  
    this.misdocumentservice.getGridData(clientId, type, month, year, userid).subscribe({
      next: (response) => {
        console.log('Response', response);
        const data = response.body;
  
        if (data && data.lstBranchWise && data.lstBranchWise.length > 0) {
          this.gridData = {
            data: data.lstBranchWise,
            total: data.lstBranchWise.length
          };
          this.loading = false;
          console.log('griddata', this.gridData);
        } else {
          this.alertData = { alertMessage: 'No data available', alertVisible: 'error' };
          this.gridData = {
            data: [],
            total: 0
          };
          this.loading = false;
        }
      },
      error: (err) => {
        console.log('error', err);
        this.alertData = { alertMessage: 'Error loading grid data', alertVisible: 'error' };
        this.loading = false;
      }
    });
  }}
