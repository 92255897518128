import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { DatePipe, NgIf } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ManageEmployeeService } from '../../../service/manage-employee.service';
import { EsicreportService } from '../../../service/esicreport.service';
import { UserService } from '../../../service/user.service';
import { KendoDatepickerComponent } from '../../../components/kendo-datepicker/kendo-datepicker.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-branch-transfer-popup',
  standalone: true,
  imports: [DialogModule, ComboBoxComponent, AlertPopupComponent, NgIf, ReactiveFormsModule, FormsModule, KendoDatepickerComponent,DatePipe,IndicatorsModule],
  templateUrl: './branch-transfer-popup.component.html',
  styleUrl: './branch-transfer-popup.component.css',
  providers: [DatePipe]
})
export class BranchTransferPopupComponent implements OnInit {
  alertData = { alertMessage: '', alertVisible: '' };
  clientId = ''
  showValidationErrors = false;
  loading=false;
  @Output() close = new EventEmitter<void>();
  @Input() Data!:any;

  public defaultSelectedValueEB = 'Search/Select State';
  public defaultSelectedValueLo = 'Search/Select Location';
  public defaultSelectedValueBr = 'Search/Select Branch';
  public defaultSelectedValueDa = 'Search/Select Date';

  public placeholder: string = 'DD-MM-YYYY';

  public TransferStateDropdownData: any[] = [];
  public TransferLocationDropdownData: any[] = [];
  public TransferBranchDropdownData: any[] = [];
  CurrentState = 'State'
  CurrentLocation = 'Location'
  CurrentBranch = 'Branch'
  CurrentLastDate = '';
  todayDate: Date = new Date();
  TransferState:any = { text: 'Select', value: '' };
  TransferLocation:any = { text: 'Select', value: '' };
  TransferBranch:any = { text: 'Select', value: '' };
  TransferDateJoining: Date | null = null;;
  BranchState = { text: 'Select', value: '' };
  constructor(private branchTransferEmployeeService: ManageEmployeeService, private esicreportService: EsicreportService, private user: UserService,private datePipe: DatePipe) { }
  ngOnInit(): void {
    this.clientId = this.user.getClientId();
    this.StateDropdown(this.clientId);
    this.InitData(this.Data);
    console.log(this.Data);
    
    this.TransferDateJoining = new Date();

  }
  InitData(value: any) {
    console.log(value.avacoM_CustomerID, value.avacoM_BranchID, value.eM_EmpID);
    this.loading=true;
    this.branchTransferEmployeeService.getBranchTransferData(value.avacoM_CustomerID, value.avacoM_BranchID, value.eM_EmpID).subscribe(
      {
        next: (response) => {
          const data = response.body;
          this.CurrentState = data?.sM_Name || '';
          this.CurrentLocation = data?.lM_Name || '';
          this.CurrentBranch = data?.avacoM_BranchName || '';
          
        }, error: (error) => {
          console.error('Error fetching State Dropdown data', error);
        }
      })
      const currentDate = new Date();
      this.CurrentLastDate = this.datePipe.transform(currentDate, 'dd-MM-yyyy') || '';
      this.loading=false;
  }
  onDateChange(value: Date, type: string) {
    const date = new Date(value.toISOString().split('T')[0]);
    const dob = this.Data.eM_DOB instanceof Date 
    ? this.Data.eM_DOB 
    : new Date(this.Data.eM_DOB);
    if (type === 'TransferDateJoining') {
      const currentDate = new Date(value.toISOString().split('T')[0]);
      if(date<=dob){
        this.alertData={alertMessage:'Please Select Last Working Date greater than Date of Birth!',alertVisible:'error'}
        this.TransferDateJoining = null;
        return;
      }
      if(date>currentDate){
        this.alertData={alertMessage:'Please Select Last Working Date greater than Last Working Date!',alertVisible:'error'}
        this.TransferDateJoining = null;
        return;
      }
      this.TransferDateJoining = date;
    }
  }
  StateDropdown(value: string) {
    this.branchTransferEmployeeService.getBranchTransferStateDropdown(value).subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && data && Array.isArray(data)) {
            this.TransferStateDropdownData = data.map((item: any) => ({
              id: item.sM_Code,
              name: item.sM_Name
            }));
          } else {
            console.error("Unexpected data format", data);
          }
        }, error: (error) => {
          console.error('Error fetching State Dropdown data', error);
        }
      });
  }
  LocationDropdown(value: string, state: string) {
    this.branchTransferEmployeeService.getBranchTransferLocationDropdown(value, state).subscribe(
      {
        next: (response) => {
          const data = response.body;          
          if (data && data && Array.isArray(data)) {
            this.TransferLocationDropdownData = data.map((item: any) => ({
              id: item.lM_Code,
              name: item.lM_Name
            }));
          } else {
            console.error("Unexpected data format", data);
          }
        }, error: (error) => {
          console.error('Error fetching State Dropdown data', error);
        }
      });
  }
  BranchDropdown(value: any, state: string, location: string) {
    this.branchTransferEmployeeService.getBranchTransferBranchDropdown(value.avacoM_CustomerID, value.eM_ClientID, state, location, value.avacoM_BranchID).subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && data && Array.isArray(data)) {
            this.TransferBranchDropdownData = data.map((item: any) => ({
              id: item.avacoM_BranchID,
              name: item.avacoM_BranchName
            }));
          } else {
            console.error("Unexpected data format", data);
          }
        }, error: (error) => {
          console.error('Error fetching State Dropdown data', error);
        }
      });
  }

  onSubmit() {
    debugger;
    this.loading=true;
    this.showValidationErrors=true;
    if (this.TransferState.value && this.TransferLocation.value && this.TransferBranch.value && this.TransferDateJoining) {
      this.branchTransferEmployeeService.createEmployeeBranchTransfer(this.Data, this.TransferState.value,  this.TransferLocation.value, this.TransferBranch.text, this.TransferDateJoining?.toISOString() || '').subscribe(
        {
          next: (response) => {
            const data=response.body;
            console.log(response);
            debugger
            if (response.status == 200) {
              this.alertData = { alertMessage: 'Employee Transfered Succesfully', alertVisible: 'success' };
              this.loading=false;
            } else {
              this.alertData = { alertMessage: 'Employee Transfer Failed', alertVisible: 'error' };
              this.loading=false;
            }
          }, error: (error) => {
            this.alertData = { alertMessage: 'Employee Transfer Failed', alertVisible: 'error' };
            console.error('Error fetching State Dropdown data', error);
            this.loading=false;
          }
        })}else{
          this.alertData = { alertMessage: 'Mandatory fields required!', alertVisible: 'error' }
          this.loading=false;
    }      
  }

  onDropdownValueChange(value: any, type: string) {
    if (type === 'TransferState') {
      this.TransferState = { text: value?.name, value: value?.id };
 
      this.LocationDropdown(this.clientId, value?.id);
    } else if (type === 'TransferLocation') {
      this.TransferLocation = { text: value?.name, value: value?.id };
      console.log('loc',value);
      this.BranchDropdown(this.Data, this.TransferState?.value, value?.id);
    } else if (type === 'TransferBranch') {
      this.TransferBranch = { text: value?.name, value: value?.id };
    }
  }
  closeAlert() {
    if(this.alertData.alertVisible=='success'){
      this.closePopup();
    }
    this.alertData = { alertMessage: '', alertVisible: '' };
  }
  closePopup() {
    this.Data = [];
    this.close.emit();
  }
}
