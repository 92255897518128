import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { NgClass, NgIf, NgFor } from '@angular/common';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { UserService } from '../../../service/user.service';
import { UserMasterService } from '../../../service/user-master.service';
import { forkJoin } from 'rxjs';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

export function noWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const isWhitespace = (control.value || '').trim().length === 0;
    return isWhitespace ? { 'whitespace': true } : null;
  };
}

@Component({
  selector: 'app-user-master-add-edit-popup',
  standalone: true,
  imports: [NgIf, NgFor, DialogModule, NgClass, DropdownListComponent, ReactiveFormsModule, AlertPopupComponent, IndicatorsModule],
  templateUrl: './user-master-add-edit-popup.component.html',
  styleUrl: './user-master-add-edit-popup.component.css'
})
export class UserMasterAddEditPopupComponent implements OnInit {
  form!: FormGroup;
  @Output() close = new EventEmitter<void>();
  @Input() UserId = '0';
  Data!: any;
  CustomerId!: any;

  loading = false;
  complianceProdType!: any;
  todayDate: Date = new Date();
  public Designation: string = ''
  public defaultRoleSelectedValue: any = { id: -1, name: 'Select', code: '' }
  public defaultReportingSelectedValue: any = { id: -1, name: 'Select' }
  public defaultStatusSelectedValue: any = { id: 0, name: 'Select' }
  public roleDropdown: { id: number; name: string, code: string }[] = [];
  public reportingToDropdown: { id: number; name: string }[] = [];
  LoggedInuserID = 0;
  showValidationErrors = false;
  checkboxReq = false;
  alertData = { alertMessage: '', alertVisible: '' };
  dropdownData = [
    { id: 0, name: 'Select' },
    { id: 1, name: 'Active' },
    { id: 2, name: 'Inactive' }
  ];
  IsEdit = 0;
  showCheckboxList: boolean = false;
  complianceTypes: string[] = ['Register', 'Return', 'Challan'];
  fileNames: { [key: string]: string | null } = {
    Logo: null
  };

  userObject: any = {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    contactNumber: '',
    address: '',
    createdOn: new Date().toISOString(),
    isDeleted: false,
    designation: '',
    departmentID: 0,
    createdBy: 0,
    createdByText: '',
    isActive: true,
    deactivatedOn: new Date().toISOString(),
    customerID: 0,
    customerBranchID: 0,
    reportingToID: 0,
    roleID: 0,
    lastLoginTime: new Date().toISOString(),
    changPasswordDate: new Date().toISOString(),
    changePasswordFlag: true,
    notLongerLoginDate: new Date().toISOString(),
    wrongAttempt: 0,
    isHead: false,
    auditorID: 0,
    isAuditHeadOrMgr: '',
    sendEmail: true,
    imagePath: '',
    imageName: '',
    lawyerFirmID: 0,
    isExternal: true,
    litigationRoleID: 0,
    startdate: new Date().toISOString(),
    enddate: new Date().toISOString(),
    auditStartPeriod: new Date().toISOString(),
    auditEndPeriod: new Date().toISOString(),
    contractRoleID: 0,
    userName: '',
    licenseRoleID: 0,
    pan: '',
    vendorRoleID: 0,
    desktopRestrict: true,
    imeiNumber: '',
    imeiChecked: true,
    enType: '',
    mobileAccess: true,
    secretarialRoleID: 0,
    hrRoleID: 0,
    insiderRoleID: 0,
    ssoAccess: true,
    cer_OwnerRoleID: 0,
    cer_OfficerRoleID: 0,
    logOutURL: '',
    createdFrom: 0,
    isCertificateVisible: 0
  };

  constructor(private fb: FormBuilder, private user: UserMasterService, private users: UserService, private cdr: ChangeDetectorRef) { }
  ngOnInit(): void {
    debugger;
    this.defaultRoleSelectedValue = { id: -1, name: 'Select', code: '' }
    this.defaultReportingSelectedValue = { id: -1, name: 'Select' }
    this.defaultStatusSelectedValue = { id: 0, name: 'Select' }
    this.form = this.fb.group({
      FirstName: ['', [Validators.required, noWhitespaceValidator(), Validators.pattern(/^[a-zA-Z\s]*$/)]],
      LastName: ['', [Validators.required, noWhitespaceValidator(), Validators.pattern(/^[a-zA-Z\s]*$/)]],
      ContactNo: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      Email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]],
      Designation: ['', [Validators.required, noWhitespaceValidator(), Validators.pattern(/^[a-zA-Z\s]*$/)]],
      Register: [false],
      Returns: [false],
      Challan: [false],
      Address: ['', [Validators.required, noWhitespaceValidator()]],
    });

    this.LoggedInuserID = this.users.getUserId();

    this.loadInitialData().subscribe({
      next: ({ roleData, reportingToData }) => {
        const roleResponse = roleData.body;
        if (roleResponse) {
          this.roleDropdown = roleResponse;
          console.log(this.roleDropdown);
          
          this.roleDropdown.unshift(this.defaultRoleSelectedValue);
        }
        const reportingResponse = reportingToData.body;
        if (reportingResponse) {
          this.reportingToDropdown = reportingResponse;
          this.reportingToDropdown.unshift(this.defaultReportingSelectedValue);
        }
        console.log('userid',this.UserId);
        
        if (this.UserId) {
          debugger;
          this.IsEdit = 1;
          this.initData();
        } else {
          this.IsEdit = 0;
          this.PredefinedData('');
        }
      },
      error: (error) => {
        console.log('Error occurred during API calls', error);
      }
    });
  }

  onKeyDownDesignation(event: KeyboardEvent) {
    const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Tab"];
    const isLetter = /^[a-zA-Z]$/.test(event.key);
    const isSpace = event.key === ' ';
    const currentInputValue = this.userObject.designation || '';
    const startsWithSpace = currentInputValue.startsWith(' ');
    if (!allowedKeys.includes(event.key) && !isLetter && !isSpace) {
      event.preventDefault();
    }
    if (startsWithSpace && isSpace) {
      event.preventDefault();
    }
  }
  blockInvalidEmailCharacters(event: KeyboardEvent): void {
    const allowedKeys = ['Backspace', 'Tab', 'Enter', 'Escape', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Home', 'End'];
    if (allowedKeys.includes(event.key)) {
      return;
    }
    const inputChar = event.key;
    const isValidCharacter = /^[a-zA-Z0-9@.]*$/.test(inputChar);
    if (!isValidCharacter) {
      event.preventDefault();
    }
  }

  PredefinedData(value: any) {
    this.form = this.fb.group({
      FirstName: [value.firstName || '', [Validators.required, noWhitespaceValidator(), Validators.pattern(/^[a-zA-Z\s]*$/)]],
      LastName: [value.lastName || '', [Validators.required, noWhitespaceValidator(), Validators.pattern(/^[a-zA-Z\s]*$/)]],
      ContactNo: [value.contactNumber || '', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      Email: [value.email || '', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]],
      Designation: [value.designation || '', [Validators.required, noWhitespaceValidator(), Validators.pattern(/^[a-zA-Z\s]*$/)]],
      Register: [value.registerApplicabilty ? true : null],
      Returns: [value.returnsApplicabilty ? true : null],
      Challan: [value.challansApplicabilty ? true : null],
      Address: [value.address || '', [Validators.required, noWhitespaceValidator()]
      ],
    });

    this.defaultStatusSelectedValue = this.dropdownData.find(type => {
      let status = value.status;
      if (status === 'A') {
        status = 'Active';
      } else if (status === 'I') {
        status = 'Inactive';
      }

      return type.name === status;
    }) || { id: 0, name: '' };
    this.defaultRoleSelectedValue = this.roleDropdown.find(type => type.code === value.avacoM_UserRole) || { id: -1, name: '' },
      this.defaultReportingSelectedValue = this.reportingToDropdown.find(type => type.id == value.user_Reporting_To) || { id: -1, name: '' }
    if (value.avacoM_UserRole === 'CORDN' || value.avacoM_UserRole === 'HMGMT' || value.avacoM_UserRole === 'HMGR') {
      this.showCheckboxList = true;
    } else {
      this.showCheckboxList = false;
    }
  }

  initData() {
    this.user.GetRLCSUserDetails(this.UserId).subscribe(
      {
        next: (response) => {
          const data = response.body;
          this.Data = data;
          console.log('init', data);

          this.PredefinedData(data)
        }, error: (error) => {
          console.error('Error fetching data', error);
        }
      });
  }

  validateMobileNo(event: any): void {
    const input = event.target.value;
    event.target.value = input.replace(/[^0-9]/g, '');
    this.form.get('ContactNo')?.setValue(event.target.value);
  }

  onDropdownValueChange(value: any, type: string) {
    if (type === 'RoleDropdown') {
      if ([15, 17, 52].includes(value.id)) {
        this.showCheckboxList = true;
      } else {
        this.showCheckboxList = false;
      }
      this.defaultRoleSelectedValue = { id: value.id, name: value.name, code: value.code };
    } else if (type == 'Status') {
      this.defaultStatusSelectedValue = { id: value.id, name: value.name };
    } else if (type == 'ReportingTo') {
      this.defaultReportingSelectedValue = { id: value.id, name: value.name };
    }
    this.cdr.detectChanges();
    console.log(value, type);

  }

  loadInitialData() {
    return forkJoin({
      roleData: this.user.getRoleData(this.LoggedInuserID),
      reportingToData: this.user.getReportingToData(596, -1, 94)
    });
  }
  GetCustomerIdByUserid(value: number) {
    this.user.GetCustomerIdByUserid(value).subscribe(
      {
        next: (response) => {
          const data = response.body;
          this.CustomerId = data.result;
          console.log(this.CustomerId);
        }, error: (error) => {
          console.error('Error fetching data', error);
        }
      });
  }
  GetComplianceProductType(value: number) {
    this.user.GetCustomerIdByUserid(value).subscribe(
      {
        next: (response) => {
          const data = response.body;
          this.CustomerId = data.result;
        }, error: (error) => {
          console.error('Error fetching data', error);
        }
      });
  }

  onSave(): void {
    this.loading = true;
    this.showValidationErrors = true;
    if ([15, 17, 52].includes(this.defaultRoleSelectedValue.id)) {
      if (!this.form.value.Challan && !this.form.value.Returns && !this.form.value.Register) {
        this.checkboxReq = true;
        this.loading = false;
      } else {
        this.checkboxReq = false;
      }
    } else {
      this.form.value.Challan = false;
      this.form.value.Returns = false;
      this.form.value.Register = false;
      this.checkboxReq = false;
    }
    this.GetCustomerIdByUserid(this.LoggedInuserID);
    let customerID = 596;
    let getproductcOMPLIANCE = this.defaultRoleSelectedValue.id
    let getHRproductCOMPLIANCE = this.defaultRoleSelectedValue.id;
    let avacom_UserID = this.Data?.avacoM_UserID;
    const CreateUpdateUser = {
      id: this.Data?.avacoM_UserID || 0,
      firstName: this.form.value.FirstName || '',
      lastName: this.form.value.LastName || '',
      email: this.form.value.Email || '',
      password: this.Data?.password || 'Admin@123',
      contactNumber: this.form.value.ContactNo || '',
      address: this.form.value.Address,
      createdOn: this.Data?.createdOn || new Date().toISOString(),
      isDeleted: false,
      designation: this.form.value.Designation,
      departmentID: 0,
      createdBy: this.Data?.createdBy || this.LoggedInuserID.toString(),
      createdByText: "",
      isActive: true,
      deactivatedOn: new Date().toISOString(),
      customerID: this.Data?.customerID || customerID,
      customerBranchID: 0,
      reportingToID: this.defaultReportingSelectedValue.id,
      roleID: this.defaultRoleSelectedValue.id,
      lastLoginTime: new Date().toISOString(),
      changPasswordDate: new Date().toISOString(),
      changePasswordFlag: true,
      notLongerLoginDate: new Date().toISOString(),
      wrongAttempt: 0,
      isHead: true,
      auditorID: 0,
      isAuditHeadOrMgr: "",
      sendEmail: true,
      imagePath: "",
      imageName: "",
      lawyerFirmID: 0,
      isExternal: true,
      litigationRoleID: 0,
      startdate: new Date().toISOString(),
      enddate: new Date().toISOString(),
      auditStartPeriod: new Date().toISOString(),
      auditEndPeriod: new Date().toISOString(),
      contractRoleID: 0,
      userName: "",
      licenseRoleID: 0,
      pan: "",
      vendorRoleID: this.defaultRoleSelectedValue.id,
      desktopRestrict: true,
      imeiNumber: "",
      imeiChecked: true,
      enType: "A",
      mobileAccess: true,
      secretarialRoleID: 0,
      hrRoleID: this.defaultRoleSelectedValue.id,
      insiderRoleID: 0,
      ssoAccess: true,
      cer_OwnerRoleID: 0,
      cer_OfficerRoleID: 0,
      logOutURL: "",
      createdFrom: 0,
      isCertificateVisible: 0
    }


    if (this.form.valid && (this.defaultRoleSelectedValue.id != -1) && !this.checkboxReq && (this.defaultReportingSelectedValue.id != -1) && (this.defaultStatusSelectedValue.id != 0)) {
      console.log('CreateUpdateUser', CreateUpdateUser);
      this.user.CreateUpdateUser(CreateUpdateUser).subscribe({
        next: (response) => {
          debugger
          if (response.status == 200) {
            console.log('CreateUpdateUser', response);
            const data = response.body;
            avacom_UserID = data.result;
            this.CreateUpdateRLCS_User_Mapping(avacom_UserID);
          } else {
            this.alertData = { alertMessage: 'Error while saving Data', alertVisible: 'error' };
            this.loading = false;
          }
        }, error: (error) => {
          console.log(error);
          this.loading = false;
          this.alertData = { alertMessage: 'Error while saving Data', alertVisible: 'error' };
        }
      })
    } else {
      this.loading = false;
      this.form.markAllAsTouched();
    }
  }
  CreateUpdateRLCS_User_Mapping(userId: any) {
    debugger;
    let customerID = 596;
    let role = this.Data?.role || 'MGR';
    let UserType = this.Data?.userType || 'A';
    if (this.defaultRoleSelectedValue.code == 'HMGMT') {
      role = 'HRMGR';
    }
    else if (this.defaultRoleSelectedValue.code == 'HMGR') {
      role = 'MGR';
      UserType = 'T';
    }
    else if (this.defaultRoleSelectedValue.code == 'HAPPR') {
      role = 'APPR';
      UserType = 'T';
    }
    else if (this.defaultRoleSelectedValue.code == 'LSPOC') {
      role = 'LSPOC';
    }
    const CreateUpdate_UserCustomerMapping = {
      id: parseInt(userId),
      userID: parseInt(userId),
      customerID: this.Data?.customerID || customerID,
      isActive: true,
      createdOn: this.Data?.createdOn || new Date().toISOString(),
      updatedOn: this.Data?.updatedOn || new Date().toISOString(),
      mgrID: 0,
      productID: 0
    }
    const CreateUpdateRLCS_User_Mapping = {
      id: this.Data?.id || parseInt(userId),
      avacoM_UserID: this.Data?.avacoM_UserID || parseInt(userId),
      avacoM_UserRole: this.defaultRoleSelectedValue.code || '',
      userType: UserType || '',
      firstName: this.form.value.FirstName || '',
      lastName: this.form.value.LastName || '',
      email: this.form.value.Email || '',
      userID: this.Data?.userID || '',
      password: this.Data?.password || 'Admin@123',
      contactNumber: this.form.value.ContactNo || '',
      address: this.form.value.Address,
      designation: this.form.value.Designation,
      department: this.Data?.department || '',
      status: this.defaultStatusSelectedValue.name[0] || '',
      customerID: this.Data?.customerID.toString() || customerID.toString(),
      role: role,
      profileID: this.Data?.profileID || "",
      isActive: this.Data?.isActive || true,
      createdOn: this.Data?.createdOn || new Date().toISOString(),
      updatedOn: this.Data?.updatedOn || new Date().toISOString(),
      modifiedDate: this.Data?.modifiedDate || new Date().toISOString(),
      enType: this.Data?.enType || "",
      emp_Code: this.Data?.emp_Code || "",
      user_Gender: this.Data?.user_Gender || "",
      user_DesignationID: this.Data?.user_DesignationID || "",
      user_DOJ: this.Data?.user_DOJ || new Date().toISOString(),
      //user_DOL: this.Data?.user_DOL || null,
      user_OfficialEmailID: this.Data?.user_OfficialEmailID || "",
      user_GroupEmailID: this.form.value.Email,
      user_Reporting_To: this.defaultReportingSelectedValue.id.toString(),
      user_Password: this.Data?.user_Password || "Admin@123",
      createdBy: this.Data?.createdBy || this.LoggedInuserID.toString(),
      modifiedBy: this.LoggedInuserID.toString(),
      registerApplicabilty: this.form.value.Register || null,
      returnsApplicabilty: this.form.value.Returns || null,
      challansApplicabilty: this.form.value.Challan || null,
      customerBranchID: 0,
      reportingToID: this.defaultReportingSelectedValue.id,
      roleID: this.defaultRoleSelectedValue.id,
      lastLoginTime: new Date().toISOString(),
      changPasswordDate: new Date().toISOString(),
      changePasswordFlag: true,
      notLongerLoginDate: new Date().toISOString(),
      wrongAttempt: 0,
      isHead: true,
      auditorID: 0,
      isAuditHeadOrMgr: "",
      sendEmail: true,
      imagePath: "",
      imageName: "",
      lawyerFirmID: 0,
      isExternal: true,
      litigationRoleID: 0,
      startdate: new Date().toISOString(),
      enddate: new Date().toISOString(),
      auditStartPeriod: new Date().toISOString(),
      auditEndPeriod: new Date().toISOString(),
      contractRoleID: 0,
      userName: "",
      licenseRoleID: 0,
      pan: "",
      vendorRoleID: this.defaultRoleSelectedValue.id,
      desktopRestrict: true,
      imeiNumber: "",
      imeiChecked: true,
      mobileAccess: true,
      secretarialRoleID: 0,
      hrRoleID: this.defaultRoleSelectedValue.id,
      insiderRoleID: 0,
      ssoAccess: true,
      cer_OwnerRoleID: 0,
      cer_OfficerRoleID: 0,
      logOutURL: "",
      createdFrom: 0,
      isCertificateVisible: 0,
      createdByText: "",
      deactivatedOn: new Date().toISOString(),
      isDeleted: false,
      departmentID: 0,
    }
    console.log('CreateUpdateRLCS_User_Mapping', CreateUpdateRLCS_User_Mapping);
    this.user.CreateUpdateRLCS_User_Mapping(CreateUpdateRLCS_User_Mapping).subscribe({
      next: (response) => {
        if (response.status == 200) {
          console.log('CreateUpdateRLCS_User_Mappingresponse', response);
          console.log('CreateUpdate_UserCustomerMapping', CreateUpdate_UserCustomerMapping);
          this.user.CreateUpdate_UserCustomerMapping(CreateUpdate_UserCustomerMapping).subscribe({
            next: (response) => {
              if (response.status == 200) {
                console.log('CreateUpdate_UserCustomerMapping', response);
                if (response.body.result == true) {
                  this.alertData = { alertMessage: 'Details Save Successfully', alertVisible: 'success' };
                  this.loading = false;
                } else {
                  this.alertData = { alertMessage: 'Error while saving Data', alertVisible: 'error' };
                  this.loading = false;
                }
              } else {
                this.loading = false;
                this.alertData = { alertMessage: 'Error while saving Data', alertVisible: 'error' };
              }
            }, error: (error) => {
              this.loading = false;
              console.log(error);
              this.alertData = { alertMessage: 'Error while saving Data', alertVisible: 'error' };
            }
          });
        } else {
          this.loading = false;
          this.alertData = { alertMessage: 'Error while saving Data', alertVisible: 'error' };
        }
      }, error: (error) => {
        this.loading = false;
        console.log(error);
        this.alertData = { alertMessage: 'Error while saving Data', alertVisible: 'error' };
      }
    });
  }
  formatDate(dateString: string): string {
    const date = new Date(dateString);

    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear().toString();

    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const seconds = date.getUTCSeconds().toString().padStart(2, '0');

    return `${day}/${month}/${year}${hours}:${minutes}:${seconds}`;
  }


  closePopup(): void {
    this.Data = undefined;
    this.UserId = '';
    this.close.emit();
  }

  closeAlert() {
    if (this.alertData.alertVisible == 'success') {
      this.close.emit();
    }
    this.alertData = { alertMessage: '', alertVisible: '' };
  }

  generateRandomPassword(length: number): string {
    const allowedChars = 'abcdefghijkmnpqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ123456789!@$?';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * allowedChars.length);
      result += allowedChars[randomIndex];
    }
    return result;
  }

}
