import { Injectable } from '@angular/core';


import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InvoiceUpdationService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {

  }
  UpdateInvoiceDescriptionDetail(payload:any): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Invoice/UpdateInvoiceDescriptionDetails?InvoiceNumber=${payload.InvoiceNumber}&Description=${payload.Description}`;
    // https://localhost:7111/api/Invoice/UpdateInvoiceDescriptionDetails?InvoiceNumber=TL33T2021%2F726&Description=wer
    return this.http.post<any>(url, payload, { observe: 'response' });
  }
  
}
