<div class="row colpadding0" style="margin-bottom: 15px;">
    <div class="col-md-12 colpadding0 toolbar" style="margin-top: 10px;">
        <div class="col-md-4 colpadding0" style=" justify-content: space-between;">
            <input kendoTextBox placeholder="Type to Search" class="f-textbox" style="width: 300px;"
                (input)="applyFilter($event)">
        </div>
        <div class="col-md-8 colpadding0">
            <div style="display: inline" style="float: right; ">
                <button class="figma-btn-blue" *ngIf="Role === 'PERF'" (click)="openHolidayMaster()">
                    <img src="../assets/vectors/uploadWhite.svg" alt="">
                    Upload Holiday Master
                </button>
                <button class="figma-btn-blue"*ngIf="Role === 'DADMN'||Role ==='CADMN'"  (click)="uploadShiftMaster()">
                    <img src="../assets/vectors/uploadWhite.svg" alt="">
                    Upload Shift Master
                </button>
                <button class="figma-btn-blue"  *ngIf="Role === 'DADMN'||Role ==='CADMN'" (click)="uploadAddressMapping()">
                    <img src="../assets/vectors/uploadWhite.svg" alt="">
                    Upload Address Mapping
                </button>
                <button class="figma-btn-blue" *ngIf="Role === 'DADMN'||Role ==='CADMN'" (click)="bulkUpload()">
                    <img src="../assets/vectors/uploadWhite.svg" alt="">
                    Upload
                </button>
                <button class="exportbtn" (click)=" exportToExcel()">
                    <img src="../assets/vectors/ExportButton.svg" alt="">

                </button>
            </div>
        </div>
    </div>
</div>

<app-tree-grid *ngIf="Role === 'DADMN'" [data]="filteredGridData" [parentIdField]="'parentID'" [columns]="parentColumns" [idField]="'id'"
    (buttonClick)="onActionClick($event)" [height]="'530px'">
<ng-container *ngFor="let icon of parentColumns">
    <button class="button-with-icon" style="background: transparent; border: none; padding: 0;" [title]="parentColumns">
        <img [src]="icon.url" [alt]="icon.alt" class="icon-image" />
    </button>
</ng-container></app-tree-grid>
<app-tree-grid *ngIf="Role === 'PERF' || Role==='RVW1'" [data]="filteredGridData" [parentIdField]="'parentID'" [columns]="parentColumns" [idField]="'id'"
    (buttonClick)="onActionClick($event)" [height]="'530px'"></app-tree-grid>

<div kendoDialogContainer *ngIf="openEdit">
    <app-premise-master-edit-popup [branchId]="branchId" (update)="handleEdit()"
        (close)="handleClosePopup('Edit')"></app-premise-master-edit-popup>
</div>

<div kendoDialogContainer *ngIf="openAddEdit">
    <app-premise-master-add-edit-popup [branchId]="branchId" (update)="handleAddEdit()"
        (close)="handleClosePopup('Add')"></app-premise-master-add-edit-popup>
</div>

<div kendoDialogContainer *ngIf="openAddressMapping">
    <app-address-mapping [statecode]="statecode" [locationcode]="locationcode" [branchName]="branchName"
        [branchId]="branchId" (update)="handleAddEdit()"
        (close)="handleClosePopup('AddressMapping')"></app-address-mapping>
</div>

<div kendoDialogContainer *ngIf="openShiftMaster">
    <app-premise-shiftmaster-upload (close)="handleClosePopup('ShiftMaster')"></app-premise-shiftmaster-upload>
</div>

<div kendoDialogContainer *ngIf="openBulkUpload">
    <app-premise-bulk-upload (close)="handleClosePopup('BulkUpload')"></app-premise-bulk-upload>
</div>
<div kendoDialogContainer *ngIf="OpenHolidayMaster">
    <app-premise-upload-holiday-master-popup (close)="handleClosePopup('HolidayMaster')"></app-premise-upload-holiday-master-popup>
</div>

<div kendoDialogContainer *ngIf="openUploadAddress">
    <app-premise-address-mapping-popup
        (close)="handleClosePopup('AddressMappingUpload')"></app-premise-address-mapping-popup>
</div>

<app-alert-popup *ngIf="alertData" [Width]="270" [Height]="230" [Data]="alertData" (close)="closePopup()"
    (confirm)="deletePremiseBranch($event)"></app-alert-popup>

<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>