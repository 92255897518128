import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PerformanceSummaryPopupService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }
  getCompliance(type:string):Observable<HttpResponse<any>>{
    let url = `${this.apiUrl}/api/Common/Get_LC_CodeDescription?Type=${type}`;
    return this.http.get<any>(url,{ observe: 'response' })
  }
  getBranchDropdown(entityId:string):Observable<HttpResponse<any>>{
    const url =`${this.apiUrl}/api/ClientDetailsManagement/GetBranchDropdownlist?EntityId=${entityId}`;
    return this.http.get<any>(url,{ observe: 'response' });
  }
  getMonth(Months:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Common/Get_LC_CodeDescription?Type=${Months}`;
    return this.http.get(url, { observe: 'response' });
  }

}
