import { Component, OnInit } from '@angular/core';
import { EsicinputsService } from '../../../service/esicinputs.service';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { KendoDatepickerComponent } from '../../../components/kendo-datepicker/kendo-datepicker.component';
import { NgClass, NgIf } from '@angular/common';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { GridModule } from '@progress/kendo-angular-grid';
import { MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { PageChangeEvent } from '@progress/kendo-angular-treelist';
import { FormsModule } from '@angular/forms';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { UserService } from '../../../service/user.service';
import { BlobService } from '../../../service/blob.service';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-lc-esic',
  standalone: true,
  imports: [DropdownListComponent, KendoDatepickerComponent, NgClass, AlertPopupComponent,DateInputsModule,GridModule,MultiSelectModule,FormsModule,NgIf,IndicatorsModule],
  templateUrl: './lc-esic.component.html',
  styleUrl: './lc-esic.component.css'
})
export class LCESICComponent implements OnInit {
  public datePlaceholder: string = 'DOJ Month';
  subCodeDropdownData: any[] = [];
  subcodeDropdown = { text: 'Select Code', value: '' };
  selectedStartDate: Date | null  = null;
  public gridData: any ={data:[],total:0};
  selectedMonthYear!: string;
  EntityId!: string;
  take = 10;
  skip = 0;
  page = 1;
  selectedItems: any[] = [];
  originalData: any[] = [];
  allSelected: boolean = false;
  loading: boolean = false;
  loadingGrid: boolean = false;
  showDownloadButton = false;
  public pageSize: number = 10;
  public buttonCount = 2;
  public sizes:any = [{text:'All',value:Infinity},5, 10, 20];
  public alertData = { alertMessage: '', alertVisible: '' };

  constructor(private LCESICService: EsicinputsService,private user:UserService,private blobService: BlobService) { }

  ngOnInit(): void {
    this.EntityId=this.user.getClientId();
    this.getSubCodeDropdown(this.EntityId);
    // this.getSubCodeDropdown('A0GRG');
    this.loadGridData('A0GRG');
  }
  gridDownload(value:any){

  }
  viewDocument(value:any){
    console.log(value);
    
  }
  loadGridData(EntityId:string) {
    this.loadingGrid=true;
    this.selectedItems = [];
    this.LCESICService.GetESICDetails(EntityId,this.subcodeDropdown.value,this.selectedMonthYear,this.selectedMonthYear).subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && data && Array.isArray(data)) {
            this.originalData = data; 
            this.gridData = {
              data: data.slice(this.skip, this.skip + this.pageSize), 
              total: data.length
            };
            this.loadingGrid = false;
          } else {
          this.loadingGrid = false;
          console.log('Unexpected API response format:', data);
        }
      }, error: (error) => {
        console.error('Error fetching grid data:', error);
        this.loadingGrid = false;
      }
    });
  }
  public pageChange(event: PageChangeEvent): void {
    this.loadingGrid=true;
    this.skip = event.skip;
    this.pageSize = event.take;
    if(event.take==Infinity){
      this.gridData=this.originalData;
      this.loadingGrid=false;
      return;
    }
    this.gridData.data= this.originalData.slice(this.skip, this.skip + this.pageSize); 
    setTimeout(() => {
      this.loadingGrid=false;
    },1500)
  }

  onItemSelectionChange(dataItem: any) {
    if (dataItem.selected) {
      if (!this.selectedItems.includes(dataItem)) {
        this.selectedItems.push(dataItem);
      }
    } else {
      const index = this.selectedItems.indexOf(dataItem);
      if (index > -1) {
        this.selectedItems.splice(index, 1);
      }
    }
    console.log(this.selectedItems);
    
    this.showDownloadButton = this.selectedItems.length > 0;
  }
  toggleSelectAll(event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    
    if (!checked) {
        this.selectedItems = [];
    }
    
    this.gridData.data.forEach((item: any) => {
        item.selected = checked;
        if (checked && !this.selectedItems.includes(item)) {
            this.selectedItems.push(item);
        } else if (!checked) {
            const index = this.selectedItems.indexOf(item);
            if (index > -1) {
                this.selectedItems.splice(index, 1);
            }
        }
    });
    
    console.log(this.selectedItems);
    this.showDownloadButton = this.selectedItems.length > 0;
}
 formatDate(dateStr: string): string {
  const date = new Date(dateStr); 
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2); 
  return `${year}-${month}-${day}`; 
}
  downloadSelectedRow(){
    const formattedData = this.selectedItems.map((item: any) => ({
      client_ID: item.clientId,
      subCode: item.subCode,
      requestDate_Month: item.requestDate.split(' ')[1],
      requestDate_Year: item.requestDate.split(' ')[2],
      employeeID: item.empId,
      doj: this.formatDate(item.doj), 
      esicNumber: item.ipNumber,
      requestDate: this.formatDate(item.requestDate)
  }));
    this.LCESICService.saveESIC_Documents(formattedData).subscribe({
      next: (response) => {
        console.log('response:', response);
        if(response.status===200){
          this.alertData = { alertMessage: 'User Branch saved successfully...', alertVisible: 'success' };
        }
      },
      error: (error) => {
        console.error('Error saving user branch mapping:', error);
      
        this.alertData.alertMessage = '500 servr side error';
        this.alertData.alertVisible = 'error';
      }
    });
  }
  clearFilter(){
    this.selectedMonthYear='';
    this.selectedStartDate=null;
    this.subcodeDropdown={text:'Select Code',value:''};
    this.gridData=[];
  }
  applyFilter(){
    if(!this.selectedMonthYear){
      this.alertData = { alertMessage: 'Please Select Month.', alertVisible: 'error' }
      return;
    }
    if(!this.subcodeDropdown.value){
      this.alertData = { alertMessage: 'Please Select Location Code.', alertVisible: 'error' }
      return;
    }
    this.loadGridData('');
  }
  
  exportToExcel(){
    this.loading=true;
    if(!this.selectedMonthYear){
      this.alertData = { alertMessage: 'Please Select Month.', alertVisible: 'error' }
      this.loading=false;
      return;
    }
    if(!this.subcodeDropdown.value){
      this.alertData = { alertMessage: 'Please Select Location Code.', alertVisible: 'error' }
      this.loading=false;
      return;
    }
    this.LCESICService.getESIC_ExportToExcel(this.EntityId,this.subcodeDropdown.value,this.selectedMonthYear,this.selectedMonthYear).subscribe({
      next: (response) => {
          const data = response.body;
          const filePath =data.filePath          
          if(filePath){
          const blobLink = filePath;
            const blobName = this.blobService.extractBlobName(blobLink);
            setTimeout(() => {
              this.blobService.getSasToken(blobName).subscribe(
                (tokenResponse: any) => {
                try {
                  const sasToken = tokenResponse.result;
                  this.blobService.downloadFile(blobName, sasToken);
                  this.loading = false;
                } catch (error) {
                  this.alertData={alertMessage:'File Not Found',alertVisible:'error'};
                  this.loading = false;
                }
              },
              (error) => {
                this.alertData={alertMessage:'File Not Found',alertVisible:'error'};
                this.loading = false; 
              }
            );
          }, 5000);
        }
      }, error: (error) => {
          this.loading = false; 
          console.error('Error fetching State Dropdown data', error);
        }
      });
  }
  getSubCodeDropdown(EntityId: string) {
    this.LCESICService.GetSubCodesBasedonEntity(EntityId).subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && data && Array.isArray(data)) {
            this.subCodeDropdownData = [{ text: 'Select Code', value: '' },
            ...data.map((item: any) => ({
              value: item.value,
              text: item.text
            }))]
          } else {
            console.error("Unexpected data format", data);
          }
        }, error: (error) => {
          console.error('Error fetching State Dropdown data', error);
        }
      });
  }

  onDropdownValueChange(value: any, type: string) {
    if (type == 'subCode') {
      this.subcodeDropdown = { text: value.text, value: value.value };
    }
  }

  onStartDateChange(value: Date) {
    const date = new Date(value);
    this.selectedStartDate=date
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const selectedMonth = monthNames[date.getMonth()];
    const selectedYear = date.getFullYear();
    const formattedDate = `${selectedMonth} ${selectedYear}`;
    this.selectedMonthYear = formattedDate;
  }
  
  closeAlert(){
    this.alertData = { alertMessage: '', alertVisible: '' }
  }
}
