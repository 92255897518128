<!-- <div class="col-md-6 colpadding0" style="display: flex; align-items: center;margin-top:10px;">


  


    <header class="panel-heading tab-bg-primary" style="background: none !important;">
        <ul style="display: flex; align-items: center; margin-top: 10px;">
            <li style="display: flex; align-items: center; margin-right: 20px;">
                <input id="rdDow" value="C" type="radio" class="custom-radio" name="measurementSystem"
                    (change)="onMeasurementSystemChange($event)" [disabled]="!isRowDataEmpty()" />
                <h4 style="margin: 0 0 0 5px;">Invoice GST Upload</h4>
            </li>

            <li style="display: flex; align-items: center;">
                <input id="rdFai" value="S" type="radio" class="custom-radio" name="measurementSystem"
                    (change)="onMeasurementSystemChange($event)" [disabled]="!isRowDataEmpty()" />
                <h4 style="margin: 0 0 0 5px;">Report</h4>
            </li>
        </ul>
    </header>

</div>

<div class="col-md-8 colpadding0">
    <app-dropdown-list [data]="ClientDropdownData" [selectedValue]="selectedClient" [width]="'250px'" textField="text"
        valueField="value" style="margin-top:16px;" (valueChange)="onDropdownValueChange($event)">
    </app-dropdown-list>
</div>

<div>
    <div class="col-md-8 colpadding0">
        <app-dropdown-list [data]="ClientDropdownData" [selectedValue]="selectedClient" [width]="'250px'"
            textField="text" valueField="value" style="margin-top:16px;" (valueChange)="onDropdownValueChange($event)">
        </app-dropdown-list>

        <button class="figma-btn-blue">
            View Report
        </button>
        <button class="figma-btn-blue ">
            Export to Excel
        </button>

    </div>
    <div>

        <table class="table Upload-table table-border">
            <thead>
                <tr>
                    <th>ClientID</th>
                    <th>GST Number</th>
                    <th>Unique ID</th>
                    <th>Activity ID</th>
                    <th>Status</th>

                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="align" style="display: flex;justify-content: center;">

                    </td>
                    <td class="align">

                    </td>
                    <td class="align">

                    </td>
                    <td class="align">

                    </td>
                    <td class="align">



                    </td>

                </tr>



            </tbody>
        </table>

    </div>
</div> -->

<div class="colpadding0" style="display: flex; align-items: center; margin-top: 3px;">
    <header class="panel-heading tab-bg-primary" style="background: none !important;margin-left:-43px;">
        <ul style="display: flex; align-items: center; margin-top: -2px;">
            <li style="display: flex; align-items: center; margin-right: 20px;">
                <input id="rdDow" value="C" type="radio" class="custom-radio" name="measurementSystem"
                    (change)="onMeasurementSystemChange($event)" [disabled]="!isRowDataEmpty()" />
                <h4 style="margin: 0 0 0 5px;color:black;">Invoice GST Upload</h4>
            </li>
            <li style="display: flex; align-items: center;">
                <input id="rdFai" value="S" type="radio" class="custom-radio" name="measurementSystem"
                    (change)="onMeasurementSystemChange($event)" [disabled]="!isRowDataEmpty()" />
                <h4 style="margin: 0 0 0 5px;color:black">Report</h4>
            </li>
        </ul>
    </header>
</div>


<!-- <div class="col-md-8 colpadding0 " *ngIf="selectedMeasurementSystem === 'C'">
    <div style="display: flex;justify-content:space-between;">
        <app-dropdown-list [data]="ClientGstDropdownData" [selectedValue]="selectedClient" [width]="'250px'"
            textField="text" valueField="value" style="margin-top:16px;" (valueChange)="onDropdownValueChange($event)">
        </app-dropdown-list>
        <button class="figma-btn-blue" (click)="openAddNewPopup()" style="margin-top: 20px;"
            [disabled]="!isUploadButtonEnabled">
            Upload
        </button>

    </div>

</div> -->
<div class="colpadding0" *ngIf="selectedMeasurementSystem === 'C'">
    <div style="display: flex; align-items: center; justify-content: space-between;">
        <app-dropdown-list [data]="ClientGstDropdownData" [selectedValue]="selectedClient" [width]="'250px'"
            textField="text" valueField="value" (valueChange)="onDropdownValueChange($event)">
        </app-dropdown-list>
        <button class="figma-btn-blue mt-1" (click)="openAddNewPopup()" style="margin-top: 20px;"
            [disabled]="!isUploadButtonEnabled">
            Upload
        </button>
    </div>
</div>



<div *ngIf="selectedMeasurementSystem === 'S'">
    <div class=" colpadding0" style="margin-bottom: 20px; display: flex; justify-content: space-between;">
        <app-dropdown-list [data]="ClientGstDropdownData" [selectedValue]="selectedClient" [width]="'250px'"
            textField="text" valueField="value" (valueChange)="onDropdownValueChange($event)">
        </app-dropdown-list>

        <div style="display: flex; ">
            <button class="figma-btn-blue" (click)="GetActiveGSTUploadedDetailsbasedOnClientId(clientID)">
                View Report
            </button>

            <button class="exportbtn" (click)="exportToExcel(clientID)">
                <img src="../assets/vectors/ExportButton.svg" alt="">
            </button>
        </div>

    </div>


    <kendo-grid class="custom-grid" [data]="rowData" *ngIf="showGrid" [columnMenu]="true" [pageSize]="pageSize" 
        [pageable]="true" (pageChange)="pageChange($event)" [sortable]="true" [skip]="skip" [resizable]="true"
        style="margin-top:-14px;">


        <kendo-grid-column field="id" title="Client ID" [width]="70">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.clientId">
                    {{dataItem.clientId}}
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="shortForm" title="GstNumber" [width]="80">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.gstNumber">
                    {{dataItem.gstNumber}}
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="branchName" title="Unique ID" [width]="80">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.uniqueId">
                    {{dataItem.uniqueId}}
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="sM_Name" title="Activity ID" [width]="60">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.activityId">
                    {{dataItem.activityId}}
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="actName" title="Status" [width]="60">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.status">
                    {{dataItem.status}}
                </div>
            </ng-template>
        </kendo-grid-column>






        <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
            <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
            <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
            <kendo-pager-next-buttons></kendo-pager-next-buttons>
            <kendo-pager-input></kendo-pager-input>
            <kendo-pager-info></kendo-pager-info>
            <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
        </ng-template>
    </kendo-grid>
</div>


<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>
<div *ngIf="isAddNewVisible" class="popup-overlay">
    <app-gst-upload-popup [clientID]="clientID" *ngIf="isAddNewVisible" (close)="handleClosePopup()">

    </app-gst-upload-popup>
</div>
<app-alert-popup *ngIf="alertData.alertVisible" [Width]="250" [Height]="265" [Data]="alertData"
    (close)="closeAlert()"></app-alert-popup>