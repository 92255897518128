import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {  DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { PaycodeMasterService } from '../../../service/paycode-master.service';
import { UserService } from '../../../service/user.service';
import { PaycodeUploadPopupComponent } from '../../popups/paycode-upload-popup/paycode-upload-popup.component';
import { NgIf } from '@angular/common';
import { PaycodeEditPopupComponent } from '../../popups/paycode-edit-popup/paycode-edit-popup.component';
import { PaycodeAddPopupComponent } from '../../popups/paycode-add-popup/paycode-add-popup.component';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-paycode-master',
  standalone: true,
  imports: [DropDownListModule,FormsModule,GridActionItemComponent,IndicatorsModule,PaycodeUploadPopupComponent,NgIf,PaycodeEditPopupComponent,PaycodeAddPopupComponent,ComboBoxComponent,AlertPopupComponent],
  templateUrl: './paycode-master.component.html',
  styleUrl: './paycode-master.component.css'
})
export class PaycodeMasterComponent implements OnInit{
 public gridData: GridDataResult = { data: [], total: 0 };
 public defaultValue:{name:any,id:any}={name:'Search Paycode/Header Name',id:0}
 public uploadOpen: boolean = false
 public editOpen: boolean = false
 public saveOpen:boolean=false
 public selectedRowData: any;
 public defaultSearch='Search Paycode/Header Name'
 public allPaycodeMappingData: any[] = [];
 public filteredPaycodeMappingData: any[] = []
 public selectedValue: any={id:null,name:null};
 deleteId = 0;
 public showLoader: boolean = false;
 
 @Output() close = new EventEmitter<void>();
 alertData = { alertMessage: '', alertVisible: '' }
   
 constructor(private paycodeService:PaycodeMasterService,private user:UserService,private cdr: ChangeDetectorRef){}

  ngOnInit(): void {
    this.loadPaycodes();
    this.loadPaycodeDropdownData();
   
  }
  loadPaycodes(): void {
    const clientId = this.user.getClientId();
    const value = this.selectedValue && this.selectedValue.id ? this.selectedValue.id :'';
    console.log('client id', clientId);

    this.paycodeService.getClientPaycodeMapping(clientId, value).subscribe({
      next:(response)=>{
        console.log('Paycode response',response);
        
        if(response.status===200){
          const data=response.body;
          console.log('body',data);
          this.gridData = {
                  data: data,
                  total: data.length
        
    }}}});     
  }
  
  

  onActionClick(event: { dataItem: any, button: any }): void {
    console.log('Button Action:', event.button, 'Data Item:', event.dataItem);
    if (event.button.action === 'edit') {
      this.selectedRowData = event.dataItem;
      console.log("OnActionClick",this.selectedRowData)
      this.editOpen=true;
    } 
    else if (event.button.action === 'delete') {
      this.deleteId = event.dataItem.id;
      this.alertData = { alertMessage: 'Are you certain you want to delete this Paycode?', alertVisible: 'confirm' };
    }
  }
  
  DeleteIndustrySkill(value: any) {
    this.alertData = { alertMessage: '', alertVisible: '' };
    const clientId = this.user.getClientId();
    this.paycodeService.deletePaycode(this.deleteId, clientId).subscribe(
      (response) => {
        this.alertData = { alertMessage: 'Data Deleted Successfully', alertVisible: 'success' };
        this.loadPaycodes();
      },
      (error) => {
        this.alertData = { alertMessage: error.error.Result, alertVisible: 'error' };
      });  
  }
loadPaycodeDropdownData(): void {
  const clientId = this.user.getClientId();
  this.paycodeService.getAllPaycodeMappingDropDown(clientId,'').subscribe({
    next:(response)=>{
      console.log('Paycode Dropdown Response:', response);
    
        const data=response.body.data;
        console.log('body',data);
        if (data && Array.isArray(data)) {
            this.allPaycodeMappingData = data.map((item: any) => ({
              id: item.value,
              name: item.key
            }));
          }
          console.log('paycode drp',this.allPaycodeMappingData);          
    }});
}

public onFilterChange(event: any): void {
  console.log(event);
  if (event.length >= 1) {
    this.filteredPaycodeMappingData = this.allPaycodeMappingData.filter(item =>
      item.name.toLowerCase().includes(event.toLowerCase())
    );
  } else {
    this.filteredPaycodeMappingData = [];
  }
}
onDropdownChange(value: any): void {
  console.log(value.id);
  this.selectedValue = {id:value.id,name:value.name};
}

public uploadDialog(): void {
  this.uploadOpen = true;
}

public closeUploadDialog(): void {
  this.uploadOpen = false;
}

public closeEditDialog(): void {
  this.editOpen = false;
  this.selectedRowData = null;
}

public handleEditPopupClose(): void {
  this.closeEditDialog();
  this.loadPaycodes(); 
}

public saveDialog():void{
  this.saveOpen=true;
}

public closePopup(): void {
  this.alertData = { alertMessage: '', alertVisible: '' };
  this.close.emit();
}
public closeSaveDialog():void{
  this.saveOpen=false  
}
  applyFilter():void{
    this.loadPaycodes();
  }

  clearFilter():void{
    this.selectedValue= null;  
    this.defaultSearch = 'Search Paycode/Header Name';
   this.loadPaycodes();
  }

  public handleUpdate(): void {
    console.log('Handle Update Called');
    this.loadPaycodes(); 
  }

  onDataSaved(): void {
    this.loadPaycodes();
  }
  
  public parentColumns: any[] = [
    { field: 'cpmD_Header', title: 'Header Name' },
    { field: 'cpmD_Deduction_Type', title: 'Paycode Type' },
    {
      field: 'cpmD_PayCode', title: 'Paycode',
      cellTemplate: 'paycodeCellTemplate'  
    },
    { field: 'cpmD_Sequence_Order', title: 'Seq. Order' },
    { field: 'cpmD_appl_ESI', title: 'ESI Applicable' },
    { field: 'cpmD_appl_PF', title: 'PF Applicable' },
    { field: 'cpmD_Appl_PT', title: 'PT Applicable' },
    { field: 'cpmD_Appl_LWF', title: 'LWF Applicable' },
    
    {
      field: 'Action',
      isIconColumn: true,
      title: 'Action',
      filteredIcons: [
        {
          url: '../assets/vectors/edit.svg',
          alt: 'Edit Icon',
          action: 'edit'
        },
        {
          url: '../assets/vectors/delete.svg',
          alt: 'Delete Icon',
          action: 'delete'
        }
      ]
    }
  ];
}


