<div class="row colpadding0">
    <div class="col-md-12 colpadding0" style="margin-left: -17px;">
        <header class="panel-heading tab-bg-primary" style=" width:100%;background: none !important; padding: 15px;">
            <ul class="nav nav-tabs">
                <li style="display: flex; align-items: center;">
                    <h4> <input id="rdState" value="S" type="radio" class="custom-radio" name="measurementSystem"
                            (change)="onRadioChange('S')" [checked]="selectionType === 'S'" />
                        State Wise</h4>
                </li>
                <li style="display: flex; align-items: center;">
                    <h4><input id="rdIndustry" value="I" type="radio" class="custom-radio" name="measurementSystem"
                            (change)="onRadioChange('I')" [checked]="selectionType === 'I'" />
                        Industry Wise</h4>
                </li>
                <div class="heading" *ngIf="selectionType === 'S' ">
                    <button class="figma-btn-blue " (click)="addNotification()">
                        <img src="../assets/vectors/Plus.svg" alt="">
                        Add State Wise Notification Details
                    </button>
                </div>

                <div class="heading" *ngIf="selectionType === 'I' ">
                    <button class="figma-btn-blue " (click)="addIndustryNotification()">
                        <img src="../assets/vectors/Plus.svg" alt="">
                        Add Industry Wise Notification Details
                    </button>
                </div>
            </ul>
        </header>
    </div>
</div>

<div id="divState" style="margin-top:10px;" *ngIf="selectionType === 'S'">
    <div class="row colpadding0 new-gen-round">
        <div class="col-12 d-flex">
            <div class="grid">
                <app-grid-action-item [gridData]="gridData" [columns]="parentColumns" [pageSize]="10" [pageable]="true"
                    [actionColumnWidth]="'120px'" [filterable]="true" (buttonClick)="onActionClick($event)">
                </app-grid-action-item>
            </div>
        </div>
    </div>
</div>

<div id="divIndustry" style="margin-top:10px;" *ngIf="selectionType === 'I'">
    <div class="row colpadding0 new-gen-round">
        <div class="col-12 d-flex">
            <div class="grid">
                <app-grid-action-item [gridData]="gridData1" [columns]="parentColumns1" [pageSize]="10"
                    [pageable]="true" [actionColumnWidth]="'120px'" [filterable]="true"
                    (buttonClick)="onActionClick($event)">
                </app-grid-action-item>
            </div>
        </div>
    </div>
</div>

<div kendoDialogContainer *ngIf="openState">
    <app-notification-add-popup (dataSaved)="onDataSaved()" (close)="closeSaveDialog()"></app-notification-add-popup>
</div>

<div kendoDialogContainer *ngIf="openIndustry">
    <app-notification-industry-add-popup (dataSaved)="onIndustryDataSaved()"
        (close)="closeSaveDialog()"></app-notification-industry-add-popup>
</div>

<app-alert-popup *ngIf="alertData" [Width]="270" [Data]="alertData" (close)="closePopup()"></app-alert-popup>