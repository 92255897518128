import { Component, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropDownsModule, MultiSelectComponent, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-multi-select-dropdown',
  standalone: true,
  imports: [CommonModule, MultiSelectModule,DropDownsModule,FormsModule],
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.css']
})
export class MultiSelectDropdownComponent {
  @Input() data: any[] = [];
  @Input() textField: string = '';
  @Input() valueField: string = '';
  @Input() width: string = '';
  @Input() height: string = '';
  @Input() selectedValues: any[] = [];
  @Output() valueChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Input() DefaultValue: string = '';
  selectAllValue = { "text": 'select-all', "value": 'Select All' ,"displayvalue":'select-all'};
  modifiedData: any[] = [];
  selectedCount: number = 0; 
  checkedData:any
  value:any
  @ViewChild('multiSelect', { static: false }) multiSelect!: MultiSelectComponent;
  @Input() placeholder: string = 'Select...';
  constructor(private cdr: ChangeDetectorRef) {}
  ngOnInit() {
    debugger
    this.modifiedData = [this.selectAllValue, ...this.data]; // Add "Select All" option to data
   
  }
  onValueChange(event: any[]) {
 debugger
    const isSelectAllChecked = event.some(item => item['text'] === 'select-all');
    // const isSelectAllChecked = event[0].text === 'select-all';
    const otherValues = event.filter(item => item['text'] !== 'select-all');
    
  
    if (isSelectAllChecked && otherValues.length == 0) {
      // If 'Select All' was selected, select all items including 'Select All'
      this.selectedValues = [...this.data, this.selectAllValue];
    } else if (!isSelectAllChecked && otherValues.length === this.data.length) {
      // If 'Select All' was unchecked directly, unselect all items
      this.selectedValues = [];
    } else {
      // Otherwise, update the selected values based on what's checked/unchecked
      this.selectedValues = otherValues;

    }

    this.valueChange.emit(this.selectedValues.filter(item => item['text'] !== 'select-all'));
    setTimeout(() => {
      if (this.multiSelect) {
        this.multiSelect.toggle(false); 
        this.multiSelect.toggle(true); // Reopen the dropdown
      }
    });
  }

  get displayText(): string {
    const count = this.selectedValues.length;
    return count > 0 ? `${count} item${count > 1 ? 's' : ''} selected` : this.placeholder;
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges(); // Manually trigger change detection
  }
  
}