import { ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { TreeGridComponent } from '../../../components/tree-grid/tree-grid.component';
import { PremiseMasterService } from '../../../service/premise-master.service';
import { UserService } from '../../../service/user.service';
import { NgIf } from '@angular/common';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { PremiseShiftmasterUploadComponent } from '../../popups/premise-shiftmaster-upload/premise-shiftmaster-upload.component';
import { BlobService } from '../../../service/blob.service';
import { CommonModule } from '@angular/common';
import { PremiseBulkUploadComponent } from '../../popups/premise-bulk-upload/premise-bulk-upload.component';
import { PremiseMasterEditPopupComponent } from '../../popups/premise-master-edit-popup/premise-master-edit-popup.component';
import { PremiseMasterAddEditPopupComponent } from '../../popups/premise-master-add-edit-popup/premise-master-add-edit-popup.component';
import { PremiseAddressMappingPopupComponent } from '../../popups/premise-address-mapping-popup/premise-address-mapping-popup.component';
import { AddressMappingComponent } from '../../popups/address-mapping/address-mapping.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { PremiseUploadHolidayMasterPopupComponent } from '../../popups/premise-upload-holiday-master-popup/premise-upload-holiday-master-popup.component';

@Component({
  selector: 'app-premise-master',
  standalone: true,
  imports: [TreeGridComponent,CommonModule, NgIf, AlertPopupComponent, PremiseShiftmasterUploadComponent, PremiseMasterEditPopupComponent, PremiseBulkUploadComponent, PremiseMasterAddEditPopupComponent, PremiseAddressMappingPopupComponent, AddressMappingComponent, IndicatorsModule,PremiseUploadHolidayMasterPopupComponent],
  templateUrl: './premise-master.component.html',
  styleUrl: './premise-master.component.css',
})

export class PremiseMasterComponent implements OnInit,OnChanges {
  @ViewChild('actionTemplate') actionTemplate!: TemplateRef<any>;
  @Output() close = new EventEmitter<void>();
  public gridData: any[] = []
  public filteredGridData: any[] = [];
  public firstRow: boolean = false;
  public openEdit: boolean = false;
  public openAddEdit: boolean = false;
  public OpenHolidayMaster: boolean = false;
  public selectedRowData: any;
  public branchId!: number;
  public openAddressMapping: boolean = false;
  public statecode!: string
  public locationcode!: number
  public branchName!: string
  public Role!: string
  openUploadAddress: boolean = false;
  openShiftMaster: boolean = false
  openBulkUpload: boolean = false
  loading: boolean = false;
  alertData = { alertMessage: '', alertVisible: '' }
  constructor(private premiseMasterService: PremiseMasterService, private user: UserService,
    private cdr: ChangeDetectorRef, private blobService: BlobService) { }

  ngOnInit(): void {
    this.Role=this.user.getRole()
    console.log(this.Role);
    // if (!(this.Role === 'PERF' || this.Role === 'RVW1')) {
    //   this.parentColumns.push({
    //     field: 'addressmapping',
    //     iconColumn: true,
    //     title: 'Address Mapping',
    //     width: '50px',
    //     filteredIcons: []
    //   });
    // }
    this.loadPremiseData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadPremiseData()
  }

  loadPremiseData(): void {
    const clientId = this.user.getClientId();
    console.log('ClientID', clientId);

    this.premiseMasterService.getPremiseMasterData(clientId).subscribe({
      next: (response) => {
        if (response.status === 200) {
          const data = response.body;
          console.log('Premise data:', data);

          // Mark leaf nodes and sort data alphabetically by 'name' field
          this.gridData = this.markLeafNodes(data).sort((a, b) => a.name.localeCompare(b.name));

          // Create a sorted copy for filteredGridData as well
          this.filteredGridData = [...this.gridData];

          this.firstRow = this.checkIfFirstRow();
          console.log('first row is parent:', this.firstRow);
          this.cdr.detectChanges();
        } else {
          console.error('Error:', response.body.error);
        }
      },
      error: (err) => {
        console.error('Failed to load premise data:', err);
      }
    });
}

  private checkIfFirstRow(): boolean {
    if (this.filteredGridData.length > 0) {
      const firstRow = this.filteredGridData[0];
      console.log('First row structure:', firstRow);
      return !!firstRow;
    }
    return false;
  }


  onActionClick(event: { dataItem: any, action: string }): void {
    console.log('event', event);
    if (event.action === 'edit') {
      this.selectedRowData = event.dataItem;
      console.log("OnActionClick", this.selectedRowData)
      this.branchId = this.selectedRowData.id;
      this.openEdit = true;

    }
    else if (event.action === 'delete') {
      this.branchId = event.dataItem.id;
      console.log('branch id', this.branchId);
      this.premiseMasterService.deleteCustomerBranchConfirmation(this.branchId).subscribe({
        next: (response) => {
          if (response) {
            console.log('delete response', response);
            this.alertData = { alertMessage: 'Are you sure you want to delete this branch?', alertVisible: 'confirm' };
          }
        },
        error: (error) => {
          console.error('Error confirming deletion:', error);
        }
      });
    }

    else if (event.action === 'Add') {
      this.selectedRowData = event.dataItem;
      console.log("OnActionClick", this.selectedRowData)
      this.branchId = this.selectedRowData.id;
      this.openAddEdit = true;
      console.log('Add action triggered, opening edit popup.');
    }
    else if (event.action === "addressmapping") {
      debugger;
      this.selectedRowData = event.dataItem;
      this.statecode = this.selectedRowData.cM_State;
      this.locationcode = this.selectedRowData.cM_City;
      this.branchId = this.selectedRowData.id;
      this.branchName = this.selectedRowData.clientName;
      this.openAddressMapping = true;
    }
  }

  deletePremiseBranch(branch: number) {
    branch = this.branchId
    console.log('branch id', branch);
    this.alertData = { alertMessage: '', alertVisible: '' };
    this.premiseMasterService.deleteCustomerBranch(branch).subscribe(
      {
        next: (data) => {
          console.log('delete branch', data);

          this.alertData = { alertMessage: 'Branch Deleted Successfully', alertVisible: 'success' };
          this.loadPremiseData();
        },
        error: (error) => {
          this.alertData = { alertMessage: error.error.Result, alertVisible: 'error' };
        }})
  }

  private markLeafNodes(data: any[]): any[] {
    return data.map(item => {
      item.leafNode = !(item.children && item.children.length > 0);
      if (item.children) {
        item.children = this.markLeafNodes(item.children);
      }
      return item;
    });
  }

  applyFilter(event: any): void {
    debugger;
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    console.log('Filter value:', filterValue);

    this.filteredGridData = this.filterGridData(this.gridData, filterValue);

    console.log('Filtered Data:', this.filteredGridData);
    this.filteredGridData = [...this.filteredGridData];
    
    this.cdr.detectChanges();
  }

  // public filterGridData(data: any[], filterValue: string): any[] {
  //   debugger;
  //   let filteredData: any[] = [];
  //   data.forEach(item => {
  //     if (item.branchType === 'B') {
  //       if (item.clientName.toLowerCase().includes(filterValue)) {
  //         if (!filteredData.some(filteredItem => filteredItem.id === item.id)) {
  //           filteredData.push({ ...item });
  //         }
  //         let parentData = this.getParentData(data, item.parentID);
  //         while (parentData) {
  //           if (!filteredData.some(filteredItem => filteredItem.id === parentData.id)) {
  //             filteredData.push(parentData);
  //           }
  //           parentData = this.getParentData(data, parentData.parentID);
  //         }
  //       }
  //     }
  //   });
  //   return filteredData;
  // }
  
  public filterGridData(data: any[], filterValue: string): any[] {
    debugger;
    let filteredData: any[] = [];
    
    data.forEach(item => {
      const clientName = item.clientName?.toLowerCase() || '';
      const stateCode = item.cM_State?.toLowerCase() || '';
      const stateName = item.sM_Name?.toLowerCase() || '';
      const cityCode = item.cM_City?.toLowerCase() || '';
      const cityName = item.lM_Name?.toLowerCase() || '';

      const isMatch = clientName.includes(filterValue) || 
                      stateCode.includes(filterValue) || 
                      stateName.includes(filterValue) || 
                      cityCode.includes(filterValue) || 
                      cityName.includes(filterValue);
      
      if (item.branchType === 'B' && isMatch) {
      
        if (!filteredData.some(filteredItem => filteredItem.id === item.id)) {
          filteredData.push({ ...item });
        }
  
        let parentData = this.getParentData(data, item.parentID);
        while (parentData) {
          if (!filteredData.some(filteredItem => filteredItem.id === parentData.id)) {
            filteredData.push(parentData);
          }
          parentData = this.getParentData(data, parentData.parentID);
        }
      }
    });
  
    return filteredData;
  }
  

  private getParentData(data: any[], parentId: any): any {
    return data.find(item => item.id === parentId) || null;
  }
 
  
  exportToExcel(): void {
    const entityId = this.user.getClientId();
    this.loading = true
    this.premiseMasterService.exportToExcel(entityId).subscribe(
      (response: any) => {
        console.log('response', response);

        const blobLink = response.body.filePath;
        const blobName = this.blobService.extractBlobName(blobLink);
        setTimeout(() => {
          this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
            debugger;
            const sasToken = tokenResponse.result;
            this.blobService.downloadFile(blobName, sasToken);
            this.loading = false;
          });
        }, 5000);
      },
      (error) => {
        console.error('Error fetching blob link:', error);
        this.loading = false;
      });
  }

  public handleEdit(): void {
    console.log('Handle Update Called');
    this.loadPremiseData();
  }

  public handleAddEdit(): void {
    this.loadPremiseData();
  }

  public handleEditPopupClose(): void {
    this.openEdit = false;
    this.selectedRowData = null;
    this.loadPremiseData();
  }
  public handleAddEditPopupClose(): void {
   
    this.openAddEdit = false;
    this.loadPremiseData();
    
  }

  openHolidayMaster(){
    this.OpenHolidayMaster=true;
  }

  public handleClosePopup(popname: string): void {
    if (popname == "Add") {
      this.openAddEdit = false;
    }
    else if (popname == "Edit") {
      this.openEdit = false;
      this.selectedRowData = null;
    }
    else if (popname == "ShiftMaster") {
      this.loading=false;
      this.openShiftMaster = false;
    }
    else if (popname == "AddressMapping") {
      this.openAddressMapping = false;
    }
    else if (popname == "AddressMappingUpload") {
      this.openUploadAddress = false;
    }
    else if (popname == "BulkUpload") {
      this.openBulkUpload = false
    }else if(popname == 'HolidayMaster'){
      this.OpenHolidayMaster=false;
    }
    this.loadPremiseData();
  }

  public closePopup(): void {
    this.alertData = { alertMessage: '', alertVisible: '' };
    this.close.emit();
  }

  handleShiftMasterClose() {
    this.openShiftMaster = false;
  }
  bulkUpload() {
    this.openBulkUpload = true
  }
  bulkUploadClose() {
    this.openBulkUpload = false
  }

  uploadShiftMaster() {
    this.loading=true;
    this.openShiftMaster = true;
  }

  uploadAddressMapping() {
    this.openUploadAddress = true;
  }
  AddressUploadClose() {
    this.openUploadAddress = false;
  }

  public parentColumns: any[] = [
    { field: 'name', title: 'Premise', expandable: true, width: '150px' },
    { field: 'cM_State', title: 'State Code', width: '80px' },
    { field: 'sM_Name', title: 'State Name', width: '120px' },
    { field: 'cM_City', title: 'Location', width: '75px' },
    { field: 'lM_Name', title: 'City', width: '70px' },
    { field: 'branchStatus', title: 'Status', width: '50px' },
    {
      field: 'action',
      iconColumn: true,
      title: 'Action',
      width: '50px',
      filteredIcons: this.getFilteredIcons()
    },
    {
      field: 'addressmapping',
      iconColumn: true,
      title: 'Address Mapping',
      width: '50px',
      filteredIcons: []
    }
  ];
  private getFilteredIcons(): any[] {
    if(this.Role==='PERF' || this.Role==='RVW1')
    {
      let icons = [
        {
            url: '../assets/vectors/edit.svg',
            alt: 'Edit Icon',
            action: 'edit',
            title: 'Edit'
        },
      ]
      return icons;
    }
    else{
      let icons = [
        {
            url: '../assets/vectors/edit.svg',
            alt: 'Edit Icon',
            action: 'edit',
            title: 'Edit'
        },
        {
            url: '../assets/vectors/delete.svg',
            alt: 'Delete Icon',
            action: 'delete',
            title: 'Delete'
        }
    ];

    if (this.firstRow) {
        icons = [{
            url: '../assets/vectors/Addnew.svg',
            alt: 'Add Icon',
            action: 'add',
            title: 'Add'
        }];
    }
    return icons;
}
    }
    
 
  
}


