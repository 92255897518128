<kendo-dialog #dialogRef title="Paycode Details" (close)="closePopup()" [width]="1050" [height]="210" class="custom-dialog">
  <div class="row dialog-content" >
    <div class="col-md-12">
      <kendo-grid [data]="[rowData]" [scrollable]="'none'" class="grid custom-grid">
            <kendo-grid-column field="headername" title="Header Name">
                <ng-template kendoGridCellTemplate>
                <input type="text" [(ngModel)]="headername" [disabled]="!headerNameEditable"  ame="headerName" class="textbox">
            </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="paycodeType" title="Paycode Type">
                <ng-template kendoGridCellTemplate>
                    <input type="text" [(ngModel)]="selectedPaycodeType.text" [disabled]="true" name="paycodeType" class="textbox" >
            </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="paycode" title="Paycode">
             <ng-template kendoGridCellTemplate>
                <input type="text" [(ngModel)]="selectedPaycode.text" [disabled]="true" name="paycode"  class="textbox">
        </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="sequenceOrder" title="Sequence Order">
                <ng-template kendoGridCellTemplate>
                <input type="number" [(ngModel)]="sequenceOrder" [disabled]="!sequenceOrderEditable" name="sequenceOrder" class="textbox">
            </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="esic_App" title="ESI Applicable">
                <ng-template kendoGridCellTemplate>
                  <input type="checkbox" [(ngModel)]="esic_App" [disabled]="isStandardPaycodeType()" name="esic_App" (change)="onCheckboxChange($event, 'esic_App')" style="width: 100%; border: 1px solid #ddd; background-color: #f5f5f5;">
                </ng-template>
              </kendo-grid-column>
              
              <kendo-grid-column field="pF_App" title="PF Applicable">
                <ng-template kendoGridCellTemplate>
                  <input type="checkbox" [(ngModel)]="pF_App" [disabled]="isStandardPaycodeType()" name="pF_App" (change)="onCheckboxChange($event, 'pF_App')" style="width: 100%; border: 1px solid #ddd; background-color: #f5f5f5;">
                </ng-template>
              </kendo-grid-column>
              
              <kendo-grid-column field="pT_App" title="PT Applicable">
                <ng-template kendoGridCellTemplate>
                  <input type="checkbox" [(ngModel)]="pT_App" [disabled]="isStandardPaycodeType()" name="pT_App"  (change)="onCheckboxChange($event, 'pT_App')" style="width: 100%; border: 1px solid #ddd; background-color: #f5f5f5;">
                </ng-template>
              </kendo-grid-column>
              
              <kendo-grid-column field="lwF_App" title="LWF Applicable">
                <ng-template kendoGridCellTemplate>
                  <input type="checkbox" [(ngModel)]="lwF_App" [disabled]="isStandardPaycodeType()" name="lwF_App" (change)="onCheckboxChange($event, 'lwF_App')" style="width: 100%; border: 1px solid #ddd; background-color: #f5f5f5;">
                </ng-template>
              </kendo-grid-column>
              
      </kendo-grid>
    </div>
  </div>
     <kendo-dialog-actions style="border: none; ">
        <button kendoButton class="figma-btn-blue figma-popup" (click)="editFiles()">Save</button>
      </kendo-dialog-actions>
</kendo-dialog>

<app-alert-popup *ngIf="alertData" [Width]="270"  [Data]="alertData" (close)="closePopup()"></app-alert-popup>
  
