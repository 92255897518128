import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridModule, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { FilterService } from "@progress/kendo-angular-grid";
import { filterBy, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { MultiCheckFilterComponent } from '../multicheck-filter/multicheck-filter.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-grid',
  standalone: true,
  imports: [CommonModule, GridModule, MultiSelectModule, MultiCheckFilterComponent,TooltipModule,IndicatorsModule],
  templateUrl: './grid.component.html',
  styleUrl: './grid.component.css',
})
export class GridComponent implements OnChanges {
  @Input() gridData: any[] = [];
  @Input() columns: any[] = [];
  @Input() public pageSize: number = 10;
  public buttonCount = 2;
  public sizes = [{text:'All',value:Infinity},5, 10, 20];
  @Input() public pageable: boolean = true;
  @Input() public filterable: boolean = true;
  // public filter?: CompositeFilterDescriptor;
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  loading = false;
  public gridView?: GridDataResult;
  public skip = 0;
  private items: any[] = [];
  public filters: { [key: string]: any[] } = {};

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['gridData']) {
      this.loading=true;
      this.items = this.gridData;
      this.loadItems();
      this.setColumnFilters();
    }
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }

  public pageChange(event: PageChangeEvent): void {
    debugger
    this.loading=true;
    this.skip = event.skip;
    if(event.take==Infinity){
      this.pageSize=this.gridData.length
    }
    this.loadItems();
    setTimeout(() => {  
      this.loading = false; 
    }, 2000);
  }

  private loadItems(): void {
    let filteredItems = this.items;
    for (const field in this.filters) {
      const values = this.filters[field];
      if (values && values.length > 0) {
        filteredItems = filteredItems.filter(item => values.includes(item[field]));
      }
    }

    this.gridView = {
      data: filteredItems.slice(this.skip, this.skip + this.pageSize),
      total: filteredItems.length,
    };
  }

  private setColumnFilters(): void {
    this.columns.forEach(column => {
      const firstItem = this.gridData[0];
      if (firstItem) {
        const fieldValue = firstItem[column.field];
        if (typeof fieldValue === 'number') {
          column.filter = 'numeric';
        } else if (typeof fieldValue === 'boolean') {
          column.filter = 'boolean';
        } else if (fieldValue instanceof Date) {
          column.filter = 'date';
        } else {
          column.filter = 'multiselect'; 
        }
      }
    });
  }

  public getUniqueColumnValues(field: string): any[] {
    const uniqueValues = new Set(this.gridData.map(item => item[field]));
    return Array.from(uniqueValues);
  }

  public onMultiSelectChange(selectedValues: any[], field: string): void {
    this.filters[field] = selectedValues;
    this.loadItems();
  }

  public categoryChange(values: any[], filterService: FilterService, field: string): void {
    filterService.filter({
      filters: values.map((value) => ({
        field: field,
        operator: "eq",
        value,
      })),
      logic: "or",
    });
  }

  private applyFilters(items: any[]): any[] {
    return Object.keys(this.filters).reduce((filtered, field) => {
      const values = this.filters[field];
      return values.length ? filtered.filter(item => values.includes(item[field])) : filtered;
    }, items);
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    if (filter.filters.length === 0) {
      const filteredItems = this.applyFilters(this.items);
      this.gridView = {
        data: this.items,
        total: this.items.length,
      };
    } else {
      const filteredData = filterBy(this.items, filter);
      this.gridView = {
        data: filteredData.slice(this.skip, this.skip + this.pageSize),
        total: filteredData.length,
      };
    }
  }

}

