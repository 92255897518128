import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { WeekService, MonthService, WorkWeekService, DayService, AgendaService, MonthAgendaService, TimelineViewsService, TimelineMonthService, ScheduleModule, EventSettingsModel } from '@syncfusion/ej2-angular-schedule';
import { DomSanitizer } from '@angular/platform-browser';
import { DashboardService } from '../../../service/dashboard.service';
import { UserService } from '../../../service/user.service';
import { CommonModule, DatePipe } from '@angular/common';
import { GridDataResult, GridModule, PageChangeEvent } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-cd-calendar',
  standalone: true,
  imports: [ScheduleModule, CommonModule, GridModule],
  providers: [DayService, WeekService, WorkWeekService, MonthService, AgendaService, MonthAgendaService, TimelineViewsService, TimelineMonthService, DatePipe],
  templateUrl: './cd-calendar.component.html',
  styleUrl: './cd-calendar.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class CdCalendarComponent implements OnInit, OnChanges {
  @Input() year: any = {};

  public selectedDate: Date = new Date();
  public showWeekend: boolean = true;
  public data: Array<any> = [];
  public eventSettings: EventSettingsModel = { dataSource: this.data };
  isLoading = true;
  eventDateSelected: string = "";
  eventColor: string = "";

  public gridData: GridDataResult = { data: [], total: 0 };
  public pageSize: number = 10;
  public page: number = 1;
  public buttonCount = 2;
  public sizes = [5, 10, 20, 50];
  take = 10;
  skip = 0;

  constructor(private sanitized: DomSanitizer, private datePipe: DatePipe, private dashboardService: DashboardService, private userService: UserService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.year = changes['year']['currentValue']
    
    switch (this.year) {
      case '2024':
        this.selectedDate = new Date()
        break;
      case '2023':
        const now = new Date();
        now.setFullYear(now.getFullYear() - 1);
        this.selectedDate = now
        break;
      case 'Current Month':
        this.selectedDate = new Date()
        break;
      case 'Previous Month':
        const now2 = new Date();
        now2.setMonth(now2.getMonth() - 1);
        this.selectedDate = now2
        break;
    
      default:
        break;
    }

    this.getCalendarColorDetails();
  }

  getCalendarColorDetails() {
    this.isLoading = true;
    this.dashboardService.getCalendarColorDetails(this.userService.getClientId(), this.userService.getUserId(), this.userService.getRole(), this.year).subscribe({
      next: (response) => {
        const data = response.body;
        this.data = data.map((item: any, index: number) => ({
          Id: index,
          Subject: `Schedule ${index}`,
          StartTime: new Date(item.performerScheduleOn),
          EndTime: new Date(item.performerScheduleOn),
          IsAllDay: false,
          Flag: item.flag,
          AssignedCount: item.assignedCount,
          Color: item.color
        }));

        this.eventSettings = { dataSource: this.data }
        this.isLoading = false;
      },
      error: (err) => {
        console.log('Error', err);
        this.isLoading = false;
      }
    });
  }

  public getCellContent(date: Date): any {
    let event = this.data.find((item: any) => item.StartTime.toLocaleDateString("en-US") == date.toLocaleDateString("en-US"));

    if (event) {
      let content = `<div class="date-card card-${event?.Color}">
                        <div class="top-bar bar-${event?.Color}"></div>
                        <div class="month-number number-${event?.Color}">${event?.AssignedCount}</div>
                      </div>`;
      return this.sanitized.bypassSecurityTrustHtml(content);
    } else {
      return '';
    }
  }

  onDateChange(event: any) {
    this.eventDateSelected = this.datePipe.transform(event?.startTime, "yyyy-MM-dd") ?? "";
    let eventItem = this.data.find((item: any) => item.StartTime.toLocaleDateString("en-US") == event?.startTime.toLocaleDateString("en-US"));
    this.eventColor = eventItem?.Color;
    this.skip = 0;
    this.take = 10;
    this.getCalendarEventDetails()
  }

  getCalendarEventDetails() {
    this.dashboardService.getCalendarEventDetails(this.userService.getClientId(), this.userService.getUserId(), this.userService.getRole(), this.year, this.eventDateSelected, this.skip, this.take).subscribe({
      next: (response) => {
        const data = response.body;
        this.gridData = data;
      },
      error: (err) => {
        console.log('Error', err);
      }
    });
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.getCalendarEventDetails();
  }
}