import { Component, EventEmitter, Output } from '@angular/core';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { UserService } from '../../../service/user.service';
import { ClientRegistrationRepositoryService } from '../../../service/client-registration-repository.service';
import { BlobService } from '../../../service/blob.service';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { NgIf } from '@angular/common';
import { RegistrationRepositoryAddEditPopupComponent } from '../../popups/registration-repository-add-edit-popup/registration-repository-add-edit-popup.component';
import { ClientRegistrationRepositoryExportPopupComponent } from "../../popups/client-registration-repository-export-popup/client-registration-repository-export-popup.component";

@Component({
  selector: 'app-client-registration-repository',
  standalone: true,
  imports: [GridActionItemComponent, IndicatorsModule, NgIf, RegistrationRepositoryAddEditPopupComponent, ClientRegistrationRepositoryExportPopupComponent],
  templateUrl: './client-registration-repository.component.html',
  styleUrl: './client-registration-repository.component.css'
})
export class ClientRegistrationRepositoryComponent {
  //@Output() openEditPopupEvent: EventEmitter<boolean> = new EventEmitter();
  public gridData: GridDataResult = { data: [], total: 0 };
  public alertData = { alertMessage: '', alertVisible: '' };
  public rowData!:any[];
  loading: boolean = false;
  openAddEditPopup=false;
  openExportPopup=false;
  isEditPopup = false;
  flag:string='C';

  public parentColumns: any[] = [
    { field: 'activity', title: 'Activity' },
    { field: 'stateName', title: 'State' },
    { field: 'lM_Name', title: 'Location' },
    { field: 'branchName', title: 'Branch' },
    { field: 'regTypeName', title: 'Type' },
    {
      field: 'Action',
      isIconColumn: true,
      title: 'Action',
      filteredIcons: [
        {
          url: '../assets/vectors/edit.svg',
          alt: 'Icon 1',
          action: 'Edit'
        },
      ]
    }
   ] 

   constructor(private clientregistrationrepository:ClientRegistrationRepositoryService, private user:UserService,private blobService: BlobService){}

   ngOnInit(): void {
    this.loadGridData();
  }

  loadGridData(){
    
    const clientId=this.user.getClientId();
    
    this.clientregistrationrepository.getGridData(clientId).subscribe({
      next:(response)=>{
        console.log('Response',response);
        
        const data=response.body;
        this.gridData={
          data: data.items || data,
          total:data.total || data.length
        }}})
  }

  applyFilter(event: KeyboardEvent){
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    const clientId = this.user.getClientId();
    
    this.clientregistrationrepository.getGridData(clientId).subscribe({
        next: (response) => {
            const data = response.body;
            const filteredData = data.filter((item:any) => 
                item.activity.toString().toLowerCase().includes(filterValue) 
                // item.stateName.toLowerCase().includes(filterValue) ||
                // item.lM_Name.toLowerCase().includes(filterValue) ||
                // item.branchName.toLowerCase().includes(filterValue)||
                // item.regTypeName.toLowerCase().includes(filterValue)
            );
            this.gridData = {
                data: filteredData,
                total: filteredData.length
            };
        }
    });
  }
  exportToExcel(): void {
    this.loading = true;
    const EntityID = this.user.getClientId();
    const validFlags = ['C', 'E', 'EC'];
    if (!validFlags.includes(this.flag)) {
      console.error('Invalid flag value:', this.flag);
      this.loading = false;
      this.alertData = { alertMessage: 'Invalid flag value', alertVisible: 'error' };
      return;
    }
  
    this.clientregistrationrepository.getExcelFile(EntityID, this.flag).subscribe({
      next: (response: any) => {
        console.log('response', response);
  
        if (response.status === 200) {
          const data = response.body;
          const blobLink = data.filePath;
          const blobName = this.blobService.extractBlobName(blobLink);
          
          setTimeout(() => {
            this.blobService.getSasToken(blobName).subscribe(
              (tokenResponse: any) => {
                try {
                  const sasToken = tokenResponse.result;
                  this.blobService.downloadFile(blobName, sasToken);
                  this.loading = false;
                } catch (error) {
                  this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
                  this.loading = false;
                }
              },
              (error) => {
                this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
                this.loading = false;
              }
            );
          }, 5000);
        } else {
          console.error('Error fetching blob link:', response.body.error);
          this.loading = false;
          this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
        }
      },
      error: (err) => {
        console.error('API call failed:', err);
        this.loading = false;
        this.alertData = { alertMessage: 'An error occurred while exporting', alertVisible: 'error' };
      }
    });
  }
  handleButtonClick(event:any){
    if (event.button.action === "Edit") {
      debugger
      console.log('value',event);
      this.rowData=event.dataItem;
      this.openAddEditPopup=true;  
      this.isEditPopup = true;  
    } 
  }

  
  openAddNewPopup(){
    debugger
    this.openAddEditPopup=true
    this.rowData=[]
  }
  openExport(){
    this.openExportPopup=true;
    this.rowData=[]
  }

  ClosePopup(){
    this.openAddEditPopup=false
    this.loadGridData();
  }
  CloseExportPopup(){
    this.openExportPopup=false
    this.loadGridData();
  }
}
