import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnchorDashboardSummaryService {
  private baseUrl: string = environment.apiUrl;

  constructor(private http:HttpClient) { }

  getCompliance(type:any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/Common/Get_LC_CodeDescription?Type=${type}`;
    return this.http.get(url, { observe: 'response' });
}

  getDetails(payload:any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/ClientsInputUpload/GetAnchorDashboardDetails?UserID=${payload.UserID}&ComType=${payload.ComType}&Month=${payload.Month}&Year=${payload.Year}`;
    return this.http.get(url, { observe: 'response' });
}

}
