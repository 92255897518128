<kendo-dialog #dialogRef title="Upload Employee Details" (close)="closePopup()" [minWidth]="300" [height]="580"
    [width]="1200" class="custom-dialog">

    <div class="row colpadding0">
        <div class="row colpadding0" style="margin-top: 10px;">
            <div class="col-md-4 colpadding0">
                <h4 style=" font-weight: 500; font-size: 11px;">
                    <span style="color: red; ">
                        Note<sup>*</sup>:
                    </span> Please Check Excel Column Header To download Template
                    
                </h4>
            </div>
            <div class="col-md-8 bulk-btn colpadding0">
                
                <button class="figma-btn-blue" type="submit" style=" float: right; margin-right:-20px; " (click)="resetData()">
                    Reset
                </button>
                <button class="figma-btn-blue" type="submit" style=" float: right; " (click)="refreshData()">
                    Refresh
                </button>
                <button class="figma-btn-blue" (click)="downloadCSVTemplate()" style=" float: right; ">
                    <img src="../assets/vectors/DownloadWhite.svg" alt="">
                    Download Template
                </button>
            </div>
        </div>
        <div class="col-md-4 colpadding0">
            <kendo-grid class="custom-grid" [data]="gridData" [resizable]="true">
                <kendo-grid-column field="clientHeaderName" title="Header Name">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div [title]="dataItem.clientHeaderName" class="cell-content">
                            {{dataItem.clientHeaderName}}
                        </div>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column title="Select All" [width]="90">
                    <ng-template kendoGridHeaderTemplate>
                        <div class="custom-tooltip" style="margin-left: 21%;">
                            <input type="checkbox" [(ngModel)]="allSelected" (change)="toggleSelectAll($event)" />
                        </div>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="checkbox-container">
                            <input type="checkbox" [(ngModel)]="dataItem.selected" [disabled]="dataItem.disabled"
                                (change)="onItemSelectionChange(dataItem)" />
                        </div>
                    </ng-template>
                </kendo-grid-column>
            </kendo-grid>

        </div>
        <div class="col-md-8 colpadding0">
            <form [formGroup]="myGroup" class="form-tabel">
                <table class="table Upload-table table-border">
                    <thead>
                        <tr>
                            <th>Input</th>
                            <th>Upload File</th>
                            <th>Uploaded</th>
                            <th>Validation</th>
                            <th>Processed</th>
                            <th>History</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div class="col-12 collpadding0">
                                    <p style="padding-top: 6px;"> Employee Master<sup class="text-danger">*</sup></p>
                                </div>
                            </td>
                            <td>
                                <div class="col-12 collpadding0" style="justify-content: space-around;">
                                    <span *ngIf="!fileNames['BulkUpload']" style="color: #A0A0A0;">No file chosen</span>
                                    <span *ngIf="fileNames['BulkUpload']">
                                        {{fileNames['BulkUpload']}}
                                    </span>
                                    <input type="file" formControlName="BulkUpload" #BulkUpload
                                        (change)="onFileChange($event, 'BulkUpload')" id="fileInput"
                                        style="display: none;" />
                                    <button class="figma-btn-gray" (click)="BulkUpload.click()">Browse</button>
                                    <button class="figma-btn-blue" style="color: white;"
                                        (click)="uploadFile()">Upload</button>
                                </div>
                            </td>
                            <td>
                                <div class="col-12 collpadding0">
                                    <!-- <img src="../assets/vectors/sandclock.svg" *ngIf="DisplayUploadFlag == 1 && IsUploaded == -1" alt=""> -->
                                    <img class="greentick" src="../assets/vectors/GreenTick.svg" *ngIf="DisplayUploadFlag == 1 && IsUploaded == 1" alt="">
                                    <img src="../assets/vectors/DownloadOrange.svg" class="svg-icon-btn" (click)="downlaodError()" *ngIf="DisplayUploadFlag == 1 && IsUploaded == -2" alt="">
                                </div>
                            </td>
                            <td>
                                <div class="col-12 collpadding0">
                                    <img src="../assets/vectors/sandclock.svg" class="svg-icon-btn" *ngIf="DisplayUploadFlag == 1 && IsValidated == -1" alt="">
                                    <img class="greentick" src="../assets/vectors/GreenTick.svg" *ngIf="DisplayUploadFlag == 1 && IsValidated == 1" alt="">
                                    <img src="../assets/vectors/DownloadOrange.svg" class="svg-icon-btn" (click)="downlaodError()" *ngIf="DisplayUploadFlag == 1 && IsValidated == -2" alt="">
                                </div>
                            </td>
                            <td>
                                <div class="col-12 collpadding0">
                                    <img src="../assets/vectors/sandclock.svg" class="svg-icon-btn" *ngIf="DisplayUploadFlag == 1 && isProcessed == -1" alt="">
                                    <img class="greentick" src="../assets/vectors/GreenTick.svg" *ngIf="DisplayUploadFlag == 1 &&isProcessed == 1" alt="">
                                    <img src="../assets/vectors/DownloadOrange.svg" class="svg-icon-btn"(click)="downlaodError()" *ngIf="DisplayUploadFlag == 1 && isProcessed == -2" alt="">
                                </div>
                            </td>
                            <td>
                                <img (click)="openHistoryPopup()" src="../assets/vectors/History.svg" class="svg-icon-btn" alt="">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>

    </div>
    <div *ngIf="loading" class="loader-overlay">
        <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
    </div>
</kendo-dialog>
<app-employee-history-popup *ngIf="EmployeeHistoryPopup" (close)="closehistory()"></app-employee-history-popup>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270" [Data]="alertData"
    (close)="closeAlert()"></app-alert-popup>