<!-- <div class=" d-flex align-items-center" style="margin-top:-3px;">
    <form [formGroup]="invoiceForm"  class="d-flex align-items-center" style="margin-top:8px;">
        <div class="form-group">
            <label class="f-label" for="invoiceNumber" title="Invoice Number">
                Invoice Number
            </label>
            <input type="number" id="invoiceNumber"   [(ngModel)]="invoiceNumber" class="form-controls f-textbox" formControlName="invoiceNumber" style="margin-top:3px;">
        </div>
    
        <div style="margin-left: 25px;">
            <label class="f-label" title="Description">Description</label>
            <div class="require-container">
                <textarea class="form-control f-textbox" title="Description" [(ngModel)]="description" formControlName="description" style="width: 308%;margin-top:3px;"></textarea>
            </div>
        </div>
    
        <button type="reset" class="figma-btn-blue" style="margin-top: 12px; margin-left: 228px;" (click)="updateInvoiceDescription()">
            Update
        </button>
    </form>
    

</div> -->
<div class="d-flex align-items-center" style="margin-top:-3px;">
    <form [formGroup]="invoiceForm" class="d-flex align-items-center" style="margin-top:8px;" (ngSubmit)="updateInvoiceDescription()">
        <div class="form-group">
            <label class="f-label" for="invoiceNumber" title="Invoice Number">Invoice Number</label>
            <input 
                type="number" 
                id="invoiceNumber" 
                class="form-controls f-textbox" 
                formControlName="invoiceNumber"
                [ngClass]="{'is-invalid': invoiceForm.get('invoiceNumber')?.invalid && invoiceForm.get('invoiceNumber')?.touched}"
                style="margin-top:3px;"
                min="1">
           
        </div>
        

        <div style="margin-left: 25px;">
            <label class="f-label" title="Description">Description</label>
            <div class="require-container">
                <textarea class="form-control f-textbox" title="Description" [(ngModel)]="description" formControlName="description" style="width: 308%;margin-top:3px;"></textarea>
            </div>
        </div>

        <button 
            type="submit" 
            class="figma-btn-blue" 
            style="margin-top: 12px; margin-left: 228px;">
            Update
        </button>
    </form>
</div>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="365" [Height]="210" [Data]="alertData"
    (close)="closeAlert()"></app-alert-popup>