<div class="row colpadding0" style="margin-bottom: 15px; margin-top: 15px;"> 
    <div class="col-md-12 col-lg-12 colpadding0">
            <kendo-multiselecttree #multiselectTree placeholder="Entity/State/Location/Branch" kendoMultiSelectTreeExpandable [popupSettings]="popupSettings"
                [kendoMultiSelectTreeHierarchyBinding]="BranchDropdown" childrenField="children" textField="name"
                valueField="id" [tagMapper]="tagMapper" (valueChange)="OnMultiSelectChange($event)"
                [expandedKeys]="['0']" class="f-textbox multi-box" width="200px">
            </kendo-multiselecttree>
        <app-dropdown-list [data]="period" [selectedValue]="selectedPeriod" textField="text" valueField="value"
            (valueChange)="onPeriodChange($event)" formControlName="period" style="margin-left: 12px;" width="150px">
            <!-- [ngClass]="{'is-invalid': form.get('period')?.invalid && form.get('period')?.touched}"> -->
        </app-dropdown-list>
        <app-dropdown-list [data]="years" [selectedValue]="selectedYear" textField="text" valueField="value"
            (valueChange)="onYearChange($event)" formControlName="year" style="margin-left: 12px;" width="150px">
            <!-- [ngClass]="{'is-invalid': form.get('year')?.invalid && form.get('year')?.touched}"> -->
        </app-dropdown-list>
    </div>
    </div>
    <div class="row" *ngIf="selectedPeriod.value != 'Period' && selectedYear.value != 'Year'">
      <div class="col-lg-4 col-md-4">
        <kendo-chart [style]="{ height: '500px' }" title="Client Compliance Summary">
          <kendo-chart-legend position="bottom"></kendo-chart-legend>
          <kendo-chart-tooltip [background]="'white'">
            <ng-template kendoChartSeriesTooltipTemplate let-dataItem="dataItem">
              <strong>{{ dataItem.category }}</strong>: {{ dataItem.value }}
            </ng-template>
          </kendo-chart-tooltip>
          <kendo-chart-series>
              <kendo-chart-series-item
                  type="pie"
                  [data]="pieData"
                  field="value"
                  categoryField="category"
                  [labels]="{
                    visible: true,
                    position: 'center',
                    content: labelContentview,
                    background: 'transparent'
                  }"
              >
              </kendo-chart-series-item>
          </kendo-chart-series>
          <kendo-chart-series-defaults [overlay]="{ gradient: 'none' }"></kendo-chart-series-defaults> <!-- Remove shadow -->
      </kendo-chart>
      
      
      </div>
    <div class="col-lg-8 col-md-8">
          <kendo-chart [style]="{ height: '500px' }">
            <kendo-chart-title text="Location Compliance Summary"></kendo-chart-title>
          
            <kendo-chart-category-axis>
              <kendo-chart-category-axis-item [categories]="categories" [labels]="{ rotation: 'auto' }">
              </kendo-chart-category-axis-item>
            </kendo-chart-category-axis>
          
            <kendo-chart-series>
              <!-- Not Complied -->
              <kendo-chart-series-item
                type="column"
                [stack]="{ type: '100%' }"
                [data]="notCompliedData"
                color="red"
                name="Not Complied"
                [labels]="{
                  visible: true,
                  format: '{0}%',
                  position: 'center',
                  background: 'transparent'
                }">
                <kendo-chart-series-item-labels>
                    <ng-template kendoChartSeriesItemLabelsTemplate let-dataItem let-seriesItem="seriesItem">
                      <svg width="100" height="30">
                        <text x="50%" y="50%" text-anchor="middle" dominant-baseline="middle"
                              fill="white" stroke="black" stroke-width="1">
                          {{ dataItem }}%
                        </text>
                      </svg>
                    </ng-template>
                  </kendo-chart-series-item-labels>
              </kendo-chart-series-item>
          
              <!-- Complied -->
              <kendo-chart-series-item
                type="column"
                [stack]="{ type: '100%' }"
                [data]="compliedData"
                color="#72bf6a"
                name="Complied"
                [labels]="{
                  visible: true,
                  format: '{0}%',
                  position: 'center',
                  background: 'transparent'
            
                }">
                <kendo-chart-series-item-labels>
                    <ng-template kendoChartSeriesItemLabelsTemplate let-dataItem let-seriesItem="seriesItem">
                      <svg width="100" height="30">
                        <text x="50%" y="50%" text-anchor="middle" dominant-baseline="middle"
                              fill="white" stroke="black" stroke-width="1">
                          {{ dataItem }}%
                        </text>
                      </svg>
                    </ng-template>
                  </kendo-chart-series-item-labels>
              </kendo-chart-series-item>  
            </kendo-chart-series>
          
            <kendo-chart-legend position="bottom" orientation="horizontal" [class]="'custom-legend'"></kendo-chart-legend>
          
            <!-- Tooltip -->
            <kendo-chart-tooltip>
                <ng-template kendoChartSeriesTooltipTemplate let-category="category" let-series="series" let-dataItem="dataItem">
                  <strong>{{ category }}</strong><br />
                  <div>
                    <strong>{{ series.name }}: {{ dataItem }}%:</strong>
                  </div>
                </ng-template>
              </kendo-chart-tooltip>
              
          </kendo-chart>
      </div>
    </div>
    <app-alert-popup
  *ngIf="alertData?.alertVisible"
  [Width]="250"
  [Height]="280"
  [Data]="alertData"
  [id]="0"
  (confirm)="onPopupConfirmed($event)"
  (cancel)="onPopupCanceled()">
</app-alert-popup>