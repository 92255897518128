import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ReactiveFormsModule } from '@angular/forms';
import { KendoDatepickerComponent } from '../../../components/kendo-datepicker/kendo-datepicker.component';
import { AgreementDetailsService } from '../../../service/agreement-details.service';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { environment } from '../../../environments/environment';
import { UserService } from '../../../service/user.service';
import { BlobService } from '../../../service/blob.service';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-agreement-details-master',
  standalone: true,
  imports: [DropdownListComponent, NgIf, ComboBoxComponent, DateInputsModule, CommonModule, ReactiveFormsModule, KendoDatepickerComponent, AlertPopupComponent, IndicatorsModule],
  templateUrl: './agreement-details-master.component.html',
  styleUrls: ['./agreement-details-master.component.css'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class AgreementDetailsMasterComponent implements OnInit {
  agreementForm!: FormGroup;
  @Input() data: any;
  @Output() close = new EventEmitter<void>();
  @Input() value: Date | null = null;
  @Input() format: string = 'dd-MM-yyyy';
  @Input() width: string | null = null;
  @Input() max!: Date;
  @Input() hasError!: any;
  @Output() valueChange: EventEmitter<Date> = new EventEmitter<Date>();
  Status = { text: 'Agreement Types', value: '' };
  State = { text: 'Select', value: '' };
  funding: any[] = [];
  sla: any[] = [];
  address: string = '';
  SecurityDeposit: any[] = [];
  astatus: any[] = [];
  zones: any[] = [];
  uploadedSlaFile!: File;
  uploadedAgreementFile!: File;
  uploadedDocFile!: File;
  SpocName: string = '';
  Email!: string;
  Clause!: string;
  NoOfEntities!: number;
  NoOfStates!: number;
  NoOfBranches!: number;
  Deposit: string = '';
  DepositPer: string = '';
  SlaUpload!: File;
  Agreement!: File;
  ProcessDoc!: File;
  ProcessDocFilePath!:any;
  AgreementFilePath!:any;
  SlaUploadFilePath!:any;
  ContactNumber!: number;
  AgreementType: string = '';
  RenewalTrigger: string = '';
  public datePlaceholder: string = 'DD-MM-YYYY';
  selectedStartDate: Date | null = null;
  agreementStartDate: Date | null = null;
  selectedEndDate: Date | null = null;
  agreementEndDate: Date | null = null;
  selectedExeDate: Date | null = null;
  agreementExeDate: Date | null = null;
  selectedTermDate: Date | null = null;
  agreementTermDate: Date | null = null;
  agreement!: { text: any; value: any; };
  public dropdown: { id: number; name: string }[] = [];
  selectedAgreement: { text: any, value: any } = { text: 'Agreement Types', value: '' }
  selectedFunding: { text: any, value: any } = { text: 'Select..', value: '' }
  selectedSecurityDeposit: { text: any, value: any } = { text: 'Select..', value: '' }
  selectedSla: { text: any, value: any } = { text: 'Select..', value: '' }
  selectedAStatus: { text: any, value: any } = { text: 'Select..', value: '' }
  selectedZone: { text: any, value: any } = { text: 'Select..', value: '' }
  selectedDeposit: { text: any, value: any } = { text: 'Select..', value: '' }
  alertData = { alertMessage: '', alertVisible: '' };
  alertVisible = false;
  public defaultSelectedValue = 'Search/Select Designation';
  public dropdownData: any[] = [];
  showValidationErrors = false;
  selectedValue: { text: any, value: any } = { text: '', value: '' }
  defaultItem = { text: 'Select an option', value: null };
  loading = false;
  public signatureOpen: boolean = false;

  fileNames: { [key: string]: string | null } = {
    SlaUpload: null,
    Agreement: null,
    ProcessDoc: null
  };
  selectedFiles: { [key: string]: File | null } = {
    SlaUpload: null,
    Agreement: null,
    ProcessDoc: null
  };
  custID!: any;
  constructor(private fb: FormBuilder, private user: UserService, private AgreementDetailsService: AgreementDetailsService, private blobService: BlobService) {
    this.agreementForm = this.fb.group({
      SpocName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]],
      ContactNumber: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      Email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]],
      address: ['', [Validators.required]],
      CTCTemplate: new FormControl(''),
      SlaUpload: [null, [Validators.required]],
      Agreement: [null, [Validators.required]],
      ProcessDoc: [null, [Validators.required]],
      DepositPer: [null, [Validators.required]],
      NoOfEntities: [null, [Validators.required]],
      NoOfBranches: [null, [Validators.required]],
      NoOfStates: [null, [Validators.required]],
      file: [null],
      RenewalTrigger: [{ value: 0, disabled: true }, [Validators.required]]
    });
  }


  ngOnInit(): void {
    this.custID = this.user.getCustomerId();
    // this.custID = localStorage.getItem('CustomerId');
    this.initializeFunding();
    this.initializeSla();
    this.initializeSecurityDeposit();
    this.initializeStatus();
    this.initializeZone();
    this.getDropdownList();
    this.getSavedData();
  }
  initializeSecurityDeposit() {
    this.SecurityDeposit = [
      { text: 'Select..', value: "" },
      { text: "Yes", value: "yes" },
      { text: "No", value: "no" }
    ];
  }
  initializeStatus() {
    this.astatus = [
      { text: 'Select..', value: "" },
      { text: "Active", value: "1" },
      { text: "Expired", value: "0" },
      { text: "Terminated", value: "-1" }
    ];
  }
  initializeFunding() {
    this.funding = [
      { text: "Select..", value: "" },
      { text: "Yes", value: "yes" },
      { text: "No", value: "no" }
    ];
  }
  initializeSla() {
    this.sla = [
      { text: "Select..", value: "" },
      { text: "Yes", value: "True" },
      { text: "No", value: "False" }
    ];
  }
  initializeZone() {
    this.zones = [
      { text: "Select..", value: "" },
      { text: "North", value: "North" },
      { text: "South", value: "South" },
      { text: "East", value: "East" },
      { text: "West", value: "West" }
    ];
  }
  onFileChange(event: any, type: string) {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel'
      ];

      if (allowedTypes.includes(file.type)) {
        this.fileNames[type] = file.name;
        this.selectedFiles[type] = file;
        this.agreementForm.get(`${type}`)?.setValue(file.name);
      } else {
        this.fileNames[type] = null;
        this.selectedFiles[type] = null;
        this.alertData = { alertMessage: 'Please select a .xlsx file', alertVisible: 'error' };
      }
    } else {
      this.fileNames[type] = null;
      this.selectedFiles[type] = null;
    }

    console.log(this.fileNames);
  }
  downloadSlaFile(): void {
    // if (this.uploadedSlaFile) {

    //   const fileType = this.uploadedSlaFile.name.split('.').pop();

    //   if (fileType && fileType.toLowerCase() === 'xlsx') {
    //     console.log('File is valid and active');

    //   } else {

    //     this.alertData = {
    //       alertMessage: 'Invalid file type. Please upload an .xlsx file.',
    //       alertVisible: 'error'
    //     };
    //   }
    // } else {
    //   this.alertData = { alertMessage: 'File Not Found.', alertVisible: 'error' };
    // }
    if (this.agreementForm.value.SlaUpload) {
      this.loading = true;
      const apiUrl = environment.blobUrl;
      const blobLink = apiUrl + this.agreementForm.value.SlaUpload;
      const blobName = this.blobService.extractBlobName(blobLink);
      setTimeout(() => {
        this.blobService.getSasToken(blobName).subscribe(
          (tokenResponse: any) => {
            try {
              const sasToken = tokenResponse.result;
              this.blobService.downloadFile(blobName, sasToken);
              this.loading = false;
            } catch (error) {
              console.log(error)
              this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
              this.loading = false;
            }
          },
          (error) => {
            console.log(error)
            this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
            this.loading = false;
          }
        );
      }, 5000);
    } else {
      this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
      this.loading = false;
    }
  }
  downloadAgreementFile(): void {
    // if (this.uploadedAgreementFile) {

    //   const fileType = this.uploadedAgreementFile.name.split('.').pop();

    //   if (fileType && fileType.toLowerCase() === 'xlsx') {
    //     console.log('File is valid and active');

    //   } else {

    //     this.alertData = {
    //       alertMessage: 'Invalid file type. Please upload an .xlsx file.',
    //       alertVisible: 'error'
    //     };
    //   }
    // } else {
    //   this.alertData = { alertMessage: 'File Not Found.', alertVisible: 'error' };
    // }
    if (this.agreementForm.value.Agreement) {
      this.loading = true;
      const apiUrl = environment.blobUrl;
      const blobLink = apiUrl + this.agreementForm.value.Agreement;
      const blobName = this.blobService.extractBlobName(blobLink);
      setTimeout(() => {
        this.blobService.getSasToken(blobName).subscribe(
          (tokenResponse: any) => {
            try {
              const sasToken = tokenResponse.result;
              this.blobService.downloadFile(blobName, sasToken);
              this.loading = false;
            } catch (error) {
              console.log(error)
              this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
              this.loading = false;
            }
          },
          (error) => {
            console.log(error)
            this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
            this.loading = false;
          }
        );
      }, 5000);
    } else {
      this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
      this.loading = false;
    }

  }
  downloadDocFile(): void {
    // if (this.uploadedDocFile) {

    //   const fileType = this.uploadedDocFile.name.split('.').pop();

    //   if (fileType && fileType.toLowerCase() === 'xlsx') {
    //     console.log('File is valid and active');

    //   } else {

    //     this.alertData = {
    //       alertMessage: 'Invalid file type. Please upload an .xlsx file.',
    //       alertVisible: 'error'
    //     };
    //   }
    // } else {
    //   this.alertData = { alertMessage: 'File Not Found.', alertVisible: 'error' };
    // }
    if (this.agreementForm.value.ProcessDoc) {
      this.loading = true;
      const apiUrl = environment.blobUrl;
      const blobLink = apiUrl + this.agreementForm.value.ProcessDoc;
      const blobName = this.blobService.extractBlobName(blobLink);
      setTimeout(() => {
        this.blobService.getSasToken(blobName).subscribe(
          (tokenResponse: any) => {
            try {
              const sasToken = tokenResponse.result;
              this.blobService.downloadFile(blobName, sasToken);
              this.loading = false;
            } catch (error) {
              console.log(error)
              this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
              this.loading = false;
            }
          },
          (error) => {
            console.log(error)
            this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
            this.loading = false;
          }
        );
      }, 5000);
    } else {
      this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
      this.loading = false;
    }
  }

  onZoneChange(value: any) {
    this.selectedZone = { text: value.text, value: value.value }
  }
  OnEntitiesChange(value: any) {
    this.NoOfEntities != null
  }
  onAgreementChange(value: any) {
    this.selectedAgreement = { text: value.text, value: value.value }
  }
  onFundingChange(value: any) {
    this.selectedFunding = { text: value.text, value: value.value }
  }
  onSlaChange(value: any) {
    this.selectedSla = { text: value.text, value: value.value }
  }
  onSecurityDepositChange(value: any) {
    this.selectedSecurityDeposit = { text: value.text, value: value.value }
  }
  onAStatusChange(value: any) {
    this.selectedAStatus = { text: value.text, value: value.value }
  }
  onValueChange(value: Date): void {
    this.valueChange.emit(value);
  }
  onStartDateChange(value: Date) {
    const date = new Date(value.toISOString().split('T')[0]);
    this.selectedStartDate = date;
    this.showValidationErrors = true;
  }
  onEndDateChange(value: Date) {
    const date = new Date(value.toISOString().split('T')[0]);
    this.selectedEndDate = date;
    this.showValidationErrors = true;
  }
  onExeDateChange(value: Date) {
    const date = new Date(value.toISOString().split('T')[0]);
    this.selectedExeDate = date;
    this.showValidationErrors = true;
  }
  onTermDateChange(value: Date) {
    const date = new Date(value.toISOString().split('T')[0]);
    this.selectedTermDate = date;
    this.showValidationErrors = true;
  }

  getDropdownList() {
    this.AgreementDetailsService.getDropdownOptions().subscribe({
      next: (response) => {
        debugger;
        const data = response.body;
        console.log(data);

        if (data && data && Array.isArray(data)) {
          this.dropdownData = [
            ...data.map((item: any) => ({
              value: item.key,
              text: item.value
            }))]
        }
      },
      error: (err) => {
        console.error('Error fetching Dispencery data List', err);
        this.loading = false;
      }
    }
    );

  }
  validateMobileNo(event: any): void {
    const input = event.target.value;
    event.target.value = input.replace(/[^0-9]/g, '');
    this.agreementForm.get('ContactNumber')?.setValue(event.target.value);
  }
  closePopup(): void {
    this.data;
    this.close.emit();
  }
  initData(value: any) {
    this.agreementForm = this.fb.group({
      Clause: new FormControl(''),
      RenewalTrigger: new FormControl({ value: value.renewalTrigger, disabled: true }, [Validators.required]),
      SpocName: [value.aggreementClientSPOCName || '', [Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]],
      ContactNumber: [value.aggreementClientMobileNo || '', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      Email: [value.aggreementClientEmailId || '', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]],
      address: [value.clientRegisteredOfficeForTDSExemptionAddress || '', [Validators.required]],

      SlaUpload: [value.slauploadfile ? value.slauploadfile : null, value.slauploadfile ? [] : [Validators.required]],
      Agreement: [value.agreementcopyuploadfile || null, [Validators.required]],
      ProcessDoc: [value.files_Processfile || null, [Validators.required]],

      DepositPer: [value.advanceSecurityDepositePercentage || null, [Validators.required]],
      NoOfEntities: [value.noOfEntities || null, [Validators.required]],
      NoOfBranches: [value.noOfBranches || null, [Validators.required]],
      NoOfStates: [value.noOfStates || null, [Validators.required]],
    });
 
    this.ProcessDocFilePath=value?.processDocumentPath
    this.AgreementFilePath=value?.agreementCopyUploadPath;
    this.SlaUploadFilePath=value?.slaUploadPath;
    this.selectedAgreement = { text: '', value: value.agreementType || '' };
    this.selectedZone = { text: '', value: value.serviceZone || '' };
    this.selectedSla = { text: '', value: value.sla || '' };

    this.selectedFunding = { text: '', value: value.funding ? 'yes' : 'no' };
    this.selectedSecurityDeposit = { text: '', value: value.advanceSecurityDeposite == 'Yes' ? 'yes' : 'no' };
    this.selectedStartDate = value.agreementEffectiveDate ? new Date(value.agreementEffectiveDate) : null;
    this.selectedEndDate = value.agreementEndDate ? new Date(value.agreementEndDate) : null;
    this.selectedExeDate = value.agreementExecutionDate ? new Date(value.agreementExecutionDate) : null;
    this.selectedTermDate = value.agreementTerminationDate ? new Date(value.agreementTerminationDate) : null;
    this.selectedAStatus = { text: '', value: value.aggreementStatus || '' };
    this.RenewalTrigger = value.renewalTrigger;
    this.SlaUpload = value.slaUploadPath;
    this.Clause = value.indemnityClause;
    this.agreementForm.markAllAsTouched()
  }
  getSavedData() {
    debugger;
    const customerId = Number(this.custID);
    if (!isNaN(customerId)) {
      this.AgreementDetailsService.getPreviousSavedData(customerId).subscribe({
        next: (response) => {
          debugger;
          const data = response.body;
          console.log('init data', data);
          if (data) {
            this.initData(data);
          }

        },
        error: (err) => {
          console.error('Error fetching Dispencery data List', err);
          this.loading = false;
        }
      }
      );
    }
    else {
      console.error('Invalid customer ID');
    }

  }
  formatDateForSave(dateString: string | null): string | null {
    if (!dateString || dateString.trim() === '') {
      return null;
    }

    const dateParts = dateString.split('/');

    if (dateParts.length === 3 && dateParts.every(part => !isNaN(Number(part)))) {
      const day = dateParts[0];
      const month = dateParts[1];
      const year = dateParts[2];

      const date = new Date(`${year}-${month}-${day}T00:00:00`);

      if (!isNaN(date.getTime())) {
        return this.convertToISOFormat(date);
      } else {
        return null;
      }
    } else {
      const date = new Date(dateString);

      if (!isNaN(date.getTime())) {
        return this.convertToISOFormat(date);
      } else {
        return null;
      }
    }
  }
  convertToISOFormat(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }
  OnSave(): void {
    debugger;
    this.showValidationErrors = true;
    const body = {
      CustomerId: this.custID || '',
      AgreementType: this.selectedAgreement.value || '',
      AgreementEffectiveDate: this.selectedStartDate ? this.formatDateForSave(this.selectedStartDate.toISOString()) : '',
      AgreementExecutionDate: this.selectedExeDate ? this.formatDateForSave(this.selectedExeDate.toISOString()) : '',
      AgreementEndDate: this.selectedEndDate ? this.formatDateForSave(this.selectedEndDate.toISOString()) : '',
      AgreementTerminationDate: this.selectedTermDate ? this.formatDateForSave(this.selectedTermDate.toISOString()) : '',
      RenewalTrigger: this.RenewalTrigger || '',
      Sla: false,
      Funding: false,
      AdvanceSecurityDeposite: false,
      ServiceZone: this.selectedZone.value || '',
      IndemnityClause: this.Clause || 'NA',
      AggreementStatus: this.selectedAStatus.value || '',

      slauploadfile: this.selectedFiles['SlaUpload'] || '',
      agreementcopyuploadfile: this.selectedFiles['Agreement'] || '',
      files_Processfile: this.selectedFiles['ProcessDoc'] || '',

      AggreementClientSPOCName: this.agreementForm.value.SpocName || '',
      AggreementClientMobileNo: this.agreementForm.value.ContactNumber || '',
      AggreementClientEmailId: this.agreementForm.value.Email || '',
      ClientRegisteredOfficeForTDSExemptionAddress: this.agreementForm.value.address || '',
      NoOfBranches: this.agreementForm.value.NoOfBranches || 0,
      NoOfEntities: this.agreementForm.value.NoOfEntities || 0,
      NoOfStates: this.agreementForm.value.NoOfStates || 0,

      AdvanceSecurityDepositePercentage: this.agreementForm.value.DepositPer || 0,

      ProcessDocumentPaths: this.fileNames['ProcessDoc']?.toString(),
      SLAUploadPath: this.fileNames['SlaUpload']?.toString(),
      AgreementCopyUploadPath: this.fileNames['Agreement']?.toString(),
    }
    console.log(body);

    if (this.agreementForm.valid && this.selectedAgreement.value && this.selectedZone.value && this.selectedStartDate
      && this.selectedEndDate && this.selectedExeDate && this.selectedTermDate && this.selectedSla.value && this.selectedFunding.value
      && this.selectedSecurityDeposit.value && this.selectedAStatus.value
    ) {
      this.AgreementDetailsService.saveAgreementDetails(body).subscribe({
        next: (response) => {
          console.log(response)
          this.alertData = { alertMessage: 'Details Saved Successfully', alertVisible: 'success' };
          this.closePopup();
        },
        error: (error) => {
          console.log(error)
          this.alertData = { alertMessage: 'Error while saving details', alertVisible: 'error' };
        }
      });
    } else {
      this.agreementForm.markAllAsTouched();
      const invalidControls = Object.keys(this.agreementForm.controls).filter(
        controlName => this.agreementForm.controls[controlName].invalid
      );

      console.log('Invalid form controls:', invalidControls);

      invalidControls.forEach(controlName => {
        const controlErrors = this.agreementForm.controls[controlName].errors;
        console.log(`Control: ${controlName}, Errors:`, controlErrors);
      });
    }
  }
  closeAlert() {
    this.alertData = { alertMessage: '', alertVisible: '' };
  }
}


