<!-- <div class="row colpadding0">
    <div class="col-md-12 colpadding0 toolbar" style="margin-top: 10px;">
        <div class="col-md-8 colpadding0">
            <app-combo-box [width]="'300px'" [Placeholder]="defaultSelectedValue" 
            (filterChange)="FilterDropdown($event)" 
           (valueChange)="DropdownGridChange($event)"
            [data]="dropdown">
           </app-combo-box>
        </div>
        
        <div class="col-md-4 colpadding0 ">
            <div style=" float: right;">
                <button (click)="exportToExcel()" class="exportbtn">
                    <img src="../assets/vectors/ExportButton.svg" alt="">
                </button>
                <button class="figma-btn-blue" (click)="openAddNewPopup()" type="submit">
                    <img src="../assets/vectors/Plus.svg" alt="">Add New
                </button>
                <input type="hidden" [(ngModel)]="tokenCookie" />
            </div>
        </div>
    </div>
</div>

<div class="row colpadding0" style="margin-top: 10px">
    <div class="col-md-12 colpadding0">
        <app-grid-action-item  [gridData]="gridData" [columns]="parentColumns" [pageSize]="10" [pageable]="true"
            [filterable]="true" (buttonClick)="handleButtonClick($event)"></app-grid-action-item>
    </div>
</div>
<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>

<div *ngIf="isAddNewVisible" class="popup-overlay">
    <app-sub-code-master-add-edit-popup [data]="rowData" [IsUpdate]="IsUpdate" (close)="handleClosePopup()"></app-sub-code-master-add-edit-popup>
</div> -->

<div class="row colpadding0">
    <div class="col-md-12 colpadding0 toolbar" style="margin-top: 10px;">
        <div class="col-md-3 colpadding0">
            <app-combo-box 
                [width]="'100%'" 
                [Placeholder]="defaultSelectedValue" 
                (filterChange)="FilterDropdown($event)" 
                (valueChange)="DropdownGridChange($event)"
                [data]="dropdown">
            </app-combo-box>
        </div>
        <div  class="col-md-4 colpadding0">

        </div>
        <div class="col-md-4 colpadding0 ">
            <div  class="button-container" style="float: right; ">
                <button class="figma-btn-blue" (click)="openAddNewPopup()" type="submit">
                    <img src="../assets/vectors/Plus.svg" alt="">Add New
                </button>
                <button (click)="exportToExcel()" class="exportbtn">
                    <img src="../assets/vectors/ExportButton.svg" alt="">
                </button>
                <input type="hidden" [(ngModel)]="tokenCookie" />
            </div>
        </div>
    </div>
</div>

<div class="row colpadding0" style="margin-top: 10px">
    <div class="col-md-12 colpadding0">
        <app-grid-action-item 
            [gridData]="gridData" 
            [columns]="parentColumns" 
            [pageSize]="10" 
            [pageable]="true"
            [filterable]="true" 
            (buttonClick)="handleButtonClick($event)">
        </app-grid-action-item>
    </div>
</div>

<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>

<div *ngIf="isAddNewVisible" class="popup-overlay">
    <app-sub-code-master-add-edit-popup 
        [data]="rowData" 
        [IsUpdate]="IsUpdate" 
        (close)="handleClosePopup()">
    </app-sub-code-master-add-edit-popup>
</div>
