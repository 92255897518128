import { Component, ElementRef, EventEmitter, Input, Output, Type, ViewChild } from '@angular/core';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { NgIf } from '@angular/common';
import { FormGroup, FormsModule, ReactiveFormsModule, FormControl, Validators } from '@angular/forms';
import { ReviewRegulatoryAbstractsService } from '../../../service/review-regulatory-abstracts.service';
import { UserService } from '../../../service/user.service';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-review-regulatory-abstract-upload-popup',
  standalone: true,
  imports: [DialogModule, DropdownListComponent, NgIf, FormsModule, AlertPopupComponent, ReactiveFormsModule,IndicatorsModule],
  templateUrl: './review-regulatory-abstract-upload-popup.component.html',
  styleUrl: './review-regulatory-abstract-upload-popup.component.css'
})
export class ReviewRegulatoryAbstractUploadPopupComponent {
  abstractForm: FormGroup;
  @Input() data: any;
  @Input() ActId: string = '';
  @Output() close = new EventEmitter<void>();
  @ViewChild('courierInput') courierInput!: ElementRef;
  dropdownDataPrimary: { text: string, value: string }[] = [];
  dropdownDataSecondary: { text: string, value: string }[] = [];
  BranchDropdownData: { text: string, value: string }[] = [];
  selectedState = { text: 'Select State', value: '' }
  selectedAct = { text: 'Select Act', value: '' }
  selectedBranch = { text: 'Select Branch', value: '' }
  selectedDisplayType = { text: 'Select Display Type', value: '' }
  selectedDispatchType = { text: 'Select Dispatch Type', value: '' }
  public alertData = { alertMessage: '', alertVisible: '' };
  DisplayDropdownData: any;
  DispatchDropdownData: any;
  showValidationError = false;
  showRadioError = false;
  showDispatchError=false;
  loading: boolean = false;
  uploadType: string = '';
  Abstract!: File;
  UserID!: number;
  EntityID!: string;
  Type!: string;
  StateId: any;
  isDisabled: boolean = false;

 fileNames: { [key: string]: string | null } = {
    Abstract: null,
  };
  selectedFiles: { [key: string]: File | null } = {
    Abstract: null,
  };
 
  constructor(private reviewRegulatoryAbstractsService: ReviewRegulatoryAbstractsService, private user: UserService) {
    this.abstractForm = new FormGroup({
      Courier: new FormControl('', [Validators.pattern(/^[a-zA-Z0-9]*$/)]),
    });
  }

  ngOnInit(): void {
    this.getDropdownList();
    this.getDisplayTypeList();
    this.getDispatchTypeList();
    this.selectedState = { text: 'Select State', value: '' };
    this.selectedAct = { text: 'Select Act', value: '' };
    this.selectedBranch = { text: 'Select Branch', value: '' };
    this.selectedDisplayType = { text: 'Select Display Type', value: '' };
    this.selectedDispatchType = { text: 'Select Dispatch Type', value: '' };
  }
  
  onDropdownValueChange(value: any, type: string) {
    //this.showValidationError = true;
    if (type === 'State') {
      this.selectedState = { text: value.text, value: value.value }
      this.StateId = value.value;
      console.log('state id', this.StateId);

      this.getActDropdownList();
      this.getBranchDropdownList();
    } else if (type == 'Act') {
      //this.showValidationError = true;
      this.selectedAct = { text: value.text, value: value.value }
    }
    else if (type == 'Branch') {
      this.selectedBranch = { text: value.text, value: value.value }
    }
    else if (type == 'DisplayType') {
      this.selectedDisplayType = { text: value.text, value: value.value }
    }
    else if (type == 'DispatchType') {
      if (this.uploadType === 'S') {

        this.selectedDispatchType = { text: 'Select Dispatch Type', value: '' };
      }
      else {
        this.selectedDispatchType = { text: value.text, value: value.value }
      }
    }
  }

  getDisplayTypeList() {
    const type = 'DisplayType'
    this.reviewRegulatoryAbstractsService.getDisplayTypeOptions(type).subscribe({
      next: (response) => {
        const data = response.body
        this.DisplayDropdownData = data.map((item: any) => ({
          text: item.text,
          value: item.value
        }))
      }
    })
  }

  getDispatchTypeList() {
    const type = 'DispatchType'
    this.reviewRegulatoryAbstractsService.getDisplayTypeOptions(type).subscribe({
      next: (response) => {
        const data = response.body
        this.DispatchDropdownData = data.map((item: any) => ({
          text: item.text,
          value: item.value
        }))
      }
    })
  }

  getBranchDropdownList() {
    debugger;
    const UserId = this.user.getUserId();
    const ClientId = this.user.getClientId();

    this.reviewRegulatoryAbstractsService.getBranchDropdownOptions(UserId, ClientId, this.StateId).subscribe({
      next: (response) => {
        debugger;
        const data = response.body;
        console.log(data);

        if (data && Array.isArray(data)) {
          this.BranchDropdownData = data.map(item => ({
            text: item.key,
            value: item.value
          }));
          console.log('branchid', this.BranchDropdownData);
        }
      },
      error: (err) => {
        console.error('Error fetching Dispencery data List', err);
      }
    });
  }

  getDropdownList() {
    debugger;
    const UserId = this.user.getUserId();
    const ClientId = this.user.getClientId();

    this.reviewRegulatoryAbstractsService.getDropdownOptions(ClientId, UserId, 0).subscribe({
      next: (response) => {
        debugger;
        const data = response.body;
        console.log(data);

        if (data && Array.isArray(data)) {
          this.dropdownDataPrimary = data.map(item => ({
            text: item.key,
            value: item.value
          }));
        }
      },
      error: (err) => {
        console.error('Error fetching Dispencery data List', err);
      }
    });
  }

  getActDropdownList() {
    debugger;
    const UserId = this.user.getUserId();
    const ClientId = this.user.getClientId();

    this.reviewRegulatoryAbstractsService.getActDropdownOptions().subscribe({
      next: (response) => {
        debugger;
        const data = response.body;

        if (data && Array.isArray(data)) {
          this.dropdownDataSecondary = data.map(item => ({
            text: item.value,
            value: item.key
          }));
        }
      },
      error: (err) => {
        console.error('Error fetching Dispencery data List', err);
      }
    });
  }

  OnSave() { 
    debugger;
    this.showDispatchError = true;
    this.showValidationError = true;
    this.showRadioError = true;
    if (!this.selectedState.value || !this.selectedAct.value || !this.selectedBranch.value || !this.selectedDisplayType.value || !this.selectedFiles['Abstract']) {
        return; 
    }
    const body = {
        _stateID: parseInt(this.selectedState.value) || 0,
        actid: parseInt(this.selectedAct.value) || 0,
        BranchID: parseInt(this.selectedBranch.value) || 0,
        displayType: this.selectedDisplayType.value || '',
        val: this.uploadType || '',
        userID: this.UserID || 0,
        file: this.selectedFiles['Abstract'] || '',
    };
    console.log('body', body); 

    if (this.uploadType === 'S') { 
        console.log('Preparing to save:', body);
        this.reviewRegulatoryAbstractsService.saveData(body).subscribe({
            next: (response) => {
                console.log('save response', response);
                if (response.body.result === "empty sheet") {
                    this.alertData = { alertMessage: 'Please upload file with some data.', alertVisible: 'error' };
                } else {
                    this.loading = true;
                    this.alertData = { alertMessage: 'Saved Successfully', alertVisible: 'success' };
                }
            },
            error: (error) => {
                console.log('error', error);
                this.alertData = { alertMessage: 'Error while saving details', alertVisible: 'error' };
            }
        });
    } else if (this.uploadType === 'H') { 
        if (!this.abstractForm.value.Courier || !this.selectedDispatchType.value) {
            this.alertData = { alertMessage: 'Enter Courier No and Dispatch type.', alertVisible: 'error' };
            return;  
        } 
        this.reviewRegulatoryAbstractsService.saveData(body).subscribe({
            next: (response) => {
                if (response.body.result === "empty sheet") {
                    this.alertData = { alertMessage: 'File is empty', alertVisible: 'error' };
                } else {
                    this.loading = true;
                    this.alertData = { alertMessage: 'Saved Successfully', alertVisible: 'success' };
                }
            },
            error: (error) => {
                console.log('error', error);
                this.alertData = { alertMessage: 'Error while saving details', alertVisible: 'error' };
            }
        });
    } else { 
        this.alertData = { alertMessage: 'Invalid document type.', alertVisible: 'error' };
    }
}
isNumberKey(event: KeyboardEvent): void {
  const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
  if (allowedKeys.includes(event.key) || /^[0-9]$/.test(event.key)) {
    return;
  } else {
    event.preventDefault();
  }
}
  onFileChange(event: any, type: string) {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = [
        'image/png',
        'image/jpeg',
        'image/jpg',
        'application/pdf',
        'text/plain'
      ];

      if (allowedTypes.includes(file.type)) {
        this.fileNames[type] = file.name;
        this.selectedFiles[type] = file;
      } else {
        this.fileNames[type] = null;
        this.selectedFiles[type] = null;
        this.alertData = { alertMessage: 'Please select png/jpg/jpeg/pdf/txt file', alertVisible: 'error' };
      }
    } else {
      this.fileNames[type] = null;
      this.selectedFiles[type] = null;
    }

  }
  onRadioChange(value: string) {
    this.uploadType = value;
    console.log('Current uploadType:', this.uploadType); 
    const courierControl = this.abstractForm.get('Courier');
    if (courierControl) {
      if (value === 'S') {
        courierControl.reset(); 
        courierControl.disable();
        this.selectedDispatchType = { text: 'Select Dispatch Type', value: '' };        
       
        this.isDisabled = true;
      }
      else if(value === 'H')
        {
          courierControl.enable();
          this.isDisabled = false;
        } 
      else {
        this.isDisabled = false;
      }
    } else {
      console.error("Form control 'Courier' not found");
    }
  }
  public closePopup(): void {
    this.close.emit();
  }
  cloasAlert() {
    this.alertData = { alertMessage: '', alertVisible: '' };

  }
}
