<div class="row colpadding0">
    <div class="col-md-12 colpadding0 toolbar" style="margin-top: 10px;">
        <div class="col-md-8 colpadding0" style=" justify-content: space-between; ">
                <app-combo-box [width]="'350px'" [Placeholder]="defaultSelectedValue" 
                (filterChange)="FilterDropdown($event)" 
               (valueChange)="DropdownGridChange($event)"
                [data]="dropdown"
                [selectedValue]="DropdownDesignation"
                (keydown)="preventInvalidInput($event)">
               </app-combo-box>
            <app-dropdown-list [data]="dropdownData" [selectedValue]="defaultFilterValue" [width]="'130px'"
                textField="text" valueField="value" (valueChange)="onDropdownValueChange($event)"
                style="margin-right: 0px; margin-left: 9px;">
            </app-dropdown-list>
            <div style="display: inline">
                <button class="figma-btn-white" (click)="applyFilter()" type="submit">
                    <img src="../assets/vectors/ApplyFilter.svg" alt="">
                </button>
                <button (click)="clearFilter()" class="figma-btn-white">
                    <img src="../assets/vectors/ClearFilter.svg" alt="">
                </button>
            </div>
        </div>
        <div class="col-md-4 colpadding0 btn">
            <button (click)="exportToExcel()" class="exportbtn">
                <img src="../assets/vectors/ExportButton.svg" alt="">
            </button>
            <button class="figma-btn-blue" (click)="OpenDesignationPopup()" type="submit">
                <img src="../assets/vectors/Plus.svg" alt="">Add New
            </button>
            <button kendoButton class="figma-btn-white"   (click)="openUploadPopup()">
                <img src="../assets/vectors/upload.svg" alt="" title="Upload Bulk">
            </button>
        </div>
    </div>
</div>


    <kendo-grid class="custom-grid" style="margin-top: 10px;" [data]="gridData" [columnMenu]="true" [pageSize]="pageSize" [pageable]="true"
    (pageChange)="pageChange($event)" [skip]="skip">
    <kendo-grid-column field="long_designation" title="Designation">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div [title]="dataItem.long_designation" class="cell-content">
              {{dataItem.long_designation}}
            </div>
          </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="status" title="Status">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div [title]="dataItem.status" class="cell-content">
              {{dataItem.status}}
            </div>
          </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="action" title="Action" [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div style="display: flex;
            justify-content: center;
            align-items: center;">
                <button kendoButton class="icon-item" (click)="UpdateNew(dataItem)" 
                style="border: 0; background: none; ">
                <img src="../assets/vectors/edit.svg" alt="Save Icon" class="save-btn svg-icon"
               />
            </button> 
            <button kendoButton
                style="border: 0; background: none; " class="icon-item">
                <img src="../assets/vectors/delete.svg" alt="Delete Icon" class="save-btn svg-icon"
                (click)="confirmAlert(dataItem)" />
            </button>   
            </div>
              
        </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-input></kendo-pager-input>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>
</kendo-grid>




<div kendoDialogContainer *ngIf="uploadPopupOpen">
    <app-designation-bulk-upload (close)="handleclosePopup()"></app-designation-bulk-upload>
</div>

<div kendoDialogContainer *ngIf="AddUpdatePopupOpen">
    <app-designation-master-add-update [data]="popupData" [defaultSelectedValue]="defaultpopupValue"
        (close)="handleclosePopup()"></app-designation-master-add-update>
</div>


<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
  </div>
  
  <app-alert-popup *ngIf="alertData.alertVisible" [Width]="270"  [id]="deleteId" [Data]="alertData"
    (close)="closeAlert()" (confirm)="DeleteMWMaster($event)"></app-alert-popup>
