<form [formGroup]="form">   
<kendo-dialog #dialogRef title="Add New Mapping" (close)="closePopup()" [minWidth]="500" [width]="500" [height]="330" class="custom-dialog">
    <div class="dialog-content" >
        <div>
            <ul class="nav nav-tabs">
                <li style="display: flex; align-items: center;">
                    <h4 class="f-label"><input id="rdApplication" value="A" type="radio" class="custom-radio" name="measurementSystem"
                            (change)="onRadioChange('Application')" [checked]="selectionType === 'Application'" />
                        Application</h4>
                </li>
                <li style="display: flex; align-items: center;">
                    <h4 class="f-label"><input id="rdIndustry" value="M" type="radio" class="custom-radio" name="measurementSystem"
                            (change)="onRadioChange('Management')" [checked]="selectionType === 'Management'" />
                        Management</h4>
                </li>
            </ul>
        </div>    
        <div class="row Container">
            <div class="col-md-12 d-flex align-items-center" style="margin-top:15px;">
                <label class="f-label me-3">Act<sub class="text-danger"> *</sub></label>
                <div class="require-container">
                    <app-combo-box [width]="'250px'" [Placeholder]="defaultAct" [data]="Acts" [selectedValue]="selectedAct"
                        (valueChange)="DropdownActChange($event)" textField="name" valueField="id"
                        [class.input-invalid]="(!selectedAct || selectedAct.id)=== 'null' &&showValidationErrors ">
                    </app-combo-box>
                </div>
                <div *ngIf="(!selectedAct || selectedAct.id)=== 'null' &&showValidationErrors " class="validation-error">
    
                </div>
            </div>
        </div>
        <div class="row Container">
            <div class="col-md-12 d-flex align-items-center">
                <label class="f-label me-3 align-middle">Entity/Branch<sub class="text-danger">*</sub></label>
                <div class="require-container flex-grow-1">
                    <kendo-multiselecttree 
                        #multiselectTree 
                        placeholder="Select Branch" 
                        kendoMultiSelectTreeExpandable [value]="selectedBranches" formControlName="branches"
                        [kendoMultiSelectTreeHierarchyBinding]="selectedAct?.id === 'CLRA' ? clraBranchDropdown : BranchDropdown"
                        childrenField="children" 
                        textField="name" 
                        valueField="id"
                        [tagMapper]="selectedAct?.id === 'CLRA' ? tagMapper : clraTagMapper"
                        (valueChange)="OnMultiSelectChange($event)" 
                        [expandedKeys]="['0']"
                        class="f-textbox multi-box" 
                        [popupSettings]="popupSettings"
                        [ngClass]="{'is-invalid': form.get('branches')?.invalid && (form.get('branches')?.touched)}">
                    </kendo-multiselecttree>
                    
                  
                    <div *ngIf="(!commaSeparatedIds || commaSeparatedIds === '') && showValidationErrors" class="validation-error">
                   
                    </div>
                </div>
            </div>
        </div>
        <div class="row Container">
            <div class="col-md-12 d-flex align-items-center">
                <label class="f-label me-3">User<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <app-combo-box [width]="'250px'" [data]="allUser" [Placeholder]="defaultUser"
                        [selectedValue]="selectedUser" (filterChange)="loadUser()"
                        (valueChange)="DropdownUserChange($event)" textField="name" valueField="id"
                        [class.input-invalid]="!selectedUser && showValidationErrors">
                        <div *ngIf="!selectedUser && showValidationErrors" class="validation-error">
    
                        </div>
                    </app-combo-box>
                </div>
            </div>
        </div>
    </div>
    
    
    <button  class="figma-btn-blue figma-popup custom-button"(click)="saveUserMapping()" >Save</button>
</kendo-dialog>
</form>

<app-alert-popup *ngIf="alertData" [Width]="270" [Data]="alertData" (close)="closePopup()"></app-alert-popup>
