import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClraAssignmentReportService {
  private baseUrl=environment.apiUrl;
  constructor(private http:HttpClient) { }

  getExcelFile(UserId:number,ProfileId:string,CustId:number,ClientId:string,DistId:number,SpId:number,RoleCode:string,CType:string,Branch:string): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/LC_Reports/GetCLRAComplianceAssignment_ExcelToExport?userID=${UserId}&profileID=${ProfileId}&custID=${CustId}&clientID=${ClientId}&distID=${DistId}&spID=${SpId}&roleCode=${RoleCode}&ctype=${CType}&Branch=${Branch}`;
    return this.http.get<any>(url, { observe: 'response' });
  }

  getComplianceTypeOptions(type:string): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/LC_Reports/Get_LC_CodeDescriptionCLRA?Type=${type}`;
    return this.http.get<any>(url,{ observe: 'response' });
  }

  getGridData(UserId:number,ProfileId:string,CustId:number,ClientId:string,DistId:number,SpId:number,RoleCode:string,Ctype:string,Branch:string,Take:number,Skip:number,Page:number,PageSize:number): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/LC_Reports/GetCLRAComplianceAssignmentNew?userID=${UserId}&profileID=${ProfileId}&custID=${CustId}&clientID=${ClientId}&distID=${DistId}&spID=${SpId}&roleCode=${RoleCode}&ctype=${Ctype}&Branch=${Branch}&take=${Take}&skip=${Skip}&page=${Page}&pageSize=${PageSize}`;
    return this.http.get<any>(url, { observe: 'response' });
  }
}
