<kendo-dialog #dialogRef [title]="LabourAddressService.header" (close)="closePopup()" [minWidth]="300" [width]="660"
    class="custom-dialog">
    <div class="row LabourContainer">
        <div class="col-md-12 d-flex align-items-center">
            <label class="f-label me-3">State<sub class="text-danger">*</sub></label>
            <div class="require-container">
                <app-dropdown-list [data]="StateDropdownData" [selectedValue]="selectedState"
                    (valueChange)="onDropdownValueChange($event,'State')"
                    [hasError]="!selectedState.value && showErrorValidation" textField="text" valueField="value"
                    [width]="'250px'"   [isDisabled]="isStateDropdownDisabled">
                </app-dropdown-list>
            </div>
        </div>
    </div>
    <div class="row LabourContainer">
        <div class="col-md-12 d-flex align-items-center">
            <label class="f-label me-3">Address Type<sub class="text-danger">*</sub></label>
            <div class="require-container">
                <app-dropdown-list [data]="AddressTypeDropdownData" [selectedValue]="selectedAddressType"
                    (valueChange)="onDropdownValueChange($event,'AddressType')" 
                    [hasError]="!selectedAddressType.value && showErrorValidation" textField="text" valueField="value"
                    [width]="'250px'"  [isDisabled]="isStateDropdownDisabled">
                </app-dropdown-list>
            </div>
        </div>
    </div>
    <div class="row LabourContainer">
        <div class="col-md-12 d-flex align-items-center">
            <label class="f-label me-3">Unique Number<sub class="text-danger">*</sub></label>
            <div class="require-container">
                <input type="text" class="f-textbox" disabled="UniqueID" placeholder="Unique Number"
                    [(ngModel)]="UniqueID" [ngClass]="{'is-invalid': !UniqueID && showErrorValidation}" />
                <button type="button" (click)="GenerateUniqueID()" class="figma-btn-blue " *ngIf="!Data">
                    Generate Unique No.
                </button>
            </div>
        </div>
    </div>
    <div class="row LabourContainer">
        <div >
            <label class="f-label me-3">Address<sub class="text-danger">*</sub></label>
            <div>
                <textarea placeholder="Address" class="text-area" name="" id="" [(ngModel)]="address"
                rows="3" [ngClass]="{'is-invalid': !address && showErrorValidation}"  (keydown)="preventLeadingSpace($event)" ></textarea>
                <!-- <textarea placeholder="Address" class="f-textbox address-textarea" name="" id="" [(ngModel)]="address" rows="2" (input)="adjustHeight()"></textarea> -->
            </div>
        </div>
    </div>


    <kendo-dialog-actions class="ksave">
        <button kendoButton type="submit" (click)="onSave()" class="figma-btn-blue f-popup">
            Save
        </button>
    </kendo-dialog-actions>
    <div *ngIf="loading" class="loader-overlay">
        <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
    </div>
</kendo-dialog>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270" [Data]="alertData"
    (close)="closeAlert()"></app-alert-popup>