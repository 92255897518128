import { Component, ElementRef, ViewChild } from '@angular/core';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { MultiSelectTreeComponent } from '../../../components/multi-select-tree/multi-select-tree.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { NgIf } from '@angular/common';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { GridDataResult, GridModule, PageChangeEvent } from '@progress/kendo-angular-grid';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { ComplianceAssignmentReportService } from '../../../service/compliance-assignment-report.service';
import { UserService } from '../../../service/user.service';
import { ManageComplianceService } from '../../../service/manage-compliance.service';
import { UserBranchMappingService } from '../../../service/user-branch-mapping.service';
import { BlobService } from '../../../service/blob.service';

type Branch = {
  id: number;
  name: string;
  parentID: number | null;
  branchType: string;
  branchStatus: string;
  fullName: string | null;
  children?: Branch[];
};

@Component({
  selector: 'app-compliance-assignment-report',
  standalone: true,
  imports: [MultiSelectTreeComponent, DropDownsModule, GridModule, DropdownListComponent, NgIf, GridActionItemComponent, IndicatorsModule],
  templateUrl: './compliance-assignment-report.component.html',
  styleUrl: './compliance-assignment-report.component.css'
})

export class ComplianceAssignmentReportComponent {
  @ViewChild(MultiSelectTreeComponent) multiSelectTree!: MultiSelectTreeComponent;
  public showGrid: boolean = false;
  showValidationError = false;
  loading: boolean = false;
  public roleId: number = 0;
  public buttonCount = 2;
  public CType: string = '';
  branchId: number = 0;
  Take = 10;
  Skip = 0;
  Page = 1;
  SpId: number = 0;
  DistId: number = 0;
  compType: string = '';
  DisplayDropdownData: any;
  public PageSize: number = 10;
  public parentColumns: any[] = [];
  public selectedBranches: any[] = [];
  public BranchDropdown: Branch[] = [];
  selectedBranch: Branch | null = null;
  public sizes = [{ text: 'All', value: 1 }, 5, 10, 20];
  public gridData: GridDataResult = { data: [], total: 0 };
  public alertData = { alertMessage: '', alertVisible: '' };
  selectedComplianceType = { text: 'Select Compliance Type', value: '' };

  constructor(private complianceAssignmentReportService: ComplianceAssignmentReportService, private user: UserService, private manageComplianceService: ManageComplianceService, private userBranch: UserBranchMappingService, private blobService: BlobService) { }

  ngOnInit(): void {
    this.showGrid = true;
    this.getComplianceDropdownList();
    this.getBranchList();
  }

  onBranchChange(selectedBranches: any[]): void {
    this.selectedBranches = selectedBranches;
    console.log('Selected Branches:', this.selectedBranches);
  }

  public pageChange(event: PageChangeEvent): void {
    console.log('Page Change Event Received:', event);
    this.Skip = event.skip;
    if (event.take == 1) {
      this.PageSize = this.gridData.total;
    } else {
      this.PageSize = event.take;
    }
    this.applyFilter();
  }

  getBranchList() {
    debugger;
    const entityId = this.user.getClientId();
    this.userBranch.getBranchDropdown(entityId).subscribe({
      next: (response) => {
        console.log('branch dropdown data', response);
        const data = response.body;
        if (data && Array.isArray(data)) {
          this.BranchDropdown = this.buildHierarchy(data);
        } else {
          console.error('Unexpected data format', data);
        }
      }
    })
  }

  public tagMapper(tags: any[]): any[] {
    return tags.length < 1 ? tags : [tags];
  }

  buildHierarchy(data: Branch[]): Branch[] {
    const grouped: { [key: number]: Branch[] } = data.reduce((acc, item) => {
      (acc[item.parentID || -1] = acc[item.parentID || -1] || []).push(item);
      return acc;
    }, {} as { [key: number]: Branch[] });

    function buildTree(parentID: number | null): Branch[] {
      return (grouped[parentID || -1] || []).map(item => ({
        ...item,
        children: buildTree(item.id)
      }));
    }
    return buildTree(null);
  }

  onDropdownValueChange(event: any) {
    console.log(event);
    this.selectedComplianceType = { text: event.text, value: event.value };
    console.log('compliance type', this.selectedComplianceType);
  }

  getComplianceDropdownList() {
    const type = 'ComplianceName'
    this.complianceAssignmentReportService.getComplianceTypeOptions(type).subscribe({
      next: (response) => {
        const data = response.body
        this.DisplayDropdownData = data.map((item: any) => ({
          text: item.text,
          value: item.value
        }))
      }
    })
  }

  exportToExcel(): void {
    this.loading = true;
    const UserId = this.user.getUserId();
    const ProfileId = this.user.getUserId().toString();
    const CustId = this.user.getCustomerId();
    const ClientId = this.user.getClientId();
    const RoleCode = this.user.getRole();
    this.CType = this.compType;

    this.complianceAssignmentReportService.getDesignationExcel(UserId, ProfileId, CustId, ClientId, this.DistId, this.SpId, RoleCode, this.CType, '').subscribe({
      next: (response: any) => {
        console.log('response', response);

        if (response.status === 200) {
          const data = response.body
          const blobLink = data.filePath;
          const blobName = this.blobService.extractBlobName(blobLink);
          setTimeout(() => {
            this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
              console.log('tokenResponse', tokenResponse);

              debugger;
              const sasToken = tokenResponse.result;
              this.blobService.downloadFile(blobName, sasToken);
              this.loading = false;
            });
          }, 5000);
        }
        else {
          console.error('Error fetching blob link:', response.body.error);
          this.loading = false;
          this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
        }
      }
    })
  }

  getGridData(value: string, filter: string) {
    this.showGrid = true;
    this.filterGridDataByChallan();
    const UserId = this.user.getUserId();
    const ProfileId = this.user.getUserId().toString();
    const CustId = this.user.getCustomerId();
    const ClientId = this.user.getClientId();
    const RoleCode = this.user.getRole();
    this.CType = this.compType;
    const branchIds = this.selectedBranches.map(branch => branch.id).join(',');

    this.complianceAssignmentReportService.getGridData(UserId, ProfileId, CustId, ClientId, this.DistId, this.SpId, RoleCode, this.CType, branchIds, this.Take, this.Skip, this.Page, this.PageSize).subscribe({
      next: (response) => {
        console.log('Response', response);
        if (response.status === 200) {
          const data = response.body;
          if (data && data.data && data.data.length > 0) {
            this.gridData = {
              data: data.data,
              total: data.total
            };
            this.loading = false;
          } else {
            console.log('No data available for the selected filters.');
            this.loading = false;
          }
        } else {
          console.error('Error fetching grid data:', response.body.status.error);
        }
      },
      error: (err) => {
        console.error('Error fetching grid data:', err);
        this.loading = false;
      }
    });
  }

  filterGridDataByChallan() {
    switch (this.selectedComplianceType.value) {
      case 'Challan':
        this.compType = 'CHA';
        break;
      case 'Register':
        this.compType = 'REG';
        break;
      case 'Return':
        this.compType = 'RET';
        break;
      default:
        console.log('Invalid challan type');
        return;
    }
  }

  clearFilter() {
    this.selectedBranches = [];
    this.gridData = { data: [], total: 0 };
    this.selectedComplianceType = { text: 'Select Compliance Type', value: '' };
  }

  applyFilter() {
    this.loading = true;
    //this.getGridData(this.selectedComplianceType.value, this.selectedBranch ? this.selectedBranch.name : '');
    this.getGridData('', '');
  }
}
