<kendo-dialog #dialogRef title="Add/Edit Registration Repository" (close)="closePopup()" [width]="500"
    class="custom-dialog">
    <form [formGroup]="form">
        <div class="row col-12 dialog-content">
            <div class="col-md-4 d-flex align-items-center">
                <label class="f-label me-3">Activity<sub class="text-danger">*</sub></label>
            </div>
            <div class="col-md-8 d-flex align-items-center">
                <!-- <app-combo-box [width]="'330px'" [Placeholder]="'Search/Select Activity'"
                (valueChange)="valueChange($event,'activity')" [data]="allActivityData"
                [selectedValue]="ActivityDropdown" textField="name" valueField="id"
                [hasError]="!ActivityDropdown.id  && showActivityValidation">
            </app-combo-box> -->
                <app-dropdown-list [data]="allActivityData" [selectedValue]="ActivityDropdown" [width]="'250px'"
                    textField="name" valueField="id" (valueChange)="valueChange($event, 'activity')"
                    [hasError]="!ActivityDropdown.id && showValidationError">
                </app-dropdown-list>
            </div>
        </div>

        <div class="row col-12  dialog-content">
            <div class="col-md-4 d-flex align-items-center">
                <label class="f-label me-3">State<sub class="text-danger">*</sub></label>
            </div>
            <div class="col-md-8 d-flex align-items-center">
                <app-dropdown-list [data]="allStatesData" [selectedValue]="StateDropdown" [width]="'250px'"
                    textField="name" valueField="id" (valueChange)="valueChange($event, 'state')"
                    [hasError]="!StateDropdown.id && showValidationError">
                </app-dropdown-list>
            </div>
        </div>
        <div class="row col-12 dialog-content">
            <div class="col-md-4 d-flex align-items-center">
                <label class="f-label me-3">Location<sub class="text-danger">*</sub></label>
            </div>
            <div class="col-md-8 d-flex align-items-center">
                <app-dropdown-list [data]="allLocationData" [selectedValue]="LocationDropdown" [width]="'250px'"
                    textField="name" valueField="id" (valueChange)="valueChange($event, 'location')"
                    [hasError]="!LocationDropdown.id && showValidationError">
                </app-dropdown-list>
            </div>
        </div>
        <div class="row col-12 dialog-content">
            <div class="col-md-4 d-flex align-items-center">
                <label class="f-label me-3">Branch<sub class="text-danger">*</sub></label>
            </div>
            <div class="col-md-8 d-flex align-items-center">
                <app-dropdown-list [data]="allBranchData" [selectedValue]="BranchDropdown" [width]="'250px'"
                    textField="name" valueField="id" (valueChange)="valueChange($event, 'branch')"
                    [hasError]="!BranchDropdown.id && showValidationError">
                </app-dropdown-list>
                <!-- <app-combo-box [width]="'330px'"  [Placeholder]="'Search/Select Activity'"
            [selectedValue]="ActivityDropdown" (valueChange)="valueChange($event,'activity')"
            [data]="allActivityData" [hasError]="!ActivityDropdown &&  showValidationError" >
        </app-combo-box> -->
            </div>
        </div>
        <div class="row col-12 dialog-content">
            <div class="col-md-4 d-flex align-items-center">
                <label class="f-label me-3">Registration Type<sub class="text-danger">*</sub></label>
            </div>
            <div class="col-md-8 d-flex align-items-center">
                <!-- <app-combo-box [width]="'330px'"  [Placeholder]="'Search/Select Type'"
                    (valueChange)="valueChange($event,'type')" [data]="allRegTypeData" [selectedValue]="RegTypeDropdown"
                    [hasError]="!RegTypeDropdown &&showValidationError">
                </app-combo-box> -->
                <app-dropdown-list [data]="allRegTypeData" [selectedValue]="RegTypeDropdown" [width]="'250px'"
                    textField="name" valueField="id" (valueChange)="valueChange($event, 'type')"
                    [hasError]="!RegTypeDropdown.id && showValidationError">
                </app-dropdown-list>
            </div>
        </div>
        <div class="row col-12 dialog-content">
            <div class="col-md-4 d-flex align-items-center">
                <label class="f-label me-3">RC Number<sub class="text-danger">*</sub></label>
            </div>
            <div class="col-md-8 d-flex align-items-center">
                <input class="form-control f-textbox" type="text" placeholder="Enter RC Number"
                    formControlName="rcNumber" [ngClass]="{'is-invalid': form.get('rcNumber')?.invalid && 
                        (form.get('rcNumber')?.touched || showValidationError)}" (keydown)="restrictInput($event)" />
                <small
                    *ngIf="form.get('rcNumber')?.invalid && (form.get('rcNumber')?.touched || showValidationError) && form.get('rcNumber')?.errors?.['pattern']"
                    class="invalid-feedback">Only numbers allowed</small>
            </div>
        </div>

        <div class="row col-12 dialog-content">
            <div class="col-md-4 d-flex align-items-center">
                <label class="f-label me-3" title="Upload">Upload File<sub class="text-danger">*</sub></label>
            </div>
            <div class="col-md-8 d-flex align-items-center">
                <div class="file-upload-container">
                    <span *ngIf="!fileNames['Upload']" title="Uploaded File" class="file-placeholder">
                        No file chosen
                    </span>
                    <span *ngIf="fileNames['Upload']" class="file-name-ellipsis" title="{{fileNames['Upload']}}">
                        {{fileNames['Upload']}}
                    </span>
                    <input type="file" #Upload (change)="onFileChange($event, 'Upload')" id="fileInput"
                        style="display: none;" accept=".xlsx" />
                    <button class="figma-btn-gray ms-2" (click)="Upload.click()">Browse</button>

                    <img *ngIf="data.length!==0" src="../assets/vectors/ExcelDownload.svg" title="SLA Download"
                        class="svg-icon-btn ms-2" (click)="downloadFile()" alt="">
                    <div *ngIf="!fileNames['Upload'] && showValidationFile" class="text-danger"
                        style="margin-top: 5px;">
                        File is required.
                    </div>
                </div>
            </div>
        </div>


        <div class="row col-12 dialog-content">
            <div class="col-md-4 d-flex align-items-center">
                <label class="f-label me-3">Is Lifetime<sub class="text-danger">*</sub></label>
            </div>
            <div class="col-md-8 d-flex align-items-center">
                <ul class="nav nav-tabs">
                    <li style="display: flex; align-items: center;">
                        <input id="new" value="Y" type="checkbox" class="custom-radio" name="measurementSystem"
                            (change)="onRadioChange($event)"
                            [checked]="selectionType === 'Y' || data.rR_ExpiryDate === 'Life Time Validity'"
                            style="vertical-align: sub; font-size: 12px;" />
                    </li>
                </ul>
            </div>
        </div>

        <div class="row col-12  dialog-content">
            <div class="col-md-4 d-flex align-items-center">
                <label class="f-label me-3">Expiry Date </label>
            </div>
            <div class="col-md-8 d-flex align-items-center">
                <app-kendo-datepicker class="uniform-size" [(value)]="expiryDate"
                    (valueChange)="onDateChange($event, 'expiryDate')" [format]="'dd-MM-yyyy'"
                    [placeholder]="placeholder" [width]="'250px'" [isDisabled]="DateDisable">
                </app-kendo-datepicker>
            </div>
            <!-- <div  [ngClass]="{'is-invalid': form.get('expiryDate')?.invalid && (form.get('expiryDate')?.touched)}">
                <app-kendo-datepicker class="uniform-size" [(value)]="expiryDate" 
                (valueChange)="onDateChange($event, 'expiryDate')" [format]="'dd-MM-yyyy'"
                [placeholder]="placeholder" [width]="'330px'">
                </app-kendo-datepicker>
            </div> -->

        </div>
        <button class="figma-btn-blue figma-popup custom-btn" type="submit" (click)="saveData()">
            Save
        </button>
    </form>

    <div *ngIf="loading" class="loader-overlay">
        <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
    </div>
</kendo-dialog>
<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270" [Data]="alertData"
    (close)="closeAlert()"></app-alert-popup>