<div style="display:flex;justify-content: space-between;margin-top: 10px;">
    <div style="display:flex;">
        <app-dropdown-list [data]="Variancerpt" [selectedValue]="selectedVariousType" [width]="'250px'" textField="text"
            valueField="value" (valueChange)="onComplianceVariousTypes($event)" formControlName="year">
        </app-dropdown-list>

        <div class="require-container" *ngIf="showDatepicker">
            <!-- <app-kendo-datepicker title="Agreement Start Date" aria-hidden="true" [(value)]="selectedStartDate"
                (valueChange)="onStartDateChange($event)" [format]="'MMMM yyyy'" [placeholder]="datePlaceholder" [bottomView]="'year'"
                [topView]="'decade'"                [width]="'330px'" [hasError]="!selectedStartDate && showValidationErrors" style="margin-left:10px;">
            </app-kendo-datepicker> -->
            <kendo-datepicker class="f-textbox" [value]="selectedStartDate" [format]="'MMMM yyyy'"
                (valueChange)="onStartDateChange($event)" [style.width]="'250px'" bottomView="year"
                style="margin-left:10px;" topView="decade" [placeholder]="datePlaceholder">
            </kendo-datepicker>
        </div>



        <!-- [class.is-invalid]="hasError"  -->
        <div *ngIf="showYearFrePeriodDropdowns">
            <app-dropdown-list [data]="years" [selectedValue]="selectedYear" [width]="'170px'" textField="text"
                style="margin-left:10px;" valueField="value" (valueChange)="onYearChange($event)"
                formControlName="year">
            </app-dropdown-list>


            <app-dropdown-list [data]="complianceFrequency" [selectedValue]="selectedComplianceTypes" [width]="'170px'"
                textField="text" style="margin-left: 10px;" valueField="value" (valueChange)="onMonthChange($event)"
                [class.input-invalid]="!selectedComplianceType && showValidationErrors">
                <div *ngIf="!selectedComplianceType && showValidationErrors" class="validation-error">

                </div>
            </app-dropdown-list>

            <app-dropdown-list [data]="complianceperiods" [selectedValue]="defaultSelectedValueMonth" [width]="'170px'"
                style="margin-left:10px;" [height]="'34px'" textField="text" valueField="value"
                (valueChange)="onPeriod($event)">
            </app-dropdown-list>

        </div>
    </div>


    <button class="figma-btn-white apply-btn" *ngIf="showApply" type="submit" (click)="applyFilters()">
        <img src="../assets/vectors/ApplyFilter.svg" alt="">
    </button>
    <button class="figma-btn-white clear-btn" (click)="clearFilter()">
        <img src="../assets/vectors/ClearFilter.svg" alt="">
    </button>

    <button class="exportbtn" (click)="exportsReports()">
        <img src="../assets/vectors/ExportButton.svg" alt="">

    </button>



</div>

<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>

<div *ngIf="isActive" class="col-md-12 colpadding0 custom-grid" [style.display]="showState ? 'block' : 'none'"
    style="margin-top:15px;">
    <app-grid [gridData]="gridData" [columns]="columns">

    </app-grid>
</div>