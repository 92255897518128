import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { observeNotification } from 'rxjs/internal/Notification';

@Injectable({
  providedIn: 'root'
})
export class ComplianceAssignmentService {
  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getGridData(custID:number,entityId:string,complianceType:string,challanType:string,selectedBranches:string,isCLRA:boolean): Observable<HttpResponse<any>> {
    const params = {
      custID: custID,
      entityId: entityId,
      complianceType: complianceType,
      challanType:challanType || '',
      selectedBranches:selectedBranches,
      isCLRA:isCLRA
    }
    console.log(params);
    
    const url=`${this.apiUrl}/api/ManageCompliances/LC_Get_ComplianceAssignment_GridRecords`;
    return this.http.post<any>(url,params,{observe:'response'});
  }
  SaveComplianceAssignment(value:any): Observable<HttpResponse<any>> {
    const params ={
      complianceType: value.complianceType,
      challanType: value.challanType,
      clientID: value.clientID,
      performerID:value.performerID,
      reviewerID: value.reviewerID,
      selectedBranch: value.selectedBranch,
      loginUserID: value.loginUserID,
      complianceDate: value.complianceDate,
      _ComplianceIDList: value._ComplianceIDList
      
    } 
    const url=`${this.apiUrl}/api/ManageCompliances/Save_ComplianceAssignment`;
    return this.http.post<any>(url,params,{observe:'response'});
  }
  getBranchDropdown(EntityId:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/ClientDetailsManagement/GetBranchDropdownlist?EntityId=${EntityId}`;
    return this.http.get(url, {observe:'response'});
  }
  getPerformerDropdown(custID:number,ClientID:string,RoleID:number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/ManageCompliances/LC_Get_UserList?custID=${custID}&ClientID=${ClientID}&RoleID=${RoleID}`;
    return this.http.post(url, {},{observe:'response'});
  }
  getReviewerDropdown(custID:number,ClientID:string,RoleID:number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/ManageCompliances/LC_Get_UserList?custID=${custID}&ClientID=${ClientID}&RoleID=${RoleID}`;
    return this.http.post(url,{}, {observe:'response'});
  }
}
