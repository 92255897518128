<div class="row colpadding0" style="margin-bottom: 10px;">
    <div class="col-md-12 colpadding0 toolbar" style="margin-top: 5px;">
        <div class="col-md-8 colpadding0" style=" justify-content: space-between; ">
            <app-combo-box [width]="'350px'" [Placeholder]="defaultSelectedValue"
                (filterChange)="FilterDropdown($event)" (valueChange)="onDropdownValueChange($event)"
                (valueChange)="DropdownGridChange($event)" [data]="dropdown">
            </app-combo-box>
            <div style="display: inline">
                <button class="figma-btn-white" (click)="applyFilter()" type="submit">
                    <img src="../assets/vectors/ApplyFilter.svg" alt="">
                </button>
                <button (click)="clearFilter()" class="figma-btn-white">
                    <img src="../assets/vectors/ClearFilter.svg" alt="">
                </button>
            </div>
        </div>
        <div class="col-md-4 colpadding0">
            <button class="figma-btn-blue "(click)="BackButton()">
                Back
            </button>
            <button *ngIf="Role == 'DADMN'" class="figma-btn-blue upload-btn" (click)="handleOpenPopup('BulkUpload')">
                <img src="../assets/vectors/uploadWhite.svg" >Bulk Upload
            </button>
                <button *ngIf="Role == 'DADMN'" class="figma-btn-blue" (click)="handleOpenPopup('Add')" type="submit">
                    <img src="../assets/vectors/Plus.svg" alt="">Add New
                </button>
        </div>
    </div>
</div>

<kendo-grid class="custom-grid" [data]="gridData" [columnMenu]="true" [pageSize]="pageSize" [pageable]="true"
    (pageChange)="pageChange($event)" [loading]="loadingGrid" [sortable]="true" [skip]="skip">
    <ng-template *ngIf="loadingGrid" kendoGridLoadingTemplate>
        <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
    </ng-template>
    <kendo-grid-column field="cO_CorporateID" title="Corporate ID" [width]="200">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div [title]="dataItem.cO_CorporateID" class="cell-content">
                {{dataItem.cO_CorporateID}}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="customerName" title="Corporate Name">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div [title]="dataItem.customerName" class="cell-content">
                {{dataItem.customerName}}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="cM_ClientID" title="Entity ID" [width]="160">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div [title]="dataItem.cM_ClientID" class="cell-content">
                {{dataItem.cM_ClientID}}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="clientName" title="Entity Name">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div [title]="dataItem.clientName" class="cell-content">
                {{dataItem.clientName}}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="action" title="Action" [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="cell-content" style="justify-content: space-around;" style="margin-left:18px;">
                <img src="../assets/vectors/edit.svg" (click)="OpenEditPopup(dataItem)"  title="Edit" alt="Save Icon" class="svg-icon" />
                <img *ngIf="Role == 'DADMN'" src="../assets/vectors/delete.svg" alt="Delete Icon" title="Delete" class="svg-icon"
                (click)="confirmAlert(dataItem)" />
            </div>
        </ng-template>
    </kendo-grid-column>
    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-input></kendo-pager-input>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>
</kendo-grid>
<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>

<!-- <app-user-master-add-edit-popup *ngIf="showAddEditPopup" (close)="closePopup()"></app-user-master-add-edit-popup> -->
<div kendoDialogContainer *ngIf="openBulkUpload">
    <app-entity-upload-popup (close)="handleClosePopup('BulkUpload')"></app-entity-upload-popup>
</div>

<app-add-edit-entity-master [Data]="Data" *ngIf="showAddEditPopup"
    (close)="closePopup()"></app-add-edit-entity-master>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270"  [id]="userID" [Data]="alertData"
    (close)="closeAlert()" (confirm)="DeleteUser($event)"></app-alert-popup>