<kendo-dialog #dialogRef [title]="Type" (close)="closePopup()" [minWidth]="400"  [width]="1200"
    class="custom-dialog">

    <div *ngIf="loading" class="loader-overlay">
        <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
    </div>

    <!-- <kendo-grid [data]="gridData" [columnMenu]="true" [pageSize]="pageSize" [pageable]="true"
   [sortable]="true" [skip]="skip" [resizable]="true">

    <kendo-grid-column field="shortForm" title="Name">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.customerBranchName">
                {{dataItem.customerBranchName}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-input></kendo-pager-input>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>
</kendo-grid> -->

<div *ngIf="Type == 'Compliance'">
  <div style="margin-bottom: 15px;">
    <kendo-multiselecttree #multiselectTree placeholder="Select Branch" kendoMultiSelectTreeExpandable
        [popupSettings]="popupSettings" [kendoMultiSelectTreeHierarchyBinding]="BranchDropdown"
        childrenField="children" textField="name" valueField="id" [tagMapper]="tagMapper"
        (valueChange)="OnMultiSelectChange($event)" [expandedKeys]="['0']" class="f-textbox multi-box">
    </kendo-multiselecttree>

    <app-combo-box style="margin-left: 10px;" [width]="'350px'" Placeholder="Select Act" (valueChange)="onActSelection($event)" [data]="acts"
        [selectedValue]="selectedAct" textField="value" valueField="key">
    </app-combo-box>

    <button class="figma-btn-white" type="submit" (click)="applyFilter()">
        <img src="../assets/vectors/ApplyFilter.svg" alt="">
    </button>
    <button class="figma-btn-white" (click)="clearFilter(multiselectTree)">
        <img src="../assets/vectors/ClearFilter.svg" alt="">
    </button>
    <button class="exportbtn" (click)="exportToExcel()">
      <img src="../assets/vectors/ExportButton.svg" alt="">
  </button>
</div>

<kendo-grid [data]="gridData1" [columnMenu]="true" [pageSize]="pageSize" [pageable]="true" class="custom-grid"
(pageChange)="pageChange($event)" [loading]="loading" [sortable]="true" [skip]="skip" [resizable]="true">
<kendo-grid-column field="customerBranchName" title="Branch">
  <ng-template kendoGridCellTemplate let-dataItem>
    <div [title]="dataItem.customerBranchName" class="cell-content">
      {{dataItem.customerBranchName}}
    </div>
  </ng-template>
</kendo-grid-column>
<kendo-grid-column field="actName" title="Act">
  <ng-template kendoGridCellTemplate let-dataItem>
    <div [title]="dataItem.actName" class="cell-content">
      {{dataItem.actName}}
    </div>
  </ng-template>
</kendo-grid-column>
<kendo-grid-column field="shortDescription" title="Compliance">
  <ng-template kendoGridCellTemplate let-dataItem>
    <div [title]="dataItem.shortDescription" class="cell-content">
      {{dataItem.shortDescription}}
    </div>
  </ng-template>
</kendo-grid-column>
<kendo-grid-column field="requiredForms" title="Form">
  <ng-template kendoGridCellTemplate let-dataItem>
    <div [title]="dataItem.requiredForms" class="cell-content">
      {{ dataItem.requiredForms}}
    </div>
  </ng-template>
</kendo-grid-column>
<kendo-grid-column field="frequency" title="Frequency">
  <ng-template kendoGridCellTemplate let-dataItem>
    <div [title]="dataItem.frequency" class="cell-content">
      {{dataItem.frequency}}
    </div>
  </ng-template>
</kendo-grid-column>


<kendo-grid-column field="action" title="Action" [width]="100">
  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
    <div class="cell-content" style="display: flex; justify-content:center" >
      <img src="../assets/vectors/EyeIcon.svg" alt="Delete Icon" class="svg-icon"  (click)="onView(dataItem)" />
    </div>
  </ng-template>
</kendo-grid-column>
<ng-template kendoPagerTemplate let-total="total" let-currentPage="currentPage">
  <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
  <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
  <kendo-pager-next-buttons></kendo-pager-next-buttons>
  <kendo-pager-input></kendo-pager-input>
  <kendo-pager-info></kendo-pager-info>
  <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
</ng-template>
<ng-template *ngIf="loading" kendoGridLoadingTemplate>
  <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</ng-template>
</kendo-grid>
</div>
   
<div *ngIf="Type == 'Location'">
  <button class="exportbtn" (click)="exportToExcel()">
    <img src="../assets/vectors/ExportButton.svg" alt="">
</button>
 <app-grid [gridData]="gridData1" [columns]="columns"></app-grid>
</div>
 
</kendo-dialog>