<kendo-dialog #dialogRef title="Update PO Date and PO Number" (close)="closePopup()" [minWidth]="300" [width]="700"
    class="custom-dialog">
    <form [formGroup]="userForm">
        <div style="display:flex;gap:10px;">
            <div class="form-group">
                <label for="poDate" class="f-label">PO Date</label>
                <app-kendo-datepicker class="uniform-size" [(value)]="consolidateDOB"
                    (valueChange)="onDateChange($event)" [format]="'dd-MM-yyyy'"
                    [placeholder]="placeholder" [max]="todayDate" [width]="'314px'">
                </app-kendo-datepicker>
            </div>
            <div class="form-group">
                <label for="poNumber" class="f-label">PO Number
                    <!-- <sub style="color: red; font-size: 15px;">*</sub> -->
                </label>
                <input class="f-textbox form-control" formControlName="poNumber" placeholder="Enter User ID"
                   
                    [ngClass]="{'is-invalid': userForm.get('poNumber')?.invalid && (userForm.get('poNumber')?.touched || userForm.get('username')?.dirty)}"
                    maxlength="15" />
                <small class="text-danger" *ngIf="userForm.get('poNumber')?.errors?.['usernameExists']"
                    style="float: left;">
                    Already exists!
                </small>
            </div>
        </div>
        <div style="margin-bottom: 0px;">
            <button kendoButton type="submit" class="figma-btn-blue"
                (click)="onSubmit()">
                Update
            </button>
        </div>
    </form>
    <app-alert-popup *ngIf="alertData" [Width]="250" [Height]="270" [Data]="alertData"
        (close)="closePopup()"></app-alert-popup>
</kendo-dialog>