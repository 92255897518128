<kendo-dialog #dialogRef title="Client Details" (close)="closePopup()" [height]="580" [width]="1000"
    class="custom-dialog">

    <div class="col-md-12 colpadding0" style="margin-top: 0px; margin-right: -25px;">
        <header class="panel-heading tab-bg-primary"
            style="background: none !important; margin-left: 33px; padding: 0px 0px; ">
            <ul class="nav nav-tabs" style="margin-left: -23px;">
                <li class="listItem pemt" [ngClass]="{'selected-tab': EmployeeOption === 'Basic' }"
                    (click)="TabValidation('Basic')" style="margin-left: -8px !important">
                    <a>Basic Details</a>
                </li>
                <li class="listItem pemt" [ngClass]="{'selected-tab': EmployeeOption === 'SPOC' }"
                    (click)="TabValidation('SPOC')" style="margin-left: -8px !important">
                    <a>Spoc & Escalation Point Details</a>
                </li>
                <li class="listItem pemt" [ngClass]="{'selected-tab': EmployeeOption === 'Setting' }"
                    (click)="TabValidation('Setting')" style="margin-left: -8px !important">
                    <a>Invoice SPOC Details & Settings</a>
                </li>
            </ul>
        </header>
    </div>

    <div class="row col-12 dialog-content" *ngIf="EmployeeOption == 'Basic'">
        <form [formGroup]="formBasic">
            <div class="row EntityContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Customer</label>
                    <div class="require-container " style="color: #495057;font-weight:normal">{{this.corporateId}}
                        <!-- <input type="text" class="f-textbox" formControlName="CustName" placeholder="Enter Customer"> -->
                        <!-- <span style="color: #495057;">{{this.corporateId}}</span> -->
                    </div>
                </div>                
            </div>
            <div class="row EntityContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Name<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="text" class="f-textbox" formControlName="Name" [(ngModel)]="Name"
                            (blur)="onBlur($event)" placeholder="Enter your Name" (keydown)="onKeyDownName($event)"
                            [ngClass]="{'is-invalid': formBasic.get('Name')?.invalid && (formBasic.get('Name')?.touched)}">
                        <div *ngIf="formBasic.get('Name')?.invalid && (formBasic.get('Name')?.touched)">
                            <div *ngIf="formBasic.get('Name')?.errors?.['pattern']" class="text-danger">
                                Must contain only alphanumeric characters
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">ClientID<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="text" style="width:185px" class="f-textbox" formControlName="ClientIds"
                            [(ngModel)]="ClientId" placeholder="Client Id" (keydown)="validateClientId($event)"
                            [ngClass]="{'is-invalid': formBasic.get('ClientIds')?.invalid && (formBasic.get('ClientIds')?.touched)}">
                        <div class="text-danger" *ngIf="formBasic.get('ClientIds')?.invalid && (formBasic.get('ClientIds')?.touched) && formBasic.get('ClientIds')?.errors?.['pattern']">
                            Must contain only alphanumeric characters
                        </div>
                        <small *ngIf="!formBasic.get('ClientIds')?.errors?.['pattern'] && ClientIdExist" class="text-danger">
                            Already exists, Please choose other
                        </small>
                        <small *ngIf="!formBasic.get('ClientIds')?.errors?.['pattern'] && !ClientIdExist && showClientIdAvailable" class="text-green">
                            ClientID Available, Please proceed further
                        </small>
                        <small *ngIf="!formBasic.get('ClientIds')?.errors?.['pattern'] && !ClientIdExist && !showClientIdAvailable && clickButton " class="text-danger">
                            Please Check Client Id
                        </small>
                    </div>
                    <button (click)="CheckClientIdExists()" [disabled]="formBasic.disabled" type="button" class="figma-btn-blue">
                        Check
                    </button>
                </div>
            </div>

            <div class="row EntityContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">PF Type<sub class="text-danger">*</sub></label>
                    <app-dropdown-list [data]="TypedropdownData" [selectedValue]="PFType" [width]="'250px'"
                        textField="text" valueField="value" name="pftype" [isDisabled]="formBasic.disabled"
                        [hasError]="!PFType.value && showValidationErrors"
                        (valueChange)="onDropdownValueChange($event, 'PFType')">
                    </app-dropdown-list>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">PF Code<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="text" class="f-textbox" placeholder="Enter your PF Code" formControlName="PFCodeNo"
                            [(ngModel)]="PFCode" [disabled]="pfCodeDisabled" minlength="22" maxlength="22"
                            [ngClass]="{'is-invalid': formBasic.get('PFCodeNo')?.invalid && (formBasic.get('PFCodeNo')?.touched)}"
                            (input)="validatePFCode($event)" (keydown)="validatePFCode($event)">

                        <div *ngIf="formBasic.get('PFCodeNo')?.invalid && (formBasic.get('PFCodeNo')?.touched)">
                            <div *ngIf="formBasic.get('PFCodeNo')?.errors?.['pattern']" class="text-danger">
                                PF Code must be 22 characters, no leading 0.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row EntityContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Address<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <textarea type="text" class="f-textbox" placeholder="Enter your Address"
                            formControlName="Address" [(ngModel)]="Address"
                            [ngClass]="{'is-invalid': formBasic.get('Address')?.invalid && (formBasic.get('Address')?.touched) && formBasic.get('Address')?.errors?.['required']}">
                            </textarea>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Email<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="text" class="f-textbox" placeholder="Enter your Email" formControlName="Email"
                            [(ngModel)]="Email" (input)="validateBasicEmail()" (keydown)="onKeyDownEmail($event)"
                            [ngClass]="{'is-invalid': formBasic.get('Email')?.invalid && formBasic.get('Email')?.touched}">
                        <div *ngIf="formBasic.get('Email')?.invalid && formBasic.get('Email')?.touched">
                            <div *ngIf="formBasic.get('Email')?.errors?.['pattern']" class="text-danger">
                                Please enter a valid email address.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row EntityContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">State<sub class="text-danger">*</sub></label>
                    <app-dropdown-list [data]="StateDropdownData" [selectedValue]="state" [width]="'250px'"
                        textField="text" valueField="value" name="state"  [isDisabled]="formBasic.disabled"
                        [hasError]="!state.value && showValidationErrors"
                        (valueChange)="onDropdownValueChange($event, 'State')">
                    </app-dropdown-list>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">City<sub class="text-danger">*</sub></label>
                    <app-dropdown-list [data]="cityDropdownData" [selectedValue]="city" [width]="'250px'"  [isDisabled]="formBasic.disabled"
                        textField="text" valueField="value" name="City" [hasError]="!city.value && showValidationErrors"
                        (valueChange)="onDropdownValueChange($event, 'City')">
                    </app-dropdown-list>
                </div>
            </div>

            <div class="row EntityContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Contact Person<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="text" class="f-textbox" placeholder="Enter your Contact Person"  (input)="validateContactPerson()" 
                            formControlName="ContactPerson" [(ngModel)]="ContactPerson" (keydown)="onKeyDownName($event)"
                            [ngClass]="{'is-invalid': formBasic.get('ContactPerson')?.invalid && (formBasic.get('ContactPerson')?.touched)}">
                        <div
                            *ngIf="formBasic.get('ContactPerson')?.invalid && (formBasic.get('ContactPerson')?.touched)">
                            <div *ngIf="formBasic.get('ContactPerson')?.errors?.['pattern']" class="text-danger">
                                Only letters and spaces allowed.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Contact Number<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="text" class="f-textbox" placeholder="Enter your Contact Number" (input)="validateContactNumber()"
                            formControlName="ContactNumber" [(ngModel)]="ContactNumber" minlength="10" maxlength="10" (keydown)="onKeyDown($event)" 
                            [ngClass]="{'is-invalid': formBasic.get('ContactNumber')?.invalid && (formBasic.get('ContactNumber')?.touched)}">
                        <div
                            *ngIf="formBasic.get('ContactNumber')?.invalid && (formBasic.get('ContactNumber')?.touched)">
                            <div *ngIf="formBasic.get('ContactNumber')?.errors?.['pattern']" class="text-danger">
                                Only Numbers, max 10 digits allowed.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row EntityContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Status<sub class="text-danger">*</sub></label>
                    <app-dropdown-list [data]="StatusDropdownData" [selectedValue]="status" [width]="'250px'"
                        textField="text" valueField="value" name="status" [isDisabled]="formBasic.disabled"
                        [hasError]="!status.value && showValidationErrors"
                        (valueChange)="onDropdownValueChange($event, 'Status')">
                    </app-dropdown-list>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Commencement Date<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <app-kendo-datepicker class="uniform-size " [(value)]="commencementDate"
                            (valueChange)="onDateChange($event, 'CommencementDate')" [format]="'dd-MM-yyyy'"
                            [placeholder]="placeholder" [width]="'250px'" [max]="todayDate"
                            [class.input-invalid]="!commencementDate && showValidationErrors"  [isDisabled]="formBasic.disabled"
                            [hasError]="!commencementDate && showValidationErrors" (keydown)="restrictDateInput($event)">
                        </app-kendo-datepicker>
                    </div>
                </div>
            </div>

            <div class="row EntityContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Wage Period From<sub class="text-danger">*</sub></label>
                    <app-dropdown-list [data]="dayDropdownData" [selectedValue]="WagePeriodFrom" [width]="'250px'"
                        textField="text" valueField="value" name="wageperiodfrom"
                        [hasError]="!WagePeriodFrom.value && showValidationErrors"  [isDisabled]="formBasic.disabled"
                        (valueChange)="onDropdownValueChange($event, 'WagePeriodFrom')">
                    </app-dropdown-list>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Wage Period To<sub class="text-danger">*</sub></label>
                    <app-dropdown-list [data]="dayDropdownData" [selectedValue]="WagePeriodTo" [width]="'250px'"
                        textField="text" valueField="value" name="wageperiodto"
                        [hasError]="!WagePeriodTo.value && showValidationErrors"  [isDisabled]="formBasic.disabled"
                        (valueChange)="onDropdownValueChange($event, 'WagePeriodTo')">
                    </app-dropdown-list>
                </div>
            </div>

            <div class="row EntityContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Payment Day<sub class="text-danger">*</sub></label>
                    <app-dropdown-list [data]="PaymentDayDropdownData" [selectedValue]="PaymentDay" [width]="'250px'"
                        textField="text" valueField="value" name="PaymentDay"  [isDisabled]="formBasic.disabled"
                        [hasError]="!PaymentDay.value && showValidationErrors"
                        (valueChange)="onDropdownValueChange($event, 'PaymentDay')">
                    </app-dropdown-list>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Establishment Type<sub class="text-danger">*</sub></label>
                    <app-dropdown-list [data]="EstablishmentTypedropdownData" [selectedValue]="EstablishmentType"
                        [width]="'250px'" textField="text" valueField="value" name="establishmenttype"
                        [hasError]="!EstablishmentType.value && showValidationErrors"  [isDisabled]="formBasic.disabled"
                        (valueChange)="onDropdownValueChange($event, 'EstablishmentType')">
                    </app-dropdown-list>
                </div>
            </div>

            <div class="row EntityContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Act Applicability<sub class="text-danger">*</sub></label>
                    <app-dropdown-list [data]="ActApplicabilitydropdownData" [selectedValue]="ActApplicability"
                        [width]="'250px'" textField="text" valueField="value" name="actapplicability"
                        [hasError]="!ActApplicability.value && showValidationErrors"  [isDisabled]="formBasic.disabled"
                        (valueChange)="onDropdownValueChange($event, 'ActApplicability')">
                    </app-dropdown-list>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Industry Type</label>
                    <app-dropdown-list [data]="IndustryTypedropdownData" [selectedValue]="IndustryApplicability"
                        [width]="'250px'" textField="text" valueField="value" name="industryapplicability"  [isDisabled]="formBasic.disabled"
                        (valueChange)="onDropdownValueChange($event, 'IndustryApplicability')">
                    </app-dropdown-list>
                </div>
            </div>

            <div class="row EntityContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Bonus Exempted<sub class="text-danger">*</sub></label>
                    <app-dropdown-list [data]="YesNoDropdownData" [selectedValue]="BonusExempted" [width]="'250px'"
                        textField="text" valueField="value" name="bonusexempted"
                        [hasError]="!BonusExempted.value && showValidationErrors"  [isDisabled]="formBasic.disabled"
                        (valueChange)="onDropdownValueChange($event, 'BonusExempted')">
                    </app-dropdown-list>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Bonus Percentage<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="text" class="f-textbox" placeholder="Enter Bonus Percentage"
                            formControlName="BonusPercentage" [(ngModel)]="BonusPercentage"  (keydown)="restrictBonusPercentageInput($event)"
               (input)="updateBonusPercentage($event)"
                            [ngClass]="{'is-invalid': formBasic.get('BonusPercentage')?.invalid && (formBasic.get('BonusPercentage')?.touched)}">
                        <div
                            *ngIf="formBasic.get('BonusPercentage')?.invalid && (formBasic.get('BonusPercentage')?.touched)">
                            <div *ngIf="formBasic.get('BonusPercentage')?.errors?.['pattern']" class="text-danger">
                                Enter Bonus Percentage only in Decimal or Integer.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <kendo-dialog-actions style="border: none;">
                <button type="submit" class="figma-btn-blue figma-popup" style="margin-top:26px;" (click)="BasicNext()">
                    Next
                </button>
            </kendo-dialog-actions>

        </form>
    </div>

    <div class="row col-12 dialog-content" *ngIf="EmployeeOption == 'SPOC'">
        <form [formGroup]="formSPOCEPD">

            <div class="row EntityContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Salutation<sub class="text-danger">*</sub></label>
                    <app-dropdown-list [data]="SalutationdropdownData" [selectedValue]="Salutation" [width]="'250px'"
                        textField="text" valueField="value" name="salutation"  [isDisabled]="formSPOCEPD.disabled"
                        [hasError]="(Salutation.value=='0') && showValidationErrorsEmp"
                        (valueChange)="onDropdownValueChange($event, 'Salutation')">
                    </app-dropdown-list>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">First Name<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="text" class="f-textbox" placeholder="Enter your First Name"
                            formControlName="FirstName" [(ngModel)]="SPOCFirstName"  (input)="validateFirstNameInput()"
                            [ngClass]="{'is-invalid': formSPOCEPD.get('FirstName')?.invalid && (formSPOCEPD.get('FirstName')?.touched)}">
                        <div *ngIf="formSPOCEPD.get('FirstName')?.invalid && (formSPOCEPD.get('FirstName')?.touched)"
                            class="text-danger">
                            <div *ngIf="formSPOCEPD.get('FirstName')?.errors?.['pattern']">
                                Only Letters and Space Allowed.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row EntityContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Last Name</label>
                    <div class="require-container">
                        <input type="text" class="f-textbox" placeholder="Enter your Last Name"
                            formControlName="LastName" [(ngModel)]="SPOCLastName" (input)="validateLastNameInput()"
                            [ngClass]="{'is-invalid': formSPOCEPD.get('LastName')?.invalid && (formSPOCEPD.get('LastName')?.touched)}">
                        <div *ngIf="formSPOCEPD.get('LastName')?.invalid && (formSPOCEPD.get('LastName')?.touched)"
                            class="text-danger">
                            <div *ngIf="formSPOCEPD.get('LastName')?.errors?.['pattern']">
                                Only Letters and Space Allowed.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Contact Number<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="text" class="f-textbox" placeholder="Enter your Contact Number"
                            formControlName="ContactNumber" [(ngModel)]="SPOCContactNumber" minlength="10"
                            maxlength="10" (input)="validateContactNumberInput()"
                            [ngClass]="{'is-invalid': formSPOCEPD.get('ContactNumber')?.invalid && (formSPOCEPD.get('ContactNumber')?.touched)}">
                        <div
                            *ngIf="formSPOCEPD.get('ContactNumber')?.invalid && (formSPOCEPD.get('ContactNumber')?.touched)">
                            <div *ngIf="formSPOCEPD.get('ContactNumber')?.errors?.['pattern']" class="text-danger">
                                Only Numbers, max 10 digits allowed.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row EntityContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Email<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="text" class="f-textbox" placeholder="Enter your Email" formControlName="SPOCEmail"
                            [(ngModel)]="SPOCEmail" (input)="validateSPOCEmail()"
                            [ngClass]="{'is-invalid': formSPOCEPD.get('SPOCEmail')?.invalid && (formSPOCEPD.get('SPOCEmail')?.touched)}">
                        <div *ngIf="formSPOCEPD.get('SPOCEmail')?.invalid && formSPOCEPD.get('SPOCEmail')?.touched">
                            <div *ngIf="formSPOCEPD.get('SPOCEmail')?.errors?.['pattern']" class="text-danger">
                                Please enter a valid email address.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Designation<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="text" class="f-textbox" placeholder="Enter your Designation"
                            formControlName="SPOCDesignation" [(ngModel)]="SPOCDesignation" (input)="validateDesignationInput()"
                            [ngClass]="{'is-invalid': formSPOCEPD.get('Designation')?.invalid && (formSPOCEPD.get('SPOCDesignation')?.touched)}">
                        <div *ngIf="formSPOCEPD.get('SPOCDesignation')?.invalid && (formSPOCEPD.get('SPOCDesignation')?.touched)"
                            class="text-danger">
                            <div *ngIf="formSPOCEPD.get('SPOCDesignation')?.errors?.['pattern']">
                                Only alaphabets and Space Allowed.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row EntityContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">EP1 Salutation<sub class="text-danger">*</sub></label>
                    <app-dropdown-list [data]="SalutationdropdownData" [selectedValue]="EP1Salutation" [width]="'250px'"
                        textField="text" valueField="value" name="salutation"
                        [hasError]="(EP1Salutation.value=='0') && showValidationErrorsEmp"  [isDisabled]="formSPOCEPD.disabled"
                        (valueChange)="onDropdownValueChange($event, 'EP1Salutation')">
                    </app-dropdown-list>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">EP1 First Name<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="text" class="f-textbox" placeholder="Enter your First Name"
                            formControlName="EP1FirstName" [(ngModel)]="EP1FirstName" (input)="validateEP1FirstNameInput()"
                            [ngClass]="{'is-invalid': formSPOCEPD.get('EP1FirstName')?.invalid && (formSPOCEPD.get('EP1FirstName')?.touched)}">
                        <div *ngIf="formSPOCEPD.get('EP1FirstName')?.invalid && (formSPOCEPD.get('EP1FirstName')?.touched)"
                            class="text-danger">
                            <div *ngIf="formSPOCEPD.get('EP1FirstName')?.errors?.['pattern']">
                                Only alaphabets and numbers are allowed.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row EntityContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">EP1 Last Name</label>
                    <div class="require-container">
                        <input type="text" class="f-textbox" placeholder="Enter your Last Name"
                            formControlName="EP1LastName" [(ngModel)]="EP1LastName" (input)="validateEP1LastNameInput()"
                            [ngClass]="{'is-invalid': formSPOCEPD.get('EP1LastName')?.invalid && (formSPOCEPD.get('EP1LastName')?.touched)}">
                        <div *ngIf="formSPOCEPD.get('EP1LastName')?.invalid && (formSPOCEPD.get('EP1LastName')?.touched)"
                            class="text-danger">
                            <div *ngIf="formSPOCEPD.get('EP1LastName')?.errors?.['pattern']">
                                Only alaphabets and spaces allowed.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">EP1 Contact No<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="text" class="f-textbox" placeholder="Enter your Contact Number"
                            formControlName="EP1ContactNumber" [(ngModel)]="EP1ContactNumber" minlength="10"
                            maxlength="10" (input)="validateEP1ContactNumber()" 
                            [ngClass]="{'is-invalid': formSPOCEPD.get('EP1ContactNumber')?.invalid && (formSPOCEPD.get('EP1ContactNumber')?.touched)}">
                        <div
                            *ngIf="formSPOCEPD.get('EP1ContactNumber')?.invalid && (formSPOCEPD.get('EP1ContactNumber')?.touched)">
                            <div *ngIf="formSPOCEPD.get('EP1ContactNumber')?.errors?.['pattern']"
                                class="text-danger">
                                Only Numbers, max 10 digits allowed.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row EntityContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">EP1 Email<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="text" class="f-textbox" placeholder="Enter your Email" formControlName="EP1Email"
                            [(ngModel)]="EP1Email"  (input)="validateEmailInput()"
                            [ngClass]="{'is-invalid': formSPOCEPD.get('EP1Email')?.invalid && (formSPOCEPD.get('EP1Email')?.touched)}">
                        <div *ngIf="formSPOCEPD.get('EP1Email')?.invalid && (formSPOCEPD.get('EP1Email')?.touched)"
                            class="text-danger">
                            <div *ngIf="formSPOCEPD.get('EP1Email')?.errors?.['pattern']">
                                Please enter a valid email address.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">EP1 Designation<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="text" class="f-textbox" placeholder="Enter your Designation"
                            formControlName="EP1Designation" [(ngModel)]="EP1Designation"  (keypress)="validateEP1Designation($event)"
                            [ngClass]="{'is-invalid': formSPOCEPD.get('EP1Designation')?.invalid && (formSPOCEPD.get('EP1Designation')?.touched)}">
                        <div *ngIf="formSPOCEPD.get('EP1Designation')?.invalid && (formSPOCEPD.get('EP1Designation')?.touched)"
                            class="text-danger">
                            <div *ngIf="formSPOCEPD.get('EP1Designation')?.errors?.['pattern']">
                                Only alaphabets and Space Allowed.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row EntityContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">EP2 Salutation</label>
                    <app-dropdown-list [data]="SalutationdropdownData" [selectedValue]="EP2Salutation" [width]="'250px'"
                        textField="text" valueField="value" name="ep2salutation"  [isDisabled]="formSPOCEPD.disabled"
                        (valueChange)="onDropdownValueChange($event, 'EP2Salutation')">
                    </app-dropdown-list>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">EP2 First Name</label>
                    <div class="require-container">
                        <input type="text" class="f-textbox" placeholder="Enter your First Name"
                            formControlName="EP2FirstName" [(ngModel)]="EP2FirstName"  (input)="validateEP2FirstName()" 
                            [ngClass]="{'is-invalid': formSPOCEPD.get('EP2FirstName')?.invalid && (formSPOCEPD.get('EP2FirstName')?.touched)}">
                        <div *ngIf="formSPOCEPD.get('EP2FirstName')?.invalid && (formSPOCEPD.get('EP2FirstName')?.touched)"
                            class="text-danger">
                            <div *ngIf="formSPOCEPD.get('EP2FirstName')?.errors?.['pattern']">
                                Only alaphabets and Space Allowed.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row EntityContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">EP2 Last Name</label>
                    <div class="require-container">
                        <input type="text" class="f-textbox" placeholder="Enter your Last Name"
                            formControlName="EP2LastName" [(ngModel)]="EP2LastName"
                            [ngClass]="{'is-invalid': formSPOCEPD.get('EP2LastName')?.invalid && (formSPOCEPD.get('EP2LastName')?.touched)}">
                        <div *ngIf="formSPOCEPD.get('EP2LastName')?.invalid && (formSPOCEPD.get('EP2LastName')?.touched)"
                            class="text-danger">
                            <div *ngIf="formSPOCEPD.get('EP2LastName')?.errors?.['pattern']">
                                Only Letters and Space Allowed.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">EP2 Contact No</label>
                    <div class="require-container">
                        <input type="text" class="f-textbox" placeholder="Enter your Contact Number"
                            formControlName="EP2ContactNumber" [(ngModel)]="EP2ContactNumber" minlength="10"
                            maxlength="10"  (input)="validateEP2ContactNumber()" 
                            [ngClass]="{'is-invalid': formSPOCEPD.get('EP2ContactNumber')?.invalid && (formSPOCEPD.get('EP2ContactNumber')?.touched)}">
                        <div
                            *ngIf="formSPOCEPD.get('EP2ContactNumber')?.invalid && (formSPOCEPD.get('EP2ContactNumber')?.touched)">
                            <div *ngIf="formSPOCEPD.get('EP2ContactNumber')?.errors?.['pattern']"
                                class="text-danger">
                                Only Numbers, max 10 digits allowed.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row EntityContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">EP2 Email</label>
                    <div class="require-container">
                        <input type="text" class="f-textbox" placeholder="Enter your Email" formControlName="EP2Email"
                            [(ngModel)]="EP2Email" (input)="validateEP2Email()"
                            [ngClass]="{'is-invalid': formSPOCEPD.get('EP2Email')?.invalid && (formSPOCEPD.get('EP2Email')?.touched)}">
                        <div *ngIf="formSPOCEPD.get('EP2Email')?.invalid && (formSPOCEPD.get('EP2Email')?.touched)"
                            class="text-danger">
                            <div *ngIf="formSPOCEPD.get('EP2Email')?.errors?.['pattern']">
                                Please enter a valid email address.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">EP2 Designation</label>
                    <input type="text" class="f-textbox" placeholder="Enter your Designation"
                        formControlName="EP2Designation" [(ngModel)]="EP2Designation" (input)="validateEP2Designation()"
                        [ngClass]="{'is-invalid': formSPOCEPD.get('EP2Designation')?.invalid && (formSPOCEPD.get('EP2Designation')?.touched)}">
                    <div *ngIf="formSPOCEPD.get('EP2Designation')?.invalid && (formSPOCEPD.get('EP2Designation')?.touched)"
                        class="text-danger">
                        <div *ngIf="formSPOCEPD.get('EP2Designation')?.errors?.['pattern']">
                            Only alaphabets and Space Allowed.
                        </div>
                    </div>
                </div>
            </div>

            <kendo-dialog-actions style="border: none;">
                <button type="submit" class="figma-btn-blue figma-popup" style="margin-top:26px;" (click)="SPOCNext()">
                    Next
                </button>
            </kendo-dialog-actions>

        </form>
    </div>

    <div class="row col-12 dialog-content" *ngIf="EmployeeOption == 'Setting'">
        <form [formGroup]="formInvoiceSettings">
            <div class="row EntityContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">IV SPOC Name<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="text" class="f-textbox" placeholder="Enter your First Name"
                            formControlName="IVSPOCName" [(ngModel)]="IVSPOCName" (input)="validateSPOCName()"
                            [ngClass]="{'is-invalid': formInvoiceSettings.get('IVSPOCName')?.invalid && (formInvoiceSettings.get('IVSPOCName')?.touched)}">
                        <div *ngIf="formInvoiceSettings.get('IVSPOCName')?.invalid && (formInvoiceSettings.get('IVSPOCName')?.touched)"
                            class="text-danger">
                            <div *ngIf="formInvoiceSettings.get('IVSPOCName')?.errors?.['pattern']">
                                Only alaphabets and spaces allowed.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">IV SPOC Email<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="text" class="f-textbox" placeholder="Enter your Email"
                            formControlName="IVSPOCEmail" [(ngModel)]="IVSPOCEmail"  (input)="validateEmail()"
                            [ngClass]="{'is-invalid': formInvoiceSettings.get('IVSPOCEmail')?.invalid && (formInvoiceSettings.get('IVSPOCEmail')?.touched)}">
                        <div *ngIf="formInvoiceSettings.get('IVSPOCEmail')?.invalid && (formInvoiceSettings.get('IVSPOCEmail')?.touched)"
                            class="text-danger">
                            <div *ngIf="formInvoiceSettings.get('IVSPOCEmail')?.errors?.['pattern']">
                                Please enter a valid email address.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row EntityContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">IV SPOC Mobile Number<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="text" class="f-textbox" placeholder="Enter your Mobile Number"
                            formControlName="IVSPOCMobileNumber" [(ngModel)]="IVSPOCMobileNumber" minlength="10"
                            maxlength="10"  (input)="validateMobileNumber()"
                            [ngClass]="{'is-invalid': formInvoiceSettings.get('IVSPOCMobileNumber')?.invalid && (formInvoiceSettings.get('IVSPOCMobileNumber')?.touched)}">
                        <div *ngIf="formInvoiceSettings.get('IVSPOCMobileNumber')?.invalid && (formInvoiceSettings.get('IVSPOCMobileNumber')?.touched)"
                            class="text-danger">
                            <div *ngIf="formInvoiceSettings.get('IVSPOCMobileNumber')?.errors?.['pattern']">
                                Only Numbers, max 10 digits allowed.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Type<sub class="text-danger">*</sub></label>
                    <app-dropdown-list [data]="CompanyTypeDropdownData" [selectedValue]="CompanyType" [width]="'250px'"
                        textField="text" valueField="value" name="companytype"  [isDisabled]="formInvoiceSettings.disabled"
                        [hasError]="!CompanyType.value && showValidationErrorsOther"
                        (valueChange)="onDropdownValueChange($event, 'CompanyType')">
                    </app-dropdown-list>
                </div>
            </div>

            <div class="row EntityContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">EDLI Excemption Type<sub class="text-danger">*</sub></label>
                    <app-dropdown-list [data]="EDLIExcemptionTypeDropdownData" [selectedValue]="EDLIExcemptionType"
                        [width]="'250px'" textField="text" valueField="value" [isDisabled]="formInvoiceSettings.disabled"
                        [hasError]="!EDLIExcemptionType.value && showValidationErrorsOther"
                        (valueChange)="onDropdownValueChange($event, 'EDLIExcemptionType')">
                    </app-dropdown-list>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">CLRA Applicable<sub class="text-danger">*</sub></label>
                    <app-dropdown-list [data]="YesNoDropdownData" [selectedValue]="CLRAApplicable" [width]="'250px'"
                        textField="text" valueField="value" [isDisabled]="formInvoiceSettings.disabled"
                        [hasError]="!CLRAApplicable.value && showValidationErrorsOther"
                        (valueChange)="onDropdownValueChange($event, 'CLRAApplicable')">
                    </app-dropdown-list>
                </div>
            </div>

            <div class="row EntityContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Service Tax Exempted<sub class="text-danger">*</sub></label>
                    <app-dropdown-list [data]="YesNoDropdownData" [selectedValue]="ServiceTaxExempted" [width]="'250px'"
                        textField="text" valueField="value" [isDisabled]="formInvoiceSettings.disabled"
                        [hasError]="!ServiceTaxExempted.value && showValidationErrorsOther"
                        (valueChange)="onDropdownValueChange($event, 'ServiceTaxExempted')">
                    </app-dropdown-list>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">CLRA - Type<sub class="text-danger">*</sub></label>
                    <app-dropdown-list [data]="CLRATypeDropdownData" [selectedValue]="CLRAType" [width]="'250px'" [isDisabled]="formInvoiceSettings.disabled"
                        textField="text" valueField="value" [hasError]="!CLRAType.value && showValidationErrorsOther"
                        (valueChange)="onDropdownValueChange($event, 'CLRAType')">
                    </app-dropdown-list>
                </div>
            </div>

            <div class="row EntityContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Contractor Employer Name<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="text" class="f-textbox" formControlName="ContractorEmployerName"
                            [(ngModel)]="ContractorEmployerName" placeholder="Enter Employer Name" (input)="validateContractorEmployerName()"
                            [ngClass]="{'is-invalid': formInvoiceSettings.get('ContractorEmployerName')?.invalid && (formInvoiceSettings.get('ContractorEmployerName')?.touched)}">
                        <div *ngIf="formInvoiceSettings.get('ContractorEmployerName')?.invalid && (formInvoiceSettings.get('ContractorEmployerName')?.touched)"
                            class="text-danger">
                            <div *ngIf="formInvoiceSettings.get('ContractorEmployerName')?.errors?.['pattern']">
                                Only letters and spaces allowed.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">PO Applicable<sub class="text-danger">*</sub></label>
                    <app-dropdown-list [data]="YesNoDropdownData" [selectedValue]="POApplicable" [width]="'250px'"
                        textField="text" valueField="value" name="poapplicable" [isDisabled]="formInvoiceSettings.disabled"
                        [hasError]="!POApplicable.value && showValidationErrorsOther"
                        (valueChange)="onDropdownValueChange($event, 'POApplicable')">
                    </app-dropdown-list>
                </div>
            </div>

            <div class="row EntityContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">AgreementID<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="text" class="f-textbox" formControlName="AgreementID" [(ngModel)]="AgreementID"
                            placeholder="Enter AgreementID" (input)="validateAgreementID()"
                            [ngClass]="{'is-invalid': formInvoiceSettings.get('AgreementID')?.invalid && (formInvoiceSettings.get('AgreementID')?.touched)}">
                        <div *ngIf="formInvoiceSettings.get('AgreementID')?.invalid && (formInvoiceSettings.get('AgreementID')?.touched)"
                            class="text-danger">
                            <div *ngIf="formInvoiceSettings.get('AgreementID')?.errors?.['pattern']">
                                Must contain only alphanumeric characters
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Mandate<sub class="text-danger">*</sub></label>
                    <app-dropdown-list [data]="MandateDropdownData" [selectedValue]="Mandate" [width]="'250px'"
                        textField="text" valueField="value" name="mandate" [isDisabled]="formInvoiceSettings.disabled"
                        [hasError]="!Mandate.value && showValidationErrorsOther"
                        (valueChange)="onDropdownValueChange($event, 'Mandate')">
                    </app-dropdown-list>
                </div>
            </div>

            <kendo-dialog-actions style="border: none;">
                <button type="submit" (click)="OnSave()" *ngIf="!formInvoiceSettings.disabled" style="margin-top:26px;" class="figma-btn-blue figma-popup">
                    Save
                </button>
            </kendo-dialog-actions>
        </form>
    </div>

    

    <div *ngIf="loading" class="loader-overlay">
        <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
    </div>

</kendo-dialog>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270" [Data]="alertData"
    (close)="closeAlert()"></app-alert-popup>