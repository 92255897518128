<kendo-dialog #dialogRef  title="Add File" (close)="closePopup()" [width]="545" [height]="340"
    class="custom-dialog">
     
    <div class="row col-12 dialog-content">
        <div class="row sampleDocument" style="display: flex; align-items: center;">
          <div class="col-md-12">
            <label class="f-label">Upload</label>
          </div>
        </div>
        <div class="col-md-12 form-group" >
          <div class="drop-zone" 
          (dragover)="onDragOver($event)" 
          (dragleave)="onDragLeave($event)" 
          (drop)="onDrop($event)">
          <div *ngIf="!selectedFile" class="f-label" style="font-size: 18px;">
            <div class="col-md-12">
              <img src="../assets/vectors/cloud.svg" alt="">
              </div>
              <div class="col-md-8">
                <label style="margin-left: 90px;">{{ dropzoneMessage }}</label>
              </div>
              <div class="col-md-4">
                <label style="z-index: 2; color: #125AAB; text-decoration: underline; cursor: pointer;  margin-left: -45px; font-weight: 500;">  
                  Browse
                  <input  type="file" style="display: none;" (change)="onFileSelect($event)"  accept=".xls,.xlsx,.csv,.pdf,.docx,.doc,.zip,.rar,.txt,.jpg,.png" />
                </label>
              </div>
            </div>
            <p *ngIf="selectedFile" style="margin-top: 30px;">{{ selectedFile.name }}</p>
          </div>
        </div>
      </div>

    <kendo-dialog-actions class="ksave">
        <button type="button" (click)="uploadFiles()" class="figma-btn-blue">
            Upload
        </button>
    </kendo-dialog-actions>
    <!-- <div *ngIf="loading" class="loader-overlay">
        <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
    </div> -->
</kendo-dialog>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270" [Height]="280" [Data]="alertData"
    (close)="closeAlert()"></app-alert-popup>