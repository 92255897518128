import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PoDetailsService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {

  }
  
getClientsMaster(): Observable<HttpResponse<any>> {
  const url = `${this.apiUrl}/api/Invoice/GetPoApplicabileClients`;
    // 'https://localhost:7111/api/Invoice/GetPoApplicabileClients'
  return this.http.get(url, {observe:'response'});
}
  
GetPOListDetails(payload:any): Observable<HttpResponse<any>> {
  const url = `${this.apiUrl}/api/Invoice/GetPOListDetails?ClientId=${payload.ClientId}`;
  // 'https://localhost:7111/api/Invoice/GetPOListDetails?ClientId=AVA159TSPL' \
  return this.http.get(url, {observe:'response'});
}
GetMarkAsInActive(payload:any): Observable<HttpResponse<any>> {
  const url = `${this.apiUrl}/api/Invoice/MarkAsInActive?PO_Id=${payload.PO_Id}&UserId=${payload.UserId}`;
  // https://localhost:7111/api/Invoice/MarkAsInActive?PO_Id=30121&UserId=11525
    return this.http.get(url, {observe:'response'});
}
SubmitPODetail(payload:any): Observable<HttpResponse<any>> {
  const url = `${this.apiUrl}/api/Invoice/SubmitPODetails`;
  return this.http.post<any>(url, payload, { observe: 'response' });
}

GetClientPODetail(payload:any): Observable<HttpResponse<any>> {
  const url = `${this.apiUrl}/api/Invoice/GetClientPODetails?ClientId=${payload.ClientId}&PO_Id=${payload.PO_Id}`;
  // https://localhost:7111/api/Invoice/GetClientPODetails?ClientId=AVAAVAADSO&PO_Id=30121
  return this.http.get(url, {observe:'response'});
}
}
