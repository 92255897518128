import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EsicinputsService {

  private apiUrl = environment.apiUrl;
  
  constructor(private http: HttpClient) { }

  UploadESICFile(formData: FormData): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/ESIC/EsicUploadExcel`;
    return this.http.post<any>(url, formData, { observe: 'response' });
  }

  getESICErrorBlobLink(fileId: string) {
    const url = `${this.apiUrl}/api/ESIC/EsicDownloadErrorFile?Fileid=${fileId}`;
    return this.http.get(url, {});
  }

  GetSubCodesBasedonEntity(EntityId: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/ESIC/GetSubCodesBasedonEntity?EntityId=${EntityId}`;
    return this.http.get(url,  { observe: 'response' });
  }
  GetESICDetails(EntityId:string,SubCode:string,FromDate:string,ToDate:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/ESIC/GetESICDetails?EntityId=A0GRG&SubCode=51001004070001008&FromDate=1-10-2022&ToDate=1-10-2022`;
    return this.http.get(url,  { observe: 'response' });
  }
  getESIC_ExportToExcel(EntityId:string,SubCode:string,FromDate:string,ToDate:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/ESIC/ESIC_ExportToExcel?EntityId=${EntityId}&SubCode=${SubCode}&FromDate=${FromDate}&ToDate=${ToDate}`;
    return this.http.get(url,  { observe: 'response' });
  }
  saveESIC_Documents(selectedItems:any): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/ESIC/GetESIC_Documents`;
    return this.http.post(url, selectedItems, { observe: 'response' });
  }

}
