import { Injectable } from '@angular/core';


import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InvoiceApprovalService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {

  }
  
  getInvoiceType(): Observable<HttpResponse<any>>{
    const url = `${this.apiUrl}/api/Invoice/GetCodeDescription?Type=InvoiceType`;
    return this.http.get(url,{ observe: 'response' });
  }
  getClientsGstMaster(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Invoice/GetClientsMaster`;
    // https://localhost:7111/api/Invoice/GetClientsMaster
    return this.http.get(url, {observe:'response'});
  }
  GetInvoiceGridDetail(payload:any): Observable<HttpResponse<any>> {
   
    const url = `${this.apiUrl}/api/Invoice/Get_ApprovalPendingInvoices?invoiceType=${payload.invoiceType}&clientid=${payload.clientid}&yearid=${payload.yearid}&monthid=${payload.monthid}&InvGenType=${payload.InvGenType}`;
    return this.http.get(url, { observe: 'response' });
    // https://localhost:7111/api/Invoice/Get_ApprovalPendingInvoices?invoiceType=RRC&clientid=HCKRG&yearid=2024&monthid=09&InvGenType=R
  }

  SubmitInvoiceApprovalPending(payload:any): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Invoice/ApprovePendingInvoices`;
    // https://localhost:7111/api/Invoice/ApprovePendingInvoices
    return this.http.post<any>(url, payload, { observe: 'response' });
  }
}
