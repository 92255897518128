<kendo-dialog #dialogRef title="Upload Address Mapping" (close)="closePopup()" [minWidth]="500" [width]="600"
    [height]="350" class="custom-dialog">
    <div class="row dialog-content">
        <div class="col-md-12">
            
            <div class="col-md-12 sampleDocument">
                <div style=" float: right; margin-top: 10px;">

                    <img src="../assets/vectors/Excel.svg" alt="">
                    <a href="../assets/files/SampleLocation.xlsx" download="SampleLocation.xlsx" class="f-label"
                        style="color: #125AAB; text-decoration: underline; padding-right: 13px;" id="btnSampleRLCS">
                        Sample Document
                    </a>

                    <img src="../assets/vectors/Excel.svg" alt="">
                    <a href="../assets/files/AddressMapping.xlsx" download="AddressMapping.xlsx" class="f-label"
                        style="color: #125AAB; text-decoration: underline; padding-right: 13px;" id="btnSampleRLCS">
                        Master Download
                    </a>

                    <img src="../assets/vectors/Excel.svg" alt="">
                    <a href="../assets/files/AvailableAddress_type.xlsx" class="f-label" download="AvailableAddress_type.xlsx"
                        style="color: #125AAB; text-decoration: underline;" id="btnSampleRLCS">
                        Available Address Type
                    </a>
                </div>
            </div>
        </div>
        <div class="col-md-12 form-group" >
            <div class="drop-zone" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)"
                (drop)="onDrop($event)">
                <div *ngIf="!selectedFile" class="f-label" style="font-size: 18px;">
                    <div class="col-md-12" style="margin-top: -5px;">
                        <img src="../assets/vectors/cloud.svg" alt="" style="height: 100px;">
                    </div>
                    <div class="col-md-8">
                        <label style="margin-left: 150px;">{{ dropzoneMessage }}</label>
                    </div>
                    <div class="col-md-4">
                        <label
                            style="color: #125AAB; text-decoration: underline; cursor: pointer; margin-left: -60px; font-weight: 500;">
                            Browse
                            <input type="file" style="display: none;" (change)="onFileSelect($event)"
                                accept=".xls,.xlsx,.csv" />
                        </label>
                    </div>
                </div>
                <p *ngIf="selectedFile">{{ selectedFile.name }}</p>
            </div>
        </div>
    </div>
   
        <button  class="figma-btn-blue figma-popup custom-button" (click)="uploadFiles()">Upload</button>
    
</kendo-dialog>

<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>


<app-alert-popup *ngIf="alertData" [Width]="270" [Data]="alertData"
    (close)="closeAlertPopup()"></app-alert-popup>