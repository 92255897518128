import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  constructor() { }

  private isEnabledSubject = new BehaviorSubject<boolean>(this.getIsEnabledFromSession());

  isEnabled$ = this.isEnabledSubject.asObservable();

  private getIsEnabledFromSession(): boolean {
    return sessionStorage.getItem('isEnabled') === 'true';
  }

  updateIsEnabled(value: boolean) {
    sessionStorage.setItem('isEnabled', value.toString());
    this.isEnabledSubject.next(value);
  }
}
