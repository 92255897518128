<div class="row colpadding0" style="margin-top: 10px;">
  <div class="col-12 d-flex justify-content-between align-items-center">
    <div class="d-flex searches">
      <div>
        <app-dropdown-list [data]="Acts" [selectedValue]="selectedAct" [width]="'250px'" 
          textField="name" valueField="id" (valueChange)="DropdownActChange($event)">
        </app-dropdown-list>

        <kendo-multiselecttree *ngIf="selectedAct.id != 'CLRA'" 
          #multiselectTree placeholder="Select Branch" kendoMultiSelectTreeExpandable 
          [popupSettings]="popupSettings" [kendoMultiSelectTreeHierarchyBinding]="BranchDropdown" 
          childrenField="children" textField="name" [value]="selectedbranch" valueField="id" 
          [tagMapper]="tagMapper" (valueChange)="OnMultiSelectChange($event)" 
          [expandedKeys]="['0']" class="f-textbox multi-box">
        </kendo-multiselecttree>

        <kendo-multiselecttree *ngIf="selectedAct.id === 'CLRA'" 
          #multiselectTree placeholder="Select Branch" kendoMultiSelectTreeExpandable 
          [popupSettings]="popupSettings" [kendoMultiSelectTreeHierarchyBinding]="clraBranchDropdown" 
          childrenField="children" textField="name" valueField="id" [tagMapper]="clraTagMapper" 
          (valueChange)="OnMultiSelectChange($event)" [expandedKeys]="['0']" 
          class="f-textbox multi-box">
        </kendo-multiselecttree>

        <app-combo-box [width]="'250px'" [data]="allUser" [Placeholder]="defaultUser" 
          [selectedValue]="selectedUser" (filterChange)="loadUser()" 
          (valueChange)="DropdownUserChange($event)">
        </app-combo-box>
      </div>
    </div>

    <!-- Buttons aligned to the right -->
    <div class="d-flex">
      
     <button *ngIf="showClearFilterButton" class="figma-btn-white clear-btn" 
      (click)="clearFilter()" style="margin-left: 10px;">
     <img src="../assets/vectors/ClearFilter.svg" alt="">
     </button>
      <button class="figma-btn-white apply-btn" type="submit" (click)="applyFilter()">
        <img src="../assets/vectors/ApplyFilter.svg" alt="">
      </button>

      <button class="figma-btn-blue map-btn" (click)="mapUser()" style="margin-left: 10px;">
        Map User
      </button>
    </div>
  </div>
</div>


<div class="grid">
  <app-grid-action-item [gridData]="gridData" [columns]="parentColumns" [pageSize]="10" [pageable]="true"
    [actionColumnWidth]="'100px'" [filterable]="true" (buttonClick)="onActionClick($event)">
  </app-grid-action-item>
</div>

<div kendoDialogContainer *ngIf="openUserBranch">
  <app-add-new-user-branch-mapping-popup (dataSaved)="onDataSaved()"
    (close)="closeSaveDialog()"></app-add-new-user-branch-mapping-popup>
</div>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270"  [id]="userId" [Data]="alertData"
  (close)="closePopup()" (confirm)="deleteUserBranch($event)"></app-alert-popup>