<div class="row colpadding0" style="margin-bottom: 15px;">
    <div class="col-md-12 colpadding0 toolbar" style="margin-top: 10px;">
        <input placeholder="Type to Search" class="f-textbox" (keyup)="applyFilter($event)">
        <button class="figma-btn-blue add-new" (click)="openAddNewPopup()" type="submit">
            <img src="../assets/vectors/Plus.svg" alt="">Add New
        </button>
    </div>
</div>
<app-grid-action-item [gridData]="gridData" [columns]="parentColumns" [pageSize]="10" [pageable]="true" 
    [filterable]="true" (buttonClick)="handleButtonClick($event)">
</app-grid-action-item>

<div *ngIf="openAddEdit" class="popup-overlay">
    <app-digital-signature-add-edit-popup [data]="rowData"
        (close)="handleClosePopup()"></app-digital-signature-add-edit-popup>
</div>