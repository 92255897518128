import { CommonModule, NgClass } from '@angular/common';
import { Component,  ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DispenceryMasterService } from '../../../service/dispencery-master.service';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { GridDataResult } from '@progress/kendo-angular-grid';
import {DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

interface State {
  value?: number;
  stateName?: string;
  text?: string;
}

@Component({
  selector: 'app-dispencerymaster-popup',
  standalone: true,
  imports: [CommonModule, FormsModule, DialogModule, ReactiveFormsModule, DropDownListModule, DropdownListComponent,AlertPopupComponent,NgClass,IndicatorsModule],
  templateUrl: './dispencerymaster-popup.component.html',
  styleUrls: ['./dispencerymaster-popup.component.css']
})
export class DispencerymasterPopupComponent implements OnInit {
  @Output() close = new EventEmitter<void>();
  @Input() data!: any;
  loading=false;
  public gridData: GridDataResult = { data: [], total: 0 };
  public dropdown: any[] = [];
  public dropdownDistrict: any[] = [];
  @Input() defaultSelectedValue = { id: null, name: 'Select State' };
  userForm!: FormGroup;
  alertData={alertMessage:'',alertVisible:''}
  selectedState={text:'Select State',value:null};
  selectedDistrict={text:'Select District',value:0};
  disableState=false;
  disableDistrict=false;
  showValidationError=false;
  stateId:any;
  constructor(private fb: FormBuilder, private dispenceryMasterService: DispenceryMasterService) { }
  
  ngOnInit(): void {
    
    this.userForm = this.fb.group({
      stateName: [''],
      dispenceryName: ['', Validators.required],
      districtName: [{ value: '', disabled: this.isEditMode() }, Validators.required],
    });
    this.loadStateDropdown();
    if (this.data && this.data.id) { 
      this.disableState=true;
      this.initData();
    }
    if(this.defaultSelectedValue.id){
      this.selectedState={text:this.defaultSelectedValue.name,value:this.defaultSelectedValue.id}
      this.disableState=true;
      this.disableDistrict=true;
    }
  }
  isEditMode(): boolean {
    return this.data && this.data.id !== undefined;
  }

  loadDistrictDropdown():void{
    this.stateId=this.selectedState.value;
    console.log(this.stateId);
    this.dispenceryMasterService.getAllDistrictsForDispencery(this.stateId).subscribe({
      next: (response) => {
        const data = response.body;
        this.dropdownDistrict = [
          { text: 'Select District', value: '' }, 
          ...data.map((item: any) => ({
            text: item.key,
            value: item.id
          }))
        ];
      },
      error: (error) => {
        console.log('error',error);
          console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }

  loadStateDropdown(): void {
    this.dispenceryMasterService.getAllStatesForDispencery().subscribe({
      next: (response) => {
        const data = response.body;
        this.dropdown = [
          { text: 'Select State', value: null }, 
          ...data.map((item: any) => ({
            text: item.stateName,
            value: item.stateCode
          }))
        ];
      },
      error: (error) => {
          console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }
  
  initData(): void {
    this.userForm.patchValue(this.data);
    this.selectedState={text:this.data.stateName,value:this.data.stateName};
  }

  onDropdownValueChange(value: any) {
    this.selectedState={text:value.text,value:value.value};
    this.loadDistrictDropdown();
  }
  onDropdownDistrictChange(value: any) {
    this.selectedDistrict={text:value.text,value:value.value};
  }
  
  onSubmit(): void {
    debugger;
    this.showValidationError=true;
    if (this.userForm.valid && this.selectedState.text) {
      console.log();
      
      this.loading=true;
      this.dispenceryMasterService.saveDispenceryMasterData(this.userForm.value, this.selectedState.text,this.data.id ).subscribe({
        next: (response) => {
          debugger;
          if(response.body.result=='success'){
            console.log(response);
            
            this.loading=false;
            if (this.data) {
              this.alertData = { alertMessage: 'Dispensary Updated successfully', alertVisible: 'success' }
            } else {
              this.alertData = { alertMessage: 'Dispensary Added successfully', alertVisible: 'success' }
            }
          }else{
            this.loading=false;
            this.alertData = { alertMessage: 'Error while updating Dispensary', alertVisible:'error'};
          }
          this.data = undefined;
        },
        error: (err) => {
          this.loading=false;
          this.alertData = { alertMessage: 'Error while updating Dispensary', alertVisible:'error'};
          console.error('Error submitting user data:', err);
        }
      });
    }else{
      this.userForm.markAllAsTouched();
    }
  }
  closeAlert(){
    if(this.alertData.alertVisible=='success'){
      this.closePopup();
    }
    this.alertData = { alertMessage: '', alertVisible:''};
  }
  closePopup(): void {
    this.selectedState={text:'',value:null};
    this.data = undefined;
    this.close.emit();
  }
}
