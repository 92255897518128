import { NgIf } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { PtSlabServiceService } from '../../../service/pt-slab-service.service';
import { UserService } from '../../../service/user.service';

@Component({
  selector: 'app-challan-bulkupload-popup',
  standalone: true,
  imports: [DialogsModule,NgIf,AlertPopupComponent],
  templateUrl: './challan-bulkupload-popup.component.html',
  styleUrl: './challan-bulkupload-popup.component.css'
})
export class ChallanBulkuploadPopupComponent {
  @Output() close = new EventEmitter<void>();
  alertData = { alertMessage: '', alertVisible: '' }
  selectedFile?: File|'';
  dropzoneMessage: string = "Drag your files here or ";
  constructor(private ptslab:PtSlabServiceService,private user:UserService){}
  public onFileSelect(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
      const file = input.files[0];
      if (this.validateFile(file)) {
        this.selectedFile = file;
        console.log('Selected file:', this.selectedFile);
      } else {
        this.alertData = { alertMessage: 'Invalid file type. Only Excel files are allowed.', alertVisible: 'error' };
      }
    }
  }

  public onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drop your files here or";
  }

  public onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drag your files here or ";
  }

  public onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      if (this.validateFile(file)) {
        this.selectedFile = file;
        console.log('Selected file:', this.selectedFile);
      } else {
        this.alertData = { alertMessage: 'Invalid file type. Only Excel files are allowed.', alertVisible: 'error' };
      }
      event.dataTransfer.clearData();
    }
  }
  private validateFile(file: File): boolean {
    const allowedExtensions = ['xls', 'xlsx', 'csv'];
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    return fileExtension ? allowedExtensions.includes(fileExtension) : false;
  }

  public uploadFiles(): void {
    if (this.selectedFile) {      
    const fileName = this.selectedFile.name;  
    const entityId= this.user.getClientId()
      this.ptslab.uploadFile(this.selectedFile,entityId).subscribe({
        next: (response) => {
        console.log('response',response);      
        if(response.status==='success'){
          this.alertData = { alertMessage: 'File Uploaded Successfully...', alertVisible: 'success' };
        }
        else if(response.status==='error'){
          this.downloadErrorFile(response.filePath, response?.sasToken);
         this.selectedFile=''
        }
        else{
          this.alertData = { alertMessage: response.status, alertVisible: 'error' };
        }
      },
        error: (error) => {
          this.alertData = { alertMessage: error.error, alertVisible: 'error' };
          console.log('error',error);
          
        }
      });
    } else {
      this.alertData = { alertMessage: 'No file selected', alertVisible: 'error' };
    }
  }

  downloadErrorFile(FilePath: string, sasToken: string) {
    const blobUrl = `${FilePath}?${sasToken}`;
    const a = document.createElement('a');
    a.href = blobUrl;
    const filename = FilePath.split('/').pop() || 'downloaded_file';
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }


  public closePopup(): void {
    this.close.emit();
  }
  public closeAlertPopup(): void {
    this.alertData.alertVisible = ''; 
  }
}
