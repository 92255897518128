import { Injectable } from '@angular/core';


import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InvoiceReportService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {

  }
  GetCodeDescriptions(InvoiceRpt:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Invoice/GetCodeDescription?Type=${InvoiceRpt}`;
    // https://localhost:7111/api/Invoice/GetCodeDescription?Type=InvoiceRpt
    return this.http.get<any>(url, {observe:'response'});
  }

  getClientsGstMaster(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Invoice/GetClientsMaster`;
    // https://localhost:7111/api/Invoice/GetClientsMaster
    return this.http.get(url, {observe:'response'});
  }
  GetClientGSTReports(payload:any): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Invoice/GetClientGSTReport?ClientId=${payload.ClientId}`;
    https://localhost:7111/api/Invoice/GetClientGSTReport?clientid=AVACORED
    return this.http.get(url, {observe:'response'});
  }

  

  GetInvoiceReports(payload:any): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Invoice/GetInvoiceReport`;
    // https://localhost:7111/api/Invoice/GetInvoiceReport
    return this.http.post<any>(url, payload, { observe: 'response' });
  }

  GetInvoiceDetailedReports(payload:any): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Invoice/GetInvoiceDetailedReport`;
    // https://localhost:7111/api/Invoice/GetInvoiceDetailedReport
    return this.http.post<any>(url, payload, { observe: 'response' });
  }
  GetCreditNoteDetailedReports(payload:any): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Invoice/GetCreditNoteDetailedReport`;
    // https://localhost:7111/api/Invoice/GetCreditNoteDetailedReport
    return this.http.post<any>(url, payload, { observe: 'response' });
  }
  GetInvoiceReportExcel(payload:any): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Invoice/InvoiceReportToExcel`;
    // https://localhost:7111/api/Invoice/InvoiceReportToExcel
    return this.http.post<any>(url, payload, { observe: 'response' });
  }

  GetInvoiceDetailedReportExcel(payload:any): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Invoice/InvoiceDetailedReportToExcel`;
    // https://localhost:7111/api/Invoice/InvoiceDetailedReportToExcel
    return this.http.post<any>(url, payload, { observe: 'response' });
  }
  GetCreditNoteDetailedReportExcel(payload:any): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Invoice/CreditNoteDetailedReportToExcel`;
    // https://localhost:7111/api/Invoice/CreditNoteDetailedReportToExcel
    return this.http.post<any>(url, payload, { observe: 'response' });
  }



  // exportToExcel(payload:any) {
  //   const url = `${this.apiUrl}/api/LC_Reports/GetComplianceStatusReport_ExporttoExcel?userID=${payload.userID}&profileID=${payload.profileID}&custID=${payload.custID}&distID=${payload.distID}&spID=${payload.spID}&roleCode=${payload.roleCode}&ClientID=${payload.ClientID}`;
  //   return this.http.get(url, { observe: 'response' });
  // }
  
}
