import { Component } from '@angular/core';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { GridDataResult, GridModule, PageChangeEvent} from '@progress/kendo-angular-grid';
import { NgIf } from '@angular/common';
import { ClraAssignmentReportService } from '../../../service/clra-assignment-report.service';
import { UserService } from '../../../service/user.service';
import { BlobService } from '../../../service/blob.service';
import { MultiSelectTreeComponent } from '../../../components/multi-select-tree/multi-select-tree.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { UserBranchMappingService } from '../../../service/user-branch-mapping.service';
import { CommonService } from '../../../service/common.service';


type Branch = {
  id: number;
  name: string;
  parentID: number | null;
  branchType: string;
  branchStatus: string;
  fullName: string | null;
  children?: Branch[];
};
type clraBranch = {
  parentID: number | null;
  id: number;
  name: string | null;
  level: number;
  type: string | null;
  children: clraBranch[];
};
@Component({
  selector: 'app-clra-assignment-report',
  standalone: true,
  imports: [DropdownListComponent,IndicatorsModule,GridModule,NgIf,MultiSelectTreeComponent,DropDownsModule],
  templateUrl: './clra-assignment-report.component.html',
  styleUrl: './clra-assignment-report.component.css'
})
export class CLRAAssignmentReportComponent {
  DisplayDropdownData: any;
  loading: boolean = false;
  public showGrid: boolean = false;
  showValidationErrors = false;
  public buttonCount = 2;
  Take = 10;
  Skip = 0;
  Page = 1;
  SpId:number=0;
  DistId:number=0;
  public CType:string='';
  public PageSize: number = 10;
  selectedBranch: Branch | null = null;  
  public selectedBranches: any[] = [];
  public BranchDropdown: Branch[] = [];
  popupSettings: any = { width: '280px' };
  public clraBranchDropdown: clraBranch[] = [];  
  public sizes = [{ text: 'All', value: 1 }, 5, 10, 20];
  public gridData: GridDataResult = { data: [], total: 0 };
  public alertData = { alertMessage: '', alertVisible: '' };
  selectedComplianceType = { text: 'Select Compliance Type', value: '' };
  

  constructor(private clraAssignmentReportService:ClraAssignmentReportService,private blobService: BlobService,private user:UserService,private common: CommonService, private userBranch: UserBranchMappingService) { }
  ngOnInit(): void {
    this.showGrid=true;
    this.getEntityDropdown();
    this.getComplianceDropdownList();
  }

  onDropdownValueChange(event:any){
    console.log(event);
    this.selectedComplianceType={text:event.text,value:event.value};
    console.log('compliance type',this.selectedComplianceType);
  }

  public tagMapper(tags: any[]): any[] {
    return tags.length < 3 ? tags : [tags];
  }

  onBranchChange(value: any) {
    this.selectedBranch = value.map((branch: any) => branch.id).join(',');
  }

  getEntityDropdown() {
    const entityId = this.user.getClientId();
    this.common.getClraType(entityId).subscribe({
          next: (response) => {

            if (response.body.result === 'CAC') {
              this.userBranch.getCLRABranchDropdown(entityId).subscribe({
                next: (response) => {
                  const data = response.body;
                  if (data && Array.isArray(data)) {
                    this.clraBranchDropdown = this.mapHierarchy(data);
                    const maxLength = this.calculateMaxTextLengthclra(this.clraBranchDropdown);

                    const calculatedWidth = Math.max(280, maxLength * 10);
                    this.popupSettings = { width: `${calculatedWidth}px` };
                  } else {
                    console.error("Unexpected data format", data);
                  }
                }
              })
            }
            else if (response.body.result === 'CAP') {
              this.userBranch.getCLRA_PEBranchDropdown(entityId).subscribe({
                next: (response) => {
                  console.log('SCLRA & FCLRA ', response);
                  const data = response.body;
                  if (data && Array.isArray(data)) {
                    this.clraBranchDropdown = this.mapHierarchy(data);
                    const maxLength = this.calculateMaxTextLengthclra(this.clraBranchDropdown);

                    const calculatedWidth = Math.max(280, maxLength * 10);
                    this.popupSettings = { width: `${calculatedWidth}px` };
                  } else {
                    console.error("Unexpected data format", data);
                  }}})}}})
  }

  getComplianceDropdownList() {
    debugger;
    const type = 'ComplianceType';
    this.clraAssignmentReportService.getComplianceTypeOptions(type).subscribe({
      next: (response) => {
        const data = response.body;
        if (data && data.length) {
          this.DisplayDropdownData = data.map((item: any) => ({
            text: item.text,
            value: item.value
          }));
          console.log('compliance dropdown', this.DisplayDropdownData);
        } else {
          console.error('No data returned for compliance dropdown');
          this.DisplayDropdownData = [];
        }
      },
      error: (err) => {
        console.error('Error fetching compliance dropdown data:', err);
        this.DisplayDropdownData = []; 
      }
    });
  }

  getGridData(value: string, filter: string) {
    debugger;
    this.showGrid=true;
    const UserId=this.user.getUserId();
    const ProfileId=this.user.getUserId().toString();
    const CustId=this.user.getCustomerId();
    const ClientId=this.user.getClientId();
    const RoleCode=this.user.getRole();
    this.CType=this.selectedComplianceType.value;
    this.clraAssignmentReportService.getGridData(UserId,ProfileId,CustId,ClientId,this.DistId,this.SpId,RoleCode,this.CType,'',this.Take,this.Skip,this.Page,this.PageSize).subscribe({
      next: (response) => {
        console.log('Response',response);
        
        if (response.status === 200) {
          const data = response.body
          this.gridData = {
            data:data.data,
            total:data.total
          }
          console.log('griddata',this.gridData);
          this.loading=false;
        }
        else {
          console.error('Error fetching grid data:', response.body.status.error);
        }
      }
    })
  }

  public pageChange(event: PageChangeEvent): void {
    console.log('Page Change Event Received:', event);
    this.Skip = event.skip;
    if (event.take == 1) {
      this.PageSize = this.gridData.total;
    } else {
      this.PageSize = event.take;
    }
    this.applyFilter();
  }

  exportToExcel(): void {
    this.loading = true;
    const UserId=this.user.getUserId();
    const ProfileId=this.user.getUserId().toString();
    const CustId=this.user.getCustomerId();
    const ClientId=this.user.getClientId();
    const RoleCode=this.user.getRole();
    this.CType=this.selectedComplianceType.value;
    
    this.clraAssignmentReportService.getExcelFile(UserId,ProfileId,CustId,ClientId,this.DistId,this.SpId,RoleCode,this.CType,'').subscribe({
     next: (response: any) => {
       console.log('response', response);
 
       if (response.status === 200) {
         const data = response.body
         const blobLink = data.filePath;
         const blobName = this.blobService.extractBlobName(blobLink);
         setTimeout(() => {
           this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
             console.log('tokenResponse', tokenResponse);
 
             debugger;
             const sasToken = tokenResponse.result;
             this.blobService.downloadFile(blobName, sasToken);
             this.loading = false;
           });
         }, 5000);
       }
       else {
         console.error('Error fetching blob link:', response.body.error);
         this.loading = false;
         this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
       }}
   })
  }

  private mapHierarchy(items: any[]): clraBranch[] {
    return items.map((item: any) => {
      const hasChildren = item.children && item.children.length > 0;
      return {
        parentID: item.parentID,
        id: item.id,
        name: item.name || "Unknown",
        level: item.level,
        type: item.type,
        children: hasChildren ? this.mapHierarchy(item.children) : []
      };
    });
  }

  calculateMaxTextLengthclra(data: clraBranch[]): number {
    let maxLength = 0;

    const findMaxLength = (items: clraBranch[]) => {
      items.forEach(item => {
        const nameLength = item.name ? item.name.length : 0;
        maxLength = Math.max(maxLength, nameLength);
        if (item.children && item.children.length) {
          findMaxLength(item.children);
        }
      });
    };
    findMaxLength(data);
    return maxLength;
  }

  applyFilter() {
    // if (this.selectedComplianceType.value) { 
      this.loading = true;
      this.getGridData(this.selectedComplianceType.value, this.selectedBranch ? this.selectedBranch.name : '');
    // } else {
    //   this.showValidationErrors = true; 
    //   console.log('Please select a compliance type before applying the filter.');
    // }
  }

  clearFilter(){
    this.selectedBranches=[];
    this.gridData={data:[],total:0};
    this.selectedComplianceType = { text: 'Select Compliance Type', value: '' }; 
  }
}
