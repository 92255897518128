import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { MultiSelectTreeComponent } from '../../../components/multi-select-tree/multi-select-tree.component';
import { DropDownListModule, DropDownsModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { LabelModule } from '@progress/kendo-angular-label';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { MasterScopingMapFrequencyComponent } from '../master-scoping-map-frequency/master-scoping-map-frequency.component';
import { MasterScopingSetupService, ScopingObjModel } from '../../../service/master-scoping-setup.service';
import { MultiSelectDropdownComponent } from '../../../components/multi-select-dropdown/multi-select-dropdown.component';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { UserService } from '../../../service/user.service';


@Component({
  selector: 'app-master-scoping-add-popup',
  standalone: true,
  imports: [DialogsModule, ReactiveFormsModule, MultiSelectTreeComponent, DropDownListModule, MultiSelectModule, TreeViewModule, LabelModule, DropDownsModule, NgIf, NgFor, DropdownListComponent, MasterScopingMapFrequencyComponent, MultiSelectDropdownComponent, AlertPopupComponent,NgClass],
  templateUrl: './master-scoping-add-popup.component.html',
  styleUrl: './master-scoping-add-popup.component.css'
})
export class MasterScopingAddPopupComponent implements OnInit {
  form!: FormGroup;
  @Output() close = new EventEmitter<void>();
  @ViewChild('multiselectTree') multiselectTree!: MultiSelectTreeComponent;
  @ViewChild(MultiSelectDropdownComponent) multiselectDropdown!: MultiSelectDropdownComponent;
  @ViewChild(MasterScopingMapFrequencyComponent) mapFrequencyComponent!: MasterScopingMapFrequencyComponent;
  EntityDropdown: any[] = []
  showEntities: boolean = false;
  EntityDefaultValue: any = 'Select Entities'
  commaSeparatedEntities: any = ''
  commaSeparatedStates: any = ''
  commaSeparatedActivity: string = ''
  commaSeparatedNature: string = ''
  commaSeparatedActs: string = ''
  showStates: boolean = false;
  showActivities: boolean = false;
  showNature: boolean = false;
  public isChecked: boolean = false;
  public isIndet: boolean = false;
  showAct: boolean = false;
  selectedEntities: any[] = [];
  selectedStates: any[] = [];
  selectedActivities: any[] = [];
  selectedNature: any[] = [];
  selectedActs: any[] = [];
  services: any[] = []
  mandate: any[] = []
  openFrequency = false;
  selectedService: { text: any, value: any } = { text: 'Select Service Type', value: null }
  selectedMandate: { text: any, value: any } = { text: 'Select Mandate', value: null }
  StateDropdown: any[] = []
  ActivityDropdown: any[] = []
  NatureDropdown: any[] = []
  ActDropdown: any[] = []
  public activityFrequencyMapping: any[] = [];
  allSelected = false;
  popupType: string = ''
  popupSettings: any = { width: '280px' };
  public alertData = { alertMessage: '', alertVisible: '' };
  constructor(private masterScopingService: MasterScopingSetupService,private fb: FormBuilder,private user:UserService) { }

  ngOnInit(): void {
    this.getEntities()
    this.getStates()
    this.getService()
    this.initializeMandate();
    this.form = this.fb.group({
      entities:['', [Validators.required]],
      states:['', [Validators.required]],
      service:['', [Validators.required]],
      mandate:['', [Validators.required]],
      frequency:['', [Validators.required]],
      activity:['', [Validators.required]],
      nature:['', [Validators.required]],
      acts:['', [Validators.required]],
    })
  }

  

  public tagMapper(tags: any[]): any[] {
    return tags.length < 3 ? tags : [tags];
  }

  getEntities() {
    // const custId = parseInt(localStorage.getItem('CustomerId') || '0');
    const custId = this.user.getCustomerId() ;

    this.masterScopingService.getEntitiesDropdown(custId ||0, 'no').subscribe({
      next: (response) => {
        console.log('Entity', response);

        const data = response.body;
        this.EntityDropdown = [{ id: 'Select All', name: 'Select All' }, ...data.map((item: any) => ({
          id: item.value,
          name: item.key
        }))];
        this.EntityDefaultValue = [this.EntityDropdown[0].id];
      }
    });
  }

  OnEntitySelectChange(event: any[]) {
    this.selectedEntities = event.map(item => ({
      id: item.id,
      name: item.name
    }));
    this.commaSeparatedEntities = this.selectedEntities.map(item => item.id).join(',');
    this.showEntities = this.selectedEntities.length > 0;

    console.log(this.commaSeparatedEntities);


    const selectAllItem = event.find(item => item.id === 'Select All');

    if (selectAllItem) {
      if (event.length === 1) {
        this.selectedEntities = [];
        this.isChecked = false;
        this.isIndet = false;
      } else if (event.length === this.EntityDropdown.length) {
        this.selectedEntities = [...this.EntityDropdown.filter(item => item.id !== 'Select All')];
        this.isChecked = true;
        this.isIndet = false;
      } else {
        this.selectedEntities = [...this.EntityDropdown.filter(item => item.id !== 'Select All')];
        this.isChecked = false;
        this.isIndet = true;
      }
    } else {
      this.selectedEntities = event;

      if (this.selectedEntities.length === this.EntityDropdown.length - 1) {
        this.isChecked = true;
        this.isIndet = false;
      } else {
        this.isChecked = false;
        this.isIndet = this.selectedEntities.length > 0;
      }}
      this.form.get('entities')?.setValue(event); 
      this.form.get('entities')?.updateValueAndValidity(); 
  }

  onClick(event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;

    this.isChecked = isChecked;
    this.isIndet = false;

    if (isChecked) {
      this.selectedEntities = [...this.EntityDropdown.filter(item => item.id !== 'Select All')];
    } else {
      this.selectedEntities = [];
    }
    this.emitValueChange();
  }
  emitValueChange() {
    if (this.multiselectDropdown) {
      this.multiselectDropdown.valueChange.emit(this.selectedEntities);
    }
  }
  getStates() {
    this.masterScopingService.getStatesDropdown().subscribe({
      next: (response) => {
        console.log('States response', response);
        const data = response.body;

        this.StateDropdown = this.buildHierarchy(
          data.map((item: any) => ({
            id: item.key,
            name: item.value
          })));
      },
      error: (err) => {
        console.error('Error fetching states', err);
      }
    });
  }

  buildHierarchy(data: any[]): any[] {
    return data;
  }

  calculateMaxTextLength(data: any[]): number {
    let maxLength = 0;

    const findMaxLength = (items: any[]) => {
      items.forEach(item => {
        maxLength = Math.max(maxLength, item.name.length);
      });
    };

    findMaxLength(data);
    return maxLength;
  }

  OnMultiSelectStateChange(event: any[]) {
    this.selectedStates = event.map(item => ({
      id: item.id,
      name: item.name
    }));
    this.commaSeparatedStates = this.selectedStates.map(item => item.id).join(',');

    console.log(this.commaSeparatedStates);
    if (this.commaSeparatedActivity && this.commaSeparatedNature && this.commaSeparatedStates) {
      this.getActs();
    }
    this.showStates = this.selectedStates.length > 0;
    this.form.get('states')?.setValue(event); 
    this.form.get('states')?.updateValueAndValidity(); 
  }
  getService() {
    this.masterScopingService.getServiceDropdown().subscribe({
      next: (response) => {
        console.log('Service Response', response);
        const data = response.body;
        this.services = data.map((item: any) => ({
          text: item.key,
          value: item.value
        }))
      }})}

  onServiceChange(value: any) {
    this.selectedService = { text: value.text, value: value.value }
    console.log(this.selectedService.value);

    if (this.selectedService) {
      this.getActivity()
    }
    this.form.get('service')?.setValue(event); 
    this.form.get('service')?.updateValueAndValidity(); 
  }
  initializeMandate() {
    this.mandate = [
      { text: 'Select Mandate', value: null },
      { text: "One Time", value: "One Time" },
      { text: "Recurring", value: "Recurring" },
      { text: "Both", value: "Both" }
    ];
  }

  onMandateChange(value: any) {
    this.selectedMandate = { text: value.text, value: value.value }
    this.form.get('mandate')?.setValue(event); 
    this.form.get('mandate')?.updateValueAndValidity(); 
  }

  getActivity() {
    const service = this.selectedService.value
     this.masterScopingService.getActivityDropdown(service).subscribe({
      next: (response) => {
        console.log('Activity response', response);
        const data = response.body
        this.ActivityDropdown = this.buildHierarchy(
          data.map((item: any) => ({
            id: item.key,
            name: item.value
          }))
        );}})
  }

  OnActivitySelectChange(event: any) {
    this.selectedActivities = event.map((item: any) => ({
      id: item.id,
      name: item.name
    }));
    this.commaSeparatedActivity = this.selectedActivities.map(item => item.name).join(',');
    if (this.commaSeparatedActivity) {

      this.getNatureOfAct()
    }
    console.log(this.commaSeparatedActivity);
    if (this.commaSeparatedActivity && this.commaSeparatedNature && this.commaSeparatedStates) {
      this.getActs();
    }
    this.showActivities = this.selectedActivities.length > 0;
    this.form.get('activity')?.setValue(event); 
    this.form.get('activity')?.updateValueAndValidity(); 
  }

  getNatureOfAct() {
    const activities = this.commaSeparatedActivity
    this.masterScopingService.getNatoreOfActDropdown(activities).subscribe({
      next: (response) => {
        console.log('Nature  response', response);
        const data = response.body
        this.NatureDropdown = this.buildHierarchy(
          data.map((item: any) => ({
            id: item.key,
            name: item.value
          }))
        );
        const maxLength = this.calculateMaxTextLength(this.NatureDropdown);

        const calculatedWidth = Math.max(280, maxLength * 8.5);
        this.popupSettings = { width: `${calculatedWidth}px` };
       }})
  }
  OnNatureSelectChange(event: any) {
    this.selectedNature = event.map((item: any) => ({
      id: item.id,
      name: item.name
    }));
    this.commaSeparatedNature = this.selectedNature.map(item => item.id).join(',');
    if (this.commaSeparatedActivity && this.commaSeparatedNature && this.commaSeparatedStates) {
      this.getActs();
    }
    console.log(this.commaSeparatedNature);
    this.showNature = this.selectedNature.length > 0;
    this.form.get('nature')?.setValue(event); 
    this.form.get('nature')?.updateValueAndValidity(); 
  }

  getActs() {
    const activity = this.commaSeparatedActivity
    const natureOfAct = this.commaSeparatedNature
    const states = this.commaSeparatedStates

    this.masterScopingService.getActDropdown(activity, natureOfAct, states, 'no').subscribe({
      next: (response) => {
        console.log('Act response', response);
        const data = response.body;
        this.ActDropdown = this.buildHierarchy(
          data.map((item: any) => ({
            id: item.actId,
            name: item.actName
          })));
        const maxLength = this.calculateMaxTextLength(this.ActDropdown);

        const calculatedWidth = Math.max(280, maxLength * 7);
        this.popupSettings = { width: `${calculatedWidth}px` };
      }})}
  OnActSelectChange(event: any) {
    this.selectedActs = event.map((item: any) => ({
      id: item.id,
      name: item.name
    }));
    this.commaSeparatedActs = this.selectedActs.map(item => item.id).join(',');
    this.showAct = this.selectedActs.length > 0;
    console.log(this.commaSeparatedActs);
    this.form.get('acts')?.setValue(event); 
    this.form.get('acts')?.updateValueAndValidity(); 
  }
  toggle(dropdownType: any) {
    if (dropdownType === 'Entity') {
      this.showEntities = !this.showEntities;
    }
    else if (dropdownType === 'State') {
      this.showStates = !this.showStates;
    }
    else if (dropdownType === 'Activity') {
      this.showActivities = !this.showActivities;
    }
    else if (dropdownType === 'Nature') {
      this.showNature = !this.showNature;
    }
    else if (dropdownType === 'Act') {
      this.showAct = !this.showAct;
    }
  }
  saveScope() {
    if (this.form.invalid) {
      this.form.markAllAsTouched(); 
      return; 
    }
    // const custId = localStorage.getItem('CustomerId')
    const custId =this.user.getCustomerId();    
    const scopingObj: ScopingObjModel = {
      CustomerId: custId.toString(),
      EntityId: this.commaSeparatedEntities,
      State: this.commaSeparatedStates,
      Service: this.selectedService.value,
      Mandate: this.selectedMandate.value,
      LstActivity: this.commaSeparatedActivity,
      LstActs: this.commaSeparatedActs,
      activityAndFrequencies: this.activityFrequencyMapping
    };
    console.log('Save Response', scopingObj);
    this.masterScopingService.saveMasterScoping(scopingObj).subscribe({
      next: (response) => {
        console.log('Result', response);
        const data = response.body;
        if (response.status === 200) {
          this.alertData = { alertMessage: 'Scope Added Successfully', alertVisible: 'success' };

        }
        else {
          this.alertData = { alertMessage: 'Error while saving scope ', alertVisible: 'error' };
        }}})
  }
  mapFrequency(type: string) {
    this.popupType = type
    this.openFrequency = true

  }
  closeFrequencyDialog() {
    this.openFrequency = false
    this.activityFrequencyMapping = this.masterScopingService.getActivityFrequencyMapping();
    this.activityFrequencyMapping ?  this.form.get('frequency')?.setValue(this.activityFrequencyMapping):'';

    console.log('Activity Frequency Mapping on init:', this.activityFrequencyMapping);
  }
  public closePopup(): void {
    if(this.alertData.alertVisible=='error'){
      this.alertData = { alertMessage: '', alertVisible: '' };
      return;
    }
    this.close.emit();
  }
}
