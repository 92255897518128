import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IndustrySkillDesignationMappingService {
  private apiUrl = environment.apiUrl;
  public selectedState:String='';
  public selectedEstablishment:String='';
  constructor(private http: HttpClient) { }

  getIndustrySkillGridData(): Observable<HttpResponse<any>>{
    const url = `${this.apiUrl}/api/MinimumWage/Get_LC_MW_IndustrySkillDesignation_Mapping`;
    return this.http.get(url,{ observe: 'response' });
  }

  getStateDropdown(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/StateMaster/Get_StateMaster`;
    return this.http.get(url, {observe:'response'});
  }
  getEstablishTypeDropdown(filter: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MinimumWage/Get_LC_Distinct_MW_IndustryType/${filter}`;
    return this.http.get(url, {observe:'response'});
  }
  getSkillCategoryDropdown(filter: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MinimumWage/Get_Distinct_LC_MW_SkillCategory/${filter}`;
    return this.http.get(url, {observe:'response'});
  }
  getDesignationDropdown(filter: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MinimumWage/Get_LC_Distinct_MW_Designation/${filter}`;
    return this.http.get(url, {observe:'response'});
  }
  getIndustryTypeDropdown(filter: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MinimumWage/Get_LC_Distinct_MW_Industry/${filter}`;
    return this.http.get(url, {observe:'response'});
  }
  DeleteIndustry(filter: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MinimumWage/Delete_LC_MW_IndustrySkillDesignation_Mapping/${filter}`;
    return this.http.get(url, { observe: 'response' });
  }
  SaveIndustry(state: string,industryType:string,industry:string,skills:string,designation:string): Observable<HttpResponse<any>> {
    const body = {
      id: 0,
      state: state,
      industryType: industryType.toString(),
      industry: industry.toString(),
      skills:skills,
      designation:designation.toString()
    };
    const url = `${this.apiUrl}/api/MinimumWage/Add_Update_LC_MW_IndustrySkillDesignation_Mapping`;
    return this.http.post(url, body,{observe:'response'});
  }

  uploadFile(file: File, state: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MinimumWage/Upload_LC_MW_IndustrySkillDesignation_Mapping`;
    const formData: FormData = new FormData();
    formData.append('state', state);
    formData.append('file', file, file.name);
  
    return this.http.post<any>(url, formData,{observe:'response'});
  }
}
