import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { DialogModule } from '@progress/kendo-angular-dialog';
declare global {
  interface Window {
    AdobeDC: any;
  }
}
@Component({
  selector: 'app-adobe-viewer',
  standalone: true,
  imports: [DialogModule],
  templateUrl: './adobe-viewer.component.html',
  styleUrl: './adobe-viewer.component.css'
})
export class AdobeViewerComponent implements OnChanges {
  @Input() fileBlob!: Blob;
  @Output() close = new EventEmitter<void>();

  ngOnChanges(changes: SimpleChanges) {
    debugger;
    if (changes['fileBlob'] && this.fileBlob) {
      debugger;
      this.showPdf(this.fileBlob);
    }
  }

  showPdf(file: Blob) {
    const reader = new FileReader();
    reader.onload = () => {
      const adobeDCView = new window.AdobeDC.View({
        clientId: '077cb0470a484b478c9e4c11130d7d4f',
        divId: 'adobe-dc-view',
      });

      adobeDCView.previewFile({
        content: {
          location: {
            url: URL.createObjectURL(file),
          },
        },
        metaData: {
          fileName: 'Document.pdf',
        },
      });
    };
    reader.readAsArrayBuffer(file);
  }

  public closePopup(): void {
    debugger;
    this.close.emit();
  }

}
