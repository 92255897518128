import { Component } from '@angular/core';
// import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { GridDataResult, GridModule, PageChangeEvent } from '@progress/kendo-angular-grid';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { UserService } from '../../../service/user.service';
import { BlobService } from '../../../service/blob.service';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { HttpResponse } from '@angular/common/http';
import { InvoiceApprovalService } from '../../../service/invoice-approval.service';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';

@Component({
  selector: 'app-invoice-approval',
  standalone: true,
  imports: [ComboBoxComponent, IndicatorsModule, AlertPopupComponent, GridModule, DropdownListComponent, ReactiveFormsModule, FormsModule, CommonModule],

  templateUrl: './invoice-approval.component.html',
  styleUrl: './invoice-approval.component.css'
})
export class InvoiceApprovalComponent {
 
  invoiceTypetDropdown: any[] = [];
  selectedInvoiceType = { text: 'Select Report', value: '' };
  InvoiceReport: any;
ClientGstDropdownData: any[] = [];
selectedClient = { text: 'Select Client', value: '' };
clientID!: string;
selectedMonth!: any;
selectedYear!: any;
monthDropdown: any[] = [];
selectedMonthYear: any;
selectedMeasurementSystemDraft!: string;
selectedMeasurementApproveReject!: string;
rowData: any;
public gridData: any;
take = 10;
  skip = 0;
  page = 1;
  public pageSize: number = 10;
  public buttonCount = 2;
  public sizes = [5, 10, 20, 50];
  public AddUpdatePopupOpen: boolean = false;
  showSave!: boolean;
  selectedItems: any[] = [];
  allSelected: boolean = false;
  loading: boolean=false;
  public alertData = { alertMessage: '', alertVisible: '' };
  Appoved: boolean=false;
  rejected: boolean=false;
  sendback: boolean=false;
constructor(private user: UserService, private blobService: BlobService, private InvoiceApprovalService: InvoiceApprovalService) { 

}
ngOnInit(): void {

  this.getClientsMaster();

  this.generateMonthYearDropdown();
  this.getInvoiceType();


}
onDropdownValueChange(data: any) {


  console.log('invoicetype', data)
  this.InvoiceReport = data?.value;
  console.log('client', this.InvoiceReport)



}

onDropdownClientGstValueChange(data: any) {


  console.log('data', data)
  this.clientID = data?.value;
  console.log('client', this.clientID)



}
onMeasurementSystemChanges(event: Event): void {
  const target = event.target as HTMLInputElement;
  this.selectedMeasurementSystemDraft = target.value;
  // this.gridData=[];
  console.log("this.selectedMeasurementSystemDraft",this.selectedMeasurementSystemDraft)
}
onMeasurementSystemChange(event: Event): void {
  const target = event.target as HTMLInputElement;
  this.selectedMeasurementApproveReject = target.value;
  // this.gridData=[];
  this.Appoved=  this.selectedMeasurementApproveReject == 'A' ? true :false;
  this.rejected= this.selectedMeasurementApproveReject == 'R' ? true :false;
  this.sendback= this.selectedMeasurementApproveReject == 'S' ? true :false;
  console.log("this.selectedMeasurementApproveReject",this.selectedMeasurementApproveReject)
}
isRowDataEmpty(): boolean {
  // Check if rowData is an empty object
  // return !this.rowData || Object.keys(this.rowData).length === 0;

  return !this.rowData || Object.keys(this.rowData).length === 0 ||
    this.rowData.some((item: any) => !item.poType);
}
isRowDataEmptys(): boolean {
  // Check if rowData is an empty object
  // return !this.rowData || Object.keys(this.rowData).length === 0;

  return !this.rowData || Object.keys(this.rowData).length === 0 ||
    this.rowData.some((item: any) => !item.poType);
}
 
generateMonthYearDropdown() {
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  const startYear = 2020; // Starting year for the dropdown
  const endYear = 2025;   // Ending year for the dropdown

  for (let year = startYear; year <= endYear; year++) {
    months.forEach((month, index) => {
      this.monthDropdown.push({
        id: `${year}-${index + 1}`,  // Example id: '2023-11'
        name: `${month}-${year}`     // Example name: 'Nov-2023'
      });
    });
  }
}

onDropdownMonthYearValueChange(data: any) {
  this.selectedMonthYear = data;

  // Assuming data.name is in the format "MMM-YYYY" (e.g., "Nov-2023")
  const [monthAbbreviation, selectedYear] = data.name.split('-');

  // Map month abbreviation to its numeric value
  const monthMap: { [key: string]: string  } = {
   Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06',
Jul: '07', Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12'
  };


  const selectedMonth = monthMap[monthAbbreviation];

  console.log('Selected numeric month:', selectedMonth);
  console.log('Selected year:', selectedYear);


  this.selectedMonth = selectedMonth;
  this.selectedYear = +selectedYear;
  this.GetInvoiceGridDetails();
}


getInvoiceType() {

  this.InvoiceApprovalService.getInvoiceType().subscribe({
    next: (response) => {
      const data = response.body;
      this.invoiceTypetDropdown = [
        { text: 'invoice Typet', value: '' },
        ...data.map((item: any) => ({
          text: item.text,
          value: item.value
        }))
      ];
    },
    error: (error) => {
      console.error('Error fetching Branch Dropdown data', error);
    }
  });
}
getClientsMaster() {
  this.InvoiceApprovalService.getClientsGstMaster().subscribe({
    next: (response) => {
      const data = response.body;
      this.ClientGstDropdownData = [
        { text: 'Select Client', value: '' },
        ...data.map((item: any) => ({
          text: item.text,
          value: item.value
        }))
      ];
    },
    error: (error) => {
      console.error('Error fetching Branch Dropdown data', error);
    }
  });
}
public pageChange(event: PageChangeEvent): void {
  console.log('Page Change Event Received:', event);
  this.skip = event.skip;
  this.pageSize = event.take;
  // this.loadGridData();
}
onItemSelectionChange(dataItem: any) {
  console.log('dsfds', dataItem)
  if (dataItem.selected) {
    if (!this.selectedItems.includes(dataItem)) {
      this.selectedItems.push(dataItem);
    }
  } else {
    const index = this.selectedItems.indexOf(dataItem);
    if (index > -1) {
      this.selectedItems.splice(index, 1);
    }
  }
  this.showSave = dataItem.pfecrA_IsProcessed == 'No';
}
toggleSelectAll(event: Event) {
  const checked = (event.target as HTMLInputElement).checked;
  debugger
  // Update the selected state for each item in gridData
  this.gridData.forEach((item: { selected: boolean; }) => item.selected = checked);

  // Toggle bulkdownload and showSave based on checkbox state
  if (checked) {

    this.showSave = true;
  } else {

    this.showSave = false;
  }
}
GetInvoiceGridDetails(): void {
  let payload = {

    // loggedUserid:'',
    invoiceType: this.InvoiceReport,
 
    monthid: String(this.selectedMonth),
    yearid: String(this.selectedYear),
    clientid: this.clientID ? this.clientID : 'all',
    InvGenType:this.selectedMeasurementSystemDraft
    
  };

  this.loading = true;
  this.InvoiceApprovalService.GetInvoiceGridDetail(payload).subscribe({
    next: (response) => {
      this.gridData = response.body.approvalPendingInvoices_lst;
      console.log("dsafds", this.gridData)
      this.loading = false;
    },
    error: (error) => {
      console.error('Error fetching Master Grid data', error);
      this.loading = false;
    }
  });
}
OnSubmitInvoiceApprovelPendingDetails() {
  
  let allSelected = this.selectedItems.every(item => item.selected === true );
  // if (this.selectedItems.length !== 0 && allSelected) {
  //   const isSelect = this.selectedItems.every(item => item.status && item.status != 'S' && item.status != "");
    // if (isSelect) {
    //   console.log(this.selectedItems, 'selecteditemssss')
      // const gstDetails = this.selectedItems.map(item => {
      //   return {
      //     status: item.status,
      //     uniqueId: item.uniqueId
      //   }
      // })
      const payload = {
       
        
          "loggedUserId": "",
          "invoiceType":this.InvoiceReport,
          "clientid": this.clientID ? this.clientID : 'all',
          "yearid": String(this.selectedYear),
          "monthid":String(this.selectedMonth),
          "approve": this.Appoved,
          "cancel": this.rejected,
          "sendback":this.sendback,
          "invoiceGenrtionType": this.selectedMeasurementSystemDraft,
          "rrcinvApprovals_lst": [
            {
              "selected": true,
              "riM_ClientID": this.gridData[0].riM_ClientID,
              "clienT_NAME": this.gridData[0].clienT_NAME,
              "riM_InvoiceID": this.gridData[0].riM_InvoiceID,
              "riM_InvoiceNo": this.gridData[0].riM_InvoiceNo,
              "riA_Amount": String(this.gridData[0].riA_Amount),
              "riS_ServiceTax": "",
              "riS_Swachh_Bharat_Cess": "",
              "riM_Invoice_Amount": String(this.gridData[0].riM_Invoice_Amount),
              "remarks": "",
              "invoiceGenrtionType": this.selectedMeasurementSystemDraft,
            }
          ],
          "clientsMaster_lst": [
            {
              "text": "string",
              "value": "string",
              "sort": 0
            }
          ],
          "approvalPendingInvoices_lst": [
            {
              "srno": 0,
              "riM_InvoiceID": this.gridData[0].riM_InvoiceID,
              "riM_ClientID": this.gridData[0].riM_ClientID,
              "clienT_NAME":this.gridData[0].clienT_NAME,
              "riM_Month": this.gridData[0].riM_Month,
              "riM_Year": this.gridData[0].riM_Year,
              "riM_InvoiceNo": this.gridData[0].riM_InvoiceNo,
              "riM_Invoice_Type": this.gridData[0].riM_Invoice_Type,
              "riM_Type": this.gridData[0].riM_Type,
              "riM_Invoice_Date": this.gridData[0].riM_Invoice_Date,
              "riM_Invoice_Amount": 0,
              "riM_Approved_Status": this.gridData[0].riM_Approved_Status,
              "riM_Approved_Date": this.gridData[0].riM_Approved_Date,
              "riM_ApprovedBy":this.gridData[0].riM_ApprovedBy,
              "riM_Finance_Approved_Status": this.gridData[0].riM_Finance_Approved_Status,
              "riM_Finance_Approved_Date": this.gridData[0].riM_Finance_Approved_Date,
              "riM_Finance_AppeovedBy": this.gridData[0].riM_Finance_AppeovedBy,
              "riM_Narration": this.gridData[0].riM_Narration,
              "riM_Activity_ProjectID": this.gridData[0].riM_Activity_ProjectID,
              "riS_Amount": 0,
              "riS_CGSTAmount": 0,
              "riS_SGSTAmount": 0,
              "riS_IGSTAmount": 0,
              "riA_Amount": 0,
              "riA_Amount_Adjustments": 0,
              "riA_CGSTAmount": 0,
              "riA_CGSTAmount_Adjustments": 0,
              "riA_SGSTAmount": 0,
              "riA_SGSTAmount_Adjustments": 0,
              "riA_IGSTAmount": 0,
              "riA_IGSTAmount_Adjustments": 0,
              "riA_Actual_Amount": 0,
              "riA_Adjustment_Amount": 0,
              "invoiceDescription": this.gridData[0].invoiceDescription,
              "supplyCodeExists": true
            }
          ],
          "invoiceUpdateStatus": true,
          "errorFlag": "string",
          "errorMessage": "string",
          "supplyCodeExists": true
        }
      

      console.log('Payload to send:', payload);


      this.InvoiceApprovalService.SubmitInvoiceApprovalPending(payload).subscribe({
        next: (response) => {
          this.loading = false;
          this.gridData = response.body;
          if (response.body.errorMessage === 'Invoice Approved Successfully') {
            this.alertData = { alertMessage: 'Invoice Approved Successfully', alertVisible: 'success' };
            this.GetInvoiceGridDetails();
          } else {
            this.alertData = { alertMessage: 'Submission Failed', alertVisible: 'error' };
          }
          this.selectedItems=[];
        },
        error: (error) => {
          // Handle error response
          console.error('Error during submission', error);
          this.loading = false;
          this.selectedItems=[];
          this.alertData = { alertMessage: 'Error submitting details', alertVisible: 'error' };
        }
      });
     }
    //  else {
    //   this.alertData = { alertMessage: 'Please Select Approve or Reject for all selected UniqueId ', alertVisible: 'info' };
    // }

  
  // else {
  //   this.alertData = { alertMessage: 'Please Select Atleast One Row', alertVisible: 'info' };
  // }
 



public closeAlert(): void {
  this.alertData = { alertMessage: '', alertVisible: '' };
}
}