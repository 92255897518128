<div class="btn-container">
    <button class="figma-btn-blue" (click)="navigateToInput()" >Back</button>
    <button class="figma-btn-blue" (click)="showTable('output')" >Output (Challans)</button>
    <button class="figma-btn-blue" (click)="showTable('final')" >Final Challan Confirmation</button>
    </div>
    <div *ngIf="activeTable === 'output'" id="outputTable">
        <kendo-grid [data]="challanWorkingList" [pageable]="false" class="grid custom-grid">
        
            <kendo-grid-column field="challanType" title="Compliance" [width]="150">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="col-12 collpadding0 inputName cell-content" [title]="dataItem.challanType">
                    <p>{{ dataItem.challanType }}</p>
                    </div>
                </ng-template>
            </kendo-grid-column>
        
            <kendo-grid-column title="Upload" [width]="230">
                <ng-template kendoGridCellTemplate let-dataItem let-i="rowIndex">
                    <div class="col-12 collpadding0 center-align cell-content">
                    <span style="color: #A0A0A0;">
                        {{ fileNames[i] ? (fileNames[i].length > 10 ? fileNames[i].substring(0, 12) + '..' : fileNames[i]) : 'No file chosen' }}
                    </span>
                    <input type="file" formControlName="Template" #TemplateFile 
                           (change)="onFileChange($event, i)" [id]="'fileInput' + i" style="display: none;" />
                    <button class="figma-btn-gray download-btn"
                            (click)="TemplateFile.click()"
                            [disabled]="!(dataItem.draftUploadButtonStatus === 1)">
                        Browse
                    </button>
                    <button class="figma-btn-blue download-btn"
                            style="color: white; margin-left: 8px;"
                            (click)="uploadFiles(i, dataItem.status)"
                            [disabled]="!(dataItem.draftUploadButtonStatus === 1)">
                        Upload
                    </button>
                    </div>
                </ng-template>
            </kendo-grid-column>
        
            <kendo-grid-column title="Status" [width]="150">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="col-12 collpadding0 center-align cell-content">
                    <app-dropdown-list 
                        [data]="status"
                        [selectedValue]="selectedStatus"
                        textField="text"
                        valueField="value"
                        (valueChange)="onStatusChange($event)"
                        [width]="'150px'"
                        [isDisabled]="true">
                    </app-dropdown-list>
                    </div>
                </ng-template>
            </kendo-grid-column>
        
            <kendo-grid-column title="Download" [width]="120">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="col-12 collpadding0 center-align cell-content">
                    <button class="figma-btn-blue download-btn"
                            [disabled]="!(dataItem.uploadFileExists === 1 && dataItem.uploadFilePath !== '')"
                            (click)="downloadChallan(dataItem.uploadFilePath, 'DC')">
                        Download
                    </button>
                    </div>
                </ng-template>
            </kendo-grid-column>
        
            <kendo-grid-column title="Re-Upload by TL" [width]="250">
                <ng-template kendoGridCellTemplate let-dataItem let-i="rowIndex">
                    <div class="col-12 collpadding0 center-align cell-content">
                    <span style="color: #A0A0A0;">
                        {{ reUploadFileNames[i] ? (reUploadFileNames[i].length > 10 ? reUploadFileNames[i].substring(0, 12) + '..' : reUploadFileNames[i]) : 'No file chosen' }}
                    </span>
                    <input type="file" formControlName="Template" #TemplateFile 
                           (change)="onReuploadFileChange($event, i)" [id]="'fileInput' + i" style="display: none;" />
                    <button class="figma-btn-gray download-btn"
                            (click)="TemplateFile.click()"
                            [disabled]="!(dataItem.reUploadButtonStatus === 1)">
                        Browse
                    </button>
                    <button class="figma-btn-blue download-btn"
                            style="color: white; margin-left: 8px;"
                            (click)="reUploadFiles(i, dataItem.status)"
                            [disabled]="!(dataItem.reUploadButtonStatus === 1)">
                        Upload
                    </button>
                    </div>
                </ng-template>
            </kendo-grid-column>
        
            <kendo-grid-column title="Upload History" [width]="100">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="col-12 collpadding0 center-align cell-content">
                    <img src="../assets/vectors/History.svg" class="svg-icon-btn" alt="" style="cursor:pointer;" (click)="openPerformerHistoryPopup()">
                    </div>
                </ng-template>
                </kendo-grid-column>
           
        </kendo-grid>
        
    </div>
    <div *ngIf="activeTable === 'final'" id="finalChallanTable">
        <kendo-grid [data]="finalChallanConfirmation" [pageable]="false" class="grid custom-grid">
            <kendo-grid-column field="challanType" title="Compliance" [width]="150">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="col-12 collpadding0 inputName cell-content" [title]="dataItem.challanType">
                    <p>{{ dataItem.challanType }}</p>
                </div>
                </ng-template>
            </kendo-grid-column>
        
            <kendo-grid-column title="ECR Challan" [width]="250">
                <ng-template kendoGridCellTemplate let-dataItem let-i="rowIndex">
                    <div class="col-12 collpadding0 center-align cell-content">
                    <span style="color: #A0A0A0;">
                        {{ finalFileNames[i] ? (finalFileNames[i].length > 10 ? finalFileNames[i].substring(0, 12) + '..' : finalFileNames[i]) : 'No file chosen' }}
                    </span>
                    <input type="file" formControlName="Template" #TemplateFile 
                           (change)="onFinalFileChange($event, i)" [id]="'fileInput' + i" style="display: none;" />
                    <button class="figma-btn-gray download-btn"
                            (click)="TemplateFile.click()"
                            [disabled]="!(dataItem.ecRfileUploadEnabled === 1)">
                        Browse
                    </button>
                    <button class="figma-btn-blue download-btn"
                            style="color: white; margin-left: 8px;"
                            (click)="uploadFinalFiles(i)"
                            [disabled]="!(dataItem.ecRfileUploadEnabled === 1)">
                        Upload
                    </button>
                    </div>
                </ng-template>
            </kendo-grid-column>
        
            <kendo-grid-column title="Payment Done" [width]="150">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="col-12 collpadding0 center-align cell-content">
                    <div *ngIf="dataItem.isPaymentDone !== 1">
                        <img src="../assets/vectors/sandclock.svg" class="svg-icon-btn" alt="" style="cursor:pointer;">
                    </div>
                    <div *ngIf="dataItem.isPaymentDone === 1">
                        <img src="../assets/vectors/GreenTick.svg" alt="" class="greentick">
                    </div>
                    </div>
                </ng-template>
            </kendo-grid-column>
        
            <kendo-grid-column title="Confirmation Slips" [width]="150">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="col-12 collpadding0 center-align cell-content">
                    <button class="figma-btn-blue download-btn"
                            [disabled]="!(dataItem.isConfirmationSlipFileExists === 1 && dataItem.confirmationSlipFilePath !== '')"
                            (click)="downloadChallan(dataItem.confirmationSlipFilePath, 'CS')">
                        Download
                    </button>
                    </div>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
        
    </div>
    
    <div *ngIf="loading" class="loader-overlay">
        <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
      </div>
    
      <app-challan-history-performer *ngIf="openHistory" [selectedMonth]="selectedMonth" [selectedYear]="selectedYear" [selectedComplianceId]="selectedComplianceId" (close)="closePerformerHistoryPopup()"></app-challan-history-performer>

      <app-alert-popup *ngIf="alertData.alertVisible" [Width]="250" [Height]="260" [Data]="alertData"
      (close)="closeAlert()"></app-alert-popup>