import { ChangeDetectorRef, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { NgIf } from '@angular/common';
import { MwNotificationService } from '../../../service/mw-notification.service';
import { LocationMasterServiceService } from '../../../service/location-master-service.service';
import { FormsModule } from '@angular/forms';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { NotificationAddPopupComponent } from '../../popups/notification-add-popup/notification-add-popup.component';
import { NotificationIndustryAddPopupComponent } from '../../popups/notification-industry-add-popup/notification-industry-add-popup.component';

@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [GridActionItemComponent, NgIf, FormsModule, AlertPopupComponent,NotificationAddPopupComponent,NotificationIndustryAddPopupComponent],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.css'
})
export class NotificationsComponent {
  public gridData: GridDataResult = { data: [], total: 0 };
  public gridData1: GridDataResult = { data: [], total: 0 };
  public selectionType: string | null = null;
  public defaultState = "Select State";
  public defaultIndustry = { text: 'Select Industry Type', value: null };
  public selectedIndustryType = this.defaultIndustry;
  public selectedIndustry: string | null = null;
  @Output() close = new EventEmitter<void>();
  public openState:boolean=false;
  public openIndustry:boolean=false;

  alertData = { alertMessage: '', alertVisible: '' }

  constructor(private notificationService: MwNotificationService, private locationMaster: LocationMasterServiceService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    if (this.selectionType === 'S') {
      this.fetchStatesData();
    } else if (this.selectionType === 'I') {
      this.fetchIndustryData();
    }
    
  }
  onRadioChange(value: string) {
    this.selectionType = value;
    if (this.selectionType === 'S') {
      this.fetchStatesData();
    } else if (this.selectionType === 'I') {
      this.fetchIndustryData();
    }
  }

  deleteNotification(id: number): void {
    if (this.selectionType === 'S') {
      this.notificationService.deleteMasterNotification(id).subscribe(
        {
          next: (response) => {
              const data = response.body;
                this.alertData = { alertMessage: 'Data Deleted Successfully...', alertVisible: 'success' };
                this.fetchStatesData();
          },error: (error) => {
            console.error('Error deleting notification:', error);
            this.alertData = { alertMessage: 'Failed to delete data', alertVisible: 'error' };
          }
        });
    } else if (this.selectionType === 'I') {
      this.notificationService.deleteIndustryMasterNotification(id).subscribe(
        {
          next: (response) => {
            console.log('Industry notification deleted successfully:', response);
            this.alertData = { alertMessage: 'Data Deleted Successfully...', alertVisible: 'success' };
            this.fetchIndustryData();
          },error: (error) => {
            console.error('Error deleting industry notification:', error);
            this.alertData = { alertMessage: 'Faild to delete data', alertVisible: 'error' };
          }
        });
    }
  }


  onActionClick(event: { dataItem: any, button: any }): void {
    console.log('Button Action:', event.button, 'Data Item:', event.dataItem);
    if (event.button.action === 'delete') {
      if (confirm('Are you sure you want to delete this item?')) {
        this.deleteNotification(event.dataItem.id);
      }
    }
  }

  public closePopup(): void {
    this.alertData = { alertMessage: '', alertVisible: '' };
    this.close.emit();
  }


  DropdownValueChange(value: any) {
    if (this.selectionType === 'I') {
      this.selectedIndustry = this.selectedIndustryType.value || '';
      console.log(this.selectedIndustry);

      this.fetchIndustryData();
    }

  }

  fetchStatesData() {
    debugger;
    this.notificationService.getMasterNotification().subscribe(
      {
        next: (response) => {
            const data = response.body;
            const transformedData = data.map((item: any) => ({
                  ...item,
                  notificationInfo: `[${item.id}]  ${item.notification_Number}`
                }));
                this.gridData = {
                  data: transformedData,
                  total: transformedData.length
                };
                this.cdr.detectChanges();  
        },error(error) {
          console.error("Error fetching data", error);
        },
      }
    );

  }


  fetchIndustryData(): void {
    debugger;
    const industryParam = this.selectedIndustry ?? '';
    console.log(`Selected Industry: ${industryParam}`);

    this.notificationService.getIndustryMasterNotification(industryParam).subscribe(
      {
        next: (response) => {
            const data = response.body;
            const transformedData = data.map((item: any) => ({
              ...item,
              notificationInfo: `[${item.id}]  ${item.notification_Number}`
            }));
            this.gridData1 = {
              data: transformedData,
              total: transformedData.length
            };
            this.cdr.detectChanges();
        },error: (error) => {
          console.error('Error fetching states data', error);
        }
      });
  }



  addNotification():void{
   this.openState=true;
  }

  addIndustryNotification():void{
   this.openIndustry=true;
  }

  onDataSaved(): void {
    this.fetchStatesData();
  }
  onIndustryDataSaved(): void {
    this.fetchIndustryData();
  }

  public closeSaveDialog():void{
    if (this.selectionType === 'S'){
      this.openState=false;
      this.fetchStatesData();

    }
   else if (this.selectionType === 'I')
   {
     this.openIndustry=false;     
    this.fetchIndustryData();
   }
    
  }





  public parentColumns: any[] = [
    {
      field: 'notificationInfo',
      title: 'Notification Id'
    },
    { field: 'state', title: 'State' },
    { field: 'effective_From', title: 'Effective From' },
    { field: 'effective_To', title: 'Effective To' },
    { field: 'notification_Number', title: 'Notification Number' },
    { field: 'notified_On', title: 'Notified On' },
    { field: 'created_On', title: 'Created On' },

    {
      field: 'Action',
      isIconColumn: true,
      title: 'Action',
      filteredIcons: [

        {
          url: '../assets/vectors/delete.svg',
          alt: 'Delete Icon',
          action: 'delete'
        }
      ]
    }
  ];


  public parentColumns1: any[] = [
    { field: 'sM_Name', title: 'State' },
    { field: 'notificationInfo', title: 'Notification Id' },
    { field: 'industry', title: 'Industry Type',width: '250px' },
    { field: 'effective_From', title: 'Effective From' },
    { field: 'effective_To', title: 'Effective To' },
    { field: 'notification_Number', title: 'Notification Number' },
    { field: 'notified_On', title: 'Notified On' },

    {
      field: 'Action',
      isIconColumn: true,
      title: 'Action',
      filteredIcons: [

        {
          url: '../assets/vectors/delete.svg',
          alt: 'Delete Icon',
          action: 'delete'
        }
      ]
    }
  ];
}
