    <div class="row colpadding0" style="margin-bottom: 15px; margin-top: 15px;">
        <div class="col-lg-12 col-md-8 colpadding0">
            <kendo-multiselecttree #multiselectTree placeholder="Entity/State/Location/Branch"
                kendoMultiSelectTreeExpandable [popupSettings]="popupSettings"
                [kendoMultiSelectTreeHierarchyBinding]="BranchDropdown" childrenField="children" textField="name"
                valueField="id" [tagMapper]="tagMapper" (valueChange)="OnMultiSelectChange($event)" [expandedKeys]="['0']" 
                class="f-textbox multi-box">
            </kendo-multiselecttree>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-5 col-md-5">
           <kendo-chart [style]="{ height: '420px', width: '420px' }">
                <kendo-chart-title text="Client Compliance Summary" class="label" ></kendo-chart-title>
                <kendo-chart-legend position="bottom"></kendo-chart-legend>
                <kendo-chart-tooltip [background]="'white'">
                    <ng-template kendoChartSeriesTooltipTemplate let-dataItem="dataItem" let-series="series">
                        <strong>{{ dataItem.category }}</strong>: {{ calculatePercentage(dataItem.value) }}
                    </ng-template>
                </kendo-chart-tooltip>
                <kendo-chart-series>
                    <kendo-chart-series-item
                    class="chart"
                        type="pie"
                        [data]="pieData"
                        field="value"
                        categoryField="category"
                        [labels]="{
                            visible: true,
                            position: 'center',
                            content: labelContentview,
                            background: 'transparent',
                            padding: { top: -25, right: -10}
                           
                        }"
                      
                        >
                    </kendo-chart-series-item>
                </kendo-chart-series>
                <kendo-chart-series-defaults [overlay]="{ gradient: 'none' }"></kendo-chart-series-defaults>
            </kendo-chart>
        </div>

        <div class="col-lg-7 col-md-7">
            <kendo-chart [style]="{ height: '420px' }"> 
                <kendo-chart-title text="Location Compliance Summary" class="label"></kendo-chart-title>
                <kendo-chart-category-axis>
                    <kendo-chart-category-axis-item [categories]="categories" [labels]="{ rotation: 'auto' }" class="chart">
                    </kendo-chart-category-axis-item>
                </kendo-chart-category-axis>
                <kendo-chart-series>
                    <kendo-chart-series-item type="column" [stack]="{ type: '100%' }" [data]="notCompliedData" color="red" class="chart"
                        name="Not Complied" [labels]="{
                            visible: true,
                            format: '{0}%',
                            position: 'center',
                            background: 'transparent'
                        }">
                        <kendo-chart-series-item-labels>
                            <ng-template kendoChartSeriesItemLabelsTemplate let-dataItem let-seriesItem="seriesItem">
                                <svg width="100" height="30">
                                    <text x="50%" y="50%" text-anchor="middle" dominant-baseline="middle" fill="white"
                                        stroke="black" stroke-width="1">
                                        {{ dataItem }}%
                                    </text>
                                </svg>
                            </ng-template>
                        </kendo-chart-series-item-labels>
                    </kendo-chart-series-item>
                    <kendo-chart-series-item type="column" [stack]="{ type: '100%' }" [data]="compliedData" color="#72bf6a"
                        name="Complied" [labels]="{
                            visible: true,
                            format: '{0}%',
                            position: 'center',
                            background: 'transparent'
                        }">
                        <kendo-chart-series-item-labels>
                            <ng-template kendoChartSeriesItemLabelsTemplate let-dataItem let-seriesItem="seriesItem">
                                <svg width="100" height="30">
                                    <text x="50%" y="50%" text-anchor="middle" dominant-baseline="middle" fill="white"
                                        stroke="black" stroke-width="1">
                                        {{ dataItem }}%
                                    </text>
                                </svg>
                            </ng-template>
                        </kendo-chart-series-item-labels>
                    </kendo-chart-series-item>
                </kendo-chart-series>
                <kendo-chart-legend position="bottom" orientation="horizontal" [class]="'custom-legend'"></kendo-chart-legend>
                <kendo-chart-tooltip>
                    <ng-template kendoChartSeriesTooltipTemplate let-category="category" let-series="series"
                        let-dataItem="dataItem">
                        <strong>{{ category }}</strong><br />
                        <div>
                            <strong>{{ series.name }}: {{ dataItem }}%:</strong>
                        </div>
                    </ng-template>
                </kendo-chart-tooltip>
            </kendo-chart>
        </div>
    </div>
