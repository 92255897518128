import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InvoiceDetailsService {
  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getInvoiceDetails(ClientId:string): Observable<HttpResponse<any>>{
    const url = `${this.apiUrl}/api/Invoice/InvoiceDetails?ClientId=${ClientId}`;
    return this.http.get(url,{ observe: 'response' });
  }
  getInvoiceDetails_ExporttoExcel(ClientId:string): Observable<HttpResponse<any>>{
    const url = `${this.apiUrl}/api/Invoice/InvoiceDetails_ExporttoExcel?ClientId=${ClientId}`;
    return this.http.get(url,{ observe: 'response' });
  }
  getInvoiceType(): Observable<HttpResponse<any>>{
    const url = `${this.apiUrl}/api/Invoice/GetCodeDescription?Type=InvoiceType`;
    return this.http.get(url,{ observe: 'response' });
  }
  getClientDetails(Type:string): Observable<HttpResponse<any>>{
    const url = `${this.apiUrl}/api/Invoice/ClientsMaster?InvoiceType=${Type}`;
    return this.http.get(url,{ observe: 'response' });
  }
  getInvoiceCreation(payload:any): Observable<HttpResponse<any>>{
    const url = `${this.apiUrl}/api/Invoice/GetInvoiceCreationData?InvoiceType=${payload.InvoiceType}&Clientid=${payload.Clientid}&Monthid=${payload.Monthid}&Yearid=${payload.Yearid}&viewtype=${payload.viewtype}&InvoiceGenertionType=${payload.InvoiceGenertionType}`;
    return this.http.get(url,{ observe: 'response' });
  }
  getClientMasterDetails(): Observable<HttpResponse<any>>{
    const url = `${this.apiUrl}/api/Invoice/GetClientsMaster`;
    return this.http.get(url,{ observe: 'response' });
  }
  getDraftInvoice(payload:any): Observable<HttpResponse<any>>{
    const url = `${this.apiUrl}/api/Invoice/GetApprovedDraftInvoiceDetails?Clientid=${payload.Clientid}&Month=${payload.Month}&Year=${payload.Year}`;
    return this.http.get(url,{ observe: 'response' });
  }
  getDownload(): Observable<HttpResponse<any>>{
    const url = `${this.apiUrl}/api/Invoice/GetBusinessSubTypeUpload`;
    return this.http.get(url,{ observe: 'response' });
  }
}
