import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { GridModule } from '@progress/kendo-angular-grid';
import { EmployeeHistoryPopupComponent } from '../employee-history-popup/employee-history-popup.component';
import { ManageEmployeeService } from '../../../service/manage-employee.service';
import { NgIf } from '@angular/common';
import * as XLSX from 'xlsx';
import { UserService } from '../../../service/user.service';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { BlobService } from '../../../service/blob.service';
@Component({
  selector: 'app-employee-select-bulk-upload',
  standalone: true,
  imports: [DialogModule, GridModule, ReactiveFormsModule, FormsModule, EmployeeHistoryPopupComponent, NgIf, AlertPopupComponent, IndicatorsModule],
  templateUrl: './employee-select-bulk-upload.component.html',
  styleUrls: ['./employee-select-bulk-upload.component.css']
})
export class EmployeeSelectBulkUploadComponent {
  @Output() close = new EventEmitter<void>();
  selectedItems: any[] = [];
  TabOption: string = 'Selected';
  take = 10;
  public pageSize = 10;
  public skip = 0;
  public buttonCount = 5;
  sizes = 10;
  loading = false;
  EmployeeHistoryPopup = false;
  public gridData: any[] = [];
  InputName!: string;
  InputID!: string;
  IsFileRequired!: number
  DisplayUploadFlag!: number
  IsUploaded!: number
  IsValidated!: number
  isProcessed!: number
  IsUpdate: number = 0;
  fileNames: { [key: string]: string | null } = {
    BulkUpload: null
  };
  selectedFile?: File | null;
  allSelected: boolean = false;
  myGroup: FormGroup;
  clientID!: string
  public headerTrackerId: any;
  alertData = { alertMessage: '', alertVisible: '' };
  ErrorFile!: string;
  constructor(private employeeUploadService: ManageEmployeeService, private user: UserService, private blobService: BlobService) {
    this.myGroup = new FormGroup({
      BulkUpload: new FormControl('')
    });
  }

  ngOnInit(): void {
    this.loading = true;
    this.clientID = this.user.getClientId();
    this.loadGridData();
    this.getUploadStatus();
    this.headerTrackerId = sessionStorage.getItem('headerTrackerId');
  }
  getUploadStatus() {
    this.employeeUploadService.getBulkPopupUploadStatus(this.clientID, this.IsUpdate).subscribe(
      {
        next: (response) => {
          console.log('status', response);

          const data = response.body; this.InputName = data[0].inputName;
          this.InputID = data[0].inputId;
          this.IsFileRequired = data[0].isFileRequired;
          this.DisplayUploadFlag = data[0].displayUploadFlag;
          this.IsUploaded = data[0].isUploaded;
          this.IsValidated = data[0].isValidated;
          this.isProcessed = data[0].isProcessed;
          this.ErrorFile = data[0]?.errorPath;
        }, error: (error) => {
          console.error('Error fetching State Dropdown data', error);
        }
      })
  }
  downlaodError() {
    debugger
    this.loading = true;
    if (this.ErrorFile) {
      const blobLink = this.ErrorFile;
      const blobName = this.blobService.extractBlobName(blobLink);
      setTimeout(() => {
        debugger
        this.blobService.getSasToken(blobName).subscribe(
          (tokenResponse: any) => {
            try {
              const sasToken = tokenResponse.result;
              this.blobService.downloadFile(blobName, sasToken);
              this.loading = false;
            } catch (error) {
              this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
              this.loading = false;
            }
          },
          (error) => {
            console.log(error);

            this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
            this.loading = false;
          }
        );
      }, 5000);
    } else {
      this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
      this.loading = false;
    }
  }
  loadGridData() {
    this.selectedItems = [];
    this.employeeUploadService.getSelectUploadGrid().subscribe(
      {
        next: (response) => {
          console.log(response);

          const data = response.body;
          if (data && data && Array.isArray(data)) {
            this.gridData = data.map((item: any) => {
              const isDefaultSelected = item.colName === 'EM_ClientID' || item.colName === 'EM_EmpID';

              if (isDefaultSelected) {
                item.selected = true;
                item.disabled = true;
                this.selectedItems.push(item);
              } else if (item.selected) {
                this.selectedItems.push(item);
              }
              return {
                ...item,
                selected: item.selected || isDefaultSelected,
                disabled: item.disabled || isDefaultSelected
              };
            });
            this.loading = false;
          }
        }, error: (error) => {
          this.loading = false;
          console.error('Error fetching grid data:', error);
        }
      });
  }

  onItemSelectionChange(dataItem: any) {
    if (dataItem.selected) {
      if (!this.selectedItems.includes(dataItem)) {
        this.selectedItems.push(dataItem);
      }
    } else {
      this.selectedItems = this.selectedItems.filter(item => item.colName !== dataItem.colName);
    }
  }

  downloadCSVTemplate() {
    debugger;
    sessionStorage.setItem('headerTrackerId', '');
    const defaultColumns = ['EM_ClientID', 'EM_EmpID'];

    const onlyDefaultColumnsSelected = this.selectedItems.length > 0 &&
      this.selectedItems.every(item => defaultColumns.includes(item.colName));

    const selectedItems = onlyDefaultColumnsSelected
      ? this.gridData.map(item => item)
      : this.selectedItems;

    const columnsToInclude: string[] = [];
    const headersToInclude: string[] = [];

    for (let i = 0; i < selectedItems.length; i++) {
      const item = selectedItems[i];
      columnsToInclude.push(item.colName.toLowerCase());
      headersToInclude.push(item.clientHeaderName.toLowerCase());
    }

    const columnList = columnsToInclude.join(',');
    const headerList = headersToInclude.join(',');

    this.employeeUploadService.insertDynamicUploadHeaders(columnList, headerList).subscribe(
      {
        next: (response) => {
          const data = response.body;
          this.headerTrackerId = data.result;
          sessionStorage.setItem('headerTrackerId', data.result)
          console.log('Header Tracker ID:', this.headerTrackerId);

          const workbook = XLSX.utils.book_new();
          const worksheetData = [headersToInclude];
          const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

          worksheet['!cols'] = headersToInclude.map((col) => {
            return { wch: col.length };
          });
          const redHeaders = ['ClientID', 'EM_EmpID', 'Emp Name'];
          headersToInclude.forEach((header, index) => {
            console.log('Checking Header:', header);
            if (redHeaders.includes(header)) {
              const cellRef = XLSX.utils.encode_cell({ c: index, r: 0 });
              if (!worksheet[cellRef]) {
                worksheet[cellRef] = { v: header, t: 's' };
              }

              worksheet[cellRef].s = {
                fill: {
                  fgColor: { rgb: "FF800000" }
                },
                font: { color: { rgb: "FFFFFF" }, bold: true },
              };
            }
          });

          XLSX.utils.book_append_sheet(workbook, worksheet, 'EmployeeMaster');

          const timestamp = new Date().toISOString().replace(/[-:.]/g, '');
          const clientID = this.user.getClientId();
          const filename = `EmployeeMaster_${clientID}_${timestamp}.xlsx`;

          XLSX.writeFile(workbook, filename);
        }, error: (error) => {
          console.error('Error inserting dynamic upload headers:', error);
        }
      });
  }


  toggleSelectAll(event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    this.gridData.forEach(item => {
      if (checked) {
        item.selected = true;
      } else {
        if (item.colName !== 'EM_ClientID' && item.colName !== 'EM_EmpID') {
          item.selected = false;
        }
      }
    });
  }

  openHistoryPopup() {
    this.EmployeeHistoryPopup = true;
  }

  uploadFile() {
    debugger
    this.loading = true;
    if (!this.headerTrackerId) {
      this.selectedFile = undefined;
      this.fileNames['BulkUpload'] = '';
      console.error('Header Tracker ID is missing.');
      this.alertData.alertMessage = 'Please download the template first.';
      this.alertData.alertVisible = 'error';
      this.loading = false;
      return;
    }

    const file = this.selectedFile;
    if (file) {
      const clientID = this.user.getClientId();
      const custId = this.user.getCustomerId();
      const userId = this.user.getUserId();
      const IsUpdate = 0;

      const formData: FormData = new FormData();
      formData.append('file', file, file.name);
      formData.append('clientID', clientID.toString());
      formData.append('inputID', this.InputID);
      formData.append('CustID', custId.toString());
      formData.append('UserID', userId.toString());
      formData.append('HeaderTrackerId', this.headerTrackerId.toString());
      formData.append('IsUpdate', IsUpdate.toString());

      this.employeeUploadService.BulkUpload(formData)
        .subscribe({
          next: (response) => {
            console.log('upload response', response);
            if (response.status == 200 && response.body.status == 'success') {
              this.alertData = { alertMessage: 'File uploaded Successfully!', alertVisible: 'success' };
              this.selectedFile = null;
              this.fileNames['BulkUpload'] = '';
              this.loading = false;
            } else {
              this.selectedFile = null;
              this.fileNames['BulkUpload'] = '';
              if (response.body?.filePath) {
                const data = response.body;
                const blobLink = data.filePath;
                const blobName = this.blobService.extractBlobName(blobLink);
                setTimeout(() => {
                  this.blobService.getSasToken(blobName).subscribe(
                    (tokenResponse: any) => {
                      try {
                        const sasToken = tokenResponse.result;
                        this.blobService.downloadFile(blobName, sasToken);
                        this.loading = false;
                      } catch (error) {
                        this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
                        this.loading = false;
                      }
                    },
                    (error) => {
                      this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
                      this.loading = false;
                      this.selectedFile = null;
                      this.fileNames['BulkUpload'] = '';
                    }
                  );
                }, 5000);
              } else {
                this.alertData = { alertMessage: response.body.status, alertVisible: 'error' };
                this.selectedFile = null;
                this.fileNames['BulkUpload'] = '';
              }
              this.loading = false;
            }
            setTimeout(() => {
              this.getUploadStatus();
            }, 5000);
          },
          error: (error) => {
            this.selectedFile = null;
            console.error('Error uploading file:', error);
            this.alertData = { alertMessage: 'File upload failed!', alertVisible: 'error' };
            this.loading = false;
          }
        });
    } else {
      this.alertData = { alertMessage: 'Please select a file to upload.', alertVisible: 'error' };
      this.loading = false;
    }

  }

  onFileChange(event: any, type: string) {
    const fileInput = event.target;
    const file = fileInput.files[0];
    
    if (file) {
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
  
      if (fileExtension === 'xlsx') {
        this.fileNames[type] = file.name;
        this.selectedFile = file;
      } else {
        this.fileNames[type] = null;
        this.selectedFile = null;
        this.alertData = { alertMessage: 'Please upload XLSX file only', alertVisible: 'error' };
      }
    } else {
      this.fileNames[type] = null;
      this.selectedFile = null;
      this.alertData = { alertMessage: 'Please upload XLSX file only', alertVisible: 'error' };
    }
    fileInput.value = '';
  }
  refreshData() {
    this.getUploadStatus();
  }
  resetData(): void {
    this.gridData.forEach(item => {
      if (!item.disabled) {
        item.selected = false;
      }
    });
    this.selectedFile = null;
    this.fileNames['BulkUpload'] = '';
    if (this.allSelected) {
      this.allSelected = false;
    }
    this.loadGridData();
  }
  closehistory() {
    this.EmployeeHistoryPopup = false;
  }
  closeAlert() {
    this.alertData = { alertMessage: '', alertVisible: '' };
  }

  closePopup() {
    this.InputName = '';
    this.InputID = '';
    this.IsFileRequired = 0;
    this.DisplayUploadFlag = 0;
    this.IsUploaded = 0;
    this.IsValidated = 0;
    this.isProcessed = 0;
    this.close.emit();
  }
}
