<div>
    <div class="align">
        <!-- <div *ngFor="let item of radioButtonValue" class="radio-row">
            <kendo-radiobutton [value]="item.value" [(ngModel)]="selectedValue"
                (ngModelChange)="onValueChange($event)"></kendo-radiobutton>
            <kendo-label [for]="item.value" [text]="item.text" class="labelhight"></kendo-label>
        </div> -->

        <ul style="display: flex; padding: 0px;">
            <li *ngFor="let item of radioButtonValue" class="radio-row">
              <div style="display: flex;">
                <input
                  type="radio"
                  [id]="item.value"
                  [value]="item.value"
                  [(ngModel)]="selectedValue"
                  (change)="onValueChange(item.value)"
                  [checked]="selectedValue === item.value" 
                  style="cursor: pointer;"/>
                <div style="margin-top: 2px;" class="labelhight">{{ item.text }}</div>
              </div>
            </li>
          </ul>
    </div>
</div>

<form [formGroup]="form" style="margin-bottom: 10px;">
    <div class="colpadding0 row-setting">
        <div class="col-12 d-flex align-items-center" style="flex-wrap:nowrap;">            
            <div class="col-sm-2 colpadding0" style="padding-right: 5px">
                <app-dropdown-list class="dropdown-setting" [data]="complianceType" [width]="'100%'"
                style="width: 100%" [selectedValue]="selectedComplianceType" textField="text"
                valueField="value" (valueChange)="onComplianceTypeChange($event)" formControlName="compliance"
                [ngClass]="{'is-invalid': form.get('compliance')?.invalid && form.get('compliance')?.touched}">
            </app-dropdown-list>
            </div>    
            <div class="col-sm-2 colpadding0" style="padding-right: 5px">
                <app-dropdown-list class="dropdown-setting" [data]="frequency" [width]="'100%'"
                style="width: 100%"[selectedValue]="selectedFrequency" textField="text"
                valueField="value" (valueChange)="onFrequencyTypeChange($event)" formControlName="frequency"
                [ngClass]="{'is-invalid': form.get('frequency')?.invalid && form.get('frequency')?.touched}"
                [isDisabled]="selectedComplianceType.value==='Register' || selectedComplianceType.value==='Challan' || selectedComplianceType.value==='Compliance'">
            </app-dropdown-list>
            </div>
            <div class="col-sm-4 colpadding0" style="padding-right: 5px">
                <app-dropdown-list class="dropdown-setting" [data]="Act" [width]="'100%'"
                style="width: 100%" [selectedValue]="selectedAct" textField="text" valueField="value"
                (valueChange)="onActTypeChange($event)" formControlName="act"
                [ngClass]="{'is-invalid': form.get('act')?.invalid && form.get('act')?.touched}">
            </app-dropdown-list>
            </div>
            <div class="col-sm-2 colpadding0" style="padding-right: 5px">
                <app-dropdown-list class="dropdown-setting" [data]="years" [width]="'100%'"
                style="width: 100%"[selectedValue]="selectedYear" textField="text" valueField="value"
                (valueChange)="onYearChange($event)" formControlName="year"
                [ngClass]="{'is-invalid': form.get('year')?.invalid && form.get('year')?.touched}">
            </app-dropdown-list>
            </div>
            <div class="col-sm-2 colpadding0" style="padding-right: 5px">
                <app-dropdown-list class="dropdown-setting" [data]="period" [width]="'100%'"
                style="width: 100%" [selectedValue]="selectedPeriod" textField="text" valueField="value"
                (valueChange)="onPeriodChange($event)" formControlName="period"
                [ngClass]="{'is-invalid': form.get('period')?.invalid && form.get('period')?.touched}">
            </app-dropdown-list>
            </div>
            
        </div>
    </div>

    <!-- <div class="colpadding0 row-setting mt-3 mb-2">


        <div *ngIf="selectedValue === 'Compliance'">
            <kendo-multiselecttree #multiselectTree placeholder="All Files" kendoMultiSelectTreeExpandable
                [kendoMultiSelectTreeHierarchyBinding]="files" formControlName="files" textField="name" valueField="id"
                [tagMapper]="tagMapper" (valueChange)="onFileChange($event)" [expandedKeys]="['0']"
                [popupSettings]="popupSettings" class="f-textbox multi-box custom-multiselect">
            </kendo-multiselecttree>
        </div>

        <div >
            <kendo-multiselecttree #multiselectTree placeholder="Entity/Sub-Entity/Location"
                kendoMultiSelectTreeExpandable
                [kendoMultiSelectTreeHierarchyBinding]="(selectedAct.value === 'SEA' || selectedAct.value === 'FACT' || selectedComplianceType.value === 'Return' || selectedComplianceType.value === 'Challan') ? BranchDropdown : clraBranchDropdown"
                childrenField="children" textField="name" valueField="id"
                [tagMapper]="(selectedAct.value === 'SEA' || selectedAct.value === 'FACT') ? tagMapper : clraTagMapper"
                (valueChange)="onEntityChange($event)" [expandedKeys]="['0']" [popupSettings]="popupSettings"
                class="f-textbox multi-box custom-multiselect-other">
            </kendo-multiselecttree>
        </div>

        <div class="col-md-3 d-flex justify-content-between">
            <button class="figma-btn-white apply-btn" type="submit" (click)="applyFilter()">
                <img src="../assets/vectors/ApplyFilter.svg" alt="Apply Filter">
            </button>
            <button class="figma-btn-white clear-btn" (click)="clearFilter()">
                <img src="../assets/vectors/ClearFilter.svg" alt="Clear Filter">
            </button>
            <button *ngIf="selectedItems.length > 0" class="figma-btn-blue proceed-btn">Proceed</button>
        </div>

    </div> -->

    <div class="col-12" style="margin-top: 10px;display: flex;justify-content: center;">
        <div class="col-md-8 colpadding0">
            
            <!-- <div class="col-md-4 colpadding0" style="padding-right: 10px" *ngIf="selectedValue === 'Compliance'">
                <kendo-multiselecttree   style="padding-right: 8px; width: 100%" #multiselectTree [placeholder]="filePlaceholder" kendoMultiSelectTreeExpandable
                [kendoMultiSelectTreeHierarchyBinding]="files" formControlName="files" textField="name" valueField="id"
                [tagMapper]="tagMapper" (valueChange)="onFileChange($event)" [expandedKeys]="['0']"
                [popupSettings]="popupSettings" class="f-textbox multi-box custom-multiselect">
            </kendo-multiselecttree>
            </div> -->
            
            <div class="col-md-3 colpadding0" style="padding-right: 5px">
                <app-dropdown-list [data]="complianceStatus" [selectedValue]="selectedStatus "
                [width]="'100%'" style="width: 100%" textField="text"  valueField="value"
                (valueChange)="oncomplianceStatus($event)" formControlName="status">
              </app-dropdown-list>
            </div>
        
            <div class="col-md-4 colpadding0" style="padding-right: 10px">
                <kendo-multiselecttree #multiselectTree [placeholder]="entityPlaceholder"
                style="padding-right: 8px; width: 100%"
                formControlName="entity"
                kendoMultiSelectTreeExpandable
                [kendoMultiSelectTreeHierarchyBinding]="(selectedAct.value === 'SEA' || selectedAct.value === 'FACT' || selectedComplianceType.value === 'Return' || selectedComplianceType.value === 'Challan') ? BranchDropdown : clraBranchDropdown"
                childrenField="children" textField="name" valueField="id"
                [tagMapper]="(selectedAct.value === 'SEA' || selectedAct.value === 'FACT') ? tagMapper : clraTagMapper"
                (valueChange)="onEntityChange($event)" [expandedKeys]="['0']" [popupSettings]="popupSettings"
                class="f-textbox multi-box custom-multiselect-other">
            </kendo-multiselecttree>
            </div>
           
        </div>
    
          <div class="col-md-4 colpadding0" style="display: flex;justify-content: end;">
            <div >
                <button class="figma-btn-white apply-btn" type="submit" (click)="applyFilter()">
                    <img src="../assets/vectors/ApplyFilter.svg" alt="Apply Filter">
                </button>
            </div>
            <div>
                <button class="figma-btn-white clear-btn" (click)="clearFilter()">
                    <img src="../assets/vectors/ClearFilter.svg" alt="Clear Filter">
                </button>
            </div>
            <div *ngIf="selectedItems.length > 0" style="padding-left: 8px;">
                <button class="figma-btn-blue proceed-btn">Proceed</button>
                <!-- <button  class="figma-btn-blue" (click)="GetInputfileisProcess()" >Proceed</button> -->
            </div>
        </div>

    </div>
</form>

<!-- <app-lazy-tree-list
  [data]="treeData"
  (fetchChildren)="onFetchChildrens($event)"
></app-lazy-tree-list> -->

<app-lazy-tree-list *ngIf="gridData" [rootData]="gridData" [payload]="payload" [parentColumns]="parentColumns"
    (imageClick)="handleImageClick($event)"></app-lazy-tree-list>




<!-- <div *ngIf="selectedComplianceType.value==='Challan'" class="grid">
    <kendo-grid [data]="challanGridData" [columnMenu]="true" [pageSize]="pageSize" [pageable]="true"
        (pageChange)="pageChange($event)" [sortable]="true" [skip]="skip" [resizable]="true">
        <kendo-grid-column [width]="60">
            <ng-template kendoGridHeaderTemplate>
                <div class="cell-content">
                    <input type="checkbox" style="margin-left: 10px;" [(ngModel)]="allSelected"
                        (change)="toggleSelectAll($event,'Challan')" />
                </div>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="checkbox-container">
                    <input type="checkbox" style="margin-left: 10px;" [checked]="dataItem.selected"
                        [disabled]="dataItem.disabled" (change)="onItemSelectionChange(dataItem,'Challan')" />
                </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="complianceID" title="Compliance ID" [width]="140"></kendo-grid-column>
        <kendo-grid-column field="complianceName" title="Compliance Description" [width]="350"></kendo-grid-column>
        <kendo-grid-column field="form" title="Format" [width]="120"></kendo-grid-column>
        <kendo-grid-column field="frequency" title="Frequency" [width]="120"></kendo-grid-column>
        <kendo-grid-column field="Action" title="Input File" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="action-icon" (click)="onActionClick(dataItem)">
                    <img src="../assets/vectors/eye_112_x2.svg" alt="Action Icon"
                        style="cursor: pointer; width: 24px; height: 24px;" />
                </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="branchCount" title="Branch Count" [width]="120"></kendo-grid-column>
    </kendo-grid>
</div> -->

<!-- <app-tree-grid *ngIf="isactive"  [data]="filteredGridData" [parentIdField]="'filteredGridData.complianceID'" [columns]="parentColumns" [idField]="'filteredGridData.id'"
          (buttonClick)="onActionClick($event)"  [height]="'530px'"></app-tree-grid> -->



<!-- <app-tree-grid-lazyloading *ngIf="isactive" [data]="filteredGridData" [parentIdField]="'filteredGridData.complianceID'"
    [columns]="parentColumns" [idField]="'filteredGridData.id'" (buttonClick)="onActionClick($event)"
    [height]="'530px'"></app-tree-grid-lazyloading>
[data]="filteredGridData"
[parentIdField]="'filteredGridData'"
[idField]="'id'"
[height]="'400px'"
[columns]="parentColumns"
(buttonClick)="onActionClick($event)" -->


<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>