import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Legend, LegendLabels, LegendLabelsContentArgs, MarkerType, SeriesClickEvent, SeriesLabelsContentArgs, SeriesMarkers } from "@progress/kendo-angular-charts";
import { KENDO_CHARTS} from '@progress/kendo-angular-charts';
import { PerformanceSummaryPopupComponent } from '../../popups/performance-summary-popup/performance-summary-popup.component';
import { UserService } from '../../../service/user.service';

@Component({
  selector: 'app-cd-performance-summary',
  standalone: true,
  imports: [KENDO_CHARTS,PerformanceSummaryPopupComponent],
  templateUrl: './cd-performance-summary.component.html',
  styleUrl: './cd-performance-summary.component.css'
})
export class CdPerformanceSummaryComponent implements OnChanges {

  @Input() chartData: any = {};
  public data: any[]= [];
  isOpenComPopup: boolean = false;
  acts: any[] = [];
  Type:any;

  legend: Legend = {
    position: "bottom",
  };
  constructor( private userService: UserService) {

  }

  public labelContent(e: SeriesLabelsContentArgs): string {
    // return `${e.category}: \n ${e.value}%`;
    return '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.chartData = changes['chartData']['currentValue']
    
    this.data = [
    {
      kind: "Compiled",
      share: this.chartData?.totalPieCompletedcount,
      color: "#65B677"
    },
    {
      kind: "Not Compiled",
      share: this.chartData?.totalPieNotCompletedCount,
      color: "#FA6462"
    },
    {
      kind: "Not Applicable",
      share: this.chartData?.totalPieNotApplicablecount,
      color: "#FED81F"
    }
  ];
  }

  onSeriesClick(e: SeriesClickEvent): void {
    console.log('Clicked Category:', e.category); // Logs the clicked category
    console.log('Clicked Value:', e.value);       // Logs the clicked value
    this.isOpenComPopup = true;
    this.Type = e;
  }
  onClosePopup(){
    console.log("Popup closed");
    this.isOpenComPopup = false;
    
  }

}
