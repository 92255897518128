<div class="row colpadding0 " style="margin-bottom: 15px; margin-top: 15px;">
    <div class="col-md-8 colpadding0">
        <input kendoTextBox placeholder="Type to Search" class="f-textbox" (keyup)="applyFilter($event)"
           >
    </div>
    <div class="col-md-4 colpadding0 button-container">
        <button class="figma-btn-blue" (click)="openAddNewPopup()" type="submit">
            <img src="../assets/vectors/Plus.svg" alt="">Add New
        </button>
        <button (click)="openExport()" class="exportbtn">
            <img src="../assets/vectors/ExportButton.svg" alt="">
        </button>
    </div>
</div>
<app-grid-action-item [gridData]="gridData" [columns]="parentColumns" [pageSize]="10" [pageable]="true" 
    [filterable]="true" (buttonClick)="handleButtonClick($event)">
</app-grid-action-item>

<app-registration-repository-add-edit-popup *ngIf="openAddEditPopup" [data]="rowData"
    (close)="ClosePopup()"></app-registration-repository-add-edit-popup>
<!-- <app-repository-add-edit-popup *ngIf="openAddEditPopup" [data]="rowData"
    (close)="ClosePopup()"></app-repository-add-edit-popup> -->
<app-client-registration-repository-export-popup *ngIf="openExportPopup" [data]="rowData"
(close)="CloseExportPopup()"></app-client-registration-repository-export-popup>

<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>