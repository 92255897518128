<kendo-dialog #dialogRef title="Details" (close)="closePopup()" [width]="950" class="custom-dialog">
    <form [formGroup]="form">
        <div class="row FileContainer">
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label me-3">Name<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <input type="text" class="form-control f-textbox" placeholder="Name" formControlName="name">
                </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label me-3">Type<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <input type="text" class="form-control f-textbox" placeholder="Type" formControlName="type"
                        [disabled]="true">
                </div>
            </div>
        </div>

        <div class="row FileContainer">
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label me-3">Created By<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <input type="text" class="form-control f-textbox" placeholder="Created By"
                        formControlName="createdBy">
                </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label me-3">Created Date<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <input type="text" class="form-control f-textbox" placeholder="Another Type"
                        formControlName="createdOn" [disabled]="true">
                </div>
            </div>
        </div>

        <div class="row FileContainer">
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label me-3">File Created Date<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <app-kendo-datepicker class="uniform-size " [(value)]="fileCreatedDate"
                        (valueChange)="onDateChange($event, 'fileCreatedDate')" [format]="'dd-MM-yyyy'"
                        [placeholder]="placeholder" [width]="'250px'"
                        [class.input-invalid]="!fileCreatedDate && showValidationErrors">
                    </app-kendo-datepicker>
                </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label me-3">Modified Date<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <input type="text" class="form-control f-textbox" placeholder="Another Type"
                        formControlName="updatedOn" [disabled]="true">
                </div>
            </div>
        </div>
        <div class="row FileContainer">
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label me-3">Header<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <input type="text" class="form-control f-textbox" placeholder="Enter Header"
                        formControlName="doc_Header"
                        [ngClass]="{'is-invalid': form.get('doc_Header')?.invalid && (form.get('doc_Header')?.touched)}">
                </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label me-3">Description<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <input type="text" class="form-control f-textbox" placeholder="Enter Description"
                        formControlName="doc_Description"
                        [ngClass]="{'is-invalid': form.get('doc_Description')?.invalid && (form.get('doc_Description')?.touched)}">
                </div>
            </div>
        </div>
        <div class="row FileContainer">
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label me-3">Others<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <input type="text" class="form-control f-textbox" placeholder="Enter Others"
                        formControlName="others"
                        [ngClass]="{'is-invalid': form.get('others')?.invalid && (form.get('others')?.touched)}">
                </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label me-3">Process<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <input type="text" class="form-control f-textbox" placeholder="Enter Process"
                        formControlName="process"
                        [ngClass]="{'is-invalid': form.get('process')?.invalid && (form.get('process')?.touched)}">
                </div>
            </div>
        </div>
        <div class="row FileContainer">
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label me-3">Sub Process<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <input type="text" class="form-control f-textbox" placeholder="Enter Sub Process"
                        formControlName="subProcess"
                        [ngClass]="{'is-invalid': form.get('subProcess')?.invalid && (form.get('subProcess')?.touched)}">
                </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label me-3">Vertical<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <input type="text" class="form-control f-textbox" placeholder="Enter Vertical"
                        formControlName="vertical"
                        [ngClass]="{'is-invalid': form.get('vertical')?.invalid && (form.get('vertical')?.touched)}">
                </div>
            </div>
        </div>
        <div class="row FileContainer">
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label me-3">Location<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <input type="text" class="form-control f-textbox" placeholder="Enter Location"
                        formControlName="location"
                        [ngClass]="{'is-invalid': form.get('location')?.invalid && (form.get('location')?.touched)}">
                </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
                <!-- <label class="f-label me-3">Tags<sub class="text-danger">*</sub></label> -->
                <div class="require-container">
                    <!-- <kendo-multiselect
                    [data]="tags"                      
                    [value]="selectedTags"             
                    (valueChange)="onTagAdd($event)"   
                    [textField]="'name'"   
                    [valueField]="'id'"            
                    [placeholder]="'Document related tags'"
                    [allowCustom]="true"
                    [tagMapper]="mapCustomTag">           
                </kendo-multiselect> -->
                    <!-- <kendo-multiselect class="f-textbox " [data]="tags" [value]="selectedTags"
                (valueChange)="onTagAdd($event)" [textField]="'name'" [valueField]="'id'"
                [placeholder]="'Document related tags'" [allowCustom]="true" (click)="preventDropdown($event)">
            </kendo-multiselect> -->
                </div>
            </div>
        </div>

    </form>

    <kendo-dialog-actions class="ksave">
        <button type="button" (click)="onUpdate()" class="figma-btn-blue">
            Update Information
        </button>

    </kendo-dialog-actions>

    <!-- <div *ngIf="loading" class="loader-overlay">
        <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
    </div> -->
</kendo-dialog>

<!-- <app-alert-popup *ngIf="alertData.alertVisible" [Width]="260" [Height]="280" [Data]="alertData" (close)="closeAlert()"></app-alert-popup> -->