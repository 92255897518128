import { Component, OnInit } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { GridDataResult, GridModule } from '@progress/kendo-angular-grid';
import { Router, RouterOutlet } from '@angular/router';
import { CustomerMasterAddEditPopupComponent } from '../../popups/customer-master-add-edit-popup/customer-master-add-edit-popup.component';
import { UserService } from '../../../service/user.service';
import { CustomerMasterService } from '../../../service/customer-master.service';
import { PageChangeEvent } from '@progress/kendo-angular-treelist';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { MasterTabsComponent } from '../../../components/master-tabs/master-tabs.component';
import { ActivityCalenderComponent } from '../../master-scoping/activity-calender/activity-calender.component';
import { EntityMasterComponent } from '../entity-master/entity-master.component';
import { AgreementDetailsMasterComponent } from '../../master-scoping/agreement-details-master/agreement-details-master.component';
import { CommercialMappingComponent } from '../../master-scoping/commercial-mapping/commercial-mapping.component';
import { MasterScopingSetupComponent } from '../../master-scoping/master-scoping-setup/master-scoping-setup.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-customer-master',
  standalone: true,
  imports: [NgClass, NgIf, ComboBoxComponent, GridActionItemComponent, CustomerMasterAddEditPopupComponent, GridModule, AlertPopupComponent,MasterTabsComponent,ActivityCalenderComponent,EntityMasterComponent,AgreementDetailsMasterComponent,CommercialMappingComponent,MasterScopingSetupComponent,IndicatorsModule],
  templateUrl: './customer-master.component.html',
  styleUrl: './customer-master.component.css'
})
export class CustomerMasterComponent implements OnInit {
  EmployeeOption='Corporate';
  showOtherTabs=false;
  currentRout!:string;
  public dropdown: { id: number; name: string }[] = [];
  public defaultSelectedValue = 'Search Customer Name/Select Corporate ID';
  selectedValue:any;
  public gridData: GridDataResult = { data: [], total: 0 };
  public pageSize: number = 10;
  public page: number = 1;
  public buttonCount = 2;
  public sizes = [{text:'All',value:Infinity},5, 10, 20];
  showAddEditPopup = false;
  role!: string;
  distID!: number;
  spID!: number;
  userID!: number;
  custID!: number;
  take = 10;
  skip = 0;
  IsSPDist = 1
  abc: any;
  CustomerData!: any
  alertData = { alertMessage: '', alertVisible: '' };
  selectedCorporateID: number = 0;
  deleteID!: number;
  loadingGrid=false;
  showError=false;
  constructor(private router: Router, private user: UserService, private CustomerMasterService: CustomerMasterService) { }
  ngOnInit(): void {
    this.role = this.user.getRole();
    this.userID = this.user.getUserId();
    this.spID = this.user.getCustomerId();
    this.custID = this.user.getCustomerId();
    this.currentRout=this.router.url;
    if (this.role == "DADMN" || this.role == "PERF" || this.role == "RVW1") {
      this.IsSPDist = 1
    } else if (this.role == "IMPT") {
      this.IsSPDist = 2;
    } else if (this.role == "CADMN") {
      this.IsSPDist = 3;
    }
    this.loadGridData(0);
 
  }
  loadGridData(value: number): void {
    debugger
    this.loadingGrid = true;
    this.CustomerMasterService.getCustomerGridData(this.userID, -1, this.spID, this.custID, this.role, 2, true, value, this.take, this.skip, this.page, this.pageSize).subscribe({
      next: (response) => {
        console.log(response);
  
        if (response.status === 200) {
          const data = response.body;
          const modifiedData = data.data.map((item: { Status: string; }) => ({
            ...item,
            Status: item.Status === 'S' ? 'Suspended' : item.Status
          }));
  
          this.gridData = {
            data: modifiedData,
            total: data.total
          };
        } else {
          console.error('Error fetching entity data');
        }
        this.loadingGrid = false;
      },
      error: (error) => {
        this.loadingGrid = false;
        console.error('Error fetching entity data', error);
      }
    });
  }
  goTo(value:string){
    this.router.navigate([value]);
  }
  public pageChange(event: PageChangeEvent): void {
    debugger
    this.loadingGrid=true;
    this.skip = event.skip;
    if(event.take==Infinity){
      this.pageSize = this.gridData.total;
      this.loadingGrid=false;
    }
    else{
      this.loadingGrid=false;
      this.pageSize = event.take;
    }
    this.page = (this.skip / this.pageSize) + 1;
    this.loadingGrid=false;
    this.loadGridData(0);
  }
  confirmDelete(value: any) {
    this.deleteID = value.id;
    this.alertData = { alertMessage: 'Are you sure you want to delete this Customer?', alertVisible: 'confirm' };
  }
  deleteCustomerBranch(value: number) {
    this.CustomerMasterService.deleteCustomerBranch(value).subscribe({
      next: (response:any) => {
        if (response.result === this.deleteID) {
          this.alertData = { alertMessage: 'Customer Deleted Successfully', alertVisible: 'success' };
          this.loadGridData(0);
        }
        else {
          this.alertData = { alertMessage: 'Customer Branch Deletion Failed', alertVisible: 'error' };
        }
      },
      error: (error) => {
        console.error('Error fetching entity data', error);
        this.alertData = { alertMessage: 'Customer Branch Deletion Failed', alertVisible: 'error' };
      }
    });

  }

  FilterDropdown(value: string) {
    this.CustomerMasterService.getCustomerDropdown(this.userID, -1, this.custID, this.spID, this.role, 2, true, value)
        .subscribe({
            next: (response) => {
                const data = response.body;
                if (data && data.data) {
                    const filteredData = data.data.filter((item: any) =>
                        item.key.toLowerCase().startsWith(value.toLowerCase())
                    );
                    this.dropdown = filteredData.map((item: any) => ({
                        id: item.value,
                        name: item.key
                    }));
                } else {
                    console.error("Unexpected data format", data);
                }
            },
            error: (error) => {
                console.error("Error fetching dropdown data", error);
            }
        });
}
  BackButton() {
    this.router.navigate(['/EntityDashboard']);
  }
  OpenAddNewPopup(value: any) {
    this.CustomerData = value;
    this.showAddEditPopup = true
  }
  goToEntity(value:any) {
    localStorage.setItem('CustomerId', value.id);
    localStorage.setItem('CorporateId', value.corporateId);
    this.user.setCustomerId(value.id);
    this.user.setCustomerName(value.corporateId);
    this.EmployeeOption='Entity';
    this.showOtherTabs=true;
  }
  applyFilter() {
    debugger
    if (this.selectedCorporateID != 0) {
      this.loadGridData(this.selectedCorporateID);
      this.showError=false;
    } else {
      this.showError=true;
    }
  }
  clearFilter() {
    this.loadGridData(0);
    this.selectedCorporateID = 0;
    this.selectedValue=null;
  }
  DropdownGridChange(value: any) {
    this.selectedCorporateID = value.id;
    // value.id ? this.showError=false:this.showError=true;
  }
  closePopup() {
    this.showAddEditPopup = false;
    this.loadGridData(this.selectedCorporateID);
  }
  closeAlert() {
    this.alertData = { alertMessage: '', alertVisible: '' };
  }
  public parentColumns: any[] = [
    { field: 'id', title: 'Corporate ID', width:'50px' },
    { field: 'Name', title: 'Name' },
    { field: 'Buyer Contact', title: 'Buyer Contact' },
    { field: 'Buyer Email', title: 'Buyer Email' },
    { field: 'Status', title: 'Status' },
    {
      field: 'Action',
      isIconColumn: true,
      title: 'Action',
      filteredIcons: [
        {
          url: '../assets/vectors/edit.svg',
          alt: 'Icon 1',
          action: 'edit'
        },
        {
          url: '../assets/vectors/delete.svg',
          alt: 'Icon 2',
          action: 'Delete'
        },
        {
          url: '../assets/vectors/RightArrowButton.svg',
          alt: 'Icon 3',
          action: 'gotoEntity'
        },
      ]
    }
  ];
}
