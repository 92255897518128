
import { NgFor, NgIf } from '@angular/common';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { PoDetailsService } from '../../../service/po-details.service';
import { EcrMasterAddEditorPopupComponent } from '../../popups/ecr-master-add-editor-popup/ecr-master-add-editor-popup.component';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { GridDataResult, GridModule, PageChangeEvent } from '@progress/kendo-angular-grid';
import { FormsModule } from '@angular/forms';
import { KendoDatepickerComponent } from '../../../components/kendo-datepicker/kendo-datepicker.component';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { HttpResponse } from '@angular/common/http';
import { DropDownsModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { MultiSelectDropdownComponent } from "../../../components/multi-select-dropdown/multi-select-dropdown.component";
import { BlobService } from '../../../service/blob.service';
import { UserService } from '../../../service/user.service';
import { PerformanceSummaryPopupService } from '../../../service/performance-summary-popup.service';
type Branch = {
  id: number;
  name: string;
  parentID: number | null;
  branchType: string;
  branchStatus: string;
  fullName: string | null;
  children?: Branch[];
};

@Component({
  selector: 'app-performance-summary-popup',
  standalone: true,
  imports: [ DropDownsModule, MultiSelectModule,MultiSelectDropdownComponent,AlertPopupComponent, KendoDatepickerComponent, ReactiveFormsModule, FormsModule, IndicatorsModule, DropdownListComponent, NgIf, NgFor, EcrMasterAddEditorPopupComponent, DialogModule, GridModule],
  templateUrl: './performance-summary-popup.component.html',
  styleUrl: './performance-summary-popup.component.css'
})
export class PerformanceSummaryPopupComponent {
  @Input() acts: any[] = [];
  @Input() Type: any;
  public buttonCount = 2;
  public sizes = [{text:'All',value:1},5, 10, 20];
  public gridData: GridDataResult = { data: [], total: 0 };
  public pageSize: number = 10;
  public skip: number = 0;   
  clientID!: string;
  alertData = { alertMessage: '', alertVisible: '' };
  @Output() close = new EventEmitter<void>();
  @Input() data: any;


  ClientDropdownData: any[] = [];
  selectedClient = { text: 'Select Client', value: '' };
  loading: boolean=false;
  complianceType: Array<{ text: any, value: any }> = [
    { text: 'Compliance', value: 'Compliance' } 
  ];
  selectedComplianceType: { text: any, value: any } = { text: 'Compliance', value: 'Compliance' }
  public selectBranchs: any[] = [];
  selectBranchstypes: { text: any, value: any } = { text: 'Various Type', value: 'Various Type' }
  public BranchDropdown: Branch[] = [];
  
  selectBranch: any;
  multiSelectEntities: any[] = [];
  popupSettings: any = { width: '280px' };
  selectedYear: any[] = [];
  years: any[] = [];
  selectedFiscalYear: any;
  complianceperiods: any[] = [];
  public defaultSelectedValueMonth: any[] = [];
  selectedPeriod: any[] = [];
  public dropdownDataMonth: any[] = [];
  public showValidationErrors = false;


  constructor(private cdr: ChangeDetectorRef, private blobService: BlobService, private user: UserService, private PerformanceSummaryService: PerformanceSummaryPopupService,) {
  }
  ngOnInit(): void {
    this.getYears();
    
  }

  oncomplianceTypeChange(value: any) {
    this.selectedComplianceType = value;
    console.log('Selected Compliance Type', this.selectedComplianceType);
  }
  onMultiSelectChange(selected: any[]) {
   

    this.multiSelectEntities = selected.map(item => item.id);

    console.log('Selected Names: ', this.multiSelectEntities);
  }

  selectedyears: any[] = [];
  onYearChange(year: any) {
    this.selectedFiscalYear = year;
    this.selectedyears = year.map((item: { value: any; }) => item.value);
    // this.selectedFiscalYear = year.map((item: { value: any; }) => item.value);
    console.log('this.selectedFiscalYear', this.selectedFiscalYear)
  }

  getYears(): void {
    const currentYear = new Date().getFullYear();
    this.years = [];
    for (let year = currentYear; year >= 2017; year--) {
      this.years.push({ text: year.toString(), value: year });
    }
  }

  selectedPeriods: any[] = [];
  onPeriod(period: any) {
    this.selectedPeriod = period;
    this.selectedPeriods = period.map((item: { value: any; }) => Number(item.value));
    console.log('period', this.selectedPeriod)
  }
 public tagMapper(tags: any[]): any[] {
    return tags.length < 3 ? tags : [tags];
  }

  getComplianceType() {
    const type = 'ComplianceName';
    this.PerformanceSummaryService.getCompliance(type).subscribe({
      next: (response) => {
        const data = response.body;
        console.log('Compliance Type', data);
        const fetchedCompliance = data.map((item: any) => ({
          text: item.text,
          value: item.value
        }));
        this.complianceType = [
          { text: 'Compliance', value: 'Compliance' }, 
          ...fetchedCompliance
        ];
      }
    });
  }


  getBranchDropdownData() {
    const entityId = this.user.getClientId();

    this.PerformanceSummaryService.getBranchDropdown(entityId).subscribe({
      next: (response) => {
        console.log('branch dropdown data', response);
        const data = response.body
        if (data && Array.isArray(data)) {
          this.BranchDropdown = this.buildHierarchy(data);
          const maxLength = this.calculateMaxTextLength(this.BranchDropdown);
          const calculatedWidth = Math.max(238, maxLength * 12);
          this.popupSettings = { width: `${calculatedWidth}px` };
          console.log('BranchDropdown', this.BranchDropdown)
        } else {
          console.error('Unexpected data format', data);
        }
      }
    })


  }
  calculateMaxTextLength(data: Branch[]): number {
    let maxLength = 0;

    const findMaxLength = (items: Branch[]) => {
      items.forEach(item => {
        maxLength = Math.max(maxLength, item.name.length);
        if (item.children && item.children.length) {
          findMaxLength(item.children);
        }
      });
    };

    findMaxLength(data);
    return maxLength;
  }

  buildHierarchy(data: Branch[]): Branch[] {
    const grouped: { [key: number]: Branch[] } = data.reduce((acc, item) => {
      (acc[item.parentID || -1] = acc[item.parentID || -1] || []).push(item);
      return acc;
    }, {} as { [key: number]: Branch[] });

    function buildTree(parentID: number | null): Branch[] {
      return (grouped[parentID || -1] || []).map(item => ({
        ...item,
        children: buildTree(item.id)
      }));
    }

    return buildTree(null);
  }

  loadMonthYearData() {
    this.PerformanceSummaryService.getMonth('Months').subscribe({
      next: (response) => {
        const data = response.body;
        console.log("period", data);
        this.dropdownDataMonth = data.map((item: any) => ({
          value: item.displayvalue,
          text: item.displayvalue
        }));
      
      },
      error: (error) => {
        console.error('Error fetching Month Dropdown data', error);
      }
    });

  }


  public pageChange(event: PageChangeEvent): void {
    console.log('Page Change Event Received:', event);
    this.loading=true;
    this.skip = event.skip;
    if(event.take==1){
      this.pageSize = this.gridData.total;
    }else{
      this.pageSize = event.take;
    }
    
  }
  closePopup() {
    this.data = undefined;
    this.close.emit();
  }
  closeAlert() {
    this.alertData = { alertMessage: '', alertVisible: '' };
  }
}
