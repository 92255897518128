import { Component } from '@angular/core';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { GridDataResult, GridModule, PageChangeEvent } from '@progress/kendo-angular-grid';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { UserService } from '../../../service/user.service';
import { BlobService } from '../../../service/blob.service';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { HttpResponse } from '@angular/common/http';
import { InvoiceFaceSheetService } from '../../../service/invoice-face-sheet.service';


@Component({
  selector: 'app-invoice-face-sheet',
  standalone: true,
  imports: [ComboBoxComponent, IndicatorsModule, AlertPopupComponent, GridModule, DropdownListComponent, ReactiveFormsModule, FormsModule, CommonModule],
  templateUrl: './invoice-face-sheet.component.html',
  styleUrl: './invoice-face-sheet.component.css'
})
export class InvoiceFaceSheetComponent {
  rowData: any;
  selectedMeasurementSystemInvoicetype!: string;
  selectedMeasurementSystemDraft!: string;
  ClientGstDropdownData: any[] = [];
  selectedClient = { text: 'Select Client', value: '' };
  clientID!: string;
  monthDropdown: any[] = [];
  selectedMonthYear: any;
  selectedMonth!: any;
  selectedYear!: any;
  loading: boolean = false;
  public showGrid: boolean = false;
  originalData: any;
  GridLoading: boolean = false;
  Skip = 0;
  public buttonCount = 5;
  public PageSize: number = 10;
  public sizes = [{ text: 'All', value: Infinity }, 5, 10, 20];
  // public gridData: GridDataResult = { data: [], total: 0 };
  gridData: any[] = []; 
  public OriginalData: GridDataResult = { data: [], total: 0 };

  constructor(private user: UserService, private blobService: BlobService, private InvoiceFaceSheetService: InvoiceFaceSheetService) { 
    
  }

  ngOnInit(): void {
    document.getElementById('rdDow1')?.click();
    document.getElementById('rdDow2')?.click();
    this.getClientsMaster();
    
    this.generateMonthYearDropdown();
    console.log('ng')
    // this.GetInvoiceGridDetails();
  }
  onMeasurementSystemChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.selectedMeasurementSystemInvoicetype = target.value;
  
    this.gridData = [];
    console.log("this.selectedMeasurementSystemInvoicetype",this.selectedMeasurementSystemInvoicetype )
  }

  isRowDataEmpty(): boolean {
    // Check if rowData is an empty object
    // return !this.rowData || Object.keys(this.rowData).length === 0;

    return !this.rowData || Object.keys(this.rowData).length === 0 ||
      this.rowData.some((item: any) => !item.poType);
  }
  onMeasurementSystemChanges(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.selectedMeasurementSystemDraft = target.value;
    
    this.gridData = [];
    console.log("this.selectedMeasurementSystemDraft",this.selectedMeasurementSystemDraft)
  }

  isRowDataEmptys(): boolean {
    // Check if rowData is an empty object
    // return !this.rowData || Object.keys(this.rowData).length === 0;

    return !this.rowData || Object.keys(this.rowData).length === 0 ||
      this.rowData.some((item: any) => !item.poType);
  }
  onDropdownClientGstValueChange(data: any) {


    console.log('data', data)
    this.clientID = data?.value;
    console.log('client', this.clientID)
    this.GetInvoiceGridDetails();



  }
  generateMonthYearDropdown() {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const startYear = 2020; // Starting year for the dropdown
    const endYear = 2025;   // Ending year for the dropdown

    for (let year = startYear; year <= endYear; year++) {
      months.forEach((month, index) => {
        this.monthDropdown.push({
          id: `${year}-${index + 1}`,  // Example id: '2023-11'
          name: `${month}-${year}`     // Example name: 'Nov-2023'
        });
      });
    }
  }

  onDropdownMonthYearValueChange(data: any) {
    this.selectedMonthYear = data;

    // Assuming data.name is in the format "MMM-YYYY" (e.g., "Nov-2023")
    const [monthAbbreviation, selectedYear] = data.name.split('-');

    // Map month abbreviation to its numeric value
    const monthMap: { [key: string]: string  } = {
     Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06',
  Jul: '07', Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12'
    };


    const selectedMonth = monthMap[monthAbbreviation];

    console.log('Selected numeric month:', selectedMonth);
    console.log('Selected year:', selectedYear);


    this.selectedMonth = selectedMonth;
    this.selectedYear = +selectedYear;
    // this.GetInvoiceGridDetails();
  }


  getClientsMaster() {
    this.InvoiceFaceSheetService.getClientsGstMaster().subscribe({
      next: (response) => {
        const data = response.body;
        this.ClientGstDropdownData = [
          { text: 'Select Client', value: '' },
          ...data.map((item: any) => ({
            text: item.text,
            value: item.value
          }))
        ];
      },
      error: (error) => {
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }



  GetInvoiceGridDetails(): void {
    let payload = {
      InvoiceType: this.selectedMeasurementSystemInvoicetype,
      DraftType: this.selectedMeasurementSystemDraft,
      Monthid: String(this.selectedMonth),
      yearid: String(this.selectedYear),
      Clientid: this.clientID ? this.clientID : 'all',
      InvoiceNumber: ''
    };

    this.loading = true;
    this.InvoiceFaceSheetService.GetInvoiceGridDetail(payload).subscribe({
      next: (response) => {
        this.gridData = response.body.lstGridDetails;
        console.log("dsafds", this.gridData)
        this.loading = false;
      },
      error: (error) => {
        console.error('Error fetching Master Grid data', error);
        this.loading = false;
      }
    });
  }

  public pageChange(event: PageChangeEvent): void {
    this.GridLoading = true;
    console.log('Page Change Event Received:', event);
    this.Skip = event.skip;
    if (event.take == Infinity) {
      this.PageSize = this.OriginalData.total
    } else {
      this.PageSize = event.take;
    }
    // this.gridData = {
    //   data: this.OriginalData.data.slice(this.Skip, this.Skip + this.PageSize),
    //   total: this.OriginalData.total
    // };
    setTimeout(() => {
      this.GridLoading = false;
    }, 2000);
  }
}
