import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReviewRegulatoryAbstractsService {
  private apiUrl = environment.apiUrl;
  
  constructor(private http: HttpClient) { }
  getDropdownOptions(ClientId:string,UserId: number,isImplementation:number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/RegulatoryAbstracts/Get_StatesList?EntityID=${ClientId}&userID=${UserId}&isImplementation=${isImplementation}`;
    return this.http.get<any>(url,{ observe: 'response' });
  }
  getActDropdownOptions(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/RegulatoryAbstracts/Get_ActsList`;
    return this.http.get<any>(url,{ observe: 'response' });
  }
  getGridData(EntityId: string, UserId: number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/RegulatoryAbstracts/BindReviewerAbstractGrid?entityID=${EntityId}&userID=${UserId}`;
    return this.http.get<any>(url,{ observe: 'response' });
  }
  getBranchDropdownOptions(UserId: number,entityId:string,stateId:number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/RegulatoryAbstracts/GetBranchDetailsBasedOnStateID?UserID=${UserId}&EntityID=${entityId}&StateID=${stateId}`;
    return this.http.get<any>(url,{ observe: 'response' });
  }
  getDisplayTypeOptions(type:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Common/Get_LC_CodeDescription?Type=${type}`;
    return this.http.get<any>(url,{ observe: 'response' });
  }
  saveData(regulatoryabstract: any): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/RegulatoryAbstracts/Save_ReviewerAcknowledgementFile`;
    console.log(regulatoryabstract);
    
    const formData: FormData = new FormData();
    formData.append('_stateID', String(parseInt(regulatoryabstract._stateID)));
    formData.append('actid', String(parseInt(regulatoryabstract.actid)));
    formData.append('BranchID', String(parseInt(regulatoryabstract.BranchID)));
    formData.append('displayType', regulatoryabstract.displayType);
    formData.append('val', regulatoryabstract.val);
    formData.append('courier', regulatoryabstract.courier);
    formData.append('dispatchtype', regulatoryabstract.dispatchtype);
    formData.append('userID', regulatoryabstract.userID);

    if (regulatoryabstract.file) {
      formData.append('file', regulatoryabstract.file);
    }
    console.log('API response',formData);
    
    return this.http.post(url, formData, { observe: 'response' });
  }
  fetchFile(FilePath: string, FileName: string, FileKey: string): Observable<Blob> {
    const url = `${this.apiUrl}/api/RegulatoryAbstracts/RegulatoryAbstractPreview?FilePath=${FilePath}&FileName=${FileName}&FileKey=${FileKey}`;
    return this.http.post(url, null, { responseType: 'blob' });
  }
}
