<kendo-dialog #dialogRef title="Employee Details" (close)="closePopup()"  [height]="585" [width]="1000"
    class="custom-dialog">
    <div class="col-md-12 colpadding0" style="margin-top: 0px;   ">
        <header class="panel-heading tab-bg-primary"
        style="background: none !important; margin-left: 33px; padding: 0px 0px; zoom: 0.9;">
        <ul class="nav nav-tabs" style="margin-left: -23px;">
                <li class="listItem pemt" [ngClass]="{'selected-tab': EmployeeOption === 'Personal' }"
                    (click)="TabValidation('Personal')" style="margin-left: -8px !important">
                    <a>Personal Details</a>
                </li>
                <li class="listItem pemt" [ngClass]="{'selected-tab': EmployeeOption === 'Employee' }"
                    (click)="TabValidation('Employee')" style="margin-left: -8px !important">
                    <a>Employee Details</a>
                </li>
                <li class="listItem pemt" [ngClass]="{'selected-tab': EmployeeOption === 'Other' }"
                    (click)="TabValidation('Other')" style="margin-left: -8px !important">
                    <a>Other Details</a>
                </li>
               
                    <!-- <button *ngIf="EmpID" type="submit" class="figma-btn-blue " (click)="OpenBranchTransfer()"
                    style="margin-right: 37px; float: right;    height: 34px; margin-top: 13px;">
                    Branch Transfer
                </button> -->
                <button *ngIf="Data.length!==0"  type="submit" class="figma-btn-blue " style="margin-right:-100px;" (click)="OpenBranchTransfer()">
                Branch Transfer
            </button>
            </ul>
        </header>
    </div>

    <!-- /////////////////////////////////////////////////////////////////////////////////////////////// -->

    <div class="row col-12 dialog-content" style="margin-left:5px" *ngIf="EmployeeOption == 'Personal'">
        <form [formGroup]="form">
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Employee Name<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="Employee Name"
                            formControlName="employeeName" [(ngModel)]="employeeName"
                            [ngClass]="{'is-invalid': form.get('employeeName')?.invalid && (form.get('employeeName')?.touched)}">
                        <div *ngIf="form.get('employeeName')?.invalid && (form.get('employeeName')?.touched) && form.get('employeeName')?.errors?.['pattern']"
                            class="text-danger">
                            Name must contain only letters and spaces
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Father/Husband Name<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="Father/Husband Name"
                            formControlName="fatherHusbandName" [(ngModel)]="fatherHusbandName"
                            [ngClass]="{'is-invalid': form.get('fatherHusbandName')?.invalid && (form.get('fatherHusbandName')?.touched)}">
                        <div *ngIf="form.get('fatherHusbandName')?.invalid && (form.get('fatherHusbandName')?.touched) && form.get('fatherHusbandName')?.errors?.['pattern']"
                            class="text-danger">
                            Name must contain only letters and spaces.
                        </div>
                    </div>
                </div>
            </div>

            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Gender<sub class="text-danger">*</sub></label>
                    <app-dropdown-list [data]="GenderdropdownData" [selectedValue]="gender" [width]="'250px'"
                        textField="text" valueField="value" name="gender"
                        [hasError]="!gender.value && showValidationErrors"
                        (valueChange)="onDropdownValueChange($event, 'gender')">
                    </app-dropdown-list>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Relationship<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <app-dropdown-list [data]="relationshipdropdownData"
                            [width]="'250px'" textField="text" valueField="value" [selectedValue]="relationship"
                            (valueChange)="onDropdownValueChange($event, 'relationship')"
                            [hasError]="!relationship.value && showValidationErrors">
                        </app-dropdown-list>
                    </div>
                </div>
            </div>

            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Date of Birth<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <app-kendo-datepicker class="uniform-size" [(value)]="dateOfBirth"
                            (valueChange)="onDateChange($event, 'dateOfBirth')" [format]="'dd-MM-yyyy'"
                            [placeholder]="placeholder" [max]="todayDate" [width]="'250px'"
                            [class.input-invalid]="!dateOfBirth && showValidationErrors"
                            [hasError]="!dateOfBirth && showValidationErrors">
                        </app-kendo-datepicker>
                    </div>

                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Address</label>
                    <textarea class="form-control f-textbox" placeholder="Address" [(ngModel)]="address"
                        [ngModelOptions]="{standalone: true}"></textarea>
                </div>
            </div>

            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Marital Status</label>
                    <app-dropdown-list [data]="maritalStatusdropdownData" [selectedValue]="maritalStatus"
                        [width]="'250px'" textField="text" valueField="value"
                        (valueChange)="onDropdownValueChange($event, 'maritalStatus')">
                    </app-dropdown-list>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Email</label>
                    <div class="require-container">
                        <input type="email" class="form-control f-textbox" placeholder="Email" formControlName="email"
                            [ngClass]="{'is-invalid': form.get('email')?.invalid && (form.get('email')?.touched)}"
                            [(ngModel)]="email">
                        <div *ngIf="form.get('email')?.invalid && (form.get('email')?.touched) && form.get('email')?.errors?.['pattern']"
                            class="text-danger">
                            Please enter a valid email address
                        </div>
                    </div>
                </div>
            </div>

            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Mobile No</label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="Mobile No" [(ngModel)]="mobileNo"
                            formControlName="mobileNo" minlength="10" maxlength="10" (input)="validateMobileNo($event)"
                            [ngClass]="{'is-invalid': form.get('mobileNo')?.invalid && (form.get('mobileNo')?.touched)}">
                        <div *ngIf="form.get('mobileNo')?.invalid && (form.get('mobileNo')?.touched) && form.get('mobileNo')?.errors?.['pattern']"
                            class="text-danger">
                            Mobile No must be exactly 10 digits
                        </div>
                    </div>
                </div>

                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Nationality</label>
                    <app-dropdown-list [data]="countryDropdownData" [selectedValue]="nationality" [width]="'250px'"
                        textField="text" valueField="value"
                        (valueChange)="onDropdownValueChange($event, 'nationality')">
                    </app-dropdown-list>
                </div>
            </div>

            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">PAN</label>
                    <div class="require-container position-relative masking">
                        <div class="masking d-flex align-items-center">
                            <input type="text" class="form-control f-textbox masking" placeholder="AAAAA1111A"
                                formControlName="pan" minlength="10" maxlength="10" style="width: 210px;"
                                (input)="formatPan($event)" [(ngModel)]="pan"
                                [ngClass]="{'is-invalid':  showValidationErrorsPAN}" />
                            <button type="button" class="figma-btn-white" (click)="togglePanVisibility()">
                                <img *ngIf="showPan" src="../assets/vectors/eye_112_x2.svg"
                                    alt="Toggle PAN visibility" />
                                <img *ngIf="!showPan" src="../assets/vectors/EyeClosed.svg" alt="">
                            </button>
                        </div>
                        <div *ngIf="showValidationErrorsPAN" class="text-danger">
                            PAN must follow the format AAAAA1111A
                        </div>
                    </div>
                </div>


                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Aadhar</label>
                    <div class="require-container position-relative">
                        <div class="masking d-flex align-items-center">
                            <input type="text" class="form-control f-textbox masking" placeholder="0000-0000-0000"
                                formControlName="aadhar" minlength="12" maxlength="14" style="width: 210px;"
                                (input)="formatAadhaar($event)" [(ngModel)]="aadhar"
                                [ngClass]="{'is-invalid':  showValidationErrorsAadhaar}" />
                            <button type="button" class="figma-btn-white" (click)="toggleAadhaarVisibility()">
                                <img *ngIf="showAadhaar" src="../assets/vectors/eye_112_x2.svg" />
                                <img *ngIf="!showAadhaar" src="../assets/vectors/EyeClosed.svg" />
                            </button>
                        </div>
                        <div *ngIf="showValidationErrorsAadhaar" class="text-danger">
                            Aadhaar must contain exactly 12 digits.
                        </div>
                    </div>
                </div>
            </div>

            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Physically Challenged<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <app-dropdown-list [data]="YesNodropdownData" [selectedValue]="physicallyChallenged"
                            [width]="'250px'" textField="text" valueField="value"
                            (valueChange)="onDropdownValueChange($event, 'physicallyChallenged')"
                            [hasError]="!physicallyChallenged.value && showValidationErrors">
                        </app-dropdown-list>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Permanent Address</label>
                    <textarea class="form-control f-textbox" placeholder="Permanent Address"
                        [(ngModel)]="permanentAddress" [ngModelOptions]="{standalone: true}"></textarea>
                </div>
            </div>

            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Passport No</label>
                    <div class="require-container position-relative masking">
                        <div class="masking d-flex align-items-center">
                            <input type="text" class="form-control f-textbox masking" placeholder="XXXXXX1234"
                                formControlName="passportNo" minlength="10" maxlength="10" style="width: 210px;"
                                (input)="formatPassport($event)" [(ngModel)]="passportNo"
                                [ngClass]="{'is-invalid': form.get('passportNo')?.invalid && form.get('passportNo')?.touched}" />
                            <button class="figma-btn-white" (click)="togglePassportVisibility()">
                                <img *ngIf="showPassport" src="../assets/vectors/eye_112_x2.svg"
                                    alt="Toggle passport no visibility" />
                                <img *ngIf="!showPassport" src="../assets/vectors/EyeClosed.svg" alt="">
                            </button>
                        </div>
                        <div *ngIf="form.get('passportNo')?.invalid && form.get('passportNo')?.touched && form.get('passportNo')?.errors?.['pattern']"
                            class="text-danger">
                            Contains 10 alphanumeric characters
                        </div>
                    </div>
                </div>

                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Passport Issued</label>
                    <app-dropdown-list [data]="countryDropdownData" [selectedValue]="passportIssued" [width]="'250px'"
                        textField="text" valueField="value"
                        (valueChange)="onDropdownValueChange($event, 'passportIssued')">
                    </app-dropdown-list>
                </div>
            </div>

            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Passport Valid From</label>
                    <app-kendo-datepicker class="uniform-size " [(value)]="passportValidFrom"
                        (valueChange)="onDateChange($event, 'passportValidFrom')" [format]="'dd-MM-yyyy'"
                        [placeholder]="placeholder" [width]="'250px'" [max]="todayDate"
                        [class.input-invalid]="!passportValidFrom && showValidationErrors">
                    </app-kendo-datepicker>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Passport Valid To</label>
                    <app-kendo-datepicker class="uniform-size " [(value)]="passportValidTo"
                        (valueChange)="onDateChange($event, 'passportValidTo')" [format]="'dd-MM-yyyy'"
                        [placeholder]="placeholder" [width]="'250px'"
                        [class.input-invalid]="!passportValidTo && showValidationErrors">
                    </app-kendo-datepicker>
                </div>
            </div>

            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Identification Mark</label>
                    <input type="text" class="form-control f-textbox" placeholder="Identification Mark"
                        [(ngModel)]="identificationMark" [ngModelOptions]="{standalone: true}">
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Emergency contact Address</label>
                    <textarea class="form-control f-textbox" placeholder="Emergency contact Address"
                        [(ngModel)]="emergencyContactAddress" [ngModelOptions]="{standalone: true}"></textarea>
                </div>
            </div>

            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Emergency Contact No</label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="Emergency Contact No"
                            [(ngModel)]="emergencyContactNum" formControlName="emergencyContactNum" minlength="10"
                            maxlength="10" (input)="validateemergencyContactNum($event)"
                            [ngClass]="{'is-invalid': form.get('emergencyContactNum')?.invalid && (form.get('emergencyContactNum')?.touched)}">
                        <div *ngIf="form.get('emergencyContactNum')?.invalid && (form.get('emergencyContactNum')?.touched) && form.get('emergencyContactNum')?.errors?.['pattern']"
                            class="text-danger">
                            Mobile No must be exactly 10 digits
                        </div>
                    </div>
                </div>
            </div>

            <kendo-dialog-actions style="border: none;">
                <button type="submit" class="figma-btn-blue figma-popup" (click)="PersonalNext()">
                    Next
                </button>
            </kendo-dialog-actions>
        </form>
    </div>


    <!-- /////////////////////////////////////////////////////////////////////////////////////////////// -->

    <div class="row col-12 dialog-content" *ngIf="EmployeeOption == 'Employee'">
        <form [formGroup]="formEm">
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Employee ID<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="text" (keyup)="applyFilterEmpID($event)" class="form-control f-textbox"
                            placeholder="Employee ID" formControlName="employeeID" [(ngModel)]="employeeID" [ngClass]="{
                          'is-invalid': formEm.get('employeeID')?.invalid && 
                                        (formEm.get('employeeID')?.touched || showValidationErrorsEmp) }">
                        <div *ngIf="formEm.get('employeeID')?.invalid && (formEm.get('employeeID')?.touched || showValidationErrorsEmp) && formEm.get('employeeID')?.errors?.['pattern']"
                            class="text-danger">
                            Contains only alphanumeric characters and hyphens
                        </div>
                        <div *ngIf="EmpIDExist" class="text-danger">
                            Employee ID Already Exist!
                        </div>
                    </div>
                </div>

                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Employment Type<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <app-dropdown-list [data]="employeeTypedropdownData" [selectedValue]="employmentType"
                            [width]="'250px'" textField="text" valueField="value"
                            (valueChange)="onDropdownValueChange($event, 'employmentType')"
                            [hasError]="!employmentType.value && showValidationErrorsEmp">
                        </app-dropdown-list>
                    </div>
                </div>
            </div>

            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Enity/Branch<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <app-combo-box [width]="'250px'" [Placeholder]="'Search/Select Entity or Branch'"
                            (valueChange)="onDropdownValueChange($event, 'Entity_Branch')"
                            [data]="Entity_BranchDropdownData" [selectedValue]="Entity_Branch" textField="name"
                            valueField="id" [hasError]="(Entity_Branch.id == 0) &&  showValidationErrorsEmp">
                        </app-combo-box>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">PT State</label>
                    <div class="require-container">
                        <app-dropdown-list [data]="PT_StateDropdownData" [selectedValue]="PT_State"
                        [width]="'250px'" textField="text" valueField="value"
                        (valueChange)="onDropdownValueChange($event, 'PT_State')">
                    </app-dropdown-list>
                    </div>
                </div>
            </div>

            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Skill Category<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <app-dropdown-list [data]="SkillCategorydropdownData" [selectedValue]="SkillCategory"
                            [width]="'250px'" textField="text" valueField="value"
                            (valueChange)="onDropdownValueChange($event, 'SkillCategory')"
                            [hasError]="!SkillCategory.value && showValidationErrorsEmp">
                        </app-dropdown-list>
                    </div>
                </div>
                <div class=" col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Joining Date<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <app-kendo-datepicker class="uniform-size " [(value)]="JoiningDate"
                            (valueChange)="onDateChange($event, 'JoiningDate')" [format]="'dd-MM-yyyy'"
                            [placeholder]="placeholder" [width]="'250px'" [max]="todayDate"
                            [class.input-invalid]="!JoiningDate && showValidationErrorsEmp"
                            [hasError]="!JoiningDate && showValidationErrorsEmp">
                        </app-kendo-datepicker>
                    </div>
                </div>
            </div>

            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Leaving Date</label>
                    <app-kendo-datepicker class="uniform-size " [(value)]="LeavingDate"
                        (valueChange)="onDateChange($event, 'LeavingDate')" [format]="'dd-MM-yyyy'"
                        [placeholder]="placeholder" [width]="'250px'" [max]="todayDate">
                    </app-kendo-datepicker>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Is International Worker<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <app-dropdown-list [data]="YesNodropdownData" [selectedValue]="InternationalWorker"
                            [width]="'250px'" textField="text" valueField="value"
                            (valueChange)="onDropdownValueChange($event, 'InternationalWorker')"
                            [hasError]="!InternationalWorker.value && showValidationErrorsEmp">
                        </app-dropdown-list>
                    </div>
                </div>
            </div>

            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Effective Change From</label>
                    <app-kendo-datepicker class="uniform-size " [(value)]="EffectiveChange"
                        (valueChange)="onDateChange($event, 'EffectiveChange')" [format]="'dd-MM-yyyy'"
                        [placeholder]="placeholder" [width]="'250px'">
                    </app-kendo-datepicker>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">IFSC</label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="IFSC" formControlName="IFSC"
                            [(ngModel)]="IFSC" maxlength="11"
                            [ngClass]="{'is-invalid': formEm.get('IFSC')?.invalid && (formEm.get('IFSC')?.touched)}">
                        <div *ngIf="formEm.get('IFSC')?.invalid && (formEm.get('IFSC')?.touched)
                        && formEm.get('IFSC')?.errors?.['pattern'] || formEm.get('IFSC')?.errors?.['minlength'] || formEm.get('IFSC')?.errors?.['maxlength']"
                            class="text-danger">
                            IFSC must be exactly 11 alphanumeric
                        </div>
                    </div>
                </div>
            </div>

            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">PF Number</label>
                
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox masking" placeholder="PF Number"
                            formControlName="PFNumber" (input)="formatPFNumber($event)" 
                            (keyup)="applyFilterPFNumber($event)"  [(ngModel)]="PFNumber"
                            [ngClass]="{'is-invalid': formEm.get('PFNumber')?.invalid && formEm.get('PFNumber')?.touched}"
                            maxlength="22" style="width: 210px;" />

                        <button type="button" class="figma-btn-white" (click)="togglePFNumberVisibility()">
                            <img *ngIf="showPFNumber" src="../assets/vectors/eye_112_x2.svg"
                                alt="Toggle PF Number visibility" />
                            <img *ngIf="!showPFNumber" src="../assets/vectors/EyeClosed.svg" alt="" />
                        </button>

                        <div *ngIf="formEm.get('PFNumber')?.invalid && formEm.get('PFNumber')?.touched && !PFNumberExist && 
                                     formEm.get('PFNumber')?.errors?.['pattern'] || formEm.get('PFNumber')?.errors?.['minlength'] || 
                                     formEm.get('PFNumber')?.errors?.['maxlength']" class="text-danger">
                            IT must be exactly 22 alphanumeric
                        </div>

                        <div *ngIf="PFNumberExist" class="text-danger">
                            PF Number Already Exist!
                        </div>
                    </div>
                </div>

                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">UAN</label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox masking" placeholder="UAN"
                            formControlName="UAN" (input)="formatUAN($event)" [disabled]="!showUAN"
                            (keyup)="applyFilterUAN($event)" [(ngModel)]="UAN"
                            [ngClass]="{'is-invalid': formEm.get('UAN')?.invalid && formEm.get('UAN')?.touched}"
                            minlength="12" maxlength="12" style="width: 210px;" />

                        <button type="button" class="figma-btn-white" (click)="toggleUANVisibility()">
                            <img *ngIf="showUAN" src="../assets/vectors/eye_112_x2.svg" alt="Toggle UAN visibility" />
                            <img *ngIf="!showUAN" src="../assets/vectors/EyeClosed.svg" alt="" />
                        </button>

                        <div *ngIf="formEm.get('UAN')?.invalid && formEm.get('UAN')?.touched && !UANExist &&  formEm.get('UAN')?.errors?.['minlength'] || 
                                     formEm.get('UAN')?.errors?.['maxlength']" class="text-danger">
                            UAN must be exactly 12 digits
                        </div>

                        <div *ngIf="UANExist" class="text-danger">
                            UAN Already Exist!
                        </div>
                    </div>
                </div>

            </div>

            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Bank Name</label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="Bank Name"
                            formControlName="BankName" [(ngModel)]="BankName" (keypress)="preventInvalidChars($event)"
                            [ngClass]="{'is-invalid': formEm.get('BankName')?.invalid && (formEm.get('BankName')?.touched || showValidationErrorsEmp)}">
                        <div *ngIf="formEm.get('BankName')?.invalid && (formEm.get('BankName')?.touched ) && formEm.get('BankName')?.errors?.['required'] && formEm.get('BankName')?.errors?.['pattern']"
                            class="text-danger">
                            Bank Name must contain only alphabets
                        </div>
                    </div>
                </div>

                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Bank A/C No</label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="Bank A/C No"
                            formControlName="BankAC" [(ngModel)]="BankAC" (keypress)="formatBankAC($event)"
                            [ngClass]="{'is-invalid': formEm.get('BankAC')?.invalid && (formEm.get('BankAC')?.touched)}">
                        <div *ngIf="formEm.get('BankAC')?.invalid && (formEm.get('BankAC')?.touched) && formEm.get('BankAC')?.errors?.['pattern']"
                            class="text-danger">
                            Bank A/C No must be between 11 and 16 digits
                        </div>
                    </div>
                </div>

            </div>

            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">ESIC Number</label>
                    <div class="require-container">
                        <input type="text" formControlName="ESICNumber" class="form-control f-textbox masking"
                            placeholder="ESIC Number" maxlength="17" style="width: 210px;" [disabled]="!showESICNumber"
                            (input)="formatESICNumber($event)" [(ngModel)]="ESICNumber"
                            [ngClass]="{'is-invalid': formEm.get('ESICNumber')?.invalid && formEm.get('ESICNumber')?.touched}" />

                        <button type="button" class="figma-btn-white" (click)="toggleESICNumberVisibility()">
                            <img *ngIf="showESICNumber" src="../assets/vectors/eye_112_x2.svg"
                                alt="Toggle ESIC Number visibility" />
                            <img *ngIf="!showESICNumber" src="../assets/vectors/EyeClosed.svg"
                                alt="Toggle ESIC Number visibility" />
                        </button>

                        <div *ngIf="formEm.get('ESICNumber')?.invalid && formEm.get('ESICNumber')?.touched && !ESICExist && 
                                 (formEm.get('ESICNumber')?.errors?.['pattern'] || formEm.get('ESICNumber')?.errors?.['minlength'] || 
                                 formEm.get('ESICNumber')?.errors?.['maxlength'])" class="text-danger">
                            ESIC Number must be exactly 17 digits
                        </div>
                    </div>
                </div>

                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Department</label>
                    <div class="require-container">
                        <input type="text" formControlName="Department" class="form-control f-textbox"
                            placeholder="Department" [(ngModel)]="Department" (keypress)="preventInvalidChars($event)"
                            [ngClass]="{'is-invalid': formEm.get('Department')?.invalid && formEm.get('Department')?.touched}" />
                        <div *ngIf="formEm.get('Department')?.invalid && formEm.get('Department')?.touched && formEm.get('Department')?.errors?.['pattern']"
                            class="text-danger">
                            Department must contain only alphabets and spaces
                        </div>
                    </div>
                </div>
            </div>

            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Designation</label>
                    <app-combo-box [width]="'250px'" [Placeholder]="'Search/Select Designation'"
                        (filterChange)="FilterDropdown($event)" textField="name" valueField="id"
                        [selectedValue]="Designation" (valueChange)="onDropdownValueChange($event, 'Designation')"
                        [data]="DesignationDropdownData">
                    </app-combo-box>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Status<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <app-dropdown-list [data]="StatusdropdownData" [selectedValue]="Status" [width]="'250px'"
                            textField="text" valueField="value" (valueChange)="onDropdownValueChange($event, 'Status')"
                            [hasError]="!Status.value && showValidationErrorsEmp">
                        </app-dropdown-list>
                    </div>
                </div>
            </div>

            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Courage Year</label>
                    <app-dropdown-list [data]="Yeardropdown" [selectedValue]="CourageYear" [width]="'250px'"
                        [height]="'34px'" textField="displayvalue" valueField="value"
                        (valueChange)="onDropdownValueChange($event, 'CourageYear')" style="margin-right: 10px;">
                    </app-dropdown-list>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">EPFO Bank A/c</label>
                    <app-dropdown-list [data]="YesNodropdownData" [selectedValue]="EPFOBank" [width]="'250px'"
                        textField="text" valueField="value" (valueChange)="onDropdownValueChange($event, 'EPFOBank')">
                    </app-dropdown-list>
                </div>
            </div>

            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">PF Capping Applicability<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <app-dropdown-list [data]="YesNodropdownData" [selectedValue]="PFCapping" [width]="'250px'"
                            textField="text" valueField="value"
                            (valueChange)="onDropdownValueChange($event, 'PFCapping')"
                            [hasError]="!PFCapping.value && showValidationErrorsEmp">
                        </app-dropdown-list>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">ESI Applicability</label>
                    <app-dropdown-list [data]="YesNodropdownData" [selectedValue]="ESI" [width]="'250px'"
                        textField="text" valueField="value" (valueChange)="onDropdownValueChange($event, 'ESI')">
                    </app-dropdown-list>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">EPFO Aadhar</label>
                    <app-dropdown-list [data]="YesNodropdownData" [selectedValue]="EPFOAddress" [width]="'250px'"
                        textField="text" valueField="value"
                        (valueChange)="onDropdownValueChange($event, 'EPFOAddress')">
                    </app-dropdown-list>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Courage Month</label>
                    <app-dropdown-list [data]="Monthdropdown" [selectedValue]="CourageMonth" [width]="'250px'"
                        [height]="'34px'" textField="displayvalue" valueField="value"
                        (valueChange)="onDropdownValueChange($event, 'CourageMonth')" style="margin-right: 10px;">
                    </app-dropdown-list>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">PMRPY</label>
                    <app-dropdown-list [data]="YesNodropdownData" [selectedValue]="PMRPY" [width]="'250px'"
                        textField="text" valueField="value" (valueChange)="onDropdownValueChange($event, 'PMRPY')">
                    </app-dropdown-list>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">PT Applicability</label>
                    <app-dropdown-list [data]="YesNodropdownData" [selectedValue]="PT" [width]="'250px'"
                        textField="text" valueField="value" (valueChange)="onDropdownValueChange($event, 'PT')">
                    </app-dropdown-list>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Employment<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <app-dropdown-list [data]="EmploymentdropdownData" [selectedValue]="Employment"
                            [width]="'250px'" textField="text" valueField="value"
                            (valueChange)="onDropdownValueChange($event, 'Employment')"
                            [hasError]="!Employment.value && showValidationErrorsEmp">
                        </app-dropdown-list>

                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">PF Applicability</label>
                    <app-dropdown-list [data]="YesNodropdownData" [selectedValue]="PF" [width]="'250px'"
                        textField="text" valueField="value" (valueChange)="onDropdownValueChange($event, 'PF')">
                    </app-dropdown-list>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Payroll Month<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <app-dropdown-list [data]="Monthdropdown" [selectedValue]="PayrollMonth" [width]="'250px'"
                            [height]="'34px'" textField="displayvalue" valueField="value"
                            (valueChange)="onDropdownValueChange($event, 'PayrollMonth')" style="margin-right: 10px;"
                            [hasError]="!PayrollMonth.value && showValidationErrorsEmp">
                        </app-dropdown-list>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">LWF Exempted</label>
                    <app-dropdown-list [data]="LWFdropdownData" [selectedValue]="LWF" [width]="'250px'" textField="text"
                        valueField="value" (valueChange)="onDropdownValueChange($event, 'LWF')">
                    </app-dropdown-list>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Payroll Year<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <app-dropdown-list [data]="Yeardropdown" [selectedValue]="PayrollYear" [width]="'250px'"
                            [height]="'34px'" textField="displayvalue" valueField="value"
                            (valueChange)="onDropdownValueChange($event, 'PayrollYear')" style="margin-right: 10px;"
                            [hasError]="!PayrollYear.value && showValidationErrorsEmp">
                        </app-dropdown-list>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Training No</label>
                    <div class="require-container">
                        <input type="number" formControlName="TrainingNo" class="form-control f-textbox"
                            placeholder="Training No" [(ngModel)]="TrainingNo"
                            [ngClass]="{'is-invalid': formEm.get('TrainingNo')?.invalid && formEm.get('TrainingNo')?.touched}" />
                        <div *ngIf="formEm.get('TrainingNo')?.invalid && formEm.get('TrainingNo')?.touched"
                            class="text-danger">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Training Date</label>
                    <app-kendo-datepicker class="uniform-size " [(value)]="TrainingDate"
                        (valueChange)="onDateChange($event, 'TrainingDate')" [format]="'dd-MM-yyyy'"
                        [placeholder]="placeholder" [width]="'250px'">
                    </app-kendo-datepicker>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">EPFO PAN</label>
                    <app-dropdown-list [data]="YesNodropdownData" [selectedValue]="EPFOPAN" [width]="'250px'"
                        textField="text" valueField="value" (valueChange)="onDropdownValueChange($event, 'EPFOPAN')">
                    </app-dropdown-list>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Exempted from S&E Act</label>
                    <app-dropdown-list [data]="YesNodropdownData" [selectedValue]="Exempted" [width]="'250px'"
                        textField="text" valueField="value" (valueChange)="onDropdownValueChange($event, 'Exempted')">
                    </app-dropdown-list>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">VPF Applicability</label>
                    <app-dropdown-list [data]="YesNodropdownData" [selectedValue]="VPF" [width]="'250px'"
                        textField="text" valueField="value" (valueChange)="onDropdownValueChange($event, 'VPF')">
                    </app-dropdown-list>
                </div>
            </div>
            <div class="row EmpContainer" *ngIf="showVPFDropdown">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">VPF Type</label>
                    <app-dropdown-list [data]="VPFdropdownData" [selectedValue]="VPFType" [width]="'250px'"
                        textField="text" valueField="value" (valueChange)="onDropdownValueChange($event, 'VPFType')">
                    </app-dropdown-list>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">VPF Value</label>
                    <div class="require-container">
                        <input type="number" class="form-control f-textbox" placeholder="VPF Value"
                            [(ngModel)]="VPFValue" [ngModelOptions]="{standalone: true}" name="VPFValue"
                            #vpfValue="ngModel">
                    </div>
                </div>
            </div>

            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Sector</label>
                    <app-dropdown-list [data]="Sectordropdown" [selectedValue]="Sector" [width]="'250px'"
                        textField="text" valueField="value" (valueChange)="onDropdownValueChange($event, 'Sector')">
                    </app-dropdown-list>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Job Category</label>
                    <app-dropdown-list [data]="JobCategorydropdown" [selectedValue]="JobCategory" [width]="'250px'"
                        textField="text" valueField="value"
                        (valueChange)="onDropdownValueChange($event, 'JobCategory')">
                    </app-dropdown-list>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Industry Type</label>
                    <app-dropdown-list [data]="IndustryTypedropdown" [selectedValue]="IndustryType" [width]="'250px'"
                        textField="text" valueField="value"
                        (valueChange)="onDropdownValueChange($event, 'IndustryType')">
                    </app-dropdown-list>
                </div>
            </div>
            <kendo-dialog-actions style="border: none;">
                <button type="submit" class="figma-btn-blue figma-popup " (click)="EmployeeNext()">
                    Next
                </button>
            </kendo-dialog-actions>
        </form>
    </div>

    <!-- ////////////////////////////////////////////////////////////////////////////////////////////// -->

    <div class="row col-12 dialog-content" *ngIf="EmployeeOption == 'Other'">
        <form [formGroup]="formSave">
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Place of work</label>
                    <app-dropdown-list [data]="PlaceofworkdropdownData" [selectedValue]="Placeofwork" [width]="'250px'"
                        textField="text" valueField="value"
                        (valueChange)="onDropdownValueChange($event, 'Placeofwork')">
                    </app-dropdown-list>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Remarks</label>
                    <input type="text" class="form-control f-textbox" placeholder="Remarks" [(ngModel)]="Remarks"
                        [ngModelOptions]="{standalone: true}">
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Education Level</label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="Education Level"
                            formControlName="EducationLevel" [(ngModel)]="EducationLevel"
                            (keypress)="preventInvalidChars($event)"
                            [ngClass]="{'is-invalid': formSave.get('EducationLevel')?.invalid && (formSave.get('EducationLevel')?.touched || showValidationErrorsOther)}">
                        <div *ngIf="formSave.get('EducationLevel')?.invalid && (formSave.get('EducationLevel')?.touched) && formSave.get('EducationLevel')?.errors?.['pattern']"
                            class="text-danger">
                            Only Characters are allowed
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Place of Employment</label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="Place of Employment"
                            formControlName="PlaceofEmployment" [(ngModel)]="PlaceofEmployment"
                            [ngClass]="{'is-invalid': formSave.get('PlaceofEmployment')?.invalid && formSave.get('PlaceofEmployment')?.touched}"
                            (keypress)="restrictInput($event)">
                        <div *ngIf="formSave.get('PlaceofEmployment')?.invalid && 
                                    (formSave.get('PlaceofEmployment')?.touched || showValidationErrorsOther) && 
                                    formSave.get('PlaceofEmployment')?.errors?.['pattern']" class="text-danger">
                            Only Characters are allowed
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Place of Employment:</label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="Place of Employment"
                            formControlName="PlaceofEmployment" [(ngModel)]="PlaceofEmployment" (keypress)="preventInvalidChars($event)"
                            [ngClass]="{'is-invalid': formSave.get('PlaceofEmployment')?.invalid && (formSave.get('PlaceofEmployment')?.touched || showValidationErrorsOther)}">
                        <div *ngIf="formSave.get('PlaceofEmployment')?.invalid && (formSave.get('PlaceofEmployment')?.touched) && formSave.get('PlaceofEmployment')?.errors?.['pattern']"
                            class="text-danger">
                            Only Characters are allowed
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">No Of Certificate</label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="No Of Certificate"
                            formControlName="NoOfCertificate" [(ngModel)]="NoOfCertificate"
                            (keypress)="formatBankAC($event)"
                            [ngClass]="{'is-invalid': formSave.controls['NoOfCertificate'].invalid && formSave.controls['NoOfCertificate'].touched}">
                        <div *ngIf="formSave.controls['NoOfCertificate'].invalid && (formSave.controls['NoOfCertificate'].touched) && formSave.controls['NoOfCertificate'].errors?.['pattern']"
                            class="text-danger">
                            Only numeric values are allowed
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Certificate Date</label>
                    <app-kendo-datepicker class="uniform-size " [(value)]="CertificateDate"
                        (valueChange)="onDateChange($event, 'TrainingDate')" [format]="'dd-MM-yyyy'"
                        [placeholder]="placeholder" [width]="'250px'">
                    </app-kendo-datepicker>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Token No</label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="Token No"
                            formControlName="TokenNo" [(ngModel)]="TokenNo" (keypress)="formatBankAC($event)"
                            [ngClass]="{'is-invalid': formSave.controls['TokenNo'].invalid && formSave.controls['TokenNo'].touched}">
                        <div *ngIf="formSave.controls['TokenNo'].invalid && (formSave.controls['TokenNo'].touched || showValidationErrorsOther) && formSave.controls['TokenNo'].errors?.['pattern']"
                            class="text-danger">
                            Only numeric values are allowed
                        </div>
                    </div>
                </div>

                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Relay Assigned</label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="Relay Assigned"
                            formControlName="RelayAssigned" [(ngModel)]="RelayAssigned"
                            (keypress)="preventInvalidChars($event)"
                            [ngClass]="{'is-invalid': formSave.controls['RelayAssigned'].invalid && formSave.controls['RelayAssigned'].touched}">
                        <div *ngIf="formSave.controls['RelayAssigned'].invalid && (formSave.controls['RelayAssigned'].touched || showValidationErrorsOther) && formSave.controls['RelayAssigned'].errors?.['pattern']"
                            class="text-danger">
                            Only Characters are allowed
                        </div>
                    </div>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Letter Group</label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="Letter Group"
                            formControlName="LetterGroup" [(ngModel)]="LetterGroup"
                            [ngClass]="{'is-invalid': formSave.controls['LetterGroup'].invalid && formSave.controls['LetterGroup'].touched}">
                        <div *ngIf="formSave.controls['LetterGroup'].invalid && (formSave.controls['LetterGroup'].touched )"
                            class="text-danger">
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Women Working Night Shift</label>
                    <app-dropdown-list [data]="YesNodropdownData" [selectedValue]="WomenWorking" [width]="'250px'"
                        textField="text" valueField="value"
                        (valueChange)="onDropdownValueChange($event, 'WomenWorking')">
                    </app-dropdown-list>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Transport Mode</label>
                    <app-dropdown-list [data]="TransportModedropdownData" [selectedValue]="TransportMode"
                        [width]="'250px'" textField="text" valueField="value"
                        (valueChange)="onDropdownValueChange($event, 'TransportMode')">
                    </app-dropdown-list>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Security Provided<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <app-dropdown-list [data]="YesNodropdownData" [selectedValue]="SecurityProvided"
                            [width]="'250px'" textField="text" valueField="value"
                            (valueChange)="onDropdownValueChange($event, 'SecurityProvided')"
                            [hasError]="!SecurityProvided.value && showValidationErrorsOther">
                        </app-dropdown-list>
                    </div>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Experience (yrs)</label>
                    <div class="require-container">
                        <input type="text" formControlName="Experience" class="form-control f-textbox"
                            placeholder="Experience (yrs)" maxlength="2" [(ngModel)]="Experience"
                            [ngClass]="{'is-invalid': formSave.controls['Experience'].invalid && (formSave.controls['Experience'].touched|| showValidationErrorsOther )}">
                        <div *ngIf="formSave.get('Experience')?.invalid && formSave.get('Experience')?.touched && formSave.get('Experience')?.errors?.['pattern']"
                            class="text-danger">
                            must be a numeric value with up to 2 digits
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Date of Clothes Given</label>
                    <app-kendo-datepicker class="uniform-size " [(value)]="DateofClothes"
                        (valueChange)="onDateChange($event, 'DateofClothes')" [format]="'dd-MM-yyyy'"
                        [placeholder]="placeholder" [width]="'250px'"
                        [class.input-invalid]="!DateofClothes && showValidationErrors">
                    </app-kendo-datepicker>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Date Of Exempting Order</label>
                    <app-kendo-datepicker class="uniform-size " [(value)]="DateOfExempting"
                        (valueChange)="onDateChange($event, 'DateOfExempting')" [format]="'dd-MM-yyyy'"
                        [placeholder]="placeholder" [width]="'250px'">
                    </app-kendo-datepicker>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Particulars Of Transfer</label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="Particulars Of Transfer"
                            formControlName="ParticularsOfTransfer" [(ngModel)]="ParticularsOfTransfer"
                            [ngClass]="{'is-invalid': formSave.controls['ParticularsOfTransfer'].invalid && formSave.controls['ParticularsOfTransfer'].touched}">
                        <div *ngIf="formSave.controls['ParticularsOfTransfer'].invalid && (formSave.controls['ParticularsOfTransfer'].touched || showValidationErrorsOther) && formSave.controls['ParticularsOfTransfer'].errors?.['pattern']"
                            class="text-danger">
                            It must contain only letters and spaces
                        </div>
                    </div>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Sales Promotion</label>
                    <app-dropdown-list [data]="YesNodropdownData" [selectedValue]="SalesPromotion" [width]="'250px'"
                        textField="text" valueField="value"
                        (valueChange)="onDropdownValueChange($event, 'SalesPromotion')">
                    </app-dropdown-list>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Payment Mode</label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="Payment Mode"
                            formControlName="PaymentMode" [(ngModel)]="PaymentMode"
                            (keypress)="preventInvalidChars($event)"
                            [ngClass]="{'is-invalid': formSave.controls['PaymentMode'].invalid && (formSave.controls['PaymentMode'].touched || showValidationErrorsOther)}">
                        <div *ngIf="formSave.controls['PaymentMode'].invalid && (formSave.controls['PaymentMode'].touched || showValidationErrorsOther) && formSave.controls['PaymentMode'].errors?.['pattern']"
                            class="text-danger">
                            It must contain only alphabets.
                        </div>
                    </div>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">EPS Applicability</label>
                    <app-dropdown-list [data]="YesNodropdownData" [selectedValue]="EPSApplicability" [width]="'250px'"
                        textField="text" valueField="value"
                        (valueChange)="onDropdownValueChange($event, 'EPSApplicability')">
                    </app-dropdown-list>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Client ESI No</label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="Client ESI No"  [(ngModel)]="ClientESINo"
                            formControlName="ClientESINo" (input)="formatClientESINo($event)" maxlength="17" [ngClass]="{'is-invalid': formSave.controls['ClientESINo'].invalid && 
                                          (formSave.controls['ClientESINo'].touched || showValidationErrorsOther)}">
                        <div *ngIf="formSave.controls['ClientESINo'].invalid && (formSave.controls['ClientESINo'].touched || showValidationErrorsOther)"
                            class="text-danger">ESIC Number must be exactly 17 digits
                        </div>
                    </div>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Client PT State</label>
                    <app-combo-box [width]="'250px'" [Placeholder]="'Search/Select Client PT State'"
                            (valueChange)="onDropdownValueChange($event, 'ClientPTState')" [data]="ClientPTStateDropdownData"
                            [selectedValue]="ClientPTState" textField="name" valueField="id">
                        </app-combo-box>
                        
                </div>
                <div class="col-md-6 d-flex align-items-center" >
                    <label class="f-label me-3">Aadhar Seeding</label>
                    <div class="d-flex align-items-center me-3">
                    <ul class="d-flex">
                            <h4 class="f-labels"><input id="rdGen" value="Y" type="radio" [(ngModel)]="AadharSeeding"
                            [ngModelOptions]="{standalone: true}" class="custom-radio" name="measurementSystem"
                            (change)="onRadioChange('Y')" />
                            <label for="rdGen" class="f-labels ms-1">Yes</label>
                            </h4>
                    </ul>
                    </div>
                    <div class="d-flex align-items-center me-3">
                    <ul class="d-flex">   
                            <h4 class="f-labels"><input id="rdGen" [(ngModel)]="AadharSeeding"
                            [ngModelOptions]="{standalone: true}" value="N" type="radio" class="custom-radio"
                            name="measurementSystem" (change)="onRadioChange('N')" />
                            <label for="rdGen" class="f-labels ms-1">No</label>
                            </h4>                       
                    </ul>
                    </div>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Employee Type<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <app-dropdown-list [data]="EmployeeTypedropdownData" [selectedValue]="EmployeeType"
                            [width]="'250px'" textField="text" valueField="value"
                            (valueChange)="onDropdownValueChange($event, 'EmployeeType')"
                            [hasError]="!EmployeeType.value&& showValidationErrorsOther">
                        </app-dropdown-list>
                    </div>
                </div>
            </div>
            <kendo-dialog-actions style="border: none;">
                <button kendoButton type="submit" (click)="OnSave()" class="figma-btn-blue figma-popup">
                    Save
                </button>
            </kendo-dialog-actions>
        </form>
    </div>

    <div *ngIf="loading" class="loader-overlay">
        <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
    </div>
</kendo-dialog>

<app-branch-transfer-popup [Data]="BranchData" *ngIf="BranchTeansferPopup"
    (close)="closeBranch()"></app-branch-transfer-popup>
<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270" [Data]="alertData"
    (close)="closeAlert()"></app-alert-popup>