<div class="row colpadding0">
    <div class="col-md-6 colpadding0" style="margin-top: 10px;">
        <app-dropdown-list class="dropdown" [data]="subCodeDropdownData" [selectedValue]="subcodeDropdown" [width]="'200px'"
            textField="text" valueField="value" (valueChange)="onDropdownValueChange($event,'subCode')">
        </app-dropdown-list>

        <kendo-datepicker class="f-textbox" [value]="selectedStartDate" [format]="'MMMM yyyy'"
            (valueChange)="onStartDateChange($event)" [style.width]="'200px'" bottomView="year" topView="decade"
            [placeholder]="datePlaceholder">
        </kendo-datepicker>
    </div>
    <div class="col-md-6 colpadding0 " style="margin-top: 10px;">
        <button (click)="exportToExcel()" class="exportbtn" >
            <img src="../assets/vectors/ExportButton.svg" alt="">
        </button>
        <button class="figma-btn-white" (click)="applyFilter()" type="submit">
            <img src="../assets/vectors/ApplyFilter.svg" alt="">
        </button>
        <button (click)="clearFilter()" class="figma-btn-white">
            <img src="../assets/vectors/ClearFilter.svg" alt="">
        </button>
        <button *ngIf="showDownloadButton" (click)="downloadSelectedRow()" class="figma-btn-blue">
            Download
        </button>
    </div>
</div>

<kendo-grid class="custom-grid" [data]="gridData" [columnMenu]="true" [pageSize]="pageSize" [pageable]="true"
    (pageChange)="pageChange($event)" [loading]="loadingGrid" [sortable]="true" [skip]="skip" [resizable]="true">
    <ng-template *ngIf="loadingGrid" kendoGridLoadingTemplate>
        <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
    </ng-template>
    <kendo-grid-column [width]="80" [columnMenu]="false">
        <ng-template kendoGridHeaderTemplate>
            <div class="checkbox-container" style="margin-left:18px;">
                <input type="checkbox"  [(ngModel)]="allSelected"
                    (change)="toggleSelectAll($event)" />
            </div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class=" checkbox-container" >
                <input type="checkbox"  class="check-box"  [(ngModel)]="dataItem.selected"
                    [disabled]="dataItem.disabled" (change)="onItemSelectionChange(dataItem)" />
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="empId" title=" Employee Code">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.empId">
                {{dataItem.empId}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="empName" title="Employee Name" >
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.empName">
                {{dataItem.empName}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="doj" title="Employee DOJ">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.doj">
                {{dataItem.doj}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="ipNumber" title="ESIC Number" >
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.ipNumber">
                {{dataItem.ipNumber}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="cardGeneratedDate" title="Card Generated Date">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.cardGeneratedDate">
                {{dataItem.cardGeneratedDate}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="action" title="Action" [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div style="margin-left: 14px;">
            <img (click)="gridDownload(dataItem)" src="../assets/vectors/DownloadBlue.svg" alt="Save Icon" class="svg-icon"  />
            <img src="../assets/vectors/EyeIcon.svg" alt="Delete Icon" class="svg-icon" (click)="viewDocument(dataItem)" /> 
            </div>
        </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate let-total="total" let-currentPage="currentPage">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-input></kendo-pager-input>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>
</kendo-grid>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="250" [Height]="265" [Data]="alertData" (close)="closeAlert()"></app-alert-popup>

<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>