<kendo-dialog #dialogRef title="Edit Scope" (close)="closePopup()" [width]="1200" [maxHeight]="585" class="custom-dialog">
    <div class="dialog-content-wrapper colpadding0">
        <div class="row col-12 dialog-content colpadding0">
            <div class="col-12 entity-label-wrapper">
                <label for="entity" class="f-label entity-label">Entity:</label>
                <span id="entity" class="f-label entity-value">{{ selectedEntity.key }}</span>
            </div>
            <div class="col-12 entity-label-wrapper">
                <label for="state" class="f-label entity-label">States:</label>
                <div class="require-container">
                    <kendo-multiselecttree #multiselectTree placeholder="Select States" kendoMultiSelectTreeExpandable
                        [kendoMultiSelectTreeHierarchyBinding]="StateDropdown" textField="name" valueField="id" [tagMapper]="tagMapper"
                        [(ngModel)]="selectedStates" (valueChange)="OnMultiSelectStateChange($event)"
                        [expandedKeys]="['0']" [popupSettings]="StatepopupSettings" class="f-textbox multi-box">
                    </kendo-multiselecttree>
                </div>
            </div>
        </div>
        <div class="col-12 grid">
            <kendo-grid [data]="editDataArray" [skip]="skip" [pageSize]="pageSize" [pageable]="true"
                (pageChange)="pageChange($event)" class="custom-grid">
                <kendo-grid-column field="service" title="Service" [width]="240">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <app-dropdown-list [data]="services" [selectedValue]="selectedService" textField="text" [isDisabled]="dataItem.disable"
                            valueField="value" [width]="'230px'" (valueChange)="onServiceChange($event, rowIndex)">
                        </app-dropdown-list>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="mandate" title="Mandate">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <app-dropdown-list [data]="mandate" [selectedValue]="selectedMandate" textField="text"
                            valueField="value" [width]="'160px'" [(ngModel)]="mandate"
                            (valueChange)="onMandateChange($event,rowIndex)">
                        </app-dropdown-list>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="lstActivity" title="Activity">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <kendo-multiselecttree #multiselectTree style="width:160px;" placeholder="Select Activity"
                            kendoMultiSelectTreeExpandable [kendoMultiSelectTreeHierarchyBinding]="ActivityDropdown" [tagMapper]="tagMapper"
                            [ngModel]="dataItem.isNew ? editDataArray[rowIndex].selectedActivities : selectedActivities"
                            textField="name" valueField="id" (valueChange)="OnActivitySelectChange($event, rowIndex)"
                            [expandedKeys]="['0']" [popupSettings]="popupSettings" class="f-textbox multi-box">
                        </kendo-multiselecttree>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="frequency" title="Frequency" [width]="130">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <button class="figma-btn-blue map-btn" (click)="mapFrequency('Edit Popup',rowIndex)">Map
                            Frequency</button>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="natureOfAct" title="Nature of Act">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <kendo-multiselecttree #multiselectTree placeholder="Select Nature of Acts"
                            [style.width]="'160px'" kendoMultiSelectTreeExpandable
                            [kendoMultiSelectTreeHierarchyBinding]="NatureDropdown" [tagMapper]="tagMapper"
                            [(ngModel)]="editDataArray[rowIndex].selectedNature" textField="name" valueField="id"
                            (valueChange)="OnNatureSelectChange($event, rowIndex)" [expandedKeys]="['0']"
                            [popupSettings]="NaturepopupSettings" class="f-textbox multi-box">
                        </kendo-multiselecttree>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="act" title="Acts">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <kendo-multiselecttree #multiselectTree placeholder="Select Acts" kendoMultiSelectTreeExpandable
                            [kendoMultiSelectTreeHierarchyBinding]="ActDropdown" textField="name" valueField="id" [tagMapper]="tagMapper"
                            [ngModel]="dataItem.isNew ? editDataArray[rowIndex].selectedActs: selectedActs"
                            (valueChange)="OnActSelectChange($event,rowIndex)" [expandedKeys]="['0']"
                            [popupSettings]="popupSettings" class="f-textbox multi-box">
                        </kendo-multiselecttree>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="action" title="Action" [width]="100">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <img src="assets/vectors/save1.svg" alt="Save Icon" class="svg-icon"
                            (click)="updateScope(dataItem)">
                        <img src="assets/vectors/Add.svg" alt="Plus Icon" class="svg-icon" (click)="addNewRow()">
                    </ng-template>
                </kendo-grid-column>
            </kendo-grid>
        </div>

    </div>
</kendo-dialog>

<div kendoDialogContainer *ngIf="openFrequency && popupType==='Edit Popup'">
    <app-master-scoping-map-frequency (close)="closeFrequencyDialog()" [selectedActivities]="editDataArray[selectedRowIndex].selectedActivities"
        [popupType]="popupType" [selectedEntity]="selectedEntity.value" [selectedService]="selectedService.value">
    </app-master-scoping-map-frequency>
</div>
<div kendoDialogContainer *ngIf="openFrequency && popupType==='Add Popup'">
    <app-master-scoping-map-frequency (close)="closeFrequencyDialog()"
        [selectedActivities]="editDataArray[selectedRowIndex].selectedActivities" [popupType]="popupType"
        [rowIndex]="selectedRowIndex">
    </app-master-scoping-map-frequency>
</div>

<app-alert-popup *ngIf="alertData" [Width]="270" [Data]="alertData" (close)="closePopup()"></app-alert-popup>