import { Component } from '@angular/core';

@Component({
  selector: 'app-addnotices',
  standalone: true,
  imports: [],
  templateUrl: './addnotices.component.html',
  styleUrl: './addnotices.component.css'
})
export class AddnoticesComponent {

}
