<div class="d-flex" style="margin-top:15px;gap:17px;">
    <div>
        <app-combo-box [data]="ClientGstDropdownData" [selectedValue]="selectedClient" [width]="'320px'"
            [Placeholder]="'Select Client'" textField="text" valueField="value"
            (valueChange)="onDropdownClientGstValueChange($event)">
        </app-combo-box>
    </div>



    <div class="col-md-2 colpadding0" style="padding-right: 10px;">
        <app-combo-box [width]="'320px'" [Placeholder]="'Select month/year'"
            (valueChange)="onDropdownMonthYearValueChange($event)" [data]="monthDropdown"
            [selectedValue]="selectedMonthYear" textField="name" valueField="id">
        </app-combo-box>
    </div>



    <div>
        <app-dropdown-list [data]="invoiceTypetDropdown" [selectedValue]="selectedInvoiceType" [width]="'320px'"
            style="margin-left: 130px;" textField="text" valueField="value"
            (valueChange)="onDropdownValueChange($event)">
        </app-dropdown-list>
    </div>
</div>

<div style="display:flex; justify-content: space-between;margin-top:25px;">
    <div>
        <div>
           
            <label class="f-label " title="Invoice Number :">Invoice Number :</label>
        </div>
        <div>
           
            <label class="f-label " title="Invoice Amount :">Invoice Amount :</label>
        </div>
        <div>
         
            <label class="f-label " title="CGST Amount :">CGST Amount :</label>
        </div>
        <div>
          
            <label class="f-label " title="SGST Amount :">SGST Amount :</label>
        </div>

        <div>
           
            <label class="f-label " title="IGST Amount:">IGST Amount :</label>
        </div>
        <div>
            
            <label class="f-label " title="Total Amount :">Total Amount :</label>
        </div>
    </div>
    <div>

        <div>
            <label class="f-label " title="Remarks">Remarks<sub class="text-danger">*</sub></label>
            <div class="require-container " style="    margin-top: 5px;">
                <textarea class="form-control f-textbox" title="Remarks"></textarea>

            </div>
        </div>

        <div>


            <label class="f-label " title="SLA Upload" style="margin-top: 10px;">Upload<sub class="text-danger">*</sub></label>
            <div class="require-container">
                <div>
                   

                    <span *ngIf="!fileNames['SlaUpload']" title="Uploaded File"
                        style="color: #A0A0A0;vertical-align:sub;">No file
                        chosen</span>
                    <span *ngIf="fileNames['SlaUpload']">
                        {{fileNames['SlaUpload']}}
                    </span>
                    <input type="file" #SlaUpload (change)="onFileChange($event, 'SlaUpload')" id="fileInput"
                        style="display: none;" accept=".xlsx" />
                    <button class="figma-btn-gray" (click)="SlaUpload.click()">Browse</button>
                </div>
                <div *ngIf="!fileNames['SlaUpload']&&showValidationErrors" class="text-danger">
                    File is required.
                </div>
            </div>
        </div>

    </div>
</div>
<div>
    <button class="figma-btn-blue " style="margin-left: 46%; margin-top: 30px;"
        (click)="OnSubmitInvoiceApprovelPendingDetails()">
        Raise Credit Note
    </button>
</div>