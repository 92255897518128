import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatusReportService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  getBranchDropdown(entityId:string):Observable<HttpResponse<any>>{
    const url =`${this.apiUrl}/api/ClientDetailsManagement/GetBranchDropdownlist?EntityId=${entityId}`;
    return this.http.get<any>(url,{ observe: 'response' });
  }
  getMonth(Months:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Common/Get_LC_CodeDescription?Type=${Months}`;
    return this.http.get(url, { observe: 'response' });
  }
  downloadDocument(recordID: number, docType: string = 'REG'): Observable<HttpResponse<Blob>> {
    const url = `${this.apiUrl}/api/RegistrationRepository/RLCS_DocumentDownload?recordID=${recordID}&docType=${docType}`;
    return this.http.get(url, { observe: 'response', responseType: 'blob' });
  }

  getcompliancePeriod(type:string):Observable<HttpResponse<any>>{
    const url = `${this.apiUrl}/api/Common/Get_LC_CodeDescription?Type=${type}`;
    return this.http.get(url, { observe: 'response' });
  }
  getComplianceStatus(ComStatus:string):Observable<HttpResponse<any>>{//complian status
    let url = `${this.apiUrl}/api/Common/Get_LC_CodeDescription?Type=${ComStatus}`;
    return this.http.get<any>(url,{ observe: 'response' })
  }
  getComplianceStatuss(Frequency:string):Observable<HttpResponse<any>>{//complian status
    let url = `${this.apiUrl}/api/Common/Get_LC_CodeDescription?Type=${Frequency}`;
    return this.http.get<any>(url,{ observe: 'response' })
  }
 
  getReportGridData(payload:any): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/LC_Reports/GetComplianceStatusReportNew?userID=${payload.userID}&profileID=${payload.profileID}&custID=${payload.custID}&distID=${payload.distID}&spID=${payload.spID}&roleCode=${payload.roleCode}&ClientID=${payload.clientID}`;
    return this.http.get(url, { observe: 'response' });
  }


  exportToExcel(payload:any) {
    const url = `${this.apiUrl}/api/LC_Reports/GetComplianceStatusReport_ExporttoExcel?userID=${payload.userID}&profileID=${payload.profileID}&custID=${payload.custID}&distID=${payload.distID}&spID=${payload.spID}&roleCode=${payload.roleCode}&ClientID=${payload.ClientID}`;
    return this.http.get(url, { observe: 'response' });
  }
  GetMonthlyComplianceSummary(value: any): Observable<any> {
    const body = {
      branchID: value.branchID,
      month: value.month,
      year: value.year,
      customerID: value.customerID,
      userID: value.userID,
      profileID: value.profileID
    };
  
    const url = `${this.apiUrl}/api/ClientPortal/GetMonthlyComplianceSummary`;
    
    // Directly return the body without wrapping in HttpResponse
    return this.http.post<any>(url, body);
  }
  GetBoardingComplianceSummary(selectedbranchID:any, customerID: any): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/ClientPortal/GetBoardingComplianceSummary?selectedbranchID=${selectedbranchID}&customerID=${customerID}`;
    return this.http.get(url, { observe: 'response' });
  }
  
}