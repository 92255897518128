import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

export interface ActivityAndFrequency {
  activity: string;
  frequencies: string[];
}

export interface ScopingObjModel {
  CustomerId: string | null;
  EntityId: string;
  State: string;
  Service: string;
  Mandate: string;
  LstActivity: string;
  LstActs: string;
  activityAndFrequencies: ActivityAndFrequency[];
}

@Injectable({
  providedIn: 'root'
})


export class MasterScopingSetupService {
  private baseUrl = environment.apiUrl;
  private activityFrequencyMapping: any[] = [];

  constructor(private http: HttpClient) { }

  getGridData(customerId: number): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/MasterScoping/Get_LC_MasterScopedEntities?CustomerId=${customerId}`;
    return this.http.get<any>(url, { observe: 'response' })
  }
  getEntitiesDropdown(customerId: number, flag: string): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/MasterScoping/GetEntitiesByCustomer?CustomerId=${customerId}&Flag=${flag}`;
    return this.http.get<any>(url, { observe: 'response' })
  }
  getStatesDropdown(): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/MasterScoping/GetStateMaster`;
    return this.http.get<any>(url, { observe: 'response' })
  }
  getServiceDropdown(): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/MasterScoping/LC_GetListOfService`;
    return this.http.get<any>(url, { observe: 'response' })
  }
  getActivityDropdown(service: string): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/MasterScoping/LC_GetActivitiesByService?Service=${service}`;
    return this.http.get<any>(url, { observe: 'response' })
  }
  getNatoreOfActDropdown(activities: string): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/MasterScoping/LC_NatureOfAct?Activities=${activities}`;
    return this.http.get<any>(url, { observe: 'response' })
  }
  getActDropdown(activities: string, natoueOfAct: string, states: string, popupType: string): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/MasterScoping/LC_GetLabourStatutoryActs?Activities=${activities}&SelectedNatureOfActs=${natoueOfAct}&SelectedStates=${states}&IsFromPopup=${popupType}`;
    return this.http.get<any>(url, { observe: 'response' })
  }
  saveMasterScoping(scopingObj: ScopingObjModel): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/MasterScoping/Save_LC_MasterScoping`;
    return this.http.post<any>(url, scopingObj, { observe: 'response' })

  }
  setActivityFrequencyMapping(mapping: any[]): void {
    this.activityFrequencyMapping = mapping;
  }
  getActivityFrequencyMapping(): any[] {
    return this.activityFrequencyMapping;
  }

  getEditDetails(entityId: string): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/MasterScoping/Get_MasterScopingDetails?EntityId=${entityId}`;
    return this.http.get<any>(url, { observe: 'response' })
  }

  getFrequencyData(entityId:string,service:string): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/MasterScoping/LC_GetFreqMapping?EntityId=${entityId}&Service=${service}`;
    return this.http.get<any>(url, { observe: 'response' })
  }

  updateMasterScoping(scopingObj: ScopingObjModel): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/MasterScoping/Update_LC_MasterScoping`;
    return this.http.post<any>(url, scopingObj, { observe: 'response' })

  }
}
