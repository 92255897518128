import { Component, OnInit, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TabsService } from '../../app/service/tabs.service';
import { MenuService } from '../service/menu.service';
import { StateService } from '../service/state.service';

interface Tab {
  tabName: string;
  routePath: string;
  moduleName: string;
  sort_order: number;
}

@Component({
  selector: 'app-tabs',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css']
})
export class TabsComponent implements OnInit {

  public tabs: Tab[] = [];
  sortedTabs: Tab[] = [];
  selectedTab?: string;

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private tabsService: TabsService,
    private menuService: MenuService,
    private stateService: StateService
  ) { }

  ngOnInit(): void {
    this.menuService.selectedMenu$.subscribe(menu => {
      this.fetchTabs(menu);
    });
    // console.log(this.fetchTabs(menu));
    
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      this.selectedTab = this.tabs.find(tab => tab.routePath === this.router.url)?.tabName;
      this.applySelectedTabClass();
    });


    const initialTab = this.tabs.find(tab => tab.routePath === this.router.url);
    if (initialTab) {
      this.selectedTab = initialTab.tabName;
      this.applySelectedTabClass();
    }
  }

  fetchTabs(menu: string) {
    this.tabsService.getTabsDetails(menu).subscribe({
      next: (response) => {
        if (response) {
          this.tabs = response.body;
          this.sortedTabs = this.tabs.sort((a, b) => a.sort_order - b.sort_order);
          console.log(this.sortedTabs)
          // let store = this.sortedTabs.length > 0 ? sessionStorage.setItem('isEnabled', 'true') : sessionStorage.setItem('isEnabled', 'false');
          const isEnabled = this.sortedTabs.length > 0;
          this.stateService.updateIsEnabled(isEnabled);
          const initialTab = this.tabs.find(tab => tab.routePath === this.router.url);
          if (initialTab) {
            this.selectedTab = initialTab.tabName;
            this.applySelectedTabClass();
          }
        }
      },
      error: (error) => {
        console.error('Error fetching Tabs data', error);
      }
    });
  }

  navigateTo(tab: Tab): void {
    this.selectedTab = tab.tabName;
    this.router.navigate([tab.routePath]);
    this.applySelectedTabClass();   // Added method call to apply the selected tab class
  }

  isTabSelected(tab: Tab): boolean {
    return this.selectedTab === tab.tabName;
  }

  applySelectedTabClass(): void {
    setTimeout(() => { // Ensure DOM is updated before applying class
      const elements = document.querySelectorAll('.listItem');
      elements.forEach((element: Element) => {
        if (element.innerHTML.includes(this.selectedTab || '')) {
          this.renderer.addClass(element, 'selected-tab');
        } else {
          this.renderer.removeClass(element, 'selected-tab');
        }
      });
    }, 0);
  }
}
