<kendo-dialog #dialogRef title="Add New Industry-Skill-Designation" (close)="closePopup()" [width]="950" 
    class="custom-dialog">
    <div class="row dialog-content">
        <div class="col-md-6 inline-flex-container">
            <label class="f-label">
                State<sub style="color: red; ">*</sub>
            </label>
            <div>
                <app-combo-box [width]="'250px'" [Placeholder]="'Search State/Select State'"
                    [data]="filteredStateDropdownData" (input)="filterStates($event)"
                    [hasError]="!selectedState && showValidation" (valueChange)="StateValueChange($event)">
                </app-combo-box>
                <!-- <app-combo-box [width]="'250px'" [Placeholder]="'Search State/Select State'"
                    (valueChange)="StateValueChange($event)" [data]="stateDropdownData" (input)="filterStates()"
                    [hasError]="!selectedState &&  showValidation">
                </app-combo-box> -->
                <!-- <small *ngIf="showValidation && !selectedState" class="invalid-feedback" style="align-items: left;">
                    State is required.
                </small> -->
            </div>
        </div>
        <div class="col-md-6 inline-flex-container">
            <label class="f-label">
                Establishment Type<sub style="color: red; ">*</sub>
            </label>
            <div>
                <!-- <app-combo-box [width]="'250px'" [Placeholder]="'Search Establishment Type'"
                    (filterChange)="EstablishTypeDropdown($event)" (valueChange)="EstablishValueChange($event)"
                    [data]="EstablishDropdownData" [hasError]="!selectedEstablishment &&  showValidation">
                </app-combo-box> -->
                <!-- <small *ngIf="showValidation && !selectedEstablishment" class="invalid-feedback">
                    Establishment Type is required.
                </small> -->
                <app-combo-box-free-text [width]="'250px'" [textField]="'name'" [valueField]="'id'"
                    [Placeholder]="'Search Establishment Type'" (filterChange)="EstablishTypeDropdown($event)"
                    (valueChange)="EstablishValueChange($event)" [data]="EstablishDropdownData" [allowCustom]="true"
                    [hasError]="!selectedEstablishment && showEstValidation">
                </app-combo-box-free-text>
            </div>
        </div>
    </div>

    <div class="row" style="margin-bottom: 25px;">
        <div class="col-md-6 inline-flex-container">
            <label class="f-label">
                Designation<sub style="color: red; ">*</sub>
            </label>
            <div class="require-container">
                <!-- <app-combo-box [width]="'250px'" [Placeholder]="'Search Designation'"
                    (filterChange)="DesignationDropdown($event)" (valueChange)="DesignationValueChange($event)"
                    [data]="DesignationDropdownData" [hasError]="!selectedDesignation &&  showValidation">
                </app-combo-box> -->
                <!-- <small *ngIf="showValidation && !selectedDesignation" class="invalid-feedback">
                    Designation is required.
                </small> -->
                <app-combo-box-free-text [width]="'250px'" [textField]="'name'" [valueField]="'id'"
                    [Placeholder]="'Search Designation'" (filterChange)="DesignationDropdown($event)"
                    (valueChange)="DesignationValueChange($event)" [data]="DesignationDropdownData" [allowCustom]="true"
                    [hasError]="!selectedDesignation &&  showDesignationValidation">
                </app-combo-box-free-text>
            </div>
        </div>
        <div class="col-md-6 inline-flex-container">
            <label class="f-label">
                Skill Category<sub style="color: red; ">*</sub>
            </label>
            <div class="require-container">
                <app-dropdown-list [data]="SkillCategorydropdownData" [width]="'250px'" textField="text"
                    valueField="value" [selectedValue]="SkillCategory" (valueChange)="SkillCategoryValueChange($event)"
                    [hasError]="!SkillCategory.value && showValidation">
                </app-dropdown-list>
                <!-- <app-dropdown-list [data]="AddressTypeDropdownData" [selectedValue]="selectedAddressType"
                    (valueChange)="onDropdownValueChange($event,'AddressType')"
                    [hasError]="!selectedAddressType.value && showErrorValidation" textField="text" valueField="value"
                    [width]="'250px'"  [isDisabled]="isStateDropdownDisabled">
                </app-dropdown-list> -->
                <!-- <app-combo-box [width]="'250px'" [Placeholder]="'Search Skill Category'"
                    (filterChange)="SkillCategoryDropdown($event)" (valueChange)="SkillCategoryValueChange($event)"
                    [data]="SkillCategoryDropdownData" [hasError]="!selectedSkills &&  showValidation">
                </app-combo-box> -->
                <!-- <small *ngIf="showValidation && !selectedSkills" class="invalid-feedback">
                    Skill Category is required.
                </small> -->
            </div>
        </div>
        <div class="col-md-6 inline-flex-container" *ngIf="industryType">
            <label class="f-label">
                Industry Type<sub style="color: red; ">*</sub>
            </label>
            <div>
                <!-- <app-combo-box [width]="'250px'" [Placeholder]="'Search Industry Type'"
                    (filterChange)="IndustryTypeDropdown($event)" (valueChange)="IndustryChange($event)"
                    [data]="IndustryTypeDropdownData" [hasError]="!selectedIndustryType &&  showValidation">

                </app-combo-box> -->
                <app-combo-box-free-text [width]="'250px'" [textField]="'name'" [valueField]="'id'"
                    [Placeholder]="'Search Industry Type'" (filterChange)="IndustryTypeDropdown($event)"
                    (valueChange)="IndustryChange($event)" [data]="IndustryTypeDropdownData" [allowCustom]="true"
                    [hasError]="!selectedIndustryType &&  showValidation">
                </app-combo-box-free-text>
            </div>
        </div>
    </div>



    <kendo-dialog-actions style="border: none; ">
        <button kendoButton class="figma-btn-blue figma-popup" (click)="OnSave()">Save</button>
    </kendo-dialog-actions>
</kendo-dialog>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270" [Data]="alertData"
    (close)="closeAlert()"></app-alert-popup>