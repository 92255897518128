import { Component, OnInit } from '@angular/core';
import { CommercialMappingService } from '../../../service/commercial-mapping.service';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { NgClass, NgIf } from '@angular/common';
import { KendoDatepickerComponent } from '../../../components/kendo-datepicker/kendo-datepicker.component';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { GridModule } from '@progress/kendo-angular-grid';
import { PageChangeEvent } from '@progress/kendo-angular-treelist';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { UserService } from '../../../service/user.service';

@Component({
  selector: 'app-commercial-mapping',
  standalone: true,
  imports: [DropdownListComponent, NgClass, NgIf, KendoDatepickerComponent, FormsModule, GridModule, GridActionItemComponent, ReactiveFormsModule, AlertPopupComponent],
  templateUrl: './commercial-mapping.component.html',
  styleUrl: './commercial-mapping.component.css'
})
export class CommercialMappingComponent implements OnInit {
  TabOption = ''
  showtabs = false;
  custId!: any;
  corporateId!: any;
  EntitydropdownData!: any;
  public searchQuery: string = '';
  public Mandate: string = '';
  public element_count!: number;
  public per_element_amount!: number;
  public consolidated_value!: number;
  showPOTypeDropdown = false;
  showWorkingOrder = false;
  showConsolidatedGrid = false;
  showSplitTypeGrid = false;
  showServiceWiseGrid = false;
  showBranchWiseDropdown = false;
  showSplitTypeDropdown = false;
  showBillingTypeDropdown = false;
  showBillingTypeRegularDropdown = false;
  showOneTimeRegularDropdown = false;
  showBillingTypeSplitDropdown = false;
  showChargesDropdown = false;
  showFixedAmount = false;
  showTLCharges = false;
  showCommercialElementTypeDropdown = false;
  showVariableBillingTypeDropdown = false;
  showVariableBillingHeadCountTable = false;
  showVariableBillingBranchCountTable = false;
  showVariableBillingVendorWiseTable = false;
  showVariableBillingHeadCountTableVar = false;
  showVariableBillingBranchCountTableVar = false;
  showVariableBillingVendorWiseTableVar = false;
  showBranchwiseCommercialUpload = false;
  showActivityWiseActivity = false;
  showCommercialType = false;
  public alertData = { alertMessage: '', alertVisible: '' };
  ActivityTypedropdownData: any[] = [];
  OneTimeActivityTypedropdownData: any[] = [];
  CommercialTypedropdownData: any[] = [];
  CommercialElementTypedropdownData: any[] = [];
  VariableBillingTypedropdownData: any[] = [];
  ActivitydropdownData: any[] = [];
  OneTimeCommercialTypedropdownData: any[] = [
    { text: "Select", value: "" },
    { text: "Fixed", value: "Fixed" },
    { text: "Included in recurring", value: "Included in recurring" }
  ]
  ChargesdropdownData: any[] = [
    { text: "Select", value: "" },
    { text: "Gov Fees", value: "Gov Fees" },
    { text: "PC Charges", value: "PC Charges" },
    { text: "TL Charges", value: "TL Charges" }
  ]
  BillingZonedropdownData: any[] = [
    { text: "Select", value: "" },
    { text: "North", value: "North" },
    { text: "South", value: "South" },
    { text: "East", value: "East" },
    { text: "West", value: "West" }
  ]
  BillingTypedropdownData: any[] = [
    { text: "Select", value: "" },
    { text: "Regular", value: "Regular" },
    { text: "Split", value: "Split" }
  ]
  RevenuedropdownData: any[] = [
    { text: "Select", value: "" },
    { text: "Greater than one lakh", value: "1" },
    { text: "Less than one lakh", value: "0" }
  ]
  MandateTypedropdownData: any[] = [
    { text: "Select", value: "" },
    { text: "One time", value: "One time" },
    { text: "Recurring", value: "Recurring" },
    { text: "Both", value: "Both" }
  ]
  PO_PWdropdownData: any[] = [
    { text: "Select", value: "" },
    { text: "PO", value: "PO" },
    { text: "WO", value: "WO" }
  ]
  POTypedropdownData: any[] = [
    { text: "Select", value: "" },
    { text: "Consolidated", value: "Consolidated" },
    { text: "Multiple", value: "Multiple" }
  ]
  SplitTypedropdownData: any[] = [
    { text: "Select", value: "" },
    { text: "Service Wise", value: "ServiceWise" },
    { text: "Branch Wise", value: "BranchWise" }
  ]
  SplitTypeComdropdownData: any[] = [
    { text: "Select", value: "" },
    { text: "Branch Wise", value: "Branch Wise" },
    { text: "Activity Wise", value: "Activity Wise" }
  ]
  public updatedInstances: { instanceId: string; activationDate: string }[] = [];
  public gridData: any = { data: [], total: 0 }
  public gridDataCommercial: any = { data: [], total: 0 }
  public parentColumns: any[] = [];
  public Entity = { text: 'Select Entity', value: '' };
  public BillingZone = { text: 'Select', value: '' };
  public CommercialType = { text: 'Select', value: '' };
  public ActivityType = { text: 'Select', value: '' };
  public CommercialElementType = { text: 'Select', value: '' };
  public BillingType = { text: 'Select', value: '' };
  public Revenue = { text: 'Select', value: '' };
  public PO_PW = { text: 'Select', value: '' };
  public POType = { text: 'Select', value: '' };
  public MandateType = { text: 'Select', value: '' };
  public Multiple = { text: 'Select', value: '' };
  public SplitType = { text: 'Select', value: '' };
  public SplitTypeCom = { text: 'Select', value: '' };
  public VariableBillingType = { text: 'Select', value: '' };
  public Activity = { text: 'Select', value: '' };
  public OneTimeActivity = { text: 'Select', value: '' };
  public OneTimeCommercialType = { text: 'Select', value: '' };
  public Charges = { text: 'Select', value: '' };
  public editedRowIndex: number | null = null;
  public editedColumn: string | null = null;
  BillingCycleDate: Date | null = null;
  BillingSubmissionDate: Date | null = null;
  datePickerValue: Date | null = null;
  poTo: Date | null = null;
  poFrom: Date | null = null;
  poDate: Date | null = null;
  isSaveButtonVisible = false;
  PaymentCreditPeriod!: number;
  PONumber!: number;
  FixedAmount!: number;
  TLCharges!: string;
  WorkOrder!: string;
  public placeholder: string = 'DD-MM-YYYY';
  headCount: number = 0;
  perHeadCountAmount: number = 0;
  consolidatedHeadCountValue: number = 0;
  noOfBranches: number = 0;
  perBranchAmount: number = 0;
  consolidatedBranchValue: number = 0;
  noOfVendors: number = 0;
  perVendorAmount: number = 0;
  consolidatedVendorValue: number = 0;
  noOfBranchesvar: number = 0;
  perBranchAmountvar: number = 0;
  consolidatedBranchValuevar: number = 0;
  amountPerHeadOffice: number = 0;
  totalHeadOfficeValue: number = 0;
  numberOfFactories: number = 0;
  amountPerFactory: number = 0;
  totalFactoryValue: number = 0;
  showValidationErrors = false;
  showValidationErrorsCom = false;
  checkedRows: boolean[] = [];
  public formattedDate!: string;
  public pageSize = 10;
  public skip = 0;
  form!: FormGroup;
  constructor(private fb: FormBuilder, private CommercialMappingService: CommercialMappingService,private user:UserService) {
    this.form = this.fb.group({
      spoc_name: ['', [Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]],
      spoc_email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]],
      spoc_contact: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      invoice_spoc_address: ['', [Validators.required]],
      dispatch_spoc_address: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.custId =this.user.getCustomerId();
    this.corporateId = this.user.getCustomerName();
    // this.custId = localStorage.getItem('CustomerId');
    // this.corporateId = localStorage.getItem('CorporateId');
    this.getEntityDropdownData(parseInt(this.custId || ''));
  }
  InitCommercialData(data: any) {
    console.log('commercial data', data);
    this.BillingType = { text: '', value: data?.billing_type || '' };
    this.ActivityType = { text: '', value: data?.activity || '' };
    this.OneTimeActivity = { text: '', value: data?.activity || '' };
    this.Activity = { text: '', value: data?.activity || '' };
    this.CommercialType = { text: '', value: data?.commercial_type || '' };
    this.OneTimeCommercialType = { text: '', value: data?.commercial_type || '' };
    this.SplitTypeCom = { text: '', value: data?.split_type || '' };
    this.PONumber = data?.po_number || '';
    this.FixedAmount = data?.fixed_amount || '';
    this.TLCharges = data?.tl_charges || '';
    this.headCount = data?.element_count || 0;
    this.perHeadCountAmount = data?.per_element_amount || 0;
    this.consolidatedHeadCountValue = data?.consolidated_value || 0;
    this.noOfBranches = data?.element_count || 0;
    this.perBranchAmount = data?.per_element_amount || 0;
    this.consolidatedBranchValue = data?.consolidated_value || 0;
    this.noOfVendors = data?.element_count || 0;
    this.perVendorAmount = data?.per_element_amount || 0;
    this.consolidatedVendorValue = data?.consolidated_value || 0;
    this.noOfBranchesvar = data?.element_count || 0;
    this.perBranchAmountvar = data?.per_element_amount || 0;
    this.consolidatedBranchValuevar = data?.consolidated_value || 0;
    this.amountPerHeadOffice = data?.per_element_amount || 0;
    this.totalHeadOfficeValue = data?.consolidated_value || 0;
    this.numberOfFactories = data?.element_count || 0;
    this.amountPerFactory = data?.per_element_amount || 0;
    this.totalFactoryValue = data?.consolidated_value || 0;
    this.Charges = { text: '', value: data?.charges || '' };
    this.CommercialElementType = { text: '', value: data?.commercial_element_type || '' };
    this.VariableBillingType = { text: '', value: data?.variable_billing_types || '' };
    if (this.Mandate == "One Time") {
      this.loadGridOneTime(this.Entity.value);
      this.showSplitTypeGrid = true;
      this.getActivityDropdownData(this.Entity.value);
      this.showOneTimeRegularDropdown = true;
      this.showChargesDropdown = true;
      this.showCommercialType = false;
      this.showBillingTypeDropdown = false;
      this.showFixedAmount = false;
      this.showBillingTypeRegularDropdown = false;
      this.showCommercialElementTypeDropdown = false;
      this.showVariableBillingTypeDropdown = false;
      this.showVariableBillingHeadCountTable = false;
      this.showVariableBillingBranchCountTable = false;
      this.showVariableBillingVendorWiseTable = false;
      this.showVariableBillingHeadCountTableVar = false;
      this.showVariableBillingBranchCountTableVar = false;
      this.showVariableBillingVendorWiseTableVar = false;
      this.showBillingTypeDropdown = false;
      this.showBillingTypeSplitDropdown = false;
      this.showTLCharges = false;
    } else if (this.Mandate == 'Both' || this.Mandate == 'Recurring') {
      this.showBillingTypeDropdown = true;
      this.showCommercialType = false;
      this.showSplitTypeGrid = false;
      this.showBillingTypeRegularDropdown = false;
      this.showFixedAmount = false;
      this.showOneTimeRegularDropdown = false;
      this.showCommercialElementTypeDropdown = false;
      this.showVariableBillingTypeDropdown = false;
      this.showVariableBillingHeadCountTable = false;
      this.showVariableBillingBranchCountTable = false;
      this.showVariableBillingVendorWiseTable = false;
      this.showVariableBillingHeadCountTableVar = false;
      this.showVariableBillingBranchCountTableVar = false;
      this.showVariableBillingVendorWiseTableVar = false;
      this.showBillingTypeSplitDropdown = false;
      this.showChargesDropdown = false;
      this.showTLCharges = false;
    } else {
      this.showBillingTypeDropdown = false;
      this.showCommercialType = false;
      this.showSplitTypeGrid = false;
      this.showBillingTypeRegularDropdown = false;
      this.showFixedAmount = false;
      this.showOneTimeRegularDropdown = false;
      this.showCommercialElementTypeDropdown = false;
      this.showVariableBillingTypeDropdown = false;
      this.showVariableBillingHeadCountTable = false;
      this.showVariableBillingBranchCountTable = false;
      this.showVariableBillingVendorWiseTable = false;
      this.showVariableBillingHeadCountTableVar = false;
      this.showVariableBillingBranchCountTableVar = false;
      this.showVariableBillingVendorWiseTableVar = false;
      this.showBillingTypeSplitDropdown = false;
      this.showChargesDropdown = false;
      this.showTLCharges = false;
    }
    if (data?.billing_type == 'Regular') {
      this.showBillingTypeRegularDropdown = true;
      this.getActivityTypeDropdownData();
      this.getCommercialTypeDropdownData();
      this.getVaribaleBillingTypeDropdownData();
    } else if (data?.billing_type == 'Split') {
      this.getActivityTypeDropdownData();
      this.getCommercialTypeDropdownData();
      this.getVaribaleBillingTypeDropdownData();
      this.showBillingTypeSplitDropdown = true;
    }
    if (data?.split_type == 'Activity Wise') {
      this.loadGridSplitActivityWise(this.Entity.value);
      this.getActivityDropdownData(this.Entity.value);
      this.showActivityWiseActivity = true;
      this.showCommercialType = true;
      this.showSplitTypeGrid = true;
    } else if (data?.split_type == 'Branch Wise') {
      this.showSplitTypeGrid = true;
      this.showBranchwiseCommercialUpload = true;
      this.loadGridSplitBranchWise(this.Entity.value);
    }
    if (data?.commercial_type == 'Fixed') {
      this.showFixedAmount = true;
    } else if (data?.commercial_type == 'Slab Wise billing') {
      this.showFixedAmount = false;
      this.getCommercialElementTypeDropdownData();
      this.showCommercialElementTypeDropdown = true;
    } else if (data?.commercial_type == 'Variable') {
      this.getVaribaleBillingTypeDropdownData();
      this.showVariableBillingTypeDropdown = true;
    }

    if (data?.commercial_element_type == 'Head Count Wise') {
      this.showVariableBillingHeadCountTable = true;
    } else if (data?.commercial_element_type == 'Branch Wise') {
      this.showVariableBillingBranchCountTable = true;
    } else if (data?.commercial_element_type == 'Vendor Wise') {
      this.showVariableBillingVendorWiseTable = true;
    }
  }

  InitBillingContactData(data: any) {
    if (data) {
      this.form.patchValue(data);
    } else {
      this.form.reset({
        spoc_name: '',
        spoc_email: '',
        spoc_contact: '',
        invoice_spoc_address: '',
        dispatch_spoc_address: ''
      });
    }
  }
  InitBillingData(data: any) {
    console.log('Billing init data',data);
    
    this.BillingZone = { text: '', value: data.billing_zone || '' };
    this.BillingCycleDate = data.billing_cycle ? new Date(data.billing_cycle) : null;
    this.BillingSubmissionDate = data.billing_subm_date ? new Date(data.billing_subm_date) : null;
    this.PaymentCreditPeriod = data.payment_credit_period || '';
    this.Revenue = { text: '', value: data.revenue_gen_tab || '' };
    this.MandateType = { text: '', value: data.mandate_type || '' };
    this.PO_PW = { text: '', value: data.po_wo_appl || '' };
    this.POType = { text: '', value: data.po_type || '' };
    this.WorkOrder = data.work_order || '';
    this.SplitType = { text: '', value: data.split_type || '' };
    if (data.po_type == 'Consolidated') {
      this.loadGrid(data.entityId);
      this.showConsolidatedGrid = true;
      this.showServiceWiseGrid = false;
      this.showSplitTypeDropdown = false;
      this.showBranchWiseDropdown = false;
    } else if (data.po_type == 'Multiple') {
      this.loadGrid(data.entityId);
      this.showConsolidatedGrid = false;
      this.showSplitTypeDropdown = true;
      this.showServiceWiseGrid = true;
    }
    if (data.po_wo_appl == 'PO') {
      this.showPOTypeDropdown = true;
    } else if (data.po_wo_appl == 'WO') {
      this.showWorkingOrder = true;
    } else {
      this.showPOTypeDropdown = false;
      this.showWorkingOrder = false;
    }
    if (data.split_type == 'BranchWise') {
      this.showBranchWiseDropdown = true
      this.showServiceWiseGrid = false;
    } else if (data.split_type == 'ServiceWise') {
      this.showBranchWiseDropdown = false;
      this.showServiceWiseGrid = true;
    }
  }

  tabsgoTo(value: string) {
    if (value == 'BillingDetails') {
      this.TabOption = value;
      this.showValidationErrors = false;
    } else if (value == 'Commercial') {
      this.TabOption = value;
      this.showValidationErrorsCom = false;
      this.GetCommercialData(this.Entity.value);
    } else {
      this.TabOption = value;
      this.getBillingContactData(this.Entity.value);
    }
  }

  onDropdownValueChange(value: any, type: string) {
    if (type === 'Entity') {
      this.showValidationErrors = false;
      this.Entity = { text: value.text, value: value.value };
      this.gridData = [];
      this.showConsolidatedGrid = false;
      this.showServiceWiseGrid = false;
      this.showSplitTypeDropdown = false;
      this.InitBillingData('');
      this.InitCommercialData('');
      this.showtabs = true;
      this.TabOption = 'BillingDetails'
      this.getBillingData(value.value);
      this.GetMandatetype(value.value);
      this.loadGrid(value.value);
      if (this.Entity.value == '') {
        this.showtabs = false;
        this.TabOption = ''
      }
    } else if (type === 'BillingZone') {
      this.BillingZone = { text: value.text, value: value.value };
    } else if (type === 'Revenue') {
      this.Revenue = { text: value.text, value: value.value };
    } else if (type === 'MandateType') {
      this.MandateType = { text: value.text, value: value.value };
    } else if (type === 'PO_PW') {
      this.PO_PW = { text: value.text, value: value.value };
      if (value.value == 'PO') {
        this.showPOTypeDropdown = true;
        this.showWorkingOrder = false;
      } else if (value.value == 'WO') {
        this.showWorkingOrder = true;
        this.showPOTypeDropdown = false;
        this.showConsolidatedGrid = false;
        this.showSplitTypeDropdown = false;
      }
    } else if (type === 'POType') {
      this.POType = { text: value.text, value: value.value };
      if (value.value == 'Consolidated') {
        this.gridData = [];
        this.loadGrid(this.Entity.value);
        this.showServiceWiseGrid = false;
        this.showSplitTypeDropdown = false;
        this.showConsolidatedGrid = true;
      } else if (value.value == 'Multiple') {
        this.gridData = [];
        this.showSplitTypeDropdown = true;
        this.showConsolidatedGrid = false;
      } else {
        this.showSplitTypeDropdown = false;
      }
    } else if (type === 'SplitType') {
      this.SplitType = { text: value.text, value: value.value };
      if (value.value == 'ServiceWise') {
        this.loadGrid(this.Entity.value);
        this.showServiceWiseGrid = true;
        this.showBranchWiseDropdown = false;
      } else if (value.value == 'BranchWise') {
        this.showServiceWiseGrid = false;
        this.showBranchWiseDropdown = true;
      }
    } else if (type === 'BillingType') {
      this.BillingType = { text: value.text, value: value.value };
      if (value.value == 'Regular') {
        this.showBillingTypeRegularDropdown = true;
        this.getActivityTypeDropdownData();
        this.getCommercialTypeDropdownData();
        this.getVaribaleBillingTypeDropdownData();
        this.showBillingTypeSplitDropdown = false;
        this.showBranchwiseCommercialUpload = false;
        this.showActivityWiseActivity = false;
        this.showSplitTypeGrid = false;
        this.showCommercialType = false;
        this.showVariableBillingHeadCountTable = false;
        this.showVariableBillingBranchCountTable = false;
        this.showVariableBillingVendorWiseTable = false;
        this.showVariableBillingHeadCountTableVar = false;
        this.showVariableBillingBranchCountTableVar = false;
        this.showVariableBillingVendorWiseTableVar = false;
        this.showCommercialElementTypeDropdown = false
      } else if (value.value == 'Split') {
        this.getActivityTypeDropdownData();
        this.getCommercialTypeDropdownData();
        this.getVaribaleBillingTypeDropdownData();
        this.showBillingTypeSplitDropdown = true;
        this.showBillingTypeRegularDropdown = false;
        this.showFixedAmount = false;
        this.showCommercialElementTypeDropdown = false;
        this.showVariableBillingTypeDropdown = false;
        this.showVariableBillingHeadCountTable = false;
        this.showVariableBillingHeadCountTable = false;
        this.showVariableBillingBranchCountTable = false;
        this.showVariableBillingVendorWiseTable = false;
        this.showVariableBillingHeadCountTableVar = false;
        this.showVariableBillingBranchCountTableVar = false;
        this.showVariableBillingVendorWiseTableVar = false;
      }
    } else if (type === 'ActivityType') {
      this.ActivityType = { text: value.text, value: value.value };
    } else if (type === 'CommercialType') {
      this.CommercialType = { text: value.text, value: value.value };
      if (value.value == 'Fixed') {
        this.showFixedAmount = true;
        this.showCommercialElementTypeDropdown = false;
        this.showVariableBillingTypeDropdown = false;
        this.showVariableBillingHeadCountTable = false;
        this.showVariableBillingBranchCountTable = false;
        this.showVariableBillingVendorWiseTable = false;
        this.showVariableBillingHeadCountTableVar = false;
        this.showVariableBillingBranchCountTableVar = false;
        this.showVariableBillingVendorWiseTableVar = false;
      } else if (value.value == 'Slab Wise billing') {
        this.showFixedAmount = false;
        this.getCommercialElementTypeDropdownData();
        this.showCommercialElementTypeDropdown = true;
        this.showVariableBillingTypeDropdown = false;
        this.showVariableBillingHeadCountTable = false;
        this.showVariableBillingHeadCountTableVar = false;
        this.showVariableBillingBranchCountTableVar = false;
        this.showVariableBillingVendorWiseTableVar = false;
      } else if (value.value == 'Variable') {
        this.getVaribaleBillingTypeDropdownData();
        this.showVariableBillingTypeDropdown = true;
        this.showCommercialElementTypeDropdown = false;
        this.showFixedAmount = false;
        this.showVariableBillingHeadCountTable = false;
        this.showVariableBillingHeadCountTable = false;
        this.showVariableBillingBranchCountTable = false;
        this.showVariableBillingVendorWiseTable = false;
      }
    } else if (type == 'CommercialElementType') {
      this.CommercialElementType = { text: value.text, value: value.value };
      if (value.value == 'Head Count Wise') {
        this.showVariableBillingHeadCountTable = true;
        this.showVariableBillingBranchCountTable = false;
        this.showVariableBillingVendorWiseTable = false;
      } else if (value.value == 'Branch Wise') {
        this.showVariableBillingHeadCountTable = false;
        this.showVariableBillingBranchCountTable = true;
        this.showVariableBillingVendorWiseTable = false;
      } else if (value.value == 'Vendor Wise') {
        this.showVariableBillingBranchCountTable = false;
        this.showVariableBillingHeadCountTable = false;
        this.showVariableBillingVendorWiseTable = true;
      }
    } else if (type == 'VariableBillingType') {
      this.VariableBillingType = { text: value.text, value: value.value };
      if (value.value == 'Head Office') {
        this.showVariableBillingHeadCountTableVar = true;
        this.showVariableBillingBranchCountTableVar = false;
        this.showVariableBillingVendorWiseTableVar = false;
      } else if (value.value == 'Branch') {
        this.showVariableBillingHeadCountTableVar = false;
        this.showVariableBillingBranchCountTableVar = true;
        this.showVariableBillingVendorWiseTableVar = false;
      } else if (value.value == 'Factory') {
        this.showVariableBillingBranchCountTableVar = false;
        this.showVariableBillingHeadCountTableVar = false;
        this.showVariableBillingVendorWiseTableVar = true;
      }
    } else if (type === 'SplitTypeCom') {
      this.SplitTypeCom = { text: value.text, value: value.value };
      if (value.value == 'Activity Wise') {
        this.loadGridSplitActivityWise(this.Entity.value);
        this.getActivityDropdownData(this.Entity.value);
        this.showActivityWiseActivity = true;
        this.showCommercialType = true;
        this.showSplitTypeGrid = true;
        this.showBranchwiseCommercialUpload = false;
      } else if (value.value == 'Branch Wise') {
        this.showCommercialType = false;
        this.showActivityWiseActivity = false;
        this.showSplitTypeGrid = true;
        this.showBranchwiseCommercialUpload = true;
        this.showCommercialElementTypeDropdown=false;
        this.showVariableBillingHeadCountTable=false;
        this.showVariableBillingVendorWiseTable=false;
        this.showVariableBillingBranchCountTableVar=false;
        this.showVariableBillingHeadCountTableVar=false;
        this.showVariableBillingVendorWiseTableVar=false;
        this.loadGridSplitBranchWise(this.Entity.value);
      }
    } else if (type === 'Activity') {
      this.Activity = { text: value.text, value: value.value };
    } else if (type === 'OneTimeActivity') {
      this.OneTimeActivity = { text: value.text, value: value.value };
    } else if (type === ' OneTimeCommercialType') {
      this.OneTimeCommercialType = { text: value.text, value: value.value };
      if (value.value == 'Fixed') {
        this.showFixedAmount = true;
      } else if (value.value == 'Included in recurring') {
        this.showFixedAmount = false;
      }
    } else if (type === 'Charges') {
      this.Charges = { text: value.text, value: value.value };
      if (value.value == 'TL Charges') {
        this.showTLCharges = true;
      } else {
        this.showTLCharges = false;
      }
    }
  }
  calculateConsolidatedValue(): void {
    this.consolidatedHeadCountValue = (this.headCount || 0) * (this.perHeadCountAmount || 0);
    this.element_count = this.headCount;
    this.per_element_amount = this.perHeadCountAmount;
    this.consolidated_value = this.consolidatedHeadCountValue;
  }
  calculateConsolidatedBranchValue(): void {
    this.consolidatedBranchValue = this.noOfBranches * this.perBranchAmount;
    this.element_count = this.noOfBranches;
    this.per_element_amount = this.perBranchAmount;
    this.consolidated_value = this.consolidatedBranchValue;
  }
  calculateConsolidatedVendorValue(): void {
    this.consolidatedVendorValue = this.noOfVendors * this.perVendorAmount;
    this.element_count = this.noOfVendors;
    this.per_element_amount = this.perVendorAmount;
    this.consolidated_value = this.consolidatedVendorValue;
  }
  calculateConsolidatedBranchValuevar(): void {
    this.consolidatedBranchValuevar = this.noOfBranchesvar * this.perBranchAmountvar;
    this.element_count = this.noOfBranchesvar;
    this.per_element_amount = this.perBranchAmountvar;
    this.consolidated_value = this.consolidatedBranchValuevar;
  }
  updateTotalHeadOfficeValue(): void {
    const fixedHeadOfficeCount = 1;
    this.totalHeadOfficeValue = fixedHeadOfficeCount * this.amountPerHeadOffice;
    this.element_count = 1;
    this.per_element_amount = this.amountPerHeadOffice;
    this.consolidated_value = this.totalHeadOfficeValue;
  }
  updateTotalFactoryValue(): void {
    this.totalFactoryValue = this.numberOfFactories * this.amountPerFactory;
    this.element_count = this.numberOfFactories;
    this.per_element_amount = this.amountPerFactory;
    this.consolidated_value = this.totalFactoryValue;
  }
  handleButtonClick(event: any) {
    if (event.button.action === "edit") {
    }
  }
  loadGridSplitBranchWise(value: string) {
    this.parentColumns = [
      { field: "state", title: 'State' },
      { field: "location", title: 'Location' },
      { field: "branch", title: 'Branch' },
      { field: "po_number", title: 'po_number' },
      { field: "fixed_amount", title: 'Fixed Amount' },
      { field: "Commercial_Type", title: 'Commercial Type' },
      { field: "Commercial_Element_Type", title: 'Element Type' },
      { field: "element_count", title: 'Element Count' },
      { field: "per_element_count", title: 'Amount' },
      { field: "consolidated_value", title: 'Value' }
    ];
    this.CommercialMappingService.getSplitBranchWiseGrid(value).subscribe({
      next: (response) => {
        debugger;
        const data = response.body;
        this.gridDataCommercial = {
          data: data,
          total: data.length
        };
        console.log('split branch', this.gridDataCommercial)
      },
      error: (err) => {
        console.error('Error fetching entity data', err);
      }
    });
  }
  loadGridOneTime(value: string) {
    this.parentColumns = [
      { field: "activity", title: 'Activity' },
      { field: "commercial_type", title: 'Commercial Type' },
      { field: "fixed_amount", title: 'Fixed Amount' },
      { field: "charges", title: 'Charges' },
      { field: "tl_charges", title: 'Tl Charges' }
    ];
    this.CommercialMappingService.getOneTimeGrid(value).subscribe({
      next: (response) => {
        debugger;
        const data = response.body;
        this.gridDataCommercial = {
          data: data,
          total: data.length
        };
        console.log('ontime grid', this.gridDataCommercial)
      },
      error: (err) => {
        console.error('Error fetching entity data', err);
      }
    });
  }
  loadGridSplitActivityWise(value: string) {
    this.parentColumns = [
      { field: "Activity", title: 'Activity' },
      { field: "po_number", title: 'po_number' },
      { field: "fixed_amount", title: 'Fixed Amount' },
      { field: "Commercial_Type", title: 'Commercial Type' },
      { field: "Commercial_Element_Type", title: 'Commercial Element Type' },
      { field: "element_count", title: 'Element Count' },
      { field: "per_element_count", title: 'Amount' },
      { field: "consolidated_value", title: 'Value' }
    ];
    this.CommercialMappingService.getSplitBranchWiseGrid(value).subscribe({
      next: (response) => {
        debugger;
        const data = response.body;
        this.gridDataCommercial = {
          data: data,
          total: data.length
        };
        console.log('split activity', this.gridDataCommercial)
      },
      error: (err) => {
        console.error('Error fetching entity data', err);
      }
    });
  }
  onFileChange(event: any, type: string) {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel'
      ];

      if (allowedTypes.includes(file.type)) {
        this.fileNames[type] = file.name; 
        this.selectedFiles[type] = file;  
      } else {
        this.fileNames[type] = null;
        this.selectedFiles[type] = null;
        this.alertData = { alertMessage: 'Please select a xlsx file', alertVisible: 'error' };
      }
    } else {
      this.fileNames[type] = null;
      this.selectedFiles[type] = null;
    }

    console.log(file);
  }
  fileNames: { [key: string]: string | null } = {
    BranchwisePOUpload: null,
    BranchwiseCommercialUpload: null
  };

  selectedFiles: { [key: string]: File | null } = {
    BranchwisePOUpload: null,
    BranchwiseCommercialUpload: null
  };

  getBillingData(value: string) {
    this.CommercialMappingService.getBillingDetails(value).subscribe({
      next: (response) => {
        console.log('Billing Data',response);
        
        const data = response.body;
        if (data) {
          this.InitBillingData(data);
        }
      },
      error: (error) => {
        console.error('Error fetching Dropdown data', error);
      }
    });
  }
  getBillingContactData(value: string) {
    this.CommercialMappingService.getBillingContacts(value).subscribe({
      next: (response) => {
        const data = response.body;
        if (data) {
          this.InitBillingContactData(data);
        }
      },
      error: (error) => {
        console.error('Error fetching Dropdown data', error);
      }
    });
  }
  GetMandatetype(value: string) {
    this.CommercialMappingService.getMandate(value).subscribe({
      next: (response) => {
        const data = response.body;
        this.Mandate = data.mandate;
        console.log('mandate', data);

      },
      error: (error) => {
        console.error('Error fetching Dropdown data', error);
      }
    });
  }

  GetCommercialData(value: string) {
    this.CommercialMappingService.getCommercialDetails(value).subscribe({
      next: (response) => {
        const data = response.body;
        this.InitCommercialData(data);
      },
      error: (error) => {
        console.error('Error fetching Dropdown data', error);
      }
    });
  }
  getEntityDropdownData(value: number) {
    this.CommercialMappingService.getEntityDropdown(value, 'yes').subscribe({
      next: (response) => {
        const data = response.body;
        this.EntitydropdownData = [
          { text: 'Select Entity', value: '' },
          ...data.map((item: any) => ({
            text: item.key,
            value: item.value
          }))
        ];
      },
      error: (error) => {
        console.error('Error fetching Dropdown data', error);
      }
    });
  }
  getActivityTypeDropdownData() {
    this.CommercialMappingService.getActivityTypeDropdown().subscribe({
      next: (response) => {
        const data = response.body;
        this.ActivityTypedropdownData = [
          { text: 'Select ', value: '' },
          ...data.map((item: any) => ({
            text: item.key,
            value: item.value
          }))
        ];
      },
      error: (error) => {
        console.error('Error fetching Dropdown data', error);
      }
    });
  }
  getCommercialTypeDropdownData() {
    this.CommercialMappingService.getCommercialTypeDropdown().subscribe({
      next: (response) => {
        const data = response.body;
        this.CommercialTypedropdownData = [
          { text: 'Select ', value: '' },
          ...data.map((item: any) => ({
            text: item.key,
            value: item.value
          }))
        ];
      },
      error: (error) => {
        console.error('Error fetching Dropdown data', error);
      }
    });
  }
  getCommercialElementTypeDropdownData() {
    this.CommercialMappingService.getCommercialElementTypeDropdown().subscribe({
      next: (response) => {
        const data = response.body;
        this.CommercialElementTypedropdownData = [
          { text: 'Select ', value: '' },
          ...data.map((item: any) => ({
            text: item.key,
            value: item.value
          }))
        ];
      },
      error: (error) => {
        console.error('Error fetching Dropdown data', error);
      }
    });
  }
  getVaribaleBillingTypeDropdownData() {
    this.CommercialMappingService.getVariableBillingTypeDropdown().subscribe({
      next: (response) => {
        const data = response.body;
        this.VariableBillingTypedropdownData = [
          { text: 'Select ', value: '' },
          ...data.map((item: any) => ({
            text: item.key,
            value: item.value
          }))
        ];
      },
      error: (error) => {
        console.error('Error fetching Dropdown data', error);
      }
    });
  }
  getActivityDropdownData(value: string) {
    this.CommercialMappingService.getActivityDropdown(value).subscribe({
      next: (response) => {
        const data = response.body;
        console.log(data);
        this.ActivitydropdownData = [
          { text: 'Select ', value: '' },
          ...data.map((item: any) => ({
            text: item.key,
            value: item.value
          }))
        ];
        this.OneTimeActivityTypedropdownData = [
          { text: 'Select ', value: '' },
          ...data.map((item: any) => ({
            text: item.key,
            value: item.value
          }))
        ];
      },
      error: (error) => {
        console.error('Error fetching Dropdown data', error);
      }
    });
  }

  validateMobileNo(event: any): void {
    const input = event.target.value;
    event.target.value = input.replace(/[^0-9]/g, '');
    this.form.get('buyerContactNumber')?.setValue(event.target.value);
  }

  onDateChange(value: Date, type: string) {
    const date = new Date(value.toISOString().split('T')[0]);

    if (type === 'BillingCycleDate') {
      this.BillingCycleDate = date;
    } else if (type === 'BillingSubmissionDate') {
      this.BillingSubmissionDate = date;
    }
  }
  loadGrid(value: string) {
    this.updateFormattedDate();
    this.CommercialMappingService.getPOGrid(value).subscribe({
      next: (response) => {
        const data = response.body;
        console.log('grid',data);
        
        const filteredData = this.applyActivationSearchFilter(data);
        if (this.POType.value == 'Consolidated') {
          if (filteredData.length === 0) {
            filteredData.push({
              entityId: "",
              poType: 0,
              branchName: "",
              serviceType: "",
              poNumber: 0,
              poDate: null,
              poFrom: null,
              poTo: null,
              poValue: 0,
              createdOn: null,
              updatedOn: null
            });
          }
        } else if (this.SplitType.value == 'ServiceWise') {
          if (filteredData.length === 0) {
            filteredData.push({
              entityId: "",
              poType: 0,
              branchName: "",
              serviceType: "OneTime",
              poNumber: 0,
              poDate: null,
              poFrom: null,
              poTo: null,
              poValue: 0,
              createdOn: null,
              updatedOn: null
            },
              {
                entityId: "",
                poType: 0,
                branchName: '',
                serviceType: "Recurring",
                poNumber: "",
                poDate: null,
                poFrom: null,
                poTo: null,
                poValue: 0,
                createdOn: null,
                updatedOn: null
              });
          }
        }
        this.gridData = {
          data: filteredData,
          total: filteredData.length
        };
      }
    });
  }



  onGridTextChange(event: Event, dataItem: any, type: string, rowIndex: number): void {
    const inputElement = event.target as HTMLInputElement;
    if (type == 'poNumber') {
      dataItem.poNumber = inputElement.value;
    } else if (type == 'poValue') {
      dataItem.poValue = inputElement.value;
    } else if (type == 'serviceType') {
      dataItem.serviceType = inputElement.value;
    }
    this.gridData[rowIndex] = dataItem;
  }
  private applyActivationSearchFilter(data: any[]): any[] {
    if (!this.searchQuery) {
      return data;
    }

    const search = this.searchQuery.toLowerCase();

    return data.filter(item => {
      const complianceMatch = item.shortForm.toLowerCase().includes(search);
      const branchMatch = item.avacoM_BranchName.toLowerCase().includes(search);
      const stateMatch = item.sM_Name.toLowerCase().includes(search);
      return complianceMatch || branchMatch || stateMatch;
    });
  }
  onDateChanges(event: any, dataItem: any, rowIndex: number, type: string): void {
    console.log('event', event);
    const formattedDate = this.formatDate(event);
    if (type == 'poFrom') {
      dataItem.poFrom = this.formatDate(event);
    } else if (type == 'poDate') {
      dataItem.poDate = this.formatDate(event);
    } else if (type == 'poTo') {
      dataItem.poTo = this.formatDate(event);
    }
    const selectedId = dataItem.complianceInstanceID;

    const existingInstanceIndex = this.updatedInstances.findIndex(
      instance => instance.instanceId === selectedId.toString()
    );

    if (existingInstanceIndex !== -1) {

      this.updatedInstances[existingInstanceIndex].activationDate = formattedDate;
    } else {

      this.updatedInstances.push({
        instanceId: selectedId?.toString(),
        activationDate: formattedDate,
      });
    }
    this.validateField(dataItem, type);
    this.gridData[rowIndex] = dataItem;

  }
  validateField(dataItem: any, field: string) {
    this.showValidationErrors = true;
  }
  public updateFormattedDate(): void {
    if (this.poDate) {
      this.formattedDate = this.formatDate(this.poDate);
    } else if (this.poFrom) {
      this.formattedDate = this.formatDate(this.poFrom);
    } else if (this.poTo) {
      this.formattedDate = this.formatDate(this.poTo);
    }
  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
  }
  public isCellBeingEdited(rowIndex: number, column: string): boolean {
    return this.editedRowIndex === rowIndex && this.editedColumn === column;
  }
  public editRow(index: number, column: string, dataItem: any): void {
    this.editedRowIndex = index;
    this.editedColumn = column;
  }
  public parseDate(dateString: string): Date {
    if (!dateString || typeof dateString !== 'string') {
      return new Date();
    }
    const [day, month, year] = dateString.split('-').map(part => parseInt(part, 10));
    return new Date(year, month - 1, day);
  }
  onInputChange(event: any) {
    const inputValue = event.target.value;

    if (inputValue.length > 2) {
      event.target.value = inputValue.slice(0, 2);
      this.PaymentCreditPeriod = event.target.value;
    }
  }

  public formatDate(date: Date): string {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
  public ValidateOneTime(): boolean {
    if (this.OneTimeActivity.value && this.OneTimeCommercialType.value && this.Charges.value) {
      if (this.OneTimeCommercialType.value == 'Fixed' && this.FixedAmount && this.Charges.value == 'TL Charges' && this.TLCharges) {
        return true;
      } else if (this.OneTimeCommercialType.value != 'Fixed' && this.OneTimeCommercialType.value && this.Charges.value != 'TL Charges') {
        return true;
      } else if (this.OneTimeCommercialType.value == 'Fixed' && this.FixedAmount && this.Charges.value != 'TL Charges') {
        return true;
      } else if (this.OneTimeCommercialType.value != 'Fixed' && this.FixedAmount && this.Charges.value == 'TL Charges' && this.TLCharges) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }
  public ValidateBoth(): boolean {
    if (this.BillingType.value == 'Regular') {
      if (this.ActivityType.value && this.CommercialType.value) {
        if (this.CommercialType.value == 'Fixed' && this.FixedAmount) {
          return true;
        } else if (this.CommercialType.value == 'Slab Wise billing' && this.CommercialElementType.value) {
          if (this.CommercialElementType.value == 'Branch Wise') {
            if (this.noOfBranches != 0 && this.perBranchAmount != 0 && this.consolidatedBranchValue != 0) {
              return true;
            }
          } else if (this.CommercialElementType.value == 'Head Count Wise') {
            if (this.headCount != 0 && this.perHeadCountAmount != 0 && this.consolidatedHeadCountValue != 0) {
              return true;
            }
          } else if (this.CommercialElementType.value == 'Vendor Wise') {
            if (this.noOfVendors != 0 && this.perVendorAmount != 0 && this.consolidatedVendorValue != 0) {
              return true;
            }
          }
        } else if (this.CommercialType.value == 'Variable' && this.VariableBillingType.value) {
          if (this.VariableBillingType.value == 'Head Office') {
            if (this.amountPerHeadOffice != 0 && this.totalHeadOfficeValue != 0) {
              return true;
            }
          } else if (this.VariableBillingType.value == 'Branch') {
            if (this.noOfBranchesvar != 0 && this.perBranchAmountvar != 0 && this.consolidatedBranchValuevar != 0) {
              return true;
            }
          } else if (this.VariableBillingType.value == 'Factory') {
            if (this.numberOfFactories != 0 && this.amountPerFactory != 0 && this.totalFactoryValue != 0) {
              return true;
            }
          } else {
            return false;
          }
        }
      }

    } else if (this.BillingType.value == 'Split') {
      if (this.SplitTypeCom.value == 'Activity Wise') {
        if (this.Activity && this.PONumber && this.CommercialType.value) {
          if (this.CommercialType.value == 'Fixed' && this.FixedAmount) {
            return true;
          } else if (this.CommercialType.value == 'Slab Wise billing' && this.CommercialElementType.value) {
            if (this.CommercialElementType.value == 'Branch Wise') {
              if (this.noOfBranches != 0 && this.perBranchAmount != 0 && this.consolidatedBranchValue != 0) {
                return true;
              }
            } else if (this.CommercialElementType.value == 'Head Count Wise') {
              if (this.headCount != 0 && this.perHeadCountAmount != 0 && this.consolidatedHeadCountValue != 0) {
                return true;
              }
            } else if (this.CommercialElementType.value == 'Vendor Wise') {
              if (this.noOfVendors != 0 && this.perVendorAmount != 0 && this.consolidatedVendorValue != 0) {
                return true;
              }
            }
          } else if (this.CommercialType.value == 'Variable' && this.VariableBillingType.value) {
            if (this.VariableBillingType.value == 'Head Office') {
              if (this.amountPerHeadOffice != 0 && this.totalHeadOfficeValue != 0) {
                return true;
              }
            } else if (this.VariableBillingType.value == 'Branch') {
              if (this.noOfBranchesvar != 0 && this.perBranchAmountvar != 0 && this.consolidatedBranchValuevar != 0) {
                return true;
              }
            } else if (this.VariableBillingType.value == 'Factory') {
              if (this.numberOfFactories != 0 && this.amountPerFactory != 0 && this.totalFactoryValue != 0) {
                return true;
              }
            } else {
              return false;
            }
          }
        }
      } else if (this.SplitTypeCom.value == 'Branch Wise') {
        if (this.fileNames['BranchwiseCommercialUpload'] != '') {
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  }
  public ValidBillingDetails(): boolean {
    if (this.BillingZone.value && this.BillingCycleDate && this.BillingSubmissionDate &&
      this.PaymentCreditPeriod && this.Revenue.value && this.MandateType.value && this.PO_PW.value) {
      if (this.PO_PW.value == 'PO' && this.POType.value) {
        if (this.POType.value == 'Consolidated') {
          if (this.gridData) {
            return true;
          }
        } else if (this.POType.value == 'Multiple' && this.SplitType.value) {
          if (this.SplitType.value == 'BranchWise') {
            if (this.fileNames['BranchwisePOUpload'] != '') {
              return true;
            } else {
              return false;
            }
          } else if (this.SplitType.value == 'ServiceWise' && this.gridData) {
            return true;
          }
        }
      } else if (this.PO_PW.value == 'WO' && this.WorkOrder) {
        return true;
      }
    }
    return false;
  }

  OnSave(type: string) {
    this.showValidationErrors = true;
    if (type == 'OnSaveBillingDetails') {
      const body = {
        id: 0,
        entityId: this.Entity.value || '',
        billing_zone: this.BillingZone.value || '',
        billing_cycle: this.BillingCycleDate ? this.formatDateForSave(this.BillingCycleDate.toISOString()) : '' ,
        billing_subm_date: this.BillingSubmissionDate ? this.formatDateForSave(this.BillingSubmissionDate.toISOString()) : '',
        payment_credit_period: this.PaymentCreditPeriod || '',
        revenue_gen_tab: this.Revenue.value || '',
        mandate_type: this.MandateType.value || '',
        po_wo_appl: this.PO_PW.value || '',
        work_order: this.WorkOrder || '',
        po_type: this.POType.value || '',
        billing_type: this.BillingType.value || '',
        split_type: this.SplitType.value || '',
      }
      console.log(body);
      const file = this.selectedFiles['BranchwiseCommercialUpload'];

        this.CommercialMappingService.saveBillingDetails(body, file).subscribe(
          {
            next: (response) => {
              console.log(response);
              if (response.status == 200) {
                if (this.POType.value == 'Consolidated' || (this.POType.value == 'Multiple' && this.SplitType.value == 'ServiceWise')) {
                  debugger
                
                    const modifiedGridData = this.gridData.data.map((row: any) => {
                      return {
                        ...row,
                        branchName: row.branchName ? row.branchName : '',
                        serviceType: row.serviceType ? row.serviceType : '',
                        poDate: row.poDate ? this.formatDateForSave(row.poDate) : null,
                        poFrom: row.poFrom ? this.formatDateForSave(row.poFrom) : null,
                        poTo: row.poTo ? this.formatDateForSave(row.poTo) : null,
                      };
                    });
                    console.log(modifiedGridData);
                    this.CommercialMappingService.saveBillingDetailsGridData(modifiedGridData).subscribe(
                      {
                        next: (response) => {
                          if (response.status == 200) {
                            console.log(response);
                            
                            this.alertData = { alertMessage: 'Details Saved Successfully', alertVisible: 'success' };
                            this.loadGrid(this.Entity.value);
                          } else {
                            this.alertData = { alertMessage: 'Failed To Save Details', alertVisible: 'error' };
                          }
                        }, error: (error) => {
                          console.log(error);
                          this.alertData = { alertMessage: 'Failed To Save Details', alertVisible: 'error' };
                        }
                      });
                  
                }
              } else {
                this.alertData = { alertMessage: 'Failed To Save Details', alertVisible: 'error' };
              }
            }, error: (error) => {
              console.log(error);
              this.alertData = { alertMessage: 'Failed To Save Details', alertVisible: 'error' };
            }
          });
      
   

    } else if (type == 'OnSaveCommercial') {
      this.showValidationErrorsCom = true;
      if (this.Mandate == 'One Time') {
        const CommercialBody = {
          entityId: this.Entity.value || '',
          billing_type: '',
          split_type: '',
          activity: this.OneTimeActivity.value || '',
          po_number: '',
          fixed_amount: this.FixedAmount || '',
          state: '',
          location: '',
          branch: '',
          invoice_spoc_name: '',
          invoice_spoc_emailid: '',
          invoice_contact_number: '',
          invoice_address: '',
          dispatch_address: '',
          commercial_activity_type: '',
          commercial_type: this.OneTimeCommercialType.value || '',
          commercial_element_type: '',
          price_structure: '',
          charges: this.Charges.value || '',
          tl_charges: this.TLCharges || '',
          variable_billing_types: '',
          element_count: '',
          per_element_amount: '',
          consolidated_value: '',
          mandate: this.Mandate
        };
        if (this.ValidateOneTime() == true) {
          this.CommercialMappingService.saveCommercialDetails(CommercialBody).subscribe(
            {
              next: (response) => {
                console.log(response);
                if (response.status == 200) {
                  this.alertData = { alertMessage: 'Details Saved Successfully', alertVisible: 'success' };
                  this.loadGridOneTime(this.Entity.value);
                } else {
                  this.alertData = { alertMessage: 'Failed To Save Details', alertVisible: 'error' };
                }
              }, error: (error) => {
                console.log(error);
                this.alertData = { alertMessage: 'Failed To Save Details', alertVisible: 'error' };
              }
            });
        } else {
          this.alertData = { alertMessage: 'Please Fill Required Fields', alertVisible: 'error' };
        }
      } else {
        if (this.BillingType.value == 'Split' && this.SplitTypeCom.value == 'Branch Wise') {
          const file = this.selectedFiles['BranchwiseCommercialUpload'];
          if (file instanceof File && this.Entity) {
            this.CommercialMappingService.saveCommercialSplitBranch(file, this.Entity.value).subscribe(
              {
                next: (response) => {
                  console.log(response);
                  if (response.status == 200) {
                    this.alertData = { alertMessage: 'Data Upload Successfully', alertVisible: 'success' };
                    this.loadGridSplitBranchWise(this.Entity.value);
                  } else {
                    this.alertData = { alertMessage: 'Failed To Upload Data', alertVisible: 'error' };
                  }
                }, error: (error) => {
                  console.log(error);
                  this.alertData = { alertMessage: 'Failed To Upload Data', alertVisible: 'error' };
                }
              });
          }
        } else if (this.BillingType.value == 'Split' && this.SplitTypeCom.value == 'Activity Wise') {
          const splitBody = {
            entityId: this.Entity.value || '',
            billing_type: this.BillingType.value || '',
            split_type: this.SplitTypeCom.value || '',
            activity: this.Activity.value || '',
            po_number: this.PONumber || '',
            fixed_amount: this.FixedAmount || '',
            state: '',
            location: '',
            branch: '',
            invoice_spoc_name: '',
            invoice_spoc_emailid: '',
            invoice_contact_number: '',
            invoice_address: '',
            dispatch_address: '',
            commercial_activity_type: '',
            commercial_type: this.CommercialType.value || '',
            commercial_element_type: this.CommercialElementType.value || '',
            price_structure: '',
            charges: '',
            tl_charges: '',
            variable_billing_types: this.VariableBillingType.value || '',
            element_count: this.element_count || '',
            per_element_amount: this.per_element_amount || '',
            consolidated_value: this.consolidated_value || '',
            mandate: this.Mandate
          };
          if (this.ValidateBoth() == true) {
            this.CommercialMappingService.saveCommercialDetails(splitBody).subscribe(
              {
                next: (response) => {
                  console.log(response);
                  if (response.status == 200) {
                    this.alertData = { alertMessage: 'Details Saved Successfully', alertVisible: 'success' };
                    this.loadGridSplitActivityWise(this.Entity.value);
                  } else {
                    this.alertData = { alertMessage: 'Failed To Save Details', alertVisible: 'error' };
                  }
                }, error: (error) => {
                  console.log(error);
                  this.alertData = { alertMessage: 'Failed To Save Details', alertVisible: 'error' };
                }
              });
          }
        } else {
          const RegularBody = {
            entityId: this.Entity.value || '',
            billing_type: this.BillingType.value || '',
            split_type: this.SplitType.value || '',
            activity: this.ActivityType.value || '',
            po_number: '',
            fixed_amount: this.FixedAmount || '',
            state: '',
            location: '',
            branch: '',
            invoice_spoc_name: '',
            invoice_spoc_emailid: '',
            invoice_contact_number: '',
            invoice_address: '',
            dispatch_address: '',
            commercial_activity_type: '',
            commercial_type: this.CommercialType.value || '',
            commercial_element_type: this.CommercialElementType.value || '',
            price_structure: '',
            charges: '',
            tl_charges: '',
            variable_billing_types: this.VariableBillingType.value || '',
            element_count: this.element_count || '',
            per_element_amount: this.per_element_amount || '',
            consolidated_value: this.consolidated_value || '',
            mandate: this.Mandate
          };
          if (this.ValidateBoth() == true) {
            this.CommercialMappingService.saveCommercialDetails(RegularBody).subscribe(
              {
                next: (response) => {
                  console.log(response);
                  if (response.status == 200) {
                    this.alertData = { alertMessage: 'Details Saved Successfully', alertVisible: 'success' };
                  } else {
                    this.alertData = { alertMessage: 'Failed To Save Details', alertVisible: 'error' };
                  }
                }, error: (error) => {
                  console.log(error);
                  this.alertData = { alertMessage: 'Failed To Save Details', alertVisible: 'error' };
                }
              });

          }

        }
      }
    } else if (type == 'onSaveBillingContacts') {
      if (this.form.valid) {
        this.CommercialMappingService.saveBillingContacts(this.Entity.value, this.form.value).subscribe(
          {
            next: (response) => {
              if (response.status == 200) {
                this.alertData = { alertMessage: 'Details Saved Successfully', alertVisible: 'success' };
              } else {
                this.alertData = { alertMessage: 'Failed To Save Details', alertVisible: 'error' };
              }
            }, error: (error) => {
              this.alertData = { alertMessage: 'Failed To Save Details', alertVisible: 'error' };
            }
          }
        );
      } else {
        this.form.markAllAsTouched();
      }
    }

  }
  formatDateForSave(dateString: string | null): string | null {
    if (!dateString || dateString.trim() === '') {
      return null;
    }

    const dateParts = dateString.split('/');

    if (dateParts.length === 3 && dateParts.every(part => !isNaN(Number(part)))) {
      const day = dateParts[0];
      const month = dateParts[1];
      const year = dateParts[2];

      const date = new Date(`${year}-${month}-${day}T00:00:00`);

      if (!isNaN(date.getTime())) {
        return this.convertToISOFormat(date);
      } else {
        return null;
      }
    } else {
      const date = new Date(dateString);

      if (!isNaN(date.getTime())) {
        return this.convertToISOFormat(date);
      } else {
        return null;
      }
    }
  }

  convertToISOFormat(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);         
    const hours = ('0' + date.getHours()).slice(-2);      
    const minutes = ('0' + date.getMinutes()).slice(-2);    
    const seconds = ('0' + date.getSeconds()).slice(-2);    

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }
  closeAlert() {
    this.alertData = { alertMessage: '', alertVisible: '' };
  }
}
