<div class="row colpadding0">
    <div class="col-md-12 colpadding0">
        <header class="panel-heading tab-bg-primary" style="background: none !important; margin-top: 15px;">
            <ul class="nav nav-tabs">
                <li>
                    <h4><input id="rdGen" value="G" type="radio" class="custom-radio" name="measurementSystem"
                            (change)="onRadioChange('G')" [checked]="uploadType === 'G'" /><span class="f-label">New Generation</span></h4>
                </li>
                <li>
                    <h4><input id="rdUpd" value="U" type="radio" class="custom-radio" name="measurementSystem"
                            (change)="onRadioChange('U')" [checked]="uploadType === 'U'" /><span class="f-label">Updation</span></h4>
                </li>
                <li>
                    <h4><input id="rdDow" value="D" type="radio" class="custom-radio" name="measurementSystem"
                            (change)="onRadioChange('D')" [checked]="uploadType === 'D'" /><span class="f-label">Downloads</span></h4>
                </li>
                <li>
                    <h4><input id="rdFai" value="F" type="radio" class="custom-radio" name="measurementSystem"
                            (change)="onRadioChange('F')" [checked]="uploadType === 'F'" /><span class="f-label">Failed</span></h4>
                </li>
            </ul>
        </header>
    </div>
</div>

<!-- <div id="divDownloads" class="align" *ngIf="uploadType === 'D'">
    <div class="row colpadding0 new-gen-round ">
        <div class="col-12 d-flex " style="padding: 20px; margin-top: 9px;">
            <div class="col-md-5">
                <div class="col-md-3 colpadding0 d-flex ">
                    <span class="f-label">Downloads</span>
                </div>
                <div class="col-md-5">
                    <img src="../assets/vectors/Excel.svg" alt="" class="excelSample">
                    <a href="../assets/BulkUploadDocuments/ESIC_Downlods.xlsx" download="ESIC_Downlods.xlsx">Sample
                        Document</a>
                </div>
            </div>
            <div class="col-md-7" style="justify-content: end; margin-top: -5px;">
                <div class="col-md-1" style="float: right;">
                    <button class="figma-icon-white" (click)="removeFile('DownloadsInput')" aria-label="Delete">
                        <img src="../assets/vectors/Trash.svg" alt="" title="Delete">
                    </button>
                </div>
                <div class="col-md-2 " style="float: right; margin-right: -10px;">
                    <button class="figma-btn-blue" style="color: white;" (click)="uploadFile()">Upload</button>
                </div>
                <div class="col-md-6" style="float: right;">
                    <div class="col-md-2" style="float: right; margin-right: 20px;">
                        <input type="file" formControlName="DownloadsInput" #DownloadsInputFile
                            (change)="onFileChange($event, 'DownloadsInput')" id="fileInput" style="display: none;" />
                        <button class="figma-btn-gray" (click)="DownloadsInputFile.click()">Browse</button>
                    </div>
                    <div *ngIf="!fileNames['DownloadsInput']" class=" col-md-6"
                        style="padding: 7px; float: right; margin-right: -40px;  ">
                        <span style="color: #A0A0A0;">No file chosen</span>
                    </div>
                    <div *ngIf="fileNames['DownloadsInput']" class="file-display col-md-6"
                        style="padding: 7px; float: right;">
                        <span style="color: #A0A0A0;">{{fileNames['DownloadsInput']}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div id="divDownloads" class="align" *ngIf="uploadType === 'D'">
    <div class="row colpadding0 new-gen-round">
        <div class="col-12 d-flex justify-content-between flex-wrap align-items-center p-3 ">
            <div class="col-md-5 col-12 mb-2 mb-md-0 mobile-responsive">
                <div class="col-md-4 d-flex left">
                    <span class="f-label">Downloads</span>
                </div>
                <div class="col-md-8 d-flex align-items-center">
                    <img src="../assets/vectors/Excel.svg" alt="" class="excelSample mr-2">
                    <a href="../assets/BulkUploadDocuments/ESIC_Downlods.xlsx" download="ESIC_Downlods.xlsx">Sample Document</a>
                </div>
            </div>
            <div class="col-md-6 col-12 mobile-responsive1 mt-3 mt-md-0 d-flex align-items-center">
                <div class="d-flex flex-grow-1 p-left">
                    <div *ngIf="!fileNames['DownloadsInput']" class="file-display d-flex align-items-center">
                        <span style="color: #A0A0A0;">No file chosen</span>
                    </div>
                    <div *ngIf="fileNames['DownloadsInput']" class="file-display d-flex align-items-center">
                        <span style="color: #A0A0A0;">{{fileNames['DownloadsInput']}}</span>
                    </div>
                    <input type="file" formControlName="DownloadsInput" #DownloadsInputFile
                        (change)="onFileChange($event, 'DownloadsInput')" id="fileInput" style="display: none;" />
                    <button class="figma-btn-gray mr-2" style="height: 27px;" (click)="DownloadsInputFile.click()">Browse</button>
                    <button class="figma-btn-blue mr-2" (click)="uploadFile()">Upload</button>
                </div>
                <button class="figma-icon-white  mr-2" (click)="removeFile('DownloadsInput')" aria-label="Delete">
                    <img src="../assets/vectors/Trash.svg" class="" alt="" title="Delete">
                </button>
            </div>
        </div>
    </div>
</div>


<!-- <div id="divGeneration" class="align" *ngIf="uploadType === 'G'">
    <div class="row colpadding0 new-gen-top ">
        <div class="col-12 d-flex " style="padding: 20px; margin-top: 9px;">
            <div class="col-md-5">
                <div class="col-md-6 colpadding0 d-flex ">
                    <span class="f-label">Employee</span>
                </div>
                <div class="col-md-6">
                    <img src="../assets/vectors/Excel.svg" alt="" class="excelSample">
                    <a href="../assets/BulkUploadDocuments/ESIC_GenerationEmployeeUpload.xlsx"
                        download="ESIC_GenerationEmployeeUpload.xlsx">Sample Document</a>
                </div>
            </div>
            <div class="col-md-7" style="justify-content: end; margin-top: -5px;">
                <div class="col-md-1" style="float: right;">
                    <button class="figma-icon-white" (click)="removeFile('EmployeeInput')" aria-label="Delete">
                        <img src="../assets/vectors/Trash.svg" alt="" title="Delete">
                    </button>
                </div>
                <div class="col-md-2 " style="float: right; margin-right: -10px;">
                    <button class="figma-btn-blue" style="color: white;" (click)="uploadFile()">Upload</button>
                </div>
                <div class="col-md-6" style="float: right;">
                    <div class="col-md-2" style="float: right; margin-right: 20px;">
                        <input type="file" formControlName="EmployeeInput" #EmployeeInputFile
                            (change)="onFileChange($event, 'EmployeeInput')" id="fileInput" style="display: none;" />
                        <button class="figma-btn-gray" (click)="EmployeeInputFile.click()">Browse</button>
                    </div>
                    <div *ngIf="!fileNames['EmployeeInput']" class=" col-md-6"
                        style="padding: 7px; float: right; margin-right: -40px;  ">
                        <span style="color: #A0A0A0;">No file chosen</span>
                    </div>
                    <div *ngIf="fileNames['EmployeeInput']" class="file-display col-md-6"
                        style="padding: 7px; float: right;">
                        <span style="color: #A0A0A0;">{{fileNames['EmployeeInput']}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row colpadding0 new-gen-bottom ">
        <div class="col-12 d-flex " style="padding: 20px; margin-top: 9px;">
            <div class="col-md-5">
                <div class="col-md-3 colpadding0 d-flex ">
                    <span class="f-label">Family Details</span>
                </div>
                <div class="col-md-5">
                    <img src="../assets/vectors/Excel.svg" alt="" class="excelSample">
                    <a href="../assets/BulkUploadDocuments/ESIC_GenerationFamilyWelfareUpload.xlsx"
                        download="ESIC_GenerationFamilyDetails.xlsx">Sample Document</a>
                </div>
            </div>
            <div class="col-md-7" style="justify-content: end; margin-top: -5px;">
                <div class="col-md-1" style="float: right;">
                    <button class="figma-icon-white" (click)="removeFile('FamilyWelfareInput')" aria-label="Delete">
                        <img src="../assets/vectors/Trash.svg" alt="" title="Delete">
                    </button>
                </div>
                <div class="col-md-2 " style="float: right; margin-right: -10px;">
                    <button class="figma-btn-blue" style="color: white;" (click)="uploadFile()">Upload</button>
                </div>
                <div class="col-md-6" style="float: right;">
                    <div class="col-md-2" style="float: right; margin-right: 20px;">
                        <input type="file" formControlName="FamilyWelfareInput" #FamilyWelfareInputFile
                            (change)="onFileChange($event, 'FamilyWelfareInput')" id="fileInput"
                            style="display: none;" />
                        <button class="figma-btn-gray" (click)="FamilyWelfareInputFile.click()">Browse</button>
                    </div>
                    <div *ngIf="!fileNames['FamilyWelfareInput']" class=" col-md-6"
                        style="padding: 7px; float: right; margin-right: -40px;  ">
                        <span style="color: #A0A0A0;">No file chosen</span>
                    </div>
                    <div *ngIf="fileNames['FamilyWelfareInput']" class="file-display col-md-6"
                        style="padding: 7px; float: right;">
                        <span style="color: #A0A0A0;">{{fileNames['FamilyWelfareInput']}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div id="divGeneration" class="align" *ngIf="uploadType === 'G'">
    <div class="row colpadding0 new-gen-top">
        <div class="col-12 d-flex justify-content-between flex-wrap align-items-center p-3 ">
            <div class="col-md-5 col-12 mb-2 mb-md-0 mobile-responsive">
                <div class="col-md-4 d-flex left">
                    <span class="f-label">Employee</span>
                </div>
                <div class="col-md-8 d-flex align-items-center">
                    <img src="../assets/vectors/Excel.svg" alt="" class="excelSample mr-2">
                    <a href="../assets/BulkUploadDocuments/ESIC_GenerationEmployeeUpload.xlsx"
                        download="ESIC_GenerationEmployeeUpload.xlsx">Sample Document</a>
                </div>
            </div>
            <div class="col-md-6 col-12 mobile-responsive1 mt-3 mt-md-0 ">
               
                <div class="d-flex flex-grow-1 p-left">
                    <div *ngIf="!fileNames['EmployeeInput']" class="file-display d-flex align-items-center">
                        <span style="color: #A0A0A0;">No file chosen</span>
                    </div>
                    <div *ngIf="fileNames['EmployeeInput']" class="file-display d-flex align-items-center">
                        <span style="color: #A0A0A0;">{{fileNames['EmployeeInput']}}</span>
                    </div>
                    <input type="file" formControlName="EmployeeInput" #EmployeeInputFile
                        (change)="onFileChange($event, 'EmployeeInput')" id="fileInput" style="display: none;" />
                    <button class="figma-btn-gray mr-2" style="height: 27px;" (click)="EmployeeInputFile.click()">Browse</button>
                    <button class="figma-btn-blue mr-2" (click)="uploadFile()">Upload</button>
                </div>
                <button class="figma-icon-white mr-2" (click)="removeFile('EmployeeInput')" aria-label="Delete">
                    <img src="../assets/vectors/Trash.svg" alt="" title="Delete">
                </button>
                
            </div>
        </div>
    </div>

    <div class="row colpadding0 new-gen-bottom">
        <div class="col-12 d-flex justify-content-between flex-wrap align-items-center p-3">
            <div class="col-md-5 col-12 mb-2 mb-md-0 mobile-responsive">
                <div class="col-md-4 d-flex left">
                    <span class="f-label">Family Details</span>
                </div>
                <div class="col-md-8  d-flex align-items-center">
                    <img src="../assets/vectors/Excel.svg" alt="" class="excelSample mr-2">
                    <a href="../assets/BulkUploadDocuments/ESIC_GenerationFamilyWelfareUpload.xlsx"
                        download="ESIC_GenerationFamilyDetails.xlsx">Sample Document</a>
                </div>
            </div>
            <div class="col-md-6 col-12 mobile-responsive1 mt-3 mt-md-0 ">
                <div class="d-flex flex-grow-1 p-left">
                    <div *ngIf="!fileNames['FamilyWelfareInput']" class="file-display d-flex align-items-center">
                        <span style="color: #A0A0A0;">No file chosen</span>
                    </div>
                    <div *ngIf="fileNames['FamilyWelfareInput']" class="file-display d-flex align-items-center">
                        <span style="color: #A0A0A0;">{{fileNames['FamilyWelfareInput']}}</span>
                    </div>
                    <input type="file" formControlName="FamilyWelfareInput" #FamilyWelfareInputFile
                        (change)="onFileChange($event, 'FamilyWelfareInput')" id="fileInput" style="display: none;" />
                    <button class="figma-btn-gray mr-2" style="height: 27px;" (click)="FamilyWelfareInputFile.click()">Browse</button>
                    <button class="figma-btn-blue mr-2" (click)="uploadFile()">Upload</button>
                </div>
                <button class="figma-icon-white mr-2" (click)="removeFile('FamilyWelfareInput')" aria-label="Delete">
                    <img src="../assets/vectors/Trash.svg" alt="" title="Delete">
                </button>
            </div>
        </div>
    </div>
    
</div>



<!-- <div id="divUpdate" class="align" *ngIf="uploadType === 'U'">
    <div class="row colpadding0 new-gen-top ">
        <div class="col-12 d-flex " style="padding: 20px; margin-top: 9px;">
            <div class="col-md-5">
                <div class="col-md-3 colpadding0 d-flex ">
                    <span class="f-label">Employee</span>
                </div>
                <div class="col-md-5">
                    <img src="../assets/vectors/Excel.svg" alt="" class="excelSample">
                    <a href="../assets/BulkUploadDocuments/ESIC_UpdationEmployeeUpload.xlsx"
                        download="ESIC_UpdateEmployeeUpload.xlsx">Sample Document</a>
                </div>
            </div>
            <div class="col-md-7" style="justify-content: end; margin-top: -5px;">
                <div class="col-md-1" style="float: right;">
                    <button class="figma-icon-white" (click)="removeFile('UpdateInputEmp')" aria-label="Delete">
                        <img src="../assets/vectors/Trash.svg" alt="" title="Delete">
                    </button>
                </div>
                <div class="col-md-2 " style="float: right; margin-right: -10px;">
                    <button class="figma-btn-blue" style="color: white;" (click)="uploadFile()">Upload</button>
                </div>
                <div class="col-md-6" style="float: right;">
                    <div class="col-md-2" style="float: right; margin-right: 20px;">
                        <input type="file" formControlName="UpdateInputEmp" #UpdateInputEmpFile
                            (change)="onFileChange($event, 'UpdateInputEmp')" id="fileInput" style="display: none;" />
                        <button class="figma-btn-gray" (click)="UpdateInputEmpFile.click()">Browse</button>
                    </div>
                    <div *ngIf="!fileNames['UpdateInputEmp']" class=" col-md-6"
                        style="padding: 7px; float: right; margin-right: -40px;  ">
                        <span style="color: #A0A0A0;">No file chosen</span>
                    </div>
                    <div *ngIf="fileNames['UpdateInputEmp']" class="file-display col-md-6"
                        style="padding: 7px; float: right;">
                        <span style="color: #A0A0A0;">{{fileNames['UpdateInputEmp']}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row colpadding0 new-gen-bottom ">
        <div class="col-12 d-flex " style="padding: 20px; margin-top: 9px;">
            <div class="col-md-5">
                <div class="col-md-3 colpadding0 d-flex ">
                    <span class="f-label">Family Details</span>
                </div>
                <div class="col-md-5">
                    <img src="../assets/vectors/Excel.svg" alt="" class="excelSample">
                    <a href="../assets/BulkUploadDocuments/ESIC_UpdationFamilyWelfare.xlsx"
                        download="ESIC_UpdateFamilyDetails.xlsx">Sample Document</a>
                </div>
            </div>
            <div class="col-md-7" style="justify-content: end; margin-top: -5px;">
                <div class="col-md-1" style="float: right;">
                    <button class="figma-icon-white" (click)="removeFile('UpdateInputFamily')" aria-label="Delete">
                        <img src="../assets/vectors/Trash.svg" alt="" title="Delete">
                    </button>
                </div>
                <div class="col-md-2 " style="float: right; margin-right: -10px;">
                    <button class="figma-btn-blue" style="color: white;" (click)="uploadFile()">Upload</button>
                </div>
                <div class="col-md-6" style="float: right;">
                    <div class="col-md-2" style="float: right; margin-right: 20px;">
                        <input type="file" formControlName="UpdateInputFamily" #UpdateInputFamilyFile
                            (change)="onFileChange($event, 'UpdateInputFamily')" id="fileInput"
                            style="display: none;" />
                        <button class="figma-btn-gray" (click)="UpdateInputFamilyFile.click()">Browse</button>
                    </div>
                    <div *ngIf="!fileNames['UpdateInputFamily']" class=" col-md-6"
                        style="padding: 7px; float: right; margin-right: -40px;  ">
                        <span style="color: #A0A0A0;">No file chosen</span>
                    </div>
                    <div *ngIf="fileNames['UpdateInputFamily']" class="file-display col-md-6"
                        style="padding: 7px; float: right;">
                        <span style="color: #A0A0A0;">{{fileNames['UpdateInputFamily']}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div id="divUpdate" class="align" *ngIf="uploadType === 'U'">
    <div class="row colpadding0 new-gen-top ">
        <div class="col-12 d-flex justify-content-between flex-wrap align-items-center p-3 ">
            <div class="col-md-5 col-12 mb-2 mb-md-0 mobile-responsive">
                <div class="col-md-4 d-flex left">
                    <span class="f-label">Employee</span>
                </div>
                <div class="col-md-8 d-flex align-items-center">
                    <img src="../assets/vectors/Excel.svg" alt="" class="excelSample mr-2">
                    <a href="../assets/BulkUploadDocuments/ESIC_UpdationEmployeeUpload.xlsx"
                        download="ESIC_UpdateEmployeeUpload.xlsx">Sample Document</a>
                </div>
            </div>
            <div class="col-md-6 col-12 mobile-responsive1 mt-3 mt-md-0">
                <div class="d-flex flex-grow-1 p-left">
                    <div *ngIf="!fileNames['UpdateInputEmp']" class="file-display d-flex align-items-center">
                        <span style="color: #A0A0A0;">No file chosen</span>
                    </div>
                    <div *ngIf="fileNames['UpdateInputEmp']" class="file-display d-flex align-items-center">
                        <span style="color: #A0A0A0;">{{fileNames['UpdateInputEmp']}}</span>
                    </div>
                    <input type="file" formControlName="UpdateInputEmp" #UpdateInputEmpFile
                        (change)="onFileChange($event, 'UpdateInputEmp')" id="fileInput" style="display: none;" />
                    <button class="figma-btn-gray mr-2" style="height: 27px;" (click)="UpdateInputEmpFile.click()">Browse</button>
                    <button class="figma-btn-blue mr-2" (click)="uploadFile()">Upload</button>
                </div>
                <button class="figma-icon-white mr-2" (click)="removeFile('UpdateInputEmp')" aria-label="Delete">
                    <img src="../assets/vectors/Trash.svg" alt="" title="Delete">
                </button>
            </div>
        </div>
    </div>
    <div class="row colpadding0 new-gen-bottom ">
        <div class="col-12 d-flex justify-content-between flex-wrap align-items-center p-3 ">
            <div class="col-md-5 col-12 mb-2 mb-md-0 mobile-responsive">
                <div class="col-md-4 d-flex left">
                    <span class="f-label">Family Details</span>
                </div>
                <div class="col-md-8 d-flex align-items-center">
                    <img src="../assets/vectors/Excel.svg" alt="" class="excelSample mr-2">
                    <a href="../assets/BulkUploadDocuments/ESIC_UpdationFamilyWelfare.xlsx"
                        download="ESIC_UpdateFamilyDetails.xlsx">Sample Document</a>
                </div>
            </div>
            <div class="col-md-6 col-12 mobile-responsive1 mt-3 mt-md-0">
                <div class="d-flex flex-grow-1 p-left">
                    <div *ngIf="!fileNames['UpdateInputFamily']" class="file-display d-flex align-items-center">
                        <span style="color: #A0A0A0;">No file chosen</span>
                    </div>
                    <div *ngIf="fileNames['UpdateInputFamily']" class="file-display d-flex align-items-center">
                        <span style="color: #A0A0A0;">{{fileNames['UpdateInputFamily']}}</span>
                    </div>
                    <input type="file" formControlName="UpdateInputFamily" #UpdateInputFamilyFile
                        (change)="onFileChange($event, 'UpdateInputFamily')" id="fileInput" style="display: none;" />
                    <button class="figma-btn-gray mr-2" style="height: 27px;" (click)="UpdateInputFamilyFile.click()">Browse</button>
                    <button class="figma-btn-blue mr-2" (click)="uploadFile()">Upload</button>
                </div>
                <button class="figma-icon-white mr-2" (click)="removeFile('UpdateInputFamily')" aria-label="Delete">
                    <img src="../assets/vectors/Trash.svg" alt="" title="Delete">
                </button>
            </div>
        </div>
    </div>
</div>

<!-- <div id="divFailed" class="align" *ngIf="uploadType === 'F'">
    <div class="row colpadding0 new-gen-top ">
        <div class="col-12 d-flex " style="padding: 20px; margin-top: 9px;">
            <div class="col-md-5">
                <div class="col-md-3 colpadding0 d-flex ">
                    <span class="f-label">Failed</span>
                </div>
                <div class="col-md-5">
                    <img src="../assets/vectors/Excel.svg" alt="" class="excelSample">
                    <a href="../assets/BulkUploadDocuments/ESIC_Failed_CasesUpload.xlsx"
                        download="ESIC_FailedUpload.xlsx">Sample Document</a>
                </div>
            </div>
            <div class="col-md-7" style="justify-content: end; margin-top: -5px;">
                <div class="col-md-1" style="float: right;">
                    <button class="figma-icon-white" (click)="removeFile('FailedInput')" aria-label="Delete">
                        <img src="../assets/vectors/Trash.svg" alt="" title="Delete">
                    </button>
                </div>
                <div class="col-md-2 " style="float: right; margin-right: -10px;">
                    <button class="figma-btn-blue" style="color: white;" (click)="uploadFile()">Upload</button>
                </div>
                <div class="col-md-6" style="float: right;">
                    <div class="col-md-2" style="float: right; margin-right: 20px;">
                        <input type="file" formControlName="FailedInput" #FailedInputFile
                            (change)="onFileChange($event, 'FailedInput')" id="fileInput" style="display: none;" accept=".xlsx" />
                        <button class="figma-btn-gray" (click)="FailedInputFile.click()">Browse</button>
                    </div>
                    <div *ngIf="!fileNames['FailedInput']" class=" col-md-6"
                        style="padding: 7px; float: right; margin-right: -40px;  ">
                        <span style="color: #A0A0A0;">No file chosen</span>
                    </div>
                    <div *ngIf="fileNames['FailedInput']" class="file-display col-md-6"
                        style="padding: 7px; float: right;">
                        <span style="color: #A0A0A0;">{{fileNames['FailedInput']}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<div id="divFailed" class="align" *ngIf="uploadType === 'F'">
    <div class="row colpadding0 new-gen-top">
        <div class="col-12 d-flex justify-content-between flex-wrap align-items-center p-3">
            <div class="col-md-5 col-12 mb-2 mb-md-0 mobile-responsive">
                <div class="col-md-4 d-flex left">
                    <span class="f-label">Failed</span>
                </div>
                <div class="col-md-8 d-flex align-items-center">
                    <img src="../assets/vectors/Excel.svg" alt="" class="excelSample mr-2">
                    <a href="../assets/BulkUploadDocuments/ESIC_Failed_CasesUpload.xlsx"
                        download="ESIC_FailedUpload.xlsx">Sample Document</a>
                </div>
            </div>
            <div class="col-md-6 col-12 mobile-responsive1 mt-3 mt-md-0">
                <div class="d-flex flex-grow-1 p-left">
                    <div *ngIf="!fileNames['FailedInput']" class="file-display d-flex align-items-center">
                        <span style="color: #A0A0A0;">No file chosen</span>
                    </div>
                    <div *ngIf="fileNames['FailedInput']" class="file-display d-flex align-items-center">
                        <span style="color: #A0A0A0;">{{fileNames['FailedInput']}}</span>
                    </div>
                    <input type="file" formControlName="FailedInput" #FailedInputFile
                        (change)="onFileChange($event, 'FailedInput')" id="fileInput" style="display: none;" accept=".xlsx" />
                    <button class="figma-btn-gray mr-2" style="height: 27px;" (click)="FailedInputFile.click()">Browse</button>
                    <button class="figma-btn-blue mr-2" (click)="uploadFile()">Upload</button>
                </div>
                <button class="figma-icon-white mr-2" (click)="removeFile('FailedInput')" aria-label="Delete">
                    <img src="../assets/vectors/Trash.svg" alt="" title="Delete">
                </button>
            </div>
        </div>
    </div>
</div>





<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
  </div>
<app-alert-popup *ngIf="alertData.alertVisible" [Width]="250" [Height]="265" [Data]="alertData" (close)="closeAlert()"></app-alert-popup>