import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { GridModule } from '@progress/kendo-angular-grid';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { EmployeeCTCService } from '../../../service/employee-ctc.service';

@Component({
  selector: 'app-employee-ctchistory',
  standalone: true,
  imports: [GridModule, DialogModule, GridActionItemComponent],
  templateUrl: './employee-ctchistory.component.html',
  styleUrl: './employee-ctchistory.component.css'
})
export class EmployeeCTCHistoryComponent implements OnInit {
  public gridData: any = { data: [], total: 0 };
  @Output() close = new EventEmitter<void>();
  constructor(private EmployeeCTCService: EmployeeCTCService) { }
  ngOnInit(): void {

  }
  loadgridData(){
    
  }

  handleButtonClick(value: any) {

  }


  uploadFiles() {

  }
  closePopup() {
    this.close.emit();
  }
  public parentColumns: any[] = [
    { field: 'filename', title: 'File Name' },
    { field: 'created_at', title: 'Upload On' },
    { field: 'status', title: 'Status' },
    {
      field: 'csvUploadDoc',
      isIconColumn: true,
      title: 'Action',
      filteredIcons: [

        {
          url: '../assets/vectors/delete.svg',
          alt: 'Download Icon',
          action: 'Download'
        }
      ]
    }
  ];
}
