<h4 class="Risk-head">Risk Summary</h4>
<div style="display: flex;
    justify-content: center;
    align-items: center;">
  <div>
  <kendo-chart style="height: 280px; width: 250px;"  >
      <kendo-chart-legend [visible]="false"></kendo-chart-legend>
      <kendo-chart-area background="none"></kendo-chart-area>
      <ng-template kendoChartDonutCenterTemplate>
          <b style="font-size: 14px;">Totals</b>
          <h3 style="margin: 0; font-size: 14px;">{{chartData?.totalPieCompletedHIGH + chartData?.totalPieNotCompletedHIGH + chartData?.totalPieNotApplicableHIGH + chartData?.totalPieCompletedMEDIUM 
            + chartData?.totalPieNotCompletedMEDIUM + chartData?.totalPieNotApplicableMEDIUM 
            + chartData?.totalPieCompletedLOW + chartData?.totalPieNotCompletedLOW + chartData?.totalPieNotApplicableLOW}}</h3>
      </ng-template>
      <kendo-chart-tooltip>
        <ng-template
          kendoChartSeriesTooltipTemplate
          let-value="value"
          let-category="category"
          let-series="series"
        >
          {{ category }} ({{ series.name }}): {{ value }}
        </ng-template>
      </kendo-chart-tooltip>
      <kendo-chart-series>
        <kendo-chart-series-item
          *ngFor="let series of model; let outermost = last"
          type="donut"
          [startAngle]="90"
          [name]="series.name"
          [data]="series.data"
          field="value"
          categoryField="category"
          colorField="color"
          [holeSize]="30"
          [margin]="10"
        >
          <kendo-chart-series-item-labels
            background="none"
            [content]="labelContent"
            color="#ffff"
          >
          </kendo-chart-series-item-labels>
        </kendo-chart-series-item>
      </kendo-chart-series>

      <kendo-chart-legend [visible]="false" position="bottom">
        <kendo-chart-legend-item  cursor="pointer"></kendo-chart-legend-item>
    </kendo-chart-legend>
    </kendo-chart>
  </div>

  <div>
    <div class="status-card complied">
      <div class="status-color"></div>
      <div class="status-text">
          <span>Hign</span>
          <h2>{{chartData?.totalPieCompletedHIGH + chartData?.totalPieNotCompletedHIGH + chartData?.totalPieNotApplicableHIGH}}</h2>
      </div>
  </div>
  
  <div class="status-card not-complied">
      <div class="status-color"></div>
      <div class="status-text">
          <span>Medium</span>
          <h2>{{chartData?.totalPieCompletedMEDIUM + chartData?.totalPieNotCompletedMEDIUM + chartData?.totalPieNotApplicableMEDIUM}}</h2>
      </div>
  </div>
  
  <div class="status-card not-applicable">
      <div class="status-color"></div>
      <div class="status-text">
          <span>Low</span>
          <h2>{{chartData?.totalPieCompletedLOW + chartData?.totalPieNotCompletedLOW + chartData?.totalPieNotApplicableLOW}}</h2>
      </div>
  </div>
  </div>
</div>

<div class="status">
  <div class="status-item">
      <div class="circle si-compiled"></div>
      <span>Compiled</span>
  </div>
  <div class="status-item">
      <div class="circle si-not-compiled"></div>
      <span>Not Compiled</span>
  </div>
  <div class="status-item">
      <div class="circle si-not-applicable"></div>
      <span>Not Applicable</span>
  </div>
</div>