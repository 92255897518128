import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GridDataResult, GridModule } from '@progress/kendo-angular-grid';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { MinimumWagePeriodService } from '../../../service/minimum-wage-period.service';
import { PageChangeEvent } from '@progress/kendo-angular-treelist';
import { NgIf } from '@angular/common';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-minimum-wages-period',
  standalone: true,
  imports: [GridModule, DropDownListModule, IndicatorsModule, IndicatorsModule, InputsModule, NgIf, AlertPopupComponent, DropdownListComponent],
  templateUrl: './minimum-wages-period.component.html',
  styleUrls: ['./minimum-wages-period.component.css']
})
export class MinimumWagesPeriodComponent implements OnInit {
  loading = false;
  public skip = 0;
  public pageSize = 10;
  public showGrid = true;
  public buttonCount = 5;
  public editedColumn: string | null = null;
  @Output() close = new EventEmitter<void>();
  public editedRowIndex: number | null = null;
  public sizes = [{ text: 'All', value: 1 }, 5, 10, 20];
  alertData = { alertMessage: '', alertVisible: '' };
  public gridData: GridDataResult = { data: [], total: 0 };
  public frequencyOptions: string[] = ['Annual', 'Half-Yearly', 'Ad-hoc Basic', 'Monthly'];

  constructor(private minimumWagePeriodService: MinimumWagePeriodService, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.loadGrid();
  }

  loadGrid() {
    this.loading = true;
    setTimeout(() => {
      this.minimumWagePeriodService.getMinimumWagePeriod().subscribe({
        next: (response) => {
          if (response.status === 200) {
            const data = response.body;
            this.gridData = {
              data: data.slice(this.skip, this.skip + this.pageSize),
              total: data.length
            };
          } else {
            console.error('Error fetching entity data');
          }
          this.loading = false;
        },
        error: (error) => {
          console.error('Error fetching entity data', error);
          this.loading = false;
        }
      });
    }, 2000);
  }

  loadAllItems(): void {
    this.gridData = this.gridData;
  }

  pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    if (event.take == 1) {
      this.pageSize = this.gridData.total;
    } else {
      this.pageSize = event.take;
    }
    this.loadGrid();
  }

  onFrequencyChange(value: string, dataItem: any): void {
    dataItem.frequency = value;
  }

  onPeriodChange(event: Event, dataItem: any): void {
    const inputElement = event.target as HTMLInputElement;
    dataItem.period = inputElement.value;
  }

  onSave(dataItem: any): void {
    this.minimumWagePeriodService.updateMinimumWagePeriod(dataItem.id, dataItem.state, dataItem.frequency, dataItem.period).subscribe({
      next: (response) => {
        if (response.status === 200) {
          this.alertData = { alertMessage: 'Data Updated Successfully', alertVisible: 'success' };
          this.cd.detectChanges();
          this.loadGrid();
        } else {
          this.alertData = { alertMessage: 'Failed to Update', alertVisible: 'error' };
        }
      },
      error: (error) => {
        this.alertData = { alertMessage: 'Error while saving data', alertVisible: 'error' };
        this.cd.detectChanges();
        console.log('error', error);
      }
    });
  }

  public closePopup(): void {
    this.alertData = { alertMessage: '', alertVisible: '' };
    this.close.emit();
  }

  public editRow(index: number, column: string): void {
    this.editedRowIndex = index;
    this.editedColumn = column;
  }

  public isCellBeingEdited(index: number, column: string): boolean {
    return this.editedRowIndex === index && this.editedColumn === column;
  }
}
