<kendo-dialog #dialogRef title="Upload Industry-Skills-Designation" (close)="closePopup()" [width]="580" 
  class="custom-dialog">
  <div class="row dialog-content">
    <div class="col-md-12 inline-flex-container ">
        <label class="f-label">
          State<sub style="color: red; ">*</sub>
        </label>
        <app-combo-box [width]="'260px'" style="margin-left: 10px;" [Placeholder]="'Search State/Select State'"
          (valueChange)="StateValueChange($event)" [data]="stateDropdownData"
          [hasError]="!selectedDropdown &&showValidation">
        </app-combo-box>
      <div class="col-md-4 sampleDocument colpadding0" style="float:right; right:0;">
        <a href="/src/app/BulkUploadDocuments/MW_IndustrySkillsDesignation.xlsx"
          download="MW_IndustrySkillsDesignation.xlsx"
          style="padding: 5px; color: #125AAB; float:right; text-decoration: underline;font-size:12px"
          id="btnSampleRLCS">
          Sample Document
        </a>
        <img src="../assets/vectors/Excel.svg" alt="" style="padding: 5px; float:right;">
      </div>
    </div>
    <div class="col-md-12 form-group" style="margin-top: 5px;">
      <div class="drop-zone" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
        <div *ngIf="!selectedFile" class="f-label" style="font-size: 12px;">
          <div class="col-md-12">
            <img src="../assets/vectors/cloud.svg" alt="">
          </div>
          <div class="col-md-8">
            <label style="margin-left: 155px;">{{ dropzoneMessage }}</label>
          </div>
          <div class="col-md-4">
            <label
              style="color: #125AAB; text-decoration: underline; cursor: pointer; margin-left: -57px; font-weight: 500;">
              Browse
              <input type="file" style="display: none;" (change)="onFileSelect($event)" accept=".xls,.xlsx,.csv" />
            </label>
          </div>
        </div>
        <p *ngIf="selectedFile" style="margin-top: 30px;">{{ selectedFile.name }}</p>
      </div>
    </div>
  </div>
  <kendo-dialog-actions style="border: none; ">
    <button kendoButton class="figma-btn-blue figma-popup" (click)="uploadFiles()">Upload</button>
  </kendo-dialog-actions>
</kendo-dialog>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270" [Data]="alertData"
  (close)="closeAlert()"></app-alert-popup>