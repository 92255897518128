import { Component, SimpleChange, ViewChild } from '@angular/core';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { ReviewRegulatoryAbstractsService } from '../../../service/review-regulatory-abstracts.service';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { UserService } from '../../../service/user.service';
import { NgFor, NgIf } from '@angular/common';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { PaycodeUploadPopupComponent } from '../../popups/paycode-upload-popup/paycode-upload-popup.component';
import { ReviewRegulatoryAbstractUploadPopupComponent } from "../../popups/review-regulatory-abstract-upload-popup/review-regulatory-abstract-upload-popup.component";
import { BlobService } from '../../../service/blob.service';
import { environment } from '../../../environments/environment';
import { GridModule } from '@progress/kendo-angular-grid';
import { AdobeViewerComponent } from '../../../components/adobe-viewer/adobe-viewer.component';


@Component({
  selector: 'app-review-regulatory-abstracts',
  standalone: true,
  imports: [DropdownListComponent, AlertPopupComponent,NgFor, GridActionItemComponent, NgIf, IndicatorsModule, PaycodeUploadPopupComponent, ReviewRegulatoryAbstractUploadPopupComponent,GridModule,AdobeViewerComponent],
  templateUrl: './review-regulatory-abstracts.component.html',
  styleUrl: './review-regulatory-abstracts.component.css'
})
export class ReviewRegulatoryAbstractsComponent {
  private blobUrl = environment.blobUrl;
  fileBlob!:any;
  public defaultActSelectedValue = { text: 'Select Act', value: '' };
  public defaultSelectedValue = { text: 'Select State', value: '' };
  public alertData = { alertMessage: '', alertVisible: '' };
  public gridData: GridDataResult = { data: [], total: 0 };
  public OriginalData: GridDataResult = { data: [], total: 0 };
  dropdownDataPrimary: { text: string, value: string }[] = [];
  dropdownDataSecondary: { text: string, value: string }[] = [];
  uploadOpen = false;
  loading: boolean = false;
  GridLoading: boolean = false;
  UserId!: number;
  Skip = 0;
  public buttonCount = 5;
  public PageSize: number = 10;
  public sizes = [{text:'All',value:Infinity},5, 10, 20];
  selectedState = { text: 'Select State', value: '' };
  selectedAct = { text: 'Select Act', value: '' };
  selectedFilters = {
    stateName: '',
    actName: ''
  };

  // parentColumns: any[] = [
  //   { field: 'stateName', title: 'State Name' },
  //   { field: 'actName', title: 'Act Name' },
  //   { field: 'avacoM_BranchName', title: 'Branch Name' },
  //   {
  //     field: 'regional',
  //     isIconColumn: true,
  //     title: 'Regional',
  //     filteredIcons: [
  //       {
  //         url: '../assets/vectors/DownloadBlue.svg',
  //         alt: 'Icon 1',
  //         action: 'regional'
  //       },
  //       {
  //         url: '../assets/vectors/eye_112_x2.svg',
  //         alt: 'Icon e',
  //         action: 'eye'
  //       },
  //     ]
  //   },
  //   {
  //     field: 'english',
  //     isIconColumn: true,
  //     title: 'English',
  //     filteredIcons: [
  //       {
  //         url: '../assets/vectors/DownloadBlue.svg',
  //         alt: 'Icon 1',
  //         action: 'english'
  //       },
  //       {
  //         url: '../assets/vectors/eye_112_x2.svg',
  //         alt: 'Icon e',
  //         action: 'eye'
  //       },
  //     ]
  //   },
  //   {
  //     field: 'acknowledgement',
  //     title: 'Acknowledgement',
  //     isIconColumn: true, 

  //   }
  // ];
  parentColumns: any[] = [
    { field: 'stateName', title: 'State Name' },
    { field: 'actName', title: 'Act Name' },
    { field: 'avacoM_BranchName', title: 'Branch Name' },
    {
      field: 'regional',
      isIconColumn: true,
      title: 'Regional',
      filteredIcons: [
        {
          url: '../assets/vectors/DownloadBlue.svg',
          alt: 'Icon 1',
          action: 'regional'
        },
        {
          url: '../assets/vectors/EyeIcon.svg',
          alt: 'Icon e',
          action: 'eye'
        },
      ]
    },
    {
      field: 'english',
      isIconColumn: true,
      title: 'English',
      filteredIcons: [
        {
          url: '../assets/vectors/DownloadBlue.svg',
          alt: 'Icon 1',
          action: 'english'
        },
        {
          url: '../assets/vectors/EyeIcon.svg',
          alt: 'Icon e',
          action: 'eye'
        },
      ]
    },
    {
      field: 'acknowledgement',
      title: 'Acknowledgement',
      isIconColumn: true,
      
    }
  ];

  constructor(private reviewRegulatoryAbstractsService: ReviewRegulatoryAbstractsService, private user: UserService, private blobService: BlobService) { }
  
  ngOnInit(): void {
    this.loadGridData()
    this.getDropdownList();
  }

  onDropdownValueChange(value: any, type: string) {
    debugger;
    console.log(value);

    if (type === 'State') {
      this.selectedState = { text: value.text, value: value.value };
      this.getActDropdownList();
      if (value.value === '') {
        this.gridData = {
          data: this.OriginalData.data,
          total: this.OriginalData.data.length
        };
      } else {
        const filteredData = this.OriginalData.data.filter(item => item.stateName === value.text);
        this.gridData = {
          data: filteredData,
          total: filteredData.length
        };
      }

    } else if (type === 'Act') {
      this.selectedAct = { text: value.text, value: value.value };
      if (value.value === '') {
        this.gridData = {
          data: this.OriginalData.data,
          total: this.OriginalData.data.length
        };
      } else {
        const filteredData = this.OriginalData.data.filter(item => item.actName === value.text && item.stateName === this.selectedState.text);
        this.gridData = {
          data: filteredData,
          total: filteredData.length
        };
      }
    }
  }

  filterData(data: any[], stateName: string, actName: string): any[] {
    return data.filter(item => {
      const matchesState = !stateName || item.stateName === stateName;
      const matchesAct = !actName || item.actName === actName;
      return matchesState && matchesAct;
    });
  }
  
  onIconClick(action: string, dataItem: any,icon:any) {
    debugger
    console.log(icon);
    
    switch (action) {
      case 'regional':
        this.handleRegionalAction(dataItem,icon);
        break;

      case 'english':
        this.handleEnglishAction(dataItem,icon);
        break;

      case 'acknowledgement':
        this.handleAcknowledgementDetails(dataItem,icon);
        break;

      default:
        console.warn('Unknown action:', action);
        break;
    }
  }
  handleRegionalAction(dataItem: any,icon:any) {
    console.log('Regional icon clicked for:', dataItem);
    if(icon.action=='eye'){
      this.viewFile(dataItem.regionalFilePath,dataItem.regionalFileName,dataItem.regionalFileKey)
    }else{
      this.loading = true;
      this.downloadFile(dataItem.regionalFilePath, dataItem.regionalFileName);
    }
  }
  handleEnglishAction(dataItem: any,icon:any) {
    console.log('English icon clicked for:', dataItem);
    if(icon.action=='eye'){
      this.viewFile(dataItem.englishFilePath,dataItem.englishFileName,dataItem.englishFileKey)
    }else{
      this.loading = true;
      this.downloadFile(dataItem.englishFilePath, dataItem.englishFileName);
    }
  }
  handleAcknowledgementDetails(dataItem: any,icon:any) {
    debugger;
    console.log('Acknowledgement icon clicked for:', dataItem);
    if(icon.action=='eye'){
      this.viewFile(dataItem.acknowledgementFilePath,dataItem.acknowledgementFileName,dataItem.acknowledgementFileKey)
    }else{ 
      this.loading = true;
      this.downloadFile(dataItem.acknowledgementFilePath, dataItem.acknowledgementFileName);
    }
  }
  viewFile(FilePath:string,FileName:string,FileKey:string){
       this.reviewRegulatoryAbstractsService.fetchFile(FilePath, FileName, FileKey).subscribe({
        next: (blob: Blob) => {
          debugger;
          this.fileBlob = blob;
          this.loading = false;
        },
        error: (error) => {
          this.loading = false;
          console.error('Error fetching Branch Dropdown data', error);
        }
      });
  }
  closeViewer(){
    this.fileBlob=null;
  }
  // handleButtonClick(event: any) {
  //   debugger;
  //   console.log('Event', event);

  //   if (event.button.action === 'regional') {
  //     this.loading = true;
  //     this.downloadFile(event.dataItem.regionalFilePath, event.dataItem.regionalFileName);
  //   } else if (event.button.action === 'english') {
  //     this.loading = true;
  //     this.downloadFile(event.dataItem.englishFilePath, event.dataItem.englishFileName);
  //   }
  //   else if (event.button.action === 'acknowledgement') {
  //     if (event.dataItem.acknowledgement === 1) {
  //       this.loading = true;
  //       this.downloadFile(event.dataItem.acknowledgementFilePath, event.dataItem.acknowledgementFileName);
  //     }

  //   }
  // }
  // downloadAckno() {
  //   if (this.selectedFile) {
  //     const link = document.createElement('a');
  //     link.href = `path/to/your/files/${this.selectedFile}`; 
  //     link.download = this.selectedFile;
  //     link.click();
  //   } else {
  //     console.log('No file selected to download');
  //   }
  // }
  downloadFile(filePath: string, fileName: string) {
    debugger;
    const blobLink = this.blobUrl + filePath;
    const blobName = this.blobService.extractBlobName(blobLink);
      this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
        debugger;
        const sasToken = tokenResponse.result;
        this.blobService.downloadFile(blobName, sasToken);
        this.loading = false;
      });
    
  }
  loadGridData(): void {
    this.GridLoading = true;
    const UserId = this.user.getUserId();
    const ClientId = this.user.getClientId();
  
    this.reviewRegulatoryAbstractsService.getGridData(ClientId, UserId).subscribe({
      next: (response) => {
        const data = response.body;
        console.log(data);
        this.OriginalData = {
          data: data.map((item: any) => ({
            ...item,
            // Any transformations to the item can be added here
          })),
          total: data.length
        };
  
        this.gridData = {
          data: this.OriginalData.data.slice(this.Skip, this.Skip + this.PageSize),
          total: this.OriginalData.total
        };
        setTimeout(() => {
          this.GridLoading = false;
        }, 2000);
      },
      error: (error) => {
        console.error(error);
        this.GridLoading = false;
      }
    });
  }



  getDropdownList() {
    debugger;
    const UserId = this.user.getUserId();
    const ClientId = this.user.getClientId();

    this.reviewRegulatoryAbstractsService.getDropdownOptions(ClientId, UserId, 0).subscribe({
      next: (response) => {
        debugger;
        const data = response.body;
        console.log(data);

        if (data && Array.isArray(data)) {
          this.dropdownDataPrimary = data.map(item => ({
            text: item.key,
            value: item.value
          }));
        }
        console.log('dropdown data', this.dropdownDataPrimary);
      },
      error: (err) => {
        console.error('Error fetching Dispencery data List', err);
        this.loading = false;
      }
    });
  }
  getActDropdownList() {
    debugger;
    const UserId = this.user.getUserId();
    const ClientId = this.user.getClientId();

    this.reviewRegulatoryAbstractsService.getActDropdownOptions().subscribe({
      next: (response) => {
        debugger;
        const data = response.body;
        console.log(data);

        if (data && Array.isArray(data)) {
          this.dropdownDataSecondary = data.map(item => ({
            text: item.value,
            value: item.key
          }));
        }

        console.log('dropdown data', this.dropdownDataSecondary);
      },
      error: (err) => {
        console.error('Error fetching Dispencery data List', err);
        this.loading = false;
      }
    });

  }
  public uploadDialog(): void {

    this.uploadOpen = true;
  }
  closeUploadDialog() {
    this.uploadOpen = false;
    this.loadGridData();
  }
  openUploadPopup() {
    this.uploadOpen = true;
  }
  public pageChange(event: PageChangeEvent): void {
    this.GridLoading=true;
    console.log('Page Change Event Received:', event);
    this.Skip = event.skip;
    if(event.take==Infinity){
      this.PageSize=this.OriginalData.total
    }else{
      this.PageSize = event.take;
    }
    this.gridData = {
      data: this.OriginalData.data.slice(this.Skip, this.Skip + this.PageSize),
      total: this.OriginalData.total
    };
    setTimeout(() => {
      this.GridLoading=false;
    }, 2000);
  }
  clearFilter() {
    this.defaultSelectedValue = { text: 'Select State', value: '' };
    this.defaultActSelectedValue = { text: 'Select Act', value: '' };
    this.loadGridData();
  }
  closeAlert() {
    this.alertData = { alertMessage: '', alertVisible: '' };
  }
}
