import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { UserService } from '../../../service/user.service';
import { UserBranchMappingService } from '../../../service/user-branch-mapping.service';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { NgIf } from '@angular/common';
import { AddNewUserBranchMappingPopupComponent } from '../../popups/add-new-user-branch-mapping-popup/add-new-user-branch-mapping-popup.component';
import { FormsModule } from '@angular/forms';
import { DropDownsModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { MultiSelectTreeComponent } from '../../../components/multi-select-tree/multi-select-tree.component';
import { LabelModule } from '@progress/kendo-angular-label';
import { CommonService } from '../../../service/common.service';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';

type Branch = {
  id: number;
  name: string;
  parentID: number | null;
  branchType: string;
  branchStatus: string;
  fullName: string | null;
  children?: Branch[];
};

type clraBranch = {
  parentID: number | null;
  id: number;
  name: string | null;
  level: number;
  type: string | null;
  children: clraBranch[];
};

@Component({
  selector: 'app-user-branch-mapping',
  standalone: true,
  imports: [ComboBoxComponent, GridActionItemComponent, AlertPopupComponent, NgIf, AddNewUserBranchMappingPopupComponent, FormsModule, MultiSelectModule, TreeViewModule, MultiSelectTreeComponent, LabelModule, DropDownsModule, DropdownListComponent],
  templateUrl: './user-branch-mapping.component.html',
  styleUrl: './user-branch-mapping.component.css'
})
export class UserBranchMappingComponent {
  @ViewChild('multiselectTree') multiselectTree!: ElementRef;
  public gridData: GridDataResult = { data: [], total: 0 };
  public defaultAct: any = { name: 'Select Act', id: 'Select Act' };
  public defaultBranch: any = 'Select Branches';
  public defaultUser: any = 'Search/Select User'
  selectedUser?: any
  selectedAct: { name: string, id: string } = { name: 'Select Act', id: 'null' };
  allUser: any[] = []
  selectedbranch:any;
  public openUserBranch: boolean = false
  public userId: number = 0
  public branchId: number = 0
  public actType: string | null = null;
  @Output() close = new EventEmitter<void>();
  alertData = { alertMessage: '', alertVisible: '' }
  Acts: { name: string, id: string }[] = [
    {
      name: 'Select Act ', id: 'Select Act'
    },
    {
      name: 'Shops and Establishment Allied ', id: 'SEA'
    },
    {
      name: 'Contract Labour Regulation and Abolition Act', id: 'CLRA'
    }
  ]
  public BranchDropdown: Branch[] = [];
  popupSettings: any = { width: '280px' };
  public clraBranchDropdown: clraBranch[] = [];
  commaSeparatedIds: string = '';
  showClearFilterButton: boolean = false

  constructor(private userBranchMappingService: UserBranchMappingService, private user: UserService, private commonService: CommonService,private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.loadEntityBranchUserMapping();
    this.loadUser();

  }

  private filterGridData(): void {
    const filteredData = this.gridData.data.filter(item => {
      let match = true;
      if (this.selectedUser && this.selectedUser.id) {
        match = match && String(item.id) === String(this.selectedUser.id);
      }
      if (this.commaSeparatedIds) {
        const selectedBranchIds = this.commaSeparatedIds.split(',').map(id => +id);
        console.log('Filtering by branch. Expected Branch IDs:', selectedBranchIds, 'Actual Branch ID:', item.avacoM_BranchID);
        match = match && selectedBranchIds.includes(+item.avacoM_BranchID);
        console.log('Branch match result:', match);
      }

      console.log('Final match for this item:', match);
      return match;
    });

    console.log('Filtered Data:', filteredData);

    this.gridData = {
      data: filteredData,
      total: filteredData.length
    };
    this.showClearFilterButton = !!this.selectedUser || !!this.commaSeparatedIds;
    console.log('grid',this.gridData);
  }


  applyFilter() {
    this.filterGridData();
  }
  
  clearFilter() {
    this.selectedAct = { name: 'Select', id: 'null' };
    this.selectedUser = undefined;
    this.commaSeparatedIds = '';
    this.loadEntityBranchUserMapping();
    this.showClearFilterButton = false;
    this.selectedbranch=[];
  }

  mapUser() {
    this.openUserBranch = true;
  }
  public tagMapper(tags: any[]): any[] {
    if (tags.length < 3) {
      return tags.map(tag => tag.name); 
    } 
  
    return [{ name: `${tags.length} items selected` }];
  }
  

  public clraTagMapper(tags: any[]): any[] {
    if (tags.length < 3) {
      return tags.map(tag => tag.name); 
    } 
  
    return [{ name: `${tags.length} items selected` }];
  }
  
  

  OnMultiSelectChange(value: any) {
    if (Array.isArray(value)) {
      this.commaSeparatedIds = value.map(item => item.id).join(',');
      console.log(this.commaSeparatedIds);
    } else {
      console.error('Expected an array of selected items.');
    }
  }

  onActionClick(event: { dataItem: any, button: any }): void {
    debugger
    console.log('delete user branch', event);
    this.userId = event.dataItem.id;
    this.branchId = event.dataItem.avacoM_BranchID;

    this.alertData = { alertMessage: 'Are you certain you want to delete this Mapping??', alertVisible: 'confirm' };
  }

  deleteUserBranch(value: any) {
    this.userBranchMappingService.deleteUserBranchMapping(this.userId, this.branchId).subscribe({
      next: (response) => {
        this.alertData = { alertMessage: 'Record deleted succesfully', alertVisible: 'success' };
        this.loadEntityBranchUserMapping();
      }

    }
    )
  }

  // loadEntityBranchUserMapping() {
  //   const entityId = this.user.getClientId();
  //   const actTypeToSend = this.actType ? this.actType : 'null';

  //   console.log('Act Type', actTypeToSend);

  //   this.userBranchMappingService.getEntityBranchUserMapping(entityId, actTypeToSend)
  //     .subscribe({
  //       next: (response) => {
  //         const data = response.body
  //         this.gridData = {
  //           data: data,
  //           total: data.length
  //         }
  //         console.log('Grid data', this.gridData);
  //         this.filterGridData();
  //       },
  //       error: (error) => {
  //         console.error('Error fetching data:', error);
  //       }
  //     });
  // }
  loadEntityBranchUserMapping() {
    const entityId = this.user.getClientId();
    const actTypeToSend = this.actType ? this.actType : 'null';

    this.userBranchMappingService.getEntityBranchUserMapping(entityId, actTypeToSend)
        .subscribe({
            next: (response) => {
                const newData = response.body;
                this.gridData.data = [...newData]; 
                this.gridData.total = this.gridData.data.length;
                console.log('Grid data updated:', this.gridData);
                this.filterGridData();
            },
            error: (error) => {
                console.error('Error fetching data:', error);
            }
        });
}

  loadUser() {
    const custId = this.user.getCustomerId();
    this.userBranchMappingService.getAllUserMasterForPopupA(custId).subscribe({
      next: (response) => {
        console.log('user data', response);
        const data = response.body
        if (data && Array.isArray(data)) {
          this.allUser = data.map((item: any) => ({
            id: item.id,
            name: item.userName
          }));
          this.defaultUser = 'Select User';
          console.log('User data:', this.allUser);
        }
      }
    })
  }
  getBranchDropdownData() {
    const entityId = this.user.getClientId();
    if (this.selectedAct.id === 'SEA') {
      this.userBranchMappingService.getBranchDropdown(entityId).subscribe({
        next: (response) => {
          console.log('branch dropdown data', response);
          const data = response.body;
          if (data && Array.isArray(data)) {
            this.BranchDropdown = this.buildHierarchy(data);
            const maxLength = this.calculateMaxTextLength(this.BranchDropdown);

            const calculatedWidth = Math.max(250, maxLength * 10);
            this.popupSettings = { width: `${calculatedWidth}px` };
          } else {
            console.error('Unexpected data format', data);
          }
        }
      })
    }

    else if (this.selectedAct.id === 'CLRA') {
      this.commonService.getClraType(entityId).subscribe({
        next: (response) => {

          if (response.body.result === 'CAC') {
            this.userBranchMappingService.getCLRABranchDropdown(entityId).subscribe({
              next: (response) => {
                const data = response.body;
                if (data && Array.isArray(data)) {
                  this.clraBranchDropdown = this.mapHierarchy(data);
                  const maxLength = this.calculateMaxTextLengthclra(this.clraBranchDropdown);

                  const calculatedWidth = Math.max(250, maxLength * 10);
                  this.popupSettings = { width: `${calculatedWidth}px` };
                } else {
                  console.error("Unexpected data format", data);
                }
              }
            })
          }
          else if (response.body.result === 'CAP') {
            this.userBranchMappingService.getCLRA_PEBranchDropdown(entityId).subscribe({
              next: (response) => {
                const data = response.body;
                if (data && Array.isArray(data)) {
                  this.clraBranchDropdown = this.mapHierarchy(data);
                  const maxLength = this.calculateMaxTextLengthclra(this.clraBranchDropdown);

                  const calculatedWidth = Math.max(250, maxLength * 10);
                  this.popupSettings = { width: `${calculatedWidth}px` };
                } else {
                  console.error("Unexpected data format", data);
                }
              }
            })
          }
        }
      })


    }
  }
  calculateMaxTextLength(data: Branch[]): number {
    let maxLength = 0;

    const findMaxLength = (items: Branch[]) => {
      items.forEach(item => {
        maxLength = Math.max(maxLength, item.name.length);
        if (item.children && item.children.length) {
          findMaxLength(item.children);
        }
      });
    };

    findMaxLength(data);
    return maxLength;
  }
  calculateMaxTextLengthclra(data: clraBranch[]): number {
    let maxLength = 0;

    const findMaxLength = (items: clraBranch[]) => {
      items.forEach(item => {
        const nameLength = item.name ? item.name.length : 0;
        maxLength = Math.max(maxLength, nameLength);
        if (item.children && item.children.length) {
          findMaxLength(item.children);
        }
      });
    };
    findMaxLength(data);
    return maxLength;
  }
  private mapHierarchy(items: any[]): clraBranch[] {
    return items.map((item: any) => {
      const hasChildren = item.children && item.children.length > 0;
      return {
        parentID: item.parentID,
        id: item.id,
        name: item.name || "Unknown",
        level: item.level,
        type: item.type,
        children: hasChildren ? this.mapHierarchy(item.children) : []
      };
    });
  }


  buildHierarchy(data: Branch[]): Branch[] {
    const grouped: { [key: number]: Branch[] } = data.reduce((acc, item) => {
      (acc[item.parentID || -1] = acc[item.parentID || -1] || []).push(item);
      return acc;
    }, {} as { [key: number]: Branch[] });

    function buildTree(parentID: number | null): Branch[] {
      return (grouped[parentID || -1] || []).map(item => ({
        ...item,
        children: buildTree(item.id)
      }));
    }

    return buildTree(null);
  }

  DropdownUserChange(value: any) {
    this.selectedUser = value
  }

  DropdownActChange(value: any) {
    this.selectedAct = value
    this.actType = this.selectedAct.id === 'null' ? null : this.selectedAct.id;
    if(value.id){
      this.getBranchDropdownData();
    }else{
      this.clraBranchDropdown=[];
      this.BranchDropdown=[];
    }
    this.loadEntityBranchUserMapping();
  }

  public closePopup(): void {
    this.alertData = { alertMessage: '', alertVisible: '' };
    this.close.emit();
  }

  onDataSaved() {
    this.openUserBranch = true;
  }

  closeSaveDialog() {
    this.openUserBranch = false;
  }


  public parentColumns: any[] = [
    { field: 'sM_Name', title: 'State', width: '180px' },
    { field: 'avacoM_BranchName', title: 'Branch' },
    { field: 'userName', title: 'User Name' },
    { field: 'email', title: 'User Email', width: '360px'  },
    { field: 'roleName', title: 'User Role', width: '150px'  },

    {
      field: 'Action',
      isIconColumn: true,
      title: 'Action',
      filteredIcons: [
        {
          url: '../assets/vectors/delete.svg',
          alt: 'Delete Icon',
          action: 'delete'
        }
      ]
    }
  ];
}
