<div class="d-flex" style="margin-top:15px;gap:17px;">

    <div>
        <app-dropdown-list [data]="invoiceReportDropdown" [selectedValue]="selectedInvoiceReport" [width]="'250px'"
            textField="text" valueField="value" (valueChange)="onDropdownValueChange($event)">
        </app-dropdown-list>
    </div>


    <div>
        <app-dropdown-list [data]="ClientGstDropdownData" [selectedValue]="selectedClient" [width]="'250px'"
            textField="text" valueField="value" (valueChange)="onDropdownClientGstValueChange($event)">
        </app-dropdown-list>
    </div>


    
    <div class="col-md-2 colpadding0" style="padding-right: 10px;">
        <app-combo-box [width]="'106%'" [Placeholder]="'Select month/year'"
            (valueChange)="onDropdownMonthYearValueChange($event)" [data]="monthDropdown"
            [selectedValue]="selectedMonthYear" textField="name" valueField="id">
        </app-combo-box>
    </div>

    <div>
        <app-dropdown-list [data]="selectInvoiceReport" [selectedValue]="selectInvoiceReportTypes" [width]="'250px'"
            textField="text" valueField="value" (valueChange)="onDropdownInvoiceReportValueChange($event)">
        </app-dropdown-list>
    </div>
</div>


<div>
    <div style="display: flex; 
margin-top:12px;
    justify-content: end; ">
        <!-- <button class="figma-btn-blue">
            Cancel
        </button>
        <button class="figma-btn-blue" (click)="GetActiveGSTUploadedDetailsbasedOnClientId(clientID)">
            View Report
        </button> -->
        <div>
            <button class="figma-btn-white apply-btn" (click)="ApplyFilter()">
                <img src="../assets/vectors/ApplyFilter.svg" alt="Apply Filter">
            </button>
        </div>
        <div>
            <button class="figma-btn-white clear-btn" (click)="clearFilter()">
                <img src="../assets/vectors/ClearFilter.svg" alt="Clear Filter">
            </button>
        </div>

        <button class="exportbtn" (click)="exportToExcel()">
            <img src="../assets/vectors/ExportButton.svg" alt="">
        </button>
    </div>
</div>

<kendo-grid class="custom-grid" style="margin-top: 15px;" [data]="gridData" [pageSize]="10" [pageable]="true"
    [skip]="Skip" (pageChange)="pageChange($event)" [loading]="GridLoading">
    <ng-template *ngIf="GridLoading" kendoGridLoadingTemplate class="loader-overlay">
        <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
    </ng-template>
    <kendo-grid-column field="clientID" title="
ClientID">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div [title]="dataItem.clientID" class="cell-content">
                {{ dataItem.clientID }}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="client_Name" title="	ClientName">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div [title]="dataItem.client_Name" class="cell-content">
                {{ dataItem.client_Name }}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="gstno" title="	TL_GSTno">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div [title]="dataItem.gstno" class="cell-content">
                {{ dataItem.gstno }}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="invoice_NO" title="InvoiceNo">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div [title]="dataItem.invoice_NO" class="cell-content">
                {{ dataItem.invoice_NO }}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="invoice_Date" title="InvoiceDate">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div [title]="dataItem.invoice_Date" class="cell-content">
                {{ dataItem.invoice_Date }}
            </div>
        </ng-template>
    </kendo-grid-column>


    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-input></kendo-pager-input>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>

</kendo-grid>

<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>