import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AuthorityAddressServiceService } from '../../../service/authority-address-service.service';
import { UserService } from '../../../service/user.service';
import { LocationMasterServiceService } from '../../../service/location-master-service.service';
import { CommonModule, NgIf } from '@angular/common';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { FormsModule } from '@angular/forms';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { LocationMasterComponent } from '../../manage-masters/location-master/location-master.component';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';

@Component({
  selector: 'app-seal-signature-upload-popup',
  standalone: true,
  imports: [NgIf, GridActionItemComponent, CommonModule, FormsModule, DialogModule, LocationMasterComponent, AlertPopupComponent],
  templateUrl: './seal-signature-upload-popup.component.html',
  styleUrls: ['./seal-signature-upload-popup.component.css']
})
export class SealSignatureUploadPopupComponent {
  @Input() locationid?: any;
  @Output() close = new EventEmitter<void>();
  selectedFile?: File;
  dropzoneMessage: string = "Drag your files here or ";
  clientid: any; 
  userid: any; 
  alertData = { alertMessage: '', alertVisible: '' };

  constructor(
    private authorityService: AuthorityAddressServiceService,
    private userService: UserService,
    private locationMaster: LocationMasterServiceService
  ) {}

  @ViewChild('dialogTitle') dialogTitle!: ElementRef;
  ngAfterViewInit() {
    const titleElement = this.dialogTitle.nativeElement;
    titleElement.style.textAlign = 'center';
    titleElement.style.width = '100%';
  }
  ngOnInit(): void {
    this.userid = this.userService.getUserId();
    this.clientid = this.userService.getClientId();
    console.log('Location ID received in ngOnInit:', this.locationid);
  }

  public onFileSelect(event: Event): void { 
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
      const file = input.files[0];
      if (this.validateFile(file)) {
        this.selectedFile = file;
        console.log('Selected file:', this.selectedFile);
      } else {
        this.alertData = { alertMessage: 'Invalid file type.', alertVisible: 'error' };
      }
    }
  }
  
  public onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drop your file here or";
  }
  
  public onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drag your files here or ";
  }
  
  public onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      if (this.validateFile(file)) {
        this.selectedFile = file;
        console.log('Selected file:', this.selectedFile);
      } else {
        this.alertData = { alertMessage: 'Invalid file type.', alertVisible: 'error' };
      }
      event.dataTransfer.clearData();
    }
  }

  public uploadFile(): void {
    if (this.selectedFile) {
      this.authorityService.uploadSignature(this.selectedFile, this.clientid, this.locationid, this.userid).subscribe({
        next: (response) => {
          console.log('File Uploaded Successfully', response);
               this.alertData = { alertMessage: 'File Uploaded Successfully', alertVisible: 'success' };
        },
        error: (error) => {
          this.alertData = { alertMessage: error.error.result, alertVisible: 'error' }; 
        }
      });
    } else {
      this.alertData = { alertMessage: 'No file selected', alertVisible: 'error' };
    }
  }

  private validateFile(file: File): boolean {
    const allowedExtensions = ['jpg', 'jpeg','png'];
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    return fileExtension ? allowedExtensions.includes(fileExtension) : false;
  }

  public closePopup(): void {
    this.close.emit();
  }
  public closeAlertPopup(): void {

    this.alertData.alertVisible = ''; 

  }
}
