<div class="row colpadding0">
    <div class="col-md-12 colpadding0 toolbar" style="margin-top: 10px;margin-bottom: 10px;">
        <div class="col-md-2 colpadding0" style="padding-right: 10px;">
           
            <app-combo-box [width]="'100%'" [Placeholder]="'Select month/year'"
            (valueChange)="onDropdownMonthYearValueChange($event)" [data]="monthDropdown"
            [selectedValue]="selectedMonthYear" textField="name" valueField="id">
        </app-combo-box>
        </div>
        <div class="col-md-2 colpadding0" style="padding-right: 10px;">
           
            <app-combo-box [width]="'100%'" [Placeholder]="'Select client'"
            (valueChange)="onDropdownValueChange($event)"
            [data]="clientDropdown" [selectedValue]="selectedClientType" textField="text"
            valueField="value" >
        </app-combo-box>
        </div>
        <div class="col-md-2 colpadding0" style="padding-right: 10px;">
           <img style="height: 27px;cursor: pointer;" src="../assets/vectors/Excel.svg"(click)="getDownload()">
            
          
        </div>
       
      
    </div>
</div>


<div>
    <kendo-grid class="custom-grid" [data]="gridData" [columnMenu]="true" [pageSize]="pageSize" [pageable]="true"
    (pageChange)="pageChange($event)" [sortable]="true" [skip]="skip" [resizable]="true"
    style="margin-top:10px;"> <!-- Set a minimum width for scrolling -->

    <kendo-grid-column [width]="80">
        <ng-template kendoGridHeaderTemplate>
            <div class="cell-content" style="margin-left:10px;">
                <input type="checkbox" style="margin-left: 10px;" [(ngModel)]="allSelected"
                    (change)="toggleSelectAll($event)" />
            </div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="checkbox-container">
                <input type="checkbox" style="margin-left: 13px;" [(ngModel)]="dataItem.selected"
                    [disabled]="dataItem.disabled" (change)="onItemSelectionChange(dataItem)" />
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="cliendID" title="Client Id" >
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.clientId">
                {{dataItem.clientId}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="draftInvoiceNumber" title="Draft Invoice Number" >
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.draftInvoiceNumber">
                {{dataItem.draftInvoiceNumber}} 
            </div>
        </ng-template>
    </kendo-grid-column>

 

    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-input></kendo-pager-input>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>
</kendo-grid>
</div>

    <div style="display: flex;justify-content: center;margin-top: 10px;">
        <div class="figma-btn-blue ">Convert</div>
        <div class="figma-btn-blue ">Reject</div>
    </div>