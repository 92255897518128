import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MinimumWagePeriodService {
  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getMinimumWagePeriod(): Observable<HttpResponse<any>>{
    const url = `${this.apiUrl}/api/MinimumWage/Get_LC_Minimum_Wage_Periodicity_Master`;
    return this.http.get(url, { observe: 'response' });
  }
  updateMinimumWagePeriod(id: number, state: string, frequency: string, period: string): Observable<HttpResponse<any>> {
    const body = {
      id: id,
      state: state,
      frequency: frequency,
      period: period
    };
    const url = `${this.apiUrl}/api/MinimumWage/Save_LC_Minimum_Wage_Periodicity_ByState`;
    return this.http.post<any>(url, body,{ observe: 'response' });
  }
  
}
