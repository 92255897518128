import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

export interface UserBranchMappingPayload {
  userId: number;
  branchIds: string;
  entityId: string;
  custId: number;
}

@Injectable({
  providedIn: 'root'
})
export class UserBranchMappingService {
  private baseUrl = environment.apiUrl;
  constructor(private http:HttpClient) { }

  getEntityBranchUserMapping(EntityId: string, ActType: string): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/UserBranchAssignment/GetEntityBranchUserMapping?EntityId=${EntityId}&ActType=${ActType}`;  
    return this.http.get<any>(url, { observe: 'response' })
  }

  deleteUserBranchMapping(userId: number, branchId: number): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/UserBranchAssignment/DeleteUserBranchMapping?UserId=${userId}&BranchId=${branchId}`;
    return this.http.get(url,  { observe: 'response' });
  }

  getAllUserMasterForPopupA(customerID: number): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/UserBranchAssignment/GetAll_UserMaster_ForPopup?customerID=${customerID}`;
    return this.http.get<any>(url,{ observe: 'response' })
  }
  getAllUserMasterForPopup(customerID: number,usertype:string): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/UserBranchAssignment/GetAll_UserMaster_ForMap?customerID=${customerID}&usertype=${usertype}`;
    return this.http.get<any>(url,{ observe: 'response' })
  }

  getBranchDropdown(entityId:string):Observable<HttpResponse<any>>{
    const url =`${this.baseUrl}/api/ClientDetailsManagement/GetBranchDropdownlist?EntityId=${entityId}`;
    return this.http.get<any>(url,{ observe: 'response' });
  }

  getCLRABranchDropdown(entityId:string):Observable<HttpResponse<any>>{
    const url =`${this.baseUrl}/api/ClientDetailsManagement/GetBranchDropdownlist_CLRA?EntityId=${entityId}`;
    return this.http.get<any>(url,{ observe: 'response' });
  }
  getCLRA_PEBranchDropdown(entityId:string):Observable<HttpResponse<any>>{
    const url =`${this.baseUrl}/api/ClientDetailsManagement/GetBranchDropdownlist_CLRA_PE?EntityId=${entityId}`;
    return this.http.get<any>(url,{ observe: 'response' });
  }

  saveUserBranchMapping(payload: UserBranchMappingPayload):Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/UserBranchAssignment/SaveUserBranchMapping`;
    return this.http.post<any>(url, payload,{ observe: 'response' });
  }
}
