<kendo-dialog title="Upload" (close)="closePopup()" [minWidth]="400" [width]="500"  class="custom-dialog">
  <div class="row dialog-content">
    <div class="col-md-12 inline-flex-container">
      <label class="f-label">
        State<sub style="color: red; font-size: 15px;">*</sub>
      </label>
      <app-combo-box [width]="'330px'" style="margin-left: 20px;" [Placeholder]="'Search State/Select State'"
        (valueChange)="StateValueChange($event)" [data]="stateDropdownData">
      </app-combo-box>
      <img src="../assets/vectors/ExcelDownload.svg" (click)="downloadCSV()" alt="" title="Download">
    </div>
   
    <div class="col-md-12 form-group">
      <div class="drop-zone" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
        <div *ngIf="!selectedFile" class="f-label" style="font-size: 18px;">
          <div class="col-md-12" style="margin-top: -5px;">
            <img src="../assets/vectors/cloud.svg" alt="">
          </div>
          <div class="col-md-8">
            <label style="margin-left: 120px;">{{ dropzoneMessage }}</label>
          </div>
          <div class="col-md-4">
            <label
              style="color: #125AAB; text-decoration: underline; cursor: pointer; margin-left: -39px; font-weight: 500;">
              Browse
              <input type="file" style="display: none;" (change)="onFileSelect($event)" accept=".xls,.xlsx,.csv" />
            </label>
          </div>
        </div>
        <p *ngIf="selectedFile" style="margin-top: 30px;">{{ selectedFile.name }}</p>
      </div>
    </div>
  </div>
  <div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
  </div>
  <kendo-dialog-actions style="border: none; ">
    <button kendoButton class="figma-btn-blue figma-popup" (click)="uploadFiles()">Upload</button>
  </kendo-dialog-actions>
</kendo-dialog>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270"  [Data]="alertData"
  (close)="closeAlert()"></app-alert-popup>