import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmployeeReportService {
  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getLimitedColumnExcel(clientID:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/EmployeeMaster/Get_EmployeeMaster_LimitedColumns?ClientID=${clientID}`;
    return this.http.get<Blob>(url,{observe:'response'});
  }
  getMasterReportExcel(clientID:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/EmployeeMaster/Get_EmployeeMasterReport?ClientID=${clientID}`;
    return this.http.get<Blob>(url,{observe:'response'});
  }
  getMonthWiseExcel(EntityId:string,Month:number,Year:number,BranchID:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/EmployeeMaster/GetEmployeeMasterMonthly?EntityId=${EntityId}&Month=${Month}&Year=${Year}&BranchID=${BranchID}`;
    return this.http.get<Blob>(url,{observe:'response'});
  }
  getBranchDropdown(EntityId:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/ClientDetailsManagement/GetBranchDropdownlist?EntityId=${EntityId}`;
    return this.http.get(url, {observe:'response'});
  }
  
}
