import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
export interface ReassignUserList_Dto {
  CustID: number;
  EntityId: string;
  RoleID: number;
  Branch: number;
}

export interface ReassignGrid_Dto {
  CustID: number;
  EntityId: string;
  RoleID: number;
  userID: number;
  selectedBranches: string;
  DocType: string;
  isCLRA: boolean;
}

export interface InstanceGridPayload_Dto {
  entityId: string;
  flag: string;
  year?: number;
  branches?: string;
  month?: string;
}
export interface UniStringObject {
  StringLIst: string;
}
export interface InstanceActivationMap {
  InstanceID: string;
  ActivationDate: string;
}
export interface MissingFormsDTO {
  ComplianceType: string;
  Month: string;
  Year: string;
  EntityID: string;
}

export interface RemoveScheduleDto {
  ScheduleList: string;
  UserID: string;
  ComplianceList: string;
  EntityId: string;
}

export interface DTOReAssignment {
  roleID: number;
  userID: number;
  instanceIDlst: string; 
}

@Injectable({
  providedIn: 'root'
})
export class ManageComplianceService {
  private baseUrl = environment.apiUrl;
  constructor(private http:HttpClient) { }

  getCompliance(type:string):Observable<HttpResponse<any>>{
    let url = `${this.baseUrl}/api/Common/Get_LC_CodeDescription?Type=${type}`;
    return this.http.get<any>(url,{ observe: 'response' })
  }

  getroles(type:string):Observable<HttpResponse<any>>{
    let url = `${this.baseUrl}/api/Common/Get_LC_CodeDescription?Type=${type}`;
    return this.http.get<any>(url,{ observe: 'response' })
  }

  getExistingUserList(reassignUserListDto: ReassignUserList_Dto): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/ManageCompliances/LC_Get_ComplianceReAssignUserList_Existing`;
    return this.http.post<any>(url, reassignUserListDto,{ observe: 'response' });
  }

  getNewUserList(reassignUserListDto: ReassignUserList_Dto): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/ManageCompliances//LC_Get_ComplianceReAssignUserList_New`;
    return this.http.post<any>(url, reassignUserListDto,{ observe: 'response' });
  }
  getReAssignmentGridData(reassignGridDto: ReassignGrid_Dto): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/ManageCompliances/LC_Get_ReAssignmentGridData`;
    return this.http.post<any>(url, reassignGridDto,{ observe: 'response' });
  }

  reassignCompliances(reassignmentData: DTOReAssignment): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/ManageCompliances/ReassignCompliances`;
    return this.http.post<any>(url, reassignmentData,{ observe: 'response' } );
  }

  getComplianceManagementData(payload: InstanceGridPayload_Dto): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/ManageCompliances/LC_Get_ComplianceManagementData`;
    return this.http.post<any>(url, payload, { observe: 'response' });
  }

  removeAssignment(stringList:UniStringObject): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/ManageCompliances/RemoveAssignment`;
    return this.http.post<any>(url,stringList, { observe: 'response' });
  }
  updateActivationDate(instanceActivationList: InstanceActivationMap[]): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/ManageCompliances/UpdateActivationDate`;
    return this.http.post<any>(url, instanceActivationList, { observe: 'response' });
  }

  CreateMissingForms(missingFormsDto: MissingFormsDTO): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/ManageCompliances/LC_Api_MissingForms`;
    return this.http.post<any>(url, missingFormsDto,{ observe: 'response' });
  }

  removeSchedules(scheduleData: RemoveScheduleDto): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/ManageCompliances/RemoveSchedules`;
    return this.http.post<any>(url,scheduleData, { observe: 'response' });
  }
}
