import { Component, OnInit } from '@angular/core';
import { EntitydashboardService } from '../../service/entitydashboard.service';
import { GridComponent } from '../../components/grid/grid.component';
import { UserService } from '../../service/user.service';
import { Router } from '@angular/router';
import { SidebarService } from '../../service/sidebar.service'
import { GridDataResult } from '@progress/kendo-angular-grid';
import { MenuService } from '../../service/menu.service';
import { GridActionItemComponent } from '../../components/grid-action-item/grid-action-item.component';
import { NgIf } from '@angular/common';
import { BlobService } from '../../service/blob.service';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { AlertPopupComponent } from '../popups/alert-popup/alert-popup.component';
import { ClientPortalLoginComponent } from '../popups/client-portal-login/client-portal-login.component';

@Component({
  selector: 'app-entity-dashboard',
  standalone: true,
  imports: [GridActionItemComponent,NgIf,IndicatorsModule,AlertPopupComponent,ClientPortalLoginComponent],
  templateUrl: './entitydashboard.component.html',
  styleUrls: ['./entitydashboard.component.scss']
})
export class EntitydashboardComponent implements OnInit {
  public originalData: any[] = [];
  userId: number = 0;
  userRole!:string
  public gridData: GridDataResult = { data: [], total: 0 };
  public pageSize: number = 10;
  loading=false;
  alertData = { alertMessage: '', alertVisible: '' }
  openClientLogin=false 

  constructor(private router: Router, private blobService: BlobService,private dashboardService: EntitydashboardService, private userService: UserService, private menuService: MenuService, private sidebarService: SidebarService) { }

  ngOnInit(): void {
    this.userId = this.userService.getUserId();
    this.userRole = this.userService.getRole();
    this.updateColumnsBasedOnRole();
    this.loadEntityData();
  }
  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    const filteredData = this.originalData.filter(item =>
      (item.customerName && item.customerName.toLowerCase().includes(filterValue)) ||
      (item.clientId && item.clientId.toString().toLowerCase().includes(filterValue)) ||
      (item.clientName && item.clientName.toString().toLowerCase().includes(filterValue))
    );

    this.gridData = {
      data: filteredData,
      total: filteredData.length
    };
  }
  downloadAnchor(){
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
 
    this.dashboardService.getAnchorExcel(this.userId.toString(),'ALL',currentMonth,currentYear).subscribe(
      {
        next: (response) => {
          const data = response.body;
          const blobLink = data.filePath;
          const blobName = this.blobService.extractBlobName(blobLink);
          setTimeout(() => {
            this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
              debugger;
              const sasToken = tokenResponse.result;
              this.blobService.downloadFile(blobName, sasToken);
              this.loading = false;
            });
          }, 5000);
        }, error: (error) => {
          console.log('masterreport', error);
          this.alertData = { alertMessage: error.error, alertVisible: 'error' }
          this.loading = false;
        }
      });
  }
  downloadEntityLocation(){
    this.dashboardService.getEntityLoactionExcel(this.userId).subscribe(
      {
        next: (response) => {
          const data = response.body;
          const blobLink = data.filePath;
          const blobName = this.blobService.extractBlobName(blobLink);
          setTimeout(() => {
            this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
              debugger;
              const sasToken = tokenResponse.result;
              this.blobService.downloadFile(blobName, sasToken);
              this.loading = false;
            });
          }, 5000);
        }, error: (error) => {
          console.log('masterreport', error);
          this.alertData = { alertMessage: error.error, alertVisible: 'error' }
          this.loading = false;
        }
      });
  }
  loadEntityData(): void {
    this.dashboardService.getEntityList(`${this.userId}`).subscribe({
      next: (response) => {
        if (response.status === 200) {
          const data = response.body;
          this.originalData = data
          this.gridData = {
            data: data,
            total: data.length
          };
        }
        else {
          console.error('Error fetching entity data');
        }
      },
      error: (error) => {
        console.error('Error fetching entity data', error);
      }
    });
  }

  gotoCorporate(){
    this.router.navigate(['/CustomerMaster']);
    this.menuService.setSelectedMenu('');
  }

  handleButtonClick(event: { dataItem: any, button: any }): void {
    debugger;
    if(event.button.action==='Portal Login'){
      this.openClientLogin=true
    }
    this.userService.setCustomerId(event.dataItem.customerId);
    this.userService.setCustomerName(event.dataItem.customerName);
    this.userService.setClientName(event.dataItem.clientName);
    this.userService.setClientId(event.dataItem.clientId);
    sessionStorage.removeItem('headerTrackerId');
    sessionStorage.removeItem('headerTrackerPremiseMaster');
    if (event.button.action === "Dashboard") {
      this.router.navigate(['/ComplianceDashboard']);
    } else if (event.button.action === "ESIC") {
      this.onSidebarLinkClick('ESIC Cards');
      this.router.navigate(['/SubcodeMaster']);
    } else if( event.button.action === 'Statutory Document'){
      this.onSidebarLinkClick('Statutory Document');
      this.router.navigate(['/LCMyComplianceDocumentComponent']);
    } else if( event.button.action === 'Statutory Documents'){
      this.onSidebarLinkClick('Statutory Documents');
      this.router.navigate(['/ClientStatutoryDocument']);
    }else if(event.button.action ==='Premise Master'){
      this.onSidebarLinkClick('Onboard Entity');
      this.router.navigate(['/PremiseMaster']);
    }else if(event.button.action ==='Workspace'){
      this.onSidebarLinkClick('WorkSpace');
      this.router.navigate(['/Workspace']);
    }else if(event.button.action ==='Portal Login'){
 
    }
  }

  onSidebarLinkClick(linkName: string) {
    this.menuService.setSelectedMenu(linkName);
  }
  closeAlert(){
    this.alertData = { alertMessage: '', alertVisible: '' }
  }
  exportToExcel1() {
    debugger;
    this.loading = true;
    this.dashboardService.getEntityLocationMasterBlobLink(this.userId).subscribe({
      next: (res: any) => {
        debugger;
        const blobLink = res.filePath;
        const blobName = this.blobService.extractBlobName(blobLink);
        setTimeout(() => {
          this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
            debugger;
            const sasToken = tokenResponse.result;
            this.blobService.downloadFile(blobName, sasToken);
            this.loading = false;
          });
        }, 5000);
      },
      error: (error: any) => {
        console.error('Error fetching blob link:', error);
        this.loading = false;
      }
    });
  }
  exportToExcel2() {
    debugger;
    this.loading = true;
    this.dashboardService.getEntityMasterBlobLink(this.userId).subscribe({
      next: (res: any) => {
        debugger;
        const blobLink = res.filePath;
        const blobName = this.blobService.extractBlobName(blobLink);
        setTimeout(() => {
          this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
            debugger;
            const sasToken = tokenResponse.result;
            this.blobService.downloadFile(blobName, sasToken);
            this.loading = false;
          });
        }, 5000);
      },
      error: (error: any) => {
        console.error('Error fetching blob link:', error);
        this.loading = false;
      }
    });
  }


  public parentColumns: any[] = [
    { field: 'customerName', title: 'Customer Name',width: '200px' },
    { field: 'clientId', title: 'Entity Id' ,width:'120px'},
    { field: 'clientName', title: 'Entity Name',width:'400px' }
  ];
  updateColumnsBasedOnRole() {
    if (this.userRole === 'HMGR') {
      this.parentColumns.push(
        {
          field: 'Dashboard',
          title: 'Dashboard',
          width: '150px',
          filteredIcons: [
            {
              url: '../assets/vectors/RightArrowButton.svg',
              alt: 'Icon 1',
              action: 'Dashboard',
              class: 'svg-icon'
            }
          ]
        },
        {
          field: 'StatutoryDocuments',
          title: 'Statutory Documents',
          width: '150px',
          filteredIcons: [
            {
              url: '../assets/vectors/RightArrowButton.svg',
              alt: 'Icon 1',
              action: 'Statutory Documents',
              class: 'svg-icon'
            }
          ]
        }
      );
    } else if (this.userRole === 'DADMN') {
      this.parentColumns.push(
        {
          field: 'Dashboard',
          title: 'Dashboard',
          width: '150px',
          filteredIcons: [
            {
              url: '../assets/vectors/RightArrowButton.svg',
              alt: 'Icon 1',
              action: 'Dashboard',
              class: 'svg-icon'
            }
          ]
        },
        {
          field: 'Premise Master',
          title: 'Premise Master',
          width: '150px',
          filteredIcons: [
            {
              url: '../assets/vectors/RightArrowButton.svg',
              alt: 'Icon 1',
              action: 'Premise Master',
              class: 'svg-icon'
            }
          ]
        }
      );
    } else if (this.userRole === 'RVW1') {
      this.parentColumns.push(
        {
          field: 'Portal Login',
          title: 'Portal Login',
          width: '150px',
          filteredIcons: [
            {
              url: '../assets/vectors/EyeIcon.svg',
              alt: 'Icon 1',
              action: 'Portal Login',
              class: 'svg-icon'
            },
          ]
        },
        {
          field: 'Dashboard',
          title: 'Dashboard',
          width: '150px',
          filteredIcons: [
            {
              url: '../assets/vectors/RightArrowButton.svg',
              alt: 'Icon 1',
              action: 'Dashboard',
              class: 'svg-icon'
            }
          ]
        },
        {
          field: 'Statutory Documents',
          title: 'Statutory Documents',
          width: '150px',
          filteredIcons: [
            {
              url: '../assets/vectors/RightArrowButton.svg',
              alt: 'Icon 1',
              action: 'Statutory Document',
              class: 'svg-icon'
            }
          ]
        }
      );
    } else if(this.userRole === 'PERF'){
      this.parentColumns.push(
        {
          field: 'Portal Login',
          title: 'Portal Login',
          width: '150px',
          filteredIcons: [
            {
              url: '../assets/vectors/EyeIcon.svg',
              alt: 'Icon 1',
              action: 'Portal Login',
              class: 'svg-icon'
            },
          ]
        },
        {
          field: 'Dashboard',
          title: 'Dashboard',
          width: '150px',
          filteredIcons: [
            {
              url: '../assets/vectors/RightArrowButton.svg',
              alt: 'Icon 1',
              action: 'Dashboard',
              class: 'svg-icon'
            }
          ]
        },
        {
          field: 'Workspace',
          title: 'Workspace',
          width: '150px',
          filteredIcons: [
            {
              url: '../assets/vectors/RightArrowButton.svg',
              alt: 'Icon 1',
              action: 'Workspace',
              class: 'svg-icon'
            }
          ]
        }
      );
    }
  }
  gotoUser() {
    this.router.navigate(['/UserMaster']);
    this.menuService.setSelectedMenu('');
  }
  closePopup() {
    this.openClientLogin=false 
   }

}

