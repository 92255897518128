import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getClraType(clientID: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Clra/Getclra_type?Avacom_EntityID=${clientID}`;
    return this.http.get(url, { observe: 'response' });
  }

  GetMappedClientHeadersColumn(Type: string, custId: number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Common/GetMappedClientHeadersColumn?TableName=${Type}&CustID=${custId}`;
    return this.http.get<any>(url, { observe: 'response' });
  }

}
