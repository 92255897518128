import { Component, OnInit } from '@angular/core';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { CommonModule, NgIf } from '@angular/common';
import { KendoDatepickerComponent } from '../../../components/kendo-datepicker/kendo-datepicker.component';
import { DropDownsModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { LabelModule } from '@progress/kendo-angular-label';
import { ComplianceAssignmentService } from '../../../service/compliance-assignment.service';
import { UserService } from '../../../service/user.service';
import { GridModule } from '@progress/kendo-angular-grid';
import { FormsModule } from '@angular/forms';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { PageChangeEvent } from '@progress/kendo-angular-treelist';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
type Branch = {
  id: number;
  name: string;
  parentID: number | null;
  branchType: string;
  branchStatus: string;
  fullName: string | null;
  children?: Branch[];
};

@Component({
  selector: 'app-compliance-assignment',
  standalone: true,
  imports: [DropdownListComponent, CommonModule,NgIf, IndicatorsModule, KendoDatepickerComponent, LabelModule, MultiSelectModule, TreeViewModule, GridModule, DropDownsModule, FormsModule, AlertPopupComponent],
  templateUrl: './compliance-assignment.component.html',
  styleUrl: './compliance-assignment.component.css'
})
export class ComplianceAssignmentComponent implements OnInit {
  public defaultFilterValue = { text: 'Select Compliance Type', value: '' };
  public ChallandefaultFilterValue = { text: 'Select Type', value: '' };
  public ChallandefaultFilterPerformer = { text: 'Select Performer', value: '' };
  public ChallandefaultFilterReviewer = { text: 'Select Reviewer', value: '' };
  alertData = { alertMessage: '', alertVisible: '' };
  challanTypeShow = false;
  PerformerdropdownData: any[] = [];
  ReviewerdropdownData: any[] = [{ text: 'Select Reviewer', value: '' }];
  public gridData: any ={data:[],total:0};
  selectedItems: any[] = [];
  allSelected: boolean = false;
  selectedChallanType = ''
  selectedComplianceType = ''
  showSave = false;
  Performer = { text: '', value: 0 };
  Reviewer = { text: '', value: 0 };
  popupSettings: any = { width: '280px' }; 
  dropdownData = [
    { text: 'Select Compliance Type', value: '' },
    { text: 'Challan', value: 'Challan' },
    { text: 'Register', value: 'Register' },
    { text: 'Return', value: 'Return' }
  ];
  ChallanTypedropdownData = [
    { text: 'Select Type', value: '' },
    { text: 'EPF', value: 'EPF' },
    { text: 'ESI', value: 'ESI' },
    { text: 'PT', value: 'PT' }
  ];
  public tagMapper(tags: any[]): any[] {
    return tags.length < 3 ? tags : [tags];
  }
  loadingGrid=false;
  public placeholder: string = 'DD-MM-YYYY';
  todayDate: Date = new Date();
  selectedDate: Date| null = null;
  DateOfExempting: Date | null = null;
  showValidationErrors = false;
  showValidationErrorsSave = false;
  public BranchDropdown: Branch[] = [];
  commaSeparatedIds: string = '';
  private clientID = '';
  private custID!: number;
  take = 10;
  skip = 0;
  page = 1;
  loading = false;
  public originalData: any[] = [];
  public pageSize: number = 10;
  public buttonCount = 2;
  public sizes = [{text:'All',value:1},5, 10, 20];
  constructor(private user: UserService, private ComplianceAssignmentService: ComplianceAssignmentService) { }
  ngOnInit(): void {
    this.clientID = this.user.getClientId();
    this.custID = this.user.getCustomerId();
    this.getReviewerDropdownData()
    this.getPerformerDropdownData()
  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;    
    this.page = (this.skip / this.pageSize) + 1;
    if(event.take==1){
      this.pageSize = this.gridData.total;
      this.gridData=this.originalData;      
      return;
    }
    else{
      this.pageSize = event.take;
      this.gridData.data= this.originalData.slice(this.skip, this.skip + this.pageSize);
    }
       this.loadGridData(); 
  }
  get paginatedData(): any[] {
    return this.gridData.slice(this.skip, this.skip + this.pageSize); 
  }
  loadGridData() {
    this.loadingGrid=true;
    this.selectedItems = [];
    this.ComplianceAssignmentService.getGridData(this.custID, this.clientID, this.selectedComplianceType, this.selectedChallanType, this.commaSeparatedIds, false).subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && data && Array.isArray(data)) {
            this.originalData = data; 
            this.gridData = {
              data: this.originalData.slice(this.skip, this.skip + this.pageSize), 
              total: data.length
            };
            this.loadingGrid = false;
          } else {
          this.loadingGrid = false;
          console.log('Unexpected API response format:', data);
        }
      }, error: (error) => {
        this.loadingGrid = false;
        console.error('Error fetching grid data:', error);
      }
    });
  }
  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.gridData = this.originalData.filter(item => {
        return (
            (item.shortForm && item.shortForm.toLowerCase().startsWith(filterValue)) ||
            (item.id && item.id.toString().toLowerCase().startsWith(filterValue))||
            (item.actName && item.actName.toString().toLowerCase().startsWith(filterValue))
        );
    });
}
  getBranchDropdownData() {
    this.ComplianceAssignmentService.getBranchDropdown(this.clientID).subscribe({
        next: (response) => {
            const data = response.body;
            if (response && Array.isArray(data)) {
                this.BranchDropdown = this.buildHierarchy(data);
              console.log(this.BranchDropdown);
              
                const maxLength = this.calculateMaxTextLength(this.BranchDropdown);
                
                const calculatedWidth = Math.max(280, maxLength * 10); 
                this.popupSettings = { width: `${calculatedWidth}px` };
            } else {
                console.error('Unexpected data format', response);
            }
        },
        error: (error) => {
            console.error('Error fetching Branch Dropdown data', error);
        }
    });
}
calculateMaxTextLength(data: Branch[]): number {
  let maxLength = 0;
  const findMaxLength = (items: Branch[]) => {
      items.forEach(item => {
          maxLength = Math.max(maxLength, item.name.length);
          if (item.children && item.children.length) {
              findMaxLength(item.children); 
          }
      });
  };

  findMaxLength(data);
  return maxLength;
}

getPerformerDropdownData() {
  this.ComplianceAssignmentService.getPerformerDropdown(this.custID, this.clientID, 3).subscribe({
    next: (response) => {
      const data = response.body;
      this.PerformerdropdownData = [
        { text: 'Select Performer', value: null }, 
        ...data.map((item: any) => ({
          text: `${item.firstName} ${item.lastName}`,
          value: item.id
        }))
      ];
    },
    error: (error) => {
      console.error('Error fetching Dropdown data', error);
    }
  });
}

  getReviewerDropdownData() {
    this.ComplianceAssignmentService.getReviewerDropdown(this.custID, this.clientID, 4).subscribe(
      {
        next: (response) => {
          const data = response.body;
          this.ReviewerdropdownData =[ { text: 'Select Reviewer', value: null },
            ...data.map((item: any) => ({
              text: item.firstName,
              value: item.id
            }))]
        }, error: (error) => {
          console.error('Error fetching Branch Dropdown data', error);
        }
      });
  }
  buildHierarchy(data: Branch[]): Branch[] {
    const grouped: { [key: number]: Branch[] } = data.reduce((acc, item) => {
      (acc[item.parentID || -1] = acc[item.parentID || -1] || []).push(item);
      return acc;
    }, {} as { [key: number]: Branch[] });
    function buildTree(parentID: number | null): Branch[] {
      return (grouped[parentID || -1] || []).map(item => ({
        ...item,
        children: buildTree(item.id)
      }));
    }
    return buildTree(null);
  }
  OnMultiSelectChange(value: any) {
    if (Array.isArray(value)) {
      this.commaSeparatedIds = value.map(item => item.id).join(',');
    } else {
      console.error('Expected an array of selected items.');
    }
  }

  onDropdownValueChange(value: any) {
    if (value.value == 'Challan') {
      this.getBranchDropdownData();
      this.selectedChallanType='';
      this.challanTypeShow = true;
      this.selectedComplianceType = value.value;
    } else if (value.value == 'Register') {
      this.getBranchDropdownData();
      this.selectedChallanType='';
      this.challanTypeShow = false;
      this.selectedComplianceType = value.value;
    } else if (value.value == 'Return') {
      this.getBranchDropdownData();
      this.selectedChallanType='';
      this.challanTypeShow = false;
      this.selectedComplianceType = value.value;
    } else {
      this.BranchDropdown=[];
      this.challanTypeShow = false;
    }
  }
  toggleSelectAll(event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    this.gridData.data.forEach((item:any) => item.selected = checked);
  }
  onItemSelectionChange(dataItem: any) {
    if (dataItem.selected) {
      if (!this.selectedItems.includes(dataItem)) {
        this.selectedItems.push(dataItem);
      }
    } else {
      const index = this.selectedItems.indexOf(dataItem);
      if (index > -1) {
        this.selectedItems.splice(index, 1);
      }
    }
    this.showSave = this.selectedItems.length > 0;
  }

  onDropdownValueChangeChallanType(value: any) {
    this.selectedChallanType = value.value;
  }
  onDateChange(value: Date) {
    const date = new Date(value.toISOString().split('T')[0]);
    this.selectedDate = date
  }
  // formatDate(dateString: string): string {
  //   const date = new Date(dateString);

  //   const day = date.getUTCDate().toString().padStart(2, '0');
  //   const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  //   const year = date.getUTCFullYear().toString();

  //   return `${day}/${month}/${year}`;
  // }
  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear().toString();

    return `${day}-${month}-${year}`;
}
  applyAction() {
    this.showValidationErrors=true;
    if (this.selectedComplianceType == 'Challan') {
      if (this.selectedChallanType && this.commaSeparatedIds && this.selectedDate) {
        this.loadGridData();
        return;
      } else {
        if(!this.selectedChallanType && !this.commaSeparatedIds && !this.selectedDate){
          this.alertData = { alertMessage: 'Please Select the Challan Type ,Branch and Date', alertVisible: 'error' };
        }else if( !this.commaSeparatedIds && !this.selectedDate){
          this.alertData = { alertMessage: 'Please Select the Branch and Date', alertVisible: 'error' };
        }else if( !this.selectedChallanType && !this.selectedDate){
          this.alertData = { alertMessage: 'Please Select the Challan Type and Date', alertVisible: 'error' };
        }else if( !this.selectedChallanType){
          this.alertData = { alertMessage: 'Please Select the Challan Type', alertVisible: 'error' };
        }else if( !this.commaSeparatedIds){
          this.alertData = { alertMessage: 'Please Select the Branch', alertVisible: 'error' };
        }else if( !this.selectedDate){
          this.alertData = { alertMessage: 'Please Select the Date', alertVisible: 'error' };
        }
        return;
      }
    } else if (this.selectedComplianceType != 'Challan' && this.commaSeparatedIds && this.selectedDate) {
      this.loadGridData();
    } else {
      if(!this.selectedComplianceType && !this.commaSeparatedIds && !this.selectedDate){
        this.alertData = { alertMessage: 'Please Select Compliance Type , Entity-Branch and Date ', alertVisible: 'error' };
      }else if( !this.commaSeparatedIds && !this.selectedDate){
        this.alertData = { alertMessage: 'Please Select the Branch and Date', alertVisible: 'error' };
      }else if( !this.selectedComplianceType && !this.selectedDate){
        this.alertData = { alertMessage: 'Please Select the Compliance Type and Date', alertVisible: 'error' };
      }else if( !this.selectedComplianceType){
        this.alertData = { alertMessage: 'Please Select the Compliance Type', alertVisible: 'error' };
      }else if( !this.commaSeparatedIds){
        this.alertData = { alertMessage: 'Please Select the Branch', alertVisible: 'error' };
      }else if( !this.selectedDate){
        this.alertData = { alertMessage: 'Please Select the Date', alertVisible: 'error' };
      }
    }
  }
  ClearAction(multiselectTree: any, searchInput: HTMLInputElement) {
    this.showSave=false;
    this.showValidationErrorsSave=false;
    this.showValidationErrors=false;
    this.selectedComplianceType='';
    this.selectedChallanType='';
    this.Performer.value=0;
    this.Reviewer.value=0;
    this.selectedDate=null;
    this.gridData=[];
    this.commaSeparatedIds='';
    this.selectedItems=[]
    multiselectTree.value = [];
    multiselectTree.reset();
    this.DateOfExempting=null;
    this.defaultFilterValue = { text: 'Select Compliance Type', value: '' }
    this.ChallandefaultFilterValue = { text: 'Type', value: '' };
    this.ChallandefaultFilterPerformer = { text: 'Select Performer', value: '' };
    this.ChallandefaultFilterReviewer = { text: 'Select Reviewer', value: '' };
    searchInput.value = '';    
  }
  onDropdownValueChangeReviewer(value: any) {
    this.Reviewer = { text: value.text, value: value.value };
  }
  onDropdownValueChangePerformer(value: any) {
    this.Performer = { text: value.text, value: value.value };
  }
  onActionClick(event: any): void {
    debugger
    console.log('delete user branch', event);
    const formattedDate = this.DateOfExempting ? this.formatDate(this.DateOfExempting.toString()) : 'Unknown Date';
        this.alertData = { 
          alertMessage: `Activation Date of Selected Compliance is - ${this.formatDate(formattedDate)}, Please Click on OK to continue!`, 
          alertVisible: 'confirm' 
      };
  }
  removeAssignedItems() {
    this.originalData = this.originalData.filter(item =>
        !this.selectedItems.some(selected => selected.id === item.id && selected.branchId === item.branchId)
    );
    this.gridData = {
        data: this.originalData.slice(this.skip, this.skip + this.pageSize),
        total: this.originalData.length
    };
}
  // OnSave() {
  //   this.loading=true;
  //   //this.showValidationErrorsSave=true;
  //   const body = {
  //     complianceType: this.selectedComplianceType,
  //     challanType: this.selectedChallanType || '',
  //     clientID: this.clientID,
  //     performerID: this.Performer.value,
  //     reviewerID: this.Reviewer.value,
  //     selectedBranch: this.commaSeparatedIds,
  //     loginUserID: this.custID,
  //     complianceDate:  this.selectedDate ? this.formatDate(this.selectedDate.toISOString()) : '',
  //     _ComplianceIDList: this.selectedItems.map(item => `${item.id}-${item.branchId}`)
  //   }
  //   if (this.Performer.value && this.Reviewer.value && this.selectedItems && this.selectedDate
  //     && this.selectedItems) {
  //     this.ComplianceAssignmentService.SaveComplianceAssignment(body).subscribe(
  //       {
  //         next: (response) => {
  //           if(response.status==200){
  //             this.loading=false
  //             this.alertData = { alertMessage: 'Compliance Assigned Successfully!', alertVisible: 'success' };
  //             this.loadGridData();
  //           }else{
  //             this.loading=false
  //             this.alertData = { alertMessage: 'Compliance Update Fail', alertVisible: 'error' };
  //           }
  //         }, error: (error) => {
  //           console.error('Error fetching  Dropdown data', error);
  //           this.loading=false
  //           this.alertData = { alertMessage: 'Compliance Update Fail', alertVisible: 'error' };
  //         }
  //       });
  //     }else{
  //     this.loading=false
  //     if(!this.Performer.value && !this.Reviewer.value && !this.selectedItems){
  //       this.alertData = { alertMessage: 'Please Select the Performer and Reviewer', alertVisible: 'error'};
  //     }else if(!this.Performer.value){
  //       this.alertData = { alertMessage: 'Please Select the Performer', alertVisible: 'error'};
  //     }else if(!this.Reviewer.value){
  //       this.alertData = { alertMessage: 'Please Select the Reviewer', alertVisible: 'error'};
  //     }else if(!this.selectedItems){
  //       this.alertData = { alertMessage: 'Please Select Grid Columns', alertVisible: 'error'};
  //     }
  //   }
  // }
  OnSave() {
    this.loading = true;
  
    const body = {
      complianceType: this.selectedComplianceType,
      challanType: this.selectedChallanType || '',
      clientID: this.clientID,
      performerID: this.Performer.value,
      reviewerID: this.Reviewer.value,
      selectedBranch: this.commaSeparatedIds,
      loginUserID: this.custID,
      complianceDate: this.selectedDate ? this.formatDate(this.selectedDate.toISOString()) : '',
      _ComplianceIDList: this.selectedItems.map(item => `${item.id}-${item.branchId}`)
    };
    if (this.Performer.value && this.Reviewer.value && this.selectedItems && this.selectedDate) {
      this.ComplianceAssignmentService.SaveComplianceAssignment(body).subscribe({
        next: (response) => {
          if (response.status === 200) {
            this.loading = false;
            this.alertData = { alertMessage: 'Compliance Assigned Successfully!', alertVisible: 'success' };
            this.removeAssignedItems();
            //this.loadGridData();
          } else {
            this.loading = false;
            this.alertData = { alertMessage: 'Compliance Update Fail', alertVisible: 'error' };
          }
        },
        error: (error) => {
          console.error('Error fetching Dropdown data', error);
          this.loading = false;
          this.alertData = { alertMessage: 'Compliance Update Fail', alertVisible: 'error' };
        }
      });
    } else {
      this.loading = false;
      if (!this.Performer.value && !this.Reviewer.value && !this.selectedItems) {
        this.alertData = { alertMessage: 'Please Select the Performer and Reviewer', alertVisible: 'error' };
      } else if (!this.Performer.value) {
        this.alertData = { alertMessage: 'Please Select the Performer', alertVisible: 'error' };
      } else if (!this.Reviewer.value) {
        this.alertData = { alertMessage: 'Please Select the Reviewer', alertVisible: 'error' };
      } else if (!this.selectedItems) {
        this.alertData = { alertMessage: 'Please Select Grid Columns', alertVisible: 'error' };
      }
    }
  }
  closeAlert() {
    this.alertData = { alertMessage: '', alertVisible: '' };
  }
}
