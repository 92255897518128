import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DesignationMasterService } from '../../../service/designation-master.service';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule, NgClass, NgIf } from '@angular/common';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { GridModule, PageChangeEvent } from '@progress/kendo-angular-grid';
import { KendoDatepickerComponent } from '../../../components/kendo-datepicker/kendo-datepicker.component';
import { UserService } from '../../../service/user.service';
import { GstApprovalService } from '../../../service/gst-approval.service';
import { EcrReportService } from '../../../service/ecr-report.service';


@Component({
  selector: 'app-gst-approval-update-popup',
  standalone: true,
  imports: [KendoDatepickerComponent, GridModule, NgIf, DialogModule, DropdownListComponent, ReactiveFormsModule, AlertPopupComponent, CommonModule, NgClass, IndicatorsModule],
  templateUrl: './gst-approval-update-popup.component.html',
  styleUrl: './gst-approval-update-popup.component.css'
})
export class GstApprovalUpdatePopupComponent {


  @Output() close = new EventEmitter<void>();
  @Input() columns: any[] = [];
  userForm!: FormGroup;
  @Input() data: any;
  @Input() uniqueId: any;
  @Input() IsUpdate!: boolean;
  showPassword = false;
  passValue: string = '';
  alertData = { alertMessage: '', alertVisible: '' }
  usernameExists: boolean = false;
  clientId: string = '';
  @Input() dialogTitles: string = 'Add/Edit ESIC Subcode';

  consolidateDOB: Date | null = null;
  public placeholder: string = 'DD-MM-YYYY';
  todayDate: Date = new Date();
  date: any
  clientID!: string;

  constructor(private fb: FormBuilder, private user: UserService, private GstApprovalService: GstApprovalService, private EcrReportService: EcrReportService) {
    this.userForm = this.fb.group({
      poDate: ['', Validators.required],
      poNumber: ['', [Validators.required, Validators.maxLength(15)]],

    });
  }

  @ViewChild('dialogTitle') dialogTitle!: ElementRef;
  ngAfterViewInit() {
    const titleElement = this.dialogTitle.nativeElement;
    titleElement.style.textAlign = 'center';
    titleElement.style.width = '100%';
  }

  ngOnInit(): void {
    // if (this.columns.length > 3) {
    //   console.log('Grid', this.columns[3].filteredIcons[0].action);
    // }
    // if (this.data && this.IsUpdate==true) {

    //   this.initUser();
    // }
    // else{
    //   this.userForm.patchValue({
    //     clientid:'',
    //     username: '',
    //     password: ''
    //   });
    // }
  }
  // initUser() {
  //   debugger
  //   this.userForm.patchValue(this.data);
  //   this.passValue = this.data?.originalPassword || '';
  //   this.clientId =this.data?.clientID || '';
  //   this.userForm.get('originalPassword')?.setValue(this.passValue);
  //   this.userForm.get('clientid')?.setValue(this.clientId);
  // }
  checkUserId(event: FocusEvent) {
    debugger;
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    if (this.data.username != inputValue) {

      this.EcrReportService.isUserIdExists(inputValue).subscribe((res: any) => {
        debugger;
        this.usernameExists = res.body.result;
        if (this.usernameExists) {
          this.userForm.get('poNumber')?.setErrors({ usernameExists: true });
        } else {
          this.userForm.get('poNumber')?.setErrors(null);
        }
      });
    }
  }
  onSubmit() {
    debugger
    // if (this.userForm.valid) {
    const date = new Date(this.date);
    const payload = {
      "poDate": date.toISOString(),
      "poNumber": this.userForm.value.poNumber,
      "userId": String(this.user.getUserId()),
      "uniqueId": this.uniqueId

    }
    console.log(payload, 'payloaddd')

    this.GstApprovalService.UpdateGSTDetail(payload).subscribe({
      next: (response: any) => {
        debugger
        console.log('Response', response);

        // if (response.body.result == "errorMessage") {
        //   if (this.data) {
        //     this.alertData = { alertMessage: 'User Updated successfully', alertVisible: 'errorMessage' }

        //   } else {
        //     this.alertData = { alertMessage: 'User Added successfully', alertVisible: 'errorMessage' }
        //   }
        // }
        // else {
        //   this.alertData = { alertMessage: 'Error Occurred', alertVisible: 'error' }
        // }

        if (response.body.errorMessage == 'Updated Succesfully') {
          this.alertData = { alertMessage: 'Updated successfully', alertVisible: 'success' }
        

        } else {
          this.alertData = { alertMessage: 'Error Occurred', alertVisible: 'errorMessage' }
        }

      },
      error: (error: any) => {
        console.error('Error submitting user data:', error);
      }
    });
    // }
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
    const input = document.querySelector('input[formControlName="password"]') as HTMLInputElement;
    if (input) {
      input.type = this.showPassword ? 'text' : 'password';
      input.value = this.passValue;


      setTimeout(() => {
        input.type = 'password';
        this.showPassword = false
      }, 5000);
    }
  }

  // formatPassword(event: Event): void {
  //   const input = event.target as HTMLInputElement;
  //   if (!input) return;
  //   this.passValue = input.value;
  //   this.userForm.get('password')?.setValue(this.passValue);

  //   input.value = this.showPassword
  //     ? this.passValue
  //     : this.maskPassword(this.passValue);
  // }

  // maskPassword(password: string): string {

  //   return password;
  // }


  closePopup() {
    this.data = undefined;
    this.close.emit();
  }
  onDateChange(value: Date) {
    console.log(value, 'valeeeeeeeeeee')
    this.date = value
    //     console.log('valueddsv', value)
    //     console.log('valueddsv', typeof (value))
    //     console.log('dataItem', dataItem)
    //     console.log('spiltpoAmount', this.spiltpoAmount)
    //     console.log('spiltpoNumbers', this.spiltpoNumbers)
    //     console.log('dataformat', typeof (dataItem.pO_ConsolidatedDate))
    //     const date = new Date(value);
    //     if (field === 'poDate') {
    //       this.dateOfBirth = date;
    //     }
    //    else if (field === 'consolidateDOB') {
    //       this.purchaseOrderDate = date;
    //   } else if (field === 'consolidatefrom') {
    //       this.effectiveFromDate = date;
    //   } else if (field === 'consolidateto') {
    //       this.effectiveToDate = date;
    //   }
    //  else if (field === 'recurringPODate') {
    //     this.recurringPODate = date;
    //     console.log('Recurring PO Date:', this.recurringPODate);
    // } else if (field === 'liasoningPODate') {
    //     this.liasoningPODate = date;
    //     console.log('Liasoning PO Date:', this.liasoningPODate);
    // } else if (field === 'recurringFromDate') {
    //     this.recurringFromDate = date;
    //     console.log('Recurring From Date:', this.recurringFromDate);
    // } else if (field === 'liasoningFromDate') {
    //     this.liasoningFromDate = date;
    //     console.log('Liasoning From Date:', this.liasoningFromDate);
    // } else if (field === 'recurringToDate') {
    //     this.recurringToDate = date;
    //     console.log('Recurring To Date:', this.recurringToDate);
    // } else if (field === 'liasoningToDate') {
    //     this.liasoningToDate = date;
    //     console.log('Liasoning To Date:', this.liasoningToDate);
    // }

  }
}
