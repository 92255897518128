import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { CheckBoxModule, NumericTextBoxModule, TextAreaModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { LocationMasterServiceService } from '../../../service/location-master-service.service';
import { PremiseMasterService, Temp_LocationMaster } from '../../../service/premise-master.service';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ComboBoxModule, DropDownListModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { KendoDatepickerComponent } from '../../../components/kendo-datepicker/kendo-datepicker.component';
import { NgClass, NgIf } from '@angular/common';
import { UserService } from '../../../service/user.service';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { AddShiftMasterDetailsComponent } from '../add-shift-master-details/add-shift-master-details.component';
import { isVisible } from '@progress/kendo-angular-common';
import { PremiseMasterComponent } from '../../onboard-entity/premise-master/premise-master.component';

@Component({
  selector: 'app-premise-master-edit-popup',
  standalone: true,
  imports: [DialogModule, AlertPopupComponent, NgClass, ReactiveFormsModule, TextBoxModule, CheckBoxModule, TextAreaModule, ComboBoxComponent, DropDownListModule, KendoDatepickerComponent, MultiSelectModule, NgIf, NumericTextBoxModule, FormsModule, DropdownListComponent, AddShiftMasterDetailsComponent,ComboBoxModule],
  templateUrl: './premise-master-edit-popup.component.html',
  styleUrl: './premise-master-edit-popup.component.css'
})

export class PremiseMasterEditPopupComponent implements OnInit {
  @Input() branchId: any;
  rowData: any;
  form!: FormGroup;
  role:string=''
  public openShiftDetails: boolean = false
  public defaultState = "Select State";
  public defaultPTState = "Select State";
  public defaultLWFState = "Select State";
  public placeholder: string = 'DD-MM-YYYY';
  selectedState: { id: string; name: string } | null ={ id: 'Select State', name: 'Select State' }
  public selectedLocation: { id: string; name: string } | null = null
  public selectedCity?: any
  selectedLwfState: { id: any, name: any } = { id: '', name: '' }
  selectedPtState: { id: any, name: any } = { id: '', name: '' }
  public allStatesData: any[] = [];
  public defaultLocation = "Select Location";
  public allLocationData: any[] = [];
  public allAnchorData: any[] = [];
  public selectedAnchor: { id: string; name: string } | null = null
  public defaultAnchor = "Select Location Anchor";
  public selectedDays: { text: any; value: any }[] = [];
  public formattedValues: string = ''
  public rcValidFrom!: Date | null
  public rcValidTo!: Date | null
  public branchEndDate!: Date | null
  public commencementDate!: Date | null
  public Entity: string = ''
  public branchCode: string = ''
  public branchName: string = ''
  public branchAddress: string = ''
  public employerName: string = ''
  public employerAddress: string = ''
  public managerName: string = ''
  public managerAddress: string = ''
  public companyPhnNo: string = ''
  public hrPhnNo: string = ''
  public natureOfBusiness: string = ''
  public municipality: string = ''
  public locationCode: string = ''
  public workHoursFrom: string = 'Select'
  public workHoursTo: string = 'Select'
  public intervalsFrom: string = 'Select'
  public intervalsTo: string = 'Select'
  public pfCode: string = ''
  public ESICCode!: string;
  originalESIC!: string;
  public hrContactPerson: string = ''
  public hrMailId: string = ''
  public nicCode: string = ''
  public licenceNo: string = ''
  public classificationOfEst: string = ''
  public lin: string = ''
  public hrFirstMail: string = ''
  public hrFirstPhnNo: string = ''
  public rcNo: string = ''
  public employerDesignation: string = ''
  public jurisdiction: string = ''
  public district: string = ''
  public defaultSelectedSelectedState: string = ''
  cateringApplicability: boolean = false;
  showValidation: boolean = false;
  public noOfEmployee: number = 0
  errorMessage: string = '';
  defaultItem = { text: 'Select', value: null };
  selectedOfficeType: any = { text: 'Select', value: '' }
  selectedEstablishmentType: any = { text: 'Select', value: '' }
  selectedBusinessType = { text: 'Select', value: '' }
  SelectedPowerToImpose = { text: 'Select', value: '' }
  selectedMaintainType = { text: 'Select', value: '' }
  selectedSectionOfAct = { text: 'Select', value: '' }
  selectedStatusType = { text: 'Select', value: '' };
  selectedTradeLicence = { text: 'Select', value: '' }
  selectedEDLI = { text: 'Select', value: '' }
  public selectedDaysFormatted: string = '';
  officeTypes = [
    { text: 'Select', value: '' },
    { text: 'Branch', value: 'BRANCH' },
    { text: 'Factory', value: 'FACTORY' },
    { text: 'Head Office', value: 'HEADOFFICE' }
  ];
  establishmentTypes = [
    { text: 'Select', value: '' },
    { text: 'Shop & Establishment', value: 'SEA' },
    { text: 'Factory', value: 'FACT' },
    { text: 'Both', value: 'SF' }
  ];
  public weekDays: { text: string, value: string }[] = [
    { text: 'Monday', value: 'MON' },
    { text: 'Tuesday', value: 'TUE' },
    { text: 'Wednesday', value: 'WED' },
    { text: 'Thursday', value: 'THU' },
    { text: 'Friday', value: 'FRI' },
    { text: 'Saturday', value: 'SAT' },
    { text: 'Sunday', value: 'SUN' }
  ];
  public filteredWeekDays = [...this.weekDays];

  public workTimings: { text: string, value: string }[] = [
    { text: 'Select', value: '' },
    { text: 'Shift', value: 'SHIFT' },
    { text: 'Standard', value: 'STANDARD' }
  ];
  public selectedWorkTiming: any = { text: '', value: '' }
  timeIntervals: string[] = [];
  public businessTypes: { text: string, value: string }[] = [
    { text: 'Select', value: '' },
    { text: 'IT', value: 'IT' },
    { text: 'ITES', value: 'ITES' },
    { text: 'NON-IT', value: 'NON-IT' }
  ];

  public Types: { text: string, value: string }[] = [
    { text: 'Select', value: '' },
    { text: 'YES', value: 'Y' },
    { text: 'NO', value: 'N' }
  ];

  public sectionOfActOptions: { text: string, value: string }[] = [
    { text: 'Select', value: '' },
    { text: '2m(i)', value: '2m(i)' },
    { text: '2m(ii)', value: '2m(ii)' },
    { text: 'Section-85', value: 'Section-85' }
  ];
  public statusType: { text: string, value: string }[] = [
    { text: 'Select', value: '' },
    { text: 'Active', value: 'A' },
    { text: 'Inactive', value: 'I' }
  ];
  @Output() close = new EventEmitter<void>();
  public showValidationErrors = false;
  alertData = { alertMessage: '', alertVisible: '' }

  showpfCode = false;
  originalpfCode = '';
  showESICCode = false;
  esicCodeValue: string = '';
  maskedESIC: string = '';

  constructor(private locationMaster: LocationMasterServiceService, private cdr: ChangeDetectorRef, private premiseMasterService: PremiseMasterService, private user: UserService, private fb: FormBuilder,private premise:PremiseMasterComponent) {

    this.form = this.fb.group({
      branchName: [{ value: '', disabled: true }],
      branchAddress: ['', [Validators.required]],
      state: ['', [Validators.required]],
      location: [this.selectedLocation?.name, []],
      pfCode: ['', [Validators.minLength(22), Validators.maxLength(22), Validators.pattern(/^[a-zA-Z0-9]{1,22}$/)]],
      ESICCode: ['', [Validators.minLength(17), Validators.maxLength(17)]],
      employerName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]],
      employerAddress: ['', [Validators.required]],
      managerName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]],
      managerAddress: ['', [Validators.required]],
      companyPhnNo: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      hrMailId: ['', [Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]],
      hrContactPerson: ['', [Validators.pattern(/^[a-zA-Z\s]*$/)]],
      hrPhnNo: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      hrFirstMail: ['', [Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]],
      hrFirstPhnNo: ['', [Validators.pattern(/^\d{10}$/)]],
      natureOfBusiness: ['', [Validators.required]],
      rcNo: ['', [Validators.pattern(/^\d{10}$/)]],
      municipality: ['', [Validators.required]],
      weekoffday: ['', [Validators.required]],
      nicCode: ['', [Validators.pattern('^[a-zA-Z0-9]*$')]],
      classificationOfEst: ['', [Validators.pattern(/^[a-zA-Z\s]*$/)]],
      district: ['', [Validators.pattern(/^[a-zA-Z\s]*$/)]],
      status: ['', [Validators.required]],
      anchor: ['', [Validators.required]],
      designation: [''],
      edli: [''],
      noOfEmp: [''],
      jurisdiction: [''],
      rcNumber: [''],
      locationCode: [''],
      lin: [''],
      licenceNo: [''],
    });

  }
  ngOnInit() {
    this.role=this.user.getRole();
    console.log('Role',this.role);
    if(this.role==='PERF' || this.role==='RVW1')
    {
      this.form.disable()
    }
    else{
      this.form.enable()
    }
    this.fetchStateMasters();
    this.fetchAnchor()
    this.fetchLocation()
    this.generateTimeIntervals()
    this.InitData();
   

   
    this.selectedLocation = this.allLocationData.find(type => type.id === this.rowData.cM_City) || { id: '', name: '' };
  
    this.updateESICDisplay();
    this.updatePFDisplay();
  }
  
  allowOnlyLettersAndSpaces(event: KeyboardEvent): void {
    const regex = /^[a-zA-Z\s]*$/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!regex.test(inputChar)) {
      event.preventDefault();
    }
  }
  blockInvalidCharacters(event: KeyboardEvent): void {
    const allowedKeys = ['Backspace', 'Tab', 'Enter', 'Escape', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Home', 'End'];

    if (allowedKeys.indexOf(event.key) !== -1) {
      return;
    }

    const isLetterOrSpace = /^[a-zA-Z\s]*$/.test(event.key);
    if (!isLetterOrSpace) {
      event.preventDefault();
    }
  }
  validateMobileNo(event: any): void {
    const input = event.target.value;
    event.target.value = input.replace(/[^0-9]/g, '');
    this.form.get('mobileNo')?.setValue(event.target.value);
  }

  initializeESICCode(esicNumber: number | null | undefined): number {
    if (esicNumber == null) {
      return 0;
    }
    this.originalESIC=esicNumber.toString();
    return esicNumber;
  }
  // formatESICCode(event: Event): void {
  //   const input = event.target as HTMLInputElement;
  //   const value = input.value.replace(/[^0-9]/g, '');
  //   this.originalESIC = value;
  //   this.updateESICDisplay();
  // }
  toggleESICCodeVisibility(): void {
    this.showESICCode = !this.showESICCode;
    this.updateESICDisplay();
  }
  formatESICCode(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input) {
      return;
    }
    let value = input.value ? input.value.replace(/[^0-9]/g, '') : '';
    this.originalESIC = value;
    if (value.length === 17) {
      this.showESICCode = false;
      this.updateESICNumberDisplay();
    } else {
      this.showESICCode = true;
      this.updateESICNumberDisplay();
    }
  }
  maskESICCode(esicCode: string): string {
    if (!esicCode) {
      return '';
    }

    const esicString = esicCode.toString();
 
    if (esicString.length === 17) {
      setTimeout(() => {
        if (this.ESICCode.length === 17) {
        this.showESICCode = false;
        this.updateESICNumberDisplay();
        }
      }, 5000);
      this.form.get('ESICCode')?.disable();
      return esicString.replace(/^(.{13})(.{4})$/, 'XXXXXXXXXXXXX$2');
    }

    return esicString;
  }
 
  updateESICDisplay(): void {
    const esicControl = this.form.get('ESICCode');
    esicControl?.setValue(
      this.showESICCode ? this.originalESIC : this.maskESICCode(this.originalESIC)
    );
  }
  updateESICNumberDisplay(): void {
    const currentESICNumber = this.form.get('ESICCode');
    if (currentESICNumber) {
      if (this.showESICCode) {
        currentESICNumber.setValue(this.originalESIC);
        currentESICNumber.enable();
      } else {
        const maskedESIC = this.maskESICCode(this.originalESIC);
        currentESICNumber.setValue(maskedESIC);
      }
    }
  }

  initializepfCode(pfCode: string | null | undefined): string {
    if (!pfCode) {
      this.showpfCode = true;
      this.originalpfCode = '';
      return '';
    }
    this.showpfCode = true;
    this.originalpfCode = pfCode;
    return pfCode;
  }
  formatPFCode(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input) {
      return;
    }

    let value = input.value ? input.value.toUpperCase().replace(/[^a-zA-Z0-9]/g, '') : '';

    this.originalpfCode = value;

    if (value.length === 22) {
      this.showpfCode = false;
      this.updatePFDisplay();
    } else {
      this.showpfCode = true;
      this.updatePFDisplay();
    }
  }

  maskPFCode(pfCode: string): string {
    if (!pfCode) {
      return '';
    }

    if (pfCode.length === 22) {
      setTimeout(() => {
        if (this.pfCode.length === 22) {
        this.showpfCode = false;
        this.updatePFDisplay();
        }
      }, 5000);
      this.form.get('pfCode')?.disable();
      return pfCode.replace(/^(.{18})(.{4})$/, 'XXXXXXXXXXXXXXXXXX$2');
    }

    return pfCode;
  }

  togglePFCodeVisibility(): void {
    this.showpfCode = !this.showpfCode;
    this.updatePFDisplay();
  }

  updatePFDisplay(): void {
    const currentPFNumber = this.form.get('pfCode');
    if (this.showpfCode) {
      currentPFNumber?.setValue(this.originalpfCode);
      currentPFNumber?.enable();
    } else {
      currentPFNumber?.setValue(this.maskPFCode(this.originalpfCode));
    }
  }

  InitData() {
    this.premiseMasterService.getDataForEdit(this.branchId).subscribe({
      next: (response) => {
        const data = response.body
        this.rowData = data;
        this.PredefinedData(data)
       
        this.fetchLocation()
      },
      error: (error: any) => {
        console.error('Error fetching data', error);
      }
    })


  }
  validateForm(): boolean {
    this.showValidationErrors = true;
    let isValid = false;

    if (this.rcValidTo && this.rcValidFrom && this.rcValidFrom >= this.rcValidTo) {
      this.alertData = { alertMessage: 'RC Valid To must be greater than RC Valid From.', alertVisible: 'error' };
      this.rcValidFrom = null;
      return isValid;
    }
    if (this.rcValidFrom && this.rcValidTo && this.rcValidTo <= this.rcValidFrom) {
      this.alertData = { alertMessage: 'RC Valid To must be greater than RC Valid From.', alertVisible: 'error' };
      this.rcValidTo = null;
      return isValid;
    }

    if (this.form.valid && this.selectedState?.id &&  this.selectedLocation?.id && this.selectedOfficeType.value
      && this.selectedEstablishmentType.value && this.selectedDays.length && this.selectedWorkTiming.value && this.selectedBusinessType.value
      && this.selectedMaintainType.value && this.SelectedPowerToImpose.value && this.selectedTradeLicence.value && this.selectedStatusType.value
      && this.selectedAnchor?.id) {
        if (this.selectedWorkTiming.value === 'STANDARD' && this.workHoursFrom && this.workHoursTo && this.intervalsFrom && this.intervalsTo) {
          isValid = true;
        } else if (this.selectedWorkTiming.value != 'STANDARD') {
          isValid = true;
        }else{
          isValid = false;
          this.form.markAllAsTouched();
          return this.form.valid && isValid;
        }
      this.form.get('pfCode')?.setValue(this.originalpfCode)
      this.form.get('ESICCode')?.setValue(this.ESICCode)
      setTimeout(() => {
        this.showpfCode = false;
        this.updatePFDisplay();
        isValid = true;
      }, 500);
    } else {
      this.form.markAllAsTouched();
      isValid = false;
      return this.form.valid && isValid;
    }
  
    return this.form.valid && isValid;

  }
  generateTimeIntervals() {
    const startTime = new Date();
    startTime.setHours(0, 0, 0, 0);
    for (let i = 0; i < 24 * 4; i++) {
      const hours = startTime.getHours();
      const minutes = startTime.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const formattedTime =
        `${('0' + (hours % 12 || 12)).slice(-2)}:${('0' + minutes).slice(-2)} ${ampm}`;
      this.timeIntervals.push(formattedTime);
      startTime.setMinutes(startTime.getMinutes() + 15);
    }
  }

  fetchStateMasters(): void {
    this.locationMaster.getStateMaster().subscribe({
      next: (response) => {
        const data = response.body;
        if (data && Array.isArray(data)) {
          this.allStatesData = data.map((item: any) => ({
            id: item.sM_Code,
            name: item.sM_Name
          }));
          this.defaultState = "Select State";
          
        }
        else {
          console.error('Unexpected API response format:', data);
        }
      },
      error: (error) => {
        console.error('Error fetching state masters:', error);
      }
    }
    )

  }

  fetchLocation(): void {
    if (!this.selectedState) {
      console.error('State is not selected.');
      return;
    }
   

    this.locationMaster.getStateLocationMasterForDD(this.selectedState.id).subscribe({
      next: (response) => {
       

        const data = response.body;
        if (data && Array.isArray(data)) {
          this.allLocationData = data.map((item: any) => ({
            id: item.value,
            name: item.key
          }));

         

          this.defaultLocation = "Select Location";
          this.selectedLocation = this.allLocationData.find(location => location.id === this.rowData.cM_City) || { id: '', name: '' };
        
          this.form.patchValue({ location: this.selectedLocation?.id ? this.selectedLocation.name : '' });
        } else {
          console.error('Unexpected API response format:', data);
        }
      },
      error: (error) => {
        console.error('Error fetching locations:', error);
      }
    }
    )

  }

  fetchAnchor() {
    const distributorId = 94;
    const customerId = this.user.getCustomerId();  
    this.premiseMasterService.getAllLocAnchor(distributorId, customerId).subscribe({
      next: (response) => {
        const data = response.body;
        console.log('Raw anchor data from API:', data);    
        if (data && Array.isArray(data)) {
          const uniqueUsernames = new Set();
          this.allAnchorData = [];  
          data.forEach((item: any) => {
            const username = item.userName.trim(); 
            if (!uniqueUsernames.has(username)) {
              uniqueUsernames.add(username); 
              this.allAnchorData.push({ id: item.id, name: username }); 
            }
          });  
          this.defaultAnchor = "Select Location Anchor";
          console.log('Filtered anchor data (no duplicates):', this.allAnchorData);
                } else {
          console.error('Unexpected API response format:', data);
        }
      },
      error: (error) => {
        console.error('Error fetching anchor data:', error);
      }
    });
  }
  PredefinedData(value: any) {
    console.log('predefineddata',value)
    if (value && value.cM_State && this.allStatesData) {
      // Find the matching state in `allStatesData`
      const matchedState = this.allStatesData.find(state => state.name === value.cM_State || state.id === value.cM_State);
      
      if (matchedState) {
          this.selectedState = { id: matchedState.id, name: matchedState.name };
      } else {
          console.warn('No matching state found for cM_State:', value.cM_State);
          this.selectedState = { id: value.cM_State, name: value.cM_State }; // Fallback
      }
  }
    //this.selectedState = this.allStatesData.find(type => type.id === value.cM_State) || { id: '', name: '' };
    this.locationCode = value.cM_City;
    this.selectedLocation = this.allLocationData.find(type => type.id === value.cM_City) || { id: '', name: '' };
    this.fetchLocation()
    if (value && value.cL_LocationAnchor) {
      // Bind the selected state with cM_State
      this.selectedAnchor = { id: value.cL_LocationAnchor, name: value.cL_LocationAnchor }; // Adjust 'id' as necessary
  }
   
    this.selectedWorkTiming = this.workTimings.find(type => type.value === (value.cL_WorkTimings ? value.cL_WorkTimings.toUpperCase() : '')) || { text: '', value: '' }

    this.form = this.fb.group({
      branchName: [value.avacoM_BranchName || '', [Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]],
      branchAddress: [value.cM_Address || '', [Validators.required]],
      state: [this.selectedState?.id, [Validators.required]],
      location: [this.selectedLocation?.name || '', []],
      pfCode: [this.initializepfCode(value.cL_PF_Code) || '', [Validators.minLength(22), Validators.maxLength(22), Validators.pattern(/^[a-zA-Z0-9]{1,22}$/)]],
      ESICCode: [this.initializeESICCode(value.cL_ESIC_Code) || '', [Validators.minLength(17), Validators.maxLength(17)]],
      employerName: [value.cL_EmployerName || '', [Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]],
      employerAddress: [value.cL_EmployerAddress || '', [Validators.required]],
      managerName: [value.cL_ManagerName || '', [Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]],
      managerAddress: [value.cL_ManagerAddress || '', [Validators.required]],
      worktiming: [value.cL_WorkTimings || this.selectedWorkTiming.value, [Validators.required]],
      companyPhnNo: [value.cL_CompPhoneNo || '', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      hrMailId: [value.cL_HRMailID || '', [Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]],
      hrContactPerson: [value.cL_HRContactPerson || '', [Validators.pattern(/^[a-zA-Z\s]*$/)]],
      hrPhnNo: [value.cL_HRPhNo || '', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      hrFirstMail: [value.cL_HR1stLevelMail || '', [Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]],
      hrFirstPhnNo: [value.cL_HR1stLevelPhNo || '', [Validators.pattern(/^\d{10}$/)]],
      natureOfBusiness: [value.cL_NatureofBusiness || '', [Validators.required]],
      rcNo: [value.cL_RCNO || '', [Validators.pattern(/^\d{10}$/)]],
      municipality: [value.cL_Municipality || '', [Validators.required]],
      nicCode: [value.cL_NICCode || '', [Validators.pattern('^[a-zA-Z0-9]*$')]],
      classificationOfEst: [value.cL_ClassificationofEstablishment || '', [Validators.pattern(/^[a-zA-Z\s]*$/)]],
      district: [value.cL_District || '', [Validators.pattern(/^[a-zA-Z\s]*$/)]],
      anchor: [this.selectedAnchor || null, [Validators.required]],
    });
    this.Entity = value.cM_ClientID ?? '',
      this.branchCode = value.cL_BranchCode ?? '',

      this.branchName = value.avacoM_BranchName ?? ''
    this.branchAddress = value.cM_Address ?? '',

      this.selectedPtState = this.allStatesData.find(type => type.id === value.cL_PT_State) || { id: '', name: '' }
    this.selectedLwfState = this.allStatesData.find(type => type.id === value.cL_LWF_State) || { id: '', name: '' }
    this.employerName = value.cL_EmployerName ?? '',
      this.employerAddress = value.cL_EmployerAddress ?? '',
      this.managerName = value.cL_ManagerName ?? '',
      this.managerAddress = value.cL_ManagerAddress ?? '',
      this.companyPhnNo = value.cL_CompPhoneNo ?? '',
      this.hrPhnNo = value.cL_HRPhNo ?? '',
      this.pfCode = value.cL_PF_Code ?? '',
      this.ESICCode = value.cL_ESIC_Code ?? '',
      this.hrContactPerson = value.cL_HRContactPerson ?? '',
      this.hrMailId = value.cL_HRMailID ?? '',
      this.natureOfBusiness = value.cL_NatureofBusiness ?? '',
      this.rcNo = value.cL_RCNO ?? '',
      this.hrFirstMail = value.cL_HR1stLevelMail ?? '',
      this.hrFirstPhnNo = value.cL_HR1stLevelPhNo ?? '',
      this.lin = value.cL_LIN
    this.classificationOfEst = value.cL_ClassificationofEstablishment ?? '',
      this.nicCode = value.cL_NICCode ?? '',
      this.licenceNo = value.cL_LicenceNo ?? '',
      this.selectedDays = value.cL_weekoffDay ? value.cL_weekoffDay.split('|') : [];
    this.selectedDays = this.selectedDays.filter((day: any) =>
      this.weekDays.some(weekDay => weekDay.value === day)
    );
  
    this.workHoursFrom = this.timeIntervals.find(type => type === value.cL_WorkHoursFrom) || '',
      this.workHoursTo = this.timeIntervals.find(type => type === value.cL_WorkHoursTo) || '',
      this.intervalsFrom = this.timeIntervals.find(type => type === value.cL_IntervalsFrom) || '',
      this.intervalsTo = this.timeIntervals.find(type => type === value.cL_IntervalsTo) || '',
      this.selectedSectionOfAct = this.sectionOfActOptions.find(type => type.value === value.cL_SectionofAct) || { text: '', value: '' }
    this.selectedBusinessType = this.businessTypes.find(type => type.value === value.cL_BusinessType) || { text: '', value: '' }
    this.selectedTradeLicence = { text: '', value: value.cL_TradeLicenceApplicability ?? '' };
    this.selectedStatusType = this.statusType.find(type => type.value === value.cM_Status) || { text: '', value: '' }
    this.selectedMaintainType = this.Types.find(type => type.value === value.cL_PermissionMaintainingForms) || { text: '', value: '' }
    this.SelectedPowerToImpose = this.Types.find(type => type.value === value.cL_RequirePowerforFines) || { text: '', value: '' }
    this.municipality = value.cL_Municipality ?? '';
    this.district = value.cL_District ?? '';
    this.jurisdiction = value.cL_Juridiction ?? '';
    this.employerDesignation = value.cL_EmployerDesignation ?? '';
    this.noOfEmployee = value.noofEmployees ?? '';
    this.rcValidFrom = value.cL_RC_ValidFrom ? new Date(value.cL_RC_ValidFrom) : null;
    this.rcValidTo = value.cL_RC_ValidTo ? new Date(value.cL_RC_ValidTo) : null;
    this.commencementDate = value.cL_CommencementDate ? new Date(value.cL_CommencementDate) : null;
    this.branchEndDate = value.cL_BranchEndDate ? new Date(value.cL_BranchEndDate) : null;
  
    this.selectedOfficeType = this.officeTypes.find(type => type.value === (value.cL_OfficeType ? value.cL_OfficeType.toUpperCase() : '')) || { text: '', value: '' }
   
    this.selectedEstablishmentType = this.establishmentTypes.find(type => type.value === value.cM_EstablishmentType) || { text: '', value: '' }
    this.selectedEDLI = this.Types.find(type => type.value === value.cL_EDLI_Excemption) || { text: '', value: '' }
   
    setTimeout(() => {
      if (this.pfCode.length == 22) {
        this.showpfCode = false;
        this.updatePFDisplay();
      }
      if (this.ESICCode.length == 17) {
        this.showESICCode;
        this.updateESICDisplay();
      }
    }, 500);
    this.cdr.detectChanges();

  }



  DropdownValueChange(value: any): void {
   
    if (value && value.id && value.name) {
      this.selectedState = { id: value.id, name: value.name };

      this.fetchLocation();

      if (this.cateringApplicability) {
        const fakeEvent = { target: { checked: this.cateringApplicability } } as unknown as Event;
        this.validateCateringApplicability(fakeEvent);
      }
    } else {
      console.error('Dropdown value is undefined or invalid:', value);
    }
  }

  DropDownPTValueChange(value: any) {
    this.selectedPtState = { id: value.id, name: value.name }
  }

  DropdownLWFStateChange(value: any) {
    this.selectedLwfState = { id: value.id, name: value.name }
  }

  DropdownLocationChange(value: any) {
    this.selectedLocation = { id: value.id, name: value.name }
    this.locationCode = value.id
    this.form.patchValue({ location: value.name });

  }

  onEstablishmentTypeChange(value: string): void {
    this.selectedEstablishmentType = value;

  }

  onOfficeTypeChange(value: string): void {
    this.selectedOfficeType = value;
  }
  onSelectedEDLI(value: any): void {
    this.selectedEDLI = value;
  }

  onSelectedStatusType(value: any): void {
    this.selectedStatusType = value;
  }
  onSelectedTradeLicence(value: any): void {
    this.selectedTradeLicence = value;
  }
  onSelectedSectionOfAct(value: any): void {
    this.selectedSectionOfAct = value;
  }
  onSelectedPowerToImpose(value: any): void {
    this.SelectedPowerToImpose = value;
  }
  onSelectedMaintainType(value: any): void {
    this.selectedMaintainType = value;
  }
  onSelectedBusinessType(value: any): void {
    this.selectedBusinessType = value;
  }

  onSelectedDays(value: any[]): void {
    debugger;
    this.selectedDays = value;
    const extractedValues = this.selectedDays.map(day => day.value);
    this.formattedValues = extractedValues.join('|');

  }

  validateCateringApplicability(event: Event): void {
    const checkbox = event.target as HTMLInputElement;

    if (this.selectedState?.id === 'TMN' || this.selectedState?.id === 'PND') {
      this.showValidation = false;
      this.errorMessage = '';
    } else {
      checkbox.checked = false;
      this.cateringApplicability = false;
      this.showValidation = true;
      this.errorMessage = 'Check this only for Tamilnadu Or Pondicherry';
    }
  }

  onDateChange(value: Date, type: string) {
    const date = new Date(value);

    if (type === 'rcValidFrom') {
      this.rcValidFrom = date;
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (this.rcValidFrom && this.rcValidFrom >= today) {
        this.alertData = { alertMessage: 'RC Valid From cannot be a future date', alertVisible: 'error' };
        this.rcValidFrom = null;
      }
    }
    else if (type === 'rcValidTo') {
      this.rcValidTo = date;
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (this.rcValidTo && this.rcValidTo < today) {
        this.alertData = { alertMessage: 'RC Valid To must be greater than current date.', alertVisible: 'error' };
        this.rcValidTo = null;
      }
    }
    else if (type === 'branchEndDate') {
      this.branchEndDate = date;
      if (this.branchEndDate && this.commencementDate && this.branchEndDate < this.commencementDate) {
        this.alertData = { alertMessage: 'Branch End Date cannot be a smaller than Branch Start Date', alertVisible: 'error' };
        this.branchEndDate=null;
      }
    }
    else if (type === 'commencementDate') {
      this.commencementDate = date;
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (this.commencementDate && this.commencementDate > today) {
        this.alertData = { alertMessage: 'Branch Start Date cannot be a future date', alertVisible: 'error' };
        this.commencementDate = null;
      }
      else if (this.branchEndDate && this.commencementDate && this.commencementDate > this.branchEndDate) {
        this.alertData = { alertMessage: 'Branch Start Date cannot be greater than the Branch End Date', alertVisible: 'error' };
        this.commencementDate = null;
      }
    }
  }

  onWorkTimingChange(value: any) {
    this.selectedWorkTiming = value
  }
  DropdownAnchorChange(value: any) {
   
    this.selectedAnchor = { name: value.name, id: value.id };
  }

  savePremiseData() {
    debugger;
    if (!this.validateForm()) {
      return;
    }

    const formatDate = (date: Date): string => {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };
    const branch: Temp_LocationMaster = {
      CustomerID: this.user.getCustomerId(),
      CM_ClientID: this.user.getClientId(),
      AVACOM_BranchName: this.branchName || 'N0',
      CM_State: this.rowData.cM_State || 'N0',
      CM_City: this.locationCode || 'N0',
      CM_Address: this.branchAddress || 'N0',
      CL_LWF_State: this.selectedLwfState.id || 'N0',
      CL_PT_State: this.selectedPtState.id || 'N0',
      CL_PF_Code: this.pfCode || 'N0',
      CL_ESIC_Code: this.ESICCode || 'N0',
      CL_OfficeType: this.selectedOfficeType.value || 'N0',
      CM_EstablishmentType: this.selectedEstablishmentType.value || 'N0',
      CL_EmployerName: this.employerName || 'N0',
      CL_EmployerAddress: this.employerAddress || 'N0',
      CL_ManagerName: this.managerName || 'N0',
      CL_ManagerAddress: this.managerAddress || 'N0',
      CL_CompPhoneNo: this.companyPhnNo.toString() || 'N0',
      CL_HRContactPerson: this.hrContactPerson || 'N0',
      CL_HRPhNo: this.hrPhnNo.toString() || 'N0',
      CL_HRMailID: this.hrMailId || 'N0',
      CL_HR1stLevelMail: this.hrFirstMail || 'N0',
      CL_HR1stLevelPhNo: this.hrFirstPhnNo.toString() || 'N0',
      CL_RCNO: this.rcNo || 'N0',
      CL_RC_ValidFrom: this.rcValidFrom ? formatDate(new Date(this.rcValidFrom)) : 'N0',
      CL_RC_ValidTo: this.rcValidTo ? formatDate(new Date(this.rcValidTo)) : 'N0',
      CL_NatureofBusiness: this.natureOfBusiness || 'N0',
      CL_weekoffDay: this.formattedValues || 'N0',
      CL_WorkHoursFrom: this.workHoursFrom === 'Select' ? 'N0' : this.workHoursFrom || 'N0',
      CL_WorkHoursTo: this.workHoursTo === 'Select' ? 'N0' : this.workHoursTo || 'N0',
      CL_IntervalsFrom: this.intervalsFrom === 'Select' ? 'N0' : this.intervalsFrom || 'N0',
      CL_IntervalsTo: this.intervalsTo === 'Select' ? 'N0' : this.intervalsTo || 'N0',
      CL_WorkTimings: this.selectedWorkTiming.value || 'N0',
      CM_Status: this.selectedStatusType.value,
      CL_LIN: this.lin || 'N0',
      CL_Municipality: this.municipality || 'N0',
      CL_PermissionMaintainingForms: this.selectedMaintainType.value || 'N0',
      CL_RequirePowerforFines: this.SelectedPowerToImpose.value || 'N0',
      CL_BusinessType: this.selectedBusinessType.value || 'N0',
      CL_CommencementDate: this.commencementDate ? formatDate(new Date(this.commencementDate)) : 'N0', CL_ClassificationofEstablishment: this.classificationOfEst || 'N0',
      CL_LicenceNo: this.licenceNo || 'N0',
      CL_NICCode: this.nicCode || 'N0',
      CL_SectionofAct: this.selectedSectionOfAct.value || 'N0',
      CL_District: this.district || 'N0',
      CL_Juridiction: this.jurisdiction || 'N0',
      CL_BranchEndDate: this.branchEndDate ? formatDate(new Date(this.branchEndDate)) : 'N0', CL_TradeLicenceApplicability: this.selectedTradeLicence.value || 'N0',
      CL_EmployerDesignation: this.employerDesignation || 'N0',
      CL_EDLI_Excemption: this.selectedEDLI.value || 'N0',
      CL_LocationAnchor: this.selectedAnchor?.id || 'N0',
      FileID: 0,
      RowNo: 0,
      AVACOM_BranchID: this.rowData?.avacoM_BranchID || 0
    };
    console.log('body',branch);
    
    
    this.premiseMasterService.addEditPremiseMaster(branch).subscribe({
      next: (response) => {
       
        if (response.status === 200) {
          this.alertData = { alertMessage: 'Details Saved Successfully', alertVisible: 'success' };
          this.premise.loadPremiseData();
        }
        else {
          this.alertData = { alertMessage: 'Failed to save data', alertVisible: 'error' };
        }
      },
      error: (error) => {
        console.error('Error saving premise data:', error);
        this.alertData = { alertMessage: 'Failed to save data', alertVisible: 'error' };
      }
    });
  }

  openShiftMasterDetails() {
    this.openShiftDetails = true;
  }
  handleShiftMasterClose() {
    this.openShiftDetails = false;
  }
  public closePopup(): void {
    this.close.emit();
  }
  closeAlertPopup(){
    this.alertData = { alertMessage: '', alertVisible: '' };   
  }
}


