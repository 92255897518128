<form [formGroup]="form">
    <div class="row colpadding0 row-setting">
        <div class="col-12 d-flex">
            <app-dropdown-list [data]="complianceType" [selectedValue]="selectedComplianceType" [width]="'200px'"
                textField="text" valueField="value" class="dropdown-setting"
                (valueChange)="onComplianceTypeChange($event)" formControlName="compliance"
                [ngClass]="{'is-invalid': form.get('compliance')?.invalid && (form.get('compliance')?.touched)}">
            </app-dropdown-list>

            <app-dropdown-list [data]="period" [selectedValue]="selectedPeriod" [width]="'200px'" textField="text"
                valueField="value" class="dropdown-setting" (valueChange)="onPeriodChange($event)"
                formControlName="period"
                [ngClass]="{'is-invalid': form.get('period')?.invalid && (form.get('period')?.touched)}">
            </app-dropdown-list>

            <app-dropdown-list [data]="years" [selectedValue]="selectedYear" [width]="'200px'" textField="text"
                valueField="value" (valueChange)="onYearChange($event)" formControlName="year"
                [ngClass]="{'is-invalid': form.get('year')?.invalid && (form.get('year')?.touched)}">
            </app-dropdown-list>
            <button *ngIf="isApplyClicked && inputListData.length > 0 && selectedComplianceType.value==='Challan'" class="figma-btn-blue output-btn" type="submit" (click)="navigateToChalllan()" >
               Output (Challans)
            </button>
            <button class="figma-btn-white apply-btn ml-auto" type="submit" (click)="applyFilter()">
                <img src="../assets/vectors/ApplyFilter.svg" alt="">
            </button>
            <button class="figma-btn-blue" type="submit" (click)="submit()">
               Submit
            </button>
        </div>
    </div>

    <kendo-grid [data]="inputListData" [pageable]="false" class="grid custom-grid">
        <kendo-grid-column field="pageName" title="Input File" [width]="250">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="col-12 collpadding0 inputName cell-content" [title]="dataItem.pageName">
            <p>
              {{ dataItem.pageName }}
              <sup class="text-danger" *ngIf="dataItem.isFileRequired === 1">*</sup>
            </p>
            </div>
          </ng-template>
        </kendo-grid-column>
      
        <kendo-grid-column title="Template" [width]="80">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="col-12 collpadding0 center-align cell-content">
            <img src="../assets/vectors/ExcelDownload.svg" (click)="Template(dataItem.inputID)" alt="" class="svg-icon-btn">
            </div>
          </ng-template>
        </kendo-grid-column>
      
        <kendo-grid-column title="Upload" [width]="100">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="col-12 collpadding0 center-align cell-content">
            <button class="figma-btn-blue download-btn" [disabled]="!(dataItem.isFilePresent === 1)" (click)="downloadFile(dataItem.csvUploadDoc)">
              Download
            </button>
            </div>
          </ng-template>
        </kendo-grid-column>
      
        <kendo-grid-column title="Template Checker">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="col-12 collpadding0 center-align cell-content">
            <ng-container *ngIf="dataItem.isValidateTemplate !== -1">
              <img *ngIf="dataItem.isValidateTemplate === 3" src="../assets/vectors/Incorrect_Icon.svg" alt="" class="greentick">
              <img *ngIf="dataItem.isValidateTemplate === 1" src="../assets/vectors/GreenTick.svg" alt="" class="greentick">
            </ng-container>
            </div>
          </ng-template>
        </kendo-grid-column>
      
        <kendo-grid-column title="Clarification Section">
            <div class="col-12 collpadding0 center-align cell-content">
                </div>
        </kendo-grid-column>
      
        <kendo-grid-column title="Final Input Uploaded" [width]="250">
          <ng-template kendoGridCellTemplate let-dataItem let-i="rowIndex">
            <div class="col-12 collpadding0 center-align cell-content">
            <div class="col-12 collpadding0" style="justify-content: space-around;">
              <span style="color: #A0A0A0;">
                {{ fileNames[i] ? (fileNames[i].length > 10 ? fileNames[i].substring(0, 12) + '..' : fileNames[i]) : 'No file chosen' }}
              </span>
              <input type="file" formControlName="Template" #TemplateFile (change)="onFileChange($event, i)" [id]="'fileInput' + i" style="display: none;" />
              <button class="figma-btn-gray download-btn" (click)="TemplateFile.click()" [disabled]="!(dataItem.isFilePresent === 1)">Browse</button>
              <button class="figma-btn-blue download-btn" style="color: white; margin-left: 8px;" (click)="uploadPerformer(i, dataItem.inputID)" [disabled]="!(dataItem.isFilePresent === 1)">Upload</button>
            </div>
            </div>
          </ng-template>
        </kendo-grid-column>
      
        <kendo-grid-column title="Uploaded">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="col-12 collpadding0 center-align cell-content">
            <ng-container *ngIf="dataItem.displayUploadFlag === 1">
              <img *ngIf="dataItem.isError === 0 && dataItem.isUploaded === 0" src="../assets/vectors/sandclock.svg" alt="" class="svg-icon-btn">
              <img *ngIf="dataItem.isUploaded === 1" src="../assets/vectors/GreenTick.svg" alt="" class="greentick">
              <img *ngIf="dataItem.isError === 1 && dataItem.isUploaded === 0" src="../assets/vectors/DownloadOrange.svg" alt="" class="svg-icon-btn" (click)="downloadErrorFile(dataItem.inputID)">
            </ng-container>
            </div>
          </ng-template>
        </kendo-grid-column>
      
        <kendo-grid-column title="Validated">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="col-12 collpadding0 center-align cell-content">
            <ng-container *ngIf="dataItem.displayValidationFlag === 1">
              <img *ngIf="dataItem.isError === 0 && dataItem.isValidated === 0" src="../assets/vectors/sandclock.svg" alt="" class="svg-icon-btn">
              <img *ngIf="dataItem.isValidated === 1" src="../assets/vectors/GreenTick.svg" alt="" class="greentick">
              <img *ngIf="dataItem.isError === 1 && dataItem.isValidated === 0" src="../assets/vectors/DownloadOrange.svg" alt="" class="svg-icon-btn" (click)="downloadErrorFile(dataItem.inputID)">
            </ng-container>
            </div>
          </ng-template>
        </kendo-grid-column>
      
        <kendo-grid-column title="Processed">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="col-12 collpadding0 center-align cell-content">
            <ng-container *ngIf="dataItem.displayProcessFlag === 1">
              <img *ngIf="dataItem.isError === 0 && dataItem.isProcessed === 0" src="../assets/vectors/sandclock.svg" alt="" class="svg-icon-btn">
              <img *ngIf="dataItem.isProcessed === 1" src="../assets/vectors/GreenTick.svg" alt="" class="greentick">
              <img *ngIf="dataItem.isError === 1 && dataItem.isProcessed === 0" src="../assets/vectors/DownloadOrange.svg" alt="" class="svg-icon-btn" (click)="downloadErrorFile(dataItem.inputID)">
            </ng-container>
            </div>
          </ng-template>
        </kendo-grid-column>
      
        <kendo-grid-column title="History">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="col-12 collpadding0 center-align cell-content">
            <img src="../assets/vectors/History.svg" class="svg-icon-btn" alt="" style="cursor:pointer;" (click)="openHistoryPopup(dataItem.inputID)">
            </div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
      
</form>

<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
  </div>

  <app-input-history-performer *ngIf="openHistory" [id]="inputId" [month]="month" [year]="year" (close)="closeHistoryPopup()"></app-input-history-performer>

  
  <app-alert-popup *ngIf="alertData.alertVisible" [Width]="250" [Height]="260" [Data]="alertData"
  (close)="closeAlert()"></app-alert-popup>