import { ChangeDetectorRef, Component } from '@angular/core';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { KendoDatepickerComponent } from '../../../components/kendo-datepicker/kendo-datepicker.component';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { NgIf } from '@angular/common';
import { VariousReportService } from '../../../service/various-report.service';
import { BlobService } from '../../../service/blob.service';
import { UserService } from '../../../service/user.service';
import { GridModule } from '@progress/kendo-angular-grid';
import { GridComponent } from '../../../components/grid/grid.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';


// type Branch = {
//   id: number;
//   name: string;
//   parentID: number | null;
//   branchType: string;
//   branchStatus: string;
//   fullName: string | null;
//   children?: Branch[];
// };
@Component({
  selector: 'app-variance-report',
  standalone: true,
  imports: [IndicatorsModule,DropdownListComponent, KendoDatepickerComponent, DateInputsModule, NgIf, GridModule, GridComponent],
  templateUrl: './variance-report.component.html',
  styleUrl: './variance-report.component.css'
})
export class VarianceReportComponent {
  public value: Date | undefined;
  loading = false;
  isPeriodLoaded = false;
  showYearFrePeriodDropdowns = false;
  showDatepicker = false;
  showState = false;
  isActive: boolean = true;
  public columns: any[] = [];
  public gridData: any[] = [];

  onVariousType: any;
  VarianceRpt: { text: string, value: string }[] = [
    { text: 'Various Type', value: 'Various Type' },

  ]
  selectedVariousType: { text: any, value: any } = { text: 'Various Type', value: 'Various Type' }


  // @Input() hasError!: any;selectedYear
  selectedStartDate: Date | null = null;
  public datePlaceholder: string = 'Month Year';
  showValidationErrors = false;


  // years: any[] = [];
  years: { text: string, value: number }[] = [];
  // selectedYear: any[] = [];
  selectedYear = { text: 'Year', value: '' };
  selectedFiscalYear: any;

  complianceFrequency: { text: string, value: string }[] = [
    { text: 'Frequency', value: 'Frequency' },

  ]
  selectedComplianceTypes: { text: any, value: any } = { text: 'Frequency', value: 'Frequency' }

  // public defaultSelectedValueMonth: any[] = [];
  defaultSelectedValueMonth: { text: string, value: string } = { text: 'Period', value: 'Period' };
  selectedMonth: { text: string, value: string } = this.defaultSelectedValueMonth;
  selectedComplianceType: any[] = [];


  // dropdownPeriods: any[] = [];
  dropdownPeriods: { text: string, value: string }[] = [
    { text: 'Period', value: 'Period' },

  ]
  selectedPeriod: any;

  // public Variancerpt: any[] =  [{ id: 0, name: 'VPF & EPS -Variance' }, { id: 1, name: 'InternationalWorker-Variance' }, { id: 2, name: 'YearlyAnnaulReturnData' },{ id: 3, name: 'LeaveClosingBalance' },{ id: 4, name: 'Paycode_Master' }];
  public Variancerpt: any[] = [];

  complianceperiods: any[] = [];


  take = 10;
  skip = 0;
  page = 10;
  pageSize = '';

  month!: number;
  year!: number;
  EntityId: string = '';
  showApply = true;
  paycode = '';

  constructor(private VariousReportService: VariousReportService, private blobService: BlobService, private user: UserService ) {


  }
  ngOnInit(): void {
    this.EntityId = 'GZ0RG';
    this.getVariousTypeData();
    this.getYears();
    this.getComplianceFrequency();
    this.GetVarianceDetailed();
    this.GetLeaveSummaryReports();
    this.GetPaycodeMasters();
    this.GetVEVarianceDetail();
    this.GetYearlyAnnualReport();
  }
  onComplianceVariousTypes(value: any) {
    this.onVariousType = value;
    console.log('onVariousType', this.onVariousType);

    // Reset all visibility flags first
    this.showDatepicker = false;
    this.showYearFrePeriodDropdowns = false;

    // Update visibility flags based on the selected value
    if (value.sort === 1 || value.sort === 2 || value.sort === 4) {
      this.showDatepicker = true;
      this.showState = false;
      this.showApply = true;

    } else if (value.sort === 3) {
      this.showYearFrePeriodDropdowns = true;
      this.showApply = false;
      this.showState = false;
    } else if (value.sort === 5) {
      this.showDatepicker = false;
      this.showState = false;
      this.showApply = true;
    }
  }


  onStartDateChange(value: Date) {
    const date = new Date(value.toISOString().split('T')[0]);
    this.selectedStartDate = date;
    this.showValidationErrors = true;
    this.month = date.getMonth() + 1; // getMonth() returns 0-based month, so add 1
    this.year = date.getFullYear();
  }
  onYearChange(year: any) {
    this.selectedFiscalYear = year;
    // this.selectedyears = year.map((item: { value: any; }) => item.value);
    console.log('this.selectedFiscalYear', this.selectedFiscalYear)
  }

  onMonthChange(selected: any) {
    this.isPeriodLoaded = false;
    this.selectedMonth = selected;
    console.log('this.selectedMonth ', this.selectedMonth);
    let param;
    if (selected.value == 'Monthly') {
      param = 'Months';
    }
    if (selected.value == 'Quarterly' || selected.value == 'HalfYearly' || selected.value == 'Annual' || selected.value == 'TwoYearly') {
      // Assuming each selected item is an object with a 'value' property
      const firstSelectedItem = selected.value[0] == 'T' ? 'B' : selected.value[0]; // a First item in the array

      // Access the 'value' property and capitalize the first letter
      const firstLetter = firstSelectedItem.charAt(0).toUpperCase();//a

      // Correctly format the subFrequency string
      param = `SubFrequency${firstLetter}`;//a
    }


    // Call the getComplianceMonthperiods method with the formatted subFrequency
    this.getComplianceMonthperiods(param);
  }


  onPeriod(period: any) {
    this.selectedPeriod = period;
    // this.selectedPeriods =period.map((item: { value: any; }) => item.value);
    console.log('period', this.selectedPeriod)
  }
  clearFilter() {
   
    this.selectedVariousType = { text: 'Various Type', value: 'Various Type' };
    this.defaultSelectedValueMonth = { text: 'Period', value: 'Period' };
    this.selectedComplianceTypes = { text: 'Monthly', value: null };
    this.selectedStartDate = null;
    this.selectedYear = { text: 'Year', value: '' };
   this.isActive=false;
  }
  getVariousTypeData() {
    // const entityId = this.user.getClientId();

    this.VariousReportService.getVariousRpt('VarianceRpt').subscribe({
      next: (response) => {
        console.log('branch dropdown data', response);
        this.Variancerpt = response.body
        // if (Variancerpt && Array.isArray(Variancerpt)) {
        //   this.Variancerpt = this.buildHierarchy(Variancerpt);
        //   console.log('BranchDropdown', this.Variancerpt)
        // } else {
        //   console.error('Unexpected data format', Variancerpt);
        // }
      }
    })


  }

  getYears(): void {
    const currentYear = new Date().getFullYear();
    this.years = [];
    for (let year = currentYear; year >= 2017; year--) {
      this.years.push({ text: year.toString(), value: year });
    }
  }
  getComplianceFrequency() {

    this.VariousReportService.getComplianceFrequency('Frequency').subscribe({
      next: (response) => {
        const data = response.body;
        if (data && Array.isArray(data)) {
          this.complianceFrequency = data.map((item: any) => ({
            value: item.value,
            text: item.text
          }));

        }

      },
      error: (err) => {
        console.error("Error fetching compliance status:", err);
      }
    });
  }
  getComplianceMonthperiods(periods: any) {

    this.VariousReportService.getComplianceFrequency(periods).subscribe({
      next: (response) => {
        debugger
        const data = response.body;
        if (data && Array.isArray(data)) {
          this.complianceperiods = data.map((item: any) => ({
            value: item.value,
            text: item.text
          }));
     
          // this.complianceperiods = data;
          this.isPeriodLoaded = true;
        }
      },
      error: (err) => {
        console.error("Error fetching compliance status:", err);
      }
    });
  }
  //grid apis
  GetVarianceDetailed() {
    
 
    let payload = {
      ClientId: this.user.getClientId(),
      Month: this.month,
      Year: this.year,
      VarianceType: this.onVariousType.value
    }

    this.columns = [
      { field: 'eM_EmpID', title: 'Employee ID' },
      { field: 'eM_EmpName', title: 'Employee Name' },
      { field: 'isInternationalvariance', title: 'Is international Varience ' },


    ];
    this.loading = true;
    this.VariousReportService.GetVarianceDetail(payload).subscribe({
      next: (response) => {
        debugger;
        let data = response.body;


        if (data) {

          this.isActive = true;
          this.gridData = data;
          // this.originalData = data;
          // console.log('grid', this.gridData)
          this.showState = true;
        }
        this.loading = false;
      },
      error: (error) => {
        debugger
        console.error('Error fetching Master Grid data', error);
        // this.showState = false;
        this.loading = false;
      }
    });
  }
  GetLeaveSummaryReports() {
  
    let payload = {
      ClientId: this.user.getClientId(),
      Month: this.month,
      Year: this.year,
      VarianceType: this.onVariousType.value
    }

    this.columns = [
      { field: 'lS_EmpId', title: 'Emp ID' },
      { field: 'lS_PayrollMonth', title: 'Month' },
      { field: 'lS_PayrollYear', title: 'Year ' },
      { field: 'lS_LeaveType', title: 'Leave Type' },
      { field: 'lS_OpeningBalance', title: 'Opening Balance' },
      { field: 'lS_MonthlyCredit', title: 'Monthly Credit' },
      { field: 'lS_LeaveUsed', title: 'Leave Used' },
      { field: 'lS_ClosingBalance', title: 'Closing Balance ' },



    ];
    this.loading = true;
    this.VariousReportService.GetLeaveSummaryReport(payload).subscribe({
      next: (response) => {
        debugger;
        let data = response.body;
        this.gridData = response.body;

        if (data) {

          this.isActive = true
          this.gridData = data;
          // this.originalData = data;
          // console.log('grid', this.gridData)
          this.showState = true;
        }
        this.loading = false;
      },
      error: (error) => {
        debugger
        console.error('Error fetching Master Grid data', error);
        // this.showState = false;
        this.loading = false;
      }
    });
  }
  GetPaycodeMasters() {//paycodemaster

    let payload = {
      take: this.take,
      skip: this.skip,
      page: this.page,
      pageSize: this.pageSize

    }
    this.columns = [
      { field: 'peM_Pay_Code', title: 'Paycode' },
      { field: 'peM_Pay_Code_Description', title: 'Description' },
      { field: 'peM_Deduction_Type', title: 'Education Type' },
      { field: 'peM_appl_ESI', title: 'ESI Appl.' },
      { field: 'peM_Appl_PF', title: 'PF Appl.' },
      { field: 'peM_Appl_PT', title: 'PT Appl.' },
      { field: 'peM_Appl_LWF', title: 'Lwl Appl.' },

    ];
 
    this.loading = true;
    this.VariousReportService.GetPaycodeMasters(payload).subscribe({
      next: (response) => {
        debugger;
        let data = response.body;


        if (data) {

          this.isActive = true
          this.gridData = data;
          // this.originalData = data;
          // console.log('grid', this.gridData)
          this.showState = true;


        }
        this.loading = false;

      },
      error: (error) => {
        debugger
        console.error('Error fetching Master Grid data', error);
        this.showState = false;
        this.loading = false;
      }
    });
  }
  GetVEVarianceDetail() {

    let payload = {
      ClientId: this.user.getClientId(),
      Month: this.month,
      Year: this.year,
      VarianceType: this.onVariousType.value
    }
    this.columns = [
      { field: 'peM_Pay_Code', title: 'Employee Id' },
      { field: 'peM_Pay_Code_Description', title: 'Employee Name' },
      { field: 'peM_Deduction_Type', title: 'IsVPFvariance' },
      { field: 'peM_appl_ESI', title: 'VPFvarianceAmount' },
      { field: 'peM_Appl_PF', title: 'isVPFvariance' },




    ];
    this.loading = true;
    this.VariousReportService.GetVarianceDetail(payload).subscribe({
      next: (response) => {
        debugger;
        let data = response.body;


        if (data) {

          this.isActive = true
          this.gridData = data;
          // this.originalData = data;
          // console.log('grid', this.gridData)
          this.showState = true;
        }
        this.loading = false;
      },
      error: (error) => {
        debugger
        console.error('Error fetching Master Grid data', error);
        this.showState = false;
        this.loading = false;
      }
    });
  }
  // GetYearlyAnnualReport(){

  //   let payload={
  //     EntityId:this.EntityId,
  //     yearID:this.selectedFiscalYear?.value,
  //     Frequency:this.selectedMonth?.value,
  //     Period:this.selectedPeriod?.value
  //   }

  //     this.loading = true
  //     this.VariousReportService.GetYearlyAnnualReport(payload).subscribe(
  //       (response: any) => {
  //         console.log('responseexcell', response);

  //         const blobLink = response.body.filePath;
  //         const blobName = this.blobService.extractBlobName(blobLink);
  //         setTimeout(() => {
  //           this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
  //             const sasToken = tokenResponse.result;
  //             this.blobService.downloadFile(blobName, sasToken);
  //             this.loading = false;
  //           });
  //         }, 5000);
  //       },
  //       (error) => {
  //         console.error('Error fetching blob link:', error);
  //         this.loading = false;
  //       });
  //   }
  GetYearlyAnnualReport() {
   
    let payload = {
      EntityId: this.EntityId,
      yearID: this.selectedFiscalYear?.value,
      Frequency: this.selectedMonth?.value,
      Period: this.selectedPeriod?.value
    };

    this.loading = true;
    this.VariousReportService.GetYearlyAnnualReport(payload).subscribe(
      (response: any) => {
        console.log('Full response:', response); // Check if response has data

        if (response && response.body && response.body.filePath) {
          const blobLink = response.body.filePath;
          const blobName = this.blobService.extractBlobName(blobLink);
          console.log('Blob link:', blobLink);
          console.log('Blob name:', blobName);

          setTimeout(() => {
            this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
              const sasToken = tokenResponse.result;
              console.log('SAS Token:', sasToken);

              if (sasToken) {
                this.blobService.downloadFile(blobName, sasToken);
              } else {
                console.error('Error: SAS token is null or undefined.');
              }

              this.loading = false;
            }, (tokenError) => {
              console.error('Error fetching SAS token:', tokenError);
              this.loading = false;
            });
          }, 5000);
        } else {
          console.error('Error: File path not found in response.');
          this.loading = false;
        }
      },
      (error) => {
        console.error('Error fetching blob link:', error);
        this.loading = false;
      }
    );
  }

  ExportToExcelvariance() {
   
 
    let payload = {
      ClientId: this.user.getClientId(),
      Month: this.month,
      Year: this.year,
      VarianceType: this.onVariousType.value
    }

    this.loading = true;
    this.VariousReportService.ExportToExcelvariance(payload).subscribe(
      (response: any) => {
        console.log('Full response:', response); // Check if response has data

        if (response && response.body && response.body.filePath) {
          const blobLink = response.body.filePath;
          const blobName = this.blobService.extractBlobName(blobLink);
          console.log('Blob link:', blobLink);
          console.log('Blob name:', blobName);

          setTimeout(() => {
            this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
              const sasToken = tokenResponse.result;
              console.log('SAS Token:', sasToken);

              if (sasToken) {
                this.blobService.downloadFile(blobName, sasToken);
              } else {
                console.error('Error: SAS token is null or undefined.');
              }

              this.loading = false;
            }, (tokenError) => {
              console.error('Error fetching SAS token:', tokenError);
              this.loading = false;
            });
          }, 5000);
        } else {
          console.error('Error: File path not found in response.');
          this.loading = false;
        }
      },
      (error) => {
        console.error('Error fetching blob link:', error);
        this.loading = false;
      }
    );
  }
  ExportToVEExcelvariance() {

    let payload = {
      ClientId: this.user.getClientId(),
      Month: this.month,
      Year: this.year,
      VarianceType: this.onVariousType.value
    }

    this.loading = true;
    this.VariousReportService.ExportToExcelvariance(payload).subscribe(
      (response: any) => {
        console.log('Full response:', response); // Check if response has data

        if (response && response.body && response.body.filePath) {
          const blobLink = response.body.filePath;
          const blobName = this.blobService.extractBlobName(blobLink);
          console.log('Blob link:', blobLink);
          console.log('Blob name:', blobName);

          setTimeout(() => {
            this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
              const sasToken = tokenResponse.result;
              console.log('SAS Token:', sasToken);

              if (sasToken) {
                this.blobService.downloadFile(blobName, sasToken);
              } else {
                console.error('Error: SAS token is null or undefined.');
              }

              this.loading = false;
            }, (tokenError) => {
              console.error('Error fetching SAS token:', tokenError);
              this.loading = false;
            });
          }, 5000);
        } else {
          console.error('Error: File path not found in response.');
          this.loading = false;
        }
      },
      (error) => {
        console.error('Error fetching blob link:', error);
        this.loading = false;
      }
    );

  }
  ExportToExcelvarianceLeaveSummary() {
 
    let payload = {
      ClientId: this.user.getClientId(),
      Month: this.month,
      Year: this.year,
      VarianceType: this.onVariousType.value
    }

    this.loading = true;
    this.VariousReportService.ExportToExcelvarianceLeaveSummary(payload).subscribe(
      (response: any) => {
        console.log('Full response:', response); // Check if response has data

        if (response && response.body && response.body.filePath) {
          const blobLink = response.body.filePath;
          const blobName = this.blobService.extractBlobName(blobLink);
          console.log('Blob link:', blobLink);
          console.log('Blob name:', blobName);

          setTimeout(() => {
            this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
              const sasToken = tokenResponse.result;
              console.log('SAS Token:', sasToken);

              if (sasToken) {
                this.blobService.downloadFile(blobName, sasToken);
              } else {
                console.error('Error: SAS token is null or undefined.');
              }

              this.loading = false;
            }, (tokenError) => {
              console.error('Error fetching SAS token:', tokenError);
              this.loading = false;
            });
          }, 5000);
        } else {
          console.error('Error: File path not found in response.');
          this.loading = false;
        }
      },
      (error) => {
        console.error('Error fetching blob link:', error);
        this.loading = false;
      }
    );
  }
  GetPaycodeMasterss() {
  
    let payload = {
      Paycode: this.paycode

    }

    this.loading = true;
    this.VariousReportService.GetPaycodeMasterExportToExcel(payload).subscribe(
      (response: any) => {
        console.log('Full response:', response); // Check if response has data

        if (response && response.body && response.body.filePath) {
          const blobLink = response.body.filePath;
          const blobName = this.blobService.extractBlobName(blobLink);
          console.log('Blob link:', blobLink);
          console.log('Blob name:', blobName);

          setTimeout(() => {
            this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
              const sasToken = tokenResponse.result;
              console.log('SAS Token:', sasToken);

              if (sasToken) {
                this.blobService.downloadFile(blobName, sasToken);
              } else {
                console.error('Error: SAS token is null or undefined.');
              }

              this.loading = false;
            }, (tokenError) => {
              console.error('Error fetching SAS token:', tokenError);
              this.loading = false;
            });
          }, 5000);
        } else {
          console.error('Error: File path not found in response.');
          this.loading = false;
        }
      },
      (error) => {
        console.error('Error fetching blob link:', error);
        this.loading = false;
      }
    );
  }




  formatDate(scheduledOn: any) {
    throw new Error('Method not implemented.');
  }


  applyFilters() {
    // Ensure that the selected value is available
    if (!this.onVariousType) {
      console.error('No selection made from the dropdown', this.onVariousType);
      return;
    }

    // Ensure a date is selected if required
    if (this.showDatepicker && !this.selectedStartDate) {
      console.error('Please select a start date');
      return;
    }

    // Use if-else to determine the action based on the selected value
    if (this.onVariousType.value === 'InternationalWorker') {
      console.log('sdsd', this.onVariousType);
      this.GetVarianceDetailed();
    } else if (this.onVariousType.value === 'LeaveClosingBalance') {
      this.GetLeaveSummaryReports();
    } else if (this.onVariousType.value === 'Paycode_Master') {
      this.GetPaycodeMasters();
    } else if (this.onVariousType.value === 'VE') {
      this.GetVEVarianceDetail()
    } else {
      console.error('Invalid selection');
    }
  }

  exportsReports() {
    if (!this.onVariousType) {
      console.error('No selection made from the dropdown', this.onVariousType);
      return;
    }

    // Ensure a date is selected if required
    if (this.showDatepicker && !this.selectedStartDate) {
      console.error('Please select a start date');
      return;
    }

    // Use if-else to determine the action based on the selected value
    if (this.onVariousType.value === 'InternationalWorker') {
      console.log('sdsd', this.onVariousType);
      this.ExportToExcelvariance();
    } else if (this.onVariousType.value === 'LeaveClosingBalance') {
      this.ExportToExcelvarianceLeaveSummary();
    } else if (this.onVariousType.value === 'Paycode_Master') {
      this.GetPaycodeMasterss();
    } else if (this.onVariousType.value === 'VE') {
      this.ExportToVEExcelvariance();
    }
    else if (this.onVariousType.value === 'YearlyAnnaulReturnData') {
      this.GetYearlyAnnualReport();
    }
    else {
      console.error('Invalid selection');
    }

  }

}
