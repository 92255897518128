<div class="col" style="width: 100%; margin-top: 10px;">
    <div class="col-md-12 colpadding0" style="height: 35px;">
        <button class="figma-btn-blue " 
            (click)="openUploadPopup()">
            <img src="../assets/vectors/uploadWhite.svg" alt="" >Upload
        </button>
        <button class="figma-btn-blue "  type="submit"
            (click)="addNew()">
            <img src="../assets/vectors/Plus.svg" alt="">Add New
        </button>
    </div>
</div>

<kendo-grid class="custom-grid" *ngIf="showGrid" [data]="gridData" [columnMenu]="true" [pageSize]="pageSize" [pageable]="true"
    (pageChange)="pageChange($event)" [sortable]="true"  [resizable]="true" style="margin-top: 10px;" [skip]="skip">

    <kendo-grid-column field="sM_Name" title="State" [title]="'State'" >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div  class="cell-content">{{ dataItem.sM_Name }}</div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="notification_Number" title="Notification ID">
        <ng-template  kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div  class="cell-content">{{ dataItem.notification_Number }}</div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="industryNotification_Number" title="Industry Notification ID" >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div  class="cell-content" >{{ dataItem.industryNotification_Number }}</div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="industry_Type" title="Establishment Type">
        <ng-template  kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div  class="cell-content" >{{ dataItem.industry_Type }}</div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="industry" title="Industry Type" >
        <ng-template  kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="cell-content">{{ dataItem.industry }}</div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="skill_Category" title="Skill Category" >
        <ng-template  kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="cell-content">{{ dataItem.skill_Category }}</div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="designation" title="Designation" >
        <ng-template  kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="cell-content">{{ dataItem.designation }}</div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="zone" title="Zone">
        <ng-template  kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div  class="cell-content">{{ dataItem.zone }}</div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="mW_Value" title="MW Value">
        <ng-template  kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="cell-content">{{ dataItem.mW_Value }}</div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="basic" title="Basic">
        <ng-template  kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div  class="cell-content" *ngIf="isCellBeingEdited(rowIndex, 'basic'); else displayBasic">
                <input class="f-textbox" type="number" [value]="dataItem.basic"
                    (input)="onBasicChange($event, dataItem)">
            </div>
            <ng-template #displayBasic>
                <div class="cell-content" (click)="editRow(rowIndex, 'basic')">{{ dataItem.basic }}</div>
            </ng-template>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="da" title="DA">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="cell-content"  *ngIf="isCellBeingEdited(rowIndex, 'da'); else displayDA">
                <input class="f-textbox" type="number" [value]="dataItem.da" (input)="onDAChange($event, dataItem)">
            </div>
            <ng-template #displayDA>
                <div class="cell-content" (click)="editRow(rowIndex, 'da')">{{ dataItem.da }}</div>
            </ng-template>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="hra" title="HRA" >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="cell-content" *ngIf="isCellBeingEdited(rowIndex, 'hra'); else displayHRA">
                <input class="f-textbox" type="number" [value]="dataItem.hra" (input)="onHRAChange($event, dataItem)">
            </div>
            <ng-template #displayHRA>
                <div class="cell-content" (click)="editRow(rowIndex, 'hra')">{{ dataItem.hra }}</div>
            </ng-template>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="vda" title="VDA" >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="cell-content"  *ngIf="isCellBeingEdited(rowIndex, 'vda'); else displayVDA">
                <input class="f-textbox" type="number" [value]="dataItem.vda" (input)="onVDAChange($event, dataItem)">
            </div>
            <ng-template #displayVDA>
                <div class="cell-content" (click)="editRow(rowIndex, 'vda')">{{ dataItem.vda }}</div>
            </ng-template>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="spl_Allowance" title="Special Allowance">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="cell-content" *ngIf="isCellBeingEdited(rowIndex, 'spl_Allowance'); else displaySplAllowance">
                <input class="f-textbox" type="number" [value]="dataItem.spl_Allowance"
                    (input)="onSplAllowanceChange($event, dataItem)">
            </div>
            <ng-template #displaySplAllowance>
                <div class="cell-content" (click)="editRow(rowIndex, 'spl_Allowance')">{{ dataItem.spl_Allowance }}</div>
            </ng-template>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="Action" title="Action" >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
           <div class="cell-content">
            <img src="../assets/vectors/save1.svg" alt="Save Icon" class="svg-icon" title="Save" 
            (click)="onSave(dataItem)" />
        <img src="../assets/vectors/delete.svg" alt="Delete Icon" class="svg-icon" title="Delete"
            (click)="confirmAlert(dataItem)" />
           </div>
        </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-input></kendo-pager-input>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>
</kendo-grid>


<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270"  [id]="deleteId" [Data]="alertData"
    (close)="closePopup()" (confirm)="DeleteMWMaster($event)"></app-alert-popup>

<div kendoDialogContainer *ngIf="uploadPopup">

    <app-mwupload-popup (close)="closePopup()"></app-mwupload-popup>
</div>
<div kendoDialogContainer *ngIf="AddNewPopup">

    <app-mwadd-new-popup (close)="closePopup()"></app-mwadd-new-popup>
</div>