<div class="row colpadding0 " style="margin-bottom: 15px; margin-top: 10px;">
    <div class="col-md-8 colpadding0" >
        <kendo-multiselecttree #multiselectTree placeholder="Entity/Sub-Entity/Location" kendoMultiSelectTreeExpandable
            [kendoMultiSelectTreeHierarchyBinding]="clraBranchDropdown"
            [value]="selectedBranches" formControlName="branches" childrenField="children" textField="name" valueField="id"
            [tagMapper]="tagMapper" (valueChange)="onBranchChange($event)" [expandedKeys]="['0']"
            [popupSettings]="popupSettings" class="f-textbox multi-box custom-multiselect-other">
        </kendo-multiselecttree>
        <app-dropdown-list [data]="DisplayDropdownData" [selectedValue]="selectedComplianceType" [width]="'200px'"
            textField="text" valueField="value" (valueChange)="onDropdownValueChange($event)"
            [hasError]="!selectedComplianceType.value && showValidationErrors">
        </app-dropdown-list>
    </div>
    <div class="col-md-4 colpadding0" >
        <button (click)="exportToExcel()" class="exportbtn">
            <img src="../assets/vectors/ExportButton.svg" alt="">
        </button>
        <button class="figma-btn-white apply-btn" style="float: right;" type="submit" (click)="applyFilter()">
            <img src="../assets/vectors/ApplyFilter.svg" alt="">
        </button>
        <button class="figma-btn-white clear-btn" style="float: right;" (click)="clearFilter()">
            <img src="../assets/vectors/ClearFilter.svg" alt="">
        </button>
    </div>
</div>
<kendo-grid 
        *ngIf="showGrid"
        [data]="gridData" 
        [pageSize]="10" 
        [pageable]="true" 
        [skip]="Skip"
        [sortable]="true"
        (pageChange)="pageChange($event)" class="custom-grid">
            <kendo-grid-column field="complianceID" title="Comp.Id" [width]="100" [sortable]="true" class="cell-content">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.complianceID" class="cell-content" >
                        {{ dataItem.complianceID }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="branch" title="Location" [width]="90" [sortable]="true" class="cell-content">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.branch" class="cell-content"
                        >
                        {{ dataItem.branch }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="startDate" title="Activation Date" [sortable]="true" class="cell-content">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.startDate" class="cell-content"
                        >
                        {{ dataItem.startDate }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="shortForm" title="Compliance" [sortable]="true" [width]="180" class="cell-content">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.shortForm" class="cell-content"
                        >
                        {{ dataItem.shortForm }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="requiredForms" title="Form" [sortable]="true" [width]="220" class="cell-content">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.requiredForms" class="cell-content"
                       >
                        {{ dataItem.requiredForms }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="frequency" title="Frequency" [width]="90" [sortable]="true" class="cell-content">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.frequency" class="cell-content"
                       >
                        {{ dataItem.frequency }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="performer" title="Performer"  [sortable]="true" [width]="120" class="cell-content">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.performer" class="cell-content"
                         >
                        {{ dataItem.performer }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="reviewer" title="Reviewer" [sortable]="true" class="cell-content">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.reviewer" class="cell-content"
                        >
                        {{ dataItem.reviewer }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
                <kendo-pager-next-buttons></kendo-pager-next-buttons>
                <kendo-pager-input></kendo-pager-input>
                <kendo-pager-info></kendo-pager-info>
                <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
        </ng-template>
</kendo-grid>
   

<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>