
<h4 class="Applicable-head">Applicable Status</h4>

<ul class="timeline">
    <li>
        <span class="year">2015</span>
        <div class="content">
            Andhra Pradesh (Issuance Of Integrated Registration And Extent And Furnishing Of Combined Returns Under Commencement Various Labour Laws By Certain Establishments) Act, 2015
        </div>
    </li>
    <li>
        <span class="year">1995</span>
        <div class="content">
            Assam Industrial Establishment (Conferment of Permanent Status to Workmen) Act, 1985 and Assam industrial Establishment (Conferment of Permanent Status to Workmen) Rules, 1995
        </div>
    </li>
    <li>
        <span class="year">1990</span>
        <div class="content">
            Andhra Pradesh Shops & Establishments Act, 1988 & Andhra Pradesh Shops & Establishments Rules, 1990
        </div>
    </li>
    <li>
        <span class="year">1988</span>
        <div class="content">
            Andhra Pradesh Labour Welfare Fund Act, 1987, and Andhra Pradesh Labour Welfare Fund Rules, 1988
        </div>
    </li>
    <li>
        <span class="year">1974</span>
        <div class="content">
            Andhra Pradesh Factories & Establishments (National, Festival & other Holidays) Act, 1974 & Andhra Pradesh Factories & Establishments (National, Festival & other Holidays) Rules, 1974
        </div>
    </li>
</ul>