<div class="row colpadding0 " style="margin-bottom: 10px; margin-top: 10px;">
    <div class="col-md-8 colpadding0">
        <app-dropdown-list [data]="dropdownDataPrimary" placeholder="Select" [selectedValue]="defaultSelectedValue" [width]="'220px'"
             textField="text" valueField="value" (valueChange)="onDropdownValueChange($event,'State')"
            style="margin-right: 10px; ">
        </app-dropdown-list>
        <app-dropdown-list [data]="dropdownDataSecondary" [selectedValue]="defaultActSelectedValue" [width]="'280px'"
             textField="text" valueField="value"(valueChange)="onDropdownValueChange($event,'Act')">
        </app-dropdown-list>
    </div>
    <div class="col-md-4 colpadding0" >
        <button class="figma-btn-blue" style="float: right;" (click)="openUploadPopup()">
            <img src="../assets/vectors/uploadWhite.svg">Upload
        </button>
        <button class="figma-btn-white clear-btn" style="float: right;" (click)="clearFilter()">
            <img src="../assets/vectors/ClearFilter.svg" alt="">
        </button>
    </div>
</div>

        <kendo-grid class="custom-grid"
            [data]="gridData" 
            [pageSize]="10" 
            [pageable]="true" 
            [skip]="Skip"
            (pageChange)="pageChange($event)"
            [loading]="GridLoading"
            [columnMenu]="{filter:true}">
            <ng-template *ngIf="GridLoading" kendoGridLoadingTemplate class="loader-overlay">
                <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
            </ng-template>
            <kendo-grid-column field="stateName" title="State Name">
                <ng-template kendoGridHeaderTemplate>
                    <span title="State Name">State Name</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.stateName" class="cell-content">
                        {{ dataItem.stateName }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="actName" title="Act Name">
                <ng-template kendoGridHeaderTemplate>
                    <span title="Act Name">Act Name</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.actName" class="cell-content">
                        {{ dataItem.actName }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="avacoM_BranchName" title="Branch Name">
                <ng-template kendoGridHeaderTemplate>
                    <span title="Branch Name">Branch Name</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.avacoM_BranchName" class="cell-content">
                        {{ dataItem.avacoM_BranchName }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="regional" title="Regional" [width]="180">
                <ng-template kendoGridHeaderTemplate>
                    <span title="Regional">Regional</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div *ngIf="dataItem.regional === 1"  class="cell-content" style="margin-left: 50px;">
                        <img [title]="dataItem.action" *ngFor="let icon of parentColumns[4].filteredIcons"
                        [src]="icon.url" class="svg-icon"
                        [alt]="icon.alt"
                             (click)="onIconClick('regional', dataItem,icon)">
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="english" title="English" [width]="180">
                <ng-template kendoGridHeaderTemplate>
                    <span title="English">English</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div *ngIf="dataItem.english === 1" class="cell-content" style="margin-left: 50px;">
                        <img [title]=""  *ngFor="let icon of parentColumns[4].filteredIcons" class="svg-icon"
                        [src]="icon.url"
                        [alt]="icon.alt"
                             (click)="onIconClick('english', dataItem,icon)">
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="acknowledgement" title="Acknowledgement" [width]="180">
                <ng-template kendoGridHeaderTemplate>
                    <span title="State Name">State Name</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div *ngIf="dataItem.acknowledgement === 1" [title]="dataItem.acknowledgement" class="cell-content" style="margin-left: 50px;">
                        <img *ngFor="let icon of parentColumns[4].filteredIcons" class="svg-icon"
                        [src]="icon.url"
                        [alt]="icon.alt"
                             (click)="onIconClick('acknowledgement', dataItem,icon)">
                    </div>
                </ng-template>
            </kendo-grid-column>
            <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
                <kendo-pager-next-buttons></kendo-pager-next-buttons>
                <kendo-pager-input></kendo-pager-input>
                <kendo-pager-info></kendo-pager-info>
                <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
            </ng-template>

        </kendo-grid>

<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>
<app-review-regulatory-abstract-upload-popup  *ngIf="uploadOpen" (close)="closeUploadDialog()"></app-review-regulatory-abstract-upload-popup>

<app-adobe-viewer [fileBlob]="fileBlob" *ngIf="fileBlob" (close)="closeViewer()"></app-adobe-viewer>