import { NgIf } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { InvoiceDetailsService } from '../../../service/invoice-details.service';
import { GridModule } from '@progress/kendo-angular-grid';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { UserService } from '../../../service/user.service';

import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { parseNumber } from '@progress/kendo-angular-intl';

@Component({
  selector: 'app-invoice-creation',
  standalone: true,
  imports: [ComboBoxComponent,FormsModule, NgIf, ReactiveFormsModule,GridModule,AlertPopupComponent, DropdownListComponent],
  templateUrl: './invoice-creation.component.html',
  styleUrl: './invoice-creation.component.css'
})
export class InvoiceCreationComponent implements OnInit{

  invoiceDropdown:any[] =[]
  clientDropdown:any[] =[]
  monthDropdown:any[] =[]
  selectedInvoiceType = { name: 'Select', id: 0 };
  selectedClientType = { name: 'Select', id: 0 };
  selectedmonthType = { name: 'Select', id: 0 };
  clientList: any[] = [];
  monthdropdownData: any;
  lstSplitCommercialDetails: any[] = [];
  recurringInvoiceData:any;
  grandtotalgrid ={
    name: 'Grand Total',
    grandtotal: '',
    adjustment:'',
    grandtotalwithadjustment:''
  };
  gridData:any[] =[]
  recurringData = [
    { actual: 0, adjustments: 0, total: 0 },
    { actual: 0, adjustments: 0, total: 0 },
    { actual: 0, adjustments: 0, total: 0 },
    { actual: 0, adjustments: 0, total: 0 }
  ];
  objectgrid = {
    activityName: 0 ,actual: 0, adjustments: 0, total: 0
  };
  totalgrid = {
    activityName:'Total', actual:0, adjustments:'', total:0
  };
  gstdetailsGrid = {
    invoiceAddress: '', 
    dispatchAddress:'',
    pO_Number:0,
    pO_Date:0,
    fromState:0,
    toState:0,
    gstNumber:0,
    gmId:0,
    cgst:0,
    sgst:0,
    igst:0
  };
  liasoningandInspectionData = {
    totalAmountWithAdjustment: 0,
    totalCGSTAmount: 0,
    totalSGSTAmount: 0,
    totalIGSTAmount: 0,
    grandTotal: 0
  };
  fieldType :any;
  clientType:any;
  selectedYear:any;
  selectedMonth:any;
  dropzoneMessage: string = "Drag your files here or ";
  alertData={alertMessage:'',alertVisible:''}
  selectedFile?: File;
  adjustment:any[] = [
    { text: '+', value: '+' },
    { text: '-', value: '-' }
  ];
  
  adjustmentSelected = { text: '+', value: '+' };
  @Output() close = new EventEmitter<void>();
  invoiceType: any;
  constructor(
    private invoice:InvoiceDetailsService,
    private user:UserService
  ){}
  ngOnInit() {
    this.getInvoiceType();
    this.setCurrentMonthDropdown(); 
  }
  // setCurrentMonthDropdown() {
  //   const currentDate = new Date();
  //   const formattedMonthYear = this.formatDate(currentDate);
  //   const monthNumber = this.getMonthNumber(currentDate);  // Get numeric month (01)
  //   const year = currentDate.getFullYear();
  
  //   // Add only the current month-year to monthDropdown array
  //   this.monthDropdown.push({
  //     id: Number(monthNumber),    // Assign month number as a numeric value
  //     name: formattedMonthYear    // e.g., 'Jan-2024'
  //   });
  
  //   // Set selected value as default (keep id as a number)
  //   this.selectedmonthType = {
  //     name: formattedMonthYear,   // 'Jan-2024'
  //     id: Number(monthNumber)     // Numeric month (e.g., 1 for January)
  //   };
  // }
  setCurrentMonthDropdown() {
    const currentDate = new Date();
  
    // Format the month and year together for the dropdown (e.g., 'Jan-2024')
    const formattedMonthYear = this.formatDate(currentDate);
  
    // Extract the year and month
    const year = currentDate.getFullYear();  // Get the 4-digit year (e.g., 2024)
  
    // Get month in "01", "02", ... format
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  
    // Add only the current month-year to monthDropdown array (unchanged)
    this.monthDropdown.push({
      id: Number(month),    // Assign month number as a numeric value
      name: formattedMonthYear    // e.g., 'Jan-2024'
    });
  
    // Set selected value as default (unchanged)
    this.selectedmonthType = {
      name: formattedMonthYear,   // 'Jan-2024'
      id: Number(month)           // Numeric month (e.g., 1 for January)
    };
  
    // Store the extracted month and year in separate variables
    this.selectedMonth = month;  // "01" for January, "02" for February, etc.
    this.selectedYear = year;    // "2024"
  }
  

  // Format date to 'MMM-yyyy' (e.g., 'Jan-2024')
  formatDate(date: Date): string {
    const month = date.toLocaleString('en-US', { month: 'short' }); // 'Jan', 'Feb', etc.
    const year = date.getFullYear(); // e.g., '2024'
    return `${month}-${year}`;
  }

  // Get month number in 'MM' format (e.g., '01' for January)
  getMonthNumber(date: Date): number {  // Return a number instead of a string
    const month = date.getMonth() + 1;  // getMonth() is 0-based, so we add 1
    return month;  // Return the month as a number (e.g., 1 for January)
  }

  // Handle dropdown value change
  onDropdownValueChange(data: any, field: string) {

    this.fieldType = data.value;

    if(field == 'invoiceType'){
    console.log('Invoice Type:', data)
    if(this.fieldType == 'RRC'){
      this.gridData = [
        { heading: 'CGST@0%', actual : 0, adjustment:0, total: 0 },
         { heading: 'SGST@0%', actual : 0, adjustment:0, total: 0 },
         { heading: 'IGST@0%', actual : 0, adjustment:0, total: 0 },
         { heading: 'Total', actual : 0, adjustment:0, total: 0 }
       ];
     
    }
    else if(this.fieldType == 'OT' ||this.fieldType == 'INS'){

    }
   
this.invoiceType = data.value;
    this.getClientsMaster(data.value)

    }
    else if(field == 'clientType'){
      console.log('clientType:', data)
      this.clientType = data.value;
      this.getinvoiceCreation();
      }
      else if(field == 'monthYear'){
        const [month, year] = data?.split('-');  // Split the '01-2024' format
      }
      else if(field == 'adjust'){
        this.adjustmentSelected = data.value;
      }
  }


  getInvoiceType() {
    this.invoice.getInvoiceType().subscribe({
      next: (response) => {
        const data = response.body;
        this.invoiceDropdown = [
          ...data.map((item: any) => ({
            text: item.text,
            value: item.value
          }))
        ];
       console.log('clienttype',this.invoiceDropdown)

      },
      error: (error) => {
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }

  
  getClientsMaster(type:any) {
    this.invoice.getClientDetails(type).subscribe({
      next: (response) => {
        const data = response.body;
        this.clientDropdown = [
          ...data.map((item: any) => ({
            text: item.text,
            value: item.value
          }))
        ];
       console.log('clientDropdown',this.clientDropdown)

      },
      error: (error) => {
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }
  updateTotal() {
    const actualValue = parseNumber(this.objectgrid.actual) || 0;
    const adjustmentValue = parseNumber(this.objectgrid.adjustments) || 0;

    const cgstPercentage = this.gstdetailsGrid.cgst || 0;
    const sgstPercentage = this.gstdetailsGrid.sgst || 0;
    const igstPercentage = this.gstdetailsGrid.igst || 0;

    const cgstAdjustment = (adjustmentValue * cgstPercentage) / 100;
    const sgstAdjustment = (adjustmentValue * sgstPercentage) / 100;
    const igstAdjustment = (adjustmentValue * igstPercentage) / 100;

    if (this.adjustmentSelected.value === '+') {
      this.objectgrid.total = actualValue + adjustmentValue;
      this.totalgrid.adjustments =  adjustmentValue;
      this.totalgrid.total = actualValue + adjustmentValue;

        this.gridData[0].adjustments = cgstAdjustment;
        this.gridData[0].total = parseFloat(this.gridData[0].actual) + cgstAdjustment;

        this.gridData[1].adjustments = sgstAdjustment;
        this.gridData[1].total = parseFloat(this.gridData[1].actual) + sgstAdjustment;

        this.gridData[2].adjustments = igstAdjustment;
        this.gridData[2].total = parseFloat(this.gridData[2].actual) + igstAdjustment;

        this.gridData[3].adjustments = cgstAdjustment + sgstAdjustment + igstAdjustment;
        this.gridData[3].total = parseFloat(this.gridData[0].total) + parseFloat(this.gridData[1].total) + parseFloat(this.gridData[2].total);

        this.grandtotalgrid.adjustment = adjustmentValue + this.gridData[3].adjustments;
        this.grandtotalgrid.grandtotalwithadjustment = (parseFloat(this.grandtotalgrid.grandtotalwithadjustment) + parseFloat(this.grandtotalgrid.adjustment)).toString();
      
    } else if (this.adjustmentSelected.value === '-') {
      this.objectgrid.total = actualValue - adjustmentValue;
      this.totalgrid.adjustments = this.adjustmentSelected.value + adjustmentValue;
      this.totalgrid.total = actualValue - adjustmentValue;

        this.gridData[0].adjustments = this.adjustmentSelected.value + cgstAdjustment;
        this.gridData[0].total = this.gridData[0].actual - cgstAdjustment;

        this.gridData[1].adjustments = this.adjustmentSelected.value + sgstAdjustment;
        this.gridData[1].total = this.gridData[1].actual - sgstAdjustment;

        this.gridData[2].adjustments = this.adjustmentSelected.value + igstAdjustment;
        this.gridData[2].total =this.gridData[2].actual - igstAdjustment;

        this.gridData[3].adjustments = this.adjustmentSelected.value +  (cgstAdjustment + sgstAdjustment + igstAdjustment);
        const tax = cgstAdjustment + sgstAdjustment + igstAdjustment;
        this.gridData[3].total = parseFloat(this.gridData[0].total) + parseFloat(this.gridData[1].total) + parseFloat(this.gridData[2].total);

        const total = adjustmentValue + tax;
        this.grandtotalgrid.adjustment = this.adjustmentSelected.value + total;
        const grandtotal = parseFloat(this.grandtotalgrid.grandtotalwithadjustment) - total
        this.grandtotalgrid.grandtotalwithadjustment = grandtotal.toString();

    }
  }

  onAdjustValueChange(event:any) {
    this.adjustmentSelected = event;
    this.updateTotal(); // Recalculate total when dropdown value changes
  }
  submitData(){
   console.log(this.recurringData);
   console.log(this.liasoningandInspectionData);
   let payload = {

    "loggedUserId": "",
    "invoiceType": "",
    "otInvoiceType": "",
    "clientid": "",
    "yearid": "",
    "monthid": "",
    "actualTLCharges": "",
    "adjustmentSign": "",
    "adjustmentAmount": "",
    "totalTLCharges": "",
    "serviceTaxBefore": "",
    "serviceTaxAfter": "",
    "totalServiceTax": "",
    "sbcBefore": "",
    "sbcAfter": "",
    "totalSBC": "",
    "kkcBefore": "",
    "kkcAfter": "",
    "totalKKC": "",
    "actualsTotal": "",
    "adjustmentsTotal": "",
    "grandTotal": "",
    "narration": "",
    "rrcGrandTotal": "",
    "recurringactualtotal": 0,
    "recurringadjustmenttotal": 0,
    "recurringgrandtotal": 0,
    "oBprojectID": "",
    "obInvoiceType": "",
    "cgstName": "",
    "sgstName": "",
    "igstName": "",
    "cgstAmountPer": 0,
    "sgstAmountPer": 0,
    "igstAmountPer": 0,
    "cgstAmountBefore": "",
    "sgstAmountBefore": "",
    "igstAmountBefore": "",
    "cgstAmountAfter": "",
    "sgstAmountAfter": "",
    "igstAmountAfter": "",
    "totalCGST": "",
    "totalSGST": "",
    "totalIGST": "",
    "gmCodeExists": true,
    "invoiceGenrtionType": "",
    "invoiceNumber": "",
    "invoiceNumbers": [
      {
        "text": "",
        "value": "",
        "sort": 0
      }
    ],
    "commercial_InvoiceType": "",
    "commercial_SplitInvoiceType": "",
    "invoiceApproved": true,
    "invoiceApprovedChangedSource": "",
    "invoiceSupportingDocument": "",
    "gstDetails_Lst": [
      {
        "cgM_ID": 0,
        "cgM_Client_ID": "",
        "cgstAmountPer": "",
        "sgstAmountPer": "",
        "igstAmountPer": ""
      }
    ],
    "getInvoiceActaulAmount": {
      "statuscode": "",
      "statusmessage": "",
      "actualamount": 0,
      "adjustedamount": 0
    },
    "clientsMaster_lst": [
      {
        "text": "",
        "value": "",
        "sort": 0
      }
    ],
    "serviceTaxMaster_lst": [
      {
        "sM_ID": 0,
        "sT_FromDate": "2024-11-08T05:25:45.401Z",
        "sT_ToDate": "2024-11-08T05:25:45.401Z",
        "sT_EffectiveDate": "2024-11-08T05:25:45.401Z",
        "sT_Type": "",
        "sT_Description": "",
        "sT_Value": 0,
        "sT_Status": "",
        "sT_CreatedBy": "",
        "sT_CreatedDate": "2024-11-08T05:25:45.401Z",
        "sT_ModifiedBy": "",
        "sT_ModifiedDate": "2024-11-08T05:25:45.401Z"
      }
    ],
    "stateMaster_lst": [
      {
        "text": "",
        "value": "",
        "sort": 0
      }
    ],
    "locationMaster_lst": [
      {
        "text": "",
        "value": "",
        "sort": 0
      }
    ],
    "branchMaster_lst": [
      {
        "text": "",
        "value": "",
        "sort": 0
      }
    ],
    "get_OneTimeProjectsForInvoice": [
      {
        "slno": 0,
        "riM_InvoiceNo": "",
        "projectid": "",
        "activityid": "",
        "activityname": "",
        "statecode": "",
        "statename": "",
        "locationcode": "",
        "locationname": "",
        "branchname": "",
        "invoiceamount": 0,
        "riA_AMOUNT_ADJUSTMENTS": 0,
        "riM_Type": "",
        "cgstAmount": 0,
        "sgstAmount": 0,
        "igstAmount": 0
      }
    ],
    "getRecurringInvoiceActaulAmount": [
      {
        "actualamount": 0,
        "activityName": "",
        "activityID": "",
        "empCount": 0,
        "invoiceAddress": "",
        "dispatchAddress": "",
        "pO_Number": "",
        "pO_Date": "",
        "cgst": 0,
        "sgst": 0,
        "igst": 0,
        "fromState": "",
        "toState": "",
        "gstNumber": "",
        "gmId": "",
        "adjustmentRecord": "",
        "adjustmentRecurring": "",
        "totalPerRow": ""
      }
    ],
    "recurring_Invoice_Details": [
      {
        "rI_ID": 0,
        "rI_ClientID": "",
        "rI_InvoiceNo": "",
        "rI_Inv_Type": "",
        "rI_Element": "",
        "rI_Amount": 0,
        "rI_EmpCount": 0,
        "rI_AdjustedAmount": 0,
        "rI_TotalAmount": 0,
        "rI_CreatedBy": "",
        "rI_CreatedDate": "2024-11-08T05:25:45.401Z",
        "rI_ModifiedBy": "",
        "rI_ModifiedDate": "2024-11-08T05:25:45.401Z",
        "rI_Version": 0,
        "rI_ElementName": ""
      }
    ],
    "regulatory_Invoice_Adjustments": [
      {
        "riA_InvoiceID": "",
        "riA_Invoice_No": "",
        "riA_Amount": 0,
        "riA_Amount_Adjustments": 0,
        "riA_ServiceTax": 0,
        "riA_ServiceTax_Adjustments": 0,
        "riA_Educational_Cess": 0,
        "riA_Educational_Cess_Adjustments": 0,
        "riA_Sec_Educational_Cess": 0,
        "riA_Sec_Educational_Cess_Adjustments": 0,
        "riA_Swachh_Bharat_Cess": 0,
        "riA_Swachh_Bharat_Cess_Adjustments": 0,
        "riA_Actual_Amount": 0,
        "riA_Adjustment_Amount": 0,
        "riA_krishi_kalyan_amount": 0,
        "riA_krishi_kalyan_adjustment": 0,
        "riA_IncomeTax_Amount": 0,
        "riA_IncomeTax_Adjustments": 0,
        "riA_CreatedBy": "",
        "riA_CreatedDate": "2024-11-08T05:25:45.401Z",
        "riA_ModifiedBy": "",
        "riA_ModifiedDate": "2024-11-08T05:25:45.401Z",
        "riA_Version": 0,
        "riA_CGST": 0,
        "riA_CGST_Adjustments": 0,
        "riA_SGST": 0,
        "riA_SGST_Adjustments": 0,
        "riA_IGST": 0,
        "riA_IGST_Adjustments": 0,
        "riaV_IGST_Adjustments": 0,
        "riaV_IGST": 0,
        "riaV_CGST": 0,
        "riaV_CGST_Adjustments": 0,
        "riaV_SGST": 0,
        "riaV_SGST_Adjustments": 0
      }
    ],
    "lstSplitCommercialDetails": [
      {
        "clientId": "",
        "commercialName": "",
        "commercialId": "",
        "commercialPrice": "",
        "cgstAmountPercentage": "",
        "sgstAmountPercentage": "",
        "igstAmountPercentage": "",
        "cgstAmount": "",
        "sgstAmount": "",
        "igstAmount": "",
        "totalAmount": "",
        "commercialInvoice_SplitType": "",
        "invoice_Address": "",
        "dispatch_Address": "",
        "pO_Number": "",
        "pO_Date": "",
        "adjustmentPrice": "",
        "adjustmentSign": "",
        "fromState": "",
        "toState": "",
        "gstNumber": "",
        "gmid": "",
        "uniqueId": ""
      }
    ],
    "oneTimeInvoiceDetails": [
      {
        "invoiceNumber": "",
        "actualAmount": "",
        "adjustmentSign": "",
        "adjustmentAmount": "",
        "totalAmount": "",
        "cgstAmount": "",
        "sgstAmount": "",
        "igstAmount": "",
        "cgstPer": "",
        "sgstPer": "",
        "igstPer": "",
        "invoiceAddress": "",
        "dispatchAddress": "",
        "pO_Number": "",
        "pO_Date": "",
        "fromState": "",
        "toState": "",
        "gstNumber": "",
        "gmid": "",
        "invoiceDescription": ""
      }
    ],
    "get_OneTimeProjectsForInvoice_lst": [
      {
        "slNO": 0,
        "selected": true,
        "projectId": "",
        "activityId": "",
        "activityName": "",
        "stateCode": "",
        "stateName": "",
        "locationCode": "",
        "locationName": "",
        "branchName": "",
        "actualAmount": 0,
        "adjustmentSign": "",
        "value": 0,
        "adjustmentAmount": 0,
        "totalAmount": 0,
        "stOnActual": 0,
        "stOnAdjustment": 0,
        "serviceTaxTotal": 0,
        "sbcOnActual": 0,
        "sbcOnAdjustment": 0,
        "sbcTotal": 0,
        "itOnActual": 0,
        "itOnAdjustment": 0,
        "itTotal": 0,
        "kkcOnActual": 0,
        "kkcOnAdjustment": 0,
        "kkcTotal": 0,
        "cgstAmount": 0,
        "sgstAmount": 0,
        "igstAmount": 0,
        "actualsTotal": 0,
        "adjustmentsTotal": 0,
        "grandTotal": 0,
        "remarks": ""
      }
    ],
    "lstLiasoningActivities": [
      {
        "oneTimeProjectId": "",
        "activityId": "",
        "activityName": "",
        "code": "",
        "invoiceAmount": "",
        "charges": "",
        "cgstAmount": "",
        "cgstPercentage": "",
        "sgstAmount": "",
        "sgstPercentage": "",
        "igstAmount": "",
        "igstPercentage": "",
        "totalTaxAmount": "",
        "totalAmount": "",
        "selected": true,
        "typeId": "",
        "typeName": "",
        "statuscode": "",
        "statusmessage": "",
        "description": "",
        "groupByFlag": 0,
        "gmId": "",
        "gstNumber": "",
        "invoiceAddress": "",
        "dispatchAddress": "",
        "adjustmentSign": "",
        "adjustmentPrice": "",
        "totalWithGST": "",
        "chargeType": "",
        "uniqueId": ""
      }
    ],
    "lstOffLineDetails": [
      {
        "clientId": "",
        "invoiceAmount": "",
        "cgstAmount": "",
        "cgstPercentage": "",
        "sgstAmount": "",
        "sgstPercentage": "",
        "igstAmount": "",
        "igstPercentage": "",
        "totalWithGST": "",
        "uniqueId": "",
        "invoiceNumber": "",
        "description": "",
        "oldInvoiceAmount": ""
      }
    ],
    "errorFlag": "",
    "errorMessage": "",
    "draftOriginalInvoice": "",
    "isWithinAgreementEndDate": true,
    "viewType": "",
    "adjustmentSigns": [
      {
        "disabled": true,
        "group": {
          "disabled": true,
          "name": ""
        },
        "selected": true,
        "text": "",
        "value": ""
      }
    ],
    "rrcGrandActualTotal": "",
    "rrcGrandAdjustmentTotal": "",
    "insertStatus": "",
    "generate_RegulatoryInvoice": {
      "statuscode": "",
      "statusmessage": ""
    }
  }
  }

  public onFileSelect(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
        this.selectedFile = input.files[0];
        console.log('Selected file:', this.selectedFile);
        const fileExtension = this.selectedFile.name.split('.').pop()?.toLowerCase();
        if (fileExtension !== 'xlsx') {
            this.selectedFile = undefined;
            this.alertData = {
                alertMessage: 'Only .xlsx files are allowed.',
                alertVisible: 'error'
            };           
        }
    }
}
isFirstRow(dataItem:any) {
  // Add logic to identify the first row, for example, by a property
  return dataItem.type === 'firstRow';
}

isSecondRow(dataItem:any) {
  return dataItem.type === 'secondRow';
}

  public onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drop the file here";
  }

  public onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drag your files here or ";
  }

  public onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      this.selectedFile = event.dataTransfer.files[0];
      event.dataTransfer.clearData();
    }
  }
  public uploadFiles(): void {
    if (this.selectedFile) {
        // this.paycodeService.uploadClientPaycodeMappings(this.selectedFile, this.EntityID).subscribe({
        //     next: (response) => {
        //       console.log('response',response);
              
        //         if (response?.status === 200) {
        //           this.alertData = {
        //             alertMessage: 'File Uploaded Successfully...',
        //             alertVisible: 'success'
        //         };
        //         console.log('File uploaded successfully', response);
               
        //         } 
        //         else {
        //           this.downloadErrorFile(response?.body.filePath, response?.body.sasToken);
        //           this.alertData = {
        //               alertMessage: 'Error uploading file.',
        //               alertVisible: 'error'
        //             };
        //             this.selectedFile=undefined;
        //             console.log('File not uploaded ', response);
        //         }
        //     },
        //     error: (error) => {
        //         console.log('error', error);
        //         this.selectedFile=undefined;
        //         this.alertData = {
        //             alertMessage:'Invalid template file!',
        //             alertVisible: 'error'
        //         };
        //     }
        // });
    } else {
      this.selectedFile=undefined;
        this.alertData = {
            alertMessage: 'No file selected.',
            alertVisible: 'error'
        };
        console.warn('No file selected.');
    }
}

public closePopup(): void {
  console.log('close')
  this.alertData = { alertMessage: '', alertVisible: '' };
}

getinvoiceCreation() {

  let payload = {
    InvoiceType :this.invoiceType ,
    Clientid : this.clientType,//'WLDRG',//'SZPRG', //this.clientType,
    Monthid : this.selectedMonth,
    Yearid : this.selectedYear,
    viewtype:'' ,
    InvoiceGenertionType:"",
  }
  
  this.invoice.getInvoiceCreation(payload).subscribe({
    next: (response) => {
      const data = response.body;

      if(data.errorFlag == "2"){
        if(this.invoiceType == 'RRC'){
          this.gridData = [
            {
              heading: data.cgstName,
              actual: data.cgstAmountBefore,
              adjustments: data.cgstAmountAfter || 0,
              total: data.totalCGST || 0
            },
            {
              heading: data.sgstName,
              actual: data.sgstAmountBefore,
              adjustments: data.sgstAmountAfter || 0,
              total: data.totalSGST || 0
            },
            {
              heading: data.igstName,
              actual: data.igstAmountBefore,
              adjustments: data.igstAmountAfter || 0,
              total: data.totalIGST
            },
            {
              heading: 'Total',
              actual: data.actualsTotal,
              adjustments: data.adjustmentsTotal,
              total: data.rrcGrandTotal
            }
          ];
          if (data && data.getRecurringInvoiceActaulAmount && data.getRecurringInvoiceActaulAmount.length) {
            this.recurringInvoiceData = data.getRecurringInvoiceActaulAmount[0];
            this.objectgrid = {
              activityName: this.recurringInvoiceData.activityName,
              actual: this.recurringInvoiceData.actualamount,
              adjustments: this.recurringInvoiceData.adjustmentRecurring || null, // Use adjustmentRecurring if available
              total: this.recurringInvoiceData.totalPerRow,
            };
            this.totalgrid = {
              activityName:'Total', 
              actual:this.recurringInvoiceData.actualamount, 
              adjustments:'', 
              total:this.recurringInvoiceData.totalPerRow
            };
    
            this.gstdetailsGrid = {
              invoiceAddress: this.recurringInvoiceData.invoiceAddress, 
              dispatchAddress:this.recurringInvoiceData.dispatchAddress,
              pO_Number:this.recurringInvoiceData.pO_Number,
              pO_Date:this.recurringInvoiceData.pO_Date,
              fromState:this.recurringInvoiceData.fromState,
              toState:this.recurringInvoiceData.toState,
              gstNumber: this.recurringInvoiceData.gstNumber,
              gmId:this.recurringInvoiceData.gmId,
              cgst:this.recurringInvoiceData.cgst,
              sgst:this.recurringInvoiceData.sgst,
              igst:this.recurringInvoiceData.igst
            };
          }
          if(data && data.lstSplitCommercialDetails && data.lstSplitCommercialDetails.length){
            this.lstSplitCommercialDetails = data.lstSplitCommercialDetails;
          }
          this.grandtotalgrid = {
            name:'Grand Total',
            grandtotal: data.rrcGrandActualTotal,
            adjustment: '',
            grandtotalwithadjustment: data.rrcGrandActualTotal
          }
        }
        else if(this.invoiceType == 'OT' || this.invoiceType == 'INS'){
          this.liasoningandInspectionData.totalCGSTAmount = data.totalCGST ?? null;
          this.liasoningandInspectionData.totalSGSTAmount = data.totalSGST ?? null;
          this.liasoningandInspectionData.totalIGSTAmount = data.totalIGST ?? null;
        }
      }
      else {
        this.alertData = {
          alertMessage: `${data.errorMessage}`,
          alertVisible: 'error'
      };  
      }
    
 
    },
    error: (error) => {
      console.error('Error fetching Branch Dropdown data', error);
    }
  });
}
}
