import { DatePipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { GridDataResult, GridModule } from '@progress/kendo-angular-grid';
import { CriticalDocumnetsService } from '../../../service/critical-documnets.service';
import { UserService } from '../../../service/user.service';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { PageChangeEvent } from '@progress/kendo-angular-treelist';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { environment } from '../../../environments/environment';
import { BlobService } from '../../../service/blob.service';
import { CriticaDocumentsAddNewFolderComponent } from '../../popups/critica-documents-add-new-folder/critica-documents-add-new-folder.component';
import { CriticaDocumentsAddNewFileComponent } from '../../popups/critica-documents-add-new-file/critica-documents-add-new-file.component';
import { CriticalDocumentsShareWithComponent } from '../../popups/critical-documents-share-with/critical-documents-share-with.component';
import { CriticalDocumentsEditDetailsComponent } from '../../popups/critical-documents-edit-details/critical-documents-edit-details.component';
import { AdobeViewerComponent } from '../../../components/adobe-viewer/adobe-viewer.component';

@Component({
  selector: 'app-critical-documents',
  standalone: true,
  imports: [NgClass, NgIf, GridModule,IndicatorsModule,NgFor,AlertPopupComponent,DatePipe,CriticaDocumentsAddNewFolderComponent,CriticaDocumentsAddNewFileComponent,CriticalDocumentsShareWithComponent,CriticalDocumentsEditDetailsComponent,AdobeViewerComponent],
  templateUrl: './critical-documents.component.html',
  styleUrl: './critical-documents.component.css'
})
export class CriticalDocumentsComponent implements OnInit {
  Skip = 0;
  fileBlob!:any
  public buttonCount = 5;
  public PageSize: number = 10;
  public sizes = [{text:'All',value:Infinity},5, 10, 20];
  loading=false;
  loadingGrid=false;
  userid!: number;
  CustomerId!: number;
  EntityId!: string;
  public gridData: GridDataResult = { data: [], total: 0 };
  public originalData: any[] = [];
  breadcrumbs: { name: string, id: number }[] = [];
  isDropdownOpen = false;
  currentFilter=''
  currentId:any=''
  deleteId!:any;
  type!:string;
  Data!:any;
  shareID!:any;
  public alertData = { alertMessage: '', alertVisible: '' };
  openAddNewFolderPopup=false;
  openAddNewFilePopup=false;
  openShareWithPopup=false;
  openEditDetailsPopup=false;
  page = 1; 

  constructor(private CriticalDocumentsService: CriticalDocumnetsService, private user: UserService,private blobService: BlobService) { }
  ngOnInit(): void {
    this.CustomerId = this.user.getCustomerId();
    this.userid = this.user.getUserId();
    this.EntityId = this.user.getClientId();
    // this.currentId=1028;
    this.loadGrid('','');
    // this.loadGrid('',1039);
  }

  public pageChange(event: PageChangeEvent): void {
    this.loadingGrid=true;
    this.Skip = event.skip;
    this.PageSize = event.take === Infinity ? this.gridData.total : event.take; 
     this.page = (this.Skip / this.PageSize) + 1;
    // this.loadGrid(this.currentFilter, this.currentId);
    this.gridData = {
      data: this.originalData.slice(this.Skip, this.Skip + this.PageSize), 
      total: this.gridData.total 
    };
    this.loadingGrid=false;
  }
  downloadFile(dataItem:any){
    this.loading=true;
    const filePath=dataItem?.name;
    if(filePath){
      const apiUrl = environment.blobUrl;
      const blobLink = apiUrl + filePath;
      const blobName = this.blobService.extractBlobName(blobLink);
      setTimeout(() => {
        this.blobService.getSasToken(blobName).subscribe(
          (tokenResponse: any) => {
            try {
              const sasToken = tokenResponse.result;
              this.blobService.downloadFile(blobName, sasToken);
              this.loading = false;
            } catch (error) {
              this.alertData={alertMessage:'File Not Found',alertVisible:'error'};
              this.loading = false;
            }
          },
          (error) => {
            console.log(error);            
            this.alertData={alertMessage:'File Not Found',alertVisible:'error'};
            this.loading = false; 
          }
        );
      }, 5000);
  }else{
    this.alertData={alertMessage:'File Not Found',alertVisible:'error'};
    this.loading=false;
  }
  }
  confirmAlert(value: any) {
    this.loadingGrid=true;
    this.deleteId = value.id;
    this.type=value.type;
    this.alertData = { alertMessage: 'Are you sure you want to delete this Employee?', alertVisible: 'confirm' };
  }
  OpenShareWith(value:any){
    this.type=value.type;
    this.shareID=value.id;
    this.openShareWithPopup=true;
  }
  OpenEditDetails(value:any){
    this.Data=value.id;
    this.openEditDetailsPopup=true;
  }
  DeleteMWMaster(value: any) {
    if(this.type=='Folder'){
      this.CriticalDocumentsService.DeleteFolder(value,this.userid, this.CustomerId,this.EntityId).subscribe(
        {
          next: (response) => {
            console.log(response);
            if (response.status == 200 && response.body.result==true) {
              this.alertData = { alertMessage: 'Deleted Successfully', alertVisible: 'success' };
              this.loadGrid('', this.currentId);
            } else {
            this.loadingGrid=false;
            this.alertData = { alertMessage: 'Error Detected', alertVisible: 'error' };
          }
        }, error: (error) => {
          console.error(error);
          this.loadingGrid=false;
          this.alertData = { alertMessage: 'Error Detected', alertVisible: 'error' };
        }
      })
    }else{
      this.CriticalDocumentsService.DeleteFile(value).subscribe(
        {
          next: (response) => {
            console.log(response);
            if (response.status == 200 && response.body.result==true) {
              this.alertData = { alertMessage: 'Deleted Successfully', alertVisible: 'success' };
              this.loadGrid('', this.currentId);
            } else {
            this.loadingGrid=false;
            this.alertData = { alertMessage: 'Error Detected', alertVisible: 'error' };
            this.loadingGrid=false;
          }
        }, error: (error) => {
          console.error(error);
          this.alertData = { alertMessage: 'Error Detected', alertVisible: 'error' };
          this.loadingGrid=false;
        }
      })
    }
  }
  loadGrid(Filter: string, id: any): void {
    this.loadingGrid = true;
    this.currentId?this.currentId:'';
    this.CriticalDocumentsService.GetFolderList(this.userid, this.CustomerId, this.EntityId, Filter, id)
      .subscribe({
        next: (response) => {
          console.log(response);
          if (response.status === 200) {
            const data = response.body;
  
            if (data && Array.isArray(data)) {
              const fileItems = data.filter(item => item.type === 'File');
              if (fileItems.length > 0) {
                fileItems.forEach(item => {
                  this.CriticalDocumentsService.GetFileInfo(item.id).subscribe({
                    next: (fileInfoResponse) => {
                      const fileInfo = fileInfoResponse.body;
                      if (fileInfo) {
                        item.fileSize = `${fileInfo.fileSize} KB`;
                        const fileExtension = item.name.split('.').pop();
                        item.type = fileExtension ? fileExtension : '';
                      } else {
                        item.fileSize = '-';
                        item.type = '';
                      }
                    },
                    error: (error) => {
                      console.error(`Error fetching file size for file ID: ${item.id}`, error);
                      item.fileSize = '-';
                    }
                  });
                });
              }
            //   data.forEach(item => {
            //     // Ensure createdOn is set for folders as well
            //     item.createdby===this.userid ? item.createdby="Me" : item.createdby ;
            // });

              this.originalData = data;
              this.gridData = {
                data: data.slice(this.Skip, this.Skip + this.PageSize), 
                total: data.length 
              };
            } else {
              console.error('Unexpected data format received:', data);
              this.gridData = {
                data: [],
                total: 0
              };
            }
          } else {
            console.error('Error fetching Grid data: Invalid status code');
          }
  
          this.loadingGrid = false; 
        },
        error: (error) => {
          console.error('Error fetching Grid data', error);
          this.gridData = {
            data: [],
            total: 0
          };
          this.loadingGrid = false; 
        }
      });
  }

  navigateToFolder(id: number, index: number): void {
    this.breadcrumbs = this.breadcrumbs.slice(0, index + 1);
    this.fetchFolderData(id);
  }
  navigateToRoot(): void {
    this.breadcrumbs = [];  
    this.fetchFolderData(null); 
  }

  enterFolder(folderName: string, folderId: number): void {
    this.breadcrumbs.push({ name: folderName, id: folderId });
    this.fetchFolderData(folderId);  
  }

  fetchFolderData(folderId: number | null): void {
    if (folderId === null) {
      this.loadGrid('', '');  
      this.currentId='';
    } else {
      this.loadGrid('', folderId); 
      this.currentId=folderId;
    }
  }

  public showUserName(createdBy: number): number|string {
    if(createdBy==this.userid){
      return 'Me'
    }
    return createdBy;
  }

  public goToSubfolder(dataItem: any): void {
    if(dataItem.type=='Folder'){
      // this.loadGrid('',dataItem.id);
      this.currentId=dataItem.id;
      this.enterFolder(dataItem.name, dataItem.id);
    }else{
      console.log('file');
      this.loading = true;
      this.CriticalDocumentsService.fetchFile(dataItem.id).subscribe({
        next: (blob: Blob) => {
          this.fileBlob = blob;
          this.loading = false;
        },
        error: (error) => {
          this.loading = false;
          console.error('Error fetching Branch Dropdown data', error);
        }
      });
    }
  }
  closeViewer(){
    this.fileBlob=null;
  }
  public viewFile(fileId: number): void {
    // Logic to view file
  }

  public shareFile(id: number, type: string): void {
    // Logic to share file
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
  @HostListener('document:click', ['$event'])
  handleOutsideClick(event: Event) {
    event.preventDefault();
    const target = event.target as HTMLElement;

    if (!target.closest('#menu1') && !target.closest('.dropdown-menu')) {
      this.toggleDropdown()
    }
  }
  openNewFolderPopup() {
    this.openAddNewFolderPopup=true
  }

  openNewFilePopup() {
    this.openAddNewFilePopup=true;
  }
  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    const filteredData = this.originalData.filter(item =>
      (item.name && item.name.toLowerCase().includes(filterValue))
    );

    this.gridData = {
      data: filteredData,
      total: filteredData.length
    };
  }

  closeAlert(){
    this.alertData = { alertMessage: '', alertVisible: '' };
  }
  closePopup(){
    this.openAddNewFolderPopup=false;
    this.openAddNewFilePopup=false;
    this.openShareWithPopup=false;
    this.openEditDetailsPopup=false;
    this.Data=''
    this.loadGrid('',this.currentId)
  }
}
