import { Component, EventEmitter, Output } from '@angular/core';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { GridModule } from '@progress/kendo-angular-grid';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';

@Component({
  selector: 'app-employee-history-popup',
  standalone: true,
  imports: [GridModule, DialogModule, GridActionItemComponent],
  templateUrl: './employee-history-popup.component.html',
  styleUrl: './employee-history-popup.component.css'
})
export class EmployeeHistoryPopupComponent {
  public gridData: any = { data: [], total: 0 };
  @Output() close = new EventEmitter<void>();

  handleButtonClick(value: any) {

  }
  uploadFiles() {

  }
  closePopup() {
    this.close.emit();
  }
  public parentColumns: any[] = [
    { field: 'filename', title: 'File Name' },
    { field: 'created_at', title: 'Upload On' },
    { field: 'status', title: 'Status' },
    {
      field: 'csvUploadDoc',
      isIconColumn: true,
      title: 'Action',
      filteredIcons: [

        {
          url: '../assets/vectors/delete.svg',
          alt: 'Download Icon',
          action: 'Download'
        }
      ]
    }
  ];
}
