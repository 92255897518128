<div class="row colpadding0 " style="margin-top: 10px;">
  <div class="col-12 ">
    <div class="col-md-8 colpadding0  ">
      <app-combo-box [width]="'350px'" [Placeholder]="defaultState" (filterChange)="fetchStateMasters($event)" (valueChange)="DropdownGridChange($event)" [data]="allStatesData" [selectedValue]="selectedState" textField="name" valueField="id" >
      </app-combo-box>

      <button class="figma-btn-white" type="submit" (click)="applyFilter()">
        <img src="../assets/vectors/ApplyFilter.svg" alt="">
      </button>
      <button class="figma-btn-white" (click)="clearFilter()">
        <img src="../assets/vectors/ClearFilter.svg" alt="">
      </button>
    </div>
    <div class="col-md-4 colpadding0 " >
      <button *ngIf="Role != 'CADMN' && Role != 'DADMN'" class="figma-btn-blue" 
        (click)="uploadDialog()">
        <img src="../assets/vectors/Plus.svg" >Authority Address Upload
      </button>
      <button *ngIf="Role == 'CADMN' || Role == 'DADMN'"  (click)="exportToExcel()" class="exportbtn" style="margin-right:-7px;">
        <img src="../assets/vectors/ExportButton.svg" alt="">
      </button>
      <button *ngIf="Role == 'CADMN' || Role == 'DADMN'" (click)="exportMpping()" class="figma-btn-blue  " >
        Export Mapping
      </button>
      <button *ngIf="Role == 'CADMN' || Role == 'DADMN'" class="figma-btn-blue " (click)="OpenAddNewPopup()"
        type="submit">
        <img src="../assets/vectors/Plus.svg" alt="">Add New
      </button>
    </div>
  </div>
 
    <div class="col-md-12 colpadding0 " style="float: right; margin-top: 5px; margin-bottom: 5px;">
      <button *ngIf="Role == 'CADMN' || Role == 'DADMN'" class="figma-btn-blue" 
        (click)="uploadDialog()">
        <img src="../assets/vectors/Plus.svg" style="margin-left: 5px;">Authority Address Upload
      </button>
  </div>
</div>

<kendo-grid class="custom-grid" *ngIf="Role != 'CADMN' && Role != 'DADMN'" [data]="gridData" 
 [columnMenu]="true" [pageSize]="pageSize" [pageable]="true"
  (pageChange)="pageChange($event)" [skip]="skip" [resizable]="true"  >
  <kendo-grid-column field="sM_Code" title="State Code">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [title]="dataItem.sM_Code" class="cell-content">
        {{ dataItem.sM_Code }}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="stateName" title="State">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [title]="dataItem.stateName" class=" cell-content">
        {{dataItem.stateName}}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="lM_Code" title="Location Code">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [title]="dataItem.lM_Code" class=" cell-content">
        {{dataItem.lM_Code}}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="lM_Name" title="Location">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [title]="dataItem.lM_Name" class=" cell-content">
        {{dataItem.lM_Name}}
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Seal & Signature" [width]="170">
    <ng-template kendoGridCellTemplate let-dataItem>
      <button kendoButton (click)="signaturePopup()" style="border: 0; background: none; margin-left: 60px;">
        <img src='../assets/vectors/UploadButton.svg' alt="Icon 1" class="svg-icon" />
      </button>
    </ng-template>
  </kendo-grid-column>
  <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
    <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
    <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
    <kendo-pager-next-buttons></kendo-pager-next-buttons>
    <kendo-pager-input></kendo-pager-input>
    <kendo-pager-info></kendo-pager-info>
    <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
  </ng-template>
</kendo-grid>


<kendo-grid class="custom-grid" *ngIf="Role == 'CADMN' || Role == 'DADMN'" [data]="gridData"  [columnMenu]="true" [pageSize]="pageSize" [pageable]="true" [height]="480"
  (pageChange)="pageChange($event)" [skip]="skip" [resizable]="true"   >
  <kendo-grid-column field="sM_Code" title="State Code">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [title]="dataItem.sM_Code" class="">
        {{ dataItem.sM_Code }}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="stateName" title="State">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [title]="dataItem.stateName" class="">
        {{dataItem.stateName}}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="lM_Code" title="Location Code">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [title]="dataItem.lM_Code" class="">
        {{dataItem.lM_Code}}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="lM_Name" title="Location" [width]="150">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [title]="dataItem.lM_Name" class="">
        {{dataItem.lM_Name}}
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Address Mapping" [width]="150">
    <ng-template kendoGridCellTemplate let-dataItem>
      <button kendoButton (click)="AddressMappingPopup(dataItem)" style="border: 0; background: none; margin-left: 60px;">
        <img src='../assets/vectors/RightArrowButton.svg' alt="Icon 1" class="svg-icon" />
      </button>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Seal & Signature" [width]="150">
    <ng-template kendoGridCellTemplate let-dataItem>
      <button kendoButton (click)="signaturePopup()" style="border: 0; background: none; margin-left: 60px;">
        <img src='../assets/vectors/UploadButton.svg' alt="Icon 1" class="svg-icon" />
      </button>
    </ng-template>
  </kendo-grid-column>
  <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
    <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
    <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
    <kendo-pager-next-buttons></kendo-pager-next-buttons>
    <kendo-pager-input></kendo-pager-input>
    <kendo-pager-info></kendo-pager-info>
    <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
  </ng-template>
</kendo-grid>



<div kendoDialogContainer *ngIf="uploadOpen">
  <app-authority-upload-popup (close)="closeUploadDialog()"></app-authority-upload-popup>
</div>


<div kendoDialogContainer *ngIf="signatureOpen">
  <app-seal-signature-upload-popup [locationid]="locationid"
    (close)="closeSignaturePopup()"></app-seal-signature-upload-popup>
</div>

<app-location-master-add-new-popup *ngIf="showAddNewPopup" (close)="closeAddNewPopup()"></app-location-master-add-new-popup>

<div *ngIf="loading" class="loader-overlay">
  <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>

<app-view-address-popup *ngIf="showAddressMappingPopup" [Data]="Data" (close)="closePopup()"></app-view-address-popup>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270"  [Data]="alertData" (close)="closeAlert()"></app-alert-popup>