import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EsicinputsService } from '../../../service/esicinputs.service';
import { BlobService } from '../../../service/blob.service';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-esicinputs',
  standalone: true,
  imports: [CommonModule,AlertPopupComponent,IndicatorsModule],
  templateUrl: './esicinputs.component.html',
  styleUrl: './esicinputs.component.css'
})
export class ESICInputsComponent implements OnInit {
  form: FormGroup;
  uploadType: string = 'G';
  fileId: string = '-1';
  loading: boolean = false;
  successVisible: boolean = false;
  downloadVisible: boolean = false;
  selectedFiles: { [key: string]: File } = {};
  selectedFileName: string | null = null;
  fileNames: { [key: string]: string | null } = {
    DownloadsInput: null,
    EmployeeInput: null,
    FamilyWelfareInput: null,
    UpdateInputEmp: null,
    UpdateInputFamily: null,
    FailedInput: null
  };
  public alertData = { alertMessage: '', alertVisible: '' };
  @ViewChild('EmployeeInputFile') EmployeeInputFile!: ElementRef;
  @ViewChild('FamilyWelfareInputFile') FamilyWelfareInputFile!: ElementRef;
  @ViewChild('UpdateInputEmpFile') UpdateInputEmpFile!: ElementRef;
  @ViewChild('UpdateInputFamilyFile') UpdateInputFamilyFile!: ElementRef;
  @ViewChild('DownloadsInputFile') DownloadsInputFile!: ElementRef;
  @ViewChild('FailedInputFile') FailedInputFile!: ElementRef;


  constructor(private http: HttpClient, private esicinputsservice: EsicinputsService, private blobService: BlobService, private fb: FormBuilder) {
    this.form = this.fb.group({
      EmployeeInput: [''],
      FamilyWelfareInput: [''],
      UpdateInputEmp: [''],
      UpdateInputFamily: [''],
      DownloadsInput: [''],
      FailedInput: ['']
    });
  }

  ngOnInit(): void { }
  
  onRadioChange(value: string) {
    this.uploadType = value;
    this.resetForm(false);
  }

  resetForm(IsdownloadVisible: boolean) {
    this.form.reset();
    this.fileId = '-1';
    this.downloadVisible = IsdownloadVisible;
    this.successVisible = false;
    this.selectedFiles = {};
  }

  onFileChange(event: any, controlName: string) {
    const file = event.target.files[0];

  if (file) {
    const fileType = file.type;
    const allowedTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];

    if (allowedTypes.includes(fileType)) {
      this.selectedFiles[controlName] = file;
      this.fileNames[controlName] = file.name;
      this.form.get(controlName)?.setValue(file);
    } else {
      this.alertData = { alertMessage: 'Please select .xlsx file', alertVisible: 'error' };
      event.target.value = '';
    }
  }
  }

  removeFile(controlName: string) {
    delete this.fileNames[controlName];
    delete this.selectedFiles[controlName];
    this.resetFileInput(controlName);
  }

  resetFileInput(controlName: string) {
    switch (controlName) {
      case 'EmployeeInput':
        this.EmployeeInputFile.nativeElement.value = '';
        break;
      case 'FamilyWelfareInput':
        this.FamilyWelfareInputFile.nativeElement.value = '';
        break;
      case 'UpdateInputEmp':
        this.UpdateInputEmpFile.nativeElement.value = '';
        break;
      case 'UpdateInputFamily':
        this.UpdateInputFamilyFile.nativeElement.value = '';
        break;
      case 'DownloadsInput':
        this.DownloadsInputFile.nativeElement.value = '';
        break;
      case 'FailedInput':
        this.FailedInputFile.nativeElement.value = '';
        break;
    }
  }

  uploadFile() {
    if (!this.uploadType) {
      alert('Please Select the Upload Type');
      return;
    }

    let inputControlName = '';
    if (this.uploadType === 'G') {
      inputControlName = this.selectedFiles['EmployeeInput'] ? 'EmployeeInput' : 'FamilyWelfareInput';
    } else if (this.uploadType === 'U') {
      inputControlName = this.selectedFiles['UpdateInputEmp'] ? 'UpdateInputEmp' : 'UpdateInputFamily';
    } else if (this.uploadType === 'D') {
      inputControlName = 'DownloadsInput';
    } else if (this.uploadType === 'F') {
      inputControlName = 'FailedInput';
    }

    const file = this.selectedFiles[inputControlName];
    if (!file) {
      this.alertData = { alertMessage: 'You have not chosen any file', alertVisible: 'error' };
      return;
    }

    const formData = new FormData();
    formData.append('FileName', file.name);
    formData.append('Clientid', 'EntityId');
    let flag=this.uploadType;
    if(this.selectedFiles['FamilyWelfareInput']){
      flag='GF'
    }else if(this.selectedFiles['UpdateInputFamily']){
      flag='UF'
    }
    formData.append('Flag', flag);
    formData.append('file', file);
    
    this.loading = true;
    
    
    this.esicinputsservice.UploadESICFile(formData).subscribe({
      next: (response) => {
        console.log(response);
        
        const apiResp = response.body;
        if (apiResp.status == "error") {
          this.resetForm(false);
          this.downloadErrorFile(apiResp.filePath, apiResp.sasToken);
          this.loading = false;
        }
        else if (apiResp.status == "success") {
          this.alertData = { alertMessage: 'File Uploaded Successfully', alertVisible: 'success' };
          this.resetForm(true);
          this.loading = false;
        }
        else {
          this.resetForm(false);
          this.loading = false;
          this.alertData = { alertMessage: 'Something Went Wrong', alertVisible: 'error' };
        }
      },
      error: (error) => {
        console.log(error);
        
        if(error.status){
          this.alertData = { alertMessage: error.error.status, alertVisible: 'error' };
        }
        this.resetForm(true);
        this.loading = false;
      }
    });
  }

  downloadErrorFile(FilePath: string, sasToken: string) {
    const blobUrl = `${FilePath}?${sasToken}`;
    const a = document.createElement('a');
    a.href = blobUrl;
    const filename = FilePath.split('/').pop() || 'downloaded_file';
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  closeAlert(){
    this.alertData = { alertMessage: '', alertVisible: '' };
  }
}
