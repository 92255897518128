import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ExpandEvent, TreeListModule } from "@progress/kendo-angular-treelist";
import { CommonModule } from '@angular/common';
import { map, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
// import { Employee } from '../../employees';
import { StatutoryService } from '../../service/statutory.service';
import { GridModule } from '@progress/kendo-angular-grid';
import { TreeViewModule } from '@progress/kendo-angular-treeview';

@Component({
  selector: 'app-lazy-tree-list',
  templateUrl: './lazy-tree-list.component.html',
  imports: [CommonModule, TreeListModule,GridModule,TreeViewModule],
  standalone: true
})
export class LazyTreeListComponent {
  @Input()rootData: any;
  @Input()payload: any;
  @Input()parentColumns:any;
  @Output() imageClick = new EventEmitter<[string, any]>();
  // public rootData: Observable<any[]> | undefined;
  private serviceUrl =
    "https://demos.telerik.com/kendo-ui/service/EmployeeDirectory";
    selectedItem:any;
  constructor(
    private http: HttpClient,
    private statutory: StatutoryService
  ) { }

  public ngOnInit(): void {
   


   this.selectedItem = sessionStorage.getItem('selected');
   console.log('init', this.selectedItem)
    console.log('p',this.payload)

  }


  public fetchChildren = (item: any): Observable<any[]> => {
    console.log('process', this.payload);
    this.payload.state = item.stateSrID;
    console.log('value', this.payload);
    this.selectedItem = sessionStorage.getItem('selected');
    console.log('branch', this.selectedItem)
    if (this.selectedItem === 'State') {
        return this.statutory.getStateGrid(this.payload).pipe(
            map((response: any) => response.body)
        );
    }
    if (this.selectedItem === 'Branch') {
      console.log('branch', this.selectedItem)
      console.log('root',this.rootData)
      console.log ('payload1',this.payload)
      const firstRootData = this.rootData[0];

      this.payload.state  = firstRootData.cM_State
      this.payload.branchId = String(firstRootData.branchSrID) 
      console.log ('payload',this.payload)
        return this.statutory.getBranchGrid(this.payload).pipe(
            map((response: any) => response.body)
        );
    }
    
    // Return an empty observable if no conditions match
    return of([]);
};


  public hasChildren = (item: any): boolean => {
    return item.hasChildren === true;
  };

  public downloadFile(dataItem: any) {
    // Logic for downloading the file
    console.log('Downloading file for:', dataItem);
  }
  onActionClick(event:any,value:any){
    console.log(event);
    console.log(value);
    
  }
  // public formatDate(date: string): string {
  //   const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
  //   return new Date(date).toLocaleDateString('en-US', options);
  // }

  public formatDate(dateString: string): string {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return ''; // Handle invalid dates
    }

    const day = ('0' + date.getDate()).slice(-2); // Get two-digit day
    const month = date.toLocaleString('default', { month: 'short' }); // Get abbreviated month
    const year = date.getFullYear(); // Get full year

    return `${day}-${month}-${year}`; // Return formatted date
  }
  checkEvent(event:any,data:any) {
    if(event == 'view'){
      this.imageClick.emit(['view', data]);
    }else if(event == 'Download'){
      this.imageClick.emit(['Download', data]);
    }else{
      this.imageClick.emit([event, data]);
    }
  }



  // public query(reportsTo: number = 0): Observable<Employee[]> {
  //   return this.http.post<Employee[]>(
  //     `${this.serviceUrl}?id=${reportsTo}`,
  //     "callback"
  //   );
  // }
}
