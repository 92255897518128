<div class="row colpadding0">
    <div class="col-md-12 colpadding0 toolbar" style="margin-top: 10px;">
        <div class="col-md-9 colpadding0">
            <div class="col-md-3 colpadding0" >
                <app-dropdown-list [data]="dropdownDataPrimary" [selectedValue]="defaultSelectedValuePrimary" [width]="'95%'"
                [height]="'27px'" textField="name" valueField="id" (valueChange)="onDropdownValueChange($event)"
                >
            </app-dropdown-list>
            </div>
            <div class="col-md-3 colpadding0" >
                <app-dropdown-list [data]="dropdownDataSecondary" [selectedValue]="defaultSelectedValue" [width]="'95%'"
                [height]="'27px'" textField="name" valueField="id" (valueChange)="onDropdownValueChangeSecond($event)"
                >
            </app-dropdown-list>
            </div>
            <div *ngIf="showMonthYearDropdowns" class="col-md-3 colpadding0">
                <app-dropdown-list [data]="dropdownDataMonth" [selectedValue]="defaultSelectedValueMonth"
                [width]="'95%'" [height]="'27px'" textField="displayvalue" valueField="value"
                (valueChange)="onDropdownValueChangeMonth($event)" >
            </app-dropdown-list>
            </div>
            <div *ngIf="showMonthYearDropdowns" class="col-md-3 colpadding0">
                <app-dropdown-list [data]="dropdownDataYear" [selectedValue]="defaultSelectedValueYear"
                [width]="'95%'" [height]="'27px'" textField="displayvalue" valueField="value"
                (valueChange)="onDropdownValueChangeYear($event)" >
            </app-dropdown-list>
            </div>
        
        </div>
        <div class="col-md-3 colpadding0">
            <button class="figma-btn-white" (click)="applyFilters()" type="submit">
                <img src="../assets/vectors/ApplyFilter.svg" alt="">
            </button>
            <button (click)="exportToExcel()" class="exportbtn">
                <img src="../assets/vectors/ExportButton.svg" alt="">
            </button>


            <div id="divGenerateReport" [style.display]="showGenerateReport ? 'block' : 'none'">
                <span style="color: black; float: right; right: 49px; top: 4px" class="k-icon k-i-excel"></span>
                <button (click)="generateReport()"
                    style="height: 25px; margin-left: 7px; margin-right: -17px; width: 71px; float: right; text-align: right;">
                    Export
                </button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>
<div id="divState" class="row colpadding0" [style.display]="showState ? 'block' : 'none'" style="margin-top: 1%;">
    <div class="col-md-12 colpadding0">
        <app-grid [gridData]="gridData" [columns]="columns"></app-grid>
    </div>
</div>
<app-alert-popup *ngIf="alertData.alertVisible" [Width]="250" [Height]="250" [Data]="alertData"
    (close)="closeAlert()"></app-alert-popup>