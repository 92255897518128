<div class="col-md-12 colpadding0">
    <div class="entity-container d-flex flex-column">
        <div class="selected-values">
            <img *ngIf="!showBasic" src="../assets/vectors/Open.svg" alt="" class="svg-icon-btn" (click)="toggle('Basic')">
            <img *ngIf="showBasic" src="../assets/vectors/Closeminus.svg"  class="svg-icon-btn" alt="" (click)="toggle('Basic')">
            <span class="f-label">Basic Information</span>
        </div>
        <div *ngIf="showBasic" class="selected-container colpadding0">
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Entity</label>
                    <app-dropdown-list [data]="EntitydropdownData" [selectedValue]="Entity" [width]="'250px'"
                        textField="text" valueField="value" (valueChange)="onDropdownValueChange($event, 'Entity')" [isDisabled]="role!=='CORDN'"
                        [hasError]="!Entity.value && showValidationErrors">
                    </app-dropdown-list>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">State</label>
                    <div class="require-container">
                        <app-dropdown-list [data]="StatedropdownData" [selectedValue]="State" [width]="'250px'"
                            textField="text" valueField="value" (valueChange)="onDropdownValueChange($event, 'State')" [isDisabled]="role!=='CORDN'" 
                            [hasError]="!State.value && showValidationErrors">
                        </app-dropdown-list>
                    </div>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Location</label>
                    <app-dropdown-list [data]="LocationdropdownData" [selectedValue]="Location" [width]="'250px'"
                        textField="text" valueField="value" (valueChange)="onDropdownValueChange($event, 'Location')" [isDisabled]="role!=='CORDN'" 
                        [hasError]="!Location.value && showValidationErrors">
                    </app-dropdown-list>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Branch</label>
                    <div class="require-container">
                        <app-dropdown-list [data]="BranchdropdownData" [selectedValue]="Branch" [width]="'250px'" [isDisabled]="role!=='CORDN'" 
                            textField="text" valueField="value" (valueChange)="onDropdownValueChange($event, 'Branch')"
                            [hasError]="!Branch.value && showValidationErrors">
                        </app-dropdown-list>
                    </div>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Mode Of Receipt</label>
                    <div class="require-container">
                        <app-dropdown-list [data]="ModeOfReceiptdropdownData" [selectedValue]="ModeOfReceipt"
                            [width]="'250px'" textField="text" valueField="value"
                            (valueChange)="onDropdownValueChange($event, 'ModeOfReceipt')" [isDisabled]="role!=='CORDN'" 
                            [hasError]="!ModeOfReceipt.value && showValidationErrors">
                        </app-dropdown-list>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Risk</label>
                    <app-dropdown-list [data]="RiskdropdownData" [selectedValue]="Risk" [width]="'250px'"
                        textField="text" valueField="value" (valueChange)="onDropdownValueChange($event, 'Risk')" [isDisabled]="role!=='CORDN'" [hasError]="!Risk.value && showValidationErrors">
                    </app-dropdown-list>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Notice Received Date</label>
                    <app-kendo-datepicker class="uniform-size " [(value)]="NoticeReceivedDate"
                        (valueChange)="onDateChange($event, 'NoticeReceivedDate')" [format]="'dd-MM-yyyy'"
                        [placeholder]="placeholder" [width]="'250px'" [isDisabled]="role!=='CORDN'" 
                        [class.input-invalid]="!NoticeReceivedDate && showValidationErrors"
                        [hasError]="!NoticeReceivedDate && showValidationErrors">
                    </app-kendo-datepicker>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Response Due Date</label>
                    <div class="require-container">
                        <app-kendo-datepicker class="uniform-size " [(value)]="ResponseDueDate"
                            (valueChange)="onDateChange($event, 'ResponseDueDate')" [format]="'dd-MM-yyyy'"
                            [placeholder]="placeholder" [width]="'250px'" [isDisabled]="role!=='CORDN'" 
                            [class.input-invalid]="!ResponseDueDate && showValidationErrors"
                            [hasError]="!ResponseDueDate && showValidationErrors">
                        </app-kendo-datepicker>
                    </div>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Notice Type</label>
                    <app-dropdown-list [data]="NoticeTypedropdownData" [selectedValue]="NoticeType" [width]="'250px'"
                        textField="text" valueField="value" (valueChange)="onDropdownValueChange($event, 'NoticeType')" [isDisabled]="role!=='CORDN'" 
                        [hasError]="!NoticeType.value && showValidationErrors">
                    </app-dropdown-list>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Remarks</label>
                    <div class="require-container">
                        <textarea class="f-textbox" placeholder="Enter Remarks" [(ngModel)]="Remarks" name=""
                            id="" [disabled]="role!=='CORDN'" [ngClass]="{'disabled-button': role!=='CORDN'}"></textarea>
                    </div>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-12 save-con">
                    <button class="figma-btn-blue" (click)="UpdateNotice()" [disabled]="role!=='CORDN'" [ngClass]="{'disabled-button': role!=='CORDN'}">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="col-md-12 colpadding0">
    <div class="entity-container d-flex flex-column">
        <div class="selected-values">
            <img *ngIf="!showRelavent" src="../assets/vectors/Open.svg" alt=""  class="svg-icon-btn" (click)="toggle('Relavent')">
            <img *ngIf="showRelavent" src="../assets/vectors/Closeminus.svg" alt=""  class="svg-icon-btn" (click)="toggle('Relavent')">
            <span class="f-label">Relevant Dates</span>
        </div>
        <div *ngIf="showRelavent" class="selected-container">
            <form [formGroup]="form">
                <div class="row EmpContainer">
                    <div class="col-md-6 d-flex align-items-center">
                        <label class="f-label me-3">Translation Request Date</label>
                        <div class="require-container">
                            <input type="text" class="form-control f-textbox " [disabled]="true"
                                formControlName="translationRequestDate">
                        </div>
                    </div>
                    <div class="col-md-6 d-flex align-items-center">
                        <label class="f-label me-3">Translated Document Upload Date</label>
                        <div class="require-container">
                            <input type="text" class="form-control f-textbox "
                                formControlName="translationDocumentUploadDate">
                        </div>
                    </div>
                </div>

                <div class="row EmpContainer">
                    <div class="col-md-6 d-flex align-items-center">
                        <label class="f-label me-3">Draft Extension Letter Submitted to SD</label>
                        <div class="require-container">
                            <input type="text" class="form-control f-textbox "
                                formControlName="extensionDraftExtensionDate">
                        </div>
                    </div>
                    <div class="col-md-6 d-flex align-items-center">
                        <label class="f-label me-3">Extended Date Applied For</label>
                        <div class="require-container">
                            <input type="text" class="form-control f-textbox " formControlName="extensionAppliedOn">
                        </div>
                    </div>
                </div>
                <div class="row EmpContainer">
                    <div class="col-md-6 d-flex align-items-center">
                        <label class="f-label me-3">Extended Application Submitted to Department</label>
                        <div class="require-container">
                            <input type="text" class="form-control f-textbox "
                                formControlName="extensionApplicationDate">
                        </div>
                    </div>
                    <div class="col-md-6 d-flex align-items-center">
                        <label class="f-label me-3">Extended Date Granted</label>
                        <div class="require-container">
                            <input type="text" class="form-control f-textbox " formControlName="extensionGrantedDate">
                        </div>
                    </div>
                </div>

                <div class="row EmpContainer">
                    <div class="col-md-6 d-flex align-items-center">
                        <label class="f-label me-3">Draft Response Submitted to SD</label>
                        <div class="require-container">
                            <input type="text" class="form-control f-textbox "
                                formControlName="noticeDraftResponseDate">
                        </div>
                    </div>
                    <div class="col-md-6 d-flex align-items-center">
                        <label class="f-label me-3">Response Submitted to Department</label>
                        <div class="require-container">
                            <input type="text" class="form-control f-textbox "
                                formControlName="noticeResponseSubmittedToDept">
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="col-md-12 colpadding0">
    <div class="entity-container d-flex flex-column">
        <div class="selected-values">
            <img *ngIf="!showAdditional" src="../assets/vectors/Open.svg" alt=""  class="svg-icon-btn" (click)="toggle('Additional')">
            <img *ngIf="showAdditional" src="../assets/vectors/Closeminus.svg" alt=""  class="svg-icon-btn" (click)="toggle('Additional')">
            <span class="f-label">Additional Information to be updated by coordinator</span>
        </div>
        <div *ngIf="showAdditional" class="selected-container">
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Notice Reference Number</label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" [ngModel]="NoticeReferenceNumber" disabled>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Translation Required</label>
                    <label class="f-label" style="width: 50px;" ><input id="rdGen" value="Y" type="radio" [(ngModel)]="TranslationRequired"
                            [ngModelOptions]="{standalone: true}" class="custom-radio" name="measurementSystem"
                            (change)="onRadioChange('Y')" />Yes</label>
                    <label class="f-label" style="width: 50px;" ><input id="rdGen" [(ngModel)]="TranslationRequired"
                            [ngModelOptions]="{standalone: true}" value="N" type="radio" class="custom-radio"
                            name="measurementSystem" (change)="onRadioChange('N')" />No</label>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">SME Team</label>
                    <app-dropdown-list [data]="SMETeamdropdownData" [selectedValue]="SMETeam" [width]="'250px'"
                        textField="text" valueField="value" (valueChange)="onDropdownValueChange($event, 'SMETeam')" [isDisabled]="role!=='CORDN'" 
                        [hasError]="!SMETeam.value && showValidationErrors">
                    </app-dropdown-list>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">SD Executer Team</label>
                    <div class="require-container">
                        <app-dropdown-list [data]="SDExecuterTeamdropdownData" [selectedValue]="SDExecuterTeam" [isDisabled]="role!=='CORDN'"
                            [width]="'250px'" textField="text" valueField="value"
                            (valueChange)="onDropdownValueChange($event, 'SDExecuterTeam')"
                            [hasError]="!SDExecuterTeam.value && showValidationErrors">
                        </app-dropdown-list>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row EmpContainer">
    <div class="col-md-12 save-con">
        <button class="figma-btn-blue" (click)="SaveNoticeDetails()" [disabled]="role!=='CORDN'" [ngClass]="{'disabled-button': role!=='CORDN'}">Save</button>
        <button class="figma-btn-blue" (click)="goBack()">Back</button>
    </div>
</div>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270"  [Data]="alertData"
    (close)="closeAlert()"></app-alert-popup>

<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>