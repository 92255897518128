<div class="row colpadding0" style="margin-top: 10px;">
    <div class="col-md-2 colpadding0">
        <header class="panel-heading tab-bg-primary" style="background: none !important; ">
            <ul class="nav nav-tabs">
                <li>
                    <h4 class="f-label"><input id="rdEnt" value="E" type="radio" class="custom-radio"
                            name="measurementSystem" (change)="onRadioChange('E')"
                            [checked]="uploadType === 'E'" />Entity</h4>
                </li>
                <li>
                    <h4 class="f-label"><input id="rdBrn" value="B" type="radio" class="custom-radio"
                            name="measurementSystem" (change)="onRadioChange('B')"
                            [checked]="uploadType === 'B'" />Branch</h4>
                </li>
            </ul>
        </header>
    </div>
    <div class="col-md-4 colpadding0">
        <kendo-datepicker class="f-textbox" [value]="selectedStartDate" [format]="'MMMM yyyy'"
            (valueChange)="onStartDateChange($event)" [style.width]="'200px'" bottomView="year" topView="decade"
            [placeholder]="datePlaceholder" [ngClass]="{'invalid-input': showValidationErrors && !selectedStartDate }">
        </kendo-datepicker>
        <!-- <div *ngIf="showValidationErrors && !selectedStartDate" class="invalid-feedback" style="color: red;">
                {{ dateErrorMessage }}
            </div>       -->
    </div>
    <div class="col-md-5 colpadding0">
        <button class="figma-btn-white apply-btn" type="submit" (click)="applyFilters()">
            <img src="../assets/vectors/ApplyFilter.svg" alt="">
        </button>
        <button class="figma-btn-white clear-btn" (click)="clearFilter()">
            <img src="../assets/vectors/ClearFilter.svg" alt="">
        </button>
    </div>
</div>

<div style="width: 100%;">
    <!-- <app-grid-action-item [gridData]="gridData" [columns]="parentColumns" [pageSize]="10" [pageable]="true"
[filterable]="true" (buttonClick)="handleButtonClick($event)"></app-grid-action-item>
</div> -->
    <div>
        <kendo-grid *ngIf="uploadType==='E'" [data]="gridData" [pageSize]="10" [pageable]="true" [skip]="skip"
            [sortable]="true" [columnMenu]="true" style="margin-top: 12px;margin-left: 10px;"
            (pageChange)="pageChange($event)" class="custom-grid">
            <kendo-grid-column [width]="92">
                <ng-template kendoGridHeaderTemplate>
                    <div class="header-checkbox">
                        <input type="checkbox" [checked]="allSelected" />
                    </div>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="checkbox-container">
                        <input type="checkbox" [checked]="dataItem.selected" [disabled]="dataItem.disabled" />
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="clientName" title="Entity Name">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.clientName" class="cell-content">
                        {{ dataItem.clientName }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="documentPath" title="Action" [width]="300">
                <ng-template kendoGridCellTemplate let-dataItem >
                    <div class="cell-content" style="display: flex; justify-content: center;">
                        <img src='../assets/vectors/DownloadBlue.svg' alt="Icon 1" (click)="downloadFile(dataItem.filePath, dataItem.fileName)" title="Download" class="svg-icon" />
                        <img src="../assets/vectors/EyeIcon.svg" alt="Icon 2" title="View" class="svg-icon" (click)="ViewFile(dataItem)" />
                    </div>
                </ng-template>
            </kendo-grid-column>
            <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
                <kendo-pager-next-buttons></kendo-pager-next-buttons>
                <kendo-pager-input></kendo-pager-input>
                <kendo-pager-info></kendo-pager-info>
                <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
            </ng-template>
        </kendo-grid>

        <kendo-grid *ngIf="uploadType==='B'" [data]="gridData" [pageSize]="10" [pageable]="true" [skip]="skip"
            [sortable]="true" [columnMenu]="true" (pageChange)="pageChange($event)"
            style="margin-top: 12px;margin-left: 10px;" class="custom-grid">
            <kendo-grid-column [width]="92">
                <ng-template kendoGridHeaderTemplate>
                    <div class="header-checkbox">
                        <input type="checkbox" [checked]="allSelected" />
                    </div>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="checkbox-container">
                        <input type="checkbox" [checked]="dataItem.selected" [disabled]="dataItem.disabled" />
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="stateName" title="State Name" [sortable]="true">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.stateName" class="cell-content">
                        {{ dataItem.stateName }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="locationName" title="Location Name" [sortable]="true">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.locationName" class="cell-content">
                        {{ dataItem.locationName }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="branch" title="Branch Name" [sortable]="true">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.branch" class="cell-content">
                        {{ dataItem.branch }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="documentPath" title="Action" [sortable]="true" [width]="140">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="cell-content" style="display: flex; justify-content: center;">
                        <img src='../assets/vectors/DownloadBlue.svg' title="View"alt="Icon 1"  (click)="downloadFile(dataItem.filePath, dataItem.fileName)" class="svg-icon" />
                        <img src="../assets/vectors/EyeIcon.svg" alt="Icon 2" title="View" class="svg-icon" (click)="ViewFile(dataItem)" />
                    </div>
                </ng-template>
            </kendo-grid-column>
            <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
                <kendo-pager-next-buttons></kendo-pager-next-buttons>
                <kendo-pager-input></kendo-pager-input>
                <kendo-pager-info></kendo-pager-info>
                <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
            </ng-template>
        </kendo-grid>
    </div>
    <app-alert-popup *ngIf="alertData.alertVisible" [Width]="270"  [Data]="alertData"
        (close)="closePopup()"></app-alert-popup>

    <div *ngIf="loading" class="loader-overlay">
        <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
    </div>
    <app-adobe-viewer [fileBlob]="fileBlob" *ngIf="fileBlob" (close)="closeViewer()"></app-adobe-viewer>