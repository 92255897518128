import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, input, OnInit, Output } from '@angular/core';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { MasterAbstractsService } from '../../../service/master-abstracts.service';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { UserService } from '../../../service/user.service';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { load } from '@progress/kendo-angular-intl';

@Component({
  selector: 'app-master-abstract-upload-popup',
  standalone: true,
  imports: [DialogModule, NgIf, AlertPopupComponent,NgClass,DropdownListComponent,IndicatorsModule],
  templateUrl: './master-abstract-upload-popup.component.html',
  styleUrl: './master-abstract-upload-popup.component.css'
})
export class MasterAbstractUploadPopupComponent implements OnInit {
  file: File | null = null;
  loading=false;
  dropzoneMessage: string = "Drag your files here or ";
  @Output() close = new EventEmitter<void>();
  @Input() StateId:string='';
  @Input() ActId:string='';
  selectedFile?: File;
  TabOption: string = 'R';
  alertData = { alertMessage: '', alertVisible: '' };
  showErrorValidation=false
  StateDropdownData:any[]=[];
  ActDropdownData:any[]=[];
  selectedState={text:'Select State',value:''}
  selectedAct={text:'Select Act',value:''}
  userID!:number;
  EntityID!:string;
  constructor(private MasterAbstractService: MasterAbstractsService,private user:UserService) { }
  ngOnInit(): void {
    this.userID=this.user.getUserId();
    this.EntityID=this.user.getClientId();
    this.getStateDropdownData();
    this.getActDropdownData();
    this.selectedState={text:'',value:this.StateId}
    this.selectedAct={text:'',value:this.ActId} 
  }
  onDropdownValueChange(value:any,type:string){
    if(type==='State'){
      this.selectedState={text:value.text,value:value.value}
    }else if(type=='Act'){
      this.selectedAct={text:value.text,value:value.value} 
    }
  }

  getStateDropdownData() {
    this.MasterAbstractService.getStateDropdown(this.EntityID,this.userID,1).subscribe({
        next: (response) => {
          const data = response.body;
          this.StateDropdownData = [
            { text: 'Select State', value: '' }, 
            ...data.map((item: any) => ({
              text: item.key,
              value: item.value
            }))
          ];
        },
        error: (error) => {
            console.error('Error fetching Branch Dropdown data', error);
        }
    });
  }
  getActDropdownData() {
    this.MasterAbstractService.getActListDropdown().subscribe({
        next: (response) => {
          const data = response.body;
          this.ActDropdownData = [
            { text: 'Select Act', value: '' }, 
            ...data.map((item: any) => ({
              text: item.value,
              value: item.key
            }))
          ];
        },
        error: (error) => {
            console.error('Error fetching Branch Dropdown data', error);
        }
    });
  }

  public uploadFiles(): void {
    debugger;
    this.loading=true;
    if (this.selectedFile && this.selectedAct.value&&this.selectedState.value) {
      this.MasterAbstractService.UploadFile(this.selectedFile,this.selectedState.value,this.selectedAct.value,this.TabOption,this.userID).subscribe({
        next: (response) => {
        this.loading=false;
        if(response.body.result=='SheetNameError'){
          this.selectedFile=undefined;
          this.alertData = { alertMessage: 'Please Upload the Valid File', alertVisible: 'error' };
        } else if(response.body.result=='Please Upload File with some data'){
          this.selectedFile=undefined;
          this.alertData = { alertMessage: response.body.result, alertVisible: 'error' };
        } else if(response.body.result==true){
          this.alertData = { alertMessage: 'File Uploaded Successfully', alertVisible: 'success' };
        } else if(response.body.result=='empty sheet') {
          this.selectedFile=undefined;
          this.alertData = { alertMessage: 'Invalid template file!', alertVisible: 'error'};
        }else{
          this.selectedFile=undefined;
          this.alertData = { alertMessage: 'File Not Uploaded.', alertVisible: 'error' };   
        }       
      },
      error: (error) => {
          this.loading=false;
          console.log(error);
          this.selectedFile=undefined;
          this.alertData = { alertMessage: error.error.result, alertVisible: 'error' };
        }
      });
    } else {
      this.loading=false;
      if(!this.selectedFile && !this.selectedAct.value && !this.selectedState.value){
        this.alertData = { alertMessage: 'Please Select Act, State and File', alertVisible: 'error'};
      } else if(!this.selectedAct.value && !this.selectedState.value){
        this.alertData = { alertMessage: 'Please Select State and Act', alertVisible: 'error' };
      } else if(!this.selectedFile  && !this.selectedState.value){
        this.alertData = { alertMessage: 'Please Select State and File', alertVisible: 'error' };
      } else if(!this.selectedFile && !this.selectedAct.value){
        this.alertData = { alertMessage: 'Please Select Act and File', alertVisible: 'error'};
      } else if(!this.selectedFile ){
        this.alertData = { alertMessage: 'Please Select File', alertVisible: 'error'}; 
      } else if( !this.selectedAct.value ){
        this.alertData = { alertMessage: 'Please Select Act', alertVisible: 'error'}; 
      } else if( !this.selectedState.value){
        this.alertData = { alertMessage: 'Please Select State', alertVisible: 'error'}; 
      }
    }
  }
  public onFileSelect(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
        const file = input.files[0];
        
        // Log MIME type and other file details for debugging
        const fileName = file.name;
        const fileExtension = fileName.split('.').pop()?.toLowerCase();
        const mimeType = file.type;
        console.log(`File Name: ${fileName}, Extracted Extension: ${fileExtension}, MIME Type: ${mimeType}`);
        
        if (this.isValidFileFormat(fileExtension)) {
            this.selectedFile = file;
        } else {
            this.alertData = { 
                alertMessage: 'Only pdf/docx/doc/zip/rar/txt/jpg/png formats are supported.', 
                alertVisible: 'error' 
            };
            this.selectedFile = undefined;
            input.value = '';  // Reset input to allow re-upload
        }
    }
}

private isValidFileFormat(fileExtension: string | undefined): boolean {
  const allowedExtensions = ['pdf', 'docx', 'doc', 'zip', 'rar', 'txt', 'jpg', 'png'];
  
  // Log the validation process
  console.log(`Checking if extension "${fileExtension}" is in allowed list:`, allowedExtensions);
  return fileExtension ? allowedExtensions.includes(fileExtension) : false;
}

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drop youe file here or ";
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drag your files here or ";
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      if (this.validateFile(file)) {
        this.file = file;
        this.selectedFile = this.file;
      } else {
        this.alertData = { alertMessage: 'Invalid file type. Only Excel files are allowed.', alertVisible: 'error' };
      }
      event.dataTransfer.clearData();
    }
  }
  private validateFile(file: File): boolean {
    const allowedExtensions = ['pdf', 'docx', 'doc', 'zip', 'rar', 'txt', 'jpg', 'png'];
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    return fileExtension ? allowedExtensions.includes(fileExtension) : false;
  }
  
  public closePopup(): void {
    this.close.emit();
  }
  cloasAlert() {
    if(this.alertData.alertVisible=='success'){
      this.closePopup();
    }
    this.alertData = { alertMessage: '', alertVisible: '' };

  }
}
