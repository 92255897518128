import { Component } from '@angular/core';
import { MultiSelectTreeComponent } from '../../../components/multi-select-tree/multi-select-tree.component';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { DropDownsModule} from '@progress/kendo-angular-dropdowns';
import { ChartsModule, KENDO_CHART } from '@progress/kendo-angular-charts';
import { NgFor, NgIf } from '@angular/common';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { UserService } from '../../../service/user.service';
import { IntlService } from '@progress/kendo-angular-intl';
import { FormGroup } from '@angular/forms';
import { StatusReportService } from '../../../service/status-report.service';

type Branch = {
  id: number;
  name: string;
  parentID: number | null;
  branchType: string;
  branchStatus: string;
  fullName: string | null;
  children?: Branch[];
};

@Component({
  selector: 'app-lc-my-reports-onboarding',
  standalone: true,
  imports: [TreeViewModule, DropDownsModule, KENDO_CHART, ChartsModule],
  templateUrl: './lc-my-reports-onboarding.component.html',
  styleUrl: './lc-my-reports-onboarding.component.css'
})
export class LCMyReportsOnboardingComponent {
  public BranchDropdown: Branch[] = [];
  compliedData: number[] = [];
  notCompliedData: number[] = [];
  categories: string[] = [];
  commaSeparatedIds: string = '';
  selectedMonth = { text: 'select month', value: '' };
  form!: FormGroup;
  EntityID!: string;
  popupSettings: any = { width: '280px' };
  public pieData = [
    { category: "Complied", value: '', color:'red'},
    { category: "Not Complied", value: '', color : '#72bf6a' },
  ];
  labelContent: any;
  selectedBranchID:any;
  customerID:any;
  constructor(private StatusReportService: StatusReportService,private user: UserService, private intl: IntlService){ }
  ngOnInit(): void {
    this.EntityID = this.user.getClientId();
    this.getBranchDropdownData();
    console.log(this.commaSeparatedIds);
    
  }

  OnMultiSelectChange(value: any) {
    console.log(value);
    
    if (Array.isArray(value)) {
        this.commaSeparatedIds = value.map(item => item.id).join(',');
    } else {
        console.error('Expected an array of selected items.');
    }
   
}
onDropdownValueChange(value: any, type: string) {
  if (type == 'activity') {
    // Update selectedAct with selected dropdown value and ensure both text and value are set
    this.selectedMonth = { text: value.text, value: value.value };
  }
}
public tagMapper(tags: any[]): any[] {
  return tags.length < 3 ? tags : [tags];
}
getBranchDropdownData() {
  console.log();
  const entityId=this.user.getClientId()
  this.StatusReportService.getBranchDropdown(entityId).subscribe({
      next: (response) => {
          const data = response.body;
          if (response && Array.isArray(data)) {
              this.BranchDropdown = this.buildHierarchy(data);
           this.OnMultiSelectChange(this.BranchDropdown);
              console.log(this.commaSeparatedIds);
              this.GetBoardingComplianceSummary();
             
          } else {
              console.error('Unexpected data format', response);
          }
      },
      error: (error) => {
          console.error('Error fetching Branch Dropdown data', error);
      }
  });
}
buildHierarchy(data: Branch[]): Branch[] {
  return data.filter(branch => branch.parentID === null);
}



GetBoardingComplianceSummary() {
  // this.selectedBranchID = 3494;
  // this.customerID = 532;

  this.selectedBranchID = this.commaSeparatedIds;
  this.customerID = this.user.getCustomerId();

  this.StatusReportService.GetBoardingComplianceSummary(this.selectedBranchID, this.customerID).subscribe({
    next: (response) => {
      const responsedata = response.body;
      this.categories = responsedata.map((item: { sM_Name: any; branch: any; }) => `${item.sM_Name} / ${item.branch}`);
      this.compliedData = responsedata.map((item: { compliedNo: any; }) => item.compliedNo);
      this.notCompliedData = responsedata.map((item: { notCompliedNo: any; }) => item.notCompliedNo);

      // Calculate the percentages for each data point and round them
      const compliedPercentages = [];
      const notCompliedPercentages = [];

      for (let i = 0; i < this.compliedData.length; i++) {
        const total = this.compliedData[i] + this.notCompliedData[i];
        const compliedPercentage = Math.round((this.compliedData[i] / total) * 100); // Round off
        const notCompliedPercentage = Math.round((this.notCompliedData[i] / total) * 100); // Round off

        compliedPercentages.push(compliedPercentage);
        notCompliedPercentages.push(notCompliedPercentage);
      }

      // Calculate the total sum of complied and not complied percentages
      const totalComplied = compliedPercentages.reduce((acc, val) => acc + val, 0);
      const totalNotComplied = notCompliedPercentages.reduce((acc, val) => acc + val, 0);
      const total = totalComplied + totalNotComplied;

      // Calculate the overall percentage for each category for the pie chart
      const compliedPiePercentage = (totalComplied / total) * 100;
      const notCompliedPiePercentage = (totalNotComplied / total) * 100;

      // Update compliedData and notCompliedData with the rounded percentages for display
      this.compliedData = compliedPercentages;
      this.notCompliedData = notCompliedPercentages;

      // Update pieData with the calculated relative percentages, formatted as strings with '%' symbol
      this.pieData = [
        { category: "Complied", value: `${compliedPiePercentage.toFixed(2)}%`, color: '#72bf6a' },
        { category: "Not Complied", value: `${notCompliedPiePercentage.toFixed(2)}%`, color: 'red' },
      ];

      console.log("Complied Percentages (Rounded):", compliedPercentages);
      console.log("Not Complied Percentages (Rounded):", notCompliedPercentages);
      console.log("Pie Chart Data:", this.pieData);
    },
    error: (err) => {
      console.error('Error fetching entity data', err);
    }
  });
}



public labelContentview = (e: any) => {
  const total = this.pieData.reduce((sum, item) => sum + parseFloat(item.value), 0);
  const percentage = (parseFloat(e.value) / total) * 100;
  
  // Format percentage: Show decimals only if necessary
  const formattedPercentage = percentage % 1 === 0 ? `${percentage}%` : `${percentage.toFixed(2)}%`;

  // Return the category along with the formatted percentage
  return `${e.category}: ${formattedPercentage}`;
};
public calculatePercentage = (value: any) => {
  const total = this.pieData.reduce((sum, item) => sum + parseFloat(item.value), 0);
  const percentage = (parseFloat(value) / total) * 100;

  // Format percentage: Show decimals only if necessary
  return percentage % 1 === 0 ? `${percentage}%` : `${percentage.toFixed(2)}%`;
};



}
