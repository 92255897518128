import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getDashboardCount(userId: number, customerId: number, entityId: string, role: string, year: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Dashboard/GetDashBoardCount?UserID=${userId}&customerID=${customerId}&EntityId=${entityId}&Role=${role}&year=${year}`;
    return this.http.get(url, { observe: 'response' });
  }

  getAbstractSummaryDetails(entityId: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Dashboard/GetAbstractPieChartCount?EntityId=${entityId}`;
    return this.http.get(url, { observe: 'response' });
  }

  getCalendarColorDetails(entityId: string, userId: number, role: string, year: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Dashboard/GetCalendarColor_Details?EntityId=${entityId}&UserId=${userId}&RoleId=${role}&DPeriodSelected=${year}`;
    return this.http.get(url, { observe: 'response' });
  }

  getCalendarEventDetails(entityId: string, userId: number, role: string, year: string, date: string, skip: number, take: number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Dashboard/GetCalendarGrid_Details?EntityId=${entityId}&UserId=${userId}&RoleId=${role}&DPeriodSelected=${year}&selectedDate=${date}&skip=${skip}&take=${take}`;
    return this.http.get(url, { observe: 'response' });
  }

  getCompliancePopupRecords(entityId: string, userId: number, role: string, year: string, skip: number, take: number, branches: string, actId: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Dashboard/GetCompliancePopup_Records?EntityId=${entityId}&RoleId=${role}&Userid=${userId}&DPeriod=${year}&branches=${branches}&Actid=${actId}`;
    return this.http.get(url, { observe: 'response' });
  }

  getLocation(entityId: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Dashboard/GetEntityLocationList?EntityId=${entityId}`;
    // return this.http.post<HttpResponse<any>>(url, {}, { observe: 'response' });
    return this.http.post(url,{}, { observe: 'response' });
  }
}
