 <kendo-dialog #dialogRef title="Notification Add Details" (close)="closePopup()" [minWidth]="500" [width]="940" [height]="400" class="custom-dialog">
    <div class="dialog-content">
        <table class="form-table">
            <tr>
                <td class="label-cell"><label class="f-label">State<span class="text-danger">*</span></label></td>
                <td class="input-cell">
                    <div [ngClass]="{'input-invalid': !selectedState.id && showValidationErrors}">
                    <app-combo-box class="uniform-size" [Placeholder]="defaultState" 
                        (filterChange)="fetchStateMasters()" 
                        (valueChange)="DropdownValueChange($event)"
                        [valueField]="'id'"
                        [textField]="'name'"
                        [selectedValue]="selectedState"
                        [data]="allStatesData" 
                        [width]="'250px'"
                        
                        [(ngModel)]="selectedState"
                        >
                    </app-combo-box>
                </div>
                  
                </td>
                <td class="label-cell"><label class="f-label">Industry Type<span class="text-danger">*</span></label></td>
                <td class="input-cell">
                    <div [ngClass]="{'input-invalid': !selectedIndustryType || selectedIndustryType === defaultIndustry && showValidationErrors}">
                    <app-dropdown-list class="uniform-size" [data]="industryTypes" [selectedValue]="selectedIndustryType"
                        (valueChange)="onIndustryChange($event)" textField="text"  [width]="'250px'"
                        valueField="value"
                       >
                    </app-dropdown-list>
                </div>
                   
                </td>
            </tr>
            <tr>
                <td class="label-cell"><label class="f-label">Notification ID<span class="text-danger">*</span></label></td>
                <td class="input-cell">
                    <div [ngClass]="{'input-invalid': !selectedNotificationId || selectedNotificationId === defaultId && showValidationErrors}">
                    <app-dropdown-list class="uniform-size" [data]="notificationId" [selectedValue]="selectedNotificationId"
                        (valueChange)="onIdChange($event)" textField="text"  [width]="'250px'"
                        valueField="value"
                        >
                    </app-dropdown-list>
                </div>
                </td>
                <td class="label-cell"><label class="f-label">Notified On<span class="text-danger">*</span></label></td>
                <td class="input-cell">
                    <div [ngClass]="{'input-invalid': !notifiedOn && showValidationErrors}">
                    <app-kendo-datepicker class="uniform-size" [(value)]="notifiedOn"
                        (valueChange)="onDateChange($event, 'notifiedOn')" [format]="'dd-MM-yyyy'" [placeholder]="placeholder"  [width]="'250px'"
                        
                        [max]="todayDate" >
                    </app-kendo-datepicker>
                </div>
                </td>
            </tr>
            <tr>
                <td class="label-cell"><label class="f-label">Effective From<span class="text-danger">*</span></label></td>
                <td class="input-cell">
                    <div [ngClass]="{'input-invalid': !effectiveFrom && showValidationErrors}">
                    <app-kendo-datepicker class="uniform-size "[(value)]="effectiveFrom"
                        (valueChange)="onDateChange($event, 'effectiveFrom')" [format]="'dd-MM-yyyy'" [placeholder]="placeholder"  [width]="'250px`'"
                         (blur)="onDateBlur()"
                        >
                    </app-kendo-datepicker>
                    <div *ngIf="showEffectiveFromError" class="validation-error text-invalid">
                        Effective From" should be greater than or equal to 'Notified On' date.
                    </div>
                </div>
                </td>
                <td class="label-cell"><label class="f-label">Effective To<span class="text-danger">*</span></label></td>
                <td class="input-cell">
                    <div [ngClass]="{'input-invalid': !effectiveTo && showValidationErrors}">
                    <app-kendo-datepicker class="uniform-size" [(value)]="effectiveTo" (valueChange)="onDateChange($event, 'effectiveTo')"
                        [format]="'dd-MM-yyyy'" [placeholder]="placeholder"  [width]="'250px'"
                         (blur)="onDateBlur()">
                    </app-kendo-datepicker>
                   </div>
                   <div *ngIf="showEffectiveToError" class="validation-error text-invalid">
                    Effective To" should be greater than or equal to 'Notified On' date.
                </div>
                <div *ngIf="showDateError" class="validation-error text-invalid">
                    Effective To" should be greater than 'Effective From' date.
                </div>
                </td>
            </tr>
            <tr>
                <td class="label-cell"><label class="f-label">Notification No<span class="text-danger">*</span></label></td>
                <td class="input-cell" colspan="3">
                    <div [ngClass]="{'input-invalid': (!notificationNo || notificationError) && showValidationErrors}">
                    <kendo-textbox id="notificationNo" class="uniform-size f-textbox" [(ngModel)]="notificationNo"
                             (ngModelChange)="validateNotificationNumber(notificationNo)" (input)="onInputChange($event)">
                    </kendo-textbox>
                </div>
                    <div *ngIf="notificationError" class="validation-error text-invalid">{{ notificationError }}</div>
                </td>
            </tr>
        </table>
    </div>
   
        <button  class="figma-btn-blue figma-popup save-btn" (click)="addOrUpdateIndustryNotification()" style="color: white;">Save</button>
    
</kendo-dialog>

<app-alert-popup *ngIf="alertData" [Width]="270" [Data]="alertData" (close)="closeAlert()"></app-alert-popup>
