import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { GridDataResult, GridModule, PageChangeEvent } from '@progress/kendo-angular-grid';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { MasterScopingSetupService } from '../../../service/master-scoping-setup.service';
import { DropDownListModule, DropDownsModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { LabelModule } from '@progress/kendo-angular-label';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-master-scoping-map-frequency',
  standalone: true,
  imports: [DialogsModule, DropdownListComponent, GridModule, DropDownListModule, MultiSelectModule, TreeViewModule, LabelModule, DropDownsModule, FormsModule, NgIf],
  templateUrl: './master-scoping-map-frequency.component.html',
  styleUrls: ['./master-scoping-map-frequency.component.css']
})
export class MasterScopingMapFrequencyComponent implements OnInit {
  @Output() close = new EventEmitter<void>();
  @Input() selectedActivities: any[] = [];
  @Input() popupType: string = '';
  @Input() selectedEntity: string = '';
  @Input() selectedService: string = '';
  @Input() rowIndex: number | null = null;
  popupSettings: any = { width: '280px' };
  
  public frequencyOptions: any[] = [
    { text: "Monthly", value: "Monthly" },
    { text: "Quarterly", value: "Quarterly" },
    { text: "Annually", value: "Annually" },
    { text: "Half-Yearly", value: "Half-Yearly" },
    { text: "On Occurrence", value: "On Occurrence" }
  ];

  public gridView!: GridDataResult;
  public pageSize = 10;
  public skip = 0;

  constructor(private masterScoping: MasterScopingSetupService) {}

  ngOnInit(): void {
    debugger
    console.log('Row index:', this.rowIndex);
    console.log('selectedActivities', this.selectedActivities);
    if (this.popupType === 'Edit Popup') {
      this.editFrequencyMapping();
    
    } else if (this.popupType === 'Add Popup') {
      this.loadGridView();
    }
  }

  private loadGridView(): void {
    console.log('Loaded grid view with activities:', this.selectedActivities);
    this.gridView = {
      data: this.selectedActivities?.slice(this.skip, this.skip + this.pageSize),
      total: this.selectedActivities?.length
    };
  }

  public toArray(value: any): any[] {
    return Array.isArray(value) ? value : [value];
  }
  
  editFrequencyMapping(): any {
    this.masterScoping.getFrequencyData(this.selectedEntity, this.selectedService).subscribe({
      next: (response) => {
        console.log('Response',response);
        
        const data = response.body;
        // this.selectedActivities = data.map((item: any) => ({
        //   Activity: item.key,
        //   Frequencies: item.value
        // }));
        this.selectedActivities = this.selectedActivities?.map((activity: any) => {
          const matchingItem = data.find((item: any) => item.key === activity.name);
          const formattedFrequencies = matchingItem 
            ? matchingItem.value.split(',')?.map((frequency: string) => ({
                text: frequency.trim(),
                value: frequency.trim()
              })) 
            : activity.Frequencies;
          
          return {
            ...activity,
            Frequencies: formattedFrequencies
          };
        });
        this.loadGridView();
      },
      error: (err) => {
        console.error('Error fetching frequency data', err);
      }
    });
   
  }

  public onFrequencyChange(index: number, selectedFrequencies: any[]): void {
    if (selectedFrequencies.length) {
      this.selectedActivities[index].Frequencies = selectedFrequencies?.map(freq => ({
        text: freq.text,
        value: freq.value
      }));
      console.log('Updated selected activities:', this.selectedActivities);
      
      this.masterScoping.setActivityFrequencyMapping(this.getActivityFrequencyMapping());
     
    } else {
      console.error('No frequencies selected', selectedFrequencies);
    }
  }
  public tagMapper(tags: any[]): any[] {
    return tags.length < 2 ? tags : [tags];
  }
  public closePopup(): void {
    debugger
    if (this.popupType === 'Edit Popup') {
      
      // this.editFrequencyMapping();
      
      setTimeout(() => {
        const updatedMapping = this.getActivityFrequencyMapping();
        this.masterScoping?.setActivityFrequencyMapping(updatedMapping);
        debugger
        console.log('Updated mapping sent to service:', updatedMapping);
        this.close.emit(); 
      }, 100);
    } else if (this.popupType === 'Add Popup') {
      const activityFrequencyMapping = this.getActivityFrequencyMapping();
      this.masterScoping.setActivityFrequencyMapping(activityFrequencyMapping);
      
      console.log('Add popup - activity frequency mapping:', activityFrequencyMapping);
      
      this.close.emit(); 
    }
  }
  

  public getActivityFrequencyMapping(): any[] {
    const activityFrequencyMapping: any[] = [];
  
    this.selectedActivities?.forEach(activity => {
     
      if (Array.isArray(activity.Frequencies)) {
       
        activity.Frequencies?.forEach((frequency: any) => {
          activityFrequencyMapping.push({
            Activity: activity.Activity || activity.name,
            Frequencies: frequency.value 
          });
        });
      } else if (typeof activity.Frequencies === 'string') {
       
        activityFrequencyMapping?.push({
          Activity: activity.Activity || activity.name,
          Frequencies: activity.Frequencies 
        });
      } else {
       
        activityFrequencyMapping?.push({
          Activity: activity.Activity || activity.name,
          Frequencies: '' 
        });
      }
    });
  
    return activityFrequencyMapping;
  }
  
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadGridView();
  }
}
