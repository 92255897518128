<div style="margin-top:10px;">
    <app-dropdown-list [data]="ClientGstDropdownData" [selectedValue]="selectedClient" [width]="'250px'"
        textField="text" valueField="value" (valueChange)="onDropdownValueChange($event)">
    </app-dropdown-list>
</div>
<!-- <div class="grid-container" style="overflow-x: auto; width: 100%;">
    <kendo-grid class="custom-grid" [data]="gridData" [columnMenu]="true" [pageSize]="pageSize" [pageable]="true"
    (pageChange)="pageChange($event)" [sortable]="true" [skip]="skip" [resizable]="true" style="margin-top:15px;">
    <kendo-grid-column [width]="80">
        <ng-template kendoGridHeaderTemplate>
            <div class="cell-content" style="margin-left:22px;">
                <input type="checkbox" style="margin-left: 13px;" [(ngModel)]="allSelected"
                    (change)="toggleSelectAll($event)" />
            </div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="checkbox-container">
                <input type="checkbox" style="margin-left: 13px;" [(ngModel)]="dataItem.selected"
                    [disabled]="dataItem.disabled" (change)="onItemSelectionChange(dataItem)" />
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="id" title="UniqueId" [width]="120">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.pfecrA_DocType">
                {{dataItem.pfecrA_DocType}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="shortForm" title="ActivityId" [width]="120">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.pfecrA_Code">
                {{dataItem.pfecrA_Code}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="branchName" title="Branch" [width]="120">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.pfecrA_FileName">
                {{dataItem.pfecrA_FileName}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="sM_Name" title="From_State" [width]="120">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.pfecrA_IsFinalized">
                {{dataItem.pfecrA_IsFinalized}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="actName" title="To_State" [width]="120">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.pfecrA_IsProcessed">
                {{dataItem.pfecrA_IsProcessed}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="requiredForms" title="GM_ID" [width]="120">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.pfecrA_FinalizedDate">
                {{dataItem.pfecrA_FinalizedDate}}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="requiredForms" title="Invoice Address" [width]="120">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.pfecrA_AutomationStatus">
                {{dataItem.pfecrA_AutomationStatus}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="requiredForms" title="Dispatch Address" [width]="120">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.pfecrA_AutomationStatus">
                {{dataItem.pfecrA_AutomationStatus}}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="requiredForms" title="PO_Number" [width]="120">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.pfecrA_AutomationStatus">
                {{dataItem.pfecrA_AutomationStatus}}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="requiredForms" title="PO_Date" [width]="120">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.pfecrA_AutomationStatus">
                {{dataItem.pfecrA_AutomationStatus}}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="requiredForms" title="GST_Excemption" [width]="120">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.pfecrA_AutomationStatus">
                {{dataItem.pfecrA_AutomationStatus}}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="requiredForms" title="SEZ_Excemption" [width]="120">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.pfecrA_AutomationStatus">
                {{dataItem.pfecrA_AutomationStatus}}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="requiredForms" title="Approve/Reject" [width]="120">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.pfecrA_AutomationStatus">
                {{dataItem.pfecrA_AutomationStatus}}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="requiredForms" title="Action" [width]="120">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.pfecrA_AutomationStatus">
                {{dataItem.pfecrA_AutomationStatus}}
            </div>
        </ng-template>
    </kendo-grid-column>


    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">

        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-input></kendo-pager-input>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>
</kendo-grid>
</div> -->
<div class="grid-container" style="overflow-x: auto; width: 100%;">
    <kendo-grid class="custom-grid" [data]="gridData" [columnMenu]="true" [pageSize]="pageSize" [pageable]="true"
        (pageChange)="pageChange($event)" [sortable]="true" [skip]="skip" [resizable]="true"
        style="margin-top:15px; min-width: 1720px;"> <!-- Set a minimum width for scrolling -->

        <kendo-grid-column [width]="80">
            <ng-template kendoGridHeaderTemplate>
                <div class="cell-content" style="margin-left:13px;">
                    <input type="checkbox" style="margin-left: 13px;" [(ngModel)]="allSelected"
                        (change)="toggleSelectAll($event)" />
                </div>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="checkbox-container">
                    <input type="checkbox" style="margin-left: 13px;" [(ngModel)]="dataItem.selected"
                        [disabled]="dataItem.disabled" (change)="onItemSelectionChange(dataItem)" />
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="uniqueId" title="UniqueId" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.uniqueId">
                    {{dataItem.uniqueId}}
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="activityId" title="ActivityId" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.activityId">
                    {{dataItem.activityId}}
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="branch" title="Branch" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.branch">
                    {{dataItem.pfecrA_FileName}}
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="fromState" title="From State" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.fromState">
                    {{dataItem.fromState}}
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="toState" title="To State" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.toState">
                    {{dataItem.toState}}
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="gmid" title="GM_ID" [width]="100">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.gmid">
                    {{dataItem.gmid}}
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="invoiceAddress" title="Invoice Address" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.invoiceAddress">
                    {{dataItem.invoiceAddress}}
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="dispatchAddress" title="Dispatch Address" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.dispatchAddress">
                    {{dataItem.dispatchAddress}}
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="poNumber" title="PO_Number" [width]="100">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.poNumber">
                    {{dataItem.poNumber}}
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="poDate" title="PO_Date" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.poDate">
                    {{dataItem.poDate}}
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="gstExcemption" title="GST_Exemption" [width]="80">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.gstExcemption">
                    {{dataItem.gstExcemption}}
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="sezExcemption" title="SEZ_Exemption" [width]="80">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.sezExcemption">
                    {{dataItem.sezExcemption}}
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="approvalStatus" title="Approve/Reject" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <!-- <div *ngIf="isCellBeingEdited(rowIndex, 'approvalStatus'); else displayApprovalStatus" class="cell-content">
                    <select class="f-dropdown" [(ngModel)]="dataItem.approvalStatus" (change)="onApprovalStatusChange($event, dataItem)">
                        <option value="Approved">Approved</option>
                        <option value="Rejected">Rejected</option>
                        <option value="Pending">Pending</option>
                    </select>
                </div>
                <ng-template #displayApprovalStatus>
                    <div class="cell-content" [title]="dataItem.pfecrA_AutomationStatus">
                        <span (click)="editRow(rowIndex, 'approvalStatus')">{{ dataItem.approvalStatus }}</span>
                    </div>
                </ng-template> -->
                <div class="cell-content">
                    <select class="f-dropdown" (change)="onApprovalStatusChange($event, dataItem)">
                        <option *ngFor="let status of approvalStatusOptions"
                            [selected]="status.value === dataItem.status" value="{{status.value}}">{{status.text}}
                        </option>
                    </select>
                </div>
                <!-- <div *ngFor="let dataItem of selectedItems" class="cell-content">
                    <select class="f-dropdown" (change)="onApprovalStatusChange($event, dataItem)">
                        <option value="Select">Select</option>
                        <option value="Approved" [selected]="dataItem.approvalStatus === 'Approved'">Approved</option>
                        <option value="Rejected" [selected]="dataItem.approvalStatus === 'Rejected'">Rejected</option>
                        <option value="Pending" [selected]="dataItem.approvalStatus === 'Pending'">Pending</option>
                    </select>
                </div>
                 -->



            </ng-template>
        </kendo-grid-column>



        <kendo-grid-column field="action" title="Action" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" style="display: flex; justify-content: center;">
                    <button class="figma-btn-blue" style="margin-left:-40px;" (click)="openAddNewPopup(dataItem)">
                        Update
                    </button>
                </div>
            </ng-template>

        </kendo-grid-column>

        <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
            <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
            <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
            <kendo-pager-next-buttons></kendo-pager-next-buttons>
            <kendo-pager-input></kendo-pager-input>
            <kendo-pager-info></kendo-pager-info>
            <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
        </ng-template>
    </kendo-grid>
</div>


<div *ngIf="isAddNewVisible" class="popup-overlay">
    <app-gst-approval-update-popup *ngIf="isAddNewVisible" [data]="gridData" [uniqueId]="uniqueId"
        (close)="handleClosePopup()">

    </app-gst-approval-update-popup>
</div>
<!-- [IsUpdate]="IsUpdate" -->
<button class="figma-btn-blue " style="margin-top:35px;margin-left: 46%;" (click)="OnSubmitgstApprovelDetails()">
    Submit
</button>
<app-alert-popup *ngIf="alertData.alertVisible" [Width]="365" [Height]="210" [Data]="alertData"
    (close)="closeAlert()"></app-alert-popup>