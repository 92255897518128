<div class="col-md-12 colpadding0" style="margin-top:10px;">
    <app-dropdown-list class="dropdowns" [data]="EntitydropdownData" [selectedValue]="Entity" [width]="'350px'"
        textField="text" valueField="value" (valueChange)="onDropdownValueChange($event,'Entity')">
    </app-dropdown-list>
</div>

<div class="col-md-12 colpadding0 tabs" *ngIf="showtabs">
    <header class="panel-heading tab-bg-primary commercialTabs"
        style="background: none !important; margin-left: 33px; padding: 0px 0px; zoom: 0.9;">
        <ul class="nav nav-tabs" style="margin-left: -23px;">
            <li class="listItem pemt" [ngClass]="{'selected-tab': TabOption === 'BillingDetails' }"
                (click)="tabsgoTo('BillingDetails')" style="margin-left: -8px !important">
                <a>Billing Details</a>
            </li>
            <li class="listItem pemt" [ngClass]="{'selected-tab': TabOption === 'Commercial' }"
                (click)="tabsgoTo('Commercial')" style="margin-left: -8px !important">
                <a>Commercial Details</a>
            </li>
            <li class="listItem pemt" [ngClass]="{'selected-tab': TabOption === 'BillingContacts' }"
                (click)="tabsgoTo('BillingContacts')" style="margin-left: -8px !important">
                <a>Billing Contacts</a>
            </li>
        </ul>
    </header>
</div>

<div class="row col-12 dialog-content colpadding0" *ngIf="TabOption == 'BillingDetails'">
    <div class="row CommercialContainer colpadding0">
        <div class="col-md-6 d-flex align-items-center colpadding0">
            <label class="f-label ">Billing Zone<sub class="text-danger">*</sub></label>
            <div class="require-container">
                <app-dropdown-list class="dropdowns" [data]="BillingZonedropdownData" [selectedValue]="BillingZone"
                    [width]="'250px'" textField="text" valueField="value"
                    (valueChange)="onDropdownValueChange($event,'BillingZone')"
                    [hasError]="!BillingZone.value && showValidationErrors">
                </app-dropdown-list>
            </div>
        </div>
        <div class="col-md-5 d-flex align-items-center colpadding0">
            <label class="f-label ">Billing Cycle<sub class="text-danger">*</sub></label>
            <div class="require-container">
                <app-kendo-datepicker class="uniform-size " [(value)]="BillingCycleDate"
                    (valueChange)="onDateChange($event, 'BillingCycleDate')" [format]="'dd-MM-yyyy'"
                    [placeholder]="placeholder" [width]="'250px'"
                    [hasError]="!BillingCycleDate && showValidationErrors">
                </app-kendo-datepicker>
            </div>
        </div>
        <div class="col-md-1 d-flex align-items-center colpadding0 " style="float: right;">
            <button type="submit" (click)="OnSave('OnSaveBillingDetails')" class="figma-btn-blue">
                Save
            </button>
        </div>
    </div>

    <div class="row CommercialContainer">
        <div class="col-md-6 d-flex align-items-center">
            <label class="f-label ">Billing Submission Date<sub class="text-danger">*</sub></label>
            <div class="require-container">
                <app-kendo-datepicker class="uniform-size " [(value)]="BillingSubmissionDate"
                    (valueChange)="onDateChange($event, 'BillingSubmissionDate')" [format]="'dd-MM-yyyy'"
                    [placeholder]="placeholder" [width]="'250px'"
                    [hasError]="!BillingSubmissionDate && showValidationErrors">
                </app-kendo-datepicker>
            </div>
        </div>

        <div class="col-md-5 d-flex align-items-center">
            <label class="f-label ">Payment Credit Period<sub class="text-danger">*</sub></label>
            <div class="require-container">
                <input type="number" class="f-textbox" placeholder="Payment Credit Period"
                    [ngClass]="{'is-invalid':!PaymentCreditPeriod&& showValidationErrors}"
                    [(ngModel)]="PaymentCreditPeriod" min="0" max="99" (input)="onInputChange($event)">
            </div>
        </div>
    </div>

    <div class="row CommercialContainer">
        <div class="col-md-6 d-flex align-items-center">
            <label class="f-label ">Revenue Generation Tab<sub class="text-danger">*</sub></label>
            <div class="require-container">
                <app-dropdown-list class="dropdowns" [data]="RevenuedropdownData" [selectedValue]="Revenue"
                    [width]="'250px'" textField="text" valueField="value"
                    (valueChange)="onDropdownValueChange($event,'Revenue')"
                    [hasError]="!Revenue.value && showValidationErrors">
                </app-dropdown-list>
            </div>
        </div>
        <div class="col-md-5 d-flex align-items-center">
            <label class="f-label ">Mandate Type<sub class="text-danger">*</sub></label>
            <div class="require-container">
                <app-dropdown-list class="dropdowns" [data]="MandateTypedropdownData" [selectedValue]="MandateType"
                    [width]="'250px'" textField="text" valueField="value"
                    (valueChange)="onDropdownValueChange($event,'MandateType')"
                    [hasError]="!MandateType.value && showValidationErrors">
                </app-dropdown-list>
            </div>
        </div>
    </div>

    <div class="row CommercialContainer">
        <div class="col-md-6 d-flex align-items-center">
            <label class="f-label">PO/WO Applicability<sub class="text-danger">*</sub></label>
            <div class="require-container">
                <app-dropdown-list class="dropdowns" [data]="PO_PWdropdownData" [selectedValue]="PO_PW"
                    [width]="'250px'" textField="text" valueField="value"
                    (valueChange)="onDropdownValueChange($event,'PO_PW')"
                    [hasError]="!PO_PW.value && showValidationErrors">
                </app-dropdown-list>
            </div>
        </div>
        <div class="col-md-5 d-flex align-items-center" *ngIf="showPOTypeDropdown">
            <label class="f-label ">PO Type<sub class="text-danger">*</sub></label>
            <div class="require-container">
                <app-dropdown-list class="dropdowns" [data]="POTypedropdownData" [selectedValue]="POType"
                    [width]="'250px'" textField="text" valueField="value"
                    (valueChange)="onDropdownValueChange($event,'POType')"
                    [hasError]="!POType.value && showValidationErrors">
                </app-dropdown-list>
            </div>
        </div>
        <div class="col-md-5 d-flex align-items-center" *ngIf="showWorkingOrder">
            <label class="f-label ">Work Order<sub class="text-danger">*</sub></label>
            <div class="require-container">
                <input type="number" class="f-textbox" placeholder="Working Order"
                    [ngClass]="{'is-invalid':!WorkOrder&& showValidationErrors}" [(ngModel)]="WorkOrder">
            </div>
        </div>
    </div>

    <div class="row CommercialContainer" *ngIf="showSplitTypeDropdown">
        <div class="col-md-6 d-flex align-items-center" *ngIf="showSplitTypeDropdown">
            <label class="f-label ">Split Type<sub class="text-danger">*</sub></label>
            <app-dropdown-list class="dropdowns" [data]="SplitTypedropdownData" [selectedValue]="SplitType"
                [width]="'250px'" textField="text" valueField="value"
                (valueChange)="onDropdownValueChange($event,'SplitType')"
                [hasError]="!SplitType.value && showValidationErrors">
            </app-dropdown-list>
        </div>
        <div class="col-md-5 d-flex align-items-center" *ngIf="showBranchWiseDropdown">
            <label class="f-label ">Branchwise PO Upload<sub class="text-danger">*</sub></label>
            <a href="../assets/files/BranchwisePOUpload.xlsx" download="BranchwisePOUpload.xlsx"
                style="color: #125AAB; text-decoration: underline;" id="btnSampleRLCS">
                <img src="../assets/vectors/ExcelDownload.svg" alt="">
            </a>
            <span *ngIf="!fileNames['BranchwisePOUpload']" style="color: #A0A0A0;">No file chosen</span>
            <span *ngIf="fileNames['BranchwisePOUpload']">
                {{fileNames['BranchwisePOUpload']}}
            </span>
            <input type="file" #BranchwisePOUpload (change)="onFileChange($event, 'BranchwisePOUpload')" id="fileInput"
                style="display: none;" accept=".xlsx, .xls" />
            <button class="figma-btn-gray" (click)="BranchwisePOUpload.click()">Browse</button>
        </div>

    </div>


    <kendo-grid class="custom-grid" *ngIf="showConsolidatedGrid" [data]="gridData" [skip]="skip" [resizable]="true" [pageSize]="pageSize"
        [pageable]="{
            buttonCount: 5,
            info: true,
            type: 'numeric',
            pageSizes: [5, 10, 25],
            previousNext: true
          }" (pageChange)="pageChange($event)">
        <kendo-grid-column field="poNumber" title="PO Number">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div *ngIf="isCellBeingEdited(rowIndex, 'poNumber'); else displaypoNumber">
                    <input class="f-textbox gridbox" type="number" [value]="dataItem.poNumber"
                        (input)="onGridTextChange($event, dataItem,'poNumber',rowIndex)">
                </div>
                <ng-template #displaypoNumber>
                    <span class="cell-content" (click)="editRow(rowIndex, 'poNumber',dataItem)">{{ dataItem.poNumber }}</span>
                </ng-template>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="poDate" title="PO Date">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div *ngIf="isCellBeingEdited(rowIndex, 'poDate'); else displaypoDate">
                    <app-kendo-datepicker class="gridbox" [(value)]="poDate" [width]="'160px'"
                        (valueChange)="onDateChanges($event, dataItem, rowIndex,'poDate')" [format]="'dd-MM-yyyy'"
                        [placeholder]="'Select Date'">
                    </app-kendo-datepicker>
                </div>
                <ng-template #displaypoDate>
                    <span (click)="editRow(rowIndex, 'poDate', dataItem)" class="cell-content">
                        {{ formatDate(parseDate(dataItem.poDate)) }}
                    </span>
                </ng-template>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="poFrom" title="PO From">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div *ngIf="isCellBeingEdited(rowIndex, 'poFrom'); else displaypoFrom">
                    <app-kendo-datepicker class="gridbox" [(value)]="poFrom" [width]="'160px'"
                        (valueChange)="onDateChanges($event, dataItem, rowIndex,'poFrom')" [format]="'dd-MM-yyyy'"
                        [placeholder]="'Select Date'">
                    </app-kendo-datepicker>
                </div>
                <ng-template #displaypoFrom>
                    <span (click)="editRow(rowIndex, 'poFrom', dataItem)" class="cell-content">
                        {{ formatDate(parseDate(dataItem.poFrom)) }}
                    </span>
                </ng-template>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="poTo" title="PO To">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div *ngIf="isCellBeingEdited(rowIndex, 'poTo'); else displaypoTo">
                    <app-kendo-datepicker class="gridbox" [(value)]="poTo" [width]="'160px'"
                        (valueChange)="onDateChanges($event, dataItem, rowIndex,'poTo')" [format]="'dd-MM-yyyy'"
                        [placeholder]="'Select Date'">
                    </app-kendo-datepicker>
                </div>
                <ng-template #displaypoTo>
                    <span (click)="editRow(rowIndex, 'poTo', dataItem)" class="cell-content">
                        {{ formatDate(parseDate(dataItem.poTo)) }}
                    </span>
                </ng-template>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="poValue" title="PO Value">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div *ngIf="isCellBeingEdited(rowIndex, 'poValue'); else displaypoValue">
                    <input class="f-textbox gridbox" type="number" [value]="dataItem.poValue"
                        (input)="onGridTextChange($event, dataItem,'poValue',rowIndex)">
                </div>
                <ng-template #displaypoValue>
                    <span class="cell-content" (click)="editRow(rowIndex, 'poValue',dataItem)">{{ dataItem.poValue }}</span>
                </ng-template>
            </ng-template>
        </kendo-grid-column>

    </kendo-grid>



    <kendo-grid class="custom-grid" *ngIf="showServiceWiseGrid" [data]="gridData" [skip]="skip" [pageSize]="pageSize" [resizable]="true"
        [pageable]="{
            buttonCount: 5,
            info: true,
            type: 'numeric',
            pageSizes: [5, 10, 25],
            previousNext: true
          }" (pageChange)="pageChange($event)">

        <kendo-grid-column field="serviceType" title="Type">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div *ngIf="isCellBeingEdited(rowIndex, 'serviceType'); else displayServiceType">
                    <input class="f-textbox gridbox" type="text" [value]="dataItem.serviceType"
                        (input)="onGridTextChange($event, dataItem,'serviceType',rowIndex)">
                </div>
                <ng-template #displayServiceType>
                    <span class="cell-content" (click)="editRow(rowIndex, 'serviceType',dataItem)">{{ dataItem.serviceType }}</span>
                </ng-template>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="poNumber" title="PO Number">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div *ngIf="isCellBeingEdited(rowIndex, 'poNumber'); else displaypoNumber">
                    <input class="f-textbox gridbox" type="number" [value]="dataItem.poNumber"
                        (input)="onGridTextChange($event, dataItem,'poNumber',rowIndex)">
                </div>
                <ng-template #displaypoNumber>
                    <span class="cell-content" (click)="editRow(rowIndex, 'poNumber',dataItem)">{{ dataItem.poNumber }}</span>
                </ng-template>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="poDate" title="PO Date">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div *ngIf="isCellBeingEdited(rowIndex, 'poDate'); else displaypoDate">
                    <app-kendo-datepicker class="gridbox" [(value)]="poDate" [width]="'160px'"
                        (valueChange)="onDateChanges($event, dataItem, rowIndex,'poDate')" [format]="'dd-MM-yyyy'"
                        [placeholder]="'Select Date'">
                    </app-kendo-datepicker>
                </div>
                <ng-template #displaypoDate>
                    <span class="cell-content" (click)="editRow(rowIndex, 'poDate', dataItem)">
                        {{ formatDate(parseDate(dataItem.poDate)) }}
                    </span>
                </ng-template>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="poFrom" title="PO From">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div *ngIf="isCellBeingEdited(rowIndex, 'poFrom'); else displaypoFrom">
                    <app-kendo-datepicker class="gridbox" [(value)]="poFrom" [width]="'160px'"
                        (valueChange)="onDateChanges($event, dataItem, rowIndex,'poFrom')" [format]="'dd-MM-yyyy'"
                        [placeholder]="'Select Date'">
                    </app-kendo-datepicker>
                </div>
                <ng-template #displaypoFrom>
                    <span class="cell-content" (click)="editRow(rowIndex, 'poFrom', dataItem)">
                        {{ formatDate(parseDate(dataItem.poFrom)) }}
                    </span>
                </ng-template>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="poTo" title="PO To">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div *ngIf="isCellBeingEdited(rowIndex, 'poTo'); else displaypoTo">
                    <app-kendo-datepicker class="gridbox" [(value)]="datePickerValue" [width]="'160px'"
                        (valueChange)="onDateChanges($event, dataItem, rowIndex,'poTo')" [format]="'dd-MM-yyyy'"
                        [placeholder]="'Select Date'">
                    </app-kendo-datepicker>
                </div>
                <ng-template #displaypoTo>
                    <span class="cell-content" (click)="editRow(rowIndex, 'poTo', dataItem)">
                        {{ formatDate(parseDate(dataItem.poTo)) }}
                    </span>
                </ng-template>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="poValue" title="PO Value">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div *ngIf="isCellBeingEdited(rowIndex, 'poValue'); else displaypoValue">
                    <input class="f-textbox gridbox" type="number" [value]="dataItem.poValue"
                        (input)="onGridTextChange($event, dataItem,'poValue',rowIndex)">
                </div>
                <ng-template #displaypoValue>
                    <span class="cell-content" (click)="editRow(rowIndex, 'poValue',dataItem)">{{ dataItem.poValue }}</span>
                </ng-template>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid>
</div>




<div class="row col-12 dialog-content colpadding0" *ngIf="TabOption == 'Commercial'">
    <div class="row CommercialContainer colpadding0">
        <div class="col-md-6 d-flex align-items-center">
            <label class="f-label ">Mandate Type</label>
            <div class="require-container">
                <h6 class="label-mandate">{{Mandate}}</h6>
            </div>
        </div>
        <div class="col-md-5 d-flex align-items-center" *ngIf="showBillingTypeDropdown">
            <label class="f-label ">Billing Type<sub class="text-danger">*</sub></label>
            <div class="require-container">
                <app-dropdown-list class="dropdowns" [data]="BillingTypedropdownData" [selectedValue]="BillingType"
                    [width]="'250px'" textField="text" valueField="value"
                    (valueChange)="onDropdownValueChange($event,'BillingType')"
                    [hasError]="!BillingType.value && showValidationErrorsCom">
                </app-dropdown-list>
            </div>
        </div>
        <div class="col-md-5 d-flex align-items-center" *ngIf="!showBillingTypeDropdown">

        </div>
        <div class="col-md-1 d-flex align-items-center" style="float: right;">
            <button type="submit" (click)="OnSave('OnSaveCommercial')" class="figma-btn-blue">
                Save
            </button>
        </div>
    </div>

    <div class="row CommercialContainer colpadding0" *ngIf="showBillingTypeRegularDropdown">
        <div class="col-md-6 d-flex align-items-center">
            <label class="f-label ">Activity Type<sub class="text-danger">*</sub></label>
            <div class="require-container">
                <app-dropdown-list class="dropdowns" [data]="ActivityTypedropdownData" [selectedValue]="ActivityType"
                    [width]="'250px'" textField="text" valueField="value"
                    (valueChange)="onDropdownValueChange($event,'ActivityType')"
                    [hasError]="!ActivityType.value && showValidationErrorsCom">
                </app-dropdown-list>
            </div>
        </div>
        <div class="col-md-5 d-flex align-items-center">
            <label class="f-label ">Commercial Type<sub class="text-danger">*</sub></label>
            <div class="require-container">
                <app-dropdown-list class="dropdowns" [data]="CommercialTypedropdownData"
                    [selectedValue]="CommercialType" [width]="'250px'" textField="text" valueField="value"
                    (valueChange)="onDropdownValueChange($event,'CommercialType')"
                    [hasError]="!CommercialType.value && showValidationErrorsCom">
                </app-dropdown-list>
            </div>
        </div>
    </div>

    <div class="row CommercialContainer colpadding0" *ngIf="showOneTimeRegularDropdown">
        <div class="col-md-6 d-flex align-items-center">
            <label class="f-label ">Activity<sub class="text-danger">*</sub></label>
            <div class="require-container">
                <app-dropdown-list class="dropdowns" [data]="OneTimeActivityTypedropdownData"
                    [selectedValue]="OneTimeActivity" [width]="'250px'" textField="text" valueField="value"
                    (valueChange)="onDropdownValueChange($event,'OneTimeActivity')"
                    [hasError]="!OneTimeActivity.value && showValidationErrorsCom">
                </app-dropdown-list>
            </div>
        </div>
        <div class="col-md-6 d-flex align-items-center">
            <label class="f-label ">Commercial Types<sub class="text-danger">*</sub></label>
            <div class="require-container">
                <app-dropdown-list class="dropdowns" [data]=" OneTimeCommercialTypedropdownData"
                    [selectedValue]="OneTimeCommercialType" [width]="'250px'" textField="text" valueField="value"
                    (valueChange)="onDropdownValueChange($event,' OneTimeCommercialType')"
                    [hasError]="! OneTimeCommercialType.value && showValidationErrorsCom">
                </app-dropdown-list>
            </div>
        </div>
    </div>

    <div class="row CommercialContainer" *ngIf="showBillingTypeSplitDropdown">
        <div class="col-md-6 d-flex align-items-center">
            <label class="f-label ">Split Type<sub class="text-danger">*</sub></label>
            <div class="require-container">
                <app-dropdown-list class="dropdowns" [data]="SplitTypeComdropdownData" [selectedValue]="SplitTypeCom"
                    [width]="'250px'" textField="text" valueField="value"
                    (valueChange)="onDropdownValueChange($event,'SplitTypeCom')"
                    [hasError]="!SplitTypeCom.value && showValidationErrors">
                </app-dropdown-list>
            </div>
        </div>
        <div class="col-md-6 d-flex align-items-center" *ngIf="showActivityWiseActivity">
            <label class="f-label ">Activity<sub class="text-danger">*</sub></label>
            <div class="require-container">
                <app-dropdown-list class="dropdowns" [data]="ActivitydropdownData" [selectedValue]="Activity"
                    [width]="'250px'" textField="text" valueField="value"
                    (valueChange)="onDropdownValueChange($event,'Activity')"
                    [hasError]="!Activity.value && showValidationErrors">
                </app-dropdown-list>
            </div>
        </div>
        <div class="col-md-6 d-flex align-items-center" *ngIf="showBranchwiseCommercialUpload">
            <label class="f-label me-3">Branchwise Commercial Upload<sub class="text-danger">*</sub></label>
            <a href="../assets/files/BranchwiseCommercialUpload.xlsx" download="BranchwiseCommercialUpload.xlsx"
                style="color: #125AAB; text-decoration: underline;" id="btnSampleRLCS">
                <img src="../assets/vectors/ExcelDownload.svg" alt="">
            </a>
            <span *ngIf="!fileNames['BranchwiseCommercialUpload']" style="color: #A0A0A0;">No file chosen</span>
            <span *ngIf="fileNames['BranchwiseCommercialUpload']">
                {{fileNames['BranchwiseCommercialUpload']}}
            </span>
            <input type="file" #BranchwiseCommercialUpload (change)="onFileChange($event, 'BranchwiseCommercialUpload')"
                id="fileInput" style="display: none;" accept=".xlsx, .xls" />
            <button class="figma-btn-gray" (click)="BranchwiseCommercialUpload.click()">Browse</button>
        </div>

    </div>

    <div class="row CommercialContainer" *ngIf="showCommercialType">
        <div class="col-md-6 d-flex align-items-center">
            <label class="f-label ">PO Number<sub class="text-danger">*</sub></label>
            <div class="require-container">
                <input type="number" class="f-textbox" placeholder="PO Number"
                    [ngClass]="{'is-invalid':!PONumber && showValidationErrorsCom}" [(ngModel)]="PONumber">
            </div>
        </div>
        <div class="col-md-6 d-flex align-items-center">
            <label class="f-label ">Commercial Type<sub class="text-danger">*</sub></label>
            <div class="require-container">
                <app-dropdown-list class="dropdowns" [data]="CommercialTypedropdownData"
                    [selectedValue]="CommercialType" [width]="'250px'" textField="text" valueField="value"
                    (valueChange)="onDropdownValueChange($event,'CommercialType')"
                    [hasError]="!CommercialType.value && showValidationErrorsCom">
                </app-dropdown-list>
            </div>
        </div>
    </div>

    <div class="row CommercialContainer">
        <div class="col-md-6 d-flex align-items-center" *ngIf="showFixedAmount">
            <label class="f-label ">Fixed Amount<sub class="text-danger">*</sub></label>
            <div class="require-container">
                <input type="number" class="f-textbox" placeholder="Fixed Amount"
                    [ngClass]="{'is-invalid':!FixedAmount&& showValidationErrorsCom}" [(ngModel)]="FixedAmount">
            </div>
        </div>
        <div class="col-md-6 d-flex align-items-center" *ngIf="showChargesDropdown">
            <label class="f-label ">Charges<sub class="text-danger">*</sub></label>
            <div class="require-container">
                <app-dropdown-list class="dropdowns" [data]="ChargesdropdownData" [selectedValue]="Charges"
                    [width]="'250px'" textField="text" valueField="value"
                    (valueChange)="onDropdownValueChange($event,'Charges')"
                    [hasError]="!Charges.value && showValidationErrorsCom">
                </app-dropdown-list>
            </div>
        </div>
        <div class="col-md-5 d-flex align-items-center" *ngIf="showCommercialElementTypeDropdown">
            <label class="f-label ">Commercial Element Type<sub class="text-danger">*</sub></label>
            <div class="require-container">
                <app-dropdown-list class="dropdowns" [data]="CommercialElementTypedropdownData"
                    [selectedValue]="CommercialElementType" [width]="'250px'" textField="text" valueField="value"
                    (valueChange)="onDropdownValueChange($event,'CommercialElementType')"
                    [hasError]="!CommercialElementType.value && showValidationErrorsCom">
                </app-dropdown-list>
            </div>
        </div>
        <div class="col-md-6 d-flex align-items-center" *ngIf="showVariableBillingTypeDropdown">
            <label class="f-label ">Variable Billing Type<sub class="text-danger">*</sub></label>
            <div class="require-container">
                <app-dropdown-list class="dropdowns" [data]="VariableBillingTypedropdownData"
                    [selectedValue]="VariableBillingType" [width]="'250px'" textField="text" valueField="value"
                    (valueChange)="onDropdownValueChange($event,'VariableBillingType')"
                    [hasError]="!VariableBillingType.value && showValidationErrorsCom">
                </app-dropdown-list>
            </div>
        </div>
    </div>

    <div class="row CommercialContainer" *ngIf="showTLCharges">
        <div class="col-md-5 d-flex align-items-center">
            <label class="f-label ">TL Charges<sub class="text-danger">*</sub></label>
            <div class="require-container">
                <input type="text" class="f-textbox" placeholder="TL Charges"
                    [ngClass]="{'is-invalid':!TLCharges && showValidationErrorsCom}" [(ngModel)]="TLCharges">
            </div>
        </div>

    </div>

    <div style="margin-bottom: 10px;" *ngIf="showVariableBillingHeadCountTable">
        <table class="table Upload-table table-border">
            <thead>
                <tr>
                    <th>Head Count</th>
                    <th>Per Head Count Amount</th>
                    <th>Consolidated Head Count Value</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="text-center">
                        <input type="number" [(ngModel)]="headCount" (input)="calculateConsolidatedValue()"
                            class="form-control center-input" />
                    </td>
                    <td class="text-center">
                        <input type="number" [(ngModel)]="perHeadCountAmount" (input)="calculateConsolidatedValue()"
                            class="form-control center-input" />
                    </td>
                    <td class="text-center">
                        {{ consolidatedHeadCountValue }}
                    </td>
                </tr>
            </tbody>
        </table>

    </div>

    <div style="margin-bottom: 15px;" *ngIf="showVariableBillingBranchCountTable">
        <table class="table Upload-table table-border">
            <thead>
                <tr>
                    <th>No Of Branches</th>
                    <th>Per Branch Amount If Any</th>
                    <th>Consolidated Branch Value</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="text-center">
                        <input type="number" [(ngModel)]="noOfBranches" (input)="calculateConsolidatedBranchValue()"
                            class="form-control center-input" />
                    </td>
                    <td class="text-center">
                        <input type="number" [(ngModel)]="perBranchAmount" (input)="calculateConsolidatedBranchValue()"
                            class="form-control center-input" />
                    </td>
                    <td class="text-center">
                        {{ consolidatedBranchValue }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div style="margin-bottom: 15px;" *ngIf="showVariableBillingVendorWiseTable">
        <table class="table Upload-table table-border">
            <thead>
                <tr>
                    <th>No Of Vendors</th>
                    <th>Per Vendor Amount If Any</th>
                    <th>Consolidated Vendors Value</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="text-center">
                        <input type="number" [(ngModel)]="noOfVendors" (input)="calculateConsolidatedVendorValue()"
                            class="form-control center-input" />
                    </td>
                    <td class="text-center">
                        <input type="number" [(ngModel)]="perVendorAmount" (input)="calculateConsolidatedVendorValue()"
                            class="form-control center-input" />
                    </td>
                    <td class="text-center">
                        {{ consolidatedVendorValue }}
                    </td>
                </tr>
            </tbody>
        </table>


    </div>

    <div style="margin-bottom: 15px;" *ngIf="showVariableBillingBranchCountTableVar">
        <table class="table Upload-table table-border">
            <thead>
                <tr>
                    <th>No Of Branches</th>
                    <th>Per Branch</th>
                    <th>Consolidated Value</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="text-center">
                        <input type="number" [(ngModel)]="noOfBranchesvar"
                            (input)="calculateConsolidatedBranchValuevar()" class="form-control center-input" />
                    </td>
                    <td class="text-center">
                        <input type="number" [(ngModel)]="perBranchAmountvar"
                            (input)="calculateConsolidatedBranchValuevar()" class="form-control center-input" />
                    </td>
                    <td class="text-center">
                        {{ consolidatedBranchValuevar }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div style="margin-bottom: 15px;" *ngIf="showVariableBillingHeadCountTableVar">
        <table class="table Upload-table table-border">
            <thead>
                <tr>
                    <th>Head Office</th>
                    <th>Amount Per HO</th>
                    <th>Total Value</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="text-center">
                        1 <!-- Head Office count is fixed -->
                    </td>
                    <td class="text-center">
                        <input type="number" [(ngModel)]="amountPerHeadOffice" (input)="updateTotalHeadOfficeValue()"
                            class="form-control center-input" />
                    </td>
                    <td class="text-center">
                        {{ totalHeadOfficeValue }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div style="margin-bottom: 10px;" *ngIf="showVariableBillingVendorWiseTableVar">
        <table class="table Upload-table table-border">
            <thead>
                <tr>
                    <th>No Of Factories</th>
                    <th>Amount Per Factory</th>
                    <th>Total Value</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="text-center">
                        <input type="number" [(ngModel)]="numberOfFactories" (input)="updateTotalFactoryValue()"
                            class="form-control center-input" />
                    </td>
                    <td class="text-center">
                        <input type="number" [(ngModel)]="amountPerFactory" (input)="updateTotalFactoryValue()"
                            class="form-control center-input" />
                    </td>
                    <td class="text-center">
                        {{ totalFactoryValue }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>


    <div class="col-md-12 colpadding0" *ngIf="showSplitTypeGrid">
        <app-grid-action-item [gridData]="gridDataCommercial" [columns]="parentColumns" [pageSize]="10"
            [pageable]="true"  [filterable]="true"
            (buttonClick)="handleButtonClick($event)"></app-grid-action-item>
    </div>
</div>



<div class="row col-12 dialog-content colpadding0" *ngIf="TabOption == 'BillingContacts'">
    <form [formGroup]="form" class=" colpadding0">
        <div class="row CommercialContainer colpadding0">
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label ">Spoc Name<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <input type="text" class="f-textbox" placeholder="Spoc Name" formControlName="spoc_name"
                        [ngClass]="{'is-invalid': form.get('spoc_name')?.invalid && (form.get('spoc_name')?.touched)}">
                    <div *ngIf="form.get('spoc_name')?.invalid && (form.get('spoc_name')?.touched) && form.get('spoc_name')?.errors?.['pattern']"
                        class="text-danger">
                        Name must contain only Characters .
                    </div>
                </div>
            </div>
            <div class="col-md-5 d-flex align-items-center">
                <label class="f-label ">Spoc Email<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <input type="email" class="f-textbox" placeholder="Spoc Email" formControlName="spoc_email"
                        [ngClass]="{'is-invalid': form.get('spoc_email')?.invalid && (form.get('spoc_email')?.touched)}">
                    <div *ngIf="form.get('spoc_email')?.invalid && (form.get('spoc_email')?.touched) && form.get('spoc_email')?.errors?.['pattern']"
                        class="text-danger">
                        Please enter a valid Email address.
                    </div>
                </div>
            </div>
            <div class="col-md-1 d-flex align-items-center" style="float: right;">
                <button type="submit" (click)="OnSave('onSaveBillingContacts')" style="margin-left: 45px;" class="figma-btn-blue">
                    Save
                </button>
            </div>
        </div>
        <div class="row CommercialContainer">
            <div class="col-md-6 d-flex align-items-center">
                <label class="f-label ">Invoice Address<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <input type="text" class="f-textbox" placeholder="Contact No" formControlName="spoc_contact"
                        minlength="10" maxlength="10" (input)="validateMobileNo($event)"
                        [ngClass]="{'is-invalid': form.get('spoc_contact')?.invalid && (form.get('spoc_contact')?.touched)}">
                    <div *ngIf="form.get('spoc_contact')?.invalid && (form.get('spoc_contact')?.touched)"
                        class="text-danger">
                        <div *ngIf="form.get('spoc_contact')?.errors?.['pattern']">
                            Contact No must be exactly 10 digits.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-5 d-flex align-items-center">
                <label class="f-label ">Invoice Address<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <textarea class="f-textbox" formControlName="invoice_spoc_address" placeholder="Address"
                        [ngClass]="{'is-invalid': form.get('invoice_spoc_address')?.invalid && (form.get('invoice_spoc_address')?.touched)}"></textarea>
                </div>
            </div>
        </div>
        <div class="row CommercialContainer">
            <div class="col-md-5 d-flex align-items-center">
                <label class="f-label ">Dispatch Address<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <textarea class="f-textbox" formControlName="dispatch_spoc_address" placeholder="Address"
                        [ngClass]="{'is-invalid': form.get('dispatch_spoc_address')?.invalid && (form.get('dispatch_spoc_address')?.touched)}"></textarea>
                </div>
            </div>
        </div>
    </form>
</div>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270"  [Data]="alertData"
    (close)="closeAlert()"></app-alert-popup>