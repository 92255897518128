import * as XLSX from 'xlsx';
import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { GridModule } from '@progress/kendo-angular-grid';
import { PremiseMasterService } from '../../../service/premise-master.service';
import { UserService } from '../../../service/user.service';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { NgIf } from '@angular/common';
import { CommonService } from '../../../service/common.service';
import { EntityService } from '../../../service/entity.service';
import { BlobService } from '../../../service/blob.service';

@Component({
  selector: 'app-entity-upload-popup',
  standalone: true,
  imports: [DialogModule, GridModule, FormsModule, AlertPopupComponent, NgIf],
  templateUrl: './entity-upload-popup.component.html',
  styleUrl: './entity-upload-popup.component.css'
})
export class EntityUploadPopupComponent {
  @Output() close = new EventEmitter<void>();
  selectedFile?: File|null;
  dropzoneMessage: string = "Drag your files here or ";
  public gridData: any[] = [];
  allSelected: boolean = false;
  selectedItems: any[] = [];
  public headerTrackerId!:any;
  loading=false;
  alertData = { alertMessage: '', alertVisible: '' }
  constructor(private premiseService: PremiseMasterService, private entityService: EntityService, private user: UserService, private commonService: CommonService,private blobService: BlobService) { }

  @ViewChild('dialogTitle') dialogTitle!: ElementRef;
  ngAfterViewInit() {
    const titleElement = this.dialogTitle.nativeElement;
    titleElement.style.textAlign = 'center';
    titleElement.style.width = '100%';
  }

  ngOnInit(): void {
    this.loadGridData();
    this.headerTrackerId=sessionStorage.getItem('headerTrackerEntityId')
  }

  public onFileSelect(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
      const file = input.files[0];
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
  
      if (fileExtension === 'xlsx') {
        this.selectedFile = file;
        console.log('Selected file:', this.selectedFile);
      } else {
        this.selectedFile = null;
        this.alertData = { alertMessage: 'Please upload XLSX file only', alertVisible: 'error' };
      }
    }
  }

  public onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drag your files here or ";
  }

  public onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drag your files here or ";
  }

  public onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    
    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      
      if (fileExtension === 'xlsx') {
        this.selectedFile = file;
        console.log('Selected file:', this.selectedFile);
      } else {
        this.selectedFile = null;
        this.alertData = { alertMessage: 'Please upload XLSX file only', alertVisible: 'error' };
      }
  
      event.dataTransfer.clearData();
    }
  }
  


  loadGridData() {
    debugger;
    this.selectedItems = [];
    const custId = this.user.getCustomerId();
    console.log(custId);
    
    this.commonService.GetMappedClientHeadersColumn('RLCS_Client_BasicDetails', custId).subscribe({
      next: (response) => {
        debugger;
        console.log('response', response);
        const data = response.body;
        if (data && Array.isArray(data)) {
          this.gridData = data
            .map((item: any) => {
              const isDefaultSelected = item.colName === 'CM_ClientID' || item.colName === 'CO_CorporateID';
              if (isDefaultSelected) {
                item.selected = true;
                item.disabled = true;
                this.selectedItems.push(item);
              } else if (item.selected) {
                this.selectedItems.push(item);
              }
              return {
                ...item,
                selected: item.selected || isDefaultSelected,
                disabled: item.disabled || isDefaultSelected
              };
            })
            // Sort gridData so that default selected items are at the top, and others are sorted alphabetically
            .sort((a, b) => {
              const aIsDefault = a.colName === 'CM_ClientID' || a.colName === 'CO_CorporateID';
              const bIsDefault = b.colName === 'CM_ClientID' || b.colName === 'CO_CorporateID';
  
              if (aIsDefault && !bIsDefault) return -1;
              if (!aIsDefault && bIsDefault) return 1;
              return a.colName.localeCompare(b.colName);
            });
        }
      }
    });
  }

  onItemSelectionChange(dataItem: any) {
    if (dataItem.selected) {
      if (!this.selectedItems.includes(dataItem)) {
        this.selectedItems.push(dataItem);
      }
    } else {
      this.selectedItems = this.selectedItems.filter(item => item.colName !== dataItem.colName);
    }
  }

  toggleSelectAll(event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
  
    this.gridData.forEach(item => {
      if (checked) {
        item.selected = true;
      } else {
        if (item.colName !== 'CM_ClientID' && item.colName !== 'CO_CorporateID') {
          item.selected = false;
        }
      }
    });
  }
  

  downloadTemplate() {
    debugger
    const defaultColumns = ['CM_ClientID', 'CO_CorporateID'];
    const onlyDefaultColumnsSelected = this.selectedItems.length > 0 &&
      this.selectedItems.every(item => defaultColumns.includes(item.colName));
    const selectedItems = onlyDefaultColumnsSelected
      ? this.gridData.map(item => item)
      : this.selectedItems;
    const columnsToInclude: string[] = [];
    const headersToInclude: string[] = [];
    for (let i = 0; i < selectedItems.length; i++) {
      const item = selectedItems[i];
      columnsToInclude.push(item.colName.toLowerCase());
      headersToInclude.push(item.clientHeaderName.toLowerCase());
    }

    const columnList = columnsToInclude.join(',');
    const headerList = headersToInclude.join(',');
    this.premiseService.insertDynamicUploadHeaders(columnList, headerList).subscribe({
      next: (response) => {
        const data = response.body
        sessionStorage.setItem('headerTrackerEntityId', data.result);
        this.headerTrackerId = data.result;
        const workbook = XLSX.utils.book_new();
        const worksheetData = [headersToInclude];
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        worksheet['!cols'] = headersToInclude.map((col) => {
          return { wch: col.length };
        });
        XLSX.utils.book_append_sheet(workbook, worksheet, 'EntityUpload');
        const timestamp = new Date().toISOString().replace(/[-:.]/g, '');
        const clientID = this.user.getClientId();
        const filename = `EntityUpload_${clientID}_${timestamp}.xlsx`;

        XLSX.writeFile(workbook, filename);

      }
    })
  }
  resetData(): void {
    this.selectedFile=undefined;
    this.gridData.forEach(item => {
      if (!item.disabled) {
        item.selected = false;
      }
    });
    if (this.allSelected) {
      this.allSelected = false;
    }
  }
  uploadFiles() {
    if (!this.selectedFile) {
      console.error('No file selected!');
      this.alertData.alertMessage = 'No file selected!';
      this.alertData.alertVisible = 'error';
      return;
    }
    
    if (!this.headerTrackerId) {
      console.error('Header Tracker ID is missing.');
      this.selectedFile=undefined;
      this.alertData.alertMessage = 'Please download the template first.';
      this.alertData.alertVisible = 'error';
      return;
    }
    
    const custId = this.user.getCustomerId();
    const userId = this.user.getUserId();
    console.log('header',this.headerTrackerId);
    
    this.entityService.EntityBulkUpload(this.selectedFile, this.headerTrackerId, custId, userId)
    .subscribe({
      next: (response) => {
        debugger
        console.log('upload response', response);
        const data = response.body;
        if (data.status === 'Invalid headers') {
          console.error('Invalid headers in the response:', response);
          this.alertData = { alertMessage: 'Invalid Headers', alertVisible: 'error' };
          this.selectedFile=undefined;
        }else if(data.status=='error') {
          this.selectedFile=undefined;
          const data = response.body;
          const blobLink = data.filePath;
          const blobName = this.blobService.extractBlobName(blobLink);
          const sasToken = data.sasToken;
          console.log(blobLink);
          this.blobService.downloadFile(blobName, sasToken);
          this.loading = false;
        }
        else {
            console.log('Upload response:', response);
            this.alertData = { alertMessage: 'File uploaded successfully!', alertVisible: 'success' };
          }
        },
        error: (error) => {
          this.selectedFile=undefined;
          this.alertData = { alertMessage: 'Error uploading file', alertVisible: 'error' };
          console.error('Error uploading file:', error);
        }
      });
    }
    public closeAlert(): void {
      if(this.alertData.alertVisible=='success'){
        this.close.emit();
      }
      this.alertData = { alertMessage: '', alertVisible: '' };
    }
    public closePopup(): void {
      this.close.emit();
    }
  }
