<kendo-dialog 
#dialogRef 
  title="File Upload"
  (close)="closePopup()"
  [minWidth]="300"
  [height]="340"
  [width]="1000"
  class="custom-dialog">
  <form  [formGroup]="abstractForm" >
<div class="row Container">
    <div class="col-md-6 d-flex align-items-center">
        <label class="f-label me-3">State<sub class="text-danger">*</sub></label>
        <div class="require-container">
            <app-dropdown-list   [data]="dropdownDataPrimary" [selectedValue]="selectedState" (valueChange)="onDropdownValueChange($event,'State')" 
             textField="text" valueField="value" [width]="'280px'" [hasError]="!selectedState.value && showValidationError">
          </app-dropdown-list>
        </div>
    </div>
    <div class="col-md-6 d-flex align-items-center">
        <label class="f-label me-3">Act<sub class="text-danger">*</sub></label>
        <div class="require-container">
            <app-dropdown-list  [data]="dropdownDataSecondary" [selectedValue]="selectedAct" [width]="'280px'" textField="text"
                valueField="value" (valueChange)="onDropdownValueChange($event,'Act')"
                [hasError]="!selectedAct.value && showValidationError">
            </app-dropdown-list>
        </div>
    </div>
</div>
<div class="row Container">
    <div class="col-md-6 d-flex align-items-center">
        <label class="f-label me-3">Branch<sub class="text-danger">*</sub></label>
        <div class="require-container">
            <app-dropdown-list [data]="BranchDropdownData" [selectedValue]="selectedBranch"
                (valueChange)="onDropdownValueChange($event,'Branch')"
                [hasError]="!selectedBranch.value && showValidationError" textField="text" valueField="value"
                [width]="'280px'">
            </app-dropdown-list>
        </div>
    </div>
    <div class="col-md-6 d-flex align-items-center">
        <label class="f-label me-3">Display<sub class="text-danger">*</sub></label>
        <div class="require-container">
            <app-dropdown-list [data]="DisplayDropdownData" [selectedValue]="selectedDisplayType" [width]="'280px'"
                textField="text" valueField="value" (valueChange)="onDropdownValueChange($event,'DisplayType')"
                [hasError]="!selectedDisplayType.value && showValidationError">
            </app-dropdown-list>
        </div>
    </div>
</div>
<div class="row Container">
    <div class="col-md-6 d-flex align-items-center">
        <label class="f-label me-4">Courier No</label>
        <div class="require-container">
           <input  formControlName="Courier" type="text"  style="width: 280px;" (keypress)="isNumberKey($event)" [disabled]="isDisabled"   class="form-control f-textbox" placeholder="Enter numbers only" >
        </div>
    </div>
    <div class="col-md-6 d-flex align-items-center">
        <label class="f-label me-3">Dispatch<sub class="text-danger">*</sub></label>
        <div class="require-container">
            <app-dropdown-list [data]="DispatchDropdownData" [selectedValue]="selectedDispatchType" [width]="'280px'"
                textField="text" valueField="value" (valueChange)="onDropdownValueChange($event,'DispatchType')"
                 >
                 <!-- [hasError]="!selectedDispatchType.value && showDispatchError" -->
            </app-dropdown-list>
        </div>
    </div>
</div>
<div class="row Container" style="margin-top: 4px;">
    <div class="col-md-6 d-flex align-items-center">
        <label class="f-label me-3">Document Type<sub class="text-danger">*</sub></label>
        <div class="require-container" style="margin-right: 125px;">
            
            <div class="align">
                <ul class="nav d-flex" style="display: flex; padding: 0px;">
                    <li class="nav-item">
                        <div style="display: flex;">
                        <input id="rdGen" value="S" type="radio" class="radio-row" name="documentType"
                            (change)="onRadioChange('S')" [checked]="uploadType === 'S'" />
                            <div style="margin-top: 5px;" class="labelhight">Soft Copy</div>
                        </div>
                    </li>
                    
                    <li class="nav-item">
                        <input id="rdUpd" value="H" type="radio" class="radio-row" name="documentType"
                            (change)="onRadioChange('H')" [checked]="uploadType === 'H'" />
                            <div style="margin-top: 5px;" class="labelhight">Hard Copy</div>
                    </li>
                </ul>
            </div>
            <div *ngIf="!uploadType && showRadioError" class="text-danger">
                Select document type
            </div>
        </div>
    </div>
    <div class="col-md-6 d-flex align-items-center">
        <label class="f-label me-3">File Upload<sub class="text-danger">*</sub></label>
        <div style="margin-left:23%">
            <div>
                <span *ngIf="!fileNames['Abstract']" title="Uploaded File"
                    style="color: #A0A0A0;vertical-align:sub;margin-right: 20px;">No file
                    chosen</span>
                <span *ngIf="fileNames['Abstract']">
                    {{fileNames['Abstract']}}
                </span>
                <input type="file" #Abstract (change)="onFileChange($event, 'Abstract')" id="fileInput"
                    style="display: none;" />
                <button class="figma-btn-gray"  (click)="Abstract.click()">Browse</button>
            </div>
            <div *ngIf="!fileNames['Abstract']&&showValidationError" class="text-danger">
                File is required.
            </div>
        </div>
    </div>
</div>

  <kendo-dialog-actions style="border: none;width: 280px;margin-bottom: 10px ;margin-left: 35%;">
    <button kendoButton type="submit" (click)="OnSave()" class="figma-btn-blue figma-popup" style="margin-top: 20px;">
      Save
    </button>
  </kendo-dialog-actions>
 </form>
</kendo-dialog>
<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>
<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270"  [Data]="alertData" (close)="cloasAlert()"></app-alert-popup>

