<kendo-dialog #dialogRef title="Upload" (close)="closePopup()" [minWidth]="400" [width]="640" [height]="470" class="custom-dialog">
  <div>
  <div class="row">
    <div class="col-md-6 inline-flex-container">
      <div class="form-group">
        <app-combo-box [width]="'280px'" [Placeholder]="'Search State/Select State'"
                       (valueChange)="StateValueChange($event)" [data]="stateDropdownData"
                       [hasError]="!selectedState && showValidation">
        </app-combo-box>
        <!-- [ngClass]="{'is-invalid': showValidation && !selectedState}" -->
        <div *ngIf="showValidation && !selectedState" class="invalid-feedback">
          State is required.
        </div>
      </div>
    </div>
    <div class="col-md-6 inline-flex-container">
      <div class="form-group">
        <app-dropdown-list [data]="StateNotificationDropdownData" [selectedValue]="StateNotificationDefaultValue"
                           (valueChange)="StateNotificationValueChange($event)" [width]="'280px'"  textField="id"
                           valueField="name" [hasError]="!selectedStateNotificationID && showValidation">
        </app-dropdown-list> 
        <!-- [ngClass]="{'is-invalid': showValidation && !selectedStateNotificationID}" -->
        <div *ngIf="showValidation && !selectedStateNotificationID" class="invalid-feedback">
          State Notification is required.
        </div>
      </div>
    </div>
  </div>
  <div class="row" >
    <div class="col-md-6 inline-flex-container">
      <div class="form-group">
        <app-dropdown-list [data]="EstablishDropdownData" [selectedValue]="EstablishmentDefaultValue"
                           (valueChange)="EstablishValueChange($event)" [width]="'280px'"  textField="id"
                           valueField="name" [hasError]="!selectedEstablishment && showValidation">
        </app-dropdown-list>
        <!-- [ngClass]="{'is-invalid': showValidation && !selectedEstablishment}" -->
        <div *ngIf="showValidation && !selectedEstablishment" class="invalid-feedback">
          Establishment is required.
        </div>
      </div>
    </div>
    <div class="col-md-6 inline-flex-container">
      <div class="form-group">
        <app-dropdown-list [data]="ZoneDropdownData" [selectedValue]="ZoneDefaultValue"
                           (valueChange)="ZoneValueChange($event)" [width]="'280px'"  textField="id"
                           valueField="name" [hasError]="!selectedZone && showValidation">
        </app-dropdown-list>
        <!-- [ngClass]="{'is-invalid': showValidation && !selectedZone}" -->
        <div *ngIf="showValidation && !selectedZone" class="invalid-feedback">
          Zone is required.
        </div>
      </div>
    </div>
  </div>
  <div class="row dialog-content">
    <div class="col-md-12" style="margin-top: 10px;">
      <div class="col-md-6 inline-flex-container colpadding0" >
        <label for="upload" class="f-label" style="font-size:15px">Download</label>
        <img  (click)="downloadCSV()" src="../assets/vectors/ExcelDownload.svg" class="svg-icon-btn" alt="">
      </div>
      <div class="col-md-6 sampleDocument colpadding0">
      </div>
    </div>
    <div class="col-md-12 form-group" >
      <div class="drop-zone" 
           (dragover)="onDragOver($event)" 
           (dragleave)="onDragLeave($event)" 
           (drop)="onDrop($event)">
        <div *ngIf="!selectedFile" class="f-label" style="font-size: 18px;">
          <div class="col-md-12" >
            <img src="../assets/vectors/cloud.svg" alt="">
          </div>
          <div class="col-md-8">
            <label style="margin-left: 185px;">{{ dropzoneMessage }}</label>
          </div>
          <div class="col-md-4">
            <label style="color: #125AAB; text-decoration: underline; cursor: pointer; margin-left: -70px; font-weight: 500;">
              Browse
              <input type="file" style="display: none;" (change)="onFileSelect($event)" accept=".xls,.xlsx,.csv" />
            </label>
          </div>
        </div>
        <p *ngIf="selectedFile" >{{ selectedFile.name }}</p>
      </div>
    </div>
  </div>
  </div>
  <kendo-dialog-actions style="border: none; padding: 15px;margin-bottom:15px;">
    <button kendoButton class="figma-btn-blue figma-popup" (click)="uploadFiles()">Upload</button>
  </kendo-dialog-actions>
</kendo-dialog>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270" [Data]="alertData" (close)="closeAlert()"></app-alert-popup>
