<div class="d-flex" style="margin-top:15px;gap:17px;">

    <div>
        <app-dropdown-list [data]="invoiceTypetDropdown" [selectedValue]="selectedInvoiceType" [width]="'250px'"
            textField="text" valueField="value" (valueChange)="onDropdownValueChange($event)">
        </app-dropdown-list>
    </div>
    <div class="colpadding0" style="display: flex; flex-direction: column; align-items: start; ">
        <header class="panel-heading tab-bg-primary" style="background: none !important; margin-left:-43px;margin-top: -5px;">




            <ul style="display: flex; align-items: center;">
                <li style="display: flex; align-items: center; margin-right: 20px;">
                    <input id="rdDow2" value="R" type="radio" class="custom-radio" name="invoiceType"
                        (change)="onMeasurementSystemChanges($event)" [disabled]="!isRowDataEmptys()" />
                    <h4 style="margin: 0 0 0 5px; color:black;">Regular</h4>
                </li>
                <li style="display: flex; align-items: center;">
                    <input id="rdFai2" value="D" type="radio" class="custom-radio" name="invoiceType"
                        (change)="onMeasurementSystemChanges($event)" [disabled]="!isRowDataEmptys()" />
                    <h4 style="margin: 0 0 0 5px; color:black;">Draft</h4>
                </li>
            </ul>
        </header>



    </div>

    <div>
        <app-dropdown-list [data]="ClientGstDropdownData" [selectedValue]="selectedClient" [width]="'250px'"
            textField="text" valueField="value" (valueChange)="onDropdownClientGstValueChange($event)">
        </app-dropdown-list>
    </div>



    <div class="col-md-2 colpadding0" style="padding-right: 10px;">
        <app-combo-box [width]="'106%'" [Placeholder]="'Select month/year'"
            (valueChange)="onDropdownMonthYearValueChange($event)" [data]="monthDropdown"
            [selectedValue]="selectedMonthYear" textField="name" valueField="id">
        </app-combo-box>
    </div>
</div>

<div class="grid-container" style="overflow-x: auto; width: 100%;">
    <kendo-grid class="custom-grid" [data]="gridData" [columnMenu]="true" [pageSize]="pageSize" [pageable]="true"
        (pageChange)="pageChange($event)" [sortable]="true" [skip]="skip" [resizable]="true"
        style="margin-top:15px; min-width: 2220px;"> <!-- Set a minimum width for scrolling -->

        <kendo-grid-column [width]="80">
            <ng-template kendoGridHeaderTemplate>
                <div class="cell-content" style="margin-left:10px;">
                    <input type="checkbox" style="margin-left: 10px;" [(ngModel)]="allSelected"
                        (change)="toggleSelectAll($event)" />
                </div>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="checkbox-container">
                    <input type="checkbox" style="margin-left: 13px;" [(ngModel)]="dataItem.selected"
                        [disabled]="dataItem.disabled" (change)="onItemSelectionChange(dataItem)" />
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="riM_ClientID" title="Client Id" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.riM_ClientID">
                    {{dataItem.riM_ClientID}}
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="clienT_NAME" title="Client Name" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.clienT_NAME">
                    {{dataItem.clienT_NAME}}
                </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="riM_InvoiceNo" title="InvoiceNo" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.riM_InvoiceNo">
                    {{dataItem.riM_InvoiceNo}}
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="invoiceDescription" title="Description" [width]="160">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.invoiceDescription">
                    {{dataItem.invoiceDescription}}
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="riA_Actual_Amount" title="Actual Amount" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.riA_Actual_Amount">
                    {{dataItem.riA_Actual_Amount}}
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="riA_Adjustment_Amount" title="Adjustment Amount" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.riA_Adjustment_Amount">
                    {{dataItem.riA_Adjustment_Amount}}
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="riS_Amount" title="	Sub Total" [width]="100">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.riS_Amount">
                    {{dataItem.riS_Amount}}
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="riA_CGSTAmount" title="CGST Amount" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.riA_CGSTAmount">
                    {{dataItem.riA_CGSTAmount}}
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="riA_CGSTAmount_Adjustments" title="CGST Amount Adjustment" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.riA_CGSTAmount_Adjustments">
                    {{dataItem.riA_CGSTAmount_Adjustments}}
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="riS_CGSTAmount" title="CGST Total" [width]="100">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.riS_CGSTAmount">
                    {{dataItem.riS_CGSTAmount}}
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="riA_SGSTAmount" title="SGST Amount" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.riA_SGSTAmount">
                    {{dataItem.riA_SGSTAmount}}
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="riA_SGSTAmount_Adjustments" title="SGST Amount Adjustment" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.riA_SGSTAmount_Adjustments">
                    {{dataItem.riA_SGSTAmount_Adjustments}}
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="riS_SGSTAmount" title="SGST Total" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.riS_SGSTAmount">
                    {{dataItem.riS_SGSTAmount}}
                </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="riA_IGSTAmount" title="IGST Amount" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.riA_IGSTAmount">
                    {{dataItem.riA_IGSTAmount}}
                </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="riA_IGSTAmount_Adjustments" title="IGST Amount Adjustment" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.riA_IGSTAmount_Adjustments">
                    {{dataItem.riA_IGSTAmount_Adjustments}}
                </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="riS_IGSTAmount" title="IGST Total" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.riS_IGSTAmount">
                    {{dataItem.riS_IGSTAmount}}
                </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="riA_Actual_Amount" title="Actuals Total" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.riA_Actual_Amount">
                    {{dataItem.riA_Actual_Amount}}
                </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="riA_Adjustment_Amount" title="Adjustments Total" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.riA_Adjustment_Amount">
                    {{dataItem.riA_Adjustment_Amount}}
                </div>
            </ng-template>
        </kendo-grid-column>
     

        



      

        <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
            <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
            <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
            <kendo-pager-next-buttons></kendo-pager-next-buttons>
            <kendo-pager-input></kendo-pager-input>
            <kendo-pager-info></kendo-pager-info>
            <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
        </ng-template>
    </kendo-grid>
</div>
<div class="colpadding0" style="display: flex; justify-content: center;">
    <header class="panel-heading tab-bg-primary" style="background: none !important; ">




        <ul style="display: flex; align-items: center; margin-top: 10px;">
            <li style="display: flex; align-items: center;">
                <input id="rdDow2" value="A" type="radio" class="custom-radio" name="invoiceType"
                    (change)="onMeasurementSystemChange($event)" [disabled]="!isRowDataEmpty()" />
                <h4 style="margin: 0 0 0 5px; color:black;">Approve </h4>
            </li>
            <li style="display: flex; align-items: center;">
                <input id="rdFai2" value="R" type="radio" class="custom-radio" name="invoiceType"
                    (change)="onMeasurementSystemChange($event)" [disabled]="!isRowDataEmpty()" />
                <h4 style="margin: 0 0 0 5px; color:black;">Reject</h4>
            </li>
            <li style="display: flex; align-items: center;">
                <input id="rdFai2" value="S" type="radio" class="custom-radio" name="invoiceType"
                    (change)="onMeasurementSystemChange($event)" [disabled]="!isRowDataEmpty()" />
                <h4 style="margin: 0 0 0 5px; color:black;">Send Back</h4>
            </li>
        </ul>
    </header>



</div>

<button class="figma-btn-blue " style="margin-left: 46%;" (click)="OnSubmitInvoiceApprovelPendingDetails()">
    Submit
</button>
<app-alert-popup *ngIf="alertData.alertVisible" [Width]="365" [Height]="210" [Data]="alertData"
    (close)="closeAlert()"></app-alert-popup>