import { Component } from '@angular/core';
import { InvoiceDetailsService } from '../../../service/invoice-details.service';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { GridDataResult, GridModule, PageChangeEvent } from '@progress/kendo-angular-grid';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { UserService } from '../../../service/user.service';
import { BlobService } from '../../../service/blob.service';

@Component({
  selector: 'app-invoice-draft-to-regular',
  standalone: true,
  imports: [ComboBoxComponent,GridActionItemComponent,FormsModule, NgIf, ReactiveFormsModule,GridModule,AlertPopupComponent, DropdownListComponent],
  templateUrl: './invoice-draft-to-regular.component.html',
  styleUrl: './invoice-draft-to-regular.component.css'
})
export class InvoiceDraftToRegularComponent {

  selectedMonth!: any;
  selectedYear!: any;
  monthDropdown: any[] = [];
  selectedMonthYear: any;
  clientType:any;
  invoiceDropdown:any[] =[]
  selectedClientType = { name: 'Select', id: 0 };
  clientDropdown:any[] =[]
  loading = false;
  public gridData :any;
  showSave!: boolean;
  take = 10;
  skip = 0;
  page = 1;
  public pageSize: number = 10;
  public buttonCount = 2;
  public sizes = [5, 10, 20, 50];
  selectedItems: any[] = [];
  allSelected: boolean = false;
  public pagedData: any[] = [];

  constructor(
    private invoice:InvoiceDetailsService,private blobService: BlobService,private users : UserService
  ){}

  ngOnInit(): void {
   this.getClientsMaster()
    this.generateMonthYearDropdown();
  }

  generateMonthYearDropdown() {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
  
    const startYear = 2020; // Starting year for the dropdown
    const endYear = 2025;   // Ending year for the dropdown
  
    for (let year = startYear; year <= endYear; year++) {
      months.forEach((month, index) => {
        this.monthDropdown.push({
          id: `${year}-${index + 1}`,  // Example id: '2023-11'
          name: `${month}-${year}`     // Example name: 'Nov-2023'
        });
      });
    }
  }

  onDropdownMonthYearValueChange(data: any) {
    this.selectedMonthYear = data;
  
    // Assuming data.name is in the format "MMM-YYYY" (e.g., "Nov-2023")
    const [monthAbbreviation, selectedYear] = data.name.split('-');
  
    // Map month abbreviation to its numeric value
    const monthMap: { [key: string]: string  } = {
     Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06',
  Jul: '07', Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12'
    };

    const selectedMonth = monthMap[monthAbbreviation];

    console.log('Selected numeric month:', selectedMonth);
    console.log('Selected year:', selectedYear);
  
  
    this.selectedMonth = selectedMonth;
    this.selectedYear = +selectedYear;
  }

  onDropdownValueChange(data: any) {
      this.clientType = data.value;
      console.log("testr",this.clientType)
      this.getGrid()
  }
  
  onItemSelectionChange(dataItem: any) {
    console.log('dsfds', dataItem)
    if (dataItem.selected) {
      if (!this.selectedItems.includes(dataItem)) {
        this.selectedItems.push(dataItem);
      }
    } else {
      const index = this.selectedItems.indexOf(dataItem);
      if (index > -1) {
        this.selectedItems.splice(index, 1);
      }
    }

    console.log('data', this.selectedItems)
    this.showSave = dataItem.pfecrA_IsProcessed == 'No';
  }

  public pageChange(event: PageChangeEvent): void {
    console.log('Page Change Event Received:', event);
    this.skip = event.skip;
    this.pageSize = event.take;
    this.loadGridData();
  }

  private loadGridData(): void {
    this.pagedData = this.gridData.slice(this.skip, this.skip + this.pageSize);
  }
  getClientsMaster() {
    this.invoice.getClientMasterDetails().subscribe({
      next: (response) => {
        const data = response.body;
        this.clientDropdown = [
          ...data.map((item: any) => ({
            text: item.text,
            value: item.value
          }))
        ];
      //  console.log('clientDropdown',this.clientDropdown)

      },
      error: (error) => {
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }

  public parentColumns: any[] = [
    { field: 'cliendID', title: 'ClientID' },
    { field: 'adressType', title: 'Draft Invoice Number' }
  ];

  handleButtonClick(event: any) {
    if (event.button.action === 'edit') {
      console.log('action grid')
    } 
  }


  getGrid() {
    this.loading = true;
  
    let payload = {
      Clientid: this.clientType,
      Month : this.selectedMonth,
      Year :this.selectedYear
    }
    this.invoice.getDraftInvoice(payload).subscribe({
      next: (response) => {
        const data = response.body;
        this.gridData = data
     
       this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }

  toggleSelectAll(event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    debugger
    // Update the selected state for each item in gridData
    this.gridData.forEach((item: { selected: boolean; }) => item.selected = checked);
  
    // Toggle bulkdownload and showSave based on checkbox state
    if (checked) {
  
      this.showSave = true;
    } else {
  
      this.showSave = false;
    }
  }

  getDownload() {
    this.loading = true;
  
    this.invoice.getDownload().subscribe({
      next: (response) => {
        const blobLink = response.body.filePath;
        const blobName = this.blobService.extractBlobName(blobLink);
        setTimeout(() => {
          this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
            const sasToken = tokenResponse.result;
        this.blobService.downloadFile(blobName,sasToken);
        this.loading = false;
          });
        }, 5000);
      },
      error: (error) => {
        this.loading = false;
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }

  setUpdate() {
    this.loading = true;
    
    this.selectedItems.forEach(item => {
      item.type = "reject"; 
      // item.userId = this.user.; 
  });
    this.invoice.getDownload().subscribe({
      next: (response) => {
        const blobLink = response.body.filePath;
        const blobName = this.blobService.extractBlobName(blobLink);
        setTimeout(() => {
          this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
            const sasToken = tokenResponse.result;
        this.blobService.downloadFile(blobName,sasToken);
        this.loading = false;
          });
        }, 5000);
      },
      error: (error) => {
        this.loading = false;
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }
}
