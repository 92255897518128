import { Component } from '@angular/core';
import { DatePipe, NgClass, NgIf } from '@angular/common';
import { GridModule } from '@progress/kendo-angular-grid';
import { PageChangeEvent } from '@progress/kendo-angular-treelist';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { BlobService } from '../../../service/blob.service';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { UserMasterAddEditPopupComponent } from '../../popups/user-master-add-edit-popup/user-master-add-edit-popup.component';
import { EntityService } from '../../../service/entity.service';
import { Router } from '@angular/router';
import { UserService } from '../../../service/user.service';
import { AddEditEntityMasterComponent } from '../../popups/add-edit-entity-master/add-edit-entity-master.component';
import { EntityUploadPopupComponent } from '../../popups/entity-upload-popup/entity-upload-popup.component';

@Component({
  selector: 'app-entity-master',
  standalone: true,
  imports: [NgClass, NgIf, DatePipe, GridModule, DropdownListComponent, AddEditEntityMasterComponent, UserMasterAddEditPopupComponent, IndicatorsModule, ComboBoxComponent, AlertPopupComponent, EntityUploadPopupComponent],
  templateUrl: './entity-master.component.html',
  styleUrl: './entity-master.component.css'
})
export class EntityMasterComponent {
  public buttonCount = 2;
  loading: boolean = false;
  loadingGrid: boolean = false;
  public sizes = [{text:'All',value:Infinity},5, 10, 20];
  public applyFilterEvent!: any;
  public gridData: any = { data: [], total: 0 };
  public pageSize: number = 10;
  take = 10;
  skip = 0;
  page = 1;
  userID = 0;
  deleteuserID = 0;
  showAddEditPopup = false;
  public defaultSelectedValue = 'Search EntityId/EntityName';
  public dropdown: { id: number; name: string }[] = [];
  public alertData ={ alertMessage: '', alertVisible: '' };
  Data !:any;
  openBulkUpload: boolean = false;
  custId!:any;
  Role!: string;
  constructor(private router: Router, private entityService: EntityService, private blobService: BlobService, private users: UserService) { }

  ngOnInit(): void {
    this.Role = this.users.getRole();
    this.custId = localStorage.getItem('CustomerId');
    this.loadGridData(this.custId, 0);
    this.userID = this.users.getUserId();
  }

  applyFilter() {
    //this.loading = true;
    this.loadGridData(this.custId,this.applyFilterEvent)
  }

  handleOpenPopup(popname: string) {
    debugger;
    if (popname == "BulkUpload") {
      this.openBulkUpload = true;
    }
    else if (popname == "Add"){
      this.Data=undefined;
      this.showAddEditPopup = true;
    }
  }

  public handleClosePopup(popname: string): void {
    if (popname == "BulkUpload") {
      this.openBulkUpload = false;
    }
  }

  onDropdownValueChange(value: any) {
    debugger;
    console.log('valuschange', value)
    this.applyFilterEvent = value.id;
  }

  OpenEditPopup(value: any) {
    this.showAddEditPopup=true;
    this.Data=value;
  }

  confirmAlert(value: any) {
    debugger;
    this.deleteuserID = value.id;
    this.alertData = { alertMessage: 'Are you sure you want to delete this record?', alertVisible: 'confirm' };
  }

  DeleteUser(value: any) {
    this.entityService.DeleteCustomerEntityConfirmation(this.deleteuserID).subscribe({
      next: (response) => {
        if (response.status === 200 && response.body.result==true) {
          this.DeleteCustomerBranch();
        } else {
          this.setErrorAlert();
        }
      },
      error: (error) => {
        console.error(error);
        this.setErrorAlert();
      }
    });
  }
  DeleteCustomerBranch() {
    this.entityService.DeleteCustomerBranch(this.deleteuserID).subscribe({
      next: (response) => {
        if (response.status === 200 && response.body.result==true) {
          this.alertData = { alertMessage: 'Deleted Successfully', alertVisible: 'success' };
          this.loadGridData(this.custId, 0);
        } else {
          this.setErrorAlert();
        }
      },
      error: (error) => {
        console.error(error);
        this.setErrorAlert();
      }
    });
  }

  private setErrorAlert() {
    this.alertData = { alertMessage: 'Server Error Occured', alertVisible: 'error' };
  }

  clearFilter() {
    this.loadGridData(this.custId, 0);
  }

  public pageChange(event: PageChangeEvent): void {
    this.loadingGrid=true;
    this.skip = event.skip;
    if(event.take==Infinity){
      this.loadingGrid=true;
      this.pageSize=this.gridData.total;
    }else{
      this.loadingGrid=true;
      this.pageSize = event.take;
    }
    this.loadGridData(this.custId, 0);
  }

  FilterDropdown(value: any) {
    this.entityService.GetCustomerEntityDetails_Forddl(this.custId, value).subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && data && data.data) {
            this.dropdown = data.data.map((item: any) => ({
              id: item.value,
              name: item.key
            }));
          } else {
            console.error("Unexpected data format", data);
          }
        }, error: (error) => {
          console.error("Error fetching dropdown data", error);
        }
      });
  }

  DropdownGridChange(value: any) {
    if (value == undefined) {
      this.loadGridData(this.custId, 0)
    }
  }

  closeAlert() {
    this.alertData = { alertMessage: '', alertVisible: '' };
  }

  loadGridData(value: number, filter: number) {
    this.loadingGrid = true;
    this.entityService.GetCustomerEntityDetails(value, filter, Math.floor(this.skip / this.pageSize) + 1, this.pageSize, this.skip).subscribe(
      {
        next: (response) => {
          console.log(response);
          const data = response.body;
          this.gridData = {
            data: data.data,
            total: data.total
          };
          this.loadingGrid = false;
          console.log(this.gridData)
        }, error: (error) => {
          this.loadingGrid = false;
          console.error('Error fetching grid data:', error);
        }
      }
    );
  }

  BackButton() {
    this.router.navigate(['/EntityDashboard']);
  }

  closePopup() {
    this.showAddEditPopup = false;
    this.loadGridData(this.custId, this.applyFilterEvent|| 0);
  }

 }
