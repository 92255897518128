<kendo-dialog #dialogRef  title="New Folder" (close)="closePopup()" [minWidth]="550" [height]="180"
    class="custom-dialog">
    <form [formGroup]="form">  
        <div class="row EmpContainer">
            <div class="col-md-12 d-flex align-items-center">
                <label class="f-label me-3">Folder Name<sub class="text-danger">*</sub></label>
                <div class="require-container">
                    <input type="text" class="form-control f-textbox" placeholder="Enter Folder Name"
                        formControlName="FolderName" [(ngModel)]="FolderName"
                        [ngClass]="{'is-invalid': form.get('FolderName')?.invalid && (form.get('FolderName')?.touched)}">
                    <div *ngIf="form.get('FolderName')?.invalid && (form.get('FolderName')?.touched) && form.get('FolderName')?.errors?.['pattern']"
                        class="invalid-feedback">
                        Restrict with alpha numeric values only.
                    </div>
                </div>
            </div>
           
        </div> </form>

    <kendo-dialog-actions class="ksave">
        <button type="button" (click)="onCreate()" class="figma-btn-blue">
            Create
        </button>
    </kendo-dialog-actions>
    <!-- <div *ngIf="loading" class="loader-overlay">
        <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
    </div> -->
</kendo-dialog>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270" [Height]="280" [Data]="alertData"
    (close)="closeAlert()"></app-alert-popup>