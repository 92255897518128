<div class="row colpadding0 " style="margin-top: 10px;">
  <div class="col-12 d-flex ">
    <div class="col-md-8 colpadding0 d-flex ">
      <app-combo-box [width]="'350px'" [Placeholder]="'Search Leave Type/Description'" (filterChange)="fetchLeaveTypeDropdown($event)"
       (valueChange)="DropdownValueChange($event)" [data]="leaveTypeDropdownData" textField="displayText" valueField="id" [selectedValue]="selectedLeaveType">
      </app-combo-box>
    
      <button class="figma-btn-white" type="submit" (click)="applyFilter()">
        <img src="../assets/vectors/ApplyFilter.svg" alt="">
      </button>
      <button class="figma-btn-white">
        <img src="../assets/vectors/ClearFilter.svg" alt="" (click)="clearFilter()">
      </button>
    </div>
    <div class="col-md-4 colpadding0">
      <button (click)="exportToExcel()"  class="exportbtn">
        <img src="../assets/vectors/ExportButton.svg" alt="">
      </button>
    </div>
  </div>
</div>



<div *ngIf="loading" class="loader-overlay">
  <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>

<kendo-grid class="custom-grid" style="margin-top:10px;" [data]="gridData" [columnMenu]="true" [pageSize]="pageSize" [pageable]="true"
  (pageChange)="pageChange($event)" [skip]="skip">
  <kendo-grid-column field="leaveType" title="Leave Type(Code)">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [title]="dataItem.leaveType" class="cell-content">
        {{dataItem.leaveType}}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="days" title="Deduction(Days)">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [title]="dataItem.days" class="cell-content">
        {{dataItem.days}}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="leave_Description" title="Description">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [title]="dataItem.leave_Description" class="cell-content">
        {{dataItem.leave_Description}}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="leaveCategory" title="Leave Category">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [title]="dataItem.leaveCategory" class="cell-content">
        {{dataItem.leaveCategory}}
      </div>
    </ng-template>
  </kendo-grid-column>
  <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
    <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
    <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
    <kendo-pager-next-buttons></kendo-pager-next-buttons>
    <kendo-pager-input></kendo-pager-input>
    <kendo-pager-info></kendo-pager-info>
    <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
  </ng-template>
</kendo-grid>