import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerMasterService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }
  getCustomerGridData(userID: number, custID: number, SPID: number, distID: number, roleCode: string, prodType: number, showSubDist: boolean, UniqueCustid: number, take: number, skip: number, page: number, pageSize: number): Observable<HttpResponse<any>> {
    let url;
    if (UniqueCustid == 0) {
      url = `${this.apiUrl}/api/Onboarding/GetCustomerList?userID=${userID}&custID=${custID}&SPID=${SPID}&distID=${distID}&roleCode=${roleCode}&prodType=${prodType}&showSubDist=${showSubDist}&take=${take}&skip=${skip}&page=${page}&pageSize=${pageSize}`;
    } else {
      url = `${this.apiUrl}/api/Onboarding/GetCustomerList?userID=${userID}&custID=${custID}&SPID=${SPID}&distID=${distID}&roleCode=${roleCode}&prodType=${prodType}&showSubDist=${showSubDist}&UniqueCustid=${UniqueCustid}&take=${take}&skip=${skip}&page=${page}&pageSize=${pageSize}`;
    }
    console.log(url);
    
    return this.http.get<any>(url, { observe: 'response' });
  }
  getDataByID(customerID: number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Onboarding/GetByID?customerID=${customerID}`;
    return this.http.get(url, { observe: 'response' });
  }
  getCustomerDropdown(userID: number, custID: number, SPID: number, distID: number, roleCode: string, prodType: number, showSubDist: boolean, Filter: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Onboarding/GetCustomerList_forddl?userID=${userID}&custID=${custID}&SPID=${SPID}&distID=${distID}&roleCode=${roleCode}&prodType=${prodType}&showSubDist=${showSubDist}&Filter=${Filter}`;
    return this.http.get<any>(url, { observe: 'response' });
  }
  deleteCustomerBranch(customerId: number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Onboarding/DeleteCustomer?customerId=${customerId}`;
    return this.http.post<any>(url, { observe: 'response' });
  }
  getCountryDropdown(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Onboarding/FillCountry`;
    return this.http.get(url, { observe: 'response' });
  }
  getStateDropdown(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Onboarding/FillStates`;
    return this.http.get(url, { observe: 'response' });
  }
  getStatusDropdown(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Onboarding/GetStatusValues`;
    return this.http.get(url, { observe: 'response' });
  }
  getCityByStateDropdown(stateID: number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Onboarding/FillLocationCityByStateID?stateID=${stateID}`;
    return this.http.get(url, { observe: 'response' });
  }
  GetServiceProviderID(avacom_CustomerID: number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/User/GetServiceProviderID?avacom_CustomerID=${avacom_CustomerID}`;
    return this.http.get(url, { observe: 'response' });
  }
  CheckCorporateIdExists(CheckCorporateIdExists: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Onboarding/CheckCorporateIdExists?CorporateId=${CheckCorporateIdExists}`;
    return this.http.get(url, { observe: 'response' });
  }
  getCustomerDataMapping(AvacomCustomerID: number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Onboarding/Get_Customer_CorporateClient_Mapping?AvacomCustomerID=${AvacomCustomerID}`;
    return this.http.get(url, { observe: 'response' });
  }
  getAssignProducts(customerID: number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Onboarding/GetAllProductMapping?customerID=${customerID}`;
    return this.http.get(url, { observe: 'response' });
  }
  createUpdateCustomer(value: any): Observable<HttpResponse<any>> {
    const body =
    {
      id: value.id || 0,
      name: value.name,
      address: value.address || "",
      industry: value.industry || 0,
      buyerName: value.buyerName || "",
      buyerContactNumber: value.buyerContactNumber || "",
      buyerEmail: value.buyerEmail || "",
      createdOn: value.createdOn || "",
      isDeleted: value.isDeleted || false,
      startDate: value.startDate || null,
      endDate: value.endDate || null,
      diskSpace: value.diskSpace || "",
      status: value.status || 0,
      iComplianceApplicable: value.iComplianceApplicable || 0,
      locationType: value.locationType || 0,
      verticalApplicable: value.verticalApplicable || 0,
      taskApplicable: value.taskApplicable || 0,
      isServiceProvider: value.isServiceProvider || true,
      serviceProviderID: value.serviceProviderID || 0,
      gstNum: value.gstNum || "",
      cRegNum: value.cRegNum || "",
      complianceProductType: value.complianceProductType || 0,
      logoPath: value.logoPath || "",
      canCreateSubDist: value.canCreateSubDist || true,
      isDistributor: value.isDistributor || true,
      parentID: value.parentID || 0,
      isPayment: value.isPayment || true,
      isLabelApplicable: value.isLabelApplicable || 0,
      isLock: value.isLock || true,
      lockingDays: value.lockingDays || 0,
      createdBy: value.createdBy || 0,
      createdFrom: value.createdFrom || 0
    }

    const url = `${this.apiUrl}/api/Onboarding/CreateUpdateCustomer`;
    return this.http.post(url, body, { observe: 'response' });
  }
  saveCustomerMaster(value: any): Observable<HttpResponse<any>> {
    const body =
    {
      avacoM_CustomerID: value.avacoM_CustomerID,
      cO_CorporateID: value.cO_CorporateID,
      cO_Name: value.cO_Name,
      cO_City: value.cO_City,
      cO_State: value.cO_State,
      cO_Country: value.cO_Country,
      cO_BDLocation: value.cO_BDLocation,
      cO_BDAnchor: value.cO_BDAnchor,
      cO_Pincode: value.cO_Pincode,
      cO_PAN: value.cO_PAN,
      cO_TAN: value.cO_TAN,
      cO_URL: value.cO_URL,
      cO_ContactDesignation: value.cO_ContactDesignation,
      cO_FAXNo: value.cO_FAXNo,
      cO_Status: value.cO_Status,
      cO_Version: 0,
      createdOn: value.createdOn,
      updatedOn: null,
      isProcessed: true,
      cO_IsAventisCorporate: 0
    }

    const url = `${this.apiUrl}/api/Onboarding/CreateUpdate_Customer_CorporateClient_Mapping`;
    return this.http.post(url, body, { observe: 'response' });
  }
  CreateProductMapping(value: any): Observable<HttpResponse<any>> {
    const body =
    {
      id: value.id|| 0,
      customerID: value.customerID,
      productID: value.productID,
      isActive: value.isActive,
      createdOn: value.createdOn,
      createdBy: value.createdBy

    }

    const url = `${this.apiUrl}/api/Onboarding/CreateProductMapping`;
    return this.http.post(url, body, { observe: 'response' });
  }
  UpdateProcessedStatus(customerID: number,corporateID:string,status:boolean): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Onboarding/Update_ProcessedStatus_Corporate?customerID=${customerID}&corporateID=${corporateID}&status=${status}`;
    return this.http.post(url,'', { observe: 'response' });
  }
  UploadClientLogo(file: File, custID: number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Onboarding/UpdateCustomerPhoto`;

    const formData: FormData = new FormData();
    formData.append('custID', custID.toString());
    formData.append('file', file);

    formData.forEach((value, key) => {
      console.log(`${key}:`, value);
    });

    return this.http.post(url, formData, {observe: 'response'});
  }
}
