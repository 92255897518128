<kendo-dialog #dialogRef [title]="dialogTitles" (close)="closePopup()"  [width]="340"
    class="custom-dialog">
    <form [formGroup]="userForm">
        <div class="dialog-content">
            <div class="form-group">
                <label for="username" class="f-label">User ID
                    <sub style="color: red; font-size: 15px;">*</sub>
                </label>
                <input class="f-textbox form-control" formControlName="username" placeholder="Enter User ID"
                    (blur)="checkUserId($event)" [attr.autocomplete]="'new-password'"
                    [ngClass]="{'is-invalid': userForm.get('username')?.invalid && (userForm.get('username')?.touched || userForm.get('username')?.dirty)}" />
                <small class="text-danger" *ngIf=" userForm.get('username')?.errors?.['usernameExists']"
                    style="float: left;">
                    Already exists!
                </small>
            </div>
            <!-- <div class="form-group">
                <label for="username" class="f-label">User ID
                    <sub style="color: red; font-size: 15px;">*</sub>
                </label>
                <input class="f-textbox form-control" formControlName="username" placeholder="Enter User ID"
                    (blur)="checkUserId($event)" [attr.autocomplete]="'new-password'" maxlength="15" pattern="\d{15}"
                    [ngClass]="{'is-invalid': userForm.get('username')?.invalid && (userForm.get('username')?.touched || userForm.get('username')?.dirty)}" />
                <small class="text-danger" *ngIf="userForm.get('username')?.errors?.['usernameExists']"
                    style="float: left;">
                    Already exists!
                </small>
                <small class="text-danger" *ngIf="userForm.get('username')?.errors?.['pattern']" style="float: left;">
                    Must be exactly 15 digits!
                </small>
            </div> -->

 <div class="form-group">
                <label for="password" class="f-label">Password
                    <sub style="color: red; font-size: 15px;">*</sub>
                </label>
                <div class="password-container">
                    <input kendoTextBox id="password" type="password" formControlName="password"
                        placeholder="Enter Password" [attr.autocomplete]="'new-password'"  class="form-control masking f-textbox"
                        (input)="formatPassword($event)"
                        [ngClass]="{'is-invalid': userForm.get('password')?.invalid && (userForm.get('password')?.touched || userForm.get('password')?.dirty)}" />

                    <button type="button" class="figma-btn-white password-toggle" (click)="togglePasswordVisibility()">
                        <img *ngIf="showPassword" src="../assets/vectors/eye_112_x2.svg"
                            alt="Toggle Password visibility" />
                        <img *ngIf="!showPassword" src="../assets/vectors/EyeClosed.svg" alt="" />
                    </button>
                </div>
            </div> 
            <!-- <div class="form-group">
                <label for="password" class="f-label">Password
                    <sub style="color: red; font-size: 15px;">*</sub>
                </label>
                <div class="password-container">
                    <input kendoTextBox id="password" [type]="showPassword ? 'text' : 'password'"
                        formControlName="password" placeholder="Enter Password" [attr.autocomplete]="'new-password'"
                        class="form-control masking f-textbox" maxlength="15" pattern="^\d{1,15}$"
                        (input)="formatPassword($event)"
                        [ngClass]="{'is-invalid': userForm.get('password')?.invalid && (userForm.get('password')?.touched || userForm.get('password')?.dirty)}" />

                    <button type="button" class="figma-btn-white password-toggle" (click)="togglePasswordVisibility()">
                        <img *ngIf="showPassword" src="../assets/vectors/eye_112_x2.svg"
                            alt="Toggle Password visibility" />
                        <img *ngIf="!showPassword" src="../assets/vectors/EyeClosed.svg"
                            alt="Toggle Password visibility" />
                    </button>
                </div>
                <small class="text-danger" *ngIf="userForm.get('password')?.errors?.['pattern']">
                    Password must be up to 15 digits only!
                </small>
            </div> -->

        </div>
        <kendo-dialog-actions style="margin-bottom: -15px;">
            <button kendoButton type="submit" class="figma-btn-blue figma-popup" [disabled]="userForm.invalid"
                (click)="onSubmit()">
                Save
            </button>
        </kendo-dialog-actions>
    </form>
</kendo-dialog>

<app-alert-popup *ngIf="alertData" [Width]="270"  [Data]="alertData"
    (close)="closePopup()"></app-alert-popup>