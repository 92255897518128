import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { environment } from '../../../environments/environment';
import { InputFilesService } from '../../../service/input-files.service';
import { UserService } from '../../../service/user.service';
import { ActivatedRoute } from '@angular/router';
import { BlobService } from '../../../service/blob.service';

@Component({
  selector: 'app-challan-history-performer',
  standalone: true,
  imports: [DialogsModule,GridActionItemComponent],
  templateUrl: './challan-history-performer.component.html',
  styleUrl: './challan-history-performer.component.css'
})
export class ChallanHistoryPerformerComponent {
  @Output() close = new EventEmitter<void>();
  public gridData: GridDataResult = { data: [], total: 0 };
  @Input() selectedMonth:any
  @Input() selectedYear:any
  @Input() selectedComplianceId:any
  
  public alertData = { alertMessage: '', alertVisible: '' };
  showState = false;
  private blobUrl: string = environment.blobUrl;

  constructor(private inputService:InputFilesService,private user:UserService, private blobService: BlobService,private route: ActivatedRoute){}
  ngOnInit(): void {
    
    this.getGridData()
  }
  getGridData(){
    const payload={
      EntityId:this.user.getClientId(),
      month:this.selectedMonth,
      year:this.selectedYear.toString(),
      ComplianceType:this.selectedComplianceId,
      UserType:'P'
    }

    this.inputService.getChallanHistoryData(payload).subscribe({
      next:(response)=>{
        console.log(response);
        
        const data = response.body || [];
        this.gridData = {
          data: data,
          total: data.length
        };
      }
    })
  }

  public parentColumns: any[] = [
    {field: 'filename',title: 'File Name'},
    { field: 'uploadedAt', title: 'Uploaded On' },
    { field: 'uploadedBy', title: 'Uploaded By' },
    { field: 'docType', title: 'Document Type' },
    {
      field: 'Action',
      isIconColumn: true,
      title: 'Action',
      
      filteredIcons: [

        {
          url: '../assets/vectors/ExcelDownload.svg',
          alt: 'Download Icon',
          action: 'download'
        }
      ]
    }
  ];

  onActionClick(value:any){
    console.log(value)
    const file = value.dataItem.filePath;    
  
    this.inputService.downloadChallan(file,'P').subscribe(
      {
        next: (response) => {
          console.log(response);          
          const blobLink = response.body.filePath;
          let sasToken = response.body.sasToken
          const blobName = this.blobService.downloadBlobFile(blobLink,sasToken);
        }, error: (error) => {
          console.error('Error fetching blob link:', error);
          this.alertData = { alertMessage: error.error, alertVisible: 'error' };
          this.showState = false;          
        }
      });
    }
  

  public closePopup(): void {
    this.close.emit();
  }   
}
