import { Component } from '@angular/core';
import { DatePipe, NgClass, NgIf } from '@angular/common';
import { GridModule } from '@progress/kendo-angular-grid';
import { PageChangeEvent } from '@progress/kendo-angular-treelist';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { UserMasterService } from '../../../service/user-master.service';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { BlobService } from '../../../service/blob.service';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { UserMasterAddEditPopupComponent } from '../../popups/user-master-add-edit-popup/user-master-add-edit-popup.component';
import { UserService } from '../../../service/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-master',
  standalone: true,
  imports: [NgClass, NgIf, DatePipe, GridModule, DropdownListComponent, UserMasterAddEditPopupComponent, IndicatorsModule, ComboBoxComponent, AlertPopupComponent],
  templateUrl: './user-master.component.html',
  styleUrl: './user-master.component.css'
})
export class UserMasterComponent {
  public buttonCount = 2;
  loading: boolean = false;
  public sizes = [{text:'All',value:Infinity},5, 10, 20];
  public applyFilterEvent:any={id:0,name:''};
  public gridData: any = { data: [], total: 0 };
  public pageSize: number = 10;
  take = 10;
  skip = 0;
  page = 1;
  LoggedInuserID = 0;
  userID = '';
  showAddEditPopup = false;
  public defaultSelectedValue = 'Search User Name/Email';
  public dropdown: { id: number; name: string }[] = [];
  public alertData = { alertMessage: '', alertVisible: '' };

  constructor(private router: Router, private user: UserMasterService, private blobService: BlobService, private users: UserService) { }

  ngOnInit(): void {
    this.loadGridData('', '');
    this.LoggedInuserID = this.users.getUserId();
  }

  applyFilter() {
    this.loading = true;
    this.user.getUserMastersByUserId(596, this.applyFilterEvent.id, Math.floor(this.skip / this.pageSize) + 1, this.pageSize, this.skip).subscribe(
      {
        next: (response) => {
          debugger;
          const data = response.body;
          this.gridData = {
            data: data.data.slice(this.skip, this.skip + this.pageSize),
            total: data.total
          };
          this.loading = false;
          console.log(this.gridData, 'user grid data')
        }, error: (error) => {
          this.loading = false;
          console.error('Error fetching grid data:', error);
        }
      }
    );
  }

  OpenAddNewPopup() {
    this.userID='';
    this.showAddEditPopup = true;
  }

  onDropdownValueChange(value: any) {
    debugger;
    this.applyFilterEvent = {id:value.id,name:value.name};
  }

  OpenEditPopup(value: any) {
    this.userID = value.id;
    this.showAddEditPopup = true;
  }

  confirmAlert(value: any) {
    debugger;
    this.LoggedInuserID = value.id;
    this.alertData = { alertMessage: 'Are you sure you want to delete this user?', alertVisible: 'confirm' };
  }

  DeleteUser(value: any) {
    debugger;
    this.user.DeleteUser(this.LoggedInuserID).subscribe({
      next: (response) => {
        if (response.status === 200 && response.body) {
          this.alertData = { alertMessage: 'Customer Deleted Successfully', alertVisible: 'success' };
          this.loadGridData('', '');
        } else {
          this.setErrorAlert();
        }
      },
      error: (error) => {
        console.error(error);
        this.setErrorAlert();
      }
    });
  }

  private setErrorAlert() {
    this.alertData = { alertMessage: 'Server Error Occured', alertVisible: 'error' };
  }

  clearFilter() {
    this.loadGridData('', '');
    this.applyFilterEvent=null
  }

  public pageChange(event: PageChangeEvent): void {
    console.log('Page Change Event Received:', event);
    this.skip = event.skip;
    if(event.take==Infinity){
      this.pageSize = this.gridData.total;
    }else{
      this.pageSize = event.take;
    }
    this.loadGridData('', '');
  }

  FilterDropdown(value: string) {
    this.user.getUserDropdown(596, value).subscribe({
        next: (response) => {
            const data = response.body;
            if (data && data.data) {
                this.dropdown = data.data.map((item: any) => ({
                    id: item.value,
                    name: item.key
                }));
                this.dropdown = this.dropdown.filter(item => 
                    item.name.toLowerCase().startsWith(value.toLowerCase())
                );
            } else {
                console.error("Unexpected data format", data);
            }
        },
        error: (error) => {
            console.error("Error fetching dropdown data", error);
        }
    });
}

  DropdownGridChange(value: any) {
    if (value == undefined) {
      this.loadGridData('', '')
    }
  }

  closeAlert() {
    this.alertData = { alertMessage: '', alertVisible: '' };
  }

  loadGridData(value: string, filter: string) {
    debugger;
    this.loading = true;
    this.user.getUserMasters(596, Math.floor(this.skip / this.pageSize) + 1, this.pageSize, this.skip).subscribe(
      {
        next: (response) => {
          debugger;
          console.log(response);
          
          const data = response.body;
          this.gridData = {
            data: data.data,
            total: data.total
          };
          this.loading = false;
        }, error: (error) => {
          this.loading = false;
          console.error('Error fetching grid data:', error);
        }
      }
    );
  }

  BackButton() {
    this.router.navigate(['/EntityDashboard']);
  }

  closePopup() {
    this.showAddEditPopup = false;
    this.loadGridData('','');
  }

  exportToExcel() {
    debugger;
    this.loading = true;
    this.user.getUserMasterBlobLink(546, this.LoggedInuserID).subscribe({
      next: (res: any) => {
        debugger;
        const blobLink = res.filePath;
        const blobName = this.blobService.extractBlobName(blobLink);
        setTimeout(() => {
          this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
            debugger;
            const sasToken = tokenResponse.result;
            this.blobService.downloadFile(blobName, sasToken);
            this.loading = false;
          });
        }, 5000);
      },
      error: (error) => {
        console.error('Error fetching blob link:', error);
        this.loading = false;
      }
    });
  }
}
