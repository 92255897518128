import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DropdownListComponent } from '../../components/dropdown-list/dropdown-list.component';
import { WorkspaceService } from '../../service/workspace.service';
import { NgClass, NgIf } from '@angular/common';
import {
  DropDownsModule,
  MultiSelectComponent,
  MultiSelectModule,
  MultiSelectTreeComponent,
} from '@progress/kendo-angular-dropdowns';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { LabelModule } from '@progress/kendo-angular-label';
import { GridModule } from '@progress/kendo-angular-grid';
import { PageChangeEvent } from '@progress/kendo-angular-treelist';
import { UserService } from '../../service/user.service';
import { CommonService } from '../../service/common.service';
import { UserBranchMappingService } from '../../service/user-branch-mapping.service';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { WorkspaceRemanualUploadPopupComponent } from '../popups/workspace-remanual-upload-popup/workspace-remanual-upload-popup.component';
import { Router } from '@angular/router';
import { AlertPopupComponent } from '../popups/alert-popup/alert-popup.component';
import { WorkspaceInputFilePopupComponent } from '../popups/workspace-input-file-popup/workspace-input-file-popup.component';

type Branch = {
  id: number;
  name: string;
  parentID: number | null;
  branchType: string;
  branchStatus: string;
  fullName: string | null;
  children?: Branch[];
};

type clraBranch = {
  parentID: number | null;
  id: number;
  name: string | null;
  level: number;
  type: string | null;
  children: clraBranch[];
};
@Component({
  selector: 'app-workspace',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    AlertPopupComponent,
    DropdownListComponent,
    NgClass,
    MultiSelectModule,
    FormsModule,
    TreeViewModule,
    LabelModule,
    DropDownsModule,
    GridModule,
    NgIf,
    IndicatorsModule,
    WorkspaceRemanualUploadPopupComponent,
    WorkspaceInputFilePopupComponent
  ],
  templateUrl: './workspace.component.html',
  styleUrl: './workspace.component.css',
})
export class WorkspaceComponent implements OnInit {
  @Output() close = new EventEmitter<void>();
  @HostListener('window:beforeunload', ['$event'])
  form!: FormGroup;
  challanGridData: any[] = [];
  registerGridData: any[] = [];
  returnGridData: any[] = [];
  take = 10;
  skip = 0;
  page = 1;
  pageSize = 10;
  allSelected: boolean = false;
  complianceType: Array<{ text: any; value: any }> = [
    { text: 'Compliance', value: 'Compliance' },
  ];
  frequency: Array<{ text: any; value: any }> = [
    { text: 'Frequency', value: 'Frequency' },
  ];
  Act: Array<{ text: any; value: any }> = [
    { text: 'Frequency', value: 'Frequency' },
  ];
  years: Array<{ text: any; value: any }> = [
    { text: 'Year', value: 'Year' }, // Default value
  ];
  period: Array<{ text: any; value: any }> = [
    { text: 'Period', value: 'Period' },
  ];
  files: any[] = [];
  entity: any[] = [];
  selectedComplianceType: { text: any; value: any } = {
    text: 'Compliance',
    value: 'Compliance',
  };
  selectedFrequency: { text: any; value: any } = {
    text: 'Frequency',
    value: 'Frequency',
  };
  selectedAct: { text: any; value: any } = {
    text: 'Select Act',
    value: 'Select Act',
  };
  selectedYear: { text: any; value: any } = { text: 'Year', value: 'Year' };
  selectedPeriod: { text: any; value: any } = {
    text: 'Period',
    value: 'Period',
  };
  filePlaceholder: string = 'All Files';
  entityPlaceholder: string = 'Entity/Sub-Entity/Location';
  selectedFiles: any;
  selectAll: boolean = false;
  type: any;
  frequencyType!: any;
  public clraBranchDropdown: clraBranch[] = [];
  public BranchDropdown: Branch[] = [];
  public selectedBranch: any;
  IOJsonId: any;
  selectedItems: any[] = [];
  public selectedBranches: any[] = [];
  loading = false;
  openUpload = false;
  inputs: any;
  public alertData = { alertMessage: '', alertVisible: '' };
  parameterID: any;
  complianceID: any;
  isError: boolean = false;
  isLocalData: boolean = false;
  file: any[] = [];
  storedBranch: any;
  isSessionData: boolean = false;
  complianceTypeLocal: any;
  openInput=false
  actId:any
  complianceId:any
  compliance:any
  @ViewChild('multiselectTree', { static: false })
  multiselectTree!: MultiSelectTreeComponent;
  popupSettings: any = { width: '280px' };
  @ViewChild('multiselectTreeBranches', { static: false })
  multiselectTreeBranches!: MultiSelectComponent;
 
  public buttonCount = 5; // Number of page buttons to display
  public sizes = [5, 10, 20]; // Page size options
  selectedscheduleList:any;
 
  constructor(
    private workspace: WorkspaceService,
    private router: Router,
    private fb: FormBuilder,
    private user: UserService,
    private common: CommonService,
    private userBranch: UserBranchMappingService,
    private cdr: ChangeDetectorRef
  ) {}

  async ngOnInit(): Promise<void> {
    this.form = this.fb.group({
      compliance: ['', [Validators.required]],
      frequency: ['', []],
      act: ['', [Validators.required]],
      year: ['', [Validators.required]],
      period: ['', [Validators.required]],
      files: ['', [Validators.required]],
      branches: [''],
    });

    this.getComplianceType();
    this.getYears();

    this.type = 'Months';
    this.getPeriods();
    this.complianceTypeLocal = sessionStorage.getItem('complianceType');
 
    window.addEventListener("beforeunload", function() {
      sessionStorage.removeItem('month');
      sessionStorage.removeItem('year');
      sessionStorage.removeItem('Input');
      sessionStorage.removeItem('complianceType');
      sessionStorage.removeItem('JsonId');
      sessionStorage.removeItem('formValues');
    }); 
    
    const storedValues = sessionStorage.getItem('formValues');
    if (storedValues) {
      this.isLocalData = true;
      this.isSessionData = true;
      const parsedValue = JSON.parse(storedValues);
      if (parsedValue.compliance) {
        this.selectedComplianceType = parsedValue.compliance;

        await this.onComplianceTypeChange(parsedValue.compliance, 0);
        this.selectedAct = parsedValue.act;
        this.form.patchValue({
          compliance: parsedValue.compliance,
        });
      }
      if (parsedValue.frequency) {
        this.selectedAct = parsedValue.frequency;
        this.onFrequencyTypeChange(parsedValue.frequency);
        this.form.patchValue({
          frequency: parsedValue.frequency,
        });
      }
      if (parsedValue.act) {
        this.selectedAct = parsedValue.act;
        this.onActTypeChange(parsedValue.act);
        this.form.patchValue({
          act: parsedValue.act,
        });
      }
      if (parsedValue.year) {
        this.selectedYear = parsedValue.year;
        this.onYearChange(parsedValue.year);
        this.form.patchValue({
          year: parsedValue.year,
        });
      }
      if (parsedValue.period) {
        this.selectedPeriod = parsedValue.period;
        this.onPeriodChange(parsedValue.period);
        this.form.patchValue({
          period: parsedValue.period,
        });
      }
      if (parsedValue.files) {
        this.selectedFiles = parsedValue.files;
        this.form.patchValue({
          files: parsedValue.files,
        });
        if (this.multiselectTree) {
          this.multiselectTree.writeValue(
            parsedValue.files.map((file: any) => file.id)
          );
        }
      }
      if (parsedValue.branches) {
        debugger;
        this.selectedBranches = parsedValue.branches;
        this.storedBranch = parsedValue.branches;
        this.form.patchValue({
          branches: parsedValue.branches,
        });
        if (this.multiselectTreeBranches) {
          this.multiselectTreeBranches.writeValue(
            parsedValue.branches.map((file: any) => file.id)
          );
        }
      }
    }
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
  }
  toggleSelectAll(event: Event, type: string) {
    const checked = (event.target as HTMLInputElement).checked;
    if (type === 'Register') {
      this.registerGridData.forEach((item) => (item.selected = checked));
      this.updateSelectedItems('Register');
    } else if (type === 'Return') {
      this.returnGridData.forEach((item) => (item.selected = checked));
      this.updateSelectedItems('Return');
    } else if (type === 'Challan') {
      this.challanGridData.forEach((item) => (item.selected = checked));
      this.updateSelectedItems('Challan');
    }
  }
  onItemSelectionChange(dataItem: any, type: string) {
    dataItem.selected = !dataItem.selected;
    if (type === 'Register') this.updateSelectedItems('Register');
    else if (type === 'Return') this.updateSelectedItems('Return');
    else if (type === 'Challan') this.updateSelectedItems('Challan');
  }
  getComplianceIDs(data: any) {
    const complianceDetails = data.map((item: any) => {
      return {
        complianceID: item.complianceID,
        scheduleList: item.scheduleList,
        returnRegisterChallanID: item.returnRegisterChallanID,
      };
    });

    const complianceIDs = complianceDetails
      .map((item: any) => item.complianceID)
      .join(',');
    const scheduleLists = complianceDetails
      .map((item: any) => item.scheduleList)
      .join(',');
    const returnRegisterChallanIDs = complianceDetails
      .map((item: any) => item.returnRegisterChallanID)
      .join(',');

    return {
      complianceIDs,
      scheduleLists,
      returnRegisterChallanIDs,
    };
  }

  updateSelectedItems(type: string) {
    if (type === 'Register') {
      this.selectedItems = this.registerGridData.filter(
        (item) => item.selected
      );
      this.complianceID = this.getComplianceIDs(this.selectedItems);
    } else if (type === 'Return') {
      this.selectedItems = this.returnGridData.filter((item) => item.selected);
      this.complianceID = this.getComplianceIDs(this.selectedItems);
    } else if (type === 'Challan') {
      this.selectedItems = this.challanGridData.filter((item) => item.selected);
      this.complianceID = this.getComplianceIDs(this.selectedItems);
    }
  }

  calculateMaxTextLength(data: any[]): number {
    let maxLength = 0;

    const findMaxLength = (items: any[]) => {
      items.forEach((item) => {
        maxLength = Math.max(maxLength, item.name.length);
      });
    };

    findMaxLength(data);
    return maxLength;
  }

  getComplianceType() {
    const type = 'ComplianceName';
    this.workspace.getcompliance(type).subscribe({
      next: (response) => {
        const data = response.body;
        const fetchedCompliance = data.map((item: any) => ({
          text: item.text,
          value: item.value,
        }));
        this.complianceType = [
          { text: 'Compliance', value: 'Compliance' },
          ...fetchedCompliance,
        ];
      },
    });
  }
  public onComplianceTypeChange(value: any, checkEventNumber: number) {
    this.isSessionData = this.complianceTypeLocal == value.value ? true : false;
    if (checkEventNumber == 1) {
      this.isSessionData = false;
    }
    this.selectedComplianceType = { text: value.text, value: value.value };
    this.selectedFrequency = { text: 'Frequency', value: 'Frequency' };
    this.selectedAct = { text: 'Select Act', value: 'Select Act' };
    this.selectedYear = { text: 'Year', value: 'Year' };
    this.selectedPeriod = { text: 'Period', value: 'Period' };
    this.selectedItems = [];
    this.filePlaceholder = 'All Files';
    this.selectedFiles = '';
    this.entityPlaceholder = 'Entity/Sub-Entity/Location';
    this.selectedBranch = '';
    this.selectedBranches = [];

    if (this.isLocalData) {
      this.loading = true;
    }
    if (this.multiselectTree) {
      this.multiselectTree.writeValue([]);
    }
    this.getActDropdown();
    this.getFiles();
    if (this.selectedComplianceType.value === 'Return') {
      this.getFrequency();
    } else {
      this.frequency = [];
    }
    if (
      this.selectedComplianceType.value === 'Register' ||
      this.selectedComplianceType.value === 'Challan'
    ) {
      this.type = 'Months';
      this.getPeriods();
    } else if (this.selectedComplianceType.value === 'Return') {
      this.period = [];
    }
    this.getActDropdown();
    this.getFiles();
    if (
      this.selectedComplianceType.value === 'Return' ||
      this.selectedComplianceType.value === 'Challan'
    ) {
      this.getEntityDropdown();
    }
    this.form.get('compliance')?.setValue(this.selectedComplianceType);
    if (this.selectedComplianceType.value === 'Compliance') {
      this.form.get('compliance')?.setErrors({ invalidCompliance: true });
    } else {
      this.form.get('compliance')?.setErrors(null);
    }
    if (this.form.get('compliance')?.valid) {
      this.form.get('compliance')?.markAsTouched();
    }

    if (this.isLocalData) {
      this.loading = false;
      this.isLocalData = false;
    }
  }
  getFrequency() {
    const type = 'Frequency';
    this.workspace.getcompliance(type).subscribe({
      next: (response) => {
        const data = response.body;
        const fetchedFrequency = data.map((item: any) => ({
          text: item.text,
          value: item.value,
        }));

        this.frequency = [
          { text: 'Frequency', value: 'Frequency' },
          ...fetchedFrequency,
        ];
      },
    });
  }

  onFrequencyTypeChange(value: any) {
    this.selectedFrequency = { text: value.text, value: value.value };

    if (this.selectedFrequency.value === 'Annual') {
      this.type = 'SubFrequencyA';
      this.getPeriods();
    } else if (this.selectedFrequency.value === 'HalfYearly') {
      this.type = 'SubFrequencyH';
      this.getPeriods();
    } else if (this.selectedFrequency.value === 'Monthly') {
      this.type = 'Months';
      this.getPeriods();
    } else if (this.selectedFrequency.value === 'Quarterly') {
      this.type = 'SubFrequencyQ';
      this.getPeriods();
    }
    this.getActDropdown();
    this.form.get('frequency')?.setValue(this.selectedFrequency);
    if (this.form.get('frequency')?.valid) {
      this.form.get('frequency')?.markAsTouched();
    }
  }

  getActDropdown() {
    const type = this.selectedComplianceType.value;
    if (type === 'Register' || type === 'Challan') {
      this.frequencyType = 'Monthly';
    } else {
      this.frequencyType = this.selectedFrequency.text;
    }

    this.workspace.getActs(type, this.frequencyType).subscribe({
      next: (response) => {
        const data = response.body;
        const fetchedAct = data.map((item: any) => ({
          text: item.text,
          value: item.value,
        }));
        this.Act = [{ text: 'Select Act', value: 'Select Act' }, ...fetchedAct];
      },
    });
  }

  onActTypeChange(value: any) {
    this.selectedAct = { text: value.text, value: value.value };

    if (this.selectedComplianceType.value === 'Register') {
      this.getEntityDropdown();
    }
    this.form.get('act')?.setValue(this.selectedAct);
    if (this.selectedAct.value === 'Select Act') {
      this.form.get('act')?.setErrors({ invalidAct: true });
    } else {
      this.form.get('act')?.setErrors(null);
    }
    if (this.form.get('act')?.valid) {
      this.form.get('act')?.markAsTouched();
    }
  }

  getYears(): void {
    const currentYear = new Date().getFullYear();
    this.years = [{ text: 'Year', value: 'Year' }];
    for (let year = currentYear; year >= 2017; year--) {
      this.years.push({ text: year.toString(), value: year });
    }
  }

  onYearChange(value: any): void {
    this.selectedYear = { text: value.text, value: value.value };
    this.form.get('year')?.setValue(this.selectedYear);
    if (this.selectedYear.value === 'Year') {
      this.form.get('year')?.setErrors({ invalidYear: true });
    } else {
      this.form.get('year')?.setErrors(null);
    }
    if (this.form.get('year')?.valid) {
      this.form.get('year')?.markAsTouched();
    }
  }

  getPeriods(): void {
    this.workspace.getcompliance(this.type).subscribe({
      next: (response) => {
        const data = response.body;
        const fetchedPeriod = data.map((item: any) => ({
          text: item.text,
          value: item.value,
        }));
        this.period = [{ text: 'Period', value: 'Period' }, ...fetchedPeriod];
        if (this.selectedPeriod != null) {
          this.selectedPeriod = this.period.filter(
            (p) => p.value == this.selectedPeriod.value
          )[0];
        }
      },
    });
  }

  onPeriodChange(value: any): void {
    this.selectedPeriod = { text: value.text, value: value.value };
    this.form.get('period')?.setValue(this.selectedPeriod);
    if (this.selectedPeriod.value === 'Period') {
      this.form.get('period')?.setErrors({ invalidPeriod: true });
    } else {
      this.form.get('period')?.setErrors(null);
    }
    if (this.form.get('period')?.valid) {
      this.form.get('period')?.markAsTouched();
    }
  }

  public tagMapper(tags: any[]): any[] {
    return tags.length < 3 ? tags : [tags];
  }

  buildHierarchy(data: Branch[]): Branch[] {
    const grouped: { [key: number]: Branch[] } = data.reduce((acc, item) => {
      (acc[item.parentID || -1] = acc[item.parentID || -1] || []).push(item);
      return acc;
    }, {} as { [key: number]: Branch[] });

    function buildTree(parentID: number | null): Branch[] {
      return (grouped[parentID || -1] || []).map((item) => ({
        ...item,
        children: buildTree(item.id),
      }));
    }

    return buildTree(null);
  }

  getFiles() {
    const entityId = this.user.getClientId();
    const complianceType = this.selectedComplianceType.value;

    this.workspace.getAllFiles('I', complianceType, entityId).subscribe({
      next: (response) => {
        const data = response.body;

        const selectAllNode = {
          id: 'select-all',
          name: 'Select All',
          items: data.map((item: any) => ({
            id: item.id,
            name: item.name,
          })),
        };

        this.files = [
          selectAllNode,
          ...data.map((item: any) => ({
            id: item.id,
            name: item.name,
          })),
        ];

        const maxLength = this.calculateMaxTextLength(this.files);
        const calculatedWidth = Math.max(280, maxLength * 10);
        this.popupSettings = { width: `${calculatedWidth}px` };
      },
      error: (err) => {
        console.error('Error fetching Files', err);
      },
    });
  }

  onFileChange(value: any) {
    const selectedIds = value.map((file: any) => file.id);

    if (selectedIds.includes('select-all')) {
      const allFileIds = this.getAllFileIds(this.files);

      this.form.controls['files'].setValue(allFileIds);
      this.selectedFiles = allFileIds.join(',');

      value = this.files.map((file: any) => {
        file.checked = true;
        if (file.items) {
          file.items.forEach((child: any) => (child.checked = true));
        }
        return file;
      });
    } else {
      this.selectedFiles = selectedIds.join(',');
      this.form.controls['files'].setValue(selectedIds);

      value.forEach((file: any) => {
        file.checked = selectedIds.includes(file.id);
        if (file.items) {
          file.items.forEach((child: any) => {
            child.checked = selectedIds.includes(child.id);
          });
        }
      });
    }

    this.form.get('files')?.patchValue(value);
  }

  private getAllFileIds(files: any): string[] {
    let ids: string[] = [];

    files.forEach((file: any) => {
      if (file.id !== 'select-all') {
        ids.push(file.id);
        if (file.items) {
          ids = ids.concat(this.getAllFileIds(file.items));
        }
      }
    });

    return ids;
  }

  getEntityDropdown() {
    const entityId = this.user.getClientId();

    if (
      this.selectedComplianceType.value === 'Return' ||
      this.selectedComplianceType.value === 'Challan'
    ) {
      this.userBranch.getBranchDropdown(entityId).subscribe({
        next: (response) => {
          const data = response.body;
          if (data && Array.isArray(data)) {
            this.BranchDropdown = this.buildHierarchy(data);
            const maxLength = this.calculateMaxTextLength(this.BranchDropdown);

            const calculatedWidth = Math.max(280, maxLength * 10);
            this.popupSettings = { width: `${calculatedWidth}px` };
          } else {
            console.error('Unexpected data format', data);
          }
        },
      });
    }

    if (this.selectedComplianceType.value === 'Register') {
      if (
        this.selectedAct.value === 'SEA' ||
        this.selectedAct.value === 'FACT'
      ) {
        this.userBranch.getBranchDropdown(entityId).subscribe({
          next: (response) => {
            const data = response.body;
            if (data && Array.isArray(data)) {
              this.BranchDropdown = this.buildHierarchy(data);
              const maxLength = this.calculateMaxTextLength(
                this.BranchDropdown
              );

              const calculatedWidth = Math.max(280, maxLength * 10);
              this.popupSettings = { width: `${calculatedWidth}px` };
            } else {
              console.error('Unexpected data format', data);
            }
          },
        });
      } else if (
        this.selectedAct.value === 'FCLRA' ||
        this.selectedAct.value === 'SCLRA'
      ) {
        this.common.getClraType(entityId).subscribe({
          next: (response) => {
            if (response.body.result === 'CAC') {
              this.userBranch.getCLRABranchDropdown(entityId).subscribe({
                next: (response) => {
                  const data = response.body;
                  if (data && Array.isArray(data)) {
                    this.clraBranchDropdown = this.mapHierarchy(data);
                    const maxLength = this.calculateMaxTextLengthclra(
                      this.clraBranchDropdown
                    );

                    const calculatedWidth = Math.max(280, maxLength * 10);
                    this.popupSettings = { width: `${calculatedWidth}px` };
                  } else {
                    console.error('Unexpected data format', data);
                  }
                },
              });
            } else if (response.body.result === 'CAP') {
              this.userBranch.getCLRA_PEBranchDropdown(entityId).subscribe({
                next: (response) => {
                  const data = response.body;
                  if (data && Array.isArray(data)) {
                    this.clraBranchDropdown = this.mapHierarchy(data);
                    const maxLength = this.calculateMaxTextLengthclra(
                      this.clraBranchDropdown
                    );

                    const calculatedWidth = Math.max(280, maxLength * 10);
                    this.popupSettings = { width: `${calculatedWidth}px` };
                  } else {
                    console.error('Unexpected data format', data);
                  }
                },
              });
            }
          },
        });
      }
    }
  }

  onEntityChange(value: any) {
    console.log(value);
    
    this.selectedBranch = value.map((branch: any) => branch.id).join(',');
    this.form.get('branches')?.patchValue(value);
  }
  calculateMaxTextLengthclra(data: clraBranch[]): number {
    let maxLength = 0;

    const findMaxLength = (items: clraBranch[]) => {
      items.forEach((item) => {
        const nameLength = item.name ? item.name.length : 0;
        maxLength = Math.max(maxLength, nameLength);
        if (item.children && item.children.length) {
          findMaxLength(item.children);
        }
      });
    };
    findMaxLength(data);
    return maxLength;
  }
  private mapHierarchy(items: any[]): clraBranch[] {
    return items.map((item: any) => {
      const hasChildren = item.children && item.children.length > 0;
      return {
        parentID: item.parentID,
        id: item.id,
        name: item.name || 'Unknown',
        level: item.level,
        type: item.type,
        children: hasChildren ? this.mapHierarchy(item.children) : [],
      };
    });
  }

  clraTagMapper(tags: any[]): any[] {
    return tags.length < 3 ? tags : [tags];
  }
  getJsonId() {
    let commaSeparatedFiles = this.selectedFiles;
    let commaSeparatedBranch = this.selectedBranch || '';

    if (this.isSessionData) {
      commaSeparatedFiles = commaSeparatedFiles
        ?.flatMap((commaSeparatedFiles: any) =>
          commaSeparatedFiles.items
            ? commaSeparatedFiles.items.map((item: any) => item.id)
            : []
        )
        .join(', ');
      commaSeparatedBranch = this.storedBranch
        ?.flatMap((commaSeparatedbranch: any) =>
          commaSeparatedbranch.items
            ? commaSeparatedbranch.items.map((item: any) => item.id)
            : []
        )
        .join(', ');
    }

    const ParameterId = `[{"InputID":"${commaSeparatedFiles}","BranchID":"${
      commaSeparatedBranch ? commaSeparatedBranch : ''
    }"}]`;
    this.parameterID = ParameterId;
    this.inputs = commaSeparatedBranch;
    this.loading = true;
    this.workspace.getJsonId([], ParameterId).subscribe({
      next: (response) => {
        this.IOJsonId = response.body.result;
        this.applyFilter();
      },
      error: (error) => {
        this.loading = false;
      },
    });
  }

  getOutputJson() {
    console.log('compliance Id',this.complianceID);
    

    let scheduleOnIDs = this.complianceID.scheduleLists.split(',');
    let returnRegisterChallanIDs =
      this.complianceID.returnRegisterChallanIDs.split(',');

      let minLength = Math.min(scheduleOnIDs.length, returnRegisterChallanIDs.length);
      let jsonArray = scheduleOnIDs.slice(0, minLength).map((scheduleOnID: string, index: number) => {
          return {
              ScheduleOnID: scheduleOnID,
              ReturnRegisterChallanID: parseInt(returnRegisterChallanIDs[index], 10),
          };
      });
    let jsonID = JSON.stringify(jsonArray);
    console.log('json Array',jsonArray);
    
    let payload = {
      JsonID: jsonID,
      ParameterID: this.parameterID,
      ComplianceID: this.complianceID.complianceIDs,
      BranchID: this.inputs ? `${this.inputs}` : '0',
    };

    console.log('payload',payload);
    this.workspace.OutputJSON(payload).subscribe({
      next: (response) => {
        
        let outputJson = response.body.result;
        sessionStorage.setItem('JsonId', outputJson);
        if (outputJson) {
          this.GetInputfileisProcess();
        } else {
          this.alertData = {
            alertMessage: 'There is an issue in generating JSON',
            alertVisible: 'error',
          };
        }
        // this. GetInputfileisProcess();
      },
    });
  }

  getRegisterGrid() {
    const custID = this.user.getCustomerId();
    const complianceType = this.selectedComplianceType.value;
    const monthId = this.selectedPeriod.value;
    const YearId = this.selectedYear.value;
    const userId = this.user.getUserId();
    const clientId = this.user.getClientId();
    const jsonId = this.IOJsonId;
    const ActGroup = this.selectedAct.value;
    this.loading = true;
    this.workspace
      .getRegisterGrid(
        custID,
        complianceType,
        '0',
        monthId,
        YearId,
        userId,
        clientId,
        jsonId,
        ActGroup
      )
      .subscribe({
        next: (response) => {
          console.log('grid response',response);
          
          const data = response.body;
          this.registerGridData = data;
          this.loading = false;
        },
      });
  }
  getReturnGrid() {
    const custID = this.user.getCustomerId();
    const complianceType = this.selectedComplianceType.value;
    const monthId = this.selectedPeriod.value;
    const YearId = this.selectedYear.value;
    const userId = this.user.getUserId();
    const clientId = this.user.getClientId();
    const jsonId = this.IOJsonId;
    const ActGroup = this.selectedAct.value;
    const frequency = this.selectedFrequency.value;
    this.loading = true;
    this.workspace
      .getReturnGrid(
        custID,
        complianceType,
        '0',
        monthId,
        YearId,
        userId,
        clientId,
        jsonId,
        ActGroup,
        frequency
      )
      .subscribe({
        next: (response) => {
          console.log('grid response',response);
         
          const data = response.body;
          this.returnGridData = data;
          this.loading = false;
        },
      });
  }
  getChallanGrid() {
    const custID = this.user.getCustomerId();
    const complianceType = this.selectedComplianceType.value;
    const monthId = this.selectedPeriod.value;
    const YearId = this.selectedYear.value;
    const userId = this.user.getUserId();
    const clientId = this.user.getClientId();
    const jsonId = this.IOJsonId;
    const ActGroup = this.selectedAct.value;

    this.loading = true;
    this.workspace
      .getChallanGrid(
        custID,
        complianceType,
        '0',
        monthId,
        YearId,
        userId,
        clientId,
        jsonId,
        ActGroup
      )
      .subscribe({
        next: (response) => {
          console.log('grid response',response);
         
          const data = response.body;
          this.challanGridData = data;
          this.loading = false;
        },
      });
  }
  onActionClick(dataItem: any, type: string) {
    if (type === 'Upload') {
      this.openUpload = true;
      this.selectedscheduleList = dataItem.scheduleList    
    }

    else if(type==='Input'){
      console.log(dataItem);
      this.actId=dataItem.actid;
      this.complianceId=dataItem.complianceID
      this.compliance=this.selectedComplianceType.value
      this.openInput=true
    }
  }

  closeInput(){
    this.openInput=false
  }
  async applyFilter() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    if (this.selectedComplianceType.value === 'Register')
      this.getRegisterGrid();
    else if (this.selectedComplianceType.value === 'Return')
      this.getReturnGrid();
    else if (this.selectedComplianceType.value === 'Challan')
      this.getChallanGrid();
  }
  clearFilter() {
    this.selectedComplianceType = { text: 'Compliance', value: 'Compliance' };
    this.selectedFrequency = { text: 'Frequency', value: 'Frequency' };
    this.selectedAct = { text: 'Select Act', value: 'Select Act' };
    this.selectedYear = { text: 'Year', value: 'Year' };
    this.selectedPeriod = { text: 'Period', value: 'Period' };

    this.selectedFiles = '';
    this.entityPlaceholder = 'Entity/Sub-Entity/Location';
    this.selectedBranch = '';
    this.selectedBranches = [];
    if (this.multiselectTree) {
      this.multiselectTree.writeValue([]);
    }
    this.cdr.detectChanges();
    this.selectedFiles = [];
    this.selectedBranch = [];
    this.challanGridData = [];
    this.registerGridData = [];
    this.returnGridData = [];
    this.isSessionData = false;

    sessionStorage.removeItem('formData');
    sessionStorage.removeItem('formState');
    sessionStorage.removeItem('gridData');
  }

  public closeUploadPopup(): void {
    this.openUpload = false;
  }

  GetInputfileisProcess() {
    this.cdr.detectChanges();
    let entity = this.user.getClientId();
    this.workspace.GetInputfileisProcessed(entity).subscribe({
      next: (response) => {
        let result = response.body.result;
        let month = this.form.get('period')?.value;
        let year = this.form.get('year')?.value;
        let type = this.form.get('compliance')?.value;
        if (result === true) {
          sessionStorage.setItem('month', month.value);
          sessionStorage.setItem('year', year.value);
          sessionStorage.setItem('Input', this.inputs);
          sessionStorage.setItem('complianceType', type.value);
          sessionStorage.setItem('act', this.selectedAct.value);
          const formValues = this.form.value;
          sessionStorage.setItem('formValues', JSON.stringify(formValues));
          this.router.navigate(['/DocumentGeneration']);
        } else {
          this.isError = true;
          this.alertData = {
            alertMessage: 'Employee data is in process, please try again',
            alertVisible: 'error',
          };
        }
      },
    });
  }

  closeAlert() {
    this.alertData = { alertMessage: '', alertVisible: '' };
  }

  ngOnDestroy() {
    if (this.router.url !== '/DocumentGeneration') {
      sessionStorage.removeItem('month');
      sessionStorage.removeItem('year');
      sessionStorage.removeItem('Input');
      sessionStorage.removeItem('complianceType');
      sessionStorage.removeItem('JsonId');
      sessionStorage.removeItem('formValues');
    }
  }

  clearSessionStorageOnUnload(event: Event) {
    this.clearSessionStorage
  }
  clearSessionStorage() {
    sessionStorage.removeItem('month');
    sessionStorage.removeItem('year');
    sessionStorage.removeItem('Input');
    sessionStorage.removeItem('complianceType');
    sessionStorage.removeItem('JsonId');
    sessionStorage.removeItem('formValues');
  }

  fileUpload(file:any){
    let payload ={
      ScheduleONID :this.selectedscheduleList,
      customerID : this.user.getCustomerId(),
      file:file
    }
    console.log('check',payload)

    this.workspace.uploadReturns(payload).subscribe({
      next: (response) => {
        console.log('check file upload',response.result)
       let result = response.result
        if (result === 1) {
          this.alertData = {
            alertMessage: 'File upload successfully',
            alertVisible: 'success',
          };
        } else {
          this.alertData = {
            alertMessage: 'Server error occurred',
            alertVisible: 'error',
          };
        }
      },
    });
  }
  handleFileUpload(file: File) {
    // Now you have access to the uploaded file in the parent component
    console.log('File uploaded:', file);
    this.fileUpload(file)
    // You can implement further logic here to process the file or send it to a server
  }
}
