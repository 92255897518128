import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { CriticalDocumnetsService } from '../../../service/critical-documnets.service';
import { UserService } from '../../../service/user.service';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { MultiSelectTreeComponent } from '../../../components/multi-select-tree/multi-select-tree.component';
import { DropDownsModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { LabelModule } from '@progress/kendo-angular-label';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { GridDataResult } from '@progress/kendo-angular-grid';


@Component({
  selector: 'app-critical-documents-share-with',
  standalone: true,
  imports: [NgIf, NgClass, DialogModule, ReactiveFormsModule, AlertPopupComponent, FormsModule, DropdownListComponent, MultiSelectTreeComponent, MultiSelectModule, LabelModule, DropDownsModule, IndicatorsModule, GridActionItemComponent],
  templateUrl: './critical-documents-share-with.component.html',
  styleUrl: './critical-documents-share-with.component.css'
})
export class CriticalDocumentsShareWithComponent implements OnInit {
  @Output() close = new EventEmitter<void>();
  @Input() ID!: any
  @Input() Type!: any
  form!: FormGroup;
  FolderName!: string;
  loading = false;
  public alertData = { alertMessage: '', alertVisible: '' };

  showValidationErrors = false;
  Permission = { text: 'Select', value: 0 };
  public BranchDropdown: any[] = [];
  public popupSettings: any = { height: '300px' };
  EntityId!: string;
  userid!: number;
  CustomerId!: number;
  selectedFiles: any;
  filePlaceholder: string = 'Select User';
  public isAllSelected: boolean = false;
  public gridData: GridDataResult = { data: [], total: 0 };
  deleteId!: any;

  constructor(private CriticalDocumentsService: CriticalDocumnetsService, private user: UserService, private fb: FormBuilder) {
    this.form = this.fb.group({
      SelectedUser: ['', [Validators.required]],
    });
  }
  ngOnInit(): void {
    this.CustomerId = this.user.getCustomerId();
    this.userid = this.user.getUserId();
    this.EntityId = this.user.getClientId();
    this.getBranchDropdownData();
    if (this.Type == 'Folder') {
      this.GetSharedUserList(this.ID, '');
    } else {
      this.GetSharedUserList('', this.ID);
    }
  }
  handleButtonClick(value: any) {
    if (value.button.action == 'Delete') {
      this.confirmAlert(value.dataItem.key);
    }
  }
  confirmAlert(value: any) {
    this.deleteId = value;
    this.alertData = { alertMessage: 'Are you sure you want to delete this Employee?', alertVisible: 'confirm' };
  }
  PermissiondropdownData = [
    { text: 'Select', value: 0 },
    { text: 'View', value: 4 },
    { text: 'View & Download', value: 1 },
    { text: 'Full Control', value: 3 }
  ];
  public tagMapper(tags: any[]): any[] {
    return tags.length < 3 ? tags : [tags];
  }
  DeleteMWMaster(value: any) {
    if (this.Type == 'Folder') {
      this.CriticalDocumentsService.UnshareFileOrFolder(value, this.ID, '').subscribe(
        {
          next: (response) => {
            console.log(response);
            if (response.status == 200 && response.body.result == true) {
              this.alertData = { alertMessage: 'Deleted Successfully', alertVisible: 'success' };
            } else {
              this.alertData = { alertMessage: 'Error Detected', alertVisible: 'error' };
            }
          }, error: (error) => {
            console.error(error);
            this.alertData = { alertMessage: 'Error Detected', alertVisible: 'error' };
          }
        })
    } else {
      this.CriticalDocumentsService.UnshareFileOrFolder(value, '', this.ID).subscribe(
        {
          next: (response) => {
            console.log(response);
            if (response.status == 200 && response.body.result == true) {
              this.alertData = { alertMessage: 'Deleted Successfully', alertVisible: 'success' };
            } else {
              this.alertData = { alertMessage: 'Error Detected', alertVisible: 'error' };
            }
          }, error: (error) => {
            console.error(error);
            this.alertData = { alertMessage: 'Error Detected', alertVisible: 'error' };
          }
        })
    }
  }
  GetSharedUserList(Folderid: any, Fileid: any) {
    this.CriticalDocumentsService.GetSharedUserList(Folderid, Fileid).subscribe({
      next: (response) => {
        const data = response.body;
        console.log(response);
        this.gridData = {
          data: data,
          total: data.length
        }

      },
      error: (error) => {
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }
  getBranchDropdownData() {
    debugger
    this.CriticalDocumentsService.GetUserShareList(this.EntityId).subscribe({
      next: (response) => {
        const data = response.body.result;
        // if (response && Array.isArray(data)) {
        // this.BranchDropdown = [{ key: 'Select All', value: '', children: [] }, ...data];
        debugger
        const selectAllNode = {
          id: 'select-all',
          name: 'Select All',
          items: data.map((item: any) => ({
            id: item.value,
            name: item.key,
          })),
        };
        this.BranchDropdown = [
          selectAllNode,
          ...data.map((item: any) => ({
            id: item.value,
            name: item.key,
          })),
        ];
        console.log(this.BranchDropdown)

        const maxLength = this.calculateMaxTextLength(this.BranchDropdown);
        const calculatedWidth = Math.max(280, maxLength * 10);
        this.popupSettings = { width: `${calculatedWidth}px` };
      },
      error: (error) => {
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }
  calculateMaxTextLength(data: any[]): number {
    let maxLength = 0;

    const findMaxLength = (items: any[]) => {
      items.forEach((item) => {
        maxLength = Math.max(maxLength, item.name.length);
      });
    };

    findMaxLength(data);
    return maxLength;
  }


  onFileChange(value: any) {
    const selectedIds = value.map((file: any) => file.id);

    if (selectedIds.includes('select-all')) {
      const allFileIds = this.getAllFileIds(this.BranchDropdown);

      this.form.controls['SelectedUser'].setValue(allFileIds);
      this.selectedFiles = allFileIds.join(',');

      value = this.BranchDropdown.map((file: any) => {
        file.checked = true;
        if (file.items) {
          file.items.forEach((child: any) => (child.checked = true));
        }
        return file;
      });
    } else {
      this.selectedFiles = selectedIds.join(',');
      this.form.controls['SelectedUser'].setValue(selectedIds);

      value.forEach((file: any) => {
        file.checked = selectedIds.includes(file.id);
        if (file.items) {
          file.items.forEach((child: any) => {
            child.checked = selectedIds.includes(child.id);
          });
        }
      });
    }
    this.form.get('SelectedUser')?.patchValue(value);
  }
  private getAllFileIds(SelectedUser: any): string[] {
    let ids: string[] = [];

    SelectedUser.forEach((SelectedUser: any) => {
      if (SelectedUser.id !== 'select-all') {
        ids.push(SelectedUser.id);
        if (SelectedUser.items) {
          ids = ids.concat(this.getAllFileIds(SelectedUser.items));
        }
      }
    });

    return ids;
  }
  onDropdownValueChange(value: any, type: string) {
    if (type == 'Permission') {
      this.Permission = { text: value.text, value: value.value }
    }
  }
  onCreate() {
    this.showValidationErrors = true;
    this.loading = true;
    if (this.Permission.value && this.form.valid) {
      const shareFolder = {
        "userList": this.form.value.SelectedUser.filter((item: any) => item.id !== 'select-all').map((item: any) => item.id),
        "folderid": this.ID || 0,
        "customerID": this.CustomerId,
        "entityId": this.EntityId,
        "userid": this.userid,
        "permissionType": this.Permission.value
      }
      const shareFile = {
        "userList": this.form.value.SelectedUser.filter((item: any) => item.id !== 'select-all').map((item: any) => item.id),
        "fileid": this.ID || 0,
        "customerID": this.CustomerId,
        "entityId": this.EntityId,
        "userid": this.userid,
        "permissionType": this.Permission.value
      }
      console.log('sharefile', shareFile);
      console.log('sharefolder', shareFolder);
      if (this.Type == 'Folder') {
        this.CriticalDocumentsService.ShareFolder(shareFolder)
          .subscribe({
            next: (response) => {
              console.log(response);
              if (response.status === 200 && response.body.result == true) {
                const data = response.body;
                this.alertData = { alertMessage: 'The Folder has been shared successfully!', alertVisible: 'success' };
                this.loading = false;
              } else {
                this.alertData = { alertMessage: 'Error sharing the folder', alertVisible: 'error' };
                this.loading = false;
              }
            },
            error: (error) => {
              this.alertData = { alertMessage: 'Something went wrong, Please try again', alertVisible: 'error' };
              console.error('Error ', error);
              this.loading = false;
            }
          });
      } else {
        this.CriticalDocumentsService.ShareFile(shareFile)
          .subscribe({
            next: (response) => {
              console.log(response);
              if (response.status === 200 && response.body.result == true) {
                const data = response.body;
                this.alertData = { alertMessage: 'The File has been shared successfully!', alertVisible: 'success' };
                this.loading = false;
              } else {
                this.alertData = { alertMessage: 'Error sharing the File', alertVisible: 'error' };
                this.loading = false;
              }
            },
            error: (error) => {
              this.alertData = { alertMessage: 'Something went wrong, Please try again', alertVisible: 'error' };
              console.error('Error fetching Grid data', error);
              this.loading = false;
            }
          });
      }
    } else {
      this.loading = false;
      this.form.markAllAsTouched();
    }
  }
  public parentColumns: any[] = [
    { field: 'value', title: 'Shared with Users' },
    {
      field: 'key',
      isIconColumn: true,
      title: 'Action',
      filteredIcons: [
        {
          url: '../assets/vectors/delete.svg',
          alt: 'Icon 1',
          action: 'Delete',
        },
      ]
    }
  ];
  closeAlert() {
    if (this.alertData.alertVisible == 'success') {
      this.close.emit();
    }
    this.alertData = { alertMessage: '', alertVisible: '' };
  }
  closePopup() {
    this.close.emit();
  }
}
