import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DesignationMasterService } from '../../../service/designation-master.service';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule, NgClass, NgIf } from '@angular/common';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-designation-master-add-update',
  standalone: true,
  imports: [NgIf, DialogModule, DropdownListComponent, ReactiveFormsModule, AlertPopupComponent,CommonModule,NgClass,IndicatorsModule],
  templateUrl: './designation-master-add-update.component.html',
  styleUrl: './designation-master-add-update.component.css'
})
export class DesignationMasterAddUpdateComponent {
  userForm!: FormGroup;
  @Input() data: any;
  @Output() close = new EventEmitter<void>();
  @Input() defaultSelectedValue = { id: 0, name: 'Select' };
  alertData = { alertMessage: '', alertVisible: '' };
  showValidationErrors = false;
  showStatusdropDown=false;
  loading=false;
  zones: any[] = [];
  dropdownData = [
    { id: 0, name: 'Select' },
    { id: 1, name: 'Active' },
    { id: 2, name: 'Inactive' }
  ];

  constructor(private fb: FormBuilder, private designationMasterService: DesignationMasterService) {
    this.userForm = this.fb.group({
      status: ['', Validators.required],
      long_designation: ['', [Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]],
    });
  }
  @ViewChild('dialogTitle') dialogTitle!: ElementRef;
  ngAfterViewInit() {
    const titleElement = this.dialogTitle.nativeElement;
    titleElement.style.textAlign = 'center';
    titleElement.style.width = '100%';
  }
  
  ngOnInit(): void {
    if (this.data) {
      this.showStatusdropDown=true;
      this.initData();
    }else{
      this.defaultSelectedValue = { id: 1, name: 'Active' };
      this.showStatusdropDown=false;
      this.onDropdownValueChange({ id: 1, name: 'Active' })
    }
    this.initializeZone();
  }
  initializeZone() {
    this.dropdownData = [
      { id: 0, name: 'Select' },
    { id: 1, name: 'Active' },
    { id: 2, name: 'Inactive' }
    ];
  }
  initData(): void {
    this.userForm.patchValue(this.data);
  }
  onSave(): void {
    debugger;
    this.loading=true;
    this.showValidationErrors=true;
    if (this.userForm.valid) {
      if(!this.showStatusdropDown){
        this.userForm.value.status='';
        this.loading=false;
      }
      this.designationMasterService.addUpdateDesignation(this.userForm.value).subscribe({
        next:(response) => {
          const res=response.body;
          if(response.status==200 && res.status){
            if(res.status=='Designation is already exists.'){
              this.alertData = { alertMessage: res.status, alertVisible: 'error' };
              this.loading=false;
            }else if(res.status=='Designation is Updated successfully'){
              this.alertData = { alertMessage:  res.status, alertVisible: 'success' };
              this.loading=false;
            }else if(res.status=='Designation is Added Successfully'){
              this.alertData = { alertMessage:  res.status, alertVisible: 'success' };
              this.loading=false;
            }
          }else{
            if (this.data) {
              this.alertData = { alertMessage: 'Error while Updating designation', alertVisible: 'error' };
              this.loading=false;
            } else {
              this.alertData = { alertMessage: 'Error while Adding designation', alertVisible: 'error' };
              this.loading=false;
            }
          }
        },
        error:(error: any) => {
          console.log(error);
          this.alertData = { alertMessage: 'Error while saving designation', alertVisible: 'error' };
          this.loading=false;
        }}
      );
    } else {
      this.userForm.markAllAsTouched();
    }
  }
  onDropdownValueChange(value: any): void {
    this.userForm.get('status')?.setValue(value.name);
    this.userForm.get('status')?.markAsTouched(); 
  }

  closePopup(): void {
    this.data = undefined;
    this.close.emit();
  }
  closeAlert() {
    if(this.alertData.alertVisible=='success'){
      this.closePopup();
    }
    this.alertData = { alertMessage: '', alertVisible: '' };
  }

}
