import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PtSlabServiceService {
  private baseUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getEmployeeSlabMaster(client: string, state: string,page: number = 1, pageSize: number = 10, skip: number = 0, take: number = 10): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/PTSlab/GetEmployee_SlabMaster?client=${client}&State=${state}&page=${page}&pageSize=${pageSize}&skip=${skip}&take=${take}`;
    return this.http.get(url, { observe: 'response' });
  }
  getSlab(state:string): Observable<HttpResponse<any>>{
    const url = `${this.baseUrl}/api/PTSlab/Get_SlabMaster?State=${state}`;
   return this.http.get(url,{ observe: 'response' });
}
  saveMunicipalityMapping(employeeDataList: any[]): Observable<HttpResponse<any>> {
      const url = `${this.baseUrl}/api/PTSlab/SaveMunicipalityMapping`;
    return this.http.post(url, employeeDataList,{ observe: 'response' });
  }

  uploadFile(file:File,entityId:string):Observable<any> {
    const url = `${this.baseUrl}/api/PTSlab/BulkUploadPTLoc`;
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('EntityId', entityId.toString());  
    
    return this.http.post<any>(url, formData);
  }

}