/// <reference types="@angular/localize" />

import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppComponent } from './app/app.component';
import { provideRouter } from '@angular/router';
import { appRoutes } from '../src/app/app.routes';
import { provideHttpClient } from '@angular/common/http'
import { registerLicense } from '@syncfusion/ej2-base';
import { environment } from '../src/app/environments/environment';

registerLicense(environment.syncfusionLicenceKey);

bootstrapApplication(AppComponent, {
  providers: [
    provideAnimations(),
    provideRouter(appRoutes),
    provideHttpClient()
  ],
})
  .catch(err => console.error(err));
