<div class="row colpadding0" style="margin-bottom: 15px; margin-top: 10px;">
    <div class="col-md-5  align-items-center">
        <h4 class="f-label" style="margin-top: 0px; font-weight: 500;">Please Check Excel Column Header To download
            Template
        </h4>

    </div>
    <button class="figma-btn-blue" type="submit" style=" float: right;">
        Reset
    </button>
    <button class="figma-btn-blue" style="width: 180px;  float: right; ">
        <img src="../assets/vectors/DownloadWhite.svg" alt="">
        Download Template
    </button>
</div>

<div class="row colpadding0">
    <div class="col-md-5">
        <kendo-grid [data]="gridData" [pageSize]="pageSize" [pageable]="true" [columnMenu]="true" [skip]="skip">

            <kendo-grid-column field="header" title="Header">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.Header" class="custom-tooltip">
                        {{dataItem.Header}}
                    </div>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Select All" [width]="150">
                <ng-template kendoGridHeaderTemplate>
                    <div class="checkbox-container">
                        <span>Select All</span>
                        <input type="checkbox" [(ngModel)]="allSelected" (change)="toggleSelectAll($event)" />
                    </div>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="checkbox-container">
                        <input type="checkbox" [(ngModel)]="dataItem.selected" />
                    </div>
                </ng-template>
            </kendo-grid-column>
            <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
                <kendo-pager-next-buttons></kendo-pager-next-buttons>
                <kendo-pager-input></kendo-pager-input>
                <kendo-pager-info></kendo-pager-info>
                <kendo-pager-page-sizes></kendo-pager-page-sizes>
            </ng-template>
        </kendo-grid>
    </div>
    <div class="col-md-7">
        <table class="table Upload-table table-border">
            <thead>
                <tr>
                    <th>Input</th>
                    <th>Upload File</th>
                    <th>Uploaded</th>
                    <th>Validation</th>
                    <th>Processed</th>
                    <th>History</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><div class="col-12 collpadding0">
                       <p style="padding-top: 6px;"> Employee Master<sup class="text-danger">*</sup></p>
                    </div>
                    </td>
                    <td>
                       <div class="col-12 collpadding0" style="justify-content: space-around;">
                                               
                                <span *ngIf="!fileNames['DownloadsInput']" style="color: #A0A0A0;">No file chosen</span>
                                <span *ngIf="fileNames['DownloadsInput']" style="color: #A0A0A0;">{{fileNames['DownloadsInput']}}</span>
                           
                                <input type="file" formControlName="DownloadsInput" #DownloadsInputFile
                                 (change)="onFileChange($event, 'DownloadsInput')" id="fileInput" style="display: none;" />
                                 <button class="figma-btn-gray"  (click)="DownloadsInputFile.click()">Browse</button>
                            
                            <button class="figma-btn-blue" style="color: white; width: 90px;" (click)="uploadFile()">Upload</button>
                        </div>
                     
                    
                    </td>
                    <td>
                        <button class="figma-btn-white" style="height: 20px; width: 20px; margin-top: 10%;">
                            <img  src="../assets/vectors/GreenTick.svg" alt="">
                        </button>
                    </td>
                    <td>
                        <button class="figma-btn-white">
                            <img src="../assets/vectors/DownloadOrange.svg" alt="">
                        </button>
                    </td>
                    <td>
                        <div class="col-12 collpadding0">
                            <!-- <p style="padding-top: 6px;"> Data</p> -->
                         </div>
                    </td>
                    <td>
                        <button class="figma-btn-white">
                            <img src="../assets/vectors/History.svg" alt="">
                        </button>
                    </td>
                </tr>
                <!-- Add more rows as needed -->
            </tbody>
        </table>
    </div>
</div>
<app-employee-history-popup *ngIf="EmployeeHistoryPopup"></app-employee-history-popup>


<!-- <app-alert-popup *ngIf="alertData.alertVisible" [Width]="250" [Height]="250" [id]="deleteId" [Data]="alertData"
    (close)="closeAlert()" (confirm)="DeleteMWMaster($event)"></app-alert-popup>

    <app-add-edit-employee-master-popup *ngIf="AddNewPopup" (close)="closePopup()"></app-add-edit-employee-master-popup> -->