import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { NgIf } from '@angular/common';
import { ClientRegistrationRepositoryService } from '../../../service/client-registration-repository.service';
import { UserService } from '../../../service/user.service';
import { BlobService } from '../../../service/blob.service';

@Component({
  selector: 'app-client-registration-repository-export-popup',
  standalone: true,
  imports: [AlertPopupComponent,DialogsModule,DropdownListComponent,IndicatorsModule,NgIf],
  templateUrl: './client-registration-repository-export-popup.component.html',
  styleUrl: './client-registration-repository-export-popup.component.css'
})
export class ClientRegistrationRepositoryExportPopupComponent {
  public alertData = { alertMessage: '', alertVisible: '' };
  selectedentity = { text: 'Select', value: '' };
  @Output() close = new EventEmitter<void>();
  public showValidationErrors = false;
  loading: boolean = false;
  @Input() data: any;  
  flag:string='';  
  dropdownData!: { text: string; value: string; }[];
  
  constructor(private clientregistrationrepository:ClientRegistrationRepositoryService, private user:UserService,private blobService: BlobService){}

  ngOnInit() {
    this.setDropdownValue();
  }

  setDropdownValue() {
    const EntityID = this.user.getClientId();
    if (EntityID) {
      this.selectedentity = { text: `${EntityID}`, value: EntityID };
    } else {
      this.selectedentity = { text: 'Select', value: '' };
    }
    this.dropdownData = [
      { text: 'Select', value: '' },
      { text: 'All', value: 'All' },
      { text: `${EntityID}`, value: EntityID },
    ];
  }

  onDropdownValueChange(value: any, type: string) {
    if (type === 'entity') {
      this.selectedentity = { text: value.text, value: value.value };
    } 
  }

  exportToExcel(flag: string): void {
    debugger;
    const validFlags = ['C', 'E', 'EC'];

    if (!validFlags.includes(flag)) {
      console.error('Invalid flag value:', flag);
      this.loading = false;
      this.alertData = { alertMessage: 'Invalid flag value', alertVisible: 'error' };
      return;
    }

    this.loading = true;
    const EntityID = this.user.getClientId();

    this.clientregistrationrepository.getExcelFile(this.selectedentity.value, flag).subscribe({
      next: (response: any) => {
        console.log('response', response);

        if (response.status === 200) {
          const data = response.body;
          const blobLink = data.filePath;
          const blobName = this.blobService.extractBlobName(blobLink);
          
          setTimeout(() => {
            this.blobService.getSasToken(blobName).subscribe(
              (tokenResponse: any) => {
                try {
                  const sasToken = tokenResponse.result;
                  this.blobService.downloadFile(blobName, sasToken);
                  this.loading = false;
                } catch (error) {
                  this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
                  this.loading = false;
                }
              },
              (error) => {
                this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
                this.loading = false;
              }
            );
          }, 5000);
        } else {
          console.error('Error fetching blob link:', response.body.error);
          this.loading = false;
          this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
        }
      },
      error: (err) => {
        console.error('API call failed:', err);
        this.loading = false;
        this.alertData = { alertMessage: 'An error occurred while exporting', alertVisible: 'error' };
      }
    });
  }

  closePopup(): void {
    this.close.emit();
  }
}
