import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ManageEmployeeService {
  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getAllEmployeeMasters(clientID: string, empid: string, statusFilter: string, take: number, skip: number, page: number, pageSize: number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/EmployeeMaster/GetAll_EmployeeMaster?ClientID=${clientID}&Empid=${empid}&Status=${statusFilter}&take=${take}&skip=${skip}&page=${page}&pageSize=${pageSize}`;
    return this.http.get<any>(url, { observe: 'response' });
  }
  getAllEmployeeMastersEditData(clientID: string, empid: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/EmployeeMaster/GetEmployeeById?ClientID=${clientID}&Empid=${empid}`;
    return this.http.get<any>(url, { observe: 'response' });
  }
  DeleteEmployeeMasters(clientID: string, empid: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/EmployeeMaster/DeleteEmployee?EmpID=${empid}&EntityId=${clientID}`;
    return this.http.post<any>(url, {}, { observe: 'response' });
  }
  getCountryDropdown(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/EmployeeMaster/GetAllCountry`;
    return this.http.get(url, { observe: 'response' });
  }
  getEmployeeDropdown(clientID: string, value: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/EmployeeMaster/GetEmployeeNameIdFordd?ClientID=${clientID}&Filter=${value}`;
    return this.http.get(url, { observe: 'response' });
  }
  getPTStateDropdown(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/EmployeeMaster/GetAllPTStates`;
    return this.http.get(url, { observe: 'response' });
  }
  getEntityBranchDropdown(EntityId: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/ClientDetailsManagement/GetBranchDropdownlist?EntityId=${EntityId}`;
    return this.http.get(url, { observe: 'response' });
  }
  getSectorDropdown(StateID: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/EmployeeMaster/GetSector?StateID=${StateID}`;
    return this.http.get(url, { observe: 'response' });
  }
  getJobCategoryDropdown(StateID: string, SectorID: number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/EmployeeMaster/GetJobCategory?StateID=${StateID}&SectorID=${SectorID}`;
    return this.http.get(url, { observe: 'response' });
  }
  getIndustryTypeDropdown(StateID: string, SectorID: number, JobID: number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/EmployeeMaster/GetIndustryType?StateID=${StateID}&SectorID=${SectorID}&JobID=${JobID}`;
    return this.http.get(url, { observe: 'response' });
  }
  getDesignationDropdown(value: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/DesignationMaster/Get_LongDesignationForDropDown?Filter=${value}`;
    return this.http.get(url, { observe: 'response' });
  }
  getBranchTransferStateDropdown(ClientID: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/EmployeeMaster/GetClientwiseStates?ClientID=${ClientID}`;
    return this.http.get(url, { observe: 'response' });
  }
  getBranchTransferLocationDropdown(ClientID: string, state: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/EmployeeMaster/GetClientwiseLocationCitiesByStateCode?ClientID=${ClientID}&stateCode=${state}`;
    return this.http.get(url, { observe: 'response' });
  }
  getBranchTransferBranchDropdown(customerID: number, clientID: string, state: string, location: string, branchID: number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/EmployeeMaster/GetBranchesClientwise?CustomerID=${customerID}&ClientID=${clientID}&SM_Code=${state}&LM_Code=${location}&BranchID=${branchID}`;
    console.log(url);

    return this.http.get(url, { observe: 'response' });
  }
  saveEmployeeMaster(body: any, IsUpdate: number): Observable<HttpResponse<any>> {
    const value = {
      fileID: body.fileID || 0,
      rowNo: body.rowNo || 0,
      userID: body.userID || 0,
      avacoM_CustomerID: body.avacoM_CustomerID || 0,
      avacoM_BranchID: body.avacoM_BranchID || 0,
      eM_EmpID: body.eM_EmpID || '',
      eM_ClientID: body.eM_ClientID || '',
      eM_EmpName: body.eM_EmpName || '',
      eM_State: body.eM_State || '',
      eM_Location: body.eM_Location || '',
      eM_Branch: body.eM_Branch || '',
      eM_PT_State: body.eM_PT_State || '',
      eM_Gender: body.eM_Gender || '',
      eM_FatherName: body.eM_FatherName || '',
      eM_Relationship: body.eM_Relationship || '',
      eM_DOB: body.eM_DOB || '',
      eM_MaritalStatus: body.eM_MaritalStatus || '',
      eM_DOJ: body.eM_DOJ || '',
      eM_DOL: body.eM_DOL || '',
      eM_PFNO: body.eM_PFNO || '',
      eM_ESICNO: body.eM_ESICNO || '',
      eM_UAN: body.eM_UAN || '',
      eM_Emailid: body.eM_Emailid || '',
      eM_MobileNo: body.eM_MobileNo || '',
      eM_PAN: body.eM_PAN || '',
      eM_Aadhar: body.eM_Aadhar || '',
      eM_BankName: body.eM_BankName || '',
      eM_Department: body.eM_Department || '',
      eM_Designation: body.eM_Designation || '',
      eM_SkillCategory: body.eM_SkillCategory || '',
      eM_Bankaccountnumber: body.eM_Bankaccountnumber || '',
      eM_IFSC: body.eM_IFSC || '',
      eM_Address: body.eM_Address || '',
      eM_PassportNo: body.eM_PassportNo || '',
      eM_PhysicallyChallenged: body.eM_PhysicallyChallenged || '',
      eM_Firsttime_secondtime: body.eM_Firsttime_secondtime || '',
      eM_International_workers: body.eM_International_workers || '',
      eM_PF_Capping_Applicability: body.eM_PF_Capping_Applicability || '',
      eM_Employmenttype: body.eM_Employmenttype || '',
      eM_ChangeEffective_from: body.eM_ChangeEffective_from || '',
      eM_Status: body.eM_Status || '',
      eM_CreatedBy: body.eM_CreatedBy || '',
      eM_modifiedBy: body.eM_modifiedBy || '',
      eM_Version: body.eM_Version || 0,
      eM_Nationality: body.eM_Nationality || '',
      eM_Passport_Valid_From: body.eM_Passport_Valid_From || '',
      eM_Passport_Valid_Upto: body.eM_Passport_Valid_Upto || '',
      eM_PayrollMonth: body.eM_PayrollMonth || 0,
      eM_PayrollYear: body.eM_PayrollYear || 0,
      eM_PF_Applicability: body.eM_PF_Applicability || '',
      eM_ESI_Applicability: body.eM_ESI_Applicability || '',
      eM_ESI_Out_of_Courage_Month: body.eM_ESI_Out_of_Courage_Month || 0,
      eM_ESI_Out_of_Courage_Year: body.eM_ESI_Out_of_Courage_Year || 0,
      eM_PassportIssued_Country: body.eM_PassportIssued_Country || '',
      eM_EPFO_Aadhar_Upload: body.eM_EPFO_Aadhar_Upload || '',
      eM_EPFO_Bank_Ac_Upload: body.eM_EPFO_Bank_Ac_Upload || '',
      eM_EPFO_PAN_Upload: body.eM_EPFO_PAN_Upload || '',
      eM_PMRPY: body.eM_PMRPY || '',
      eM_PT_Applicability: body.eM_PT_Applicability || '',
      eM_NoOf_Certificate: body.eM_NoOf_Certificate || '',
      eM_NoOf_Certificate_Date: body.eM_NoOf_Certificate_Date || '',
      eM_TokenNo: body.eM_TokenNo || '',
      eM_Relay_Assigned: body.eM_Relay_Assigned || '',
      eM_Letter_Of_Group: body.eM_Letter_Of_Group || '',
      eM_WomenWorkingNightshift: body.eM_WomenWorkingNightshift || '',
      eM_ModeofTransport: body.eM_ModeofTransport || '',
      eM_SecurityProvided: body.eM_SecurityProvided || '',
      eM_ExistReasonCode: body.eM_ExistReasonCode || '',
      eM_YearsOfExperience: body.eM_YearsOfExperience || '',
      eM_DateWhenClothesGiven: body.eM_DateWhenClothesGiven || '',
      eM_NumberandDateOfExemptingOrder: body.eM_NumberandDateOfExemptingOrder || '',
      eM_ParticularsOfTransferFromOneGroupToAnother: body.eM_ParticularsOfTransferFromOneGroupToAnother || '',
      eM_SalesPromotion: body.eM_SalesPromotion || '',
      eM_PaymentMode: body.eM_PaymentMode || '',
      eM_PermanentAddress: body.eM_PermanentAddress || '',
      eM_Markof_Identification: body.eM_Markof_Identification || '',
      eM_Placeof_work: body.eM_Placeof_work || '',
      eM_Remarks: body.eM_Remarks || '',
      eM_EductionLevel: body.eM_EductionLevel || '',
      eM_Place_of_Employment: body.eM_Place_of_Employment || '',
      eM_Training_Number: body.eM_Training_Number || '',
      eM_Training_Date: body.eM_Training_Date || '',
      eM_Emergency_contact_Address: body.eM_Emergency_contact_Address || '',
      eM_Emergency_contact_mobile_no: body.eM_Emergency_contact_mobile_no || '',
      eM_EPS_Applicabilty: body.eM_EPS_Applicabilty || '',
      eM_Client_ESI_Number: body.eM_Client_ESI_Number || '',
      eM_Client_PT_State: body.eM_Client_PT_State || '',
      eM_IsLwf_Exempted: body.eM_IsLwf_Exempted || '',
      eM_Sectorid: body.eM_Sectorid || 0,
      eM_JobCategory: body.eM_JobCategory || 0,
      eM_IndustryType: body.eM_IndustryType || 0,
      eM_ExemptedSEA_Act: body.eM_ExemptedSEA_Act || '',
      eM_VPF_Applicability: body.eM_VPF_Applicability || '',
      eM_VPF_Type: body.eM_VPF_Type || '',
      eM_VPF_Value: body.eM_VPF_Value || 0,
      eM_AadharSeeding: body.eM_AadharSeeding || '',
      employee_Type: body.employee_Type || '',
      municipalityName: body.municipalityName || ''
    };
    console.log('service', value)
    const url = `${this.apiUrl}/api/EmployeeMaster/AddEditEmployeeMaster?IsUpdate=${IsUpdate}`;
    return this.http.post(url, value, { observe: 'response' });
  }
  getSelectUploadGrid(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Common/GetMappedClientHeadersColumn?TableName=RLCS_Employee_Master&CustID=0`;
    return this.http.get<any>(url, { observe: 'response' });
  }
  getBranchTransferData(custID: number, avacomID: number, empID: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/EmployeeMaster/GetEmployeeTransferData?CustomerID=${custID}&Avacom_BranchID=${avacomID}&EMPID=${empID}`;
    return this.http.get<any>(url, { observe: 'response' });
  }
  createEmployeeBranchTransfer(data: any, state: string, location: string, branch: string, CreatedDate: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/EmployeeMaster/CreateEmployeeBranchTransfer`;
    const transferData = new FormData();
    transferData.append('AVACOM_CustomerID', data.avacoM_CustomerID);
    transferData.append('EMT_CreatedDate', new Date().toISOString());
    transferData.append('ISProcessed', String(data.isProcessed));
    transferData.append('EMT_Status', data.eM_Status);
    transferData.append('AVACOM_BranchID', data.avacoM_BranchID);
    transferData.append('EMT_Location', location);
    transferData.append('EMT_FromDate', CreatedDate);
    transferData.append('EMT_ID', '0');
    transferData.append('EMT_EmpID', data.eM_EmpID);
    transferData.append('EMT_Branch', branch);
    transferData.append('EMT_ClientId', data.eM_ClientID);
    transferData.append('EMT_ToDate', '');
    transferData.append('EMT_State', state);

    console.log('URL:', url);
    console.log('Data:', transferData);

    return this.http.post(url, transferData, { observe: 'response' });
  }

  ISEmpIDExistCheckExist(EmpID: string, EntityId: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/EmployeeMaster/ISEmpIDExistCheckExist?EmpID=${EmpID}&EntityId=${EntityId}`;
    return this.http.get<any>(url, { observe: 'response' });
  }
  CheckUANExistsClientWise(UAN: string, ClientId: string, EmpID: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/EmployeeMaster/CheckUANExistsClientWise?UAN=${UAN}&ClientId=${ClientId}&EmpID=${EmpID}`;
    return this.http.get<any>(url, { observe: 'response' });
  }
  CheckESICExist(ESICNo: string, ClientId: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/EmployeeMaster/CheckESICExist?ESICNo=${ESICNo}&ClientId=${ClientId}`;
    return this.http.get<any>(url, { observe: 'response' });
  }
  CheckPFExist(PFNo: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/EmployeeMaster/CheckPFExist?PFNo=${PFNo}`;
    return this.http.get<any>(url, { observe: 'response' });
  }
  insertDynamicUploadHeaders(columnList: string, headerList: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Common/InsertDynamicUploadHeadersNew`
    // let params = new HttpParams()
    //   .set('ColumnList', columnList)
    //   .set('HeaderList', headerList);
    const body = {
      "columnList": columnList,
      "headerList": headerList
    }
    return this.http.post<any>(url, body, { observe: 'response' })
  }
  BulkUpload(formData: FormData): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/EmployeeMaster/EmployeeMasterBulkUpload`;
    return this.http.post(url, formData, { observe: 'response' });
  }
  getBulkPopupUploadStatus(clientID: string, IsUpdate: number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/EmployeeMaster/LC_DIYInputGetFilesStatus?clientid=${clientID}&IsUpdate=${IsUpdate}`;
    return this.http.get(url, { observe: 'response' });
  }
}
