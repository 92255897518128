import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LoginService } from '../service/login.service'
import { UserService } from '../service/user.service';
import { MenuService } from '../../app/service/menu.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  loginusername: string = '';
  loginpassword: string = '';
  loginError: boolean = false;

  constructor(
    private router: Router, 
    private loginService: LoginService, 
    private userService: UserService, 
    private menuService: MenuService) { }

  ngOnInit(): void {
    debugger;
  }

  onSidebarLinkClick(linkName: string) {
    this.menuService.setSelectedMenu(linkName);
  }

  // onSubmit(form: any) {
  //   if (form.valid) {
  //     this.loginService.validateUser(this.username, this.password).subscribe(
  //       (data) => {
  //         debugger;
  //           debugger;
  //           this.userService.setUserId(data.id);
  //           this.userService.setRole(data.role);
  //           this.userService.setRoleId(data.roleId);
  //           this.userService.setFirstName(data.firstName);
  //           this.userService.setLastName(data.lastName);
  //           this.userService.setCustomerId(data.customerID);
  //           if (data.Result.role === 'LSA') {
  //             this.onSidebarLinkClick('Minimum Wages');
  //             this.router.navigate(['/MinimumWagesPeriod']);
  //           } else {
  //             this.router.navigate(['/EntityDashboard']);
  //           }
  //       },
  //       (error) => {
  //         debugger;
  //         this.loginError = true;
  //       }
  //     );
  //   }
  // }

  onSubmit(form: any) {
    if (form.valid) {
      this.loginService.validateUser(this.loginusername, this.loginpassword).subscribe({
        next: (response) => {
          debugger;
          if (response.status === 200) {
            const data = response.body;
            this.userService.setUserId(data.id);
            this.userService.setRole(data.role);
            this.userService.setRoleId(data.roleId);
            this.userService.setFirstName(data.firstName);
            this.userService.setLastName(data.lastName);
            this.userService.setCustomerId(data.customerID);

            if (data.role === 'LSA') {
              this.onSidebarLinkClick('Master Abstracts');
             this.router.navigate(['/Master_Abstracts']);
            }
            else if(data.role === 'CORDN' ){
              this.router.navigate(['/Notices']);
            }
            else if(data.role === 'SME'){
              
              this.router.navigate(['/Notices']);
            }
            else if(data.role === 'SDEXE'){
              
              this.router.navigate(['/Notices']);
            }
            else if(data.role === 'BT'){
              this.onSidebarLinkClick('Invoice Setup');
              this.router.navigate(['/ClientCommercialSetup']);
            }
            else {
              this.router.navigate(['/EntityDashboard']);
            }
          } else {
            this.loginError = true;
          }
        },
        error: (err) => {
          console.log('getAllStateList error : ', err);
        }
      });
    }
  }

}
