import { Component, EventEmitter, Output } from '@angular/core';
import { ZoneLocationMappingService } from '../../../service/zone-location-mapping.service';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { FormsModule } from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-zone-location-mapping-bulk-upload',
  standalone: true,
  imports: [DialogModule, FormsModule, NgIf, NgClass, AlertPopupComponent, ComboBoxComponent,IndicatorsModule],
  templateUrl: './zone-location-mapping-bulk-upload.component.html',
  styleUrl: './zone-location-mapping-bulk-upload.component.css'
})
export class ZoneLocationMappingBulkUploadComponent {
  file: File | null = null;
  dropzoneMessage: string = "Drag your files here or ";
  @Output() close = new EventEmitter<void>();
  public selectedFile?: File;
  public designationOption: string = 'add';
  public alertData = { alertMessage: '', alertVisible: '' };
  public stateDropdownData: any[] = [];
  public selectedDropdown = ''
  public isFormValid: boolean = false;
  loading=false;

  constructor(private zoneLocationMappingService: ZoneLocationMappingService) { }

  ngOnInit(): void {
    this.StateDropdown()
  }
  StateValueChange(value: any) {
    this.selectedDropdown = value.id;
  }
  StateDropdown() {
    this.zoneLocationMappingService.getAllStateList().subscribe(
      {
        next: (response) => {
            const data = response.body;
                  this.stateDropdownData = data.map((item: any) => ({
                    id: item.sM_Code,
                    name: item.sM_Name
                  }));
        },error(error) {
          console.error("Error fetching dropdown data", error);
        },
      }
    );
  }

  public onFileSelect(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
      const file = input.files[0];
      if (this.validateFile(file)) {
        this.selectedFile = file;
      } else {
        this.alertData = { alertMessage: 'Only .xlsx files are allowed.', alertVisible: 'error' };
      }
    }
  }
  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drop your file here or";
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drag your files here or";
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      if (this.validateFile(file)) {
        this.file = file;
        this.selectedFile = this.file;
      } else {
        this.alertData = { alertMessage: 'Only .xlsx files are allowed.', alertVisible: 'error' };
      }
      event.dataTransfer.clearData();
    }
  }

  private validateFile(file: File): boolean {
    if (this.selectedDropdown) {
      this.isFormValid = true;
    }
    const allowedExtensions = ['xls', 'xlsx', 'csv'];
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    return fileExtension ? allowedExtensions.includes(fileExtension) : false;
  }

  public uploadFiles(): void {
    debugger;
    if (this.selectedFile && this.selectedDropdown) {
      this.loading=true;
      const file=this.selectedFile;
      const state=this.selectedDropdown;
      this.zoneLocationMappingService.uploadFile(file,state).subscribe({
        next: (response) => {
          this.alertData = { alertMessage: 'File Uploaded Successfully...', alertVisible: 'success' };
          console.log('File uploaded successfully', response);
          this.loading=false;
        },
        error: (error) => {
          console.log('error',error)
          this.alertData = { alertMessage: 'Invalid template file!', alertVisible: 'error' };
          this.selectedFile=undefined;
        }
      });
    } else {
      if (this.selectedFile) {
        this.selectedFile=undefined;
        this.alertData = { alertMessage: 'No State Selected ', alertVisible: 'error' };
      } else {
        this.alertData = { alertMessage: 'You have not chosen any file', alertVisible: 'error' };
      }

    }
    this.loading=false;
  }

  downloadCSV() {
    if (this.selectedDropdown) {
      this.zoneLocationMappingService.getMWTemplate(this.selectedDropdown, '').subscribe({
        next: (response) => {
          const data = response.body;
          if (!data || !data.length) {
            console.error('No data found in the Result array');
            return;
          }
  
          // Convert the data to a tab-separated format (basic representation of Excel sheet)
          const rows = data.map((row: any) => Object.values(row).join("\t")).join("\n");
          
          const excelContent = `
            <html xmlns:x="urn:schemas-microsoft-com:office:excel">
            <head><meta charset="UTF-8"></head>
            <body>
            <table>${rows}</table>
            </body>
            </html>`;
  
          const blob = new Blob([excelContent], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;' });
          const link = document.createElement('a');
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', `${this.selectedDropdown}_Zone-Location_Mapping.xlsx`);
  
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        },
        error: (error) => {
          console.error("Error fetching dropdown data", error);
        }
      });
    } else {
      this.alertData = { alertMessage: 'Please select state', alertVisible: 'error' };
    }
  }

  convertToCSV(data: any[]): string {
    if (!data || !data.length) {
      console.error('No data received');
      return '';
    }
    const headers = Object.keys(data[0]);
    const columnWidths = headers.map(header => {
      const headerLength = header.length;
      const maxDataLength = Math.max(...data.map(row => (row[header] ? row[header].toString().length : 0)));
      return Math.max(headerLength, maxDataLength);
    });

    const headerRow = headers.map((header, index) => this.padColumn(header, columnWidths[index])).join(',');
    const rows = data.map(row => {
      return headers.map((header, index) => this.padColumn(row[header], columnWidths[index])).join(',');
    });

    // Add footer notes
    const footerItems = [
      "Note",
      "Mandatory Fields:",
      "Zone"
    ];

    const csvContent = [
      headerRow,
      ...rows,
      '',
      ...footerItems
    ].join('\r\n');

    console.log('CSV Content:', csvContent);

    return csvContent;
  }

  padColumn(value: any, width: number): string {
    const cell = value ? value.toString() : '';
    return `"${cell.padEnd(width, ' ')}"`; 
  }
  closeAlert() {
    if(this.alertData.alertVisible=='success'){
      this.close.emit()
    }
    this.alertData = { alertMessage: '', alertVisible: '' };
  }

  closePopup() {
    this.close.emit()
  }
}
