
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from './user.service'; 
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActivityCalenderServiceService {
  private apiUrl = environment.apiUrl;
  
  constructor(private http: HttpClient, private userService: UserService) {}

  getDropdownOptions(CustomerId: number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MasterScoping/Get_LC_MasterScopedEntities?CustomerId=${CustomerId}`;
    return this.http.get<any>(url,{ observe: 'response' });
  }
  getGridData(EntityId: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MasterScoping/LC_GetLCActivityCalendar?EntityId=${EntityId}`;
    return this.http.get<any>(url,{ observe: 'response' });
  }

}
