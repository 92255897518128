<div class="col-md-12 colpadding0">
    <div class="entity-container d-flex flex-column">
        <div class="selected-values">
            <img *ngIf="!showRepository" src="../assets/vectors/Open.svg" class="svg-icon-btn" alt="" (click)="toggle('Repository')">
            <img *ngIf="showRepository" src="../assets/vectors/Closeminus.svg" class="svg-icon-btn" alt="" (click)="toggle('Repository')">
            <span class="f-label">Document Repository</span>
        </div>
        <div *ngIf="showRepository" class="selected-container colpadding0">

            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Notice Receipt Acknowledgement</label>
                    <div class="require-container">
                        <img src="../assets/vectors/DownloadBlue.svg" *ngIf="ModeData.acknowledgement" (click)="downloadFile('acknowledgement')"
                            title="Download the Notice Receipt Acknowledgement" alt="Download">
                        <img src="../assets/vectors/NoAccessDownload.svg" *ngIf="!ModeData.acknowledgement"
                            title="No File Availabe" alt="Download">
                        </div>
                    </div>
                    <div class="col-md-6 d-flex align-items-center">
                        <label class="f-label me-3">Draft Extension Application</label>
                        <div class="require-container">
                            <img src="../assets/vectors/DownloadBlue.svg" class="svg-icon-btn"
                            (click)="downloadFile('draftExtensionApplication')"
                            title="Download the Draft Extension Application"  *ngIf="ModeData?.draftExtensionApplication" alt="Download">
                            <img src="../assets/vectors/NoAccessDownload.svg" *ngIf="!ModeData?.draftExtensionApplication"
                                title="No File Availabe" alt="Download">
                                
                            </div>
                        </div>
                    </div>
                    <div class="row EmpContainer">
                        <div class="col-md-6 d-flex align-items-center">
                            <label class="f-label me-3">Notice Document</label>
                            <div class="require-container">
                                <img src="../assets/vectors/DownloadBlue.svg" *ngIf="ModeData.noticeDocument" class="svg-icon-btn" (click)="downloadFile('noticeDocument')"
                                title="Download the Notice Document" alt="">
                                <img src="../assets/vectors/NoAccessDownload.svg" *ngIf="!ModeData.noticeDocument"
                                    title="No File Availabe" alt="Download">
                                    
                                </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Draft Response (Prepared by SME)</label>
                    <div class="require-container">
                        <img src="../assets/vectors/DownloadBlue.svg"  *ngIf="ModeData.noticeDraftResponse" class="svg-icon-btn"(click)="downloadFile('noticeDraftResponse')"
                        title="Download Draft Response (Prepared by SME)" alt="">
                        <img src="../assets/vectors/NoAccessDownload.svg" *ngIf="!ModeData.noticeDraftResponse"
                            title="No File Availabe" alt="Download">
                        </div>
                    </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Translated Notice Document</label>
                    <div class="require-container">
                        <img src="../assets/vectors/DownloadBlue.svg" *ngIf="ModeData.translationDocument" class="svg-icon-btn" (click)="downloadFile('translationDocument')"
                        title="Download Translated Notice Document" alt="">
                        <img src="../assets/vectors/NoAccessDownload.svg" *ngIf="!ModeData.translationDocument"
                            title="No File Availabe" alt="Download">
                            
                        </div>
                    </div>
                    <div class="col-md-6 d-flex align-items-center">
                        <label class="f-label me-3">Notice Response Acknowledgement</label>
                        <div class="require-container">
                            <img src="../assets/vectors/DownloadBlue.svg" class="svg-icon-btn"
                            (click)="downloadFile('noticeResponseAcknowledgement')" *ngIf="ModeData.noticeResponseAcknowledgement"
                            title="Download Notice Response Acknowledgement" alt="">
                            <img src="../assets/vectors/NoAccessDownload.svg" *ngIf="!ModeData.noticeResponseAcknowledgement"
                                title="No File Availabe" alt="Download">
                                
                            </div>
                        </div>
                    </div>
                    <div class="row EmpContainer">
                        <div class="col-md-6 d-flex align-items-center">
                            <label class="f-label me-3">Extention Application Acknowledgement</label>
                            <div class="require-container">
                                <img src="../assets/vectors/DownloadBlue.svg" class="svg-icon-btn"
                                (click)="downloadFile('extensionAcknowledgementDocument')"  *ngIf="ModeData.extensionAcknowledgementDocument"
                                title="Download Extention Application Acknowledgement" alt="">
                                <img src="../assets/vectors/NoAccessDownload.svg" *ngIf="!ModeData.extensionAcknowledgementDocument"
                                    title="No File Availabe" alt="Download">
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="col-md-12 colpadding0">
    <div class="entity-container d-flex flex-column">
        <div class="selected-values">
            <img *ngIf="!showRequirements" src="../assets/vectors/Open.svg" class="svg-icon-btn" alt="" (click)="toggle('Requirements')">
            <img *ngIf="showRequirements" src="../assets/vectors/Closeminus.svg" class="svg-icon-btn" alt=""
                (click)="toggle('Requirements')">
            <span class="f-label">Document Requirements for the Notice</span>
        </div>
        <div *ngIf="showRequirements" class="selected-container">
            <kendo-grid class="custom-grid" [data]="gridData.data" [skip]="skip" [pageSize]="pageSize" [pageable]="{
                buttonCount: 5,
                info: true,
                type: 'numeric',
                pageSizes: [5, 10, 25],
                previousNext: true
              }" [resizable]="true" (pageChange)="pageChange($event)">
                <kendo-grid-column field="noticeType" title="Notice Type">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <div class="nowrap-cell" [title]="dataItem.noticeType">{{ dataItem.noticeType }}</div>                        
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="act" title="Act">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">                      
                        <div class="nowrap-cell" [title]="dataItem.act">{{ dataItem.act }}</div>                     
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="documentList" title="Document Name">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">                       
                        <div class="nowrap-cell" [title]="dataItem.documentList">{{ dataItem.documentList }}</div>                        
                    </ng-template>
                </kendo-grid-column>
                
                <kendo-grid-column field="periodFrom" title="Period From" [width]="115">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" class="cell-content">
                        <!-- <div [title]="dataItem.periodFrom" > -->

                            <app-kendo-datepicker class="gridbox" [(value)]="dataItem.periodFrom"
                            (valueChange)="onDateChanges($event, dataItem, rowIndex, 'periodFrom')"
                            [format]="'dd-MM-yyyy'" [placeholder]="'Select Date'" [width]="'100px'">
                        </app-kendo-datepicker>
                    <!-- </div> -->
                        <ng-template #displayPeriodFrom>
                            <span (click)="editRow(rowIndex, 'periodFrom', dataItem)">
                                {{ formatDate(parseDate(dataItem.periodFrom)) }}
                            </span>
                        </ng-template>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="periodTo" title="Period To" [width]="115">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" class="cell-content">
                        <app-kendo-datepicker class="gridbox" [(value)]="dataItem.periodTo"
                            (valueChange)="onDateChanges($event, dataItem, rowIndex, 'periodTo')"
                            [format]="'dd-MM-yyyy'" [placeholder]="'Select Date'" [width]="'100px'">
                        </app-kendo-datepicker>
                        <ng-template #displayPeriodTo>
                            <span (click)="editRow(rowIndex, 'periodTo', dataItem)">
                                {{ formatDate(parseDate(dataItem.periodTo)) }}
                            </span>
                        </ng-template>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="isAvailable" title="Available" class="cell-content" [width]="115">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <kendo-dropdownlist class="f-textbox" [data]="['Yes', 'No']"
                            [value]="dataItem.isAvailable === null || dataItem.isAvailable === undefined ? 'No' : (dataItem.isAvailable ? 'Yes' : 'No')"
                            (valueChange)="isAvailableChange($event, dataItem)" style="width: 100px;">
                        </kendo-dropdownlist>
                    </ng-template>
                </kendo-grid-column>


                <kendo-grid-column field="expectedDate" title="Expected Date" [width]="118">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <app-kendo-datepicker class="gridbox" [(value)]="dataItem.expectedDate"
                            (valueChange)="onDateChanges($event, dataItem, rowIndex, 'expectedDate')"
                            [format]="'dd-MM-yyyy'" [placeholder]="'Select Date'" [width]="'102px'"
                            [isDisabled]="dataItem.isAvailable">
                        </app-kendo-datepicker>
                        <ng-template #displayExpectedDate>
                            <span (click)="editRow(rowIndex, 'expectedDate', dataItem)">
                                {{ formatDate(parseDate(dataItem.expectedDate)) }}
                            </span>
                        </ng-template>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column *ngIf="Role=='CORDN'" field="upload" title="Upload" [width]="210">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <div class="col-12 upload">
                            <span *ngIf="!dataItem.FileName" style="color: #A0A0A0;">No file chosen</span>
                            <span *ngIf="dataItem.FileName">
                                {{dataItem.FileName}}
                            </span>
                            <input type="file" #BulkUploadFile (change)="onFileChange($event, dataItem, rowIndex)"
                                id="fileInput" style="display: none;" />
                            <button class="figma-btn-gray" (click)="BulkUploadFile.click()">Browse</button>
                        </div>
                    </ng-template>
                </kendo-grid-column>


                <kendo-grid-column *ngIf="Role === 'SDEXE' || Role === 'SME'" field="downloadPath"
                    title="Download" [width]="130">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <img *ngIf="dataItem.downloadPath" src="../assets/vectors/DownloadBlue.svg" class="svg-icon"
                            (click)="downloadGridFile(dataItem)" title="Download the Notice Receipt Acknowledgement"
                            alt="Download">
                        <span *ngIf="!dataItem.downloadPath">No file to download</span>
                    </ng-template>
                </kendo-grid-column>
            </kendo-grid>
            <div class="row EmpContainer">
                <div class="col-md-12 save-con">
                    <button *ngIf="Role==='CORDN'" class="figma-btn-blue" (click)="onSave()">Save</button>
                </div>
            </div>
        </div>
    </div>
    <div class="row EmpContainer">
        <div class="col-md-12 save-con">
            <button class="figma-btn-blue" (click)="goBack()">Back</button>
        </div>
    </div>
</div>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270"  [Data]="alertData"
    (close)="closeAlert()"></app-alert-popup>

<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>