import { Component } from '@angular/core';
import { LayoutModule } from '@progress/kendo-angular-layout';

@Component({
  selector: 'app-cd-applicable-status',
  standalone: true,
  imports: [LayoutModule],
  templateUrl: './cd-applicable-status.component.html',
  styleUrl: './cd-applicable-status.component.css'
})
export class CdApplicableStatusComponent {

}
