<div class="row  " style="margin-top: 10px;">
    <div class="col-md-11 colpadding0">
        <div class="col-md-3 colpadding0">
            <app-dropdown-list [data]="dropdownData" [selectedValue]="defaultFilterValue" [width]="'250px'"
                textField="text" valueField="value" (valueChange)="onDropdownValueChange($event)"
                style="margin-right: 0px; margin-left: 9px;">
            </app-dropdown-list>
        </div>

        <div class="col-md-8 colpadding0 PreviousInput" *ngIf="PreviousInput">
            <kendo-multiselecttree #multiselectTree placeholder="Select Branch" kendoMultiSelectTreeExpandable [popupSettings]="popupSettings"
                [kendoMultiSelectTreeHierarchyBinding]="BranchDropdown" childrenField="children" textField="name"
                valueField="id" [tagMapper]="tagMapper" (valueChange)="OnMultiSelectChange($event)"
                [expandedKeys]="['0']" class="f-textbox multi-box">
            </kendo-multiselecttree>

            <app-dropdown-list [data]="dropdownDataMonth" [selectedValue]="defaultSelectedValueMonth" [width]="'150px'"
                 textField="displayvalue" valueField="value"
                (valueChange)="onDropdownValueChangeMonth($event)" style="margin-right: 10px;">
            </app-dropdown-list>
            <app-dropdown-list [data]="dropdownDataYear" [selectedValue]="defaultSelectedValueYear" [width]="'150px'"
                 textField="displayvalue" valueField="value"
                (valueChange)="onDropdownValueChangeYear($event)" style="margin-right: 10px;">
            </app-dropdown-list>
        </div>
    </div>

    <div class="col-md-1 colpadding0">
        <div style="display: inline" style="float: right;">
            <button (click)="exportToExcel()" class="exportbtn">
                <img src="../assets/vectors/ExportButton.svg" alt="">
            </button>
        </div>
    </div>
</div>

<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270"  [Data]="alertData"
    (close)="closeAlert()"></app-alert-popup>