import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlobService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getSasToken(blobName: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/Common/GetSasToken`, { params: { BlobName: blobName } });
  }

  extractBlobName(blobLink: string): string {
    const url = new URL(blobLink);
    const containerName = 'labourcompliancetest/';
    const startIndex = url.pathname.indexOf(containerName) + containerName.length;
    return url.pathname.substring(startIndex);
  }


  downloadFile(blobName: string, sasToken: string) {
    const blobUrl = `${environment.blobUrl}${blobName}?${sasToken}`;
    const a = document.createElement('a');
    a.href = blobUrl;
    const filename = blobName.split('/').pop() || 'downloaded_file';
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  downloadError(blobName: string, sasToken: string) {
    debugger
    const blobUrl = `${blobName}?${sasToken}`;
    const a = document.createElement('a');
    a.href = blobUrl;
    const filename = blobName.split('/').pop() || 'downloaded_file';
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  

  downloadBlobFile(blobName: string, sasToken: string) {
    const blobUrl = `${blobName}?${sasToken}`;
    const a = document.createElement('a');
    a.href = blobUrl;
    const filename = blobName.split('/').pop() || 'downloaded_file';
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
