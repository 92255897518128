<form [formGroup]="commercialForm">

  <div class="row colpadding0" style="display: flex; margin-bottom: 0px;margin-top: 15px;">
    <div class="col-md-6 colpadding0" style="display: flex;justify-content: space-between;">
      <div class="col-md-4 sidebar colpadding0 f-label" style="margin-top: 7px;">Client</div>
      <div class="col-md-8">
        <app-dropdown-list 
        [data]="ClientDropdownData" 
        [selectedValue]="selectedClient" 
        [width]="'100%'"
        textField="text" 
        valueField="value" 
        (valueChange)="onDropdownValueChange($event, 'client')">
    </app-dropdown-list>
      </div>  
    </div>
    <div class="col-md-6 colpadding0" style="display: flex;justify-content: space-between;">
      <div class="col-md-4 sidebar colpadding0" style="margin-top: 7px;"></div>
      <div class="col-md-8">
        
      </div>  
    </div>
</div>


<div *ngIf="isActive">
  <div class="row colpadding0" style="display: flex; margin-bottom: 0px; margin-top: 1%;">
    <div class="col-md-6 colpadding0" style="display: flex;justify-content: space-between;">
      <div class="col-md-4 sidebar colpadding0 f-label" style="margin-top: 7px;">Billing Zone</div>
      <div class="col-md-8">
        <app-dropdown-list 
        [data]="BillingZone" 
        [selectedValue]="selectedBillingZone" 
        [width]="'100%'"
        textField="text" 
        valueField="value" 
        (valueChange)="onBillingZoneChange($event)"
        >
    </app-dropdown-list>
      </div>  
    </div>
    <div class="col-md-6 colpadding0" style="display: flex;justify-content: space-between;">
      <div class="col-md-4 sidebar colpadding0 f-label" style="margin-top: 7px;">Invoice Creation Date</div>
      <div class="col-md-8">
        <app-dropdown-list 
        [data]="InvoiceCreation" 
        [selectedValue]="selectedInvoiceDate" 
        [width]="'100%'"
        textField="text" 
        valueField="value" 
        (valueChange)="onInvoiceDateChange($event)"
        style="margin-left: 7px;">
    </app-dropdown-list>
      </div>  
    </div>
</div>
<div class="row colpadding0" style="display: flex; margin-bottom: 0px; margin-top: 1%;">
  <div class="col-md-6 colpadding0 " style="display: flex;justify-content: space-between;">
    <div class="col-md-4 sidebar colpadding0 f-label" style="margin-top: 7px;">Invoice Type</div>
    <div class="col-md-8 radiobutton">
     
  <!-- <div *ngFor="let item of radioButtonValue" class="radio-column">
    <kendo-radiobutton formControlName="invoiceType" [value]="item.value" [(ngModel)]="invoiceType"
    (ngModelChange)="onInvoiceChange($event)">
    </kendo-radiobutton>
    <kendo-label [for]="item.value" [text]="item.text" class="labelhight"></kendo-label>
</div> -->
<ul class="radio-column" style="gap: 48px;">
  <li *ngFor="let item of radioButtonValue">
    <div style="display: flex;margin-top: 10px;">
      <input
        type="radio"
        class="custom-radio"
        [id]="item.value"
        [value]="item.value"
        name="invoiceType"
        [(ngModel)]="invoiceType"
        formControlName="invoiceType"
        (change)="onInvoiceChange(item.value)"
        [checked]="invoiceType === item.value" />
        <div style="margin-top: 7px;">{{ item.text }}</div>
      
    </div>
  </li>
</ul>

    </div>  
  </div>
  
  <div class="col-md-6 colpadding0" style="display: flex;justify-content: space-between;">
    <div class="col-md-4 sidebar colpadding0 f-label" style="margin-top: 7px;">PO Applicability</div>
    <div class="col-md-8 radiobutton" style="padding-left: 25px;">
     
      <!-- <div *ngFor="let item of PoApplicabilityButtonValue" class="radio-column">
        <kendo-radiobutton formControlName="poApplicability" [value]="item.value" [(ngModel)]="poApplicability"
        (onChange)="onPOChange($event)">
        </kendo-radiobutton>
        <kendo-label [for]="item.value" [text]="item.text" class="labelhight"></kendo-label>
    </div> -->
    <ul class="radio-column">
      <li *ngFor="let item of PoApplicabilityButtonValue">
        <div style="display: flex; margin-top: 10px;">

          <input
        type="radio"
        class="custom-radio"
        [id]="item.value"
        [value]="item.value"
        [(ngModel)]="poApplicability"
        name="poApplicability"
        (change)="onPOChange(item.value)"
        formControlName="poApplicability"
        [checked]="poApplicability === item.value" />
          <div style="margin-top: 7px; margin-left: 5px;">
            {{ item.text }}
          </div>
        </div>
      </li>
    </ul>
    
    </div>  
  </div>
</div>
<div class="row colpadding0" *ngIf="invoiceType == 'R'" style="display: flex; margin-bottom: 0px; margin-top: 1%;">
  <div class="col-md-6 colpadding0" style="display: flex;justify-content: space-between;">
    <div class="col-md-4 sidebar colpadding0 f-label" style="margin-top: 7px;">Commercial Type</div>
    <div class="col-md-8 radiobutton">
    
<!-- <div *ngFor="let item of CommercialradioButtonValue" class="radio-column">
  <kendo-radiobutton 
  formControlName="commercialType"
  [value]="item.value" [(ngModel)]="commercialType"
    (ngModelChange)="onCommercialChange($event)">
  </kendo-radiobutton>
  <kendo-label [for]="item.value" [text]="item.text" class="labelhight"></kendo-label>
</div> -->
<ul class="radio-column">
  <li *ngFor="let item of CommercialradioButtonValue">
    <div style="display: flex; margin-top: 10px;">

      <input
    type="radio"
    class="custom-radio"
    [id]="item.value"
    [value]="item.value"
    name="commercialType"
    [(ngModel)]="commercialType"
    (change)="onCommercialChange(item.value)"
    formControlName="commercialType"
    [checked]="commercialType === item.value" />
      <div style="margin-top: 7px; margin-left: 5px;">
        {{ item.text }}
      </div>
    </div>
  </li>
</ul>
    </div>  
  </div>
  
  <div class="col-md-6 colpadding0" style="display: flex;justify-content: space-between;" *ngIf="commercialType == 'L' && invoiceType == 'R'">
    <div class="col-md-4 sidebar colpadding0" style="margin-top: 7px;">
      <label class="f-label">Status<sub class="text-danger">*</sub></label>
    </div>
    <div class="col-md-8 radiobutton" style="padding-left: 25px;"> 
      <app-dropdown-list 
          [data]="ActivityStatus" 
          [selectedValue]="selectedStatus" 
          [width]="'100%'"
          textField="text" 
          valueField="value" 
          formControlName="Status"
          (valueChange)="onStatusChange($event)"
          [ngClass]="{
            'is-invalid':
            commercialForm.get('Status')?.invalid && commercialForm.get('Status')?.touched
          }"
          [hasError]="showValidationErrorsStatus && commercialForm.get('Status')?.invalid"
          style="width: 100%;">
          
        </app-dropdown-list>
    </div>  
  </div>
</div>

<div class="row colpadding0" *ngIf="invoiceType == 'S'" style="display: flex; margin-bottom: 0px; margin-top: 1%;">
  <div class="col-md-6 colpadding0" style="display: flex;justify-content: space-between;">
    <div class="col-md-4 sidebar colpadding0 f-label" style="margin-top: 7px;">Split Invoice Type</div>
    <div class="col-md-8 radiobutton">
    
      <!-- <div *ngFor="let item of SplitradioButtonValue" class="radio-column">
        <kendo-radiobutton
        formControlName="splitInvoiceType"
            [value]="item.value"
            [ngModel]="splitInvoiceType" (ngModelChange)="onSplitChange1($event)">
        </kendo-radiobutton>
        <kendo-label [for]="item.value" [text]="item.text" class="labelhight"></kendo-label>
</div> -->
<ul class="radio-column">
  <li *ngFor="let item of SplitradioButtonValue">
    <div style="display: flex; margin-top: 10px;">

      <input
    type="radio"
    class="custom-radio"
    [id]="item.value"
    [value]="item.value"
    name="splitInvoiceType"
    [(ngModel)]="splitInvoiceType"
    (change)="onSplitChange1(item.value)"
    formControlName="splitInvoiceType"
    [checked]="splitInvoiceType === item.value" />
      <div style="margin-top: 7px; margin-left: 5px;">
        {{ item.text }}
      </div>
    </div>
  </li>
</ul>
    </div>  
  </div>
  
  <div class="col-md-6 colpadding0" style="display: flex;justify-content: space-between;">
    <div class="col-md-4 sidebar colpadding0" style="margin-top: 7px;"></div>
    <div class="col-md-8 radiobutton" style="padding-left: 25px;"> 
    </div>  
  </div>
</div>



<!-- <div *ngIf="commercialType == 'A' && invoiceType == 'R'">
<div class="row colpadding0" style="display: flex; margin-bottom: 0px; margin-top: 1%;">
  <div class="col-md-6 colpadding0" style="display: flex;justify-content: space-between;">
    <div class="col-md-4 sidebar colpadding0" style="margin-top: 7px;">Select Activity
      <sub class="text-danger">*</sub>
    </div>
    <div class="col-md-8">
     
  <app-dropdown-list 
  [data]="activityList" 
  [selectedValue]="selectedActivity" 
  [width]="'100%'"
  textField="text" 
  valueField="value" 
  [(ngModel)]="selectedActivity"
  formControlName="commercialType"
  (valueChange)="onActivityChange($event)"
 
  [hasError]="!selectedActivity.value && showValidationErrorsAct2">
</app-dropdown-list>
    </div>  
  </div>
  <div class="col-md-6 colpadding0" style="display: flex;justify-content: space-between;">
    <div class="col-md-4 sidebar colpadding0" style="margin-top: 7px;">Variable Type<sub class="text-danger">*</sub></div>
    <div class="col-md-8">
      <app-dropdown-list 
                  [data]="variableList" 
                  [selectedValue]="selectedVariable" 
                  [width]="'100%'"
                  textField="text" 
                  valueField="value" 
                  formControlName="variableType"
                  (valueChange)="onVariableChange($event)"
                  style="margin-left: 7px;"
                  [ngClass]="{
                    'is-invalid':
                    commercialForm.get('variableType')?.invalid && commercialForm.get('variableType')?.touched
                  }"
                  [hasError]="!selectedVariable.value && showValidationErrorsVar2">
                </app-dropdown-list>
    </div>  
  </div>
</div>
<div class="row colpadding0" style="display: flex; margin-bottom: 0px; margin-top: 1%;">
 
  <div class="col-md-6 colpadding0" style="display: flex; justify-content: space-between;" *ngIf="selectedVariable.value == 'F'">
    <div class="col-md-4 sidebar colpadding0" style="margin-top: 7px;">
      <label class="f-label">Fixed Amount<sub class="text-danger">*</sub></label>
    </div>
    <div class="col-md-8 require-container">
      <input kendoTextBox class="f-textbox" formControlName="FixedAmount" 
        [ngClass]="{'is-invalid': commercialForm.get('FixedAmount')?.invalid && (commercialForm.get('FixedAmount')?.touched)}" 
        style="width: 100%">
      <div *ngIf="commercialForm.get('FixedAmount')?.invalid && commercialForm.get('FixedAmount')?.touched && commercialForm.get('FixedAmount')?.errors?.['pattern']"
           class="invalid-feedback">
        Amount must contain only numbers
      </div>
    </div>
  </div>

  <div class="col-md-6 colpadding0" style="display: flex; justify-content: space-between;" *ngIf="selectedVariable.value == 'S'">
    <div class="col-md-4 sidebar colpadding0" >
      <label class="f-label">Commercial Element Type<sub class="text-danger">*</sub></label>
    </div>
    <div class="col-md-8">
      <app-dropdown-list 
        [data]="elementList" 
        [selectedValue]="selectedElement" 
        [width]="'100%'" 
        textField="text" 
        valueField="value" 
        formControlName="commercialElement"
        [ngClass]="{
          'is-invalid':
          commercialForm.get('commercialElement')?.invalid && commercialForm.get('commercialElement')?.touched
        }"
        (valueChange)="onElementChange($event)" 
        >
      </app-dropdown-list>
    </div>
  </div>
</div>
<div class="row colpadding0" style="display: flex; margin-bottom: 0px; margin-top: 1%;" *ngIf="selectedVariable.value == 'V'">
 
  <div class="col-md-6 colpadding0" style="display: flex; justify-content: space-between;">
    <div class="col-md-4 sidebar colpadding0">
      <label class="f-label">Commercial Element Type<sub class="text-danger">*</sub></label>
    </div>
    <div class="col-md-8">
      <app-dropdown-list 
        [data]="elementList" 
        [selectedValue]="selectedElement" 
        [width]="'100%'" 
        textField="text" 
        valueField="value" 
        formControlName="commercialElement"
        [ngClass]="{
          'is-invalid':
          commercialForm.get('commercialElement')?.invalid && commercialForm.get('commercialElement')?.touched
        }"
        (valueChange)="onElementChange($event)" 
        >
      </app-dropdown-list>
    </div>
  </div>

 
  <div class="col-md-6 colpadding0" style="display: flex; justify-content: space-between;">
    <div class="col-md-4 sidebar colpadding0" style="margin-top: 7px;">
      <label class="f-label">Fixed Amount<sub class="text-danger">*</sub></label>
    </div>
    <div class="col-md-8">
      <input kendoTextBox class="f-textbox" formControlName="FixedAmount"  
             [ngClass]="{'is-invalid': commercialForm.get('FixedAmount')?.invalid && (commercialForm.get('FixedAmount')?.touched)}" 
             style="width: 100%">
    </div>
  </div>
</div>
<div class="row colpadding0" style="display: flex; margin-bottom: 0px; margin-top: 1%;">
 
  <div class="col-md-6 colpadding0" style="display: flex; justify-content: space-between;">
    <div class="col-md-4 sidebar colpadding0" style="margin-top: 7px;">
      <label class="f-label">Client Activity Name<sub class="text-danger">*</sub></label>
    </div>
    <div class="col-md-8 require-container">
      <input kendoTextBox class="f-textbox" formControlName="ClientActivityName" 
      [ngClass]="{
        'is-invalid':
        commercialForm.get('ClientActivityName')?.invalid && commercialForm.get('ClientActivityName')?.touched
      }"
      style="width:100%">
     
    </div>
  </div>

  <div class="col-md-6 colpadding0" style="display: flex; justify-content: space-between;">
    <div class="col-md-4 sidebar colpadding0" style="margin-top: 7px;">
      <label class="f-label">Status<sub class="text-danger">*</sub></label>
    </div>
    <div class="col-md-8">
      <app-dropdown-list 
        [data]="ActivityStatus" 
        [selectedValue]="selectedStatus" 
        [width]="'100%'" 
        textField="text" 
        valueField="value" 
        (valueChange)="onStatusChange($event)" 
        style="margin-left: 7px;" 
        formControlName="Status"
        [ngClass]="{
          'is-invalid':
          commercialForm.get('Status')?.invalid && commercialForm.get('Status')?.touched
        }"
        [hasError]="!selectedStatus.value && showValidationErrorsStatus">
      </app-dropdown-list>
    </div>
  </div>
</div>

</div> -->

<div *ngIf="commercialType == 'A' && invoiceType == 'R'">
  <div class="row colpadding0" style="display: flex; margin-bottom: 0px; margin-top: 1%;">
 
    <div class="col-md-6 colpadding0" style="display: flex; justify-content: space-between;">
      <div class="col-md-4 sidebar colpadding0" style="margin-top: 7px;">
        <label class="f-label">Select Activity<sub class="text-danger">*</sub></label>
      </div>
      <div class="col-md-8">
        <app-dropdown-list 
          [data]="activityList" 
          [selectedValue]="selectedActivity" 
          [width]="'100%'" 
          textField="text" 
          valueField="value" 
          (valueChange)="onActivityChange($event)" 
          [hasError]="!selectedActivity.value && showValidationErrorsAct">
        </app-dropdown-list>
      </div>
    </div>
  
  
    <div class="col-md-6 colpadding0" style="display: flex; justify-content: space-between;">
      <div class="col-md-4 sidebar colpadding0" style="margin-top: 7px;">
        <label class="f-label">Variable Type<sub class="text-danger">*</sub></label>
      </div>
      <div class="col-md-8">
        <app-dropdown-list 
          [data]="variableList" 
          [selectedValue]="selectedVariable" 
          [width]="'100%'" 
          textField="text" 
          valueField="value" 
          (valueChange)="onVariableChange($event)" 
          style="margin-left: 7px;" 
          [hasError]="!selectedVariable.value && showValidationErrorsVar">
        </app-dropdown-list>
      </div>
    </div>
  </div>
  <div class="row colpadding0" style="display: flex; margin-bottom: 0px; margin-top: 1%;" *ngIf="selectedVariable.value == 'F'">
   
    <div class="col-md-6 colpadding0" style="display: flex; justify-content: space-between;">
      <div class="col-md-4 sidebar colpadding0" style="margin-top: 7px;">
        <label class="f-label">Fixed Amount<sub class="text-danger">*</sub></label>
      </div>
      <div class="col-md-8">
        <input kendoTextBox 
          class="f-textbox" 
          style="width: 100%;" 
          formControlName="FixedAmount"
          [ngClass]="{'is-invalid': commercialForm.get('FixedAmount')?.invalid && (commercialForm.get('FixedAmount')?.touched)}">
        
        <div *ngIf="commercialForm.get('FixedAmount')?.invalid && (commercialForm.get('FixedAmount')?.touched) && commercialForm.get('FixedAmount')?.errors?.['pattern']"
          class="invalid-feedback">
          Amount must contain only numbers
        </div>
      </div>
    </div>
  </div>
  <div class="row colpadding0" style="display: flex; margin-bottom: 0px; margin-top: 1%;" *ngIf="selectedVariable.value == 'V'">
    <!-- Commercial Element Type -->
    <div class="col-md-6 colpadding0" style="display: flex; justify-content: space-between;">
      <div class="col-md-4 sidebar colpadding0" >
        <label class="f-label">Commercial Element Type<sub class="text-danger">*</sub></label>
      </div>
      <div class="col-md-8">
        <app-dropdown-list 
          [data]="elementList" 
          [selectedValue]="selectedElement" 
          [width]="'100%'"
          textField="text" 
          valueField="value" 
          (valueChange)="onElementChange($event)"
          [hasError]="!selectedVariable.value && showValidationErrorsC4">
        </app-dropdown-list>
      </div>
    </div>
  
    <!-- Fixed Amount -->
    <div class="col-md-6 colpadding0" style="display: flex; justify-content: space-between;">
      <div class="col-md-4 sidebar colpadding0" style="margin-top: 7px;">
        <label class="f-label">Fixed Amount<sub class="text-danger">*</sub></label>
      </div>
      <div class="col-md-8">
        <input kendoTextBox 
          class="f-textbox" 
          style="margin-left: 7px; width: 100%;" 
          formControlName="FixedAmount"
          [ngClass]="{'is-invalid': commercialForm.get('FixedAmount')?.invalid && (commercialForm.get('FixedAmount')?.touched)}">
        <!-- Validation message -->
        <div *ngIf="commercialForm.get('FixedAmount')?.invalid && (commercialForm.get('FixedAmount')?.touched) && commercialForm.get('FixedAmount')?.errors?.['pattern']"
          class="invalid-feedback">
          Amount must contain only numbers
        </div>
      </div>
    </div>
  </div>
  <div class="row colpadding0" *ngIf="selectedVariable.value == 'S'" style="display: flex; margin-bottom: 0px; margin-top: 1%;">
    
    <div class="col-md-6 colpadding0" style="display: flex; justify-content: space-between;">
      <div class="col-md-4 sidebar colpadding0" >
        <label class="f-label">Commercial Element Type<sub class="text-danger">*</sub></label>
      </div>
      <div class="col-md-8">
        <app-dropdown-list 
          [data]="elementList" 
          [selectedValue]="selectedElement" 
          [width]="'100%'" 
          textField="text" 
          valueField="value" 
          (valueChange)="onElementChange($event)"
          [hasError]="!selectedVariable.value && showValidationErrorsC3">
        </app-dropdown-list>
      </div>
    </div>
  </div>
  <div class="row colpadding0" style="display: flex; margin-bottom: 0px; margin-top: 1%;">
    <div class="col-md-6 colpadding0" style="display: flex; justify-content: space-between; margin-bottom: 1%;">
      <div class="col-md-4 sidebar colpadding0">
        <label class="f-label">Client Activity Name<sub class="text-danger">*</sub></label>
      </div>
      <div class="col-md-8 require-container">
        <input kendoTextBox class="f-textbox" style="width: 100%;" formControlName="ClientActivityName" 
          [ngClass]="{'is-invalid': commercialForm.get('ClientActivityName')?.invalid && (commercialForm.get('ClientActivityName')?.touched)}">
        <div *ngIf="commercialForm.get('ClientActivityName')?.invalid && (commercialForm.get('ClientActivityName')?.touched) && commercialForm.get('ClientActivityName')?.errors?.['pattern']"
          class="invalid-feedback">
          Name must contain only letters and spaces
        </div>
      </div>
    </div>
  
    <div class="col-md-6 colpadding0" style="display: flex; justify-content: space-between; margin-bottom: 1%;">
      <div class="col-md-4 sidebar colpadding0">
        <label class="f-label">Status<sub class="text-danger">*</sub></label>
      </div>
      <div class="col-md-8">
        <app-dropdown-list 
          [data]="ActivityStatus" 
          [selectedValue]="selectedStatus" 
          [width]="'100%'"  
          textField="text" 
          valueField="value" 
          (valueChange)="onStatusChange($event)"
          formControlName="Status"
          [ngClass]="{
            'is-invalid':
            commercialForm.get('Status')?.invalid && commercialForm.get('Status')?.touched
          }"
          style="margin-left: 7px;"
          [hasError]="!selectedStatus.value && showValidationErrorsStatus">
        </app-dropdown-list>
      </div>
    </div>
  </div>
  
</div>

<div *ngIf="splitInvoiceType == 'A' && invoiceType == 'S'">
  <div class="row colpadding0" style="display: flex; margin-bottom: 0px; margin-top: 1%;">
 
    <div class="col-md-6 colpadding0" style="display: flex; justify-content: space-between;">
      <div class="col-md-4 sidebar colpadding0" style="margin-top: 7px;">
        <label class="f-label">Select Activity<sub class="text-danger">*</sub></label>
      </div>
      <div class="col-md-8">
        <app-dropdown-list 
          [data]="activityList" 
          [selectedValue]="selectedActivity" 
          [width]="'100%'" 
          textField="text" 
          valueField="value" 
          (valueChange)="onActivityChange($event)" 
          [hasError]="!selectedActivity.value && showValidationErrorsAct">
        </app-dropdown-list>
      </div>
    </div>
  
  
    <div class="col-md-6 colpadding0" style="display: flex; justify-content: space-between;">
      <div class="col-md-4 sidebar colpadding0" style="margin-top: 7px;">
        <label class="f-label">Variable Type<sub class="text-danger">*</sub></label>
      </div>
      <div class="col-md-8">
        <app-dropdown-list 
          [data]="variableList" 
          [selectedValue]="selectedVariable" 
          [width]="'100%'" 
          textField="text" 
          valueField="value" 
          (valueChange)="onVariableChange($event)" 
          style="margin-left: 7px;" 
          [hasError]="!selectedVariable.value && showValidationErrorsVar">
        </app-dropdown-list>
      </div>
    </div>
  </div>
  <div class="row colpadding0" style="display: flex; margin-bottom: 0px; margin-top: 1%;" *ngIf="selectedVariable.value == 'F'">
   
    <div class="col-md-6 colpadding0" style="display: flex; justify-content: space-between;">
      <div class="col-md-4 sidebar colpadding0" style="margin-top: 7px;">
        <label class="f-label">Fixed Amount<sub class="text-danger">*</sub></label>
      </div>
      <div class="col-md-8">
        <input kendoTextBox 
          class="f-textbox" 
          style="width: 100%;" 
          formControlName="FixedAmount"
          [ngClass]="{'is-invalid': commercialForm.get('FixedAmount')?.invalid && (commercialForm.get('FixedAmount')?.touched)}">
        
        <div *ngIf="commercialForm.get('FixedAmount')?.invalid && (commercialForm.get('FixedAmount')?.touched) && commercialForm.get('FixedAmount')?.errors?.['pattern']"
          class="invalid-feedback">
          Amount must contain only numbers
        </div>
      </div>
    </div>
  </div>
  <div class="row colpadding0" style="display: flex; margin-bottom: 0px; margin-top: 1%;" *ngIf="selectedVariable.value == 'V'">
    <!-- Commercial Element Type -->
    <div class="col-md-6 colpadding0" style="display: flex; justify-content: space-between;">
      <div class="col-md-4 sidebar colpadding0" >
        <label class="f-label">Commercial Element Type<sub class="text-danger">*</sub></label>
      </div>
      <div class="col-md-8">
        <app-dropdown-list 
          [data]="elementList" 
          [selectedValue]="selectedElement" 
          [width]="'100%'"
          textField="text" 
          valueField="value" 
          (valueChange)="onElementChange($event)"
          [hasError]="!selectedVariable.value && showValidationErrorsC4">
        </app-dropdown-list>
      </div>
    </div>
  
    <!-- Fixed Amount -->
    <div class="col-md-6 colpadding0" style="display: flex; justify-content: space-between;">
      <div class="col-md-4 sidebar colpadding0" style="margin-top: 7px;">
        <label class="f-label">Fixed Amount<sub class="text-danger">*</sub></label>
      </div>
      <div class="col-md-8">
        <input kendoTextBox 
          class="f-textbox" 
          style="margin-left: 7px; width: 100%;" 
          formControlName="FixedAmount"
          [ngClass]="{'is-invalid': commercialForm.get('FixedAmount')?.invalid && (commercialForm.get('FixedAmount')?.touched)}">
        <!-- Validation message -->
        <div *ngIf="commercialForm.get('FixedAmount')?.invalid && (commercialForm.get('FixedAmount')?.touched) && commercialForm.get('FixedAmount')?.errors?.['pattern']"
          class="invalid-feedback">
          Amount must contain only numbers
        </div>
      </div>
    </div>
  </div>
  <div class="row colpadding0" *ngIf="selectedVariable.value == 'S'" style="display: flex; margin-bottom: 0px; margin-top: 1%;">
    
    <div class="col-md-6 colpadding0" style="display: flex; justify-content: space-between;">
      <div class="col-md-4 sidebar colpadding0" >
        <label class="f-label">Commercial Element Type<sub class="text-danger">*</sub></label>
      </div>
      <div class="col-md-8">
        <app-dropdown-list 
          [data]="elementList" 
          [selectedValue]="selectedElement" 
          [width]="'100%'" 
          textField="text" 
          valueField="value" 
          (valueChange)="onElementChange($event)"
          [hasError]="!selectedVariable.value && showValidationErrorsC3">
        </app-dropdown-list>
      </div>
    </div>
  </div>
  <div class="row colpadding0" style="display: flex; margin-bottom: 0px; margin-top: 1%;">
    <div class="col-md-6 colpadding0" style="display: flex; justify-content: space-between; margin-bottom: 1%;">
      <div class="col-md-4 sidebar colpadding0">
        <label class="f-label">Client Activity Name<sub class="text-danger">*</sub></label>
      </div>
      <div class="col-md-8 require-container">
        <input kendoTextBox class="f-textbox" style="width: 100%;" formControlName="ClientActivityName" 
          [ngClass]="{'is-invalid': commercialForm.get('ClientActivityName')?.invalid && (commercialForm.get('ClientActivityName')?.touched)}">
        <div *ngIf="commercialForm.get('ClientActivityName')?.invalid && (commercialForm.get('ClientActivityName')?.touched) && commercialForm.get('ClientActivityName')?.errors?.['pattern']"
          class="invalid-feedback">
          Name must contain only letters and spaces
        </div>
      </div>
    </div>
  
    <div class="col-md-6 colpadding0" style="display: flex; justify-content: space-between; margin-bottom: 1%;">
      <div class="col-md-4 sidebar colpadding0">
        <label class="f-label">Status<sub class="text-danger">*</sub></label>
      </div>
      <div class="col-md-8">
        <app-dropdown-list 
          [data]="ActivityStatus" 
          [selectedValue]="selectedStatus" 
          [width]="'100%'"  
          textField="text" 
          valueField="value" 
          (valueChange)="onStatusChange($event)"
          formControlName="Status"
          [ngClass]="{
            'is-invalid':
            commercialForm.get('Status')?.invalid && commercialForm.get('Status')?.touched
          }"
          style="margin-left: 7px;"
          [hasError]="!selectedStatus.value && showValidationErrorsStatus">
        </app-dropdown-list>
      </div>
    </div>
  </div>
  
</div>

<div *ngIf="commercialType == 'L' && invoiceType == 'R'">
  <div class="row colpadding0" style="margin-top: 10px;">
   
    <div class="col-md-6 colpadding0" style="display: flex; justify-content: space-between; ">
      <div class="col-md-4 sidebar colpadding0" style="margin-top: 7px;">
        <label class="f-label">Lump Sum Name<sub class="text-danger">*</sub></label>
      </div>
      <div class="col-md-8 require-container">
        <input kendoTextBox class="f-textbox" style="width: 100%;" formControlName="lumpSumName"
          [ngClass]="{'is-invalid': commercialForm.get('lumpSumName')?.invalid && (commercialForm.get('lumpSumName')?.touched)}" required>
        <div *ngIf="commercialForm.get('lumpSumName')?.invalid && (commercialForm.get('lumpSumName')?.touched) && commercialForm.get('lumpSumName')?.errors?.['pattern']"
          class="invalid-feedback">
          Name must contain only letters and spaces
        </div>
      </div>
    </div>
  
   
    <div class="col-md-6 colpadding0" style="display: flex; justify-content: space-between; ">
      <div class="col-md-4 sidebar colpadding0" style="margin-top: 7px;">
        <label class="f-label">Lump Sum Amount<sub class="text-danger">*</sub></label>
      </div>
      <div class="col-md-8 require-container" style="padding-left: 27px;">
        <input kendoTextBox type="number" class="f-textbox" style="width: 100%;" formControlName="lumpSumAmount" 
          [ngClass]="{'is-invalid': commercialForm.get('lumpSumAmount')?.invalid && (commercialForm.get('lumpSumAmount')?.touched)}" required>
       
      </div>
    </div>
  </div>
  <div class="row colpadding0" >
    
    <!-- <div class="col-md-6 colpadding0" style="display: flex; justify-content: space-between; margin-bottom: 1%;">
      <div class="col-md-4 sidebar colpadding0" style="margin-top: 7px;">
        <label class="f-label">Status<sub class="text-danger">*</sub></label>
      </div>
      <div class="col-md-8 require-container">
      
        <app-dropdown-list 
        [data]="ActivityStatus" 
        [selectedValue]="selectedStatus" 
        [width]="'100%'"
        textField="text" 
        valueField="value" 
        formControlName="Status"
        (valueChange)="onStatusChange($event)"
        [ngClass]="{
          'is-invalid':
          commercialForm.get('Status')?.invalid && commercialForm.get('Status')?.touched
        }"
        [hasError]="showValidationErrorsStatus && !selectedStatus">
      </app-dropdown-list>
      <div *ngIf="showValidationErrorsStatus && !selectedStatus" class="invalid-feedback">
        Status is required
      </div>
       
      </div>
    </div> -->
    <!-- <div class="col-md-6 colpadding0" style="display: flex; justify-content: space-between; margin-bottom: 1%;">
      <div class="col-md-4 sidebar colpadding0" style="margin-top: 7px;">
        <label class="f-label">Status<sub class="text-danger">*</sub></label>
      </div>
      <div class="col-md-8 require-container">
        <app-dropdown-list 
          [data]="ActivityStatus" 
          [selectedValue]="selectedStatus" 
          [width]="'100%'"
          textField="text" 
          valueField="value" 
          formControlName="Status"
          (valueChange)="onStatusChange($event)"
          [ngClass]="{
            'is-invalid':
            commercialForm.get('Status')?.invalid && commercialForm.get('Status')?.touched
          }"
          [hasError]="showValidationErrorsStatus && commercialForm.get('Status')?.invalid">
        </app-dropdown-list>
      </div>
    </div> -->
    
  </div>
  
</div>

</div>
  </form>


    <div class="row colpadding0" *ngIf="selectedVariable.value == 'S'" style="margin-top: 1%;">
      <div class="col-md-5" style="width:fit-content;">
        <!-- <kendo-grid class="custom-grid" 
          [data]="SlabGrid" 
          [pageSize]="10" 
          [pageable]="false" 
          [skip]="Skip"
          (pageChange)="pageChange($event)">
    
          <kendo-grid-column field="slab" title="Slab">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div [title]="dataItem.slab" class="custom-tooltip">
                {{ dataItem.slab }}
              </div>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="from" title="From">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <input 
                kendoTextBox 
                [(ngModel)]="dataItem.from" 
                class="f-textbox" 
                style="margin-left: 7px;" 
                [readonly]="rowIndex === 0" />
            </ng-template>
          </kendo-grid-column>
    
          <kendo-grid-column field="to" title="To">
            <ng-template kendoGridCellTemplate let-dataItem>
              <input kendoTextBox [(ngModel)]="dataItem.to" class="f-textbox" style="margin-left: 7px;" />
            </ng-template>
          </kendo-grid-column>
    
          <kendo-grid-column field="amount" title="Amount">
            <ng-template kendoGridCellTemplate let-dataItem>
              <input kendoTextBox [(ngModel)]="dataItem.amount" class="f-textbox" style="margin-left: 7px;" />
            </ng-template>
          </kendo-grid-column>
        </kendo-grid> -->
        
        <kendo-grid class="custom-grid" 
    [data]="SlabGrid" 
    [pageSize]="10" 
    [pageable]="false" 
    [skip]="Skip"
    (pageChange)="pageChange($event)">

  <!-- Slab Column -->
  <kendo-grid-column field="slab" title="Slab" [width]="150">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{ dataItem.slab }}</span>
    </ng-template>
  </kendo-grid-column>

  <!-- From Column with Input Field -->
  <kendo-grid-column field="from" title="From" [width]="150">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <input 
        type="text" 
        [(ngModel)]="dataItem.from" 
        style="width: 100%; border: 1px solid #ddd; background-color: #f5f5f5;" 
        [readonly]="rowIndex === 0" />
    </ng-template>
  </kendo-grid-column>

  <!-- To Column with Input Field -->
  <kendo-grid-column field="to" title="To" [width]="150">
    <ng-template kendoGridCellTemplate let-dataItem>
      <input 
        type="text" 
        [(ngModel)]="dataItem.to" 
        style="width: 100%; border: 1px solid #ddd; background-color: #f5f5f5;" />
    </ng-template>
  </kendo-grid-column>

  <!-- Amount Column with Input Field -->
  <kendo-grid-column field="amount" title="Amount" [width]="150">
    <ng-template kendoGridCellTemplate let-dataItem>
      <input 
        type="number" 
        [(ngModel)]="dataItem.amount" 
        style="width: 100%; border: 1px solid #ddd; background-color: #f5f5f5;" />
    </ng-template>
  </kendo-grid-column>

</kendo-grid>

    
      </div>
    
      <div class="buttonAlign">
        <button class="figma-btn-blue" (click)="addSlab()">Add Slab</button>
        <button class="figma-btn-blue" (click)="deleteSlab()">Delete Slab</button>
      </div>
    </div>

    <div *ngIf="isActive &&  splitInvoiceType != 'B'" style="margin-top: 10px;" >
      <app-grid-action-item [gridData]="gridData" [columns]="parentColumns" [pageSize]="10" [pageable]="true"
      [filterable]="true"></app-grid-action-item>
      <div class="buttonAlign">
        <button class="figma-btn-blue" (click)="onSubmit()">Save</button>
        <button class="figma-btn-blue">Reset</button>
      </div>
    </div>
    <div class="row colpadding0" style="margin-top: 10px;" *ngIf="splitInvoiceType == 'B' &&  isActive && invoiceType == 'S'">
      <div class="col-md-5" style="width:fit-content;" *ngIf="BranchWisePriceDetails && BranchWisePriceDetails.total != 0">
        <!-- <kendo-grid class="custom-grid" 
          [data]="BranchWisePriceDetails.data" 
          [pageSize]="10" 
          [pageable]="false" 
          [skip]="Skip"
          (pageChange)="pageChange($event)">
          
          
          <kendo-grid-column [width]="80">
            <ng-template kendoGridHeaderTemplate let-dataItem>
              <div class="checkbox-container" style="align-items: center;">
                <input 
                  type="checkbox" 
                  [(ngModel)]="allSelectedBranch" 
                  (change)="toggleSelectAllBranch($event)"/>
              </div>
            </ng-template>
      
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="checkbox-container" style="align-items: center;">
                <input 
                type="checkbox" 
                [(ngModel)]="dataItem.selected" 
                (change)="onRowSelectionChange1(dataItem)" />
              </div>
            </ng-template>
          </kendo-grid-column>
          
          <kendo-grid-column field="state" title="State">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div [title]="dataItem.state" class="custom-tooltip">
                {{ dataItem.state }}
              </div>
            </ng-template>
          </kendo-grid-column>
          
          <kendo-grid-column field="locationName" title="Location">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div [title]="dataItem.locationName" class="custom-tooltip">
                {{ dataItem.locationName }}
              </div>
            </ng-template>
          </kendo-grid-column>
          
          <kendo-grid-column field="cL_BranchName" title="Branch">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div [title]="dataItem.cL_BranchName" class="custom-tooltip">
                {{ dataItem.cL_BranchName }}
              </div>
            </ng-template>
          </kendo-grid-column>
          
          <kendo-grid-column field="ccB_Name" title="Name">
            <ng-template kendoGridCellTemplate let-dataItem>
              <input kendoTextBox [(ngModel)]="dataItem.ccB_Name" (ngModelChange)="onNameChange(dataItem)" class="f-textbox branch-textbox" style="margin-left: 7px;" />
            </ng-template>
          </kendo-grid-column>
      
          <kendo-grid-column field="price" title="Price">
            <ng-template kendoGridCellTemplate let-dataItem>
              <input kendoTextBox [(ngModel)]="dataItem.price" (ngModelChange)="onPriceChange(dataItem)" class="f-textbox branch-textbox" style="margin-left: 7px;" />
            </ng-template>
          </kendo-grid-column>
      
          <kendo-grid-column field="status" title="Status">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div [title]="dataItem.status" class="custom-tooltip">
                {{ dataItem.status }}
              </div>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid> -->
        <kendo-grid class="custom-grid" 
        [data]="BranchWisePriceDetails.data" 
        [pageSize]="10" 
        [pageable]="false" 
        [skip]="Skip"
        (pageChange)="pageChange($event)">
      
      <!-- Checkbox Column -->
      <kendo-grid-column [width]="80">
        <ng-template kendoGridHeaderTemplate>
          <div class="checkbox-container" style="align-items: center;">
            <input 
              type="checkbox" 
              [(ngModel)]="allSelectedBranch" 
              (change)="toggleSelectAllBranch($event)" />
          </div>
        </ng-template>
    
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="checkbox-container" style="align-items: center;">
            <input 
              type="checkbox" 
              [(ngModel)]="dataItem.selected" 
              (change)="onRowSelectionChange1(dataItem)" />
          </div>
        </ng-template>
      </kendo-grid-column>
      
      <!-- State Column -->
      <kendo-grid-column field="state" title="State" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div [title]="dataItem.state" class="custom-tooltip">
            {{ dataItem.state }}
          </div>
        </ng-template>
      </kendo-grid-column>
      
      <!-- Location Column -->
      <kendo-grid-column field="locationName" title="Location" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div [title]="dataItem.locationName" class="custom-tooltip">
            {{ dataItem.locationName }}
          </div>
        </ng-template>
      </kendo-grid-column>
      
      <!-- Branch Column -->
      <kendo-grid-column field="cL_BranchName" title="Branch" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div [title]="dataItem.cL_BranchName" class="custom-tooltip">
            {{ dataItem.cL_BranchName }}
          </div>
        </ng-template>
      </kendo-grid-column>
      
      <!-- Name Column -->
      <kendo-grid-column field="ccB_Name" title="Name" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
          <input 
            kendoTextBox 
            [(ngModel)]="dataItem.ccB_Name" 
            (ngModelChange)="onNameChange(dataItem)" 
            style="width: 100%; border: 1px solid #ddd; background-color: #f5f5f5;" />
        </ng-template>
      </kendo-grid-column>
    
      <!-- Price Column -->
      <kendo-grid-column field="price" title="Price" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
          <input 
          type="number"
            kendoTextBox 
            [(ngModel)]="dataItem.price" 
            (ngModelChange)="onPriceChange(dataItem)" 
            style="width: 100%; border: 1px solid #ddd; background-color: #f5f5f5;" />
        </ng-template>
      </kendo-grid-column>
    
      <!-- Status Column -->
      <kendo-grid-column field="status" title="Status" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div [title]="dataItem.status" class="custom-tooltip">
            {{ dataItem.status }}
          </div>
        </ng-template>
      </kendo-grid-column>
    
    </kendo-grid>
    
      </div>
      
      <div class="col-md-5" style="width:fit-content; padding-top:10px">
        <!-- <kendo-grid class="custom-grid" 
          [data]="OnetimeActivityGrid.data" 
          [pageSize]="10" 
          [pageable]="false" 
          [skip]="Skip"
          (pageChange)="pageChange($event)">
          
          <kendo-grid-column [width]="80">
            <ng-template kendoGridHeaderTemplate let-dataItem>
              <div class="checkbox-container" style="align-items: center;">
                <input 
                  type="checkbox" 
                  [(ngModel)]="allSelected" 
                  (change)="toggleSelectAll($event)"/>
              </div>
            </ng-template>
      
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="checkbox-container" style="align-items: center;">
                <input 
                type="checkbox" 
                [(ngModel)]="dataItem.selected" 
                (change)="onRowSelectionChange(dataItem)" />
              </div>
            </ng-template>
          </kendo-grid-column>
          
          <kendo-grid-column field="name" title="Activity Name">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div [title]="dataItem.name" class="custom-tooltip">
                {{ dataItem.name }}
              </div>
            </ng-template>
          </kendo-grid-column>
          
          <kendo-grid-column field="commercialName" title="Commercial Name">
            <ng-template kendoGridCellTemplate let-dataItem>
              <input kendoTextBox [(ngModel)]="dataItem.commercialName" (ngModelChange)="onCommercialNameChange(dataItem)" class="f-textbox grid-textbox" style="margin-left: 7px;" />
            </ng-template>
          </kendo-grid-column>
      
          <kendo-grid-column field="price" title="Price">
            <ng-template kendoGridCellTemplate let-dataItem>
              <input kendoTextBox [(ngModel)]="dataItem.price" (ngModelChange)="onPriceChangeCo(dataItem)" class="f-textbox grid-textbox" style="margin-left: 7px;" />
            </ng-template>
          </kendo-grid-column>
      
          <kendo-grid-column field="status" title="Status">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div [title]="dataItem.status" class="custom-tooltip">
                {{ dataItem.status }}
              </div>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid> -->
        <kendo-grid class="custom-grid" 
        [data]="OnetimeActivityGrid.data" 
        [pageSize]="10" 
        [pageable]="false" 
        [skip]="Skip"
        (pageChange)="pageChange($event)">
    
      <!-- Checkbox Column -->
      <kendo-grid-column [width]="80">
        <ng-template kendoGridHeaderTemplate>
          <div class="checkbox-container" style="align-items: center;">
            <input 
              type="checkbox" 
              [(ngModel)]="allSelected" 
              (change)="toggleSelectAll($event)" />
          </div>
        </ng-template>
    
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="checkbox-container" style="align-items: center;">
            <input 
              type="checkbox" 
              [(ngModel)]="dataItem.selected" 
              (change)="onRowSelectionChange(dataItem)" />
          </div>
        </ng-template>
      </kendo-grid-column>
    
      <!-- Activity Name Column -->
      <kendo-grid-column field="name" title="Activity Name" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div [title]="dataItem.name" class="custom-tooltip">
            {{ dataItem.name }}
          </div>
        </ng-template>
      </kendo-grid-column>
    
      <!-- Commercial Name Column -->
      <kendo-grid-column field="commercialName" title="Commercial Name" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
          <input 
            kendoTextBox 
            [(ngModel)]="dataItem.commercialName" 
            (ngModelChange)="onCommercialNameChange(dataItem)" 
            style="width: 100%; border: 1px solid #ddd; background-color: #f5f5f5;" />
        </ng-template>
      </kendo-grid-column>
    
      <!-- Price Column -->
      <kendo-grid-column field="price" title="Price" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
          <input 
          type="number"
            kendoTextBox 
            [(ngModel)]="dataItem.price" 
            (ngModelChange)="onPriceChangeCo(dataItem)" 
            style="width: 100%; border: 1px solid #ddd; background-color: #f5f5f5;" />
        </ng-template>
      </kendo-grid-column>
    
      <!-- Status Column -->
      <kendo-grid-column field="status" title="Status" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div [title]="dataItem.status" class="custom-tooltip">
            {{ dataItem.status }}
          </div>
        </ng-template>
      </kendo-grid-column>
    
    </kendo-grid>
    
      </div>
      
      <div class="buttonAlign">
        <button class="figma-btn-blue" (click)="onSubmit()">Save</button>
      </div>
    </div>
    <app-alert-popup 
    *ngIf="alertData?.alertVisible === 'confirm'"
    [Width]="250"
    [Height]="280"
    [Data]="alertData",
    [id] = '0',
    (confirm)="onPopupConfirmed($event)"
    (cancel)="onPopupCanceled()">
</app-alert-popup>