import { Component } from '@angular/core';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { GridDataResult, GridModule, PageChangeEvent } from '@progress/kendo-angular-grid';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { GstApprovalService } from '../../../service/gst-approval.service';
import { UserService } from '../../../service/user.service';
import { BlobService } from '../../../service/blob.service';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { HttpResponse } from '@angular/common/http';
import { InvoiceReportService } from '../../../service/invoice-report.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-invoice-report',
  standalone: true,
  imports: [ComboBoxComponent, IndicatorsModule, AlertPopupComponent, GridModule, DropdownListComponent, ReactiveFormsModule, FormsModule, CommonModule],
  templateUrl: './invoice-report.component.html',
  styleUrl: './invoice-report.component.css'
})
export class InvoiceReportComponent {
  invoiceReportDropdown: any[] = [];
  selectedInvoiceReport = { text: 'Select Report', value: '' };
  selectMonthYear: any[] = [];
  selectMonthYears = { text: 'Select Month/Year', value: '' };
  ClientGstDropdownData: any[] = [];
  selectedClient = { text: 'Select Client', value: '' };
  selectInvoiceReport: any[] = [{ value: "ALL", text: "ALL" },
  { value: "ROT", text: "TL Charges" },
  { value: "ROG", text: "Government Fee" },
  { value: "ROA", text: "Associate Fee" },
  { value: "RRC", text: "Recurring invoice" },
  { value: "ROB", text: "OnBoard invoice" },
  { value: "RMB", text: "Reimbursement invoice" }];
  selectInvoiceReportTypes = { text: 'Select Invoice Type', value: '' };
  InvoiceReport: any;
  rowData: any;
  loading: boolean = false;
  public showGrid: boolean = false;
  originalData: any;
  GridLoading: boolean = false;
  Skip = 0;
  public buttonCount = 5;
  public PageSize: number = 10;
  public sizes = [{ text: 'All', value: Infinity }, 5, 10, 20];
  public gridData: GridDataResult = { data: [], total: 0 };
  public OriginalData: GridDataResult = { data: [], total: 0 };

  selectedmonthType!: {
    name: string; // 'Jan-2024'
    id: number; // Numeric month (e.g., 1 for January)
  };
  selectedMonth!: any;
  selectedYear!: any;
  fieldType: any;

  monthDropdown: any[] = [];
  selectedMonthYear: any;

  isActive: boolean = false;
  showState: boolean = false;
  invoiceTypes: any;
  clientID!: string;



  constructor(private user: UserService, private blobService: BlobService, private InvoiceReportService: InvoiceReportService) { }
  ngOnInit(): void {
    this.GetCodeDescription();
    this.getClientsMaster();


    this.generateMonthYearDropdown();

  }

  generateMonthYearDropdown() {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const startYear = 2020; // Starting year for the dropdown
    const endYear = 2025;   // Ending year for the dropdown

    for (let year = startYear; year <= endYear; year++) {
      months.forEach((month, index) => {
        this.monthDropdown.push({
          id: `${year}-${index + 1}`,  // Example id: '2023-11'
          name: `${month}-${year}`     // Example name: 'Nov-2023'
        });
      });
    }
  }

  onDropdownMonthYearValueChange(data: any) {
    this.selectedMonthYear = data;

    // Assuming data.name is in the format "MMM-YYYY" (e.g., "Nov-2023")
    const [monthAbbreviation, selectedYear] = data.name.split('-');

    // Map month abbreviation to its numeric value
    const monthMap: { [key: string]: number } = {
      Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6,
      Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12
    };


    const selectedMonth = monthMap[monthAbbreviation];

    console.log('Selected numeric month:', selectedMonth);
    console.log('Selected year:', selectedYear);


    this.selectedMonth = selectedMonth;
    this.selectedYear = +selectedYear;
  }


  onDropdownValueChange(data: any) {


    console.log('data', data)
    this.InvoiceReport = data?.value;
    console.log('client', this.InvoiceReport)



  }

  onDropdownClientGstValueChange(data: any) {


    console.log('data', data)
    this.clientID = data?.value;
    console.log('client', this.clientID)



  }

  onDropdownInvoiceReportValueChange(data: any) {


    console.log('invoiceTypes', data)
    this.invoiceTypes = data?.value;
    console.log('invoiceTypes', this.invoiceTypes)


  }





  GetCodeDescription() {

    this.InvoiceReportService.GetCodeDescriptions('InvoiceRpt').subscribe({
      next: (response) => {
        const data = response.body;
        this.invoiceReportDropdown = [
          { text: 'Select Report', value: '' },
          ...data.map((item: any) => ({
            text: item.text,
            value: item.value
          }))
        ];
      },
      error: (error) => {
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }

  getClientsMaster() {
    this.InvoiceReportService.getClientsGstMaster().subscribe({
      next: (response) => {
        const data = response.body;
        this.ClientGstDropdownData = [
          { text: 'Select Client', value: '' },
          ...data.map((item: any) => ({
            text: item.text,
            value: item.value
          }))
        ];
      },
      error: (error) => {
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }


  ApplyFilter() {
    console.log('InvoiceReport value:', this.InvoiceReport);
    if (!this.InvoiceReport) {
      console.error('No selection made from the dropdown', this.InvoiceReport);
      return;
    }

    if (this.InvoiceReport === 'R') {
      console.log('Fetching Invoice Report');
      this.GetInvoiceReport();
    } else if (this.InvoiceReport === 'D') {
      console.log('Fetching Invoice Detailed Report');
      this.GetInvoiceDetailedReport();
    } else if (this.InvoiceReport === 'CN') {
      console.log('Fetching Credit Note Detailed Report');
      this.GetCreditNoteDetailedReport();
    } else {
      console.error('Invalid selection', this.InvoiceReport);
    }
  }
  clearFilter() {

    this.selectedInvoiceReport = { text: '', value: '' };
    this.selectedClient = { text: '', value: '' };
    this.selectedMonthYear = null;
    this.gridData = { data: [], total: 0 };
    // this.selectInvoiceReportTypes = { text: '', value: '' };


  }

  GetInvoiceReport() {
    let payload = {
      clientId: this.clientID ? this.clientID : 'all',

      invoiceType: String(this.invoiceTypes),
      monthID: String(this.selectedMonth),
      year: String(this.selectedYear),
    };

    console.log('Payload:', payload);

    this.loading = true;
    this.InvoiceReportService.GetInvoiceReports(payload).subscribe({
      next: (response) => {
        let data = response.body;
        this.gridData = data;


        this.loading = false;
      },
      error: (error) => {
        console.error('Error fetching Master Grid data', error);
        this.loading = false;
      }
    });
  }

  GetInvoiceDetailedReport() {

    let payload = {
      clientId: this.clientID ? this.clientID : 'all',

      invoiceType: String(this.invoiceTypes),
      monthID: String(this.selectedMonth),
      year: String(this.selectedYear),
    };

    console.log('Payload:', payload);

    this.loading = true;
    this.InvoiceReportService.GetInvoiceDetailedReports(payload).subscribe({
      next: (response) => {
        let data = response.body;
        this.gridData = data;


        this.loading = false;
      },
      error: (error) => {
        console.error('Error fetching Master Grid data', error);
        this.loading = false;
      }
    });
  }
  GetCreditNoteDetailedReport() {

    let payload = {
      clientId: this.clientID ? this.clientID : 'all',

      invoiceType: String(this.invoiceTypes),
      monthID: String(this.selectedMonth),
      year: String(this.selectedYear),
    };

    console.log('Payload:', payload);

    this.loading = true;
    this.InvoiceReportService.GetCreditNoteDetailedReports(payload).subscribe({
      next: (response) => {
        let data = response.body;
        this.gridData = data;


        this.loading = false;
      },
      error: (error) => {
        console.error('Error fetching Master Grid data', error);
        this.loading = false;
      }
    });
  }

  exportToExcel() {
    console.log('InvoiceReport value:', this.InvoiceReport);
    if (!this.InvoiceReport) {
      console.error('No selection made from the dropdown', this.InvoiceReport);
      return;
    }

    if (this.InvoiceReport === 'R') {
      console.log('Fetching Invoice Report');
      this.GetInvoiceReportExcels();
    } else if (this.InvoiceReport === 'D') {
      console.log('Fetching Invoice Detailed Report');
      this.GetInvoiceDetailedReportExcels();
    } else if (this.InvoiceReport === 'CN') {
      console.log('Fetching Credit Note Detailed Report');
      this.GetCreditNoteDetailedReportExcels();
    } else {
      console.error('Invalid selection', this.InvoiceReport);
    }
  }

  // GetInvoiceReportExcels(): void {

  //   let payload = {
  //     clientId: this.clientID ? this.clientID : 'all',

  //     invoiceType: String(this.invoiceTypes),
  //     monthID: String(this.selectedMonth),
  //     year: String(this.selectedYear),
  //   };

  //   // this.loading = true
  //   this.InvoiceReportService.GetInvoiceReportExcel(payload).subscribe(
  //     (response: any) => {
  //       console.log('responseexcell', response);
  //       const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(response.body);

  //       // Create a new workbook and append the worksheet
  //       const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  //       XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  //       // Generate a file and trigger a download
  //       XLSX.writeFile(workbook, 'Report.xlsx');
  //       // const blobLink = response.body.filePath;
  //       // const blobName = this.blobService.extractBlobName(blobLink);
  //       // setTimeout(() => {
  //       //   this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
  //       //     const sasToken = tokenResponse.result;
  //       //     this.blobService.downloadFile(blobName, sasToken);
  //       //     this.loading = false;
  //       //   });
  //       // }, 5000);
  //       // this.loading = false;
  //     },
  //     (error) => {
  //       console.error('Error fetching blob link:', error);
  //       // this.loading = false;
  //     });
  // }
  // GetInvoiceDetailedReportExcels(): void {

  //   let payload = {
  //     clientId: this.clientID ? this.clientID : 'all',

  //     invoiceType: String(this.invoiceTypes),
  //     monthID: String(this.selectedMonth),
  //     year: String(this.selectedYear),
  //   };

  //   this.loading = true
  //   this.InvoiceReportService.GetInvoiceDetailedReportExcel(payload).subscribe(
  //     (response: any) => {
  //       console.log('responseexcell', response);
  //       const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(response.body);

  //       // Create a new workbook and append the worksheet
  //       const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  //       XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  //       // Generate a file and trigger a download
  //       XLSX.writeFile(workbook, 'Report.xlsx');
  //       // const blobLink = response.body.filePath;
  //       // const blobName = this.blobService.extractBlobName(blobLink);
  //       // setTimeout(() => {
  //       //   this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
  //       //     const sasToken = tokenResponse.result;
  //       //     this.blobService.downloadFile(blobName, sasToken);
  //       //     this.loading = false;
  //       //   });
  //       // }, 5000);
  //       this.loading = false;
  //     },
  //     (error) => {
  //       console.error('Error fetching blob link:', error);
  //       this.loading = false;
  //     });
  // }
  // GetCreditNoteDetailedReportExcels(): void {

  //   let payload = {
  //     clientId: this.clientID ? this.clientID : 'all',

  //     invoiceType: String(this.invoiceTypes),
  //     monthID: String(this.selectedMonth),
  //     year: String(this.selectedYear),
  //   };

  //   this.loading = true
  //   this.InvoiceReportService.GetCreditNoteDetailedReportExcel(payload).subscribe(
  //     (response: any) => {
  //       console.log('responseexcell', response);
  //       const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(response.body);

  //       // Create a new workbook and append the worksheet
  //       const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  //       XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  //       // Generate a file and trigger a download
  //       XLSX.writeFile(workbook, 'Report.xlsx');
  //       // const blobLink = response.body.filePath;
  //       // const blobName = this.blobService.extractBlobName(blobLink);
  //       // setTimeout(() => {
  //       //   this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
  //       //     const sasToken = tokenResponse.result;
  //       //     this.blobService.downloadFile(blobName, sasToken);
  //       //     this.loading = false;
  //       //   });
  //       // }, 5000);
  //       this.loading = false;
  //     },
  //     (error) => {
  //       console.error('Error fetching blob link:', error);
  //       this.loading = false;
  //     });
  // }
  public pageChange(event: PageChangeEvent): void {
    this.GridLoading = true;
    console.log('Page Change Event Received:', event);
    this.Skip = event.skip;
    if (event.take == Infinity) {
      this.PageSize = this.OriginalData.total
    } else {
      this.PageSize = event.take;
    }
    this.gridData = {
      data: this.OriginalData.data.slice(this.Skip, this.Skip + this.PageSize),
      total: this.OriginalData.total
    };
    setTimeout(() => {
      this.GridLoading = false;
    }, 2000);
  }

  GetInvoiceReportExcels(): void {

    let payload = {
      clientId: this.clientID ? this.clientID : 'all',

      invoiceType: String(this.invoiceTypes),
      monthID: String(this.selectedMonth),
      year: String(this.selectedYear),
    };
    this.loading = true
    this.InvoiceReportService.GetInvoiceReportExcel(payload).subscribe(
      (response: any) => {
        console.log('responseexcell', response);

        const blobLink = response.body.filePath;
        const blobName = this.blobService.extractBlobName(blobLink);

        setTimeout(() => {
          this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
            const sasToken = tokenResponse.result;
        this.blobService.downloadFile(blobName, sasToken);
        this.loading = false;
          });
        }, 5000);
      },
      (error) => {
        console.error('Error fetching blob link:', error);
        this.loading = false;
      });



  }
  GetInvoiceDetailedReportExcels(): void {

    let payload = {
      clientId: this.clientID ? this.clientID : 'all',

      invoiceType: String(this.invoiceTypes),
      monthID: String(this.selectedMonth),
      year: String(this.selectedYear),
    };
    this.loading = true
    this.InvoiceReportService.GetInvoiceDetailedReportExcel(payload).subscribe(
      (response: any) => {
        console.log('responseexcell', response);

        const blobLink = response.body.filePath;
        const blobName = this.blobService.extractBlobName(blobLink);
        // setTimeout(() => {
        //   this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
        //     const sasToken = tokenResponse.result;
        this.blobService.downloadBlobFile(blobName, response.body.sasToken);
        this.loading = false;
        //   });
        // }, 5000);
      },
      (error) => {
        console.error('Error fetching blob link:', error);
        this.loading = false;
      });
  }
  GetCreditNoteDetailedReportExcels(): void {

    let payload = {
      clientId: this.clientID ? this.clientID : 'all',

      invoiceType: String(this.invoiceTypes),
      monthID: String(this.selectedMonth),
      year: String(this.selectedYear),
    };
    this.loading = true
    this.InvoiceReportService.GetCreditNoteDetailedReportExcel(payload).subscribe(
      (response: any) => {
        console.log('responseexcell', response);

        const blobLink = response.body.filePath;
        const blobName = this.blobService.extractBlobName(blobLink);
        // setTimeout(() => {
        //   this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
        //     const sasToken = tokenResponse.result;
        this.blobService.downloadBlobFile(blobName, response.body.sasToken);
        this.loading = false;
        //   });
        // }, 5000);
      },
      (error) => {
        console.error('Error fetching blob link:', error);
        this.loading = false;
      });
  }
}
