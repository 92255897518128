import { Component, EventEmitter, Output } from '@angular/core';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { NgIf } from '@angular/common';
import { LabourDepartmentAddressService } from '../../../service/labour-department-address.service';
import { BlobService } from '../../../service/blob.service';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-labour-department-upload-popup',
  standalone: true,
  imports: [DialogModule,AlertPopupComponent,NgIf,IndicatorsModule],
  templateUrl: './labour-department-upload-popup.component.html',
  styleUrl: './labour-department-upload-popup.component.css'
})
export class LabourDepartmentUploadPopupComponent {
  loading=false;
  file: File | null = null;
  dropzoneMessage: string = "Drag your files here or ";
  @Output() close = new EventEmitter<void>();
  selectedFile?: File;
  alertData = { alertMessage: '', alertVisible: '' };

  constructor(private LabourDepartmentService:LabourDepartmentAddressService, private blobService: BlobService){}
  public onFileSelect(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
        const file = input.files[0];
        if (this.validateFile(file)) {
            this.selectedFile = file;
        } else {
            this.alertData = { alertMessage: 'Only xlsx files are allowed.', alertVisible: 'error' };
        }
    }
}
  uploadFiles(){
    if (this.selectedFile) {
      this.loading=true;
      this.LabourDepartmentService.uploadFile(this.selectedFile, 9,7).subscribe({
        next: (response) => {
          if(response.body.status=='SheetNameError'){
            this.loading=false;
            this.selectedFile=undefined;
            this.alertData = { alertMessage: 'Invalid template', alertVisible: 'error' };
          } else if(response.body.status=='Please Upload File with some data'){
            this.loading=false;
            this.selectedFile=undefined;
            this.alertData = { alertMessage: 'Empty File!', alertVisible: 'error' };
          } else if(response.body.status=='success'){
            this.loading=false;
            this.alertData = { alertMessage: 'File Upload Successfully', alertVisible: 'success' };
          } else if(response.body.status=='empty sheet') {
            this.loading=false;
            this.alertData = { alertMessage: 'Invalid template file!', alertVisible: 'error'};
            this.selectedFile=undefined;
          }else if(response.body.status=='error'){
            // debugger
            // this.selectedFile=undefined;
            // const data = response.body;
            // const blobLink = data.filePath;
            // const blobName = this.blobService.extractBlobName(blobLink);
            // this.alertData = {alertMessage: 'Error while uploading, please check error file',alertVisible: 'error'};
            // setTimeout(() => {
            //   this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
            //     debugger;
            //     const sasToken = tokenResponse.result;
            //     this.blobService.downloadFile(blobName, sasToken);
            //     this.loading=false;
            //   });
            // }, 5000); 
            // this.loading=false;
            this.selectedFile=undefined;
            this.downloadErrorFile(response.body.filePath, response?.body.sasToken);
            this.alertData = {alertMessage: 'Error while uploading, please check error file',alertVisible: 'error'};
          }      
        },
        error: (error) => {
          this.selectedFile=undefined;
          this.loading=false;
          this.alertData = { alertMessage: "Invalid template", alertVisible: 'error' };
        }
      });
    } else {
      this.alertData = { alertMessage: 'No File Selected', alertVisible: 'error' }; 
    }
  }
  downloadErrorFile(FilePath: string, sasToken: string) {
    const blobUrl = `${FilePath}?${sasToken}`;
    const a = document.createElement('a');
    a.href = blobUrl;
    const filename = FilePath.split('/').pop() || 'downloaded_file';
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drop your file here or ";
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drag your files here or ";
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      if (this.validateFile(file)) {
        this.file = file;
        this.selectedFile = this.file;
      } else {
        this.alertData = { alertMessage: 'Please upload only xlsx file.', alertVisible: 'error' };
      }
      event.dataTransfer.clearData();
    }
  }
  private validateFile(file: File): boolean {
     const allowedExtensions = ['xls', 'xlsx', 'csv'];
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    return fileExtension ? allowedExtensions.includes(fileExtension) : false;
  }
  public closePopup(): void {
    this.close.emit();
  }
  closeAlert() {
    if(this.alertData.alertVisible=='success'){
      this.closePopup();
    }
    this.alertData = { alertMessage: '', alertVisible: '' };

  }
}
