import { Injectable } from '@angular/core';


import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CreditNoteService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {

  }
  getInvoiceType(): Observable<HttpResponse<any>>{
    const url = `${this.apiUrl}/api/Invoice/GetCodeDescription?Type=InvoiceType`;
    return this.http.get(url,{ observe: 'response' });
  }
  getClientsGstMaster(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Invoice/GetClientsMaster`;
    // https://localhost:7111/api/Invoice/GetClientsMaster
    return this.http.get(url, {observe:'response'});
  }
 
}
