
import { NgFor, NgIf } from '@angular/common';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { PoDetailsService } from '../../../service/po-details.service';
import { EcrMasterAddEditorPopupComponent } from '../../popups/ecr-master-add-editor-popup/ecr-master-add-editor-popup.component';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { GridModule } from '@progress/kendo-angular-grid';
import { FormsModule } from '@angular/forms';
import { KendoDatepickerComponent } from '../../../components/kendo-datepicker/kendo-datepicker.component';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { HttpResponse } from '@angular/common/http';
import { UserService } from '../../../service/user.service';


@Component({
  selector: 'app-add-edit-podetails-popup',
  standalone: true,
  imports: [AlertPopupComponent, KendoDatepickerComponent, ReactiveFormsModule, FormsModule, IndicatorsModule, DropdownListComponent, NgIf, NgFor, EcrMasterAddEditorPopupComponent, DialogModule, GridModule],
  templateUrl: './add-edit-podetails-popup.component.html',
  styleUrl: './add-edit-podetails-popup.component.css'
})
export class AddEditPODetailsPopupComponent {
  @Output() close = new EventEmitter<void>();
  @Input() data: any;
  @Input() rowData: any;
  loading: boolean | undefined;
  showSave = false;
  noRecords = false;
  alertData = { alertMessage: '', alertVisible: '' };
  GetClientpODetail: any;
  public poDate: Date | null = null; // PO Date
  public poNumber: number | null = null; // PO Number
  public poAmount: number | null = null; // PO Amount
  public fromDate: Date | null = null; // From Date
  public toDate: Date | null = null; 


  constructor(private user: UserService, private PoDetailsService: PoDetailsService) { }

  ClientDropdownData: any[] = [];
  selectedClient = { text: 'Select Client', value: '' };
  clientID!: string;
  consolidatepoNumber: number | null = null;
  consolidatepoAmount: number | null = null;
  dateOfBirth: Date | null = null;
  consolidateDOB: Date | null = null;
  consolidatefrom :Date | null = null;
  consolidateto :Date | null = null;
  public placeholder: string = 'DD-MM-YYYY';
  todayDate: Date = new Date();
  public showValidationErrors = false;
  selectedRadioValue: any
  datefields: any
  // ClientId: string = '';
  id!: string;
  consolidate = {
    pO_Type: 'Consolidated',
    esic_App: null,
    pO_ConsolidatedPONumber: null,
    pO_ConsolidatedAmount: null,
    fromDate: null,
    toDate: null
  };
  purchaseOrderDate!: Date | null;
  purchaseOrderNumber!: number | null;
  purchaseOrderAmount!: number | null;
  effectiveFromDate!: Date | null;
  effectiveToDate!: Date | null

  selectedOptionValue!: string; // Assuming this is defined elsewhere
  currentDate: Date = new Date(); // Example initialization
  displayValidationErrors!: boolean; // Assuming this is defined elsewhere
  gridData: any;
  recurringPODate!: Date | null;
  liasoningPODate!: Date | null;
  recurringFromDate!: Date | null;
  liasoningFromDate!: Date | null;
  recurringToDate!: Date | null;
  liasoningToDate!: Date | null;

  // For number inputs
  recurringPONumber!: number;
  liasoningPONumber!: number;
  recurringPOAmount!: number;
  liasoningPOAmount!: number;
  // spiltpoNumbers: string[] = [];
  spiltpoNumbers: string[] = ["", ""];
  spiltpoAmount = []
  splitPODate: (Date | null)[] = [null, null];
  splitfrom: (Date | null)[] = [null, null];
  splitTo: (Date | null)[] = [null, null];
  splitvalue:any
  ngOnInit(): void {
    // this.ClientId = 'AVA159TSPL';
    this.getClientsMaster();
    debugger
    console.log('data',this.data)
    if (this.data.length != 0) {
      // this.foundItem = this.items.find(item => item.id === inputId);
      // this.selectedClient = this.data.clientId;
      this.id = this.data.clientId;
      this.clientID =this.id;
      // this.selectedClient = this.ClientDropdownData.find(item=>item.value ===this.data.clientId);
      this.GetClientPODetail(this.data.clientId);
    }



  }
  onDropdownValueChange(data: any) {

    // Update selectedAct with selected dropdown value and ensure both text and value are set
    this.rowData = [];
    this.noRecords =false;
    this.clientID = data?.value;
    console.log('client', this.clientID)
    this.GetClientPODetail(this.clientID);
    //  this.GetPOListDetail(this.clientID);


  }



  getClientsMaster() {
    this.PoDetailsService.getClientsMaster().subscribe({
      next: (response) => {
        const data = response.body;
        this.ClientDropdownData = [
          { text: 'Select Client', value: '' },
          ...data.map((item: any) => ({
            text: item.text,
            value: item.value
          }))
        ];
        debugger
        if (this.id) {
          this.selectedClient = this.ClientDropdownData.find(item => { if (item.value === this.id) { return item } });
        }
      },
      error: (error) => {
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }

  GetClientPODetail(clientID: any) {

    let payload = {
      PO_Id: 0,
      ClientId: clientID,
    };

    this.PoDetailsService.GetClientPODetail(payload).subscribe({
      next: (response) => {
        debugger
        this.rowData = [];
        console.log("API response:", response.body);
        const data = response.body;
        this.datefields = data;
       
        data.pO_ConsolidatedDateFormatted = data.pO_ConsolidatedDate ?  new Date(data.pO_ConsolidatedDate) : null;
        data.pO_ConsolidatedFromFormated = data.pO_ConsolidatedFrom ? new Date(data.pO_ConsolidatedFrom) : null
        data.pO_ConsolidatedToFormated = data.pO_ConsolidatedTo ? new Date(data.pO_ConsolidatedTo) : null
        /////
        data.pO_RecurringDateFormatted = data.pO_RecurringDate ? new Date(data.pO_RecurringDate) : null
        data.pO_LiasoningDateFormatted = data.pO_LiasoningDate ? new Date(data.pO_LiasoningDate) :null

        data.pO_RecurringFromFormated = data.pO_RecurringFrom ? new Date(data.pO_RecurringFrom) : null
        data.pO_LiasoningFromFormated = data.pO_LiasoningFrom ? new Date(data.pO_LiasoningFrom) : null

        data.pO_RecurringToFormated = data.pO_RecurringTo ? new Date(data.pO_RecurringTo) : null
        data.pO_LiasoningToFormated = data.pO_LiasoningTo ? new Date(data.pO_LiasoningTo) : null



        this.rowData = [data];
        console.log('datapo', data.poType)
        console.log('datapooooo', this.rowData[0].poType)
        if(data.pO_Id == null){
          console.log('check')
         this.selectedRadioValue = 'C'
         document.getElementById('rdDow')?.click();
         console.log('selectedRadioValue',this.selectedRadioValue)
        }
        if (data.poType == 'S') {
          console.log('seee', this.rowData)
          document.getElementById('rdFai')?.click();
          this.rowData = [
            {
              poType: 'Recurring',
              pO_RecurringDate: new Date(data.pO_RecurringDate),
              pO_RecurringPONumber: data.pO_RecurringPONumber,
              pO_RecurringAmount: data.pO_RecurringAmount,
              pO_RecurringFrom: new Date(data.pO_RecurringFrom),
              pO_RecurringTo: new Date(data.pO_RecurringTo)
            },
            {
              poType: 'Liasoning',
              pO_RecurringDate: new Date(data.pO_LiasoningDate),
              pO_RecurringPONumber: data.pO_LiasoningPONumber,
              pO_RecurringAmount: data.pO_LiasoningAmount,
              pO_RecurringFrom: new Date(data.pO_LiasoningFrom),
              pO_RecurringTo: new Date(data.pO_LiasoningTo)
            }
          ];

          console.log('seeedsgfdsgd', this.rowData)
          this.splitvalue = {
            pO_Id : data.pO_Id,
            poType : data.poType,
            pO_ConsolidatedPONumber : data.pO_ConsolidatedPONumber
          }
          this.splitPODate = this.rowData.map((row:any) => row.pO_RecurringDate);
          this.spiltpoNumbers = this.rowData.map((row:any) => row.pO_RecurringPONumber);
          this.spiltpoAmount = this.rowData.map((row:any) => row.pO_RecurringAmount);
          this.splitfrom = this.rowData.map((row:any) => row.pO_RecurringFrom);
          this.splitTo = this.rowData.map((row:any) => row.pO_RecurringTo);
        } else if (data.poType == 'C') {
          document.getElementById('rdDow')?.click();
        }
        // this.rowData = data.map((item:any) => {
        //   return {
        //     ...item,
        //     // poDate: item.poDate !== item.poDate ? this.formatDate(item.poDate) : null
        //   };
        // });
        console.log("podetails", this.rowData);
        this.noRecords= Object.values(this.rowData[0]).every(value => value === null)

      },
      error: (error) => {
        this.rowData = [];
        console.error("Error fetching PO details", error);
      },
    });
  }


  getPONumber(rowIndex: number): number | null {
    return this.rowData[rowIndex]?.poNumber || null; 
  }


  setPONumber(rowIndex: number, value: number | null) {
    if (rowIndex >= 0 && rowIndex < this.rowData.length) {
      this.rowData[rowIndex].poNumber = value; 
    }
  }
  


  formatDates(dateString: string): Date | null {
    const date = new Date(dateString);

    // Check if date is valid
    if (isNaN(date.getTime())) {
      return null;  // or any fallback date you want
    }

    return date;
  }

  closePopup() {
    this.data = undefined;
    this.close.emit();
  }
  onDateChange(value: Date, field: string, dataItem: any) {

    console.log('valueddsv', value)
    console.log('valueddsv', typeof (value))
    console.log('dataItem', dataItem)
    console.log('spiltpoAmount', this.spiltpoAmount)
    console.log('spiltpoNumbers', this.spiltpoNumbers)
    console.log('dataformat', typeof (dataItem.pO_ConsolidatedDate))
    const date = new Date(value);
    if (field === 'poDate') {
      this.dateOfBirth = date;
    }
   else if (field === 'consolidateDOB') {
      this.purchaseOrderDate = date;
  } else if (field === 'consolidatefrom') {
      this.effectiveFromDate = date;
  } else if (field === 'consolidateto') {
      this.effectiveToDate = date;
  }
 else if (field === 'recurringPODate') {
    this.recurringPODate = date;
    console.log('Recurring PO Date:', this.recurringPODate);
} else if (field === 'liasoningPODate') {
    this.liasoningPODate = date;
    console.log('Liasoning PO Date:', this.liasoningPODate);
} else if (field === 'recurringFromDate') {
    this.recurringFromDate = date;
    console.log('Recurring From Date:', this.recurringFromDate);
} else if (field === 'liasoningFromDate') {
    this.liasoningFromDate = date;
    console.log('Liasoning From Date:', this.liasoningFromDate);
} else if (field === 'recurringToDate') {
    this.recurringToDate = date;
    console.log('Recurring To Date:', this.recurringToDate);
} else if (field === 'liasoningToDate') {
    this.liasoningToDate = date;
    console.log('Liasoning To Date:', this.liasoningToDate);
}

  }

  onMeasurementSystemChange(event: Event) {
    this.noRecords = false;
    this.selectedRadioValue = (event.target as HTMLInputElement).value;
    console.log('Selected Measurement System:', this.selectedRadioValue);
    if(this.selectedRadioValue == 'C'){
      this.spiltpoAmount = []
      this.spiltpoNumbers = []
      this.recurringFromDate = null;
      this.liasoningFromDate = null
      this.recurringToDate = null
      this.liasoningToDate = null
      this.purchaseOrderDate = null
      this.consolidatepoAmount = null
      this.consolidatepoNumber = null
      this.effectiveFromDate = null
      this.effectiveToDate = null
    }else if (this.selectedRadioValue == 'S'){
      this.purchaseOrderDate = null
      this.consolidatepoAmount = null
      this.consolidatepoNumber = null
      this.effectiveFromDate = null
      this.effectiveToDate = null

      
    }
  }
  isRowDataEmpty(): boolean {
    // Check if rowData is an empty object
    // return !this.rowData || Object.keys(this.rowData).length === 0;

    return !this.rowData || Object.keys(this.rowData).length === 0 || 
           this.rowData.some((item:any) => !item.poType);
  }

  formatDate = (date: any) => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  getFormattedDate(formattedDate: string | number | Date | undefined, fallbackDate: Date | null): string | null {
    return formattedDate ? this.formatDate(formattedDate) : (fallbackDate ? this.formatDate(fallbackDate) : null);
}
getFormattedAmount(amount: number | null, fallback: number | null): number | null {
  return amount ?? fallback ?? null;
}

getFormattedPONumber(poNumber: string | null, fallback: string | null): string | null {
  console.log(fallback);
  return poNumber ?? fallback ?? null;
}
  OnSubmitPoDetails() {
    this.loading = true;
    let data;
    // const recurringData = this.rowData[0]; // Get Recurring data
    // const liasoningData = this.rowData[1];
    if(!this.rowData){
      return
    }
    if(this.rowData?.length == 2){
      this.rowData = [{}]
      data =  this.splitvalue
      console.log('index',data)
      
     
      console.log( this.splitPODate )
      console.log( this.spiltpoNumbers )
      console.log( this.spiltpoAmount )
      console.log( this.splitfrom )
      console.log( this.splitTo )
    }else{
       data = this.rowData[0]
      console.log("data", data)
    }
    
    debugger
    console.log("rerfdata", this.rowData)
    

   
    const payload = {
      pO_Id: data?.pO_Id || 0,
      pO_ClientId: this.clientID,
      poType: data?.poType || this.selectedRadioValue, // Use poType from data or fallbac
      // pO_RecurringDate:this.getFormattedDate(data.pO_RecurringDateFormatted, this.recurringPODate),
      pO_RecurringDate: this.splitPODate ? this.formatDate(this.splitPODate[0] ) : this.getFormattedDate(data.pO_RecurringDateFormatted, this.recurringPODate),

      
      pO_LiasoningDate: this.splitPODate ? this.formatDate(this.splitPODate[1] ) : this.getFormattedDate(data.pO_LiasoningDateFormatted, this.liasoningPODate),
      pO_ConsolidatedDate: this.getFormattedDate(data.pO_ConsolidatedDateFormatted, this.purchaseOrderDate),
      pO_ConsolidatedAmount: this.getFormattedAmount(data.pO_ConsolidatedAmount ?? null, this.consolidatepoAmount),
      pO_RecurringAmount:  this.spiltpoAmount ?  this.spiltpoAmount[0]: this.getFormattedAmount(data.pO_RecurringAmount ?? null, this.spiltpoAmount[0]),
      pO_LiasoningAmount:  this.spiltpoAmount ?  this.spiltpoAmount[1]:this.getFormattedAmount(data.pO_LiasoningAmount ?? null, this.spiltpoAmount[1]),      
      pO_RecurringPONumber: this.spiltpoNumbers ? `${this.spiltpoNumbers[0]}` :this.getFormattedPONumber(data.pO_RecurringPONumber ?? null, `${this.spiltpoNumbers[0]}`),
      pO_LiasoningPONumber: this.spiltpoNumbers ? `${this.spiltpoNumbers[1]}` :this.getFormattedPONumber(data.pO_LiasoningPONumber ?? null, `${this.spiltpoNumbers[1]}`),
      pO_ConsolidatedPONumber: data.pO_ConsolidatedPONumber ? data.pO_ConsolidatedPONumber : this.consolidatepoNumber?.toString(), // Use PO Number or null
      pO_RecurringFrom: this.splitfrom ? this.formatDate(this.splitfrom [0]): this.getFormattedDate(data.pO_RecurringFromFormated, this.recurringFromDate),
      pO_LiasoningFrom:this.splitfrom ? this.formatDate(this.splitfrom [1]): this.getFormattedDate(data.pO_LiasoningFromFormated, this.liasoningFromDate),
      pO_ConsolidatedFrom: this.getFormattedDate(data.pO_ConsolidatedFromFormated, this.effectiveFromDate),
      pO_RecurringTo: this.splitTo ? this.formatDate(this.splitTo[0]) : this.getFormattedDate(data.pO_RecurringToFormated, this.recurringToDate),
      pO_LiasoningTo: this.splitTo ? this.formatDate(this.splitTo[1]) : this.getFormattedDate(data.pO_LiasoningToFormated, this.liasoningToDate),
      pO_ConsolidatedTo: this.getFormattedDate(data.pO_ConsolidatedToFormated, this.effectiveToDate),
      createdBy: this.user.getUserId().toString() || null,
      versionNumber: 0 
  };
    console.log('Payload to send:', payload);
  
    this.PoDetailsService.SubmitPODetail(payload).subscribe({
      next: (response: HttpResponse<any>) => {
        this.loading = false; 
        this.rowData = [{}]
        if (response.body.statusCode === 1) {
          // this.getClientsMaster();
          
          this.alertData = { alertMessage: 'Saved Successfully', alertVisible: 'success' };
        } else {
          this.alertData = { alertMessage: 'PO Submission Failed', alertVisible: 'error' };
        }
      },
      error: (error) => {
        // Handle error response
        console.error('Error during PO submission', error);
        this.loading = false;
        this.alertData = { alertMessage: 'Error submitting PO details', alertVisible: 'error' };
      }
    });

  }
  closeAlert() {
    this.alertData = { alertMessage: '', alertVisible: '' };
  }
  isDataInvalid(): boolean {
    debugger
    console.log("dsd")
    return !this.rowData || this.rowData.length === 0 || this.rowData.some((item:any) => !item.poType || this.rowData[0].pO_Id == null);
    
}

printValues() {
  console.log('Purchase Order Date:', this.purchaseOrderDate);
  console.log('Purchase Order Number:', this.purchaseOrderNumber);
  console.log('Purchase Order Amount:', this.purchaseOrderAmount);
  console.log('Effective From Date:', this.effectiveFromDate);
  console.log('Effective To Date:', this.effectiveToDate);
}
}