import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TabsService {

  private apiUrl = environment.apiUrl;
  private selectedMenuSubject = new BehaviorSubject<string>('ESIC Cards');
  selectedMenu$ = this.selectedMenuSubject.asObservable();

  setSelectedMenu(menu: string) {
    this.selectedMenuSubject.next(menu);
  }
  constructor(private http: HttpClient) { }

  getTabsDetails(ModuleName: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MenuHandler/GetTabsByModule?ModuleName=${ModuleName}`;
    return this.http.get(url, { observe: 'response' });
  }

}
