<kendo-dialog *ngIf="Data.alertVisible=='success'" (close)="closePopup()"  [width]="Width"
    class="custom-dialog  ">
    <div class="row col-12 dialog-content ">
        <div class="arrow-svg colpadding0">
            <div class="col-12 image colpadding0">
                <img class="alertTick" src="../assets/vectors/alertTick.svg">
            </div>
            <div class="col-12 image colpadding0">
                <img class="alertHurry" src="../assets/vectors/alertHurry.svg">
            </div>
        </div>
        <div class="col-md-12 f-label alert-msg">
            <h4 class="f-label" >{{Data.alertMessage}}</h4>
        </div>
    </div>
    <kendo-dialog-actions style="border: none; margin-bottom: 10px;">
        <div class="col-md-12 ok-btn colpadding0">
            <button (click)="closePopup()" class="figma-btn-green"><span>OK</span></button>
        </div>
    </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog *ngIf="Data.alertVisible=='error'" [width]="Width" 
    class="custom-dialog  ">
    <div class="row col-12 dialog-content ">
        <div class="arrow-svg colpadding0">
            <div class="col-12 image colpadding0" >
                <img clas="alertCross" src="../assets/vectors/alertCross.svg"
                    >
            </div>
            <div class="col-12 image colpadding0">
                <img class="alertOps" src="../assets/vectors/alertOps.svg">
            </div>
        </div>
        <div class="col-md-12 f-label alert-msg" >
            <h4 class="f-label">{{Data.alertMessage}}</h4>
        </div>
    </div>
    <kendo-dialog-actions style="border: none; margin-bottom: 10px;">
        <div class="col-md-12 f-label ok-btn colpadding0">
            <button kendoButton (click)="closePopup()" class="figma-btn-green"
                style="background-color: #F34C4D;"><span>OK</span></button>
        </div>
    </kendo-dialog-actions>
</kendo-dialog>


<kendo-dialog *ngIf="Data.alertVisible=='confirm'" [width]="Width" 
    class="custom-dialog  ">
    <div class="row col-12 dialog-content ">
        <div class="arrow-svg colpadding0">
            <div class="col-12 image colpadding0" >
               <label  style="color: black; font-weight: 600; margin-top: 1.8rem; font-size: 18px; ">Confirmation!</label>
            </div>
        </div>  
        <div class="col-md-12 f-label alert-msg" style="margin-top:10px">
            <h4 class="f-label" >{{Data.alertMessage}}</h4>
        </div>
    </div>
    <kendo-dialog-actions style="border: none; margin-bottom: 10px;"> 
        <div class="col-md-12  ok-btn-confirm colpadding0">
            <button  (click)="closePopup()" class="figma-btn-gray " ><span>Cancel</span></button>
            <button  (click)="onConfirm()" class="figma-btn-blue" ><span>OK</span></button>
        </div>
    </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog *ngIf="Data.alertVisible =='info'" [width]="Width" class="custom-dialog  ">
    <div class="row col-12 dialog-content ">
        <!-- <div class="arrow-svg">
            <div class="col-12" >
               <label  style="color: black; font-weight: 600; margin-top: 2rem; font-size: 18px; margin-left: -20px;">Confirmation!</label>
            </div>
        </div> -->
        <div class="col-md-12 f-label alert-msg colpadding0" style="margin-top: 2rem;">
            <h4 class="f-label " >{{Data.alertMessage}}</h4>
        </div>
    </div>

    <kendo-dialog-actions style="border: none; margin-bottom: 10px;">
        <div class="col-md-12 f-label ok-btn colpadding0">
            <button kendoButton (click)="closePopup()" class="figma-btn-green"
                style="background-color: #125AAB;"><span>OK</span></button>
        </div>
    </kendo-dialog-actions>
</kendo-dialog>