<kendo-dialog #dialogRef title="Paycode Details" (close)="closePopup()" [width]="1050" [height]="210" class="custom-dialog">
  <div class="row dialog-content" >
    <div class="col-md-12">
      <kendo-grid [data]="[rowData]" [scrollable]="'none'" class="grid custom-grid">
        <kendo-grid-column field="headername" title="Header Name">
          <ng-template kendoGridCellTemplate>
            <input type="text"     
                    class="textbox"
                   [(ngModel)]="headername"
                   name="headerName"
                   [ngClass]="{'is-invalid': !headername && isFormSubmitted}"
                 >
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="paycodeType" title="Paycode Type">
          <ng-template kendoGridCellTemplate>
            <kendo-dropdownlist
              [(ngModel)]="selectedPaycodeType"
              name="paycodeType"
              [ngClass]="{'is-invalid': !selectedPaycodeType.value && isFormSubmitted}"
              [data]="paycodeType"
              textField="text"
              valueField="value"
              class="textbox"
              (valueChange)="onPaycodeTypeChange()"
            >
            </kendo-dropdownlist>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="paycode" title="Paycode">
          <ng-template kendoGridCellTemplate>
            <kendo-dropdownlist
            [data]="paycode"
            textField="text"
            [ngClass]="{'is-invalid': !selectedPaycode.value && isFormSubmitted}"
            valueField="value"
            class="textbox"
            [(ngModel)]="selectedPaycode"
            [filterable]="true"
            style="width: 200px"
            (valueChange)="onValueChange($event)"
            (filterChange)="onFilterChange($event)">
          </kendo-dropdownlist>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="sequenceOrder" title="Sequence Order">
          <ng-template kendoGridCellTemplate>
            <input type="number" [(ngModel)]="sequenceOrder" name="sequenceOrder"   class="textbox" >
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="esic_App" title="ESI Applicable">
          <ng-template kendoGridCellTemplate>
            <input type="checkbox" [(ngModel)]="esic_App" name="esic_App" [disabled]="isStandardPaycodeType()" style="width: 100%;">
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="pF_App" title="PF Applicable">
          <ng-template kendoGridCellTemplate>
            <input type="checkbox" [(ngModel)]="pF_App" [disabled]="isStandardPaycodeType()" name="pF_App" style="width: 100%;">
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="pT_App" title="PT Applicable">
          <ng-template kendoGridCellTemplate>
            <input type="checkbox" [(ngModel)]="pT_App" [disabled]="isStandardPaycodeType()" name="pT_App" style="width: 100%;">
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="lwF_App" title="LWF Applicable">
          <ng-template kendoGridCellTemplate>
            <input type="checkbox" [(ngModel)]="lwF_App" [disabled]="isStandardPaycodeType()" name="lwF_App" style="width: 100%;">
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div> 
  </div>

  <kendo-dialog-actions style="border: none; ">
    <button kendoButton class="figma-btn-blue figma-popup" (click)="savePaycodeMapping()">Save</button>
  </kendo-dialog-actions>
</kendo-dialog>

<app-alert-popup *ngIf="alertData" [Width]="270" [Data]="alertData" (close)="closePopup()"></app-alert-popup>
