<!-- <kendo-treelist kendoTreeListExpandable [data]="rootData" [fetchChildren]="fetchChildren"
    [hasChildren]="hasChildren">
    <kendo-treelist-column [expandable]="true" title="branch">
        <ng-template kendoTreeListCellTemplate let-dataItem>
            {{ dataItem.branch }}
        </ng-template>
    </kendo-treelist-column>
    <kendo-treelist-column field="requiredForms" title="requiredForms" [width]="180">
    </kendo-treelist-column>
    <kendo-treelist-column field="shortForm" title="shortForm" [width]="180">
    </kendo-treelist-column>
</kendo-treelist> -->

<!-- //working solution -->

<!-- <kendo-treelist
  kendoTreeListExpandable
  [data]="rootData"
  [fetchChildren]="fetchChildren"
  [hasChildren]="hasChildren"
>
  <ng-container *ngFor="let column of parentColumns">
    <kendo-treelist-column
      [field]="column.field"
      [title]="column.title"
      [width]="column.width"
      [expandable]="column.expandable"
    >
      <ng-template kendoTreeListCellTemplate let-dataItem>
        {{ dataItem[column.field] }}
      </ng-template>
    </kendo-treelist-column>
  </ng-container>
  <kendo-treelist-column field="action" title="Action">
    <ng-template kendoTreeListCellTemplate let-dataItem>
      <img
        src="../assets/vectors/DownloadBlue.svg"
        alt="Download"
        (click)="downloadFile(dataItem)"
        style="cursor: pointer;"
      />
    </ng-template>
  </kendo-treelist-column>
</kendo-treelist> -->

<!-- <kendo-treelist
  kendoTreeListExpandable
  [data]="rootData"
  [fetchChildren]="fetchChildren"
  [hasChildren]="hasChildren"
>
  <ng-container *ngFor="let column of parentColumns">
    <kendo-treelist-column
      [field]="column.field"
      [title]="column.title"
      [width]="column.width"
      [expandable]="column.expandable"
    >
      <ng-template kendoTreeListCellTemplate let-dataItem>
        <ng-container *ngIf="column.field === 'action'">
          <img
            src="../assets/vectors/DownloadBlue.svg"
            alt="Download"
            (click)="checkEvent('Download',dataItem)"
            style="cursor: pointer; "
          />
        </ng-container>
        <ng-container *ngIf="column.field !== 'action'">
          {{ column.field === 'scheduledOn' ? formatDate(dataItem.scheduledOn) : dataItem[column.field] }}
        </ng-container>
      </ng-template>
    </kendo-treelist-column>
  </ng-container>
</kendo-treelist> -->




<!-- <kendo-treelist
    kendoTreeListExpandable
    [data]="rootData"
    [fetchChildren]="fetchChildren"
    [hasChildren]="hasChildren">

    <ng-container *ngFor="let column of parentColumns">
        <kendo-treelist-column
            [field]="column.field"
            [title]="column.title"
            [width]="column.width"
            [expandable]="column.expandable">

           
            <ng-template kendoTreeListCellTemplate let-dataItem>
                <ng-container *ngIf="column.field === 'scheduledOn'">
                    {{ formatDate(dataItem.scheduledOn) }}
                </ng-container>
                <ng-container *ngIf="column.field !== 'scheduledOn'">
                    {{ dataItem[column.field] }} 
                </ng-container>
                <ng-container *ngIf="column.field === 'action'" >
                  <div style="text-align: center;" [innerHTML]="column.cellTemplate(dataItem)" class="cell-content"></div>
                </ng-container>
            </ng-template>

        </kendo-treelist-column>
    </ng-container> -->

    <!-- <kendo-treelist-column field="action" title="Action" >
        <ng-template kendoTreeListCellTemplate let-dataItem>
         
            <div *ngIf="dataItem.isFilePresent === 1" >
                <img src="../assets/vectors/DownloadBlue.svg" alt="" (click)="downloadFile(dataItem)">
                <img src="../assets/vectors/EyeIcon.svg" alt="" (click)="checkEvent('view',dataItem)">
            </div>
            <div *ngIf="dataItem.isFilePresent === 0" >
                <img src="../assets/vectors/NoAccessDownload.svg" alt="">
                <img src="../assets/vectors/EyeIcon.svg" alt="">
            </div>
        </ng-template>
    </kendo-treelist-column> -->
<!-- </kendo-treelist> -->



<!-- <kendo-treelist
  kendoTreeListExpandable
  [data]="rootData"
  [fetchChildren]="fetchChildren"
  [hasChildren]="hasChildren"
>
  <ng-container *ngFor="let column of parentColumns">
    <kendo-treelist-column
      [field]="column.field"
      [title]="column.title"
      [width]="column.width"
      [expandable]="column.expandable"
    >
      <ng-template kendoTreeListCellTemplate let-dataItem>
        <ng-container *ngIf="column.field === 'scheduledOn'">
          {{ formatDate(dataItem.scheduledOn) }}
        </ng-container>
        <ng-container *ngIf="column.field !== 'scheduledOn'">
          {{ dataItem[column.field] }}
        </ng-container>
      </ng-template>
    </kendo-treelist-column>
  </ng-container>

  <kendo-treelist-column field="action" title="Action" [width]="100">
    <ng-template kendoTreeListCellTemplate let-dataItem>
      <div *ngIf="dataItem.isFilePresent === 1">
        <img src="../assets/vectors/DownloadBlue.svg" alt="" (click)="downloadFile(dataItem)">
        <img src="../assets/vectors/EyeIcon.svg" alt="" (click)="checkEvent('view', dataItem)">
      </div>
      <div *ngIf="dataItem.isFilePresent === 0">
        <img src="../assets/vectors/NoAccessDownload.svg" alt="">
        <img src="../assets/vectors/EyeIcon.svg" alt="">
      </div>
    </ng-template>
  </kendo-treelist-column>
</kendo-treelist> -->



<!-- <kendo-treelist class="custom-grid"
  kendoTreeListExpandable
  [data]="rootData"
  [fetchChildren]="fetchChildren"
  [hasChildren]="hasChildren" [resizable]="true"
>
  <ng-container *ngFor="let column of parentColumns">
    <kendo-treelist-column
      [field]="column.field"
      [title]="column.title"
      [width]="column.width"
      [expandable]="column.expandable" >
      <ng-template kendoTreeListCellTemplate let-dataItem>
        <ng-container *ngIf="column.field === 'action'" >
          <div style="text-align: center;" [innerHTML]="column.cellTemplate(dataItem)" class="cell-content"></div>
        </ng-container>
        <ng-container *ngIf="column.field !== 'action'" >
          <div class="cell-content" [title]="dataItem[column.field]">
            {{ column.field === 'scheduledOn' ? formatDate(dataItem.scheduledOn) : dataItem[column.field] }}
          </div>
        </ng-container>
      </ng-template>
    </kendo-treelist-column>
  </ng-container>
</kendo-treelist> -->


<!-- 
<kendo-treelist class="custom-grid"
  kendoTreeListExpandable
  [data]="rootData"
  [fetchChildren]="fetchChildren"
  [hasChildren]="hasChildren"
  [resizable]="true"
>
  <ng-container *ngFor="let column of parentColumns">
    <kendo-treelist-column
      [field]="column.field"
      [title]="column.title"
      [width]="column.width"
      [expandable]="column.expandable"  
    >
      <ng-template kendoTreeListCellTemplate let-dataItem>
        <ng-container *ngIf="column.field === 'action'" >
          <div style="text-align: center;" [innerHTML]="column.cellTemplate(dataItem)" class="cell-content"></div>
        </ng-container>
        <ng-container *ngIf="column.field !== 'action'" >
          <div  [title]="dataItem[column.field]">
            {{ column.field === 'scheduledOn' ? formatDate(dataItem.scheduledOn) : dataItem[column.field] }}
          </div>
        </ng-container>
      </ng-template>
    </kendo-treelist-column>
  </ng-container> -->

  <!-- <ng-container *ngFor="let column of parentColumns">
    <kendo-treelist-column
        [field]="column.field"
        [title]="column.title"
        [width]="column.width"
        [expandable]="column.expandable">

       
        <ng-template kendoTreeListCellTemplate let-dataItem>
            <ng-container *ngIf="column.field === 'scheduledOn'">
                {{ formatDate(dataItem.scheduledOn) }}
            </ng-container>
            <ng-container *ngIf="column.field !== 'scheduledOn'">
                {{ dataItem[column.field] }} 
            </ng-container>
        </ng-template>
    </kendo-treelist-column>
</ng-container> -->


<kendo-treelist
kendoTreeListExpandable
[data]="rootData"
[fetchChildren]="fetchChildren"
[hasChildren]="hasChildren"
[sortable]="true"
[resizable]="true" class="custom-grid"
[columnMenu]="true" filterable="menu"
>

<ng-container *ngFor="let column of parentColumns">
    <kendo-treelist-column
        [field]="column.field"
        [title]="column.title"
        [width]="column.width"
        [expandable]="column.expandable">

       
        <ng-template kendoTreeListCellTemplate let-dataItem>
            <ng-container *ngIf="column.field === 'scheduledOn'">
                {{ formatDate(dataItem.scheduledOn) }}
            </ng-container>
            <ng-container *ngIf="column.field !== 'scheduledOn'">
                {{ dataItem[column.field] }} 
            </ng-container>
            <ng-container *ngIf="column.field === 'eye'" >
              <div style="text-align: center;" [innerHTML]="column.cellTemplate(dataItem)" class="cell-content"></div>
            </ng-container>
            <ng-container *ngIf="column.field === 'action'" >
              <div style="text-align: center;" [innerHTML]="column.cellTemplate(dataItem)"   class="cell-content"></div>
            </ng-container>
        </ng-template>
       
    </kendo-treelist-column>
</ng-container>
<ng-template #noDataTemplate>
  <div class="no-data col-3">No records found.</div>
</ng-template>
</kendo-treelist>

