import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StatutoryService {

  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getcompliance(type: string): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/Common/Get_LC_CodeDescription?Type=${type}`;
    return this.http.get(url, { observe: 'response' });
  }

  getActs(type: string, frequency: string): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/StatutoryDocumentsContoller/Get_Labour_StatutoryActs?Type=${type}&frequency=${frequency}`;
    return this.http.get(url, { observe: 'response' });
  }

  // getRegisterGrid(payload:any): Observable<HttpResponse<any>> {
  //   const url = `${this.baseUrl}/api/StatutoryDocumentsContoller/LC_RLCS_ComplianceDocuments_StateWise_LoadOnDemand?userID=${payload.userID}&customerID=${payload.customerID}&monthId=${payload.monthId}&staOrInt=${payload.staOrInt}&staIntFlag=${payload.staIntFlag}&fy=${payload.fy}&profileID=${payload.profileID}&docType=${payload.docType}&challanType=${payload.challanType}&month=${payload.month}&year=${payload.year}&entityID=${payload.entityID}&actType=${payload.actType}&frequency=${payload.frequency}&branchId=${payload.branchId}&state=${payload.state}`;

  //   return this.http.post<any>(url, { observe: 'response' });
  // }

  getStateGrid(payload: any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/StatutoryDocumentsContoller/LC_RLCS_ComplianceDocuments_StateWise_LoadOnDemand`;


    let params = new HttpParams()
      .set('userID', payload.userID)
      .set('customerID', payload.customerID)
      .set('monthId', payload.monthId)
      .set('staOrInt', payload.staOrInt)
      .set('staIntFlag', payload.staIntFlag)
      .set('fy', payload.fy)
      .set('profileID', payload.profileID)
      .set('docType', payload.docType)
      .set('challanType', payload.challanType)
      .set('month', payload.month)
      .set('year', payload.year)
      .set('entityID', payload.entityID)
      .set('actType', payload.actType)
      .set('frequency', payload.frequency)
      .set('branchId', payload.branchId)
      .set('state', payload.state);


    return this.http.post<any>(url, payload, { observe: 'response', params });
  }

  // getReturnGrid(payload:any): Observable<HttpResponse<any>> {
  //   const url = `${this.baseUrl}/api/StatutoryDocumentsContoller/LC_RLCS_ComplianceDocuments_StateWise_LoadOnDemand?userID=${payload.userID}&customerID=${payload.customerID}&monthId=${payload.monthId}&staOrInt=${payload.staOrInt}&staIntFlag=${payload.staIntFlag}&fy=${payload.fy}&profileID=${payload.profileID}&docType=${payload.docType}&challanType=${payload.challanType}&month=${payload.month}&year=${payload.year}&entityID=${payload.entityID}&actType=${payload.actType}&frequency=${payload.frequency}&branchId=${payload.branchId}&state=${payload.state}`;

  //   return this.http.post<any>(url, { observe: 'response' });
  // }

  getBranchGrid(payload: any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/StatutoryDocumentsContoller/LC_GetAll_RLCS_ComplianceDocumentsLoadOnDemand`;


    let params = new HttpParams()
      .set('userID', payload.userID)
      .set('customerID', payload.customerID)
      .set('monthId', payload.monthId)
      .set('staOrInt', payload.staOrInt)
      .set('staIntFlag', payload.staIntFlag)
      .set('fy', payload.fy)
      .set('profileID', payload.profileID)
      .set('docType', payload.docType)
      .set('challanType', payload.challanType)
      .set('month', payload.month)
      .set('year', payload.year)
      .set('entityID', payload.entityID)
      .set('actType', payload.actType)
      .set('frequency', payload.frequency)
      .set('branchId', payload.branchId)
      .set('state', payload.state);

    // Making the POST request
    return this.http.post<any>(url, payload, { observe: 'response', params });
  }



  // getChallanGridStatutory(payload:any): Observable<HttpResponse<any>> {
  //   const url = `${this.baseUrl}/api/StatutoryDocumentsContoller/LC_RLCS_ComplianceDocuments_StateWise_LoadOnDemand?userID=${payload.userID}&customerID=${payload.customerID}&monthId=${payload.monthId}&staOrInt=${payload.staOrInt}&staIntFlag=${payload.staIntFlag}&fy=${payload.fy}&profileID=${payload.profileID}&docType=${payload.docType}&challanType=${payload.challanType}&month=${payload.month}&year=${payload.year}&entityID=${payload.entityID}&actType=${payload.actType}&frequency=${payload.frequency}&branchId=${payload.branchId}&state=${payload.state}`;

  //   return this.http.post<any>(url, { observe: 'response' });
  // }

  getComplianceChallan(payload: any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/StatutoryDocumentsContoller/LC_GetAll_RLCS_ComplianceDocuments_Challan`;


    let params = new HttpParams()
      .set('userID', payload.userID)
      .set('customerID', payload.customerID)
      .set('monthId', payload.monthId)
      .set('staOrInt', payload.staOrInt)
      .set('staIntFlag', payload.staIntFlag)
      .set('fy', payload.fy)
      .set('profileID', payload.profileID)
      .set('docType', payload.docType)
      .set('challanType', payload.challanType)
      .set('month', payload.month)
      .set('year', payload.year)
      .set('entityID', payload.entityID)


    return this.http.post<any>(url, payload, { observe: 'response', params });
  }
  
  getComplianceStatutory(payload: any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/StatutoryDocumentsContoller/LC_GetAll_RLCS_MyDocuments`;


    let params = new HttpParams()
      .set('userID', payload.userID)
      .set('customerID', payload.customerID)
      .set('monthId', payload.monthId)
      .set('staOrInt', payload.staOrInt)
      .set('staIntFlag', payload.staIntFlag)
      .set('fy', payload.fy)
      .set('profileID', payload.profileID)
      .set('docType', payload.docType)
      .set('challanType', payload.challanType)
      .set('month', payload.month)
      .set('year', payload.year)
      .set('entityID', payload.entityID)
      .set('actType', payload.actType)
      .set('frequency', payload.frequency)
      .set('branchId', payload.branchId)
      .set('state', payload.state);


    return this.http.post<any>(url, payload, { observe: 'response', params });
  }
  getRegisterStatutoryGrid(payload:any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/StatutoryDocumentsContoller/LC_GetDocuments_BranchList_Registers?customerID=${payload.customerID}&userID=${payload.userID}&MonthId=${payload.MonthId}&YearID=${payload.YearID}&profileID=${payload.profileID}&EntityID=${payload.EntityID}&Type=${payload.Type}`;
    
    return this.http.get(url, { observe: 'response' });

  }
  
  getChallansStatutoryGrid(payload:any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/StatutoryDocumentsContoller/LC_GetDocuments_BranchList_Challans?customerID=${payload.customerID}&userID=${payload.userID}&MonthId=${payload.MonthId}&YearID=${payload.YearID}&profileID=${payload.profileID}&EntityID=${payload.EntityID}&challanType=${payload.challanType}`;
    
    return this.http.get(url, { observe: 'response' });
    //'https://localhost:7111/api/StatutoryDocumentsContoller/LC_GetDocuments_BranchList_Challans?customerID=1641&userID=11338&challanType=EPF&MonthId=05&YearID=2023&profileID=11338&EntityID=%20AVACORED' \


  }
  getComplianceStatus(ComStatus:string):Observable<HttpResponse<any>>{
    let url = `${this.baseUrl}/api/Common/Get_LC_CodeDescription?Type=${ComStatus}`;
    return this.http.get<any>(url,{ observe: 'response' })
}

getReturnsStatutoryGrid(payload:any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/StatutoryDocumentsContoller/LC_GetDocuments_BranchList_Returns?customerID=${payload.customerID}&userID=${payload.userID}&MonthId=${payload.MonthId}&YearID=${payload.YearID}&profileID=${payload.profileID}&EntityID=${payload.EntityID}&Type=${payload.Type}&frequency=${payload.frequency}`;
    
    return this.http.get(url, { observe: 'response' });
  // 'https://localhost:7111/api/StatutoryDocumentsContoller/LC_GetDocuments_BranchList_Returns?customerID=1641&userID=11338&MonthId=05&YearID=2023&profileID=11338&EntityID=%20AVACORED&Type=%20EPF&frequency=Months' \

  }


  bulkDownloadBranchWiseRegister(payload: any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/StatutoryDocumentsContoller/BulkDownloadBranchWise`;

    // Optionally, set headers if needed
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    // POST request with payload and observe the response
    return this.http.post<any>(url, payload, {
      headers: headers,
      observe: 'response'  // to get the full HTTP response, including status code
    });
  }
  bulkDownloadBranchWiseReturn(payload: any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/StatutoryDocumentsContoller/BulkDownloadBranchWise`;

    // Optionally, set headers if needed
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    // POST request with payload and observe the response
    return this.http.post<any>(url, payload, {
      headers: headers,
      observe: 'response'  // to get the full HTTP response, including status code
    });
  }
  bulkDownloadBranchWiseChallan(payload: any): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/StatutoryDocumentsContoller/BulkDownloadBranchWise`;

    // Optionally, set headers if needed
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    // POST request with payload and observe the response
    return this.http.post<any>(url, payload, {
      headers: headers,
      observe: 'response'  // to get the full HTTP response, including status code
    });
  }

}
