import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class EcrReportService {

  private apiUrl = environment.apiUrl;
  public header = 'Add New PF/ECR Login';

  constructor(private http: HttpClient) {
  }
  setHeader(newHeader: string) {
    this.header = newHeader;
  }

  GetECRgridDetail(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/ECR/GetECRMasterData`;
    return this.http.get<any>(url,{ observe: 'response' })
    // 'https://localhost:7111/api/ECR/GetECRMasterData' \
    }
    ECRMasterExportToExcel(): Observable<HttpResponse<any>> {
      const url = `${this.apiUrl}/api/ECR/ECRMaster_ExportToExcel`;
      return this.http.get<any>(url,{ observe: 'response' })
  //'https://localhost:7111/api/ECR/ECRMaster_ExportToExcel'
      }

      // saveSubCode(userForm: any): Observable<HttpResponse<any>> {
      //   const keyValue = { key: userForm.username, value: userForm.password };
      //   const url = `${this.apiUrl}/api/ESIC/SaveSubcodeData`;
      //   return this.http.post(url, keyValue, { observe: 'response' });
      // }
      saveEcrCode(userForm: any): Observable<HttpResponse<any>> {
       
        const url = `${this.apiUrl}/api/ECR/SaveECRSubcodeData`;
        return this.http.post(url, userForm, { observe: 'response' });
      }

      getUserIDDropdown(Stateid:string): Observable<HttpResponse<any>> {
        const url = `${this.apiUrl}/api/ECR/GetECRMasterData_Search?Filter=${Stateid}`;
        return this.http.get(url, { observe: 'response' });

        //https://localhost:7111/api/ECR/GetECRMasterData_Search?Filter=12
      }

      isUserIdExists(Username: string): Observable<HttpResponse<any>> {
        const url = `${this.apiUrl}/api/ESIC/IsUserIdExists?Username=${Username}`;
        return this.http.get(url,{ observe: 'response' });
      }


      uploadFile(file: File): Observable<HttpResponse<any>> {
        const url =`${this.apiUrl}/api/ECR/BulkUploadPFECRFile`;
        const formData: FormData = new FormData();
        formData.append('_file', file, file.name);
      
     
      // 'https://localhost:7111/api/ECR/BulkUploadPFECRFile'
        return this.http.post<any>(url,formData,{ observe: 'response' });
      }
      
  // uploadFile(file: File, isAddOrUpdate: string): Observable<HttpResponse<any>> {
  //   const url = `${this.baseUrl}/api/DesignationMaster/UploadDesignationMaster`;
  //   const formData: FormData = new FormData();
  //   formData.append('file', file, file.name);
  //   formData.append('IsAddOrUpdate', isAddOrUpdate);
  
  //   return this.http.post<any>(url, formData,{ observe: 'response' });
  //     // 'https://localhost:7111/api/ECR/BulkUploadPFECRFile'
  // }
}
