<hr class="hr-fluid">
<div class="col-md-12 colpadding0" style="margin-top: -14px;">
    <header class="panel-heading tab-bg-primary colpadding0" style="background: none !important;">
        <ul class="nav nav-tabs">
            <li class="listItem pemt" [ngClass]="{'selected-tab':  EmployeeOption==='Corporate'}"
                (click)="EmployeeOption='Corporate'" style="margin-left: -8px !important">
                <a>Corporate</a>
            </li>
            <li class="listItem pemt" *ngIf="showOtherTabs" [ngClass]="{'selected-tab':  EmployeeOption==='Agreement'}"
                (click)="EmployeeOption='Agreement'" style="margin-left: -8px !important">
                <a>Agreement Details</a>
            </li>
            <li class="listItem pemt" *ngIf="showOtherTabs" [ngClass]="{'selected-tab':  EmployeeOption==='Entity'}"
                (click)="EmployeeOption='Entity'" style="margin-left: -8px !important">
                <a>Entity</a>
            </li>
            <li class="listItem pemt" *ngIf="showOtherTabs" [ngClass]="{'selected-tab':  EmployeeOption==='Master'}"
                (click)="EmployeeOption='Master'" style="margin-left: -8px !important">
                <a>Master Scoping</a>
            </li>
            <li class="listItem pemt" *ngIf="showOtherTabs" [ngClass]="{'selected-tab':  EmployeeOption==='Commercial'}"
                (click)="EmployeeOption='Commercial'" style="margin-left: -8px !important">
                <a>Commercial Setup</a>
            </li>
            <li class="listItem pemt" *ngIf="showOtherTabs"
                [ngClass]="{'selected-tab':  EmployeeOption==='ActivityCalender'}"
                (click)=" EmployeeOption='ActivityCalender'" style="margin-left: -8px !important">
                <a>Activity Calendar</a>
            </li>
        </ul>
    </header>
</div>

<div class="colpadding0 " *ngIf="EmployeeOption==='Corporate'">
    <div class="row colpadding0" style="margin-bottom: 10px;">
        <div class="col-md-12 colpadding0 toolbar" style="margin-top: 5px;">
            <div class="col-md-8 colpadding0" style=" justify-content: space-between; ">
                <app-combo-box [width]="'350px'" [Placeholder]="defaultSelectedValue" [selectedValue]="selectedValue"
                (valueChange)="DropdownGridChange($event)" (filterChange)="FilterDropdown($event)" [data]="dropdown">
            </app-combo-box>
            <!-- [hasError]="(selectedCorporateID == 0) &&  showError"  -->

                <div style="display: inline">
                    <button class="figma-btn-white" (click)="applyFilter()" type="submit">
                        <img src="../assets/vectors/ApplyFilter.svg" alt="">
                    </button>
                    <button (click)="clearFilter()" class="figma-btn-white">
                        <img src="../assets/vectors/ClearFilter.svg" alt="">
                    </button>
                </div>
            </div>
            <div class="col-md-4 colpadding0">
                <button class="figma-btn-blue " (click)="BackButton()">
                    Back
                </button>
                <button *ngIf="role == 'DADMN'" class="figma-btn-blue" (click)="OpenAddNewPopup('')" type="submit">
                    <img src="../assets/vectors/Plus.svg" alt="">Add New
                </button>
            </div>
        </div>
    </div>

    <kendo-grid class="custom-grid" [data]="gridData" [columnMenu]="{ filter: true, sort: true }" [pageSize]="pageSize"
        [pageable]="true" (pageChange)="pageChange($event)" [loading]="loadingGrid" [sortable]="true" [skip]="skip"
        [resizable]="true">

        <kendo-grid-column field="name" title="Name" [width]="400">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.name">
                    {{dataItem.name}}
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="corporateId" title="Corporate ID" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.corporateId">
                    {{dataItem.corporateId}}
                </div>
            </ng-template>
        </kendo-grid-column>



        <kendo-grid-column field="buyerContactNumber" title="Buyer Contact" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.buyerContactNumber">
                    {{dataItem.buyerContactNumber}}
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="buyerEmail" title="Buyer Email" [width]="200">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.buyerEmail">
                    {{dataItem.buyerEmail}}
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="fStatus" title="Status" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cell-content" [title]="dataItem.fStatus">
                    <span *ngIf="dataItem.status==1">Active</span>
                    <span *ngIf="dataItem.status==0">In Active</span>
                    <span *ngIf="dataItem.status==-1">Suspended</span>
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="Action" title="Action" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div class="cell-content" style="justify-content: space-around;" style="margin-left:18px;">
                    <img src="../assets/vectors/edit.svg" (click)="OpenAddNewPopup(dataItem)" alt="Edit Icon"
                        class="svg-icon" />
                    <img src="../assets/vectors/delete.svg" alt="Delete Icon" class="svg-icon" *ngIf="role == 'DADMN'"
                        (click)="confirmDelete(dataItem)" />
                    <img src="../assets/vectors/RightArrowButton.svg" (click)="goToEntity(dataItem)" alt="goto Icon"
                        class="svg-icon" />
                </div>
            </ng-template>
        </kendo-grid-column>
        <ng-template *ngIf="loadingGrid" kendoGridLoadingTemplate class="loading-overlay">
            <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
        </ng-template>

        <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
            <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
            <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
            <kendo-pager-next-buttons></kendo-pager-next-buttons>
            <kendo-pager-input></kendo-pager-input>
            <kendo-pager-info></kendo-pager-info>
            <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
        </ng-template>
    </kendo-grid>

</div>
<div class="colpadding0" *ngIf="EmployeeOption=='Agreement'">
    <app-agreement-details-master></app-agreement-details-master>
</div>
<div class="colpadding0" *ngIf="EmployeeOption=='Entity'">
    <app-entity-master></app-entity-master>
</div>
<div class="colpadding0" *ngIf="EmployeeOption=='Master'">
    <app-master-scoping-setup></app-master-scoping-setup>
</div>
<div class="colpadding0" *ngIf="EmployeeOption=='Commercial'">
    <app-commercial-mapping></app-commercial-mapping>
</div>
<div class="colpadding0" *ngIf="EmployeeOption=='ActivityCalender'">
    <app-activity-calender></app-activity-calender>
</div>



<app-customer-master-add-edit-popup *ngIf="showAddEditPopup" [data]="CustomerData"
    (close)="closePopup()"></app-customer-master-add-edit-popup>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270" [Data]="alertData" [id]="deleteID" (close)="closeAlert()"
    (confirm)="deleteCustomerBranch($event)"></app-alert-popup>