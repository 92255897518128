<div class="row colpadding0">
    <div class="col-md-12 colpadding0 toolbar" style="margin-top: 10px;margin-bottom: 10px;">
        <div class="col-md-2 colpadding0" style="padding-right: 10px;">
           
            <app-combo-box [width]="'100%'" [Placeholder]="'Select invoice type'"
            (valueChange)="onDropdownValueChange($event, 'invoiceType')"
            [data]="invoiceDropdown" [selectedValue]="selectedInvoiceType" textField="text"
            valueField="value" >
        </app-combo-box>
        </div>
        <div class="col-md-2 colpadding0" style="padding-right: 10px;">
           
            <app-combo-box [width]="'100%'" [Placeholder]="'Select client'"
            (valueChange)="onDropdownValueChange($event, 'clientType')"
            [data]="clientDropdown" [selectedValue]="selectedClientType" textField="text"
            valueField="value" >
        </app-combo-box>
        </div>
        <div class="col-md-2 colpadding0" style="padding-right: 10px;">
           
            <app-combo-box 
            [width]="'100%'" 
            [Placeholder]="'Select month/year'"
            (valueChange)="onDropdownValueChange($event, 'monthYear')"
            [data]="monthDropdown" 
            [selectedValue]="selectedmonthType"
            textField="name" 
            valueField="id">
          </app-combo-box>
          
        </div>
       
      
    </div>
</div>
<div  *ngIf="fieldType == 'RRC'">
  <kendo-grid [data]="gridData" [scrollable]="'none'" class="custom-grid">
      <kendo-grid-column field="heading" title="Heading">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{ dataItem.heading }}</span>
        </ng-template>
      </kendo-grid-column>
    
      <kendo-grid-column field="actual" title="Actual">
        <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
          <input 
            type="number" 
            [(ngModel)]="recurringData[rowIndex].actual" 
            style="width: 100%; border: 1px solid #ddd; background-color: #f5f5f5;">
        </ng-template>
      </kendo-grid-column>
    
      <kendo-grid-column field="adjustments" title="Adjustments">
        <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
          <input 
            type="number" 
            [(ngModel)]="recurringData[rowIndex].adjustments" 
            style="width: 100%; border: 1px solid #ddd; background-color: #f5f5f5;">
        </ng-template>
      </kendo-grid-column>
    
      <kendo-grid-column field="total" title="Total">
        <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
          <input 
            type="number" 
            [(ngModel)]="recurringData[rowIndex].total" 
            style="width: 100%; border: 1px solid #ddd; background-color: #f5f5f5;">
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
    <div class="mt-3" style="display: flex;justify-content: center;">
      <button  class="figma-btn-blue " (click)="submitData()">Submit</button>

    </div>
</div>
<div *ngIf="this.gstdetailsGrid.invoiceAddress != ''">
  <div class="gst-details-header">GST Details</div>
  <div class="gst-details">
    <div class="details-container">
      <div class="left-section">
        <p><strong>Invoice Address:</strong> {{ gstdetailsGrid.invoiceAddress }}</p>
        <p><strong>Dispatch Address:</strong> {{ gstdetailsGrid.dispatchAddress }}</p>
        <p><strong>PO Number:</strong> {{ gstdetailsGrid.pO_Number }}</p>
        <p><strong>PO Date:</strong> {{ gstdetailsGrid.pO_Date }}</p>
      </div>
      <div class="right-section">
        <p><strong>From State:</strong> {{ gstdetailsGrid.fromState }}</p>
        <p><strong>To State:</strong> {{ gstdetailsGrid.toState }}</p>
        <p><strong>GST Number:</strong> {{ gstdetailsGrid.gstNumber }}</p>
        <p><strong>GM ID:</strong> {{ gstdetailsGrid.gmId }}</p>
      </div>
    </div>
  </div>
  
  <div class="2">
    <kendo-grid [data]="[{}]" [scrollable]="'none'" class="custom-grid">
      <kendo-grid-column field="heading" title="Heading">
        <ng-template kendoGridCellTemplate let-dataItem>
          <textarea [(ngModel)]="objectgrid.activityName" kendoTextBox class="f-textbox grid-textbox"></textarea>
        </ng-template>
      </kendo-grid-column>
    
      <kendo-grid-column field="actual" title="Actual">
        <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
          <input kendoTextBox class="f-textbox grid-textbox" [(ngModel)]="objectgrid.actual" (ngModelChange)="updateTotal()">
        </ng-template>
      </kendo-grid-column>
    
      <kendo-grid-column-group title="Adjustment" [columnMenu]="false">
        <kendo-grid-column field="rating" title="" [width]="100" [resizable]="false" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <app-dropdown-list 
              [data]="adjustment" 
              [selectedValue]="adjustmentSelected"
              [width]="'100px'"
              textField="text" 
              valueField="value" 
              (valueChange)="onAdjustValueChange($event)"
              style="margin-left: 7px;">
            </app-dropdown-list>
          </ng-template>
        </kendo-grid-column>
    
        <kendo-grid-column field="adjustment" title="Adjustment" [width]="150" [resizable]="false">
          <ng-template kendoGridCellTemplate let-dataItem>
            <input kendoTextBox 
                   class="f-textbox grid-textbox" 
                   [(ngModel)]="objectgrid.adjustments" 
                   (blur)="updateTotal()">
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>
    
      <kendo-grid-column field="total" title="Total">
        <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
          <input kendoTextBox class="f-textbox grid-textbox" [value]="objectgrid.total" readonly>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
    
     
  </div>


  <kendo-grid [data]="[{}]" [scrollable]="'none'" class="hide-header-grid custom-grid" style="margin-top: 10px;">
    <kendo-grid-column field="Total">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ totalgrid.activityName }}</span>
      </ng-template>
    </kendo-grid-column>
  
    <kendo-grid-column field="Actual">
      <ng-template kendoGridCellTemplate let-dataItem>
        <input kendoTextBox class="f-textbox grid-textbox" style="width: 100%;" [(ngModel)]="totalgrid.actual">
      </ng-template>
    </kendo-grid-column>
  
    <kendo-grid-column field="totalIGSTAmount">
      <ng-template kendoGridCellTemplate let-dataItem>
        <input kendoTextBox class="f-textbox grid-textbox" style="width: 100%;" [(ngModel)]="totalgrid.adjustments">
      </ng-template>
    </kendo-grid-column>
  
    <kendo-grid-column field="grandTotal">
      <ng-template kendoGridCellTemplate let-dataItem>
        <input kendoTextBox class="f-textbox grid-textbox" style="width: 100%;" [(ngModel)]="totalgrid.total">
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
  <div  *ngIf="this.invoiceType == 'RRC'" style="margin-top: 10px;">
    <kendo-grid [data]="gridData" [scrollable]="'none'" class="custom-grid">
      <kendo-grid-column field="heading" title="Heading">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{ dataItem.heading }}</span>
        </ng-template>
      </kendo-grid-column>
    
      <kendo-grid-column field="actual" title="Actual">
        <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
          <input kendoTextBox
            [(ngModel)]="gridData[rowIndex].actual" 
            class="f-textbox grid-textbox">
        </ng-template>
      </kendo-grid-column>
    
      <kendo-grid-column field="adjustments" title="Adjustments">
        <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
          <input kendoTextBox
            [(ngModel)]="gridData[rowIndex].adjustments" 
            class="f-textbox grid-textbox">
        </ng-template>
      </kendo-grid-column>
    
      <kendo-grid-column field="total" title="Total">
        <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
          <input kendoTextBox class="f-textbox grid-textbox" [(ngModel)]="gridData[rowIndex].total">
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>  
  </div>
     
    <kendo-grid [data]="[{}]" [scrollable]="'none'" class="hide-header-grid custom-grid" style="margin-top: 10px;">
      <kendo-grid-column field="Total">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{ grandtotalgrid.name }}</span>
        </ng-template>
      </kendo-grid-column>
  
      <kendo-grid-column field="Actual">
        <ng-template kendoGridCellTemplate let-dataItem>
          <input kendoTextBox class="f-textbox grid-textbox" style="width: 100%;" [(ngModel)]="grandtotalgrid.grandtotal">
        </ng-template>
      </kendo-grid-column>
    
      <kendo-grid-column field="totalIGSTAmount">
        <ng-template kendoGridCellTemplate let-dataItem>
          <input kendoTextBox class="f-textbox grid-textbox" style="width: 100%;" [(ngModel)]="grandtotalgrid.adjustment">
        </ng-template>
      </kendo-grid-column>
    
      <kendo-grid-column field="grandTotal">
        <ng-template kendoGridCellTemplate let-dataItem>
          <input kendoTextBox class="f-textbox grid-textbox" style="width: 100%;" [(ngModel)]="grandtotalgrid.grandtotalwithadjustment">
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  
    <kendo-grid [data]="[{}]" [scrollable]="'none'" class="hide-header-grid custom-grid" style="margin-top: 10px;">
      <kendo-grid-column field="Total">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>Reward</span>
        </ng-template>
      </kendo-grid-column>
  
      <kendo-grid-column field="Actual">
        <ng-template kendoGridCellTemplate let-dataItem>
          <textarea kendoTextBox class="f-textbox grid-textbox"></textarea>
        </ng-template>
      </kendo-grid-column>
    
      <kendo-grid-column field="totalIGSTAmount">
        <ng-template kendoGridCellTemplate let-dataItem>
        </ng-template>
      </kendo-grid-column>
    
      <kendo-grid-column field="grandTotal">
        <ng-template kendoGridCellTemplate let-dataItem>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
    <div class="mt-3" style="display: flex;justify-content: center;">
      <button  class="figma-btn-blue " (click)="submitData()">Submit</button>
  
    </div>
  </div>

  <div *ngIf="this.lstSplitCommercialDetails.length > 0">
    <kendo-grid class="custom-grid" 
                [data]="lstSplitCommercialDetails" 
                [pageSize]="10" 
                [pageable]="false"
                scrollable="scrollable">
                
                <kendo-grid-column field="commercialName" title="Invoice Heading" [width]="250">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <!-- <div [title]="dataItem.commercialName" class="custom-tooltip">
                      {{ dataItem.commercialName }}
                    </div> -->
                    <textarea [(ngModel)]="dataItem.commercialName"  kendoTextBox class="f-textbox grid-textbox"></textarea>
                  </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="commercialPrice" title="Price">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.commercialPrice" class="custom-tooltip">
                      {{ dataItem.commercialPrice }}
                    </div>
                  </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="cgstAmountPercentage" title="CGSTAmount @ 0%">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.cgstAmountPercentage" class="custom-tooltip">
                      {{ dataItem.cgstAmountPercentage }}
                    </div>
                  </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="sgstAmountPercentage" title="SGSTAmount @ 0%">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.sgstAmountPercentage" class="custom-tooltip">
                      {{ dataItem.sgstAmountPercentage }}
                    </div>
                  </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="igstAmountPercentage" title="IGSTAmount @ 0%">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.igstAmountPercentage" class="custom-tooltip">
                      {{ dataItem.igstAmountPercentage }}
                    </div>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="totalAmount" title="Total Amount">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.totalAmount" class="custom-tooltip">
                      {{ dataItem.totalAmount }}
                    </div>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="invoice_Address" title="Invoice Address">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.invoice_Address" class="custom-tooltip">
                      {{ dataItem.invoice_Address }}
                    </div>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="dispatch_Address" title="Dispatch Address">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.dispatch_Address" class="custom-tooltip">
                      {{ dataItem.dispatch_Address }}
                    </div>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="pO_Number" title="PO Number">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.pO_Number" class="custom-tooltip">
                      {{ dataItem.pO_Number }}
                    </div>
                  </ng-template>
                </kendo-grid-column>
            
                <kendo-grid-column field="pO_Date" title="PO Date">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.pO_Date" class="custom-tooltip">
                      {{ dataItem.pO_Date }}
                    </div>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="fromState" title="From Date">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.fromState" class="custom-tooltip">
                      {{ dataItem.fromState }}
                    </div>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="toState" title="To Date">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.toState" class="custom-tooltip">
                      {{ dataItem.toState }}
                    </div>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="gstNumber" title="GST Number">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.gstNumber" class="custom-tooltip">
                      {{ dataItem.gstNumber }}
                    </div>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="gmid" title="GMID">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.gmid" class="custom-tooltip">
                      {{ dataItem.gmid }}
                    </div>
                  </ng-template>
                </kendo-grid-column>
              </kendo-grid>
  </div>
  
  
<div *ngIf="this.invoiceType == 'OT' || this.invoiceType == 'INS'">
    <kendo-grid [data]="[{}]" [scrollable]="'none'" class="custom-grid">
        <kendo-grid-column field="totalAmountWithAdjustment" title="Total Amount with Adjustment">
          <ng-template kendoGridCellTemplate let-dataItem>
            <input 
              type="number" class="f-textbox"
              [(ngModel)]="liasoningandInspectionData.totalAmountWithAdjustment"
              style="width: 100%; border: 1px solid #ddd; background-color: #f5f5f5;">
          </ng-template>
        </kendo-grid-column>
      
        <kendo-grid-column field="totalCGSTAmount" title="Total CGST Amount">
          <ng-template kendoGridCellTemplate let-dataItem>
            <input class="f-textbox"
              type="number" 
              [(ngModel)]="liasoningandInspectionData.totalCGSTAmount"
              style="width: 100%; border: 1px solid #ddd; background-color: #f5f5f5;">
          </ng-template>
        </kendo-grid-column>
      
        <kendo-grid-column field="totalSGSTAmount" title="Total SGST Amount">
          <ng-template kendoGridCellTemplate let-dataItem>
            <input class="f-textbox"
              type="number" 
              [(ngModel)]="liasoningandInspectionData.totalSGSTAmount"
              style="width: 100%; border: 1px solid #ddd; background-color: #f5f5f5;">
          </ng-template>
        </kendo-grid-column>
      
        <kendo-grid-column field="totalIGSTAmount" title="Total IGST Amount">
          <ng-template kendoGridCellTemplate let-dataItem>
            <input class="f-textbox"
              type="number" 
              [(ngModel)]="liasoningandInspectionData.totalIGSTAmount"
              style="width: 100%; border: 1px solid #ddd; background-color: #f5f5f5;">
          </ng-template>
        </kendo-grid-column>
      
        <kendo-grid-column field="grandTotal" title="Grand Total">
          <ng-template kendoGridCellTemplate let-dataItem>
            <input class="f-textbox"
              type="number" 
              [(ngModel)]="liasoningandInspectionData.grandTotal"
              style="width: 100%; border: 1px solid #ddd; background-color: #f5f5f5;">
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
      <div class="mt-3" style="display: flex;justify-content: center;">
        <button  class="figma-btn-blue mt-2" (click)="submitData()">Submit</button>

      </div>
</div>
 
<div>
<!-- <div class="col-md-6 form-group" style="margin-top: 60px;">
    <div class="drop-zone" 
         (dragover)="onDragOver($event)" 
         (dragleave)="onDragLeave($event)" 
         (drop)="onDrop($event)">
      <div *ngIf="!selectedFile" class="f-label" style="font-size: 18px;">
        <div class="col-md-12" style="margin-top: -15px;">
          <img src="../assets/vectors/cloud.svg" alt=""  >
         </div>
        <div class="col-md-8">
          <label style="margin-left: 160px;">{{ dropzoneMessage }}</label>
        </div>
        <div class="col-md-4">
          <label style="color: #125AAB; text-decoration: underline; cursor: pointer; margin-left: -47px; font-weight: 500;">
            Browse
            <input type="file" style="display: none;" (change)="onFileSelect($event)" accept=".xls,.xlsx,.csv" />
          </label>
        </div>
      </div>
      <p *ngIf="selectedFile">{{ selectedFile.name }}</p>
    </div>
  </div>
  <button kendoButton class="figma-btn-blue figma-popup" (click)="uploadFiles()">Upload</button>
</div> -->

<div style="display: flex; justify-content: center;"  *ngIf="this.invoiceType == 'OFF' " > 


<div class="card" style="width: 70%; margin-top: 20px; padding: 20px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);">
    <div class="card-body">
      <div style="display: flex;justify-content: space-between;">
        <h5 class="card-title" style="color: #666;font-size: 12px;font-weight: 500 !important;">File Upload</h5>
        <!-- <div style="display: flex;">
          <img src="../assets/vectors/Excel.svg" alt="" style="padding: 5px;">
          <div style="flex: 1; display: flex; align-items: center;">
            <a href="../assets/files/GstUploads.xlsx" download="GstUploads.xlsx"
            style="color: #125AAB; text-decoration: underline;font-size:15px" id="btnSampleRLCS">
            EmptyTemplate
          </a>
          </div>
        </div> -->
      </div>
      
      <div class="form-group" style="margin-top: 30px;">
        <div class="drop-zone" 
             (dragover)="onDragOver($event)" 
             (dragleave)="onDragLeave($event)" 
             (drop)="onDrop($event)">
          <div *ngIf="!selectedFile" class="f-label" style="font-size: 18px;">
            <div class="col-md-12" style="margin-top: -15px;">
              <img src="../assets/vectors/cloud.svg" alt="">
            </div>
            <div class="col-md-8">
              <label style="margin-left: 210px;">{{ dropzoneMessage }}</label>
            </div>
            <div class="col-md-4">
              <label style="color: #125AAB; text-decoration: underline; cursor: pointer; margin-left: -67px; font-weight: 500;">
                Browse
                <input type="file" style="display: none;" (change)="onFileSelect($event)" accept=".xls,.xlsx,.csv" />
              </label>
            </div>
          </div>
          <p *ngIf="selectedFile">{{ selectedFile.name }}</p>
        </div>
      </div>
  <div style="display: flex;justify-content: center;">
    <button kendoButton class="figma-btn-blue" (click)="uploadFiles()">Upload</button>

  </div>
    </div>
  </div>
</div>
<app-alert-popup *ngIf="alertData"[Width]="270"  [Data]="alertData" (close)="closePopup()"></app-alert-popup>