<kendo-dialog #dialogRef title="Digital Signature" (close)="closePopup()" [width]="600" class="custom-dialog">
    <form [formGroup]="form">
        <div class="row col-12 dialog-content ">
            <div class="col-md-5 d-flex align-items-center">
                <label class="f-label me-3">Authorisation Person Type<sub class="text-danger">*</sub></label>
            </div>
            <div class="col-md-7 d-flex align-items-center">
                <ul class="nav nav-tabs">
                    <li style="display: flex; align-items: center;">
                        <h4 class="f-label"> <input id="new" value="N" type="radio" class="custom-radio"
                                name="measurementSystem" (change)="onRadioChange('N')"
                                [checked]="selectionType === 'N'" />
                            New</h4>
                    </li>
                    <li style="display: flex; align-items: center;">
                        <h4 class="f-label"><input id="existing" value="E" type="radio" class="custom-radio"
                                name="measurementSystem" (change)="onRadioChange('E')"
                                [checked]="selectionType === 'E'" />
                            Existing</h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row col-12 dialog-content" *ngIf="selectionType==='N' && data.length===0">
            <div class="col-md-5 d-flex align-items-center">
                <label class="f-label me-3">Authorised Person Id<sub class="text-danger">*</sub></label>
            </div>
            <div class="col-md-7 d-flex align-items-center">
                <input *ngIf="selectionType==='N'" type="text" class="form-control f-textbox masking"
                    placeholder="Authorised Person Id" [(ngModel)]="authorisedPersonId"
                    formControlName="authorisedPersonId" style="width: 250px;"
                    [ngClass]="{'is-invalid': form.get('authorisedPersonId')?.invalid && (form.get('authorisedPersonId')?.touched)}" />

            </div>
        </div>
        <div class="row col-12 dialog-content" *ngIf="selectionType==='E'">
            <div class="col-md-5 d-flex align-items-center">
                <label class="f-label me-3">Select Authorised Person<sub class="text-danger">*</sub></label>
            </div>
            <div class="col-md-7 d-flex align-items-center">
                <div class="require-container"
                    [ngClass]="{'is-invalid': form.get('authorisedPersonId')?.invalid && (form.get('authorisedPersonId')?.touched)}">
                    <app-dropdown-list [data]="employees" [selectedValue]="selectedEmployee" textField="text"
                        valueField="value" [width]="'250px'" (valueChange)="onSelectedEmployee($event)">
                    </app-dropdown-list>
                </div>
            </div>
        </div>
        <div class="row col-12 dialog-content">
            <div class="col-md-5 d-flex align-items-center">
                <label class="f-label me-3">Authorised Person<sub class="text-danger">*</sub></label>
            </div>
            <div class="col-md-7 d-flex align-items-center">
                <input type="text" class="form-control f-textbox masking" placeholder="Authorised Person"
                    [(ngModel)]="authorisedPerson" formControlName="authorisedPerson" style="width: 250px;"
                    [ngClass]="{'is-invalid': form.get('authorisedPerson')?.invalid && (form.get('authorisedPerson')?.touched)}" />

            </div>
        </div>
        <div class="row col-12 dialog-content">
            <div class="col-md-5 d-flex align-items-center">
                <label class="f-label me-3">Designation<sub class="text-danger">*</sub></label>
            </div>
            <div class="col-md-7 d-flex align-items-center">
                <input type="text" class="form-control f-textbox masking" placeholder="Designation"
                    [(ngModel)]="designation" formControlName="designation" style="width: 250px;"
                    [ngClass]="{'is-invalid': form.get('designation')?.invalid && (form.get('designation')?.touched)}" />
            </div>
        </div>
        <div class="row col-12 dialog-content">
            <div class="col-md-5 d-flex align-items-center">
                <label class="f-label me-3">Mobile Number<sub class="text-danger">*</sub></label>
            </div>
            <div class="col-md-7 d-flex flex-column align-items-start">
                <input type="text" class="form-control f-textbox masking" placeholder="Mobile Number"
                    [(ngModel)]="mobile" [maxlength]="10" formControlName="mobile" style="width: 250px;"
                    [ngClass]="{'is-invalid': form.get('mobile')?.invalid && (form.get('mobile')?.touched)}"
                    (keypress)="onKeyPress($event)" />

                <div *ngIf="form.get('mobile')?.invalid && (form.get('mobile')?.touched) && form.get('mobile')?.errors?.['pattern']"
                    class="text-danger mt-1">
                    Mobile No must be exactly 10 digits
                </div>
            </div>
        </div>

        <div class="row col-12 dialog-content">
            <div class="col-md-5 d-flex align-items-center">
                <label class="f-label me-3">Effective From<sub class="text-danger">*</sub></label>
            </div>
            <div class="col-md-7 d-flex align-items-center">
                <div class="require-container"
                    [ngClass]="{'is-invalid': form.get('effectiveFrom')?.invalid && (form.get('effectiveFrom')?.touched)}">
                    <app-kendo-datepicker class="uniform-size" [(value)]="effectiveFrom"
                        (valueChange)="onDateChange($event, 'effectiveFrom')" [format]="'dd-MM-yyyy'"
                        [placeholder]="placeholder" [width]="'250px'">
                    </app-kendo-datepicker>
                </div>
            </div>
        </div>
        <div class="row col-12 dialog-content">
            <div class="col-md-5 d-flex align-items-center">
                <label class="f-label me-3">Effective To<sub class="text-danger">*</sub></label>
            </div>
            <div class="col-md-7 d-flex align-items-center">
                <div class="require-container"
                    [ngClass]="{'is-invalid': form.get('effectiveTo')?.invalid && (form.get('effectiveTo')?.touched)}">
                    <app-kendo-datepicker class="uniform-size" [(value)]="effectiveTo"
                        (valueChange)="onDateChange($event, 'effectiveTo')" [format]="'dd-MM-yyyy'"
                        [placeholder]="placeholder" [width]="'250px'">
                    </app-kendo-datepicker>
                </div>
            </div>
        </div>
        <div class="row col-12 dialog-content">
            <div class="col-md-5 d-flex align-items-center">
                <label class="f-label me-3">Remark<sub class="text-danger">*</sub></label>
            </div>
            <div class="col-md-7 d-flex align-items-center">
                <textarea class="form-control f-textbox" formControlName="remark" [(ngModel)]="remark"
                    placeholder="Remarks"
                    [ngClass]="{'is-invalid': form.get('remark')?.invalid && (form.get('remark')?.touched)}"></textarea>
            </div>
        </div>
        <div class="row col-12 dialog-content">
            <div class="col-md-5 d-flex align-items-center">
                <label class="f-label me-3">Status<sub class="text-danger">*</sub></label>
            </div>
            <div class="col-md-7 d-flex align-items-center">
                <div class="require-container"
                    [ngClass]="{'is-invalid': form.get('statusType')?.invalid && (form.get('statusType')?.touched)}">
                    <app-dropdown-list [data]="statusType" [selectedValue]="selectedStatusType" textField="text"
                        valueField="value" [width]="'250px'" (valueChange)="onSelectedStatusType($event)">
                    </app-dropdown-list>
                </div>
            </div>
        </div>

        <button class="figma-btn-blue figma-popup custom-btn" (click)="saveData()">
            Save
        </button>

    </form>
</kendo-dialog>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="300" [Height]="250" [Data]="alertData"
    (close)="closeAlertPopup()"></app-alert-popup>