import { ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { MultiSelectDropdownComponent } from "../../../components/multi-select-dropdown/multi-select-dropdown.component";
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { DropDownsModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { GridComponent } from '../../../components/grid/grid.component';
import { StatusReportService } from '../../../service/status-report.service';
import { BlobService } from '../../../service/blob.service';
import { UserService } from '../../../service/user.service';
import { FormGroup } from '@angular/forms';
import { NgIf } from '@angular/common';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';



type Branch = {
  id: number;
  name: string;
  parentID: number | null;
  branchType: string;
  branchStatus: string;
  fullName: string | null;
  children?: Branch[];
};
@Component({
  selector: 'app-compliance-status-report',
  standalone: true,
  imports: [IndicatorsModule, GridComponent, MultiSelectDropdownComponent, NgIf, TreeViewModule, DropDownsModule, MultiSelectModule, DropdownListComponent, GridModule],
  templateUrl: './compliance-status-report.component.html',
  styleUrl: './compliance-status-report.component.css',
  encapsulation: ViewEncapsulation.None
})
export class ComplianceStatusReportComponent {
  form!: FormGroup
  years: any[] = [];
  period: any[] = [];
  public dropdownDataMonth: any[] = [];
  public defaultSelectedValueMonth: any[] = [];
  selectedMonth: any = this.defaultSelectedValueMonth;
  public dropdownDataYear: any[] = [];
  public defaultSelectedValueYear = { value: 0, displayvalue: 'Select Year' };

  selectedPeriod: any[] = []
  selectedComplianceType: any[] = [];
  selectedComplianceTypes: { text: any, value: any } = { text: 'monthly', value: 'monthly' }
  // selectedComplianceTypes: { text: any; value: any }[] = [{ text: 'monthly', value: 'monthly' }];
  allSelected: boolean = false;
  public gridData: any[] = [];
  public originalData: any[] = [];
  public columns: any[] = [];
  showState = false;
  loading: boolean = false;
  public showValidationErrors = false;
  private clientID = '';
  private custID = '';
  profileID = '';
  distID = '';
  roleCode = '';
  spID = '';
  userID = '';
  type: any
  filteredGridData: any[] = [];
  selectedBranches: string[] = []; // Store selected IDs from the MultiSelectTree
  isComplianceLoaded = false;
  isPeriodLoaded = false;

  selectedYear: any[] = []
  complianceType: any[] = []
  complianceTypes: { text: string, value: string }[] = [
    { text: 'Compliance Type', value: 'Compliance Type' },

  ]

  // complianceperiods: any[] = [];
  complianceperiods: any[] = [];




  complianceReassignment: { text: string, value: string }[] = [
    { text: 'Activity', value: 'Activity' },

  ]
  multiSelectEntities: any[] = []; // To hold selected entities from MultiSelectTree
  selectedFiscalYear: any; // For year selection
  selectedStatusType: any; // For compliance type selection
  selectedCompliance: any[] = []; // For compliance types selection
  selectedMonths: any; // For month selection
  filteredGridDatas: any[] = []; // To hold filtered data
  originalGridData: any[] = [];
  commaSeparatedIds: string = '';
  popupSettings: any = { width: '280px' };
  public BranchDropdown: Branch[] = [];
  isActive: boolean = false;
  constructor(private cdr: ChangeDetectorRef, private StatusReportService: StatusReportService, private blobService: BlobService, private user: UserService,) {
    this.getCompliance();
  }


  ngOnInit(): void {
    this.getYears();
    this.getBranchDropdownData();
    this.getPeriods();
    this.loadMonthYearData();
    this.reportGridData();
    this.getComplianceMonth();
    

  }



  public tagMapper(tags: any[]): any[] {
    return tags.length < 3 ? tags : [tags];
  }

  // gridData = [
  //   { field1: 'AVATCGEN 5', field2: 100, field3: true },
  //   { field1: 'value2', field2: 200, field3: false },
  //   more data...
  // ];

  // columns = [
  //   { field: 'field1', title: 'Customer', filter: 'text' },
  //   { field: 'field2', title: 'Branch', filter: 'numeric' },
  //   { field: 'field3', title: 'Compliance', filter: 'boolean' },
  //   { field: 'field4', title: 'Form', filter: 'boolean' },
  //   { field: 'field5', title: 'Frequency', filter: 'boolean' },
  //   { field: 'field6', title: 'period', filter: 'boolean' },
  //   { field: 'field7', title: 'Due date', filter: 'boolean' },
  //   { field: 'field8', title: 'status', filter: 'boolean' },
  //   // more columns...
  // ];

  getBranchDropdownData() {
    const entityId = this.user.getClientId();

    this.StatusReportService.getBranchDropdown(entityId).subscribe({
      next: (response) => {
        console.log('branch dropdown data', response);
        const data = response.body
        if (data && Array.isArray(data)) {
          this.BranchDropdown = this.buildHierarchy(data);
          const maxLength = this.calculateMaxTextLength(this.BranchDropdown);
          const calculatedWidth = Math.max(238, maxLength * 12);
          this.popupSettings = { width: `${calculatedWidth}px` };
          console.log('BranchDropdown', this.BranchDropdown)
        } else {
          console.error('Unexpected data format', data);
        }
      }
    })


  }
  calculateMaxTextLength(data: Branch[]): number {
    let maxLength = 0;

    const findMaxLength = (items: Branch[]) => {
      items.forEach(item => {
        maxLength = Math.max(maxLength, item.name.length);
        if (item.children && item.children.length) {
          findMaxLength(item.children);
        }
      });
    };

    findMaxLength(data);
    return maxLength;
  }

  buildHierarchy(data: Branch[]): Branch[] {
    const grouped: { [key: number]: Branch[] } = data.reduce((acc, item) => {
      (acc[item.parentID || -1] = acc[item.parentID || -1] || []).push(item);
      return acc;
    }, {} as { [key: number]: Branch[] });

    function buildTree(parentID: number | null): Branch[] {
      return (grouped[parentID || -1] || []).map(item => ({
        ...item,
        children: buildTree(item.id)
      }));
    }

    return buildTree(null);
  }


  loadMonthYearData() {
    this.StatusReportService.getMonth('Months').subscribe({
      next: (response) => {
        const data = response.body;
        console.log("period", data);
        this.dropdownDataMonth = data.map((item: any) => ({
          value: item.displayvalue,
          text: item.displayvalue
        }));
        this.dropdownDataMonth = data;
        this.isPeriodLoaded = true;
        // if (data) {
        //   this.dropdownDataMonth = [this.defaultSelectedValueMonth, ...data];
        // }
      },
      error: (error) => {
        console.error('Error fetching Month Dropdown data', error);
      }
    });

  }

  // onYearChange(value: any): void {
  //   this.selectedYear = { text: value.text, value: value.value }
  // }

  onPeriodChange(value: any): void {
    this.selectedPeriod = value;
    // this.selectedPeriod = { text: value.text, value: value.value }
  }
  getYears(): void {
    const currentYear = new Date().getFullYear();
    this.years = [];
    for (let year = currentYear; year >= 2017; year--) {
      this.years.push({ text: year.toString(), value: year });
    }
  }


  getPeriods(): void {

    this.StatusReportService.getcompliancePeriod(this.type).subscribe({
      next: (response) => {
        const data = response.body
        console.log('Period', data);
        this.period = data.map((item: any) => ({
          text: item.text,
          value: item.value
        }))
      }
    })
  }
  getCompliance() {

    this.StatusReportService.getComplianceStatus('ComStatus').subscribe({
      next: (response) => {
        const data = response.body;
        if (data && Array.isArray(data)) {
          this.complianceType = data.map((item: any) => ({
            value: item.value,
            text: item.text
          }));
          this.isComplianceLoaded = true;
        }
        console.log(this.complianceType, '****************')
      },
      error: (err) => {
        console.error("Error fetching compliance status:", err);
      }
    });
  }

  getComplianceMonth() {

    this.StatusReportService.getComplianceStatuss('Frequency').subscribe({
      next: (response) => {
        const data = response.body;
        if (data && Array.isArray(data)) {
          this.complianceTypes = data.map((item: any) => ({
            value: item.value,
            text: item.text
          }));

        }

      },
      error: (err) => {
        console.error("Error fetching compliance status:", err);
      }
    });
  }
  getComplianceMonthperiods(periods: any) {

    this.StatusReportService.getComplianceStatuss(periods).subscribe({
      next: (response) => {
        const data = response.body;
        if (data && Array.isArray(data)) {
          this.complianceperiods = data.map((item: any) => ({
            value: item.value,
            displayvalue: item.text
          }));
          console.log(data, 'dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
          // this.complianceperiods = data;
          this.isPeriodLoaded = true;
        }
      },
      error: (err) => {
        console.error("Error fetching compliance status:", err);
      }
    });
  }



  clearFilter(multiselectTree: any) {
    this.selectedYear = [];
    this.defaultSelectedValueMonth = [];
    this.selectedComplianceType = [];
    this.selectedComplianceTypes = { text: 'Monthly', value: null };
    multiselectTree.value = [];
    multiselectTree.reset();
    this.reportGridData();

  }




  exportToExcel(): void {

    let payload = {
      userID: this.user.getUserId(),
      custID: this.user.getCustomerId(),
      profileID: this.user.getUserId(),
      distID: -1,
      spID: -1,
      roleCode: this.user.getRole(),
      ClientID: this.user.getClientId()

    }
    this.loading = true
    this.StatusReportService.exportToExcel(payload).subscribe(
      (response: any) => {
        console.log('responseexcell', response);

        const blobLink = response.body.filePath;
        const blobName = this.blobService.extractBlobName(blobLink);
        setTimeout(() => {
          this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
            const sasToken = tokenResponse.result;
            this.blobService.downloadFile(blobName, sasToken);
            this.loading = false;
          });
        }, 5000);
      },
      (error) => {
        console.error('Error fetching blob link:', error);
        this.loading = false;
      });
  }

  reportGridData(): void {
  
    let payload = {
      userID: this.user.getUserId(),
      custID: this.user.getCustomerId(),
      profileID: this.user.getUserId(),
      distID: -1,
      spID: -1,
      roleCode: this.user.getRole(),
      clientID: this.user.getClientId()

    }
    this.columns = [
      { field: 'customerName', title: 'Customer', width: 120 },
      { field: 'customerBranchName', title: 'Branch',width: 100  },
      { field: 'shortForm', title: 'Compliance',width: 150  },
      { field: 'requiredForms', title: 'Form' ,width: 150},
      { field: 'frequency', title: 'Frequency',width: 120 },
      // { field: 'forMonth', title: 'period' },
      {
        field: 'forMonth',
        title: 'Period',width: 120,
        cellTemplate: (dataItem: any) => {
          return this.formatDate(dataItem.scheduledOn); // Format Date
        },
      },
      { field: 'scheduledOn', title: 'Due date',width: 120  },
      { field: 'filterStatus', title: 'Status',width: 120  },

    ];
    this.loading = true;
    this.StatusReportService.getReportGridData(payload).subscribe({
      next: (response) => {
        debugger;
        let data = response.body;
        data = data.map((item: any) => {
          return {
            ...item,
            scheduledOn: this.formatDate(item.scheduledOn), // Format the `scheduledOn` date
          };
        });
        if (data) {
          
          this.isActive = true
          this.gridData = data;
          this.originalData = data;
          console.log('grid', this.gridData)
          this.showState = true;
        }
        this.loading = false;
      },
      error: (error) => {
        debugger
        console.error('Error fetching Master Grid data', error);
        this.showState = false;
        this.loading = false;
      }
    });
  }
  private formatDate(dateString: string): string {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return ''; // Handle invalid dates
    }

    const day = ('0' + date.getDate()).slice(-2); // Get two-digit day
    const month = date.toLocaleString('default', { month: 'short' }); // Get abbreviated month
    const year = date.getFullYear(); // Get full year

    return `${day}-${month}-${year}`; // Return formatted date
  }
  onMultiSelectChange(selected: any[]) {
    // this.multiSelectEntities = selected; // Update selected entities
    // console.log(' this.multiSelectEntities ', this.multiSelectEntities)

    this.multiSelectEntities = selected.map(item => item.id);

    console.log('Selected Names: ', this.multiSelectEntities);
  }
  // onMultiSelectChange(type: any) {
  //   this.selectedStatusType = type;
  //   console.log('sdsdsa', this.selectedStatusType)
  // }

  // Function to handle year change
  selectedyears: any[] = [];
  onYearChange(year: any) {
    this.selectedFiscalYear = year;
    this.selectedyears = year.map((item: { value: any; }) => item.value);
    // this.selectedFiscalYear = year.map((item: { value: any; }) => item.value);
    console.log('this.selectedFiscalYear', this.selectedFiscalYear)
  }

  // Function to handle compliance type change
  selectedCompliances: any[] = [];
  oncomplianceTypeChange(type: any) {
    this.selectedStatusType = type;
    this.selectedCompliances = type.map((item: any) => item.value);
    console.log('this.selectedStatusType', this.selectedStatusType)
  }

  //  onMonthChange(selected: any[]) {
  //     this.selectedMonth = selected;
  //     // this.pe('SubFrequencyQ')
  //     console.log('this.selectedMonth ', this.selectedMonth )
  // }

  onMonthChange(selected: any) {
    this.isPeriodLoaded = false;
    this.selectedMonth = selected;
    console.log('this.selectedMonth ', this.selectedMonth);
    let param;
    if (selected.value == 'Monthly') {
      param = 'Months';
    }
    if (selected.value == 'Quarterly' || selected.value == 'HalfYearly' || selected.value == 'Annual' || selected.value == 'TwoYearly') {
      // Assuming each selected item is an object with a 'value' property
      const firstSelectedItem = selected.value[0] == 'T' ? 'B' : selected.value[0]; // a First item in the array

      // Access the 'value' property and capitalize the first letter
      const firstLetter = firstSelectedItem.charAt(0).toUpperCase();//a

      // Correctly format the subFrequency string
      param = `SubFrequency${firstLetter}`;//a
    }


    // Call the getComplianceMonthperiods method with the formatted subFrequency
    this.getComplianceMonthperiods(param);
  }

  // Function to handle month change
  selectedPeriods: any[] = [];
  onPeriod(period: any) {
    this.selectedPeriod = period;
    this.selectedPeriods = period.map((item: { value: any; }) => Number(item.value));
    console.log('period', this.selectedPeriod)
  }


  extractNames(nodes: any) {
    let names: any[] = [];

    nodes.forEach((node: any) => {
      if (node.name) {
        names.push(node.name);
      }
      if (node.children && Array.isArray(node.children) && node.children.length > 0) {
        names = names.concat(this.extractNames(node.children));
      }
    });

    return names;
  }


  // // Apply Filters
  // applyFilters() {

  //   let entity1: any[] = []; // Array to store matching gridData objects
  //   let entity: any[] = [];

  //   if (this.multiSelectEntities) {
  //     entity = this.gridData.filter(item => {
  //       const branchID = item.customerBranchID; // Get the branch ID

  //       // Check if the branch ID is in the selected entities
  //       const matchFound = this.multiSelectEntities.includes(branchID);


  //       if (matchFound) {
  //         entity1.push(item); // Push the entire item to entity1 if there's a match
  //       }

  //       return matchFound; // Return true if there's a match to keep it in the filtered array
  //     });
  //   }
  //   debugger
  //   // Select years
  //   if (this.selectedFiscalYear) {
  //     entity = this.gridData.filter(item => {
  //       if (Number(item.rlcS_PayrollYear) === this.selectedFiscalYear[0].value) {
  //         entity1.push(item);
  //       }
  //     });
  //   }

  //   // select status
  //   if (this.selectedStatusType) {
  //     entity = this.gridData.filter(item => {
  //       if (item.filterStatus === this.selectedStatusType[0].value) {
  //         entity1.push(item);
  //       }
  //     });
  //   }

  //   // selected monthly
  //   if (this.selectedMonth) {
  //     entity = this.gridData.filter(item => {
  //       if (item.frequency === this.selectedMonth.value) {
  //         entity1.push(item);
  //       }

  //     });

  //   }

  //   // period
  //   if (this.selectedPeriod) {
  //     entity = this.gridData.filter(item => {
  //       if (item.forPeriod === this.selectedPeriod[0].value) {
  //         entity1.push(item);
  //       }
  //     });
  //   }

  //   console.log('Filtered Grid Data:', entity);
  //   console.log('Entity with Matching Items:', entity1); // This will contain all matching items

  //   // Update gridData with a new reference
  //   this.gridData = [...entity1]; // Spread operator creates a new reference
  //   this.detectChanges.detectChanges();
  //   console.log('Filtered Data:', this.gridData);

  // }
  // applyFilters() {
  //   debugger
  //   let filteredData = this.gridData;
  //   let data: any[] = [];
  //   // Filter by multiSelectEntities if selected
  //   if (this.multiSelectEntities && this.multiSelectEntities.length > 0) {
  //     data = [];
  //     filteredData = filteredData.filter(item => {
  //       const branchID = item.customerBranchID;
  //       if (this.multiSelectEntities.includes(branchID)) {
  //         data.push(item)
  //       }
  //       filteredData = data;
  //       return data;
  //     });
  //   }

  //   // Filter by selectedFiscalYear if selected
  //   if (this.selectedFiscalYear && this.selectedFiscalYear.length > 0) {
  //     data = [];
  //     filteredData = filteredData.filter(item => {
  //       // return Number(item.rlcS_PayrollYear) === this.selectedFiscalYear[0].value; 
  //       if (this.selectedyears.includes(Number(item.rlcS_PayrollYear))) {
  //         data.push(item)
  //       }
  //       // let datas =this.selectedyears.includes(item.rlcS_PayrollYear);
  //       filteredData = data;
  //       return data;
  //     });
  //   }

  //   // Filter by selectedStatusType if selected
  //   if (this.selectedStatusType && this.selectedStatusType.length > 0) {
  //     data = [];
  //     filteredData = filteredData.filter(item => {
  //       // return item.filterStatus === this.selectedStatusType[0].value; 
  //       if (this.selectedCompliances.includes(item.filterStatus)) {
  //         data.push(item)
  //       }
  //       // let datas =this.selectedyears.includes(item.rlcS_PayrollYear);
  //       filteredData = data;
  //       return data;
  //       // let data =this.selectedCompliances.includes(item.filterStatus);
  //       // return data;

  //     });
  //   }

  //   // Filter by selectedMonth if selected
  //   if (this.selectedMonth && this.selectedMonth.value) {
  //     filteredData = filteredData.filter(item => {
  //       return item.frequency === this.selectedMonth.value;
  //     });
  //   }

  //   // Filter by selectedPeriod if selected
  //   if (this.selectedPeriod && this.selectedPeriod.length > 0) {
  //     data = []
  //     filteredData = filteredData.filter(item => {
  //       // return item.forPeriod === this.selectedPeriod[0].value;
  //       if (this.selectedPeriods.includes(item.forPeriod)) {
  //         data.push(item)
  //       }
  //       // let datas =this.selectedyears.includes(item.rlcS_PayrollYear);
  //       filteredData = data;
  //       return data;
  //       // let data = this.selectedPeriods.includes(item.forPeriod);
  //       // return data; 
  //     });
  //   }

  //   // After all filters, update the gridData with the filtered results
  //   this.gridData = [...filteredData];

  //   console.log('Filtered Data:', this.gridData);
  // }

  applyFilters() {
   debugger
    // this.loading=true;
    this.gridData = [...this.originalData];
    let filteredData = [...this.gridData];
  
    // Filter by multiSelectEntities if selected
    if (this.multiSelectEntities && this.multiSelectEntities.length > 0) {
      filteredData = filteredData.filter(item => this.multiSelectEntities.includes(item.customerBranchID));
    }
    
    // Filter by selectedFiscalYear if selected
    if (this.selectedFiscalYear && this.selectedFiscalYear.length > 0) {
      filteredData = filteredData.filter(item => this.selectedyears.includes(Number(item.rlcS_PayrollYear)));
    }
  
    if (this.selectedStatusType && this.selectedStatusType.length > 0) {
    
      filteredData = filteredData.filter(item => this.selectedCompliances.includes(item.filterStatus));
      // filteredData = filteredData.filter(item => this.selectedStatusType.includes(item.filterStatus));

    }
  
    // Filter by selectedMonth if selected
    if (this.selectedMonth && this.selectedMonth.value) {
      filteredData = filteredData.filter(item => item.frequency === this.selectedMonth.value);
    }
  
    // Filter by selectedPeriod if selected
    if (this.selectedPeriod && this.selectedPeriod.length > 0) {
      filteredData = filteredData.filter(item => this.selectedPeriods.includes(item.forPeriod));
    }
    // selectedPeriods
    // forPeriod
    // After all filters, update the gridData with the filtered results
    this.gridData = [...filteredData];
    console.log('Fid Data:', this.gridData);
    this.cdr.detectChanges()
  // this.loading=false;
    console.log('Filtered Data:', this.gridData);
  }
  
}


