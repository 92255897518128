import { CommonModule, NgIf } from '@angular/common';
import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { FormsModule } from '@angular/forms';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { LocationMasterComponent } from '../../manage-masters/location-master/location-master.component';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { PremiseMasterService } from '../../../service/premise-master.service';
import { UserService } from '../../../service/user.service';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-premise-shiftmaster-upload',
  standalone: true,
  imports: [NgIf, GridActionItemComponent, CommonModule, FormsModule, DialogModule, LocationMasterComponent, AlertPopupComponent, IndicatorsModule],
  templateUrl: './premise-shiftmaster-upload.component.html',
  styleUrl: './premise-shiftmaster-upload.component.css'
})
export class PremiseShiftmasterUploadComponent {
  @Output() close = new EventEmitter<void>();
  selectedFile?: File;
  dropzoneMessage: string = "Drag your files here or ";
  clientid: any;
  userid: any;
  alertData = { alertMessage: '', alertVisible: '' };
  loading = false;
  constructor(private premiseMasterService: PremiseMasterService, private user: UserService) { }

  @ViewChild('dialogTitle') dialogTitle!: ElementRef;
  ngAfterViewInit() {
    const titleElement = this.dialogTitle.nativeElement;
    titleElement.style.textAlign = 'center';
    titleElement.style.width = '100%';
  }

  public onFileSelect(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
      this.selectedFile = input.files[0];
      console.log('Selected file:', this.selectedFile);
      const fileExtension = this.selectedFile.name.split('.').pop()?.toLowerCase();
      if (fileExtension !== 'xlsx') {
        this.selectedFile = undefined;
        this.alertData = {
          alertMessage: 'Please upload XLSX file only.',
          alertVisible: 'error'
        };
      }
    }
  }

  public onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drop the file here";
  }

  public onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drag your files here or ";
  }

  public onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      this.selectedFile = event.dataTransfer.files[0];
      event.dataTransfer.clearData();
    }
  }

  

  public closePopup(): void {
    this.close.emit();
    this.alertData = { alertMessage: '', alertVisible: '' };   
  }
  public closeAlert(): void {
  if (this.alertData.alertVisible == 'success') {
    this.closePopup();
  }
  this.alertData = { alertMessage: '', alertVisible: '' };
  }

  uploadShiftMaster() {
    debugger;
    if (this.selectedFile) {
        const entityID = this.user.getClientId();
        const userID = this.user.getUserId();
        const custID = this.user.getCustomerId();

        // Create a FileReader to read the content of the selected file
        const reader = new FileReader();
        reader.onload = (e) => {
            const fileContent = e.target?.result as string;
            const rows = fileContent.split(/\r?\n/);
            const hasContent = rows.some(row => row.trim() !== '');

            if (!hasContent) {
                // If the file has no meaningful content, trigger an error
                this.selectedFile = undefined;
                console.error('Empty file uploaded!');
                this.alertData = {
                    alertMessage: 'Empty file uploaded!',
                    alertVisible: 'error'
                };
                return; // Exit if the file is empty
            }

            // Proceed with the upload if the file is not empty
            this.premiseMasterService.shiftMasterBulkUpload(this.selectedFile as File, custID, userID, entityID).subscribe({
                next: (response) => {
                    debugger;
                    console.log("shift master upload popup", response);
                    const data = response.body;
                    this.loading = true;

                    if (data.status === 'Invalid headers') {
                        this.selectedFile = undefined;
                        this.alertData = {
                            alertMessage: 'Invalid headers!',
                            alertVisible: 'error'
                        };
                        this.loading = false;
                        console.log('File not uploaded ', response);
                    } else if (response.status === 200 && data.status === 'error') {
                        this.selectedFile = undefined;
                        this.downloadErrorFile(response.body.filePath, response?.body.sasToken);
                        this.alertData = {
                            alertMessage: 'Uploaded file contains an error...',
                            alertVisible: 'error'
                        };
                    } else if (data.status === 'Invalid Template') {
                        this.alertData = {
                            alertMessage: 'Invalid Template!',
                            alertVisible: 'error'
                        };
                        this.loading = false;
                        console.log('Invalid headers!');
                    } else if (response.status === 200 && data.status === 'success') {
                        this.alertData = {
                            alertMessage: 'File Uploaded Successfully.',
                            alertVisible: 'success'
                        };
                        this.loading = false;
                        console.log('File uploaded successfully', response);
                    }
                },
                error: (error) => {
                    console.log('error', error);
                    this.selectedFile = undefined;
                    this.alertData = {
                        alertMessage: 'An error occurred during the upload.',
                        alertVisible: 'error'
                    };
                    this.loading = false;
                }
            });
        };

        // Read the file as text to check for content
        reader.readAsText(this.selectedFile);
    } else {
        this.alertData = {
            alertMessage: 'You have not chosen any file.',
            alertVisible: 'error'
        };
        console.warn('You have not chosen any file.');
    }
}

  downloadErrorFile(FilePath: string, sasToken: string) {
    const blobUrl = `${FilePath}?${sasToken}`;
    const a = document.createElement('a');
    a.href = blobUrl;
    const filename = FilePath.split('/').pop() || 'downloaded_file';
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

}





