<div class="row colpadding0 " style="margin-top: 10px;">
    <div class="col-md-12 colpadding0" >
        <app-dropdown-list class="dropdowns" [data]="dropdownData" [selectedValue]="defaultFilterValue"
            [width]="'200px'" textField="text" valueField="value" (valueChange)="onDropdownValueChange($event)">
        </app-dropdown-list>
        <app-dropdown-list *ngIf="challanTypeShow" class="dropdowns" [data]="ChallanTypedropdownData"
            [selectedValue]="ChallandefaultFilterValue" [width]="'125px'" textField="text" valueField="value"
            (valueChange)="onDropdownValueChangeChallanType($event)">
        </app-dropdown-list>
        <kendo-multiselecttree   class="dropdowns" #multiselectTree placeholder="Select Branch"
            [popupSettings]="popupSettings" kendoMultiSelectTreeExpandable
            [kendoMultiSelectTreeHierarchyBinding]="BranchDropdown" childrenField="children" textField="name"
            valueField="id" [tagMapper]="tagMapper" style="width: 250px; margin-right: 9px;"
            (valueChange)="OnMultiSelectChange($event)" [expandedKeys]="['0']" class="f-textbox multi-box">
        </kendo-multiselecttree>
        <app-kendo-datepicker aria-hidden="true" class="dropdowns" [(value)]="DateOfExempting"
            (valueChange)="onDateChange($event)" [format]="'dd-MM-yyyy'" [placeholder]="placeholder" [width]="'200px'"
            [class.input-invalid]="!DateOfExempting && showValidationErrors">
        </app-kendo-datepicker>

        <button (click)="applyAction()" class="figma-btn-white Actionbutton">
            <img src="../assets/vectors/ApplyFilter.svg" alt="">
        </button>
        <button (click)="ClearAction(multiselectTree, searchInput)" class="figma-btn-white Actionbutton">
            <img src="../assets/vectors/ClearFilter.svg" alt="">
        </button>
        <button *ngIf="showSave" (click)="onActionClick($event)" class="figma-btn-blue Actionbutton">
            Save
        </button>
    </div>
</div>

<div class="row   colpadding0 " style="margin-top: 10px;">
    <div class="col-md-12 colpadding0">
        <app-dropdown-list class="dropdowns" [data]="PerformerdropdownData"
            [selectedValue]="ChallandefaultFilterPerformer" [width]="'200px'" textField="text" valueField="value"
            (valueChange)="onDropdownValueChangePerformer($event)">
        </app-dropdown-list>
        <app-dropdown-list class="dropdowns" [data]="ReviewerdropdownData"
            [selectedValue]="ChallandefaultFilterReviewer" [width]="'200px'" textField="text" valueField="value"
            (valueChange)="onDropdownValueChangeReviewer($event)">
        </app-dropdown-list>
        <input #searchInput type="text" class="f-textbox dropdowns" (keyup)="applyFilter($event)" placeholder="Type To Search">
    </div>
</div>

<kendo-grid class="custom-grid" [data]="gridData" [columnMenu]="{ filter: true, sort: true }" [pageSize]="pageSize" [pageable]="true"
    (pageChange)="pageChange($event)" [loading]="loadingGrid" [sortable]="true" [skip]="skip" [resizable]="true">
    <ng-template *ngIf="loadingGrid" kendoGridLoadingTemplate>
        <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
    </ng-template>
    <kendo-grid-column [width]="92"  >
        <ng-template kendoGridHeaderTemplate>
            <div class="header-column">
                <input type="checkbox"[(ngModel)]="allSelected"
                    (change)="toggleSelectAll($event)" />
            </div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <div >
                <input type="checkbox"  [(ngModel)]="dataItem.selected"
                    [disabled]="dataItem.disabled" (change)="onItemSelectionChange(dataItem)" />
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="id" title="ComplianceID" [width]="100" class="cell-content">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.id" >
                {{dataItem.id}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="shortForm" title="Compliance" [width]="200" class="cell-content">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.shortForm">
                {{dataItem.shortForm}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="branchName" title="Branch" [width]="80" class="cell-content">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.branchName">
                {{dataItem.branchName}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="sM_Name" title="State" [width]="80" class="cell-content">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.sM_Name">
                {{dataItem.sM_Name}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="actName" title="Act" class="cell-content" [width]="200">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.actName" >
                {{dataItem.actName}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="requiredForms" title="Form" [width]="80" class="cell-content">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.requiredForms">
                {{dataItem.requiredForms}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="EstablishmentType" title="Establishment Type" [width]="100" class="cell-content">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.EstablishmentType">
                {{dataItem.EstablishmentType}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="ActApplicability" title="Act Applicability" [width]="100" class="cell-content">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" [title]="dataItem.ActApplicability">
                {{dataItem.ActApplicability}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate let-total="total" let-currentPage="currentPage">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-input></kendo-pager-input>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>
</kendo-grid>

<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270"  [Data]="alertData"
    (close)="closeAlert()" (confirm)="OnSave()"></app-alert-popup>