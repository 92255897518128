import { Component, Input } from '@angular/core';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { NgFor, NgIf } from '@angular/common';
import { UserService } from '../../../service/user.service';
import { GstuploadService } from '../../../service/gstupload.service';
import { GstUploadPopupComponent } from '../../popups/gst-upload-popup/gst-upload-popup.component';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { BlobService } from '../../../service/blob.service';
import * as XLSX from 'xlsx';
import { GridModule, PageChangeEvent } from '@progress/kendo-angular-grid';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-gst-upload',
  standalone: true,
  imports: [IndicatorsModule, GridModule, DropdownListComponent, NgIf, NgFor, GstUploadPopupComponent, AlertPopupComponent],
  templateUrl: './gst-upload.component.html',
  styleUrl: './gst-upload.component.css'
})
export class GstUploadComponent {
  selectedRadioValue: any
  rowData: any;
  originalData: any;
  clientID: any;
  public buttonCount = 2;
  ClientGstDropdownData: any[] = [];
  selectedClient = { text: 'Select Client', value: '' };
  public isAddNewVisible: boolean = false;
  public pageSize: number = 10;
  take = 10;
  skip = 0;
  page = 1;
  public showGrid: boolean = false;
  public sizes = [5, 10, 20, 50];
  public alertData = { alertMessage: '', alertVisible: '' };
  GetActiveGSTUploadData: any;
  isUploadButtonEnabled = false;
  loading: boolean = false;



  constructor(private GstuploadService: GstuploadService, private user: UserService, private blobService: BlobService) { }

  ngOnInit(): void {
    document.getElementById('rdDow')?.click();
    this.getClientsMaster();



  }


  selectedMeasurementSystem!: string;

  onMeasurementSystemChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.selectedMeasurementSystem = target.value;
  }

  isRowDataEmpty(): boolean {
    // Check if rowData is an empty object
    // return !this.rowData || Object.keys(this.rowData).length === 0;

    return !this.rowData || Object.keys(this.rowData).length === 0 ||
      this.rowData.some((item: any) => !item.poType);
  }
  onDropdownValueChange(data: any) {


    console.log('data', data)
    this.clientID = data?.value ? data?.value : 'all';
    console.log('client', this.clientID)
    this.selectedClient = data; // Update the selected client
    this.isUploadButtonEnabled = !!data;

    this.GetActiveGSTUploadedDetailsbasedOnClientId(this.clientID);
    this.showGrid = true;
  }



  enableUploadButton() {
    if (this.selectedClient) {
      this.isUploadButtonEnabled = true;
    }
  }

  getClientsMaster() {
    this.GstuploadService.getClientsGstMaster().subscribe({
      next: (response) => {
        const data = response.body;
        this.ClientGstDropdownData = [
          { text: 'Select Client', value: '' },
          ...data.map((item: any) => ({
            text: item.text,
            value: item.value
          }))
        ];
      },
      error: (error) => {
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }

  openAddNewPopup() {//subcode
    this.isAddNewVisible = true;
    // this.rowData = [];
    // this.password = '';
    // this.IsUpdate=false;


  }
  handleClosePopup() {
    this.isAddNewVisible = false;
    //  this.GetEcrMasterGrid();
  }
  closeAlert() {
    this.alertData = { alertMessage: '', alertVisible: '' };
  }


  GetActiveGSTUploadedDetailsbasedOnClientId(clientID: any) {
    this.showGrid = true;
    console.log(clientID, 'clienttttttttttt')
    let payload = {
      ClientId: clientID ? clientID : 'all'

    }
    this.loading = true;
    this.GstuploadService.GetClientGSTReports(payload).subscribe({
      next: (response) => {

        const data = response.body;
        this.rowData = data;
        this.originalData = data;
        // this.originalData = {
        //   data: data.map((item: any) => ({
        //     ...item,

        //   })),
        //   total: data.length
        // };
        // this.rowData =  {
        //   data: this.originalData.data.slice(this.skip, this.skip + this.pageSize),
        //   total: this.originalData.length
        // };

        debugger
        // this.rowData = this.originalData.slice(this.skip, this.skip + this.pageSize);
        // total: this.originalData.length
        this.rowData['total'] = this.originalData.length

        console.log("rowData", this.rowData);
        this.loading = false;
      },

      error: (error) => {
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }
  exportToExcel(clientID: any): void {
    console.log(clientID, 'clientqqqqqqqqqqqqqqqqqqqqqqqqqq')
    let payload = {
      ClientId: clientID ? clientID : 'all'

    }
 
    this.loading = true
    this.GstuploadService.exportToExcel(payload).subscribe(
      (response: any) => {
        console.log('responseexcell', response);
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(response.body);

        // Create a new workbook and append the worksheet
        const workbook: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Generate a file and trigger a download
        XLSX.writeFile(workbook, 'Report.xlsx');
        // const blobLink = response.body.filePath;
        // const blobName = this.blobService.extractBlobName(blobLink);
        // setTimeout(() => {
        //   this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
        //     const sasToken = tokenResponse.result;
        //     this.blobService.downloadFile(blobName, sasToken);
        //     this.loading = false;
        //   });
        // }, 5000);
        this.loading = false;
      },
      (error) => {
        console.error('Error fetching blob link:', error);
        this.loading = false;
      });
  }

  public pageChange(event: PageChangeEvent): void {
    debugger
    console.log('Page Change Event Received:', event);
    // this.skip = event.skip;
    // this.pageSize = event.take;
    // this.loadGridData();
    console.log('Page Change Event Received:', event);
    this.skip = event.skip;
    if (event.take == Infinity) {
      this.pageSize = this.originalData.total
    } else {
      this.pageSize = event.take;
    }
    // this.rowData = {
    //   data: this.originalData.data.slice(this.skip, this.skip + this.pageSize),
    //   total: this.originalData.length
    // };
    // this.rowData = this.originalData.slice(this.skip, this.skip + this.pageSize);
    this.rowData['total'] = this.originalData.length
  }
}