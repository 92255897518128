import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { AddShiftMasterDetailsComponent } from '../add-shift-master-details/add-shift-master-details.component';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { LocationMasterServiceService } from '../../../service/location-master-service.service';
import { PremiseMasterService, Temp_LocationMaster } from '../../../service/premise-master.service';
import { UserService } from '../../../service/user.service';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropDownListModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { KendoDatepickerComponent } from '../../../components/kendo-datepicker/kendo-datepicker.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-premise-master-add-edit-popup',
  standalone: true,
  imports: [NgIf, AlertPopupComponent, NgClass, ReactiveFormsModule, AddShiftMasterDetailsComponent, ComboBoxComponent, FormsModule, DialogModule, DropDownListModule, DropdownListComponent, KendoDatepickerComponent, MultiSelectModule, IndicatorsModule],
  templateUrl: './premise-master-add-edit-popup.component.html',
  styleUrl: './premise-master-add-edit-popup.component.css'
})
export class PremiseMasterAddEditPopupComponent {
  @Input() branchId: any;
  @Input() max!: Date;
  rowData: any;
  form!: FormGroup;
  loading = false
  public openShiftDetails: boolean = false
  public defaultState = "Select State";
  public placeholder: string = 'DD-MM-YYYY';
  public selectedState: { id: any, name: any } = { id: '', name: '' }
  public selectedLocation: { id: any, name: any } = { id: '', name: '' }
  public selectedCity?: any
  selectedLwfState: { id: any, name: any } = { id: '', name: '' }
  selectedPtState: { id: any, name: any } = { id: '', name: '' }
  public allStatesData: any[] = [];
  public defaultLocation = "Select Location";
  public allLocationData: any[] = [];
  public allAnchorData: any[] = [];
  public selectedAnchor: { id: any, name: any } = { id: '', name: '' }
  public defaultAnchor = "Select Location Anchor";
  public selectedDays: { text: any; value: any }[] = [];
  public rcValidFrom!: Date | null;
  public rcValidTo!: Date | null;
  public branchEndDate!: Date | null;
  public commencementDate!: Date | null;
  public Entity: string = ''
  public branchCode: string = ''
  public branchName: string = ''
  public branchAddress: string = ''
  public employerName: string = ''
  public employerAddress: string = ''
  public managerName: string = ''
  public managerAddress: string = ''
  public companyPhnNo: string = ''
  public hrPhnNo: string = ''
  public natureOfBusiness: string = ''
  public municipality: string = ''
  public locationCode: string = ''
  public formattedValues: string = ''
  public workHoursFrom: string = 'Select'
  public workHoursTo: string = 'Select'
  public intervalsFrom: string = 'Select'
  public intervalsTo: string = 'Select'
  public pfCode: string = ''
  ESICCode!: string;
  public hrContactPerson: string = ''
  public hrMailId: string = ''
  public nicCode: string = ''
  public licenceNo: string = ''
  public classificationOfEst: string = ''
  public lin: string = ''
  public hrFirstMail: string = ''
  public hrFirstPhnNo: string = ''
  public rcNo: string = ''
  public employerDesignation: string = ''
  public jurisdiction: string = ''
  public district: string = ''
  cateringApplicability: boolean = false;
  showValidation: boolean = false;
  public noOfEmployee: number = 0
  errorMessage: string = '';
  defaultItem = { text: 'Select', value: null };
  selectedOfficeType: any = { text: 'Select', value: '' }
  selectedEstablishmentType: any = { text: 'Select', value: '' }
  selectedBusinessType = { text: 'Select', value: '' }
  SelectedPowerToImpose = { text: 'Select', value: '' }
  selectedMaintainType = { text: 'Select', value: '' }
  selectedSectionOfAct = { text: 'Select', value: '' }
  selectedStatusType = { text: 'Select', value: '' };
  selectedTradeLicence = { text: 'Select', value: '' }
  selectedEDLI = { text: 'Select', value: '' }
  public selectedDaysFormatted: string = '';
  officeTypes = [
    { text: 'Select', value: '' },
    { text: 'Branch', value: 'Branch' },
    { text: 'Factory', value: 'Factory' },
    { text: 'Head Office', value: 'HeadOffice' }
  ];
  establishmentTypes = [
    { text: 'Select', value: '' },
    { text: 'Shop & Establishment', value: 'SEA' },
    { text: 'Factory', value: 'FACT' },
    { text: 'Both', value: 'SF' }
  ];
  public weekDays: { text: string, value: string }[] = [
    { text: 'Monday', value: 'MON' },
    { text: 'Tuesday', value: 'TUE' },
    { text: 'Wednesday', value: 'WED' },
    { text: 'Thursday', value: 'THU' },
    { text: 'Friday', value: 'FRI' },
    { text: 'Saturday', value: 'SAT' },
    { text: 'Sunday', value: 'SUN' }
  ];
  public filteredWeekDays = [...this.weekDays];

  public workTimings: { text: string, value: string }[] = [
    { text: 'Select', value: '' },
    { text: 'Shift', value: 'Shift' },
    { text: 'Standard', value: 'Standard' }
  ];
  public selectedWorkTiming: any = { text: '', value: '' }
  timeIntervals: string[] = [];
  public businessTypes: { text: string, value: string }[] = [
    { text: 'Select', value: '' },
    { text: 'IT', value: 'IT' },
    { text: 'ITES', value: 'ITES' },
    { text: 'NON-IT', value: 'NON-IT' }
  ];

  public Types: { text: string, value: string }[] = [
    { text: 'Select', value: '' },
    { text: 'YES', value: 'Y' },
    { text: 'NO', value: 'N' }
  ];

  public sectionOfActOptions: { text: string, value: string }[] = [
    { text: 'Select', value: '' },
    { text: '2m(i)', value: '2m(i)' },
    { text: '2m(ii)', value: '2m(ii)' },
    { text: 'Section-85', value: 'Section-85' }
  ];
  public statusType: { text: string, value: string }[] = [
    { text: 'Select', value: '' },
    { text: 'Active', value: 'A' },
    { text: 'Inactive', value: 'I' }
  ];
  @Output() close = new EventEmitter<void>();
  public showValidationErrors = false;
  alertData = { alertMessage: '', alertVisible: '' }
  showpfCode = false;
  originalpfCode = '';
  showESICCode = false;
  private originalESIC: string = '';

  constructor(private fb: FormBuilder, private locationMaster: LocationMasterServiceService, private cdr: ChangeDetectorRef, private premiseMasterService: PremiseMasterService, private user: UserService) {
    this.form = this.fb.group({
      branchName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z\s]+$/)]],
      branchAddress: ['', [Validators.required]],
      pfCode: ['', [Validators.minLength(22), Validators.maxLength(22), Validators.pattern(/^[a-zA-Z0-9]{1,22}$/)]],
      ESICCode: ['', [Validators.pattern(/^\d{17}$/)]],
      employerName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]],
      employerAddress: ['', [Validators.required]],
      managerName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]],
      managerAddress: ['', [Validators.required]],
      companyPhnNo: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      hrMailId: ['', [Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]],
      hrContactPerson: ['', [Validators.pattern(/^[a-zA-Z\s]*$/)]],
      hrPhnNo: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      hrFirstMail: ['', [Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]],
      hrFirstPhnNo: ['', [Validators.pattern(/^\d{10}$/)]],
      natureOfBusiness: ['', [Validators.required]],
      rcNo: ['', [Validators.pattern(/^\d{10}$/)]],
      municipality: ['', [Validators.required]],
      nicCode: ['', [Validators.pattern('^[a-zA-Z0-9]*$')]],
      classificationOfEst: ['', [Validators.pattern(/^[a-zA-Z\s]*$/)]],
      district: ['', [Validators.pattern(/^[a-zA-Z\s]*$/)]]
    });

  }
  alphabetOnlyValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value || '';
    const isValid = /^[a-zA-Z\s]*$/.test(value); // Regex allows only alphabets and spaces
    return isValid ? null : { alphabetOnly: true };
  }

  ngOnInit() {
    this.fetchStateMasters();
    this.fetchAnchor()
    this.generateTimeIntervals()
    this.InitData();
    this.branchName = this.rowData?.cM_ClientID
  }
  // allowOnlyLettersAndSpaces(event: KeyboardEvent): void {
  //   const regex = /^[a-zA-Z\s]*$/;
  //   const inputChar = String.fromCharCode(event.charCode);

  //   if (!regex.test(inputChar)) {
  //     event.preventDefault(); // Prevent the key press if it's not a letter or space.
  //   }
  // }
  allowOnlyLettersAndSpaces(event: KeyboardEvent): void {
    const regex = /^[a-zA-Z\s]*$/;
    const inputChar = String.fromCharCode(event.charCode);
    const inputElement = event.target as HTMLInputElement;
    const isFirstChar = inputElement.selectionStart === 0;

    if (!regex.test(inputChar) || (inputChar === ' ' && isFirstChar)) {
      event.preventDefault();
    }
  }
  blockInvalidRcNoCharacters(event: KeyboardEvent): void {
    const allowedKeys = ['Backspace', 'Tab', 'Enter', 'Escape', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Home', 'End'];
    if (allowedKeys.includes(event.key)) {
      return;
    }
    const inputChar = event.key;
    const isValidCharacter = /^[a-zA-Z0-9]*$/.test(inputChar);
    if (!isValidCharacter) {
      event.preventDefault();
    }
  }
  blockInvalidLinCharacters(event: KeyboardEvent): void {
    const allowedKeys = ['Backspace', 'Tab', 'Enter', 'Escape', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Home', 'End'];
    if (allowedKeys.includes(event.key)) {
      return;
    }

    // Get the character that is being typed
    const inputChar = event.key;

    // Regex to check for valid alphanumeric characters (letters and numbers only)
    const isValidCharacter = /^[a-zA-Z0-9]*$/.test(inputChar);

    // Prevent the default action if the character is invalid
    if (!isValidCharacter) {
      event.preventDefault();
    }
  }
  blockInvalidCharacters(event: KeyboardEvent): void {
    const allowedKeys = ['Backspace', 'Tab', 'Enter', 'Escape', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Home', 'End'];
    if (allowedKeys.includes(event.key)) {
      return;
    }
    const inputElement = event.target as HTMLInputElement;
    const isFirstChar = inputElement.selectionStart === 0;
    const isLetterOrSpace = /^[a-zA-Z\s]*$/.test(event.key);
    if ((event.key === ' ' && isFirstChar) || !isLetterOrSpace) {
      event.preventDefault();
    }
  }
  blockInvalidEmailCharacters(event: KeyboardEvent): void {
    const allowedKeys = ['Backspace', 'Tab', 'Enter', 'Escape', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Home', 'End'];
    if (allowedKeys.includes(event.key)) {
      return;
    }
    const inputChar = event.key;
    const isValidCharacter = /^[a-zA-Z0-9@.]*$/.test(inputChar);
    if (!isValidCharacter) {
      event.preventDefault();
    }
  }
  validateMobileNo(event: any): void {
    const input = event.target.value;
    event.target.value = input.replace(/[^0-9]/g, '');
    this.form.get('mobileNo')?.setValue(event.target.value);
  }
  preventLeadingSpace(field: 'branchAddress' | 'employerAddress' | 'managerAddress'): void {
    if (this[field] && this[field].startsWith(' ')) {
      this[field] = this[field].trimStart();
      this.form.get(field)?.setValue(this[field], { emitEvent: false });
    }
  }

  formatESICCode(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input) {
      return;
    }
    let value = input.value ? input.value.replace(/[^0-9]/g, '') : '';
    this.originalESIC = value;
    if (value.length === 17) {
      this.showESICCode = false;
      this.updateESICNumberDisplay();
    } else {
      this.showESICCode = true;
      this.updateESICNumberDisplay();
    }
  }


  maskESICCode(esicCode: string): string {
    if (esicCode.length === 17) {
      setTimeout(() => {
        if (this.ESICCode.length === 17) {
          this.showESICCode = false;
          this.updateESICNumberDisplay();
        }
      }, 5000);
      this.form.get('ESICCode')?.disable();
      return esicCode.replace(/^(.{13})(.{4})$/, 'XXXXXXXXXXXXX$2');
    }
    return esicCode;
  }

  toggleESICCodeVisibility(): void {
    this.showESICCode = !this.showESICCode;
    this.updateESICNumberDisplay();
  }

  updateESICNumberDisplay(): void {
    const currentESICNumber = this.form.get('ESICCode');
    if (currentESICNumber) {
      if (this.showESICCode) {
        currentESICNumber.setValue(this.originalESIC);
        currentESICNumber.enable();
      } else {
        const maskedESIC = this.maskESICCode(this.originalESIC);
        currentESICNumber.setValue(maskedESIC);
      }
    }
  }
  formatpfCode(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input) {
      return;
    }

    let value = input.value ? input.value.toUpperCase().replace(/[^a-zA-Z0-9]/g, '') : '';
    this.originalpfCode = value;

    if (value.length === 22) {
      this.showpfCode = false;
      this.updatepfCodeDisplay();
    } else {
      this.showpfCode = true;
      this.updatepfCodeDisplay();
    }
  }

  maskpfCode(pfCode: string | null | undefined): string {
    if (!pfCode) {
      return '';
    }
    if (pfCode.length === 22) {
      setTimeout(() => {
        if (this.pfCode.length === 22) {
          this.showpfCode = false;
          this.updatepfCodeDisplay();
        }
      }, 5000);
      this.form.get('pfCode')?.disable();
      return pfCode.replace(/^(.{18})(.{4})$/, 'XXXXXXXXXXXXXXXXXX$2');
    }
    return pfCode;
  }

  togglepfCodeVisibility(): void {
    this.showpfCode = !this.showpfCode;
    this.updatepfCodeDisplay();
  }

  updatepfCodeDisplay(): void {
    const currentpfCode = this.form.get('pfCode');
    if (this.showpfCode) {
      currentpfCode?.setValue(this.originalpfCode);
      currentpfCode?.enable();
    } else {
      currentpfCode?.setValue(this.maskpfCode(this.originalpfCode));
    }
  }


  initializepfCode(pfCode: string | null | undefined): string {
    if (!pfCode) {
      this.showpfCode = true;
      this.originalpfCode = '';
      return '';
    }

    this.showpfCode = true;
    this.originalpfCode = pfCode;
    return pfCode;
  }

  InitData() {
    this.premiseMasterService.getDataForEdit(this.branchId).subscribe({
      next: (response) => {
        const data = response.body;
        this.rowData = data;
        this.Entity = this.rowData.avacoM_BranchName;
      },
      error: (error: any) => {
        console.error('Error fetching data', error);
      }
    })

  }
  validateForm(): boolean {
    this.showValidationErrors = true;
    let isValid = false;

    if (this.rcValidTo && this.rcValidFrom && this.rcValidFrom >= this.rcValidTo) {
      this.alertData = { alertMessage: 'RC Valid To must be greater than RC Valid From.', alertVisible: 'error' };
      this.rcValidFrom = null;
      return isValid;
    }
    if (this.rcValidFrom && this.rcValidTo && this.rcValidTo <= this.rcValidFrom) {
      this.alertData = { alertMessage: 'RC Valid To must be greater than RC Valid From.', alertVisible: 'error' };
      this.rcValidTo = null;
      return isValid;
    }

    if (this.form.valid && this.selectedState.id && this.selectedLocation.id && this.selectedOfficeType.value && this.selectedEstablishmentType.value && this.selectedDays.length && this.selectedWorkTiming.value && this.selectedBusinessType.value
      && this.selectedMaintainType.value && this.SelectedPowerToImpose.value && this.selectedTradeLicence.value && this.selectedStatusType.value
      && this.selectedAnchor) {
      if (this.selectedWorkTiming.value === 'Standard' && this.workHoursFrom && this.workHoursTo && this.intervalsFrom && this.intervalsTo) {
        isValid = true;
      } else if (this.selectedWorkTiming.value != 'Standard') {
        isValid = true;
      } else {
        return this.form.valid && isValid;
      }
      this.form.get('pfCode')?.setValue(this.originalpfCode)
      this.form.get('ESICCode')?.setValue(this.ESICCode)
      setTimeout(() => {
        this.showpfCode = false;
        this.updatepfCodeDisplay();
        isValid = true;
      }, 500);
    } else {
      this.form.markAllAsTouched();
      isValid = false;
      return this.form.valid && isValid;
    }

    return this.form.valid && isValid;

  }

  generateTimeIntervals() {
    const startTime = new Date();
    startTime.setHours(0, 0, 0, 0);
    for (let i = 0; i < 24 * 4; i++) {
      const hours = startTime.getHours();
      const minutes = startTime.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const formattedTime =
        `${('0' + (hours % 12 || 12)).slice(-2)}:${('0' + minutes).slice(-2)} ${ampm}`;
      this.timeIntervals.push(formattedTime);
      startTime.setMinutes(startTime.getMinutes() + 15);
    }
  }

  fetchStateMasters(filterText: string = ''): void {
    this.locationMaster.getStateMaster().subscribe({
      next: (response) => {
        const data = response.body;
        if (data && Array.isArray(data)) {
          // Filter states based on the starting letters of `filterText`
          this.allStatesData = data
            .map((item: any) => ({
              id: item.sM_Code,
              name: item.sM_Name
            }))
            .filter(state =>
              state.name.toLowerCase().startsWith(filterText.toLowerCase())
            );
          this.defaultState = "Select State";
        } else {
          console.error('Unexpected API response format:', data);
        }
      },
      error: (error) => {
        console.error('Error fetching state masters:', error);
      }
    });
  }
  fetchLocation(filterText: string = ''): void {
    if (!this.selectedState) {
      console.error('State is not selected.');
      return;
    }

    this.locationMaster.getStateLocationMasterForDD(this.selectedState.id).subscribe({
      next: (response) => {
        const data = response.body;
        if (data && Array.isArray(data)) {
          this.allLocationData = data
            .map((item: any) => ({
              id: item.value,
              name: item.key
            }))
            .filter(location =>
              location.name.toLowerCase().startsWith(filterText.toLowerCase())
            );

          this.defaultLocation = "Select Location";
        } else {
          console.error('Unexpected API response format:', data);
        }
      },
      error: (error) => {
        console.error('Error fetching locations:', error);
      }
    });
  }
  // fetchAnchor() {
  //   const distributorId = 94;
  //   const customerId = this.user.getCustomerId();

  //   this.premiseMasterService.getAllLocAnchor(distributorId, customerId).subscribe({
  //     next: (response) => {

  //       const data = response.body
  //       if (data && Array.isArray(data)) {
  //         this.allAnchorData = data.map((item: any) => ({
  //           id: item.id,
  //           name: item.userName
  //         }));
  //         this.defaultAnchor = "Select Location Anchor";
  //       } else {
  //         console.error('Unexpected API response format:', data);
  //       }
  //     }
  //   })
  // }
  fetchAnchor() {
    const distributorId = 94;
    const customerId = this.user.getCustomerId();  
    this.premiseMasterService.getAllLocAnchor(distributorId, customerId).subscribe({
      next: (response) => {
        const data = response.body;
        console.log('Raw anchor data from API:', data);    
        if (data && Array.isArray(data)) {
          const uniqueUsernames = new Set();
          this.allAnchorData = [];  
          data.forEach((item: any) => {
            const username = item.userName.trim(); 
            if (!uniqueUsernames.has(username)) {
              uniqueUsernames.add(username); 
              this.allAnchorData.push({ id: item.id, name: username }); 
            }
          });  
          this.defaultAnchor = "Select Location Anchor";
          console.log('Filtered anchor data (no duplicates):', this.allAnchorData);
                } else {
          console.error('Unexpected API response format:', data);
        }
      },
      error: (error) => {
        console.error('Error fetching anchor data:', error);
      }
    });
  }

  DropdownValueChange(value: any) {

    this.selectedState = { name: value.name, id: value.id };


    this.fetchLocation();

    if (this.cateringApplicability) {
      const fakeEvent = { target: { checked: this.cateringApplicability } } as unknown as Event;
      this.validateCateringApplicability(fakeEvent);
    }
  }

  DropDownPTValueChange(value: any) {
    this.selectedPtState = { name: value.name, id: value.id };
  }

  DropdownLWFStateChange(value: any) {
    this.selectedLwfState = { name: value.name, id: value.id };
  }
  DropdownLocationChange(value: any) {

    this.selectedLocation = { name: value.name, id: value.id };;
    this.locationCode = this.selectedLocation.id
  }

  onEstablishmentTypeChange(value: string): void {
    this.selectedEstablishmentType = value;
  }
  onOfficeTypeChange(value: string): void {
    this.selectedOfficeType = value;
  }
  onSelectedEDLI(value: any): void {
    this.selectedEDLI = value;
  }
  onSelectedStatusType(value: any): void {
    this.selectedStatusType = value;
  }
  onSelectedTradeLicence(value: any): void {
    this.selectedTradeLicence = value;
  }
  onSelectedSectionOfAct(value: any): void {
    this.selectedSectionOfAct = value;
  }
  onSelectedPowerToImpose(value: any): void {
    this.SelectedPowerToImpose = value;
  }
  onSelectedMaintainType(value: any): void {
    this.selectedMaintainType = value;
  }
  onSelectedBusinessType(value: any): void {
    this.selectedBusinessType = value;
  }

  onSelectedDays(value: any[]): void {
    debugger;
    this.selectedDays = value;

    const extractedValues = this.selectedDays.map(day => day.value);

    this.formattedValues = extractedValues.join('|');
  }
  public tagMapper(tags: any[]): any[] {
    console.log('tags', tags);

    return tags.length < 3 ? tags : [tags];
  }



  validateCateringApplicability(event: Event): void {
    const checkbox = event.target as HTMLInputElement;

    if (this.selectedState.id === 'TMN' || this.selectedState.id === 'PND') {
      this.showValidation = false;
      this.errorMessage = '';
    } else {

      checkbox.checked = false;
      this.cateringApplicability = false;


      this.showValidation = true;
      this.errorMessage = 'Check this only for Tamilnadu Or Pondicherry';
    }
  }

  onDateChange(value: Date, type: string) {
    const date = new Date(value);
    if (type === 'rcValidFrom') {
      this.rcValidFrom = date;
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (this.rcValidFrom && this.rcValidFrom >= today) {
        this.alertData = { alertMessage: 'RC Valid From cannot be a future date', alertVisible: 'error' };
        this.rcValidFrom = null;
      }
    }
    else if (type === 'rcValidTo') {
      this.rcValidTo = date;
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (this.rcValidTo && this.rcValidTo < today) {
        this.alertData = { alertMessage: 'RC Valid To must be greater than current date.', alertVisible: 'error' };
        this.rcValidTo = null;
      }
    }
    else if (type === 'branchEndDate') {
      this.branchEndDate = date;
      if (this.branchEndDate && this.commencementDate && this.branchEndDate < this.commencementDate) {
        this.alertData = { alertMessage: 'Branch Start Date cannot be a greater than Branch End Date', alertVisible: 'error' };
      }
    }
    else if (type === 'commencementDate') {
      this.commencementDate = date;
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (this.commencementDate && this.commencementDate > today) {
        this.alertData = { alertMessage: 'Branch Start Date cannot be a future date', alertVisible: 'error' };
        this.commencementDate = null;
      }
      else if (this.branchEndDate && this.commencementDate && this.commencementDate > this.branchEndDate) {
        this.alertData = { alertMessage: 'Branch Start Date cannot be greater than the Branch End Date', alertVisible: 'error' };
        this.commencementDate = null;
      }
    }
  }



  onWorkTimingChange(value: string) {

    this.selectedWorkTiming = value
  }
  DropdownAnchorChange(value: any) {

    this.selectedAnchor = { id: value.id, name: value.name };


  }

  savePremiseData() {
    if (!this.validateForm()) {
      return;
    }
    const formatDate = (date: Date): string => {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };
    const branch: Temp_LocationMaster = {
      CustomerID: this.user.getCustomerId(),
      CM_ClientID: this.user.getClientId(),
      AVACOM_BranchName: this.branchName || '',
      CM_State: this.selectedState.id || '',
      CM_City: this.locationCode || '',
      CM_Address: this.branchAddress || '',
      CL_LWF_State: this.selectedLwfState.id || '',
      CL_PT_State: this.selectedPtState.id || '',
      CL_PF_Code: this.pfCode || '',
      CL_ESIC_Code: this.ESICCode || '',
      CL_OfficeType: this.selectedOfficeType.value || '',
      CM_EstablishmentType: this.selectedEstablishmentType.value || '',
      CL_EmployerName: this.employerName || '',
      CL_EmployerAddress: this.employerAddress || '',
      CL_ManagerName: this.managerName || '',
      CL_ManagerAddress: this.managerAddress || '',
      CL_CompPhoneNo: this.companyPhnNo || '',
      CL_HRContactPerson: this.hrContactPerson || '',
      CL_HRPhNo: this.hrPhnNo || '',
      CL_HRMailID: this.hrMailId || '',
      CL_HR1stLevelMail: this.hrFirstMail || '',
      CL_HR1stLevelPhNo: this.hrFirstPhnNo || '',
      CL_RCNO: this.rcNo.toString() || '',
      CL_RC_ValidFrom: this.rcValidFrom ? formatDate(new Date(this.rcValidFrom)) : '',
      CL_RC_ValidTo: this.rcValidTo ? formatDate(new Date(this.rcValidTo)) : '',
      CL_NatureofBusiness: this.natureOfBusiness || '',
      CL_weekoffDay: this.formattedValues || '',
      CL_WorkHoursFrom: this.workHoursFrom === 'Select' ? 'N0' : this.workHoursFrom || 'N0',
      CL_WorkHoursTo: this.workHoursTo === 'Select' ? 'N0' : this.workHoursTo || 'N0',
      CL_IntervalsFrom: this.intervalsFrom === 'Select' ? 'N0' : this.intervalsFrom || 'N0',
      CL_IntervalsTo: this.intervalsTo === 'Select' ? 'N0' : this.intervalsTo || 'N0',
      CL_WorkTimings: this.selectedWorkTiming.value || '',
      CM_Status: this.selectedStatusType.value || '',
      CL_LIN: this.lin || '',
      CL_Municipality: this.municipality || '',
      CL_PermissionMaintainingForms: this.selectedMaintainType.value || '',
      CL_RequirePowerforFines: this.SelectedPowerToImpose.value || '',
      CL_BusinessType: this.selectedBusinessType.value || '',
      CL_CommencementDate: this.commencementDate ? formatDate(new Date(this.commencementDate)) : '', CL_ClassificationofEstablishment: this.classificationOfEst || '',
      CL_LicenceNo: this.licenceNo || '',
      CL_NICCode: this.nicCode || '',
      CL_SectionofAct: this.selectedSectionOfAct.value || '',
      CL_District: this.district || '',
      CL_Juridiction: this.jurisdiction || '',
      CL_BranchEndDate: this.branchEndDate ? formatDate(new Date(this.branchEndDate)) : '', CL_TradeLicenceApplicability: this.selectedTradeLicence.value || '',
      CL_EmployerDesignation: this.employerDesignation || '',
      CL_EDLI_Excemption: this.selectedEDLI.value || '',
      CL_LocationAnchor: this.selectedAnchor.id || '',
      FileID: 0,
      RowNo: 0,
      AVACOM_BranchID: this.rowData?.AVACOM_BranchID || 0
    };

    this.loading = true
    this.premiseMasterService.addEditPremiseMaster(branch).subscribe({
      next: (response) => {
        console.log('save response',response);
        
        if (response.status === 200) {

          this.alertData = { alertMessage: 'Details Saved Successfully', alertVisible: 'success' };
          this.loading = false
        }
        else {

          this.alertData = { alertMessage: 'Failed to save data', alertVisible: 'error' };
          this.loading = false
        }
      },
      error: (error) => {
        console.error('Error saving premise data:', error);
        this.alertData = { alertMessage: 'Failed to save data', alertVisible: 'error' };
        this.loading = false
      }
    });
  }


  openShiftMasterDetails() {
    this.openShiftDetails = true;
  }
  handleShiftMasterClose() {
    this.openShiftDetails = false;
  }
  public closePopup(): void {
    this.close.emit();
  }
  closeAlertPopup() {
    if (this.alertData.alertVisible == 'success') {
      this.close.emit();
    }
    this.alertData = { alertMessage: '', alertVisible: '' };
  }
}
