import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { DashboardService } from '../../../service/dashboard.service';
import { UserService } from '../../../service/user.service';
import { GridDataResult, GridModule, PageChangeEvent } from '@progress/kendo-angular-grid';
import { GridComponent } from '../../../components/grid/grid.component';
import { DropDownsModule, MultiSelectModule, MultiSelectTreeComponent } from '@progress/kendo-angular-dropdowns';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { EmployeeReportService } from '../../../service/employee-report.service';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import * as XLSX from 'xlsx';
type Branch = {
  id: number;
  name: string;
  parentID: number | null;
  branchType: string;
  branchStatus: string;
  fullName: string | null;
  children?: Branch[];
};

@Component({
  selector: 'app-dashboard-compliance-popup',
  standalone: true,
  imports: [DialogModule, NgIf, IndicatorsModule, GridModule, GridComponent, MultiSelectModule,
    MultiSelectTreeComponent,
    DropDownsModule,
    TreeViewModule, ComboBoxComponent],
  templateUrl: './dashboard-compliance-popup.component.html',
  styleUrl: './dashboard-compliance-popup.component.css'
})
export class DashboardCompliancePopupComponent implements OnInit, OnChanges {


  @Input() year: any = {};
  @Input() acts: any[] = [];
  @Input() Type: any;
  @Output() close = new EventEmitter<void>();

  loading: boolean = false;

  public gridData: GridDataResult = { data: [], total: 0 };
  public pageSize: number = 10;
  public page: number = 1;
  public buttonCount = 2;
  public sizes = [5, 10, 20, 50];
  take = 10;
  skip = 0;

  gridData1: any[] = [];

  popupSettings: any = { width: '280px' };
  public BranchDropdown: Branch[] = [];

  selectedAct: any;
  actId: string = ""

  columns :any;

  branchIds: string = '';

  constructor(private dashboardService: DashboardService, private userService: UserService,
    private employeeReportService: EmployeeReportService,) {

  }

  ngOnInit(): void {

    console.log('acts', this.acts);
    console.log('Type', this.Type);
    if(this.Type === 'Compliance'){
      this.getCompliancePopRecordDetails();
      this.getBranchDropdownData();
    } else if(this.Type === 'Location' ){
      this.parentsColumns()
      this.getLocation()
    }
    
  }

  public closePopup(): void {
    this.close.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.year = changes['year']['currentValue']
  }

  getCompliancePopRecordDetails() {
    this.dashboardService.getCompliancePopupRecords(this.userService.getClientId(), this.userService.getUserId(), this.userService.getRole(), this.year, this.skip, this.take, this.branchIds, this.actId).subscribe({
      next: (response) => {
        const data = response.body;
        this.gridData = data;
        this.gridData1 = data?.data;
      },
      error: (err) => {
        console.log('Error', err);
      }
    });
  }

  getBranchDropdownData() {
    this.employeeReportService.getBranchDropdown(this.userService.getClientId()).subscribe({
      next: (response) => {
        const data = response.body;
        if (response && Array.isArray(data)) {
          this.BranchDropdown = this.buildHierarchy(data);

          const maxLength = this.calculateMaxTextLength(this.BranchDropdown);

          const calculatedWidth = Math.max(280, maxLength * 10);
          this.popupSettings = { width: `${calculatedWidth}px` };
        } else {
          console.error('Unexpected data format', response);
        }
      },
      error: (error) => {
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }

  buildHierarchy(data: Branch[]): Branch[] {
    const grouped: { [key: number]: Branch[] } = data.reduce((acc, item) => {
      (acc[item.parentID || -1] = acc[item.parentID || -1] || []).push(item);
      return acc;
    }, {} as { [key: number]: Branch[] });

    function buildTree(parentID: number | null): Branch[] {
      return (grouped[parentID || -1] || []).map(item => ({
        ...item,
        children: buildTree(item.id)
      }));
    }

    return buildTree(null);
  }

  calculateMaxTextLength(data: Branch[]): number {
    let maxLength = 0;

    const findMaxLength = (items: Branch[]) => {
      items.forEach(item => {
        maxLength = Math.max(maxLength, item.name.length);
        if (item.children && item.children.length) {
          findMaxLength(item.children);
        }
      });
    };

    findMaxLength(data);
    return maxLength;
  }
  public tagMapper(tags: any[]): any[] {
    return tags.length < 3 ? tags : [tags];
  }

  OnMultiSelectChange(value: any) {
    if (Array.isArray(value)) {
      this.branchIds = value.map(item => item.id).join(',');
    } else {
      console.error('Expected an array of selected items.');
    }
  }

  public applyFilter(): void {
    this.skip = 0;
    this.getCompliancePopRecordDetails();
  }

  public clearFilter(multiselectTree: any): void {
    this.skip = 0;
    this.branchIds = '';
    this.actId = '';
    multiselectTree.value = [];
    multiselectTree.reset();
    this.getCompliancePopRecordDetails();
  }

  getActsData() {
    this.employeeReportService.getBranchDropdown(this.userService.getClientId()).subscribe({
      next: (response) => {
        const data = response.body;
        if (response && Array.isArray(data)) {
          this.BranchDropdown = this.buildHierarchy(data);

          const maxLength = this.calculateMaxTextLength(this.BranchDropdown);

          const calculatedWidth = Math.max(280, maxLength * 10);
          this.popupSettings = { width: `${calculatedWidth}px` };
        } else {
          console.error('Unexpected data format', response);
        }
      },
      error: (error) => {
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }

  onActSelection(value: any) {
    this.selectedAct = { key: value.key, value: value.value };
    this.actId = value.key;
  }



onView(value: any): void {
  console.log('check')
  console.log('val',value)
}


public pageChange(event: PageChangeEvent): void {
  debugger
  this.skip = event.skip;
  if(event.take==Infinity){
    this.pageSize = this.gridData.total;
  }else{
    this.pageSize = event.take;
  }
  this.page = (this.skip / this.pageSize) + 1;
  
}

exportToExcel() {
  this.dashboardService.getCompliancePopupRecords(this.userService.getClientId(), this.userService.getUserId(), this.userService.getRole(), this.year, this.skip, this.take, this.branchIds, this.actId).subscribe({
    next: (response) => {
     
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(response.body.data);

        const workbook: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        XLSX.writeFile(workbook, 'Report.xlsx');
        
        this.loading = false;
    },
    error: (err) => {
      console.log('Error', err);
    }
  });
}

parentsColumns(){
  if(this.Type =='Location'){
    this.columns = [
      { field: 'clientId', title: 'Entity' },
      { field: 'state', title: 'State' },
      { field: 'city', title: 'Location' },
      { field: 'branchName', title: 'Branch Name' },
      { field: 'status', title: 'Status' }
    ];
  }
}

getLocation() {
  this.loading = true;
  // this.dashboardService.getLocation(this.userService.getClientId()).subscribe({
  //   next: (response) => {
  //    console.log('res', response)
  //     const data = response.body
  //     this.gridData = data.map((item:any) => {
  //       return {
  //         ...item,
  //         status: item.status === 'A' ? 'Active' : 'Inactive'
  //       };
  //     });
  //     this.gridData1 = data?.data;
  //       this.loading = false;
  //   },
  //   error: (err) => {
  //     this.loading = false;
  //     console.log('Error', err);
  //   }
  // });
  this.dashboardService.getLocation(this.userService.getClientId()).subscribe({
    next: (response) => {
      console.log('Full response:', response); // Check the response structure
      const data = response.body;
      console.log('Response body:', data); // Check if data exists
      
      if (data) {
        this.gridData = data
  
        // Only assign to gridData1 if data.data exists
        this.gridData1 = data.map((item: any) => ({
          ...item,
          status: item.status === 'A' ? 'Active' : 'Inactive'
        }));
      } else {
        this.gridData1 = []; // Default to an empty array if no data
      }
  
      this.loading = false;
    },
    error: (err) => {
      this.loading = false;
      console.log('Error', err);
    }
  });
  
}
}
