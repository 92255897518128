import { CommonModule, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { KendoDatepickerComponent } from '../../../components/kendo-datepicker/kendo-datepicker.component';
import { UserService } from '../../../service/user.service';
import { ExtensionDetailsDto, NoticesServiceService, TranslationDetailsDto } from '../../../service/notices-service.service';
import { environment } from '../../../environments/environment';
import { BlobService } from '../../../service/blob.service';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { load } from '@progress/kendo-angular-intl';

@Component({
  selector: 'app-smeresponse',
  standalone: true,
  imports: [NgIf,FormsModule,KendoDatepickerComponent,AlertPopupComponent,IndicatorsModule,CommonModule],
  templateUrl: './smeresponse.component.html',
  styleUrl: './smeresponse.component.css'
})
export class SMEResponseComponent implements OnInit{
  @Input() Data!:any;
  @Output() close = new EventEmitter<void>();
  userId:any
  showTranslation: boolean = false;
  showExtension: boolean = false;
  showNotice: boolean = false;
  TranslationRequired!:string
  TranslationRequestDate!:string;
  TranslatedDocumentUploadDate!:string;
  extensionRequired!:string
  extensionDocumentDate!:Date|null;
  applicationAcknowledgement!:string
  extendedDateGranted!:string
  extendedDate: Date | null = null;
  showValidationErrors: boolean = false;
  public placeholder: string = 'DD-MM-YYYY';
  noticeDocumentReceived!:string
  draftResponseToSD!:Date|null
  SMETeamdropdownData: any[] = [];
  status!:string
  clientId!:string
  stateId!:string
  locationId!:string
  branch!:string
  noticeTypeid!:number
  noticeNumber!:string
  receiptMode!:string
  risk!:string
  loading=false
  role:string=''
  formattedDate:any
  formatteddraftResponseToSD:any
  fileNames: { [key: string]: string } = {
    draft: '',
    draftResponse:''
  };
  selectedFiles: { [key: string]: File | null } = {
    draft: null,
    draftResponse:null
  };
  alertData = { alertMessage: '', alertVisible: '' }
constructor(private user:UserService,private editNoticeService:NoticesServiceService,private blobService: BlobService){}
  ngOnInit(): void {
    this.userId=this.user.getUserId();
    this.role=this.user.getRole();
    console.log(this.role);
    
    if(this.Data){
     
      this.getSMETeamList();
      this.GetNoticeData(this.Data.noticeReferenceNo);

    }
  }

  InitData(data: any) {
    console.log('initdata', data);
    if (data.translationDocumentUploadDate) {
      const uploadDate = new Date(data.translationDocumentUploadDate);
      
      this.TranslatedDocumentUploadDate = uploadDate.toLocaleDateString('en-GB'); 
    } else {
      this.TranslatedDocumentUploadDate = ''; 
    }
    if(data.translationDocument){
      const filePath=data?.translationDocument;
      // this.downloadDocument(filePath);   
    }
    // if (data.extensionDraftExtensionDate) {
    //   const uploadDate = new Date(data.extensionDraftExtensionDate);
      
    //   this.extensionDocumentDate = uploadDate.toLocaleDateString('en-GB'); 
    // } else {
    //   this.extensionDocumentDate = ''; 
    // }
    this.status=data.status;
    this.clientId=data.clientId;
    this.stateId=data.state
    this.locationId=data.location
    this.branch=data.branch;
    this.noticeTypeid=data.noticeTypeId
    this.noticeNumber=this.Data.noticeReferenceNo
    this.receiptMode=data.modeOfReceipt
    this.risk=data.risk
    // if (data.extensionDraftExtensionDate) {
    //   const noticeDate = new Date(data.extensionDraftExtensionDate);
    //   if (!isNaN(noticeDate.getTime())) {
    //     this.extensionDocumentDate = noticeDate.toLocaleDateString('en-GB');
    //   } else {
    //     console.error("Invalid date format for noticeReceivedDate");
    //     this.extensionDocumentDate = '';
    //   }
    // } else {
    //   console.error("noticeReceivedDate is null or undefined");
    //   this.extensionDocumentDate = ''; 
    // }
    
    if (data.noticeReceivedDate) {
      const noticeDate = new Date(data.noticeReceivedDate);
      if (!isNaN(noticeDate.getTime())) {
        this.noticeDocumentReceived = noticeDate.toLocaleDateString('en-GB');
      } else {
        console.error("Invalid date format for noticeReceivedDate");
        this.noticeDocumentReceived = '';
      }
    } else {
      console.error("noticeReceivedDate is null or undefined");
      this.noticeDocumentReceived = ''; 
    }

    // if (data.noticeDraftResponseDate) {
    //   const noticeDate = new Date(data.noticeDraftResponseDate);
    //   if (!isNaN(noticeDate.getTime())) {
    //     this.draftResponseToSD = noticeDate.toLocaleDateString('en-GB');
    //   } else {
    //     console.error("Invalid date format for noticeReceivedDate");
    //     this.draftResponseToSD = '';
    //   }
    // } else {
    //   console.error("noticeReceivedDate is null or undefined");
    //   this.draftResponseToSD = ''; 
    // }
   
  }
  toggle(dropdownType: any) {
    if (dropdownType === 'translation') {
      this.showTranslation = !this.showTranslation;
    }
    else if (dropdownType === 'extension') {
      this.showExtension = !this.showExtension;
    }
    else if (dropdownType === 'notice') {
      this.showNotice = !this.showNotice;
    }
  }

  onRadioChange(value: string,type:string) {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); 
    const year = today.getFullYear();
    if (value == 'Y' && type==='translation') {
      this.TranslationRequired = 'true';
      this.TranslationRequestDate = `${day}/${month}/${year}`;
    } else if (value == 'N'  && type==='translation') {
      this.TranslationRequired = 'false';
      this.TranslationRequestDate = '';
    }
    if (value == 'Y' && type==='extension') {
      this.extensionRequired = 'true';
    } else if (value == 'N'  && type==='extension') {
      this.extensionRequired = 'false';
    }
  }

  onDateChange(value: Date, type: string) {
    const date = new Date(value.toISOString().split('T')[0]);
    if (type === 'extendedDate') {
      this.extendedDate = date;
    }
  }
  onFileChange(event: any, type: string) {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = [
        'application/msword', 
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 
        'application/pdf' 
      ];

      if (allowedTypes.includes(file.type)) {
        this.fileNames[type] = file.name;
        this.selectedFiles[type] = file;
      } else {
        this.fileNames[type] = '';
        this.selectedFiles[type] = null;
        this.alertData = { alertMessage: 'Please select a .doc,.docx or .pdf file', alertVisible: 'error' };
      }
    } else {
      this.fileNames[type] = '';
      this.selectedFiles[type] = null;
    }
    this.extensionDocumentDate = this.extensionDocumentDate || new Date(); 
    this.draftResponseToSD=this.draftResponseToSD|| new Date()
    // Format the date in DD/MM/YYYY format, or keep it null if not a valid date
    this.formattedDate = this.extensionDocumentDate 
        ? this.extensionDocumentDate.toLocaleDateString('en-GB') 
        : null;
    this.formatteddraftResponseToSD = this.draftResponseToSD 
        ? this.draftResponseToSD.toLocaleDateString('en-GB') 
        : null;
    console.log(this.fileNames);
  }

  GetNoticeData(Noticenumber: string) {
    this.editNoticeService.GetNoticeData(Noticenumber).subscribe(
      {
        next: (response) => {
          const data = response.body; 
          this.InitData(data[0])
        }, error: (error) => {
          console.error('Error fetching State Dropdown data', error);
        }
      });
  }
  getSMETeamList() {
    this.editNoticeService.getSMETeamList().subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && data && Array.isArray(data)) {
            this.SMETeamdropdownData =[ { text: 'Select SME Team', value: '' },
              ...data.map((item: any) => ({
                value: item.value,
                text: item.text
              }))]
          } else {
            console.error("Unexpected data format", data);
          }
        }, error: (error) => {
          console.error('Error fetching State Dropdown data', error);
        }
      });
  }

  downloadDocument(){
    this.loading=true
    const filePath=this.Data.translationDocument;
    console.log(filePath);
    
       if(filePath){
        const apiUrl = environment.blobUrl;
        const blobLink = apiUrl + filePath;
        const blobName = this.blobService.extractBlobName(blobLink);
        setTimeout(() => {
          this.blobService.getSasToken(blobName).subscribe(
            (tokenResponse: any) => {
              try {
                const sasToken = tokenResponse.result;
                this.blobService.downloadFile(blobName, sasToken);
                this.loading=false;
              } catch (error) {
                this.alertData={alertMessage:'File Not Found',alertVisible:'error'};             
                this.loading=false;
              }
            },
            (error) => {
              this.alertData={alertMessage:'File Not Found',alertVisible:'error'};            
              this.loading=false;
            }
          );
        }, 5000);
      }else{
        this.alertData={alertMessage:'File Not Found',alertVisible:'error'};
        this.loading=false;
    
    }
  }
  
  downloadNoticeAcknowledgement(){
    this.loading=true
    const filePath=this.Data.noticeResponseAcknowledgement;
    console.log(filePath);
    
       if(filePath){
        const apiUrl = environment.blobUrl;
        const blobLink = apiUrl + filePath;
        const blobName = this.blobService.extractBlobName(blobLink);
        setTimeout(() => {
          this.blobService.getSasToken(blobName).subscribe(
            (tokenResponse: any) => {
              try {
                const sasToken = tokenResponse.result;
                this.blobService.downloadFile(blobName, sasToken);
                this.loading=false;
              } catch (error) {
                this.alertData={alertMessage:'File Not Found',alertVisible:'error'};             
                this.loading=false;
              }
            },
            (error) => {
              this.alertData={alertMessage:'File Not Found',alertVisible:'error'};            
              this.loading=false;
            }
          );
        }, 5000);
      }else{
        this.alertData={alertMessage:'File Not Found',alertVisible:'error'};
        this.loading=false;
    
    }
  }
  saveTranslationDetails(){
    this.loading=true
    const translationData: TranslationDetailsDto = {
      translationDocumentUploadDate:this.TranslatedDocumentUploadDate ? new Date(this.TranslatedDocumentUploadDate) : undefined,
      translationRequestDate: new Date(this.TranslationRequestDate),
      isTranslationRequired: Boolean(this.TranslationRequired),
      status: this.status,
      clientId: this.clientId,
      stateId: this.stateId,
      locationId: this.locationId,
      branch: this.branch,
      noticeTypeid: this.noticeTypeid,
      noticeNumber: this.noticeNumber,
      receiptMode: this.receiptMode,
      risk: this.risk
    };
    console.log('save response',translationData);
    this.editNoticeService.submitTranslationDetails(translationData).subscribe({
      next:(response)=>{
        if(response.body===1){
          this.loading=false
          this.alertData={alertMessage:'Translation Submitted Successfully',alertVisible:'success'};    
        }
        else{
          this.loading=false
          this.alertData={alertMessage:'Submission Failed',alertVisible:'error'};     
        }
      },
      error: error => {
     this.loading=false
      this.alertData={alertMessage:'Submission Failed',alertVisible:'error'};            
    }})
  }
  saveExtensionDetails() {
    this.loading=true
   console.log(this.formattedDate);
   
  
    const extensionData: ExtensionDetailsDto = {
      clientId: this.clientId,
      extensionDraftExtensionDate:this.extensionDocumentDate|| null, 
      extensionAppliedOn: this.extendedDate || null,
      isExtensionRequired: Boolean(this.extensionRequired),
      status: this.status,
      stateId: this.stateId,
      locationId: this.locationId,
      branch: this.branch,
      noticeNumber: this.noticeNumber,
      draftExtensionApplicationfile: this.selectedFiles['draft']
    };
  
    console.log('save response', extensionData);
  
    this.editNoticeService.submitExtensionDetails(extensionData).subscribe({
      next: (response) => {
        console.log(response);
  
        if (response.body === 1) {
          this.loading=false
          this.alertData = { alertMessage: 'Extension Submitted Successfully', alertVisible: 'success' };
        } else {
          this.loading=false
          this.alertData = { alertMessage: 'Extension Failed', alertVisible: 'error' };
        }
      },
      error: error => {
        this.loading=false
        this.alertData = { alertMessage: 'Extension Failed', alertVisible: 'error' };
        console.log(error);
      }
    });
  }
  
  parseDate(dateString: string): Date | null {
    if (!dateString) {
      return null;
    }
    const parts = dateString.split('/');
    if (parts.length === 3) {
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[2], 10);
  
      const date = new Date(year, month, day);
      if (!isNaN(date.getTime())) {
        return date;
      }
    }
    console.error("Invalid date format: ", dateString);
    return null;
  }

  submitNoticeResponse() {
    this.loading=true
     
    const data = {
      Status: this.status, 
      NoticeDraftResponseDate: this.formattedDate, 
      ClientId: this.clientId,
      StateId: this.stateId, 
      Branch: this.branch, 
      LocationId: this.locationId, 
      NoticeNumber: this.noticeNumber 
    };
    console.log('save response',data);
    
    if (this.selectedFiles['draftResponse']) {
      this.editNoticeService.saveNoticeResponseBySME(data, this.selectedFiles['draftResponse']).subscribe({
        next:(response)=>{
          console.log(response);
          
          if(response.body.result===1){
            this.loading=false
            this.alertData={alertMessage:'Notice Submitted Successfully',alertVisible:'success'};    
          }
          else{
            this.loading=false
            this.alertData={alertMessage:'Submission Failed',alertVisible:'error'};     
          }
          
        }
      })
    } else {
      this.loading=false
      this.alertData={alertMessage:'No File Selected',alertVisible:'error'};  
    }
  }

  
  closeAlert(){
    this.alertData={alertMessage:'',alertVisible:''};
  }

  goBack() {
    // this.router.navigate(['/Notices']);
    this.Data = undefined;
    this.close.emit();
  }
}
