import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule, NgClass } from '@angular/common';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';

@Component({
  selector: 'app-kendo-datepicker',
  standalone: true,
  imports: [ DateInputsModule,NgClass],
  templateUrl: './kendo-datepicker.component.html',
  styleUrls: ['./kendo-datepicker.component.css']
})
export class KendoDatepickerComponent {
  @Input() value: Date | null = null; // Initialize value as null
  @Input() format: string =  'dd-MM-yyyy';
  @Input() width: string | null = null;
  @Input() height: string | null = null;
  @Input() placeholder: string = 'DD-MM-YYYY';
  @Input() topView: string = '';
  @Input() bottomView: string = '';
  @Input() max!: Date ; 
  @Input() isDisabled: boolean = false;
  @Input() hasError!: any ;
  @Output() valueChange: EventEmitter<Date> = new EventEmitter<Date>();



  onValueChange(value: Date): void {
    this.valueChange.emit(value);
  }
}
