<div class="row colpadding0" style="margin-bottom: 10px; margin-top: 10px;" *ngIf="currentId">
    <div class="col-md-10 FolderNav colpadding0">
        <a (click)="navigateToRoot()">
            My Docs
        </a>
        <a style="color: black;"> &nbsp;>&nbsp;</a>

        <span *ngFor="let breadcrumb of breadcrumbs; let i = index">
            <a (click)="navigateToFolder(breadcrumb.id, i)">
                {{ breadcrumb.name }}
            </a>

            <span *ngIf="i !== breadcrumbs.length - 1" class="breadcrumb-arrow">&nbsp;&gt;&nbsp;</span>
        </span>
    </div>
</div>


<div class="row colpadding0" style="margin-bottom: 10px; margin-top: 10px;">
    <div class="col-md-8 colpadding0">
        <input class="f-textbox" type="text" (keyup)="applyFilter($event)"
            placeholder="Type to Search Files, folders(e.g. tags, process and subprocess)">
    </div>
    <div class="col-md-4 colpadding0" style="float:right;">
        <button class="figma-btn-blue" (click)="openNewFolderPopup()" type="button" id="menu1">
            <img src="../assets/vectors/Plus.svg" alt=""> New Folder
        </button>
        <button *ngIf="currentId" class="figma-btn-blue" (click)="openNewFilePopup()" type="button"
            id="menu1">
            <img src="../assets/vectors/Plus.svg" alt=""> New File
        </button>
        <!-- <button class="dropdown-toggle figma-btn-blue" (click)="toggleDropdown()" type="button" id="menu1">
            <img src="../assets/vectors/Plus.svg" alt=""> New Folder
        </button> -->
        <!-- <ul class="dropdown-menu" [ngClass]="{'show': isDropdownOpen}" role="menu" aria-labelledby="menu1">
            <li role="presentation">
                <a role="menuitem" tabindex="-1" (click)="openNewFolderPopup()">
                    New Folder
                </a>
            </li>
            <li role="presentation" *ngIf="currentId">
                <a role="menuitem" tabindex="-1" (click)="openNewDocumentPopup()">
                    New File
                </a>
            </li>
        </ul> -->
    </div>
</div>

<kendo-grid class="custom-grid" [data]="gridData" [skip]="Skip" [sortable]="true" [pageSize]="10" [pageable]="true"
    [selectable]="true" [loading]="loadingGrid" [resizable]="true" (pageChange)="pageChange($event)">

    <ng-template *ngIf="loadingGrid" kendoGridLoadingTemplate>
        <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
    </ng-template>

    <kendo-grid-column field="name" title="Name">
        <ng-template kendoGridCellTemplate let-dataItem (dblclick)="goToSubfolder(dataItem)">
            <div class="cell-content" title="{{dataItem.name}}" (dblclick)="goToSubfolder(dataItem)">
                <img *ngIf="dataItem.type == 'Folder'" src="../assets/vectors/FolderIcon.svg" class="foldertogo" alt="Go to sub-folder">
                <img *ngIf="dataItem.type === 'File'" src="../assets/Images/File.png" class="fileicon" alt="File" />
                <img *ngIf="dataItem.type === 'zip'" src="../assets/vectors/ZIP_Icon.svg" class="fileicon" alt="File" />
                <img *ngIf="dataItem.type === 'pdf'" src="../assets/vectors/PDF_Icon.svg" class="fileicon" alt="File" />
                <img *ngIf="dataItem.type === 'doc'" src="../assets/vectors/DOC_Icon.svg" class="fileicon" alt="File" />
                <img *ngIf="dataItem.type === 'docx'" src="../assets/vectors/DOCX_Icon.svg" class="fileicon" alt="File" />
                <img *ngIf="dataItem.type === 'xls' || dataItem.type ==='csv'" src="../assets/vectors/XLS_Icon.svg" class="fileicon" alt="File" />
                <img *ngIf="dataItem.type === 'img'|| dataItem.type === 'jpg'" src="../assets/vectors/JPG_Icon.svg" class="fileicon" alt="File" />
                <img *ngIf="dataItem.type === 'png'" src="../assets/vectors/PNG_Icon.svg" class="fileicon" alt="File" />
                <img *ngIf="dataItem.type === 'txt'" src="../assets/vectors/TXT_Icon.svg" class="fileicon" alt="File" />
                <img *ngIf="dataItem.type === 'rar'" src="../assets/vectors/RAR_Icon.svg" class="fileicon" alt="File" />
                <img *ngIf="dataItem.type === 'xlsx'" src="../assets/vectors/XLSX_Icon.svg" class="fileicon" alt="File" />
                {{dataItem.name}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Owner" [width]="80">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" title="{{ showUserName(dataItem.createdby) }}"
                (dblclick)="goToSubfolder(dataItem)">
                {{ showUserName(dataItem.createdby) }}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="createdOn" title="Last Modified" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" title="{{ dataItem.updatedOn |  date: 'dd-MMM-yyyy'}}"
                (dblclick)="goToSubfolder(dataItem)">
                {{ dataItem.updatedOn | date: 'dd-MMM-yyyy' }}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Size" [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" title="{{dataItem.fileSize ||'-' }}" (dblclick)="goToSubfolder(dataItem)">
                {{dataItem.fileSize ||'-'}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Type" [width]="90">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content" title="{{dataItem.type }}" (dblclick)="goToSubfolder(dataItem)">
                {{dataItem.type }}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Action" [width]="120" [headerStyle]="{ 'text-align': 'center' }" [style]="{ 'text-align': 'center' }" >
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-content action-column" style="display: flex; justify-content: center;">
                <img src="../assets/vectors/Share_Icon.svg" alt="Save Icon" class="svg-icon" (click)="OpenShareWith(dataItem)"
                    title="Click to Share Document" />
                <img *ngIf="dataItem.type != 'Folder'" src="../assets/vectors/DownloadBlue.svg" alt="Save Icon"
                    class="svg-icon" (click)="downloadFile(dataItem)" title="Click to Download" />
                <img *ngIf="dataItem.type != 'Folder'" (click)="OpenEditDetails(dataItem)" src="../assets/vectors/edit.svg" alt="Save Icon" class="svg-icon"
                    title="Click to Edit Details" />
                <img src="../assets/vectors/delete.svg" alt="Delete Icon" class="svg-icon" title="Delete"
                    (click)="confirmAlert(dataItem)" />
            </div>
        </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate let-total="total" let-currentPage="currentPage">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-input></kendo-pager-input>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>
</kendo-grid>


<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>

<app-alert-popup *ngIf="alertData.alertVisible"[id]="deleteId" [Width]="290" [Data]="alertData"
    (close)="closeAlert()" (confirm)="DeleteMWMaster($event)"></app-alert-popup>

<app-critica-documents-add-new-folder [ParentId]="currentId" *ngIf="openAddNewFolderPopup"
    (close)="closePopup()"></app-critica-documents-add-new-folder>
<app-critica-documents-add-new-file [FolderId]="currentId" *ngIf="openAddNewFilePopup"
    (close)="closePopup()"></app-critica-documents-add-new-file>
<app-critical-documents-share-with *ngIf="openShareWithPopup"  [ID]="shareID" [Type]="type"
    (close)="closePopup()"></app-critical-documents-share-with>
<app-critical-documents-edit-details [FileId]="Data" *ngIf="openEditDetailsPopup"
    (close)="closePopup()"></app-critical-documents-edit-details>
<app-adobe-viewer [fileBlob]="fileBlob" *ngIf="fileBlob" (close)="closeViewer()"></app-adobe-viewer>