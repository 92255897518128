<div class="row colpadding0" style="margin-bottom: 10px; margin-top: 10px;">
    <div class="col-md-9 colpadding0">
        <app-dropdown-list [data]="StateDropdownData" [selectedValue]="selectedState" [width]="'250px'" textField="text"
            valueField="value" (valueChange)="onDropdownValueChange($event,'State')">
        </app-dropdown-list>
        <app-dropdown-list [data]="ActDropdownData" [selectedValue]="selectedAct" [width]="'250px'" textField="text"
            valueField="value" (valueChange)="onDropdownValueChange($event,'Act')" style=" margin-left: 7px;">
        </app-dropdown-list>
        <input *ngIf="loginusername === 'Client1'" style="margin-left: 7px;" class="f-textbox" type="text"
            (keyup)="applyFilter($event)" width="'250px'" placeholder="Type to Search">
    </div>
    <div class="col-md-3 colpadding0">
        <button *ngIf="loginusername != 'Client1'" class="figma-btn-blue" (click)="openUploadPopup()">
            <img src="../assets/vectors/uploadWhite.svg" alt="">Upload
        </button>
        <button (click)="clearFilter()" class="figma-btn-white">
            <img src="../assets/vectors/ClearFilter.svg" alt="">
        </button>
    </div>
</div>

<app-grid-action-item [gridData]="gridData" [columns]="parentColumns" [pageSize]="10" [pageable]="true"
    [filterable]="true" (buttonClick)="handleButtonClick($event)"></app-grid-action-item>

<app-master-abstract-upload-popup [StateId]="selectedState.value" [ActId]="selectedAct.value" *ngIf="showUploadPopup"
    (close)="closePopup()"></app-master-abstract-upload-popup>

<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>

<div *ngIf="showViewer" class="popup-overlay">
    <app-adobe-viewer (close)="closePopupViewer()" [fileBlob]="fileBlob"></app-adobe-viewer>
</div>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270" [Data]="alertData"
    (close)="closeAlert()"></app-alert-popup>