import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { NgClass, NgIf } from '@angular/common';
import { MultiSelectDropdownComponent } from '../../../components/multi-select-dropdown/multi-select-dropdown.component';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { MinimumWagesMasterService } from '../../../service/minimum-wages-master.service';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
  selector: 'app-mwadd-new-popup',
  standalone: true,
  imports: [DialogModule,AlertPopupComponent,ReactiveFormsModule,NgIf,NgClass,MultiSelectDropdownComponent,DropdownListComponent,ComboBoxComponent],
  templateUrl: './mwadd-new-popup.component.html',
  styleUrl: './mwadd-new-popup.component.css'
})
export class MWAddNewPopupComponent implements OnInit{
  @Output() close = new EventEmitter<void>();
  public alertData = { alertMessage: '', alertVisible: '' };
  form: FormGroup;
  industryType = false;
  EstablishmentDefaultValue: { id: string, name: any } = { id: 'Establishment Type', name: 0 };
  IndustryTypeDefaultValue: { id: string, name: any } = { id: 'Industry Type', name: 0 };
  NotificationIDDefaultValue: { id: string, name: any } = { id: 'Notification ID', name: 0 };
  SkillDefaultValue: { id: string, name: any } = { id: 'Skills', name: 0 };
  DesignationDefaultValue: { id: string, name: any } = { id: 'Designation', name: 0 };
  ZoneDefaultValue: { id: string, name: any } = { id: 'Zone', name: 0 };
  StateNotificationDefaultValue: { id: string, name: any } = { id: 'Select Notification ID', name: 'Select Notification ID' };
  ComponentDefaultValue = 'All Components';
  public selectedIndustryType: string = '';
  public selectedState: string = '';
  public selectedEstablishment: string = '';
  public selectedDesignation: string = '';
  public selectedSkills: string = '';
  public selectedStateNotificationID: string = '';
  public selectedNotificationID: string = '';
  public selectedZone: string = '';
  public stateDropdownData: any[] = [];
  public EstablishDropdownData: any[] = [];
  public SkillCategoryDropdownData: any[] = [];
  public DesignationDropdownData: any[] = [];
  public IndustryTypeDropdownData: any[] = [];
  public StateNotificationDropdownData: any[] = [];
  public NotificationIDDropdownData: any[] = [];
  public ZoneDropdownData: any[] = [];
  public isFormValid: boolean = false;
  selectedComponents: any[] = [];
  public showValidation: boolean = false; 
  public showValidationErrors = false;
  public ComponentDropdownData: any = [
    { id: 'all', name: 'Select All' },
    { id: '1', name: 'Basic' },
    { id: '2', name: 'DA' },
    { id: '3', name: 'HRA' },
    { id: '4', name: 'VDA' },
    { id: '5', name: 'Special Allowance' }
  ];
  public selectedZones: Array<string> = [];
  @ViewChild('dialogTitle') dialogTitle!: ElementRef;
  constructor(private MinimumWagesService: MinimumWagesMasterService, private fb: FormBuilder) {
    this.form = this.fb.group({
      basic: ['', [Validators.pattern(/^[0-9]*$/)]],
      da: ['', [Validators.pattern(/^[0-9]*$/)]],
      hra: ['', [Validators.pattern(/^[0-9]*$/)]],
      vda: ['', [Validators.pattern(/^[0-9]*$/)]],
      specialAllowance: ['', [Validators.pattern(/^[0-9]*$/)]]
    });
  }
  ngOnInit(): void {
    this.StateDropdown();
    this.SkillCategoryDropdown();
    this.EstablishTypeDropdown('');
  }
ngAfterViewInit() {
    const titleElement = this.dialogTitle.nativeElement;
    titleElement.style.textAlign = 'center';
    titleElement.style.width = '100%';
  }


  OnSave() {
    debugger;
    const selectedNotification = this.StateNotificationDropdownData.find(item => item.id === this.selectedStateNotificationID);
    const body = {
      id: 0,
      notification_Id: selectedNotification ? selectedNotification.name : null,
      notification_Number: "",
      industryNotification_Id: parseInt(this.selectedNotificationID, 10) || 0,
      industryNotification_Number: "",
      state: this.selectedState,
      sM_Name: "",
      industry_Type: this.selectedEstablishment,
      industry: this.selectedIndustryType,
      skill_Category: this.selectedSkills,
      designation: this.selectedDesignation,
      zone: this.selectedZone,
      status: "",
      mW_MasterId: 0,
      mW_Value: this.form.value.basic + this.form.value.da + this.form.value.hra + this.form.value.vda + this.form.value.specialAllowance || 0,
      basic: this.form.value.basic || 0,
      da: this.form.value.da || 0,
      hra: this.form.value.hra || 0,
      vda: this.form.value.vda || 0,
      spl_Allowance: this.form.value.specialAllowance || 0
    };
    console.log('Body before saving:', body);
    this.showValidation = true;
    this.showValidationErrors=true;
    if (this.isFormValid) {
      this.MinimumWagesService.saveMWMaster(body).subscribe((response) => {
        this.alertData = { alertMessage: 'Data Added Successfully', alertVisible: 'success' };
      }, (error) => {
        console.log('error',error);
        this.alertData = { alertMessage: 'Error while saving data', alertVisible: 'error' };
      })
    } else {
      console.log('Please fill all the fields');
      // this.alertData = { alertMessage: 'Please fill all the fields', alertVisible: 'error' };      
    }
    this.EstablishmentDefaultValue = { id: 'Establishment Type', name: 0 };
    this.IndustryTypeDefaultValue = { id: 'Industry Type', name: 0 };
    this.NotificationIDDefaultValue = { id: 'Notification ID', name: 0 };
    this.SkillDefaultValue= { id: 'Skills', name: 0 };
    this.DesignationDefaultValue ={ id: 'Designation', name: 0 };
    this.ZoneDefaultValue= { id: 'Zone', name: 0 };
    this.StateNotificationDefaultValue={ id: 'Select Notification ID', name: 'Select Notification ID' };
    this.ComponentDefaultValue = 'All Components';
  }

  public checkableSettings: any = {
    checkAll: true
  };

  onZoneSelectionChange(selected: any): void {
    const filteredSelection = selected.filter((item: any) => item.id !== 'all');
    const allItemsSelected = filteredSelection.length === this.ComponentDropdownData.length - 1; 
    if (allItemsSelected) {
      this.selectedComponents = [...this.ComponentDropdownData];
    } else {
      this.selectedComponents = selected;
    }
    const selectAllOption = selected.find((item: any) => item.id === 'all');
    if (selectAllOption) {
      this.selectedComponents =  [...this.ComponentDropdownData];
    } else if (filteredSelection.length === 0) {
      this.selectedComponents = [];
    }  
    console.log(this.selectedComponents);
  }

  isComponentSelected(name: string): boolean {
    return this.selectedComponents.some(component => component.name === name);
  }

  StateDropdown() {
    this.MinimumWagesService.getStateDropdown().subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && Array.isArray(data)) {
            this.stateDropdownData = data.map((item: any) => ({
              id: item.sM_Code,
              name: item.sM_Name
            }));
          }
          else {
            console.error('Unexpected API response format:', data);
          }
        }, error: (error) => {
          console.error('Error fetching states data', error);
        }
      }
    );
  }

  StateValueChange(value: any) {
    if (value == undefined) {
      this.EstablishmentDefaultValue = { id: 'Establishment Type', name: 0 };
      this.IndustryTypeDefaultValue = { id: 'Industry Type', name: 0 };
      this.NotificationIDDefaultValue = { id: 'Notification ID', name: 0 };
      this.SkillDefaultValue= { id: 'Skills', name: 0 };
      this.DesignationDefaultValue ={ id: 'Designation', name: 0 };
      this.ZoneDefaultValue= { id: 'Zone', name: 0 };
      this.StateNotificationDefaultValue={ id: 'Select Notification ID', name: 'Select Notification ID' };
      this.ComponentDefaultValue = 'All Components';
    }
    this.selectedState = value.id || '';
    this.StateNotificationDropdown(value.id);
    this.EstablishTypeDropdown(value.id);
    this.ZoneDropdown();
    this.validateForm();
  }

  StateNotificationValueChange(value: any) {
    this.selectedStateNotificationID = value.id;
  }
  
  StateNotificationDropdown(value: any) {
    this.MinimumWagesService.getNotificationIdDropdown(value).subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && Array.isArray(data)) {
            this.StateNotificationDropdownData = data.map((item: any) => ({
              id: `[${item.id}] ${item.notification_Number}`,
              name: item.id
            }));
            console.log('notification id',this.StateNotificationDropdownData);
          }
          else {
            console.error('Unexpected API response format:', data);
          }
        }, error: (error) => {
          console.error('Error fetching states data', error);
        }
      }
    );
  }

  EstablishValueChange(value: any) {
    if (value && value.name === 'FACT') {
      this.industryType = true;
      this.IndustryTypeDropdown(value.id);
      this.SkillCategoryDropdown();
    } else {
      this.industryType = false;
    }
    this.selectedEstablishment = value.name;
    this.validateForm();
  }

  EstablishTypeDropdown(value: any) {
    this.MinimumWagesService.getEstablishTypeDropdown(value).subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && Array.isArray(data)) {
            this.EstablishDropdownData = data.map((item: any) => ({
              id: item.key,
              name: item.value
            }));
          }
          else {
            console.error('Unexpected API response format:', data);
          }
        }, error: (error) => {
          console.error('Error fetching states data', error);
        }
      } );
  }

  IndustryTypeDropdown(value: any) {
    this.MinimumWagesService.getIndustryTypeDropdown(this.selectedState, value).subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && Array.isArray(data)) {
            this.IndustryTypeDropdownData = data.map((item: any) => ({
              id: item.industry,
              name: item.industry
            }));
            console.log('industry id',this.IndustryTypeDropdownData);
          }
          else {
            console.error('Unexpected API response format:', data);
          }
        }, error: (error) => {
          console.error('Error fetching states data', error);
        }
      }
    );
  }

  IndustryChange(value: any) {
    this.selectedIndustryType = value.name;
    this.NotificationIDDropdown(value.name)
    this.SkillCategoryDropdown();
    console.log(value.id)
    this.validateForm();
  }
  NotificationIDDropdown(value: any) {
    this.MinimumWagesService.getNotificationIDDropdown(value).subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && Array.isArray(data)) {
            this.NotificationIDDropdownData = data.map((item: any) => ({
              id: `[${item.id}] ${item.notification_Number}`,
              //id:item.notification_Number,
              name: item.id
            }));
            console.log('NID',this.NotificationIDDropdownData);
          }
          else {
            console.error('Unexpected API response format:', data);
          }
        }, error: (error) => {
          console.error('Error fetching states data', error);
        }
      }
    );
  }

  NotificationIDChange(value: any) {
    this.selectedNotificationID = value.id;
    this.validateForm();
  }

  SkillCategoryDropdown() {
    this.MinimumWagesService.getSkillCategoryDropdown(this.selectedState, this.selectedEstablishment, this.selectedIndustryType).subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && Array.isArray(data)) {
            this.SkillCategoryDropdownData = data.map((item: any) => ({
              id: item.skills,
              name: item.skills
            }));
          }
          else {
            console.error('Unexpected API response format:', data);
          }
        }, error: (error) => {
          console.error('Error fetching states data', error);
        }
      }
    );
  }

  SkillCategoryValueChange(value: any) {
    this.selectedSkills = value.name;
    this.DesignationDropdown();
    this.validateForm();
  }

  DesignationDropdown() {
    this.MinimumWagesService.getDesignationDropdown(this.selectedState, this.selectedEstablishment, this.selectedIndustryType, this.selectedSkills).subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && Array.isArray(data)) {
            this.DesignationDropdownData = data.map((item: any) => ({
              id: item.designation,
              name: item.designation
            }));
          }
          else {
            console.error('Unexpected API response format:', data);
          }
        }, error: (error) => {
          console.error('Error fetching states data', error);
        }
      }
      );
  }

  DesignationValueChange(value: any) {
    this.selectedDesignation = value.name;
    this.validateForm();
  }
  ZoneDropdown() {
    this.MinimumWagesService.getZoneDropdown(this.selectedState).subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && Array.isArray(data)) {
            this.ZoneDropdownData = data.map((item: any) => ({
              id: item.zone,
              name: item.zone
            }));
          }
          else {
            console.error('Unexpected API response format:', data);
          }
        }, error: (error) => {
          console.error('Error fetching states data', error);
        }
      });
  }

  ZoneValueChange(value: any) {
    this.selectedZone = value.name;
    this.validateForm();
  }

  validateForm() {
    if (this.industryType) {
      if (this.selectedState && this.selectedEstablishment && this.selectedIndustryType && this.selectedSkills && this.selectedDesignation ) {
        this.isFormValid = true;
      } else {
        this.isFormValid = false;
      }
    } else {
      if (this.selectedState && this.selectedEstablishment && this.selectedSkills && this.selectedDesignation ) {
        this.isFormValid = true;
      } else {
        this.isFormValid = false;
      }
    }
  }
  closeAlert() {
    this.alertData = { alertMessage: '', alertVisible: '' };
  }

  closePopup() {
    this.close.emit();
  }
}
