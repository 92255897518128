import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { EntitydashboardComponent } from './pages/entitydashboard/entitydashboard.component';
import { ComplianceDashboardComponent } from './pages/compliance-dashboard/compliance-dashboard.component';
import { WorkspaceComponent } from './pages/workspace/workspace.component';
import { DispenceryMasterComponent } from './pages/ESIC/dispencery-master/dispencery-master.component';
import { EsicSubcodeComponent } from './pages/ESIC/esic-subcode/esic-subcode.component';
import { ESICInputsComponent } from './pages/ESIC/esicinputs/esicinputs.component';
import { ESICReportComponent } from './pages/ESIC/esicreport/esicreport.component';
import { LocationMasterComponent } from './pages/manage-masters/location-master/location-master.component'
import { DesignationMasterComponent } from './pages/manage-masters/designation-master/designation-master.component';
import { LeaveTypeMasterComponent } from './pages/manage-masters/leave-type-master/leave-type-master.component';
import { ChallanPtSlabComponent } from './pages/manage-masters/challan-pt-slab/challan-pt-slab.component';
import { StatutoryMasterComponent } from './pages/manage-masters/statutory-master/statutory-master.component';
import { PaycodeMasterComponent } from './pages/manage-masters/paycode-master/paycode-master.component'
import { MinimumWagesPeriodComponent } from './pages/minimum-wages/minimum-wages-period/minimum-wages-period.component'
import { IndustrySkillDesignationMappingComponent } from './pages/minimum-wages/industry-skill-designation-mapping/industry-skill-designation-mapping.component'
import { ZoneLocationMappingComponent } from './pages/minimum-wages/zone-location-mapping/zone-location-mapping.component'
import { NotificationsComponent } from './pages/minimum-wages/notifications/notifications.component'
import { MinimumWagesComponent } from './pages/minimum-wages/minimum-wages/minimum-wages.component'
import { ManageEmployeeComponent } from './pages/onboard-employee/manage-employee/manage-employee.component'
import { EmployeeMasterUploadComponent } from './pages/onboard-employee/employee-master-upload/employee-master-upload.component'
import { EmployeeReportComponent } from './pages/onboard-employee/employee-report/employee-report.component'
import { EmployeeCtcComponent } from './pages/onboard-employee/employee-ctc/employee-ctc.component'
import { PremiseMasterComponent } from './pages/onboard-entity/premise-master/premise-master.component'
import { UserBranchMappingComponent } from './pages/onboard-entity/user-branch-mapping/user-branch-mapping.component'
import { AddnoticesComponent } from './pages/notices/addnotices/addnotices.component'
import { ComplianceAssignmentComponent } from './pages/onboard-entity/compliance-assignment/compliance-assignment.component'
import { ManageCompliancesComponent } from './pages/onboard-entity/manage-compliances/manage-compliances.component'
import { CustomerMasterComponent } from './pages/onboard-entity/customer-master/customer-master.component';
import { ReviewRegulatoryAbstractsComponent } from './pages/abstract/review-regulatory-abstracts/review-regulatory-abstracts.component';
import { MasterAbstractsComponent } from './pages/abstract/master-abstracts/master-abstracts.component';
import { LabourDepartmentAddressComponent } from './pages/abstract/labour-department-address/labour-department-address.component';
import { ActivityCalenderComponent } from './pages/master-scoping/activity-calender/activity-calender.component';
import { UserMasterComponent } from './pages/onboard-entity/user-master/user-master.component';
import { ComplianceAssignmentReportComponent } from './pages/reports/compliance-assignment-report/compliance-assignment-report.component';
import { MyComplianceDocumentComponent } from './pages/statutorydocument/my-compliance-document/my-compliance-document.component';
import { ComplianceStatusReportComponent } from './pages/reports/compliance-status-report/compliance-status-report.component';
import { DigitalSignatureComponent } from './pages/my-documents/digital-signature/digital-signature.component';
import { VarianceReportComponent } from './pages/reports/variance-report/variance-report.component';
import { NoticesComponent } from './pages/notices/notices/notices.component';
import { EcrMasterComponent } from './pages/ecr/ecr-master/ecr-master.component';
import { CLRAAssignmentReportComponent } from './pages/reports/clra-assignment-report/clra-assignment-report.component';
import { DocumentGenerationComponent } from './pages/workspace/document-generation/document-generation.component';
import { EcrConfirmationComponent } from './pages/ecr/ecr-confirmation/ecr-confirmation.component';
import { ClientRegistrationRepositoryComponent } from './pages/my-documents/client-registration-repository/client-registration-repository.component';
import { RegistrationRepositoryComponent } from './pages/my-documents/registration-repository/registration-repository.component';
import { ClientCommercialSetupComponent } from './pages/Invoice/Setup/client-commercial-setup/client-commercial-setup.component';
import { ClientStatutoryDocumentComponent } from './pages/statutorydocument/client-statutory-document/client-statutory-document.component';
import { MisDocumentComponent } from './pages/mis-document/mis-document/mis-document.component';
import { InvoiceDetailsComponent } from './pages/Invoice/invoice-details/invoice-details.component';
import { LCESICComponent } from './pages/ESIC/lc-esic/lc-esic.component';
import { PoDetailsComponent } from './pages/Invoice/po-details/po-details.component';
import { CriticalDocumentsComponent } from './pages/critical-documnets/critical-documents/critical-documents.component';
import { NoticeClientPortalComponent } from './pages/notices/notice-client-portal/notice-client-portal.component';
import { InputFilesClientComponent } from './pages/inputfiles/input-files-client/input-files-client.component';
import { InvoiceCreationComponent } from './pages/Invoice/invoice-creation/invoice-creation.component';
import { GstUploadComponent } from './pages/Invoice/gst-upload/gst-upload.component';
import { LCMyReportsMonthlyComponent } from './pages/compliance-summary/lc-my-reports-monthly/lc-my-reports-monthly.component';
import { LCMyReportsOnboardingComponent } from './pages/compliance-summary/lc-my-reports-onboarding/lc-my-reports-onboarding.component';
import { GstApprovalComponent } from './pages/Invoice/gst-approval/gst-approval.component';
import { InvoiceReportComponent } from './pages/Invoice/invoice-report/invoice-report.component';
import { OutputChallanCustomerComponent } from './pages/inputfiles/output-challan-customer/output-challan-customer.component';
import { InputFilesPerformerComponent } from './pages/inputfiles/input-files-performer/input-files-performer.component';
import { OutputChallanPerformerComponent } from './pages/inputfiles/output-challan-performer/output-challan-performer.component';
import { InvoiceFaceSheetComponent } from './pages/Invoice/invoice-face-sheet/invoice-face-sheet.component';
import { AnchorDashboardSummariesComponent } from './pages/inputfiles/anchor-dashboard-summaries/anchor-dashboard-summaries/anchor-dashboard-summaries.component';
import { InvoiceApprovalComponent } from './pages/Invoice/invoice-approval/invoice-approval.component';
import { CreditnoteComponent } from './pages/Invoice/creditnote/creditnote.component';
import { InvoiceDraftToRegularComponent } from './pages/Invoice/invoice-draft-to-regular/invoice-draft-to-regular.component';
import { AgreementDetailsMasterComponent } from './pages/master-scoping/agreement-details-master/agreement-details-master.component';
import { CommercialMappingComponent } from './pages/master-scoping/commercial-mapping/commercial-mapping.component';
import { MasterScopingSetupComponent } from './pages/master-scoping/master-scoping-setup/master-scoping-setup.component';
import { InvoiceUpdationComponent } from './pages/Invoice/invoice-updation/invoice-updation.component';

export const appRoutes: Routes = [
    { path: '', redirectTo: '/Login', pathMatch: 'full' },
    { path: 'Login', component: LoginComponent },
    { path: 'Login', component: LoginComponent },
    { path: 'EntityDashboard', component: EntitydashboardComponent },
    { path: 'ComplianceDashboard', component: ComplianceDashboardComponent },
    { path: 'Workspace', component: WorkspaceComponent },
    { path: 'DispenceryMaster', component: DispenceryMasterComponent },
    { path: 'SubcodeMaster', component: EsicSubcodeComponent },
    { path: 'ESICInputs', component: ESICInputsComponent },
    { path: 'ESICReport', component: ESICReportComponent },
    { path: 'LocationMaster', component: LocationMasterComponent },
    { path: 'DesignationMaster', component: DesignationMasterComponent },
    { path: 'LeaveTypeMaster', component: LeaveTypeMasterComponent },
    { path: 'StatutoryMaster', component: StatutoryMasterComponent },
    { path: 'ChallanPTSlab', component: ChallanPtSlabComponent },
    { path: 'PayCodeMapping', component: PaycodeMasterComponent },
    { path: 'MinimumWagesPeriod', component: MinimumWagesPeriodComponent },
    { path: 'IndustrySkillDesignationMapping', component: IndustrySkillDesignationMappingComponent },
    { path: 'ZoneLocationMapping', component: ZoneLocationMappingComponent },
    { path: 'Notifications', component: NotificationsComponent },
    { path: 'MinimumWages', component: MinimumWagesComponent },
    { path: 'ManageEmployee', component: ManageEmployeeComponent },
    { path: 'EmployeeMasterUpload', component: EmployeeMasterUploadComponent },
    { path: 'EmployeeMasterReport', component: EmployeeReportComponent },
    { path: 'EmployeeCTCMaster', component: EmployeeCtcComponent },
    { path: 'PremiseMaster', component: PremiseMasterComponent },
    { path: 'UserBranchMapping', component: UserBranchMappingComponent },
    { path: 'AddNotice', component: AddnoticesComponent },
    { path: 'ComplianceAssignment', component: ComplianceAssignmentComponent },
    { path: 'ManageCompliances', component: ManageCompliancesComponent },
    { path: 'CustomerMaster', component: CustomerMasterComponent },
    { path: 'ReviewRegulatoryAbstracts', component: ReviewRegulatoryAbstractsComponent },
    { path: 'Master_Abstracts', component: MasterAbstractsComponent },
    { path: 'LabourDepartmentAddress', component: LabourDepartmentAddressComponent },
    { path: 'ActivityCalender', component: ActivityCalenderComponent },
    { path: 'UserMaster', component: UserMasterComponent },
    { path: 'ComplianceAssignmentReport', component: ComplianceAssignmentReportComponent },
    { path: 'LCMyComplianceDocumentComponent', component: MyComplianceDocumentComponent },
    { path: 'ComplianceStatusReport', component: ComplianceStatusReportComponent },
    { path: 'DigitalSignature', component: DigitalSignatureComponent },
    { path: 'VarianceReport', component: VarianceReportComponent },
    { path: 'Notices', component: NoticesComponent },
    { path: 'ECRMaster', component: EcrMasterComponent },
    { path: 'CLRAAssignment', component: CLRAAssignmentReportComponent },
    { path: 'DocumentGeneration', component: DocumentGenerationComponent },
    { path: 'EcrConfirmation', component: EcrConfirmationComponent },
    { path: 'ClientRegistrationRepository', component: ClientRegistrationRepositoryComponent },
    { path: 'RegistrationRepository', component: RegistrationRepositoryComponent },
    { path: 'ClientCommercialSetup', component: ClientCommercialSetupComponent },
    { path: 'ClientStatutoryDocument', component: ClientStatutoryDocumentComponent },
    { path: 'LC_MIS', component: MisDocumentComponent },
    { path: 'InvoiceDetails', component: InvoiceDetailsComponent },
    { path: 'LC_ESIC', component: LCESICComponent },
    { path: 'PODetails', component: PoDetailsComponent },
    { path: 'CriticalDocuments', component: CriticalDocumentsComponent },
    { path: 'NoticeClientPortal', component: NoticeClientPortalComponent },
    { path: 'InputFilesClient', component: InputFilesClientComponent },
    { path: 'InvoiceCreation', component: InvoiceCreationComponent },
    { path: 'GstUpload', component: GstUploadComponent },
    { path: 'LC_MyReportsMonthly', component: LCMyReportsMonthlyComponent },
    { path: 'LC_MyReportsOnboarding', component: LCMyReportsOnboardingComponent },
    { path: 'InvoiceGstUploadApproval', component: GstApprovalComponent },
    { path: 'InvoiceReport', component: InvoiceReportComponent },
    { path: 'OutputChallanCustomer', component: OutputChallanCustomerComponent },
    { path: 'InputFilesPerformer', component: InputFilesPerformerComponent },
    { path: 'OutputChallanPerformer', component: OutputChallanPerformerComponent },
    { path: 'InvoiceFaceSheet', component: InvoiceFaceSheetComponent },
    { path: 'AnchorDashboardSummary', component: AnchorDashboardSummariesComponent },
    { path: 'InvoiceApproval', component: InvoiceApprovalComponent },
    { path: 'CreditNote', component: CreditnoteComponent },
    { path: 'InvoiceDraftToRegular', component: InvoiceDraftToRegularComponent },
    { path: 'ActivityCalender', component: ActivityCalenderComponent },
    { path: 'AgreementDetails', component: AgreementDetailsMasterComponent },
    { path: 'CommercialMapping', component: CommercialMappingComponent },
    { path: 'MasterScopingSetup', component: MasterScopingSetupComponent },
    { path: 'InvoiceFaceSheet', component: InvoiceFaceSheetComponent},
    { path: 'InvoiceApproval', component: InvoiceApprovalComponent},
    { path: 'CreditNote', component: CreditnoteComponent},
    { path: 'InvoiceUpdation', component: InvoiceUpdationComponent},
    
];
