   <kendo-dialog #dialogRef title="Notification Add Details" (close)="closePopup()"  [width]="1000" [height]="380" class="custom-dialog">
    <div class="dialog-content">
        <table class="form-table">
            <tbody>
                <tr>
                    <td class="label-cell">
                        <label class="f-label">State<span style="color:red;">*</span></label>
                    </td>
                    <td class="input-cell">
                        <div [ngClass]="{'input-invalid': !selectedState.id && showValidationErrors}">
                        <app-combo-box class="uniform-size"
                                       [Placeholder]="defaultState"
                                       [valueField]="'id'"
                                       [textField]="'name'"
                                       [selectedValue]="selectedState"
                                       (filterChange)="fetchStateMasters()"
                                       (valueChange)="DropdownValueChange($event)"
                                       [data]="allStatesData"
                                       [width]="'250px'"
                                       
                                       >
                        </app-combo-box>
                        </div>
                    </td>
                    <td class="label-cell">
                        <label class="f-label">Notified On<span style="color:red">*</span></label>
                    </td>
                    <td class="input-cell">
                        <div [ngClass]="{'input-invalid': !notifiedOn && showValidationErrors}">
                        <app-kendo-datepicker class="uniform-size"
                                              [(value)]="notifiedOn"
                                              (valueChange)="onDateChange($event, 'notifiedOn')"
                                              [format]="'dd-MM-yyyy'"
                                              [placeholder]="placeholder"
                                              [max]="todayDate"
                                              [width]="'250px'"
                                              
                                              >
                        </app-kendo-datepicker>
                    </div>
                    </td>
                </tr>
                <tr>
                    <td class="label-cell">
                        <label class="f-label">Effective From<span style="color:red">*</span></label>
                    </td>
                    <td class="input-cell">
                        <div [ngClass]="{'input-invalid': !effectiveFrom && showValidationErrors}">
                        <app-kendo-datepicker class="uniform-size"
                                              [(value)]="effectiveFrom"
                                              (valueChange)="onDateChange($event, 'effectiveFrom')"
                                              [format]="'dd-MM-yyyy'"
                                              [placeholder]="placeholder"
                                              [width]="'250px'"
                                              
                                              (blur)="onDateBlur()"
                                              >
                        </app-kendo-datepicker>
                        <div *ngIf="showEffectiveFromError" class="validation-error text-invalid">
                            Effective From" should be greater than or equal to 'Notified On' date.
                        </div>
                    </div>
                    </td>
                    <td class="label-cell">
                        <label class="f-label">Effective To<span style="color:red">*</span></label>
                    </td>
                    <td class="input-cell">
                        <div [ngClass]="{'input-invalid': !effectiveTo && showValidationErrors}">
                        <app-kendo-datepicker class="uniform-size"
                                              [(value)]="effectiveTo"
                                              (valueChange)="onDateChange($event, 'effectiveTo')"
                                              [format]="'dd-MM-yyyy'"
                                              [placeholder]="placeholder"
                                              [width]="'250px'"
                                              (blur)="onDateBlur()"
                                              >
                        </app-kendo-datepicker>
                    </div>
                        <div *ngIf="showEffectiveToError" class="validation-error text-invalid">
                            Effective To" should be greater than or equal to 'Notified On' date.
                        </div>
                        <div *ngIf="showDateError" class="validation-error text-invalid">
                            Effective To" should be greater than 'Effective From' date.
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="label-cell">
                        <label class="f-label">Notification No <span class="text-danger">*</span></label>
                    </td>
                    <td class="input-cell">
                        <div [ngClass]="{'input-invalid': (!notificationNo || notificationError) && showValidationErrors}">
                        <kendo-textbox id="notificationNo" class="uniform-size f-textbox"
                                       [(ngModel)]="notificationNo"
                                       (ngModelChange)="validateNotificationNumber(notificationNo)"
                                       (input)="onInputChange($event)" >
                        </kendo-textbox>
                        </div>
                        <div *ngIf="notificationError" class="validation-error text-invalid">
                            {{ notificationError }}
                        </div>
                    </td>
                    <td colspan="2"></td>
                </tr>
            </tbody>
        </table>

    </div>
    
        <button class="figma-btn-blue figma-popup save-btn" (click)="addOrUpdateNotification()" >
            Save
        </button>
 
</kendo-dialog>

<app-alert-popup *ngIf="alertData" [Width]="270" [Height]="270" [Data]="alertData" (close)="closeAlert()"></app-alert-popup>
