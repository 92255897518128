<kendo-dialog #dialogRef title="View Address" (close)="closePopup()"  [height]="550" [width]="1150"
    class="custom-dialog">

    <kendo-grid class="custom-grid" [data]="gridData" [columnMenu]="true" [pageSize]="pageSize" [pageable]="true" [style.height]="600"
        (pageChange)="pageChange($event)" [skip]="skip" [resizable]="true" [filter]="filter" filterable="menu">
        <kendo-grid-column field="dM_Name" title="Address Type">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div [title]="dataItem.dM_Name" class="cell-content">
                    {{ dataItem.dM_Name }}
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="dM_Value" title="Address">
            <ng-template kendoGridCellTemplate let-dataItem>
         
                    <kendo-multiselect
                    [data]="dataItem.frequencyOptions"
                    [(ngModel)]="dataItem.selectedOptions"
                    [valueField]="'uniqueID'" 
                    [textField]="'addressName'"
                    (valueChange)="onValueChange($event, dataItem)"
                    placeholder="Select Address"
                    [tagMapper]="tagMapper">
                 
                </kendo-multiselect>

            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Action" [width]="100">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <img src="../assets/vectors/save1.svg" alt="Save Icon" class="svg-icon" (click)="onSave(dataItem)"
                    style="margin-left: 25px;" />
            </ng-template>
        </kendo-grid-column>
        <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
            <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
            <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
            <kendo-pager-next-buttons></kendo-pager-next-buttons>
            <kendo-pager-input></kendo-pager-input>
            <kendo-pager-info></kendo-pager-info>
            <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
        </ng-template>
    </kendo-grid>

    <div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>
</kendo-dialog>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270" [Data]="alertData" (close)="cloasAlert()"></app-alert-popup>

