<div class="col-md-12 colpadding0">
    <div class="entity-container d-flex flex-column">
        <div class="selected-values">
            <img *ngIf="!showTranslation" src="../assets/vectors/Open.svg" class="svg-icon-btn" alt="" (click)="toggle('translation')">
            <img *ngIf="showTranslation" src="../assets/vectors/Closeminus.svg" class="svg-icon-btn" alt="" (click)="toggle('translation')">
            <span class="f-label">Translation Required</span>
        </div>
        <div *ngIf="showTranslation" class="selected-container">
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Translation Required</label>
                    <h4 class="f-label" style="width: 50px;"><input id="translation" value="true" type="radio" [(ngModel)]="TranslationRequired"
                            [ngModelOptions]="{standalone: true}" class="custom-radio" name="translation"
                            (change)="onRadioChange('Y','translation')" />Yes</h4>
                    <h4 class="f-label" style="width: 50px;"><input id="translation" [(ngModel)]="TranslationRequired"
                            [ngModelOptions]="{standalone: true}" value="false" type="radio" class="custom-radio"
                            name="translation" (change)="onRadioChange('N','translation')" />No</h4>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Translation Request Date</label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" [ngModel]="formattedTranslationRequestDate" disabled>
                    </div>
                </div>

            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Translated Document Upload date</label>
                    <input type="text" class="form-control f-textbox" [(ngModel)]="formattedTranslatedDocumentUploadDate" disabled>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Original Notice Document</label>
                    <div class="require-container">
                        <img src="../assets/vectors/DownloadMedium.svg" class="svg-icon-btn" alt="Action Icon" (click)="downloadDocument()" />

                    </div>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Translated Document</label>
                    <span *ngIf="!fileNames['translatedDocument']" title="Uploaded File"
                            style="color: #A0A0A0;vertical-align:sub;">No file
                            chosen</span>
                        <span *ngIf="fileNames['translatedDocument']"  [style.color]="'#A0A0A0'">
                            {{fileNames['translatedDocument']}}
                        </span>
                        <input type="file" #translatedDocument (change)="onFileChange($event, 'translatedDocument')" id="fileInput"
                            style="display: none;" accept=".doc,.docx,.pdf" />
                        <button class="figma-btn-gray" (click)="translatedDocument.click()">Browse</button>
                </div>
                
            </div>
            <div class="row EmpContainer">
                <div class="col-md-12 save-con">
                    <button class="figma-btn-blue" (click)="saveTranslation()" [disabled]="role!== 'SDEXE'" [ngClass]="{'disabled-button': role !== 'SDEXE'}">Submit</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="col-md-12 colpadding0">
    <div class="entity-container d-flex flex-column">
        <div class="selected-values">
            <img *ngIf="!showExtension" src="../assets/vectors/Open.svg" class="svg-icon-btn" alt="" (click)="toggle('extension')">
            <img *ngIf="showExtension" src="../assets/vectors/Closeminus.svg" class="svg-icon-btn" alt="" (click)="toggle('extension')">
            <span class="f-label">Extension Application</span>
        </div>
        <div *ngIf="showExtension" class="selected-container">
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Extension Required</label>
                    <h4 class="f-label" style="width: 50px;"><input id="extension" value="true" type="radio" [(ngModel)]="extensionRequired"
                            [ngModelOptions]="{standalone: true}" class="custom-radio" name="extension"
                            (change)="onRadioChange('Y','extension')" />Yes</h4>
                    <h4 class="f-label" style="width: 50px;"><input id="extension" [(ngModel)]="extensionRequired"
                            [ngModelOptions]="{standalone: true}" value="false" type="radio" class="custom-radio"
                            name="extension" (change)="onRadioChange('N','extension')" />No</h4>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Date of Draft Extention Document Received From SME</label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" [ngModel]="extensionDocumentDate" disabled>
                    </div>
                </div>

            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Extended Application Submitted To Department</label>
                    <app-kendo-datepicker class="uniform-size " [(value)]="extendedSubmittedDate"
                        (valueChange)="onDateChange($event, 'extendedSubmittedDate')" [format]="'dd-MM-yyyy'"
                        [placeholder]="placeholder" [width]="'250px'" [isDisabled]="status === 'Closed'" [class.input-invalid]="!extendedSubmittedDate && showValidationErrors"
                        [hasError]="!extendedSubmittedDate && showValidationErrors">
                    </app-kendo-datepicker>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Extended Date Granted</label>
                    <app-kendo-datepicker class="uniform-size " [(value)]="extendedGrantedDate"
                        (valueChange)="onDateChange($event, 'extendedGrantedDate')" [format]="'dd-MM-yyyy'"
                        [placeholder]="placeholder" [width]="'250px'" [isDisabled]="status === 'Closed'" [class.input-invalid]="!extendedGrantedDate && showValidationErrors"
                        [hasError]="!extendedGrantedDate && showValidationErrors">
                    </app-kendo-datepicker>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Draft Extension Application (from SME)</label>
                    <img src="../assets/vectors/DownloadMedium.svg" class="svg-icon-btn" alt="Action Icon" (click)="downloadExtensionApplication()" />
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Acknowledgment Document</label>
                    <div class="require-container">
                        <span *ngIf="!fileNames['acknowledgmentDocument']" title="Uploaded File"
                            style="color: #A0A0A0;vertical-align:sub;">No file
                            chosen</span>
                        <span *ngIf="fileNames['acknowledgmentDocument']" [style.color]="'#A0A0A0'">
                            {{fileNames['acknowledgmentDocument']}}
                        </span>
                        <input type="file" #acknowledgmentDocument (change)="onFileChange($event, 'acknowledgmentDocument')" id="fileInput"
                            style="display: none;" accept=".doc,.docx,.pdf" />
                        <button class="figma-btn-gray" (click)="acknowledgmentDocument.click()">Browse</button>

                    </div>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-12 save-con">
                    <button class="figma-btn-blue" (click)="saveExtension()" [disabled]="role!== 'SDEXE'" [ngClass]="{'disabled-button': role !== 'SDEXE'}">Submit</button>
                </div>
            </div>
        </div>

    </div>
</div>
<div class="col-md-12 colpadding0">
    <div class="entity-container d-flex flex-column">
        <div class="selected-values">
            <img *ngIf="!showNotice" src="../assets/vectors/Open.svg" class="svg-icon-btn" alt="" (click)="toggle('notice')">
            <img *ngIf="showNotice" src="../assets/vectors/Closeminus.svg" class="svg-icon-btn" alt="" (click)="toggle('notice')">
            <span class="f-label">Notice Response</span>
        </div>
        <div *ngIf="showNotice" class="selected-container">
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Notice Document Received On</label>
                    <input type="text" class="form-control f-textbox" [(ngModel)]="noticeDocumentReceived" disabled>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Draft Response Received From SME</label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" [ngModel]="draftResponseFromSME" disabled>
                    </div>
                </div>

            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Notice Response Submitted To Department</label>
                    <div class="require-container">
                        <app-kendo-datepicker class="uniform-size " [(value)]="noticeResponseDate"
                        (valueChange)="onDateChange($event, 'noticeResponseDate')" [format]="'dd-MM-yyyy'"
                        [placeholder]="placeholder" [width]="'250px'" [isDisabled]="status === 'Closed'" [class.input-invalid]="!noticeResponseDate && showValidationErrors"
                        [hasError]="!noticeResponseDate && showValidationErrors">
                    </app-kendo-datepicker>

                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Acknowledgment Document</label>
                    <div class="require-container">
                        <span *ngIf="!fileNames['noticeAcknowledgment']" title="Uploaded File"
                            style="color: #A0A0A0;vertical-align:sub;">No file
                            chosen</span>
                        <span *ngIf="fileNames['noticeAcknowledgment']" [style.color]="'#A0A0A0'">
                            {{fileNames['noticeAcknowledgment']}}
                        </span>
                        <input type="file" #noticeAcknowledgment (change)="onFileChange($event, 'noticeAcknowledgment')" id="fileInput"
                            style="display: none;" accept=".doc,.docx,.pdf" />
                        <button class="figma-btn-gray" (click)="noticeAcknowledgment.click()">Browse</button>
                    </div>
                </div>

            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Draft Response (from SME)</label>
                    <div class="require-container">
                        <img src="../assets/vectors/DownloadMedium.svg" alt="Action Icon" class="svg-icon-btn"  (click)="downloadDraftResponseFronSME()" />
                    </div>
                </div>              
            </div>
            <div class="row EmpContainer">
                <div class="col-md-12 save-con">
                    <button class="figma-btn-blue" (click)="saveNotice()" [disabled]="role!== 'SDEXE'" [ngClass]="{'disabled-button': role !== 'SDEXE'}">Submit</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row EmpContainer">
    <div class="col-md-12 save-con">
        <button class="figma-btn-blue" (click)="goBack()" >Back</button>
    </div>
</div>
<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>
<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270"  [Data]="alertData"
(close)="closeAlert()"></app-alert-popup>