import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DigitalSignatureService {
  private baseUrl: string = environment.apiUrl;

  constructor(private http:HttpClient) { }

  getGridData(clientId:string): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/DigitalSignature/GetAll_DigitalSignature?ClientID=${clientId}`;
    return this.http.get(url, { observe: 'response' });
}

  getEmployees(clientId:string): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/DigitalSignature/GetAll_EmployeeMaster?ClientId=${clientId}`;
    return this.http.post(url, {},{ observe: 'response' });
}
saveUpdateData(empid: string,person: string,desig: string,mobile: string, efrom: string | null, eto: string| null,remark: string, status: string,entityid: string, userid: string): Observable<HttpResponse<any>> {
  const url = `${this.baseUrl}/api/DigitalSignature/saveupdatedigitalsignature?empid=${empid}&person=${person}&desig=${desig}&mobile=${mobile}&efrom=${efrom}&eto=${eto}&remark=${remark}&status=${status}&entityid=${entityid}&userid=${userid}`;
  return this.http.post(url,{}, { observe: 'response' });
}
}
