<kendo-dialog 
#dialogRef 
    title="Upload Seal and Signature"
    (close)="closePopup()"
    [minWidth]="300"
    [width]="500"
    [height]="345"
    class="custom-dialog">
  
  <!-- <label for="upload" class="f-label" style="margin-left: 15px;">Upload</label> -->
  
  <div class="row col-12 dialog-content">
    <div class="col-md-12 form-group">
      <div class="drop-zone" 
           (dragover)="onDragOver($event)" 
           (dragleave)="onDragLeave($event)" 
           (drop)="onDrop($event)">
        <div *ngIf="!selectedFile" class="f-label" style="font-size: 18px;">
          <div class="col-md-12" style="margin-top: 20px;">
            <img src="../assets/vectors/cloud.svg" alt=""  >
          </div>
          <div class="col-md-8">
            <label style="margin-left: 105px;">{{ dropzoneMessage }} </label>
          </div>
          <div class="col-md-3">
            <label style="color: #125AAB; text-decoration: underline; cursor: pointer; margin-left: -40px;  font-weight: 500;">
              Browse
              <input id="file-upload" type="file" style="display: none;" (change)="onFileSelect($event)"
                     accept=".jpg,.jpeg,.png" />
            </label>
          </div>
        </div>
        <p *ngIf="selectedFile" style="margin-top: 30px;">{{ selectedFile.name }}</p>
      </div>
    </div>
  </div>

  <kendo-dialog-actions style="border: none;">
    <button  class="figma-btn-blue figma-popup" (click)="uploadFile()">Upload</button>
  </kendo-dialog-actions>
</kendo-dialog>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270" [Data]="alertData" (close)="closeAlertPopup()"></app-alert-popup>
