import { Component, OnInit } from '@angular/core';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { UserService } from '../../../service/user.service';
import { MasterScopingSetupService } from '../../../service/master-scoping-setup.service';
import { MasterScopingAddPopupComponent } from '../../popups/master-scoping-add-popup/master-scoping-add-popup.component';
import { NgIf } from '@angular/common';
import { MasterScopeEditPopupComponent } from '../../popups/master-scope-edit-popup/master-scope-edit-popup.component';

@Component({
  selector: 'app-master-scoping-setup',
  standalone: true,
  imports: [GridActionItemComponent,MasterScopingAddPopupComponent,NgIf,MasterScopeEditPopupComponent],
  templateUrl: './master-scoping-setup.component.html',
  styleUrl: './master-scoping-setup.component.css'
})
export class MasterScopingSetupComponent implements OnInit {
  public gridData: GridDataResult = { data: [], total: 0 };
  openAdd=false
  openEdit=false
  selectedEntity!:any
  constructor(private masterScopingService:MasterScopingSetupService,private user:UserService){}
  ngOnInit(): void {
    this.getGridEntities();
  }
  getGridEntities(){
    // const custId = parseInt(localStorage.getItem('CustomerId') || '0');
    const custId =this.user.getCustomerId();    
    this.masterScopingService.getGridData(custId).subscribe({
      next:(response)=>{
        console.log('Response',response);
        const data=response.body;
        this.gridData={
          data:data,
          total:data.length
        }
        
      }
    })
  }
  onActionClick(event:any){
    console.log('Action Click:',event );  
    this.selectedEntity=event.dataItem
    this.openEdit=true
  }

  addNew(){
  this.openAdd=true;
  }

  closeAddDialog(){
    this.openAdd=false;
    this.getGridEntities();
  }
  closeEditDialog(){
    this.openEdit=false;
    this.getGridEntities();
  }

  public parentColumns: any[] = [
    { field: 'key', title: 'Entity' },
    {
      field: 'Action',
      isIconColumn: true,
      title: 'Action',
      filteredIcons: [
        {
          url: '../assets/vectors/EyeIcon.svg',
          alt: 'Add/View',
          action: 'edit',
          class:'svg-icon'
        }
      ]
    }
  ];
}
