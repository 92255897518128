import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { EsicreportService } from '../../../service/esicreport.service';
import { GridComponent } from '../../../components/grid/grid.component';
import { UserService } from '../../../service/user.service';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { BlobService } from '../../../service/blob.service';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { load } from '@progress/kendo-angular-intl';

@Component({
  selector: 'app-esicreport',
  standalone: true,
  imports: [DropdownListComponent, CommonModule, GridComponent, IndicatorsModule, AlertPopupComponent],
  templateUrl: './esicreport.component.html',
  styleUrls: ['./esicreport.component.css']
})
export class ESICReportComponent {
  @Input() className: string = 'my-custom-class';
  showGenerateReport = false;
  showState = false;
  showMonthYearDropdowns = false; // Flag to control visibility of Month and Year dropdowns
  public dropdownDataPrimary: any[] = [{ id: 0, name: 'Select Report Type' }, { id: 1, name: 'Master' }, { id: 2, name: 'ESIC Reports' }];
  public dropdownDataSecondary: any[] = [];
  public dropdownDataMonth: any[] = [];
  public dropdownDataYear: any[] = [];
  public gridData: any[] = [];
  public columns: any[] = [];
  EntityId: string = '';
  public alertData = { alertMessage: '', alertVisible: '' };

  public defaultSelectedValuePrimary = { id: 0, name: 'Select Report Type' };
  public defaultSelectedValue = { id: 0, name: 'Select Type' };
  public defaultSelectedValueMonth = { value: 0, displayvalue: 'Select Month' };
  public defaultSelectedValueYear = { value: 0, displayvalue: 'Select Year' };
  loading: boolean = false;

  constructor(private esicreportService: EsicreportService, private userService: UserService, private blobService: BlobService) { }

  selectedPrimaryValue: any = this.defaultSelectedValue;
  selectedSecondaryValue: any = this.defaultSelectedValue;
  selectedMonth: any = this.defaultSelectedValueMonth;
  selectedYear: any = this.defaultSelectedValueYear;

  ngOnInit(): void {
    this.EntityId = 'GZ0RG';
    this.loadMonthYearData();
  }

  closeAlert() {
    this.alertData = { alertMessage: '', alertVisible: '' };
  }

  applyFilters() {
    this.loading = true;
    if (!this.defaultSelectedValuePrimary) {
      this.alertData = { alertMessage: 'Select Report Type', alertVisible: 'error' };
      this.loading = false;
      return;
    }

    try {
      if (this.selectedPrimaryValue === "Master") {
        if (this.selectedSecondaryValue === "States") {
          this.columns = [
            { field: 'stateCode', title: 'State Code' },
            { field: 'stateName', title: 'State Name' }
          ];
          this.loadMasterGridData('FetchESICStateData');
        } else if (this.selectedSecondaryValue === "District") {
          this.columns = [
            { field: 'stateCode', title: 'State Code' },
            { field: 'district', title: 'District' },
            { field: 'subCode', title: 'SubCode' }
          ];
          this.loadMasterGridData('FetchESICDistrictData');
        } else if (this.selectedSecondaryValue === "Locations") {
          this.columns = [
            { field: 'locationCode', title: 'Location Code' },
            { field: 'pincode', title: 'Pincode' },
            { field: 'subCode', title: 'SubCode' }
          ];
          this.loadMasterGridData('FetchESICLocationData');
        } else {
          this.loading = false;
          this.alertData = { alertMessage: 'Select regions.', alertVisible: 'error' };
          return;
        }
      } else if (this.selectedPrimaryValue === "ESIC Reports") {
        if (this.selectedMonth.displayvalue =='Select Month') {
          this.alertData = { alertMessage: 'Select Month', alertVisible: 'error' };
          this.loading = false;
          return;
        }
        if (this.selectedYear.displayvalue =='Select Year') {
          this.loading = false;
          this.alertData = { alertMessage: 'Select Year', alertVisible: 'error' };
          return;
        }
        this.columns = [
          { field: 'clientId', title: 'ClientId' },
          { field: 'empNo', title: 'Emp No' },
          { field: 'subCode', title: 'SubCode' },
          { field: 'doj', title: 'DOJ' },
          { field: 'status', title: 'Status' },
          { field: 'esicNo', title: 'ESICNo' },
          { field: 'errorMessage', title: 'Error Message' },
          { field: 'requestedDate', title: 'Requested Date' }
        ];

        if (this.selectedSecondaryValue === "Generation") {
          this.loadReportsGridData('GEN');
        } else if (this.selectedSecondaryValue === "Updation") {
          this.loadReportsGridData('UPD');
        } else if (this.selectedSecondaryValue === "Downloads") {
          this.loadReportsGridData('DOW');
        } else {
          this.loading = false;
          this.alertData = { alertMessage: 'Select a valid report type.', alertVisible: 'error' };
          return;
        }
      } else {
        this.loading = false;
        this.alertData = { alertMessage: 'Select Report Type.', alertVisible: 'error' };
        return;
      }
    } catch (error) {
      this.loading = false;
      this.alertData = { alertMessage: 'An error occurred while loading data.', alertVisible: 'error' };
      console.error('Error in applyFilters:', error);
    }
  }

  exportToExcel() {
    if (!this.defaultSelectedValue) {
      this.alertData = { alertMessage: 'Select Report Type', alertVisible: 'error' };
      return;
    }
    this.loading = true;

    try {
      if (this.selectedPrimaryValue === "Master") {
        if (this.selectedSecondaryValue === "State") {
          this.callMasterExportAPI('ESICStates_ExportToExcel');
        } else if (this.selectedSecondaryValue === "District") {
          this.callMasterExportAPI('ESICDistricts_ExportToExcel');
        } else if (this.selectedSecondaryValue === "Locations") {
          this.callMasterExportAPI('ESICLocations_ExportToExcel');
        } else {
          this.callMasterExportAPI('ESICStates_ExportToExcel');
        }
      } else if (this.selectedPrimaryValue === "ESIC Reports") {
        if (!this.selectedMonth || !this.selectedYear) {
          this.alertData = { alertMessage: 'Select both Month and Year', alertVisible: 'error' };
          this.loading = false;
          return;
        }

        if (this.selectedSecondaryValue === "Generation") {
          this.callExportAPI('GEN');
        } else if (this.selectedSecondaryValue === "Updation") {
          this.callExportAPI('UPD');
        } else if (this.selectedSecondaryValue === "Downloads") {
          this.callExportAPI('DOW');
        } else {
          this.alertData = { alertMessage: 'Select a valid option for ESIC Reports', alertVisible: 'error' };
          this.loading = false;
          return;
        }
      } else {
        this.alertData = { alertMessage: 'Select Report Type', alertVisible: 'error' };
        this.loading = false;
      }
    } catch (error) {
      this.loading = false;
      this.alertData = { alertMessage: 'An error occurred during export', alertVisible: 'error' };
      console.error(error);
    }
  }

  callExportAPI(ActionType: string) {
    this.loading = true;
    this.esicreportService.getESICReportBlobLink(this.EntityId, this.selectedMonth, this.selectedYear, ActionType).subscribe({
      next: (response) => {
        debugger;
        const data = response.body;
        const blobLink = data.filePath;
        const blobName = this.blobService.extractBlobName(blobLink);
        setTimeout(() => {
          this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
            debugger;
            const sasToken = tokenResponse.result;
            this.blobService.downloadFile(blobName, sasToken);
            this.loading = false;
          });
        }, 5000);
      }, error: (error) => {
        console.error('Error fetching blob link:', error);
        this.showState = false;
        this.loading = false;
      }
    });
  }

  callMasterExportAPI(apiname: string) {
    this.loading = true;
    this.esicreportService.getESICMasterReportBlobLink(apiname).subscribe({
      next: (response) => {
        debugger;
        const blobLink = response.filePath;
        const blobName = this.blobService.extractBlobName(blobLink);
        setTimeout(() => {
          this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
            debugger;
            const sasToken = tokenResponse.result;
            this.blobService.downloadFile(blobName, sasToken);
            this.loading = false;
          });
        }, 5000);
      },
      error: (error) => {
        console.error('Error fetching blob link:', error);
        this.showState = false;
        this.loading = false;
      }
    });
  }

  generateReport() {
    if (!this.defaultSelectedValue) {
      console.log('Generating report');
    }
    else {
      this.alertData = { alertMessage: 'Select Report Type', alertVisible: 'error' };
    }
  }

  onDropdownValueChange(value: any) {
    this.selectedPrimaryValue = value.name;
    if (value.id == 1) {
      this.showState = false;
      this.defaultSelectedValue = { id: 0, name: 'Select Type' };
      this.dropdownDataSecondary = [{ id: 0, name: 'Select Type' }, { id: 1, name: 'States' }, { id: 2, name: 'District' }, { id: 3, name: 'Locations' }];
      this.showMonthYearDropdowns = false;
    }
    else if (value.id == 2) {
      this.showState = false;
      this.defaultSelectedValue = { id: 0, name: 'Select Type' };
      this.dropdownDataSecondary = [{ id: 0, name: 'Select Type' }, { id: 1, name: 'Generation' }, { id: 2, name: 'Updation' }, { id: 3, name: 'Downloads' }];
      this.showMonthYearDropdowns = true;
    }
    else if (value.id == 0) {
      this.showState = false;
      this.defaultSelectedValue = { id: 0, name: 'Select Type' };
      this.dropdownDataSecondary = [];
      this.showMonthYearDropdowns = false;
    }
  }

  onDropdownValueChangeSecond(value: any) {
    this.selectedSecondaryValue = value.name;
    this.showState = false;
  }

  onDropdownValueChangeMonth(value: any) {
    this.selectedMonth = value.value;
    this.showState = false;
  }

  onDropdownValueChangeYear(value: any) {
    this.selectedYear = value.value;
    this.showState = false;
  }

  loadMasterGridData(GridName: string) {
    this.loading = true;
    this.esicreportService.getMasterGridData(GridName).subscribe({
      next: (response) => {
        const data = response.body;
        if (data) {
          this.gridData = data;
          this.showState = true;
        }
        this.loading = false;
      },
      error: (error) => {
        console.error('Error fetching Master Grid data', error);
        this.showState = false;
        this.loading = false;
      }
    });
  }

  loadReportsGridData(ActionType: string) {
    this.loading = true;
    this.esicreportService.getReportGridData(this.EntityId, this.selectedMonth, this.selectedYear, ActionType).subscribe({
      next: (response) => {
        const data = response.body;
        if (data) {
          this.gridData = data;
          this.showState = true;
        }
        this.loading = false;
      },
      error: (error) => {
        console.error('Error fetching Master Grid data', error);
        this.showState = false;
        this.loading = false;
      }
    });
  }

  loadMonthYearData() {
    this.esicreportService.getMonth().subscribe({
      next: (response) => {
        const data = response.body;
        if (data) {
          this.dropdownDataMonth = [this.defaultSelectedValueMonth, ...data];
        }
      },
      error: (error) => {
        console.error('Error fetching Month Dropdown data', error);
      }
    });
    this.esicreportService.getYear().subscribe({
      next: (response) => {
        const data = response.body;
        if (data) {
          this.dropdownDataYear = [this.defaultSelectedValueYear, ...data];
        }
      },
      error: (error) => {
        console.error('Error fetching Year Dropdown data', error);
      }
    });
  }
}
