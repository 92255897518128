import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocationMasterServiceService {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getLocationList(State?: string, page: number = 1, pageSize: number = 10, skip: number = 0, take: number = 10): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/StateMaster/GetAll_StateLocationMaster?State=${State}&take=${take}&skip=${skip}&page=${page}&pageSize=${pageSize}`;
    return this.http.get(url, { observe: 'response' });
  }
  getExportFile(State?: string): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/StateMaster/GetAll_StateLocationMaster_Exporttoexcel?State=${State}`;
    return this.http.get(url, { observe: 'response' });
  }
  getExportMappingFile(): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/AuthorityAddress/GetExport_mapping`;
    return this.http.get(url, { observe: 'response' });
  }
  
  getStateMaster(): Observable<HttpResponse<any>> {
    return this.http.get(`${this.baseUrl}/api/StateMaster/Get_StateMaster`, { observe: 'response' })
  }

  getAllStateMasters(filter: string = ''): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/StateMaster/GetAll_StateMaster?Filter=${filter}`;
    return this.http.get(url, { observe: 'response' });
  }
  
  getStateLocationMasterForDD(state: string): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/StateMaster/GetAll_StateLocationMaster_ForDD?State=${state}`;
    return this.http.get(url, { observe: 'response' });
  }
  saveLocationMaster(value: any): Observable<HttpResponse<any>> {
    const body = {
      stateId: value.stateId,
      cityName: value.cityName,
      locationCode: value.locationCode,
      isEdit: value.isEdit,
      cityID: value.cityID
    }
    console.log('service',body);
    
    const url = `${this.baseUrl}/api/StateMaster/CreateUpdateStateCityMapping`;
    return this.http.post(url, body, { observe: 'response' });
  }
  getAddressList(): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/AuthorityAddress/Get_AddressTypeList`;
    return this.http.get(url, { observe: 'response' });
  }
  getDropdownData(stateid:string,adrtype:string,lcode:string): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/AuthorityAddress/GetAddress?stateid=${stateid}&adrtype=${adrtype}&lcode=${lcode}`;
    return this.http.get(url, { observe: 'response' });
  }
  SaveViewAddressData(addrtype:string,addrIds:string,lcode:string,stateid:string): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/RegulatoryAbstracts/SaveAddress?addrtype=${addrtype}&addrIds=${addrIds}&lcode=${lcode}&stateid=${stateid}`;
    return this.http.post(url,{}, { observe: 'response' });
  }
}