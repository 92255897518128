import { Component, EventEmitter, Output } from '@angular/core';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { GridDataResult } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-client-portal-login',
  standalone: true,
  imports: [DialogsModule,GridActionItemComponent],
  templateUrl: './client-portal-login.component.html',
  styleUrl: './client-portal-login.component.css'
})
export class ClientPortalLoginComponent {
  @Output() close = new EventEmitter<void>();
  public gridData: GridDataResult = { data: [], total: 0 };
  onActionClick(value:any){

  }
  public parentColumns: any[] = [
    {
      field: 'clientId',title: 'Client Id'},
    { field: 'userName', title: 'User Name' },
    { field: 'userId', title: 'User Id' },
    { field: 'role', title: 'Role' },
   
    {
      field: 'Action',
      isIconColumn: true,
      title: 'Sign In',
      filteredIcons: [

        {
          url: '../assets/vectors/delete.svg',
          alt: 'Delete Icon',
          action: 'delete'
        }
      ]
    }
  ];
  public closePopup(): void {
    this.close.emit();
  }
}
