import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { LabourDepartmentAddressService } from '../../../service/labour-department-address.service';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { FormsModule } from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';
import { UserService } from '../../../service/user.service';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-labour-department-add-edit-popup',
  standalone: true,
  imports: [DialogModule, DropdownListComponent, AlertPopupComponent, FormsModule, NgIf, NgClass, IndicatorsModule],
  templateUrl: './labour-department-add-edit-popup.component.html',
  styleUrl: './labour-department-add-edit-popup.component.css'
})
export class LabourDepartmentAddEditPopupComponent implements OnInit {
  @Input() header!: string;
  @Output() close = new EventEmitter<void>();
  @ViewChild('addressTextarea') addressTextarea: any;
  @Output() headerChange = new EventEmitter<string>();
  @Input() Data!: any;
  UniqueID!: string;
  userID!: number;
  loading = false;
  rowCount = 2;
  address: string = '';
  showErrorValidation = false;
  isStateDropdownDisabled = false;
  StateDropdownData: any[] = [];
  addressChangesSubscription: any;
  AddressTypeDropdownData: any[] = [];
  alertData = { alertMessage: '', alertVisible: '' };
  selectedState = { text: 'Select State', value: '' };
  selectedAddressType = { text: 'Select Address Type', value: '' };

  constructor(public LabourAddressService: LabourDepartmentAddressService, private user: UserService) { }

  ngOnInit(): void {
    this.getStateDropdownData();
    this.getAddressTypeDropdownData();
    if (this.Data) {
      //this.loading=true;
      this.InitData(this.Data);
    }
    this.userID = this.user.getUserId();
    console.log(this.userID);

  }
  // ngAfterViewInit() {
  //   this.addressTextarea.nativeElement.addEventListener('input', (event: any) => {
  //     this.rowCount = event.target.value.split('\n').length;
  //   });
  // }
  InitData(data: any) {
    console.log(data);

    this.selectedState = { text: data.stateID, value: data.stateID };
    this.selectedAddressType = { text: data.adressType, value: data.adressType };
    this.UniqueID = data.uniqueID;
    this.address = data.addressName;
    this.isStateDropdownDisabled = true;
    this.loading = false;
  }
  
  // adjustHeight() {
  //   const textarea = document.querySelector('.address-textarea') as HTMLTextAreaElement;
  //   if (textarea) {
  //     console.log('scrollHeight:', textarea.scrollHeight);
  //     console.log('style.height:', textarea.style.height);
  //     textarea.style.height = 'auto';
  //     textarea.style.height = textarea.scrollHeight + 'px';
  //     console.log('style.height after:', textarea.style.height);
  //   }
  // }
  GenerateUniqueID() {
    if (this.selectedState.value && this.selectedAddressType.value) {
      this.LabourAddressService.getUniqueID(this.selectedState.value, this.selectedAddressType.value).subscribe({
        next: (response) => {
          const data = response.body;
          this.UniqueID = data.result;
        },
        error: (error) => {
          console.error('Error fetching Branch Dropdown data', error);
        }
      });
    } else {
      this.UniqueID = '';
      this.alertData = { alertMessage: 'Please select State and Address Type', alertVisible: 'error' };
    }

  }

  onDropdownValueChange(value: any, type: string) {
    this.UniqueID = '';
    if (type == 'State') {
      this.selectedState = { text: value.text, value: value.value };
    } else if (type == 'AddressType') {
      this.selectedAddressType = { text: value.text, value: value.value };
    }
  }
  // preventLeadingSpace(event: KeyboardEvent): void {
  //   const inputElement = event.target as HTMLInputElement;
  //   if (event.key === ' ' && inputElement.selectionStart === 0) {
  //     event.preventDefault();
  //   }
  // }
  preventLeadingSpace(event: KeyboardEvent): void {
    const input = event.target as HTMLTextAreaElement;
    
    // Prevent a space if it’s the first character
    if (event.key === ' ' && input.selectionStart === 0) {
      event.preventDefault();
    }
  }
  autoExpand(event: Event): void {
    const target = event.target as HTMLTextAreaElement;
    target.style.height = 'auto';
    target.style.height = target.scrollHeight + 'px';
  }
  adjustTextareaHeight(textarea: HTMLTextAreaElement): void {
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }
  getStateDropdownData() {
    this.LabourAddressService.getStateDropdown().subscribe({
      next: (response) => {
        const data = response.body;
        this.StateDropdownData = [
          { text: 'Select State', value: '' },
          ...data.map((item: any) => ({
            text: item.sM_Name,
            value: item.sM_Code
          }))
        ];
      },
      error: (error) => {
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }
  getAddressTypeDropdownData() {
    this.LabourAddressService.getAddressTypeDropdown().subscribe({
      next: (response) => {
        const data = response.body;
        this.AddressTypeDropdownData = [
          { text: 'Select Address Type', value: '' },
          ...data.map((item: any) => ({
            text: item.dM_Name,
            value: item.dM_Value
          }))
        ];
      },
      error: (error) => {
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }
  onSave() {
    this.showErrorValidation = true;
    if (this.selectedState.value && this.selectedAddressType.value && this.UniqueID && this.address) {

      this.LabourAddressService.saveDepartmentAddress(this.selectedState.value, this.selectedAddressType.value, this.UniqueID, this.address, this.userID.toString()).subscribe({
        next: (response) => {
          console.log(response);

          const data = response.result;
          if (data == 'success') {
            if (this.Data) {
              this.alertData = { alertMessage: 'Address updated successfully', alertVisible: 'success' };
            } else {
              this.alertData = { alertMessage: 'Address added successfully', alertVisible: 'success' };
            }
          } else if (data == 'exist') {
            this.alertData = { alertMessage: 'Record Already Exist', alertVisible: 'error' };
          }
        },
        error: (error) => {
          this.alertData = { alertMessage: 'Error while saving Data', alertVisible: 'error' };
          console.error('Error fetching Branch Dropdown data', error);
        }
      });
    } else {
    }
  }
  closeAlert() {
    if (this.alertData.alertVisible == 'success') {
      this.closePopup();
    }
    this.alertData = { alertMessage: '', alertVisible: '' };

  }
  public closePopup(): void {
    this.Data = undefined;
    this.close.emit();
  }
}
