<kendo-dialog #dialogRef title="Designation Bulk Upload" (close)="closePopup()" [minWidth]="300" [height]="375"
  [width]="550" class="custom-dialog">
  <!-- title="Designation Bulk Upload" -->
  <div class="col-md-12 colpadding0">
    <header class="panel-heading tab-bg-primary"
      style="background: none !important; margin-left: 33px; padding: 0px 0px; ">
      <ul class="nav nav-tabs" style="margin-left: -23px;">
        <li class="listItem pemt" [ngClass]="{'selected-tab': designationOption === 'add'}"
          (click)="designationOption = 'add'" style="margin-left: -8px !important">
          <a>Add New Designation</a>
        </li>
        <li class="listItem pemt" [ngClass]="{'selected-tab': designationOption === 'update'}"
          (click)="designationOption = 'update'" style="margin-left: -8px !important">
          <a>Upload Designation Details</a>
        </li>
      </ul>
    </header>
  </div>

  <div class="row col-12 dialog-content">
    <div class="row sampleDocument">
      <!-- <div class="col-md-7"> -->
      <!-- <label class="f-label">Upload</label> -->
      <!-- </div> -->
      <div class="col-md-12">
        <a href="../assets/files/DesignationSample.xlsx" download="DesignationSample.xlsx"
          style="color: #125AAB; text-decoration: underline;font-size: 12px; float:right; margin-top:5px; font-weight:400;" id="btnSampleRLCS">
          Sample Document
        </a>
        <img src="../assets/vectors/Excel.svg" alt="" style="padding: 5px; float:right;">
      </div>
    </div>
    <div class="col-md-12 form-group">
      <div class="drop-zone" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
        <div *ngIf="!selectedFile" class="f-label" style="font-size: 18px;">
          <div class="col-md-12">
            <img src="../assets/vectors/cloud.svg" alt="">
          </div>
          <div class="col-md-8">
            <label style="margin-left: 95px;">{{ dropzoneMessage }}</label>
          </div>
          <div class="col-md-4">
            <label
              style="color: #125AAB; text-decoration: underline; cursor: pointer;  margin-left: -35px; font-weight: 500;">
              Browse
              <input type="file" style="display: none;" (change)="onFileSelect($event)" accept=".xls,.xlsx,.csv" />
            </label>
          </div>
        </div>
        <p *ngIf="selectedFile" style="margin-top: 30px;">{{ selectedFile.name }}</p>
      </div>
    </div>
  </div>

  <kendo-dialog-actions style="border: none;">
    <button kendoButton type="submit" (click)="uploadFiles()" class="figma-btn-blue figma-popup">
      Upload
    </button>
  </kendo-dialog-actions>

  <div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
  </div>
</kendo-dialog>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270" [Height]="265" [Data]="alertData"
  (close)="closeAlert()"></app-alert-popup>