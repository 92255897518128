import { Component, Input } from '@angular/core';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { GridModule, PageChangeEvent } from '@progress/kendo-angular-grid';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { GstApprovalService } from '../../../service/gst-approval.service';
import { UserService } from '../../../service/user.service';
import { BlobService } from '../../../service/blob.service';
import { GstApprovalUpdatePopupComponent } from '../../popups/gst-approval-update-popup/gst-approval-update-popup.component';

import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { HttpResponse } from '@angular/common/http';
import { NgModule } from '@angular/core';
@Component({
  selector: 'app-gst-approval',
  standalone: true,
  imports: [IndicatorsModule, AlertPopupComponent, GridModule, DropdownListComponent, ReactiveFormsModule, FormsModule, CommonModule, GstApprovalUpdatePopupComponent],
  templateUrl: './gst-approval.component.html',
  styleUrl: './gst-approval.component.css'
})
export class GstApprovalComponent {
  ClientGstDropdownData: any[] = [];
  selectedClient = { text: 'Select Client', value: '' };
  clientID!: string;
  showGrid: boolean = false;
  take = 10;
  skip = 0;
  page = 1;
  public pageSize: number = 10;
  // public gridData: any[] = [];
  public gridData: any;
  public checkedData: any;
  allSelected: boolean = false;
  bulkdownload!: boolean;
  showSave!: boolean;
  selectedItems: any[] = [];
  public buttonCount = 2;
  public sizes = [5, 10, 20, 50];
  public AddUpdatePopupOpen: boolean = false;
  public defaultpopupValue = { id: 0, name: 'Select' };
  public popupData!: any;
  public alertData = { alertMessage: '', alertVisible: '' };
  loading: boolean = false;
  IsUpdate: boolean = false;
  uniqueId: any;
  public isAddNewVisible: boolean = false;
  // public rowData!: string[];
  public password: string = '';
  approvalStatus: any;
  @Input() dialogTitles: string = 'Add/Edit ESIC Subcode';

  approvalStatusOptions = [
    { text: 'Select', value: "S" },
    { text: 'Approved', value: "A" },
    { text: 'Rejected', value: "R" },
    { text: 'Pending', value: "P" }
  ];



  constructor(private GstApprovalService: GstApprovalService, private user: UserService, private blobService: BlobService) { }

  ngOnInit(): void {

    this.getClientsMaster();



  }

  public data: any[] = [
    { approvalStatus: 'Approved', pfecrA_AutomationStatus: 'Status 1' },
    { approvalStatus: 'Rejected', pfecrA_AutomationStatus: 'Status 2' },
    { approvalStatus: 'Pending', pfecrA_AutomationStatus: 'Status 3' },
    // Add more data items as needed
  ];

  private editingRowIndex: number | null = null;
  private editingField: string | null = null;

  public isCellBeingEdited(rowIndex: number, field: string): boolean {
    return this.editingRowIndex === rowIndex && this.editingField === field;
  }

  public editRow(rowIndex: number, field: string): void {
    this.editingRowIndex = rowIndex;
    this.editingField = field;
  }
  public onApprovalStatusChange(event: Event, dataItem: any): void {
    const selectedValue = (event.target as HTMLSelectElement).value;
    debugger
    console.log(dataItem)
    const index = this.selectedItems.findIndex(item => item.uniqueId === dataItem.uniqueId);
    if (index !== -1) {
      this.selectedItems[index].status = selectedValue;
    } else {
      // this.selectedItems.forEach((item) => {
      //   if (item.uniqueId == dataItem.uniqueId) {
      //     // item.approvalStatus = selectedValue
      //     item.status = selectedValue
      //   }
      // });
      dataItem.status = selectedValue;
      this.selectedItems.push(dataItem);
    }

    console.log(this.selectedItems, 'statussss')
    // if (!this.selectedItems.includes(dataItem)) {
    //   this.selectedItems.push(dataItem);
    // }
    // Additional logic can be added here if needed
  }

  // onApprovalStatusChange(dataItem: any, newValue: string) {
  //   // Handle the change of approval status here
  //   console.log(`Row ${dataItem.id} updated to ${newValue}`);
  //   // You can also update the data source or perform any other logic
  // }
  onDropdownValueChange(data: any) {


    console.log('data', data)
    this.clientID = data?.value;
    console.log('client', this.clientID)

    this.selectedItems = [];

    this.GetPendingGstDetailsBasedOnClientId(this.clientID);
    // this.showGrid = true;
  }

  public pageChange(event: PageChangeEvent): void {
    console.log('Page Change Event Received:', event);
    this.skip = event.skip;
    this.pageSize = event.take;
    // this.loadGridData();
  }
  toggleSelectAll(event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    debugger
    // Update the selected state for each item in gridData
    this.gridData.forEach((item: { selected: boolean; }) => item.selected = checked);

    // Toggle bulkdownload and showSave based on checkbox state
    if (checked) {
      this.bulkdownload = true;
      this.showSave = true;
    } else {
      this.bulkdownload = false;
      this.showSave = false;
    }
  }
  onItemSelectionChange(dataItem: any) {
    console.log('dsfds', dataItem)
    if (dataItem.selected) {
      if (!this.selectedItems.includes(dataItem)) {
        this.selectedItems.push(dataItem);
      }
    } else {
      const index = this.selectedItems.indexOf(dataItem);
      if (index > -1) {
        this.selectedItems.splice(index, 1);
      }
    }
    this.showSave = dataItem.pfecrA_IsProcessed == 'No';
  }
  getClientsMaster() {
    this.GstApprovalService.getClientsGstMaster().subscribe({
      next: (response) => {
        const data = response.body;
        this.ClientGstDropdownData = [
          { text: 'Select Client', value: '' },
          ...data.map((item: any) => ({
            text: item.text,
            value: item.value
          }))
        ];
      },
      error: (error) => {
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }

  handleClosePopup() {
    this.isAddNewVisible = false;

    this.GetPendingGstDetailsBasedOnClientId(this.clientID);
  }
  public closeAlert(): void {
    this.alertData = { alertMessage: '', alertVisible: '' };
  }
  openAddNewPopup(values: any) {
    console.log(values, 'valuessssssss');
     this.uniqueId = values.uniqueId;


    this.isAddNewVisible = true;
    // this.gridData = [];


    this.password = '';
    this.IsUpdate = false;

  }
  GetPendingGstDetailsBasedOnClientId(clientID: any) {

    let payload = {
      ClientId: clientID,
    }
    this.GstApprovalService.GetPendingGstDetailsBasedOnClientIds(payload).subscribe({
      next: (response) => {

        const data = response.body;
        this.gridData = data;
        //   const statusMap = {
        //     "A": "Approved",
        //     "R": "Rejected",
        //     "P": "Pending"
        // };
        // this.approvalStatus = statusMap[this.gridData.status] || "Select";
        const statusMap = {
          "A": "Approved",
          "R": "Rejected",
          "P": "Pending"
        };

        const status = this.gridData[0]?.status as keyof typeof statusMap;
        console.log('ffd', status)
        this.approvalStatus = statusMap[status];
        console.log('con', this.approvalStatus)

      },
      error: (error) => {
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }


  OnSubmitgstApprovelDetails() {
    // Constructing the payload
    debugger
    console.log('check',this.selectedItems)
    let allSelected = this.selectedItems.every(item => item.selected === true );
    if (this.selectedItems.length !== 0 ) {
      const isSelect = this.selectedItems.every(item => item.status && item.status != 'S' && item.status != "");
      if (isSelect) {
        console.log(this.selectedItems, 'selecteditemssss')
        const gstDetails = this.selectedItems.map(item => {
          return {
            status: item.status,
            uniqueId: item.uniqueId
          }
        })
        const payload = {
          lstApproveGSTDetails: gstDetails,
          userId: String(this.user.getUserId()),
        };

        console.log('Payload to send:', payload);


        this.GstApprovalService.SubmitInvoiceGstUploadApprovals(payload).subscribe({
          next: (response: HttpResponse<any>) => {
            this.loading = false;
            this.gridData = response.body;
            if (response.body.errorMessage === 'Updated Successfully') {
              this.alertData = { alertMessage: 'Saved Successfully', alertVisible: 'success' };
              this.GetPendingGstDetailsBasedOnClientId(this.clientID);
            } else {
              this.alertData = { alertMessage: 'Submission Failed', alertVisible: 'error' };
            }
            this.selectedItems=[];
          },
          error: (error) => {
            // Handle error response
            console.error('Error during submission', error);
            this.loading = false;
            this.selectedItems=[];
            this.alertData = { alertMessage: 'Error submitting details', alertVisible: 'error' };
          }
        });
      } else {
        this.alertData = { alertMessage: 'Please Select Approve or Reject for all selected UniqueId ', alertVisible: 'info' };
      }

    } else {
      this.alertData = { alertMessage: 'Please Select Atleast One Row', alertVisible: 'info' };
    }

  }

}
