<div class="col" style="width: 100%; margin-top: 5px; margin-bottom: 10px;">
  <div class="col-md-12 colpadding0" style="height: 35px;" style="float: right;">
      <button class="figma-btn-blue" 
          (click)="openUploadPopup()">
          <img src="../assets/vectors/uploadWhite.svg" alt="" >Upload
      </button>
      <button class="figma-btn-blue " 
          (click)="AddNew()">
          <img src="../assets/vectors/Plus.svg" alt="">Add New
      </button>
  </div>
</div>
  
  <app-grid-action-item [gridData]="gridData" [columns]="parentColumns" [pageSize]="10" [pageable]="true" [filterable]="true" (buttonClick)="confirmAlert($event)" [actionColumnWidth]="'100px'">
  </app-grid-action-item>
  
  <app-alert-popup *ngIf="alertData.alertVisible" [Width]="270"  [id]="deleteId" [Data]="alertData" (close)="closePopup()" (confirm)="DeleteIndustrySkill($event)"></app-alert-popup>
  
  <div kendoDialogContainer *ngIf="uploadPopup">
    <app-industry-skill-bulk-upload (close)="closePopup()"></app-industry-skill-bulk-upload>
  
</div>
  <div kendoDialogContainer *ngIf="AddNewPopup">
    <app-industry-skill-add-new-popup (close)="closePopup()"></app-industry-skill-add-new-popup>
  
</div>
<div *ngIf="loading" class="loader-overlay">
  <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>