import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-dropdown-list',
  standalone: true,
  imports: [CommonModule, DropDownListModule],
  templateUrl: './dropdown-list.component.html',
  styleUrls: ['./dropdown-list.component.css']
})
export class DropdownListComponent {
  @Input() data: any[] = [];
  @Input() textField: string = '';
  @Input() valueField: string = '';
  @Input() width: string = '';
  @Input() height: string = '';
  @Input() hasError!: any // To handle error styling
  @Input() isRequired: boolean = false; // To handle required validation
  @Input() isDisabled: boolean = false; // To handle required validation
  @Input() className: string = '';
  @Input() selectedValue: any; // Set the predefined value
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  

  onValueChange(value: any) {
    this.valueChange.emit(value);
  }
}
