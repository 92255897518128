import { NgClass, NgIf } from '@angular/common';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { IndustrySkillDesignationMappingService } from '../../../service/industry-skill-designation-mapping.service';

@Component({
  selector: 'app-industry-skill-bulk-upload',
  standalone: true,
  imports: [DialogModule, FormsModule, NgIf, NgClass, AlertPopupComponent, ComboBoxComponent],
  templateUrl: './industry-skill-bulk-upload.component.html',
  styleUrl: './industry-skill-bulk-upload.component.css'
})
export class IndustrySkillBulkUploadComponent implements OnInit {
  file: File | null = null;
  dropzoneMessage: string = "Drag your files here or ";
  @Output() close = new EventEmitter<void>();
  public selectedFile?: File;
  public designationOption: string = 'add';
  public alertData = { alertMessage: '', alertVisible: '' };
  public stateDropdownData: any[] = [];
  public selectedDropdown = ''
  public isFormValid: boolean = false;
  public showValidation: boolean = false;

  constructor(private IndustrySkillService: IndustrySkillDesignationMappingService) { }

  @ViewChild('dialogTitle') dialogTitle!: ElementRef;
  ngAfterViewInit() {
    const titleElement = this.dialogTitle.nativeElement;
    titleElement.style.textAlign = 'center';
    titleElement.style.width = '100%';
  }

  ngOnInit(): void {
    this.StateDropdown()
  }
  StateValueChange(value: any) {
    this.selectedDropdown = value.id;
  }
  StateDropdown() {
    this.IndustrySkillService.getStateDropdown().subscribe(
         {
      next: (response) => {
        console.log(response);
          const data = response.body;
                this.stateDropdownData = data.map((item: any) => ({
                  id: item.sM_Code,
                  name: item.sM_Name
                }));
      },error(error) {
        console.error("Error fetching dropdown data", error);
      },
    }
  );
  }

  // public onFileSelect(event: Event): void {
  //   const input = event.target as HTMLInputElement;
  //   if (input.files?.length) {
  //     const file = input.files[0];
  //     if (this.validateFile(file)) {
  //       this.selectedFile = file;
  //     } else {
  //       this.alertData = { alertMessage: 'Invalid file type. Only Excel files are allowed.', alertVisible: 'error' };
  //     }
  //   }
  // }
  public onFileSelect(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!this.selectedDropdown) {
        this.alertData = { alertMessage: 'Please select a state', alertVisible: 'error' };
        input.value = ''; 
        return;
    }
    if (input.files?.length) {
        const file = input.files[0];
        if (this.validateFile(file)) {
            this.selectedFile = file;
        } else {
            this.alertData = { alertMessage: 'Only .xlsx files are allowed.', alertVisible: 'error' };
            input.value = ''; 
        }
    }
}
  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drop the file here or";
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drag your files here or";
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      if (this.validateFile(file)) {
        this.file = file;
        this.selectedFile = this.file;
      } else {
        this.alertData = { alertMessage: 'Only .xlsx files are allowed.', alertVisible: 'error' };
      }
      event.dataTransfer.clearData();
    }
  }

  private validateFile(file: File): boolean {
    if (this.selectedDropdown ) {
      this.isFormValid = true;
    }
    const allowedExtensions = ['xls', 'xlsx', 'csv'];
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    return fileExtension ? allowedExtensions.includes(fileExtension) : false;
  }

  // public uploadFiles(): void {
  //   this.showValidation=true;
  //   if (this.selectedFile && this.isFormValid) {
  //     this.IndustrySkillService.uploadFile(this.selectedFile, this.selectedDropdown).subscribe({
  //       next: (response) => {
  //         this.alertData = { alertMessage: 'File Uploaded Successfully...', alertVisible: 'success' };
  //         console.log('File uploaded successfully', response);
          
  //       },
  //       error: (error) => {
  //         this.selectedFile=undefined;
  //         this.alertData = { alertMessage: 'Invalid template file!', alertVisible: 'error' };
  //       }
  //     });
  //   } else {
  //     if(!this.selectedFile)
  //     {
  //       this.alertData = { alertMessage: 'No File Selected ', alertVisible: 'error' };
  //     }else{
  //       this.alertData = { alertMessage: 'Please select state', alertVisible: 'error' };
  //     }

  //   }
  // }
  public uploadFiles(): void {
    this.showValidation = true;
    if (!this.selectedDropdown) {
        this.alertData = { alertMessage: 'Please select state', alertVisible: 'error' };
        return;
    }
    if (this.selectedFile && this.isFormValid) {
        this.IndustrySkillService.uploadFile(this.selectedFile, this.selectedDropdown).subscribe({
            next: (response) => {
                this.alertData = { alertMessage: 'File Uploaded Successfully...', alertVisible: 'success' };
                this.selectedFile = undefined; 
                this.selectedDropdown = 'Search State/Select State'; 
            },
            error: (error) => {
                this.selectedFile = undefined;
                this.alertData = { alertMessage: 'Invalid template file!', alertVisible: 'error' };
            }
        });
    } else {
        if (!this.selectedFile) {
            this.alertData = { alertMessage: 'No File Selected', alertVisible: 'error' };
        } else {
            this.alertData = { alertMessage: 'Please select state', alertVisible: 'error' };
        }
    }
}
  closeAlert() {
    this.alertData = { alertMessage: '', alertVisible: '' };
  }
  closePopup() {
    this.close.emit()
  }
}
