<kendo-dialog #dialogRef title="Add New" (close)="closePopup()" [minWidth]="300" [width]="340" class="custom-dialog">
    <form [formGroup]="userForm">
        <div class="form-group">
            <label for="State" class="f-label">State<sub class="text-danger">*</sub></label>
            <app-dropdown-list [data]="StatedropdownData" [selectedValue]="SelectedState"
                (valueChange)="onDropdownValueChange($event)" [hasError]="!SelectedState.value && showErrorValidation"
                textField="text" valueField="value" [width]="'300px'">
            </app-dropdown-list>
        </div>
        <div class="form-group">
            <label for="City" class="f-label">City<sub class="text-danger">*</sub></label>
            <input kendoTextBox id="City" formControlName="City" placeholder="Enter City"
                [ngClass]="{'is-invalid': userForm.get('City')?.invalid && (userForm.get('City')?.touched)}"
                class="f-textbox" style="width: 300px;" />
            <small
                *ngIf="userForm.get('City')?.invalid &&  userForm.get('City')?.touched && userForm.get('City')?.errors?.['pattern']"
                class="text-danger" style="font-weight: bold;">
                City must contain only letters
            </small>
        </div>
        <div class="form-group">
            <label for="Location" class="f-label">Location Code<sub class="text-danger">*</sub></label>
            <input kendoTextBox id="Location" formControlName="Location"
                [ngClass]="{'is-invalid': userForm.get('Location')?.invalid && (userForm.get('Location')?.touched)}"
                placeholder="Enter Location" class="f-textbox" style="width: 300px;" />
            <small
                *ngIf="userForm.get('Location')?.invalid &&  userForm.get('Location')?.touched && userForm.get('Location')?.errors?.['pattern']"
                class="text-danger" style="font-weight: bold;">
                Location code must be exactly 6 digits.
            </small>
        </div>
    </form>

    <kendo-dialog-actions class="ksave">
        <button kendoButton type="button" (click)="onSave()" class="figma-btn-blue figma-popup">
            Save
        </button>
    </kendo-dialog-actions>
</kendo-dialog>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270" class="alert" [Data]="alertData"
    (close)="closeAlert()">
</app-alert-popup>
