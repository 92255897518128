import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogModule } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-alert-popup',
  standalone: true,
  imports: [NgIf, DialogModule],
  templateUrl: './alert-popup.component.html',
  styleUrl: './alert-popup.component.css'
})
export class AlertPopupComponent {
  @Input() Data = { alertMessage: '', alertVisible: '' }
  @Input() Message: string = '';
  @Input() isSuccess: string = '';
  @Output() close = new EventEmitter<void>();
  @Input() Width = 280;
  @Input() Height = 250;
  @Input() id = 0;
  successMessage: string = '';
  errorMessage: string = '';
  @Output() confirm = new EventEmitter<number>();
  
  onConfirm() {
    this.confirm.emit(this.id);
  }


  closePopup() {
    this.isSuccess = '';
    this.close.emit();
  }
}
