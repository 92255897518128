import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {  DialogModule } from '@progress/kendo-angular-dialog';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { NgIf } from '@angular/common';
import { PremiseMasterService, ShiftMasterList } from '../../../service/premise-master.service';
import { UserService } from '../../../service/user.service';

@Component({
  selector: 'app-add-shift-master-details',
  standalone: true,
  imports: [DialogModule,GridModule,DropDownListModule,FormsModule,AlertPopupComponent,NgIf],
  templateUrl: './add-shift-master-details.component.html',
  styleUrl: './add-shift-master-details.component.css'
})
export class AddShiftMasterDetailsComponent {
  @Input() rowData: any;
  @Input() branchId: any;
  data: any[] = [];
  @Output() close = new EventEmitter<void>();
  timeIntervals: string[] = [];
  alertData = { alertMessage: '', alertVisible: '' }
  @ViewChild('dialogTitle') dialogTitle!: ElementRef;

  public nightShiftOptions: { text: string, value: string }[] = [
    { text: 'YES', value: 'Y' },
    { text: 'NO', value: 'N' }
  ];
  public selectedNightShift: { text: string, value: string } = { text: 'Select', value: '' };

  constructor(private premiseMasterService:PremiseMasterService,private user:UserService){}

  ngOnInit() {    
    this.generateTimeIntervals();
    if (this.rowData) {
      console.log('Row data received:', this.rowData);
     
    }
    this.loadShifts();
    this.getShiftMaster()
    console.log(this.data);
    
  }

  ngAfterViewInit() {
    const titleElement = this.dialogTitle.nativeElement;
    titleElement.style.textAlign = 'center';
    titleElement.style.width = '100%';
  }


  generateTimeIntervals() {
    const startTime = new Date();
    startTime.setHours(0, 0, 0, 0); 
    for (let i = 0; i < 24 * 4; i++) { 
      const hours = startTime.getHours();
      const minutes = startTime.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const formattedTime = 
        `${('0' + (hours % 12 || 12)).slice(-2)}:${('0' + minutes).slice(-2)} ${ampm}`;
      this.timeIntervals.push(formattedTime);
      startTime.setMinutes(startTime.getMinutes() + 15); 
    }
  }

  getShiftMaster() {
    const ClientId = this.rowData?.cM_ClientID ?? '';
    const StateId = this.rowData?.cM_State ?? '';
    const LocationID = this.rowData?.cM_City ?? '';
    const Branch = this.rowData?.avacoM_BranchID.toString() ?? '';
    console.log(ClientId, StateId, LocationID, Branch);
    
    this.premiseMasterService.getShiftData(ClientId, StateId, LocationID, Branch).subscribe({
        next: (response) => {
            console.log('shiftdata response', response);
            this.data = response.body.map((item:any) => ({
                shiftName: item.shiftName,
                shiftCode: item.shiftCode,
                nightShift: { text: item.nightShift === 'Y' ? 'YES' : 'NO', value: item.nightShift },
                shiftFrom: item.shiftFrom,
                shiftTo: item.shiftTo,
                shiftIntervalFrom: item.shiftIntervalFrom,
                shiftIntervalTo: item.shiftIntervalTo
            }));
        },
        error: (err) => {
            console.error('Error fetching shift data', err);
        }
    });
  }
  addNewShiftMaster() {
    if (this.data.length < 8) {
     
      if (this.data.length > 0) {
        const lastShift = this.data[this.data.length - 1];
        if (
          lastShift.shiftName === 'Select' ||
          lastShift.shiftCode === 'Select' ||
          lastShift.nightShift.value === '' ||
          lastShift.shiftFrom === 'Select' ||
          lastShift.shiftTo === 'Select' ||
          lastShift.intervalFrom === 'Select' ||
          lastShift.shiftIntervalTo === 'Select'
        ) {
          this.alertData = { alertMessage: 'Please fill out all required fields first', alertVisible: 'error' };
          return;
        }
      }
  
     
      const newShiftIndex = this.data.length + 1;
      const newShift = {
        shiftName: `Shift${newShiftIndex}`,
        shiftCode: `${newShiftIndex}`,
        nightShift: { text: 'Select', value: '' },
        shiftFrom: 'Select',
        shiftTo: 'Select',
        intervalFrom: 'Select',
        shiftIntervalTo: 'Select'
      };
      this.data.push(newShift);
      this.alertData = { alertMessage: '', alertVisible: '' };
    } else {
      this.alertData = { alertMessage: 'You cannot add more than 8 shifts.', alertVisible: 'error' };
    }
  }
  
  
  loadShifts() {
    this.premiseMasterService.getShifts().subscribe(shifts => {
      this.data = shifts;
    });
  }
  


  deleteShift(dataItem: any) {
    if (this.data.length > 0 && this.data[this.data.length - 1] === dataItem) {
      
      const index = this.data.indexOf(dataItem);
      if (index !== -1) {
        this.data.splice(index, 1);
        this.alertData = { alertMessage: '', alertVisible: '' }
      }
    } else {
      this.alertData = { alertMessage: 'In between shift slab cannot be deleted', alertVisible: 'error' };      
      
    }
  }

  
  saveShift() {
    for (const shift of this.data) {
      if (
        shift.shiftName === 'Select' ||
        shift.shiftCode === 'Select' ||
        shift.nightShift.value === '' ||
        shift.shiftFrom === 'Select' ||
        shift.shiftTo === 'Select' ||
        shift.shiftIntervalFrom === 'Select' ||
        shift.shiftIntervalTo === 'Select'
      ) {
        this.alertData = {
          alertMessage: 'Please fill out all required fields before saving.',
          alertVisible: 'error'
        };
        return; 
      }
    }
  
    const shiftMasterList: ShiftMasterList[] = this.data.map(shift => ({
      Id: 0,
      ClientId: this.rowData?.cM_ClientID ?? '',
      StateId: this.rowData?.cM_State ?? '',
      LocationID: this.rowData?.cM_City ?? '',
      Branch: this.rowData?.avacoM_BranchID.toString() ?? '',
      BranchName: this.rowData?.avacoM_BranchName ?? '',
      ShiftName: shift.shiftName,
      ShiftCode: shift.shiftCode,
      NightShift: shift.nightShift.value,
      ShiftFrom: shift.shiftFrom,
      ShiftTo: shift.shiftTo,
      ShiftIntervalFrom: shift.shiftIntervalFrom,
      ShiftIntervalTo: shift.shiftIntervalTo,
      CreatedBy: this.user.getUserId().toString()
    }));
  
    console.log('Data to be sent to save API:', shiftMasterList);
  
    this.premiseMasterService.addShiftMaster(shiftMasterList).subscribe({
      next: (response) => {
        console.log('Save response', response);
        this.alertData = {
          alertMessage: 'Shift Saved Successfully',
          alertVisible: 'success'
        };
      },
      error: (error) => {
        console.log('Error', error);
        this.alertData = {
          alertMessage: error.error?.Result?.status ?? 'Error saving shift',
          alertVisible: 'error'
        };
      }
    });
  }
  

  public closePopup(): void {
    this.close.emit();
  }
  public closeDialog(): void {
    this.close.emit();
  
  }
  public closeAlertPopup(): void {
   this.alertData={ alertMessage: '',alertVisible: ''}
  }


}
