import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { KendoDatepickerComponent } from '../../../components/kendo-datepicker/kendo-datepicker.component';
import { ClientRegistrationRepositoryService } from '../../../service/client-registration-repository.service';
import { NgIf, NgClass } from '@angular/common';
import { DatePickerComponent } from '@progress/kendo-angular-dateinputs';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { UserService } from '../../../service/user.service';
import { forkJoin } from 'rxjs';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { BlobService } from '../../../service/blob.service';
import { environment } from '../../../environments/environment';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-registration-repository-add-edit-popup',
  standalone: true,
  imports: [DropdownListComponent, ReactiveFormsModule, FormsModule, DialogsModule, ComboBoxComponent, AlertPopupComponent, NgClass, NgIf, KendoDatepickerComponent, IndicatorsModule],
  templateUrl: './registration-repository-add-edit-popup.component.html',
  styleUrl: './registration-repository-add-edit-popup.component.css'
})
export class RegistrationRepositoryAddEditPopupComponent implements OnInit {
  @Input() data: any;
  @ViewChild('expiryDatePicker') expiryDatePicker!: DatePickerComponent;
  @Output() close = new EventEmitter<void>();
  alertData = { alertMessage: '', alertVisible: '' };
  defaultSelection = 'Search/Select Activity';
  public showValidationError = false;
  public showActivityValidation = false;
  public showValidationFile: boolean = false;

  isEditMode: boolean = false;
  loading = false;
  form!: FormGroup;
  RC: string = '';
  DateDisable = false;
  rcNumber: string = '';
  rR_ExpiryDate!: Date;
  selectionType: string = '';
  searchTerm: string = '';
  public selectedDropdown = '';
  public expiryDate!: Date | null;
  placeholder = 'DD-MM-YYYY';
  public selectedActivityDropdown = '';
  isDatePickerDisabled: boolean = false;
  ActivityDropdown = { name: 'Select Activity', id: '' };
  StateDropdown = { name: 'Select State', id: '' };
  LocationDropdown = { name: 'Select Location', id: '' };
  BranchDropdown = { name: 'Select Branch', id: '' };
  RegTypeDropdown = { name: 'Select Type', id: '' };
  public allBranchData: any[] = [];
  public allStatesData: any[] = [];
  public allRegTypeData: any[] = [];
  public allActivityData: any[] = [];
  public allLocationData: any[] = [];
  public stateDropdownData: any[] = [];

  constructor(private fb: FormBuilder, private user: UserService, private clientregistrationrepository: ClientRegistrationRepositoryService, private blobService: BlobService) {
    this.form = this.fb.group({
      rcNumber: ['', [Validators.required, Validators.pattern(/^[\s\S]*$/)]]
    });
  }
  ngOnInit(): void {
    debugger;
    const activityObs = this.clientregistrationrepository.getAllActivities();
    const stateObs = this.clientregistrationrepository.getAllStates(this.user.getClientId());
    const locationObs = this.clientregistrationrepository.getAllLocations(this.user.getClientId());
    const branchObs = this.clientregistrationrepository.getAllBranches(this.user.getClientId());
    const regTypeObs = this.clientregistrationrepository.getAllTypes();

    forkJoin([activityObs, stateObs, locationObs, branchObs, regTypeObs]).subscribe(
      ([activities, states, locations, branches, regTypes]) => {
        debugger;
        this.handleActivityResponse(activities);
        this.handleStateResponse(states);
        this.handleLocationResponse(locations);
        this.handleBranchResponse(branches);
        this.handleRegTypeResponse(regTypes);
        if (this.data) {
          debugger;
          this.predefinedData(this.data);
        } else {
          this.predefinedData('');
        }
      },
      (error) => {
        console.error("Error in fetching dropdown data", error);
      }
    );
  }
  fileNames: { [key: string]: string | null } = {
    Upload: null
  };
  selectedFiles: { [key: string]: File | null } = {
    Upload: null
  };

  handleActivityResponse(response: any) {
    const data = response.body;
    this.allActivityData = [{ name: 'Select Activity', id: '' },
    ...data.map((item: any) => ({
      name: item.activityName,
      id: item.activityId
    }))];
  }
  restrictInput(event: KeyboardEvent): void {
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete'];
    const isNumberKey = event.key >= '0' && event.key <= '9';

    if (!isNumberKey && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  handleStateResponse(response: any) {
    const data = response.body;
    this.allStatesData = [{ name: 'Select State', id: '' },
    ...data.map((item: any) => ({
      name: item.stateName,
      id: item.stateId
    }))];
  }

  handleLocationResponse(response: any) {
    const data = response.body;
    this.allLocationData = [{ name: 'Select Location', id: '' },
    ...data.map((item: any) => ({
      name: item.locationName,
      id: item.locationId
    }))];
  }

  handleBranchResponse(response: any) {
    const data = response.body;
    this.allBranchData = [{ name: 'Select Branch', id: '' },
    ...data.map((item: any) => ({
      name: item.branchName,
      id: item.avacomBranchId
    }))];
  }

  handleRegTypeResponse(response: any) {
    const data = response.body;
    this.allRegTypeData = [{ name: 'Select Type', id: '' },
    ...data.map((item: any) => ({
      name: item.typeName,
      id: item.typeCode
    }))];
  }

  saveData() {
    debugger;
    this.showValidationError = true;
    this.showValidationFile = true;
    this.showActivityValidation = true;
    if (this.ActivityDropdown.name && this.StateDropdown.name && this.LocationDropdown.name && this.BranchDropdown.name && this.RegTypeDropdown.name && this.selectedFiles['Upload']) {
      // this.alertData = { alertMessage: 'Error while saving details', alertVisible: 'error' };
      if (this.selectionType == 'Y') {
        this.expiryDate = null;
      }

      const data = {
        StateId: this.StateDropdown.id,
        Activity: this.ActivityDropdown.id,
        EntityId: this.user.getClientId(),
        userID: this.user.getUserId(),
        LocationId: this.LocationDropdown.id,
        Branch: this.BranchDropdown.id,
        RegType: this.RegTypeDropdown.id,
        RCNumber: this.form.value.rcNumber,
        ExpiryDate: this.expiryDate?.toISOString() || '',
        file: this.selectedFiles['Upload'],
      };
      console.log('save data', data);

      this.clientregistrationrepository.saveUpdateData(data).subscribe({
        next: (response) => {
          // this.loading = true;
          console.log(response);

          this.alertData = { alertMessage: 'Details Saved Successfully', alertVisible: 'success' };
        },
        error: (error) => {
          console.log('error', error);
          this.alertData = { alertMessage: 'Error while saving details', alertVisible: 'error' };
        }
      });
    } else {
      this.form.markAllAsTouched();
    }
  }

  valueChange(value: any, type: string) {
    if (type === 'activity') {
      if (value) {
        this.ActivityDropdown = {
          name: value.name ? value.name : '',
          id: value.id ? value.id : ''
        };
      } else {
        this.ActivityDropdown = {
          name: '',
          id: ''
        };
      }
      console.log('Selected Activity:', this.ActivityDropdown);
    } else if (type === 'state') {
      if (value) {
        this.StateDropdown = {
          name: value.name ? value.name : '',
          id: value.id ? value.id : ''
        };
      } else {
        this.StateDropdown = {
          name: '',
          id: ''
        };
      }
      console.log('Selected State:', this.StateDropdown);
    } else if (type === 'location') {
      if (value) {
        this.LocationDropdown = {
          name: value.name ? value.name : '',
          id: value.id ? value.id : ''
        };
      } else {
        this.LocationDropdown = {
          name: '',
          id: ''
        };
      }
      console.log('Selected location:', this.LocationDropdown);
    } else if (type === 'branch') {
      if (value) {
        this.BranchDropdown = {
          name: value.name ? value.name : '',
          id: value.id ? value.id : ''
        };
      } else {
        this.BranchDropdown = {
          name: '',
          id: ''
        };
      }
      console.log('Selected branch:', this.BranchDropdown);
    } else if (type === 'type') {
      if (value) {
        this.RegTypeDropdown = {
          name: value.name ? value.name : '',
          id: value.id ? value.id : ''
        };
      } else {
        this.RegTypeDropdown = {
          name: '',
          id: ''
        };
      }
      console.log('Selected type:', this.RegTypeDropdown);
    } else {
      console.error('Invalid type provided:', type);
    }
  }

  downloadFile() {
    this.loading = true;
    const filePath = this.data.documentPath
    if (filePath) {
      const apiUrl = environment.blobUrl;
      const blobLink = apiUrl + filePath;
      const blobName = this.blobService.extractBlobName(blobLink);
      setTimeout(() => {
        this.blobService.getSasToken(blobName).subscribe(
          (tokenResponse: any) => {
            try {
              const sasToken = tokenResponse.result;
              this.blobService.downloadFile(blobName, sasToken);
              this.loading = false;
            } catch (error) {
              this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
              this.loading = false;
            }
          },
          (error) => {
            this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
            this.loading = false;
          }
        );
      }, 5000);
    } else {
      this.alertData = { alertMessage: 'File Not Found', alertVisible: 'error' };
      this.loading = false;
    }
  }
  predefinedData(value: any) {
    debugger
    console.log('predefine data', value);
    if (value && value.documentPath) {
      this.isEditMode = true;
    } else {
      this.isEditMode = false;
    }
    this.form = this.fb.group({
      rcNumber: [
        { value: this.data.rcNumber || '', disabled: this.isEditMode },
        [Validators.required, Validators.pattern(/^[\s\S]*$/)]
      ],
    });

    this.ActivityDropdown = this.allActivityData.find(type => type.id === value.rR_DocID) || { id: '', name: '' }
    this.StateDropdown = this.allStatesData.find(type => type.id === value.rR_State) || { id: '', name: '' }
    this.LocationDropdown = this.allLocationData.find(type => type.id === value.locationID) || { id: '', name: '' }
    this.BranchDropdown = this.allBranchData.find(type => type.id === value.avacoM_BranchID) || { id: '', name: '' }
    this.RegTypeDropdown = this.allRegTypeData.find(type => type.id === value.regTypeId) || { id: '', name: '' }
    if (this.data.rR_ExpiryDate === 'Life Time Validity') {
      this.selectionType === 'Y';
      this.DateDisable = true;
    }
    else {
      this.expiryDate = this.data.rR_ExpiryDate ? new Date(this.data.rR_ExpiryDate) : null;
    }
    console.log('regtype', this.allRegTypeData);
    console.log('location', this.allLocationData);

  }

  onRadioChange(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    this.selectionType = checkbox.checked ? 'Y' : 'N';
    if (this.selectionType == 'Y') { this.DateDisable = true } else { this.DateDisable = false };
    this.isDatePickerDisabled = this.selectionType === 'Y';
  }
  onFileChange(event: any, key: string): void {
    const file = event.target.files[0];
    if (file) {
      this.fileNames[key] = file.name;
      this.selectedFiles[key] = file;
      this.showValidationFile = false;
    } else {
      this.fileNames[key] = null;
      this.selectedFiles[key] = null;
      this.showValidationFile = true;
    }
  }
  onDateChange(value: Date, type: string) {
    const date = new Date(value);
    if (type === 'expiryDate') {
      this.expiryDate = date;
      this.form.get('expiryDate')?.setValue(date);
      this.form.get('expiryDate')?.updateValueAndValidity();
    }
  }
  closePopup(): void {
    this.close.emit();
  }
  closeAlert() {
    if(this.alertData.alertVisible=='success'){
      this.close.emit();
    }
    this.alertData = { alertMessage: '', alertVisible: '' };
  }

}
