import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComboBoxModule } from '@progress/kendo-angular-dropdowns';
import { EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-combo-box-free-text',
  standalone: true,
  imports: [CommonModule, ComboBoxModule],
  templateUrl: './combo-box-free-text.component.html',
  styleUrl: './combo-box-free-text.component.css'
})
export class ComboBoxFreeTextComponent {
  @Input() allowCustom: boolean = false;
  @Input() width: string = '100%';
  @Input() disabled: boolean = false;
  @Input() height: string = '35px';
  @Input() data: { id: any; name: string }[] = [];
  @Input() Placeholder: string = '';
  @Input() hasError!: any;
  @Input() textField: string = 'name';
  @Input() valueField: string = 'id';
  @Input() selectedValue: any;  // Added input for selected value
  // public filter: string = '';
  @Input() isDisabled: boolean = false;
  public filteredData: { id: any; name: string }[] = [];
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterChange: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit() {
    this.filteredData = this.data;
    console.log("Filtered Data from Resuable Component", this.filteredData);
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['selectedValue']) {
      if (this.selectedValue === null) {
        this.filteredData = this.data;
      }
    }
  }

  public handleFilter(value: string): void {
    if (!value) {
      this.filteredData = this.data;
    } else {
      const lowerValue = value.trim().toLowerCase();

      this.filteredData = this.data.filter(item =>
        item.name.toLowerCase().includes(lowerValue) ||
        item.id.toString().toLowerCase().includes(lowerValue)
      );
    }

    this.filterChange.emit(value);
  }


  public resetFilter() {
    debugger;
    this.filteredData = this.data;
  }

  onValueChange(value: any) {
    if (this.data.some(item => (item as any)[this.valueField] === value || (item as any)[this.textField] === value)) {
      // Value exists in data, proceed as normal
      this.valueChange.emit(value);
    } else {
      // Handle free text input (new value)
      this.selectedValue = value;
      this.valueChange.emit(value);
    }
  }

  clearSelection() {
    this.selectedValue = null;  // Clears the selection
    this.filteredData = this.data;  // Resets the filtered data
  }

}
