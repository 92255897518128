import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CriticalDocumnetsService {
  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  GetFolderList(UserId:number,Customerid:number,EntityId:string,SearchFilter:string,ParentId:any): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/CriticalDocuments/GetFolderList?UserId=${UserId}&Customerid=${Customerid}&EntityId=${EntityId}&SearchFilter=${SearchFilter}&ParentId=${ParentId}`;
    return this.http.get(url, {observe:'response'});
  }
  GetFileInfo(Fileid:number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/CriticalDocuments/GetFileInfo?Fileid=${Fileid}`;
    return this.http.get(url, {observe:'response'});
  }
  DeleteFolder(FolderId:number,UserId:number,Customerid:number,Entityid:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/CriticalDocuments/DeleteFolder?FolderId=${FolderId}&UserId=${UserId}&Customerid=${Customerid}&Entityid=${Entityid}`;
    return this.http.get(url, {observe:'response'});
  }
  DeleteFile(Fileid:number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/CriticalDocuments/DeleteFile?Fileid=${Fileid}`;
    return this.http.get(url, {observe:'response'});
  }
  GetUserShareList(EntityId:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/CriticalDocuments/GetUserShareList?EntityId=${EntityId}`;
    return this.http.get(url, {observe:'response'});
  }
  CreateFolder(UserId:number,Customerid:number,EntityId:string,Foldername:string,ParentId:any): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/CriticalDocuments/CreateFolder?UserId=${UserId}&Customerid=${Customerid}&EntityId=${EntityId}&Foldername=${Foldername}&ParentId=${ParentId}`;
    return this.http.get(url, {observe:'response'});
  }
  uploadFile(formData: FormData): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/CriticalDocuments/UploadDocument`;
    return this.http.post<any>(url, formData,{ observe: 'response' });
  }
  ShareFile(formData: any): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/CriticalDocuments/ShareFile`;
    return this.http.post<any>(url, formData,{ observe: 'response' });
  }
  ShareFolder(formData: any): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/CriticalDocuments/ShareFolder`;
    return this.http.post<any>(url, formData,{ observe: 'response' });
  }
  SaveFileInfo(formData: any): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/CriticalDocuments/SaveFileInfo`;
    return this.http.post<any>(url, formData,{ observe: 'response' });
  }
  GetSharedUserList(Folderid:any,Fileid:any): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/CriticalDocuments/GetSharedUserList?Folderid=${Folderid}&Fileid=${Fileid}`;
    return this.http.get(url, {observe:'response'});
  }
  UnshareFileOrFolder(Userid:any,Folderid:any,Fileid:any): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/CriticalDocuments/UnshareFileOrFolder?Userid=${Userid}&Folderid=${Folderid}&Fileid=${Fileid}`;
    return this.http.get(url, {observe:'response'});
  }
  fetchFile(Fileid: number): Observable<Blob> {
    const url = `${this.apiUrl}/api/CriticalDocuments/Preview_CriticalDocument?Fileid=${Fileid}`;
    return this.http.post(url, null, { responseType: 'blob' });
  }
}
