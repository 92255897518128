import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';
import { LocationMasterServiceService } from '../../../service/location-master-service.service';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';

@Component({
  selector: 'app-location-master-add-new-popup',
  standalone: true,
  imports: [DropdownListComponent, DialogModule, ReactiveFormsModule, NgIf, NgClass, AlertPopupComponent],
  templateUrl: './location-master-add-new-popup.component.html',
  styleUrl: './location-master-add-new-popup.component.css'
})
export class LocationMasterAddNewPopupComponent implements OnInit {
  userForm!: FormGroup;
  showErrorValidation = false;
  alertData = { alertMessage: '', alertVisible: '' }
  @Output() close = new EventEmitter<void>();
  SelectedState = { text: 'Select State', value: '', id: 0 };
  StatedropdownData: any[] = [];
  ngOnInit(): void {
    this.getStateDropdownData();
  }
  constructor(private fb: FormBuilder, private locationMasterService: LocationMasterServiceService) {
    this.userForm = this.fb.group({
      City: ['', [Validators.required, Validators.pattern('^[A-Za-z\\s]+$')]],
      Location: ['', [Validators.required, Validators.pattern('^[0-9]{6}$')]]
    });

  }
  onSave() {
    this.showErrorValidation = true;
    if (this.userForm.valid && this.SelectedState.value) {
      const body = {
        stateId: this.SelectedState.id,
        cityName: this.userForm.value.City,
        locationCode: this.userForm.value.Location,
        isEdit: "New",
        cityID: 0
      }
      this.locationMasterService.saveLocationMaster(body).subscribe({
        next: (response) => {
          if (response.status == 200) {
            const data = response.body.result;
            if (data == "success") {
              this.alertData = { alertMessage: 'Saved Successfully', alertVisible: 'success' };
            }
            else if (data == "Updated") {
              this.alertData = { alertMessage: 'Details Updated Successfully', alertVisible: 'success' }
            }
            else if (data == "Exists") {
              this.alertData = { alertMessage: 'Location already added for other state', alertVisible: 'error' }
            }
            else {
              this.alertData = { alertMessage: 'Location already added for this state', alertVisible: 'error' }
            }
          } else {
            this.alertData = { alertMessage: 'Saved Successfully', alertVisible: '' }
          }
        },error:(error)=>{
          this.alertData = { alertMessage: 'Something went wrong', alertVisible: 'error'}
        }
      })
    } else {
      this.userForm.markAllAsTouched();
    }
  }
  onDropdownValueChange(value:any){
    this.SelectedState = {text:value.text,value:value.value,id:value.id};
  }
  getStateDropdownData() {
    this.locationMasterService.getStateMaster().subscribe({
      next: (response) => {
        const data = response.body;
        this.StatedropdownData = [
          { text: 'Select State', value: '', id: 0 },
          ...data.map((item: any) => ({
            text: item.sM_Name,
            value: item.sM_Code,
            id: item.avacoM_StateID
          }))
        ];
      },
      error: (error) => {
        console.error('Error fetching Dropdown data', error);
      }
    });
  }
  closeAlert() {
    this.alertData = { alertMessage: '', alertVisible: '' }
  }
  closePopup(): void {
    this.close.emit();
  }
}
