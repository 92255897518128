<div class="btn-container">
<button class="figma-btn-blue" (click)="navigateToInput()" >Back</button>
<button class="figma-btn-blue" (click)="showTable('output')" >Output (Challans)</button>
<button class="figma-btn-blue" (click)="showTable('final')" >Final Challan Confirmation</button>
</div>
<div *ngIf="activeTable === 'output'" id="outputTable">
    <kendo-grid [data]="challanWorkingList" [pageable]="false" class="grid custom-grid">
        <kendo-grid-column field="challanType" title="Compliance" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="col-12 collpadding0 inputName cell-content" [title]="dataItem.challanType">
                <p>{{ dataItem.challanType }}</p>
                </div>
            </ng-template>
        </kendo-grid-column>
    
        <kendo-grid-column title="Challan Upload" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="col-12 collpadding0 center-align cell-content">
                <button class="figma-btn-blue download-btn"
                        (click)="downloadChallan(dataItem.uploadFilePath, 'DC')">
                    Download
                </button>
            </div>
            </ng-template>
        </kendo-grid-column>
    
        <kendo-grid-column title="Status" [width]="200">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="col-12 collpadding0 center-align cell-content">
                <app-dropdown-list [data]="status"
                                   [selectedValue]="selectedStatus"
                                   textField="text"
                                   valueField="value"
                                   (valueChange)="onStatusChange($event)"
                                   [width]="'150px'"
                                   [isDisabled]="(dataItem.status === 'S' && dataItem.uploadFilePath === '') || dataItem.status === 'F'"
                                   [ngClass]="{'cursor-not-allowed': (dataItem.status === 'S' && dataItem.uploadFilePath === '') || dataItem.status === 'F'}">
                </app-dropdown-list>
                </div>
            </ng-template>
        </kendo-grid-column>
    
        <kendo-grid-column title="Upload" [width]="300">
            <ng-template kendoGridCellTemplate let-dataItem let-i="rowIndex">
                <div class="col-12 collpadding0 center-align cell-content">
                <span style="color: #A0A0A0;">
                    {{ fileNames[i] ? (fileNames[i].length > 10 ? fileNames[i].substring(0, 12) + '..' : fileNames[i]) : 'No file chosen' }}
                </span>
                <input type="file" formControlName="Template" #TemplateFile 
                       (change)="onFileChange($event, i)" [id]="'fileInput' + i" style="display: none;" />
                <button class="figma-btn-gray download-btn"
                        (click)="TemplateFile.click()"
                        [disabled]="!(dataItem.status === 'R')">
                    Browse
                </button>
                <button class="figma-btn-blue download-btn"
                        style="color: white; margin-left: 8px;"
                        (click)="uploadFiles(i)"
                        [disabled]="!(dataItem.status === 'R')">
                    Upload
                </button>
                </div>
            </ng-template>
        </kendo-grid-column>
    
        <kendo-grid-column title="Re-Upload by TL" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="col-12 collpadding0 center-align cell-content">
                <button class="figma-btn-blue download-btn"
                        [disabled]="!(dataItem.reUploadButtonStatus === 1 && dataItem.reUploadFilePath !== '')"
                        (click)="downloadChallan(dataItem.reUploadFilePath, 'DC')">
                    Download
                </button>
                </div>
            </ng-template>
        </kendo-grid-column>
    
        <kendo-grid-column title="Upload History" [width]="100">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="col-12 collpadding0 center-align cell-content">
                <img src="../assets/vectors/History.svg"
                     class="svg-icon-btn"
                     alt=""
                     style="cursor:pointer;"
                     (click)="openHistoryPopup()">
                     </div>
            </ng-template>
           
        </kendo-grid-column>
    </kendo-grid>
    
</div>
<div *ngIf="activeTable === 'final'" id="finalChallanTable" >
    <kendo-grid [data]="finalChallanConfirmation" [pageable]="false" class="grid custom-grid">
       
        <kendo-grid-column field="challanType" title="Compliance" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="col-12 collpadding0 inputName cell-content" [title]="dataItem.challanType">
                <p>{{ dataItem.challanType }}</p>
                </div>
            </ng-template>
        </kendo-grid-column>
    
        <kendo-grid-column title="ECR Challan" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="col-12 collpadding0 center-align cell-content">
                <button class="figma-btn-blue download-btn"
                        [disabled]="!(dataItem.ecrFileExists === 1 && dataItem.ecrFilePath !== '')"
                        (click)="downloadChallan(dataItem.ecrFilePath, 'EC')">
                    Download
                </button>
                </div>
            </ng-template>
        </kendo-grid-column>
    
        <kendo-grid-column title="Payment Done" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="col-12 collpadding0 center-align cell-content">
                <div *ngIf="dataItem.isPaymentDone !== 1">
                    <img src="../assets/vectors/sandclock.svg" class="svg-icon-btn" alt="" style="cursor:pointer;">
                </div>
                <div *ngIf="dataItem.isPaymentDone === 1">
                    <img src="../assets/vectors/GreenTick.svg" alt="" class="greentick">
                </div>
                </div>
            </ng-template>
        </kendo-grid-column>
    
        <kendo-grid-column title="Confirmation Slips" [width]="300">
            <ng-template kendoGridCellTemplate let-dataItem let-i="rowIndex">
                <div class="col-12 collpadding0 center-align cell-content">
                <span style="color: #A0A0A0;">
                    {{ fileNames[i] ? (fileNames[i].length > 10 ? fileNames[i].substring(0, 12) + '..' : fileNames[i]) : 'No file chosen' }}
                </span>
                <input type="file" formControlName="Template" #TemplateFile 
                       (change)="onFinalFileChange($event, i)" [id]="'fileInput' + i" style="display: none;" />
                <button class="figma-btn-gray download-btn"
                        (click)="TemplateFile.click()"
                        [disabled]="!(dataItem.confirmationSlipfileUploadEnabled === 1)">
                    Browse
                </button>
                <button class="figma-btn-blue download-btn"
                        style="color: white; margin-left: 8px;"
                        (click)="uploadFinalFiles(i)"
                        [disabled]="!(dataItem.confirmationSlipfileUploadEnabled === 1)">
                    Upload
                </button>
                </div>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid>
    
</div>

<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
  </div>

  <app-challan-history-popup *ngIf="openHistory" [selectedMonth]="selectedMonth" [selectedYear]="selectedYear" [selectedComplianceId]="selectedComplianceId" (close)="closeHistoryPopup()"></app-challan-history-popup>

  <app-alert-popup *ngIf="alertData.alertVisible" [Width]="250" [Height]="260" [Data]="alertData"
  (close)="closeAlert()"></app-alert-popup>