import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridModule } from '@progress/kendo-angular-grid';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { TreeListModule } from "@progress/kendo-angular-treelist";
import { UserService } from '../../service/user.service';

@Component({
  selector: 'app-tree-grid',
  standalone: true,
  imports: [CommonModule, GridModule, TreeViewModule, TreeListModule],
  templateUrl: './tree-grid.component.html',
  styleUrls: ['./tree-grid.component.css']
})
export class TreeGridComponent implements OnInit {
  @Input() data: any[] = [];
  @Input() parentIdField: string = 'parentID';
  @Input() idField: string = 'id';
  @Input() height: string = '';
  @Input() expandable: boolean = false;
  @Input() columns: Array<{ field: string, title: string, width: number, expandable?: boolean, iconColumn?: boolean, filteredIcons?: any[] }> = [];
  Role:string=''
  @Output() buttonClick = new EventEmitter<{ dataItem: any, action: string }>();
  constructor(private user:UserService){}

  ngOnInit() {
    debugger;
    if (this.data && this.data.length > 0) {
      this.generateColumns();
    }
    this.Role=this.user.getRole()
  }

  generateColumns() {
    // Skip auto-generation if data is empty or 'action' column already exists
    if (this.data.length === 0) {
      return;
    }

    const actionColumnExists = this.columns.some(col => col.field === 'action' || col.field === 'Action');
    if (actionColumnExists) {
      return;
    }

    const sampleData = this.data[0];
    const keys = Object.keys(sampleData);

    this.columns = keys.map(key => {
      const isExpandable = key === 'name'; // Assuming 'name' field should be expandable
      return {
        field: key,
        title: this.capitalize(key),
        width: 180,
        expandable: isExpandable,
        iconColumn: key === 'action',
        filteredIcons: []
      };
    });

    // Customize specific fields
    this.columns.forEach(column => {
      if (column.field === 'name') {
        column.width = 250;
      }
    });
  }



  isLeafNode(dataItem: any): boolean {
    let filteredIcons = [];
    let filteredIcon = [];

    if (dataItem.parentID == null && !(this.Role==='PERF' || this.Role==='RVW1')) {
        filteredIcons.push({
            url: '../assets/vectors/Add.svg',
            alt: 'Add Icon',
            action: 'Add'
        });

        this.columns[6].filteredIcons = filteredIcons;
        this.columns[7].filteredIcons = [];
        return true;
    } else {
        let isLeaf = !this.data.some(item => item[this.parentIdField] === dataItem[this.idField]);

        if (isLeaf) {
            if (!(this.Role === 'PERF' || this.Role === 'RVW1')) {
              filteredIcons.push(
                {
                    url: '../assets/vectors/edit.svg',
                    alt: 'Edit Icon',
                    action: 'edit'
                },
                {
                    url: '../assets/vectors/delete.svg',
                    alt: 'Delete Icon',
                    action: 'delete'
                }
            );
            filteredIcon.push({
              url: '../assets/vectors/RightArrowButton.svg',
              alt: 'Address Mapping Icon',
              action: 'addressmapping'
          });
            } else {
                              
                filteredIcons.push({
                  url: '../assets/vectors/edit.svg',
                  alt: 'Edit Icon',
                  action: 'edit'
              });
            }

          
        }

        this.columns[6].filteredIcons = filteredIcons;
        this.columns[7].filteredIcons = filteredIcon;
        return isLeaf;
    }
}


  capitalize(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  onButtonClick(dataItem: any, action: string): void {
    this.buttonClick.emit({ dataItem, action });
  }

}
