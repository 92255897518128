<hr class="hr-fluid">
<div class="col-md-12 colpadding0" style="margin-top: -14px;">
    <header class="panel-heading tab-bg-primary  colpadding0" style="background: none !important;   ">
        <ul class="nav nav-tabs">
            <li class="listItem pemt" style="margin-left: -8px !important">
                <a>User</a>
            </li>
        </ul>
    </header>
</div>
<div class="row colpadding0" style="margin-bottom: 10px;">
    <div class="col-md-12 colpadding0 toolbar" style="margin-top: 5px;">
        <div class="col-md-8 colpadding0" style=" justify-content: space-between; ">
            <app-combo-box [width]="'350px'" [Placeholder]="defaultSelectedValue" [selectedValue]="applyFilterEvent"
                (filterChange)="FilterDropdown($event)" (valueChange)="onDropdownValueChange($event)"  (valueChange)="DropdownGridChange($event)" [data]="dropdown">
            </app-combo-box>
            <div style="display: inline">
                <button class="figma-btn-white" (click)="applyFilter()" type="submit">
                    <img src="../assets/vectors/ApplyFilter.svg" alt="">
                </button>
                <button (click)="clearFilter()" class="figma-btn-white">
                    <img src="../assets/vectors/ClearFilter.svg" alt="">
                </button>
            </div>
        </div>
        <div class="col-md-4 colpadding0">
                <button (click)="exportToExcel()" class="exportbtn">
                    <img src="../assets/vectors/ExportButton.svg" alt="">
                </button>
                <button class="figma-btn-blue" (click)="OpenAddNewPopup()" type="submit">
                    <img src="../assets/vectors/Plus.svg" alt="">Add New
                </button>
                <button class="figma-btn-blue " style=" font-size: 800; " (click)="BackButton()">
                    Back
                </button>
        </div>
    </div>
</div>

<kendo-grid class="custom-grid" [data]="gridData" [columnMenu]="true" [pageSize]="pageSize" [pageable]="true"
    (pageChange)="pageChange($event)" [loading]="loading" [sortable]="true" [skip]="skip">
    <kendo-grid-column field="firstName" title="First Name">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div [title]="dataItem.firstName" class="custom-tooltip">
                {{dataItem.firstName}}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="lastName" title="Last Name">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div [title]="dataItem.lastName" class="custom-tooltip">
                {{dataItem.lastName}}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="email" title="Email">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div [title]="dataItem.email" class="custom-tooltip">
                {{dataItem.email}}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="contactNumber" title="Contact No" [width]="140">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div [title]="dataItem.contactNumber" class="custom-tooltip">
                {{dataItem.contactNumber}}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="hR_Role" title="Role" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div [title]="dataItem.hR_Role" class="custom-tooltip">
                {{dataItem.hR_Role}}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="isActive" title="Status" [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div [title]="dataItem.eM_Status" class="custom-tooltip">
                {{dataItem.isActive}}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="action" title="Action" [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <img src="../assets/vectors/edit.svg" (click)="OpenEditPopup(dataItem)"  alt="Save Icon" class="svg-icon" />
                <img src="../assets/vectors/delete.svg" alt="Delete Icon" class="svg-icon"
                    (click)="confirmAlert(dataItem)" />
        </ng-template>
    </kendo-grid-column>
    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-input></kendo-pager-input>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>
    <ng-template *ngIf="loading" kendoGridLoadingTemplate>
        <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
    </ng-template>
</kendo-grid>

<app-user-master-add-edit-popup [UserId]="userID" *ngIf="showAddEditPopup"
    (close)="closePopup()"></app-user-master-add-edit-popup>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270"  [id]="LoggedInuserID" [Data]="alertData"
  (close)="closeAlert()" (confirm)="DeleteUser($event)"></app-alert-popup>