import { Component, ElementRef, EventEmitter, Output, Input,ViewChild } from '@angular/core';
import { UserService } from '../../../service/user.service';
import { CommonModule, NgIf } from '@angular/common';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { FormsModule } from '@angular/forms';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { GstuploadService } from '../../../service/gstupload.service';
import { BlobService } from '../../../service/blob.service';
@Component({
  selector: 'app-gst-upload-popup',
  standalone: true,
  imports: [DialogModule,NgIf,AlertPopupComponent],
  templateUrl: './gst-upload-popup.component.html',
  styleUrl: './gst-upload-popup.component.css'
})
export class GstUploadPopupComponent {
  @Output() close = new EventEmitter<void>();
  @Input() clientID :any;
  selectedFile?: File|'';
  dropzoneMessage: string = "Drag your files here or ";
  
  UserID: any = 0;
  EntityID: any = 0;
  alertData = { alertMessage: '', alertVisible: '' };
  loading: boolean=false;

  constructor(private GstuploadService: GstuploadService, private userService: UserService, private blobService: BlobService,) {}

  @ViewChild('dialogTitle') dialogTitle!: ElementRef;
  ngAfterViewInit() {
    const titleElement = this.dialogTitle.nativeElement;
    titleElement.style.textAlign = 'center';
    titleElement.style.width = '100%';
  }

  ngOnInit(): void {
    this.UserID = this.userService.getUserId();
    this.EntityID = this.userService.getClientId();
  }

  handleButtonClick(event: { dataItem: any, button: any }): void {
    console.log(event);
    this.userService.setCustomerId(event.dataItem.customerId);
  }

  public closePopup(): void {
    this.close.emit();
  }
  public closeAlertPopup(): void {
    this.alertData.alertVisible = ''; 
  }

  public onFileSelect(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
      const file = input.files[0];
      if (this.validateFile(file)) {
        this.selectedFile = file;
        console.log('Selected file:', this.selectedFile);
      } else {
        this.alertData = { alertMessage: 'Invalid file type. Only Excel files are allowed.', alertVisible: 'error' };
      }
    }
  }

  public onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drop the file here or";
  }

  public onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drag your files here or ";
  }

  public onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      console.log('file', event.dataTransfer.files)
      const file = event.dataTransfer.files[0];
      if (this.validateFile(file)) {
        this.selectedFile = file;
        console.log('Selected file:', this.selectedFile);
      } else {
        this.alertData = { alertMessage: 'Invalid file type. Only Excel files are allowed.', alertVisible: 'error' };
      }
      event.dataTransfer.clearData();
    }
  }

  public uploadFile(): void {
    if (this.selectedFile) {      
    const fileName = this.selectedFile.name;   
    const extensions = fileName.split('.');    
    if (extensions.length > 2) {
      this.selectedFile=undefined;
      this.alertData = { alertMessage: 'Please upload files with a single extension', alertVisible: 'error' };
      return; 
    }
      this.GstuploadService.uploadFile(this.selectedFile, this.clientID, this.UserID).subscribe({
        next: (response) => {
        console.log('response',response);
         debugger;
          if(response.status==='success'){
            this.alertData = { alertMessage: 'File Uploaded Successfully', alertVisible: 'success' };
          }
          else if(response.status==='error'){
            this.selectedFile=undefined;
            console.log('error')
            debugger;
            this.alertData = { alertMessage: 'There is an error in file .Error file downloaded', alertVisible: 'error' };
            this.downloadErrorFile(response.filePath, response?.sasToken);
            this.selectedFile=''
          }
          else{
             console.log('error');
              debugger;
            this.selectedFile=undefined;
            this.alertData = { alertMessage: response.status, alertVisible: 'error' };
          }
        },
        error: (error) => {
          this.selectedFile=undefined;
          this.alertData = { alertMessage: error.error.Result, alertVisible: 'error' };
          console.log('errorinlast',error);
          
        }
      });
    } else {
      this.alertData = { alertMessage: 'No file selected', alertVisible: 'error' };
    }
  }

  downloadErrorFile(FilePath: string, sasToken: string) {
    debugger;
    const blobUrl = `${FilePath}?${sasToken}`;
    const a = document.createElement('a');
    a.href = blobUrl;
    const filename = FilePath.split('/').pop() || 'downloaded_file';
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  private validateFile(file: File): boolean {
    const allowedExtensions = ['xls', 'xlsx', 'csv', 'xlsm', 'xlsb','xltx'];
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    return fileExtension ? allowedExtensions.includes(fileExtension) : false;
  }

  ActivityDetailsExporttoExcel(): void {

  
    this.loading = true
    this.GstuploadService.ActivityDetailsExporttoExcel().subscribe(
      (response: any) => {
        console.log('responseexcell', response);

        const blobLink = response.body.filePath;
        const blobName = this.blobService.extractBlobName(blobLink);

        setTimeout(() => {
          this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
            const sasToken = tokenResponse.result;
        this.blobService.downloadFile(blobName,sasToken);
        this.loading = false;
          });
        }, 5000);
      },
      (error) => {
        console.error('Error fetching blob link:', error);
        this.loading = false;
      });



  }

}
