import { Component, EventEmitter, Output } from '@angular/core';
import { MinimumWagesMasterService } from '../../../service/minimum-wages-master.service';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { FormsModule } from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';

@Component({
  selector: 'app-mwupload-popup',
  standalone: true,
  imports: [DialogModule, FormsModule, NgIf, NgClass, ComboBoxComponent, AlertPopupComponent, DropdownListComponent],
  templateUrl: './mwupload-popup.component.html',
  styleUrls: ['./mwupload-popup.component.css']
})
export class MWUploadPopupComponent {
  file: File | null = null;
  dropzoneMessage: string = "Drag your files here or ";
  @Output() close = new EventEmitter<void>();
  public selectedFile?: File;
  public designationOption: string = 'add';
  public alertData = { alertMessage: '', alertVisible: '' };
  public stateDropdownData: any[] = [];
  public selectedDropdown = '';
  public isFormValid: boolean = false;
  public showValidation: boolean = false;
  public selectedIndustryType: string = '';
  public selectedState: string = '';
  public selectedEstablishment: string = '';
  public selectedDesignation: string = '';
  public selectedSkills: string = '';
  public selectedStateNotificationID: string = '';
  public selectedNotificationID: string = '';
  public selectedZone: string = '';
  StateNotificationDefaultValue: { id: string, name: any } = { id: 'Select Notification ID', name: 'Select Notification ID' };
  EstablishmentDefaultValue: { id: string, name: any } = { id: 'Establishment Type', name: 0 };
  ZoneDefaultValue: { id: string, name: any } = { id: 'Zone', name: 0 };
  public EstablishDropdownData: any[] = [];
  public SkillCategoryDropdownData: any[] = [];
  public DesignationDropdownData: any[] = [];
  public IndustryTypeDropdownData: any[] = [];
  public StateNotificationDropdownData: any[] = [];
  public NotificationIDDropdownData: any[] = [];
  public ZoneDropdownData: any[] = [];

  constructor(private MinimumWagesService: MinimumWagesMasterService) { }

  ngOnInit(): void {
    this.StateDropdown();
  }

  StateDropdown() {
    this.MinimumWagesService.getStateDropdown().subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && Array.isArray(data)) {
            this.stateDropdownData = data.map((item: any) => ({
              id: item.sM_Code,
              name: item.sM_Name
            }));
          }
          else {
            console.error('Unexpected API response format:', data);
          }
        }, error: (error) => {
          console.error('Error fetching states data', error);
        }
      }
    );
  }

  StateValueChange(value: any) {
    if (value == undefined) {
      this.EstablishmentDefaultValue = { id: 'Establishment Type', name: 0 };
      this.StateNotificationDefaultValue = { id: 'Select Notification ID', name: 'Select Notification ID' };
      this.ZoneDefaultValue = { id: 'Zone', name: 0 };
    }
    this.selectedState = value.id;
    this.StateNotificationDropdown(value.id);
    this.EstablishTypeDropdown(value.id);
    this.ZoneDropdown();
    this.validateForm();
  }

  StateNotificationValueChange(value: any) {
    this.selectedStateNotificationID = value.name;
    this.validateForm();
  }

  StateNotificationDropdown(value: any) {
    this.MinimumWagesService.getNotificationIdDropdown(value).subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && Array.isArray(data)) {
            this.StateNotificationDropdownData = data.map((item: any) => ({
              id: `[${item.id}] ${item.notification_Number}`,
              name: item.id
            }));
          }
          else {
            console.error('Unexpected API response format:', data);
          }
        }, error: (error) => {
          console.error('Error fetching states data', error);
        }
      });
  }

  EstablishValueChange(value: any) {
    this.selectedEstablishment = value.name;
    this.validateForm();
  }

  EstablishTypeDropdown(value: any) {
    this.MinimumWagesService.getEstablishTypeDropdown(value).subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && Array.isArray(data)) {
            this.EstablishDropdownData = data.map((item: any) => ({
              id: item.key,
              name: item.value
            }));
          }
          else {
            console.error('Unexpected API response format:', data);
          }
        }, error: (error) => {
          console.error('Error fetching states data', error);
        }
      });
  }

  ZoneDropdown() {
    this.MinimumWagesService.getZoneDropdown(this.selectedState).subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && Array.isArray(data)) {
            this.ZoneDropdownData = data.map((item: any) => ({
              id: item.zone,
              name: item.zone
            }));
          }
          else {
            console.error('Unexpected API response format:', data);
          }
        }, error: (error) => {
          console.error('Error fetching states data', error);
        }
      });
  }

  ZoneValueChange(value: any) {
    this.selectedZone = value.name;
    this.validateForm();
  }

  public onFileSelect(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
      const file = input.files[0];
      if (this.validateFile(file)) {
        this.selectedFile = file;
      } else {
        this.alertData = { alertMessage: 'Only Excel files are allowed.', alertVisible: 'error' };
      }
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drop the file here or";
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drag your files here or";
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      if (this.validateFile(file)) {
        this.file = file;
        this.selectedFile = this.file;
      } else {
        this.alertData = { alertMessage: 'Invalid file type. Only Excel files are allowed.', alertVisible: 'error' };
      }
      event.dataTransfer.clearData();
    }
  }

  validateForm() {
    this.isFormValid = !!(this.selectedState && this.selectedEstablishment && this.selectedStateNotificationID && this.selectedZone);
  }

  private validateFile(file: File): boolean {
    const allowedExtensions = ['xls', 'xlsx', 'csv'];
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    return fileExtension ? allowedExtensions.includes(fileExtension) : false;
  }

  public uploadFiles(): void {
    this.showValidation = true;
    if (this.selectedFile && this.isFormValid) {
      this.MinimumWagesService.uploadFile(this.selectedDropdown, this.selectedEstablishment, this.selectedStateNotificationID, this.selectedZone, this.selectedFile).subscribe({
        next: (response) => {
          this.alertData = { alertMessage: 'File Uploaded Successfully...', alertVisible: 'success' };
          console.log('File uploaded successfully', response);
        },
        error: (error) => {
          this.selectedFile = undefined;
          console.log('error', error);
          this.alertData = { alertMessage: 'Error while saving data', alertVisible: 'error' };
        }
      });
    } else {
      if (!this.selectedFile) {
        this.alertData = { alertMessage: 'Please fill all the fields', alertVisible: 'error' };
      } else {
        this.selectedFile = undefined;
        this.alertData = { alertMessage: 'Please fill all the fields', alertVisible: 'error' };
      }
    }
  }

  downloadCSV() {
    console.log(this.selectedState, this.selectedEstablishment);
    if (this.selectedState && this.selectedEstablishment) {
      this.MinimumWagesService.getMWTemplate(this.selectedState, this.selectedEstablishment).subscribe(
        {
          next: (response) => {
            const data = response.body;

            if (!data || !data.length) {
              console.error('No data found in the Result array');
              return;
            }
            const csv = this.convertToCSV(data);

            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            if (link.download !== undefined) {
              const url = URL.createObjectURL(blob);
              link.setAttribute('href', url);
              link.setAttribute('download', `${this.selectedState}_${this.selectedEstablishment}.csv`);

              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              URL.revokeObjectURL(url);
            }
          }, error: (error) => {
            console.error('Error:', error);
          }
        });
    } else {
      this.alertData = { alertMessage: 'Please select state and Establishment type', alertVisible: 'error' };
    }
  }

  convertToCSV(data: any[]): string {
    if (!data || !data.length) {
      console.error('No data received');
      return '';
    }
    const headers = Object.keys(data[0]);
    const columnWidths = headers.map(header => {
      const headerLength = header.length;
      const maxDataLength = Math.max(...data.map(row => (row[header] ? row[header].toString().length : 0)));
      return Math.max(headerLength, maxDataLength);
    });

    const headerRow = headers.map((header, index) => this.padColumn(header, columnWidths[index])).join(',');
    const rows = data.map(row => {
      return headers.map((header, index) => this.padColumn(row[header], columnWidths[index])).join(',');
    });

    const footerItems = [
      "Note",
      "Mandatory Fields:",
      "Industry_Type - When Establishment type is FACT",
      "Skill_Category",
      "Designation"
    ];

    const csvContent = [
      headerRow,
      ...rows,
      '',
      ...footerItems
    ].join('\r\n');

    console.log('CSV Content:', csvContent);

    return csvContent;
  }

  padColumn(value: any, width: number): string {
    const cell = value ? value.toString() : '';
    return `"${cell.padEnd(width, ' ')}"`;
  }

  closeAlert() {
    this.alertData = { alertMessage: '', alertVisible: '' };
  }

  closePopup() {
    this.close.emit();
  }
}
