import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  validateUser(username: string, password: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Auth/ValidateUser`;
    const body = {
      username: username,
      password: password
    };
    return this.http.post(url, body, { observe: 'response' });
  }

}
