<kendo-dialog #dialogRef title="Add/Edit New Dispencery" (close)="closePopup()"  [height]="250"
  [width]="550" class="custom-dialog">
  <form [formGroup]="userForm" class="dialog-content">
    <div class="form-group row">
      <div class="col-md-4">
        <label for="stateName" class="f-label">State<sub style="color: red; font-size: 15px;">*</sub></label>
      </div>
      <div class="col-md-8">
        <app-dropdown-list [data]="dropdown" [selectedValue]="selectedState" [isDisabled]="disableState" [width]="'300px'"
          textField="text" valueField="value" (valueChange)="onDropdownValueChange($event)"
          [hasError]="selectedState.text =='Select State' && showValidationError" >
        </app-dropdown-list>
        <div *ngIf="userForm.get('stateName')?.invalid && userForm.get('stateName')?.touched" class="text-danger">
          <small class="text-danger" *ngIf="userForm.get('stateName')?.errors?.['required']"> State is required.</small>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-4">
        <label for="districtName" class="f-label">District Name<sub style="color: red; font-size: 15px;">*</sub></label>
      </div>
      <div class="col-md-8">
        <input kendoTextBox id="districtName" formControlName="districtName" placeholder="Enter district name"
          class="f-textbox"   [ngClass]="{'is-invalid': userForm.get('districtName')?.invalid &&  userForm.get('districtName')?.touched}" />
  
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-4">
        <label for="dispenceryName" class="f-label">Dispencery Name<sub
            style="color: red; font-size: 15px;">*</sub></label>
      </div>
      <div class="col-md-8">
        <input kendoTextBox id="dispenceryName" formControlName="dispenceryName" placeholder="Enter dispencery name"
          class="f-textbox"  [ngClass]="{'is-invalid':userForm.get('dispenceryName')?.invalid &&  userForm.get('dispenceryName')?.touched}" />
      </div>
    </div>
  </form>
  <kendo-dialog-actions class="ksave">
    <button  type="submit" (click)="onSubmit()" class="figma-btn-blue figma-popup">
      Save
    </button>
  </kendo-dialog-actions>

  <div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>
</kendo-dialog>

<app-alert-popup *ngIf="alertData" [Width]="270" [Data]="alertData"
  (close)="closeAlert()"></app-alert-popup>