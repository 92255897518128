<!-- <kendo-multiselect class="f-textbox" [data]="data" [textField]="textField" [valueField]="valueField"
    [value]="selectedValues" [style.width]="width" [style.height]="height" (valueChange)="onValueChange($event)"
    [checkboxes]="true" [placeholder]="DefaultValue">
    <ng-template kendoMultiSelectValueTemplate let-values>
        {{ values.length }} item(s) selected
    </ng-template>
</kendo-multiselect> -->
<!-- <kendo-multiselect #multiSelect class="f-textbox" [data]="modifiedData" [textField]="textField" [valueField]="valueField"
    [value]="selectedValues" [style.width]="width" [style.height]="height" (valueChange)="onValueChange($event)" [popupSettings]="{ animate: false }"
    [checkboxes]="true"   [placeholder]="placeholder" >
    <ng-template kendoMultiSelectValueTemplate let-values>
        {{ values.length }} item(s) selected
    </ng-template>
</kendo-multiselect> -->
<kendo-multiselect #multiSelect class="f-textbox" [data]="modifiedData" [textField]="textField" [style.width]="width"
[value]="selectedValues" [style.height]="height" [valuePrimitive]="false" [valueField]="'value'" (valueChange)="onValueChange($event)"
    [popupSettings]="{ animate: false }" [checkboxes]="true" [placeholder]="placeholder">
    <ng-template kendoMultiSelectTagTemplate let-value>
        <!-- <span class="selected-items-text no-wrap">
            {{ selectedValues.length > 0 ? selectedValues.length + ' item(s) selected' : 'No items selected' }}
        </span> -->
        <!-- <span *ngIf="selectedValues.length > 0" 
            [attr.title]="selectedValues.length + ' item(s) selected'">{{ selectedValues.length }} item(s) selected
      </span> -->
        <!-- <span *ngIf="selectedValues.length === 0">No items selected</span> -->
        <span *ngIf="selectedValues.length > 0">{{ selectedValues.length }} item(s) selected</span>
        <span *ngIf="selectedValues.length === 0">No items selected</span>
    </ng-template>
</kendo-multiselect>
