import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EntitydashboardService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getEntityList(userId: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/ClientDetailsManagement/Get_LC_EntityListBasedOnUserId?UserID=${userId}`;
    return this.http.get(url, { observe: 'response' });
  }
  getAnchorExcel(UserID: string,ComType:string,Month:number,Year:number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Onboarding/AnchorDashboard_ExportToExcel?UserID=${UserID}&ComType=${ComType}&Month=${Month}&Year=${Year}`;
    return this.http.get(url, { observe: 'response' });
  }
  getEntityLoactionExcel(UserID: number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/ClientDetailsManagement/Get_LC_EntityListBasedOnUserId?UserID=${UserID}`;
    return this.http.get(url, { observe: 'response' });
  }
  getEntityMasterBlobLink(UserID: number) {
    const url = `${this.apiUrl}/api/Onboarding/getAll_Entity_Details_exporttoexcel?userID=${UserID}`;
    return this.http.get(url, {});
  }
  getEntityLocationMasterBlobLink(UserID: number) {
    const url = `${this.apiUrl}/api/Onboarding/GetALL_EntityLocation_Details_ExportToExcel?userID=${UserID}`;
    return this.http.get(url, {});
  }

}
