import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { IndustrySkillDesignationMappingService } from '../../../service/industry-skill-designation-mapping.service';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { NgClass, NgIf } from '@angular/common';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { IndustrySkillBulkUploadComponent } from '../../popups/industry-skill-bulk-upload/industry-skill-bulk-upload.component';
import { IndustrySkillAddNewPopupComponent } from '../../popups/industry-skill-add-new-popup/industry-skill-add-new-popup.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-industry-skill-designation-mapping',
  standalone: true,
  imports: [GridActionItemComponent,IndicatorsModule, DropdownListComponent, ComboBoxComponent, NgIf, AlertPopupComponent, IndustrySkillBulkUploadComponent, NgClass, IndustrySkillAddNewPopupComponent],
  templateUrl: './industry-skill-designation-mapping.component.html',
  styleUrls: ['./industry-skill-designation-mapping.component.css']
})
export class IndustrySkillDesignationMappingComponent implements OnInit {
  public gridData: GridDataResult = { data: [], total: 0 };
  alertData = { alertMessage: '', alertVisible: '' };
  public maxStateWidth!: string;
  public maxEstablishmentTypeWidth!: string;
  @Output() close = new EventEmitter<void>();
  AddNewPopup = false;
  deleteId = 0;
  uploadPopup = false;
  loading = false;
  
  constructor(private IndustrySkillService: IndustrySkillDesignationMappingService) { }

  ngOnInit(): void {
    this.loadGrid();
    // this.maxStateWidth = this.calculateMaxWidth(this.selectedState) + 'px';
    // this.maxEstablishmentTypeWidth = this.calculateMaxWidth(this.selectedEstablishment) + 'px';
  }

  AddNew() {
    this.AddNewPopup=true;
  }
  calculateMaxWidth(values: string[]): number {
    const maxLength = Math.max(...values.map(v => v.length));
    return maxLength * 8; // Approximate width in pixels (8-10px per character)
  }
  loadGrid() {
    this.IndustrySkillService.getIndustrySkillGridData().subscribe({
      next: (response) => {
        if (response.status === 200) {
          const data = response.body;
          this.gridData = {
            data: data,
            total: data.length
          };
          this.loading=false;
        }
        else {
          console.error('Error fetching entity data');
        }
      },
      error: (error) => {
        console.error('Error fetching entity data', error);
      }
    }
  );
  }

  DeleteIndustrySkill(value: any) {
    this.alertData = { alertMessage: '', alertVisible: '' };
    this.IndustrySkillService.DeleteIndustry(value).subscribe({
      next: (response) => {
        if (response.status === 200) {
          const data = response.body;
          this.alertData = { alertMessage: 'Mapping Deleted Successfully', alertVisible: 'success' }; 
        }
        else {
          console.error('Error fetching entity data');
        }
      },
      error: (error) => {
        console.error('Error fetching entity data', error);
      }
    }
    );
  }

  confirmAlert(event: { dataItem: any, button: any }): void {
    if (event.button.action === 'delete') {
      this.deleteId = event.dataItem.id;
      this.alertData = { alertMessage: 'Are you sure you want to delete this record?', alertVisible: 'confirm' };
    }
  }

  public parentColumns: any[] = [
    { field: 'sM_Name', title: 'State', width: '150px' },
    { field: 'industryType', title: 'Establishment Type',width: '150px' },
    { field: 'industry', title: 'Industry',width:'220px' },
    { field: 'designation', title: 'Designation' },
    { field: 'skills', title: 'Skill Category' },
    {
      field: 'Action',
      isIconColumn: true,
      title: 'Action',
      filteredIcons: [
        {
          url: '../assets/vectors/delete.svg',
          alt: 'Delete Icon',
          action: 'Delete',
          class:'svg-icon'
        }
      ]
    }
  ];

  public closePopup(): void {
    this.alertData = { alertMessage: '', alertVisible: '' };
    this.uploadPopup = false;
    this.AddNewPopup=false;
    // this.loading=true;
    this.loadGrid();
  }

  openUploadPopup() {
    this.uploadPopup = true;
  }
}
