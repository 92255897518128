import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MasterAbstractsService {
  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getGridDate(EntityId:string,userID:number,_stateID:number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/RegulatoryAbstracts/Bind_AbstractGrid?entityID=${EntityId}&userID=${userID}&_stateID=${_stateID}`;
    return this.http.get(url, {observe:'response'});
  }
  getRegistrationGrid(custID: number, userID: number, profileID: number | null, entityID: string, isLocationWise: boolean): Observable<HttpResponse<any>> {
    let url = `${this.apiUrl}/api/RegistrationRepository/LC_GetAll_RegistrationRepository?customerID=${custID}&userID=${userID}&EntityID=${entityID}&IsLocationWise=${isLocationWise}`;
  
    // Conditionally append profileID if it's not null
    if (profileID !== null) {
      url += `&profileID=${profileID}`;
    }
  
    return this.http.get(url, { observe: 'response' });
  }
  
  getStateDropdown(EntityId:string,userID:number,isImplementation:number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/RegulatoryAbstracts/Get_StatesList?EntityId=${EntityId}&userID=${userID}&isImplementation=${isImplementation}`;
    return this.http.get(url, {observe:'response'});
  }
  getActListDropdown(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/RegulatoryAbstracts/Get_ActsList`;
    return this.http.get(url, {observe:'response'});
  }
  getOnetimeActivityDropdown(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/RegistrationRepository/GetAll_OneTimeActivityMaster`;
    return this.http.get(url, {observe:'response'});
  }
  getRegisterRepoExport(custID:number,userID:number,profileID:number, entityID:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/RegistrationRepository/ExportToExcelRepo?customerID=${custID}&userID=${userID}&profileID=${profileID}&EntityID=${entityID}`;
    return this.http.get(url, {observe:'response'});
  }

  UploadFile(file: File, _stateID: string,actid:string,languageType:string,userID:number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/RegulatoryAbstracts/Save_AbstractFile`;

    const formData: FormData = new FormData();
    formData.append('_stateID', _stateID.toString());
    formData.append('actid', actid.toString());
    formData.append('languageType', languageType);
    formData.append('userID', userID.toString());
    formData.append('file', file);

    formData.forEach((value, key) => {
      console.log(`${key}:`, value);
    });

    return this.http.post(url, formData, {observe: 'response'});
  }
  getClientsMaster(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Invoice/GetClientsMaster`;
    return this.http.get(url, {observe:'response'});
  }
  GetVariableList(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Invoice/GetVariableList`;
    return this.http.get(url, {observe:'response'});
  }
  GetCodeDescription(type:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Invoice/GetCodeDescription?Type=${type}`;
    return this.http.get(url, {observe:'response'});
  }
  getActivityList(clientID: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Invoice/GetActivityListBasedOnClientId?ClientId=${clientID}}`;
    return this.http.get(url, {observe:'response'});
  }
  getCommercialGridData(clientID: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Invoice/CommercialSetupDetails?ClientId=${clientID}`;
    return this.http.get(url, {observe:'response'});
  }
  GetOnetimeActivity(clientID: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Invoice/GetOneTimeActivitiesBasedOnClientId?_clientid=${clientID}`;
    return this.http.get(url, {observe:'response'});
  }
  GetBranchWisePriceDetailsBasedOnClientId(clientID: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Invoice/GetBranchWisePriceDetailsBasedOnClientId?_clientid=${clientID}`;
    return this.http.get(url, {observe:'response'});
  }
  GetCommercialElementList(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Invoice/GetCommercialElementList`;
    return this.http.get(url, {observe:'response'});
  }
  GetClientInvoiceCommercialType(clientID:any): Observable<HttpResponse<any>> {
    console.log('service triggered')
    const url = `${this.apiUrl}/api/Invoice/GetClientInvoiceCommercialType?_clientid=${clientID}`;
    return this.http.get(url, {observe:'response',responseType: 'text'});
  }
  GetClientInvoiceSplitCommercialType(clientID:any): Observable<HttpResponse<any>> {
    console.log('service triggered')
    const url = `${this.apiUrl}/api/Invoice/GetClientInvoiceSplitCommercialType?_clientid=${clientID}`;
    return this.http.get(url, {observe:'response',responseType: 'text'});
  }
  LumpSumDetails(clientID:any): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Invoice/LumpSumDetails?ClientID=${clientID}`;
    return this.http.get(url, {observe:'response'});
  }
  SaveCommercialChange(value: any): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Invoice/InsertCommercialSetUP`;
    return this.http.post<any>(url, value, { observe: 'response' });
  }
  fetchFile(FilePath: string, FileName: string, FileKey: string): Observable<Blob> {
    const url = `${this.apiUrl}/api/RegulatoryAbstracts/RegulatoryAbstractPreview?FilePath=${FilePath}&FileName=${FileName}&FileKey=${FileKey}`;
    return this.http.post(url, null, { responseType: 'blob' });
  }
  RegRepoFile(recordID: number, docType: string): Observable<Blob> {
    const url = `${this.apiUrl}/api/RegistrationRepository/RegistrationRepositoryPreview?recordID=${recordID}&docType=${docType}`;
    return this.http.post(url, null, { responseType: 'blob' });
  }
}
