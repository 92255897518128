import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StatutoryMasterService {
  private baseUrl = environment.apiUrl;
  
  constructor(private http: HttpClient) { }

  getAllChallanMasters(comp: string, take: number, skip: number, page: number, pageSize: number):Observable<HttpResponse<any>> {   
    const url=`${this.baseUrl}/api/StatutoryMaster/GetChallanMasterData?comp=${comp}&take=${take}&skip=${skip}&page=${page}&pageSize=${pageSize}`;
    return this.http.get<any>(url,{ observe: 'response' });

  }
  getAllReturnsMasters(comp: string, take: number, skip: number, page: number, pageSize: number):Observable<HttpResponse<any>> {
    const url=`${this.baseUrl}/api/StatutoryMaster/GetReturnsMasterData?comp=${comp}&take=${take}&skip=${skip}&page=${page}&pageSize=${pageSize}`;
    return this.http.get<any>(url,{ observe: 'response' });

  }

  getAllRegisterMasters(comp: string, take: number, skip: number, page: number, pageSize: number):Observable<HttpResponse<any>> {
    const url=`${this.baseUrl}/api/StatutoryMaster/GetRegisterMasterData?comp=${comp}&take=${take}&skip=${skip}&page=${page}&pageSize=${pageSize}`;
    return this.http.get<any>(url,{ observe: 'response' });

  }

  getReturnExcel(): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/StatutoryMaster/ExportReturnMaster`;
    return this.http.get<any>(url,{ observe: 'response'});
  }
  getChallanExcel(): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/StatutoryMaster/ExportChallanMaster`;
    return this.http.get<Blob>(url,{ observe: 'response'});
  }
  getRegisterExcel(): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/StatutoryMaster/ExportRegisterMaster`;
    return this.http.get<Blob>(url,{ observe: 'response'});
  }
  getDropdownData(value: string): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/StatutoryMaster/Get_AllcomplianceDropDown?ctype=${value}`;
    return this.http.get(url,{ observe: 'response'});
  }
}
