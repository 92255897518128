import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubcodeService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getSubCodeData(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/ESIC/GetSubcodeData`;
    return this.http.get(url, { observe: 'response' });
  }

  getSubCodeMasterBlobLink() {
    const url = `${this.apiUrl}/api/ESIC/SubCode_ExportToExcel`;
    return this.http.get(url, {});
  }

  saveSubCode(userForm: any): Observable<HttpResponse<any>> {
    const keyValue = { key: userForm.username, value: userForm.password };
    const url = `${this.apiUrl}/api/ESIC/SaveSubcodeData`;
    return this.http.post(url, keyValue, { observe: 'response' });
  }
  isUserIdExists(Username: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/ESIC/IsUserIdExists?Username=${Username}`;
    return this.http.get(url,{ observe: 'response' });
  }

  getUserIDDropdown(Stateid:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/ESIC/GetSubcodeDataBySearch?Filter=${Stateid}`;
    return this.http.get(url, { observe: 'response' });
  }
}
