<!-- <div class="colpadding0" style="display: flex; align-items: center; margin-top: 3px;">
    <header class="panel-heading tab-bg-primary" style="background: none !important;margin-left:-43px;">
        <ul style="display: flex; align-items: center; margin-top: -2px;">
            <div style="display:flex;">
                <li style="display: flex; align-items: center; margin-right: 20px;">
                    <input id="rdDow" value="C" type="radio" class="custom-radio" name="measurementSystem"
                        (change)="onMeasurementSystemChange($event)" [disabled]="!isRowDataEmpty()" />
                    <h4 style="margin: 0 0 0 5px;color:black;">Invoice</h4>
                </li>
                <li style="display: flex; align-items: center;">
                    <input id="rdFai" value="S" type="radio" class="custom-radio" name="measurementSystem"
                        (change)="onMeasurementSystemChange($event)" [disabled]="!isRowDataEmpty()" />
                    <h4 style="margin: 0 0 0 5px;color:black">Credit Note</h4>
                </li>
            </div>
            <div style="display:flex;">
                <li style="display: flex; align-items: center; margin-right: 20px;">
                    <input id="rdDow" value="C" type="radio" class="custom-radio" name="measurementSystem"
                        (change)="onMeasurementSystemChange($event)" [disabled]="!isRowDataEmpty()" />
                    <h4 style="margin: 0 0 0 5px;color:black;">Regular</h4>
                </li>
                <li style="display: flex; align-items: center;">
                    <input id="rdFai" value="S" type="radio" class="custom-radio" name="measurementSystem"
                        (change)="onMeasurementSystemChange($event)" [disabled]="!isRowDataEmpty()" />
                    <h4 style="margin: 0 0 0 5px;color:black">Draft</h4>
                </li>
            </div>
        </ul>
    </header>
</div> -->
<div class="colpadding0" style="display: flex; flex-direction: column; align-items: start; margin-top: 3px;">
    <header class="panel-heading tab-bg-primary" style="background: none !important; margin-left:-43px;">
 
        <ul style="display: flex; align-items: center; margin-top: -2px;">
            <li style="display: flex; align-items: center; margin-right: 20px;">
                <input id="rdDow1" value="INV" type="radio" class="custom-radio" name="documentType"
                    (change)="onMeasurementSystemChange($event)" [disabled]="!isRowDataEmpty()" />
                <h4 style="margin: 0 0 0 5px; color:black;">Invoice</h4>
            </li>
            <li style="display: flex; align-items: center;">
                <input id="rdFai1" value="CN" type="radio" class="custom-radio" name="documentType"
                    (change)="onMeasurementSystemChange($event)" [disabled]="!isRowDataEmpty()" />
                <h4 style="margin: 0 0 0 5px; color:black;">Credit Note</h4>
            </li>
        </ul>

     
        <ul style="display: flex; align-items: center; margin-top: 10px;">
            <li style="display: flex; align-items: center; margin-right: 20px;">
                <input id="rdDow2" value="R" type="radio" class="custom-radio" name="invoiceType"
                    (change)="onMeasurementSystemChanges($event)" [disabled]="!isRowDataEmptys()" />
                <h4 style="margin: 0 0 0 5px; color:black;">Regular</h4>
            </li>
            <li style="display: flex; align-items: center;">
                <input id="rdFai2" value="D" type="radio" class="custom-radio" name="invoiceType"
                    (change)="onMeasurementSystemChanges($event)" [disabled]="!isRowDataEmptys()" />
                <h4 style="margin: 0 0 0 5px; color:black;">Draft</h4>
            </li>
        </ul>
    </header>
    <div style="margin-left:53%;margin-top: -25px;">
        <h4 style=" font-weight: 500; font-size: 11px;background-color: #ECF5FF;
        padding: 14px 6px 4px 6px;
        border-radius: 5px;
        height: 55px;margin-top:-46px;    width: 482px;">
            <span style="color: red; ">
                Note<sup>*</sup>:
            </span> Button Will be Enabled Between 7PM to 8AM
            <button class="figma-btn-blue" style=" float: right; ">
                <img src="../assets/vectors/DownloadWhite.svg" alt="">
                Generate All Document
            </button>

        </h4>

    </div>
</div>

<div style="display: flex;gap:10px;">
    <div class="col-md-2 colpadding0" style="padding-right: 10px;">
        <app-combo-box [width]="'106%'" [Placeholder]="'Select month/year'"
            (valueChange)="onDropdownMonthYearValueChange($event)" [data]="monthDropdown"
            [selectedValue]="selectedMonthYear" textField="name" valueField="id">
        </app-combo-box>
    </div>
    <div>
        <app-dropdown-list [data]="ClientGstDropdownData" [selectedValue]="selectedClient" [width]="'250px'"
            textField="text" valueField="value" (valueChange)="onDropdownClientGstValueChange($event)">
        </app-dropdown-list>
    </div>
  
</div>
<kendo-grid class="custom-grid" style="margin-top: 15px;" [data]="gridData" [pageSize]="10" [pageable]="true"
    [skip]="Skip" (pageChange)="pageChange($event)" [loading]="GridLoading">
    <ng-template *ngIf="GridLoading" kendoGridLoadingTemplate class="loader-overlay">
        <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
    </ng-template>
    <kendo-grid-column field="riM_ClientID" title="ClientID">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div [title]="dataItem.riM_ClientID" class="cell-content">
                {{ dataItem.riM_ClientID }}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="riM_InvoiceNo" title="		InvoiceNumber">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div [title]="dataItem.riM_InvoiceNo" class="cell-content">
                {{ dataItem.riM_InvoiceNo }}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="rgM_GSTNO" title="GST">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div [title]="dataItem.rgM_GSTNO" class="cell-content">
                {{ dataItem.rgM_GSTNO }}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="riM_Invoice_Amount" title="InvoiceAmount">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div [title]="dataItem.riM_Invoice_Amount" class="cell-content">
                {{ dataItem.riM_Invoice_Amount }}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="riM_Draft_Status" title="Status">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div [title]="dataItem.riM_Draft_Status" class="cell-content">
                {{ dataItem.riM_Draft_Status }}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="isirnGenerated" title="	IRNGenerated">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div [title]="dataItem.isirnGenerated" class="cell-content">
                {{ dataItem.isirnGenerated }}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="riM_Invoice_Type" title="	Type">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div [title]="dataItem.riM_Invoice_Type" class="cell-content">
                {{ dataItem.riM_Invoice_Type }}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="invoice_Date" title="Action">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div [title]="dataItem.invoice_Date" class="cell-content">
                {{ dataItem.invoice_Date }}
            </div>
        </ng-template>
    </kendo-grid-column>


    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-input></kendo-pager-input>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>

</kendo-grid>