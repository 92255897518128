import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserMasterService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getUserMasters(customerId: number, page: number = 1, pageSize: number = 10, skip: number = 0, take: number = 10,): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/User/GetAll_UserMaster?customerID=${customerId}&take=${take}&skip=${skip}&page=${page}&pageSize=${pageSize}`;
    return this.http.get<any>(url, { observe: 'response' });
  }

  getUserMastersByUserId(customerId: number, UserID: number, page: number = 1, pageSize: number = 10, skip: number = 0, take: number = 10,): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/User/GetAll_UserMaster?customerID=${customerId}&UserId=${UserID}&take=${take}&skip=${skip}&page=${page}&pageSize=${pageSize}`;
    return this.http.get<any>(url, { observe: 'response' });
  }

  getUserDropdown(customerID: number, value: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/User/GetAll_UserMaster_Fordd?customerID=${customerID}&Filter=${value}`;
    return this.http.get(url, { observe: 'response' });
  }

  DeleteUser(userID: number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/User/DeleteUser?UserID=${userID}`;
    return this.http.post(url, {}, { observe: 'response' });
  }

  getUserMasterBlobLink(customerID: number, userId: number) {
    const url = `${this.apiUrl}/api/User/User_ExportToExcel?customerID=${customerID}&UserId=${userId}`;
    return this.http.get(url, {});
  }

  getRoleData(userId: number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/User/GetAllRoles?UserID=${userId}`;
    return this.http.get(url, { observe: 'response' });
  }

  getReportingToData(customerID: number, serviceProviderID: number, distributorID: number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/User/GetServiceProvider?customerID=${customerID}&serviceProviderID=${serviceProviderID}&distributorID=${distributorID}`;
    return this.http.get(url, { observe: 'response' });
  }

  GetRLCSUserDetails(userId: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/User/GetRLCSUserDetails?UserID=${userId}`;
    return this.http.get(url, { observe: 'response' });
  }
  GetCustomerIdByUserid(userId: number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/User/GetCustomerIdByUserid?UserID=${userId}`;
    return this.http.get(url, { observe: 'response' });
  }
  GetComplianceProductType(customerID: number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/User/GetComplianceProductType?customerID=${customerID}`;
    return this.http.get(url, { observe: 'response' });
  }
  CreateUpdateRLCS_User_Mapping(value: any): Observable<HttpResponse<any>> {
    // const body={
    //   id: value.id,
    //   avacoM_UserID: value.avacoM_UserID,
    //   avacoM_UserRole: value.avacoM_UserRole,
    //   userType: value.userType,
    //   firstName: value.firstName,
    //   lastName: value.lastName,
    //   email: value.email,
    //   userID: value.userID,
    //   password: value.password,
    //   contactNumber: value.contactNumber,
    //   address: value.address,
    //   designation: value.designation,
    //   department: value.department,
    //   status: value.status,
    //   customerID: value.customerID,
    //   role: value.role,
    //   profileID: value.profileID,
    //   isActive: value.isActive,
    //   createdOn: value.createdOn,
    //   updatedOn: value.updatedOn,
    //   modifiedDate:value.modifiedDate,
    //   enType: value.enType,
    //   emp_Code:value.emp_Code,
    //   user_Gender: value.user_Gender,
    //   user_DesignationID: value.user_DesignationID,
    //   user_DOJ:value.user_DOJ,
    //   user_DOL: value.user_DOL,
    //   user_OfficialEmailID: value.user_OfficialEmailID,
    //   user_GroupEmailID: value.user_GroupEmailID,
    //   user_Reporting_To: value.user_Reporting_To,
    //   user_Password: value.user_Password,
    //   createdBy: value.createdBy,
    //   modifiedBy: value.modifiedBy,
    //   registerApplicabilty: value.registerApplicabilty,
    //   returnsApplicabilty: value.returnsApplicabilty,
    //   challansApplicabilty: value.challansApplicabilty
    // }
    const url = `${this.apiUrl}/api/User/CreateUpdateRLCS_User_Mapping`;
    return this.http.post(url,value, { observe: 'response' });
  }
  CreateUpdateUser(value: any): Observable<HttpResponse<any>> {
    const body={
      id: value.id,
      firstName: value.firstName,
      lastName: value.lastName,
      email: value.email,
      password: value.password,
      contactNumber: value.contactNumber,
      address: value.address,
      createdOn: value.createdOn,
      isDeleted: value.isDeleted,
      designation: value.designation,
      departmentID: value.departmentID,
      createdBy: value.createdBy,
      createdByText: value.createdByText,
      isActive: value.isActive,
      deactivatedOn:value.deactivatedOn,
      customerID: value.customerID,
      customerBranchID: value.customerBranchID,
      reportingToID: value.reportingToID,
      roleID: value.roleID,
      lastLoginTime: value.lastLoginTime,
      changPasswordDate:value.changPasswordDate,
      changePasswordFlag: value.changePasswordFlag,
      notLongerLoginDate: value.notLongerLoginDate,
      wrongAttempt: value.wrongAttempt,
      isHead: value.isHead,
      auditorID: value.auditorID,
      isAuditHeadOrMgr: value.isAuditHeadOrMgr,
      sendEmail: value.sendEmail,
      imagePath: value.imagePath,
      imageName: value.imageName,
      lawyerFirmID: value.lawyerFirmID,
      isExternal: value.isExternal,
      litigationRoleID: value.litigationRoleID,
      startdate: value.startdate,
      enddate: value.enddate,
      auditStartPeriod: value.auditStartPeriod,
      auditEndPeriod: value.auditEndPeriod,
      contractRoleID: value.contractRoleID,
      userName: value.userName,
      licenseRoleID: value.licenseRoleID,
      pan: value.pan,
      vendorRoleID: value.vendorRoleID,
      desktopRestrict: value.desktopRestrict,
      imeiNumber: value.imeiNumber,
      imeiChecked: value.imeiChecked,
      enType: value.enType,
      mobileAccess: value.mobileAccess,
      secretarialRoleID: value.secretarialRoleID,
      hrRoleID: value.hrRoleID,
      insiderRoleID: value.insiderRoleID,
      ssoAccess: value.ssoAccess,
      cer_OwnerRoleID: value.cer_OwnerRoleID,
      cer_OfficerRoleID: value.cer_OfficerRoleID,
      logOutURL: value.logOutURL,
      createdFrom: value.createdFrom,
      isCertificateVisible: value.isCertificateVisible
    }
    const url = `${this.apiUrl}/api/User/CreateUpdateUser`;
    return this.http.post(url,body, { observe: 'response' });
  }
  CreateUpdate_UserCustomerMapping(value: any): Observable<HttpResponse<any>> {
    const body={
      id: value.id,
      userID: value.userID,
      customerID: value.customerID,
      isActive: value.isActive,
      createdOn: value.createdOn,
      updatedOn: value.updatedOn,
      mgrID: value.mgrID,
      productID: value.productID
    }
    const url = `${this.apiUrl}/api/User/CreateUpdate_UserCustomerMapping`;
    return this.http.post(url,body, { observe: 'response' });
  }
  // CreateUpdate_RLCS_EntityAssignment(value: any): Observable<HttpResponse<any>> {
  //   const body={
  //     userID: 0,
  //     branchIDs: [
  //       0
  //     ],
  //     recordActive: true,
  //     complianceCategories: [
  //       0
  //     ]
  //   }
  //   const url = `${this.apiUrl}/api/User/CreateUpdate_RLCS_EntityAssignment`;
  //   return this.http.post(url,body, { observe: 'response' });
  // }
}
