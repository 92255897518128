import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridModule } from '@progress/kendo-angular-grid';
import { IconsModule } from '@progress/kendo-angular-icons';
import { UserService } from '../../../service/user.service';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { BlobService } from '../../../service/blob.service';
import { WorkspaceService } from '../../../service/workspace.service';
import { NgFor, NgIf } from '@angular/common';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { WorkspaceHistoryPopupComponent } from '../../popups/workspace-history-popup/workspace-history-popup.component';

@Component({
  selector: 'app-document-generation',
  standalone: true,
  imports: [GridModule,
    ButtonsModule,
    IconsModule, NgIf,NgFor, ReactiveFormsModule, IndicatorsModule, AlertPopupComponent,WorkspaceHistoryPopupComponent],
  templateUrl: './document-generation.component.html',
  styleUrl: './document-generation.component.css'
})
export class DocumentGenerationComponent {
  public alertData = { alertMessage: '', alertVisible: '' };
  EmployeeHistoryPopup = false;
  fileNames: string[] = [];
  clientID!: string;
  InputName!: string;
  customerID!: number;
  inputID!: number;
  userID!: number
  IsFileRequired!: number
  DisplayUploadFlag!: number
  IsUploaded!: number
  IsValidated!: number
  isProcessed!: number
  showState = false;
  loading: boolean = false;
  myGroup: FormGroup;
  formValues:any
  json:any
  year:any
  month:any
  input :any
  compliance:any
  inputListData : any[] = [];
  refreshListData : any[] = [];
  generateDocument:any;
  act:any;
  inputIds:any;
  Id:any
  openHistory=false
  constructor(private userService: UserService,private router:Router,private workspace:WorkspaceService, private blobService: BlobService) {
    this.myGroup = new FormGroup({
      Template: new FormControl('')
    });
  }
  ngOnInit(): void {
    this.clientID = this.userService.getClientId();
    this.customerID = this.userService.getCustomerId();
    this.userID = this.userService.getUserId();
    this.json = JSON.parse(sessionStorage.getItem('JsonId') || 'null');
    this.year = sessionStorage.getItem('year'); 
    this.month = sessionStorage.getItem('month'); 
    this.input = JSON.parse(sessionStorage.getItem('inputs') || 'null');
    this.compliance = sessionStorage.getItem('complianceType'); 
    this.act = sessionStorage.getItem('act'); 
    this.getUploadStatus();
    this.fileNames = new Array(this.inputListData.length).fill('');
  }

  onFileChange(event: any, index: number) {
    const file = event.target.files[0];

    if (file) {
        this.fileNames[index] = file.name;
    } else {
        this.fileNames[index] = '';  
    }
}

  getUploadStatus() {
    this.loading = true;
    let payload ={
      
      "userId": this.userID,
      "customerId": this.customerID,
      "entityId": this.clientID,
      "month": this.month,
      "year": this.year,
      "complianceIds": "",
      "jsonId": this.json,
      "complianceType": this.compliance,
      "inputID": 0 
  }
console.log('payload',payload);

    this.workspace.ComplianceInput(payload).subscribe({
      next: (response) => {
        console.log(response);
        
        this.loading = false;
        this.inputListData  = response.body.inputList; 

        this.inputIds = this.inputListData.length === 1
        ? this.inputListData[0].inputId
        : this.inputListData.map(item => item.inputId).join(',');
      
      },
      error: (error) => {
        this.loading = false;
        console.error(error);
      }
    });
  }


  Template(inputID:any) {
    this.loading = true;
    let payload ={
      
      "userId": this.userID,
      "customerId": this.customerID,
      "entityId": this.clientID,
      "month": this.month,
      "year": this.year,
      "jsonId": this.json,
      "inputID": inputID
  }
    this.workspace.GetSampleBasedOnInputID(payload).subscribe(
      {
        next: (response) => {
          this.loading = false;
          const blobLink = response.body.filePath;
          let sasToken = response.body.sasToken
          console
          const blobName = this.blobService.downloadBlobFile(blobLink,sasToken);
        }, error: (error) => {
          console.error('Error fetching blob link:', error);
          this.alertData = { alertMessage: error.error, alertVisible: 'error' };
          this.showState = false;
          this.loading = false;
        }
      });
  }

  openHistoryPopup(InputId:any) {
    this.Id=InputId;
    this.openHistory = true;
  }
  closeHistoryPopup(){
    this.openHistory=false
  }

  uploadFiles(index: number,inputID:any) {
    this.loading = true;
    if (this.fileNames[index]) {
      const fileInput = <HTMLInputElement>document.getElementById('fileInput'+index);
      const file = fileInput?.files?.[0];
      let payload={
        EntityId : this.clientID,
        Month:this.month,
        Year:this.year,
        InputId:inputID,
        JsonId : this.json,
        UserId:this.userID,
        FileName:this.fileNames[index],
        CustomerID:this.customerID 
      }
      if (file) {
        const formData = new FormData();
        formData.append('file', file);

        this.workspace.uploadFile(payload,formData).subscribe(
          {
            next: (response) => {
              this.loading = false;
             let result =  response.body.result
              if (result === 2) {
                this.fileNames[index] = ''; 
                this.alertData = { alertMessage: 'Invalid Headers', alertVisible: 'info' };
              }
              else if(result === 4 ){
                this.fileNames[index] = ''; 
                this.alertData = { alertMessage: 'Invalid CSV', alertVisible: 'info' };
              }
              else if(result === 1 ){
                this.fileNames[index] = ''; 
                this.alertData = { alertMessage: 'File uploaded successfully', alertVisible: 'success' };
              }
               else {
                this.fileNames[index] = ''; 
                this.alertData = { alertMessage: 'Error uploading file', alertVisible: 'error' };
              }
            }, error: (error) => {
              this.fileNames[index] = ''; 
              console.error('Error uploading file:', error);
              this.alertData = { alertMessage: 'Error uploading file', alertVisible: 'error' };
            }
          }
        );
      } else {
        this.alertData = { alertMessage: 'No file selected', alertVisible: 'error' };
        this.loading = false;
      }
    } else {
      this.alertData = { alertMessage: 'No file chosen', alertVisible: 'error' };
      this.loading = false;
    }
  }


  closeAlert() {
    this.alertData = { alertMessage: '', alertVisible: '' };
  }
  goBack(){
    this.router.navigate(['/Workspace'])
  }
  DocumentStatus(){
    this.loading = true;
    let payload ={
      
      "userId": this.userID,
      "customerId": this.customerID,
      "entityId": this.clientID,
      "month": this.month,
      "year": this.year,
      "complianceIds": "",
      "jsonId": this.json,
      "complianceType": this.compliance,
      "inputID": 0 
  }

  this.workspace.GetGenerateDocumentStatus(payload).subscribe({
    next: (response) => {
      this.loading = false;
      this.generateDocument  = response.body.result; 
      this.alertData = { alertMessage: 'Generate document button will be enable if All required input are processed', alertVisible: 'success' };
     
    },
    error: (error) => {
      this.loading = false;
      console.error(error);
    }
  });
  }

  Generate(){

    let payload = {
      "customerid": this.customerID,
      "entityId": this.clientID,
      "userid": this.userID,
      "jsonid":  this.json,
      "documentType": this.compliance,
      "actType": this.act,
      "month": this.month,
      "year": this.year
    }
    this.loading = true;
    
   if(this.compliance == 'Register'){
    this.generateDocumentRegister(payload)
   }else if(this.compliance == 'Challan'){
    this.generateDocumentChallan(payload)
   }else if(this.compliance == 'Return'){
    this.generateDocumentReturn(payload)
    }

  }

  generateDocumentRegister(payload: any) {
    this.workspace.GenerateDocumentRegister(payload).subscribe({
      next: (response) => {
        this.loading = false;
        let result = response?.body?.result;
        if(result == 1){
          this.alertData = { alertMessage: 'System will take sometime to generate documents. It will notify you once get generated, you can download from Statutory Documents.', alertVisible: 'info' };

        }else if(result == 0){
          this.alertData = { alertMessage: 'Document not generated. please contact support', alertVisible: 'info' };
        }
      },
      error: (error) => {
        this.loading = false;
        console.error(error);
      }
    });
  }
  generateDocumentChallan(payload: any) {
    this.workspace.GenerateDocumentChallan(payload).subscribe({
      next: (response) => {
        this.loading = false;
        let result = response?.body?.result;
        if(result == 1){
          this.alertData = { alertMessage: 'System will take sometime to generate documents. It will notify you once get generated, you can download from Statutory Documents.', alertVisible: 'info' };

        }else if(result == 0){
          this.alertData = { alertMessage: 'Document not generated. please contact support', alertVisible: 'info' };
        }
      },
      error: (error) => {
        this.loading = false;
        console.error(error);
      }
    });
  }
  generateDocumentReturn(payload: any) {
    this.workspace.GenerateDocumentReturn(payload).subscribe({
      next: (response) => {
        this.loading = false;
        let result = response?.body?.result;
        if(result == 1){
          this.alertData = { alertMessage: 'System will take sometime to generate documents. It will notify you once get generated, you can download from Statutory Documents.', alertVisible: 'info' };

        }else if(result == 0){
          this.alertData = { alertMessage: 'Document not generated. please contact support', alertVisible: 'info' };
        }
      },
      error: (error) => {
        this.loading = false;
        console.error(error);
      }
    });
  }
  GetUpoadedFilesStatus() {
    this.loading = true;
    let payload ={
      
      "userId": this.userID,
      "customerId": this.customerID,
      "entityId": this.clientID,
      "month": this.month,
      "year": this.year,
      "complianceIds": "",
      "jsonId": this.json,
      "complianceType": this.compliance,
      "inputID": 0
  }
    this.workspace.GetUpoadedFilesStatus(payload).subscribe({
      next: (response) => {
        this.loading = false;
        this.refreshListData  = response.body; 
      
      this.inputListData.forEach((inputItem) => {
        const matchedItem = this.refreshListData.find(
          (refreshItem) => refreshItem.inputID === inputItem.inputId
        );

        if (matchedItem) {
          Object.keys(matchedItem).forEach((key) => {
            const normalizedKey = key.charAt(0).toLowerCase() + key.slice(1);
            if (inputItem.hasOwnProperty(normalizedKey)) {
              inputItem[normalizedKey] = matchedItem[key];
            }
          });
        }
      });
      },
      error: (error) => {
        this.loading = false;
        console.error(error);
      }
    });
  }

  downloadErrorFile(inputID:any){
    let payload = {
      userId: this.userID,
      customerId: this.customerID,
      entityId: this.clientID,
      month: this.month,
      year: this.year,
      complianceIds:'',
      jsonId:  this.json,
      complianceType: this.compliance,
      inputID: inputID,
    }
    this.loading = true;

    this.workspace.GetErrorFile(payload).subscribe(
      {
        next: (response) => {
          this.loading = false;
          const blobLink = response.body.filePath;
          let sasToken = response.body.sasToken
          const blobName = this.blobService.downloadBlobFile(blobLink,sasToken);
          if(response.body.result){

          }
        }, error: (error) => {
          console.error('Error fetching blob link:', error.error.result);
          this.alertData = { alertMessage: error.error.result, alertVisible: 'error' };
          this.loading = false;
        }
      });
  }

}
