import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ClientPaycodeMappingDetails, PaycodeMasterService } from '../../../service/paycode-master.service';
import { UserService } from '../../../service/user.service';
import { GridModule } from '@progress/kendo-angular-grid';
import { FormsModule } from '@angular/forms';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { NgClass, NgIf } from '@angular/common';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';

interface PaycodeItem {
  text: string;
  value: string;
}

@Component({
  selector: 'app-paycode-add-popup',
  standalone: true,
  imports: [GridModule, FormsModule, DropDownListModule, NgIf,NgClass, GridModule, DialogModule, AlertPopupComponent],
  templateUrl: './paycode-add-popup.component.html',
  styleUrls: ['./paycode-add-popup.component.css']
})
export class PaycodeAddPopupComponent {
  @Input() rowData: any;
  @Output() close = new EventEmitter<void>();
  @Output() dataSaved = new EventEmitter<ClientPaycodeMappingDetails>();

  public headername: string = '';
  isFormSubmitted: boolean = false;
  public sequenceOrder: number | null = null;

  public paycodeType: { text: string, value: string }[] = [
    { text: 'Select', value: '' },
    { text: 'Earning', value: 'E' },
    { text: 'Deduction', value: 'D' },
    { text: 'Standard', value: 'S' }
  ];
  public selectedPaycodeType: { text: string, value: string } = { text: 'Select', value: '' };
  public paycode: { text: string, value: string }[] = [{ text: 'Select', value: '' }];;

  public selectedPaycode: { text: string, value: string } = { text: 'Select', value: '' };
  public esic_App!: boolean;
  public pF_App!: boolean;
  public pT_App!: boolean;
  public lwF_App!: boolean;
  alertData = { alertMessage: '', alertVisible: '' };
  
  @ViewChild('dialogTitle') dialogTitle!: ElementRef;

  constructor(private paycodeService: PaycodeMasterService, private user: UserService,private cd: ChangeDetectorRef) { }

  ngAfterViewInit() {
    const titleElement = this.dialogTitle.nativeElement;
    titleElement.style.textAlign = 'center';
    titleElement.style.width = '100%';
  }

  ngOnInit() {
    this.loadPaycodes();
  }

  public closePopup(): void {
    this.close.emit();
  }

  public isStandardPaycodeType(): boolean {
    return this.selectedPaycodeType?.value === 'S';
  }

  public loadPaycodes(): void {
    if (this.selectedPaycodeType.value === 'S') {
      this.paycodeService.getStandardPaycodeDropdown().subscribe({
          next:(response)=>{
            if(response.status===200){
              const data=response.body;
              console.log('body',data);
              if (Array.isArray(data.Result)) {
                        this.paycode = [{ text: 'Select', value: '' }, ...data.map((item: any) => ({
                          text: item.key,
                          value: item.value
                        }))];
                        this.selectedPaycode = this.paycode[0];
                        this.cd.detectChanges();
                      } else {
                        console.error('Invalid data structure received:', data);
                      }
                }
        }}
      )

    } else if (this.selectedPaycodeType.value === '') {
      this.paycode = [{ text: 'Select', value: '' }];
      this.selectedPaycode = this.paycode[0];
      this.cd.detectChanges();
      
    } else {
      this.paycodeService.getPaycodeDropdown(this.selectedPaycodeType.value).subscribe({
        next:(response)=>{
          if(response.status===200){
            const data=response.body;
          this.paycode = [{ text: 'Select', value: '' }, ...data.flat().map((item: any) => ({
              text: item.key,
              value: item.value
            }))];
            this.selectedPaycode = this.paycode[0];
          }
          this.cd.detectChanges();
          }
       
        
      });
            
    }
  }


  public onPaycodeTypeChange(): void {

    this.loadPaycodes();
  }

  public onFilterChange(filter: string): void {
    if (filter) {
      this.paycodeService.getPaycodeDropdown(this.selectedPaycodeType.value).subscribe( {
        next:(response)=>{
          console.log('add popup response',response);
         
            const data=response.body
            if (Array.isArray(data)) {
                  this.paycode = data.flat().map((item: any) => ({
                    text: item.key,
                    value: item.value
                  })).filter((pc: PaycodeItem) => pc.text.toLowerCase().includes(filter.toLowerCase()));
                } else {
                  console.error('Invalid data structure received:', data);
                }          
        }
      });     
    } 
  }


  public onValueChange(value: { text: string, value: string }): void {
    console.log(value);
    
    this.selectedPaycode = value;
  }

  savePaycodeMapping(): void {

    this.isFormSubmitted = true;
    if (!this.headername || !this.selectedPaycodeType || !this.selectedPaycode) {
     
      return;
    }

    const paycodeMapping: ClientPaycodeMappingDetails = {
      cpmd_Id: 0,
      cpmd_Cpm_Id: this.user.getCustomerId(),
      cpmd_ClientID: this.user.getClientId(),
      cpmd_PayCode: this.selectedPaycode?.value || '',
      cpmd_PayGroup: this.isStandardPaycodeType() ? 'standard' : 'paycodes',
      cpmd_Standard_Column: this.isStandardPaycodeType() ? this.selectedPaycode?.value || '' : '',
      cpmd_Header: this.headername,
      column_DataType: 'float',
      cpmd_Sequence_Order: this.sequenceOrder || 0,
      cpmd_Deduction_Type: this.selectedPaycodeType?.value || '',
      cpmd_Status: 'A',
      cpmd_appl_ESI: this.esic_App ? 'Y' : 'N',
      cpmd_Appl_PT: this.pT_App ? 'Y' : 'N',
      cpmd_Appl_LWF: this.lwF_App ? 'Y' : 'N',
      cpmd_appl_PF: this.pF_App ? 'Y' : 'N',
      createdOn: new Date(),
      updatedOn: new Date()
    };
    console.log(paycodeMapping);
    
    this.paycodeService.saveClientPaycodeMappings(paycodeMapping).subscribe({
      next:(response)=>{    
        console.log('Add Response',response);
           
        if(response.body.result==='success'){
          this.alertData = { alertMessage: 'Data Saved Successfully...', alertVisible: 'success' };
          this.dataSaved.emit(paycodeMapping);
        } else {
          this.alertData = { alertMessage: response.body.result || 'Save failed. Please try again.', alertVisible: 'error' };
        }
        
      }
    });
  
  }
}
