import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DesignationMasterService {
  private baseUrl=environment.apiUrl;
  constructor(private http:HttpClient) { }

  getAllForDesignation(status:string): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/DesignationMaster/Get_LongDesignationForDropDown?Filter=${status}`;
    return this.http.get(url,{ observe: 'response' });
  }
  getAllDesignationMasters(designationFilter: string, statusFilter: string, take: number, skip: number, page: number, pageSize: number):Observable<HttpResponse<any>> {
    const url=`${this.baseUrl}/api/DesignationMaster/GetAll_DesignationMaster?DesignationFilter=${designationFilter}&StatusFilter=${statusFilter}&take=${take}&skip=${skip}&page=${page}&pageSize=${pageSize}`;
    return this.http.get<any>(url,{ observe: 'response' });

  }
  getDesignationExcel(): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/DesignationMaster/ExportDesignationMaster`;
    return this.http.get<any>(url, { observe: 'response' });
  }
  addUpdateDesignation(userForm:any):Observable<HttpResponse<any>>{
    const url=`${this.baseUrl}/api/DesignationMaster/AddUpdateDesignation?LongDesignation=${userForm.long_designation}&status=${userForm.status||''}`
    return this.http.get<any>(url,{ observe: 'response' })
  }
  deleteDesignation(id:any):Observable<HttpResponse<any>>{
    const url=`${this.baseUrl}/api/DesignationMaster/DeleteDesignation?Id=${id}`
    return this.http.get<any>(url,{ observe: 'response' })
  }
  

  uploadFile(file: File, isAddOrUpdate: string): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/DesignationMaster/UploadDesignationMaster`;
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('IsAddOrUpdate', isAddOrUpdate);
  
    return this.http.post<any>(url, formData,{ observe: 'response' });
      // 'https://localhost:7111/api/ECR/BulkUploadPFECRFile'
  }
  getDesignationDropdown(value: string): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/DesignationMaster/Get_LongDesignationForDropDown?Filter=${value}`;
    return this.http.get(url,{ observe: 'response' });
  }
}
