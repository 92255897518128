import { Component } from '@angular/core';
import { MultiSelectDropdownComponent } from '../../../../components/multi-select-dropdown/multi-select-dropdown.component';
import { MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { FormsModule } from '@angular/forms';
import { DropdownListComponent } from '../../../../components/dropdown-list/dropdown-list.component';
import { AnchorDashboardSummaryService } from '../../../../service/anchor-dashboard-summary.service';
import { GridActionItemComponent } from '../../../../components/grid-action-item/grid-action-item.component';
import { GridDataResult, GridModule } from '@progress/kendo-angular-grid';
import { UserService } from '../../../../service/user.service';
import { CommonModule, NgClass } from '@angular/common';
import { PageChangeEvent } from '@progress/kendo-angular-treelist';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-anchor-dashboard-summaries',
  standalone: true,
  imports: [MultiSelectDropdownComponent,MultiSelectModule,FormsModule,DropdownListComponent,GridActionItemComponent,GridModule,CommonModule,IndicatorsModule],
  templateUrl: './anchor-dashboard-summaries.component.html',
  styleUrl: './anchor-dashboard-summaries.component.css'
})
export class AnchorDashboardSummariesComponent {
  public gridData: GridDataResult = { data: [], total: 0 };
  complianceType: { value: string; text: string }[] = [];
  selectedComplianceType: any[] = [];
  years: Array<{ text: any; value: any }> = [
    { text: 'Year', value: 'Year' }, // Default value
  ];
  period: Array<{ text: any; value: any }> = [
    { text: 'Period', value: 'Period' },
  ];
  selectedYear: { text: any; value: any } = { text: 'Year', value: 'Year' };
  selectedPeriod: { text: any; value: any } = {
    text: 'Period',
    value: 'Period',
  };
  public buttonCount = 2;
  public sizes = [{text:'All',value:1},5, 10, 20];
  public pageSize: number = 10;
  public skip: number = 0;
  loading=false;
  selectedComplianceTypesText: string = '';
  constructor(private anchorService:AnchorDashboardSummaryService,private user:UserService){}

  ngOnInit(): void {
    this.getCompliance()
    this.getYears()
    this.getPeriods()
  }
  public tagMapper(tags: any[]): any[] {
    return tags.length < 1 ? tags : [tags];
  }
  public pageChange(event: PageChangeEvent): void {
    this.loading=true;
    this.skip = event.skip;
    if(event.take==1){
      this.pageSize = this.gridData.total;
    }else{
      this.pageSize = event.take;
    }
    this.getGridData();
  }
  getCompliance(): void {
    this.anchorService.getCompliance('compliancename').subscribe({
      next: (response) => {
        const data = response.body;
        if (data && Array.isArray(data)) {
         this.complianceType = [
            { value: 'all', text: 'Select All' },
            ...data.map((item: any) => ({
              value: item.value,
              text: item.text
            }))
          ];
          console.log('Compliance types:', this.complianceType);
        }
      },
      error: (err) => {
        console.error('Failed to fetch compliance data:', err);
      }
    });
  }
  onComplianceTypeChange(selectedValues: any[]): void {
    if (selectedValues.includes('all')) {
      if (this.selectedComplianceType.length === this.complianceType.length) {
        this.selectedComplianceType = [];
      } else {
        this.selectedComplianceType = this.complianceType
          .filter(item => item.value !== 'all')
          .map(item => item.value);
      }
    } else {
      this.selectedComplianceType = selectedValues;
    }

    this.selectedComplianceTypesText = this.selectedComplianceType
      .map(value => this.complianceType.find(item => item.value === value)?.text)
      .filter(text => text)
      .join(',');

    console.log('Selected Compliance Types:', this.selectedComplianceTypesText);
  }

  getYears(): void {
    const currentYear = new Date().getFullYear();
    this.years = [{ text: 'Year', value: 'Year' }];
    for (let year = currentYear; year >= 2017; year--) {
      this.years.push({ text: year.toString(), value: year });
    }
  }

  onYearChange(value: any): void {
    this.selectedYear = { text: value.text, value: value.value };
    
  }

  getPeriods(): void {
    this.anchorService.getCompliance('Months').subscribe({
      next: (response) => {
        const data = response.body;
        const fetchedPeriod = data.map((item: any) => ({
          text: item.text,
          value: item.value,
        }));
        this.period = [{ text: 'Period', value: 'Period' }, ...fetchedPeriod];
        if (this.selectedPeriod != null) {
          this.selectedPeriod = this.period.filter(
            (p) => p.value == this.selectedPeriod.value
          )[0];
        }
      },
    });
  }

  onPeriodChange(value: any): void {
    this.selectedPeriod = { text: value.text, value: value.value };
    
  }

  getGridData() {
    this.loading=true
    const payload = {
      UserID: this.user.getUserId(),
      ComType: this.selectedComplianceTypesText,
      Month: this.selectedPeriod.value,
      Year: this.selectedYear.value
    };

    this.anchorService.getDetails(payload).subscribe({
      next: (response) => {
        console.log('grid response', response);

        const data = response.body.result || [];
        this.gridData = {
          data: data.slice(this.skip, this.skip + this.pageSize),
          total: data.length  
        };
        this.loading=false
      },
      error: (error) => {
        console.error('Error fetching grid data', error);
        this.loading=false
      }
    });
  }

  applyFilter(){
    this.getGridData()
  }

  // public parentColumns: any[] = [
  //   {field: 'customerName',title: 'Customer Name'},
  //   { field: 'clientId', title: 'Client ID' },
  //   { field: 'entityName', title: 'Entity Name' },
  //   { field: 'totalNoInputs', title: 'Total No of Inputs(Count)' },
  //   { field: 'totalNoUploaded', title: 'No of Inputs uploaded (Count)' },
  //   { field: 'totalInputReceivedStatus', title: 'No of Inputs uploaded (Count)' },
  //   {
  //     field: 'totalInputReceivedStatus',
  //     title: 'Status',
  //     template: (dataItem: any) => {
  //         return dataItem.totalInputReceivedStatus== 1 ? 
  //             `<div style="background-color: green; color: white; width: 30px; height: 30px; border-radius: 50%; margin-left:15px">&nbsp;</div>` : 
  //             `<div style="background-color: red; color: white; width: 30px; height: 30px; border-radius: 50%; margin-left:15px">&nbsp;</div>`;
  //     }
  // }
  // ];

}