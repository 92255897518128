<div class="row colpadding0" style="margin-top: 10px;">
    <div style="display:flex;">

        <kendo-multiselecttree #multiselectTree placeholder="Entity/Sub-Entity/Location" kendoMultiSelectTreeExpandable
            [kendoMultiSelectTreeHierarchyBinding]="BranchDropdown" childrenField="children" textField="name"
            valueField="id" [tagMapper]="tagMapper" (valueChange)="onMultiSelectChange($event)" [expandedKeys]="['0']"
            class="f-textbox " style="width:220px">
        </kendo-multiselecttree>

        <app-multi-select-dropdown [data]="years" [selectedValues]="selectedYear" [width]="'120px'" textField="text"
            style="margin-left:10px" valueField="value" (valueChange)="onYearChange($event)" formControlName="year"
            [placeholder]="'Year'">
        </app-multi-select-dropdown>






        <!-- <app-dropdown-list [data]="complianceType" [selectedValue]="selectedComplianceType || 'Status'"
        [width]="'200px'" textField="text" style="margin-left: 10px;" valueField="value"
        (valueChange)="oncomplianceTypeChange($event)"
        [class.input-invalid]="!selectedComplianceType && showValidationErrors">
        <div *ngIf="!selectedComplianceType && showValidationErrors" class="validation-error">

        </div>
    </app-dropdown-list> -->
        <app-multi-select-dropdown *ngIf="isComplianceLoaded" [data]="complianceType"
            [selectedValues]="selectedComplianceType" [width]="'150px'" textField="text" style="margin-left:10px;"
            valueField="value" (valueChange)="oncomplianceTypeChange($event)" [placeholder]="'Status'"
            [class.input-invalid]="!selectedComplianceType && showValidationErrors">
            <div *ngIf="!selectedComplianceType && showValidationErrors" class="validation-error">

            </div>
        </app-multi-select-dropdown>

        <app-dropdown-list [data]="complianceTypes" [selectedValue]="selectedComplianceTypes" [width]="'120px'"
            textField="text" style="margin-left: 10px;" valueField="value" (valueChange)="onMonthChange($event)"
            [class.input-invalid]="!selectedComplianceType && showValidationErrors">
            <div *ngIf="!selectedComplianceType && showValidationErrors" class="validation-error">

            </div>
        </app-dropdown-list>

        <!-- <app-dropdown-list [data]="dropdownDataMonth" [selectedValue]="defaultSelectedValueMonth" [width]="'200px'"
            style="margin-left:10px;" [height]="'34px'" textField="displayvalue" valueField="value"
            (valueChange)="onPeriod($event)">
        </app-dropdown-list> -->
        <!-- <app-multi-select-dropdown *ngIf="isPeriodLoaded" [data]="dropdownDataMonth"
            [selectedValues]="defaultSelectedValueMonth" [width]="'200px'" style="margin-left:10px;" [height]="'34px'"
            textField="displayvalue" valueField="value" [placeholder]="'Period'" (valueChange)="onPeriod($event)">
        </app-multi-select-dropdown> -->

        
        <app-multi-select-dropdown *ngIf="isPeriodLoaded" [data]="complianceperiods"
            [selectedValues]="defaultSelectedValueMonth" [width]="'120px'" style="margin-left:10px;" [height]="'34px'"
            textField="displayvalue" valueField="value" [placeholder]="'Period'" (valueChange)="onPeriod($event)">
        </app-multi-select-dropdown>


        <button class="figma-btn-white clear-btn" (click)="clearFilter(multiselectTree)">
            <img src="../assets/vectors/ClearFilter.svg" alt="">
        </button>


        <button class="figma-btn-white apply-btn" type="submit" (click)="applyFilters()">
            <img src="../assets/vectors/ApplyFilter.svg" alt="">
        </button>

        <button class="exportbtn" (click)="exportToExcel()">
            <img src="../assets/vectors/ExportButton.svg" alt="">

        </button>
    </div>

    <!-- 
        <app-dropdown-list [data]="years" [selectedValue]="selectedYear" [width]="'200px'" textField="text"
            style="margin-left:10px;" valueField="value" (valueChange)="onYearChange($event)" formControlName="year">
        </app-dropdown-list> -->
    <!-- <div>





        <div style="display:flex;justify-content: end;gap:10px;margin-top:30px;">

            <button class="figma-btn-white clear-btn" (click)="clearFilter(multiselectTree)" style="margin-right:10px;">
                <img src="../assets/vectors/ClearFilter.svg" alt="">
            </button>


            <button class="figma-btn-white apply-btn" type="submit" (click)="applyFilters()">
                <img src="../assets/vectors/ApplyFilter.svg" alt="">
            </button>

            <button class="figma-btn-blue" style="width: 90px;" (click)="exportToExcel()">
                <img src="../assets/vectors/ExportButton.svg" alt="">Export

            </button>
        </div>
    </div> -->
</div>
<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>
<div *ngIf="isActive" class="col-md-12 colpadding0 " style="margin-top:10px;" class="custom-grid">
    <app-grid [gridData]="gridData" [columns]="columns" class="ellipsis-grid"></app-grid>
</div>