import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { KendoDatepickerComponent } from '../../../components/kendo-datepicker/kendo-datepicker.component';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { ManageEmployeeService } from '../../../service/manage-employee.service';
import { EsicreportService } from '../../../service/esicreport.service';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { BranchTransferPopupComponent } from '../branch-transfer-popup/branch-transfer-popup.component';
import { UserService } from '../../../service/user.service';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-add-edit-employee-master-popup',
  standalone: true,
  imports: [NgClass, DialogModule, AlertPopupComponent, NgIf, ReactiveFormsModule, FormsModule, KendoDatepickerComponent, DropdownListComponent, ComboBoxComponent, BranchTransferPopupComponent, IndicatorsModule],
  templateUrl: './add-edit-employee-master-popup.component.html',
  styleUrl: './add-edit-employee-master-popup.component.css'
})
export class AddEditEmployeeMasterPopupComponent implements OnInit {
  EmployeeOption: string = 'Personal';
  alertData = { alertMessage: '', alertVisible: '' };
  @Output() close = new EventEmitter<void>();
  @Input() data: any;
  @Input() EmpID = 'ID99998';
  Data: any[] = [];
  BranchData: any[] = [];
  BranchTeansferPopup = false;
  showVPFDropdown = false;
  clientId = '';
  customerId !: number;
  CreatedDate = ''
  userID!: number;
  PersonalValid = false;
  EmployeeValid = false;
  isUpdate = 0;
  validationpopup!: boolean;
  employeeName: string = '';
  fatherHusbandName: string = '';
  gender = { text: 'Select', value: '' };
  relationship = { text: 'Select Relationship', value: '' };
  dateOfBirth: Date | null = null;
  address: string = '';
  maritalStatus = { text: 'Select', value: '' };
  email: string = '';
  mobileNo: string = '';
  nationality = { text: 'Select', value: '' };
  pan: string = '';
  aadhar: string = '';
  physicallyChallenged = { text: 'Select', value: '' };
  permanentAddress: string = '';
  passportNo: string = '';
  passportIssued = { text: 'Select', value: '' };
  passportValidFrom: Date | null = null;
  passportValidTo: Date | null = null;
  identificationMark: string = '';
  emergencyContactAddress: string = '';
  emergencyContactNum: string = '';
  employeeID: string = '';
  employmentType = { text: 'Select', value: '' };
  Entity_Branch = { name: 'Select', id: 0 };
  PT_State = { text: 'Select', value: '' };
  SkillCategory = { text: 'Select', value: '' };
  JoiningDate: Date | null = null;
  LeavingDate: Date | null = null;
  InternationalWorker = { text: 'Select', value: '' };
  EffectiveChange: Date | null = null;
  IFSC: string = '';
  PFNumber!: string;
  UAN!: string;
  BankName: string = '';
  BankAC: string = '';
  ESICNumber!: string;
  Department: string = '';
  Designation = { name: 'Select', id: '' };
  Status = { text: 'Select', value: '' };
  CourageYear = { displayvalue: 'Select', value: '' };
  EPFOBank = { text: 'Select', value: '' };
  EPFOAddress = { text: 'Select', value: '' };
  PFCapping = { text: 'Select', value: '' };
  ESI = { text: 'Select', value: '' };
  CourageMonth = { displayvalue: 'Select', value: '' };
  PMRPY = { text: 'Select', value: '' };
  PT = { text: 'Select', value: '' };
  Employment = { text: 'Select', value: '' };
  PF = { text: 'Select', value: 'select' };
  PayrollMonth = { displayvalue: 'Select', value: '' };
  LWF = { text: 'Select', value: '' };
  PayrollYear = { displayvalue: 'Select', value: '' };
  TrainingNo: string = '';
  TrainingDate: Date | null = null;
  EPFOPAN = { text: 'Select', value: '' };
  Exempted = { text: 'Select', value: '' };
  VPF = { text: 'Select', value: '' };
  VPFType = { text: 'Select', value: '' };
  VPFValue!: number;
  Sector = { text: 'Select', value: 0 };
  JobCategory = { text: 'Select', value: 0 };
  IndustryType = { text: 'Select', value: 0 };
  Placeofwork = { text: 'Select', value: '' };
  Remarks: string = '';
  EducationLevel: string = '';
  PlaceofEmployment: string = '';
  NoOfCertificate: string = '';
  CertificateDate: Date | null = null;
  TokenNo: string = '';
  RelayAssigned: string = '';
  LetterGroup: string = '';
  WomenWorking = { text: 'Select', value: '' };
  TransportMode = { text: 'Select', value: '' };
  SecurityProvided = { text: 'Select', value: '' };
  Experience: string = '';
  DateofClothes: Date | null = null;
  DateOfExempting: Date | null = null;
  ParticularsOfTransfer: string = '';
  SalesPromotion = { text: 'Select', value: '' };
  PaymentMode: string = '';
  EPSApplicability = { text: 'Select', value: '' };
  ClientESINo: string = '';
  ClientPTState = { id: 'Select', name: '' };
  AadharSeeding: string = '';
  EmployeeType = { text: 'Select', value: '' };
  form!: FormGroup;
  formEm!: FormGroup;
  formSave!: FormGroup;
  EmpIDExist !: boolean;
  UANExist  !: boolean;
  ESICExist  !: boolean;
  PFNumberExist  !: boolean;
  loading = false;
  showAadhaar: boolean = false;
  private originalAadhaar: string = '';
  public showPan: boolean = false;
  private originalPan: string = '';
  public showPassport: boolean = false;
  private originalPassport: string = '';
  private originalESIC: string = '';
  public showESICNumber: boolean = false;
  public showUAN: boolean = false;
  public showPFNumber: boolean = false;
  private originalUAN: string = '';
  private originalPFNumber: string = '';
  public placeholder: string = 'DD-MM-YYYY';
  todayDate: Date = new Date();
  public showValidationErrorsPAN = false;
  public showValidationErrorsAadhaar = false;
  public showValidationErrors = false;
  public showValidationErrorsEmp = false;
  public showValidationErrorsOther = false;
  public defaultFilterValue = { text: 'Select', value: '' };
  public defaultSelectedValueMonth = { value: 0, displayvalue: 'Select Month' };
  public defaultSelectedValueYear = { value: 0, displayvalue: 'Select Year' };

  GenderdropdownData = [
    { text: 'Select', value: '' },
    { text: 'Male', value: 'M' },
    { text: 'Female', value: 'F' }
  ];
  relationshipdropdownData = [
    { text: 'Select', value: '' },
    { text: 'Father', value: 'Father' },
    { text: 'Husband', value: 'Husband' }
  ];
  maritalStatusdropdownData = [
    { text: 'Select', value: '' },
    { text: 'Married', value: 'M' },
    { text: 'Unmarried', value: 'U' }
  ];
  employeeTypedropdownData = [
    { text: 'Select', value: '' },
    { text: 'Contract', value: 'C' },
    { text: 'Permanent', value: 'P' }
  ];
  SkillCategorydropdownData = [
    { text: 'Select', value: '' },
    { text: 'Highly Skilled', value: 'HIGHLY SKILLED' },
    { text: 'Skilled', value: 'SKILLED' },
    { text: 'Semiskilled', value: 'SEMISKILLED' },
    { text: 'Unskilled', value: 'UNSKILLED' }
  ];

  StatusdropdownData = [
    { text: 'Select', value: '' },
    { text: 'Active', value: 'A' },
    { text: 'InActive', value: 'I' }
  ];
  EmploymentdropdownData = [
    { text: 'Select', value: '' },
    { text: 'First Time', value: 'F' },
    { text: 'Second Time', value: 'S' }
  ];
  YesNodropdownData = [
    { text: 'Select', value: '' },
    { text: 'Yes', value: 'Y' },
    { text: 'No', value: 'N' }
  ];
  LWFdropdownData = [
    { text: 'Select', value: '' },
    { text: 'Yes', value: 'Y' },
    { text: 'No', value: 'N' }
  ];
  VPFdropdownData = [
    { text: 'Select', value: '' },
    { text: 'Inputed Percentage', value: 'Inputed Percentage' },
    { text: 'Fixed', value: 'Fixed' }
  ];
  PlaceofworkdropdownData = [
    { text: 'Select', value: '' },
    { text: 'Underground', value: 'Underground' },
    { text: 'Open cast', value: 'Open cast' },
    { text: 'Surface', value: 'Surface' }
  ];
  EmployeeTypedropdownData = [
    { text: 'Select', value: '' },
    { text: 'Core', value: 'Core' },
    { text: 'Contactor', value: 'Contactor' }
  ];
  TransportModedropdownData = [
    { text: 'Select', value: '' },
    { text: 'Cab', value: 'CAB' },
    { text: 'Transport Facility', value: 'TRANSPORT FACILITY' }
  ];


  public countryDropdownData: any[] = [];
  public Entity_BranchDropdownData: any[] = [];
  public PT_StateDropdownData: any[] = [];
  public ClientPTStateDropdownData: any[] = [];
  public DesignationDropdownData: any[] = [];
  public Yeardropdown: any[] = [];
  public Monthdropdown: any[] = [];
  public Sectordropdown: any[] = [];
  public JobCategorydropdown: any[] = [];
  public IndustryTypedropdown: any[] = [];
  constructor(private fb: FormBuilder, private addEditEmployeeService: ManageEmployeeService, private esicreportService: EsicreportService, private user: UserService) {

  }
  ngOnInit(): void {
    this.loading = true;
    this.clientId = this.user.getClientId();
    this.customerId = this.user.getCustomerId();
    this.userID = this.user.getUserId();
    this.FilterDropdown('0');
    this.CountryDropdown();
    this.loadMonthYearData();
    this.PTStateDropdown();
    this.SectorDropdown('');
    this.JobCategoryDropdown('', 0);
    this.IndustryTypeDropdown('', 0, 0);
    this.EnitiyBranchDropdown(this.clientId);
    if (this.EmpID) {
      this.isUpdate = 1;
      this.InitData(this.clientId, this.EmpID);
    } else {
      this.isUpdate = 0;
      this.PredefinedData('');
    }

    this.form = this.fb.group({
      employeeName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]],
      fatherHusbandName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]],
      email: ['', [Validators.pattern('^[a-zA-Z0-9.]+@[a-zA-Z0-9.]+\\.[a-zA-Z]{2,}$')]],
      mobileNo: ['', [Validators.pattern(/^\d{10}$/)]],
      pan: ['', [Validators.pattern(/^[A-Z0-9]{10}$/)]],
      aadhar: ['', [Validators.pattern(/^\d{12,14}$|^\d{4}-\d{4}-\d{4}$/)]],
      passportNo: ['', [Validators.pattern(/^[A-Z0-9]{10}$/)]],
      emergencyContactNum: ['', [Validators.pattern(/^[0-9]{10}$/)]]
    });
    this.formEm = this.fb.group({
      employeeID: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9\-]*$/)]],
      IFSC: ['', [Validators.minLength(11), Validators.maxLength(11), Validators.pattern(/^[a-zA-Z0-9]{11}$/)]],
      PFNumber: ['', [Validators.minLength(22), Validators.maxLength(22), Validators.pattern(/^[a-zA-Z0-9]{22}$/)]],
      UAN: ['', [ Validators.minLength(12), Validators.maxLength(12)]],
      BankName: ['', [Validators.pattern(/^[a-zA-Z\s]*$/)]],
      BankAC: ['', [Validators.pattern(/^\d{11,16}$/)]],
      ESICNumber: ['', [Validators.minLength(17), Validators.maxLength(17)]],
      Department: ['', [Validators.pattern(/^[a-zA-Z\s]*$/)]],
      TrainingNo: [''],
    });
    this.formSave = this.fb.group({
      EducationLevel: ['', [Validators.pattern('^[a-zA-Z\s]*$')]],
      PlaceofEmployment: ['', [Validators.pattern('^[a-zA-Z\\s]*$')]],
      NoOfCertificate: ['', [Validators.pattern('^[0-9]*$')]],
      TokenNo: ['', [Validators.pattern('^[0-9]*$')]],
      RelayAssigned: ['', [Validators.pattern('^[a-zA-Z]*$')]],
      LetterGroup: [''],
      Experience: ['', [Validators.maxLength(2), Validators.pattern('^[0-9]{1,2}$')]],
      ParticularsOfTransfer: ['', [Validators.pattern('^[a-zA-Z\\s]*$')]],
      PaymentMode: ['', [Validators.pattern('^[a-zA-Z]*$')]],
      ClientESINo: ['', [Validators.minLength(17), Validators.maxLength(17), Validators.pattern('^[0-9]*$')]],

    });
    this.form.get('aadhar')?.valueChanges.subscribe(value => {
      if (!this.showAadhaar) {
        this.formatAadhaar(value);
      }
    });
    this.loading = false;
  }
  restrictInput(event: KeyboardEvent): void {
    const regex = /^[a-zA-Z\s]*$/;
    const key = String.fromCharCode(event.charCode); 

    if (!regex.test(key)) {
      event.preventDefault(); 
    }
  }

  validateMobileNo(event: any): void {
    const input = event.target.value;
    event.target.value = input.replace(/[^0-9]/g, '');
    this.form.get('mobileNo')?.setValue(event.target.value);
  }
  preventInvalidChars(event: KeyboardEvent) {
    const pattern = /^[a-zA-Z\s]*$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  validateNumericInput(event: KeyboardEvent): void {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  }
  validateInput(): void {
    const tokenNoControl = this.formSave.get('TokenNo');
    if (tokenNoControl) {
      const value = tokenNoControl.value;
      const numericPattern = /^[0-9]*$/;
      if (!numericPattern.test(value)) {
        tokenNoControl.setErrors({ pattern: true });
      } else {
        if (tokenNoControl.hasError('pattern')) {
          tokenNoControl.setErrors(null);
        }
      }
    }
  }
  formatBankAC(event: KeyboardEvent): void {

    const char = String.fromCharCode(event.which);

    if (!/[0-9]/.test(char)) {

      event.preventDefault();

    }

  }
  validateemergencyContactNum(event: any): void {
    const input = event.target.value;
    event.target.value = input.replace(/[^0-9]/g, '');
    this.form.get('emergencyContactNum')?.setValue(event.target.value);
  }

  PredefinedData(value: any) {
    console.log('predefine data', value);

    this.form = this.fb.group({
      employeeName: [value.eM_EmpName || '', [Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]],
      fatherHusbandName: [value.eM_FatherName || '', [Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]],
      email: [value.eM_Emailid || '', [Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]],
      mobileNo: [value.eM_MobileNo || '', [Validators.pattern(/^[0-9]{10}$/)]],
      pan: [this.initializePan(value.eM_PAN) || '', [Validators.pattern(/^[A-Z0-9]{10}$/)]],
      aadhar: [this.initializeAadhaar(value.eM_Aadhar) || '', [Validators.pattern(/^\d{12,14}$|^\d{4}-\d{4}-\d{4}$/)]],
      passportNo: [this.initializePassport(value.eM_PassportNo) || '', [Validators.pattern(/^[A-Z0-9]{10}$/)]],
      emergencyContactNum: [value.eM_Emergency_contact_mobile_no || '', [Validators.pattern(/^[0-9]{10}$/)]]
    });
    this.formEm = this.fb.group({
      employeeID: [value.eM_EmpID || '', [Validators.required, Validators.pattern(/^[a-zA-Z0-9\-]*$/)]],
      IFSC: [value.eM_IFSC || '', [Validators.minLength(11), Validators.maxLength(11), Validators.pattern(/^[a-zA-Z0-9]{11}$/)]],
      PFNumber: [this.initializePFNumber(value.eM_PFNO) || '', [Validators.minLength(22), Validators.maxLength(22), Validators.pattern(/^[a-zA-Z0-9]{22}$/)]],
      UAN: [this.initializeUAN(value.eM_UAN) || '',[ Validators.minLength(12), Validators.maxLength(12)]],
      BankName: [value.eM_BankName || '', [Validators.pattern(/^[a-zA-Z\s]*$/)]],
      BankAC: [value.eM_Bankaccountnumber || '', [Validators.pattern(/^\d{11,16}$/)]],
      ESICNumber: [this.initializeESICNumber(value.eM_ESICNO) || '', [Validators.minLength(17), Validators.maxLength(17)]],
      Department: [value.eM_Department || '', [Validators.pattern(/^[a-zA-Z\s]*$/)]],
      TrainingNo: [value.eM_Training_Number || '']
    });
    this.formSave = this.fb.group({
      EducationLevel: [value.eM_EductionLevel || '', [Validators.pattern('^[a-zA-Z\s]*$')]],
      PlaceofEmployment: [value.eM_Place_of_Employment || '', [Validators.pattern('^[a-zA-Z\\s]*$')]],
      NoOfCertificate: [value.eM_NoOf_Certificate || '', [Validators.pattern('^[0-9]*$')]],
      TokenNo: [value.eM_TokenNo || '', [Validators.pattern('^[0-9]*$')]],
      RelayAssigned: [value.eM_Relay_Assigned || '', [Validators.pattern('^[a-zA-Z]*$')]],
      LetterGroup: [value.eM_Letter_Of_Group || ''],
      Experience: [value.eM_YearsOfExperience || '', [Validators.maxLength(2), Validators.pattern('^[0-9]{1,2}$')]],
      ParticularsOfTransfer: [value.eM_ParticularsOfTransferFromOneGroupToAnother || '', [Validators.pattern('^[a-zA-Z\\s]*$')]],
      PaymentMode: [value.eM_PaymentMode || '', [Validators.pattern('^[a-zA-Z]*$')]],
      ClientESINo: [value.eM_Client_ESI_Number || '', [Validators.pattern('^[0-9]{1,17}$')]]
    });
    this.Entity_Branch = { name: value.eM_Branch || '', id: value.avacoM_BranchID || '' };
    this.employeeID = value.eM_EmpID;
    this.employeeName = value.eM_EmpName;
    this.PT_State = { text: '', value: value.eM_PT_State || '' };
    this.gender = { text: '', value: value.eM_Gender || '' };
    this.fatherHusbandName = value.eM_FatherName;
    this.relationship = { text: '', value: value.eM_Relationship || '' };
    this.dateOfBirth = value.eM_DOB ? new Date(value.eM_DOB) : null;
    this.maritalStatus = { text: '', value: value.eM_MaritalStatus || '' };
    this.JoiningDate = value.eM_DOJ ? new Date(value.eM_DOJ) : null;
    this.LeavingDate = value.eM_DOL ? new Date(value.eM_DOL) : null;
    if (this.dateOfBirth) {
      this.validationpopup = true;
    }
    this.PFNumber = value.eM_PFNO;
    this.ESICNumber = value.eM_ESICNO;
    this.UAN = value.eM_UAN;
    this.email = value.eM_Emailid;
    this.mobileNo = value.eM_MobileNo;
    this.pan = value.eM_PAN;
    if (value?.eM_PAN) {
      const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

      if (!panRegex.test(value.eM_PAN)) {
        this.form.get('pan')?.setErrors({ pattern: true });
        console.log('invalid');

        this.showValidationErrorsPAN = true;
      } else {
        this.form.get('pan')?.setErrors(null);
        this.showValidationErrorsPAN = false;
      }
    }
    this.BankName = value.eM_BankName;
    this.aadhar = value.eM_Aadhar;
    if (value?.eM_Aadhar) {
      if (value.eM_Aadhar.length != 12) {
        this.showValidationErrorsAadhaar = true;
      }

    }
    this.Department = value.eM_Department;
    this.FilterDropdown(value.eM_Designation);
    this.Designation = { name: '', id: value.eM_Designation || '' };
    // this.Designation = this.DesignationDropdownData.find(type => type.id === value.eM_Designation) || { id: '', name: '' };
    console.log(this.Designation);
    
    this.SkillCategory = {
      text: '',
      value: (value.eM_SkillCategory || '').toUpperCase()
    };
    this.BankAC = value.eM_Bankaccountnumber;
    this.IFSC = value.eM_IFSC;
    this.address = value.eM_Address;
    this.passportNo = value.eM_PassportNo || '';
    this.physicallyChallenged = { text: '', value: value.eM_PhysicallyChallenged || '' };
    this.Employment = { text: '', value: value.eM_Firsttime_secondtime || '' };
    this.InternationalWorker = { text: '', value: value.eM_International_workers || '' };
    this.PF = { text: '', value: value.eM_PF_Capping_Applicability || '' };
    this.employmentType = { text: '', value: value.eM_Employmenttype || '' };
    this.EffectiveChange = value.eM_ChangeEffective_from ? new Date(value.eM_ChangeEffective_from) : null;
    this.Status = { text: '', value: value.eM_Status || '' };
    // this.nationality = { text: '', value: value.eM_Nationality || '' };
    this.nationality = this.countryDropdownData.find(type => type.value === value.eM_Nationality) || { value: '', text: '' };
    this.passportValidFrom = value.eM_Passport_Valid_From ? new Date(value.eM_Passport_Valid_From) : null;
    this.passportValidTo = value.eM_Passport_Valid_Upto ? new Date(value.eM_Passport_Valid_Upto) : null;
    this.PayrollMonth = {
      displayvalue: '',
      value: value.eM_PayrollMonth != null ? (value.eM_PayrollMonth < 10 ? '0' + value.eM_PayrollMonth.toString() : value.eM_PayrollMonth.toString()) : 0
    };
    this.PayrollYear = {
      displayvalue: '',
      value: value.eM_PayrollMonth != null ? (value.eM_PayrollYear < 10 ? 'Select' + value.eM_PayrollYear.toString() : value.eM_PayrollYear.toString()) : ''
    };
    this.PFCapping = { text: '', value: value.eM_PF_Applicability || '' };
    this.ESI = { text: '', value: value.eM_ESI_Applicability || '' };
    this.CourageMonth = {
      displayvalue: '',
      value: value.eM_ESI_Out_of_Courage_Month != null ? (value.eM_ESI_Out_of_Courage_Month < 10 ? '0' + value.eM_ESI_Out_of_Courage_Month.toString() : value.eM_ESI_Out_of_Courage_Month.toString()) : 0
    };
    this.CourageYear = {
      displayvalue: '',
      value: value.eM_ESI_Out_of_Courage_Year != null ? (value.eM_ESI_Out_of_Courage_Year < 10 ? '' + value.eM_ESI_Out_of_Courage_Year.toString() : value.eM_ESI_Out_of_Courage_Year.toString()) : ''
    };
    this.passportIssued = this.countryDropdownData.find(type => type.value === value.eM_PassportIssued_Country) || { value: '', text: '' };
    // this.passportIssued = { text: '', value: value.eM_PassportIssued_Country || '' };
    this.EPFOAddress = { text: '', value: value.eM_EPFO_Aadhar_Upload || '' };
    this.EPFOBank = { text: '', value: value.eM_EPFO_Bank_Ac_Upload || '' };
    this.EPFOPAN = { text: '', value: value.eM_EPFO_PAN_Upload || '' };
    this.PMRPY = { text: '', value: value.eM_PMRPY || '' };
    this.PT = { text: '', value: value.eM_PT_Applicability || '' };
    this.NoOfCertificate = value.eM_NoOf_Certificate || '';
    this.CertificateDate = value.eM_NoOf_Certificate_Date ? new Date(value.eM_NoOf_Certificate_Date) : null;
    this.TokenNo = value.eM_TokenNo || '';
    this.RelayAssigned = value.eM_Relay_Assigned || '';
    this.LetterGroup = value.eM_Letter_Of_Group || '';
    this.WomenWorking = { text: '', value: value.eM_WomenWorkingNightshift || '' };
    this.TransportMode = { text: '', value: value.eM_ModeofTransport || '' };
    this.SecurityProvided = { text: '', value: value.eM_SecurityProvided || '' };
    this.Experience = value.eM_YearsOfExperience || '';
    this.DateofClothes = value.eM_DateWhenClothesGiven ? new Date(value.eM_DateWhenClothesGiven) : null;
    this.DateOfExempting = value.eM_NumberandDateOfExemptingOrder ? new Date(value.eM_NumberandDateOfExemptingOrder) : null;
    this.ParticularsOfTransfer = value.eM_ParticularsOfTransferFromOneGroupToAnother || '';
    this.SalesPromotion = { text: '', value: value.eM_SalesPromotion || '' };
    this.PaymentMode = value.eM_PaymentMode || '';
    this.permanentAddress = value.eM_PermanentAddress || '';
    this.identificationMark = value.eM_Markof_Identification || '';
    this.Placeofwork = { text: '', value: value.eM_Placeof_work || '' };
    this.Remarks = value.eM_Remarks || '';
    this.EducationLevel = value.eM_EductionLevel || '';
    this.PlaceofEmployment = value.eM_Place_of_Employment || '';
    this.TrainingNo = value.eM_Training_Number || '';
    this.TrainingDate = value.eM_Training_Date ? new Date(value.eM_Training_Date) : null;
    this.emergencyContactAddress = value.eM_Emergency_contact_Address || '';
    this.emergencyContactNum = value.eM_Emergency_contact_mobile_no?.toString() || '';
    this.EPSApplicability = { text: '', value: value.eM_EPS_Applicabilty || '' };
    this.ClientESINo = value.eM_Client_ESI_Number || '';
    this.ClientPTState = { name: '', id: value.eM_Client_PT_State || '' };
    if (value.eM_IsLwf_Exempted === true) {
      this.LWF = { text: '', value: 'Y' };
    } else if (value.eM_IsLwf_Exempted === false) {
      this.LWF = { text: '', value: 'N' };
    } else {
      this.LWF = { text: '', value: '' };
    }


    this.Sector = { text: '', value: value.eM_Sectorid || 0 };
    this.JobCategory = { text: '', value: value.eM_JobCategory || 0 };
    this.IndustryType = { text: '', value: value.eM_IndustryType || 0 };
    this.Exempted = { text: '', value: value.eM_ExemptedSEA_Act || '' };
    this.VPF = { text: '', value: value.eM_VPF_Applicability || '' };
    if (value.eM_VPF_Applicability == 'Y') {
      this.showVPFDropdown = true;
    }
    this.VPFType = { text: '', value: value.eM_VPF_Type || '' };
    this.VPFValue = value.eM_VPF_Value || 0;
    this.AadharSeeding = value.eM_AadharSeeding || '';
    this.EmployeeType = { text: '', value: value.employee_Type || '' };
    this.CreatedDate = value.eM_CreateDate || '';
    this.onRadioChange(value.eM_AadharSeeding);
    if (this.EmpID) {
      setTimeout(() => {
        this.showAadhaar = false;
        this.updateAadhaarDisplay();
        this.showPan = false;
        this.updatePanDisplay();
        this.showPassport = false;
        this.updatePassportDisplay();
        this.showESICNumber = true;
        this.updateESICNumberDisplay();
        this.showUAN = false;
        this.updateUANDiplay();
        this.showPFNumber = false;
        this.updatePFNumberDisplay();
      }, 500);
    }
    setTimeout(() => {
      this.loading = false;
    }, 1500)

    this.form.markAllAsTouched();
    this.formEm.markAllAsTouched();
  }
  formatPFNumber(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input) {
      return;
    }

    let value = input.value ? input.value.toUpperCase().replace(/[^a-zA-Z0-9]/g, '') : '';

    this.originalPFNumber = value;

    if (value.length === 22) {
      this.showPFNumber = false;
      this.updatePFNumberDisplay();
    } else {
      this.showPFNumber = true;
      this.updatePFNumberDisplay();
    }
  }
  maskPFNumber(pfNumber: string | null | undefined): string {
    if (!pfNumber) {
      return '';
    }

    if (pfNumber.length === 22) {
      setTimeout(() => {
        if (this.PFNumber.length === 22) {
        this.showPFNumber = false;
        this.updatePFNumberDisplay();
        }
      }, 5000);
      this.formEm.get('PFNumber')?.disable();
      return pfNumber.replace(/^(.{18})(.{4})$/, 'XXXXXXXXXXXXXXXXXX$2');
    }

    return pfNumber;
  }

  togglePFNumberVisibility(): void {
    this.showPFNumber = !this.showPFNumber;
    this.updatePFNumberDisplay();
  }

  updatePFNumberDisplay(): void {
    const currentPFNumber = this.formEm.get('PFNumber');
    if (this.showPFNumber) {
      currentPFNumber?.setValue(this.originalPFNumber);
      currentPFNumber?.enable();
    } else {
      currentPFNumber?.setValue(this.maskPFNumber(this.originalPFNumber));
    }
  }
  initializePFNumber(pfNumber: string | null | undefined): string {
    if (!pfNumber) {
      this.showPFNumber = true;
      this.originalPFNumber = '';
      return '';
    }

    this.showPFNumber = true;
    this.originalPFNumber = pfNumber;
    return pfNumber;
  }

  formatClientESINo(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input) {
      return;
    }
    let value = input.value.replace(/[^0-9]/g, '');
    value = value.slice(0, 17);
    input.value = value;
  }

  formatUAN(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input) {
      return;
    }

    let value = input.value ? input.value.replace(/[^0-9]/g, '') : '';

    this.originalUAN = value;

    if (value.length === 12) {
      this.showUAN = false;
      this.updateUANDiplay();
    } else {
      this.showUAN = true;
      this.updateUANDiplay();
    }
  }

  maskUAN(UAN: string | null | undefined): string {
    if (!UAN) {
      return '';
    }
    if (UAN.length === 12) {
      setTimeout(() => {
        if (this.UAN.length === 12) {
        this.showUAN = false;
        this.updateUANDiplay();
        }
      }, 5000);
      this.formEm.get('UAN')?.disable();
      return UAN.replace(/^(.{8})(.{4})$/, 'XXXXXXXX$2');
    }
    return UAN;
  }

  updateUANDiplay(): void {
    const currentUAN = this.formEm?.get('UAN');
    if (this.showUAN) {
      currentUAN?.setValue(this.originalUAN);
      currentUAN?.enable();
    } else {
      currentUAN?.setValue(this.maskUAN(this.originalUAN));
    }
  }
  initializeUAN(UAN: number | null | undefined): number | null {
    if (!UAN) {
      this.showUAN = true;
      this.originalUAN = ''
      return null;
    }

    this.showUAN = true;
    this.originalUAN = UAN.toString();
    return UAN;
  }
  toggleUANVisibility(): void {
    this.showUAN = !this.showUAN;
    this.updateUANDiplay();
  }


  formatESICNumber(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input) {
      return;
    }
    let value = input.value ? input.value.replace(/[^0-9]/g, '') : '';
    this.originalESIC = value;
    if (value.length === 17) {
      this.showESICNumber = false;
      this.updateESICNumberDisplay();
    } else {
      this.showESICNumber = true;
      this.updateESICNumberDisplay();
    }
  }

  maskESICNumber(esicNumber: number | string | null | undefined): string {
    if (!esicNumber) {
      return '';
    }

    const esicString = esicNumber.toString();
 
    if (esicString.length === 17) {
      setTimeout(() => {
        if (this.ESICNumber.length === 17) {
        this.showESICNumber = false;
        this.updateESICNumberDisplay();
        }
      }, 5000);
      this.formEm.get('ESICNumber')?.disable();
      return esicString.replace(/^(.{13})(.{4})$/, 'XXXXXXXXXXXXX$2');
    }

    return esicString;
  }



  initializeESICNumber(esicNumber: number | null | undefined): number {
    if (esicNumber == null) {
      return 0;
    }
    this.originalESIC=esicNumber.toString();
    return esicNumber;
  }
  toggleESICNumberVisibility(): void {
    this.showESICNumber = !this.showESICNumber;
    this.updateESICNumberDisplay();
  }
  formatPassport(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input) {
      return;
    }

    let value = input.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
    this.originalPassport = value;
    this.updatePassportDisplay();
  }


  updateESICNumberDisplay(): void {
    const currentESICNumber = this.formEm.get('ESICNumber');
    if (currentESICNumber) {
      if (this.showESICNumber) {
        currentESICNumber.setValue(this.originalESIC);
        currentESICNumber.enable();
      } else {
        const maskedESIC = this.maskESICNumber(this.originalESIC);
        currentESICNumber.setValue(maskedESIC);
      }
    }
  }


  maskPassport(passport: string): string {
    if (passport.length === 10) {
      setTimeout(() => {
        if (this.passportNo.length === 10) {
        this.showPassport = false;
        this.updatePassportDisplay();
        }
      }, 5000);
      this.form.get('passportNo')?.disable();
      return passport.replace(/^(.{6})(.{4})$/, 'XXXXXX$2');
    }
    return passport;
  }


  togglePassportVisibility(): void {
    this.showPassport = !this.showPassport;
    this.updatePassportDisplay();
  }

  updatePassportDisplay(): void {
    if (this.showPassport) {
      this.form.get('passportNo')?.setValue(this.originalPassport, { emitEvent: false });
      this.form.get('passportNo')?.enable();
    } else {
      this.form.get('passportNo')?.setValue(this.maskPassport(this.originalPassport), { emitEvent: false });
    }
  }

  initializePassport(passport: string | null | undefined): string {
    if (!passport) {
      this.showPassport = false;
      this.originalPassport = '';
      return '';
    }

    this.originalPassport = passport.toUpperCase();
    this.showPassport = !passport.includes('X');
    return this.showPassport ? this.originalPassport : this.maskPassport(this.originalPassport);
  }


  formatPan(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input) {
      return;
    }

    let value = input.value.toUpperCase();
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

    if (!panRegex.test(value)) {
      this.form.get('pan')?.setErrors({ pattern: true });

      this.showValidationErrorsPAN = true;
    } else {
      this.form.get('pan')?.setErrors(null);
      this.showValidationErrorsPAN = false;
    }

    this.originalPan = value;

    if (value.length === 10) {
      this.showPan = false;
      this.updatePanDisplay();
    } else {
      this.showPan = true;
      this.updatePanDisplay();
    }
  }


  maskPan(pan: string): string {
    if (pan.length === 10) {
      setTimeout(() => {
        if (this.pan.length === 10) {
        this.showPan = false;
        this.updatePanDisplay();
        }
      }, 5000);
      this.form.get('pan')?.disable();
      return pan.replace(/^(.{6})(.{4})$/, 'XXXXXX$2');
    }
    return pan;
  }
  togglePanVisibility(): void {
    this.showPan = !this.showPan;
    this.updatePanDisplay();
  }

  updatePanDisplay(): void {
    const panControl = this.form.get('pan');
    if (this.showPan) {
      panControl?.setValue(this.originalPan);
      panControl?.enable(); 
    } else {
      panControl?.setValue(this.maskPan(this.originalPan));
    }
  }

  initializePan(pan: string | null | undefined): string {
    if (!pan) {
      this.showPan = false;
      this.originalPan = '';
      return '';
    }

    this.originalPan = pan.toUpperCase();
    this.showPan = !pan.includes('X');

    return this.showPan ? this.originalPan : this.maskPan(this.originalPan);
  }

  formatAadhaar(event: Event): void {
    const input = event?.target as HTMLInputElement;
    if (!input) return;
    const cursorPosition = input.selectionStart || 0;
    let rawValue = input.value.replace(/[^0-9]/g, '');
    if (rawValue.length > 0) {
      rawValue = rawValue.match(/.{1,4}/g)?.join('-') || rawValue;
    }
    this.originalAadhaar = rawValue.replace(/-/g, '');
    if (this.originalAadhaar.length !== 12) {
      this.form.get('aadhar')?.setErrors({ pattern: true });
      this.showValidationErrorsAadhaar = true;
    } else {
      this.form.get('aadhar')?.setErrors(null);
      this.showValidationErrorsAadhaar = false;

      const maskedAadhaar = this.maskAadhar(this.originalAadhaar);
      input.value = maskedAadhaar;
      this.form.get('aadhar')?.setValue(maskedAadhaar);
      this.showAadhaar = false;
    }
    if (this.originalAadhaar.length < 12) {
      input.value = rawValue;
      this.form.get('aadhar')?.setValue(rawValue);
    }
    const newCursorPosition = cursorPosition + (rawValue[cursorPosition - 1] === '-' ? 1 : 0);
    input.setSelectionRange(newCursorPosition, newCursorPosition);
  }

  toggleAadhaarVisibility(): void {
    this.showAadhaar = !this.showAadhaar;
    this.updateAadhaarDisplay();
  }

  maskAadhar(aadhar: string): string {
    if (aadhar.length === 12) {
      setTimeout(() => {
        if (this.aadhar.length === 12) {
        this.showAadhaar = false;
        this.updateAadhaarDisplay();
        }
      }, 5000);
      this.form.get('aadhar')?.disable();
      return aadhar.replace(/^(\d{4})(\d{4})(\d{4})$/, 'XXXX-XXXX-$3');
    }
    return aadhar;
  }
  updateAadhaarDisplay(): void {
    const currentAadhaar = this.form.get('aadhar');
    if (currentAadhaar) {
      if (this.showAadhaar) {
        const formattedAadhaar = this.originalAadhaar.match(/.{1,4}/g)?.join('-') || this.originalAadhaar;
        currentAadhaar.setValue(formattedAadhaar);
        currentAadhaar.enable();
      } else {
        const maskedAadhaar = this.maskAadhar(this.originalAadhaar);
        currentAadhaar.setValue(maskedAadhaar);
      }
    } else {
      console.error('Aadhaar form control not found.');
    }
  }

  initializeAadhaar(aadhaar: string | null | undefined): string {
    if (!aadhaar) {
      this.showAadhaar = false;
      this.originalAadhaar = '';
      return '';
    }

    if (aadhaar.includes('-')) {
      this.showAadhaar = false;
      this.originalAadhaar = aadhaar.replace(/-/g, '');
    } else {
      this.showAadhaar = true;
      this.originalAadhaar = aadhaar;
    }
    return this.showAadhaar ? this.originalAadhaar : this.maskAadhar(this.originalAadhaar);
  }

  TabValidation(value: string) {
    if (value == 'Personal' && this.PersonalValid) {
      this.EmployeeOption = value;
      setTimeout(() => {
        this.showPan = false;
        this.updatePanDisplay();
        this.showAadhaar = false;
        this.updateAadhaarDisplay();
        this.showPassport = false;
        this.updatePassportDisplay();
      }, 500);
    } else if (value == 'Employee' && this.PersonalValid) {
      this.EmployeeOption = value;
      setTimeout(() => {
        this.showESICNumber = false;
        this.updateESICNumberDisplay();
        this.showUAN = false;
        this.updateUANDiplay();
        this.showPFNumber = false;
        this.updatePFNumberDisplay();
      }, 500);

    } else if (value == 'Other' && this.EmployeeValid) {
      this.EmployeeOption = value;
    }
  }
  applyFilterEmpID(event: any) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.addEditEmployeeService.ISEmpIDExistCheckExist(filterValue, this.clientId).subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data.result == true) {
            this.EmpIDExist = true;
          } else {
            this.EmpIDExist = false;
          }
        }, error: (error) => {
          console.error('Error fetching State Dropdown data', error);
        }
      })
  }
  applyFilterUAN(event: any) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    const input = event.target as HTMLInputElement;
    this.addEditEmployeeService.CheckUANExistsClientWise(this.UAN.toString(), this.clientId, this.EmpID).subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data.Result == true) {
            this.UANExist = true;
          } else {
            this.UANExist = false;
          }
        }, error: (error) => {
          console.error('Error fetching State Dropdown data', error);
        }
      }
    )
  }
  applyFilterESIC(event: any) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.addEditEmployeeService.CheckESICExist(filterValue, this.clientId).subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data.Result == 'Y') {
            this.ESICExist = true;
          } else {
            this.ESICExist = false;
          }
        }, error: (error) => {
          console.error('Error fetching State Dropdown data', error);
        }
      })
  }
  applyFilterPFNumber(event: any) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.addEditEmployeeService.CheckPFExist(filterValue).subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data.Result == true) {
            this.PFNumberExist = true;
          } else {
            this.PFNumberExist = false;
          }
        }, error: (error) => {
          console.error('Error fetching State Dropdown data', error);
        }
      })
  }
  InitData(client: string, value: string) {
    this.addEditEmployeeService.getAllEmployeeMastersEditData(client, value).subscribe(
      {
        next: (response) => {
          const data = response.body;
          this.Data = data;
          this.PredefinedData(data)
        }, error: (error) => {
          console.error('Error fetching data', error);
          this.loading = false;
        }
      });
  }

  OpenBranchTransfer() {
    this.BranchData = this.Data;
    this.BranchTeansferPopup = true;
  }
  loadMonthYearData() {
    this.esicreportService.getMonth().subscribe(
      {
        next: (res: any) => {
          const data = res.body;
          if (data) {
            this.Monthdropdown = [this.defaultSelectedValueMonth, ...data];
          }
        },
        error: (error) => {
          console.error('Error fetching Month Dropdown data', error);
        }
      });
    this.esicreportService.getYear().subscribe({
      next: (res: any) => {
        console.log('year', res);
        const data = res.body;
        if (data) {
          const currentYear = new Date().getFullYear();
          const yearRange = [];
          for (let year = 0; year <= currentYear; year++) {
            yearRange.push({ displayvalue: year.toString(), value: year.toString() });
          }
          this.Yeardropdown = [{ displayvalue: 'Select', value: '' }, ...yearRange.reverse()];
          console.log('year', this.Yeardropdown);
        }
      },
      error: (error) => {
        console.error('Error fetching Year Dropdown data', error);
      }
    });

  }
  EnitiyBranchDropdown(value: string) {
    this.addEditEmployeeService.getEntityBranchDropdown(value).subscribe({
      next: (response) => {
        console.log(response);

        const data = response.body;
        if (data && Array.isArray(data)) {
          this.Entity_BranchDropdownData = data
            .filter((item: any) => item.branchType === "B")
            .map((item: any) => ({
              id: item.id,
              name: item.name
            }));
        } else {
          console.error("Unexpected data format", data);
        }
      },
      error: (error) => {
        console.error('Error fetching State Dropdown data', error);
      }
    });
  }

  PTStateDropdown() {
    this.addEditEmployeeService.getPTStateDropdown().subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && data && Array.isArray(data)) {
            this.PT_StateDropdownData = [{ text: 'Select State', value: '' },
              ...data.map((item: any) => ({
                value: item.sM_Code,
                text: item.sM_Name
              }))];
            this.ClientPTStateDropdownData = [
              ...data.map((item: any) => ({
                id: item.sM_Code,
                name: item.sM_Name
              }))];
          } else {
            console.error("Unexpected data format", data);
          }
        }, error: (error) => {
          console.error('Error fetching State Dropdown data', error);
        }
      });
  }
  SectorDropdown(value: string) {
    this.addEditEmployeeService.getSectorDropdown(value).subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && data && Array.isArray(data)) {
            this.Sectordropdown = [{ text: 'Select State', value: 0 },
            ...data.map((item: any) => ({
              value: item.value,
              text: item.text
            }))]
          } else {
            console.error("Unexpected data format", data);
          }
        }, error: (error) => {
          console.error('Error fetching State Dropdown data', error);
        }
      });
  }
  JobCategoryDropdown(StateID: string, SectorID: number) {
    this.addEditEmployeeService.getJobCategoryDropdown(StateID, SectorID).subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && data && Array.isArray(data)) {
            this.JobCategorydropdown = [{ text: 'Select ', value: 0 },
            ...data.map((item: any) => ({
              value: item.value,
              text: item.text
            }))]
          } else {
            console.error("Unexpected data format", data);
          }
        }, error: (error) => {
          console.error('Error fetching State Dropdown data', error);
        }
      });
  }
  IndustryTypeDropdown(StateID: string, SectorID: number, JobID: number) {
    this.addEditEmployeeService.getIndustryTypeDropdown(StateID, SectorID, JobID).subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && data && Array.isArray(data)) {
            this.IndustryTypedropdown = [{ text: 'Select ', value: 0 },
            ...data.map((item: any) => ({
              value: item.value,
              text: item.text
            }))]
          } else {
            console.error("Unexpected data format", data);
          }
        }, error: (error) => {
          console.error('Error fetching State Dropdown data', error);
        }
      });
  }
  CountryDropdown() {
    this.addEditEmployeeService.getCountryDropdown().subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && Array.isArray(data)) {
            const sortedData = data.sort((a: any, b: any) => a.cnT_Name.localeCompare(b.cnT_Name));
            this.countryDropdownData = [
              { text: 'Select', value: '' },
              ...sortedData.map((item: any) => ({
                value: item.cnT_ID,
                text: item.cnT_Name
              }))
            ];
          } else {
            console.error("Unexpected data format", data);
          }
        },
        error: (error) => {
          console.error('Error fetching Country Dropdown data', error);
        }
      }
    );
  }
  FilterDropdown(value: any) {
    if (value && value.length >= 2) {
      this.addEditEmployeeService.getDesignationDropdown(value).subscribe(
        {
          next: (response) => {
            const data = response.body;
            if (data.data && Array.isArray(data.data)) {
              this.DesignationDropdownData = data.data.map((item: any) => ({
                id: item.key,
                name: item.value
              }));
            } else {
              console.error("Unexpected data format", data);
            }
          }, error: (error) => {
            console.error('Error fetching State Dropdown data', error);
          }
        });
    }
  }
  onDropdownValueChange(value: any, type: string) {
    if (type === 'gender') {
      this.gender = { text: value.text, value: value.value };
    } else if (type === 'relationship') {
      this.relationship = { text: value.text, value: value.value };
    } else if (type === 'maritalStatus') {
      this.maritalStatus = { text: value.text, value: value.value };
    } else if (type === 'physicallyChallenged') {
      this.physicallyChallenged = { text: value.text, value: value.value };
    } else if (type === 'nationality') {
      this.nationality = { text: value.text, value: value.value };
    } else if (type === 'passportIssued') {
      this.passportIssued = { text: value.text, value: value.value };
    } else if (type === 'employmentType') {
      this.employmentType = { text: value.text, value: value.value };
    } else if (type === 'Entity_Branch') {
      console.log(value);
      if(value){
        this.Entity_Branch = {
          name: value.name ? value.name : '',
          id: value.id ? value.id : 0
        };
      }else{
        this.Entity_Branch = {
          name:  '',
          id:0
        };
      }
    } else if (type === 'PT_State') {
      this.PT_State = { text: value.text, value: value.value };
      this.SectorDropdown(value.value)
    } else if (type === 'SkillCategory') {
      this.SkillCategory = { text: value.text, value: value.value };
    } else if (type === 'InternationalWorker') {
      this.InternationalWorker = { text: value.text, value: value.value };
    } else if (type === 'Designation') {
      if(value){
        this.Designation = {
          name: value.name ? value.name : '',
          id: value.id ? value.id : ''
        };
      }else{
        this.Designation = {
          name:  '',
          id:''
        };
      }
    } else if (type === 'Status') {
      this.Status = { text: value.text, value: value.value };
    } else if (type === 'CourageYear') {
      this.CourageYear = { displayvalue: value.text, value: value.value };
    } else if (type === 'EPFOBank') {
      this.EPFOBank = { text: value.text, value: value.value };
    } else if (type === 'PFCapping') {
      this.PFCapping = { text: value.text, value: value.value };
    } else if (type === 'ESI') {
      this.ESI = { text: value.text, value: value.value };
    } else if (type === 'CourageMonth') {
      this.CourageMonth = { displayvalue: value.text, value: value.value };
    } else if (type === 'PMRPY') {
      this.PMRPY = { text: value.text, value: value.value };
    } else if (type === 'PT') {
      this.PT = { text: value.text, value: value.value };
    } else if (type === 'Employment') {
      this.Employment = { text: value.text, value: value.value };
    } else if (type === 'PF') {
      this.PF = { text: value.text, value: value.value };
    } else if (type === 'PayrollMonth') {
      this.PayrollMonth = { displayvalue: value.text, value: value.value };
    } else if (type === 'LWF') {
      this.LWF = { text: value.text, value: value.value };
    } else if (type === 'PayrollYear') {
      this.PayrollYear = { displayvalue: value.text, value: value.value };
    } else if (type === 'PF') {
      this.PF = { text: value.text, value: value.value };
    } else if (type === 'EPFOPAN') {
      this.EPFOPAN = { text: value.text, value: value.value };
    } else if (type === 'EPFOAddress') {
      this.EPFOAddress = { text: value.text, value: value.value };
    } else if (type === 'Exempted') {
      this.Exempted = { text: value.text, value: value.value };
    } else if (type === 'VPF') {
      this.VPF = { text: value.text, value: value.value };
      if (this.VPF.value == 'Y') {
        this.showVPFDropdown = true;
      } else {
        this.showVPFDropdown = false;
      }
    } else if (type === 'VPFType') {
      this.VPFType = { text: value.text, value: value.value };
    } else if (type === 'Sector') {
      this.Sector = { text: value.text, value: value.value };
      this.JobCategoryDropdown(this.PT_State.value, value.value);
    } else if (type === 'JobCategory') {
      if (this.PT_State.value != 'select') {
        this.JobCategory = { text: value.text, value: value.value };
        this.IndustryTypeDropdown(this.PT_State.value, this.Sector.value, value.value);
      } else {
        this.alertData = { alertMessage: 'Please Select Sector First', alertVisible: 'error' };
        this.JobCategory = { text: 'Select', value: 0 };
      }
    } else if (type === 'IndustryType') {
      if (this.PT_State.value != 'select' && this.Sector.value != 0) {
        this.IndustryType = { text: value.text, value: value.value };
      } else {
        this.alertData = { alertMessage: 'Please Select Sector and JobCategory First', alertVisible: 'error' };
        this.IndustryType = { text: 'Select', value: 0 };
      }
    } else if (type === 'Placeofwork') {
      this.Placeofwork = { text: value.text, value: value.value };
    } else if (type === 'WomenWorking') {
      this.WomenWorking = { text: value.text, value: value.value };
    } else if (type === 'TransportMode') {
      this.TransportMode = { text: value.text, value: value.value };
    } else if (type === 'SecurityProvided') {
      this.SecurityProvided = { text: value.text, value: value.value };
    } else if (type === 'SalesPromotion') {
      this.SalesPromotion = { text: value.text, value: value.value };
    } else if (type === 'EPSApplicability') {
      this.EPSApplicability = { text: value.text, value: value.value };
    } else if (type === 'ClientPTState') {
      if(value){
        this.ClientPTState = {
          name: value.name ? value.name : '',
          id: value.id ? value.id : ''
        };
      }else{
        this.ClientPTState = {
          name:  '',
          id:''
        };
      }
    } else if (type === 'EmployeeType') {
      this.EmployeeType = { text: value.text, value: value.value };
    }
  }
  onRadioChange(value: string) {
    if (value == 'Y') {
      this.AadharSeeding = value;
    } else if (value == 'N') {
      this.AadharSeeding = value;
    }
  }
  formatDate(dateString: string): string {
    const date = new Date(dateString);

    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear().toString();

    return `${day}/${month}/${year}`;
  }
  onDateChange(value: Date, type: string) {
    const date = new Date(value);
    if (type === 'dateOfBirth') {
      this.dateOfBirth = date;    
    } else if (type === 'passportValidFrom') {
      debugger
      if (this.dateOfBirth != null && date<= this.dateOfBirth) {
        this.passportValidFrom = null;
        this.validationpopup = true;
        this.alertData = { alertMessage: 'Passport Valid From cannot be smaller Date of Birth', alertVisible: 'error' }
      } else if ( this.passportValidTo != null && date > this.passportValidTo) {
        this.validationpopup = true;
        this.passportValidFrom = null;
        this.alertData = { alertMessage: 'Passport valid To should be greater than passport valid From', alertVisible: 'error' }
      } else{
        this.passportValidFrom = date;
      }
    } else if (type === 'passportValidTo') {
      if (this.dateOfBirth != null && date<= this.dateOfBirth) {
        this.passportValidFrom = null;
        this.validationpopup = true;
        this.alertData = { alertMessage: 'Passport Valid FroTom cannot be smaller Date of Birth', alertVisible: 'error' }
      } 
      if (this.passportValidFrom != null  && this.passportValidFrom > date) {
        this.passportValidTo = null;
        this.validationpopup = true;
        this.alertData = { alertMessage: 'Passport valid To should be greater than passport valid From', alertVisible: 'error' }
      }else{
        this.passportValidTo = date;
      }

    } else if (type === 'JoiningDate') {
      const currentDate = new Date();
      if ( this.dateOfBirth != null && date <= this.dateOfBirth) {
        this.validationpopup = true;
        this.JoiningDate = null;
        this.alertData = { alertMessage: 'Joining Date cannot be smaller than Date of Birth', alertVisible: 'error' }
      } else if ( date > currentDate) {
        this.validationpopup = true;
        this.JoiningDate = null;
        this.alertData = { alertMessage: 'Joining Date cannot be greater than Current Date', alertVisible: 'error' };
      } else{
        this.JoiningDate = date;
      }
    } else if (type === 'LeavingDate') {
      const currentDate = new Date();
      if ( this.dateOfBirth != null && date <= this.dateOfBirth) {
        this.validationpopup = true;
        this.JoiningDate = null;
        this.alertData = { alertMessage: 'Leaving Date cannot be smaller than Date of Birth', alertVisible: 'error' }
        return;
      }
      if (this.JoiningDate != null && date< this.JoiningDate) {
        this.validationpopup = true;
        this.LeavingDate = null;
        this.alertData = { alertMessage: 'Leaving Date cannot be smaller than Joining Date', alertVisible: 'error' }
        return;
      }
      if ( date > currentDate) {
        this.validationpopup = true;
        this.LeavingDate = null;
        this.alertData = { alertMessage: 'Leaving Date cannot be greater than Current Date', alertVisible: 'error' };
        return;
      }

      this.LeavingDate = date;

    } else if (type === 'EffectiveChange') {
      this.EffectiveChange = date;
    } else if (type === 'TrainingDate') {
      this.TrainingDate = date;
    } else if (type === 'CertificateDate') {
      this.CertificateDate = date;
    } else if (type === 'DateofClothes') {
      this.DateofClothes = date;
      if (date != null) {
        if (this.JoiningDate != null && date < this.JoiningDate) {
          this.validationpopup = true;
          this.DateofClothes = null;
          this.alertData = { alertMessage: 'Date of Clothes Given cannot be smaller than Joining Date', alertVisible: 'error' };
          return;
        }
        if (this.LeavingDate != null && date > this.LeavingDate) {
          this.validationpopup = true;
          this.DateofClothes = null;
          this.alertData = { alertMessage: 'Date of Clothes Given cannot be greater than Leaving Date', alertVisible: 'error' };
          return;
        }
        this.DateofClothes = date;
      } else {
        this.DateofClothes = null;
      }
    } else if (type == 'DateOfExempting') {
      this.DateOfExempting = date;
    }
  }
  isValidDate(date: any): boolean {
    return date instanceof Date && !isNaN(date.getTime());
  }

  OnSave() {
    this.loading = true;
    this.showValidationErrorsOther = true;
    const body = {
      fileID: 0,
      rowNo: 0,
      userID: this.userID,
      avacoM_CustomerID: this.customerId,
      avacoM_BranchID: this.Entity_Branch.id || 0,
      eM_EmpID: this.employeeID || '',
      eM_ClientID: this.clientId || '',
      eM_EmpName: this.form.value.employeeName || '',
      eM_State: '',
      eM_Location: '',
      eM_Branch: this.Entity_Branch.name,
      eM_PT_State: this.PT_State.value || '',
      eM_Gender: this.gender.value || '',
      eM_FatherName: this.form.value.fatherHusbandName || '',
      eM_Relationship: this.relationship.value || '',
      eM_DOB: this.dateOfBirth ? this.formatDate(this.dateOfBirth.toISOString()) : '',
      eM_MaritalStatus: this.maritalStatus.value,
      eM_DOJ: this.JoiningDate ? this.formatDate(this.JoiningDate.toISOString()) : '',
      eM_DOL: this.LeavingDate ? this.formatDate(this.LeavingDate.toISOString()) : '',
      eM_PFNO: this.originalPFNumber?.toString(),
      eM_ESICNO: this.ESICNumber?.toString() || '',
      eM_UAN: this.UAN || '',
      eM_Emailid: this.form.value.email || '',
      eM_MobileNo: this.form.value.mobileNo?.toString() || '',
      eM_PAN: this.pan || '',
      eM_Aadhar: this.originalAadhaar.toString() || '',
      eM_BankName: this.BankName || '',
      eM_Department: this.Department || '',
      eM_Designation: this.Designation.id || '',
      eM_SkillCategory: this.SkillCategory.value || '',
      eM_Bankaccountnumber: this.BankAC || '',
      eM_IFSC: this.IFSC || '',
      eM_Address: this.address || '',
      eM_PassportNo: this.originalPassport || '',
      eM_PhysicallyChallenged: this.physicallyChallenged.value || '',
      eM_Firsttime_secondtime: this.Employment.value || '',
      eM_International_workers: this.InternationalWorker.value || '',
      eM_PF_Capping_Applicability: this.PF.value || '',
      eM_Employmenttype: this.employmentType.value || '',
      eM_ChangeEffective_from: this.EffectiveChange ? this.formatDate(this.EffectiveChange.toISOString()) : '',
      eM_Status: this.Status.value || '',
      eM_CreatedBy: this.userID?.toString(),
      eM_modifiedBy: this.userID?.toString(),
      eM_Version: 0,
      eM_Nationality: this.nationality.text || '',
      eM_Passport_Valid_From: this.passportValidFrom ? this.formatDate(this.passportValidFrom.toISOString()) : '',
      eM_Passport_Valid_Upto: this.passportValidTo ? this.formatDate(this.passportValidTo.toISOString()) : '',
      eM_PayrollMonth: parseInt(this.PayrollMonth.value, 10) || 0,
      eM_PayrollYear: parseInt(this.PayrollYear.value, 10) || 0,
      eM_PF_Applicability: this.PFCapping.value || '',
      eM_ESI_Applicability: this.ESI.value || '',
      eM_ESI_Out_of_Courage_Month: parseInt(this.CourageMonth.value, 10) || 0,
      eM_ESI_Out_of_Courage_Year: parseInt(this.CourageYear.value, 10) || 0,
      eM_PassportIssued_Country: this.passportIssued.text || '',
      eM_EPFO_Aadhar_Upload: this.EPFOAddress.value || '',
      eM_EPFO_Bank_Ac_Upload: this.EPFOBank.value || '',
      eM_EPFO_PAN_Upload: this.EPFOPAN.value || '',
      eM_PMRPY: this.PMRPY.value || '',
      eM_PT_Applicability: this.PT.value || '',
      eM_NoOf_Certificate: this.NoOfCertificate || '',
      eM_NoOf_Certificate_Date: this.CertificateDate ? this.formatDate(this.CertificateDate.toISOString()) : '',
      eM_TokenNo: this.TokenNo || '',
      eM_Relay_Assigned: this.RelayAssigned || '',
      eM_Letter_Of_Group: this.LetterGroup || '',
      eM_WomenWorkingNightshift: this.WomenWorking.value || '',
      eM_ModeofTransport: this.TransportMode.value || '',
      eM_SecurityProvided: this.SecurityProvided.value || '',
      eM_ExistReasonCode: '',
      eM_YearsOfExperience: this.Experience || '',
      eM_DateWhenClothesGiven: this.DateofClothes ? this.formatDate(this.DateofClothes.toISOString()) : '',
      eM_NumberandDateOfExemptingOrder: this.DateOfExempting && this.isValidDate(this.DateOfExempting)
        ? this.formatDate(this.DateOfExempting.toISOString())
        : null,

      eM_ParticularsOfTransferFromOneGroupToAnother: this.ParticularsOfTransfer || '',
      eM_SalesPromotion: this.SalesPromotion.value || '',
      eM_PaymentMode: this.PaymentMode || '',
      eM_PermanentAddress: this.permanentAddress || '',
      eM_Markof_Identification: this.identificationMark || '',
      eM_Placeof_work: this.Placeofwork.value || '',
      eM_Remarks: this.Remarks || '',
      eM_EductionLevel: this.formSave.value.EducationLevel || '',
      eM_Place_of_Employment: this.PlaceofEmployment || '',
      eM_Training_Number: this.TrainingNo.toString() || '',
      eM_Training_Date: this.TrainingDate ? this.formatDate(this.TrainingDate.toISOString()) : '',
      eM_Emergency_contact_Address: this.emergencyContactAddress || '',
      eM_Emergency_contact_mobile_no: this.form.value.emergencyContactNum?.toString() || '',
      eM_EPS_Applicabilty: this.EPSApplicability.value || '',
      eM_Client_ESI_Number: this.ClientESINo || '',
      eM_Client_PT_State: this.ClientPTState.id || '',
      eM_IsLwf_Exempted: this.LWF.value || '',
      eM_Sectorid: this.Sector.value || 0,
      eM_JobCategory: this.JobCategory.value || 0,
      eM_IndustryType: this.IndustryType.value || 0,
      eM_ExemptedSEA_Act: this.Exempted.value || '',
      eM_VPF_Applicability: this.VPF.value || '',
      eM_VPF_Type: this.VPFType.value || '',
      eM_VPF_Value: this.VPFValue || 0,
      eM_AadharSeeding: this.AadharSeeding || '',
      employee_Type: this.EmployeeType.value || '',
      municipalityName: ''
    }
    if (this.formSave.valid && this.PersonalValid && this.EmployeeValid &&
      this.EmployeeType.value && this.SecurityProvided.value && (this.validationpopup == true)) {
      this.EmployeeValid = true;
      if (this.isUpdate == 0) {
        this.addEditEmployeeService.saveEmployeeMaster(body, this.isUpdate).subscribe(
          {
            next: (response) => {
              console.log('save',response);
              
              if (response.status == 200) {
                this.alertData = { alertMessage: 'Employee Added Successfully', alertVisible: 'success' };
                this.loading = false;
              } else {
                this.alertData = { alertMessage: 'Employee Creation Failed', alertVisible: 'error' };
                this.loading = false;
                this.validationpopup = false;
              }
            }, error: (error) => {
              console.error(error);
              this.loading = false;
              this.validationpopup = true;
            }
          })
        } else if (this.isUpdate == 1) {
          this.addEditEmployeeService.saveEmployeeMaster(body, this.isUpdate).subscribe(
            {
              next: (response) => {
              console.log('save',response);
              if (response.status == 200) {
                this.alertData = { alertMessage: 'Employee Updated Successfully', alertVisible: 'success' };
                this.loading = false;
                this.validationpopup = false;
              } else {
                this.alertData = { alertMessage: 'Employee Update Failed', alertVisible: 'error' };
                this.loading = false;
                this.validationpopup = true;
              }
            }, error: (error) => {
              this.loading = false;
              this.validationpopup = true;
              console.error(error);
            }
          });
      }
    } else {
      this.loading = false;
      // this.alertData = { alertMessage: 'Please Valid All the Feilds', alertVisible: 'error' };
      this.formSave.markAllAsTouched();
      this.validationpopup = true;
    }
  }

  PersonalNext() {
    this.showValidationErrors = true;
    if (this.dateOfBirth != null &&this.passportValidFrom != null && this.passportValidFrom<= this.dateOfBirth) {
      this.passportValidFrom = null;
      this.alertData = { alertMessage: 'Passport Valid From cannot be smaller Date of Birth', alertVisible: 'error' };
      return;
    }
    if (this.dateOfBirth != null &&this.passportValidTo != null && this.passportValidTo<= this.dateOfBirth) {
      this.passportValidFrom = null;
      this.alertData = { alertMessage: 'Passport Valid To cannot be smaller Date of Birth', alertVisible: 'error' };
      return;
    }
    if (this.passportValidFrom != null && this.passportValidTo == null) {
      this.alertData = { alertMessage: 'Passport Valid To should be present when Passport Valid From is Present', alertVisible: 'error' };
      return;
    }
    if (this.passportValidTo != null && this.passportValidFrom == null) {
      this.alertData = { alertMessage: 'Passport Valid From should be present when Passport Valid To is Present', alertVisible: 'error' };
      return;
    }

    if (this.form.valid && !this.showValidationErrorsPAN && this.gender.value && this.dateOfBirth && this.relationship.value && this.physicallyChallenged.value) {
      this.PersonalValid = true;
      this.EmployeeOption = 'Employee';
      this.form.get('aadhar')?.setValue(this.originalAadhaar);
      this.form.get('pan')?.setValue(this.originalPan);
      this.form.get('passportNo')?.setValue(this.originalPassport);
      setTimeout(() => {
        if (this.PFNumber) {
          this.showESICNumber = false;
          this.updateESICNumberDisplay();
          this.showPFNumber = false;
          this.updatePFNumberDisplay();
          if (this.PFNumber.length != 22) {
            this.showPFNumber = true;
            this.updatePFNumberDisplay();
          }
        }
        if (this.ESICNumber) {
          this.showESICNumber = false;
          this.updateESICNumberDisplay();
        }
        if (this.UAN) {
          this.showUAN = false;
          this.updateUANDiplay();
        }
      }, 500);
    } else {
      if (this.showValidationErrorsPAN) {
        this.showPan = true;
        this.updatePanDisplay();
      }
      this.PersonalValid = false;
      this.form.markAllAsTouched();
    }
  }
  EmployeeNext() {
    this.showValidationErrorsEmp = true;

    if (this.dateOfBirth != null &&this.JoiningDate != null && this.JoiningDate <= this.dateOfBirth) {
      this.passportValidFrom = null;
      this.alertData = { alertMessage: 'Joining Date cannot be smaller Date of Birth', alertVisible: 'error' };
      return;
    }
    if (this.dateOfBirth != null &&this.LeavingDate != null && this.LeavingDate <= this.dateOfBirth) {
      this.passportValidFrom = null;
      this.alertData = { alertMessage: 'Leaving Date cannot be smaller Date of Birth', alertVisible: 'error' };
      return;
    }
    if (this.JoiningDate != null && this.LeavingDate!=null && this.LeavingDate< this.JoiningDate) {
      this.validationpopup = true;
      this.alertData = { alertMessage: 'Leaving Date cannot be smaller than Joining Date', alertVisible: 'error' }
      this.LeavingDate = null;
      return;
    }
    if (this.formEm.valid && this.employmentType.value && (this.Entity_Branch.id != 0) && this.SkillCategory.value
      && this.JoiningDate && this.InternationalWorker.value && this.Status.value && this.Employment.value && this.PFCapping.value && this.PayrollMonth.value) {
      this.EmployeeValid = true;
      this.EmployeeOption = 'Other';
      this.showESICNumber = false;
      this.showUAN = false;
      this.showPFNumber = false;
      this.updateESICNumberDisplay();
      this.updateUANDiplay();
      this.updatePFNumberDisplay();
      this.formEm.get('ESICNumber')?.setValue(this.originalESIC)
      this.formEm.get('UAN')?.setValue(this.originalUAN);
      this.formEm.get('PFNumber')?.setValue(this.originalPFNumber);
      console.log(this.UAN ,'/',this.originalUAN,'/', this.ESICNumber,'/',this.originalESIC);
      
    } else {
      this.formEm.markAllAsTouched();
      this.EmployeeValid = false;
    }
  }



  public closeAlert(): void {
    if (this.alertData.alertVisible == 'success') {
      this.closePopup();
    }
    this.alertData = { alertMessage: '', alertVisible: '' };
  }
  closeBranch() {
    this.BranchTeansferPopup = false;
  }
  public closePopup(): void {
    this.Data = [];
    this.EmpID = '';
    this.close.emit();
  }
}
