<div  class=" dropdown">
                    <kendo-multiselect
                        [data]="complianceType" [(ngModel)]="selectedComplianceType"                [valueField]="'value'" [textField]="'text'" (valueChange)="onComplianceTypeChange($event)" placeholder="Compliance Type" [tagMapper]="tagMapper" [valuePrimitive]="true" [filterable]="true" class="f-textbox">
                     
                    </kendo-multiselect>
    
                    <app-dropdown-list [data]="years" [selectedValue]="selectedYear" [width]="'120px'" textField="text"
                    valueField="value" class="dropdown-setting" (valueChange)="onYearChange($event)">
                  </app-dropdown-list>
            
                  <app-dropdown-list [data]="period" [selectedValue]="selectedPeriod" [width]="'120px'" textField="text"
                    valueField="value" (valueChange)="onPeriodChange($event)">
                  </app-dropdown-list>

                  <button class="figma-btn-white apply-button" type="submit" (click)="applyFilter()">
                    <img src="../assets/vectors/ApplyFilter.svg" alt="">
                  </button>
    </div>

    <div class="grid">
  
        <kendo-grid [data]="gridData" 
 [columnMenu]="true" [pageSize]="pageSize" [pageable]="true"
  (pageChange)="pageChange($event)" [skip]="skip" [resizable]="true"  >
  <kendo-grid-column field="customerName" title="Customer Name" [style]="{'white-space': 'nowrap'}" [width]="300"></kendo-grid-column>
  <kendo-grid-column field="clientId" title="Client ID" [style]="{'white-space': 'nowrap'}" [width]="150"></kendo-grid-column>
  <kendo-grid-column field="entityName" title="Entity Name" [style]="{'white-space': 'nowrap'}" [width]="300" > </kendo-grid-column>
  <kendo-grid-column field="totalNoInputs" title="Total No of Inputs(Count)" [style]="{'white-space': 'nowrap'}" [width]="100"></kendo-grid-column>
  <kendo-grid-column field="totalNoUploaded" title="No of Inputs uploaded (Count)" [style]="{'white-space': 'nowrap'}" [width]="100"></kendo-grid-column>

  <kendo-grid-column title="Status" [width]="100">
      <ng-template kendoGridCellTemplate let-dataItem>
          <div
              [ngStyle]="{
                  'background-color': dataItem.totalInputReceivedStatus === 1 ? 'green' : 'red',
                  color: 'white',
                  width: '20px',
                  height: '20px',
                  'border-radius': '50%',
                  display: 'flex',
                  'align-items': 'center',
                  'justify-content': 'center',
                  'margin-left': '23px'
              }">
          </div>
      </ng-template>
  </kendo-grid-column>
  <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
    <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
    <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
    <kendo-pager-next-buttons></kendo-pager-next-buttons>
    <kendo-pager-input></kendo-pager-input>
    <kendo-pager-info></kendo-pager-info>
    <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
  </ng-template>
</kendo-grid>


    </div>
    <div *ngIf="loading" class="loader-overlay">
        <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
      </div>
      