import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

export interface MinimumWageNotification {
  id: number; 
  state: string;
  effective_From: string; 
  effective_To: string; 
  notification_Number: string;
  notified_On: string; 
  created_On: string; 
}
export interface MinimumWageIndustryNotification {
  id: number; 
  notification_Id:number,
  state: string;
  industry:string,
  effective_From: string; 
  effective_To: string; 
  notification_Number: string;
  notified_On: string; 
  created_On: string; 
}
@Injectable({
  providedIn: 'root'
})
export class MwNotificationService {

  private baseUrl  =environment.apiUrl;
  constructor(private http:HttpClient) { }

  getMasterNotification(): Observable<HttpResponse<any>> {
    const url =`${this.baseUrl}/api/MinimumWage/Get_LC_MW_MasterNotification`
    return this.http.get(url,{observe:'response'})
    
  }
  getStateMaster(): Observable<HttpResponse<any>> {
    const url =`${this.baseUrl}/api/StateMaster/Get_StateMaster`
    return this.http.get(url,{observe:'response'})
  }
  deleteMasterNotification(id: number): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/MinimumWage/Delete_LC_MW_MasterNotification/${id}`;
    return this.http.get<any>(url,{observe:'response'});
  }

  addOrUpdateNotification(notification: MinimumWageNotification): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/MinimumWage/Add_Update_LC_MW_MasterNotification`;
    return this.http.post<any>(url, notification,{observe:'response'});
  }

  getIndustryMasterNotification(industry: string =''): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/MinimumWage/Get_LC_MW_MasterNotification_Industrywise/${industry}`;
    return this.http.get<any>(url,{observe:'response'}); 
  }

  getIndustryType(state:string,type:string):Observable<HttpResponse<any>>{
    const url = `${this.baseUrl}/api/MinimumWage/Get_LC_MW_Industry_ByState/${state}/${type}`;
    return this.http.get<any>(url,{observe:'response'}); 
  }

  getNotificationId(state:string):Observable<HttpResponse<any>>{
    const url = `${this.baseUrl}/api/MinimumWage/Get_LC_MW_MasterNotification_Industrywise_ByState/${state}`;
    return this.http.get<any>(url,{observe:'response'});
  }

  deleteIndustryMasterNotification(id: number): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/MinimumWage/Delete_LC_MW_MasterNotification_Industrywise/${id}`;
    return this.http.get<any>(url,{observe:'response'});
  }
  addOrUpdateIndustryNotification(notification: MinimumWageIndustryNotification): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/api/MinimumWage/Add_Update_LC_MW_MasterNotification_Industrywise`;
    return this.http.post<any>(url, notification,{observe:'response'});
  }


}