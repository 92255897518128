import { Component } from '@angular/core';
// import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { GridDataResult, GridModule, PageChangeEvent } from '@progress/kendo-angular-grid';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { UserService } from '../../../service/user.service';
import { BlobService } from '../../../service/blob.service';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { HttpResponse } from '@angular/common/http';
import { CreditNoteService } from '../../../service/credit-note.service';

import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';

@Component({
  selector: 'app-creditnote',
  standalone: true,
  imports: [ComboBoxComponent, IndicatorsModule, AlertPopupComponent, GridModule, DropdownListComponent, ReactiveFormsModule, FormsModule, CommonModule],

  templateUrl: './creditnote.component.html',
  styleUrl: './creditnote.component.css'
})
export class CreditnoteComponent {
  ClientGstDropdownData: any[] = [];
  selectedClient = { text: 'Select Client', value: '' };
  clientID!: string;
  selectedMonth!: any;
selectedYear!: any;
monthDropdown: any[] = [];
selectedMonthYear: any;
invoiceTypetDropdown: any[] = [];
  selectedInvoiceType = { text: 'Select Report', value: '' };
  InvoiceReport: any;
  fileNames: { [key: string]: string | null } = {
    DownloadsInput: null,
    EmployeeInput: null,
    FamilyWelfareInput: null,
    UpdateInputEmp: null,
    UpdateInputFamily: null,
    FailedInput: null
  };
  form!: FormGroup;
  public alertData = { alertMessage: '', alertVisible: '' };
  selectedFiles: { [key: string]: File } = {};
  uploadedSlaFile!: File;
  showValidationErrors = false;
  agreementForm!: FormGroup;


  constructor(private user: UserService, private CreditNoteService: CreditNoteService, ) { 

  }
  ngOnInit(): void {
    // this.getInvoiceType();
    this.getClientsMaster();
    this.generateMonthYearDropdown();
  
  
  }
  onDropdownClientGstValueChange(data: any) {


    console.log('data', data)
    this.clientID = data?.value;
    console.log('client', this.clientID)
  
  
  
  }
  generateMonthYearDropdown() {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
  
    const startYear = 2020; // Starting year for the dropdown
    const endYear = 2025;   // Ending year for the dropdown
  
    for (let year = startYear; year <= endYear; year++) {
      months.forEach((month, index) => {
        this.monthDropdown.push({
          id: `${year}-${index + 1}`,  // Example id: '2023-11'
          name: `${month}-${year}`     // Example name: 'Nov-2023'
        });
      });
    }
  }
  
  onDropdownMonthYearValueChange(data: any) {
    this.selectedMonthYear = data;
  
    // Assuming data.name is in the format "MMM-YYYY" (e.g., "Nov-2023")
    const [monthAbbreviation, selectedYear] = data.name.split('-');
  
    // Map month abbreviation to its numeric value
    const monthMap: { [key: string]: string  } = {
     Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06',
  Jul: '07', Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12'
    };
  
  
    const selectedMonth = monthMap[monthAbbreviation];
  
    console.log('Selected numeric month:', selectedMonth);
    console.log('Selected year:', selectedYear);
  
  
    this.selectedMonth = selectedMonth;
    this.selectedYear = +selectedYear;
  
  }
  onDropdownValueChange(data: any) {


    console.log('invoicetype', data)
    this.InvoiceReport = data?.value;
    console.log('client', this.InvoiceReport)
  
  
  
  }
  
  getClientsMaster() {
    this.CreditNoteService.getClientsGstMaster().subscribe({
      next: (response) => {
        const data = response.body;
        this.ClientGstDropdownData = [
          { text: 'Select Client', value: '' },
          ...data.map((item: any) => ({
            text: item.text,
            value: item.value
          }))
        ];
      },
      error: (error) => {
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }

  getInvoiceType() {

    this.CreditNoteService.getInvoiceType().subscribe({
      next: (response) => {
        const data = response.body;
        this.invoiceTypetDropdown = [
          { text: 'invoice Typet', value: '' },
          ...data.map((item: any) => ({
            text: item.text,
            value: item.value
          }))
        ];
      },
      error: (error) => {
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }
  onFileChange(event: any, controlName: string) {
    const file = event.target.files[0];

    if (file) {
      const fileType = file.type;
      const allowedTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];

      if (allowedTypes.includes(fileType)) {
        this.selectedFiles[controlName] = file;
        this.fileNames[controlName] = file.name;
        this.form.get(controlName)?.setValue(file);
      } else {
        this.alertData = { alertMessage: 'Please select .xlsx file', alertVisible: 'error' };
        event.target.value = '';
      }
    }
  }

  OnSubmitInvoiceApprovelPendingDetails() {
  
        // const payload = {
         
          
        //     "loggedUserId": "",
        //     "invoiceType":this.InvoiceReport,
        //     "clientid": this.clientID ? this.clientID : 'all',
        //     "yearid": String(this.selectedYear),
        //     "monthid":String(this.selectedMonth),
        //     "approve": this.Appoved,
        //     "cancel": this.rejected,
        //     "sendback":this.sendback,
        //     "invoiceGenrtionType": this.selectedMeasurementSystemDraft,
        //     "rrcinvApprovals_lst": [
        //       {
        //         "selected": true,
        //         "riM_ClientID": this.gridData[0].riM_ClientID,
        //         "clienT_NAME": this.gridData[0].clienT_NAME,
        //         "riM_InvoiceID": this.gridData[0].riM_InvoiceID,
        //         "riM_InvoiceNo": this.gridData[0].riM_InvoiceNo,
        //         "riA_Amount": String(this.gridData[0].riA_Amount),
        //         "riS_ServiceTax": "",
        //         "riS_Swachh_Bharat_Cess": "",
        //         "riM_Invoice_Amount": String(this.gridData[0].riM_Invoice_Amount),
        //         "remarks": "",
        //         "invoiceGenrtionType": this.selectedMeasurementSystemDraft,
        //       }
        //     ],
        //     "clientsMaster_lst": [
        //       {
        //         "text": "string",
        //         "value": "string",
        //         "sort": 0
        //       }
        //     ],
        //     "approvalPendingInvoices_lst": [
        //       {
        //         "srno": 0,
        //         "riM_InvoiceID": this.gridData[0].riM_InvoiceID,
        //         "riM_ClientID": this.gridData[0].riM_ClientID,
        //         "clienT_NAME":this.gridData[0].clienT_NAME,
        //         "riM_Month": this.gridData[0].riM_Month,
        //         "riM_Year": this.gridData[0].riM_Year,
        //         "riM_InvoiceNo": this.gridData[0].riM_InvoiceNo,
        //         "riM_Invoice_Type": this.gridData[0].riM_Invoice_Type,
        //         "riM_Type": this.gridData[0].riM_Type,
        //         "riM_Invoice_Date": this.gridData[0].riM_Invoice_Date,
        //         "riM_Invoice_Amount": 0,
        //         "riM_Approved_Status": this.gridData[0].riM_Approved_Status,
        //         "riM_Approved_Date": this.gridData[0].riM_Approved_Date,
        //         "riM_ApprovedBy":this.gridData[0].riM_ApprovedBy,
        //         "riM_Finance_Approved_Status": this.gridData[0].riM_Finance_Approved_Status,
        //         "riM_Finance_Approved_Date": this.gridData[0].riM_Finance_Approved_Date,
        //         "riM_Finance_AppeovedBy": this.gridData[0].riM_Finance_AppeovedBy,
        //         "riM_Narration": this.gridData[0].riM_Narration,
        //         "riM_Activity_ProjectID": this.gridData[0].riM_Activity_ProjectID,
        //         "riS_Amount": 0,
        //         "riS_CGSTAmount": 0,
        //         "riS_SGSTAmount": 0,
        //         "riS_IGSTAmount": 0,
        //         "riA_Amount": 0,
        //         "riA_Amount_Adjustments": 0,
        //         "riA_CGSTAmount": 0,
        //         "riA_CGSTAmount_Adjustments": 0,
        //         "riA_SGSTAmount": 0,
        //         "riA_SGSTAmount_Adjustments": 0,
        //         "riA_IGSTAmount": 0,
        //         "riA_IGSTAmount_Adjustments": 0,
        //         "riA_Actual_Amount": 0,
        //         "riA_Adjustment_Amount": 0,
        //         "invoiceDescription": this.gridData[0].invoiceDescription,
        //         "supplyCodeExists": true
        //       }
        //     ],
        //     "invoiceUpdateStatus": true,
        //     "errorFlag": "string",
        //     "errorMessage": "string",
        //     "supplyCodeExists": true
        //   }
        
  
        // console.log('Payload to send:', payload);
  
  
        // this.InvoiceApprovalService.SubmitInvoiceApprovalPending(payload).subscribe({
        //   next: (response) => {
        //     this.loading = false;
        //     this.gridData = response.body;
        //     if (response.body.errorMessage === 'Invoice Approved Successfully') {
        //       this.alertData = { alertMessage: 'Invoice Approved Successfully', alertVisible: 'success' };
        //       this.GetInvoiceGridDetails();
        //     } else {
        //       this.alertData = { alertMessage: 'Submission Failed', alertVisible: 'error' };
        //     }
        //     this.selectedItems=[];
        //   },
        //   error: (error) => {
        //     // Handle error response
        //     console.error('Error during submission', error);
        //     this.loading = false;
        //     this.selectedItems=[];
        //     this.alertData = { alertMessage: 'Error submitting details', alertVisible: 'error' };
        //   }
        // });
       }

       downloadSlaFile(): void {
        if (this.uploadedSlaFile) {
    
          const fileType = this.uploadedSlaFile.name.split('.').pop();
    
          if (fileType && fileType.toLowerCase() === 'xlsx') {
            console.log('File is valid and active');
    
          } else {
    
            this.alertData = {
              alertMessage: 'Invalid file type. Please upload an .xlsx file.',
              alertVisible: 'error'
            };
          }
        } else {
          this.alertData = { alertMessage: 'File Not Found.', alertVisible: 'error' };
        }
    
    
      }
}
