import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { DropDownsModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { MultiSelectTreeComponent } from '../../../components/multi-select-tree/multi-select-tree.component';
import { LabelModule } from '@progress/kendo-angular-label';
import { UserBranchMappingPayload, UserBranchMappingService } from '../../../service/user-branch-mapping.service';
import { UserService } from '../../../service/user.service';
import { NgClass, NgIf } from '@angular/common';
import { CommonService } from '../../../service/common.service';
import { UserBranchMappingComponent } from '../../onboard-entity/user-branch-mapping/user-branch-mapping.component';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';


type Branch = {
  id: number;
  name: string;
  parentID: number | null;
  branchType: string;
  branchStatus: string;
  fullName: string | null;
  children?: Branch[];
};

type clraBranch = {
  parentID: number | null;
  id: number;
  name: string | null;
  level: number;
  type: string | null;
  children: clraBranch[];
};

@Component({
  selector: 'app-add-new-user-branch-mapping-popup',
  standalone: true,
  imports: [DialogModule, FormsModule, AlertPopupComponent, ReactiveFormsModule, ComboBoxComponent, MultiSelectModule, NgClass, TreeViewModule, MultiSelectTreeComponent, LabelModule, DropDownsModule, NgIf],
  templateUrl: './add-new-user-branch-mapping-popup.component.html',
  styleUrl: './add-new-user-branch-mapping-popup.component.css'
})
export class AddNewUserBranchMappingPopupComponent {
  form!: FormGroup
  @Output() close = new EventEmitter<void>();
  @ViewChild('dialogTitle') dialogTitle!: ElementRef;
  public selectionType = 'Application'
  public defaultAct: any = 'Select Act';
  public defaultBranch: any = 'Select Branches';
  public defaultUser: any = 'Select User'
  selectedUser?: any
  selectedAct: { name: string, id: string } | null = { name: 'Select', id: 'Select' };
  allUser: any[] = []
  public selectedBranches: any[] = [];
  public openUserBranch: boolean = false;
  popupSettings: any = { width: '280px' };
  public userId: number = 0
  public branchId: number = 0
  public actType: string | null = null;
  alertData = { alertMessage: '', alertVisible: '' }
  Acts: { name: string, id: string }[] = [
    { name: 'Shops and Establishment Allied ', id: 'SEA' },
    { name: 'Contract Labour Regulation and Abolition Act', id: 'CLRA' }]
  public showValidationErrors = false;
  public BranchDropdown: Branch[] = [];
  public clraBranchDropdown: clraBranch[] = [];
  commaSeparatedIds: string = '';


  constructor(private userBranchMappingService: UserBranchMappingService, private fb: FormBuilder, private user: UserService, private commonService: CommonService, private userBranchMapping: UserBranchMappingComponent, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.loadUser();
    this.form = this.fb.group({
      branches: ['', [Validators.required]],
    })
  }

  ngAfterViewInit() {
    const titleElement = this.dialogTitle.nativeElement;
    titleElement.style.textAlign = 'center';
    titleElement.style.width = '100%';
  }

  validateForm(): boolean {
    const isValid = !!(
      this.selectedAct &&
      this.selectedUser &&
      this.commaSeparatedIds
    );

    this.showValidationErrors = !isValid;
    return isValid;
  }

  saveUserMapping() {
    if (this.form.invalid && !this.validateForm()) {
      this.form.markAllAsTouched();
      return;
    }

    const payload: UserBranchMappingPayload = {
      userId: this.selectedUser.id,
      branchIds: this.commaSeparatedIds,
      entityId: this.user.getClientId(),
      custId: this.user.getCustomerId()
    };

    console.log('data going for saving', payload);

    this.userBranchMappingService.saveUserBranchMapping(payload).subscribe({
      next: (response) => {
        console.log('Save user branch mapping response:', response);
        if (response.status === 200) {
          console.log('Data saved successfully');
          this.alertData = { alertMessage: 'User Branch mapped successfully...', alertVisible: 'success' };
          this.userBranchMapping.loadEntityBranchUserMapping();
          this.cd.detectChanges();
        }
      },
      error: (error) => {
        console.error('Error saving user branch mapping:', error);
        this.alertData = { alertMessage: 'Failed to save user branch mapping', alertVisible: 'error' };
      }
    });
  }


  mapUser() {
    this.openUserBranch = true;
  }
  public tagMapper(tags: any[]): any[] {
    return tags.length < 3 ? tags : [tags];
  }

  clraTagMapper(tags: any[]): any[] {
    return tags.length < 3 ? tags : [tags];
  }

  OnMultiSelectChange(value: any) {
    if (Array.isArray(value)) {
      this.commaSeparatedIds = value.map(item => item.id).join(',');
      this.form.get('branches')?.patchValue(value);
      console.log(this.commaSeparatedIds);
    } else {
      console.error('Expected an array of selected items.');
    }
  }
  loadUser() {
    const custId = this.user.getCustomerId();
    console.log('customerid', custId)
    let usertype = '';
    if (this.selectionType === 'Application') {
      usertype = 'Application';
    } else if (this.selectionType === 'Management') {
      usertype = 'Management';
    }
    console.log('usertype', usertype);
    this.userBranchMappingService.getAllUserMasterForPopup(custId, usertype).subscribe({
      next: (response) => {
        console.log('user data', response);
        const data = response.body
        if (data && Array.isArray(data)) {
          this.allUser = data.map((item: any) => ({
            id: item.id,
            name: item.userName
          }));
          this.defaultUser = 'Select User';
          console.log('User data:', this.allUser);
        }
      }
    })
  }
  getBranchDropdownData() {
    const entityId = this.user.getClientId();
    if (this.selectedAct?.id === 'SEA') {
      this.userBranchMappingService.getBranchDropdown(entityId).subscribe({
        next: (response) => {
          console.log('branch dropdown data', response);
          const data = response.body
          if (data && Array.isArray(data)) {
            this.BranchDropdown = this.buildHierarchy(data);
            const maxLength = this.calculateMaxTextLength(this.BranchDropdown);

            const calculatedWidth = Math.max(280, maxLength * 10);
            this.popupSettings = { width: `${calculatedWidth}px` };
          } else {
            console.error('Unexpected data format', data);
          }
        }
      })
    }

    else if (this.selectedAct?.id === 'CLRA') {
      this.commonService.getClraType(entityId).subscribe({
        next: (response) => {
          console.log('get clra type', response);
          if (response.body.result === 'CAC') {
            this.userBranchMappingService.getCLRABranchDropdown(entityId).subscribe({
              next: (response) => {
                console.log('CLRA branch dropdown data', response);
                const data = response.body
                if (data && Array.isArray(data)) {
                  this.clraBranchDropdown = this.mapHierarchy(data);
                  const maxLength = this.calculateMaxTextLengthclra(this.clraBranchDropdown);

                  const calculatedWidth = Math.max(280, maxLength * 10);
                  this.popupSettings = { width: `${calculatedWidth}px` };
                } else {
                  console.error("Unexpected data format", data);
                }
              }
            })
          }

          else if (response.body.result === 'CAP') {
            this.userBranchMappingService.getCLRA_PEBranchDropdown(entityId).subscribe({
              next: (response) => {
                console.log('CLRA_PE branch dropdown data', response);
                const data = response.body
                if (data && Array.isArray(data)) {
                  this.clraBranchDropdown = this.mapHierarchy(data);
                  const maxLength = this.calculateMaxTextLengthclra(this.clraBranchDropdown);

                  const calculatedWidth = Math.max(280, maxLength * 10);
                  this.popupSettings = { width: `${calculatedWidth}px` };
                } else {
                  console.error("Unexpected data format", data);
                }
              }
            })
          }
        }
      })
    }
  }
  calculateMaxTextLength(data: Branch[]): number {
    let maxLength = 0;

    const findMaxLength = (items: Branch[]) => {
      items.forEach(item => {
        maxLength = Math.max(maxLength, item.name.length);
        if (item.children && item.children.length) {
          findMaxLength(item.children);
        }
      });
    };

    findMaxLength(data);
    return maxLength;
  }
  calculateMaxTextLengthclra(data: clraBranch[]): number {
    let maxLength = 0;

    const findMaxLength = (items: clraBranch[]) => {
      items.forEach(item => {
        const nameLength = item.name ? item.name.length : 0;
        maxLength = Math.max(maxLength, nameLength);
        if (item.children && item.children.length) {
          findMaxLength(item.children);
        }
      });
    };
    findMaxLength(data);
    return maxLength;
  }
  private mapHierarchy(items: any[]): clraBranch[] {
    return items.map((item: any) => {
      const hasChildren = item.children && item.children.length > 0;
      console.log(`Processing item ID: ${item.id}, Name: ${item.name}, Has children: ${hasChildren}`);

      return {
        parentID: item.parentID,
        id: item.id,
        name: item.name || "Unknown",
        level: item.level,
        type: item.type,
        children: hasChildren ? this.mapHierarchy(item.children) : []
      };
    });
  }


  buildHierarchy(data: Branch[]): Branch[] {
    const grouped: { [key: number]: Branch[] } = data.reduce((acc, item) => {
      (acc[item.parentID || -1] = acc[item.parentID || -1] || []).push(item);
      return acc;
    }, {} as { [key: number]: Branch[] });

    function buildTree(parentID: number | null): Branch[] {
      return (grouped[parentID || -1] || []).map(item => ({
        ...item,
        children: buildTree(item.id)
      }));
    }
    return buildTree(null);
  }

  DropdownUserChange(value: any) {
    console.log('user value', value);
    this.selectedUser =  {id:value.id,name:value.name}
  }

  DropdownActChange(value: any) {
    console.log('Act value', value);
    this.selectedAct = {id:value.id,name:value.name}
    this.getBranchDropdownData();
  }
  onRadioChange(value: string) {
    this.selectionType = value;
    this.selectedAct=null
    this.selectedUser=null
    this.selectedBranches=[]
    this.loadUser();
  }
  public closePopup(): void {
    this.close.emit();
  }
}
