import { Component } from '@angular/core';
import { DropDownsModule, MultiSelectModule, MultiSelectTreeComponent } from '@progress/kendo-angular-dropdowns';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { GridModule } from '@progress/kendo-angular-grid';
import { NgFor, NgIf } from '@angular/common';
import { WorkspaceService } from '../../../service/workspace.service';
import { FormGroup } from '@angular/forms';
import { StatusReportService } from '../../../service/status-report.service';
import { UserService } from '../../../service/user.service';
import { ChartsModule, KENDO_CHART } from '@progress/kendo-angular-charts';
import { IntlService } from '@progress/kendo-angular-intl';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';

type Branch = {
  id: number;
  name: string;
  parentID: number | null;
  branchType: string;
  branchStatus: string;
  fullName: string | null;
  children?: Branch[];
};
@Component({
  selector: 'app-lc-my-reports-monthly',
  standalone: true,
  imports: [MultiSelectTreeComponent, DropdownListComponent, TreeViewModule, 
    DropDownsModule, MultiSelectModule, DropdownListComponent, GridModule, NgFor, NgIf, KENDO_CHART, ChartsModule, AlertPopupComponent],
  templateUrl: './lc-my-reports-monthly.component.html',
  styleUrl: './lc-my-reports-monthly.component.css'
})
export class LCMyReportsMonthlyComponent {
  public BranchDropdown: Branch[] = [];
  commaSeparatedIds: string = '';
  selectedMonth = { text: 'select month', value: '' };
  MonthDropdownData : any [] = [];
  popupSettings: any = { width: '280px' }; 
  selectedYear: { text: any; value: any } = { text: 'Year', value: 'Year' };
  selectedPeriod: { text: any; value: any } = {
    text: 'Period',
    value: 'Period',
  };
  years: any[] = [];
  type: any;
  period: any[] = [];
  form!: FormGroup;
  EntityID!: string;
  public pieData = [
    { category: "Complied", value: '', color:'red'},
    { category: "Not Complied", value: '', color : '#72bf6a' },
  ];
  labelContent: any;
  compliedData: number[] = [];
  notCompliedData: number[] = [];
  categories: string[] = [];
  UserID:any;
  CustomerID:any;
  alertData = { alertMessage: '', alertVisible: '' };
  constructor(private workspace: WorkspaceService, private StatusReportService: StatusReportService,private user: UserService, private intl: IntlService){ }
  ngOnInit(): void {
    this.type = 'Months';
    this.getYears();
    this.getPeriods();
    this.EntityID = this.user.getClientId();
    this.UserID = this.user.getUserId();
    this.CustomerID = this.user.getCustomerId();
    this.getBranchDropdownData();
    console.log("entity", this.EntityID);
  }
  OnMultiSelectChange(value: any) {
    if (Array.isArray(value)) {
        this.commaSeparatedIds = value.map(item => item.id).join(',');
    } else {
        console.error('Expected an array of selected items.');
    }
}
onDropdownValueChange(value: any, type: string) {
  if (type == 'activity') {
    // Update selectedAct with selected dropdown value and ensure both text and value are set
    this.selectedMonth = { text: value.text, value: value.value };
  }
}
getYears(): void {
  const currentYear = new Date().getFullYear();
  this.years = [];
  for (let year = currentYear; year >= 2017; year--) {
    this.years.push({ text: year.toString(), value: year });
  }
}

onYearChange(value: any): void {
  this.selectedYear = { text: value.text, value: value.value };
  if(this.selectedYear.value != 'Year' && this.selectedPeriod.value != 'Period'){
    this.GetMonthlyComplianceSummary();
  }
}

getPeriods(): void {
  this.workspace.getcompliance(this.type).subscribe({
    next: (response) => {
      const data = response.body;
      console.log('Period', data);
      this.period = data.map((item: any) => ({
        text: item.text,
        value: item.value,
      }));
    },
  });
}

onPeriodChange(value: any): void {
  this.selectedPeriod = { text: value.text, value: value.value };
  if(this.selectedPeriod.value != 'Period' && this.selectedYear.value != 'Year'){
    this.GetMonthlyComplianceSummary();
  }
}
public tagMapper(tags: any[]): any[] {
  return tags.length < 3 ? tags : [tags];
}
getBranchDropdownData() {
  this.StatusReportService.getBranchDropdown('AVATCGEN').subscribe({
      next: (response) => {
          const data = response.body;
          if (response && Array.isArray(data)) {
              this.BranchDropdown = this.buildHierarchy(data);

              const maxLength = this.calculateMaxTextLength(this.BranchDropdown);
              
              const calculatedWidth = Math.max(280, maxLength * 10); 
              this.popupSettings = { width: `${calculatedWidth}px` };
          } else {
              console.error('Unexpected data format', response);
          }
      },
      error: (error) => {
          console.error('Error fetching Branch Dropdown data', error);
      }
  });
}
buildHierarchy(data: Branch[]): Branch[] {
  const grouped: { [key: number]: Branch[] } = data.reduce((acc, item) => {
    (acc[item.parentID || -1] = acc[item.parentID || -1] || []).push(item);
    return acc;
  }, {} as { [key: number]: Branch[] });

  function buildTree(parentID: number | null): Branch[] {
    return (grouped[parentID || -1] || []).map(item => ({
      ...item,
      children: buildTree(item.id)
    }));
  }

  return buildTree(null);
}
calculateMaxTextLength(data: Branch[]): number {
  let maxLength = 0;

  const findMaxLength = (items: Branch[]) => {
      items.forEach(item => {
          maxLength = Math.max(maxLength, item.name.length); 
          if (item.children && item.children.length) {
              findMaxLength(item.children); 
          }
      });
  };

  findMaxLength(data);
  return maxLength;
}
GetMonthlyComplianceSummary() {
  const value = {
    branchID: 0,
    month: this.selectedPeriod.value,
    year: this.selectedYear.value,
    customerID: this.CustomerID,
    userID: this.UserID,
    profileID: this.UserID.toString()
  };

  this.StatusReportService.GetMonthlyComplianceSummary(value).subscribe({
    next: (response) => {
      if (response.length === 0) {
        // Show the popup when no data is found
        this.alertData = {
          alertMessage: 'No data found for the current selection.',
          alertVisible: ''
        };
      } else {
        console.log(response);
        this.categories = response.map(
          (item: { sM_Name: any; branch: any }) => `${item.sM_Name} / ${item.branch}`
        );
        this.compliedData = response.map((item: { compliedNo: any }) => item.compliedNo);
        this.notCompliedData = response.map((item: { notCompliedNo: any }) => item.notCompliedNo);

        const averageComplied = this.compliedData.reduce(
          (acc: number, val: number) => acc + val, 0) / this.compliedData.length;
        const averageNotComplied = this.notCompliedData.reduce(
          (acc: number, val: number) => acc + val, 0) / this.notCompliedData.length;

        this.pieData = [
          { category: 'Complied', value: `${averageComplied}%`, color: '#72bf6a' },
          { category: 'Not Complied', value: `${averageNotComplied}%`, color: 'red' },
        ];
      }
    },
    error: (err) => {
      console.error('Error fetching entity data', err);
    }
  });
}

public labelContentview = (e: any) => {
  const total = this.pieData.reduce((sum, item) => sum + parseFloat(item.value), 0);
  const percentage = (parseFloat(e.value) / total) * 100;
  const formattedPercentage = percentage % 1 === 0 ? `${percentage}%` : `${percentage.toFixed(2)}%`;
  return `${e.category}: ${formattedPercentage}`;
};
onPopupConfirmed(event: any) {
  this.alertData.alertVisible = ''; // Close the popup
}

onPopupCanceled() {
  this.alertData.alertVisible = ''; // Close the popup
}
}
