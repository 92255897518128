<div class="row colpadding0" style="margin-bottom: 10px; margin-top: 15px;"> 
    <div class="col-md-12 colpadding0">
        <button class="exportbtn" (click)="exportExcel()">
            <img src="../assets/vectors/ExportButton.svg" alt="">
        </button>
    </div>
</div>

<app-grid-action-item [gridData]="gridData" [columns]="parentColumns" [pageSize]="10" [pageable]="true" 
[filterable]="true" (buttonClick)="handleButtonClick($event)" >
</app-grid-action-item>

<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="250" [Height]="270" [Data]="alertData"
    (close)="closeAlert()"></app-alert-popup>