<div class="row colpadding0" style="margin-top:10px;" >
    <div class="col-12 d-flex justify-content-between align-items-center">
        <div class="add-button" >
            <button class="figma-btn-blue " (click)="addNew()" >
              <img src="../assets/vectors/Plus.svg" alt="">Add New
            </button>
        </div>
    </div>
    <div class="grid">
        <app-grid-action-item [gridData]="gridData" [columns]="parentColumns" [pageSize]="10" [pageable]="true"
            [actionColumnWidth]="'100px'" [filterable]="true" (buttonClick)="onActionClick($event)">
        </app-grid-action-item>
    </div>

    <div kendoDialogContainer *ngIf="openAdd">
    <app-master-scoping-add-popup (close)="closeAddDialog()">

    </app-master-scoping-add-popup>
    </div>
    <div kendoDialogContainer *ngIf="openEdit">
    <app-master-scope-edit-popup  [selectedEntity]="selectedEntity"  (close)="closeEditDialog()">

    </app-master-scope-edit-popup>
    </div>