import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class VariousReportService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  getVariousRpt(VarianceRpt:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/LC_Reports/Get_var_CodeDescription?Type=${VarianceRpt}`;
    return this.http.get<any>(url,{ observe: 'response' })
  }

 //st:7111/api/LC_Reports/Get_var_CodeDescription?Type=VarianceRpt'

  getComplianceFrequency(Frequency:string):Observable<HttpResponse<any>>{
    let url = `${this.apiUrl}/api/Common/Get_LC_CodeDescription?Type=${Frequency}`;
    return this.http.get<any>(url,{ observe: 'response' })
  }

  getMonth(Months:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Common/Get_LC_CodeDescription?Type=${Months}`;
    return this.http.get(url, { observe: 'response' });
  }
  
  GetVarianceDetail(payload:any): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/LC_Reports/GetVarianceDetails?ClientId=${payload.ClientId}&Month=${payload.Month}&Year=${payload.Year}&VarianceType=${payload.VarianceType}`;
    return this.http.get<any>(url,{ observe: 'response' })
    // 'https://localhost:7111/api/LC_Reports/GetVarianceDetails?ClientId=AVACORED&Month=3&Year=2024&VarianceType=InternationalWorker' 
  }

  GetLeaveSummaryReport(payload:any): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/LC_Reports/GetLeaveSummaryReport?ClientId=${payload.ClientId}&Month=${payload.Month}&Year=${payload.Year}&VarianceType=${payload.VarianceType}`;
    return this.http.get<any>(url,{ observe: 'response' })
  }
  GetPaycodeMasters(payload:any): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/LC_Reports/GetPaycode_Master?take=${payload.take}&skip=${payload.skip}&page=${payload.page}&pageSize=${payload.pageSize}`;
    return this.http.get<any>(url,{ observe: 'response' })
    // https://complianceapi.teamleaseregtech.com/api/Onboarding/GetPaycode_Master?Paycode=&take=10&skip=0&page=1&pageSize=10

  }
  GetVEVarianceDetail(payload:any): Observable<HttpResponse<any>> {//no api
    const url = `${this.apiUrl}/api/LC_Reports/GetVEVarianceDetails?ClientId=${payload.ClientId}&Month=${payload.Month}&Year=${payload.Year}&VarianceType=${payload.VarianceType}`;
    return this.http.get<any>(url,{ observe: 'response' })
  }
  GetYearlyAnnualReport(payload:any): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/LC_Reports/Get_YearlyAnnaulReturnReportsData?EntityId=${payload.EntityId}&yearID=${payload.yearID}&Frequency=${payload.Frequency}&Period=${payload.Period}`;
    return this.http.get<any>(url,{ observe: 'response' })

    //https://localhost:7111/api/LC_Reports/Get_YearlyAnnaulReturnReportsData?EntityId=GZ0RG&yearID=2023&Frequency=Half%20Yearly&Period=Half%20-Yearly1  }
}

ExportToExcelvariance(payload:any): Observable<HttpResponse<any>> {
  const url = `${this.apiUrl}/api/LC_Reports/ExportToExcelvariance?ClientId=${payload.ClientId}&Month=${payload.Month}&Year=${payload.Year}&VarianceType=${payload.VarianceType}`;
  return this.http.get<any>(url,{ observe: 'response' })
  //localhost:7111/api/LC_Reports/ExportToExcelvariance?ClientId=AVACORED&Month=3&Year=2024&VarianceType=InternationalWorker
}
ExportToExcelvarianceLeaveSummary(payload:any): Observable<HttpResponse<any>> {
  const url = `${this.apiUrl}/api/LC_Reports/ExportToExcelvarianceLeaveSummary?ClientId=${payload.ClientId}&Month=${payload.Month}&Year=${payload.Year}&VarianceType=${payload.VarianceType}`;
  return this.http.get<any>(url,{ observe: 'response' })
  //localhost:7111/api/LC_Reports/ExportToExcelvariance?ClientId=AVACORED&Month=3&Year=2024&VarianceType=InternationalWorker
}
GetPaycodeMasterExportToExcel(payload:any): Observable<HttpResponse<any>> {
  const url = `${this.apiUrl}/api/LC_Reports/GetPaycode_Master_ExportToExcel?Paycode=${payload.Paycode}`;
  return this.http.get<any>(url,{ observe: 'response' })
//https://localhost:7111/api/LC_Reports/GetPaycode_Master_ExportToExcel?Paycode=3455
}

}