<form [formGroup]="form">
    <div class="row colpadding0 row-setting">
        <div class="col-12 d-flex">
            <app-dropdown-list [data]="complianceType" [selectedValue]="selectedComplianceType" [width]="'200px'"
                textField="text" valueField="value" class="dropdown-setting"
               (valueChange)="onComplianceTypeChange($event)"
                [ngClass]="{'is-invalid': form.get('compliance')?.invalid && (form.get('compliance')?.touched)}">
            </app-dropdown-list>

            <app-dropdown-list [data]="period" [selectedValue]="selectedPeriod" [width]="'200px'" textField="text"
                valueField="value" class="dropdown-setting" (valueChange)="onPeriodChange($event)"
                formControlName="period"
                [ngClass]="{'is-invalid': form.get('period')?.invalid && (form.get('period')?.touched)}">
            </app-dropdown-list>

            <app-dropdown-list [data]="years" [selectedValue]="selectedYear" [width]="'200px'" textField="text"
                valueField="value" (valueChange)="onYearChange($event)" formControlName="year"
                [ngClass]="{'is-invalid': form.get('year')?.invalid && (form.get('year')?.touched)}">
            </app-dropdown-list>
            <button *ngIf="isApplyClicked && inputListData.length > 0 && selectedComplianceType.value==='Challan'" class="figma-btn-blue output-btn" type="submit" (click)="navigateToChalllan()" >
               Output (Challans)
            </button>
            <button class="figma-btn-white apply-btn ml-auto" type="submit" (click)="applyFilter()">
                <img src="../assets/vectors/ApplyFilter.svg" alt="">
            </button>
        </div>
    </div>

    <kendo-grid [data]="inputListData" [pageable]="false" class="grid custom-grid" >
        <kendo-grid-column title="Input" [width]="250"  >
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="col-12 collpadding0 inputName cell-content" [title]="dataItem.pageName">
              <p >
                {{ dataItem.pageName }}
                <sup class="text-danger" *ngIf="dataItem.isFileRequired === 1">*</sup>
              </p>
            </div>  
          </ng-template>
        </kendo-grid-column>
      
        <kendo-grid-column title="Template" [width]="80">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="col-12 collpadding0 center-align cell-content">
              <img src="../assets/vectors/ExcelDownload.svg" title="Download"
                   (click)="Template(dataItem.inputID)" alt="" class="svg-icon-btn">
            </div>
          </ng-template>
        </kendo-grid-column>
      
        <kendo-grid-column title="Upload" [width]="230">
          <ng-template kendoGridCellTemplate let-dataItem let-i="rowIndex">
            <div class="col-12 collpadding0 center-align cell-content">
              <span class="filename" style="color: #A0A0A0;">
                {{ fileNames[i] ? (fileNames[i].length > 10 ? fileNames[i].substring(0, 12) + '..' : fileNames[i]) : 'No file chosen' }}
              </span>
              <input type="file" formControlName="Template" #TemplateFile
                     (change)="onFileChange($event, i)" [id]="'fileInput' + i" style="display: none;" />
              <button class="figma-btn-gray" height="30px" (click)="TemplateFile.click()">Browse</button>
              <button class="figma-btn-blue" style="color: white; margin-left: 8px;" (click)="uploadFiles(i, dataItem.inputID)">Upload</button>
            </div>
          </ng-template>
        </kendo-grid-column>
      
        <kendo-grid-column title="Template Checker" [width]="100">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="center-align cell-content">
                <ng-container *ngIf="dataItem.isValidateTemplate === 3">
                  <img src="../assets/vectors/Incorrect_Icon.svg" alt="" class="greentick">
                </ng-container>
                <ng-container *ngIf="dataItem.isValidateTemplate === 1">
                  <img src="../assets/vectors/GreenTick.svg" alt="" class="greentick">
                </ng-container>
              </div>
          </ng-template>
        </kendo-grid-column>
      
        <kendo-grid-column title="Clarification Section" [width]="100">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="center-align cell-content">
            
              </div>
            </ng-template>
          </kendo-grid-column>
        
          <kendo-grid-column title="Final Input Uploaded" [width]="100">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="center-align cell-content">
                <button class="figma-btn-blue download-btn"
                        [disabled]="dataItem.isEnableDisableDownload === 0"
                        (click)="dataItem.isEnableDisableDownload === 1 ? downloadFile(dataItem.finalizedFile) : null">
                  Download
                </button>
              </div>
            </ng-template>
          </kendo-grid-column>
        
          <kendo-grid-column title="History" [width]="80" [headerClass]="'header-tooltip'">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="center-align cell-content">
                <img src="../assets/vectors/History.svg" title="History" class="svg-icon-btn" alt=""
                     style="cursor:pointer;" (click)="openHistoryPopup(dataItem.inputID)">
              </div>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
</form>

<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
  </div>

  <app-input-client-history-popup *ngIf="openHistory" [id]="inputId" [month]="month" [year]="year" (close)="closeHistoryPopup()"></app-input-client-history-popup>

  
  <app-alert-popup *ngIf="alertData.alertVisible" [Width]="250" [Height]="260" [Data]="alertData"
  (close)="closeAlert()"></app-alert-popup>
