import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface NoticesModel {
  ClientId: string;
  Branch: string;
  StateId: string;
  LocationId: string;
  ReceiptMode: string;
  NoticeReceivedDate: Date | null;
  NoticeTypeid: number;
  NoticeNumber: string;
  Risk: string;
  ResponseDueDate: Date | null;
  Remarks: string;
  Status: string;
  CreatedBy: number;
  CreatedDate: Date;
  AcknowledgementFile:File | null;
  NoticeDocumentfile:File | null

}
@Injectable({
  providedIn: 'root'
})
export class NoticePortalService {
  private baseUrl  = environment.apiUrl;
  constructor(private http:HttpClient) { }

  getGridData(clientId:string):Observable<HttpResponse<any>>{
    const url = `${this.baseUrl}/api/Notices/NoticeDetailsClientWise?Entity=${clientId}`;
    return this.http.get(url, { observe: 'response' });
  }

  getEntityDropdown(userId:number):Observable<HttpResponse<any>>{
    const url = `${this.baseUrl}/api/Notices/GetNoticeClientList?UserId=${userId}`;
    return this.http.get(url, { observe: 'response' });
  }

  getStateDropdown(clientId:string):Observable<HttpResponse<any>>{
    const url = `${this.baseUrl}/api/Notices/GetNoticeState?clientId=${clientId}`;
    return this.http.get(url, { observe: 'response' });
  }
  getLocationDropdown(status:string,selectedItem:string,state:string,clientId:string):Observable<HttpResponse<any>>{
    const url = `${this.baseUrl}/api/Notices/GetNoticeLocation?Status=${status}&SelectedItem=${selectedItem}&State_code=${state}&Clientid=${clientId}`;
    return this.http.get(url, { observe: 'response' });
  }
  getBranchDropdown(selectedItem:string,clientId:string,state:string,location:string):Observable<HttpResponse<any>>{
    const url = `${this.baseUrl}/api/Notices/GetNoticeBranchForClient?SelectedItem=${selectedItem}&ClientID=${clientId}&State_code=${state}&Location_Code=${location}`;
    return this.http.get(url, { observe: 'response' });
  }

  getNoticeDropdown():Observable<HttpResponse<any>>{
    const url = `${this.baseUrl}/api/Notices/NoticeTypeList`;
    return this.http.get(url, { observe: 'response' });
  }

  saveNotice(formData: FormData): Observable<HttpResponse<any>> {
    console.log('Notice in API', formData);
    const url = `${this.baseUrl}/api/Notices/SaveNotice`;
    return this.http.post(url, formData, { observe: 'response' });
  }
  fetchFile(filepath:string): Observable<Blob> {
    const url = `${this.baseUrl}/api/Notices/NoticePreview?filepath=${filepath}`;
    return this.http.post(url, null, { responseType: 'blob' });
  }
}
