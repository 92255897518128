import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EsicreportService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  getMonth(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/ESIC/GetMonthsForddl`;
    return this.http.get(url, { observe: 'response' });
  }

  getYear(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/ESIC/GetYearsForddl`;
    return this.http.get(url, { observe: 'response' });
  }

  getMasterGridData(APIName: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/ESIC/${APIName}`;
    return this.http.get(url, { observe: 'response' });
  }

  getESICMasterReportBlobLink(APIName: string): Observable<any> {
    const url = `${this.apiUrl}/api/ESIC/${APIName}`;
    return this.http.get(url, {});
  }

  getReportGridData(EntityName: string, Month: number, Year: number, ActionType: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/ESIC/GetTICData?EntityId=${EntityName}&Month=${Month}&Year=${Year}&ActionType=${ActionType}`;
    return this.http.get(url, { observe: 'response' });
  }

  getESICReportBlobLink(EntityName: string, Month: number, Year: number, ActionType: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/ESIC/TICData_ExportToExcel?EntityId=${EntityName}&Month=${Month}&Year=${Year}&ActionType=${ActionType}`;
    return this.http.get(url, { observe: 'response' });
  }

}
