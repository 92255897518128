import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { CriticalDocumnetsService } from '../../../service/critical-documnets.service';
import { UserService } from '../../../service/user.service';
import { NgClass, NgIf } from '@angular/common';
import { BlobService } from '../../../service/blob.service';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';

@Component({
  selector: 'app-critica-documents-add-new-file',
  standalone: true,
  imports: [DialogModule, NgIf, NgClass, ReactiveFormsModule,AlertPopupComponent],
  templateUrl: './critica-documents-add-new-file.component.html',
  styleUrl: './critica-documents-add-new-file.component.css'
})
export class CriticaDocumentsAddNewFileComponent {
  @Output() close = new EventEmitter<void>();
  @Input() FolderId!:any;
  file: File | null = null;
  dropzoneMessage: string = "Drag your files here or ";
  selectedFile?: File;
  form!: FormGroup;
  FolderName!: string;
  loading = false; 
  CustomerId!:number;
  userid!:number;
  EntityId!:string;
  public alertData = { alertMessage: '', alertVisible: '' };

  constructor(private CriticalDocumentsService: CriticalDocumnetsService, private user: UserService, private fb: FormBuilder,private blobService: BlobService) { }
  ngOnInit(): void {
    this.CustomerId = this.user.getCustomerId();
    this.userid = this.user.getUserId();
    this.EntityId = this.user.getClientId();

  }
  @ViewChild('dialogTitle') dialogTitle!: ElementRef;
  ngAfterViewInit() {
    const titleElement = this.dialogTitle.nativeElement;
    titleElement.style.textAlign = 'center';
    titleElement.style.width = '100%';
  }
  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drop your file here or";
  }
  
  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneMessage = "Drag your files here or ";
  }
  
  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      if (this.validateFile(file)) {
        this.file = file;
        this.selectedFile = this.file;
      } else {
        this.alertData = { alertMessage: 'Unsupported file format. Please select a valid file.', alertVisible: 'error' };
      }
      event.dataTransfer.clearData();
    }
  }
  private validateFile(file: File): boolean {
    const allowedExtensions = ['pdf', 'docx', 'doc', 'zip', 'rar', 'txt', 'jpg', 'png', 'xls', 'xlsx', 'csv'];
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    return fileExtension ? allowedExtensions.includes(fileExtension) : false;
  }
  public onFileSelect(event: Event): void {
    debugger
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
      const file = input.files[0];
      if (this.validateFile(file)) {
        this.selectedFile = file;
      } else {
        this.alertData = { alertMessage: 'Unsupported file format. Please select a valid file.', alertVisible: 'error' };
      }
    }
  }

  public uploadFiles(): void {
    const PermissionType='3'
    if (this.selectedFile) {
      const formData: FormData = new FormData();
      formData.append('file', this.selectedFile, this.selectedFile.name);
      formData.append('folderid', this.FolderId);
      formData.append('CustomerID', this.CustomerId.toString());
      formData.append('EntityId', this.EntityId);
      formData.append('Userid', this.userid.toString());
      formData.append('PermissionType', PermissionType);
      this.loading = true; 
      this.CriticalDocumentsService.uploadFile(formData).subscribe({
        next: (response) => {
          console.log(response);
          if(response.body.result==1){
            this.alertData = { alertMessage: 'File Uploaded Successfully', alertVisible: 'success' };
            this.loading = false; 
          }else {
            this.loading = false; 
            this.alertData = { alertMessage: 'Failed to Upload', alertVisible: 'error' };
          }
        },
        error: (error) => {
          console.log(error);
          this.selectedFile=undefined;
          this.loading = false; 
          this.alertData = { alertMessage: 'Invalid Template File!', alertVisible: 'error' };
        }
      });
    } else {
      this.alertData = { alertMessage: 'No File Selected', alertVisible: 'error' }; 
    }
  }

  closeAlert() {
    if(this.alertData.alertVisible=='success'){
      this.close.emit();
    }
    this.alertData={alertMessage:'',alertVisible:''};
  }

  closePopup() {
    this.close.emit();
  }
}
