<div class="col-12 " style="display: flex;margin-top: 10px;">
  <div class="col-md-8 colpadding0">
    <app-dropdown-list [data]="ClientDropdownData" [selectedValue]="selectedClient" [width]="'250px'" textField="text"
      valueField="value" style="margin-top:16px;" (valueChange)="onDropdownValueChange($event)">
    </app-dropdown-list>
  </div>
  <div class="col-md-4 colpadding0" style="display:flex;justify-content: end;">

    <button class="figma-btn-blue " (click)="openAddNewPopup()">
      <img src="../assets/vectors/Plus.svg" alt="">Create New Po
    </button>
  </div>
</div>



<!-- [formGroup]="myGroup"  -->
<table class="table Upload-table table-border" style="margin-top:10px;">
  <thead>
    <tr>
      <th style="width:500px;">Client ID</th>
      <th>Po type</th>
      <th>Action</th>

    </tr>
  </thead>
  <tbody>
    <!-- <tr *ngFor="let input of GetClientpODetail; let i = index">
                <td class="align" style="display: flex;justify-content: center;">
                    <div class="col-12 collpadding0 inputName">
                        <p style="padding-top: 6px;">{{ input.pO_ClientId  }}
                           
                        </p>
                    </div>
                </td>
                <td class="align">
                    <p style="padding-top: 6px;">{{ input.poType }}
                    </p>
                </td>
                <td class="align">
                    <div class="col-12 collpadding0" >
                      
                        <button class="figma-btn-white apply-btn" style="width:102px;" (Click)="markActive()">
                            Mark InActive
                        </button>
                      <button (click)="fun()">check</button>
                        
                    </div>
                </td>
               
            </tr> -->

    <tr *ngIf="GetClientpODetail">
      <td class="align" style="display: flex;justify-content: center;">
        <div class="col-12 collpadding0 inputName">
          <p style="padding-top: 6px;">{{ GetClientpODetail.clientId }}</p>
        </div>
      </td>
      <td class="align">
        <p style="padding-top: 6px;">{{ GetClientpODetail.pO_Type }}</p>
      </td>
      <td class="align">
        <div class="col-12 collpadding0">
          <button class="figma-btn-blue upload-btn" (click)="update()">
            Update
          </button>
          <button class="figma-btn-white apply-btn" style="width:102px;" (click)="markActive()">
            Mark InActive
          </button>
        </div>
      </td>
    </tr>
    <tr *ngIf="!GetClientpODetail">
      <td colspan="3" style="text-align: center;">
        No records available.
      </td>
    </tr>

  </tbody>
</table>



<div *ngIf="isAddNewVisible" class="popup-overlay">
  <app-add-edit-podetails-popup *ngIf="isAddNewVisible" [data]="rowData" (close)="handleClosePopup()">

  </app-add-edit-podetails-popup>
</div>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="250" [Height]="250" [id]="deleteId" [Data]="alertData"
  (close)="closePopup()" (confirm)="GetMarkAsInActives($event)">
</app-alert-popup>