<form [formGroup]="form">
    <div class="row colpadding0 row-setting">
        <div class="col-12 d-flex align-items-center" style="flex-wrap:nowrap;">

            <app-dropdown-list [data]="complianceType" [width]="'200px'" [selectedValue]="selectedComplianceType"
                textField="text" valueField="value" class="dropdown-setting"
                (valueChange)="onComplianceTypeChange($event)" formControlName="compliance"
                [ngClass]="{'is-invalid': form.get('compliance')?.invalid && (form.get('compliance')?.touched)}">
            </app-dropdown-list>

            <app-dropdown-list [width]="'200px'" [data]="frequency" [selectedValue]="selectedFrequency" textField="text"
                valueField="value" (valueChange)="onFrequencyTypeChange($event)" formControlName="frequency"
                class="dropdown-setting"
                [ngClass]="{'is-invalid': form.get('frequency')?.invalid && (form.get('frequency')?.touched)}"
                [isDisabled]="selectedComplianceType.value==='Register' || selectedComplianceType.value==='Challan' || selectedComplianceType.value==='Compliance'">
            </app-dropdown-list>

            <app-dropdown-list [data]="Act" [width]="'200px'" [selectedValue]="selectedAct" textField="text"
                valueField="value" class=" dropdown-setting" (valueChange)="onActTypeChange($event)"
                formControlName="act"
                [ngClass]="{'is-invalid': form.get('act')?.invalid && (form.get('act')?.touched)}">
            </app-dropdown-list>

            <app-dropdown-list [data]="years" [selectedValue]="selectedYear" [width]="'150px'" textField="text"
                valueField="value" class="dropdown-setting" (valueChange)="onYearChange($event)" formControlName="year"
                [ngClass]="{'is-invalid': form.get('year')?.invalid && (form.get('year')?.touched)}">
            </app-dropdown-list>

            <app-dropdown-list [data]="period" [selectedValue]="selectedPeriod" [width]="'120px'" textField="text"
                valueField="value" (valueChange)="onPeriodChange($event)" formControlName="period"
                [ngClass]="{'is-invalid': form.get('period')?.invalid && (form.get('period')?.touched)}">
            </app-dropdown-list>

        </div>
    </div>
    <div class="singlerow" style="margin-top: 10px;">
        <div class="left-panel searches">
            <!-- <kendo-multiselecttree style="padding-right: 8px;" class="f-textbox multi-box" #multiselectTree
                [placeholder]="filePlaceholder" kendoMultiSelectTreeExpandable
                [kendoMultiSelectTreeHierarchyBinding]="files" formControlName="files" textField="name" valueField="id"
                childrenField="children" [tagMapper]="tagMapper" (valueChange)="onFileChange($event)"
                [expandedKeys]="['0']" [popupSettings]="popupSettings"
                [ngClass]="{'is-invalid': form.get('files')?.invalid && (form.get('files')?.touched)}">
            </kendo-multiselecttree> -->

            <kendo-multiselecttree class="f-textbox multi-box" #multiselectTreeBranches
                [placeholder]="entityPlaceholder" kendoMultiSelectTreeExpandable
                [kendoMultiSelectTreeHierarchyBinding]="selectedAct.value === 'SEA'|| selectedAct.value==='FACT' || selectedComplianceType.value==='Return' || selectedComplianceType.value==='Challan' ? BranchDropdown : clraBranchDropdown"
                [value]="selectedBranches" formControlName="branches" childrenField="children" textField="name"
                valueField="id"
                [tagMapper]="selectedAct.value === 'SEA'||selectedAct.value==='FACT' ? tagMapper : clraTagMapper"
                (valueChange)="onEntityChange($event)" [expandedKeys]="['0']" [popupSettings]="popupSettings"
                [ngClass]="{'is-invalid': form.get('branches')?.invalid && (form.get('branches')?.touched)}">
            </kendo-multiselecttree>

        </div>

        <div class="right-panel">
            <button *ngIf="Download" class="figma-btn-blue upload-btn " (click)="downloadFiles()">
                Download
              </button>
            <div>
                <button class="figma-btn-white" type="submit"  (click)="applyFilter() ">
                    <img src="../assets/vectors/ApplyFilter.svg" alt="">
                </button>
            </div>
            <div>
                <button class="figma-btn-white" (click)="clearFilter()">
                    <img src="../assets/vectors/ClearFilter.svg" alt="">
                </button>
            </div>
          
            <!-- <div *ngIf="selectedItems.length > 0" style="padding-left: 8px;">
                <button class="figma-btn-blue" (click)="getOutputJson()">Proceed</button>
                <button  class="figma-btn-blue" (click)="GetInputfileisProcess()" >Proceed</button> 
            </div> -->
        </div>

    </div>

</form>

<div *ngIf="selectedComplianceType.value==='Register'" class="grid">
    <kendo-grid class="custom-grid" [data]="registerGridData" [columnMenu]="true" [pageSize]="pageSize" [pageable]="true"
        (pageChange)="pageChange($event)" [sortable]="true" [skip]="skip" [resizable]="true">
        <kendo-grid-column [width]="45" >
            <ng-template kendoGridHeaderTemplate>
                <div class="header-checkbox">
                    <input type="checkbox" [(ngModel)]="allSelected" (change)="toggleSelectAll($event, 'Register')" />
                </div>
            </ng-template>

            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="checkbox-container">
                    <input type="checkbox" [checked]="dataItem.selected" [disabled]="dataItem.disabled"
                        (change)="onItemSelectionChange(dataItem, 'Register')" />
                </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="entity" title="Entity/Client" [width]="80" class="cell-content"></kendo-grid-column>
        <kendo-grid-column field="cM_State" title="State" [width]="60" class="cell-content"></kendo-grid-column>
        <kendo-grid-column field="lM_Name" title="Location" [width]="80" class="cell-content"></kendo-grid-column>
        <kendo-grid-column field="avacoM_BranchName" title="Branch" [width]="80" class="cell-content"></kendo-grid-column>
        <kendo-grid-column field="monthName" title="Month" [width]="60" class="cell-content"></kendo-grid-column>
        <kendo-grid-column field="lM_PayrollYear" title="Year" [width]="60" class="cell-content"></kendo-grid-column>
        <kendo-grid-column field="establishmentType" title="Establishment type" [width]="120" class="cell-content"></kendo-grid-column>
   
        <kendo-grid-column field="Action" title="Action" [width]="60">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="action-icon" (click)="onActionClick(dataItem,'Input')">
                    <img src="../assets/vectors/DownloadBlue.svg" class="svg-icon"  >
                    <img src="../assets/vectors/EyeIcon.svg" alt="Action Icon" class="svg-icon"/>
                </div>
            </ng-template>
        </kendo-grid-column>
        <!-- <kendo-grid-column field="branchCount" title="Branch Count" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="branch-count-cell">
                    {{ dataItem.branchCount }}
                </div>
            </ng-template>
        </kendo-grid-column>-->
    </kendo-grid> 
</div>

<div *ngIf="selectedComplianceType.value==='Return'" class="grid">
    <kendo-grid class="custom-grid" [data]="returnGridData" [columnMenu]="true" [pageSize]="pageSize" [pageable]="true"
        (pageChange)="pageChange($event)" [sortable]="true" [skip]="skip" [resizable]="true">
        <kendo-grid-column [width]="50">
            <ng-template kendoGridHeaderTemplate>
                <div class="header-checkbox">
                    <input type="checkbox" style="margin-left: 10px;" [(ngModel)]="allSelected"
                        (change)="toggleSelectAll($event,'Return')" />
                </div>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="checkbox-container">
                    <input type="checkbox" style="margin-left: 10px;" [checked]="dataItem.selected"
                        [disabled]="dataItem.disabled" (change)="onItemSelectionChange(dataItem,'Return')" />
                </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="entity" title="Entity/Client" [width]="80" class="cell-content"></kendo-grid-column>
        <kendo-grid-column field="stateName" title="State" [width]="80" class="cell-content"></kendo-grid-column>
        <kendo-grid-column field="locationName" title="Location" [width]="80" class="cell-content"></kendo-grid-column>
        <kendo-grid-column field="SM_SectionName" title="Section" [width]="80" class="cell-content"></kendo-grid-column>
        <kendo-grid-column field="Action" title="Action" [width]="50" >
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="action-icon" (click)="onActionClick(dataItem,'Input')">
                    <img src="../assets/vectors/DownloadBlue.svg" class="svg-icon">
                    <img src="../assets/vectors/EyeIcon.svg" alt="Action Icon" class="svg-icon" />
                </div>
            </ng-template>
        </kendo-grid-column>
        <!-- <kendo-grid-column field="Action" title="Upload" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="action-icon" (click)="onActionClick(dataItem,'Upload')">
                    <img src="../assets/vectors/DownloadBlue.svg" alt="Action Icon"
                        style="cursor: pointer; width: 24px; height: 24px;" />
                </div>

            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="branchCount" title="Branch Count" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="branch-count-cell">
                    {{ dataItem.branchCount }}
                </div>
            </ng-template>
        </kendo-grid-column> -->
    </kendo-grid>

</div>

<div *ngIf="selectedComplianceType.value==='Challan'" class="grid">
    <kendo-grid class="custom-grid" [data]="challanGridData" [columnMenu]="true" [pageSize]="pageSize" [pageable]="true"
        (pageChange)="pageChange($event)" [sortable]="true" [skip]="skip" [resizable]="true">
        <kendo-grid-column [width]="45">
            <ng-template kendoGridHeaderTemplate>
                <div class="header-checkbox">
                    <input type="checkbox" [(ngModel)]="allSelected" (change)="toggleSelectAll($event,'Challan')" />
                </div>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="checkbox-container">
                    <input type="checkbox" class="checkbox" [checked]="dataItem.selected" [disabled]="dataItem.disabled"
                        (change)="onItemSelectionChange(dataItem,'Challan')" />
                </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="entity" title="Entity/Client" [width]="80" class="cell-content"></kendo-grid-column>
        <kendo-grid-column field="code" title="Code" [width]="80" class="cell-content"></kendo-grid-column>
        <kendo-grid-column field="monthName" title="Month" [width]="80" class="cell-content"></kendo-grid-column>
        <kendo-grid-column field="payrollYear" title="Year" [width]="80" class="cell-content"></kendo-grid-column>
        <kendo-grid-column field="Branches" title="Branches" [width]="80" class="cell-content">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="action-icon" (click)="onActionClick(dataItem,'Input')">
                    <img src="../assets/vectors/EyeIcon.svg" alt="Action Icon" class="svg-icon" >
                </div>
            </ng-template>
            <!-- (click)="onIconClick(icon.action, dataItem) -->
        </kendo-grid-column>
        <kendo-grid-column field="frequency" title="Working" [width]="70">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="action-icon" (click)="onActionClick(dataItem,'Input')">
                    <img src="../assets/vectors/DownloadBlue.svg"   class="svg-icon">
                    <img src="../assets/vectors/EyeIcon.svg" alt="Action Icon" class="svg-icon" />
                </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="frequency" title="Compliance" [width]="70">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="action-icon" (click)="onActionClick(dataItem,'Input')">
                    <img src="../assets/vectors/DownloadBlue.svg" class="svg-icon">
                    <img src="../assets/vectors/EyeIcon.svg" alt="Action Icon"class="svg-icon" />
                </div>
            </ng-template>
        </kendo-grid-column>
        <!-- <kendo-grid-column field="Action" title="Input File" [width]="120" [style]="{'text-align': 'center'}">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="action-icon" (click)="onActionClick(dataItem,'Input')">
                    <img src="../assets/vectors/eye_112_x2.svg" alt="Action Icon"
                        style="cursor: pointer; width: 24px; height: 24px;" />
                </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="branchCount" title="Branch Count" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="branch-count-cell">
                    {{ dataItem.branchCount }}
                </div>
            </ng-template>
        </kendo-grid-column> -->
    </kendo-grid>
</div>
<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>


<!--     
        <div kendoDialogContainer *ngIf="openUpload">
            <app-workspace-remanual-upload-popup (close)="closeUploadPopup()">
    
            </app-workspace-remanual-upload-popup>
        </div> -->
<app-alert-popup *ngIf="alertData.alertVisible" [Width]="250" [Height]="260" [Data]="alertData"
    (close)="closeAlert()"></app-alert-popup>