import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { KENDO_CHARTS, MarkerType, SeriesLabelsContentArgs, SeriesMarkers } from '@progress/kendo-angular-charts';

@Component({
  selector: 'app-cd-risk-summary',
  standalone: true,
  imports: [KENDO_CHARTS, CommonModule],
  templateUrl: './cd-risk-summary.component.html',
  styleUrl: './cd-risk-summary.component.css'
})
export class CdRiskSummaryComponent implements OnChanges {

  @Input() chartData: any = {};
  
  public model: any[] = [];

  public labelContent(e: SeriesLabelsContentArgs): string {
    return `${e.value}`;
  }

  chartMarker: SeriesMarkers = {
    borderRadius: 10,
    visible: true
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.chartData = changes['chartData']['currentValue']
    
    this.model = [
      {
        name: "Low",
        data: [
          {
            category: "Completed",
            value: this.chartData?.totalPieCompletedLOW,
            color: "#65B677",
          },
          {
            category: "Not Completed",
            value: this.chartData?.totalPieNotCompletedLOW,
            color: "#FA6462",
          },
          {
            category: "Not Applicable",
            value: this.chartData?.totalPieNotApplicableLOW,
            color: "#FED81F",
          },
        ],
      },
      {
        name: "Medium",
        data: [
          {
            category: "Completed",
            value: this.chartData?.totalPieCompletedMEDIUM,
            color: "#65B677",
          },
          {
            category: "Not Completed",
            value: this.chartData?.totalPieNotCompletedMEDIUM,
            color: "#FA6462",
          },
          {
            category: "Not Applicable",
            value: this.chartData?.totalPieNotApplicableMEDIUM,
            color: "#FED81F",
          },
        ],
      },
      {
        name: "High",
        data: [
          {
            category: "Completed",
            value: this.chartData?.totalPieCompletedHIGH,
            color: "#65B677",
          },
          {
            category: "Not Completed",
            value: this.chartData?.totalPieNotCompletedHIGH,
            color: "#FA6462",
          },
          {
            category: "Not Applicable",
            value: this.chartData?.totalPieNotApplicableHIGH,
            color: "#FED81F",
          },
        ],
      },
    ];
  }
}
