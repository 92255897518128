<kendo-dialog #dialogRef title="Entity Master Bulk Upload" (close)="closePopup()" [minWidth]="300" [height]="580"
    [width]="1200" class="custom-dialog">


    <div class="row colpadding0">
        <div class="row colpadding0" style="margin-bottom: 5px; margin-top: 10px;">
            <div class="col-md-5  align-items-center">
                <h4 style=" font-weight: 500;font-size: 12px;">
                    <span style="color: red; ">
                        Note<sup>*</sup>:
                    </span>  Please Check Excel Column Header To download Template
                    
                </h4>
            </div>
            <div class="col-md-7  align-items-center">
                <button class="figma-btn-blue" type="submit" style=" float: right;" (click)="resetData()">
                    Reset
                </button>
                <!-- <button class="figma-btn-blue" style="float: right; " (click)="downloadTemplate()"> -->
                    <img src="../assets/vectors/ExcelDownload.svg" class="svg-icon-btn" alt=""  style="float: right; " (click)="downloadTemplate()">
                    <!-- Download Template -->
                <!-- </button> -->
            </div>
        </div>
        <div class="col-md-5 ">
            <kendo-grid class="custom-grid" [data]="gridData" [resizable]="true">
                <kendo-grid-column field="colName" title="Header">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div [title]="dataItem. clientHeaderName" class="cell-content">
                            {{dataItem. clientHeaderName}}
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column [width]="110">
                    <ng-template kendoGridHeaderTemplate>
                        <div class="custom-tooltip" >
                            <input type="checkbox" [(ngModel)]="allSelected" (change)="toggleSelectAll($event)" />
                        </div>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="checkbox-container">
                            <input type="checkbox" [(ngModel)]="dataItem.selected" [disabled]="dataItem.disabled"
                                (change)="onItemSelectionChange(dataItem)" />
                        </div>
                    </ng-template>
                </kendo-grid-column>
            </kendo-grid>
        </div>

        <div class="col-md-7 colpadding0 ">
            <div class="col-12 custom-container">

                <label for="" class="custom-label">Upload Excel File</label>
            </div>
            <div class="drop-zone" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)"
                (drop)="onDrop($event)">
                <div *ngIf="!selectedFile" class="f-label" style="font-size: 22px;">
                    <div class="col-md-12" style="margin-top: 20px;">
                        <img src="../assets/vectors/cloud.svg" alt="">
                    </div>
                    <div class="col-md-8">
                        <label style="margin-left: 140px;">{{ dropzoneMessage }}</label>
                    </div>
                    <div class="col-md-4">
                        <label
                            style="color: #125AAB; text-decoration: underline; cursor: pointer; margin-left: -40px; font-weight: 500;">
                            Browse
                            <input type="file" style="display: none;" (change)="onFileSelect($event)"
                                accept=".xls,.xlsx,.csv" />
                        </label>
                    </div>
                </div>
                <p  style="margin-top: 70px;" *ngIf="selectedFile">{{ selectedFile.name }}</p>
            </div>
            
            <button kendoButton class="figma-btn-blue figma-popup custom-button"
                (click)="uploadFiles()">Upload</button>
        </div>
    </div>
</kendo-dialog>

<app-alert-popup *ngIf="alertData" [Width]="270" [Data]="alertData"
    (close)="closeAlert()"></app-alert-popup>