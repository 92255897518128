<kendo-dialog #dialogRef title="View Address" (close)="closeDialog()" [minWidth]="500" [width]="800" [height]="'auto'"
    class="custom-dialog">
    <div class="dialog-content">
        <kendo-grid [data]="gridData" [columnMenu]="true" [pageSize]="pageSize" [pageable]="true"
            (pageChange)="pageChange($event)" [skip]="skip">

            <kendo-grid-column field="dM_Name" title="Address Type" class="cell-content">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <span>{{ dataItem.dM_Name }}</span>
                </ng-template>
            </kendo-grid-column>

            <!-- <app-multi-select-dropdown [data]="dataItem.AddressOptions || []" [textField]="'addressName'"
                [valueField]="'uniqueID'" [width]="'514px'" [selectedValues]="dataItem.selectedOptions"
                [DefaultValue]="'Select Address'" (valueChange)="onValueChange($event, dataItem)">
            </app-multi-select-dropdown> -->
            <kendo-grid-column field="Address" title="Address" class="cell-content">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <app-dropdown-list [data]="dataItem.AddressOptions" [selectedValue]="dataItem.selectedOptions"
                        [width]="'310px'" [selectedValue]="defaultSelectedValueMonth" textField="addressName"
                        valueField="uniqueID" (valueChange)="onValueChange($event, dataItem)">
                    </app-dropdown-list>
                </ng-template>
            </kendo-grid-column>
            <!-- <kendo-multiselecttree
                #multiselectTree
                style="width:514px"
                kendoMultiSelectTreeExpandable
                [kendoMultiSelectTreeHierarchyBinding]="dataItem.AddressOptions"
                textField="addressName"
                valueField="uniqueID"
                childrenField="children"
                [value]="getSelectedOptionValues(dataItem)" 
                [tagMapper]="tagMapper"
                (valueChange)="onValueChange($event, dataItem)"
                [expandedKeys]="['0']"
                [popupSettings]="popupSettings"
                class="f-textbox multi-box custom-multiselect">
              </kendo-multiselecttree> -->



            <kendo-grid-column field="Action" title="Action" [width]="100">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <img src="../assets/vectors/save1.svg" alt="Save Icon" class="save-btn" (click)="onSave(dataItem)"
                        style="margin-left: 25px;" />
                </ng-template>
            </kendo-grid-column>

            <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
                <kendo-pager-next-buttons></kendo-pager-next-buttons>
                <kendo-pager-input></kendo-pager-input>
                <kendo-pager-info></kendo-pager-info>
                <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
            </ng-template>
        </kendo-grid>
    </div>
    <div *ngIf="loading" class="loader-overlay">
        <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
    </div>
    <app-alert-popup *ngIf="alertData.alertVisible" [Width]="270"  [Data]="alertData"
        (close)="closeAlert()"></app-alert-popup>
</kendo-dialog>