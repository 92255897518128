import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { GridDataResult, GridModule } from '@progress/kendo-angular-grid';
import { PremiseMasterService } from '../../../service/premise-master.service';
import { UserService } from '../../../service/user.service';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropDownListModule, DropDownsModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { FormsModule } from '@angular/forms';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { PageChangeEvent } from '@progress/kendo-angular-treelist';
import { NgIf } from '@angular/common';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { MultiSelectDropdownComponent } from '../../../components/multi-select-dropdown/multi-select-dropdown.component';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { LabelModule } from '@progress/kendo-angular-label';

interface AddressOption {
  uniqueID: string;
  isSelected: number;
  addressName: string;
}

@Component({
  selector: 'app-address-mapping',
  standalone: true,
  imports: [GridActionItemComponent, DialogModule, TreeViewModule, LabelModule, DropDownsModule, NgIf, MultiSelectDropdownComponent, DropdownListComponent, FormsModule, DropDownListModule, MultiSelectModule, InputsModule, GridModule, AlertPopupComponent, IndicatorsModule],
  templateUrl: './address-mapping.component.html',
  styleUrl: './address-mapping.component.css'
})
export class AddressMappingComponent implements OnInit {
  @Input() statecode: any;
  @Input() locationcode: any;
  @Input() branchId: any;
  @Input() branchName: any;
  @Output() close = new EventEmitter<void>();
  public gridData: GridDataResult = { data: [], total: 0 };
  public pageSize = 10;
  public skip = 0;
  public sizes = [5, 10, 20];
  public buttonCount = 5;
  Placeholder = 'Select Address'
  popupSettings: any = { width: '280px' };
  public alertData = { alertMessage: '', alertVisible: '' };
  loading = false;
  public defaultSelectedValueMonth = { uniqueID: 0, addressName: 'Select Address' };

  constructor(private premiseMasterService: PremiseMasterService, private user: UserService) { }

  ngOnInit(): void {
    this.loadGridData();
  }

  loadGridData(): void {
    this.loading = true;

    this.premiseMasterService.getAddressList(this.statecode, this.locationcode).subscribe({
      next: (res) => {
        const result = res?.body ?? [];

        this.gridData = {
          data: result.slice(this.skip, this.skip + this.pageSize),
          total: result.length
        };

        this.gridData.data.forEach((dataItem) => {
          this.loadDropdownData(dataItem);
        });

        this.loading = false;
      },
      error: (err) => {
        console.error('Error fetching address list:', err);
        this.loading = false;
      }
    });
  }

  loadDropdownData(dataItem: any): void {
    const clientId = this.user.getClientId();

    this.premiseMasterService.GetAddressList(this.statecode, this.locationcode, dataItem.dM_Value, clientId).subscribe({
      next: (res) => {
        console.log(res);
        
        if (res?.body?.length > 0) {
          dataItem.AddressOptions = [
            { uniqueID: 0, addressName: 'Select Address', isSelected: 0, avacomBranchId: null }, // Default option
            ...res.body
          ];
          console.log(res);

          const selectedOptions = dataItem.AddressOptions
            .filter((option: AddressOption) => option.isSelected === 1)
            .map((option: AddressOption) => ({
              uniqueID: option.uniqueID,
              addressName: option.addressName
            }));

          dataItem.selectedOptions = selectedOptions.length > 0
            ? selectedOptions[0]
            : { uniqueID: 0, addressName: 'Select Address', isSelected: 0, avacomBranchId: null };
        }
      },
      error: (err) => {
        console.error('Error fetching dropdown options:', err);
      }
    });
  }

  public tagMapper(tags: any[]): any[] {
    return tags.length < 1 ? tags : [tags];
  }
  getSelectedOptionValues(dataItem: any): any[] {
    return dataItem.selectedOptions?.map((option: any) => ({ uniqueID: option.uniqueID })) || [];
  }
  getSelectedValues(options: any[]): any[] {
    return options.filter(option => option.isSelected === 1).map(option => option.uniqueID);
  }

  onValueChange(selectedValues: any, dataItem: any): void {
    dataItem.Address = { uniqueID: selectedValues.uniqueID, addressName: selectedValues.addressName, isSelected: 1, avacomBranchId: null };
    dataItem.selectedOptions = { uniqueID: selectedValues.uniqueID, addressName: selectedValues.addressName, isSelected: 1, avacomBranchId: null };
    console.log('Updated dataItem:', dataItem);
  }


  onSave(dataItem: any): void {
    debugger;
    const requestBody = {
      addrtype: dataItem.dM_Value,
      addr: dataItem.selectedOptions.uniqueID,
      lcode: this.locationcode.toString(),
      stateid: this.statecode,
      ClientID: this.user.getClientId(),
      userid: this.user.getUserId(),
      branchname: this.branchName,
      branchid: this.branchId
    };
    console.log(requestBody);

    this.premiseMasterService.SaveAddressMapping(requestBody).subscribe({
      next: (res) => {
        debugger;
        this.alertData = { alertMessage: 'Save successfully', alertVisible: 'success' };
      },
      error: (err: any) => {
        console.error('Error saving address mapping:', err);
        this.alertData = { alertMessage: 'Server Error Occurred', alertVisible: 'error' };
      }
    });
  }



  pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadGridData();
  }

  public closeDialog(): void {
    this.close.emit();
  }

  public closeAlert(): void {
    this.loading = false;
    this.alertData = { alertMessage: '', alertVisible: '' };
    if (this.alertData.alertVisible == 'success') {
      this.closeDialog()
    }
  }

}
