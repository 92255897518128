<div class="sidebar" #sidebar>
    <div class="logo-details">
        <img [src]="logoImageUrl" [class]="logoImageClass" alt="TeamLease Logo">
        <span class="logo_name"></span>
    </div>
    <ul class="nav-links">
        <li *ngFor="let link of navLinks">
            <a [routerLink]="link.hrefurl" [ngClass]="{'active': link.name === 'Dashboard'}"
                (click)="onSidebarLinkClick(link.name)">
                <!-- <i [class]="link.icon"></i> -->
                <img [src]="'../assets/vectors/'+link.icon+'.svg'" alt="">
                <!-- <img src="../assets/vectors/Regulatory_Abstracts.svg"  alt=""> -->
                <span class="links_name">{{ link.name }}</span>
            </a>
        </li>
    </ul>
</div>
<section class="home-section">
    <nav class="navbar-fluid"
        style="display: flex; justify-content: space-between; align-items: center; position: relative; width: 100%; left: 0;">

        <div class="sidebar-button" style="color: #fff;">
            <i class="menu-btn" (click)="toggleSidebar(!sidebarActive)"><img style="width: 25px;" [src]="toggleimg"></i>
            <span class="dashboard" style="font-size: 15px;">{{CustomerName}}{{ClientId}}</span>
        </div>
        <div class="navbar-end" style="display: flex; align-items: center;">
            <span class="user-name" style="margin-right: 20px;color: white;">
                {{ firstName }} {{ lastName }}
            </span>
            <i class="bx " style="color: #fff; font-size: 24px; margin-right: 10px;"><img style="width: 35px;"
                    src="../assets/vectors/Profile.svg"></i>
            <i class="bx " style="color: #fff; font-size: 24px;"><img style="width: 35px;"
                    src="../assets/vectors/TransferNav.svg"></i>

        </div>
    </nav>
    <br />
    <div class="container-fluid">
        <div class="container-content">
            <!-- height: 89vh; -->
            <div class="container-fluid ">
                <ng-container
                    *ngIf="(this.currentRoute !== '/EntityDashboard' 
           && this.currentRoute !== '/CustomerMaster' && this.currentRoute !== '/ComplianceDashboard' && this.currentRoute !== '/UserMaster' && this.currentRoute !== '/Notices'&& this.currentRoute !== '/DocumentGeneration' && this.currentRoute !== '/OutputChallanCustomer' && this.currentRoute !== '/OutputChallanPerformer' )">

                    <app-tabs></app-tabs>

                    <span *ngIf="isActive">
                        <hr class="hr-fluid">
                    </span>
                </ng-container>
            </div>

            <!-- <div class="mb-4"></div> -->
            <router-outlet></router-outlet>
        </div>
    </div>
</section>