import { Injectable } from '@angular/core';


import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GstuploadService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {

  }
  getClientsGstMaster(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Invoice/GetClientsMaster`;
    // https://localhost:7111/api/Invoice/GetClientsMaster
    return this.http.get(url, {observe:'response'});
  }
  GetClientGSTReports(payload:any): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Invoice/GetClientGSTReport?ClientId=${payload.ClientId}`;
    https://localhost:7111/api/Invoice/GetClientGSTReport?clientid=AVACORED
    return this.http.get(url, {observe:'response'});
  }

  uploadFile(file: File, EntityID: string, UserID: string): Observable<any> {
    const url=`${this.apiUrl}/api/Invoice/Upload_Client_GST_Details`
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('EntityID',(EntityID).toString());
    formData.append('UserID', Number(UserID).toString());
    formData.append('HeaderTrackerId', Number(0).toString());
    // https://localhost:7111/api/Invoice/Upload_Client_GST_Details
    return this.http.post<any>(url, formData);
  }
  exportToExcel(payload:any) {
    const url = `${this.apiUrl}/api/Invoice/GetClientGSTReport?ClientId=${payload.ClientId}`;
    return this.http.get(url, { observe: 'response' });
  }


  ActivityDetailsExporttoExcel(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Invoice/ActivityDetails_ExporttoExcel`;

    // https://localhost:7111/api/Invoice/ActivityDetails_ExporttoExcel
    return this.http.get(url, {observe:'response'});
  }



}
