import { Component } from '@angular/core';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { NgIf } from '@angular/common';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { EcrReportService } from '../../../service/ecr-report.service';
import { BlobService } from '../../../service/blob.service';
import { EcrMasterAddEditorPopupComponent } from '../../popups/ecr-master-add-editor-popup/ecr-master-add-editor-popup.component';
import { EcrMasterBulkUploadComponent } from '../../popups/ecr-master-bulk-upload/ecr-master-bulk-upload.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';


@Component({
  selector: 'app-ecr-master',
  standalone: true,
  imports: [EcrMasterBulkUploadComponent,ComboBoxComponent,NgIf,GridActionItemComponent,EcrMasterAddEditorPopupComponent,IndicatorsModule],
  templateUrl: './ecr-master.component.html',
  styleUrl: './ecr-master.component.css'
})
export class EcrMasterComponent {
  public defaultSelectedValue = 'Search User ID';
  public dropdown: { id: number; name: string }[] = [];
  public uploadPopupOpen: boolean = false;
  public isAddNewVisible: boolean = false;
  public rowData!: string[];
  IsUpdate: boolean = false;
  loading: boolean = false;
  public password: string = '';
  public originalData: any[] = [];
  public AddUpdatePopupOpen: boolean = false;
  public gridData: GridDataResult = { data: [], total: 0 };
  parentHeader: string = 'Add New PF/ECR Login';
  // @ViewChild(EcrMasterAddEditorPopupComponent) dialogRef!: EcrMasterAddEditorPopupComponent;

  constructor(private EcrReportService: EcrReportService,private blobService: BlobService) {


  }
  ngOnInit(): void {
    this.GetEcrMasterGrid();

}
onHeaderChange(newHeader: string) {

  this.parentHeader = newHeader;

}

  FilterDropdown(value: any) {
    this.EcrReportService.getUserIDDropdown(value).subscribe({
      next: (response) => {
        if (response.status === 200) {
          const data = response.body;
          if (data) {
            this.dropdown = data.map((item: any) => ({
              id: item.username,
              // name: item.subCode
              name: item.username
            }));
          }
        }
      }
    })
  }
  applyFilter(event: any) {
    const filterValue = event;
    this.gridData = {
      data: this.originalData.filter(item => item.username.toLowerCase().includes(filterValue)),
      total: this.originalData.filter(item => item.username.toLowerCase().includes(filterValue)).length
    };
  }
  DropdownGridChange(value: any) {
    debugger
    if (value == undefined) {
      this.GetEcrMasterGrid()
    }
    this.applyFilter(value.name)
  }

  openUploadPopup() {
    this.uploadPopupOpen = true;
  }

  exportToExcel(): void {
    this.loading = true;
    this.EcrReportService. ECRMasterExportToExcel().subscribe({
      next: (response: any) => {
        if (response.status === 200) {
          const data = response.body
          const blobLink = data.filePath;
          const blobName = this.blobService.extractBlobName(blobLink);
          setTimeout(() => {
            this.blobService.getSasToken(blobName).subscribe(
              (tokenResponse: any) => {
                try {
                  const sasToken = tokenResponse.result;
                  this.blobService.downloadFile(blobName, sasToken);
                 this.loading = false;
                } catch (error) {
                  // this.alertData={alertMessage:'File Not Found',alertVisible:'error'};
                  // this.loading = false;
                }
              },
              (error) => {
                // this.alertData={alertMessage:'File Not Found',alertVisible:'error'};
                // this.loading = false; 
              }
            );
          }, 5000);
        }
        else {
          console.error('Error fetching blob link:', response.body.error);
          // this.showState = false;
          // this.loading = false;
        }
      }})
}

openAddNewPopup() {//subcode
  this.isAddNewVisible = true;
  this.rowData = [];
  this.password = '';
  this.IsUpdate=false;


}
handleClosePopup() {
  this.isAddNewVisible = false;
 this.GetEcrMasterGrid();
}
handleclosePopup() {
  this.AddUpdatePopupOpen = false;
  this.uploadPopupOpen = false;
    // this.loadGridData('', '');
    this.GetEcrMasterGrid();
}
public parentColumns: any[] = [
  { field: 'clientID', title: 'Client ID' },
  { field: 'username', title: 'User ID' },
  { field: 'password', title: 'Password' },
  {
    field: 'Action',
    title: 'Action',
    filteredIcons: [
      {
        url: '../assets/vectors/edit.svg',
        alt: 'Icon 1',
        action: 'Edit'
      },
    ]
  }
];


handleButtonClick(event: any) {
  if (event.button.action === "Edit") {
    this.rowData = event.dataItem;
    this.EcrReportService.setHeader('Edit PF/ECR Login');
    console.log('row',this.rowData)
    this.password = event.dataItem.originalPassword;
    this.isAddNewVisible = true;
    this.IsUpdate=true;

  }
}
// GetEcrMasterGrid() {
//  this.EcrReportService.GetECRgridDetail().subscribe({
//       next:(response)=>{
//         console.log('Response',response);
        
//         const data=response.body;
//         this.originalData = data.map((item: any) => ({
//                   ...item,
//                   originalPassword: item.password
//                 }));
        
    
//         this.gridData={
//           data:data,
//           total:data.total
//         }}})
//   }

GetEcrMasterGrid(): void {
    // this.loading = true;
    this.EcrReportService.GetECRgridDetail().subscribe({
      next: (response) => {
        const data = response.body;
        this.originalData = data.map((item: any) => ({
          ...item,
          originalPassword: item.password
        }));


        this.gridData = {
          data: this.originalData,
          total: this.originalData.length
        };

        setTimeout(() => {
          this.originalData.forEach(item => {
            item.password = '**********';
          });
          this.gridData = {
            data: this.originalData,
            total: this.originalData.length
          };
        }, 5000);

        // this.loading = false;
      },
      error: (err) => {
        console.error('Error fetching entity data', err);
        // this.loading = false;
      }
    });
  }
  applyMasking(): void {
    setTimeout(() => {
      this.gridData.data.forEach(item => {
        item.password = '**********';
      });
    }, 5000);
  }

}

