import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { CriticalDocumnetsService } from '../../../service/critical-documnets.service';
import { UserService } from '../../../service/user.service';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';

@Component({
  selector: 'app-critica-documents-add-new-folder',
  standalone: true,
  imports: [DialogModule,ReactiveFormsModule,NgIf,NgClass,AlertPopupComponent],
  templateUrl: './critica-documents-add-new-folder.component.html',
  styleUrl: './critica-documents-add-new-folder.component.css'
})
export class CriticaDocumentsAddNewFolderComponent implements OnInit{
  @Output() close = new EventEmitter<void>();
  @Input() ParentId!:number
  form!: FormGroup;
  FolderName!:string;
  loading = false; 
  CustomerId!:number;
  userid!:number;
  EntityId!:string;
  public alertData = { alertMessage: '', alertVisible: '' };

  constructor(private CriticalDocumentsService: CriticalDocumnetsService, private user: UserService,private fb: FormBuilder) { }
  ngOnInit(): void {
    this.CustomerId = this.user.getCustomerId();
    this.userid = this.user.getUserId();
    this.EntityId = this.user.getClientId();
    this.form = this.fb.group({
      FolderName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9\s]*$/)]]
    })
  }
  onCreate(){
    debugger
    this.loading = true; 
    if(this.form.valid){
      this.CriticalDocumentsService.CreateFolder(this.userid, this.CustomerId, this.EntityId,this.form.value.FolderName, this.ParentId)
      .subscribe({
        next: (response) => {
          console.log(response);
          if (response.status === 200 && response.body.result==1) {
            const data = response.body;
            this.alertData={alertMessage:'Folder Created Succesfully',alertVisible:'success'};
            this.loading = false; 
          } else {
            this.alertData={alertMessage:'Folder Name already Exists',alertVisible:'error'};
            console.error('Error fetching Grid data: Invalid status code');
            this.loading = false; 
          }
        },
        error: (error) => {
          this.alertData={alertMessage:'Something went wrong, Please try again',alertVisible:'error'};
          console.error('Error fetching Grid data', error);
          this.loading = false; 
        }
      });
    }else{
      this.form.markAllAsTouched();
      this.loading=false;
    }
  }
  closeAlert(){
    if(this.alertData.alertVisible=='success'){
      this.close.emit();
    }
    this.alertData={alertMessage:'',alertVisible:''};
  }
  closePopup(){
    this.close.emit();
  }
}
