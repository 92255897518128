<kendo-dialog #dialogRef title="Upload Paycode Details" (close)="closePopup()" [width]="500" [height]="320" class="custom-dialog">
    <div class="row dialog-content" >

        <div class="col-md-12 sampleDocument">
          <div style=" float: right;">
            <img src="../assets/vectors/Excel.svg" alt=""  >
            <a href="../assets/files/MasterPaycodeSample.xlsx" download="MasterPaycodeSample.xlsx"
            style="color: #125AAB; text-decoration: underline; padding: 5px; font-size:12px;" id="btnSampleRLCS" >
            Master Paycode
            </a>
        
            <img src="../assets/vectors/Excel.svg" alt="" >
            <a href="../assets/files/SamplePaycodeMappingUpload.xlsx" download="SamplePaycodeMappingUpload.xlsx" 
            style="color: #125AAB; text-decoration: underline; padding: 5px; font-size:12px" id="btnSampleRLCS">
            Upload Paycode
            </a>
      </div>
  </div>

   
     
      <div class="col-md-12 form-group" style="margin-top: 10px;">
        <div class="drop-zone" 
             (dragover)="onDragOver($event)" 
             (dragleave)="onDragLeave($event)" 
             (drop)="onDrop($event)">
          <div *ngIf="!selectedFile" class="f-label" style="font-size: 18px;">
            <div class="col-md-12" style="margin-top: -15px;">
              <img src="../assets/vectors/cloud.svg" alt=""  >
             </div>
            <div class="col-md-8">
              <label style="margin-left: 100px;">{{ dropzoneMessage }}</label>
            </div>
            <div class="col-md-4">
              <label style="color: #125AAB; text-decoration: underline; cursor: pointer; margin-left: -62px; font-weight: 500;">
                Browse
                <input type="file" style="display: none;" (change)="onFileSelect($event)" accept=".xls,.xlsx,.csv" />
              </label>
            </div>
          </div>
          <p *ngIf="selectedFile">{{ selectedFile.name }}</p>
        </div>
      </div>
    </div>
    <kendo-dialog-actions style="border: none; ">
      <button kendoButton class="figma-btn-blue figma-popup" (click)="uploadFiles()">Upload</button>
    </kendo-dialog-actions>
  </kendo-dialog>

  <app-alert-popup *ngIf="alertData"[Width]="270"  [Data]="alertData" (close)="closePopup()"></app-alert-popup>
  