import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownListComponent } from "../../../../components/dropdown-list/dropdown-list.component";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { KendoDatepickerComponent } from '../../../../components/kendo-datepicker/kendo-datepicker.component';
import { LabelModule } from '@progress/kendo-angular-label';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { RadioButtonModule } from '@progress/kendo-angular-inputs';
import { MasterAbstractsService } from '../../../../service/master-abstracts.service';
import { UserService } from '../../../../service/user.service';
import { GridActionItemComponent } from '../../../../components/grid-action-item/grid-action-item.component';
import { GridDataResult, GridModule } from '@progress/kendo-angular-grid';
import { PageChangeEvent } from '@progress/kendo-angular-treelist';
import { ViewEncapsulation } from '@angular/core';
import { AlertPopupComponent } from '../../../popups/alert-popup/alert-popup.component';

@Component({
  selector: 'app-client-commercial-setup',
  standalone: true,
  imports: [DropdownListComponent, FormsModule, KendoDatepickerComponent, LabelModule, NgFor, RadioButtonModule, NgIf, GridActionItemComponent, GridModule, AlertPopupComponent, ReactiveFormsModule, NgClass],
  templateUrl: './client-commercial-setup.component.html',
  styleUrl: './client-commercial-setup.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class ClientCommercialSetupComponent {
  commercialForm!: FormGroup;
  ClientDropdownData: any[] = [];
  BillingZone: any[] = [];
  InvoiceCreation: any[] = [];
  selectedClient = { text: 'Select Client', value: '' };
  selectedVariable = { text : 'Select Variable', value: ''};
  selectedElement = { text : 'Select Element', value: ''};
  selectedInvoiceDate = { text: 'Select Date', value: '' };
  selectedBillingZone = { text: 'North', value: 'N' };
  ActivityStatus = [
    { text: 'Select Status', value: '' },
    { text: 'Active', value: 'A' },
    { text: 'InActive', value: 'I' }
  ];
  pendingValue: any = null;
  loading = false;
  activityList: any [] = [];
  selectedActivity = { text: 'Select Activity', value: '' };
  selectedStatus = {text: 'Select Status', value:''};
  isStatusTouched = false;
  selectedStartDate: Date | null = null;
  public datePlaceholder: string = 'DD-MM-YYYY';
  showValidationErrorsStatus = false;
  showValidationErrorsAct = false;
  showValidationErrorsVar = false;
  showValidationErrorsAct2 = false;
  showValidationErrorsVar2 = false;
  showValidationErrorsC1 = false;
  showValidationErrorsC2 = false;
  showValidationErrorsC3 = false;
  showValidationErrorsC4 = false;
  showValidationErrorsC5 = false;
  showValidationErrorsC6 = false;
  allSelected: boolean = false;
  allSelectedBranch: boolean = false;
  clientID : any;
  Skip = 0;
  public PageSize: number = 10;
  public sizes = [5, 10, 20, 50];
  public buttonCount = 5;
  @Input() hasError!: any;
  public gridData: GridDataResult = { data: [], total: 0 };
  public OnetimeActivityGrid: GridDataResult = { data: [], total: 0 };
  public BranchWisePriceDetails: GridDataResult = { data: [], total: 0 };
  @Output() close = new EventEmitter<void>();
  radioButtonValue = [
    { text: 'Regular', value: 'R' },
    { text: 'Split', value: 'S' }
  ];
  PoApplicabilityButtonValue : any [] = [];
  SplitradioButtonValue = [
    { text: 'Branch Wise', value: 'B' },
    { text: 'Activity Wise', value: 'A' }
  ];
  CommercialradioButtonValue = [
    { text: 'LumpSum', value: 'L' },
    { text: 'Activity Based', value: 'A' }
  ];
  public invoiceType = 'R';
  public poApplicability = 'Y';
  public commercialType = 'L';
  public splitInvoiceType = 'A';
  userID: any;
  customerID: any;
  EntityID: any;
  alertData = { alertMessage: '', alertVisible: '' };
  SlabGrid = [
    { slab: 1, from: "1", to: "0", amount: 0 }, // First row is unchangeable (from value)
    { slab: 2, from: "0", to: "0", amount: 0 }  // Second row is editable
  ];
  public selectedActivityList: any[] = [];
  public randomString: string = '';
  isActive : boolean =false
  isFormValid: boolean = false;
  constructor(private MasterAbstractService: MasterAbstractsService, private user: UserService, private fb: FormBuilder,private cdr:ChangeDetectorRef){}
  ngOnInit(): void {
    this.getClientsMaster();
    this.populateBillingZone();
    this.initializeForm();
    this.commercialForm.valueChanges.subscribe((changes) => {
     
    });
    console.log('selectedClient',this.selectedClient)
  }
  initializeForm(): void {
    this.commercialForm = this.fb.group({
      billingZone: [this.selectedBillingZone?.value || ''], // Extract only the value
      invoiceDate: [this.selectedInvoiceDate?.value || ''],
      invoiceType: ['R'],
      poApplicability: ['Y'],
      commercialType: ['L'],
      splitInvoiceType: ['B'],
      lumpSumName: [''],
      lumpSumAmount: [''],
      FixedAmount:[''],
      commercialElement:[this.selectedElement?.value || ''],
      variableType:[this.selectedVariable?.value || ''],
      Activity:[this.selectedActivity?.value || ''],
      ClientActivityName : [''],
      Status:[this.selectedStatus?.value || '']
    });
    this.onFormChanges();
  }
  onFormChanges(): void {
    this.commercialForm.get('billingZone')?.valueChanges.subscribe((value) => {
      if (typeof value === 'object') {
        this.commercialForm.get('billingZone')?.setValue(value.value, { emitEvent: false });
      }
    });
    this.commercialForm.get('invoiceDate')?.valueChanges.subscribe((value) => {
      if (typeof value === 'object') {
        this.commercialForm.get('invoiceDate')?.setValue(value.value, { emitEvent: false });
      }
    });
    this.commercialForm.get('Status')?.valueChanges.subscribe((value) => {
      if (typeof value === 'object') {
        this.commercialForm.get('Status')?.setValue(value.value, { emitEvent: false });
      }
    });
    this.commercialForm.get('Activity')?.valueChanges.subscribe((value) => {
      if (typeof value === 'object') {
        this.commercialForm.get('Activity')?.setValue(value.value, { emitEvent: false });
      }
    });
    this.commercialForm.get('variableType')?.valueChanges.subscribe((value) => {
      if (typeof value === 'object') {
        this.commercialForm.get('variableType')?.setValue(value.value, { emitEvent: false });
      }
    });
    // Repeat the same logic for other fields where the object is being set
    this.commercialForm.get('commercialElement')?.valueChanges.subscribe((value) => {
      if (typeof value === 'object') {
        this.commercialForm.get('commercialElement')?.setValue(value.value, { emitEvent: false });
      }
    });
  }
  onDropdownValueChange(value: any, type: string) {
    if(type == 'client'){
       // Update selectedAct with selected dropdown value and ensure both text and value are set
       this.selectedClient = { text: value.text, value: value.value };
       
        this.isActive = true
       
       this.clientID = this.selectedClient.value;
       console.log(this.clientID);
       this.GetVariableList();
       this.GetBillingZone();
       this.PoApplicability();
       this.getActivityList();
       this.loadGridData();
       this.GetOnetimeActivity();
       this.GetBranchWisePriceDetailsBasedOnClientId();
       this.GetClientInvoiceCommercialType();
       this.GetClientInvoiceSplitCommercialType();
       this.getLumpSumDetails();
    }
  }
  populateBillingZone() {
    for (let i = 1; i <= 28; i++) {
      this.InvoiceCreation.push({ text: i.toString(), value: i.toString() });
    }
  }
  clientList : any [] = [];
 
  variableList :any [] = [];
  elementList :any [] = [];

  onClientChange() {
    // Handle logic when client is selected.
    console.log('Selected client:', this.selectedClient);
  }
  closeAlert(){
    if(this.alertData.alertVisible=='success'){
      this.closePopup();
    }
    this.alertData = { alertMessage: '', alertVisible: '' };
  }
  closePopup(): void {
    this.alertData.alertVisible = ''; // Hide popup
    this.pendingValue = null; // Clear pending value if canceled
}

onConfirm(): void {
    this.alertData.alertVisible = ''; // Hide popup
    this.splitInvoiceType = this.pendingValue; // Apply the pending value
    this.clearFilter(); // Call clearFilter or any other logic
    this.pendingValue = null; // Clear pending value
}
  onStartDateChange(value: Date) {
    const date = new Date(value.toISOString().split('T')[0]);
    this.selectedStartDate = date;
  }
  onInvoiceChange(event: any): void {
   
    this.splitInvoiceType = 'A';
    this.commercialForm.get('splitInvoiceType')?.setValue(this.splitInvoiceType);
    this.invoiceType = event;
    this.cdr.detectChanges();
    this.clearFilter();
    
  }
  onPOChange(event: any): void {
    console.log('check',event)
    this.alertData = {
      alertMessage: 'Are you sure you want to change the invoice type?',
      alertVisible: 'success'
  };
  this.cdr.detectChanges();
  console.log('th',this.alertData)
    // this.poApplicability = event;
    // this.clearFilter();
  }
  onCommercialChange(event: any): void {
    this.splitInvoiceType = 'A';
    this.commercialForm.get('splitInvoiceType')?.setValue(this.splitInvoiceType);

    // this.commercialForm.get('commercialType')?.setValue(event);
    this.commercialType = event;
    this.cdr.detectChanges();
    this.clearFilter();
     this.commercialForm.get('Status')?.clearValidators();
   
    // this.cdr.detectChanges();
  }
  onSplitChange1(event: any): void {
    this.splitInvoiceType = event;
    this.cdr.detectChanges();
    this.clearFilter();
  }
  onVariableChange(event: any): void {
    console.log('variable',event)
    this.commercialForm.get('variableType')?.setValue(event);
    this.selectedVariable = event;
    this.clearFilter();
    if(this.selectedVariable.value == 'S' || this.selectedVariable.value == 'V'){
      this.GetCommercialElementList();
    }
  }
  onElementChange(event: any): void {
    this.commercialForm.get('commercialElement')?.setValue(event);
    this.selectedElement = event;
    // if (this.selectedElement) {
    //   this.showValidationErrorsStatus = false;
    // }
    this.clearFilter();
  }
  onStatusChange(event: any): void {
    console.log('onStatusChange', event);
    this.commercialForm.get('Status')?.setValue(event);
    this.selectedStatus = event;
    if (this.selectedStatus) {
    this.showValidationErrorsStatus = false;
  }
    this.isStatusTouched = true;
    this.clearFilter();
  }
  onActivityChange(event: any): void {
    this.commercialForm.get('Activity')?.setValue(event);
    this.selectedActivity = event;
    if (this.selectedActivity) {
      this.showValidationErrorsAct = false;
    }
    this.clearFilter();
  }
  onSplitChange(event: any): void {
    // Store the selected value but don't apply it yet
    this.pendingValue = event;
    this.alertData.alertVisible = 'confirm'; // Trigger popup visibility
    this.alertData.alertMessage = 'Are you sure you want to change the invoice type?';
}

  onBillingZoneChange(event: any): void {
    this.commercialForm.get('billingZone')?.setValue(event);
    this.selectedBillingZone = event;
    this.clearFilter();
  }
  onInvoiceDateChange(event: any): void {
    this.commercialForm.get('invoiceDate')?.setValue(event); 
    this.selectedInvoiceDate = event;
    this.clearFilter();
  }
  public pageChange(event: PageChangeEvent): void {
    console.log('Page Change Event Received:', event);
    this.Skip = event.skip;
    this.PageSize = event.take;
  }
  clearFilter(): void {
    // Object.keys(this.commercialForm.controls).forEach(control => {
    //   this.commercialForm.get(control)?.clearValidators(); 
    //   this.commercialForm.get(control)?.updateValueAndValidity(); 
    // });
  }
  openConfirmation(invoiceType: any): void {
    this.pendingValue = invoiceType;
    this.alertData = {
        alertMessage: 'Are you sure you want to change the invoice type?',
        alertVisible: 'confirm'
    };
}
onPopupConfirmed(event:any): void {
  if (this.pendingValue) {
      this.splitInvoiceType = this.pendingValue;
      this.poApplicability = 'Y'
      this.clearFilter();
  }
  this.pendingValue = null;
  console.log(event);
}
public onPopupCanceled(): void {
  this.alertData = { alertMessage: '', alertVisible: '' };
  this.close.emit();
}
addSlab() {
  const lastSlab = this.SlabGrid[this.SlabGrid.length - 1];
  if (lastSlab.from === "0" || lastSlab.to === "0" || lastSlab.amount === 0) {
    this.alertData.alertMessage = 'Please fill all the slab details';
    this.alertData.alertVisible = 'error';
    return;
  }
  const newRow = { slab: this.SlabGrid.length + 1, from: "0", to: "0", amount: 0 };
  this.SlabGrid.push(newRow);
}

deleteSlab() {
  if (this.SlabGrid.length > 2) { // Ensuring the first 2 rows remain
    this.SlabGrid.pop();
  }
}


  getClientsMaster() {
    this.MasterAbstractService.getClientsMaster().subscribe({
      next: (response) => {
        const data = response.body;
        this.ClientDropdownData = [
          { text: 'Select Client', value: '' },
          ...data.map((item: any) => ({
            text: item.text,
            value: item.value
          }))
        ];
      },
      error: (error) => {
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }
  GetVariableList(){
    this.MasterAbstractService.GetVariableList().subscribe({
      next: (response) => {
        const data = response.body;
        this.variableList = [
          { text: 'Select Variable', value: '' },
          ...data.map((item: any) => ({
            text: item.text,
            value: item.value
          }))
        ];
      },
      error: (error) => {
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }
  GetBillingZone(){
    const type = 'CommercialBilling';
    this.MasterAbstractService.GetCodeDescription(type).subscribe({
      next: (response) => {
        const data = response.body;
        this.BillingZone = [
          ...data.map((item: any) => ({
            text: item.text,
            value: item.value
          }))
        ];
      },
      error: (error) => {
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }
  PoApplicability(){
    const type = 'VendorCode';
    this.MasterAbstractService.GetCodeDescription(type).subscribe({
      next: (response) => {
        const data = response.body;
        this.PoApplicabilityButtonValue = [
          ...data.map((item: any) => ({
            text: item.text,
            value: item.value
          }))
        ];
      },
      error: (error) => {
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }
  getActivityList(){
    this.MasterAbstractService.getActivityList(this.clientID).subscribe({
      next: (response) => {
        const data = response.body;
        this.activityList = [
          { text: 'Select Activity', value: '' },
          ...data.map((item: any) => ({
            text: item.text,
            value: item.value
          }))
        ];
      },
      error: (error) => {
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }
  loadGridData(): void {
    this.loading = true;
    var profileID = null;
    this.MasterAbstractService.getCommercialGridData(this.clientID).subscribe({
      next: (response) => {
        console.log(response);
        
        const data = response.body.map((item:any, index:any) => ({
          ...item,
          srNo: index + 1
        }));
        this.gridData = {
          data: data,
          total: data.length
        };
        this.loading = false;
        console.log(this.gridData);
      },
      error: (err) => {
        console.error('Error fetching entity data', err);
        this.loading = false;
      }
    });
  }
  GetOnetimeActivity(): void {
    this.loading = true;
    this.MasterAbstractService.GetOnetimeActivity(this.clientID).subscribe({
      next: (response) => {
        console.log(response);
        
        const data = response.body.map((item:any, index:any) => ({
          ...item,
          srNo: index + 1
        }));
        this.OnetimeActivityGrid = {
          data: data,
          total: data.length
        };
        this.loading = false;
        console.log(this.gridData);
      },
      error: (err) => {
        console.error('Error fetching entity data', err);
        this.loading = false;
      }
    });
  }
  GetBranchWisePriceDetailsBasedOnClientId(): void {
    this.loading = true;
    //value '93TRG'
    //this.clientID
    this.MasterAbstractService.GetBranchWisePriceDetailsBasedOnClientId('93TRG').subscribe({
      next: (response) => {
        console.log(response);
        
        const data = response.body.map((item:any, index:any) => ({
          ...item,
          srNo: index + 1
        }));
        this.BranchWisePriceDetails = {
          data: data,
          total: data.length
        };
        this.loading = false;
        console.log(this.gridData);
      },
      error: (err) => {
        console.error('Error fetching entity data', err);
        this.loading = false;
      }
    });
  }
  GetCommercialElementList(){
    this.MasterAbstractService.GetCommercialElementList().subscribe({
      next: (response) => {
        const data = response.body;
        this.elementList = [
          { text: 'Select Element', value: '' },
          ...data.map((item: any) => ({
            text: item.text,
            value: item.value
          }))
        ];
      },
      error: (error) => {
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }
  
  getLumpSumDetails(): void {
    this.MasterAbstractService.LumpSumDetails(this.clientID).subscribe(
      (response) => {
        if (response.body) {
          const data = response.body;
          const matchedStatus = this.ActivityStatus.find(
            (status) => status.value === data.cC_Status
          )?.value || '';
          this.commercialForm.patchValue({
            lumpSumName: data.cC_Name || '',
            lumpSumAmount: data.cC_Price || '',
          });
          if (matchedStatus) {
            console.log('matched',matchedStatus);
            // this.selectedStatus.value = matchedStatus;
             if(matchedStatus == 'A'){
              let event = {text:'Active',value:'A'}
              this.commercialForm.patchValue({
                Status :event
              })
              this.selectedStatus = event
             }
             if(matchedStatus == 'I'){
              let event = {text:'InActive',value:'I'}
              this.commercialForm.patchValue({
                Status :event
              })
              this.selectedStatus = event
             }
          }
        }
      },
      (error) => {
        console.error('Error fetching Lump Sum details:', error);
      }
    );
  }
  GetClientInvoiceCommercialType() {
    this.MasterAbstractService.GetClientInvoiceCommercialType(this.clientID).subscribe({
      next: (response) => {
        const value = response?.body.trim();
        this.invoiceType = value?value:'R';
      },
      error: (error) => {
        console.error('Error fetching invoice commercial type', error);
      }
    });
  }
  GetClientInvoiceSplitCommercialType() {
    this.MasterAbstractService.GetClientInvoiceSplitCommercialType(this.clientID).subscribe({
      next: (response) => {
        const value = response?.body.trim();
        this.splitInvoiceType = value?value:'A';
      },
      error: (error) => {
        console.error('Error fetching invoice split commercial type', error);
      }
    });
  }
  public parentColumns: any[] = [
    { field: 'srNo', title: 'Sr.no', width:100},
    { field: 'entityClientName', title: 'Activity Name' },
    { field: 'activityName', title: 'Client Activity Name', width:300 },
    { field: 'slabType', title: 'Variable Type' },
    { field: 'elementName', title: 'Commercial Element Type' },
    { field: 'amount', title: 'Amount' },
    { field: 'slabStatus', title: '	Status' },
    { field: 'rR_Branch', title: 'Slab Details' },
  ];

  // Toggle Select All functionality
  toggleSelectAll(event: any) {
    const isChecked = event.target.checked;
    this.OnetimeActivityGrid.data.forEach(item => {
      item.selected = isChecked;
      this.onRowSelectionChange(item);
    });
  }

  // Track changes to row selection
  onRowSelectionChange(dataItem: any) {
    if (dataItem.selected) {
      // If selected, add to the selected list
      const existingIndex = this.selectedActivityList.findIndex(x => x.id === dataItem.id);
      if (existingIndex === -1) {
        this.selectedActivityList.push({ ...dataItem, checkStatus: true });
      }
    } else {
      // If unselected, remove from the selected list
      this.selectedActivityList = this.selectedActivityList.filter(x => x.id !== dataItem.id);
    }
  }

  // Track changes to the commercial name field
  onCommercialNameChange(dataItem: any) {
    const index = this.selectedActivityList.findIndex(x => x.id === dataItem.id);
    if (index > -1) {
      this.selectedActivityList[index].commercialName = dataItem.commercialName;
    }
  }

  // Track changes to the price field
  onPriceChangeCo(dataItem: any) {
    const index = this.selectedActivityList.findIndex(x => x.id === dataItem.id);
    if (index > -1) {
      this.selectedActivityList[index].price = dataItem.price;
    }
  }
  // Array to store selected branches
  public selectedBranchList: any[] = [];

  // Function to toggle select all branches
  toggleSelectAllBranch(event: any) {
    const isChecked = event.target.checked;
    this.BranchWisePriceDetails.data.forEach(item => {
      item.selected = isChecked;
      this.onRowSelectionChange1(item);
    });
  }

  // Track changes to row selection
  onRowSelectionChange1(dataItem: any) {
    if (dataItem.selected) {
      // If selected, add to the selected list
      const existingIndex = this.selectedBranchList.findIndex(x => x.cL_BranchName === dataItem.cL_BranchName);
      if (existingIndex === -1) {
        this.selectedBranchList.push({ ...dataItem, checkStatus: true });
      }
    } else {
      // If unselected, remove from the selected list
      this.selectedBranchList = this.selectedBranchList.filter(x => x.cL_BranchName !== dataItem.cL_BranchName);
    }
  }

  // Track changes to the name field
  onNameChange(dataItem: any) {
    const index = this.selectedBranchList.findIndex(x => x.cL_BranchName === dataItem.cL_BranchName);
    if (index > -1) {
      this.selectedBranchList[index].ccB_Name = dataItem.ccB_Name;
    }
  }

  // Track changes to the price field
  onPriceChange(dataItem: any) {
    const index = this.selectedBranchList.findIndex(x => x.cL_BranchName === dataItem.cL_BranchName);
    if (index > -1) {
      this.selectedBranchList[index].price = dataItem.price;
    }
  }

  applyDefaultValues() {
    if (this.invoiceType === 'R') {
      this.commercialForm.get('commercialType')?.setValue('commercial1'); // Default value
    } 
    if (this.invoiceType === 'S') {
      this.commercialForm.get('splitInvoiceType')?.setValue('split1'); // Default value
    }
  }
  // updateValidators() {
  //   if (this.invoiceType === 'R' && this.commercialType === 'L') {
  //     this.commercialForm.get('commercialType')?.setValidators([Validators.required]);
  //     this.commercialForm.get('lumpSumName')?.setValidators([Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]);
  //     this.commercialForm.get('lumpSumAmount')?.setValidators([Validators.required, Validators.pattern(/^\d+$/)]);
  //     this.commercialForm.get('splitInvoiceType')?.clearValidators();
  //   } else if(this.invoiceType === 'R' && this.commercialType === 'A'){
      
  //   } 
  //   else if (this.invoiceType === 'S') {
  //     this.commercialForm.get('splitInvoiceType')?.setValidators([Validators.required]);
  //     this.commercialForm.get('commercialType')?.clearValidators();
  //   }

  //   // Ensure the form revalidates after setting validators
  //   this.commercialForm.get('commercialType')?.updateValueAndValidity();
  //   this.commercialForm.get('splitInvoiceType')?.updateValueAndValidity();
  // }
  // Submit and store selected rows and edited values
  submitChanges() {
    const submittedData = this.OnetimeActivityGrid.data
      .filter(item => item.selected)
      .map(item => ({
        id: item.id,
        name: item.name,
        price: item.price
      }));
  }
  generateRandomString(): string {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const numbers = '0123456789';
    let result = '';
  
    for (let i = 0; i < 8; i++) {
      result += letters.charAt(Math.floor(Math.random() * letters.length));
    }

    for (let i = 0; i < 4; i++) {
      result += numbers.charAt(Math.floor(Math.random() * numbers.length));
    }
  
    return result.split('').sort(() => 0.5 - Math.random()).join('');
  }
  
  onSubmit(){
    if (this.invoiceType == 'R' && this.commercialType == 'L') {
      this.commercialForm.markAllAsTouched();
      this.commercialForm.get('Status')?.setValidators([Validators.required]);
      this.commercialForm.get('Status')?.updateValueAndValidity();
      
      if (this.commercialForm.invalid || !this.selectedStatus) {
        // Set the showValidationErrorsStatus flag to true if the dropdown is not selected
        this.showValidationErrorsStatus = !this.selectedStatus;
    
        // Log message and return to stop further submission
        console.log('Form is invalid or Status is not selected');
        return;
      }
      console.log('Form is valid and submitted');
      // this.commercialForm.get('lumpSumName')?.setValidators([Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]);
      // this.commercialForm.get('lumpSumAmount')?.setValidators([Validators.required, Validators.pattern(/^\d+$/)]);
      this.commercialForm.get('FixedAmount')?.clearValidators();
      this.commercialForm.get('ClientActivityName')?.clearValidators();
      this.showValidationErrorsC1 = true;
      this.showValidationErrorsC2 = false;
      this.showValidationErrorsC3 = false;
      this.showValidationErrorsC4 = false;
      this.showValidationErrorsC5 = false;
      this.showValidationErrorsC6 = false;
      console.log('test')

      this.commercialForm.get('lumpSumName')?.updateValueAndValidity();
      this.commercialForm.get('lumpSumAmount')?.updateValueAndValidity();
      this.commercialForm.get('FixedAmount')?.updateValueAndValidity();
      this.commercialForm.get('ClientActivityName')?.updateValueAndValidity();
    }
    else if(this.splitInvoiceType == 'A' && this.invoiceType == 'S'){
      this.showValidationErrorsVar = true;
      this.showValidationErrorsAct = true;
      this.commercialForm.get('ClientActivityName')?.setValidators([Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]);
      if(this.commercialType == 'A' && this.invoiceType == 'S' && this.selectedVariable.value == 'F'){
      this.commercialForm.get('FixedAmount')?.setValidators([Validators.required, Validators.pattern(/^\d+$/)]);
      this.commercialForm.get('ClientActivityName')?.setValidators([Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]);
      this.commercialForm.get('lumpSumName')?.clearValidators();
      this.commercialForm.get('lumpSumAmount')?.clearValidators();
      this.showValidationErrorsC1 = false;
      this.showValidationErrorsC2 = true;
      this.showValidationErrorsC3 = false;
      this.showValidationErrorsC4 = false;
      this.showValidationErrorsC5 = false;
      this.showValidationErrorsC6 = false;
      this.commercialForm.get('lumpSumName')?.updateValueAndValidity();
      this.commercialForm.get('lumpSumAmount')?.updateValueAndValidity();
      this.commercialForm.get('FixedAmount')?.updateValueAndValidity();
      this.commercialForm.get('ClientActivityName')?.updateValueAndValidity();
      }
      else if(this.commercialType == 'A' && this.invoiceType == 'S' && this.selectedVariable.value == 'S'){
        this.commercialForm.markAllAsTouched();
        this.commercialForm.get('commercialElement')?.setValidators([Validators.required]);
      this.commercialForm.get('commercialElement')?.updateValueAndValidity();
        this.commercialForm.get('FixedAmount')?.clearValidators();
      this.commercialForm.get('lumpSumName')?.clearValidators();
      this.commercialForm.get('lumpSumAmount')?.clearValidators();
      this.commercialForm.get('ClientActivityName')?.setValidators([Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]);
      this.showValidationErrorsC1 = false;
      this.showValidationErrorsC2 = false;
      this.showValidationErrorsC3 = true;
      this.showValidationErrorsC4 = false;
      this.showValidationErrorsC5 = false;
      this.showValidationErrorsC6 = false;
      this.commercialForm.get('lumpSumName')?.updateValueAndValidity();
      this.commercialForm.get('lumpSumAmount')?.updateValueAndValidity();
      this.commercialForm.get('FixedAmount')?.updateValueAndValidity();
      this.commercialForm.get('ClientActivityName')?.updateValueAndValidity();
      }
      else if(this.commercialType == 'A' && this.invoiceType == 'S' && this.selectedVariable.value == 'V'){
        this.commercialForm.markAllAsTouched();
        this.commercialForm.get('commercialElement')?.setValidators([Validators.required]);
        this.commercialForm.get('commercialElement')?.updateValueAndValidity();
        this.commercialForm.get('FixedAmount')?.setValidators([Validators.required]);
        this.commercialForm.get('FixedAmount')?.updateValueAndValidity();
        this.commercialForm.get('FixedAmount')?.setValidators([Validators.required, Validators.pattern(/^\d+$/)]);
      this.commercialForm.get('ClientActivityName')?.setValidators([Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]);
        this.commercialForm.get('lumpSumName')?.clearValidators();
        this.commercialForm.get('lumpSumAmount')?.clearValidators();
        this.showValidationErrorsC1 = false;
        this.showValidationErrorsC2 = false;
        this.showValidationErrorsC3 = false;
        this.showValidationErrorsC4 = true;
        this.showValidationErrorsC5 = false;
        this.showValidationErrorsC6 = false;
        this.commercialForm.get('lumpSumName')?.updateValueAndValidity();
        this.commercialForm.get('lumpSumAmount')?.updateValueAndValidity();
        this.commercialForm.get('FixedAmount')?.updateValueAndValidity();
        this.commercialForm.get('ClientActivityName')?.updateValueAndValidity();
      }
    }
    else if(this.commercialType == 'A' && this.invoiceType == 'R'){
      this.showValidationErrorsVar2 = true;
      this.showValidationErrorsAct2 = true;
      this.showValidationErrorsStatus = true;
      this.commercialForm.markAllAsTouched();
      this.commercialForm.get('Activity')?.setValidators([Validators.required]);
      this.commercialForm.get('Activity')?.updateValueAndValidity();
      this.commercialForm.get('variableType')?.setValidators([Validators.required]);
      this.commercialForm.get('variableType')?.updateValueAndValidity();
      this.commercialForm.get('ClientActivityName')?.setValidators([Validators.required]);
      this.commercialForm.get('ClientActivityName')?.updateValueAndValidity();
      this.commercialForm.get('Status')?.setValidators([Validators.required]);
      this.commercialForm.get('Status')?.updateValueAndValidity();
      // this.commercialForm.get('ClientActivityName')?.setValidators([Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]);
      if(this.commercialType == 'A' && this.invoiceType == 'R' && this.selectedVariable.value == 'F'){
        this.commercialForm.markAllAsTouched();
        this.commercialForm.get('FixedAmount')?.setValidators([Validators.required]);
        this.commercialForm.get('FixedAmount')?.updateValueAndValidity();
        // this.commercialForm.get('FixedAmount')?.setValidators([Validators.required, Validators.pattern(/^\d+$/)]);
      this.commercialForm.get('ClientActivityName')?.setValidators([Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]);
      this.commercialForm.get('lumpSumName')?.clearValidators();
      this.commercialForm.get('lumpSumAmount')?.clearValidators();
      this.showValidationErrorsC1 = false;
      this.showValidationErrorsC2 = false;
      this.showValidationErrorsC3 = false;
      this.showValidationErrorsC4 = false;
      this.showValidationErrorsC5 = false;
      this.showValidationErrorsC6 = false;
      this.commercialForm.get('lumpSumName')?.updateValueAndValidity();
      this.commercialForm.get('lumpSumAmount')?.updateValueAndValidity();
      this.commercialForm.get('FixedAmount')?.updateValueAndValidity();
      this.commercialForm.get('ClientActivityName')?.updateValueAndValidity();
      }
      else if(this.commercialType == 'A' && this.invoiceType == 'R' && this.selectedVariable.value == 'S'){
      //   this.commercialForm.get('FixedAmount')?.clearValidators();
      // this.commercialForm.get('lumpSumName')?.clearValidators();
      // this.commercialForm.get('lumpSumAmount')?.clearValidators();
      
      this.commercialForm.markAllAsTouched();
      this.commercialForm.get('Activity')?.setValidators([Validators.required]);
      this.commercialForm.get('Activity')?.updateValueAndValidity();
      this.commercialForm.get('variableType')?.setValidators([Validators.required]);
      this.commercialForm.get('variableType')?.updateValueAndValidity();
      this.commercialForm.get('ClientActivityName')?.setValidators([Validators.required]);
      this.commercialForm.get('ClientActivityName')?.updateValueAndValidity();
      this.commercialForm.get('Status')?.setValidators([Validators.required]);
      this.commercialForm.get('Status')?.updateValueAndValidity();
      // this.commercialForm.get('ClientActivityName')?.setValidators([Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]);
      this.commercialForm.get('commercialElement')?.setValidators([Validators.required]);
      this.commercialForm.get('commercialElement')?.updateValueAndValidity();
      this.showValidationErrorsC1 = false;
      this.showValidationErrorsC2 = false;
      this.showValidationErrorsC3 = false;
      this.showValidationErrorsC4 = false;
      this.showValidationErrorsC5 = true;
      this.showValidationErrorsC6 = false;
      // this.commercialForm.get('lumpSumName')?.updateValueAndValidity();
      // this.commercialForm.get('lumpSumAmount')?.updateValueAndValidity();
      // this.commercialForm.get('FixedAmount')?.updateValueAndValidity();
      // this.commercialForm.get('ClientActivityName')?.updateValueAndValidity();
      }
      else if(this.commercialType == 'A' && this.invoiceType == 'R' && this.selectedVariable.value == 'V'){
        console.log('triggered')
        this.commercialForm.markAllAsTouched();
        this.commercialForm.get('commercialElement')?.setValidators([Validators.required]);
        this.commercialForm.get('commercialElement')?.updateValueAndValidity();
        this.commercialForm.get('FixedAmount')?.setValidators([Validators.required]);
        this.commercialForm.get('FixedAmount')?.updateValueAndValidity();
      // this.commercialForm.get('ClientActivityName')?.setValidators([Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]);
        this.commercialForm.get('lumpSumName')?.clearValidators();
        this.commercialForm.get('lumpSumAmount')?.clearValidators();
        
        this.showValidationErrorsC1 = false;
        this.showValidationErrorsC2 = false;
        this.showValidationErrorsC3 = false;
        this.showValidationErrorsC4 = false;
        this.showValidationErrorsC5 = false;
        this.showValidationErrorsC6 = true;
        this.commercialForm.get('lumpSumName')?.updateValueAndValidity();
        this.commercialForm.get('lumpSumAmount')?.updateValueAndValidity();
       
        this.commercialForm.get('ClientActivityName')?.updateValueAndValidity();
      }
    }
    // this.showValidationErrorsStatus = true;
    this.isFormValid = this.commercialForm.valid;

    this.randomString = this.generateRandomString();
    const formValues = this.commercialForm.value;

    const slabElements_lst = this.SlabGrid.map(slab => ({
      from: slab.from || '',
      to: slab.to || '',
      amount: slab.amount  || 0
    }));
    const commercialBranchList = this.selectedBranchList.map(item => ({
      cL_ClientID: item.cL_ClientID || '',
      cL_StateID: item.cL_StateID || '',
      state: item.state || '',
      cL_CityID: item.cL_CityID || '',
      locationName: item.locationName || '',
      cL_BranchName: item.cL_BranchName || '',
      ccB_Name: item.ccB_Name || '',
      price: item.price || '',
      checkedFlag: item.selected ? "1" : "0",
      checkStatus: true
    }));
    const lstOneTimeClientActivityList = this.selectedActivityList.map(item => ({
      id: item.id || '',
      name: item.name || '',
      commercialName: item.commercialName || '',
      checkedFlag: item.selected ? 1 : 0,
      price: item.price || '',
      checkStatus: true
    }));
const payload = {
    cC_ID: this.randomString,
    lumpShumpAmount: String(this.commercialForm.get('lumpSumAmount')?.value),
    radioValue: "",
    lumpShump: true,
    activityBased: true,
    clientId: this.clientID,
    clientList: [
      {
        text: "",
        value: "",
        sort: 0
      }
    ],
    activityID: formValues.Activity || "",
    activityName: this.commercialForm.get('ClientActivityName')?.value,
    fixedAmount: this.commercialForm.get('FixedAmount')?.value,
    activityList: [
      {
        text: "",
        value: "",
        sort: 0
      }
    ],
    variableID: formValues.variableType || "",
    variableList: [
      {
        text: "",
        value: "",
        sort: 0
      }
    ],
    lumSumActivityName: this.commercialForm.get('lumpSumName')?.value || '',
    lumpSumCStatus: formValues.Status,
    slabCount: 0,
    version: 0,
    commercialElementID: formValues.commercialElement,
    commercialElementList: [
      {
        text: "",
        value: "",
        sort: 0
      }
    ],
    recurringId: "",
    cM_Recurring_lst: [
      {
        rcM_ID: "",
        rcM_Name: "",
        rcM_Standard_Price: 0,
        rcM_Status: "",
        rcM_CreatedBy: "",
        rcM_CreatedDate: "2024-10-24T11:54:34.322Z",
        rcM_ModifiedBy: "",
        rcM_ModifiedDate: "2024-10-24T11:54:34.322Z"
      }
    ],
    recurringAmount: 0,
    cM_OneTime_lst: [
      {
        rcM_ID: "",
        rcM_Name: "",
        rcM_Standard_Price: 0,
        rcM_Status: "",
        rcM_CreatedBy: "",
        rcM_CreatedDate: "2024-10-24T11:54:34.322Z",
        rcM_ModifiedBy: "",
        rcM_ModifiedDate: "2024-10-24T11:54:34.322Z"
      }
    ],
    ccm_lst: [
      {
        cm_id: "",
        cm_price: 0,
        cm_standardprice: 0,
        cm_name: ""
      }
    ],
    slabElements_lst: slabElements_lst,
    cStatus: formValues.Status || "",
    advancePercentage: 0,
    ccM_InsertStatus: formValues.Status || "",
    client_ApplicabiltyLst: [
      {
        applicableName: "",
        applicableValue: "",
        applicableFlag: ""
      }
    ],
    clientAdvanceApplicabilitySelected: "",
    client_Invoice_Types: [
      {
        commInvoiceType: "",
        commInvoiceValue: ""
      }
    ],
    commercialInvoiceRadioValue: this.commercialForm.get('invoiceType')?.value,
    commercialInvoiceSplitType_Types: [
      {
        commercialSplitName: "",
        commercialSplitValue: ""
      }
    ],
    commercialInvoiceSplitTypeValue: this.commercialForm.get('invoiceType')?.value == 'S' ? this.commercialForm.get('splitInvoiceType')?.value : '',
    commercialSetupTypes: [
      {
        commercialSetUpName: "",
        commercialSetUpValue: ""
      }
    ],
    commercialSetUpValue: this.commercialForm.get('commercialType')?.value,
    commercialBranchList: commercialBranchList,
    lstOneTimeClientActivityList: lstOneTimeClientActivityList,
    loggedUserID: this.userID || '',
    clientWiseApplicabilty: [
      {
        advanceApplicabilty: "",
        percentage: 0,
        vendorCodeRequired: "",
        poApplicabilty: ""
      }
    ],
    poApplicabilty:this.commercialForm.get('poApplicability')?.value,
    poApplicabiltyList: [
      {
        text: "",
        value: "",
        sort: 0
      }
    ],
    vendorCodeRequired: "",
    vendorCodeRequiredList: [
      {
        text: "",
        value: "",
        sort: 0
      }
    ],
    billingZone: formValues.billingZone,
    invoiceCreationDate: formValues.invoiceDate
  }
  if(this.isFormValid){
    this.MasterAbstractService.SaveCommercialChange(payload).subscribe({
      next: (response) => {
        this.loading = false;
        // Check if the response status is 200 and response body is 0 (successful save)
        if (response.status === 200 && response.body === 1) {
          this.alertData = { 
            alertMessage: 'Commercial setup saved successfully', 
            alertVisible: 'success' 
          };
          this.loadGridData(); 
          this.getLumpSumDetails();
            // this.commercialForm.reset();
            // this.selectedStatus.value = '';
            //  this.poApplicability = 'Y';
            //  this.commercialType = 'L';
            //  this.splitInvoiceType = 'A';
            //  this.invoiceType = 'R';
            // this.SlabGrid = [];
            // this.selectedBranchList = [];
            // this.selectedActivityList = [];
        } else {
          this.alertData = { 
            alertMessage: 'Commercial setup Update Failed', 
            alertVisible: 'error' 
          };
        }
      },
      error: (error) => {
        console.error('Error saving Commercial setup', error);
        this.loading = false;
        this.alertData = { 
          alertMessage: 'Commercial setup Update Failed', 
          alertVisible: 'error' 
        };
      }
    });
  }
  }
}

