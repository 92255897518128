import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { KendoDatepickerComponent } from '../../../components/kendo-datepicker/kendo-datepicker.component';
import { NoticesServiceService } from '../../../service/notices-service.service';
import { UserService } from '../../../service/user.service';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isTransformerResult } from '@progress/kendo-data-query/dist/npm/transducers';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-edit-notice',
  standalone: true,
  imports: [NgIf, NgFor, DropdownListComponent, KendoDatepickerComponent, ReactiveFormsModule, FormsModule, AlertPopupComponent,IndicatorsModule,NgClass],
  templateUrl: './edit-notice.component.html',
  styleUrl: './edit-notice.component.css'
})
export class EditNoticeComponent implements OnInit {
  @Input() Data!: any;
  @Output() close = new EventEmitter<void>();
  form!: FormGroup;
  userId!: number;
  ModData!: any;
  loading=false;
  public placeholder: string = 'DD-MM-YYYY';
  todayDate: Date = new Date();
  NoticeReceivedDate: Date | null = null;
  ResponseDueDate: Date | null = null;
  showBasic: boolean = false;
  showRelavent: boolean = false;
  showAdditional: boolean = true;
  showValidationErrors: boolean = false;
  Remarks!: string;
  NoticeReferenceNumber!: string;
  TranslationRequired!: string;
  StatedropdownData: any[] = [];
  EntitydropdownData: any[] = [];
  LocationdropdownData: any[] = [];
  BranchdropdownData: any[] = [];
  ModeOfReceiptdropdownData: any[] = [
    { text: 'Select Mode Of Reciept', value: '' },
    { text: 'Email', value: 'Email' },
    { text: 'RegisteredPost', value: 'RegisteredPost' }
  ];
  RiskdropdownData: any[] = [
    { text: 'Select Risk', value: '' },
    { text: 'Highly Critical', value: 'HighlyCritical' },
    { text: 'Critical', value: 'Critical' },
    { text: 'Medium', value: 'Medium' }
  ];
  NoticeTypedropdownData: any[] = [];
  SMETeamdropdownData: any[] = [];
  SDExecuterTeamdropdownData: any[] = [];
  public alertData = { alertMessage: '', alertVisible: '' };
  Entity = { text: 'Select Entity', value: '' }
  State = { text: 'Select State', value: '' }
  Location = { text: 'Select Location', value: '' }
  Branch = { text: 'Select Branch', value: '' }
  ModeOfReceipt = { text: 'Select Receipt', value: '' }
  Risk = { text: 'Select Risk', value: '' }
  NoticeType = { text: 'Select Notice Type', value: '' }
  SMETeam = { text: 'Select SME Team', value: 0 }
  SDExecuterTeam = { text: 'Select SD Executer Team', value: 0 }
  role:string=''

  constructor(private editNoticeService: NoticesServiceService, private user: UserService, private fb: FormBuilder, private router: Router) {

    this.form = this.fb.group({
      translationRequestDate: [{ value: '', disabled: true }],
      translationDocumentUploadDate: [{ value: '', disabled: true }],
      extensionDraftExtensionDate: [{ value: '', disabled: true }],
      extensionAppliedOn: [{ value: '', disabled: true }],
      extensionApplicationDate: [{ value: '', disabled: true }],
      extensionGrantedDate: [{ value: '', disabled: true }],
      noticeDraftResponseDate: [{ value: '', disabled: true }],
      noticeResponseSubmittedToDept: [{ value: '', disabled: true }]
    });
  }
  ngOnInit(): void {
    this.userId = this.user.getUserId();
    // this.getStateDropdown('AVAPRINCE');
    // this.GetNoticeClientList(this.userId);
    // this.getNoticeTypeList();
    // this.getGetNoticeLocation('ANP','AVAPRINCE');
    // this.GetNoticeBranchForClient('AVAPRINCE','ANP','505327');
    // // this.getGetNoticeRisk('9')
    // this.getSMETeamList();
    // this.getSDExecuterTeamList();
    // this.GetNoticeData('ABCD');
    this.role=this.user.getRole()
    console.log(this.user.getRole());
    
    if (this.Data) {
      this.loading=true;
      this.GetNoticeClientList(this.userId);
      this.getStateDropdown(this.Data.clientId);
      this.getNoticeTypeList();
      this.getGetNoticeLocation(this.Data.state, this.Data.clientId);
      this.GetNoticeBranchForClient(this.Data.clientId, this.Data.state, this.Data.location);
      this.getSMETeamList();
      this.getSDExecuterTeamList();
      this.GetNoticeData(this.Data.noticeReferenceNo);
      this.loading=false;

    }
  }
  InitData(data: any) {
      this.form.patchValue({
      translationRequestDate: new Date(data.translationRequestDate).toLocaleDateString('en-GB'),
      translationDocumentUploadDate: new Date(data.translationDocumentUploadDate).toLocaleDateString('en-GB'),
      extensionDraftExtensionDate: new Date(data.extensionDraftExtensionDate).toLocaleDateString('en-GB'),
      extensionAppliedOn: new Date(data.extensionAppliedOn).toLocaleDateString('en-GB'),
      extensionApplicationDate: new Date(data.extensionApplicationDate).toLocaleDateString('en-GB'),
      extensionGrantedDate: new Date(data.extensionGrantedDate).toLocaleDateString('en-GB'),
      noticeDraftResponseDate: new Date(data.noticeDraftResponseDate).toLocaleDateString('en-GB'),
      noticeResponseSubmittedToDept: new Date(data.noticeResponseSubmittedToDept).toLocaleDateString('en-GB'),
    });
    this.Entity = { text: data?.clientId, value: data?.clientId }
    this.State = { text: '', value: data?.state }
    this.Location = { text: '', value: data?.location }
    this.Branch = { text: '', value: data?.branch }
    this.ModeOfReceipt = { text: '', value: data?.modeOfReceipt };
    this.Risk = { text: '', value: data?.risk };
    this.NoticeType = { text: data?.noticeType, value: data?.noticeTypeId.toString() };
    this.NoticeReceivedDate = data.noticeReceivedDate ? new Date(data.noticeReceivedDate) : null;
    this.ResponseDueDate = data.responseDueDate ? new Date(data.responseDueDate) : null;
    this.Remarks = data?.remarks
    this.NoticeReferenceNumber = this.Data?.noticeReferenceNo
    this.SMETeam = { text: '', value: data?.sme.toString() };
    this.SDExecuterTeam = { text: '', value: data?.sdExecuter.toString() };
    this.onRadioChange(data?.isTranslationRequired);
  }
  GetNoticeData(Noticenumber: string) {
    this.editNoticeService.GetNoticeData(Noticenumber).subscribe(
      {
        next: (response) => {
          const data = response.body;
          this.InitData(data[0]);
          this.ModData = data[0];
        }, error: (error) => {
          console.error('Error fetching State Dropdown data', error);
        }
      });
  }
  MailSentAfterNoticeAssignment(NoticeNumber:string): void {
    this.loading = true;
    this.editNoticeService.MailSentAfterNoticeAssignment(NoticeNumber).subscribe({
      next: (response) => {
        console.log('grid response', response);

        if (response.status === 200) {
          const data = response.body;
        console.log();
        
        }
        else {
          console.error('Error fetching entity data');
          this.loading = false;
        }
      },
      error: (error) => {
        this.loading = false;
        console.error('Error fetching entity data', error);
      }
    });
  }
  getStateDropdown(clientId: string) {
    this.editNoticeService.GetNoticeState(clientId).subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && data && Array.isArray(data)) {
            this.StatedropdownData = [{ text: 'Select State', value: '' },
            ...data.map((item: any) => ({
              value: item.value,
              text: item.text
            }))]
          } else {
            console.error("Unexpected data format", data);
          }
        }, error: (error) => {
          console.error('Error fetching State Dropdown data', error);
        }
      });
  }
  GetNoticeClientList(UserId: number) {
    this.editNoticeService.GetNoticeClientList(UserId).subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && data && Array.isArray(data)) {
            this.EntitydropdownData = [{ text: 'Select Entity', value: '' },
            ...data.map((item: any) => ({
              value: item.value,
              text: item.text
            }))]
          } else {
            console.error("Unexpected data format", data);
          }
        }, error: (error) => {
          console.error('Error fetching State Dropdown data', error);
        }
      });
  }
  getGetNoticeLocation(statecode: string, clientid: string) {
    this.editNoticeService.getGetNoticeLocation(statecode, clientid).subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && data && Array.isArray(data)) {
            this.LocationdropdownData = [{ text: 'Select Location', value: '' },
            ...data.map((item: any) => ({
              value: item.value,
              text: item.text
            }))]
          } else {
            console.error("Unexpected data format", data);
          }
        }, error: (error) => {
          console.error('Error fetching State Dropdown data', error);
        }
      });
  }
  GetNoticeBranchForClient(clientid: string, statecode: string, locationcode: string) {
    this.editNoticeService.GetNoticeBranchForClient(clientid, statecode, locationcode).subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && data && Array.isArray(data)) {
            this.BranchdropdownData = [{ text: 'Select Branch', value: '' },
            ...data.map((item: any) => ({
              value: item.value,
              text: item.text
            }))]
          } else {
            console.error("Unexpected data format", data);
          }
        }, error: (error) => {
          console.error('Error fetching State Dropdown data', error);
        }
      });
  }
  getNoticeTypeList() {
    this.editNoticeService.getNoticeTypeList().subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && data && Array.isArray(data)) {
            this.NoticeTypedropdownData = [{ text: 'Select Notice Type', value: '' },
            ...data.map((item: any) => ({
              value: item.value,
              text: item.text
            }))]
          } else {
            console.error("Unexpected data format", data);
          }
        }, error: (error) => {
          console.error('Error fetching State Dropdown data', error);
        }
      });
  }
  getSMETeamList() {
    this.editNoticeService.getSMETeamList().subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && data && Array.isArray(data)) {
            this.SMETeamdropdownData = [{ text: 'Select SME Team', value: 0 },
            ...data.map((item: any) => ({
              value: item.value,
              text: item.text
            }))]
          } else {
            console.error("Unexpected data format", data);
          }
        }, error: (error) => {
          console.error('Error fetching State Dropdown data', error);
        }
      });
  }
  getSDExecuterTeamList() {
    this.editNoticeService.getSDExecuterTeamList().subscribe(
      {
        next: (response) => {
          const data = response.body;
          if (data && data && Array.isArray(data)) {
            this.SDExecuterTeamdropdownData = [{ text: 'Select SD Executer Team', value: 0 },
            ...data.map((item: any) => ({
              value: item.value,
              text: item.text
            }))]
          } else {
            console.error("Unexpected data format", data);
          }
        }, error: (error) => {
          console.error('Error fetching State Dropdown data', error);
        }
      });
  }
  onRadioChange(value: any) {
    if (value == 'Y') {
      this.TranslationRequired = value;
    } else if (value == 'N') {
      this.TranslationRequired = value;
    } else if (value == true) {
      this.TranslationRequired = 'Y';
    } else if (value == false) {
      this.TranslationRequired = 'N';
    }
  }
  onDateChange(value: Date, type: string) {
    const date = new Date(value.toISOString().split('T')[0]);
    if (type === 'NoticeReceivedDate') {
      this.NoticeReceivedDate = date;
    }
    if (type === 'ResponseDueDate') {
      this.ResponseDueDate = date;
    }
  }
  onDropdownValueChange(value: any, type: string) {
    if (type == 'Entity') {
      this.Entity = { text: value.text, value: value.value }
    }
    else if (type == 'State') {
      this.State = { text: value.text, value: value.value }
    }
    else if (type == 'Location') {
      this.Location = { text: value.text, value: value.value }
    }
    else if (type == 'Branch') {
      this.Branch = { text: value.text, value: value.value }
    }
    else if (type == 'ModeOfReceipt') {
      this.ModeOfReceipt = { text: value.text, value: value.value }
    }
    else if (type == 'Risk') {
      this.Risk = { text: value.text, value: value.value }
    }
    else if (type == 'NoticeType') {
      this.NoticeType = { text: value.text, value: value.value }
    }
    else if (type == 'SMETeam') {
      this.SMETeam = { text: value.text, value: value.value }
    }
    else if (type == 'SDExecuterTeam') {
      this.SDExecuterTeam = { text: value.text, value: value.value }
    }
  }
  toggle(dropdownType: any) {
    if (dropdownType === 'Basic') {
      this.showBasic = !this.showBasic;
      this.showRelavent = false;
      this.showAdditional = false;
    }
    else if (dropdownType === 'Relavent') {
      this.showRelavent = !this.showRelavent;
      this.showBasic = false;
      this.showAdditional = false;
    }
    else if (dropdownType === 'Additional') {
      this.showAdditional = !this.showAdditional;
      this.showRelavent = false;
      this.showBasic = false;
    }
  }
  UpdateNotice() {
    this.loading=true;
    const body = {
      clientId: this.ModData?.clientId,
      status: this.ModData?.status,
      stateId: this.State.value || '',
      locationId: this.Location.value || '',
      branch: this.Branch.value || '',
      noticeNumber: this.NoticeReferenceNumber || '',
      receiptMode: this.ModeOfReceipt.value,
      risk: this.Risk.value || '',
      responseDueDate: this.ResponseDueDate ? this.formatDate(this.ResponseDueDate) : null,
      noticeReceivedDate: this.NoticeReceivedDate ? this.formatDate(this.NoticeReceivedDate) : null,
      noticeTypeid: this.NoticeType.value || 0,
      remarks: this.Remarks || ''
    }

    this.editNoticeService.UpdateNotice(body).subscribe({
      next: (response) => {
        if (response.status == 200) {
          console.log('EditNoticeDetails', response);
          const data = response.body;
          if(data.result==1){
            this.alertData = { alertMessage: 'Notice saved succesfully', alertVisible: 'success' };
            this.loading=false;
          }else{

            this.loading=false;
            this.alertData = { alertMessage: 'Notice Not saved succesfully', alertVisible: 'error' };
          }
        } else {
          this.loading=false;
          this.alertData = { alertMessage: 'Notice Not saved succesfully', alertVisible: 'error' };
        }
      }, error: (error) => {
        this.loading=false;
        this.alertData = { alertMessage: 'Notice Not saved succesfully', alertVisible: 'error' };
        console.log(error);
      }
    });
  }
  SaveNoticeDetails() {
    let TranslationRequired = null;
    if (this.TranslationRequired == 'Y') {
      TranslationRequired = true;
    } else if (this.TranslationRequired == 'N') {
      TranslationRequired = false;
    } else {
      TranslationRequired = null;
    }
    const body = {
      translationRequestDate: this.ModData?.translationRequestDate,
      isTranslationRequired: TranslationRequired,
      sme: this.SMETeam.value || '',
      clientId: this.ModData?.clientId||'',
      sdExecuter: this.SDExecuterTeam.value || '',
      status: this.ModData?.status,
      stateId: this.State.value || '',
      locationId: this.Location.value || '',
      branch: this.Branch.value || '',
      noticeNumber: this.NoticeReferenceNumber || '',
    }
    console.log('save api', body);

    this.editNoticeService.SaveNoticeDetails(body).subscribe({
      next: (response) => {
        if (response.status == 200) {
          console.log('SaveNoticeDetails', response);
          const data = response.body;
          if(data==1){
            this.alertData = { alertMessage: 'Notice saved succesfully', alertVisible: 'success' };
            this.MailSentAfterNoticeAssignment(body.noticeNumber);
            this.loading=false;
          }else{

            this.loading=false;
            this.alertData = { alertMessage: 'Notice Not saved succesfully', alertVisible: 'error' };
          }
        } else {
          this.loading=false;
          this.alertData = { alertMessage: 'Notice Not saved succesfully', alertVisible: 'error' };
        }
      }, error: (error) => {
        this.loading=false;
        this.alertData = { alertMessage: 'Notice Not saved succesfully', alertVisible: 'error' };
        console.log(error);
      }
    });
  }
  formatDate(date: Date): string {
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear().toString();

    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const seconds = date.getUTCSeconds().toString().padStart(2, '0');

    // Include 'Z' to indicate UTC time
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
  }

  closeAlert() {
    if(this.alertData.alertVisible=='success'){
      this.alertData = { alertMessage: '', alertVisible: '' };
      this.ngOnInit();
    }
    this.alertData = { alertMessage: '', alertVisible: '' };
  }

  goBack() {
    // this.router.navigate(['/Notices']);
    this.Data = undefined;
    this.close.emit();
  }
}
