<kendo-dialog #dialogRef title="Upload Holiday Master" (close)="closePopup()" [minWidth]="300" [width]="500" class="custom-dialog">
  
        <div class="col-md-12 sampleDocument">
            <div style=" float: right;">
            <img src="../assets/vectors/Excel.svg" alt="">
            <a *ngIf="holidayType=='C'" href="../assets/BulkUploadDocuments/Sample-YearlyHoliday_Clientwise.xlsx" class="f-label" download="Sample-YearlyHoliday_Clientwise.xlsx"
                style="color: #125AAB; text-decoration: underline;" id="btnSampleRLCS">
            </a>
            <a *ngIf="holidayType=='S'" href="../assets/BulkUploadDocuments/Sample-YearlyHoliday_Statewise.xlsx" class="f-label" download="Sample-YearlyHoliday_Statewise.xlsx"
                style="color: #125AAB; text-decoration: underline;" id="btnSampleRLCS">
            </a>
            <a *ngIf="holidayType=='B'" href="../assets/BulkUploadDocuments/Sample-YearlyHoliday_Branchwise.xlsx" class="f-label" download="Sample-YearlyHoliday_Branchwise.xlsx"
                style="color: #125AAB; text-decoration: underline;" id="btnSampleRLCS">
                Sample Document
            </a>
        </div>
    </div>

    <div class="row col-12 dialog-content">
        <div class="col-md-12 form-group">
            <div class="drop-zone"  (dragover)="onDragOver($event)"
                (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
                <div *ngIf="!selectedFile" class="f-label" style="font-size: 18px;">
                    <div class="col-md-12" style="margin-top: -10px;">
                        <img src="../assets/vectors/cloud.svg" alt="">
                    </div>
                    <div class="col-md-8">
                        <label style="margin-left: 90px;">{{ dropzoneMessage }} </label>
                    </div>
                    <div class="col-md-3">
                        <label
                            style="color: #125AAB; text-decoration: underline; cursor: pointer; margin-left: -40px;  font-weight: 500;">
                            Browse
                            <input id="file-upload" type="file" style="display: none;" (change)="onFileSelect($event)"
                                accept=".xls,.xlsx,.csv" />
                        </label>
                    </div>
                </div>
                <p *ngIf="selectedFile" style="margin-top: 40px;">{{ selectedFile.name }}</p>
            </div>
        </div>
    </div>

    
        <button class="figma-btn-blue figma-popup upload-btn"
            (click)="uploadHolidayMaster()">Upload</button>
</kendo-dialog>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270"   [Data]="alertData"
    (close)="closeAlert()"></app-alert-popup>
    
    <div *ngIf="loading" class="loader-overlay">
        <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
    </div>