<kendo-dialog #dialogRef title="Designation Details" (close)="closePopup()" [minWidth]="300" [width]="500"
    class="custom-dialog">
    <form [formGroup]="userForm">
        <div class="form-group">
            <label for="long_designation" class="f-label">Designation</label>
            <input id="Designation" formControlName="long_designation" placeholder="Enter Designation" class="f-textbox"
                style="width: 470px;"
                [ngClass]="{'is-invalid': userForm.get('long_designation')?.invalid &&  userForm.get('long_designation')?.touched }" />
            <div *ngIf="userForm.get('long_designation')?.invalid && (userForm.get('long_designation')) && userForm.get('long_designation')?.errors?.['pattern']"
                class="text-danger" style="float:left; margin-bottom: 2px;">
                Designation must contain only letters.
            </div>
        </div>
        <div class="form-group" *ngIf="showStatusdropDown">
            <label for="status" class="f-label">Status</label>
            <app-dropdown-list [data]="dropdownData" [selectedValue]="defaultSelectedValue" textField="name"
                valueField="id" [width]="'470px'" (valueChange)="onDropdownValueChange($event)"
                [hasError]="userForm.get('status')?.invalid &&  userForm.get('status')?.touched && userForm.get('status')?.errors?.['required']">
            </app-dropdown-list>
        </div>
    </form>

    <kendo-dialog-actions class="ksave">
        <button kendoButton type="button" (click)="onSave()" class="figma-btn-blue figma-popup">
            Save
        </button>
    </kendo-dialog-actions>
</kendo-dialog>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270" [Height]="270" [Data]="alertData"
    (close)="closeAlert()"></app-alert-popup>

<div *ngIf="loading" class="loader-overlay">
    <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
</div>