import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ZoneLocationMappingService {
  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }
  getPincodeAndLocationByState(state: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MinimumWage/GetPincodeAndLocationByState/${state}`;
    return this.http.get<any>(url,{observe:'response'});
  }
  getAllStateList(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/StateMaster/Get_StateMaster`;
    return this.http.get<any>(url,{observe:'response'});
  }
  getZoneList(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MinimumWage/Get_LC_MW_ZoneLocation_Mapping`;
    return this.http.get<any>(url, {observe:'response'});
  }
  getZoneByState(state:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MinimumWage/Get_LC_MW_ZoneLocation_Mapping_ByState/${state}`;
    return this.http.get<any>(url, {observe:'response'});
  }
  deleteZoneMapping(id: any): Observable<HttpResponse<any>>  {
    const url = `${this.apiUrl}/api/MinimumWage/delete_Locations_ForZoneMapping/${id}`;
    return this.http.delete<any>(url);
  }
  uploadFile(file: File,state: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MinimumWage/Upload_LC_MW_ZoneLocation_Mapping`;
    const formData: FormData = new FormData();
    formData.append('state', state);
    formData.append('file', file, file.name);

    return this.http.post<any>(url, formData);
  }
  saveZoneMappingData(state: any, zone: any, location: any, pincode: any): Observable<HttpResponse<any>>  {
    const url = `${this.apiUrl}/api/MinimumWage/Add_Update_LC_MW_ZoneLocation_Mapping`;
    const createdDate = new Date().toISOString();
    const updatedDate = new Date().toISOString();

    const payload = {
      state: state,
      zone: zone,
      location: location,
      pincode: pincode,
      createdOn: createdDate,
      updatedOn: updatedDate
    };
    return this.http.post<any>(url, payload, { observe:'response'} );
  }

  getMWTemplate(state: any,industry_Type:string): Observable<HttpResponse<any>> {
    const body={
     state: state,
     industry_type: industry_Type
    }
    const url = `${this.apiUrl}/api/MinimumWage/Get_ZLM_Template`;
    return this.http.post<any>(url, body,{observe:'response'});
  }

}
