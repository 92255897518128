import { Component, ViewChild } from '@angular/core';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { NgIf } from '@angular/common';
import { DesignationMasterService } from '../../../service/designation-master.service';
import { DesignationBulkUploadComponent } from '../../popups/designation-bulk-upload/designation-bulk-upload.component';
import { DesignationMasterAddUpdateComponent } from '../../popups/designation-master-add-update/designation-master-add-update.component';
import { BlobService } from '../../../service/blob.service';
import { GridDataResult, GridModule, PageChangeEvent } from '@progress/kendo-angular-grid';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';

@Component({
  selector: 'app-designation-master',
  standalone: true,
  imports: [DropDownListModule, DropdownListComponent, AlertPopupComponent, NgIf, DesignationBulkUploadComponent, DesignationMasterAddUpdateComponent, GridModule, ComboBoxComponent, IndicatorsModule],
  templateUrl: './designation-master.component.html',
  styleUrl: './designation-master.component.css'
})
export class DesignationMasterComponent {
  public buttonCount = 2;
  public sizes = [{text:'All',value:1},5, 10, 20];
  public gridData: GridDataResult = { data: [], total: 0 };
  public pageSize: number = 10;
  public uploadPopupOpen: boolean = false;
  public AddUpdatePopupOpen: boolean = false;
  public applyFilterEvent!: any;
  public popupData!: any;
  showState = false;
  loading: boolean = false;
  deleteId = 0;
  public alertData = { alertMessage: '', alertVisible: '' };
  dropdownData = [
    { text: 'Select', value: 'select' },
    { text: 'Active', value: 'active' },
    { text: 'InActive', value: 'inactive' }
  ];
  public dropdown: { id: number; name: string }[] = [];
  DropdownDesignation :any={id:null,name:null};

  public defaultFilterValue = { text: 'Select', value: 'select' };
  public defaultpopupValue = { id: 0, name: 'Select' };
  public defaultSelectedValue = 'Search Designation';
  take = 10;
  skip = 0;
  page = 1;
  constructor(private DesignationServices: DesignationMasterService, private blobService: BlobService) { }

  ngOnInit(): void {
    this.loadGridData('', '');
    this.FilterDropdown('0')
  }

  // public pageChange(event: PageChangeEvent): void {
    
  //   this.skip = event.skip;
  //   this.page = (this.skip / this.pageSize) + 1;
  //   if(event.take==1){
  //     this.gridData.total;
  //   }
  //   this.pageSize = event.take;
  //   this.loadGridData('', '');
  // }
  public pageChange(event: PageChangeEvent): void {
    this.loading=true;
    this.skip = event.skip;
    this.page = (this.skip / this.pageSize) + 1;
    if(event.take==1){
      this.pageSize = this.gridData.total;
    }else{
      this.pageSize = event.take;
    }
     this.loadGridData('', '');
  }


  FilterDropdown(value: any) {
    this.DesignationServices.getDesignationDropdown(value).subscribe({
      next: (response) => {
        if (response.status === 200) {
          const data = response.body;
          if (data && data.data) {
            this.dropdown = data.data.map((item: any) => ({
              id: item.key,
              name: item.value
            }));
          }
        }
      }
    })
  }
  preventInvalidInput(event: KeyboardEvent): void {
    const regex = /^[a-zA-Z\s]*$/; 

    const key = event.key;

    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];

    if (!regex.test(key) && !allowedKeys.includes(key)) {
      event.preventDefault();
    }
  }
  DropdownGridChange(value: any) {
    if (value == undefined) {
      this.loadGridData('', '')
    }
    this.DropdownDesignation = {id:value.id,name:value.name};
  }

  loadGridData(value: string, filter: string) {
    this.DesignationServices.getAllDesignationMasters(value, '', this.take, this.skip, this.page, this.pageSize).subscribe({
      next: (response) => {
        if (response.status === 200) {
          const data = response.body
          this.gridData = {
            data: data.data,
            total: data.total
          };
          this.loading=false;
        }
        else {
          console.error('Error fetching grid data:', response.body.status.error);
        }
      }
    })
  }
  exportToExcel(): void {
    this.loading = true;
    this.DesignationServices.getDesignationExcel().subscribe({
      next: (response: any) => {
        if (response.status === 200) {
          const data = response.body
          const blobLink = data.filePath;
          const blobName = this.blobService.extractBlobName(blobLink);
          setTimeout(() => {
            this.blobService.getSasToken(blobName).subscribe(
              (tokenResponse: any) => {
                try {
                  const sasToken = tokenResponse.result;
                  this.blobService.downloadFile(blobName, sasToken);
                  this.loading = false;
                } catch (error) {
                  this.alertData={alertMessage:'File Not Found',alertVisible:'error'};
                  this.loading = false;
                }
              },
              (error) => {
                this.alertData={alertMessage:'File Not Found',alertVisible:'error'};
                this.loading = false; 
              }
            );
          }, 5000);
        }
        else {
          console.error('Error fetching blob link:', response.body.error);
          this.showState = false;
          this.loading = false;
        }
      }})
}

  UpdateNew(event: any) {
    this.popupData = event;
    this.defaultpopupValue = { id: event.long_designation, name: event.status }
    this.AddUpdatePopupOpen = true;
  }
  confirmAlert(value: any) {
    this.deleteId = value.id;
    this.alertData = { alertMessage: 'Are you sure you want to delete this record?', alertVisible: 'confirm' };

  }
  DeleteMWMaster(value: any) {
    this.DesignationServices.deleteDesignation(value).subscribe({
      next: (response) => {
        if (response.status === 200) {
          this.alertData = { alertMessage: 'Data Deleted Successfully', alertVisible: 'success' };
          this.loadGridData('', '');
        }
        else {
          this.alertData = { alertMessage: 'Error fetching grid data', alertVisible: 'error' };
        }
      }
    })
  }

  clearFilter() {
    this.loadGridData('', '')
    this.defaultFilterValue = { text: 'Select', value: 'select' }
    this.defaultSelectedValue = 'Search/Select Designation';
    this.DropdownDesignation=null
    this.dropdown = [];
  }
  
  applyFilter() {
    this.loadGridData(this.DropdownDesignation && this.DropdownDesignation.name ? this.DropdownDesignation.name : '', this.applyFilterEvent.text[0])
  }
  onDropdownValueChange(value: any) {
    this.applyFilterEvent = value;
  }
  OpenDesignationPopup() {
    this.defaultpopupValue = { id: 0, name: 'Select ' };
    this.popupData = '';
    this.AddUpdatePopupOpen = true;
  }
  openUploadPopup() {
    this.uploadPopupOpen = true;
  }

  handleclosePopup() {
    this.AddUpdatePopupOpen = false;
    this.uploadPopupOpen = false;
      this.loadGridData('', '');
  }
  public closeAlert(): void {
    this.alertData = { alertMessage: '', alertVisible: '' };
  }
}
