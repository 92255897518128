<div class="row colpadding0  " style="margin-top: 10px;">
    <div class="col-12  ">
        <div *ngIf="shouldShowDropdowns()">
            <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex">
                    <app-dropdown-list [data]="complianceReassignment" [selectedValue]="selectedComplianceReassignment"
                        class="space-setting" [width]="'200px'" textField="text" valueField="value"
                        (valueChange)="oncomplianceReassignmentChange($event)">
                    </app-dropdown-list>

                    <app-dropdown-list [data]="complianceType" [selectedValue]="selectedComplianceType"
                        [width]="'200px'" class="space-setting" textField="text" valueField="value"
                        (valueChange)="oncomplianceTypeChange($event)"
                        [class.input-invalid]="!selectedComplianceType.value && showValidationErrors">
                        <div *ngIf="!selectedComplianceType.value && showValidationErrors" class="validation-error">
                        </div>
                    </app-dropdown-list>


                    <div class="dropdown-container" (document:click)="onDocumentClick($event)">
                        <kendo-dropdownlist [data]="[selectedNodeName || 'Entity/Sub-Entity/Location']"
                            [(ngModel)]="selectedNodeName" [valuePrimitive]="true" (click)="toggleDropdown()"
                            [readonly]="true" class="f-textbox">
                        </kendo-dropdownlist>
                        <div class="treeview-dropdown" *ngIf="isDropdownOpen">

                            <kendo-treeview placeholder="Entity/Sub-Entity/Location" [nodes]="BranchDropdown"
                                textField="name" [hasChildren]="hasChildren" [children]="fetchChildren"
                                kendoTreeViewExpandable [expandedKeys]="['0']" kendoTreeViewSelectable
                                (selectedKeys)="branchId" (selectionChange)="onBranchChange($event)">
                            </kendo-treeview>
                        </div>
                    </div>

                </div>

                <div class="d-flex">
                    <button *ngIf="isAnyCheckboxChecked" class="figma-btn-blue save-btn" (click)="reassignCompliance()">
                        Save
                    </button>
                    <button class="figma-btn-white clear-btn" (click)="clearFilter()">
                        <img src="../assets/vectors/ClearFilter.svg" alt="">
                    </button>
                    <button class="figma-btn-white apply-btn" type="submit" (click)="applyFilter()">
                        <img src="../assets/vectors/ApplyFilter.svg" alt="">
                    </button>
                    <button class="figma-btn-blue map-btn" (click)="upload()">
                        <img src="../assets/vectors/uploadWhite.svg" alt=""> Upload
                    </button>
                </div>
            </div>


            <div class="row-2">



                <app-dropdown-list [data]="role" [selectedValue]="selectedRole" [width]="'200px'" textField="text"
                    valueField="value" (filterChange)="getRoles()" (valueChange)="onRoleChange($event)">
                </app-dropdown-list>

                <app-dropdown-list [data]="existingUser" [selectedValue]="selectedExistingUser"
                    (filterChange)="getExistingUserList()" (valueChange)="onUserChange($event)" textField="key"
                    valueField="value" [width]="'200px'">
                </app-dropdown-list>

                <app-dropdown-list [data]="newUser" [selectedValue]="selectedNewUser" (filterChange)="getNewUserList()"
                    (valueChange)="onNewUserChange($event)" textField="key" valueField="value" [width]="'200px'">
                </app-dropdown-list>

                <input #searchInput placeholder="Type to Search" class="f-textbox " (input)="filterChange($event)">
            </div>
        </div>

        <div *ngIf="selectedComplianceReassignment.value==='ActivationDateChange'">
            <app-dropdown-list [data]="complianceReassignment" [selectedValue]="selectedComplianceReassignment"
                [width]="'200px'" textField="text" valueField="value"
                (valueChange)="oncomplianceReassignmentChange($event)">
            </app-dropdown-list>
            <kendo-multiselecttree #multiselectTree placeholder="Entity/Sub-Entity/Location"
                [popupSettings]="popupSettings" kendoMultiSelectTreeExpandable
                [kendoMultiSelectTreeHierarchyBinding]="BranchDropdown" childrenField="children" textField="name"
                valueField="id" [tagMapper]="tagMapper" [value]="branch2" (valueChange)="OnMultiSelectChange($event)"
                [expandedKeys]="['0']" class="f-textbox multi-box" style="margin-left: 10px;">
            </kendo-multiselecttree>
            <input #searchInput kendoTextBox placeholder="Type to Search" class="f-textbox searchbox"
                (keyup)="activationDatefilterChange($event)">

            <button class="figma-btn-blue map-btn" (click)="upload()">
                <img src="../assets/vectors/uploadWhite.svg" alt="">
                Upload
            </button>
            <button class="figma-btn-white apply-btn" type="submit" (click)="applyFilter()">
                <img src="../assets/vectors/ApplyFilter.svg" alt="">
            </button>
            <button class="figma-btn-white clear-btn" (click)="clearFilter()">
                <img src="../assets/vectors/ClearFilter.svg" alt="">
            </button>
            <button *ngIf="isSaveButtonVisible" class="figma-btn-blue save-btn" (click)="saveUpdatedDate()">
                Save
            </button>
        </div>

        <div *ngIf="selectedComplianceReassignment.value==='AssignmentRemoval'">
            <app-dropdown-list [data]="complianceReassignment" [selectedValue]="selectedComplianceReassignment"
                [width]="'200px'" textField="text" valueField="value"
                (valueChange)="oncomplianceReassignmentChange($event)">
            </app-dropdown-list>
            <kendo-multiselecttree #multiselectTree placeholder="Entity/Sub-Entity/Location"
                [popupSettings]="popupSettings" kendoMultiSelectTreeExpandable
                [kendoMultiSelectTreeHierarchyBinding]="BranchDropdown" childrenField="children" textField="name"
                valueField="id" [tagMapper]="tagMapper" [value]="branch3" (valueChange)="OnMultiSelectChange($event)"
                [expandedKeys]="['0']" class="f-textbox multi-box" style="margin-left: 10px;">
            </kendo-multiselecttree>
            <input #searchInput kendoTextBox placeholder="Type to Search" class="f-textbox searchbox"
                (keyup)="activationDatefilterChange($event)">

            <button class="figma-btn-blue map-btn" (click)="upload()">
                <img src="../assets/vectors/uploadWhite.svg" alt="">
                Upload
            </button>
            <button class="figma-btn-white apply-btn" type="submit" (click)="applyFilter()">
                <img src="../assets/vectors/ApplyFilter.svg" alt="">
            </button>
            <button class="figma-btn-white clear-btn" (click)="clearFilter()">
                <img src="../assets/vectors/ClearFilter.svg" alt="">
            </button>
            <button *ngIf="anyChecked" class="figma-btn-blue remove-btn" (click)="removeAssignment()">
                Remove
            </button>
        </div>

        <!-- ************************************************************************************ -->

        <div *ngIf="selectedComplianceReassignment.value==='FormsMappping'">
            <app-dropdown-list [data]="complianceReassignment" [selectedValue]="selectedComplianceReassignment"
                class="space-setting" [width]="'250px'" textField="text" valueField="value"
                (valueChange)="oncomplianceReassignmentChange($event)">
            </app-dropdown-list>
            <app-dropdown-list [data]="schedulesComplianceType" [selectedValue]="selectedschedulesComplianceType"
                class="space-setting" [width]="'150px'" textField="text" valueField="value"
                (valueChange)="onSchedulescomplianceTypeChange($event)"
                [ngClass]="{'input-invalid': formSubmitted && !selectedschedulesComplianceType}">
            </app-dropdown-list>
            <app-dropdown-list [data]="periods" [selectedValue]="selectedPeriodCreation" [width]="'150px'"
                textField="text" class="space-setting" valueField="value" (valueChange)="onPeriodChange($event)"
                [ngClass]="{'input-invalid': formSubmitted && !selectedPeriodCreation}">
            </app-dropdown-list>
            <app-dropdown-list [data]="years" [selectedValue]="selectedYearCreation" [width]="'150px'" textField="text"
                valueField="value" (valueChange)="onYearChange($event)"
                [ngClass]="{'input-invalid': formSubmitted && !selectedYearCreation}">
            </app-dropdown-list>
            <button class="figma-btn-blue map-btn" (click)="upload()">
                <img src="../assets/vectors/uploadWhite.svg" alt="">
                Upload
            </button>
            <button class="figma-btn-blue apply-btn" type="submit" (click)="scheduleCreation()">
                Submit
            </button>
            <button class="figma-btn-white clear-btn" (click)="clearFilter()">
                <img src="../assets/vectors/ClearFilter.svg" alt="">
            </button>

        </div>
        <!-- ******************************************************************************* -->

        <div *ngIf="selectedComplianceReassignment.value==='ScheduleRemoval'">
            <app-dropdown-list [data]="complianceReassignment" [selectedValue]="selectedComplianceReassignment"
                [width]="'250px'" textField="text" valueField="value"
                (valueChange)="oncomplianceReassignmentChange($event)">
            </app-dropdown-list>
            <kendo-multiselecttree #multiselectTree placeholder="Entity/Sub-Entity/Location"
                [popupSettings]="popupSettings" kendoMultiSelectTreeExpandable
                [kendoMultiSelectTreeHierarchyBinding]="BranchDropdown" childrenField="children" textField="name"
                valueField="id" [tagMapper]="tagMapper" [value]="branch4" (valueChange)="OnMultiSelectChange($event)"
                [expandedKeys]="['0']" class="f-textbox multi-box" style="margin-left: 10px;">
            </kendo-multiselecttree>
            <app-dropdown-list [data]="periods" [selectedValue]="selectedPeriod" [width]="'200px'" textField="text"
                    valueField="value" (valueChange)="onPeriodChange($event)">
                </app-dropdown-list>
            <button class="figma-btn-blue map-btn" (click)="upload()">
                <img src="../assets/vectors/uploadWhite.svg" alt="">
                Upload
            </button>
            <button class="figma-btn-white apply-btn" type="submit" (click)="applyFilter()">
                <img src="../assets/vectors/ApplyFilter.svg" alt="">
            </button>
            <button class="figma-btn-white clear-btn" (click)="clearFilter()">
                <img src="../assets/vectors/ClearFilter.svg" alt="">
            </button>
            <button *ngIf="scheduleList" class="figma-btn-blue clear-btn" (click)="removeSchedules()">
                Remove
            </button>
            <div class="row-2">
                <app-dropdown-list [data]="years" [selectedValue]="selectedYear" [width]="'200px'" textField="text"
                    valueField="value" (valueChange)="onYearChange($event)">
                </app-dropdown-list>
                <input #searchInput placeholder="Type to Search" class="f-textbox searchbox"
                    (input)="scheduleFilterChange($event)">
            </div>
        </div>
    </div>


    <div class="grid" *ngIf="shouldShowDropdowns() ">
        <kendo-grid class="custom-grid" [data]="gridData" [skip]="skip" [pageSize]="pageSize"
            [columnMenu]="{ filter: true, sort: true }" [pageable]="true" (pageChange)="pageChange($event)">

            <kendo-grid-column [width]="50" [columnMenu]="false">
                <ng-template kendoGridHeaderTemplate>
                    <div style="margin-left: 25%;">
                        <input type="checkbox" [(ngModel)]="allSelected" (change)="toggleSelectAll($event)" />
                    </div>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="checkbox-container">
                        <input type="checkbox" [(ngModel)]="dataItem.selected"
                            (change)="onReassignmentCheckboxChange(dataItem.complianceInstanceID, $event)" />
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="complianceID" title="Compliance ID" [width]="120" class="cell-content">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.complianceID" class=" cell-content">
                        {{ dataItem.complianceID }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="sM_Name" title="State" [width]="100" class="cell-content">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.sM_Name" class=" cell-content">
                        {{ dataItem.sM_Name }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="branchName" title="Branch" [width]="80" class="cell-content">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.branchName" class=" cell-content">
                        {{ dataItem.branchName }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="actName" title="Act" [width]="250">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.actName" class=" cell-content">
                        {{ dataItem.actName }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="shortForm" title="Compliance" [width]="250">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.shortForm" class="cell-content">
                        {{ dataItem.shortForm }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
                <kendo-pager-next-buttons></kendo-pager-next-buttons>
                <kendo-pager-input></kendo-pager-input>
                <kendo-pager-info></kendo-pager-info>
                <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
            </ng-template>
        </kendo-grid>
    </div>

    <div class="grid" *ngIf="selectedComplianceReassignment.value === 'ActivationDateChange'&&  gridVisible">
        <kendo-grid class="custom-grid" [data]="gridData1" [skip]="skip" [pageSize]="pageSize"
            [columnMenu]="{ filter: true, sort: true }" [pageable]="true" (pageChange)="pageChange($event)">
            <kendo-grid-column field="checkbox" [width]="70" title="" [columnMenu]="false">
                <ng-template kendoGridHeaderTemplate>
                    <div style="margin-left: 25%;">
                        <input type="checkbox" [(ngModel)]="allSelected" (change)="toggleSelectAll($event)" />
                    </div>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div class="checkbox-container">
                        <input type="checkbox" [(ngModel)]="checkedRows[rowIndex]"
                            (change)="onActivationDateCheckboxChange(rowIndex)" />
                    </div>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="complianceId" title="Compliance ID" [width]="140">
                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                    <multicheck-filter 
                    [isPrimitive]="true"
                    [field]="column.field"
                    [filterService]="filterService"
                    [currentFilter]="filter"
                    [data]="getUniqueColumnValues('complianceId')"
                  >
                  </multicheck-filter>
                  </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="shortForm" title="Compliance" [width]="300">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.shortForm" class="cell-content">
                        {{ dataItem.shortForm }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="avacoM_BranchName" title="Branch Name" [width]="150" class="cell-content">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.avacoM_BranchName" class="cell-content">
                        {{ dataItem.avacoM_BranchName }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="sM_Name" title="State" [width]="100" class="cell-content">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.sM_Name" class=" cell-content">
                        {{ dataItem.sM_Name }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="cM_City" title="Location" [width]="100" class="cell-content">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.cM_City" class=" cell-content">
                        {{ dataItem.cM_City }}
                    </div>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="activationDate" title="Activation Date" [width]="200" class="cell-content">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div *ngIf="checkedRows[rowIndex]; else displayFrequency">
                        <app-kendo-datepicker [(value)]="datePickerValue"
                            (valueChange)="onDateChange($event, dataItem, rowIndex)" [format]="'dd-MM-yyyy'"
                            [placeholder]="'Select Date'" [width]="'120px'">
                        </app-kendo-datepicker>
                    </div>
                    <ng-template #displayFrequency>
                        <span (click)="editRow(rowIndex, 'activationDate', dataItem)">
                            {{ formatDate(parseDate(dataItem.activationDate)) }}
                        </span>
                    </ng-template>
                </ng-template>
            </kendo-grid-column>
            <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
                <kendo-pager-next-buttons></kendo-pager-next-buttons>
                <kendo-pager-input></kendo-pager-input>
                <kendo-pager-info></kendo-pager-info>
                <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
            </ng-template>
        </kendo-grid>
    </div>


    <div class="grid" *ngIf="selectedComplianceReassignment.value === 'ScheduleRemoval'&&  gridVisible">
        <kendo-grid class="custom-grid" [data]="gridData2" [columnMenu]="{ filter: true, sort: true }" [skip]="skip"
            [pageSize]="pageSize" [pageable]="true" (pageChange)="pageChange($event)">
            <kendo-grid-column field="checkbox" [width]="62" title="" [columnMenu]="false" class="cell-content">

                <ng-template kendoGridHeaderTemplate>
                    <div style="margin-left: 25%;">
                        <input type="checkbox" [(ngModel)]="allSelected" (change)="toggleSelectAll($event)" />
                    </div>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div class="checkbox-container cell-content">
                        <input type="checkbox" [(ngModel)]="dataItem.selected"
                            (change)="onScheduleCheckboxChange(dataItem.complianceId,dataItem.scheduleid, $event)" />
                    </div>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="complianceId" title="Compliance ID" class="cell-content">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.complianceId" class=" cell-content">
                        {{ dataItem.complianceId }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="scheduleid" title="Schedule ID" class="cell-content">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.scheduleid" class=" cell-content">
                        {{ dataItem.scheduleid }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="shortForm" title="Compliance" class="cell-content">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.shortForm" class=" cell-content">
                        {{ dataItem.shortForm }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="forMonth" title="Period" class="cell-content">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.forMonth" class="cell-content">
                        {{ dataItem.forMonth }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="sM_Name" title="State" class="cell-content">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.sM_Name" class="cell-content">
                        {{ dataItem.sM_Name }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="cM_City" title="Location" class="cell-content">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.cM_City" class="cell-content">
                        {{ dataItem.cM_City }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="avacoM_BranchName" title="Branch">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.avacoM_BranchName" class="cell-content">
                        {{ dataItem.avacoM_BranchName }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="scheduleOn" title="Scheduled On" class="cell-content">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.scheduleOn" class="cell-content">
                        {{ dataItem.scheduleOn }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="complianceStatus" title="Compliance Status" class="cell-content">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.complianceStatus" class="cell-content">
                        {{ dataItem.complianceStatus }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="activationDate" title="Activation Date" class="cell-content">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.activationDate" class="cell-content">
                        {{ dataItem.activationDate }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
                <kendo-pager-next-buttons></kendo-pager-next-buttons>
                <kendo-pager-input></kendo-pager-input>
                <kendo-pager-info></kendo-pager-info>
                <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
            </ng-template>
        </kendo-grid>
    </div>


    <div class="grid" *ngIf="selectedComplianceReassignment.value === 'AssignmentRemoval' &&  gridVisible">
        <kendo-grid class="custom-grid" [data]="gridData1" [skip]="skip" [pageSize]="pageSize"
            [columnMenu]="{ filter: true, sort: true }" [pageable]="{
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: size1,
        previousNext: true
      }" (pageChange)="pageChange($event)">
            <kendo-grid-column field="checkbox" [width]="62" title="" [columnMenu]="false">
                <ng-template kendoGridHeaderTemplate>
                    <div style="margin-left: 25%;">
                        <input type="checkbox" [(ngModel)]="allSelected" (change)="toggleSelectAll($event)" />
                    </div>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div class="checkbox-container ">
                        <input type="checkbox" [(ngModel)]="dataItem.selected"
                            (change)="onCheckboxChange(dataItem,rowIndex)" />
                    </div>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="complianceId" title="Compliance ID" [width]="140"
                class="cell-content"></kendo-grid-column>
            <kendo-grid-column field="shortForm" title="Compliance" [width]="300">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.shortForm" class="cell-content">
                        {{ dataItem.shortForm }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="avacoM_BranchName" title="Branch Name" [width]="100">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.avacoM_BranchName" class="cell-content">
                        {{ dataItem.avacoM_BranchName }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="sM_Name" title="State" [width]="100">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.sM_Name" class="cell-content">
                        {{ dataItem.sM_Name }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="cM_City" title="Location" [width]="100">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.cM_City" class="cell-content">
                        {{ dataItem.cM_City }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="activationDate" title="Activation Date" [width]="100">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.activationDate" class="cell-content">
                        {{ dataItem.activationDate }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
                <kendo-pager-next-buttons></kendo-pager-next-buttons>
                <kendo-pager-input></kendo-pager-input>
                <kendo-pager-info></kendo-pager-info>
                <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
            </ng-template>
        </kendo-grid>
    </div>



    <div *ngIf="openUpload">
        <app-manage-compliance-upload-popup (close)="closePopup()"></app-manage-compliance-upload-popup>
    </div>

    <app-alert-popup *ngIf="alertData" [Width]="270" [Data]="alertData" (close)="closeAlertPopup()"></app-alert-popup>

    <div *ngIf="loading" class="loader-overlay">
        <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
    </div>