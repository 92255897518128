import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { GridDataResult, GridModule } from '@progress/kendo-angular-grid';
import { PageChangeEvent } from '@progress/kendo-angular-treelist';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { LocationMasterServiceService } from '../../../service/location-master-service.service';
import { MultiSelectDropdownComponent } from '../../../components/multi-select-dropdown/multi-select-dropdown.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { NgClass, NgIf } from '@angular/common';
import { MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { FormsModule } from '@angular/forms';
interface FrequencyOption {
  uniqueID: string;
  isSelected: number;
  addressName: string;
}
@Component({
  selector: 'app-view-address-popup',
  standalone: true,
  imports: [DialogModule, GridModule,MultiSelectDropdownComponent,IndicatorsModule,AlertPopupComponent,NgIf,MultiSelectModule,FormsModule],
  templateUrl: './view-address-popup.component.html',
  styleUrl: './view-address-popup.component.css'
})
export class ViewAddressPopupComponent implements OnInit {
  @Output() close = new EventEmitter<void>();
  @Input() Data!: any;
  public buttonCount = 2;
  public loading=false;
  public sizes = [5, 10, 20, 50];
  public gridData: GridDataResult = { data: [], total: 0 };
  public pageSize: number = 10;
  public skip: number = 0;
  selectedDropdown!:any;
  selectedUniqueIDs!:string;
  alertData = { alertMessage: '', alertVisible: '' }
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  public defaultSelectedValueMonth = { uniqueID: 0, addressName: 'Select Address' };

  constructor(private ViewAddressService: LocationMasterServiceService) { }
  ngOnInit(): void {
    this.loadGrid();
  }
  public tagMapper(tags: any[]): any[] {
    return tags.length < 1 ? tags : [tags];
  }


  onValueChange(value: any, dataItem: any) {
    const selectedUniqueIDs = value.map((item: any) => item.uniqueID).join(',');
    dataItem.selectedUniqueIDs = selectedUniqueIDs;
  }
  
  loadGrid() {
    this.ViewAddressService.getAddressList().subscribe({
      next: (res) => {
        const result = res?.body ?? [];
        console.log('grid response',res);
        
        this.gridData = {
          data: result.slice(this.skip, this.skip + this.pageSize),
          total: result.length
        };

          this.gridData.data.forEach((data) => {
            this.loadDropdownData(data);
          });
        this.loading = false;
      },
      error: (err) => {
        console.error('Error fetching address list:', err);
        this.loading = false;
      }
    })
  }

  loadDropdownData(dataItem: any): void {
    console.log('dataitem',dataItem);
    
    this.ViewAddressService.getDropdownData(this.Data.sM_Code, dataItem.dM_Value, this.Data.lM_Code).subscribe({
      next: (res) => {
        console.log('dropdown res',res);
        
        if (res.body.length > 0) {
          dataItem.frequencyOptions = [this.defaultSelectedValueMonth, ...res.body];
  
          dataItem.selectedOptions = dataItem.frequencyOptions
            .filter((option: FrequencyOption) => option.isSelected === 1)
            .map((option: FrequencyOption) => ({
              uniqueID: option.uniqueID,
              addressName: option.addressName,
            }));
  
          if (dataItem.selectedOptions.length === 0) {
            dataItem.selectedOptions = [];
          }
        } else {
          dataItem.frequencyOptions = [];
          dataItem.selectedOptions = [];
        }
      },
      error: (err) => {
        console.error('Error fetching dropdown options:', err);
      }
    });
  }
  
  onSave(data:any){
    this.loading=true;
    this.ViewAddressService.SaveViewAddressData(data.dM_Value,data.selectedUniqueIDs,this.Data.lM_Code,this.Data.sM_Code).subscribe({
      next: (response) => {
        if (response.body.result == "success") {
          this.alertData = { alertMessage: 'Record Added successfully', alertVisible: 'success' }
        }
        else {
          this.alertData = { alertMessage: 'Failed to save data', alertVisible: 'error' }
        }
        this.loading=false;
      },
      error: (error) => {
        this.alertData = { alertMessage: 'Failed to save data', alertVisible: 'error' };
        this.loading=false;
      }
    });
  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.loadGrid();
  }
  cloasAlert(){
    if(this.alertData.alertVisible=='success'){
      this.closePopup();
    }
    this.alertData = { alertMessage: '', alertVisible: '' }
  }
  closePopup() {
    this.close.emit();
  }
}
