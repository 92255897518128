import { ChangeDetectorRef, Component } from '@angular/core';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { BlobService } from '../../../service/blob.service';
import { UserService } from '../../../service/user.service';
import { EcrConfirmationServiceService } from '../../../service/ecr-confirmation-service.service';
import { GridModule, PageChangeEvent } from '@progress/kendo-angular-grid';
import { FormsModule } from '@angular/forms';
import { CommonModule, NgIf } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
// import{DownloadBlue} from '../../../../assets/vectors/DownloadBlue.svg'
import { LabelModule } from '@progress/kendo-angular-label';
import { environment } from '../../../environments/environment';
import { AdobeViewerComponent } from '../../../components/adobe-viewer/adobe-viewer.component';
import { DocViewerGridPopupComponent } from '../../popups/doc-viewer-grid-popup/doc-viewer-grid-popup.component';


@Component({
  selector: 'app-ecr-confirmation',
  standalone: true,
  imports: [AlertPopupComponent, DropdownListComponent, GridModule, NgIf, LabelModule, FormsModule, CommonModule, ReactiveFormsModule, AdobeViewerComponent,DocViewerGridPopupComponent],
  templateUrl: './ecr-confirmation.component.html',
  styleUrl: './ecr-confirmation.component.css'
})
export class EcrConfirmationComponent {
  private blobUrl = environment.blobUrl;
  years: any[] = [];
  fileBlob!: any;
  viewFile!:any
  selectedFiscalYear: any;
  selectedYear = { text: 'Year', value: '' };
  complianceperiods: any[] = [];
  selectedPeriod: any;
  defaultSelectedValueMonth: { text: string, value: string } = { text: 'Period', value: 'Period' };
  public gridData: any[] = [];
  take = 10;
  skip = 0;
  page = 1;
  public pageSize: number = 10;
  allSelected: boolean = false;
  selectedItems: any[] = [];
  showSave = false;
  public buttonCount = 2;
  public sizes = [{text:'All',value:Infinity},5, 10, 20];

  public showGrid: boolean = false;
  public originalData: any[] = [];
  loading = false;
  showValidationErrorsSave = false;
  private userID!: number;
  alertData = { alertMessage: '', alertVisible: '' };
  public uploadPopupOpen: boolean = false;
  public bulkdownload: boolean = false;

  constructor(private EcrConfirmationServiceService: EcrConfirmationServiceService, private blobService: BlobService, private user: UserService, private cdr: ChangeDetectorRef) {
  }
  ngOnInit(): void {
    this.userID = this.user.getUserId();
    this.allSelected = false;
    this.getYears();
    this.getMonthperiods();
    this.loadGridData();

  }

  onYearChange(year: any) {
    this.selectedFiscalYear = year;
    // this.selectedyears = year.map((item: { value: any; }) => item.value);
    console.log('this.selectedFiscalYear', this.selectedFiscalYear)
  }
  getYears(): void {
    const currentYear = new Date().getFullYear();
    this.years = [];
    for (let year = currentYear; year >= 2017; year--) {
      this.years.push({ text: year.toString(), value: year });
    }
  }

  onPeriod(period: any) {
    this.selectedPeriod = period;
    // this.selectedPeriods =period.map((item: { value: any; }) => item.value);
    console.log('period', this.selectedPeriod)
  }


  getMonthperiods() {
    this.EcrConfirmationServiceService.getMonth('Months').subscribe({
      next: (response) => {
        debugger
        const data = response.body;
        if (data && Array.isArray(data)) {
          this.complianceperiods = data.map((item: any) => ({
            value: item.value,
            text: item.text
          }));

          this.complianceperiods = data;
          // this.isPeriodLoaded = true;
        }
      },
      error: (err) => {
        console.error("Error fetching compliance status:", err);
      }
    });
  }

  public pageChange(event: PageChangeEvent): void {
    console.log('Page Change Event Received:', event);
    this.skip = event.skip;
    // this.loadGridData();
    if(event.take==Infinity){
      this.pageSize=this.originalData.length
    }else{
      this.pageSize = event.take;
    }
    this.loadGridData();
  }
  toggleSelectAll(event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    this.allSelected = checked;
    this.gridData.forEach(item => {
      if (!item.disabled) {
        item.selected = checked;
      }
    });
    this.selectedItems = checked ? this.gridData.filter(item => !item.disabled) : [];
    this.bulkdownload = checked;
    this.showSave = checked && this.gridData.some(item => item.pfecrA_IsProcessed === 'No');
  }

  onItemSelectionChange(dataItem: any): void {
    console.log('dataitem', dataItem);

    // dataItem.selected = !dataItem.selected;
    // this.selectedItems = this.gridData.filter(item => item.selected);
    // this.allSelected = this.gridData.every(item => item.selected || item.disabled);

    // this.bulkdownload = this.selectedItems.length > 0;
    // this.showSave = this.selectedItems.some(item => item.pfecrA_IsProcessed === 'No');
    // console.log(this.selectedItems);
      const selectedItems = this.gridData.filter(item => item.selected).length;
      this.showSave = selectedItems >= 1;
      this.bulkdownload = selectedItems > 1;
  }
  // toggleSelectAll(event: Event) {
  //   const checked = (event.target as HTMLInputElement).checked;
  //   this.gridData.forEach(item => item.selected = checked);
  //   // this.bulkdownload=true;
  //   // this.showSave=true;

  // }
  // toggleSelectAll(event: Event) {
  //   const checked = (event.target as HTMLInputElement).checked;

  //   // Update the selected state for each item in gridData
  //   this.gridData.forEach(item => item.selected = checked);

  //   // Toggle bulkdownload and showSave based on checkbox state
  //   if (checked) {
  //     this.bulkdownload = true;
  //     this.showSave = true;
  //   } else {
  //     this.bulkdownload = false;
  //     this.showSave = false;
  //   }
  // }

  applyFilters() {

    // Ensure you are passing the correct type for year and month (probably numbers or strings)
    if (this.selectedFiscalYear?.value && this.selectedPeriod?.value) {
      this.loadGridData();
      this.showGrid = true;

    } else {
      console.warn('Please select both a valid fiscal year and period before applying filters.');
    }

  }

  // loadGridData() {
  //   this.loading=true;
  // let payload={
  //   ClientID:this.user.getClientId(),
  //   month:this.selectedPeriod,
  //   year:this.selectedFiscalYear
  // }
  //   this.selectedItems = [];
  //   this.EcrConfirmationServiceService.getReportGridData(payload).subscribe(
  //     {
  //       next: (response) => {
  //         const data = response.body;
  //         if (data && data && Array.isArray(data)) {
  //           this.gridData = data;
  //           this.originalData = data;
  //           this.loading = false;
  //       } else {
  //         console.log('Unexpected API response format:', data);
  //       }
  //       console.log('grid',this.gridData);

  //     }, error: (error) => {
  //       console.error('Error fetching grid data:', error);
  //       this.loading = false;
  //     }
  //   });
  // }
  loadGridData() {
    const payload = {
      ClientID: this.user.getClientId(),
      month: this.selectedPeriod.value,
      year: this.selectedFiscalYear.value
    };
    this.selectedItems = [];

    this.loading = true;
    this.EcrConfirmationServiceService.getReportGridData(payload).subscribe({
      next: (response) => {
        const data = response.body;
        if (data && Array.isArray(data)) {
          this.gridData = data;
          this.originalData = data;
        }

        else {
          console.warn('Unexpected API response format:', data);
        }
        console.log('Grid Data:', this.gridData);
        this.loading = false;
      },
      error: (error) => {
        console.error('Error fetching grid data:', error);
        this.loading = false;
      }
    });
  }

  OnFinalize() {
    this.loading = true;
    // this.showValidationErrorsFinalize = true;

    // const body = {
    //   UserId:this.userID, // assuming custID holds the logged-in user's ID
    //   _RecordIDList: this.selectedItems.map(item => item.pfecrA_ID), // finalization requires _RecordIDList array
    // };
    const body = {
      UserId: this.userID, // assuming custID holds the logged-in user's ID
      _RecordIDList: this.selectedItems.map(item => String(item.pfecrA_ID)), // ensure all IDs are strings
    };
    if (this.selectedItems && this.userID) {
      this.EcrConfirmationServiceService.FinalizeComplianceAssignment(body).subscribe({
        next: (response) => {
          console.log(response);
          if (response.status == 200) {
            this.loading = false;
            this.alertData = { alertMessage: 'Compliance Finalized Successfully', alertVisible: 'success' };
            this.loadGridData(); // reload the grid data after finalization
            this.showSave = false;
          } else {
            this.loading = false;
            this.alertData = { alertMessage: 'Compliance Finalization Failed', alertVisible: 'error' };
          }
        },
        error: (error) => {
          console.error('Error during finalization', error);
          this.loading = false;
          this.alertData = { alertMessage: 'Compliance Finalization Failed', alertVisible: 'error' };
        }
      });
    } else {
      this.loading = false;
      if (!this.selectedItems) {
        this.alertData = { alertMessage: 'Please Select Grid Columns for Finalization', alertVisible: 'error' };
      } else if (!this.userID) {
        this.alertData = { alertMessage: 'User is not authorized to finalize compliance', alertVisible: 'error' };
      }
    }
  }
  closeAlert() {
    this.alertData = { alertMessage: '', alertVisible: '' };
  }
  onIconClick(dataItem: any) {
    debugger
    this.handleRegionalAction(dataItem);
  }
  onIconClickView(dataItem: any) {
    console.log(dataItem);
    this.viewFile={
      data:[{fileName:dataItem.pfecrA_DocType,filePath:dataItem.pfecrA_TextFilePath},
        {fileName:dataItem.pfecrA_FileName,filePath:dataItem.pfecrA_ExcelFilePath}],
        length:2
    };
  }
  ViewFile(value:any){
    console.log(value);
    
     this.EcrConfirmationServiceService.fetchFile(value.dataItem.filePath).subscribe({
      next: (blob: Blob) => {
        debugger;
        this.fileBlob = blob;
        this.loading = false;
      },
      error: (error) => {
        console.error('Error fetching Branch Dropdown data', error);
      }
    });
  }
closeViewer(){
  debugger
  this.fileBlob=null;
}
closeDocGrid(){
  this.viewFile=null;
}
  handleRegionalAction(dataItem: any) {
    debugger;
    console.log('Download icon clicked for:', dataItem);
    this.loading = true;
    this.downloadFile(dataItem.pfecrA_ExcelFilePath, dataItem.pfecrA_FileName);
  }

  downloadFile(filePath: string, fileName: string) {
    debugger;
    const blobLink = this.blobUrl + filePath;
    const blobName = this.blobService.extractBlobName(blobLink);
    setTimeout(() => {
      this.blobService.getSasToken(blobName).subscribe((tokenResponse: any) => {
        debugger;
        const sasToken = tokenResponse.result;
        this.blobService.downloadFile(blobName, sasToken);
        this.loading = false;
      });
    }, 5000);
  }

  public parentColumns: any[] = [

    { field: 'pfecrA_DocType', title: 'Document Type' },
    { field: 'pfecrA_Code', title: 'Code' },
    { field: 'pfecrA_FileName', title: 'File Name' },
    { field: 'pfecrA_IsFinalized', title: 'Finalize' },
    { field: 'pfecrA_IsProcessed', title: 'Processed' },
    { field: 'pfecrA_FinalizedDate', title: 'Form Generation' },
    { field: 'pfecrA_AutomationStatus', title: 'Upload Status' },
    { hidden: true, field: 'pfecrA_ID', title: 'RecordId' },
    {
      field: 'requiredForms',
      isIconColumn: true,
      title: 'View Document',
      filteredIcons: [
        {
          url: '../assets/vectors/DownloadBlue.svg',
          alt: 'Download Document',
          action: 'download',
          class: 'svg-icon'
        },
        {
          url: '../assets/vectors/EyeIcon.svg',
          alt: 'View Document',
          action: 'view',
          class: 'svg-icon'
        }
      ]
    }
  ];
  // parentColumns: any[] = [
  //   { field: 'pfecrA_DocType', title: 'Document Type' },
  //     { field: 'pfecrA_Code', title: 'Code' },
  //     { field: 'pfecrA_FileName', title: 'File Name' },
  //     { field: 'pfecrA_IsFinalized', title: 'Finalize' },
  //     { field: 'pfecrA_IsProcessed', title: 'Processed' },
  //     { field: 'pfecrA_FinalizedDate', title: 'Form Generation' },
  //     { field: 'pfecrA_AutomationStatus', title: 'Upload Status' },
  //     {
  //       field: 'regional',
  //       isIconColumn: true,
  //       title: 'Regional',
  //       filteredIcons: [
  //         {
  //           url: '../assets/vectors/DownloadBlue.svg',
  //           alt: 'Icon 1',
  //           action: 'regional'
  //         },
  //         {
  //           url: '../assets/vectors/EyeIcon.svg',
  //           alt: 'Icon e',
  //           action: 'eye'
  //         },
  //       ]
  //     },
  //     {
  //       field: 'english',
  //       isIconColumn: true,
  //       title: 'English',
  //       filteredIcons: [
  //         {
  //           url: '../assets/vectors/DownloadBlue.svg',
  //           alt: 'Icon 1',
  //           action: 'english'
  //         },
  //         {
  //           url: '../assets/vectors/EyeIcon.svg',
  //           alt: 'Icon e',
  //           action: 'eye'
  //         },
  //       ]
  //     },
  //     {
  //       field: 'acknowledgement',
  //       title: 'Acknowledgement',
  //       isIconColumn: true,

  //     }
  //   ];

  bulkDownload() {
    debugger;
    const payload = {
      entityId: this.user.getClientId(),
      recordIds: this.selectedItems.map(item => String(item.pfecrA_ID)).join(',')
    };
    console.log('payload', payload);

    this.EcrConfirmationServiceService.ecrBulkDownload(payload).subscribe({
      next: (response) => {
        debugger;
        const blob = new Blob([response.body], { type: 'application/zip' });
        const downloadLink = document.createElement('a');
        const fileName = 'PF_ECR.zip';
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = fileName;
        downloadLink.click();
      },
      error: (error) => {
        debugger;
        console.error('Error downloading files:', error);
      }
    });
  }

}
