<kendo-dialog #dialogRef title="Customer Details" (close)="closePopup()" [width]="900" class="custom-dialog">
    <div class="row col-12 dialog-content">
        <form [formGroup]="form">
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Name<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="Customer Name"
                            formControlName="cO_Name" (input)="generateCorporateId()"
                            (keydown)="onKeyDownBuyerName($event)"
                            [ngClass]="{'is-invalid': form.get('cO_Name')?.invalid && (form.get('cO_Name')?.touched)}">
                        <div *ngIf="form.get('cO_Name')?.invalid && (form.get('cO_Name')?.touched)" class="text-danger">
                            <small *ngIf="form.get('cO_Name')?.errors?.['pattern']" style="float: left;">
                                Must contain only alphanumeric characters
                            </small>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">CorporateId<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <div class="masking d-flex align-items-center" style="bottom: 5px;">
                            <input type="text" class="form-control f-textbox masking" placeholder="Corporate ID"
                                formControlName="cO_CorporateID" style="width: 210px;" (input)="FormatCorporate($event)"
                                (keydown)="allowAlphanumeric($event)" maxlength="10"
                                [ngClass]="{'is-invalid': form.get('cO_CorporateID')?.invalid && form.get('cO_CorporateID')?.touched}" />
                            <button (click)="CheckCorporateIdExists()" type="button" class="figma-btn-blue"
                                [disabled]="hasPredefinedData">
                                Check
                            </button>
                        </div>
                        <div *ngIf="form.get('cO_CorporateID')?.invalid && (form.get('cO_CorporateID')?.touched) && form.get('cO_CorporateID')?.errors?.['pattern']"
                            class="text-danger">
                            Must contain only alphanumeric characters
                        </div>
                        <small *ngIf="!form.get('cO_CorporateID')?.errors?.['pattern'] && startswith "
                            class="text-danger">
                            CorporateId should starts with AVA only
                        </small>
                        <small
                            *ngIf="!form.get('cO_CorporateID')?.errors?.['pattern'] && !startswith && CorporateIdExist==null && isSameAsName"
                            class="text-danger">
                            Corporate ID should not be the same as Customer Name
                        </small>
                        <small
                            *ngIf="!form.get('cO_CorporateID')?.errors?.['pattern'] && !startswith && ButtonClick==false"
                            class="text-danger">
                            Please click on Check to verify CorporateId
                        </small>
                        <small
                            *ngIf="!form.get('cO_CorporateID')?.errors?.['pattern'] && !startswith && CorporateIdExist==true && ButtonClick==true "
                            class="text-danger">
                            CorporateID already exists
                        </small>
                        <small
                            *ngIf="!form.get('cO_CorporateID')?.errors?.['pattern'] && !startswith && CorporateIdExist==null && ButtonClick==true"
                            class="text-green">

                        </small>
                        <small
                            *ngIf="!form.get('cO_CorporateID')?.errors?.['pattern'] && !startswith && CorporateIdExist==false && ButtonClick==true "
                            class="text-green">
                            CorporateID Available
                        </small>
                    </div>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Buyer Name<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="Buyer Name"
                            formControlName="buyerName" (keydown)="onKeyDownBuyerName($event)"
                            [ngClass]="{'is-invalid': form.get('buyerName')?.invalid && (form.get('buyerName')?.touched)}">
                        <div *ngIf="form.get('buyerName')?.invalid && (form.get('buyerName')?.touched)"
                            class="text-danger">
                            <small *ngIf="form.get('buyerName')?.errors?.['pattern']">
                                Buyer Name must contain only letters and spaces.
                            </small>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Contact No<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="text" class="form-control f-textbox" placeholder="Contact No"
                            formControlName="buyerContactNumber" minlength="10" maxlength="10"
                            (input)="validateMobileNo($event)"
                            [ngClass]="{'is-invalid': form.get('buyerContactNumber')?.invalid && (form.get('buyerContactNumber')?.touched)}">
                        <div *ngIf="form.get('buyerContactNumber')?.invalid && (form.get('buyerContactNumber')?.touched)"
                            class="text-danger">
                            <small *ngIf="form.get('buyerContactNumber')?.errors?.['pattern']" style="float: left;">
                                Contact No must be exactly 10 digits.
                            </small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Buyer Email<sub class="text-danger">*</sub></label>
                    <div class="require-container">
                        <input type="email" class="form-control f-textbox" placeholder="Email"
                            formControlName="buyerEmail"
                            [ngClass]="{'is-invalid': form.get('buyerEmail')?.invalid && (form.get('buyerEmail')?.touched)}">
                        <div *ngIf="form.get('buyerEmail')?.invalid && (form.get('buyerEmail')?.touched)"
                            class="text-danger">
                            <small *ngIf="form.get('buyerEmail')?.errors?.['pattern']" style="float: left;">
                                Please enter a valid Email address.
                            </small>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Status</label>
                    <app-dropdown-list [data]="StatusdropdownData" [selectedValue]="Status" [width]="'250px'"
                        [isDisabled]="form.disabled" textField="text" valueField="value"
                        (valueChange)="onDropdownValueChange($event, 'Status')">
                    </app-dropdown-list>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">PAN</label>
                    <div class="require-container position-relative masking">
                        <div class="masking d-flex align-items-center">
                            <input type="text" class="form-control f-textbox masking" placeholder="XXXXX0000X"
                                formControlName="cO_PAN" minlength="10" maxlength="10" style="width: 210px;"
                                (input)="formatPan($event)" [(ngModel)]="cO_PAN"
                                [ngClass]="{'is-invalid':  showValidationErrorsPAN}" />
                            <button type="button" [disabled]="form.disabled" class="figma-btn-white"
                                (click)="togglePanVisibility()">
                                <img *ngIf="showPan" src="../assets/vectors/eye_112_x2.svg"
                                    alt="Toggle PAN visibility" />
                                <img *ngIf="!showPan" src="../assets/vectors/EyeClosed.svg" alt="">
                            </button>
                        </div>
                        <div *ngIf="showValidationErrorsPAN" class="text-danger">
                            PAN must follow the format AAAAA1111A
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">TAN</label>
                    <div class="require-container position-relative masking">
                        <div class="masking d-flex align-items-center">
                            <input type="text" class="form-control f-textbox masking" placeholder="XXXXX0000X"
                                formControlName="cO_TAN" minlength="10" maxlength="10" style="width: 210px;"
                                (input)="formatcO_TAN($event)" [(ngModel)]="cO_TAN"
                                [ngClass]="{'is-invalid': form.get('cO_TAN')?.invalid && form.get('cO_TAN')?.touched}" />
                            <button type="button" [disabled]="form.disabled" class="figma-btn-white"
                                (click)="togglecO_TANVisibility()">
                                <img *ngIf="showcO_TAN" src="../assets/vectors/eye_112_x2.svg"
                                    alt="Toggle cO_TAN visibility" />
                                <img *ngIf="!showcO_TAN" src="../assets/vectors/EyeClosed.svg" alt="">
                            </button>
                        </div>
                        <div *ngIf="form.get('cO_TAN')?.invalid && form.get('cO_TAN')?.touched" class="text-danger">
                            <small *ngIf="form.get('cO_TAN')?.errors?.['pattern']" style="float: left;">
                                TAN must follow the format XXXXX0000X.
                            </small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Address</label>
                    <textarea class="form-control f-textbox" formControlName="address" placeholder="Address"></textarea>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Country</label>
                    <app-dropdown-list [data]="CountrydropdownData" [selectedValue]="Country" [width]="'250px'"
                        [isDisabled]="form.disabled" textField="text" valueField="value"
                        (valueChange)="onDropdownValueChange($event, 'Country')">
                    </app-dropdown-list>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">State</label>
                    <app-dropdown-list [data]="StatedropdownData" [selectedValue]="State" [width]="'250px'"
                        [isDisabled]="form.disabled" textField="text" valueField="value"
                        (valueChange)="onDropdownValueChange($event, 'State')">
                    </app-dropdown-list>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">City</label>
                    <app-dropdown-list [data]="CitydropdownData" [selectedValue]="City" [width]="'250px'"
                        [isDisabled]="form.disabled" textField="text" valueField="value"
                        (valueChange)="onDropdownValueChange($event, 'City')">
                    </app-dropdown-list>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Compliance Product</label>
                    <app-dropdown-list [data]="CompliancedropdownData" [selectedValue]="Compliance" [width]="'250px'"
                        [isDisabled]="form.disabled" textField="text" valueField="value"
                        (valueChange)="onDropdownValueChange($event, 'Compliance')">
                    </app-dropdown-list>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Assign Product</label>
                    <kendo-listbox [toolbar]="false" [data]="AssignProductdropdownData" textField="text"
                        valueField="value">
                    </kendo-listbox>
                </div>
            </div>
            <div class="row EmpContainer">
                <div class="col-md-6 d-flex align-items-center">
                    <label class="f-label me-3">Logo</label>
                    <span *ngIf="!fileNames['Logo']" style="color: #A0A0A0;">No file chosen</span>
                    <span *ngIf="fileNames['Logo']">
                        {{fileNames['Logo']}}
                    </span>
                    <input type="file" #Logo (change)="onFileChange($event, 'Logo')" id="fileInput"
                        style="display: none;" accept="image/*" />
                    <button class="figma-btn-gray" [disabled]="form.disabled" (click)="Logo.click()">Browse</button>
                </div>
            </div>
            <kendo-dialog-actions style="border: none;">
                <button type="button" *ngIf="!form.disabled" (click)="onSave()" class="figma-btn-blue figma-popup">
                    Save
                </button>
            </kendo-dialog-actions>
            <!-- <kendo-dialog-actions style="border: none;">
                <button type="button" (click)="onSave()" class="figma-btn-blue figma-popup" 
                    [disabled]="!form.get('cO_CorporateID')?.valid || CorporateIdExist !== true">
                    Save
                </button>
            </kendo-dialog-actions> -->
        </form>
    </div>
    <div *ngIf="loading" class="loader-overlay">
        <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
    </div>

</kendo-dialog>

<app-alert-popup *ngIf="alertData.alertVisible" [Width]="270" [Height]="270" [Data]="alertData"
    (close)="closeAlert()"></app-alert-popup>