import { NgIf } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { GridModule } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-manage-compliance-upload-popup',
  standalone: true,
  imports: [DialogModule,NgIf,ReactiveFormsModule,GridModule],
  templateUrl: './manage-compliance-upload-popup.component.html',
  styleUrl: './manage-compliance-upload-popup.component.css'
})
export class ManageComplianceUploadPopupComponent {
  myGroup!: FormGroup;
  Name:string='Compliance Assignment'
  @Output() close = new EventEmitter<void>();
  public selectionType: string | null = 'CA';
  
 
  onRadioChange(value: string) {
    this.selectionType = value;
    switch (value) {
      case 'CA':
        this.Name = 'Compliance Assignment';
        break;
      case 'CR':
        this.Name = 'Compliance Re-Assignment';
        break;
      case 'RA':
        this.Name = 'Remove Assignment';
        break;
      case 'RS':
        this.Name = 'Remove Schedules';
        break;
      case 'CAD':
        this.Name = 'Change Activation Date';
        break;
      default:
        this.Name = 'Compliance Assignment';
    }
  }

  downloadTemplate(){
      if (this.selectionType === 'CA') {
        const link = document.createElement('a');
        link.href = '../assets/files/ComplianceAssignment.xlsx'; 
        link.download = 'ComplianceAssignment.xlsx'; 
        link.click();
      }
      else if (this.selectionType === 'CR') {
        const link = document.createElement('a');
        link.href = '../assets/files/ComplianceReAssignment.xlsx'; 
        link.download = 'ComplianceReAssignment.xlsx'; 
        link.click();
      }
      else if (this.selectionType === 'RA') {
        const link = document.createElement('a');
        link.href = '../assets/files/RemoveAssignment.xlsx'; 
        link.download = 'RemoveAssignment.xlsx'; 
        link.click();
      }
      else if (this.selectionType === 'RS') {
        const link = document.createElement('a');
        link.href = '../assets/files/RemoveSchedules.xlsx'; 
        link.download = 'RemoveSchedules.xlsx'; 
        link.click();
      }
      else if (this.selectionType === 'CAD') {
        const link = document.createElement('a');
        link.href = '../assets/files/ChangeActivationDate.xlsx'; 
        link.download = 'ChangeActivationDate.xlsx'; 
        link.click();
      }
    
  }

  uploadFiles(){}

  closePopup(){
    this.close.emit();
  }
}
