import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DispenceryMasterService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getAllStatesForDispencery(): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/ESIC/GetAllStatesForDispencery`;
    return this.http.get(url, { observe: 'response' });
  }
  getAllDistrictsForDispencery(stateId:string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/ESIC/GetDistrictByStateid?Stateid=${stateId}`;
    return this.http.get(url, { observe: 'response' });
  }

  getDispenceryDataList(State: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/ESIC/GetDispenceryData?StateName=${State}`;
    return this.http.get(url, { observe: 'response' });
  }

  getDispenceryExcel(): Observable<Blob> {
    const url = `${this.apiUrl}/api/ESIC/Dispencery_ExportToExcel`;
    return this.http.post<Blob>(url, {});
  }

  saveDispenceryMasterData(userForm: any,state:string, id: any): Observable<HttpResponse<any>> {
    let stringId: string;
    if (id) {
      stringId = id.toString();
    } else {
      stringId = "";
    }
    const url = `${this.apiUrl}/api/ESIC/SaveDispenceryData?StateName=${state}&District=${userForm.districtName}&Dispencery=${userForm.dispenceryName}&RecordId=${stringId}`;
    return this.http.get(url, { observe: 'response' });
  }

}
