<div class="row colpadding0" style="margin-bottom: 15px;">
    <div class="col-md-12 colpadding0 toolbar" style="margin-top: 10px;">
        
            <input kendoTextBox placeholder="Type to Search" class="f-textbox"  (keyup)="applyFilter($event)">
            <button class="figma-btn-blue add-new" (click)="openAddNewPopup()" type="submit">
                <img src="../assets/vectors/Plus.svg" alt="">Add New
            </button>
    
        </div>
        </div>
    <app-grid-action-item [gridData]="gridData" [columns]="parentColumns" [pageSize]="10" [pageable]="true" [height]="500" [filterable]="true" (buttonClick)="handleButtonClick($event)" >
    </app-grid-action-item>

    <app-notice-add-new-popup *ngIf="openAddNotice" (close)="closeAddPopup()"></app-notice-add-new-popup>
    <div *ngIf="loading" class="loader-overlay">
        <kendo-loader type="converging-spinner" themeColor="primary" class="loader"></kendo-loader>
    </div>
    <app-adobe-viewer [fileBlob]="fileBlob" *ngIf="fileBlob" (close)="closeViewer()"></app-adobe-viewer>