import { Component, OnInit } from '@angular/core';
import { GridActionItemComponent } from '../../../components/grid-action-item/grid-action-item.component';
import { AlertPopupComponent } from '../../popups/alert-popup/alert-popup.component';
import { GridDataResult, GridModule } from '@progress/kendo-angular-grid';
import { ComboBoxComponent } from '../../../components/combo-box/combo-box.component';
import { CommonModule, NgClass, NgFor, NgIf } from '@angular/common';
import { MinimumWagesMasterService } from '../../../service/minimum-wages-master.service';
import { DropdownListComponent } from '../../../components/dropdown-list/dropdown-list.component';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { MultiSelectDropdownComponent } from '../../../components/multi-select-dropdown/multi-select-dropdown.component';
import { MWUploadPopupComponent } from '../../popups/mwupload-popup/mwupload-popup.component';
import { MWAddNewPopupComponent } from '../../popups/mwadd-new-popup/mwadd-new-popup.component';
interface EditedCell {
  rowIndex: number;
  field: string;
}
@Component({
  selector: 'app-minimum-wages',
  standalone: true,
  imports: [GridActionItemComponent, AlertPopupComponent, MWAddNewPopupComponent,ReactiveFormsModule,MWUploadPopupComponent, ComboBoxComponent, NgIf, DropdownListComponent, GridModule, ButtonsModule, MultiSelectModule, FormsModule, MultiSelectDropdownComponent, NgFor, NgClass],
  templateUrl: './minimum-wages.component.html',
  styleUrl: './minimum-wages.component.css'
})
export class MinimumWagesComponent implements OnInit {
  public gridData: GridDataResult = { data: [], total: 0 };
  public alertData = { alertMessage: '', alertVisible: '' };
  deleteId = 0;
  uploadPopup=false;
  public showGrid = true;
  public pageSize = 10;
  public skip = 0;
  public buttonCount = 5;
  public sizes = [{text:'All',value:1},5, 10, 20];
  public editedRowIndex: number | null = null;
  public editedColumn: string | null = null;
  public editedCell: EditedCell | null = null;
  AddNewPopup=false;
  constructor(private MinimumWagesService: MinimumWagesMasterService) {

  }

  ngOnInit(): void {
    this.loadGrid();
  }

  addNew(){
this.AddNewPopup=true;
  }
 
  pageChange(event: any): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    if(event.take==1){
      this.pageSize = this.gridData.total;
    }else  {
      this.pageSize = event.take;
    }
    this.loadGrid();
  }
  // isCellBeingEdited(rowIndex: number, field: string): boolean {
  //   return !!this.editedCell && this.editedCell.rowIndex === rowIndex && this.editedCell.field === field;
  // }
  public isCellBeingEdited(index: number, column: string): boolean {
    return this.editedRowIndex === index && this.editedColumn === column;
  }

  // editRow(rowIndex: number, field: string): void {
  //   this.editedCell = { rowIndex, field };
  // }
  public editRow(index: number, column: string): void {
    this.editedRowIndex = index;
    this.editedColumn = column;
  }
  onBasicChange(event: Event, dataItem: any): void {
    dataItem.basic = (event.target as HTMLInputElement).value;
  }

  onDAChange(event: Event, dataItem: any): void {
    dataItem.da = (event.target as HTMLInputElement).value;
  }

  onHRAChange(event: Event, dataItem: any): void {
    dataItem.hra = (event.target as HTMLInputElement).value;
  }

  onVDAChange(event: Event, dataItem: any): void {
    dataItem.vda = (event.target as HTMLInputElement).value;
  }

  onSplAllowanceChange(event: Event, dataItem: any): void {
    dataItem.spl_Allowance = (event.target as HTMLInputElement).value;
  }
 
  // onSave(dataItem: any): void {
  //   console.log(dataItem)
  //   this.MinimumWagesService.saveMWMaster(dataItem).subscribe( {
  //     next:(response)=> {
  //       this.alertData = { alertMessage: 'Data Updated Successfully', alertVisible: 'success' };
  //     },error:(error)=>{
  //       console.error('Error:', error);
  //       this.alertData = { alertMessage: error.error.Result, alertVisible: 'error' };
  //     }
  //   });
  // }
  onSave(dataItem: any): void {
    this.MinimumWagesService.saveMWMaster(dataItem).subscribe({
      next: (response) => {
        if (response.status === 200) {
          this.alertData = { alertMessage: 'Data Updated Successfully', alertVisible: 'success' };
        } else {
          this.alertData = { alertMessage: 'Failed to Update', alertVisible: 'error' };
        }
      },
      error: (error) => {
        this.alertData = { alertMessage: 'Error while saving data', alertVisible: 'error' };
        console.log('error', error);
      }
    });
  }

  getGridDataItem(id: any): any {
    return this.gridData.data.find((item: any) => item.id === id);
  }
  openUploadPopup() {
    this.uploadPopup = true;
  }
  loadGrid() {
    this.MinimumWagesService.getMinimumWage().subscribe(
      {
        next:(response)=> {
          // const data=response.body;
          // this.gridData = data;
          if (response.status === 200) {
            const data = response.body;
            this.gridData = {
              data: data.slice(this.skip, this.skip + this.pageSize),
              total: data.length
            };
          }
          else {
            console.error('Error fetching entity data');
          }
        },error:(error)=>{
          console.error('Error:', error);
        }
      });
  }

  confirmAlert(event: any): void {
    this.deleteId = event.id;
    this.alertData = { alertMessage: 'Are you sure you want to delete this record?', alertVisible: 'confirm' };
  }
  DeleteMWMaster(value: any) {
    console.log(value)
    this.alertData = { alertMessage: '', alertVisible: '' };
    this.MinimumWagesService.deleteMWMaster(value).subscribe(
      {
        next:(response)=> {
          this.alertData = { alertMessage: 'Data Deleted Successfully', alertVisible: 'success' };
          this.loadGrid();
        },error:(error)=>{
          this.alertData = { alertMessage: 'Data Deletion Fail', alertVisible: 'error' };
        }
      });
  }
  public closePopup(): void {
    this.alertData = { alertMessage: '', alertVisible: '' };
    this.uploadPopup=false;
    this.AddNewPopup=false;
  }
}


