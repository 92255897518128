<div class="row colpadding0">
    <div class="col-md-12 colpadding0 toolbar" style="margin-top: 10px;">
        <div class="col-md-3 colpadding0" style="padding-right: 10px;">
            <app-combo-box  [width]="'100%'" [Placeholder]="'Search State/Select State'"  (valueChange)="onDropdownValueChange($event)" [data]="dropdownData">
            </app-combo-box>
        </div>
        <div class="col-md-5 colpadding0">
            <input class="f-textbox" type="text" style="width: 45%; " (keyup)="applyFilter($event)" placeholder="Type to Search">
        </div>
        <div class="col-md-4 colpadding0">
            <div class="button-container"  style="float: right; ">
                <button (click)="exportToExcel()" class="exportbtn">
                    <img src="../assets/vectors/ExportButton.svg" alt="">
                </button>
                <button class="figma-btn-blue" (click)="openAddNewPopup()" type="submit">
                    <img src="../assets/vectors/Plus.svg" alt="">Add New
                </button>
            </div>
        </div>
    </div>
</div>

<div class="row colpadding0 " style="margin-top: 1%">
    <div class="col-md-12 colpadding0">
        <app-grid-action-item [gridData]="gridData" [columns]="parentColumns" [pageSize]="10" [pageable]="true"
            [filterable]="true" (buttonClick)="handleButtonClick($event)" style="margin-left:0px;">
        </app-grid-action-item>
    </div>
</div>

<div *ngIf="isAddNewVisible" class="popup-overlay">
    <app-dispencerymaster-popup [data]="rowData" [defaultSelectedValue]="dropData"
        (close)="handleClosePopup()"></app-dispencerymaster-popup>
</div>